import { Component, forwardRef, ViewEncapsulation, Output, EventEmitter, Input } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { RetailLocalization } from '../common/localization/retail-localization';


@Component({
  selector: 'app-retail-toggle-switch',
  templateUrl: './retail-toggle-switch.component.html',
  styleUrls: ['./retail-toggle-switch.component.scss'],
  encapsulation:ViewEncapsulation.None,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => RetailToggleSwitchComponent),
      multi: true
    }
  ]
})
export class RetailToggleSwitchComponent implements ControlValueAccessor {
  @Output()  changeToggleEvent:EventEmitter<any> = new EventEmitter();
  @Input() disableToggle: boolean = false;
  @Input('value') _value = false;
  onChange = (value: any) => {
    //the on change
  };
  onTouched = () => {
    // the on touched.
  };
  localisation:any;

  constructor(private ls:RetailLocalization) {
    this.localisation = ls;
    this.disableToggle = this.disableToggle != undefined ? this.disableToggle : false
  }

  get name() {
    return this._value;
  }

  set name(val) {
    this._value = val;

  }


  writeValue(value): void {
    if (value || value == "true") {
      this.name = true;
    }
    else {
      this.name = false;
    }

  }

  setDisabledState(isDisabled :  boolean) :  void {


    this.disableToggle  =  isDisabled;
  }

  toggleChange($event) {
    this.name = !this.name;
    this.changeToggleEvent.emit([$event.checked]);
    this.onChange($event.checked);
    this.onTouched();
  }

  registerOnChange(fn: (value: string) => void): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }


}
