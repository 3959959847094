import { Component, EventEmitter, Input, OnInit, Output, ViewChild, ViewEncapsulation } from '@angular/core';
import { from, Observable, of } from 'rxjs';
import { MatDialog } from '@angular/material/dialog';
import { map } from 'rxjs/operators';
import { ActionTypeEnum, FromTypeEnum } from 'src/app/common/components/cdkvirtual/cdkvirtual.model';
import { createModal } from 'src/app/common/components/create-modal/create-model';
import { ActionMode, AlertAction, AlertType, ButtonType } from 'src/app/common/enums/shared-enums';
import { ButtonValue, TableHeaderOptions, TableOptions, TableSearchHeader } from 'src/app/common/Models/ag-models';
import { EventSchedulerBusiness } from './event-scheduler.business';
import { ExecuteQuery, PatternType, UI, USERPREFERENCE_SCREENTYPE } from './event-scheduler.model';
import { ReportAPIModel, ReportAPIOptions, ReportDownloadFormat } from './event-scheduler.model';
import { UntypedFormGroup } from '@angular/forms';
import { cloneDeep } from 'lodash';
import { CdkDragDrop } from '@angular/cdk/drag-drop';
import { moveItemInArray, transferArrayItem } from '@angular/cdk/drag-drop';
import { CommonUtilities } from '../utilities/common-utilities';
import { EventSchedulerHistoryComponent } from 'src/app/common/shared/shared/event-scheduler-history/event-scheduler-history.component';
import { Localization } from 'src/app/common/localization/localization';

@Component({
  selector: 'app-shared-event-scheduler',
  templateUrl: './event-scheduler.component.html',
  styleUrls: ['./event-scheduler.component.scss'],
  providers: [EventSchedulerBusiness],
  encapsulation: ViewEncapsulation.None
})
export class EventSchedulerComponent implements OnInit {
  @ViewChild('configurePopover_Grid') configurePopover_Grid;

  captions: any;
  ReportSchedular: any;
  CreationToggler: boolean;
  headerOptions: TableHeaderOptions[] = [];
  isViewOnly: boolean;
  originalData: Observable<UI.ScheduleGrid[]>;
  options: TableOptions;
  searchHeaderOption: TableSearchHeader;
  searchText: string;
  tableContent: any;
  showInactiveTog: any;
  createModalData: createModal;
  crudActionInput: UI.CrudInput;
  IsEditModeEnabled = false;
  reportConfig: { reportGroup: number, reportAPIOptions: ReportAPIModel };
  reportGroup: number;
  configureButtonType: ButtonValue;
  showConfigure = true;
  _configureHeaderOptions: any[];
  _configureHeaderOptions_Grid: any[] = this.business.getTableConfigureInitData();
  _configureHeaderOptions_Grid1 = cloneDeep(this._configureHeaderOptions_Grid);
  renderingScreenObj: any;
  _configureHeaderOptionsPr;
  _configureHeaderOptions_Grid1Pr;
  formobjCol: any;
  configureFieldsDisable = false;
  report: any;
  productId: number;
  disableCreateButton: boolean;
  extractMonitorButton: ButtonValue;
  scheduleState = UI.ScheduleState;
  @Output() editSlider = new EventEmitter();
  dropDownEmit: any;
  saveReportConfig: any;
  executeQuery : ExecuteQuery
  @Output() emitReportSelector = new EventEmitter();
  @Output() retailReportEmitter = new EventEmitter();
  @Output() slideEmit = new EventEmitter();
  @Output() editEventEmit = new EventEmitter();

  @Input('report')
  set setInputs(value) {
    this.report = value;
  }

  @Input('isViewOnly')
  set setViewOnly(value) {
    this.isViewOnly = value;
  }
  @Input('displayReportConfiguration')
  set setdropdownConfig(value) {
    this.dropDownEmit = value;
  }
  @Input('saveReportConfig')
  set setsaveReportConfig(value){
    if(value){
      this.saveReportConfig = value;
    }
  }
  @Input('callEdit')
  set setcallEdit(value){
    if(value){
      this.editEvent(value);
    }
  }
  constructor(private business: EventSchedulerBusiness, private utilities: CommonUtilities, private dialog: MatDialog, private localization: Localization) {
    this.captions = this.business.captions;
  }

  ngOnInit(): void {
    this.extractMonitorButton = {
      type: 'primary',
      label: this.captions.lbl_extractMonitor,
      disabledproperty: false
    };
    this.productId = Number(this.utilities.GetPropertyInfo('ProductId'));
    this.intializeSearch();
    this.initializeTable();
    this.refreshGrid();
    this.configureButtonType = {
      label: this.captions.btn_apply,
      type: 'secondary',
      customclass: 'ag_w--100',
      disabledproperty: true
    };
    this.formobjCol = this.business.getjsonobj();
    this.business.getConfigureHDRDetails().then(
      a => {
        this._configureHeaderOptions_Grid = this.business.getReorderDetails(USERPREFERENCE_SCREENTYPE.ScheduleConfigure);
        this._configureHeaderOptions_Grid1 = cloneDeep(this._configureHeaderOptions_Grid);
        let idx = this._configureHeaderOptions_Grid1.findIndex(header => header.key === 'action')
        let idx1 = this._configureHeaderOptions_Grid1.findIndex(header => header.key === 'selected')
        this._configureHeaderOptions_Grid1.splice(idx, 1)
        this._configureHeaderOptions_Grid1.splice(idx1, 1)

        this._configureHeaderOptionsPr = cloneDeep(this._configureHeaderOptions);
        this._configureHeaderOptions_Grid1Pr = cloneDeep(this._configureHeaderOptions_Grid1);
      }
    );
  }

  async intializeSearch() {
    this.disableCreateButton = await this.business.isServiceAccountExist();
    this.searchHeaderOption = {
      createBtnLabel: this.captions.CreateEventSchedular,
      searchPlaceHolder: this.captions.lbl_searchByEventNameDistributionName,
      showInactive: false,
      showPrint: false,
      toggleLabel: this.captions.lbl_showInactive,
      createBtnDisabled: !this.disableCreateButton
    };
  }

  onApplyClick_Grid() {
    this.configureButtonType.disabledproperty = true;
    this._configureHeaderOptions_Grid1.forEach((x, index) => {
      x.order = index;
    })
    this.business.saveTableOptionSettings(this._configureHeaderOptions_Grid1, USERPREFERENCE_SCREENTYPE.ScheduleConfigure)
    this.business.updateMasterReorderDetailsData(this._configureHeaderOptions_Grid1, USERPREFERENCE_SCREENTYPE.ScheduleConfigure)
    this.headerOptions = this.business.getSortedTableHeaderOptions();
    this._configureHeaderOptions_Grid1Pr = cloneDeep(this._configureHeaderOptions_Grid1);
    this.configurePopover_Grid.hide();
  }

  onDrop(event: CdkDragDrop<string[]>) {
    if (event.previousContainer === event.container) {
      moveItemInArray(event.container.data,
        event.previousIndex, event.currentIndex);

    } else {
      transferArrayItem(event.previousContainer.data,
        event.container.data,
        event.previousIndex,
        event.currentIndex);
    }
    this.configureButtonType.disabledproperty = false;
  }

  OnResetClick_Grid() {
    this.configureButtonType.disabledproperty = false;
    this._configureHeaderOptions_Grid = this.business.getTableConfigureInitData();
    this._configureHeaderOptions_Grid1 = cloneDeep(this._configureHeaderOptions_Grid);
  }

  configureOptionClick(headerOptions, headerOption, index) {
    headerOption.checked = !headerOption.checked;

    this.configureButtonType.disabledproperty = !headerOptions.some(x => {
      if (x.key === ActionTypeEnum.menu || x.key === ActionTypeEnum.checkbox || x.displayName === '') { // these values have checked true from configureMapper()
        return false;
      } else {
        return x.checked;
      }
    });
  }

  onCdkDragStarted(e) {
    if (document.querySelectorAll('.cdk-drag-preview')[0]) {
      document.querySelectorAll('.cdk-drag-preview')[0].classList.add('previewIndex');
    }
  }

  onCdkDragEnded(e) {
    if (document.querySelectorAll('.cdk-drag-preview')[0]) {
      document.querySelectorAll('.cdk-drag-preview')[0].classList.remove('previewIndex');
    }
  }

  initializeTable() {
    this.options = this.business.getTableOptions(this.isViewOnly);
    this.headerOptions = this.business.getTableHeaderOptions();

  }

  searchChange(event) {
    this.searchText = event;
  }

  refreshGrid() {
    this.originalData = from(this.business.getTableContent());
    this.tableContent = this.originalData.pipe(map(x => x));
  }

  showInactiveToggler(event) {
    this.showInactiveTog = event.checked;
    this.refreshGrid();
  }

  createEvent(event) {
    this.CreationToggler = true;
    this.createModalData = {
      title: this.captions.pg_title_createEventSchedule
    };
    this.crudActionInput = {
      mode: ActionMode.create,
      actionButton: this.captions.btn_create,
      form: {
        notificationDetails: {
          scheduleType: PatternType.Daily.toString(),
          startTime: '12:00 AM'
        }

      }
    };
  }
  editEvent(value) {
    this.CreationToggler = true;
    this.createModalData = {
      title: this.captions.pg_title_editEventSchedule
    };
    this.crudActionInput = {
      mode: ActionMode.update,
      actionButton: this.captions.btn_update,
      form: {
        eventDetails: value.eventDetailsData,
        notificationDetails: value.notificationData
      }
    };
  }

  deleteSchedule(event: any) {
    const warningMsg = this.localization.replacePlaceholders(this.captions.warn_deleteWithName, ['name'], [event.menuData.eventName]);
    this.utilities.showAlert(warningMsg, AlertType.Warning, ButtonType.YesNo, async (res) => {
      if (res === AlertAction.YES) {
        this.utilities.ToggleLoader(true);
        this.business.deleteSchedule(event.menuData.id).then(result => {
          if (result) {
            this.refreshGrid();
          }
          this.utilities.ToggleLoader(false);
        });
      }
    });
  }

  refreshDistributionList(event: any) {
    const warningMsg = this.localization.replacePlaceholders("Refresh distribution list", ['name'], [event.menuData.eventName]);
    this.utilities.showAlert(warningMsg, AlertType.Info, ButtonType.YesNo, async (res) => {
      if (res === AlertAction.YES) {
        this.utilities.ToggleLoader(true);
        this.business.refreshDistributionList(event.menuData.id).then(result => {
        //   if (result) {
        //     this.refreshGrid();
        //   }
          this.utilities.ToggleLoader(false);
        });
      }
    });
  }

  pauseSchedule(event: any) {
    const warningMsg = this.localization.replacePlaceholders("Are you sure you want to pause schedule", ['name'], [event.menuData.eventName]);
    this.utilities.showAlert(warningMsg, AlertType.Info, ButtonType.YesNo, async (res) => {
      if (res === AlertAction.YES) {
        this.utilities.ToggleLoader(true);
        this.business.pauseSchedule(event.menuData.id).then(result => {
          if (result) {
            this.refreshGrid();
          }
        });
        this.utilities.ToggleLoader(false);
      }
    });
  }

  tableAction(event) {
    // switch (event.fromType) {
    //   case FromTypeEnum.edit:
    //     this.IsEditModeEnabled = true;
    //     this.editEventEmit.emit({event : event.Obj.id, report: this.report});
    //     break;
    //   case FromTypeEnum.delete:
    //     this.deleteSchedule(event);
    //     break;
    // }
      switch (event.fromType) {
          case FromTypeEnum.menuoption:
              const menuaction = event.Obj.id;
              switch (menuaction) {
                  case UI.ScheduleActionOptions.update:
                      this.IsEditModeEnabled = true;
                      this.editEventEmit.emit({ event: event.menuData.id, report: this.report });
                      break;
                  case UI.ScheduleActionOptions.delete:
                      this.deleteSchedule(event);
                      break;
                  case UI.ScheduleActionOptions.refreshDistributionList:
                      this.refreshDistributionList(event);
                      break;
                  case UI.ScheduleActionOptions.pause:
                      this.pauseSchedule(event);
                      break;
              }
      }
  }
  handleClick(eve) {
    switch (eve.mode) {
      case ActionMode.create:
        this.createSchedule(eve);
        break;
      case ActionMode.update:
        this.updateSchedule(eve);
        break;
      case ActionMode.cancel:
        this.back(eve);
        break;
    }
  }

  moveBack(arg) {
    this.back(arg);
  }

  createSchedule(eve) {
    this.business.createSchedule(eve, this.reportConfig,eve.executeQuery).then(result => {
      if (result) {
        this.utilities.showAlert(this.captions.successfullysaved, AlertType.WellDone, ButtonType.Ok, (res) => {
          if (res === AlertAction.CONTINUE) {
            this.backEmit();
            this.refreshGrid();
          }});        
      }
    });
  }

  updateSchedule(eve) {
    this.business.updateSchedule(eve, this.reportConfig,eve.executeQuery).then(result => {
      if (result) {
        this.utilities.showAlert(this.captions.successfullysaved, AlertType.WellDone, ButtonType.Ok, (res) => {
          if (res === AlertAction.CONTINUE) {
            this.backEmit();
            this.refreshGrid();
          }});   
      }
    });
  }

  back(data) {
    this.backEmit();
  }

  backEmit() {
    this.CreationToggler = false;
    this.IsEditModeEnabled = false;
  }

  cancel(e) {
    this.back(e);
  }

  extractReportAPIOptions(e: ReportAPIOptions) {
    if (e) {
      this.reportConfig = {
        reportGroup: this.reportGroup,
        reportAPIOptions: this.createAPIOptions(e, 'HTML')
      };
    }
  }

  private createAPIOptions(options: any, format: ReportDownloadFormat): ReportAPIModel {

    const apiRequestModel: ReportAPIModel = {
      code: options.code,
      format,
      downloadFileName: 'Download',
      parameters: options.params ? this.arrayToObject(options.params) :  options.parameters,
      uRIParams: options.URIParams ? this.arrayToObject(options.URIParams) : options.uRIParams,
      filterBody: options.filters ? (options.filters && typeof options.filters === 'string' ? JSON.parse(options.filters) : options.filters) :
                                    (options.filterBody && typeof options.filterBody === 'string' ? JSON.parse(options.filterBody) : options.filterBody),
      dateFormat: this.localization.dateFormat,
      isJasperReport: options?.isJasperReport,
      name: options?.name ?? options?.code,
      sftpSetupId : 0
    };
    return apiRequestModel;
  }

  private arrayToObject(objectArr: any[]): { [key: string]: string } {
    const result = {};
    objectArr.forEach(o => {
      result[Object.keys(o)[0]] = Object.values(o)[0];
    });
    return result;
  }

  onReportOptionChange(e: UntypedFormGroup){
    if(e){
      this.reportGroup = e.value.report.id;
    }
  }

  configure() {
    this.configureButtonType.disabledproperty = true;
    this.showConfigure = !this.showConfigure;
    this._configureHeaderOptions_Grid1 = cloneDeep(this._configureHeaderOptions_Grid1Pr);
    if (this._configureHeaderOptions_Grid1 != null && this.headerOptions != null) {
      const lstSorted_Grid = this._configureHeaderOptions_Grid1.sort((a, b) => a.order - b.order);
      lstSorted_Grid.forEach(a => { a.checked = false })
      this.headerOptions.forEach(a => {
        if (lstSorted_Grid.filter(x => x.key == a.key)[0] != null) {
          lstSorted_Grid.filter(x => x.key == a.key)[0].checked = true;
        }
      })
    }
    this._configureHeaderOptions = cloneDeep(this._configureHeaderOptionsPr);
    if (this._configureHeaderOptions != null && this.renderingScreenObj) {
      const lstSorted_Search = this._configureHeaderOptions.sort((a, b) => a.order - b.order);
      lstSorted_Search.forEach(a => { a.checked = false })
      this.renderingScreenObj.forEach(a => { lstSorted_Search.filter(x => x.key == a.ctrlnmeKey)[0].checked = true; })
    }

  }
  edittedValues(e) {
    this.editSlider.emit(e);
  }
  reportSelector(e) {
    this.emitReportSelector.emit(e);
  }
  retailReport(e) {
    this.retailReportEmitter.emit(e);
  }
  slideSelector(e) {
    this.slideEmit.emit(e);
  }

  openExtractMonitor() {
    this.dialog.open(EventSchedulerHistoryComponent, {
      height: '100%',
      width: '100%',
      maxWidth: '100%',
      disableClose: false
    });
  }
}
