export interface stagingStatus {
    id: number;
    name: string;
    isSelected: boolean;
}

export enum stagingStatusDesc {
    posted = 'POSTED',
    notPosted = 'NOT POSTED',
    duplicates = 'DUPLICATES'
}

export interface InventoryStagingFilter {
    InventoryDate: string;
    InputString: string;
    FilterType: StagingFilterType;
    PageSize: number;
    PageNumber: number;
}

export interface InventoryScannerUIRequestModel {
    stagingDetails: StagingDetails[];
    isCumulativeCount: boolean;
}

export interface Users {
    tenantId: number;
    userId: number;
    userName: string;
    firstName: string;
    lastName: string;
    password: null;
    isActive: boolean;
  }

export interface StagingDetails {
    inventoryScannerStagingId: number;
    oldQuantity: number;
    newQuantity: number;
    operation: InventoryScannerStagingOperation
}

export interface TransferInventoryUIRequest {
    inventoryScannerStagingId: number;
    oldQuantity: number;
    newQuantity: number;
    transferReason: string;
    oldOutletId: number;
    outletDetails: InventoryTransferOutletDetails[]
}

export interface InventoryTransferOutletDetails {
    quantity: number;
    outletId: number;
}

export enum RetailScreen{
    retailSetup = 'retailSetup',
    inventoryStaging = 'inventoryStaging'
}

export enum InventoryScannerStagingOperation{
    Post = 1,
    PostAll = 2,
    TransferParent = 3,
    TransferChild = 4,
    QuantityChange = 5,
    Delete = 6,
    None = 0
}

export enum InventoryStagingPostType {
    AskEveryTime = 1,
    Override, //Override existing Quantity with new Quantity
    Cumulative // Adds up new Quantity to existing Quantity
}

export enum StagingFilterType {
    NotPosted = 1,
    Posted = 2,
    Duplicates = 4,
    All = 7
}

export interface InventoryScannerStaging {
    id: number;
    itemId: number;
    itemNumber: string;
    itemName: string;
    scannedDate: Date;
    barcode: string;
    quantityOnHand: number;
    scannedQuantity: number;
    outletId: number;
    productId: number;
    uploadedStaff: string;
    uploadedStaffId: number;
    uploadedDate: Date;
    requestLogId: number;
    status: StagingState;
    batchProcessId: number;
    deviceId: string;
    approvedDate: Date | null;
    approvedUser: number;
    inventoryDate: Date;
    isDeleted: boolean;
    transferReason: string;
    outletName: string;
    approvedUserName: string;
}
export enum StagingState {
    Unposted = 1,
    Posted = 2
}

export interface InventoryScannerStagingUI {
    checked: boolean,
    isCheckboxDisabled: boolean,
    isDeleteDisabled: boolean,
    id: number;
    itemNumber: string;
    itemName: string;
    barcode: string;
    quantity: number;
    outletId: number;
    outletName: string;
    uploadedStaff: string;
    stagingStatus:string;
    deviceId: string;
    approvedDate: string;
    approvedUser: string;
    oldQuantity: number;
}
export interface StagedRecordsAPI{
totalRecords: number;
pageSize: number;
pageNumber: number;
stagedRecords: InventoryScannerStaging[];
}

