import { ChangeDetectorRef, Component, EventEmitter, OnInit, Output } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { SpaLocalization } from 'src/app/core/localization/spa-localization';
import { ReportBusinessService } from 'src/app/report/business/report-business.service';
import { ReportDataService } from 'src/app/report/data/report-data.services';
import { DropDownData } from 'src/app/report/business/report.modals';
import { ValidationMessage } from 'src/app/report/basereport/base-report.component';

@Component({
  selector: 'app-staff-log-report',
  templateUrl: './staff-log-report.component.html',
  styleUrls: ['./staff-log-report.component.scss']
})
export class StaffLogReportComponent implements OnInit {
  staffLogFrmGrp: UntypedFormGroup;
  captions: any;
  isClass: boolean = false;
  @Output() formReady = new EventEmitter();
  @Output() IsValidTextInput = new EventEmitter<ValidationMessage>();
  @Output() validateUser = new EventEmitter<boolean>();
  reportTypes: { id: number; name: any; }[];
  therapists: DropDownData[] = [];
  therapistData: DropDownData[] = [];
  radioFilter = [];
  IncludeInactive: any;
  StaffDropDown: any;
  validTherapistSelection: boolean;
  validTherapistErrMsg: string;
  defaultData: DropDownData = {
      id: 0,
      code: 'ALL',
      description: 'All',
      showInDropDown: true
  };
  constructor(public fb: UntypedFormBuilder,private cdr: ChangeDetectorRef,public business: ReportBusinessService, public data: ReportDataService, public localization: SpaLocalization) 
  { 
    this.captions = this.localization.captions.reports;
  }

  ngOnInit(): void {
    this.formReady.emit(this.staffLogFrmGrp);
    this.staffLogFrmGrp = this.fb.group({
          includeInactiveTherapists: false,
          reportTypes:0,
          therapists: new UntypedFormControl([this.defaultData]),
          radioFilter: 0
    });
    this.onLoad();
    this.isValidText(true);
    this.formReady.emit(this.staffLogFrmGrp);
  }

  reportTypeChange(id){
    this.toggleIncludeInactive(false);
    this.staffLogFrmGrp.controls['includeInactiveTherapists'].setValue(false);
    this.staffLogFrmGrp.controls.includeInactiveTherapists.updateValueAndValidity();
    if(id == 1)
    {
      this.isClass = true;
    }
    else
    {
      this.isClass = false;
    }
    this.onLoad();
    this.isValidText(true);
    this.formReady.emit(this.staffLogFrmGrp);
  }

  isValidText(inputValue: boolean) {
    this.IsValidTextInput.emit({ dropDownName: "TextField", validity: inputValue });
  }

  toggleIncludeInactive(e){
    this.therapists = [];
    if(e){
     this.therapists = this.therapistData.map(s => {
      return {
          id: s.id,
          description: s.description,
          showInDropDown: true
      }
    });
    } else {
     this.therapists = this.therapistData.filter(t=>t.isActive).map(s => {
      return {
          id: s.id,
          description: s.description,
          showInDropDown: true
      }
    });
    }
    this.cdr.detectChanges(); 
    this.formReady.emit(this.staffLogFrmGrp); 
  }

  private async onLoad() {
    this.reportTypes =[
      { id: 0, name: this.captions.Therapist },
      { id: 1, name: this.captions.Instructor}
     ];
     this.radioFilter =[
      { id: 0, name: this.captions.LoggedDate },
      { id: 1, name: this.captions.ExpiryDate}
     ];
    this.getCaptions();
    if(!this.isClass){
    this.therapistData = await this.data.getAllTherapist("StaffLog");
    this.therapists = this.therapistData.filter(t=>t.isActive);
    this.business.FilterDataSource["therapists"] = this.therapists;
  }
  else{
    this.therapistData = await this.data.getAllInstructors("StaffLog");
    this.therapists = this.therapistData.filter(t=>t.isActive);
    this.business.FilterDataSource["therapists"] = this.therapists;
  }
  }

  getCaptions()
  {
    this.IncludeInactive = this.isClass?this.captions.IncludeInactiveInstructors:this.captions.IncludeInactiveTherapist;
    this.StaffDropDown = this.isClass? this.captions.Instructors : this.captions.Therapists;
  }

  IsTherapistValid(validity: boolean) {
    this.validTherapistSelection = validity;
    this.validateUser.emit(validity);
    if (!validity) {
      const caption:any = this.localization.captions.reports.PleaseSelectAtleastOneOption;
      this.validTherapistErrMsg = this.localization.replacePlaceholders(caption,["option"],[this.captions.Therapists]);
    }
  }

  getFormControlValue(event) 
  {
     if (event[0] === 'therapists') {
        this.staffLogFrmGrp.setControl('therapists', event[1]);
        } 
  }
}
