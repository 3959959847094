
import { Injectable } from '@angular/core';
import { RetailRoutes } from '../../retail-route';
import { RetailCommunication } from '../../retailcommunication.service';
import { API } from './retail-tier-level.model';

@Injectable()
export class TierLevelDataService {

    constructor(private retailCommunication: RetailCommunication) {
    }

    public getAllTierLevel(): Promise<API.TierLevel[]> {
        return this.retailCommunication.getPromise<API.TierLevel[]>(
            { route: RetailRoutes.GetAllTierLevel }, false);
    }
    public createTierLevel(body: API.TierLevel): Promise<API.TierLevel[]> {
        return this.retailCommunication.postPromise<API.TierLevel[]>(
            { route: RetailRoutes.CreateTierLevel, body: body }, true);
    }
    public updateTierLevel(body: API.TierLevel): Promise<API.TierLevel[]> {
        return this.retailCommunication.putPromise<API.TierLevel[]>(
            { route: RetailRoutes.UpdateTierLevel, body: body }, true);
    }
    public deleteTierLevel(id: number): Promise<API.TierLevel[]> {
        return this.retailCommunication.deletePromise<API.TierLevel[]>(
            { route: RetailRoutes.DeleteTierLevel, uriParams: { "id": id } }, true);
    }
}