import { Component, OnInit, ViewChild, Inject, OnDestroy, Input, Output, EventEmitter, AfterViewInit, ViewEncapsulation } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatTabGroup, MatTabHeader, MatTab } from '@angular/material/tabs';
import { SpaLocalization } from '../../../core/localization/spa-localization';
import { AppointmentpopupService } from '../../service/appointmentpopup.service';
import { HttpServiceCall, HttpMethod } from '../../service/http-call.service';
import { SpaUtilities } from '../../utilities/spa-utilities';
import * as GlobalConst from '../../globalsContant';
import { BaseResponse } from '../../business/shared.modals';
import { ClientLabel } from '../../business/new-booking.model';
import { BreakPointAccess } from '../../service/breakpoint.service';
import { I } from '@angular/cdk/keycodes';
import { RetailFeatureFlagInformationService } from 'src/app/retail/shared/service/retail.feature.flag.information.service';
import { RetailRoutes } from 'src/app/retail/retail-route';

@Component({
  selector: 'app-create-client',
  templateUrl: './create-client.component.html',
  styleUrls: ['./create-client.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class CreateClientComponent implements OnInit, OnDestroy,AfterViewInit {
  @ViewChild('clientTabGroup', { static: true }) tabGroup: MatTabGroup;
  captions: any = this.localization.captions.bookAppointment;
  allCaptions: any = this.localization.captions;
  activateClientSelected: any;
  isServiceTherapistLoaded: boolean = false;
  isExpand: boolean = false;
  changedClientData: any;
  isLockerEnabled: boolean;
  @Input() inputData;
  @Input() guestId;
  @Input() from: boolean = false;
  @Input() fromClient: boolean = false;
  @Input() IsGDPREnabled : boolean = false;
  @Output() onItineraryEdit = new EventEmitter();
  @Output() updateClientDetails = new EventEmitter();
  tabIndex = 0;

  tabIndexes = {
    PersonalInformation: 0,
    AdditionalInformation: 1,
    ClientBlockInfo: 2,
    MedicalHistory: 3,
    SoapNotes: 4,
    History: 5,
    Preferences: 6,
    Itinerary: 7,
    ContactLog: 8,
    GuestContact: 9
  };

  constructor(public appointmentservice: AppointmentpopupService, @Inject(MAT_DIALOG_DATA) private data: any,
    private http: HttpServiceCall, private utils: SpaUtilities, public localization: SpaLocalization, private breakpoint: BreakPointAccess, private featureFlagInfo: RetailFeatureFlagInformationService) { }

  ngOnInit() {
    this.tabGroup._handleClick = this.handleTabChange.bind(this);
    this.GetAllServices();
    this.GetAllTherapists();
    this.isLockerEnabled = this.featureFlagInfo.IsLockersEnabled;
    if (this.appointmentservice.clientId && this.appointmentservice.clientId != 0 && !this.appointmentservice.copySelectedClient) {
      this.GetClientBlockById(this.appointmentservice.clientId);
    }
    this.appointmentservice.ClientSelectedTab = 0;
  }
  ngAfterViewInit(){
    this.appointmentservice.ClientSelectedTab = 0;
  }
  ngOnDestroy() {
    this.appointmentservice.ClientSelectedTab = 0;
  }

  async GetAllServices(){
    this.appointmentservice.allServices = await this.http.CallApiAsync<any>({
      host: GlobalConst.Host.spaManagement,
      callDesc: "GetAllSpaService",
      method: HttpMethod.Get,
      showError: true
    });
  }

  async GetAllTherapists(){
    this.appointmentservice.allTherapists = await this.http.CallApiAsync<any>({
      host: GlobalConst.Host.spaManagement,
      callDesc: "GetAllTherapist",
      method: HttpMethod.Get,
      showError: true
    });
    this.isServiceTherapistLoaded = true;
  }

  lockerInfo = {
    lockerClientHistoryRoute: RetailRoutes.GetLockerClientHistory,
    featureFlagInfoForLocker : this.featureFlagInfo.IsLockersEnabled,
  };

  handleTabChange(tab: MatTab, tabHeader: MatTabHeader, idx: number) {
    this.tabIndex = idx;
    if(this.tabGroup.selectedIndex == this.tabIndexes.Itinerary){
      this.appointmentservice['isFromItinerary'] = true;
    } else {this.appointmentservice['isFromItinerary'] = false;}
    if(idx == this.tabIndexes.Itinerary || idx == this.tabIndexes.AdditionalInformation){
      return true && MatTabGroup.prototype._handleClick.apply(this.tabGroup, arguments);
    }

    if (this.appointmentservice.clientWidowActionType == 'EDIT' && idx == this.tabIndexes.Preferences && !this.appointmentservice.hasAccessForClientPreference) {
      this.breakpoint.showBreakPointPopup(this.localization.captions.breakpoint[GlobalConst.SPAManagementBreakPoint.EditClientPreferences]);
      return false && MatTabGroup.prototype._handleClick.apply(this.tabGroup, arguments);
    } 

    if (idx == this.tabIndexes.MedicalHistory && !this.appointmentservice.hasAccessForMedicalHistory) {
      this.breakpoint.showBreakPointPopup(this.localization.captions.breakpoint[GlobalConst.SPAManagementBreakPoint.AccessClientMedicalHistory]);
      return false && MatTabGroup.prototype._handleClick.apply(this.tabGroup, arguments);
    }

    if (idx == this.tabIndexes.SoapNotes && !this.appointmentservice.hasAccessForSoapNotes) {
      this.breakpoint.showBreakPointPopup(this.localization.captions.breakpoint[GlobalConst.SPAManagementBreakPoint.AddSOAPNotes]);
      return false && MatTabGroup.prototype._handleClick.apply(this.tabGroup, arguments);
    }

    if (this.appointmentservice.clientWidowActionType == 'EDIT' && idx == this.tabIndexes.SoapNotes && !this.appointmentservice.hasAccessForEditSoapNotes) {
      this.breakpoint.showBreakPointPopup(this.localization.captions.breakpoint[GlobalConst.SPAManagementBreakPoint.EditSOAPNotes]);
      return false && MatTabGroup.prototype._handleClick.apply(this.tabGroup, arguments);
    }

    if (this.appointmentservice.clientWidowActionType == 'EDIT' && this.appointmentservice.ClientSelectedTab == this.tabIndexes.AdditionalInformation && this.appointmentservice.additionalDetailsFormGroup.invalid) {
      return false && MatTabGroup.prototype._handleClick.apply(this.tabGroup, arguments);
    }


    if (idx == this.tabIndexes.ClientBlockInfo && (!this.appointmentservice.hasAccessForClientBlockInfo)) {
      const breakPointInfo = this.appointmentservice.clientWidowActionType == 'EDIT' ? GlobalConst.SPAManagementBreakPoint.EditClientBlockInfo : GlobalConst.SPAManagementBreakPoint.AddClientBlockInfo;
      this.breakpoint.showBreakPointPopup(this.localization.captions.breakpoint[breakPointInfo]);
      return false && MatTabGroup.prototype._handleClick.apply(this.tabGroup, arguments);
    }

    this.appointmentservice.ClientSelectedTab = idx;
    return true && MatTabGroup.prototype._handleClick.apply(this.tabGroup, arguments);
  }

  ClientAddedEvent(clientId) {
    if (clientId && clientId > 0) {
      this.getClientInformation(clientId);
      this.getRecentAppointments(clientId);
    }
  }

  GetClientBlockById(clientId: number) {
    this.http.CallApiWithCallback<any[]>({
      host: GlobalConst.Host.spaManagement,
      success: this.successCallback.bind(this),
      error: this.utils.errorCallback.bind(this),
      callDesc: "GetClientBlockById",
      uriParams: { id: clientId },
      method: HttpMethod.Get,
      showError: false,
      extraParams: []
    });
  }



  getRecentAppointments(clientId: number) {
    this.http.CallApiWithCallback<any[]>({
      host: GlobalConst.Host.schedule,
      success: this.successCallback.bind(this),
      error: this.utils.errorCallback.bind(this),
      callDesc: "getClientRecentAppointmentInfo",
      uriParams: { clientID: clientId },
      method: HttpMethod.Get,
      showError: false,
      extraParams: []
    });
  }

  getClientInformation(clientId: number) {
    this.http.CallApiWithCallback<any[]>({
      host: GlobalConst.Host.spaManagement,
      success: this.successCallback.bind(this),
      error: this.utils.errorCallback.bind(this),
      callDesc: "getClientInfo",
      uriParams: { id: clientId },
      method: HttpMethod.Get,
      showError: false,
      extraParams: []
    });
  }

  successCallback<T>(result: BaseResponse<T>, callDesc: string, extraParams: any[]): void {
    if (callDesc == "getClientRecentAppointmentInfo") {
      let resp: any = result.result;
      let RecentAppsUIArr = [];
      for (let item of resp) {
        const element = item;
        RecentAppsUIArr.push(this.createRecentAppsObj(element.serviceName, element.therapistNames, element.date, element.status));
      }
      this.appointmentservice.RecentAppsUIArr = RecentAppsUIArr;
    }
    if (callDesc == "getClientInfo") {
      let resp: any = result.result;
      if (!this.appointmentservice.labelRecords) {
        this.appointmentservice.labelRecords = [];
      }
      if (!this.appointmentservice.labelRecords.some(r => r.Id == resp.clientDetail.id)) {
        let clientLable: ClientLabel = {
          Id: resp.clientDetail.id,
          FirstName: resp.clientDetail.firstName,
          LastName: resp.clientDetail.lastName
        }
        this.appointmentservice.labelRecords.push(clientLable);
      }

      if (!this.appointmentservice.recordsArray) {
        this.appointmentservice.recordsArray = [];
      }
      resp.id = resp.clientDetail.id;

      if (this.appointmentservice.recordsArray.map(x => x.clientDetail).map(x => x.id).indexOf(resp.id) == -1) {
        this.appointmentservice.recordsArray.push(resp);
      }
      this.guestId = resp.clientDetail.guestId;
      this.activateClientSelected = resp;
     
    }

    if (callDesc == "GetClientBlockById") {
      const response = result.result ? <any>result.result : null;
      this.appointmentservice.ClientBlockInformation = response;
      if (!this.appointmentservice.clientBlockInfos)
        this.appointmentservice.clientBlockInfos = [];
      if (response) {
        let data = this.appointmentservice.clientBlockInfos.find(y => y.id === response.id);
        if (!(data)) {
          this.appointmentservice.clientBlockInfos.push(response);
        }
      }
    }

  }
 
  createRecentAppsObj(ServiceName: string, TherapistNames: string[], onDate: Date, Status: string): any {
    var dt = this.utils.getDate(onDate);
    var dtStr = this.localization.LocalizeShortDateTime(dt);
    let desc: string = ServiceName + " " + this.captions.by + " " + TherapistNames.toString() + " " + this.captions.on + " " + dtStr;
    let UiStatus: string;

    if (Status == "RESV") {
      UiStatus = this.captions.Scheduled
    } else if (Status == "CKIN") {
      UiStatus = this.captions.CheckedIn
    }
    else if (Status == "CKOUT") {
      UiStatus = this.captions.CheckedOut
    }
    else if (Status == "NOSHOW") {
      UiStatus = this.captions.NoShow
    }
    else if (Status == "CANC") {
      UiStatus = this.captions.Cancelled
    }
    return {
      appointmentDetails: desc,
      action: UiStatus
    };
  }

  onItineraryEditFlow(e){
    this.onItineraryEdit.emit(e);
  }

  expand(){
    this.isExpand = !this.isExpand
  }

  updateGuestDetails(data){
    this.appointmentservice.clientEditData = data;
    this.changedClientData = data;
  }

}
