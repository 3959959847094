import { UntypedFormGroup } from "@angular/forms";
import { BaseReport } from "../common/base-report";
import { ReportDataService } from "../../data/report-data.services";
import { ReportBusinessService } from "../report-business.service";
import { RetailFunctionalityBusiness } from "src/app/retail/shared/business/retail-functionality.business";
import { RetailLocalization } from "src/app/retail/common/localization/retail-localization";
import { RetailPropertyInformation } from "src/app/retail/common/services/retail-property-information.service";
import { ReportUIConfig, ReportAPIOptions, ReportParams, AllReports, VATClosedChecksFilter } from "../report.modals";

export class VATClosedChecks extends BaseReport {
    
    functionalities: { [key: string]: boolean} = {};
    constructor(public reportFormGrp: UntypedFormGroup, public data: ReportDataService, public business: ReportBusinessService, private func: RetailFunctionalityBusiness
        , private propertyInfo: RetailPropertyInformation, public retailLocalization : RetailLocalization) {
        super(reportFormGrp);
    }

    getReportUIConfig(): ReportUIConfig {
        return {
            startDatePicker: true,
            endDatePicker: true,
            inActiveToggle: false,
            pageBreakToggle: false,
            layout: 'LANDSCAPE',
            allowFutureDate: true,
            restrictPastDate: true
        };
    }
    generateReportAPIOptions(): ReportAPIOptions {
        return {
            code: AllReports.VATClosedChecks,
            params: this.formReportParams(),
            URIParams: this.formURIParams(),
            filters: this.formFilters(),
            pageBreak: true
        }; 
    }
    protected formReportParams(): ReportParams[] {
        this.functionalities = this.func.getFunctionality();
        let toApi = this.localization.convertDateObjToAPIdate;

        return [
            { "pStartDate": toApi(this.startDate) },
            { "pEndDate": toApi(this.endDate) },
            { "pPropertyName": this.propertyName },
            { "pDate" : this.printedDate }

            ];
    }
    protected formURIParams(): ReportParams[] {
        let toApi = this.localization.convertDateObjToAPIdate;
        return [{ "StartDate": toApi(this.startDate) }, { "EndDate": toApi(this.endDate) }];
    }
    protected formFilters() {
        let toApi = this.localization.convertDateObjToAPIdate;
        let filter: Partial<VATClosedChecksFilter> = {
            startDate: toApi(this.startDate),
            endDate: toApi(this.endDate)

        }; 
        return JSON.stringify(filter);
    }
}