import { Component, OnInit, Input, ElementRef, ViewEncapsulation, Output, EventEmitter, ViewChild, Renderer2 } from '@angular/core';
import { UntypedFormBuilder, NgModel } from '@angular/forms';
import * as myGlobals from '../../shared/globalsContant'; //CONSTANT FILE ADD ANY CONSTANT VALUE
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import { MatDialog } from '@angular/material/dialog';
import { SPAConfig } from '../../common/config/SPA-config';
import { MatMenuTrigger } from '@angular/material/menu';
import * as _ from 'lodash';
import { RetailLocalization } from '../../common/localization/retail-localization';
import { RetailPropertyInformation } from '../../common/services/retail-property-information.service';
import { RetailUtilities } from '../../shared/utilities/retail-utilities';
import { dateRangeUIModel, ReportAPIOptions } from '../shop.modals';
import { ReprintAdvancedSearch } from './reprint-advanced-search/reprint-advanced-search.component';
import { RetailTransactions } from 'src/app/common/shared/shared/globalsContant';
import { EmailReceiptComponent } from '../email-receipt/email-receipt.component';
import { takeUntil } from 'rxjs/operators';
import { ReplaySubject } from 'rxjs';
import { ReceiptBusinessService } from '../receipt/business/receipt-business.service';
import { Localization } from 'src/app/common/localization/localization';
import { RetailFunctionalityBusiness } from 'src/app/retail/shared/business/retail-functionality.business';
import { MemberBusinessService } from '../../shared/business/Member-business.service';
import { RetailItemType } from '../../retail.modals';

declare var require: any 
@Component({
  selector: 'app-shop-table',
  templateUrl: './retail-table.component.html',
  styleUrls: ['./retail-table.component.scss'],
  encapsulation: ViewEncapsulation.None,

})
export class RetailTableComponent implements OnInit { 
  maxCol: number; 
  SelectRow: boolean = false; 
  SelectedData: any = [];
  sortingColoumn: any;
  setinterscroll: any;
  showOutlet: any;
  outletArray: Options[]; 
  @Input() options;
  @Input() showOptionsArray: DisplayOption;
  @Output() afterEditClose: EventEmitter<any> = new EventEmitter();
  @Output() dragDropEvt: EventEmitter<any> = new EventEmitter();
  @Output() deleteEvt: EventEmitter<any> = new EventEmitter();
  @Output() editEvt: EventEmitter<any> = new EventEmitter();
  @Output() RowSelectEmitter: EventEmitter<any> = new EventEmitter();
  @Output() CurrentRowSelectedEmitter: EventEmitter<any> = new EventEmitter();
  @Output() toggleEvtEmitter: EventEmitter<any> = new EventEmitter(); 
  @Output() LBLClickEvtEmitter: EventEmitter<any> = new EventEmitter();
  @Output() radioClickEvtEmitter: EventEmitter<any> = new EventEmitter();
  @Output() dateEvtEmitter: EventEmitter<any> = new EventEmitter();
  @ViewChild(MatMenuTrigger) trigger: MatMenuTrigger;
  @Output() OutletChanged: EventEmitter<any> = new EventEmitter();
  @ViewChild('fixedTable') ele: ElementRef;

  @ViewChild('tableInput') tableInput: ElementRef;
  //#region Advanced Search

  tableDivClass = '';
  public showAdvancedSearch = false;
  public serviceID: RetailTransactions;
  @Output() advancedSearch: EventEmitter<ReprintAdvancedSearch> = new EventEmitter();
  @Output() advancedSearchClose: EventEmitter<any> = new EventEmitter();  
  @Input() isAdvancedSearchLoading = false;
  @Input('fromScreen')
  set setAdvanceSearch(value) {
    this.serviceID = value;
    if (value && (value === RetailTransactions.reprintticket || value === RetailTransactions.returnwithticket || value === RetailTransactions.opentransactions || RetailTransactions.correctvoid)){
      this.showAdvancedSearch = true;
    }
  }
  @Input() isPrintGiftReceiptEnabled = false;
  //#endregion


  startDate: any;
  RadiobuttonOption: boolean = false;
  IsCheckAll: boolean = false;
  InActiveTherapistChkBoxEvt: any;
  orderTypearr: any = [];
  orderType: any = 'asc'; 
  hdrArray: any = [];
  bodyArray: any = [];
  selectedDefaultHeader: any;
  searchText: any;
  hdrkeyArray: any = [];
  PlaceHoldertext: string;
  EnableActions: boolean;
  currentPage: any = 1;
  EnablePagination: boolean = true;
  fromDate: any;
  toDate: any;
  transactionDate: any;
  openTransactionDate: any; 
  SetColumnGridValue: boolean = false;
  SelectedSettingId: number = 1;
  blnDraggable: boolean = true;
  remailLength: any = [];
  Sortable: any; 
  NewerData: any; 
  givenOrderType: any;
  EditMoreOption: any;
  defaulSortColumn: any; 
  editable: boolean = true;
  deletable: boolean = true;
  deleteIcon: boolean = true;
  overriddenSortColumn: string;
  sortColumnDataType: string;
  selectedOutlet: any; 
  captions: any = {};
  commonCaption:any={};
  ShowNoOfRecordInGrid = myGlobals.RetailFunctionalities.ShowNoOfRecordInGrid;
  functionalities: { [key: string]: boolean } = {};
  totalMsg: string;

  openTransactions: any = [this.localization.captions.shop.Settle, this.localization.captions.shop.Reopen, this.localization.captions.shop.CancelTransaction];
  correctVoid: any = [this.localization.captions.shop.Void, this.localization.captions.shop.Correct];
  returnWithTicket: any = [this.localization.captions.shop.Return];
  ArrayList = [];
  disableDelete: any; 
  toggleDisplayText: any; 
  GridType = myGlobals.GridType;
  RetailTransactions = myGlobals.RetailTransactions;
  retailProduct:number = myGlobals.Product.RETAIL;
  sncProduct:number=myGlobals.Product.SNC;
  IsViewOnly: boolean;
  isDataLoading: boolean = false;
  currentRowObj: any;
  currentproductId: number;
  viewCheckedFlag = false;
  originalHdrKeyArray: any =[];
  placeholderFormat: string;
  useRetailInterface: boolean = false;
  @ViewChild('fromDateModel') fromDateModelControl: NgModel;
  @ViewChild('toDateModel') toDateModelControl: NgModel;
  floatLabel: string;
  destroyed$: ReplaySubject<boolean> = new ReplaySubject(1);

  get isEmailOnDemandProduct():boolean
  {
    return (this.currentproductId == myGlobals.Product.RETAIL || this.currentproductId == myGlobals.Product.SPA || this.currentproductId == myGlobals.Product.GOLF)
  }


  constructor(public dialog: MatDialog, private spaconfig: SPAConfig, private renderer: Renderer2, public el: ElementRef, public fb: UntypedFormBuilder, public localization: RetailLocalization, private utils: RetailUtilities, private ProperyInfo: RetailPropertyInformation,public _localization:Localization, private func: RetailFunctionalityBusiness, private receiptService: ReceiptBusinessService,  private _memberService: MemberBusinessService) {
    this.currentproductId = Number(this.utils.GetPropertyInfo("ProductId"));
    this.placeholderFormat = this.localization.inputDateFormat;
    this.commonCaption= this._localization.captions;
    this.useRetailInterface = this.ProperyInfo.UseRetailInterface;
    this.floatLabel = this.localization.setFloatLabel;
  }
 

  LoadDecimalValue(val: any): string {
    let StrVal: string = val ? val.toString() : "";
    if (!StrVal.includes(this.localization.decimalSeparator)) {
      StrVal = StrVal + this.localization.decimalSeparator + "00"
    }
    return StrVal;
  }



  onDragOver(event, ele, newarr) {
    event.preventDefault();
  }

  radioClick(rowData, index) {
    this.radioClickEvtEmitter.emit(rowData);
  }
  onItemDrop(event, ele, newarr) { 
    let draggeddata = event.dragData;
    let droppeddata = ele;
    let dragindex = newarr.indexOf(draggeddata);
    let dropindex = newarr.indexOf(droppeddata);
    let dragLstOrder: listOrder = draggeddata;
    let dropLstOrder: listOrder = droppeddata;
    this.dragDropEvt.emit([dragLstOrder.listOrder, dropLstOrder.listOrder, this.SelectedSettingId, this.InActiveTherapistChkBoxEvt]);
    newarr.splice(dragindex, 1);
    newarr.splice(dropindex, 0, draggeddata);
  }

 

  lblclick(rowData, index, clickable) {
    console.log("row data value", rowData);
    if (clickable == 'clickable') {
      this.LBLClickEvtEmitter.emit(rowData);
      return;
    } else {
      return false;
    }
  } 
  CheckInnerBool(paramsinner: any) {
    let ReturnBool;
    if (typeof paramsinner === "object") {
      ReturnBool = true;
    } else {
      ReturnBool = false;
    }
    return ReturnBool;
  }
  /***
   * Change Check Boolean of the Function
   * @input Params -Boolean
   * @output Return the Boolean Value
   * If Check the any Boolean value form the Array
  */

  IfBoolean(IfBoolean: any) {
    let ReturnBool;
    if (typeof IfBoolean === "boolean") {
      ReturnBool = true;
    } else {
      ReturnBool = false;
    }
    return ReturnBool;
  } 


  ngOnInit() {
    this.captions = this.localization.captions;
    for (let i = 1; i <= this.ArrayList.length; i++) {
      this.ArrayList.push(`item ${i}`);
    }
 

    if (typeof this.options == "undefined") { return; }
    this.defaulSortColumn = this.options[0].Sortable;
    this.showOutlet = this.showOptionsArray && this.showOptionsArray.showOutlet;
    this.openTransactionDate = this.localization.localizeDisplayDate(this.localization.ConvertDateToISODateTime(this.ProperyInfo.CurrentDate));
    this.transactionDate = this.ProperyInfo.CurrentDate; 
    this.toggleDisplayText = this.options[0].toggleDisplayText;
    const temp = (this.options[0].Sortable ? this.options[0].Sortable : 'id');
    this.Sortable = this.selectedDefaultHeader ? this.selectedDefaultHeader : temp;
    this.sortingColoumn = this.hdrkeyArray.indexOf(this.Sortable);
    this.selectedDefaultHeader = this.Sortable;
    let overriddenSortColumn = this.hdrArray.filter(o => o.jsonkey == this.Sortable).length > 0 ? this.hdrArray.filter(o => o.jsonkey == this.Sortable)[0].sortcolumn : null;
    let SortColumnDataType = this.hdrArray.filter(o => o.jsonkey == this.Sortable).length > 0 ? this.hdrArray.filter(o => o.jsonkey == this.Sortable)[0].sortcolumndatatype : null;
    this.orderTypearr.push(this.selectedDefaultHeader);
    this.sortingFunc(this.selectedDefaultHeader, this.sortingColoumn, 'onInit', overriddenSortColumn, SortColumnDataType);
 
  }
  ngOnChanges() {
    this.IsCheckAll = false;
    if (typeof this.options == "undefined") { this.isDataLoading = true; return; }
    this.isDataLoading = false;
    if (this.showOptionsArray && this.showOptionsArray.options && this.showOptionsArray.options.length > 0) {
      this.outletArray = this.showOptionsArray.options;
      this.selectedOutlet = this.outletArray && this.outletArray.length > 0 && this.showOptionsArray.selectedValue ? this.showOptionsArray.selectedValue : this.outletArray[0].id;
    }

    this.startDate = this.localization.localizeDisplayDate(this.ProperyInfo.CurrentDTTM);
    this.orderTypearr = [];
    this.defaulSortColumn = this.options[0].Sortable;
    const temp = (this.options[0].Sortable ? this.options[0].Sortable : 'id');
    this.Sortable = (this.selectedDefaultHeader && !this.options[0].isInitial) ? this.selectedDefaultHeader : temp;

    this.selectedDefaultHeader = this.Sortable;
    this.SelectRow = this.options[0].SelectRows;
    this.IsViewOnly = this.options[0].IsViewOnly;

    this.toggleDisplayText = this.options[0].toggleDisplayText; 
    this.RadiobuttonOption = this.options[0].RadiobuttonOption;
    this.blnDraggable = this.options[0].TableDraggable;
    this.editable = this.options[0].Editable != undefined ? this.options[0].Editable : true;
    this.deletable = this.options[0].deletable != undefined ? this.options[0].deletable : true;
    this.deleteIcon = this.options[0].DeleteIcon != undefined ? this.options[0].DeleteIcon : true;
    this.showOutlet = this.showOptionsArray && this.showOptionsArray.showOutlet;

    this.setReprintDateFilter();
    this.openTransactionDate = this.localization.localizeDisplayDate(this.localization.ConvertDateToISODateTime(this.ProperyInfo.CurrentDate));
    this.maxCol = 4;
    this.SelectedSettingId = this.options[0].SelectedSettingId;
    let minarrlength = this.options[0].TableHdrData.length <= this.maxCol ? this.maxCol - this.options[0].TableHdrData.length : 0;
    this.remailLength = [];
    for (let m = 0; m < minarrlength; m++) {
      this.remailLength.push(m);
    }
    this.hdrArray = this.options[0].TableHdrData;
    this.originalHdrKeyArray = this.options[0].TableHdrData;
    this.bodyArray = this.options[0].TablebodyData;
    this.SelectedData = [];
    //Set Checked items on load - based on the checked field in body data
    this.bodyArray.forEach(element => {
      if (element.checked != null && element.checked){
        this.SelectedData.push(element);
      }
      element.isTransactionDetailAvailable = element.transactionDetails && !element.isVoided
                                             && element.transactionDetails.some(x => !x.isReturn);
    });


    this.NewerData = this.options[0].NewData;

    this.NewerData = this.bodyArray.filter(o => {
      if (this.NewerData) {
        return ((o.code && o.code == this.NewerData.code) || (o.name && o.name == this.NewerData.name) || (o.addOnName && o.addOnName == this.NewerData.addOnName))
      }
    })

    if (this.NewerData[0]) {
      this.setinterscroll = setInterval(() => {
        const Itemtemp = (this.NewerData[0] ? this.NewerData[0].id : '');
        let curiddata = this.NewerData ? Itemtemp : '';
        this.autoscrolltocurrtime(curiddata);
      }, 500);
    }



    this.searchText = this.options[0].TableSearchText;
    for (let item of this.hdrArray) {
      if (item.searchable != undefined ? item.searchable : true)
        this.hdrkeyArray.push(item.jsonkey);
    }
    this.PlaceHoldertext = this.options[0].PlaceHoldertext;
    this.EnableActions = this.options[0].EnableActions;
    this.disableDelete = this.options[0].disableDelete;
    this.EnablePagination = this.options[0].pagination; 
    this.EditMoreOption = this.options[0].EditMoreOption; 


    this.sortingColoumn = this.hdrkeyArray.indexOf(this.Sortable);
    let overriddenSortColumn = this.hdrArray.filter(o => o.jsonkey == this.Sortable).length > 0 ? this.hdrArray.filter(o => o.jsonkey == this.Sortable)[0].sortcolumn : null;
    let SortColumnDataType = this.hdrArray.filter(o => o.jsonkey == this.Sortable).length > 0 ? this.hdrArray.filter(o => o.jsonkey == this.Sortable)[0].sortcolumndatatype : null;
    if (this.options[0].TablebodyData.length !== 0) {
      this.orderTypearr.push(this.selectedDefaultHeader);
      this.sortingFunc(this.selectedDefaultHeader, this.sortingColoumn, 'onInit', overriddenSortColumn, SortColumnDataType);
    }


    this.IsCheckAll = (_.difference(this.bodyArray, this.SelectedData).length === 0); 
this.viewCheckedFlag = false;
    if(this.functionalities[myGlobals.RetailFunctionalities.ShowNoOfRecordInGrid]){
      this.totalMsg = this._localization.replacePlaceholders(this.commonCaption.lbl_totalRecordsFoundMsg, ['count'], [this.bodyArray.length]);
    }
  }


  private setReprintDateFilter(): void {
    if (this.showOptionsArray && this.showOptionsArray.reprintDateFilterValues) {
      if(!this.fromDateModelControl){
        this.fromDate = this.showOptionsArray.reprintDateFilterValues.startDate;
      } 
      if(!this.toDateModelControl){
        this.toDate = this.showOptionsArray.reprintDateFilterValues.endDate;
      }     
    }
  }

  outletChange(eve) {
    console.log(eve.value);
    this.OutletChanged.emit(eve);
  }

  customtablealignment() {
    let dropdown: any = [];
    let groupArray: any = {

      "retailtransactions": {
        "ticket": "10",
        "date": "15",
        "clerkid": "10",
        "outlet": "10",
        "amount": "10",
        "dummy": "5",
        "clientname": "15",
        "membername": "15",
        "appointment": "0"
      },
      "returnwithticket": {
        "ticket": "10",
        "date": "15",
        "clerkid": "10",
        "outlet": "10",
        "amount": "10",
        "dummy": "5",
        "clientname": "15",
        "membername": "15",
        "appointment": "0"
      },
      "correctvoid": {
        "ticket": "10",
        "date": "15",
        "clerkid": "10",
        "outlet": "10",
        "amount": "10",
        "dummy": "5",
        "clientname": "15",
        "membername": "15",
        "appointment": "0"
      },
      "reprint": {
        "ticket": "10",
        "date": "15",
        "clerkid": "10",
        "outlet": "10",
        "amount": "10",
        "dummy": "5",
        "clientname": "15",
        "membername": "15",
        "appointment": "0"
      },
      "modifypostedcommission": {
        "ticketNumber": "20",
        "transactionDate": "20",
        "outletId": "20",
        "totalAmount": "40"
      },
      "modifypostedcommissionticketdetails": {
        "radio":"0",
        "item": "15",
        "desc": "20",
        "quantity": "10",
        "name": "15",
        "commission": "10",
        "commissionpercent": "15",
        "amount": "15",
      }
    };

    switch (this.SelectedSettingId) {
      case myGlobals.RetailTransactions.opentransactions:
        dropdown = (Object.values(groupArray.retailtransactions));
        break;
      case myGlobals.RetailTransactions.returnwithticket:
        dropdown = (Object.values(groupArray.retailtransactions));
        break;
      case myGlobals.RetailTransactions.correctvoid:
        dropdown = (Object.values(groupArray.retailtransactions));
        break;
      case myGlobals.RetailTransactions.reprintticket:
        dropdown = (Object.values(groupArray.retailtransactions));
        break;
      case myGlobals.RetailTransactions.modifypostedcommission:
        dropdown = (Object.values(groupArray.retailtransactions));
        break;
      case myGlobals.RetailTransactions.modifypostedcommissionticketdetails:
          dropdown = (Object.values(groupArray.modifypostedcommissionticketdetails));
          break;
    }

    let coloumncount = document.getElementsByTagName('th').length;
    let overallgivenwidth = 0;
    let ga;
    if (dropdown) {
      for (ga = 0; ga < dropdown.length; ga++) {
        if (document.getElementsByTagName('th')[ga])
          document.getElementsByTagName('th')[ga].style.width = Object.values(dropdown)[ga] + '%';
        overallgivenwidth += Number(dropdown[ga]);
      }
      for (let remCol = ga; remCol < coloumncount; remCol++) {
        if (document.getElementsByTagName('th')[coloumncount - 2]) {
          document.getElementsByTagName('th')[coloumncount - 2].style.width = (92 - overallgivenwidth) + '%';
          if (this.EditMoreOption) {
            document.getElementsByTagName('th')[coloumncount - 1].style.width = '2%';
          }
          else {
            if (this.SelectedSettingId == myGlobals.GridType.customfield)
              document.getElementsByTagName('th')[coloumncount - 1].style.width = '6%';
            else
              document.getElementsByTagName('th')[coloumncount - 1].style.width = '3%';
          }
        }
      }
    }

  }


  sliderChange(event: any, rowData?: any) {
    let data = { 'value': event, 'data': rowData }
    this.toggleEvtEmitter.emit(data);
  }
 

  RowSelect(event, SelectedRow, Frm) {
 
    if (Frm == 'All') {
      if (event.checked) {
        this.SelectedData = [];
        for (let item of SelectedRow) {
          this.SelectedData.push(item);
        }
      }
      else {
        this.SelectedData = _.difference(this.SelectedData, this.bodyArray);
      }
    }
    else {
      if (this.SelectedData.indexOf(SelectedRow) == -1) {
        this.SelectedData.push(SelectedRow);
      } else {
        this.SelectedData.splice(this.SelectedData.indexOf(SelectedRow), 1);
      }
      this.IsCheckAll = (_.difference(this.bodyArray, this.SelectedData).length === 0);
    }

    this.RowSelectEmitter.emit(this.SelectedData);
    this.CurrentRowSelectedEmitter.emit({ "event": event, "SelectedRow": SelectedRow, "From": Frm });
  }
 
  rowClick(e, rowData, rowIdx) {
    let restrictCorrection=this.currentproductId==this.sncProduct && rowData?.transactionDetails?.find(o=>o.itemType==RetailItemType.RevenueItem || o.itemType==RetailItemType.Deposit);
    this.correctVoid=restrictCorrection?[this.localization.captions.shop.Void]:[this.localization.captions.shop.Void, this.localization.captions.shop.Correct];
    this.SelectedData = rowData;
  }

  async openEmailReceipt(){
    let manualEmailId : any[] = [];
    if (this.SelectedData && this.SelectedData.guestGuid != null && this.SelectedData.guestGuid != undefined) {
      let clientInfo = await this.receiptService.getClientInfobyGuid(this.SelectedData.guestGuid.toString());
      if (clientInfo.emails != null && clientInfo.emails.length > 0)
        manualEmailId.push(clientInfo.emails.find(x => x.isPrimary) ? clientInfo.emails.find(x => x.isPrimary)?.emailId : clientInfo.emails[0]?.emailId)
    }
    else if (this.SelectedData && this.SelectedData.memberId && this.SelectedData.memberId != "0") {
      let memberInfo = await this._memberService.getMemberInfo(this.SelectedData.memberId, this.SelectedData.transactionDateTime)
      if (memberInfo && memberInfo.emailId) {
        manualEmailId.push(memberInfo.emailId);
      }
    }

    this.dialog.open(EmailReceiptComponent, {
      height: '450px',
      width: '500px',
      panelClass: 'ag_dialog--lg',
      data: manualEmailId
  }).afterClosed().pipe(takeUntil(this.destroyed$)).subscribe(res => {
    var email = res.controls['mail'].value.map(x=>x.emailValue)
    this.editEvt.emit([this.currentRowObj, this.options[0].ServiceId, this.captions.shop.emailreceipt, this.captions.shop.emailreceipt, email]);
  });
  }
  sortingFunc(dh, i, from, overriddenSortColumn, sortColumnDataType) {

    this.selectedDefaultHeader = dh;
    this.overriddenSortColumn = overriddenSortColumn;
    this.sortColumnDataType = sortColumnDataType;
    if (!this.options[0].isInitial && this.defaulSortColumn == this.selectedDefaultHeader
      && from == "change"
    ) {
      if (this.orderTypearr.length > 0) {
        this.orderTypearr.splice(this.orderTypearr.indexOf(dh), 1);
      }
      else {
        this.orderTypearr.push(dh);
      }
    }
    else {

      if (this.givenOrderType == 'desc') {
        this.orderTypearr = [];
        this.orderTypearr.push(dh);
        this.givenOrderType = '';
      } else {
        this.givenOrderType = '';
      }
      
      if (this.orderTypearr.indexOf(dh) == -1) {
        this.orderTypearr = [];
        this.orderTypearr.push(dh);
        this.orderType = 'asc';
      } else {
        this.orderTypearr.splice(this.orderTypearr.indexOf(dh), 1);
        this.orderType = 'desc';
      }
    }

    if (document.getElementById("sortArrow" + i)) {
      for (let j = 0; j < this.options[0].TableHdrData.length; j++) {
        let ele = document.getElementById("sortArrow" + j);
        if (ele)
          ele.classList.remove("IC6");
        let headerEle = document.getElementById("header" + j);
        if (headerEle)
          headerEle.classList.remove("IC6");
      }
      let element = document.getElementById("sortArrow" + i);
      if (element)
        element.classList.add("IC6");
      let headerElement = document.getElementById("header" + i);
      if (headerElement)
        headerElement.classList.add("IC6");
    }
    
  }

  FormatCurrency(orgAmount: number): string {
    let price = '';
    if (orgAmount < 0) {
      price = `(${this.localization.localizeCurrency(orgAmount * -1, false)})`;
    }
    else {
      price = this.localization.localizeCurrency(orgAmount, false);
    }
    return price;
  }

  DeleteRecords(e) {
    this.deleteEvt.emit([e, this.options[0].ServiceId, this.InActiveTherapistChkBoxEvt]);
    this.givenOrderType = this.orderType;
  }

 

  autoscrolltocurrtime(curid) {
    if (document.getElementsByClassName('highlight').length > 0) {
      let curRow = curid.toString();
      var elms = document.getElementById(curRow);
      if (elms) {

        let scrolltoPos = (document.getElementById(curRow).offsetTop);
        let thPos = (document.getElementById('SPAHeaderRow').offsetHeight);
        document.getElementById("SPACustomTableShop").scrollTop = scrolltoPos - thPos;
        clearInterval(this.setinterscroll);
      }
    }
  }
  editRow(event: any, type?: any, belonTo?: any, frm?: any, d?: any) {
     
    if (document.getElementsByClassName("highlight").length > 0) {
      document.getElementsByClassName("highlight")[0].classList.remove("highlight");
    }
    event.target.parentElement.parentElement.classList.add("highlight");
    this.EditRecords(this.currentRowObj, type, frm);
   


  }
  EditRecords(e, type, frm) {

    if (this.editEvt)
      if (this.SelectedSettingId == myGlobals.GridType.commission) {
        this.editEvt.emit([e, type]);
      } else {
        this.editEvt.emit([e, this.options[0].ServiceId, type, frm]);
        this.givenOrderType = this.orderType;
      }
  }
  
  async ngAfterViewInit() {
    this.functionalities = await this.func.getRetailFunctionality();
    this.calwidthwithtimeout();
  }

  SetColumnValue(bodyCnt: any, jsonkey: string): string {

    jsonkey = jsonkey ? jsonkey : "";
    if (jsonkey.includes("+")) {
      let result = "";
      let arrKey = jsonkey.split("+");
      for (let i = 0; i < arrKey.length; i++) {
        let val = bodyCnt[arrKey[i]];
        val = val ? val : "";
        result = result + (i == 0 ? "" : " ") + val;
      }
      return result;
    }
    return bodyCnt[jsonkey];
  }

  onDateChange(_controlType: string, _value: MatDatepickerInputEvent<Date>) {
    if(this.fromDate >= this.toDate)
    {
      this.toDate = this.fromDate;
    }
    let _dateRange = {
      "startDate": this.localization.getDate(this.fromDate),
      "endDate": this.localization.getDate(this.toDate)
    };
    let event: dateRangeUIModel = {
      dateRange: _dateRange,
      value: _value.value,
      controlchanged: _controlType
    };
    this.dateEvtEmitter.emit(event);
  }

  setCurrentRowInfo(arg){
    this.currentRowObj = arg;
  }

  trackByFn(index: number) {
    return index;
  }

  calwidthwithtimeout() {
    setTimeout(() => {
        this.customtablealignment();
        this.calculateWidth();
      },1);
  }

  ngAfterViewChecked() {
    if(!this.viewCheckedFlag) {
      this.viewCheckedFlag = true;
    this.calwidthwithtimeout();
    }
  }

  setMatformWidth(myElement) {
    if (this.tableInput) {
      let minWidth = myElement.parentElement.parentElement.getElementsByClassName('actionitems')[0] ? myElement.parentElement.parentElement.parentElement.getElementsByClassName('actionitems')[0]['offsetWidth'] : 300; //min-300 max-470
      minWidth += myElement.getElementsByClassName('retail-data')[0] ? myElement.getElementsByClassName('retail-data')[0]['offsetWidth'] : 0;
      return minWidth;
    }
  }

  calculateWidth() {
    let table = document.querySelectorAll('table');
    if (table && table.length > 0) { 
      let tableHeight = table[0]['offsetHeight'];
      if (table[0].closest(".fixed-table-container")) {
        let parentHeight = table[0].closest(".fixed-table-container")['offsetHeight'];
        if (parentHeight > tableHeight)
          table[0].closest("#SPACustomTableShop")['style']['height'] = tableHeight + 2 + 'px';
        else if (parentHeight < tableHeight)
          table[0].closest("#SPACustomTableShop")['style']['height'] = parentHeight + 'px';
      } 
      let searchClass = document.getElementsByClassName('retailCustomDataTable');
      for (let i = 0; i < searchClass.length; i++) {
        let pageHeader = searchClass[i].getElementsByClassName('page-header')[0] ? searchClass[i].getElementsByClassName('page-header')[0]['offsetWidth'] : 0;
        let searchInput = searchClass[i].getElementsByClassName('searchbox')[0];
    
        if (pageHeader > 0) {
          pageHeader = pageHeader - this.setMatformWidth(searchClass[i]) - 60;
        }
        let inputLength = this.tableInput ?this.tableInput.nativeElement.attributes['data-placeholder'].value.length : 1;
        let inputWidth = inputLength <= 30 ? inputLength * 10 : inputLength * 7.5 + 20;
        if (searchInput && pageHeader > 0) {
          searchInput['style'].width = (pageHeader > inputWidth) ? inputWidth + 'px' : pageHeader + 'px';
        }
      }



    }


  }
 

    public onAdvancedSearchClose() {
      this.advancedSearchClose.emit();
    }

    public onAdvancedSearchOpen() {
      this.tableDivClass = `container-${this.SelectedSettingId}`;
      this.showAdvancedSearch = true;
      this.calwidthwithtimeout();
    }

    public onAdvancedSearchClick(event: ReprintAdvancedSearch) {
      this.advancedSearch.emit(event);
    }
 

}


export interface listOrder {
  listOrder: number;
}
export interface DisplayOption {
  options: Options[],
  showOutlet: boolean;
  selectedValue: number;
  reprintDateFilterValues: { startDate: Date, endDate: Date };
  showOutletInGrid: boolean;
  restrictDataFilterChange?: boolean;
}
export interface Options {
  id: number;
  value: string;
}