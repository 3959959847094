import { AgDateConfig, AgDropdownConfig, ButtonValue, DropdownOptions } from 'src/app/common/Models/ag-models';
import { Input, Output, Component, OnInit, EventEmitter, ViewEncapsulation } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { Localization } from 'src/app/common/localization/localization';
import { AlertType } from 'src/app/common/shared/shared/enums/enums';
import { ReplaySubject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ActionMode, ButtonType } from 'src/app/common/enums/shared-enums';
import { AlertAction } from 'src/app/common/shared/shared.modal';
import { CommonUtilities } from 'src/app/common/shared/shared/utilities/common-utilities';
// import * as GlobalConst from 'src/app/retail/shared/globalsContant';

@Component({
  selector: 'app-create-deposity-policy-mapping',
  templateUrl: './create-deposity-policy-mapping.component.html',
  styleUrls: ['./create-deposity-policy-mapping.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class CreateDeposityPolicyMappingComponent implements OnInit {
  policyMappingForm: UntypedFormGroup;
  actionButton: ButtonValue;
  cancelButton: ButtonValue;
  captions: any;
  destroyed$: ReplaySubject<boolean> = new ReplaySubject(1);
  searchText: string;
  subGroupList = [];
  subHeader: any;
  mainHeader: any;
  originalData = [];
  allData: any = [];
  selectedGuestTagKey: string[] = ["name"];
  autoCompleteKeys: string[] = ['name'];
  searchGuestMaxLength = 50;
  tagSearchKey: string[] = ["name"];
  selectedTagData: any = [];
  // @Input() policyOptions;
  @Input() cancellationPolicyOptions;
  @Input() applyToOptions;
  @Input() mainGroupList;
  @Input() productId;
  @Input() searchPlaceholder;
  @Input() inputs;
  @Output() formReady = new EventEmitter();
  @Output() handleClickEvent = new EventEmitter();
  startDateInput: AgDateConfig;
  endDateInput:  AgDateConfig;
  guestOptions : DropdownOptions[];
  policyOptions : DropdownOptions[];

  constructor(private localization: Localization,
    private fb: UntypedFormBuilder, private utils: CommonUtilities,) {
      this.captions = this.localization.captions;
    }
  ngOnInit(): void {
    this.formGenerator();
    this.originalData = this.mainGroupList
    // this.mainHeader = this.productId ? this.productId == GlobalConst.Product.GOLF ? this.captions.playerType : '' : ''
    // this.subHeader = this.productId ? this.productId == GlobalConst.Product.GOLF ? this.captions.rateType : '' : ''
    this.policyMappingForm.valueChanges.pipe(takeUntil(this.destroyed$)).subscribe(val => {
     this.actionButton.disabledproperty = !(this.policyMappingForm.dirty && this.policyMappingForm.valid)
   });
   }
 
   formGenerator(){
     this.policyMappingForm = this.fb.group({
       policy:0,
       cancellationPolicy: '',
       applyTo:'',
       startDate:'',
       endDate:'',
       guestType:''
     })
     this.actionButton = {
       type: "primary",
       label: this.inputs.mode === ActionMode.update ? this.captions.btn_update : this.captions.btn_save,
       disabledproperty: true,
     };
     this.cancelButton = {
       type: "tertiary",
       label: this.captions.btn_cancel,
     };
     this.startDateInput = {
       form: this.policyMappingForm,
       formControlName: 'startDate',
       placeHolderId: 'lbl_startDate',
       placeHolder: this.captions.lbl_startDate,
       automationId:"'Txt_policyMapping_startDate'"
     };
     this.endDateInput = {
       form: this.policyMappingForm,
       formControlName: 'endDate',
       placeHolderId: 'lbl_endDate',
       placeHolder: this.captions.lbl_endDate,
       automationId:"'Txt_policyMapping_endDate'"
     };
     this.guestOptions = this.getGuestOptions();
     this.policyOptions = this.getPolicyOptions();
   }

   getGuestOptions() {
    return[
      {
        id : 1,
        value: this.captions.lbl_localGuests,
        viewValue:this.captions.lbl_localGuests
      },
      {
        id : 2,
        value: this.captions.lbl_resortGuests,
        viewValue:this.captions.lbl_resortGuests
      }
    ]
   }

   getPolicyOptions() {
    return [
      {
        id : 1,
        value:'Summer Deposit Policy',
        viewValue:'Summer Deposit Policy'
      },
      {
        id : 2,
        value:'Winter Deposit Policy',
        viewValue:'Winter Deposit Policy'
      },
      {
        id : 3,
        value:'Mid-term Deposit Policy',
        viewValue:'Mid-term Deposit Policy'
      }
    ]
   }
 
   startDateChange(e){
 
   }
   endDateChange(e){
 
   }
 
   applyTo(event,option){
 
   }
 
   groupTypeSelection(event,selectedGroup){
     selectedGroup.selected = event.checked;
       if(event.checked){
         selectedGroup.rateTypes.forEach(element => {
           this.subGroupList.push(element);
         });
       }
       else{
         this.subGroupList = this.subGroupList.filter(x=> x.playerId != selectedGroup.id);
       }
       this.policyMappingForm.markAsDirty();
   }
 
   subGroupTypeSelection(event,selectedObj){
     selectedObj.selected = event.checked;
     this.policyMappingForm.markAsDirty();
   }
 
   searchValueChange(eve) {
     if(eve){
       this.searchText = eve;
       this.mainGroupList = this.originalData.filter(x=> x.name.toLowerCase().includes(this.searchText.toLowerCase()));
     }
     else{
       this.mainGroupList = this.originalData
     }
   
   }
 
   policyChange(){
     console.log();
   }
 
   onAction(e){
     const clickReturnValue = {
       from: this.inputs.mode,
       formValues: this.policyMappingForm.getRawValue(),
       form: this.policyMappingForm
     };
     this.handleClickEvent.emit(clickReturnValue);
   }
 
   onCancel(eve) {
     const clickReturnValue = {
       from: ActionMode.cancel,
       formValues: this.policyMappingForm.getRawValue(),
       form: this.policyMappingForm
     };
     if (this.policyMappingForm.dirty) {
       this.utils.showAlert(this.captions.lbl_Cancel_Msg, AlertType.Warning, ButtonType.YesNo, (res) => {
         if (res == AlertAction.YES) {
           this.handleClickEvent.emit(clickReturnValue);
         }
       })
     }
     else{
       this.handleClickEvent.emit(clickReturnValue);
     }
   }
 
   async selectedTagEmit(eve) {
     // this.selectedGuest = eve[0];
   }
 
   onGuestChipRemoveEvent(event: any) {
     this.allData = [];
   }
 
   tagSearchTextEmit(searchInput: string) {
   }
   onBlur(e) { }

}
