import { Injectable } from "@angular/core";
import { ReportDownloadFormat, ReportAPIOptions, ReportAPIModel } from "../business/report.modals";
import { SPAConfig } from "../../common/config/SPA-config";
import { Host } from "../../shared/globalsContant";
import { HttpServiceCall } from "../../shared/service/http-call.service";
import { RetailLocalization } from "../../common/localization/retail-localization";
import * as saveAs from 'file-saver'
import { PrintManagerDataService } from "src/app/common/dataservices/printmanager.data.services";
import { CashDrawerDetails, PrintDocumentInput} from "src/app/common/Models/common.models";
import { RetailUtilities } from "../../shared/utilities/retail-utilities";
import { AllReports } from "src/app/retail/shop/shop.modals";
import { CommonPropertyInformation } from 'src/app/common/shared/services/common-property-information.service';
import { DMReceiptPosting } from 'src/app/common/data-magine/data-magine-integration/data-magine-models';
import { SignatureCaptureService } from "../../shop/signature-capture/signature-capture.service";
export const saveFile = (blobContent: Blob, fileName: string) => {
    const blob = new Blob([blobContent], { type: 'application/octet-stream' });
    saveAs(blob, fileName);
    document.getElementById('custom-cover-spin').style.display = 'none';

};

@Injectable({ providedIn: 'root' })
export class FastReportBusinessService {

    constructor(private spaConfig: SPAConfig, private http: HttpServiceCall, private localization: RetailLocalization, private _printManager: PrintManagerDataService, private utils: RetailUtilities, private PropertyInfo: CommonPropertyInformation, private sigCapService: SignatureCaptureService) {

    }
    formatArray: any = { 'PDF': 0, 'HTML': 1, 'EXCEL': 2, 'WORD': 3, 'IMAGE': 4 };
    isLatestPrintManager: boolean = false;
    public async downloadReport(format: ReportDownloadFormat, options: ReportAPIOptions, showPrintDialog: boolean = false, callBack?: any, isDownloadWithPreview?: boolean, receiptPrinter?: PrintDocumentInput,sigcapIndexingDetails:DMReceiptPosting=null):Promise<void> {
        let downloadURL: string = "";
        if(options.code == "TransactionExtract")
        {
            downloadURL = this.spaConfig.getHost( Host.retailPOS) +'/' + this.spaConfig.getUrl("transactionExtract");
           
        }else{
            downloadURL = this.spaConfig.getHost(Host.reporting) + '/' + this.spaConfig.getUrl("GetReport");
        }
        
        let autoPrint =  await this.validatePrinterConfiguration(receiptPrinter);
        let useTrimmedNewReceipt = false;

        if(autoPrint)
        {
            let propConfigs = this.PropertyInfo.GetPropertyConfiguration();
            
            //To read the property configs and decide print format
            if (propConfigs != null)
            {
                if (propConfigs['UseTrimmedNewReceipt'] != null && propConfigs['UseTrimmedNewReceipt'] == "true")
                {
                    useTrimmedNewReceipt = true;
                }
            }
        }

        format = autoPrint ? ((this.isLatestPrintManager && useTrimmedNewReceipt) ? 'IMAGE' : 'HTML') : format;
        receiptPrinter ? ((this.isLatestPrintManager && useTrimmedNewReceipt) ? receiptPrinter.printFormat = "FRXIMAGE" : receiptPrinter.printFormat = "IMAGE") : undefined;
        this.downloadFile(downloadURL, format, options, showPrintDialog,autoPrint, callBack, isDownloadWithPreview, receiptPrinter,sigcapIndexingDetails);
    }
    
    private downloadFile(url: string, type: ReportDownloadFormat, options: ReportAPIOptions, showPrintDialog: boolean, autoPrint : boolean,callBack?: any, isDownloadWithPreview?: boolean, receiptPrinter?: PrintDocumentInput,sigcapIndexingDetails:DMReceiptPosting=null): void {
        let fileName: string;
        this.utils.ToggleLoader(true);
        if (type == "PDF") {
            fileName = `${options.code}.pdf`
        }
        else if (type == "WORD") {
            fileName = `${options.code}.docx`
        } else if (type == "EXCEL"|| type == "RAWDATA") {
            fileName = `${options.code}.xlsx`
        } else if (type == "IMAGE") {
            fileName = `${options.code}.png`
        }
        options.format = type;
        let apiModel: any;
        if(options.code == "TransactionExtract"){
                apiModel = options.filters;
        }else{
            apiModel = this.createAPIOptions(options);
        }
        
            this.http.putHTTPBlobData(url, apiModel).subscribe(async res => {
                if (showPrintDialog || isDownloadWithPreview) {
                    this.showOrPrintFile(res,autoPrint, receiptPrinter, options.code);
                    if (callBack) {
                        callBack();
                    }
                }
                if (!showPrintDialog || isDownloadWithPreview) {
                    saveFile(res, fileName);
                }
                if(sigcapIndexingDetails){
                    sigcapIndexingDetails.receipt = await this.utils.getByteData(res);
                    sigcapIndexingDetails.receiptInput=JSON.stringify(apiModel);
                  this.sigCapService.PostReceiptToDM(sigcapIndexingDetails);
                }
            },
            err=>{
                this.utils.ToggleLoader(false);
                document.getElementById('custom-cover-spin').style.display = 'none';
                if(options.code == "TransactionExtract")
                {
                    this.utils.ShowErrorMessage(this.localization.captions.common.Information, this.localization.getError(-118));
                }
                else
                this.utils.showError(this.localization.captions.shop.Unabletoprintreceipt);
            });
        this.addReceiptLoader(options);
    }

    public PostReceiptToDM(options: ReportAPIOptions, sigcapIndexingDetails: DMReceiptPosting) {
        let apiModel = this.createAPIOptions(options);
        if (sigcapIndexingDetails) {
            sigcapIndexingDetails.receiptInput = JSON.stringify(apiModel);
            this.sigCapService.PostReceiptToDM(sigcapIndexingDetails);
        }

    }
    
    private addReceiptLoader(options: ReportAPIOptions) {
        const ReceiptCodes = [String(AllReports.GolfReceipt), String(AllReports.Receipt), String(AllReports.SNCReceipt), String(AllReports.RetailReceipt), String(AllReports.PMSReceipt)];
        if (ReceiptCodes.includes(options.code)) {
            const loadingContainer = document.getElementById('cover-spin');
            if (loadingContainer != null) {
                loadingContainer.style.display = 'none';
            }
            this.utils.ToggleLoader(true, this.localization.captions.shop.WaitForMoment);
        }
    }
    private async validatePrinterConfiguration(receiptPrinter?: PrintDocumentInput): Promise<boolean>
    {
        this.isLatestPrintManager = false;
        let printerManagerURI = this.localization.GetPrinterManagerURI();
        let autoPrint=true;
        if (!printerManagerURI || printerManagerURI == '' || !receiptPrinter || !receiptPrinter.printerName || receiptPrinter.printerName == '') {
            autoPrint=false;
        }
        if(autoPrint)
        {
            this.utils.ToggleLoader(true, this.localization.captions.shop.WaitForMoment);
            try
            {
                try
                {
                    let isImageSupported = await this._printManager.IsImageSupportAvailable(printerManagerURI);
                    if(isImageSupported != undefined && isImageSupported)
                        this.isLatestPrintManager = true;
                }
                catch
                {}
                finally
                {
                    if(!this.isLatestPrintManager)
                    {
                        await this._printManager.PrinterManagerHeartBeat(printerManagerURI);
                    }
                    this.utils.ToggleLoader(false);
                    autoPrint = true;
                }
            }
            catch(e)
            {
                autoPrint = false;
                this.utils.ToggleLoader(false);
                this.utils.showError(this.localization.captions.common.NoPrinterFound);
            }

        }
        return autoPrint;
    }
    private async showOrPrintFile(blob,autoPrint:boolean, receiptPrinter?: PrintDocumentInput, fileName?: string) {
        let printerManagerURI = this.localization.GetPrinterManagerURI();
            if (!autoPrint) {
            this.showFile(blob);
            }
        else {
            let byte: any;
            byte = await this.utils.getByteData(blob);
            receiptPrinter.reportData = byte;
            receiptPrinter.documentName = fileName ? fileName : "";;
            this._printManager.Print(printerManagerURI, receiptPrinter);
            this.utils.ToggleLoader(false);
        }
    }

    ShowOrSaveFile(reportblobData:Blob,fileName:string,showPrintDialog){
        this.utils.ToggleLoader(false);
        if (showPrintDialog) {
          this.showFile(reportblobData);
        } else {
          saveFile(reportblobData, fileName);
        }
      }

    private async showFile(blob) {
            var newBlob = new Blob([blob], { type: "application/pdf" })
            if (window.navigator && (window.navigator as any).msSaveOrOpenBlob) {
                (window.navigator as any).msSaveOrOpenBlob(newBlob);
                return;
            }
            const loadingContainer = document.getElementById('cover-spin');
            if (loadingContainer != null) {
                loadingContainer.style.display = 'none';
            }
            this.utils.ToggleLoader(false);
            const data = window.URL.createObjectURL(newBlob);
            let newTab: Window = window.open(data);
            newTab.focus();
            newTab.print();
            document.getElementById('custom-cover-spin').style.display = 'none'
            setTimeout(function () {
                // For Firefox it is necessary to delay revoking the ObjectURL
                window.URL.revokeObjectURL(data);
            }, 100);
    }


    public createAPIOptions(options: ReportAPIOptions): ReportAPIModel {
        let _format = options.format ? options.format : "HTML";
        return {
            code: options.code,
            format: options.format ? options.format : "HTML",
            downloadFileName: options.downloadFileName ? options.downloadFileName : "Download",
            parameters: this.arrayToObject(options.params, _format, true),
            uRIParams: this.arrayToObject(options.URIParams, _format, false),
            filterBody: options.filters,
            dateFormat: this.localization.dateFormat
        };
    }

    private arrayToObject(objectArr: any[], format: any, includeFormat: boolean): { [key: string]: string } {
        var result = {};
        objectArr.forEach(o => {
            result[Object.keys(o)[0]] = Object.values(o)[0];
        });
        if (includeFormat) {
            result['pFormat'] = this.formatArray[format];
        }
        return result;   //Dictionary<string,string>
    }

    public OpenCashDrawer(cashDrawerDetails: CashDrawerDetails) {
        let cashDrawerUrl = this.localization.GetCashDrawerURI();
        this._printManager.OpenCashDrawer(cashDrawerUrl, cashDrawerDetails)
        return true;
    }


}
