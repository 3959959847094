import { Component, OnDestroy, OnInit } from '@angular/core';
import { AppointmentpopupService } from '../../../service/appointmentpopup.service';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { SpaLocalization } from 'src/app/core/localization/spa-localization';
import { LogApplicableType } from 'src/app/common/enums/shared-enums';




@Component({
  selector: 'app-spa-contact-log-wrapper',
  templateUrl: './spa-contact-log-wrapper.component.html',
  styleUrls: ['./spa-contact-log-wrapper.component.scss']
})
export class SpaContactLogWrapperComponent implements OnInit, OnDestroy {

  clientEditData;
  clientWidowActionType;
  clientContactLogs;
  isPurged;
  applicableType;

  constructor(public appoinmentPopupService: AppointmentpopupService, public fb: UntypedFormBuilder, public localization: SpaLocalization) { }

  ngOnInit(): void {
    this.clientEditData = this.appoinmentPopupService?.clientEditData;
    this.clientWidowActionType = this.appoinmentPopupService.clientWidowActionType;
    this.isPurged = this.clientEditData?.clientDetail?.isPurged;
    this.applicableType=LogApplicableType.Client;
    if (this.clientEditData && this.clientWidowActionType == "EDIT") {
      this.clientContactLogs = this.appoinmentPopupService?.clientEditData.clientContactLogs
    } else {
      this.clientContactLogs = this.appoinmentPopupService.clientContactLogs;
    }
  }


  InitializeDummyForm() {
    this.appoinmentPopupService.contactLogsFormGrp = this.fb.group({});
  }

  createRecord(clientContactLogs) {
    if (this.appoinmentPopupService.clientEditData) {
      this.appoinmentPopupService.clientEditData.clientContactLogs = clientContactLogs;
    } else {
      this.appoinmentPopupService.clientContactLogs = clientContactLogs;
    }
    this.appoinmentPopupService.contactLogsFormGrp.markAsDirty();
  }

  editRecord(clientContactLogs) {
    if (this.appoinmentPopupService.clientEditData) {
      this.appoinmentPopupService.clientEditData.clientContactLogs = clientContactLogs;
    } else {
      this.appoinmentPopupService.clientContactLogs = clientContactLogs;
    }
    this.appoinmentPopupService.contactLogsFormGrp.markAsDirty();
  }

  deleteRecord(clientContactLogs) {
    if (this.appoinmentPopupService.clientEditData) {
      this.appoinmentPopupService.clientEditData.clientContactLogs = clientContactLogs;
    } else {
      this.appoinmentPopupService.clientContactLogs = clientContactLogs;
    }
    this.appoinmentPopupService.contactLogsFormGrp.markAsDirty();
  }

  ngOnDestroy(): void {
    this.appoinmentPopupService.clientContactLogs = [];
  }

}
