import { Formatter } from 'src/app/eatecui/source/shared/models/formater.interface';

export const DecimalFormater: Formatter = (gridData: any): any => {
    try {
        const INIDecimalValue = sessionStorage.getItem('INIDecimalValue');
        const visibleNumberLength = sessionStorage.getItem('appsetting');
        const VisibleDigit = INIDecimalValue ? parseInt(INIDecimalValue, 10) : 
        visibleNumberLength ? JSON.parse(sessionStorage.getItem('appsetting')).VisibleNumberLength : 2;
        if ((gridData.value && !isNaN(parseFloat(gridData.value))) || gridData.value === 0) {
            return parseFloat(gridData.value.toString()).toFixed(VisibleDigit);
        } else {
            return gridData.value;
        }
    } catch (error) {
        console.log('error occured CustomValidation', error);
    }
  }