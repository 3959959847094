import { AfterViewInit, ChangeDetectorRef, Component, ElementRef, HostListener, Input, OnChanges, OnDestroy, OnInit, Pipe, PipeTransform, SecurityContext, ViewChild, ViewEncapsulation } from '@angular/core';
import { UntypedFormArray, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatInput } from '@angular/material/input';
import { MatTabChangeEvent } from '@angular/material/tabs';
import { DomSanitizer } from '@angular/platform-browser';
import * as _ from 'lodash';
import { CommonVariablesService, SelectedProducts, ApplyDiscountInput, Ticket, LineItem, FolioInfo, GroupRetailItems, CustomFeeConfig, TransactionCustomFee, RentalItemPurchase, RentalSourceType, ShopRentalInfoRequest, RentalInfoWithAvailability } from '../../../shared/service/common-variables.service';
import { HttpMethod, HttpServiceCall, ServiceParamsAsync } from '../../../shared/service/http-call.service';
import { RetailLocalization } from '../../../common/localization/retail-localization';
import { DialogOverviewExampleDialog } from '../../../shared/dialog-popup/dialogPopup-componenet';
import { RetailPropertyInformation } from '../../../common/services/retail-property-information.service';
import { ActivatedRoute, Router } from '@angular/router';
import { BreakPointAccess } from '../../../shared/service/breakpoint.service';
import { ScrollbarComponent } from 'ngx-scrollbar';
import { SwiperComponent, SwiperConfigInterface, SwiperDirective } from 'ngx-swiper-wrapper';
import { ReplaySubject, Subject } from 'rxjs';
import { takeUntil, debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { RetailItemType, QuickSaleItem, QuickSaleCategoryOutlets, SystemConfiguration } from 'src/app/retail/retail.modals';
import { GiftCardConfiguration, IssueGiftCardPopupModel } from 'src/app/retail/shared/service/payment/payment-model';
import { AlertType, AutoRetailTransactionRequestModel, AutoRetailTransactionResponse, AutoServiceChargeGratuity, ClientInformation } from 'src/app/retail/shared/shared.modal';
import { GiftCardBusiness } from 'src/app/retail/sytem-config/gift-cards/gift-cards.business';
import { AlertMessagePopupComponent } from '../../../shared/alert-message-popup/alert-message-popup.component';
import { AppointmentLineNumber, BaseResponse, HandleResponse, OutletSubProperty, PaymentMethods, ShopItem, StoreTerminal,Course, HoleTypes } from '../../../shared/business/shared.modals';
import { IClassCheckOutEventModel, RetailEventParameters, RetailEventType, VoidEventModel } from '../../../shared/events/event.model';
import { retailPublisher } from '../../../shared/events/pubsub/retail.publishers';
import * as GlobalConst from '../../../shared/globalsContant';
import { RetailSharedVariableService } from '../../../shared/retail.shared.variable.service';
import { ImageProcessorService } from '../../../shared/service/image-processor-service';
import { RedirectToModules, RetailUtilities } from '../../../shared/utilities/retail-utilities';
import { ApplyDiscountService } from '../../apply-discount/appply-discount.service';
import { ReceiptBusinessService } from '../../receipt/business/receipt-business.service';
import { ShopDialogPopUp } from '../../shop-dialog-popup/shop-dialog-popup.component';
import {
  ApplyCommissionServGrat, ApplyDiscount, enterCommentPrompt, GiftCardShopDetails, StayRetailItemInfo, GroupedRetailItemForShop, ItemDiscount, ItemForShop
  , RetailCategoryAndDiscountTypeDetails, RetailShopItem, shopPricePrompt, shopScaledItems, LinkedRetailItemsData, ItemType,
  TeeTimeLinkedRetailItemsData,
  RedeemMultiGridData
} from '../../shop.modals';
import { RetailTransactionService } from '../retail-transactions/retail.transactions.service';
import { PropertySettingDataService } from 'src/app/retail/sytem-config/property-setting.data.service';
import { RetailService } from 'src/app/retail/retail.service';
import { RetailFunctionalityBusiness } from 'src/app/retail/shared/business/retail-functionality.business';
import { MiscellaneousSwitch, SyncStatus } from '../../../shared/globalsContant';
import { RetailFeatureFlagInformationService } from 'src/app/retail/shared/service/retail.feature.flag.information.service';
import { ApplyDiscountComponent } from '../../apply-discount/apply-discount.component';
import { ShopAddItemComponent } from '../../shop-common-components/shop-add-item/shop-add-item.component';
import { DialogCloseEnum } from 'src/app/common/enums/shared-enums';
import { RetailDataAwaiters } from '../../../shared/events/awaiters/retail.data.awaiters';
import { TransactionEngineBusiness } from 'src/app/retail/retail-transaction-engine/transaction-engine-business';
import { AlertAction } from 'src/app/common/enums/shared-enums';
import { ButtonType } from 'src/app/common/enums/shared-enums';
import { RetailRoutes } from 'src/app/retail/retail-route';
import { FolioBusiness } from 'src/app/retail/Folio/folio-business';
import { SettingModule, SettingScreen, SourceType } from 'src/app/retail/Folio/Model/folioDetails-model';
import { AutoRetailTransactionBusiness } from 'src/app/retail/shared/business/auto-retailtransaction.business';
import { cloneDeep } from 'lodash';
import { AgTimeConfig } from 'src/app/common/Models/ag-models';
import { FacadeService } from 'src/app/common/services/facade.service';
import { UserAccessBreakPoints } from 'src/app/retail/shared/constants/useraccess.constants';
import { UserAccessService } from 'src/app/common/services/user-access.service';
import { ShopBussinessService } from '../../shop-business.service';
import { CashDrawerConnectionType, CashDrawerDetails } from 'src/app/common/Models/common.models';
import { ReceiptService } from '../../receipt/business/receipt.service';
import { QuickLoginDialogResult } from 'src/app/common/shared/shared/quick-login/quick-login.component';
import { QuickLoginUtilities } from 'src/app/common/shared/shared/utilities/quick-login-utilities';
@Component({
  selector: 'app-retail-items',
  templateUrl: './retail-items.component.html',
  styleUrls: ['./retail-items.component.scss'],
  encapsulation: ViewEncapsulation.None,
  providers: [FacadeService, UserAccessService]
})

export class RetailItemsComponent implements AfterViewInit, OnDestroy, OnInit, AfterViewInit, OnChanges {

  private SetBarCodeTimer;
  @ViewChild(SwiperComponent) componentRef: SwiperComponent;
  @ViewChild(SwiperDirective) directiveRef: SwiperDirective;
  @ViewChild(ScrollbarComponent) scrollRef: ScrollbarComponent;
  @ViewChild('discountPopover') discountPopover;
  @ViewChild('searchTextBarCode') searchTextBarCode: MatInput;
  AllShopItems: any[] = [];
  AllActiveShopItems: any[] = [];
  products: GroupedRetailItemForShop[] = [];
  RetailProducts: GroupedRetailItemForShop[] = [];
  rentalItemCountMap: Map<number, number> = new Map<number, number>(); // To store Rental Item quantity retrieved from server
  withMoreItems: any[] = [];
  ShopitemsDetails: any;
  isOverflown: boolean = false;
  ShopFormGrp: UntypedFormGroup;
  item: UntypedFormArray;
  selectedTabIndex: number = 0;
  transactions: boolean = true;
  quickSaleItems: QuickSaleItem[] = [];
  packagedItemIds: number[] = [];
  packagedItem: ShopItem[] = [];
  packagedItemCount: number;
  packagedItemIndex: number = 0;
  packagedItemPrice: number;
  selectedParentItem: ShopItem[] = [];
  fromViewShop: boolean = false;
  saveValues: ApplyCommissionServGrat;
  noOfButtons: number;
  noOfProducts: any[] = [];
  showViewLess: any[] = [];
  addedItem: boolean = false;
  setIntervalWidth: NodeJS.Timer;
  selectShopItem: any;
  reatailIndex: any;
  productDataIndex: any
  outlets: OutletSubProperty[] = [];
  outletTerminals: StoreTerminal[] = []
  subPropertyAccessByUser_apiResponse: Promise<BaseResponse<OutletSubProperty[]>>;
  isReturnMode = false;
  unitOfMeasures: any;
  isDebit = false;
  private $destroyed: ReplaySubject<boolean> = new ReplaySubject(1);
  xPos: number;
  yPos: number = 0;
  isRight: boolean;
  currentSelectedItemForDiscount: any;
  discountInput: ApplyDiscountInput = {
    CategoryId: 0,
    SelectedDiscount: null,
    showItemDetails: true,
    isMultipleItems: true,
    isCostOrCostPlusEligible: false
  };
  percentage = [
    { id: 1, name: 'Staff', percentage: '30%' }
  ]
  openedIdx: number = -1;
  isOpened: boolean = false;
  ItemEligibleForDiscount: any = {};
  ItemEligibleForCostOrCostPlus: any = {};
  captions: any = this.localization.captions.shop;
  retailimages = [];
  hasAccessToCreateTran: any;
  enableSearchIcon: boolean = true;
  enablebarSearchIcon: boolean = true;
  noCartUrl: any;
  disableOutlet = false;
  globalSearchParam: string = "";
  globalSearchItemId: any = 0;
  giftCardConfig: GiftCardConfiguration;
  showReturn: boolean;
  clonedProducts: Array<any> = [];
  pageStart = 0;
  pageLength = 10;
  sortBy = 1;
  isSortByAscending = true;
  isLoading = false;
  searchByBarcode: false;
  notifier: Subject<void> = new Subject<void>();
  searchTextChanged = new Subject<{ searchtxt, searchbyBarCode, from }>();
  quickSaleCateroryId: any;
  isClickedViewMore: boolean;
  isFromGlobalSearch: boolean;
  saveCart: boolean;
  functionalities: { [key: string]: boolean } = {};
  linkedRetailItem: LinkedRetailItemsData;
  ShowOutletSelectionFieldInShopScreen = GlobalConst.RetailFunctionalities.ShowOutletSelectionFieldInShopScreen;
  defaultOutlet: number;
  originalNoOfButtons: number;
  isCheckWidth = false;
  stayId: number;
  roomNumber: string;
  itemnotSynchronized: boolean = true;
  isUpdate: boolean;
  miscSetting: SystemConfiguration[];
  dialogCloseEnum = DialogCloseEnum;
  stayarrivalDate: Date;
  staydepartureDate: Date;
  isEmptyOpenItemDesc = false;
  isDisplayImagesInShop: boolean;
  togglePanel: boolean = false;
  toggleCategories: boolean = false;
  isApplyRevisedMultiPackLogic: boolean = false;
  floatLabel: string;
  addOrUpdateBtnLabel: string;
  updateItemsToAppt = false;
  payeeInfo: any;
  toggleForMobile:boolean = false;
  toggleForCartMobile = false;
  postfolio_flag: boolean = false;
  barcodeItemLoader: boolean = false;
  playerDetails = [];
  selectedPlayer = [];
  groupList: GroupRetailItems[] = [];
  removedGroupList: GroupRetailItems[] = [];
  productId: any;
  GolfId = GlobalConst.Product.GOLF;
  isFromTeeTimeAddRetailItem: boolean = false;
  itemTypeEnum = ItemType;
  isSelectAllClicked: boolean = false;
  checkedOutGroupListCount: number = 0;
  isUpdated : boolean = false;
  rentalInformation: RentalInfoWithAvailability[];
  isRetailRental: boolean = false;
  courses: Course;
  holeDurationinMins: number;
  from;
  triggerItems= new Subject();
 
  @Input('from')
  set setfromvalue(value){
    this.isRetailRental = true;
    this.from = value;
  };
  startTimeInput: AgTimeConfig;
  endTimeInput: AgTimeConfig;
  _showMultiPackRedeem = false;
  get ShowMultiPackRedeem() {
    return this._showMultiPackRedeem && !this._shopservice.isReturnWithoutTicket && !this._shopservice.isfromReturnPage
      && !this._shopservice.reOpenTransaction && !this.isFromTeeTimeAddRetailItem
      && !this._shopservice.addRetailItemToSource;
  }
  quickLoginPopupEnabled: boolean = false;
  isAllowUserPriceOverride: boolean = false;
	isQuickLoginEnabledForOverride: boolean = false;


  constructor(public dialog: MatDialog
    , public _utilities: RetailUtilities
    , public _shopservice: CommonVariablesService
    , private Form: UntypedFormBuilder
    , private http: HttpServiceCall
    , public localization: RetailLocalization
    , private _cdr: ChangeDetectorRef
    , public propertyInfo: RetailPropertyInformation
    , public route: ActivatedRoute
    , private breakPoint: BreakPointAccess
    , public applyDiscountService: ApplyDiscountService
    , private transactionService: RetailTransactionService
    , public imgService: ImageProcessorService
    , public _domsanitzer: DomSanitizer
    , private receiptService: ReceiptBusinessService
    , private _retailService: RetailSharedVariableService
    , private _giftCardBusiness: GiftCardBusiness
    , private _propertySettingDataService: PropertySettingDataService
    , private _rs: RetailService
    , private func: RetailFunctionalityBusiness
    , private router: Router
    , private _retailFeatureFlagInfo: RetailFeatureFlagInformationService
    , private transactionEngineBusiness: TransactionEngineBusiness
    , private _folioBusiness: FolioBusiness
    , private _autoRetailTransactionBusiness: AutoRetailTransactionBusiness
    , private _facadeService: FacadeService
    , private _shopBusiness: ShopBussinessService
    , private _receiptService: ReceiptService
    , private _commonUtils: QuickLoginUtilities
  ) {

    // Route Params
    route.queryParams.pipe(takeUntil(this.$destroyed)).subscribe(val => {
      if (val) {
        this.stayId = val?.stayId;
        this.roomNumber = val?.room;
        if (val?.id) {
          this.globalSearchParam = '';
          this.isFromGlobalSearch = true;
          this.globalSearch(val?.id, this.globalSearchParam);
        }
      }
    });
    // this.noCartUrl = this._domsanitzer.sanitize(SecurityContext.URL, this._domsanitzer.bypassSecurityTrustUrl('assets/images/shop/Shopnocart.jpg'));
    this.noCartUrl = 'assets/images/shop/Shopnocart.jpg';
    this.defaultOutlet = this.propertyInfo.GetDefaultOutlet();
    this.floatLabel = this.localization.setFloatLabel;
    this.isAllowUserPriceOverride = this.breakPoint.CheckForAccess([GlobalConst.RetailBreakPoint.PriceOverride], false);
    this.SetCreditOrDebit()
  }
  @HostListener('window:resize', ['$event'])
  onResize(event) {
    setTimeout(() => this.checkProductsWidth(), 1000);
  }

  async globalSearch(retailItemId, searchTxt) {
    try {
      const qsc = this.categorySelected.length !== 0 && !this.categorySelected.includes(0) ? this.categorySelected.join('&quickSaleCategory=') : '0';

      this.notifier = new Subject<void>();
      const queryParams = {
        outletId: this._shopservice.SelectedOutletId,
        includeInactive: false,
        pageStart: this.pageStart,
        pageLength: this.pageLength,
        sortBy: this.sortBy,
        isSortByAscending: this.isSortByAscending,
        quickSaleCategory: this.quickSaleCateroryId || qsc,
        searchText: searchTxt,
        searchByBarcode: this.searchByBarcode,
        itemId: retailItemId,
        showRentalItemOnly: this.ShopFormGrp.controls.ShowRentalItemOnly.value,
        itemType: this.from == ItemType.PMSAddOnsRentalItem ? RetailItemType.PMSAddOnsRentalItem : 0

      };
      const response = await this.http.cancellableObservalble<any>(
        {
          method: HttpMethod.Get,
          callDesc: 'GetShopItemByPagination',
          uriParams: queryParams,
          header: undefined,
          body: undefined,
          showError: false,
          host: GlobalConst.Host.retailManagement,
        }, this.notifier).toPromise();

      if (response) {
        this.AllShopItems = response.result;
        this._shopservice.AllShopItems = _.cloneDeep(this.AllShopItems);

        const itemIds = this.AllShopItems.map(x => x.id);
        this.AllActiveShopItems = this.getSellableRetailItems(this.AllShopItems);
        if (this.propertyInfo.UseGiftCardInterface) {
          this.AllActiveShopItems = this._giftCardBusiness.PopulateDefaultGiftCardValues(this.AllActiveShopItems);
        }
        this.loadRetailItemImages(itemIds);

        const isRevenueItemIncluded = this._shopservice.selectedProducts.some(x => x.ItemType && x.ItemType === RetailItemType.RevenueItem);
        this.initializeForm();
        this.handleGiftCardsForReturnFlow(this._shopservice.isReturnWithoutTicket, true);
        //this.RemoveRestrictedItemsBasedOnTypeForAppointment();
        const fixedDescItemTypes = [
          RetailItemType.RevenueItem
          , RetailItemType.Deposit
          , RetailItemType.SpaPackage
          , RetailItemType.SpaServices
          , RetailItemType.AppointmentAddon
        ];
        this._shopservice.selectedProducts.forEach(x => {

          if (!x.MultiPack) {
            const item = this.AllShopItems.filter(a => {
              return a.id === x.ItemId;
            });

            const description = this._giftCardBusiness.AddCardNumberToProductName(x, item);

            if (item && item.length > 0) {
              const allowDescriptionChange = (isRevenueItemIncluded && item[0].retailItemDetail.itemType === RetailItemType.RevenueItem || item[0].retailItemDetail.itemType === RetailItemType.Deposit)
                || (fixedDescItemTypes.includes(item[0].retailItemDetail.itemType));
              x.ExternalPOSItemId = item[0].retailItemDetail.externalPOSId;
              x.ItemDescription = allowDescriptionChange ? x.ItemDescription : description;
              x.ProductName = allowDescriptionChange ? x.ItemDescription : description;
            }
          }
        });
      }
    } catch (ex) {
      console.error(ex);
    }
  }

  categorySelected = [];

  @Input() frompage;
  @Input() buyretailItem;
  selected = 1;
  numbers: any[] = [1, 2, 3, 4, 5];
  viewmore: any = [];
  noofproducts: any = [];
  swiperDisabled: boolean = false;

  product: any;
  categories: any[] = []
  Ticket: Ticket = null;
  isCashDrawerEnabled: boolean = false;
  public config: SwiperConfigInterface = {
    direction: 'horizontal',
    slidesPerView: 4,
    spaceBetween: 10,
    keyboard: {
      enabled: true,
    },
    mousewheel: true,
    navigation: {
      nextEl: '.swiper-button-next',
      prevEl: '.swiper-button-prev',
    },
    slideToClickedSlide: true,
    loop: false,
    breakpoints: {
      1160: {
        slidesPerView: 2,
      },
      1280: {
        slidesPerView: 3,
      },
      1400: {
        slidesPerView: 4,
      },
      1500: {
        slidesPerView: 6,
      }
    }
  };

  async ngOnInit(): Promise<void> {
    this.isQuickLoginEnabledForOverride = this.propertyInfo?.getQuickIdConfig?.[GlobalConst.QuickIdConfigSetting.priceOverride] ?? false;
    this.productId = Number(this._utilities.GetPropertyInfo('ProductId'));
    this.showReturn = this._shopservice.ProductId !== GlobalConst.Product.SNC && !this._retailService.hideReturnWithoutTicketToggle;
    this.isFromTeeTimeAddRetailItem = this._shopservice.ProductId === this.GolfId && this._shopservice.addRetailItemToSource;
    this.route.data.pipe(takeUntil(this.$destroyed)).subscribe((data) => {
      if (Object.keys(data).length > 0) {
        this.frompage = data.frompage ? data.frompage : this.frompage;
        this.buyretailItem = data.buyretailItem ? data.buyretailItem : this.buyretailItem;
      }
    });
    this._retailService.enableAddToReservation = this._shopservice.selectedProducts.length > 0;
    this._utilities.ToggleLoaderWithMessage(true, this.localization.captions.lbl_processing);
    this.isCashDrawerEnabled = !!sessionStorage.getItem('CashDrawerURL');
    await this.LoadItemShowCountConfig();
    this.ngOnChanges();
    await this.MapFunctionality();
    this.LoadOutlets();
    this.LoadReceiptConfigurationAsync();
    this.LoadDiscountConfig();
    this._shopservice.GetMiscConfig().then(async (x) => {
      this.miscSetting = x;
      await this.ApplyMemberDiscountIfApplicable();
      const miscValue = this.miscSetting.find(x => x.switch == GlobalConst.MiscellaneousSwitch.ITEM_NAME_DELETE_OPENITEM_POPULATE).value;
      if (miscValue && miscValue.toString().toLowerCase() === 'true') {
        this.isEmptyOpenItemDesc = true;
      }
      this.isDisplayImagesInShop = this.miscSetting.find(x => x.switch == GlobalConst.MiscellaneousSwitch.DISPLAY_IMAGES_IN_SHOP).value === 'true' ? true : false;
    });

    this.ShopFormGrp = this.Form.group({
      terminal: this._shopservice.SelectedTerminalId.toString(),
      searchtext: '',
      searchtextBarCode: '',
      scanQuantity: '1',
      returnServiceFlag: this._shopservice.isReturnWithoutTicket ? true : false,
      products: this.Form.array([]),
      ShowRentalItemOnly: false,
      startTime: '',
      endTime: ''
    });
    this.startTimeInput = {
      form: this.ShopFormGrp,
      formControlName: 'startTime',
      placeHolder: this.localization.captions.lbl_startTime,
      isTimeRequired: true,
      errorMessage: this.localization.captions.startTimeError,
    }

    this.endTimeInput = {
      form: this.ShopFormGrp,
      formControlName: 'endTime',
      placeHolder: this.localization.captions.lbl_endTime,
      isTimeRequired: true,
      errorMessage: this.localization.captions.endTimeError
    }
    
    if (this.functionalities[GlobalConst.RetailFunctionalities.ShowOutletSelectionFieldInShopScreen]) {
      this.ShopFormGrp.addControl('outlet', new UntypedFormControl(this._retailService.SelectedOutletId))
    }

    if (this.from == this.itemTypeEnum.PMSAddOnsRentalItem) {
      let holeDurationinMins: number = 30;
      let endDateTime: Date = this.localization.AddMins(this.localization.getDate(this._shopservice.golfPlayerInfo[0].time), holeDurationinMins);
      let startTime = this.localization.getDate(this._shopservice.golfPlayerInfo[0].time);
      this.ShopFormGrp.controls.startTime.setValue(this.localization.ConvertDateToTime(this._shopservice.golfPlayerInfo[0].time))
      this.ShopFormGrp.controls.endTime.setValue(this.localization.ConvertDateToTime(endDateTime.toString()));
      this.endTimeInput.minTime = this._utilities.addMinsInTime(this.ShopFormGrp.controls.startTime.value,1);
      this.endTimeInput = { ...this.endTimeInput };
      if(endDateTime.getDate() > startTime.getDate()){
        this.ShopFormGrp.controls.endTime.setValue('');
        this.ShopFormGrp.controls.endTime.markAsDirty();
        this.ShopFormGrp.controls.endTime.markAsTouched();
        this.ShopFormGrp.controls.endTime.updateValueAndValidity();
      }
      await this.GetCourseDetails();
    }

    if (this._shopservice.GoToRetailTransaction) {
      this.selectedTabIndex = 1;
      this._shopservice.retailTransaction.tabState = 1;
    }
    else {
      this.selectedTabIndex = 0;
      this._shopservice.retailTransaction.tabState = 0;
    }
    if (this._shopservice.isfromReturnPage) {
      this.disableOutlet = true;
    } else {
      this._shopservice.isFromRefund = false;
      this._shopservice.RefundTicketTransactionIDs = [];
    }
    this._shopservice.isReturnItem = this._shopservice.isReturnItem ? this._shopservice.isReturnItem : false;
    this.callHttpServiceWithCallBack(GlobalConst.Host.retailManagement, "GetUnitOfMeasures", HttpMethod.Get);
    this.hasAccessToCreateTran = this.breakPoint.CheckForAccess([GlobalConst.RetailBreakPoint.CreateTransaction], false);
    this.CheckForRequireComments();
    setTimeout(() => this.searchTextBarCode.focus(), 1);
    this.clonedProducts = this._retailService.isFromReservation ? _.cloneDeep(this._retailService.selectedProducts) : [];
    if (!this._shopservice.isFromContinueShopping)
      this.LoadLinkedItemsIntoCart();
    let folioConfigSwitches = sessionStorage.getItem('FolioConfigurationSwitches');
    let folioJwt = sessionStorage.getItem('_folioJwt');
    if (folioJwt) {
      this.postfolio_flag = this._shopservice.ProductId == GlobalConst.Product.SPA ? true : false;
      if (!folioConfigSwitches)
        await this.SetFolioConfigurationSwitches();
    }
    this._shopservice.addToTeeTime.pipe(takeUntil(this.$destroyed)).subscribe(() => {
      if (this.from == ItemType.retailItem)
        this.addToBooking();
    });
    this._shopservice.releaseTempHold.pipe(takeUntil(this.$destroyed)).subscribe(async (eve) => {
      if (this.from == ItemType.retailItem)
        await RetailDataAwaiters.ReleaseTempHoldTeeTime(eve)
    });
  }

  async timeChange(e) {
    this.endTimeInput.minTime = this._utilities.addMinsInTime(this.ShopFormGrp.controls.startTime.value,1);
    this.endTimeInput = { ...this.endTimeInput };
    const itemIds = this.isClickedViewMore ? this._shopservice.AllShopItems.map(x => x.id) : this.AllShopItems.map(x => x.id);
    if (this.RetailProducts.length == 0) {
      await this.loadRetailItems();
      this.mapRentalInfo(this.RetailProducts,this.rentalInformation);
    } else {
      await this.getRentalInformation(itemIds);
    }
  }
  updateRentalItemCountByPlayer(player) {
    player.retailItems.map(x => {
      this.updateRentalItemCount(x.ItemId, x.Noofitems, true);
    });
  }

  updateRentalItemCount(ItemId: number, count: number, isremoved: boolean = false) {
    let currentCount = 0;
    if (this.rentalItemCountMap.has(ItemId))
      currentCount = this.rentalItemCountMap.get(ItemId);
    this.rentalItemCountMap.set(ItemId, (isremoved ? currentCount - count : currentCount + count));
    this.RetailProducts.map(x => {
      if (x.data) {
        x.data.map(y => {
          if (y.itemId == ItemId) {
            if (isremoved) {
              y.availableQty += count;
            } else {
              y.availableQty -= count;
            }
          }

        });
      }
    });
  }


  retailItemsMapper(retailItem: any) {
    if (!retailItem.isGroupingKey) {
      this._shopservice.isdisableUpdate = false;
      let outletId = this._shopservice.selectedOutlet ? this._shopservice.SelectedOutletId : 0;

      this.selectedPlayer.filter(x => x.isClicked).forEach((player) => {
        let retailItemCopy = _.cloneDeep(retailItem);
        retailItemCopy.playerId = player.scheduledTeeTimePlayerFee.scheduledTeeTimePlayerId;
        retailItemCopy.playerName = player.fullName;
        retailItemCopy.isFromTeeTimeAddRetailItem = true;
        if (retailItem.ItemType == RetailItemType.PMSAddOnsRentalItem) {
          retailItemCopy.rentalStartTime = this.localization.convertDateTimeToAPIDateTime(this.localization.AddTimeToDate(player.time, this.localization.TimeToDateAllformat(this.ShopFormGrp.get('startTime')?.value)));
          let endTimeWithoutBuffer: Date = this.localization.AddTimeToDate(player.time, this.localization.TimeToDateAllformat(this.ShopFormGrp.get('endTime')?.value));
          retailItemCopy.rentalEndTimeWithoutBuffer = this.localization.convertDateTimeToAPIDateTime(endTimeWithoutBuffer);
          retailItemCopy.rentalEndTime = this.localization.AddMins(endTimeWithoutBuffer, retailItem.bufferMins);
          retailItemCopy.rentalEndTime= this.localization.convertDateTimeToAPIDateTime(retailItemCopy.rentalEndTime);
          this.updateRentalItemCount(retailItemCopy.ItemId, retailItemCopy.Noofitems)

        }
        let grpList = this.groupList.find(x => x.groupId == player.scheduledTeeTimePlayerFee.scheduledTeeTimePlayerId);
        if (grpList) {
          grpList.retailItems.push(retailItemCopy);
          if (retailItem.ItemType == RetailItemType.PMSAddOnsRentalItem) {
            let rentalGrpList = this._shopservice.rentalItemGroupList.find(x => x.groupId == player.scheduledTeeTimePlayerFee.scheduledTeeTimePlayerId);
            if (rentalGrpList) {
              rentalGrpList.retailItems.push(retailItemCopy);
            }
          }
        }
        else {
          let playerItem = {
            groupName: `${this.localization.captions.teetime.p}${player.playPos} - ${player.fullName}`,
            groupId: player.scheduledTeeTimePlayerFee.scheduledTeeTimePlayerId,
            outletId: outletId,
            retailItems: [retailItemCopy]
          }
          this.groupList.push(playerItem)
          if (retailItem.ItemType == RetailItemType.PMSAddOnsRentalItem) {
            this._shopservice.rentalItemGroupList.push(cloneDeep(playerItem));
          }
          this.removedGroupList = this.removedGroupList.filter(x => x.groupId !== playerItem.groupId);
        }
      });
      this._shopservice.selectedProducts = this.groupList.flatMap(x => x.retailItems);
    }
  }

  async onPlayerSelect(player) {
   
    if (this.from == ItemType.retailItem) {
      player.isClicked = !player.isClicked
    }
    else if (this.from == ItemType.PMSAddOnsRentalItem) {
      this.playerDetails.forEach(x => {
        x.isClicked = false;
      })
      player.isClicked = !player.isClicked;
      if(player.holes == HoleTypes.avg9Holes){
       this.holeDurationinMins = this.courses?.avgPlay9Holes;

      }
      else if(player.holes == HoleTypes.avg18Holes){
        this.holeDurationinMins = this.courses?.avgPlay18Holes;
      }
      if(this.holeDurationinMins == 0)
      {
        this.holeDurationinMins = HoleTypes.defaultHoleDuration;
      }
      let endDateTime: Date = this.localization.AddMins(this.localization.getDate(this._shopservice.golfPlayerInfo[0].time), this.holeDurationinMins);
      let startTime = this.localization.getDate(this._shopservice.golfPlayerInfo[0].time);
      this.ShopFormGrp.controls.startTime.setValue(this.localization.ConvertDateToTime(this._shopservice.golfPlayerInfo[0].time));
      if(endDateTime.getDate() > startTime.getDate()){
        this.ShopFormGrp.controls.endTime.markAsTouched();
        this.ShopFormGrp.controls.endTime.setValue('');
        this.RetailProducts = [];
      }else{
        this.ShopFormGrp.controls.endTime.setValue(this.localization.ConvertDateToTime(endDateTime.toString()));
      }
      this.endTimeInput.minTime = this._utilities.addMinsInTime(this.ShopFormGrp.controls.startTime.value,1);
      this.endTimeInput = { ...this.endTimeInput };
      const itemIds = this.isClickedViewMore ? this._shopservice.AllShopItems.map(x => x.id) : this.AllShopItems.map(x => x.id);
      this.getRentalInformation(itemIds);
    }
    if (player.isClicked) {
      if (!this.selectedPlayer.some(x => x.scheduledTeeTimePlayerId == player.scheduledTeeTimePlayerId))
        this.selectedPlayer.push(player)
    }
    else {
      this.selectedPlayer = this.selectedPlayer.filter(x => x.playPos != player.playPos);
    }
    if (this.selectedPlayer?.length == this.playerDetails.filter(x => !(x.isPaidPlayer || x.isCheckedOut))?.length) {
      this.isSelectAllClicked = true;
    }
    else {
      this.isSelectAllClicked = false;
    }
  }

  selectAllPlayers() {
    this.isSelectAllClicked = true;
    this.playerDetails.forEach(x => {
      x.isClicked = !(x.isPaidPlayer || x.isCheckedOut);
    })
    this.selectedPlayer = this.playerDetails.filter(x => x.isClicked);
  }
  deSelectAllPlayers() {
    this.isSelectAllClicked = false;
    this.playerDetails.forEach(x => {
      x.isClicked = false;
    })
    this.selectedPlayer = this.playerDetails.filter(x => x.isClicked);
  }

  //remove single item in cart when redirected from tee time slider
  removeGolfProduct(product: any, index: number, group: any) {
    let warningMsg = this.localization.captions.shop.RemoveItemDeletionConfirmmsg + '"' + product.ProductName + '"' + '?'
    this._utilities.showAlert(warningMsg, AlertType.Warning, ButtonType.YesNo, (res) => {
      if (res === AlertAction.YES) {
        this._shopservice.isdisableUpdate = false;
        group.retailItems.splice(index, 1)[0];
        if (this.from == ItemType.PMSAddOnsRentalItem) {
          if (product.rentalItemPurchaseId > 0)
            this._shopservice.removedRentalItems.push(product);
          this.updateRentalItemCount(product.ItemId, product.Noofitems, true);
          let rentalGroupList = this._shopservice.rentalItemGroupList.find(x => x.groupId == group.groupId);
          if (rentalGroupList)
            rentalGroupList.retailItems = _.cloneDeep(group.retailItems);
        }
        this._shopservice.selectedProducts = this.groupList.flatMap(x => x.retailItems);
        if (group.retailItems.length == 0)
          this.removeGroup(group);
      }
    });
  }


  removeGroupWithWarning(player) {
    let warningMsg = this.localization.captions.shop.RemovePlayerWithItems + ' "' + player.groupName + '"' + '?'
    this._utilities.showAlert(warningMsg, AlertType.Warning, ButtonType.YesNo, (res) => {
      if (res === AlertAction.YES) {
        this.removeGroup(player);
      }
    });
  }

  removeGroup(player) {
    if (this.from == ItemType.PMSAddOnsRentalItem) {
      this.updateRentalItemCountByPlayer(player);
      player.retailItems.map(x => {
        if (x.rentalItemPurchaseId > 0)
          this._shopservice.removedRentalItems.push(x)
      });
      player.retailItems = [];
      this._shopservice.removedRentalItemGroupList.push(player);
      this._shopservice.rentalItemGroupList = this._shopservice.rentalItemGroupList.filter(group => group.groupId != player.groupId)
    }
    player.retailItems = [];
    this._shopservice.isdisableUpdate = false;
    this.removedGroupList.push(player);
    this.groupList = this.groupList.filter(group => group.groupId != player.groupId);
    this._shopservice.selectedProducts = this.groupList.flatMap(x => x.retailItems);
    if (this.updateItemsToAppt && this.groupList.length == 0)
      this._shopservice.isdisableUpdate = true;
  }

  async MapFunctionality() {
    this.functionalities = await this.func.getRetailFunctionality();
    console.log(this.functionalities);
    this._showMultiPackRedeem = this.functionalities[GlobalConst.RetailFunctionalities.ShowMultiPackRedeemButtonInShopScreen];
    if (!this.functionalities[GlobalConst.RetailFunctionalities.ShowOutletSelectionFieldInShopScreen] &&
      this.defaultOutlet === 0) {
      this.defaultOutlet = await this.func.getDefaultOutlet();
      if (this.defaultOutlet > 0) {
        this.propertyInfo.SetDefaultOutlet(this.defaultOutlet);
      }
    }
  }

  CheckForRequireComments() {
    if (this._shopservice.selectedProducts.length > 0 && this._shopservice.selectedProducts.some(x => x.isRequireComments)) {
      var requireCommentsProducts = this._shopservice.selectedProducts.filter(x => x.isRequireComments && (x.itemComments || '').trim() == '');
      requireCommentsProducts.forEach(x => {
        const dialogRef: MatDialogRef<DialogOverviewExampleDialog> = this._shopservice.openEnterCommentDialog(x);

        dialogRef.afterClosed()
          .pipe(takeUntil(this.$destroyed))
          .subscribe((res: enterCommentPrompt) => {
            this.searchTextBarCode.focus();
            if (res.res === GlobalConst.ButtonOptions.Save && res.comment) {
              x.itemComments = res.comment;
            }

          });
      });
    }
  }

  async LoadItemShowCountConfig() {
    let count = this.propertyInfo.GetShopItemLoadCount() || 0;
    if (Number(count) <= 0) {
      const config: any = await this._propertySettingDataService.GetShopItemListCountConfigurationSetting() || {};
      count = config.value;
    }
    this.pageLength = Number.parseInt(count.toString()) || this.pageLength;
  }

  async LoadReceiptConfigurationAsync() {
    if (this._shopservice.SelectedOutletId && this._shopservice.SelectedOutletId > 0) {
      this.receiptService.SetReceiptConfiguration(this._shopservice.SelectedOutletId);
    }
  }

  async LoadDiscountConfig() {
    await this.applyDiscountService.getDiscountsForItem(0, true);
    await this.FillDiscountConfigEligibility();
  }

  async FillDiscountConfigEligibility() {
    const categoryDiscount: RetailCategoryAndDiscountTypeDetails[] = this.applyDiscountService.CategoryDiscountList || [];
    this.ItemEligibleForDiscount = {};
    categoryDiscount.filter(x => x.eligibleForDiscount).forEach(x => { this.ItemEligibleForDiscount[x.retailCategoryId] = x.eligibleForDiscount;
      this.ItemEligibleForCostOrCostPlus[x.retailCategoryId] = x.eligibleForCostOrCostPlus ?? false;
     });
    this._shopservice.ItemEligibleForDiscount = this.ItemEligibleForDiscount;
    this._shopservice.ItemEligibleForCostOrCostPlus = this.ItemEligibleForCostOrCostPlus;
  }

  async ngOnChanges() {

    const hasOutletAccess = await this.checkUserHasOutletAccess();
    if (!hasOutletAccess) {
      this._shopservice.SelectedOutletId = 0;
    }
    this.checkscrollBar(true);
    if (this.ShopFormGrp) {
      if (this.functionalities[GlobalConst.RetailFunctionalities.ShowOutletSelectionFieldInShopScreen]) {
        this.ShopFormGrp.controls['outlet']?.setValue(this._shopservice.SelectedOutletId);
      }
      this.ShopFormGrp.controls['terminal'].setValue(this._shopservice.SelectedTerminalId.toString());
    }
    this.getAllQuickSaleCategories();
    this.FillDiscountConfigEligibility();
    this.setTransactionActionRule();
  }

  async setTransactionActionRule() {
    if (this.propertyInfo.UseRetailInterface && (this._shopservice.reOpenTransaction || this._shopservice.correctTransaction || this._shopservice.isfromReturnPage)) {
      this._shopservice.isfromReturnPage = false;
      const body = [this._shopservice.transactionId];
      const response: BaseResponse<any> = await this.InvokeServiceCallAsync('GetTransactions', GlobalConst.Host.retailPOS, HttpMethod.Put, '', body);
      if (response && response.successStatus && response.result && response.result.length > 0) {
        this.disableOutlet = true;
        this._shopservice.SelectedOutletId = response.result[0].transactionData.outletId;
        this._shopservice.SelectedTerminalId = response.result[0].transactionData.terminalId;
        if (this.functionalities[GlobalConst.RetailFunctionalities.ShowOutletSelectionFieldInShopScreen]) {
          this.ShopFormGrp.controls['outlet'].setValue(this._shopservice.SelectedOutletId);
        }
        this.ShopFormGrp.controls['terminal'].setValue(this._shopservice.SelectedTerminalId.toString());
        this.loadOutletTerminals();
      }
    }
  }

  ngOnDestroy(): void {
    this._shopservice.rentalItemGroupList = [];
    this._shopservice.removedRentalItemGroupList = [];
    this._shopservice.SelectedItemDiscount = [];
    this.ResetServiceFlagsAfterLoad();
    this.DiscardTicket();
    this.ResetTeeTimeRetailItems();
    this.$destroyed.next(true);
    this.$destroyed.complete();

  }

  ResetTeeTimeRetailItems() {
    if (this.isFromTeeTimeAddRetailItem) {
      this.groupList = [];
      this._shopservice.selectedProducts = [];
      this._shopservice.groupedRetailItems = [];
      this._shopservice.addRetailItemToSource = false;
    }
  }

  changeProductsView(productId: number, index: number, type, updateView: boolean = true): void {
    this.quickSaleCateroryId = productId;
    if (this.noOfProducts[index] != this.RetailProducts[index].data.length) {
      this.noOfProducts[index] = this.RetailProducts[index].data.length;
      this.noOfProducts = { ...this.noOfProducts }
      this.showViewLess[index] = true;
      if (!this.RetailProducts[index].viewMoreclicked) {
        this.RetailProducts[index].isViewMoreEnable = true;
      }
    }
    else {
      this.checkProductsWidth(index);
    }
    this.quickSaleCateroryId = 0;
    this.RetailProducts.forEach((res) => {
      if (res.data && res.data.length && type === 'viewmore' && res.id === productId) {
        if (!res.viewMoreclicked) {
          res.isViewMoreEnable = true;
        }
      } else if (type === 'viewless' && res.id === productId) {
        res.isViewMoreEnable = false;
      }
    });
    if(updateView){
      this.UpdateViewMoreButtonState(index);
    }
  }

  AddSelectedRetailItemWithOutCommission(products, rowindex: number, index: number, quantity: number, isOpenPricePopupShown?: boolean): void {
    if (!this.CheckDiscountAmount(products, quantity)) {
      this._utilities.ShowErrorMessage(this.localization.captions.common.Warning, this.captions.DiscountAmountGreaterThanActual, GlobalConst.ButtonType.YesNo, this.AmountExceedWithOutCommissionCallback.bind(this), [products, rowindex, index, isOpenPricePopupShown]);
      return;
    }
    this.addWithoutCommission(products, rowindex, index, isOpenPricePopupShown);
  }

  AmountExceedWithOutCommissionCallback(result: string, extraParams) {
    if (result.toUpperCase() == "YES") {
      this.addWithoutCommission(extraParams[0], extraParams[1], extraParams[2], extraParams[3]);
    }
  }

  async addWithoutCommission(products: ItemForShop, rowindex: number, index: number, isOpenPricePopupShown: boolean) {
    if (!isOpenPricePopupShown) {
      this.openScaledItemsDialog(products, rowindex, index, false);
    } else {
      await this.AddProductItem(products, rowindex, index, products.price);
    }
    setTimeout(() => this.searchTextBarCode.focus(), 1);
  }

  addProduct(products: ItemForShop, rowindex: number, index: number, isOpenPricePopupShown: boolean, isEnterCommentPopupShown: boolean = false): void {
    if (this.isFromTeeTimeAddRetailItem && this.selectedPlayer.length == 0) {
      this._utilities.ShowErrorMessage(this.localization.captions.common.Warning, this.captions.PleaseSelectPlayer);
      return;
    }
    this.searchTextBarCode.focus();
    if (!this.hasAccessToCreateTransaction() || !this.ShopFormGrp.value.products[rowindex][index]) {
      return;
    }

    let NoofProducts = parseInt(this.ShopFormGrp.value.products[rowindex][index].itemcount, 10);
    NoofProducts = NoofProducts ? NoofProducts : 0;
    if (!NoofProducts || NoofProducts <= 0) {
      return;
    }

    let productPrice = products.price;
    if (products.isOpenItem && !products.isGiftCardItem && !isOpenPricePopupShown) {
      if (this.isEmptyOpenItemDesc) {
        products.itemDescription = "";
      }
      const dialogRef = this.dialog.open(DialogOverviewExampleDialog, {
        height: 'auto',
        width: '750px',
        data: { Type: "OPENPRICE", headername: this.localization.captions.shop.OpenPricedItem, data: products, quantity: this.ShopFormGrp.value.products[rowindex][index].itemcount, closebool: true, buttonType: GlobalConst.ButtonType.SaveCancel },
        panelClass: 'small-popup',
        disableClose: true,
        hasBackdrop: true
      });
      dialogRef.afterClosed().pipe(takeUntil(this.$destroyed)).subscribe((res: shopPricePrompt) => {
        this.searchTextBarCode.focus();
        if (res.res == GlobalConst.ButtonOptions.Save && res.price) {
          productPrice = this.localization.currencyToSQLFormat(res.price.toString());
          const productClone = _.cloneDeep(products);
          productClone.price = productPrice;
          productClone.itemDescription = productClone.title = res.itemName;
          if (productClone.isRequireComments && !isEnterCommentPopupShown) {
            this.openEnterCommentDialog(productClone, rowindex, index, NoofProducts, isOpenPricePopupShown, true)

          } else {
            this.AddSelectedRetailItemWithOutCommission(productClone, rowindex, index, NoofProducts, isOpenPricePopupShown);
          }

        } else {
          this.resetBarCodeSearchFilter()
        }
      });
    } else if (products.isGiftCardItem && !isOpenPricePopupShown && products.isOpenItem) {
      if (this.CheckMaxCardsPerTransactionLimitExceeded(products.GiftCardTransactionItem)) { return; }
      const isDefaultGCOpenItem = !products.isGiftCardCustomValueItem;
      const giftCardData: IssueGiftCardPopupModel = {
        Amount: isDefaultGCOpenItem ? 0 : products.price,
        openItem: isDefaultGCOpenItem,
        HandleInfo: ""
      };
      this._giftCardBusiness.ShowIssueGiftCardModal(giftCardData, this.AddGiftCardToCart.bind(this), [products, rowindex, index]);
    } else if (products.isRequireComments && !isEnterCommentPopupShown && !products.isGiftCardItem) {
      this.openEnterCommentDialog(products, rowindex, index, NoofProducts, isOpenPricePopupShown, true)
    }
    else if (products.isGiftCardItem && !products.isOpenItem) {
      this._utilities.ShowErrorMessage(this.localization.captions.common.Warning, this.captions.OpenItemValidationforGiftCard);
      return;
    }
    else {
      this.AddSelectedRetailItemWithOutCommission(products, rowindex, index, NoofProducts, isOpenPricePopupShown);
    }
    if (isOpenPricePopupShown) this.checkscrollBar(true);
    if (this.ShopFormGrp.controls.searchtextBarCode.value)
      this.resetBarCodeSearchFilter();
    this.ShopFormGrp.markAsDirty();
  }

  async AddProductItem(products: ItemForShop, rowindex: number, index: number, price: number) {
    const item = this._shopservice.AllShopItems.filter(x => { return x.id == products.itemId });

    if (item && item[0].retailItemDetail.isCommissionable && item[0].retailItemDetail.isCommissionRequired) {
      await this.onOptionChange(products, rowindex, index);
      return;
    }

    let NoofProducts = parseInt(this.ShopFormGrp.value.products[rowindex][index].itemcount);
    if (this.ShopFormGrp.controls.searchtextBarCode.value) {
      NoofProducts = parseInt(this.ShopFormGrp.controls.scanQuantity.value);
    }
    NoofProducts = NoofProducts ? NoofProducts : 0;
    if (this.from == this.itemTypeEnum.PMSAddOnsRentalItem) {
      NoofProducts = NoofProducts > products.availableQty ? products.availableQty : NoofProducts;
    }
    if (NoofProducts <= 0) return;
    this.product = this._shopservice.selectedProducts.find(result => result.ItemId == products.itemId && !result.isPackagedItem);
    if (products.isGroupingKey) {
      this._shopservice.PackageGroupId = this._shopservice.PackageGroupId + 1;
    }

    if (this._shopservice.isReturnWithoutTicket) {
      price = price * -1;
    }

    let gratuityValue = 0;
    let serviceChargeValue = 0;
    await this.func.getRetailFunctionality().then(res => {
      this.functionalities = res;
      if (this.functionalities.ApplyRevisedMultiPackLogic) {
        this.isApplyRevisedMultiPackLogic = true;
      }
    });
    if (!this.isApplyRevisedMultiPackLogic || !item[0].retailItemDetail.isMultiPack) {
      gratuityValue = item[0].retailItemDetail.gratuity;
      serviceChargeValue = item[0].retailItemDetail.serviceCharge;
      gratuityValue = item[0].retailItemDetail.isMultiPack ? item[0].retailItemDetail.multiGratuity : gratuityValue;
      serviceChargeValue = item[0].retailItemDetail.isMultiPack ? item[0].retailItemDetail.multiServiceCharge : serviceChargeValue;
    }
    const itemValue = price * NoofProducts;
    this.saveValues = {
      commission: [],
      Gratuity: [{ Id: 0, TransactionDetailId: 0, TherapistId: 0, Amount: 0, Percentage: 0, PercentageId: 0, PercOrAmount: 0, gratuity: 0 }],
      ServiceCharge: [{ Id: 0, TransactionDetailId: 0, TherapistId: 0, Amount: 0, Percentage: 0, PercentageId: 0, PercOrAmount: 0, ServiceCharge: 0 }]
    };

    this.saveValues.Gratuity[0].gratuity = Number((gratuityValue / 100 * itemValue).customToFixed());

    this.saveValues.Gratuity[0].PercOrAmount = 1;
    this.saveValues.Gratuity[0].Amount = Number((gratuityValue / 100 * itemValue).customToFixed());
    this.saveValues.Gratuity[0].Percentage = gratuityValue;

    this.saveValues.ServiceCharge[0].ServiceCharge = Number((gratuityValue / 100 * itemValue).customToFixed());
    this.saveValues.ServiceCharge[0].PercOrAmount = 1;
    this.saveValues.ServiceCharge[0].Amount = Number((serviceChargeValue / 100 * itemValue).customToFixed());
    this.saveValues.ServiceCharge[0].Percentage = serviceChargeValue;

    const itemDiscount = this._shopservice.SelectedItemDiscount.find(x => x.itemId == products.itemId);
    let discount: number = 0;
    let discountTypeId: number = 0;
    let DiscountPercentage: number = 0;
    if (itemDiscount) {
      let itemPrice = this._shopservice.isReturnWithoutTicket ? price * -1 : price;
      discount = itemDiscount.discountPercentage > 0 ? this._utilities.MidPointRoundOffTwo((NoofProducts * itemPrice * itemDiscount.discountPercentage / 100)) : itemDiscount.isCostDiscount || itemDiscount.isCostPlusDiscount ? itemDiscount.discountAmount * NoofProducts : itemDiscount.discountAmount;
      discountTypeId = itemDiscount.isCostPlusDiscount ? GlobalConst.DiscountType.CostPlus : itemDiscount.isCostDiscount ? GlobalConst.DiscountType.Cost : itemDiscount.discountId;
      DiscountPercentage = (itemDiscount.discountPercentage > 0) ? itemDiscount.discountPercentage : 0;
    }
    let itemLineNumber = this._shopservice.GetNextLineNumber()
    const product: SelectedProducts = {
      ItemId: products.itemId,
      ExternalPOSItemId: products.externanId,
      ItemDescription: (products.title || products.itemDescription),
      ItemNumber: String(products.itemNumber),
      ItemType: products.itemType,
      SalesPrice: 0,
      ServiceId: 0,
      ProductName: products.title,
      ProductPrice: price,
      Noofitems: NoofProducts,
      Discount: Math.abs(discount),
      isModificationRestricted: false,
      DiscountTypeId: discountTypeId,
      DiscountPercentage: DiscountPercentage,
      category: products.category,
      isCommissionable: item[0].retailItemDetail.isCommissionable,
      isCommissionRequired: item[0].retailItemDetail.isCommissionRequired,
      Commission: [],
      Gratuity: gratuityValue > 0 ? this.saveValues.Gratuity : null,
      ServiceCharge: serviceChargeValue > 0 ? this.saveValues.ServiceCharge : null,
      isGroupingKey: products.isGroupingKey,
      isPackagedItem: false,
      PackageItemId: 0,
      MultiPack: products.multipack,
      ClientMultiPackId: 0,
      PackageGroupId: products.isGroupingKey ? this._shopservice.PackageGroupId : 0,
      isOpenPricedItem: products.isOpenItem,
      isGiftCardItem: products.isGiftCardItem,
      GiftCardTransactionItem: products.GiftCardTransactionItem,
      scaledUnits: products.scaledUnit,
      unitOfMeasureId: products.unitOfMeasureId,
      uom: products.uom,
      isReturn: this._shopservice.isReturnWithoutTicket,
      LineNumber: itemLineNumber,
      isRequestName: products.isRequestName,
      isAllowPriceOverride: products.isAllowPriceOverride,
      itemComments: products.itemComments,
      costPrice: products.costPrice,
      marginPercentage: products.marginPercentage,
      allowEarn: products.allowEarn,
      discountComments: itemDiscount ? itemDiscount.discountComment : '',
      discountReason: itemDiscount ? itemDiscount.discountReason : 0,
      roomIndex: this.roomNumber ? Number(this.roomNumber) - 1 : 0,
      rentType: products.itemType === RetailItemType.RentalItem ? 1 : 0,
      period: products.itemType === RetailItemType.RentalItem ? 1 : 0,
      perHourRentalRate: products.perHourRentalRate,
      perDayRentalRate: products.perDayRentalRate,
      MemberPrice: products.memberPrice,
      retailItemType: products.itemType,
      linkedItemLineNumber: 0,
      customFee: null,
      appliedCustomFee: [],
      sourceType: GlobalConst.CustomFeeSourceType.RetailItem,
      sourceTypeId: itemLineNumber,
      bufferTime: products.bufferTime,
      bufferMins: products.bufferTime,
      linkedRetailItemGuid: this._utilities.generateGUID(),
      useInventory: item[0].retailItemDetail.useInventory,
      isCostDiscountApplied: itemDiscount?.isCostDiscount ?? false,
      isCostPlusDiscountApplied: itemDiscount?.isCostPlusDiscount ?? false,
      costPlusDiscountDetails: itemDiscount?.costPlusDiscountDetails,      
      DiscountName: itemDiscount?.isCostDiscount ? GlobalConst.DiscountName.Cost
        : itemDiscount?.isCostPlusDiscount ? this.localization.replacePlaceholders(GlobalConst.DiscountName.CostPlus, ["value"], 
          [itemDiscount.costPlusDiscountDetails.isPercentage ? `${itemDiscount.costPlusDiscountDetails.value}%` : `${this.localization.currencySymbol}${itemDiscount.costPlusDiscountDetails.value}`])
          : '',
    };

    //mapping item to golf player
    if (this.isFromTeeTimeAddRetailItem)
      this.retailItemsMapper(product);

    // Splitting due to new Reservation Shop Logic
    if (this._retailService.isFromReservation) {
      this.showRentalItemShopScreen(product);
    } else if (!this.isFromTeeTimeAddRetailItem) {
      this.emitProductData(product);
    }
    if (products.isGroupingKey) {
      const packagedItems = await this.getPackageItemsforItem(item);

      const isDiscountExceeds = item[0].packagedItem.some(pi => {
        const packagedItemDetail = packagedItems.find(x => { return x.id == pi.parentItemId });
        let packagedItemdiscount = 0;
        if (itemDiscount && !this._shopservice.isReturnWithoutTicket && this.ItemEligibleForDiscount[packagedItemDetail.retailItemDetail.category] && !itemDiscount.isCostDiscount && !itemDiscount.isCostPlusDiscount) {
          packagedItemdiscount = itemDiscount.discountPercentage > 0 ? this._utilities.MidPointRoundOffTwo(NoofProducts * pi.price * itemDiscount.discountPercentage / 100) : itemDiscount.discountAmount;
        }
        return (NoofProducts * pi.price) < packagedItemdiscount;
      });

      if (isDiscountExceeds) {
        this._utilities.ShowErrorMessage(this.localization.captions.common.Warning,
          this.captions.DiscountAmountGreaterThanActual, GlobalConst.ButtonType.YesNo,
          this.packagedItemDiscountExceedsCallback.bind(this),
          [item, itemDiscount, NoofProducts, products, packagedItems]);
        return;
      }
      this.AddPackagedItems(packagedItems, item, itemDiscount, NoofProducts, products);
    }
    this.SetCreditOrDebit();
    this.checkscrollBar(true);
    this.resetUpdatedValue(products)
  }

  resetUpdatedValue(data) {
    if(data && data.backupPrice) {
      data.price = _.cloneDeep(data.backupPrice);
      data.dispPrice = _.cloneDeep(data.backupPrice);
      data.isEditTriggered = false;
      data.isPriceOverridded = false;
      data = _.cloneDeep(data);
    }
  }

  onPriceOverrideBlur(data) {
    if(data) {
      data.isEditTriggered = false;
    data['dispPrice'] = _.cloneDeep(data.price);
    data['updatedPrice'] = _.cloneDeep(data.price);
    if(data['updatedPrice'] != data['backupPrice']) 
      data.isPriceOverridded = true;
    data = _.cloneDeep(data);
    }
  }

  transcustomFeeMapper(customfeeconfig: CustomFeeConfig) {
    return {
      id: 0,
      customFeeId: customfeeconfig.id,
      isOverWritten: customfeeconfig.isOverWritten ?? false,
      value: customfeeconfig.value,
      isPercentage: customfeeconfig.isPercentage
    } as TransactionCustomFee
  }

  showRentalItemShopScreen(product: SelectedProducts) {
    const dialogRef = this.dialog.open(ShopAddItemComponent, {
      panelClass: 'ag_dialog--lg',
      data: {
        products: product,
        stayarrivalDate: this._retailService.arrivalDate,
        staydepartureDate: this._retailService.departureDate
      },
    });
    dialogRef.afterClosed()
      .pipe(takeUntil(this.$destroyed))
      .subscribe((res) => {
        if (res.from === this.dialogCloseEnum.Action) {
          product.productDetails = res.data;
          this.emitProductData(product);
        }
      });
  }

  emitProductData(product: SelectedProducts) {
    this._shopservice.selectedProducts.push(product);
    this._shopservice.selectedProducts = [...this._shopservice.selectedProducts];
    if (this._shopservice.selectedProducts && this._shopservice.selectedProducts.length > 0) {
      this.barcodeItemLoader = false;
    }
    this._retailService.enableAddToReservation = this._shopservice.selectedProducts.length > 0;
  }

  async getPackageItemsforItem(item: any) {
    if (item[0].packagedItem && item[0].packagedItem.length) {
      const parentItemIds = item[0].packagedItem.map(x => x.parentItemId);
      const response = await this.InvokeServiceCallAsync('GetRetailItemDetailedInfoList', GlobalConst.Host.retailManagement, HttpMethod.Put, '', parentItemIds);

      return response.result;
    } else {
      return [];
    }
  }

  AddPackagedItems(packagedItems: any, item: any, itemDiscount: ItemDiscount, NoofProducts: number, products: any) {
    const groupingId = item[0].id;
    const groupingItemDescription = item[0].retailItemDetail.itemDescription;
    const groupingUniqueIdentifier = this._utilities.generateGUID();
    let nonApplicableForCostPlus = [];
    for (const packagedItem of item[0].packagedItem) {
      const packagedItemDetail = packagedItems.find(x => { return x.id == packagedItem.parentItemId });
      if (packagedItemDetail.retailItemDetail.isActive && this._utilities.GetDateWithoutTime(this._utilities.getDate(this.propertyInfo.CurrentDate)) >= this._utilities.GetDateWithoutTime(this._utilities.getDate(packagedItem.startDate))
        && this._utilities.GetDateWithoutTime(this._utilities.getDate(this.propertyInfo.CurrentDate)) <= this._utilities.GetDateWithoutTime(this._utilities.getDate(packagedItem.endDate))) {
        this.product = this._shopservice.selectedProducts.find(result => result.ItemId == packagedItem.parentItemId && result.PackageItemId == packagedItem.retailItemId);

        let packagedItemdiscount = 0;
        let packagedItemdiscountTypeId = 0;
        let packagedItemDiscountPercentage = 0;
        let packagedItemDiscountName = '';
        if (itemDiscount && !this._shopservice.isReturnWithoutTicket && this.ItemEligibleForDiscount[packagedItemDetail.retailItemDetail.category] && this.ItemEligibleForCostOrCostPlus[packagedItemDetail.retailItemDetail.category]) {
          packagedItemdiscountTypeId = itemDiscount.discountId;
          if (itemDiscount.isCostPlusDiscount) {
            let total = 0;
            let amount = itemDiscount.costPlusDiscountDetails.value;
            total = itemDiscount.costPlusDiscountDetails.isPercentage ? Number((packagedItemDetail.retailItemDetail.costPrice + (packagedItemDetail.retailItemDetail.costPrice * amount) / 100).customToFixed()) : Number((packagedItemDetail.retailItemDetail.costPrice + amount).customToFixed());
            if (total > packagedItemDetail.retailItemDetail.salesPrice) {
              nonApplicableForCostPlus.push(packagedItem);
              continue;
            }
            itemDiscount.discountAmount = (packagedItemDetail.retailItemDetail.salesPrice - total) * Number(NoofProducts);
            itemDiscount.isPercentage = false;
            packagedItemdiscountTypeId = GlobalConst.DiscountType.CostPlus;
            packagedItemDiscountName = this.localization.replacePlaceholders(GlobalConst.DiscountName.CostPlus, ["value"], 
              [itemDiscount.costPlusDiscountDetails.isPercentage ? `${amount}%` : `${this.localization.currencySymbol}${amount}`]);
          }
          else if (itemDiscount.isCostDiscount) {
            itemDiscount.discountAmount = Number(((packagedItemDetail.retailItemDetail.salesPrice - packagedItemDetail.retailItemDetail.costPrice) * Number(NoofProducts)).customToFixed());
            itemDiscount.isPercentage = false;
            packagedItemdiscountTypeId = GlobalConst.DiscountType.Cost;				
            packagedItemDiscountName = GlobalConst.DiscountName.Cost;
          }
          packagedItemdiscount =  itemDiscount.discountPercentage > 0 ? this._utilities.MidPointRoundOffTwo(NoofProducts * packagedItem.price * itemDiscount.discountPercentage / 100) : itemDiscount.discountAmount;
          
          packagedItemDiscountPercentage = (itemDiscount.discountPercentage > 0) ? itemDiscount.discountPercentage : 0;
        }

        const product: SelectedProducts = {
          ItemId: packagedItemDetail.retailItemDetail.id,
          ExternalPOSItemId: packagedItemDetail.retailItemDetail.externalPOSId,
          ItemDescription: packagedItemDetail.retailItemDetail.itemDescription,
          ItemType: packagedItemDetail.retailItemDetail.itemType,
          ItemNumber: packagedItemDetail.retailItemDetail.itemNumber,
          SalesPrice: 100,
          ServiceId: 0,
          ProductName: packagedItemDetail.retailItemDetail.itemDescription,
          ProductPrice: this._shopservice.isReturnWithoutTicket ? packagedItem.price * -1 : packagedItem.price,
          Noofitems: NoofProducts,
          Discount: packagedItemdiscount,
          DiscountPercentage: packagedItemDiscountPercentage,
          DiscountTypeId: packagedItemdiscountTypeId,
          category: packagedItemDetail.retailItemDetail.category,
          isCommissionable: packagedItemDetail.retailItemDetail.isCommissionable,
          isCommissionRequired: packagedItemDetail.retailItemDetail.isCommissionRequired,
          Commission: [],
          isGroupingKey: packagedItemDetail.retailItemDetail.isGroupingKey,
          isPackagedItem: true,
          isModificationRestricted: false,
          PackageItemId: packagedItem.retailItemId,
          MultiPack: false,
          ClientMultiPackId: 0,
          PackageGroupId: this._shopservice.PackageGroupId,
          isOpenPricedItem: products.isOpenItem,
          scaledUnits: products.scaledUnit,
          unitOfMeasureId: products.unitOfMeasureId,
          uom: products.uom,
          isReturn: this._shopservice.isReturnWithoutTicket,
          LineNumber: this._shopservice.GetNextLineNumber(),
          costPrice: packagedItemDetail.retailItemDetail.costPrice,
          marginPercentage: packagedItemDetail.retailItemDetail.marginPercentage,
          itemComments: products.itemComments,
          allowEarn: packagedItemDetail.retailItemDetail.allowEarn,
          discountComments: itemDiscount ? itemDiscount.discountComment : '',
          discountReason: itemDiscount ? itemDiscount.discountReason : 0,
          GroupingParentId: groupingId,
          GroupingUniqueIdentifier: groupingUniqueIdentifier,
          GroupingItemDescription: groupingItemDescription,
          appliedCustomFee: [],
          isCostDiscountApplied: itemDiscount?.isCostDiscount ?? false,
          isCostPlusDiscountApplied: itemDiscount?.isCostPlusDiscount ?? false,
          costPlusDiscountDetails: itemDiscount?.isCostPlusDiscount ? itemDiscount.costPlusDiscountDetails : null,
          DiscountName: packagedItemDiscountName
        };
        if (this.isFromTeeTimeAddRetailItem)
          this.retailItemsMapper(product);
        else
          this._shopservice.selectedProducts.push(product);
      }
    }
    if (nonApplicableForCostPlus.length > 0) {
      this._utilities.ShowErrorMessage(
        this.localization.captions.common.Error,
        this.localization.replacePlaceholders(
          this.captions.CostPlusDiscountNotEligibleForSomeItem,
          ['ItemCount'],
          [nonApplicableForCostPlus.length]
        )
      );
    }
  }

  packagedItemDiscountExceedsCallback(result: string, extraParams: any[]) {
    if (result.toUpperCase() == "YES") {
      this.AddPackagedItems(extraParams[0], extraParams[1], extraParams[2], extraParams[3], extraParams[4]);
      this.SetCreditOrDebit();
    }
    else {
      this._shopservice.selectedProducts = this._shopservice.selectedProducts.filter(x => x.PackageGroupId != this._shopservice.PackageGroupId);
    }
    this.checkscrollBar(true);
  }

  SetCreditOrDebit() {
    const totalPrice = this._shopservice.selectedProducts.reduce(function (prev, cur) {
      return prev + cur.ProductPrice;
    }, 0);
    this.isDebit = totalPrice < 0;
  }

  removeproductsFromCart($event) {
    this.removeproduct($event);
    this._shopservice.selectedProducts = [... this._shopservice.selectedProducts];
    this._retailService.enableAddToReservation = this._shopservice.selectedProducts.length < 0 ? true :
      this._retailService.selectedResvProducts.length !== this._shopservice.selectedProducts.length;

  }

  // DELETE SINGLE ITEM FROM CART
  removeproduct(SelectedProduct) {
    let warningMsg = this.localization.captions.shop.RemoveItemDeletionConfirmmsg + '"' + SelectedProduct.ProductName + '"' + '?'
    this._utilities.showAlert(warningMsg, AlertType.Warning, ButtonType.YesNo, (res) => {
      if (res === AlertAction.YES) {
        let seletedItemIdx = this._shopservice.SelectedItemDiscount.findIndex(x => x.itemId == SelectedProduct.ItemId);
        if (seletedItemIdx > -1) {
          this._shopservice.SelectedItemDiscount.splice(seletedItemIdx, 1);
        }

        if (this._shopservice.selectedProducts.some(r => r.ItemType && r.ItemType == RetailItemType.RevenueItem) && (SelectedProduct.ItemType == RetailItemType.RevenueItem || SelectedProduct.ItemType == RetailItemType.Deposit) && (this._shopservice.correctTransaction || this._shopservice.reOpenTransaction)) {
          if (this._shopservice.correctTransaction && this._shopservice.voidedTransactionId > 0) {
            if (this._shopservice.selectedProducts.length === this._shopservice.selectedProducts.filter(r => r.ItemType && r.ItemType == RetailItemType.RevenueItem || r.ItemType == RetailItemType.Deposit).length) {
              this.ShowVoidTxnDialog();
              return;
            }
          }
          this._shopservice.selectedProducts = this._shopservice.selectedProducts.filter(r => r.ItemType && r.ItemType != RetailItemType.Deposit && r.ItemType != RetailItemType.RevenueItem);
          this._shopservice.IsbeoItemCorrected = this._shopservice.correctTransaction;
          this._shopservice.IsbeoItemReopened = this._shopservice.reOpenTransaction;
        }
        else if (!SelectedProduct.isModificationRestricted) {

          // CHECK FOR CORRECTED TRANSACTION
          if (this._shopservice.correctTransaction && this._shopservice.voidedTransactionId > 0) {
            if (this._shopservice.selectedProducts.length === 1) {
              this.ShowVoidTxnDialog();
              return;
            }

            if (this.transactionService.IsSPAAppointmentRelatedRetailItem(SelectedProduct)) {
              this._utilities.ShowErrorMessage(this.localization.captions.common.Information, this.localization.getError(10717));
              return;
            }
          }

          if (this._shopservice.selectedProducts.length > 0) {
            this._shopservice.selectedProducts.splice(this._shopservice.selectedProducts.indexOf(SelectedProduct), 1);
          }
          this.checkscrollBar(false);
          this.SetCreditOrDebit();
          this.checkAllPackagedItemRemoved(SelectedProduct);
        }
        this._shopservice.selectedProducts = [... this._shopservice.selectedProducts];
        this._retailService.enableAddToReservation = this._shopservice.selectedProducts.length < 0 ? true :
          this._retailService.selectedResvProducts.length !== this._shopservice.selectedProducts.length;
      }
    });
  }

  checkAllPackagedItemRemoved(SelectedProduct: SelectedProducts) {
    if (this._shopservice.checkAllPackagedItemRemoved(SelectedProduct, this._shopservice.selectedProducts)) {
      this._shopservice.selectedProducts = this._shopservice.selectedProducts.filter(x => !(x.ItemId == SelectedProduct.PackageItemId && x.PackageGroupId == SelectedProduct.PackageGroupId));
    }
  }

  async ReleaseLock() {
    if (this._retailService.SelectedPlayers && this._retailService.SelectedPlayers.length > 0) {
      let playerIds: number[] = [];
      this._retailService.SelectedPlayers.forEach(x => {
        playerIds.push(x.playerId);
      });
      await RetailDataAwaiters.ReleasePlayerTempHold(playerIds);
    } else if (this._shopservice.AppoinmentIdForCheckOut && this._shopservice.AppoinmentIdForCheckOut.length > 0) {
      let appointmentId: number[] = [];
      this._shopservice.AppoinmentIdForCheckOut.forEach(x => {
        appointmentId.push(x);
      });
      await RetailDataAwaiters.ReleaseAppointmentLock(appointmentId);
    }
  }

  //RESET CART
  removeproducts() {
    let warningMsg = this.localization.captions.shop.RemoveAllItemsFromCart + '?'
    this._utilities.showAlert(warningMsg, AlertType.Warning, ButtonType.YesNo, (res) => {
      if (res === AlertAction.YES) {
        this.ReleaseLock();

        //CHECK FOR CORRECTED TRANSACTION
        if (this._shopservice.correctTransaction && this._shopservice.voidedTransactionId > 0) {
          this.ShowVoidTxnDialog();
        }
        else {
          if (this.from == ItemType.PMSAddOnsRentalItem || this.from == ItemType.retailItem) {
            this.groupList.map(x => {
              if (!x.isCheckedOut) {
                this.removeGroup(x)
              }
            });
          }
          if (this.isFromTeeTimeAddRetailItem && this.updateItemsToAppt)
            this._shopservice.isdisableUpdate = true;
          this._shopservice.isAppointmentCheckOut = false;
          this._shopservice.isClassCheckOut = false;
          this._shopservice.AppoinmentIdForCheckOut = [];
          this._shopservice.SelectedItemDiscount = [];
          this._shopservice.selectedProducts.splice(0, this._shopservice.selectedProducts.length);
          this._retailService.selectedProducts = [];
          this._retailService.SelectedPlayers = [];
          this._shopservice.SelectedPlayers = [];
          this._shopservice.selectedappointments = [];
          this._retailService.selectedappointments = [];
          this._shopservice.PackageGroupId = 0;
          this.checkscrollBar(false);
          this._shopservice.isReturnWithoutTicket = false;
          this.ShopFormGrp.controls.returnServiceFlag.setValue(false);
          this._retailService.isFromEditTeeTime = false;
          this._retailService.isFromSncBeo = false;
          this._shopservice.isFromSncBeo = false;
          this._shopservice.isFromUnpaidPlayer = false;
          this._retailService.isFromSpaWizard = false;
          this._shopservice.isFromSpaWizard = false;
          this._retailService.groupAppoinmentId = 0;
          this._shopservice.memberCardNumber = "0";
          this._shopservice.memberArNumber = "0";
          this._rs.labelRecords = [];
          this.SetCreditOrDebit();
          this.DiscardTicket();
          if (this.checkedOutGroupListCount > 0) {
            this.groupList = this.groupList.filter(x => x.isCheckedOut);
          }
          else {
            this.groupList = [];
          }
          if (this._retailService.isTeeTimeAsPaid) {
            this._shopservice.SelectedOutletId = null;
            this._retailService.isTeeTimeAsPaid = false;
            this.LoadOutlets();
            if (this.ShopFormGrp && this.functionalities[GlobalConst.RetailFunctionalities.ShowOutletSelectionFieldInShopScreen])
              this.ShopFormGrp.controls['outlet'].setValue(this._shopservice.SelectedOutletId);           
          }
          this._retailService.enableAddToReservation = this._shopservice.selectedResvProducts.length > 0;
          this._shopservice.selectedProducts = [... this._shopservice.selectedProducts];
          this._shopservice.ticketDiscount = null;
        }
        if (this._shopservice.SelectedOutletId == 0) {
          this.RetailProducts = [];
          this.products = [];
        }
      }
    });
  }

  ShowVoidTxnDialog() {
    const dialogRef = this.openVoidTxnPopup();
    dialogRef.afterClosed().subscribe(result => {
      this.searchTextBarCode.focus();
      if (result.toLowerCase() == this.localization.captions.common.Yes.toLowerCase()) {
        // UnDo CheckOut if any appointments associated with the voided transaction
        const eventData: RetailEventParameters<VoidEventModel> =
        {
          data: {
            transactionId: this._shopservice.voidedTransactionId,
            isDeposit: this._shopservice.isDepositTransactionCorrection,
            ticketNumber: this._shopservice.ticketNumber
          },
          eventType: RetailEventType.Void
        }
        retailPublisher.publishEvent(eventData);
        this._shopservice.isDepositTransactionCorrection = false;
        this._shopservice.correctTransaction = false;
        this._shopservice.ClearServiceObj();
        this.checkscrollBar(false);
        this.DiscardTicket();
      }
      else {
        return;
      }
    });
  }

  openVoidTxnPopup() {
    return this.dialog.open(AlertMessagePopupComponent, {
      width: '550px',
      height: '300px',
      hasBackdrop: true,
      panelClass: 'small-popup',
      data: {
        headername: this.localization.captions.common.Warning, headerIcon: 'icon-warning-icon', headerMessage: this.localization.captions.shop.CorrectionTransactionMsg, buttonName: this.localization.captions.common.Yes, noButton: true, noButtonName: this.localization.captions.common.No, type: 'message'
      },
      disableClose: true,
    });
  }

  SelectCategory(category) {
    this.quickSaleCateroryId = 0;
    this.categorySelected = this._utilities.getToggleFilter(this.categories, this.categorySelected, category);
    this.ProductCategoryFilter();
    for(let i=0;i<this.showViewLess.length;i++){
      this.showViewLess[i] = false;
    }
    // this.showViewLess.forEach(element => {
    //   element = false;
    // });
    this.noOfProducts = this.RetailProducts.map((r, i) => {
      if (!this.showViewLess[i]) {
        return this.noOfButtons;
      }
    });
  }

  ProductCategoryFilter() {
    this.filter(this.ShopFormGrp.value.searchtext, 'categories', false);
  }

  filter(e, frm, SearchbyBarCode: boolean, triggerBarcodeLoader?: boolean) {
    if (triggerBarcodeLoader) {
      this.barcodeItemLoader = true;
    }
    let searchtext: any;
    this.pageStart = 0;
    this.quickSaleCateroryId = 0;
    if (frm === 'filter') {
      this.pageStart = 0;
      if (e === '') {
        searchtext = '';
        this.ShopFormGrp.controls.searchtextBarCode.setValue('');
        this.ShopFormGrp.controls.searchtext.setValue('');
        this.ShopFormGrp.controls.scanQuantity.setValue('1');
      } else {
        SearchbyBarCode ? this.ShopFormGrp.controls.searchtext.setValue('') : this.ShopFormGrp.controls.searchtextBarCode.setValue('');
        searchtext = e.target.value;
        SearchbyBarCode ? this.ShopFormGrp.controls.searchtextBarCode.markAsPristine() : this.ShopFormGrp.controls.searchtext.markAsPristine();
      }
    } else if (frm === 'categories') {
      searchtext = e;
    }

    if (SearchbyBarCode) {
      let NoofProducts = 0;
      if (this.ShopFormGrp.controls.searchtextBarCode.value) {
        NoofProducts = parseInt(this.ShopFormGrp.controls.scanQuantity.value);
      }
      NoofProducts = NoofProducts ? NoofProducts : 0;
      if (NoofProducts <= 0) { return; }
      this.StopTimer();
      this.SetBarCodeTimer = setTimeout(() => {
        this.SearchbyInput(searchtext, SearchbyBarCode, frm);
      }, 100);
    } else {
      this.SearchbyInput(searchtext, SearchbyBarCode, frm);
    }
    // this.showViewLess.forEach(element => {
    //   element = false;
    // });
    for (let element of this.showViewLess) {
      element = false;  // Noncompliant
    }
    this.isClickedViewMore = false;

    this.isCheckWidth = false;
    this.UpdateViewMoreButtonState();
  }

  SearchbyInput(searchtxt, searchbyBarCode, from) {
    this.searchTextChanged.next({ searchtxt, searchbyBarCode, from });
  }

  async SearchbyInputAfterDebounce({ searchtxt, searchbyBarCode, from }) {

    if (this.globalSearchParam) {
      return;
    }

    this.searchByBarcode = searchbyBarCode;
    await this.getAllItems();

    const ItemDetails = _.cloneDeep(this.products);
    const rentalItems = this.ShopFormGrp.controls.ShowRentalItemOnly.value;
    if (searchtxt || rentalItems) {

      if (searchbyBarCode) {
        this.enablebarSearchIcon = false;
      } else {
        this.enableSearchIcon = false;
      }

      const item = ItemDetails.find(x => x.id === -1); //  `Others` category
      if (item && this.categorySelected.length === 0 || this.categorySelected.includes(0)) {
        item.hidden = false;
      }

      this.RetailProducts = ItemDetails.filter(x => {
        x.data = x.data.filter(y => {
          const itemPrice = y.price.customToFixed().toString();
          if (this.categorySelected.length === 0 || this.categorySelected.includes(0)) {
            if (!searchbyBarCode) {
              return (y.title.toLowerCase().includes(searchtxt.toLowerCase()) ||
                itemPrice.includes(searchtxt.toLowerCase()) ||
                y.itemNumber.toString().includes(searchtxt.toLowerCase())
                || y.vendorName?.toString().toLowerCase().includes(searchtxt.toLowerCase()));
            } else {
              return y.barCodes.includes(searchtxt.toLowerCase());
            }
          } else {
            if (!searchbyBarCode) {
              return ((this.categorySelected.some(r => x['id'] == r) || (-1 === x['id'])) &&
                (y.title.toLowerCase().includes(searchtxt.toLowerCase()))
                || itemPrice.toLowerCase().includes(searchtxt.toLowerCase())
                || y.itemNumber.toString().toLowerCase().includes(searchtxt.toLowerCase())
                || y.vendorName?.toString().toLowerCase().includes(searchtxt.toLowerCase()));
            } else {
              return ((this.categorySelected.some(r => x['id'] === r) || (-1 === x['id']) || searchbyBarCode) && y.barCodes.includes(searchtxt.toLowerCase()));
            }
          }
        });

        if (x.data.length > 0) {
          return x;
        }
      });
      if (this.from == this.itemTypeEnum.PMSAddOnsRentalItem) {
      this.RetailProducts = this.mapRentalInfo(this.RetailProducts,this.rentalInformation);
      }
    } else {
      searchbyBarCode ? this.enablebarSearchIcon = true : this.enableSearchIcon = true;
      // Make SearchbyBarCode FALSE when searchtext is empty
      searchbyBarCode = false;
      const item = ItemDetails.find(x => x.id === -1);
      if (item) {
        item.hidden = true;
      }

      if (this.categorySelected.length === 0 || this.categorySelected.includes(0)) {
        this.RetailProducts = ItemDetails;
      } else if (this.searchByBarcode) {
        this.RetailProducts = ItemDetails.filter(x => x.data.length > 0);
      }
      else {
        this.RetailProducts = ItemDetails.filter(x => (this.categorySelected.some(r => x['id'] === r)));
      }
    }

    await this.handleGiftCardsForReturnFlow(this._shopservice.isReturnWithoutTicket, true);
    //this.RemoveRestrictedItemsBasedOnTypeForAppointment();
    if (from === 'filter') {
      this.UpdateForm(this.RetailProducts, searchbyBarCode);
    }

    this.UpdateForm(this.RetailProducts, false);
    this.RetailProducts.forEach((product, index)=>{
      this.changeProductsView(product.id, index, 'viewall', false)
    });
    
    if (this.RetailProducts.length > 1) {
      for (let i = 0; i < this.showViewLess.length; i++) {
        this.showViewLess[i] = false;
      }
    }
    this.UpdateViewMoreButtonState();
    this.triggerItems.next(Math.random());
  }

  StopTimer() {
    clearTimeout(this.SetBarCodeTimer);
  }

  AddSelectedRetailItem(productdetails, rowindex, index, quantity, isEnterCommentPopupShown: boolean = false) {
    if (productdetails.itemNotSynced || !productdetails.isCategoryMapped) {
      return;
    }

    if (!this.CheckDiscountAmount(productdetails, quantity)) {
      this._utilities.ShowErrorMessage(this.localization.captions.common.Warning
        , this.captions.DiscountAmountGreaterThanActual
        , GlobalConst.ButtonType.YesNo
        , this.AmountExceedCallback.bind(this)
        , [productdetails, rowindex, index, quantity]);
      return;
    }


    this.openScaledItemsDialog(productdetails, rowindex, index, true, isEnterCommentPopupShown);
  }

  CheckDiscountAmount(productdetails, quantity) {
    const itemDiscount = this._shopservice.SelectedItemDiscount.find(x => x.itemId === productdetails.itemId);
    let discount = 0;
    if (itemDiscount) {
      discount = itemDiscount.discountPercentage > 0 ? this._utilities.MidPointRoundOffTwo(quantity * productdetails.price * itemDiscount.discountPercentage / 100) : itemDiscount.discountAmount;
    }

    if (discount > quantity * Math.abs(productdetails.price)) {
      return false;
    }

    return true;
  }
  async triggerPricePrompt(productdetails, rowindex, index, quantity) {
    if (this.isFromTeeTimeAddRetailItem && this.selectedPlayer.length == 0) {
      this._utilities.ShowErrorMessage(this.localization.captions.common.Warning, this.captions.PleaseSelectPlayer);
      return;
    }
    if (!this.hasAccessToCreateTransaction()) {
      return;
    }

    const i = this.ShopFormGrp.value.products[rowindex][index];
    if (!i) {
      return;
    }

    let NoofProducts = i.itemcount;
    NoofProducts = NoofProducts ? NoofProducts : 0; if (this.ShopFormGrp.controls.searchtextBarCode.value) {
      NoofProducts = parseInt(this.ShopFormGrp.controls.scanQuantity.value);
      quantity = parseInt(this.ShopFormGrp.controls.scanQuantity.value);
    }

    if (!NoofProducts || NoofProducts <= 0) {
      return;
    }

    if (productdetails.isOpenItem && !productdetails.isGiftCardItem) {
      if (this.isEmptyOpenItemDesc) {
        productdetails.itemDescription = "";
      }

      const dialogRef = this.dialog.open(DialogOverviewExampleDialog, {
        height: 'auto',
        width: '750px',
        data: {
          Type: 'OPENPRICE',
          headername: this.localization.captions.shop.OpenPricedItem,
          data: productdetails,
          quantity: quantity,
          closebool: true,
          buttonType: GlobalConst.ButtonType.SaveCancel
        },
        panelClass: 'small-popup',
        disableClose: true,
        hasBackdrop: true
      });

      dialogRef.afterClosed()
        .pipe(takeUntil(this.$destroyed))
        .subscribe((res: shopPricePrompt) => {
          this.searchTextBarCode.focus();
          if (res.res === GlobalConst.ButtonOptions.Save && res.price) {
            const productClone = _.cloneDeep(productdetails);
            productClone.price = this.localization.currencyToSQLFormat(res.price.toString());
            productClone.itemDescription = productClone.title = res.itemName;
            if (productdetails.isRequireComments) {
              this.openEnterCommentDialog(productClone, rowindex, index, quantity);
            } else {
              this.AddSelectedRetailItem(productClone, rowindex, index, quantity);
            }

          } else {
            this.resetBarCodeSearchFilter();
          }
        });

    } else if (productdetails.isOpenItem && productdetails.isGiftCardItem) {
      if (this.CheckMaxCardsPerTransactionLimitExceeded(productdetails.GiftCardTransactionItem)) { return; }
      const defaultOpenItem = !productdetails.isGiftCardCustomValueItem;
      const giftCardData: IssueGiftCardPopupModel = {
        Amount: defaultOpenItem ? 0 : productdetails.price,
        openItem: defaultOpenItem,
        HandleInfo: ''
      };
      this._giftCardBusiness.ShowIssueGiftCardModal(giftCardData, this.AddGiftCardToCart.bind(this), [productdetails, rowindex, index]);
    }
    else if (productdetails.isRequireComments) {
      this.openEnterCommentDialog(productdetails, rowindex, index, quantity);
    }
    else if (productdetails.isGiftCardItem && !productdetails.openItem) {
      this._utilities.ShowErrorMessage(this.localization.captions.common.Warning, this.captions.OpenItemValidationforGiftCard);
      return;
    }
    else {
      this.AddSelectedRetailItem(productdetails, rowindex, index, quantity);
    }
    this.ShopFormGrp.markAsDirty();
  }

  async resetBarCodeSearchFilter(e?) {
    this.enablebarSearchIcon = true;
    let searchVal = e ? e : ''
    this.filter(searchVal, 'filter', searchVal ? false : true);
    await this.loadRetailItems()
  }
  openEnterCommentDialog(productdetails, rowindex, index, quantity, isOpenPricePopupShown?: boolean, isWithoutCommission = false) {
    if (this.ShopFormGrp.controls.searchtextBarCode.value) {
      quantity = parseInt(this.ShopFormGrp.controls.scanQuantity.value);
    }
    const dialogRef = this.dialog.open(DialogOverviewExampleDialog, {
      height: 'auto',
      width: '750px',
      data: {
        Type: 'ENTERCOMMENT',
        headername: this.localization.captions.shop.EnterComment,
        data: productdetails,
        quantity: quantity,
        closebool: true,
        buttonType: GlobalConst.ButtonType.SaveCancel
      },
      panelClass: 'small-popup',
      disableClose: true,
      hasBackdrop: true
    });
    dialogRef.afterClosed()
      .pipe(takeUntil(this.$destroyed))
      .subscribe((res: enterCommentPrompt) => {
        this.searchTextBarCode.focus();
        if (res.res === GlobalConst.ButtonOptions.Save && res.comment) {
          productdetails.itemComments = res.comment;
          if (isWithoutCommission) {
            this.AddSelectedRetailItemWithOutCommission(productdetails, rowindex, index, quantity, isOpenPricePopupShown);

          } else {
            this.AddSelectedRetailItem(productdetails, rowindex, index, quantity, true);
          }

        } else {
          this.resetBarCodeSearchFilter(this.ShopFormGrp.controls.searchtext?.value);
        }
      });

  }

  async openScaledItemsDialog(productdetails, rowindex, index, isDirectAdd = true, isEnterCommentPopupShown: boolean = false) {
    const item = this._shopservice.AllShopItems.filter(x => x.id === productdetails.itemId);
    const uomId = item[0].retailItemDetail.unitOfMeasure ? item[0].retailItemDetail.unitOfMeasure : 0;

    if (item && item[0].retailItemDetail.isScaledItem && item[0].retailItemDetail.unitOfMeasure) {
      const uom = this.unitOfMeasures && this.unitOfMeasures.length > 0 ? this.unitOfMeasures.filter(x => x.id === uomId) : [];
      const uomDispValue = uom ? uom[0].name : '';

      const dialogRef = this.dialog.open(DialogOverviewExampleDialog, {
        height: 'auto',
        width: '750px',
        panelClass: 'small-popup',
        data: {
          Type: 'SCALEDITEM',
          headername: this.localization.captions.shop.ScaledItem,
          data: productdetails,
          quantity: uomDispValue,
          closebool: true,
          buttonType: GlobalConst.ButtonType.AddCancel
        },
        hasBackdrop: true,
        disableClose: true
      });

      dialogRef.afterClosed().pipe(takeUntil(this.$destroyed)).subscribe(async (res: shopScaledItems) => {
        this.searchTextBarCode.focus();
        if (res.res === GlobalConst.ButtonOptions.Add && res.unit) {
          productdetails.scaledUnit = res.unit;
          productdetails.uom = uomDispValue;
          productdetails.unitOfMeasureId = uomId;

          const productDetailPrice = (productdetails.isOpenItem ? productdetails.price : productdetails.dispPrice) * this.localization.currencyToSQLFormat(res.unit.toString());
          productdetails.price = this._utilities.RoundOff2DecimalPlaces(productDetailPrice);
          if (isDirectAdd) {
            await this.onOptionChange(productdetails, rowindex, index, isEnterCommentPopupShown);
          } else {
            await this.AddProductItem(productdetails, rowindex, index, productdetails.price);
          }
        } else {
          this.resetBarCodeSearchFilter();
        }
        this.checkscrollBar(true);
        this.barcodeItemLoader = false;
      });
    } else {
      if (isDirectAdd) {
        await this.onOptionChange(productdetails, rowindex, index, isEnterCommentPopupShown);
      } else {
        await this.AddProductItem(productdetails, rowindex, index, productdetails.price);
      }
      this.checkscrollBar(true);
      this.barcodeItemLoader = false;
    }
  }

  async onOptionChange(productdetails, rowindex, index, isEnterCommentPopupShown: boolean = false) {
    const item = this._shopservice.AllShopItems.filter(x => { return x.id == productdetails.itemId });
    this.selectedParentItem = item;
    if (item && item[0].retailItemDetail.isOpenItem) {
      item[0].retailItemDetail.salesPrice = productdetails.price;
      //Overriding the ItemName
      item[0].retailItemDetail.itemDescription = productdetails.itemDescription;
    }

    if (item && !item[0].retailItemDetail.isCommissionable) {
      this.addProduct(productdetails, rowindex, index, true, isEnterCommentPopupShown);
      return;
    }
    const title = this.localization.captions.shop.AssignCommissionGratuity;
    const templateName = 'AC';

    const i = this.ShopFormGrp.value.products[rowindex][index];
    if (!i) {
      return;
    }

    let NoofProducts = i.itemcount;
    if (this.ShopFormGrp.controls.searchtextBarCode.value) {
      NoofProducts = parseInt(this.ShopFormGrp.controls.scanQuantity.value);
    }
    NoofProducts = NoofProducts ? NoofProducts : 0;
    if (!NoofProducts || NoofProducts <= 0) return;
    let therapistSelectedList: any[] = [];

    let PerviousTherapistSameItem: any[] = [];

    this._shopservice.selectedProducts.forEach(x => {
      if (x.ItemId == productdetails.itemId) {
        PerviousTherapistSameItem = PerviousTherapistSameItem.concat(x.Commission ? x.Commission.map(a => a.id) : 0);
      }
      if (x.Commission && x.Commission.length > 0) {
        therapistSelectedList = therapistSelectedList.concat(x.Commission ? x.Commission.map(a => a.id) : 0);
      }
    });
    if (item[0].retailItemDetail.isGroupingKey) {

      const packagedItems = await this.getPackageItemsforItem(item);
      const groupingId = item[0].id;
      const groupingItemDescription = item[0].retailItemDetail.itemDescription;
      const groupingUniqueIdentifier = this._utilities.generateGUID();

      this._shopservice.PackageGroupId = this._shopservice.PackageGroupId + 1;

      const itemId = item[0].retailItemDetail.id;
      const selitem = this._shopservice.AllShopItems.filter(product => product.id == itemId);
      const newItem = {
        itemType: item[0].retailItemDetail.itemType,
        ExternalPOSItemId: item[0].retailItemDetail.externalPOSId,
        ItemDescription: item[0].retailItemDetail.itemDescription,
        ItemNumber: String(item[0].retailItemDetail.itemNumber),
        ItemType: item[0].retailItemDetail.itemType,
        ItemId: itemId,
        SalesPrice: item[0].retailItemDetail.salesPrice,
        ServiceId: 0,
        ProductName: item[0].retailItemDetail.itemDescription,
        ProductPrice: item[0].retailItemDetail.salesPrice,
        Noofitems: NoofProducts,
        Discount: 0,
        DiscountPercentage: 0,
        DiscountTypeId: 0,
        Gratuity: null,
        ServiceCharge: null,
        isModificationRestricted: false,
        Commission: null,
        isCommissionable: selitem[0].retailItemDetail.isCommissionable,
        isGroupingKey: selitem[0].retailItemDetail.isGroupingKey,
        isPackagedItem: false,
        PackageItemId: 0,
        MultiPack: selitem[0].retailItemDetail.isMultiPack,
        ClientMultiPackId: 0,
        PackageGroupId: this._shopservice.PackageGroupId,
        isOpenPricedItem: item[0].retailItemDetail.isOpenItem,
        category: selitem[0].retailItemDetail.category,
        LineNumber: 0,
        costPrice: selitem[0].retailItemDetail.costPrice,
        marginPercentage: selitem[0].retailItemDetail.marginPercentage,
        itemComments: productdetails.itemComments,
        isRequestName: productdetails.isRequestName,
        allowEarn: item[0].retailItemDetail.allowEarn,
        discountComments: '',
        discountReason: 0,
        GroupingParentId: groupingId,
        GroupingUniqueIdentifier: groupingUniqueIdentifier,
        GroupingItemDescription: groupingItemDescription,
        appliedCustomFee: []
      };
      this._shopservice.selectedProducts.push(newItem);
      this.packagedItemIds = item[0].packagedItem.map(x => x.parentItemId);
      this.packagedItemPrice = item[0].packagedItem.filter(x => x.parentItemId == this.packagedItemIds[this.packagedItemIndex])[0].price;
      this.packagedItemCount = this.packagedItemIds.length;
      this.packagedItem = packagedItems.filter(x => x.id == this.packagedItemIds[this.packagedItemIndex]);
      const selectedDiscount = this._shopservice.SelectedItemDiscount.find(r => r.itemId == item[0].id);
      this.packagedItemCommission(packagedItems, this.packagedItemIds[0], title, templateName, this.packagedItem,
        this.packagedItemPrice, NoofProducts, therapistSelectedList, PerviousTherapistSameItem, item[0].packagedItem,
        selectedDiscount, productdetails.itemComments, groupingId, groupingItemDescription, groupingUniqueIdentifier);
    }
    else {
      this._utilities.ToggleLoaderWithMessage(true, this.captions.lbl_processing);
      setTimeout(async () => {
        const commissionItem = { ...item[0] };
        const discount = this._shopservice.SelectedItemDiscount.find(x => x.itemId == commissionItem.retailItemDetail.id)
        let discountAmt = 0;
        if (discount) {
          discountAmt = (discount.discountPercentage > 0 ? this._utilities.MidPointRoundOffTwo(NoofProducts * productdetails.price * discount.discountPercentage / 100) : discount.discountAmount);
        }
        commissionItem.retailItemDetail.Discount = discountAmt;
        commissionItem.retailItemDetail.DiscountTypeId = discount ? discount.discountId : 0;
        commissionItem.retailItemDetail.DiscountPercentage = discount ? discount.discountPercentage : 0;

        if (await this._shopservice.IsServiceChargeOnNet() || await this._shopservice.IsGratuityOnNet()) {
          let tempDiscountValue = 0;
          if (discount) {
            tempDiscountValue = discount.discountPercentage > 0 ? discount.discountPercentage : discount.discountAmount;
          }
          const discountModel: ApplyDiscount = {
            index: 0,
            value: discount ? tempDiscountValue : 0,
            isPercentage: discount && discount.discountPercentage > 0 ? true : false
          }
          await this.AddorUpdateTicket(productdetails.itemId, productdetails.externanId, productdetails.price, NoofProducts,
            discountModel, productdetails.itemDescription, productdetails.itemType, productdetails.costPrice,
            productdetails.marginPercentage, productdetails.allowEarn);
        }

        const dialogRef = this.dialog.open(ShopDialogPopUp, {
          width: '1155px',
          height: '90%',
          maxHeight: '700px',
          disableClose: true,
          hasBackdrop: true,
          data: {
            from: "retailItem",
            headername: title, closebool: true, templatename: templateName,
            data: [commissionItem, NoofProducts, therapistSelectedList, PerviousTherapistSameItem],
            ProductPrice: productdetails.price,
            scaledUnit: productdetails.scaledUnit,
            Quantity: NoofProducts,
            uom: productdetails.uom,
            isReturn: this._shopservice.isReturnWithoutTicket,
            unitOfMeasureId: productdetails.unitOfMeasureId,
            netAmount: this.transactionService.GetNetValue(productdetails.price * NoofProducts,
              productdetails.externanId, this.Ticket, 1, productdetails.itemId),
            itemComments: productdetails.itemComments,
            netUnitPriceWithoutDiscount: this.transactionService.GetNetUnitValueWithoutDiscount(productdetails.price * NoofProducts,
              productdetails.externanId, this.Ticket, 1, productdetails.itemId)
          },
          panelClass: 'small-popup'
        })
        this._utilities.ToggleLoader(false);
        dialogRef.afterClosed().pipe(takeUntil(this.$destroyed)).subscribe((res: any) => {
          if (this.isFromTeeTimeAddRetailItem)
            this.retailItemsMapper(res);
          this.searchTextBarCode.focus();
          this.checkscrollBar(true);
          this.SetCreditOrDebit();
          this.resetBarCodeSearchFilter(this.ShopFormGrp.controls.searchtext?.value);
        })
      }, 1)
    }
  }
  async AddorUpdateTicket(itemId: number, externalPOSId: number, price: number, qunatity: number, discount: ApplyDiscount,
    itemDescription: string, itemType, costPrice, marginPercentage, allowEarn, groupingParentId?: number,
    groupingUniqueIdentifier?: string, groupingItemDescription?: string) {
    if (!this.Ticket) { //Createt new ticket
      const lineItem: RetailShopItem = {
        ItemId: itemId,
        ItemType: itemType,
        ItemDescription: itemDescription,
        ExternalPOSItemId: externalPOSId,
        QuantitySold: qunatity,
        UnitPrice: price,
        Discount: 0,
        DiscountTypeId: 0,
        DiscountPercentage: 0,
        StaffTransactionDetail: [],
        LineNumber: 0,
        Tax: 0,
        BaseTax: 0,
        LinkedTax: 0,
        TotalAmount: qunatity * price,
        OutletId: this._shopservice.SelectedOutletId,
        IsGroupingKey: false,
        IsPackagedItem: false,
        PackageItemId: 0,
        IsMultiPackRedeem: false,
        IsModificationRestricted: false,
        ClientMultiPackId: 0,
        PackageGroupId: 0,
        IsOpenPricedItem: false,
        id: 0,
        IsTaxExempt: false,
        discountModel: discount,
        costPrice: costPrice,
        marginPercentage: marginPercentage,
        allowEarn: allowEarn,
        discountComments: '',
        discountReason: 0,
        GroupingParentId: groupingParentId,
        GroupingUniqueIdentifier: groupingUniqueIdentifier,
        GroupingItemDescription: groupingItemDescription
      };
      const terminalId = this.outletTerminals && this.outletTerminals.length > 0 ? this.outletTerminals[0].terminalId : 0;
      this.Ticket = this.propertyInfo.UseRetailInterface ?
        await this.transactionService.CreateTicketForItem([lineItem], Number(terminalId)) :
        await this.transactionEngineBusiness.CreateTicketForItem(this._shopservice.SelectedOutletId, [lineItem]);
    }
    else { // Update existing ticekt with new item

      if (this.propertyInfo.UseRetailInterface) {
        if (this.Ticket.checkData && this.Ticket.lineItems) {
          this.Ticket.lineItems.forEach(r => r.isVoided = true);
          const newLineItem: LineItem = {
            itemId: itemId,
            itemDescription: itemDescription,
            externalPOSItemId: externalPOSId,
            quantity: qunatity,
            unitPrice: price,
            memberUnitPrice: 0,
            itemType: itemType,
            tax: 0,
            baseTax: 0,
            linkedTax: 0,
            amount: qunatity * price,
            discount: 0,
            isMultiPackRedeem: false,
            index: this.Ticket.lineItems.length + 1,
            isVoided: false,
            isOpenPricedItem: false,
            taxDetails: null,
            isTaxExempt: false,
            discountModel: discount,
            netPrice: 0,
            netUnitPrice: 0,
            netUnitPriceWithoutDiscount: 0,
            vat: 0,
            OriginalTax: 0
          };
          this.Ticket.lineItems.push(newLineItem);
          this.Ticket = await this.transactionService.AddAndRemoveTicketItems(this.Ticket);
        }
      }
      else {
        const lineItem: RetailShopItem = {
          ItemId: itemId,
          ItemType: itemType,
          ItemDescription: itemDescription,
          ExternalPOSItemId: externalPOSId,
          QuantitySold: qunatity,
          UnitPrice: price,
          Discount: 0,
          DiscountTypeId: 0,
          DiscountPercentage: 0,
          StaffTransactionDetail: [],
          LineNumber: 0,
          Tax: 0,
          BaseTax: 0,
          LinkedTax: 0,
          TotalAmount: qunatity * price,
          OutletId: this._shopservice.SelectedOutletId,
          IsGroupingKey: false,
          IsPackagedItem: false,
          PackageItemId: 0,
          IsMultiPackRedeem: false,
          IsModificationRestricted: false,
          ClientMultiPackId: 0,
          PackageGroupId: 0,
          IsOpenPricedItem: false,
          id: 0,
          IsTaxExempt: false,
          discountModel: discount,
          costPrice: costPrice,
          marginPercentage: marginPercentage,
          allowEarn: allowEarn,
          discountComments: '',
          discountReason: 0,
          GroupingParentId: groupingParentId,
          GroupingUniqueIdentifier: groupingUniqueIdentifier,
          GroupingItemDescription: groupingItemDescription
        };
        this.Ticket = await this.transactionEngineBusiness.CreateTicketForItem(this._shopservice.SelectedOutletId, [lineItem]);
      }
    }
  }

  ngAfterViewInit() {
    this.hideViewMore();
    this.setIntervalWidth = setInterval(() => {
      this.RetailProducts.forEach((r, i) => {
        if (document.getElementsByClassName('items-collapse'))
          this.checkProductsWidth(i);
      });
      if (!this.isFromGlobalSearch) {
        this.UpdateViewMoreButtonState();
      }
    }, 500);
    this.checkscrollBar(false);
    this._cdr.detectChanges();
    
    this.triggerItems.subscribe((x)=>{
      if(window.innerWidth <= 767){
        this.loadRetailItemsForMobile();
      }
    });

    this.searchTextChanged.pipe(
      debounceTime(1000),
      distinctUntilChanged())
      .subscribe(async ({ searchtxt, searchbyBarCode, from }) => {
        await this.SearchbyInputAfterDebounce({ searchtxt, searchbyBarCode, from })
  });
      
  }

  loadRetailItemsForMobile(){
    setTimeout((xx)=>{
        this.RetailProducts.forEach((product, index)=>{
          this.changeProductsView(product.id, index, 'viewall')
        });
      },3000);
  }

  checkProductsWidth(index?: any) {
    if(window.innerWidth > 767){
      const productsDiv = document.getElementsByClassName('inner-wrapper');
      if (productsDiv.length > 0) {
        clearInterval(this.setIntervalWidth);
        const servicegrpWidth = productsDiv[0]['offsetWidth'];
        const productWidth = 200;
        this.noOfButtons = Math.floor(servicegrpWidth / productWidth) - 1;
        this.originalNoOfButtons = Math.floor(servicegrpWidth / productWidth) - 1;
        if (index >= 0) {
          this.showViewLess[index] = false;
          this.noOfProducts[index] = this.noOfButtons;

        }
        else {
          this.showViewLess.forEach(element => {
            element = false;
          });
          this.RetailProducts.forEach((res, i) => this.noOfProducts[i] = this.noOfButtons);
        }
      }
      this.RetailProducts.forEach((res, productIndex) => {
        if (res.data && res.data.length && (res.data.length < this.noOfButtons)) {
          if (res.id === this.quickSaleCateroryId) {
            if (!res.viewMoreclicked) {
              res.isViewMoreEnable = true;
            }
          }
        }
      });
      this.UpdateViewMoreButtonState();
    }
  }

  async packagedItemCommission(packagedItems, id, title, templateName, packagedItem, packagedItemPrice, NoofProducts,
    therapistSelectedList, PerviousTherapistSameItem, packagedItemList?: any[], selectedDiscount?: ItemDiscount,
    itemComments?: string, groupingId?: number, groupingItemDescription?: string,
    groupingUniqueIdentifier?: string) {
    this._utilities.ToggleLoaderWithMessage(true, this.localization.captions.lbl_processing);
    const curPackagedItem = packagedItemList ? packagedItemList[this.packagedItemIndex] : null;
    // Check Valid Date
    if (curPackagedItem && this.packagedItemCount > 0 && !(packagedItem[0].retailItemDetail.isActive && this._utilities.GetDateWithoutTime(this._utilities.getDate(this.propertyInfo.CurrentDate)).getTime() >= this._utilities.GetDateWithoutTime(this._utilities.getDate(curPackagedItem.startDate)).getTime()
      && this._utilities.GetDateWithoutTime(this._utilities.getDate(this.propertyInfo.CurrentDate)).getTime() <= this._utilities.GetDateWithoutTime(this._utilities.getDate(curPackagedItem.endDate)).getTime())) {

      this.packagedItemCount--;
      this.packagedItemIndex++;

      if (this.packagedItemCount > 0) {
        this.packagedItem = packagedItems.filter(x => x.id == this.packagedItemIds[this.packagedItemIndex]);
        this.packagedItemPrice = packagedItemList[this.packagedItemIndex].price;
      }
      if (this.packagedItemCount > 0) {
        this.packagedItemCommission(packagedItems, id, title, templateName, this.packagedItem, this.packagedItemPrice, NoofProducts,
          therapistSelectedList, PerviousTherapistSameItem, packagedItemList, selectedDiscount, itemComments,
          groupingId, groupingItemDescription, groupingUniqueIdentifier);
      }
      else {
        this.packagedItemPrice = 0;
        this.packagedItemIndex = 0;
      }
      this._utilities.ToggleLoader(false);
      return;

    }

    const discountAmt = selectedDiscount ? this.getDiscount(selectedDiscount, NoofProducts, packagedItemPrice) : 0;

    if (!packagedItem[0].retailItemDetail.isCommissionable || packagedItem[0].retailItemDetail.isMultiPack) {
      const newItem = {
        ItemType: packagedItem[0].retailItemDetail.itemType,
        ExternalPOSItemId: packagedItem[0].retailItemDetail.externalPOSId,
        ItemDescription: packagedItem[0].retailItemDetail.itemDescription,
        ItemNumber: packagedItem[0].retailItemDetail.itemNumber,
        ItemId: packagedItem[0].retailItemDetail.id,
        SalesPrice: packagedItemPrice,
        ServiceId: 0,
        ProductName: packagedItem[0].retailItemDetail.itemDescription,
        ProductPrice: this._shopservice.isReturnWithoutTicket ? packagedItemPrice * -1 : packagedItemPrice,
        Noofitems: NoofProducts,
        Discount: discountAmt,
        DiscountPercentage: (selectedDiscount && selectedDiscount.discountPercentage > 0) ? selectedDiscount.discountPercentage : 0,
        DiscountTypeId: selectedDiscount ? selectedDiscount.discountId : 0,
        Gratuity: null,
        ServiceCharge: null,
        Commission: null,
        isCommissionable: packagedItem[0].retailItemDetail.isCommissionable,
        isGroupingKey: packagedItem[0].retailItemDetail.isGroupingKey,
        isPackagedItem: false,
        PackageItemId: 0,
        MultiPack: packagedItem[0].retailItemDetail.isMultiPack,
        ClientMultiPackId: 0,
        PackageGroupId: this._shopservice.PackageGroupId,
        isOpenPricedItem: packagedItem[0].retailItemDetail.isOpenItem,
        category: packagedItem[0].retailItemDetail.category,
        LineNumber: this._shopservice.GetNextLineNumber(),
        isReturn: this._shopservice.isReturnWithoutTicket,
        costPrice: packagedItem[0].retailItemDetail.costPrice,
        marginPercentage: packagedItem[0].retailItemDetail.marginPercentage,
        itemComments: itemComments,
        allowEarn: packagedItem[0].retailItemDetail.allowEarn,
        discountComments: selectedDiscount ? selectedDiscount.discountComment : '',
        discountReason: selectedDiscount ? selectedDiscount.discountReason : 0,
        GroupingParentId: groupingId,
        GroupingUniqueIdentifier: groupingUniqueIdentifier,
        GroupingItemDescription: groupingItemDescription,
        appliedCustomFee: []
      };
      this._shopservice.selectedProducts.push(newItem);
      if (this.isFromTeeTimeAddRetailItem)
        this.retailItemsMapper(newItem);
      this.packagedItemCount--;
      this.packagedItemIndex++;
      if (this.packagedItemCount > 0) {
        this.packagedItem = packagedItems.filter(x => x.id == this.packagedItemIds[this.packagedItemIndex]);
        this.packagedItemPrice = packagedItemList[this.packagedItemIndex].price;
      }
      if (this.packagedItemCount > 0) {
        this.packagedItemCommission(packagedItems, id, title, templateName, this.packagedItem, this.packagedItemPrice,
          NoofProducts, therapistSelectedList, PerviousTherapistSameItem, packagedItemList, selectedDiscount, itemComments,
          groupingId, groupingItemDescription, groupingUniqueIdentifier);
      }
      else {
        this.packagedItemPrice = 0;
        this.packagedItemIndex = 0;
      }
      this._utilities.ToggleLoader(false);
      return;
    }

    // Setting previously selected therapists
    PerviousTherapistSameItem = [];
    therapistSelectedList = [];
    this._shopservice.selectedProducts.forEach(x => {
      if (x.ItemId === id) {
        PerviousTherapistSameItem = PerviousTherapistSameItem.concat(x.Commission ? x.Commission.map(a => a.id) : 0);
      }
      if (x.Commission && x.Commission.length > 0) {
        therapistSelectedList = therapistSelectedList.concat(x.Commission ? x.Commission.map(a => a.id) : 0);
      }
    });


    if (await this._shopservice.IsServiceChargeOnNet() || await this._shopservice.IsGratuityOnNet()) {
      const discount: ApplyDiscount = {
        index: 0,
        value: selectedDiscount ? (selectedDiscount.discountAmount > 0 ? selectedDiscount.discountAmount :
          selectedDiscount.discountPercentage) : 0,
        isPercentage: selectedDiscount && selectedDiscount.discountPercentage ? true : false,
        minimumAmountInCart: selectedDiscount && selectedDiscount.discountId != 0 ? selectedDiscount.minimumAmountInCart : 0,
        appliedDiscount: selectedDiscount ? (selectedDiscount.discountAmount > 0 ? selectedDiscount.discountAmount :
          selectedDiscount.discountPercentage) : 0
      };
      await this.AddorUpdateTicket(packagedItem[0].retailItemDetail.itemId, packagedItem[0].retailItemDetail.externalPOSId,
        packagedItemPrice, NoofProducts, discount, packagedItem[0].retailItemDetail.itemDescription,
        packagedItem[0].retailItemDetail.itemType, packagedItem[0].retailItemDetail.costPrice,
        packagedItem[0].retailItemDetail.marginPercentage, packagedItem[0].retailItemDetail.allowEarn);
      this._utilities.ToggleLoaderWithMessage(true, this.localization.captions.lbl_processing);
    }

    // Update discount value in package item
    packagedItem[0].retailItemDetail.Discount = discountAmt;
    setTimeout(async () => {
      const dialogRef = this.dialog.open(ShopDialogPopUp, {
        width: '1155px',
        height: '90%',
        maxHeight: '700px',
        disableClose: true,
        hasBackdrop: true,
        data: {
          headername: title, closebool: true, templatename: templateName, data:
            [packagedItem[0], NoofProducts, therapistSelectedList, PerviousTherapistSameItem, packagedItemPrice, true,
            this.selectedParentItem[0].id, this._shopservice.PackageGroupId, groupingId, groupingUniqueIdentifier, groupingItemDescription],
          isReturn: this._shopservice.isReturnWithoutTicket,
          ProductPrice: packagedItemPrice,
          Quantity: NoofProducts,
          netAmount: this.transactionService.GetNetValue(packagedItemPrice * NoofProducts, packagedItem[0].retailItemDetail.externalPOSId,
            this.Ticket, 1, packagedItem[0].retailItemDetail.itemId),
          netUnitPriceWithoutDiscount: this.transactionService.GetNetUnitValueWithoutDiscount(packagedItemPrice * NoofProducts, packagedItem[0].retailItemDetail.externalPOSId,
            this.Ticket, 1, packagedItem[0].retailItemDetail.itemId)
        },
        panelClass: 'small-popup'
      });
      this._utilities.ToggleLoader(false);
      dialogRef.afterClosed().pipe(takeUntil(this.$destroyed)).subscribe((res) => {
        if (res) {
          this.searchTextBarCode.focus();
          if (this.isFromTeeTimeAddRetailItem)
            this.retailItemsMapper(res);
          this.packagedItemCount--;
          this.packagedItemIndex++;
          if (this.packagedItemCount > 0) {
            this.packagedItem = packagedItems.filter(x => x.id === this.packagedItemIds[this.packagedItemIndex]);
            this.packagedItemPrice = packagedItemList[this.packagedItemIndex].price;
            this.packagedItemCommission(packagedItems, id, title, templateName, this.packagedItem, this.packagedItemPrice,
              NoofProducts, therapistSelectedList, PerviousTherapistSameItem, packagedItemList, selectedDiscount, '', groupingId,
              groupingItemDescription, groupingUniqueIdentifier);
          }
          else {
            this.packagedItemPrice = 0;
            this.packagedItemIndex = 0;
          }
        }
        else if (this.packagedItemCount > 0) {
          this._shopservice.selectedProducts = this._shopservice.selectedProducts.filter(x => x.PackageGroupId != this._shopservice.PackageGroupId);
          this.packagedItemPrice = 0;
          this.packagedItemIndex = 0;
        }
        this.checkscrollBar(true);
      });
    }, 1);
  }

  getDiscount(selectedDiscount: ItemDiscount, quantity: number, packagedItemPrice) {
    return (selectedDiscount?.discountPercentage > 0 ?
      this._utilities.MidPointRoundOffTwo(quantity * packagedItemPrice * selectedDiscount?.discountPercentage / 100)
      : selectedDiscount?.discountAmount);
  }

  hideViewMore() {
    const productCatDiv = document.getElementsByClassName('items-collapse');
    _.forEach(productCatDiv, (product, index) => {
      if (!(product.scrollHeight > product.clientHeight + 1 || product.scrollWidth > product.clientWidth)) {
        document.getElementsByClassName('viewmore')[index].classList.add('hide');
      }
      else {
        document.getElementsByClassName('viewmore')[index].classList.remove('hide');
      }
    })
  }

  viewAll(index) {

    if (document.getElementsByClassName('items-ex'))
      if (document.getElementsByClassName('items-ex')[index]) document.getElementsByClassName('items-ex')[index].classList.add('items-collapse');
    this.viewmore[index] = -1

  }

  async getAllItems(): Promise<void> {
    if (this._shopservice.SelectedOutletId > 0) {
      this.cancelOngoingCall();
      await this.loadRetailItems();
    }
  }

  cancelOngoingCall() {
    this.notifier.next();
    this.notifier.complete();
  }
  CheckfromExistsinItemType = (): boolean => Object.values(ItemType).includes(this.from);
  


  async loadRetailItems(index?:number) {
    this.isLoading = true;
    this._utilities.ToggleLoader(true);
    try {
      const qsc = this.categorySelected.length !== 0 && !this.categorySelected.includes(0) ? this.categorySelected.join('&quickSaleCategory=') : '0';
      const searchTxt = this.searchByBarcode ? this.ShopFormGrp.controls.searchtextBarCode.value : this.ShopFormGrp.controls.searchtext.value;

      this.notifier = new Subject<void>();
      const queryParams = {
        outletId: this._shopservice.SelectedOutletId,
        includeInactive: false,
        pageStart: this.pageStart,
        pageLength: this.pageLength,
        sortBy: this.sortBy,
        isSortByAscending: this.isSortByAscending,
        quickSaleCategory: this.quickSaleCateroryId || qsc,
        searchText: encodeURIComponent(searchTxt),
        searchByBarcode: this.searchByBarcode,
        itemId: 0,
        showRentalItemOnly: this.ShopFormGrp.controls.ShowRentalItemOnly.value,
        itemType: this.from == ItemType.PMSAddOnsRentalItem ? RetailItemType.PMSAddOnsRentalItem : 0
      };
      
      const response = await this.http.cancellableObservalble<any>(
        {
          method: HttpMethod.Get,
          callDesc: 'GetShopItemByPagination',
          uriParams: queryParams,
          header: undefined,
          body: undefined,
          showError: false,
          host: GlobalConst.Host.retailManagement,
        }, this.notifier).toPromise();

      if (response) {
        this._utilities.ToggleLoader(false);
        this.AllShopItems = response.result;
        if (this._shopservice.selectedProducts && this._shopservice.selectedProducts.length > 0) {
          this.barcodeItemLoader = false;
        }
        this.isClickedViewMore || ( this.from == ItemType.PMSAddOnsRentalItem || this.from == ItemType.retailItem) ? this.AllShopItems.forEach(r => {
          if (this._shopservice.isReturnWithoutTicket) {
            if (!this._shopservice.AllShopItems.map(x => x.id).includes(r.id)) {
              this._shopservice.AllShopItems.push(r);
            }
          } else {
            this._shopservice.AllShopItems.push(r);
          }
        })
          : this._shopservice.AllShopItems = _.cloneDeep(this.AllShopItems);

        if (this.isClickedViewMore && index != null && this.quickSaleCateroryId != 0) {
          let count = 0;
          response.result.forEach(x => {
            let quickSaleCategoryData = this.RetailProducts.find(x => x.id == this.quickSaleCateroryId).data;
            if (!quickSaleCategoryData.some(y => x.id === y.itemId)) {
              count++;
              const itemImage = this.retailimages.find(img => img.referenceId === x.id);
              const quicksaleitem = this.quickSaleItems.find(p => p.itemId == x.id && p.quickSaleCategoryId == this.quickSaleCateroryId)
              quickSaleCategoryData.push({
                itemId: x.id,
                itemType: x.retailItemDetail.itemType,
                itemNumber: x.retailItemDetail.itemNumber,
                vendorName: x.retailItemVendor?.find(f => x.id == f.retailItemId)?.vendorName,
                itemDescription: x.retailItemDetail.itemDescription,
                title: x.retailItemDetail.itemDescription,
                dispPrice: x.retailItemDetail.salesPrice,
                price: x.retailItemDetail.salesPrice,
                isEditTriggered : false,
                isPriceOverridded: false,
                image: itemImage ? itemImage : '',
                multipack: x.retailItemDetail.isMultiPack,
                gratuity: x.retailItemDetail.gratuity,
                serviceCharge: x.retailItemDetail.serviceCharge,
                externanId: x.retailItemDetail.externalPOSId,
                isOpenItem: x.retailItemDetail.isOpenItem,
                isGiftCardItem: x.retailItemDetail.isGiftCardItem,
                isGiftCardCustomValueItem: x.retailItemDetail.isGiftCardCustomValueItem,
                isGroupingKey: x.retailItemDetail.isGroupingKey,
                category: x.retailItemDetail.category,
                isScaledItem: x.retailItemDetail.isScaledItem,
                barCodes: x.retailItemBarCode.map(b => b.barCode.toLowerCase()),
                isRequestName: x.retailItemDetail.isRequestName,
                isAllowPriceOverride: x.retailItemDetail.isAllowPriceOverride,
                isRequireComments: x.retailItemDetail.isRequireComments,
                costPrice: x.retailItemDetail.costPrice,
                marginPercentage: x.retailItemDetail.marginPercentage,
                allowEarn: x.retailItemDetail.allowEarn,
                listOrder: quicksaleitem ? quicksaleitem.listOrder : 0,
                perHourRentalRate: x.retailItemDetail.perHourRentalRate,
                perDayRentalRate: x.retailItemDetail.perDayRentalRate,
                isCategoryMapped: x.retailItemDetail.category != 0 && x.retailItemDetail.category != null,
                itemNotSynced: false,
                memberPrice: x.retailItemDetail.memberPrice,
                availableQty: 0,
                totalQty: 0,
                bufferTime: 0
              });
            }
          });  
          let productArray = this.ShopFormGrp.get('products') as UntypedFormArray;

          if (productArray && productArray.at(index) instanceof UntypedFormArray) {
            let subArray = productArray.at(index) as UntypedFormArray;          
            for (let i = 0; i < count; i++) {
              subArray.push(
                this.Form.group({
                  itemcount: new UntypedFormControl(1)
                })
              );
            }
            this.ShopFormGrp.updateValueAndValidity();
          }
        }
        const itemIds = this.isClickedViewMore ? this._shopservice.AllShopItems.map(x => x.id) : this.AllShopItems.map(x => x.id);
        this.AllActiveShopItems = this.getSellableRetailItems(this.isClickedViewMore ? this._shopservice.AllShopItems : this.AllShopItems);
        if (this.propertyInfo.UseGiftCardInterface) {
          this.AllActiveShopItems = this._giftCardBusiness.PopulateDefaultGiftCardValues(this.AllActiveShopItems);
        }
        this.loadRetailItemImages(itemIds);
        await this.getRentalInformation(itemIds);

        const isRevenueItemIncluded = this._shopservice.selectedProducts.some(x => x.ItemType && x.ItemType === RetailItemType.RevenueItem);
        const fixedDescItemTypes = [
          RetailItemType.RevenueItem
          , RetailItemType.Deposit
          , RetailItemType.SpaPackage
          , RetailItemType.SpaServices
          , RetailItemType.AppointmentAddon
        ];
        if(!this.isClickedViewMore){
          await this.initializeForm();
        }
        this.handleGiftCardsForReturnFlow(this._shopservice.isReturnWithoutTicket, true);
        //this.RemoveRestrictedItemsBasedOnTypeForAppointment();
        this._shopservice.selectedProducts.forEach(x => {

          if (!x.MultiPack) {
            const item = this.AllShopItems.filter(a => {
              return a.id === x.ItemId;
            });
            if (item?.length > 0) {
              const description = this._giftCardBusiness.AddCardNumberToProductName(x, item);
              const allowDescriptionChange = (isRevenueItemIncluded && item[0].retailItemDetail.itemType === RetailItemType.RevenueItem ||
                item[0].retailItemDetail.itemType === RetailItemType.Deposit)
                || (fixedDescItemTypes.includes(item[0].retailItemDetail.itemType));

              if (item && item.length > 0) {
                x.ExternalPOSItemId = item[0].retailItemDetail.externalPOSId;
                x.ItemDescription = allowDescriptionChange ? x.ItemDescription : description;
                x.ProductName = allowDescriptionChange ? x.ItemDescription : description;
              }
            }
          }
        });
      }


      this.RetailProducts.forEach(res => {
        if (res.id === this.quickSaleCateroryId) {
          if ((this.AllShopItems && this.AllShopItems.length === 0) || (this.AllShopItems && this.AllShopItems.length < this.pageLength)) {
            res.isViewMoreEnable = false;
          }
        }
        res.maxNumberOfItems = this.quickSaleItems.filter(y=>y.quickSaleCategoryId == res.id  && y.outletId == this._shopservice.SelectedOutletId).length;
        res.isViewMoreEnable = res.data.length < this.quickSaleItems.filter(y=>y.quickSaleCategoryId == res.id && y.outletId == this._shopservice.SelectedOutletId).length;
      });

      this.isClickedViewMore ? this.noOfProducts =
        this.RetailProducts.map((r, i) => {
          if (r && r.data && r.data.length && this.showViewLess[i]) {
            return r.data.length;
          } else {
            return this.noOfButtons;
          }
        }) :
        this.RetailProducts.forEach((res, i) => this.noOfProducts[i] = this.noOfButtons);
      this.RetailProducts.forEach((r, i) => {
        if (r.id == -1) {
          this.RetailProducts[i].data = _.orderBy(r.data, [item => item.itemDescription ? item.itemDescription.toLowerCase() : item.itemDescription])
        }
        else {
          this.RetailProducts[i].data = this.RetailProducts[i].data.sort((a, b) => a.listOrder - b.listOrder);
        }
        
      });
      this.isLoading = false;
      this.quickSaleCateroryId = 0;
      this.pageStart = 0;
      try {
        this._cdr.detectChanges();
      }
      catch (ex) { }
    } catch (ex) {
      //console.error(ex);
      this._utilities.ToggleLoader(false);
    }
  }
  async searchClose() {
    this.ShopFormGrp.controls.searchtextBarCode.setValue('');
    this.ShopFormGrp.controls.searchtext.setValue('');
    await this.loadRetailItems()
  }
  getSellableRetailItems(retailItems: any[]): any {
    if (retailItems) {
      return retailItems.filter(o => o.retailItemDetail.isActive && o.retailItemDetail.itemType !== RetailItemType.RevenueItem);
    }

    return [];
  }

  successCallback<T>(result: BaseResponse<T>, callDesc: string, extraParams: any[]): void {

    if (callDesc === 'AllQuickSale') {
      const quickSaleCategories = result.result ? result.result as any : {};
      this.categories = [];
      this.products = [];
      this.RetailProducts = [];
      this.categories.push({
        id: 0,
        name: this.localization.captions.shop.AllCategories
      });
      const outletId = this._retailService.SelectedOutletId;
      const quickSaleCategoryOutlets: QuickSaleCategoryOutlets[] = quickSaleCategories.category.map(x => x.quickSaleCategoryOutlets)?.flat();
      let outletSpecificCategoryOutlets = quickSaleCategoryOutlets.filter(x => x.outletId === outletId);
      if (outletSpecificCategoryOutlets.length > 0) {
        outletSpecificCategoryOutlets = outletSpecificCategoryOutlets.sort((a, b) => a.listOrder - b.listOrder);
        for (const category of outletSpecificCategoryOutlets) {
          const categoryDetail = quickSaleCategories.category.find(x => x.id === category.quickSaleCategoryId);
          if (categoryDetail) {
            this.categories.push({
              id: categoryDetail.id,
              name: categoryDetail.quickSaleCategory,
              listOrder: category.listOrder
            });

            this.products.push({
              id: categoryDetail.id,
              name: categoryDetail.quickSaleCategory,
              data: [],
              listOrder: category.listOrder
            });

          }


        }
      }

      // for (const quickCategory of quickSaleCategories.category) {
      //   if (quickCategory.quickSaleCategoryOutlets.length == 0) {
      //     this.categories.push({
      //       id: quickCategory.id,
      //       name: quickCategory.quickSaleCategory,
      //       listOrder: quickCategory.listOrder
      //     });

      //     this.products.push({
      //       id: quickCategory.id,
      //       name: quickCategory.quickSaleCategory,
      //       data: [],
      //       listOrder: quickCategory.listOrder
      //     });
      //   }

      // }

      if (this.categories && this.categories.length > 0) {
        this.categories.sort((a, b) => a.listOrder - b.listOrder);
      }
      if (this.products && this.products.length > 0) {
        this.products.sort((a, b) => a.listOrder - b.listOrder);
      }
      this.products.push({
        id: -1,
        name: this.localization.captions.shop.Others,
        hidden: true,
        data: []
      });

      this.quickSaleItems = quickSaleCategories.items ? quickSaleCategories.items : [];

      if (!this.isFromGlobalSearch) {
        this.getAllItems();
      } else if (this.isFromGlobalSearch) {
        this.formShopItemData(this.AllActiveShopItems);

        this.isClickedViewMore ? this.noOfProducts =
          this.RetailProducts.map((r, i) => {
            if (r && r.data && r.data.length && this.showViewLess[i]) {
              return r.data.length;
            } else {
              return this.noOfButtons;
            }
          }) :
          this.isLoading = false;
        this.RetailProducts.forEach(r => { if (r.id === -1) { r.hidden = false; } });
        this.RetailProducts.forEach((r, i) => {
          if (r.id == -1) {
            this.RetailProducts[i].data = _.orderBy(r.data, [item => item.itemDescription ? item.itemDescription.toLowerCase() : item.itemDescription])
          }
          else {
            this.RetailProducts[i].data = this.RetailProducts[i].data.sort((a, b) => a.listOrder - b.listOrder);
          }
        });
        setTimeout(() => this.isFromGlobalSearch = false, 500);
        this._cdr.detectChanges();
      }
    } else if (callDesc === 'GetUnitOfMeasures') {
      if (result.result) {
        this._shopservice.unitOfMeasures = this.unitOfMeasures = result.result;
      }
    } else if (callDesc === 'getAllImagesByReference') {
      this.retailimages = result.result as any;
      const value = [...this.mapImg(this.RetailProducts, this.retailimages)];
      this.RetailProducts = value;
    }
    this._utilities.ToggleLoaderWithMessage(false);
  }

  loadRetailItemImages = (retailItemIds) => {
    this.imgService.GetAllImagesByReference(retailItemIds, GlobalConst.ImgRefType.retailItem, false, this.successCallback.bind(this), this.errorCallback.bind(this), []);
  }

  async getRentalInformation(retailItemIds: number[]) {
    if (this.from == ItemType.PMSAddOnsRentalItem && this._shopservice.golfPlayerInfo.length > 0) {
      if(this.ShopFormGrp.controls.endTime.value == ''){
        this.RetailProducts = [];
      }
      else{
        const rentalStartTime = this.localization.AddTimeToDate(this._shopservice.golfPlayerInfo[0].time, this.localization.TimeToDateAllformat(this.ShopFormGrp.get('startTime')?.value));
        const rentalEndTime = this.localization.AddTimeToDate(this._shopservice.golfPlayerInfo[0].time, this.localization.TimeToDateAllformat(this.ShopFormGrp.get('endTime')?.value));
        const timeDifference = this.localization.getTimeDifference(rentalStartTime, rentalEndTime, 'Min')
        if (this.from == ItemType.PMSAddOnsRentalItem && this._shopservice.golfPlayerInfo.length > 0 && timeDifference >= 0) {
          let request: ShopRentalInfoRequest = {
            startDateTime: this.localization.convertDateTimeToAPIDateTime(rentalStartTime),
            endDateTime: this.localization.convertDateTimeToAPIDateTime(rentalEndTime),
            itemIds: retailItemIds,
            outletId: this._shopservice.SelectedOutletId
          }
          this.rentalInformation = await this._shopBusiness.getRentalInformation(request);
          const value = [...this.mapRentalInfo(this.RetailProducts, this.rentalInformation)];
          this.RetailProducts = value;
      }
        // this.UpdateForm(this.RetailProducts, false);
      }
    }

  }

  mapImg(retailItems: any[], images: any[]) {
    images.forEach(element => {
      retailItems.forEach(itemArr => {
        const item = itemArr.data.find(i => i.itemId === element.referenceId);
        if (item) {
          item['image'] = element;
        }
      });
    });

    return retailItems;
  }
  mapRentalInfo(retailItems: any[], rentalInformation: RentalInfoWithAvailability[]) {
    rentalInformation.forEach(element => {
      retailItems.forEach(itemArr => {
        const item = itemArr.data.find(i => i.itemId === element.retailItemId);
        if (item) {
          item.dispPrice = element.rate;
          item.price = element.rate;
          item.availableQty = element.availableQuantity;
          item.totalQty = element.totalQuantity;
          item.bufferTime = element.bufferTime;
          if (this.rentalItemCountMap.has(item.itemId)) {
            item.availableQty -= this.rentalItemCountMap.get(item.itemId);
          }
        }
      });
    });

    return retailItems;
  }

  errorCallback<T>(error: BaseResponse<T>, callDesc: string, extraParams: any[]): void {
    console.error(error.result);
    this._utilities.ToggleLoaderWithMessage(false);
  }


  createFormItem(index) {

    return this.createItem(index);
  }

  createItem(index) {

    const item: UntypedFormArray = this.Form.array([]);
    for (let i = 0; i < this.products[index].data.length; i++) {
      item.push(this.Form.group({
        itemcount: 1
      }))
    }
    return item;
  }

  addItem(index): void {
    this.item = this.ShopFormGrp.get('products') as UntypedFormArray;
    this.item.push(this.createFormItem(index));
  }


  async initializeForm() {

    if (!this.globalSearchParam) {
      this.resetControls();
    }

    for (let i = 0; i < this.products.length; i++) {
      this.addItem(i);
    }
    this.formShopItemData(this.AllActiveShopItems);
  }

  UpdateForm(filteredData, barCodeSearch: boolean) {
    const productArray = this.ShopFormGrp.get('products') as UntypedFormArray;
    this.resetControls();
    if (!this.isClickedViewMore) {
      this.showViewLess = [];
    }

    for (let i = 0; i < filteredData.length; i++) {
      productArray.push(this.UpdateFilterFormGroup(filteredData[i]));
      if (!this.isClickedViewMore) { this.showViewLess.push(false); }
    }

    if (barCodeSearch && productArray.value.length > 0 && productArray.value[0].length === 1 && productArray.value[0][0]) {
      this.triggerPricePrompt(filteredData[0].data[0], 0, 0, productArray.value[0][0].itemcount);
    }
  }

  UpdateFilterFormGroup(filteredData) {
    const productArray: UntypedFormArray = this.Form.array([]);

      for (let j = 0; j < filteredData.data.length; j++) {
        productArray.push(this.Form.group({
          itemcount: 1
        }));
      }

    return productArray;
  }

  resetControls() {
    const productArray = this.ShopFormGrp.get('products') as UntypedFormArray;
    const count: number = productArray.length;

    for (let i = 0; i < count; i++) {
      productArray.removeAt(0);
    }
  }

  formShopItemData(shopItems: ShopItem[]) {

    this.quickSaleItems = this.quickSaleItems ? this.quickSaleItems : [];
    this.products.forEach((x, i) => {
      !x.viewMoreclicked ? x.data = [] :
        x.data = this.withMoreItems[i]?.data;
    });

    for (let i = 0; i < this.quickSaleItems.length; i++) {

      const item = this.quickSaleItems[i];

      const shopItem = shopItems.filter(x => x.id === item.itemId);

      if (shopItem.length > 0 && item.outletId === this._retailService.SelectedOutletId) {

        shopItem.forEach(x => {
          const product = this.products.find(p => p.id === item.quickSaleCategoryId);
          const itemImage = this.retailimages.find(img => img.referenceId === x.id);
          const quicksaleitem = this.quickSaleItems.find(p => p.itemId == x.id && p.quickSaleCategoryId == item.quickSaleCategoryId)
          if (product && product.data && !product.viewMoreclicked) {
            let isItemCategoryMapped = false;
            if (x.retailItemDetail.category != 0 && x.retailItemDetail.category != null) {
              isItemCategoryMapped = true;
            }
            product.data.push({
              itemId: x.id,
              itemType: x.retailItemDetail.itemType,
              itemNumber: x.retailItemDetail.itemNumber,
              vendorName: x.retailItemVendor?.find(f => x.id == f.retailItemId)?.vendorName,
              itemDescription: x.retailItemDetail.itemDescription,
              title: x.retailItemDetail.itemDescription,
              dispPrice: x.retailItemDetail.salesPrice,
              price: x.retailItemDetail.salesPrice,
              isPriceOverridded: false,
              isEditTriggered: false,
              image: itemImage ? itemImage : '',
              multipack: x.retailItemDetail.isMultiPack,
              gratuity: x.retailItemDetail.gratuity,
              serviceCharge: x.retailItemDetail.serviceCharge,
              externanId: x.retailItemDetail.externalPOSId,
              isOpenItem: x.retailItemDetail.isOpenItem,
              isGiftCardItem: x.retailItemDetail.isGiftCardItem,
              isGiftCardCustomValueItem: x.retailItemDetail.isGiftCardCustomValueItem,
              isGroupingKey: x.retailItemDetail.isGroupingKey,
              category: x.retailItemDetail.category,
              isScaledItem: x.retailItemDetail.isScaledItem,
              barCodes: x.retailItemBarCode.map(b => b.barCode.toLowerCase()),
              isRequestName: x.retailItemDetail.isRequestName,
              isAllowPriceOverride: x.retailItemDetail.isAllowPriceOverride,
              isRequireComments: x.retailItemDetail.isRequireComments,
              costPrice: x.retailItemDetail.costPrice,
              marginPercentage: x.retailItemDetail.marginPercentage,
              allowEarn: x.retailItemDetail.allowEarn,
              listOrder: quicksaleitem ? quicksaleitem.listOrder : 0,
              perHourRentalRate: x.retailItemDetail.perHourRentalRate,
              perDayRentalRate: x.retailItemDetail.perDayRentalRate,
              isCategoryMapped: isItemCategoryMapped,
              itemNotSynced: false,
              memberPrice: x.retailItemDetail.memberPrice,
              availableQty: 0,
              totalQty: 0,
              bufferTime: 0
              //itemNotSynced: x.outletItem?.find(y => y.outletId == this._retailService.SelectedOutletId).syncStatus == SyncStatus.NOTSYNCED ? true : false
            });
          }
        });

      }
    }

    // add non-qk sale items
    // Get quickSale items of selected Outlet
    const selectedOutletQuickSaleItems = this.quickSaleItems.filter(x => x.outletId === this._retailService.SelectedOutletId);
    const qkItems: number[] = selectedOutletQuickSaleItems.map(x => x.itemId);

    const NonQkSaleItems: ShopItem[] = shopItems.filter(x => !qkItems.includes(x.id));
    const Otherproduct = this.products.find(x => x.id === -1);

    for (const x of NonQkSaleItems) {
      let isItemCategoryMapped = false;
      if (x.retailItemDetail.category != 0 && x.retailItemDetail.category != null) {
        isItemCategoryMapped = true;
      }
      const itemImage = this.retailimages.find(img => img.referenceId === x.id);
      Otherproduct && Otherproduct.data ? Otherproduct.data.push({
        itemId: x.id,
        itemType: x.retailItemDetail.itemType,
        itemNumber: x.retailItemDetail.itemNumber,
        vendorName: x.retailItemVendor.find(f => x.id == f.retailItemId)?.vendorName,
        itemDescription: x.retailItemDetail.itemDescription,
        title: x.retailItemDetail.itemDescription,
        dispPrice: x.retailItemDetail.salesPrice,
        price: x.retailItemDetail.salesPrice,
        isPriceOverridded: false,
        isEditTriggered: false,
        image: itemImage ? itemImage : '',
        multipack: x.retailItemDetail.isMultiPack,
        gratuity: x.retailItemDetail.gratuity,
        serviceCharge: x.retailItemDetail.serviceCharge,
        externanId: x.retailItemDetail.externalPOSId,
        isOpenItem: x.retailItemDetail.isOpenItem,
        isGiftCardItem: x.retailItemDetail.isGiftCardItem,
        isGiftCardCustomValueItem: x.retailItemDetail.isGiftCardCustomValueItem,
        isGroupingKey: x.retailItemDetail.isGroupingKey,
        category: x.retailItemDetail.category,
        isScaledItem: x.retailItemDetail.isScaledItem,
        barCodes: x.retailItemBarCode.map(b => b.barCode.toLowerCase()),
        isRequestName: x.retailItemDetail.isRequestName,
        isAllowPriceOverride: x.retailItemDetail.isAllowPriceOverride,
        isRequireComments: x.retailItemDetail.isRequireComments,
        costPrice: x.retailItemDetail.costPrice,
        marginPercentage: x.retailItemDetail.marginPercentage,
        allowEarn: x.retailItemDetail.allowEarn,
        perHourRentalRate: x.retailItemDetail.perHourRentalRate,
        perDayRentalRate: x.retailItemDetail.perDayRentalRate,
        isCategoryMapped: isItemCategoryMapped,
        itemNotSynced: false,
        memberPrice: x.retailItemDetail.memberPrice,
        availableQty: 0,
        totalQty: 0,
        bufferTime: 0,
        useInventory : x.retailItemDetail.useInventory
      }) : null;
    }
    this.RetailProducts = this.products;
    if (
      this.from == ItemType.PMSAddOnsRentalItem &&
      this._shopservice.golfPlayerInfo.length > 0 &&
      this.ShopFormGrp.controls.endTime.value == ""
    ) {
      this.RetailProducts = [];
    }
    this.globalSearchItems(this.ShopFormGrp.controls.searchtext.value);
  }


  globalSearchItems(name: string) {

    const searchtxt = name;
    const itemDetails = this.RetailProducts;

    if (itemDetails.length > 0) {
      if (searchtxt) {
        const item = itemDetails.find(x => x.id === -1);
        if (this.categorySelected.length === 0 || this.categorySelected[0].id === 0) {
          item.hidden = false;
        }

        this.RetailProducts = itemDetails.filter(x => {
          x.data = x.data.filter(y => {
            const itemPrice = y.price.customToFixed().toString();
            if (this.categorySelected.length === 0 || this.categorySelected[0].id === 0) {
              return (y.title.toLowerCase().includes(searchtxt.toLowerCase()) || itemPrice.toLowerCase().includes(searchtxt.toLowerCase())
                || y.itemNumber.toString().toLowerCase().includes(searchtxt.toLowerCase()) || y.vendorName?.toString().toLowerCase().includes(searchtxt.toLowerCase())
                || y.barCodes.includes(searchtxt.toLowerCase()));
            } else {
              return ((this.categorySelected.some(r => x['id'] == r) || (-1 == x['id']) || this.searchByBarcode)
                && (y.title.toLowerCase().includes(searchtxt.toLowerCase())
                  || itemPrice.toLowerCase().includes(searchtxt.toLowerCase())
                  || y.itemNumber.toString().toLowerCase().includes(searchtxt.toLowerCase()) || y.vendorName?.toString().toLowerCase().includes(searchtxt.toLowerCase())
                  || y.barCodes.includes(searchtxt.toLowerCase())));
            }
          });

          if (x.data.length > 0) {
            return x;
          }
        });
      } else {
        const item = itemDetails.find(x => x.id === -1);
        if (item) {
          item.hidden = true;
        }

        if (this.categorySelected.length === 0 || this.categorySelected[0].id === 0) {
          this.RetailProducts = itemDetails;
        }
        else if (this.searchByBarcode) {
          this.RetailProducts = itemDetails.filter(f => f.data.length > 0);
        } else {
          this.RetailProducts = itemDetails.filter(x => (this.categorySelected.some(r => x['id'] === r)));
        }
      }
      this.RetailProducts.forEach((r, i) => {
        if (r.id == -1) {
          this.RetailProducts[i].data = _.orderBy(r.data, [item => item.itemDescription ? item.itemDescription.toLowerCase() : item.itemDescription])
        }
        else {
          this.RetailProducts[i].data = this.RetailProducts[i].data.sort((a, b) => a.listOrder - b.listOrder);
        }
      });
      this.UpdateForm(this.RetailProducts, false);
    }

  }





  getCategoryname(id: number): string {

    const category = this.categories.filter(x => { return x.id == id });
    if (category && category.length > 0) return category[0].name;
    return "";
  }

  getAllQuickSaleCategories() {

    this.http.CallApiWithCallback<any>({
      host: GlobalConst.Host.retailManagement,
      success: this.successCallback.bind(this),
      error: this.errorCallback.bind(this),
      callDesc: "AllQuickSale",
      method: HttpMethod.Get,
      showError: true,
      extraParams: []
    });
  }

  handleSelectedTabChange(event: MatTabChangeEvent): void {

    this._shopservice.retailTransaction.tabState = this.selectedTabIndex = event.index;

    if (this.selectedTabIndex == 1)
      this.transactions = !this.transactions;
  }

  checkscrollBar(data?: any) {
    try {
      this.fromViewShop = true;
    }
    catch (e) { this.fromViewShop = true; }
  }

  BuyItems(): void {
    if (!this.hasAccessToCreateTransaction()) return;
    if (this._shopservice.SelectedTerminalId == 0 || Number(this.ShopFormGrp.controls.terminal.value) == 0) {
      this._utilities.ShowErrorMessage(this.localization.captions.common.Error, this.captions.selectTerminal, GlobalConst.ButtonType.Ok);
      return;
    }
    this._shopservice.isFromBuy = true;
    let linenumber = 1;
    if (this._shopservice.selectedProducts.length > 0) {
      this._shopservice.selectedProducts.forEach(product => {
        product.LineNumber = linenumber;
        linenumber = linenumber + 1;
      });
      let hasMultiPackItem = this._shopservice.selectedProducts.some(x => x.MultiPack); // Only if Multipack item exists in Line item.
      if (this.functionalities[GlobalConst.RetailFunctionalities.ApplyRevisedMultiPackLogic] && hasMultiPackItem) {
        this.InvokeTaxforMultipack();
      }
    }
    this.quickLoginPopupEnabled = false; //Resetting the flag as the quick login popup should be shown once per transaction.
    this._utilities.RedirectTo(RedirectToModules.order);
  }

  async InvokeTaxforMultipack() {
    let ticket: Ticket = await this.transactionEngineBusiness.CreateTaxforMultipack(
      this._shopservice.SelectedOutletId,
      this._shopservice.selectedProducts,
      this._shopservice.settleOpenTransaction
    );

    if (ticket) {
      ticket?.lineItems?.forEach(itm => {
        let selectedProduct: SelectedProducts = this._shopservice.selectedProducts.filter(x => x.ItemId == itm.itemId && x.LineNumber == itm.index && x.MultiPack)[0];
        if (selectedProduct) {
          selectedProduct.ProductPrice = selectedProduct.costPrice = itm.unitPrice;
          selectedProduct.DisplayPrice = itm.unitPrice.toString();
          selectedProduct.MemberPrice = selectedProduct.MemberPrice == 0 ? itm.memberUnitPrice : selectedProduct.MemberPrice;
          selectedProduct.multipackCustomFee = itm.multipackCustomFee;
          selectedProduct.multipackMemberCustomFee = itm.multipackMemberCustomFee;
        }
      });
    }

  }


  Exchangetems() {

    this._shopservice.selectedExchangeRetailProducts = this._shopservice.selectedProducts;
    this._shopservice.sectionName = 'RIS'; // Retail Item Summary
  }
  goback() {
    this._shopservice.sectionName = 'RTID'; //Retail transaction Item Detail
  }


  async outletChange(arg) {
    this._retailService.SelectedOutletId = this._shopservice.SelectedOutletId = arg.value;
    this.getAllQuickSaleCategories();
    this.LoadReceiptConfigurationAsync();
    this.isClickedViewMore = false;
    await this.getAllItems();
    if (this.propertyInfo.UseRetailInterface) {
      this._shopservice.SelectedTerminalId = this._retailService.SelectedTerminalId = 0;
      this.ShopFormGrp.controls['terminal'].setValue(this._shopservice.SelectedTerminalId.toString());
      this.loadOutletTerminals();
    }

    for (let element of this.showViewLess) {
      element = false;  // Noncompliant
    }
    this.isClickedViewMore = false;

    this.UpdateViewMoreButtonState();
    if(window.innerWidth <= 767){
      this.loadRetailItemsForMobile();
    }
  }

  async loadOutletTerminals() {
    const terminals: BaseResponse<StoreTerminal[]> = await this.InvokeServiceCallAsync('GetStoreTerminal', GlobalConst.Host.retailManagement, HttpMethod.Get, { outletId: this._shopservice.SelectedOutletId });
    this.outletTerminals = terminals.result && terminals.result.length > 0 ? terminals.result : [];
  }

  terminalChange(terminalId: number) {
    this._retailService.SelectedTerminalId = this._shopservice.SelectedTerminalId = Number(terminalId);
  }

  ShowItemPrice(product: SelectedProducts): string {
    let orgAmount = 0;
    let price: string = '';
    const totalprice = (product.Noofitems * product.ProductPrice)
    if (product.Discount) {
      let perItemDiscount = product.DiscountPercentage > 0 ? this._utilities.MidPointRoundOffTwo(product.DiscountPercentage * totalprice / 100) : product.Discount;
      perItemDiscount = (perItemDiscount > totalprice) ? totalprice : perItemDiscount;
      orgAmount = totalprice - perItemDiscount;
    }
    else {
      orgAmount = totalprice;
    }

    if (orgAmount < 0) {
      price = `(${this.localization.localizeCurrency(orgAmount * -1)})`;
    }
    else {
      price = this.localization.localizeCurrency(orgAmount);
    }
    return price;
  }
  trimunwantedCharacter(str) {
    return str.replace(/-|\(|\)/g, function () { return ""; });
  }

  callHttpServiceWithCallBack(host: GlobalConst.Host, callDesc: string, method: HttpMethod, uriParam: any = undefined, showError: boolean = true, extraParams: any = []): void {
    this.http.CallApiWithCallback<any>({
      host: host,
      success: this.successCallback.bind(this),
      error: this.errorCallback.bind(this),
      callDesc: callDesc,
      method: method,
      uriParams: uriParam,
      showError: showError,
      extraParams: extraParams
    });
  }

  async InvokeServiceCallAsync(route: string, domain: GlobalConst.Host, callType: HttpMethod, uriParams?: any, body?: any): Promise<BaseResponse<any>> {
    try {
      return await this.http.CallApiAsync({
        host: domain,
        callDesc: route,
        method: callType,
        body: body,
        uriParams: uriParams,
      });
    } catch (e) {
      this.http.exceptionHandle(e);
    }
  }

  async LoadOutlets() {
    let userMachineConfig = null;
    if (this._shopservice.ProductId == GlobalConst.Product.GOLF || this._shopservice.ProductId == GlobalConst.Product.SNC) {
      userMachineConfig = this._utilities.getUserDefaults();
    } else {
      userMachineConfig = this._utilities.getLoggedinUserSessionConfiguration();
    }
    if (userMachineConfig && this._shopservice.selectedProducts.length == 0) {
      if (!this._shopservice.SelectedOutletId) {
        this._shopservice.SelectedOutletId = userMachineConfig.defaultOutletId;
        this._retailService.SelectedOutletId = userMachineConfig.defaultOutletId;
      }
      if (!this._shopservice.SelectedTerminalId)
        this._shopservice.SelectedTerminalId = userMachineConfig.defaultTerminalId;
    }
    this.outlets = [];
    const apiResponse: BaseResponse<OutletSubProperty[]> = await this.subPropertyAccessByUser_apiResponse;
    if (apiResponse && apiResponse.successStatus && apiResponse.result && apiResponse.result.length > 0) {
      apiResponse.result.forEach(e => {
        if (e.isActive) {
          this.outlets.push(e);
        }
      });
      this._shopservice.AllOutlets = this.outlets;
      if (!this.functionalities[GlobalConst.RetailFunctionalities.ShowOutletSelectionFieldInShopScreen]) {
        const outletId = this.defaultOutlet > 0 ? this.defaultOutlet : (this._shopservice.AllOutlets || [])[0]?.subPropertyID;
        this._shopservice.SelectedOutletId = outletId;
        this._retailService.SelectedOutletId = outletId;
      }
      if (this.propertyInfo.UseRetailInterface) {
        const terminals = await this.InvokeServiceCallAsync('GetStoreTerminal', GlobalConst.Host.retailManagement, HttpMethod.Get, { outletId: this._shopservice.SelectedOutletId });
        this.outletTerminals = terminals.result && terminals.result.length > 0 ? terminals.result : [];
        if (this.outletTerminals.includes(userMachineConfig.defaultTerminalId))
          this._retailService.SelectedTerminalId = this._shopservice.SelectedTerminalId = Number(userMachineConfig.defaultTerminalId);
        this.ShopFormGrp.controls.terminal.enable();
      } else {
        this._shopservice.SelectedTerminalId = this._retailService.SelectedTerminalId = 1;
      }
    } else {
      this._retailService.SelectedOutletId = this._shopservice.SelectedOutletId = 0;
    }
    if (this.outlets.length === 0) {
      this._retailService.SelectedOutletId = this._shopservice.SelectedOutletId = 0;
    }
  }
  ValidateAccess(event) {
    if (event[0] && !this.breakPoint.CheckForAccess([GlobalConst.RetailBreakPoint.ReturnWithoutTicket])) {
      setTimeout(() => {
        this._shopservice.isReturnWithoutTicket = false;
        this.handleGiftCardsForReturnFlow(false);
        //this.RemoveRestrictedItemsBasedOnTypeForAppointment();
      }, 1);
    }
    setTimeout(() => {
      this._shopservice.isReturnWithoutTicket = event[0];
      this.FillDiscountConfigEligibility();
      this.handleGiftCardsForReturnFlow(event[0]);
      //this.RemoveRestrictedItemsBasedOnTypeForAppointment();
    }, 2);
  }

  LoadRentalItems(event) {
    this.SearchbyInput(this.ShopFormGrp.controls.searchtextBarCode.value, this.ShopFormGrp.controls.searchtext.value, '');
  }

  // OnDiscountPopOverClick(arg, data, idx, applyAllPopover) {
  //   if (data.isGroupingKey) {
  //     applyAllPopover.hide();
  //     this._utilities.ShowErrorMessage(this.localization.captions.common.Warning, this.captions.PackagedItemDiscountWarning, GlobalConst.ButtonType.Ok, this.PackagedItemDiscountCallBack.bind(this), [arg, data, idx, applyAllPopover]);
  //     return;
  //   }
  //   this.positionPopover(arg, data, idx, applyAllPopover);
  // }


  OnDiscountpopClick(arg, data, idx, applyAllPopover) {
    let discountBr = [] = this._shopservice.isFromTherapistPortal ? [GlobalConst.TherapistScheduleView.ApplyDiscount] : [GlobalConst.RetailBreakPoint.ApplyDiscount];
    if (!this.breakPoint.CheckForAccess(discountBr)) {
      return;
    }
    this.currentSelectedItemForDiscount = data;
    this.discountInput = {
      CategoryId: data.category,
      SelectedDiscount: this._shopservice.SelectedItemDiscount.find(x => x.itemId == data.itemId),
      isMultipleItems: false,
      showItemDetails: true,
      itemName: data.itemDescription,
      itemPrice: data.dispPrice,
      costPrice: data.costPrice,
      isCostOrCostPlusEligible: this.ItemEligibleForCostOrCostPlus[data.category]
    }

    this.dialog.open(ApplyDiscountComponent, {
      width: '700px',
      height: '480px',
      data: {
        discountInput: this.discountInput,
        validateMinimumAmountInCart: false
      },
      disableClose: true
    }).afterClosed().subscribe(result => {
      this.searchTextBarCode.focus()
      if (result) {
        this.applyDiscount(result)
      }
    });
  }

  PackagedItemDiscountCallBack(result: string, extraParams) {
    extraParams[3].show();
    this.positionPopover(extraParams[0], extraParams[1], extraParams[2], extraParams[3]);
  }


  positionPopover(arg, data, idx, applyAllPopover) {
    if (!this.breakPoint.CheckForAccess([GlobalConst.RetailBreakPoint.ApplyDiscount])) {
      applyAllPopover.hide();
      return;
    }
    if (idx === this.openedIdx) {
      const midPointOfPop = 150;
      const widthtillArrow = 300 * 0.85;
      const arrowPositionFromMid = 45;
      this.xPos = arrowPositionFromMid - midPointOfPop;
      this.isRight = false;
      const leftPosition = arg.target.closest('.product-desc').offsetLeft + arg.target.offsetLeft;
      if (leftPosition < widthtillArrow) {
        this.xPos = arg.target.offsetLeft - arg.target.closest('.product-desc').offsetLeft - (0.17 * 300);
        this.isRight = true;
      }
    }
    this.currentSelectedItemForDiscount = data;
    this.discountInput = {
      CategoryId: data.category,
      SelectedDiscount: this._shopservice.SelectedItemDiscount.find(x => x.itemId == data.itemId),
      isMultipleItems: false,
      itemName: data.itemDescription,
      showItemDetails: true,
      itemPrice: data.productPrice,
      costPrice: data.costPrice,
      isCostOrCostPlusEligible: this._shopservice.ItemEligibleForCostOrCostPlus[data.category]
    }
  }

  applyDiscount(discount: ItemDiscount) {
    if (discount.discountAmount < 0) {
      this._utilities.ShowErrorMessage(this.localization.captions.common.Error, this.captions.InvalidAmount);
      return;
    }
    this.AddDiscountData(discount);
  }


  AddDiscountData(discount: ItemDiscount) {
    const data = this.currentSelectedItemForDiscount;
    if(discount.isCostPlusDiscount){
        let total = 0;
        let amount = discount.costPlusDiscountDetails.value;
        total = discount.costPlusDiscountDetails.isPercentage ? Number((this.discountInput.costPrice + (this.discountInput.costPrice * amount) / 100).customToFixed()) : Number((this.discountInput.costPrice + amount).customToFixed());
        discount.discountAmount = this.discountInput.itemPrice - total;
        discount.isPercentage = false;
    }
    else if( discount.isCostDiscount){
      discount.discountAmount = Number((this.discountInput.itemPrice - this.discountInput.costPrice).customToFixed());
      discount.isPercentage = false;
    }
    if (this._shopservice.SelectedItemDiscount.length > 0 && this._shopservice.SelectedItemDiscount.some(x => x.itemId == data.itemId)) {
      const itemDiscount = this._shopservice.SelectedItemDiscount.find(x => x.itemId == data.itemId);
      itemDiscount.discountId = discount.discountId;
      itemDiscount.discountAmount = this._utilities.MidPointRoundOffTwo(discount.discountAmount);
      itemDiscount.discountPercentage = discount.discountPercentage;
      itemDiscount.discountReason = discount.discountReason;
      itemDiscount.discountComment = discount.discountComment;
      itemDiscount.isCostPlusDiscount = discount.isCostPlusDiscount;
      itemDiscount.isCostDiscount = discount.isCostDiscount;
      itemDiscount.costPlusDiscountDetails = discount.costPlusDiscountDetails;
    }
    else {
      discount.itemId = data.itemId;
      this._shopservice.SelectedItemDiscount.push(discount);
    }

  }

  AmountExceedCallback(result: string, extraParams) {
    if (result.toUpperCase() == "YES") {
      this.openScaledItemsDialog(extraParams[0], extraParams[1], extraParams[2]);
    }
  }

  setIdx(idx, i) {
    this.isOpened = true;
    if (this.openedIdx == -1) {
      this.openedIdx = idx;
      const shopDiscount = document.querySelectorAll('.product-desc-wrapper')[i].querySelectorAll('.shop-discount')[this.openedIdx] as HTMLElement;
      this.discountPopover.top = shopDiscount.offsetTop + 35;
    }
  }

  clearIdx() {
    this.isOpened = false;
    this.openedIdx = -1;
  }

  onScroll(evt) {
    if (evt && evt.target) {

      const scrollHeight = evt.target.scrollHeight;
      const eleHeight = evt.target.offsetHeight;
      const bufferHeight = scrollHeight * (60 / 100); // Scroll event is fired when screen reached
      if (this.yPos + eleHeight >= bufferHeight) {
        console.log('fetch next set of records');
        console.log('catogories selected', this.categorySelected);
        console.log('bar Code search text', this.ShopFormGrp.controls.searchtextBarCode.value);
        console.log('search text', this.ShopFormGrp.controls.searchtext.value);
        console.log('return toggle value', this.ShopFormGrp.controls.returnServiceFlag.value);
      }
    }
  }

  hasAccessToCreateTransaction() {
    if (!this.hasAccessToCreateTran) {
      this.breakPoint.showBreakPointPopup(this.localization.captions.breakpoint[GlobalConst.RetailBreakPoint.CreateTransaction]);
      return false;
    }

    return true;
  }

  async checkUserHasOutletAccess() {
    this.subPropertyAccessByUser_apiResponse = this.InvokeServiceCallAsync('GetSubPropertyAccessByUser', GlobalConst.Host.retailManagement, HttpMethod.Get, { userId: this._utilities.GetPropertyInfo("UserId") });
    const apiResponse: BaseResponse<OutletSubProperty[]> = await this.subPropertyAccessByUser_apiResponse;
    if (apiResponse && apiResponse.successStatus && apiResponse.result && apiResponse.result.length == 0) {
      return false;
    }
    return true;
  }

  async ResetServiceFlagsAfterLoad() {
    this._shopservice.isFromContinueShopping = false;
    this._shopservice.isReturnWithoutTicket = false;
    this._shopservice.addRetailItemToSource = false;
    this._shopservice.isFromTherapistPortal = false;
  }

  DiscardTicket() {
    if (this.propertyInfo.UseRetailInterface && this.Ticket && this.Ticket.checkData) {
      this.transactionService.DiscardCheck(this.Ticket.checkData);
      this.Ticket = null;
    }
  }

  AddGiftCardToCart(popupData, extraParams) {
    console.log({ popupData });
    console.log({ extraParams });
    const giftCardData: IssueGiftCardPopupModel = popupData;
    const product: ItemForShop = _.cloneDeep(extraParams[0]);
    const handleInfo: HandleResponse = giftCardData.HandleInfo as any;
    if (giftCardData) {
      product.price = product.dispPrice = giftCardData.Amount;
      product.title = `${product.title} ${giftCardData.GiftCardNumber}`;
      product.itemComments = giftCardData.itemComments;
      product.GiftCardTransactionItem = {
        amount: giftCardData.Amount,
        firstName: giftCardData.FirstName,
        lastName: giftCardData.LastName,
        email: giftCardData.EmailId,
        phoneNumber: giftCardData.PhoneNumber,
        cardNumber: giftCardData.GiftCardNumber,
        cardType: giftCardData.thirdparty ? GlobalConst.GiftCardType.ExternalGiftCard : GlobalConst.GiftCardType.V1GiftCard,
        expiryDate: giftCardData.NeverExpire ? null : giftCardData.ExpiryDate,
        handleInfo: giftCardData.HandleInfo,
        transactionType: !handleInfo.isCardActive ? GlobalConst.GiftCardTransactionType.Issue : GlobalConst.GiftCardTransactionType.Load,
        isIssue: !handleInfo.isCardActive
      } as GiftCardShopDetails;
      if (this.CheckMaxCardsPerTransactionLimitExceeded(product.GiftCardTransactionItem)) { return; }
      product.isGiftCardItem = true;
      const rowindex: number = extraParams[1];
      const index: number = extraParams[2];
      this.addProduct(product, rowindex, index, true);
    }
  }

  CheckMaxCardsPerTransactionLimitExceeded(giftcardInfo: GiftCardShopDetails): boolean {
    const GiftCardsInCart: number = this._shopservice.selectedProducts && this._shopservice.selectedProducts.filter(x => x.isGiftCardItem).length;
    if (!giftcardInfo
      && (this._retailFeatureFlagInfo.ExternalGiftcardMaxLimitPerTransaction || this._retailFeatureFlagInfo.V1GiftcardMaxLimitPerTransaction)
      && GiftCardsInCart >= (this._retailFeatureFlagInfo.ExternalGiftcardMaxLimitPerTransaction + this._retailFeatureFlagInfo.V1GiftcardMaxLimitPerTransaction)) {
      this._utilities.showAlert(this.captions.GiftCard.MaxGiftCardsPerTransactionValidationMsg, AlertType.Warning, GlobalConst.ButtonType.Ok);
      return true;
    }
    if (!giftcardInfo) { return }
    //Check number of Giftcards Added to cart
    const MAX_GIFTCARDS_PER_TRANSACTION = giftcardInfo?.cardType == GlobalConst.GiftCardType.ExternalGiftCard ?
      this._retailFeatureFlagInfo.ExternalGiftcardMaxLimitPerTransaction :
      this._retailFeatureFlagInfo.V1GiftcardMaxLimitPerTransaction;
    const isLimitExceed: boolean = (GiftCardsInCart >= MAX_GIFTCARDS_PER_TRANSACTION);
    const allowToAddIntoCart = (GiftCardsInCart < MAX_GIFTCARDS_PER_TRANSACTION);
    if (isLimitExceed || !allowToAddIntoCart) { // Show Warning when max number of card added
      this._utilities.showAlert(this.captions.GiftCard.MaxGiftCardsPerTransactionValidationMsg, AlertType.Warning, GlobalConst.ButtonType.Ok);
    }
    return !allowToAddIntoCart;
  }

  handleGiftCardsForReturnFlow(isReturnWithoutTicket, isFrmSearch: boolean = false) {
    if (isReturnWithoutTicket) {
      if (this.RetailProducts && this.RetailProducts.length > 0) {
        this.RetailProducts.forEach(product => {
          if (product.data.some(x => x.isGiftCardItem)) {
            product.data = product.data.filter(x => !x.isGiftCardItem);
          }
        });
      }
    } else {
      if (this.propertyInfo.UseGiftCardInterface && !this.RetailProducts.some(product => product.data.some(x => x.isGiftCardItem))) {
        this.AllActiveShopItems = this._giftCardBusiness.PopulateDefaultGiftCardValues(this.AllActiveShopItems);
        if (!isFrmSearch) {
          this.initializeForm();
        }
      }
    }
  }
  //For chickasaw not using this method
  RemoveRestrictedItemsBasedOnTypeForAppointment() {
    if (this._shopservice.isFromAppointment) {
      const restrictedItemTypes = [
        RetailItemType.AppointmentAddon,
        RetailItemType.Deposit,
        RetailItemType.RetailItemAvailableForSpaPackages,
        RetailItemType.SpaPackage,
        RetailItemType.SpaServices
      ];
      if (this.AllActiveShopItems.some(x => restrictedItemTypes.includes(x.retailItemDetail.itemType)
        && !x.retailItemDetail.isGiftCardItem
        || x.retailItemDetail.isGiftCardItem || x.retailItemDetail.isMultiPack)) {
        //Filter based on ItemType, multipack and non giftcard item
        this.AllActiveShopItems = this.AllActiveShopItems.filter(x => !restrictedItemTypes.includes(x.retailItemDetail.itemType)
          && !x.retailItemDetail.isGiftCardItem
          && !x.retailItemDetail.isMultiPack);

        //Refresh the grid object
        this.initializeForm();
      }
    }
  }

  async getMoreRetailItems(product: any, i) {
    if (!this.isLoading) {
      this.isClickedViewMore = true;
      const totalRetailItems = product.data && product.data.filter(r => !r.isGiftCardItem);
      const totalGiftItems = product.data && product.data.filter(r => r.isGiftCardItem && !r.isGiftCardCustomValueItem);
      this.pageStart = totalRetailItems && totalRetailItems.length ? totalRetailItems.length : 0;
      this.pageStart = this.pageStart + totalGiftItems.length;
      this.quickSaleCateroryId = product.id;
      this.showViewLess[i] = true;
      await this.loadRetailItems(i);
      this.RetailProducts[i]['viewMoreclicked'] = true;
      this.withMoreItems = _.cloneDeep(this.RetailProducts);
      this.UpdateViewMoreButtonState();
    }
  }

  UpdateViewMoreButtonState(index?) {
    if(window.innerWidth > 767){
      if (!index) { index = 0; }
      if (this.RetailProducts[index] && (this.RetailProducts.length === 1 ||
        (this.RetailProducts.length === 2 && this.RetailProducts.some(x => x.hidden))) &&
        (this.showViewLess[index] || this.noOfButtons !== this.originalNoOfButtons * 2) &&
        this.noOfProducts[index] !== this.RetailProducts[index].data.length) {
        this.noOfButtons = this.originalNoOfButtons * 2;
        this.RetailProducts.forEach((res, i) => this.noOfProducts[i] = this.noOfButtons);
      } else if (((this.RetailProducts.length === 2 && !this.RetailProducts.some(x => x.hidden)) || this.RetailProducts.length > 2) && !this.isCheckWidth) {
        this.isCheckWidth = true;
        this.checkProductsWidth();
      }
      this.RetailProducts.forEach((res, i) => {
        const currentQuickSaleItems = this.quickSaleItems.filter(x => x.quickSaleCategoryId == res.id && x.outletId == this._shopservice.SelectedOutletId);
        if ((currentQuickSaleItems && res.data.length < currentQuickSaleItems.length)) {
          res.isViewMoreEnable = true;
        }
        else if ((res.data && res.data?.length && res.data.length > this.noOfButtons && this.noOfProducts[i] < res.data.length)) {
          res.isViewMoreEnable = true;
        }
        else {
          res.isViewMoreEnable = false;
        }
      });
    }
    if (this.from == this.itemTypeEnum.PMSAddOnsRentalItem) {
      const value = [...this.mapRentalInfo(this.RetailProducts, this.rentalInformation)];
      this.RetailProducts = value;
    }
  }

  async addToReservation() {
    this._utilities.ToggleLoader(true);
    this._shopservice.isFromContinueBooking = true;
    this._retailService.selectedProducts = _.cloneDeep(this._shopservice.selectedProducts);
    this._retailService.selectedProducts = _.cloneDeep(this._shopservice.selectedProducts);
    this._retailService.isReset = this._retailService.selectedProducts.length == 0;
    // calculate Rental Item price
    if (this._shopservice.selectedProducts.some(s => s.ItemType === RetailItemType.RentalItem)) {
      this._shopservice.selectedProducts.filter(s => s.ItemType === RetailItemType.RentalItem).forEach(f => {
        f.ProductPrice = (f.rentType === 1 ? f.perDayRentalRate : f.perHourRentalRate) * Number(f.period) * f.Noofitems;
      });
    }

    // const ticketInfo = await this.transactionService.CreateTicket(this._shopservice.selectedProducts, false);
    // (this._retailService.selectedProducts || []).forEach(x => {
    //   const checkItem = ticketInfo.lineItems.find(y => y.externalPOSItemId == x.ExternalPOSItemId && y.index == x.LineNumber);
    //   if (checkItem) {
    //     x.Tax = checkItem.tax;
    //   }
    // });

    this._shopservice.selectedProducts.forEach(x => {
      x['linkedRetailItemGuid'] = x['linkedRetailItemGuid'] && x['linkedRetailItemGuid'] !== '' ? x.linkedRetailItemGuid : this._utilities.generateGUID();
    });

    if (this.saveCart && this.linkedRetailItem) {
      const body = this.linkedRetailItem;
      body.linkedRetailItemDetails = JSON.stringify(this._shopservice.selectedProducts);
      body.linkUpdatedDate = this.localization.getCurrentDate();
      body.linkCreatedDate = body?.id ? body.linkCreatedDate : this.localization.getCurrentDate();
      // await this.InvokeServiceCallAsync('LinkedRetailItems', GlobalConst.Host.retailPOS, HttpMethod.Put, { sourceType: '0', sourceTypeId: Number(this.stayId) }, body);
    }

    if (this.stayId && this._retailService.isFromReservation) {
      this.postResvShopRetailItems();
    } else {
      this.redirecttoReservation();
    }
  }

  async postResvShopRetailItems() {
    const selectedProducts = this._shopservice.selectedProducts;
    const returnProducts: StayRetailItemInfo[] = [];
    selectedProducts.forEach(element => {
      element.productDetails.map(x => {
        x.startDate = this.localization.convertDateObjToAPIdate(x.startDate);
        x.endDate = this.localization.convertDateObjToAPIdate(x.endDate);
      })
      element.productDetails.map(prod => {
        const stayDates = this.localization.getDatesForGivenRange(this.localization.getDate(prod.startDate), this.localization.getDate(prod.endDate));
        stayDates.map(stayDate => {
          const stayRetailItemInfo = {
            stayId: this.stayId,
            itemId: element.ItemId,
            staydate: this.localization.convertDateObjToAPIdate(stayDate.dateObj),
            quantity: prod.quantity,
            price: prod.price,
            hours: prod.hours,
            itemDetails: JSON.stringify(element),
            comments: prod.comments
          } as StayRetailItemInfo;
          returnProducts.push(stayRetailItemInfo);
        });
      });
    });
    const response = await this.InvokeServiceCallAsync('CreateStayRetailItemInfos', GlobalConst.Host.reservation, HttpMethod.Post, [], returnProducts);
    if (response.result) {
      this.redirecttoReservation();
    } else {
      this._utilities.ToggleLoader(false);
      this._utilities.showAlert(this.localization.captions.alertPopup.error, AlertType.Error, GlobalConst.ButtonType.Ok);
    }
  }

  redirecttoReservation() {
    this._utilities.ToggleLoader(false);
    this.isUpdated = this.groupList.some(group => group.retailItems.length > 0);
    if(this._retailFeatureFlagInfo.IsRentalEnabled){
      this.isUpdated = this._shopservice.rentalItemGroupList.some(group => group.retailItems.length > 0) || this.isUpdated;
    }
    let alertmessage = this.isFromTeeTimeAddRetailItem ? this.updateItemsToAppt ? this.isUpdated ? this.localization.captions.shop.ItemsSuccessfullyUpdated : this.localization.captions.shop.ItemsSuccessfullyDeleted : this.localization.captions.shop.ItemsSuccessfullyAdded : this.localization.captions.alertPopup.successfullysaved;
    this._utilities.showAlert(alertmessage, AlertType.Success, GlobalConst.ButtonType.Ok, res => {
      if (res) {
        let redirectUrl = this._retailService.RedirectModule;
        if (!this._retailService.RedirectModule) {
          redirectUrl = this.isFromTeeTimeAddRetailItem ? RedirectToModules.TeeTime : RedirectToModules.BackToPrevLocation;
        }
        this._utilities.RedirectTo(redirectUrl);
      }
    });
  }

  cancelToReservation(resetRequired: boolean) {
    this._shopservice.selectedProducts = _.cloneDeep(this.clonedProducts);
    this._shopservice.isFromContinueBooking = true;
    if (resetRequired) {
      this._shopservice.selectedProducts = [];
    }
    let redirectUrl = this._retailService.RedirectModule;
    if (!this._retailService.RedirectModule) {
      redirectUrl = RedirectToModules.BackToPrevLocation;
    }
    this._utilities.RedirectTo(redirectUrl);
  }

  togglerSlider(e) {
    if(window.innerWidth >=767){
      this.togglePanel = !this.togglePanel;
      setTimeout(() => { this.checkProductsWidth(); }, 1000);  
    }
  }
  togglerCategories(e) {
    this.toggleCategories = !this.toggleCategories;
    setTimeout(() => { this.checkProductsWidth(); }, 1000);
  }

  togglerForMobileView(){
    if(window.innerWidth <= 600){
      this.toggleForMobile = !this.toggleForMobile;
    }
  }
  togglerForCart(){
    if(window.innerWidth <= 600){
      this.toggleForMobile = !this.toggleForMobile;
      this.toggleCategories = !this.toggleCategories;
    }
  }

  async addToBooking() {
    if (this._shopservice.ProductId == GlobalConst.Product.GOLF) {
      console.log('Rentals-rentalItems', this._shopservice.rentalItemGroupList);
      console.log('Rentals-retailItems', this.groupList);
      let finalGroupList = [], finalRentalGroupList = [];
      this.groupList.push(...this.removedGroupList);
      if (this.groupList.length == 0 && this._shopservice.groupedRetailItems) {
        this._shopservice.groupedRetailItems.map(item => {
          item.retailItems = [];
        });
        finalGroupList.push(...this._shopservice.groupedRetailItems);
      }
      else {
        finalGroupList.push(...this.groupList);
      }
      if (this._shopservice.rentalItemGroupList.length > 0 || this._shopservice.removedRentalItemGroupList.length > 0) {
        let playerIdsWithRentals = this._shopservice.rentalItemGroupList.map(x => x.groupId);
        this._shopservice.removedRentalItemGroupList =  this._shopservice.removedRentalItemGroupList.filter(x => !playerIdsWithRentals.includes(x.groupId))
        this._shopservice.rentalItemGroupList.push(...this._shopservice.removedRentalItemGroupList);
        finalRentalGroupList.push(...this._shopservice.rentalItemGroupList);
      }
      let playerGroupedRetailItems: GroupRetailItems[][] = [];
      playerGroupedRetailItems[0] = finalGroupList; // Retail Items
      playerGroupedRetailItems[1] = finalRentalGroupList // Rental Items
      let playerGroupedRetailItemsData: TeeTimeLinkedRetailItemsData = {
        playerRetailItemDetails: playerGroupedRetailItems,
        isEdit: this.updateItemsToAppt ? true : false,
        retailItemType: this.from == ItemType.retailItem ? RetailItemType.RetailItemRetailPOSOnly : RetailItemType.RentalItem,
        outletId: this._shopservice.SelectedOutletId
      }
      const body = {} as LinkedRetailItemsData;
      let retailAndRentalItemGroupList: GroupRetailItems[] = [];
      let rentalItemPurchases: RentalItemPurchase[] = [];
      if (this._shopservice.rentalItemGroupList.length > 0 || this._shopservice.removedRentalItems.length > 0) {
        if (this._shopservice.rentalItemGroupList.length > 0) {
          this._shopservice.rentalItemGroupList.map(x => {
            let rentalItemPurchase: RentalItemPurchase;
            x.retailItems.map(y => {
              rentalItemPurchase = {
                id: y.rentalItemPurchaseId,
                rentalItemId: y.ItemId,
                sourceType: RentalSourceType.ScheduledTeeTimePlayer,
                sourceTypeId: x.groupId,
                rentalStartTime: this.localization.convertDateTimeToAPIDateTime(y.rentalStartTime),
                rentalEndTime: this.localization.convertDateTimeToAPIDateTime(y.rentalEndTime),
                rentalEndTimeWithoutBuffer: this.localization.convertDateTimeToAPIDateTime(y.rentalEndTimeWithoutBuffer),
                buffer: y.bufferTime,
                duration: this.localization.getTimeDifference(this.localization.getDate(y.rentalStartTime), this.localization.getDate(y.rentalEndTimeWithoutBuffer), 'Min'),
                quantity: y.Noofitems,
                comments: y?.itemComments ?? "",
                isActive: true,
                rentalItemDetails: JSON.stringify(y),
                outletId: this._shopservice.SelectedOutletId,
                uid: y.linkedRetailItemGuid,
                amount: y.ProductPrice,
                itemDescription : y.ItemDescription
              };
              if (rentalItemPurchase)
                rentalItemPurchases.push(rentalItemPurchase);
            })

          });
        }
        console.log('removedRentalItems', this._shopservice.removedRentalItems);
        if (this._shopservice.removedRentalItems.length > 0) {
          this._shopservice.removedRentalItems.map(y => {
            let removedRentalItemPurchase: RentalItemPurchase;
            removedRentalItemPurchase = {
              id: y.rentalItemPurchaseId,
              rentalItemId: y.ItemId,
              sourceType: RentalSourceType.ScheduledTeeTimePlayer,
              sourceTypeId: y.playerId,
              rentalStartTime: this.localization.convertDateTimeToAPIDateTime(y.rentalStartTime),
              rentalEndTime: this.localization.convertDateTimeToAPIDateTime(y.rentalEndTime),
              rentalEndTimeWithoutBuffer: this.localization.convertDateTimeToAPIDateTime(y.rentalEndTimeWithoutBuffer),
              buffer: y.bufferTime,
              duration: this.localization.getTimeDifference(this.localization.getDate(y.rentalStartTime), this.localization.getDate(y.rentalEndTimeWithoutBuffer), 'Min'),
              quantity: y.Noofitems,
              comments: y?.itemComments ?? "",
              isActive: false,
              rentalItemDetails: JSON.stringify(y),
              outletId: this._shopservice.SelectedOutletId,
              uid: y.linkedRetailItemGuid,
              amount: y.ProductPrice,
              itemDescription : y.ItemDescription
            };
            if (removedRentalItemPurchase)
              rentalItemPurchases.push(removedRentalItemPurchase);

          })
        }
        let isUpdate: boolean = rentalItemPurchases.some(x => x.id > 0);
        let rentalItemPurchaseResult = await this.http.CallApiAsync<RentalItemPurchase[]>({
          host: GlobalConst.Host.retailPOS,
          callDesc: isUpdate ? RetailRoutes.updateRentalItemPurchase : RetailRoutes.CreateRentalItemPurchase,
          method: isUpdate ? HttpMethod.Put : HttpMethod.Post,
          body: rentalItemPurchases
        });

        if (playerGroupedRetailItemsData?.playerRetailItemDetails[1]?.length > 0 && rentalItemPurchaseResult?.result?.length > 0) {
          playerGroupedRetailItemsData.playerRetailItemDetails[1].map(x => {
            x.retailItems.map(y => {
              let rental = rentalItemPurchaseResult.result.find(r => r.uid == y.linkedRetailItemGuid);
              y.rentalItemPurchaseId = rental?.id ?? 0;
            })
          });
        }
      }

      if (this.groupList.length > 0 || this._shopservice.rentalItemGroupList.length > 0) {
        retailAndRentalItemGroupList = [...finalGroupList, ...this._shopservice.rentalItemGroupList]
      }
      if (retailAndRentalItemGroupList.length > 0) {
        body.linkedRetailItemDetails = JSON.stringify(retailAndRentalItemGroupList);
        body.linkCreatedDate = this.propertyInfo.CurrentDTTM;
        this.http.CallApiAsync<LinkedRetailItemsData>({
          host: GlobalConst.Host.retailPOS,
          callDesc: RetailRoutes.CreateLinkedRetailItemsData,
          method: HttpMethod.Post,
          body: body,
          uriParams: { sourceType: '0', sourceTypeId: 0 },
        });
      }
      const eventParams: RetailEventParameters<TeeTimeLinkedRetailItemsData> = {
        data: playerGroupedRetailItemsData,
        eventType: RetailEventType.TeeTimeLinkRetailItem
      }
      retailPublisher.publishEvent(eventParams);
      this.redirecttoReservation();
      this._shopservice.selectedProducts = [];
      this.groupList = [];
    }
    else {
      this._utilities.ToggleLoader(true);
      this._shopservice.isFromContinueBooking = true;
      this._shopservice.selectedProducts = this._shopservice.selectedProducts.filter(x => !x.isGroupingKey);
      this._retailService.selectedProducts = _.cloneDeep(this._shopservice.selectedProducts);
      this._retailService.isReset = this._retailService.selectedProducts.length == 0;
      this._shopservice.selectedProducts.forEach(x => {
        x['linkedRetailItemGuid'] = x['linkedRetailItemGuid'] ? x.linkedRetailItemGuid : this._utilities.generateGUID();
      });

      if (this._shopservice.selectedProducts) {
        const body = this.linkedRetailItem ?? {} as LinkedRetailItemsData;
        body.linkedRetailItemDetails = JSON.stringify(this._shopservice.selectedProducts);
        body.linkUpdatedDate = this.localization.getCurrentDate();
        body.linkCreatedDate = body?.id ? body.linkCreatedDate : this.localization.getCurrentDate();
        body.sourceTypeId = this._retailService.appoinmentId;
        const retailItemUrl = this._shopservice.LinkedRetailItemDetails && this._shopservice.LinkedRetailItemDetails.id ? RetailRoutes.UpdateLinkedRetailItems
          : RetailRoutes.CreateLinkedRetailItemsData;
        const actionMethod = body?.id ? HttpMethod.Put : HttpMethod.Post;
        try {
          let response = await this.http.CallApiAsync<LinkedRetailItemsData>({
            host: GlobalConst.Host.retailPOS,
            callDesc: retailItemUrl,
            method: actionMethod,
            body: body,
            uriParams: { sourceType: '0', sourceTypeId: 0 },
          });
          if (response && response?.result) {
            const eventParams: RetailEventParameters<LinkedRetailItemsData> = {
              data: body?.id ? body : response.result,
              eventType: RetailEventType.LinkRetailItem
            }
            retailPublisher.publishEvent(eventParams);
            this._shopservice.selectedProducts = [];
            this.redirecttoReservation();
          } else {
            this._utilities.ToggleLoader(false);
            this._utilities.showAlert(this.localization.captions.alertPopup.error, AlertType.Error, GlobalConst.ButtonType.Ok);
          }
        } catch (error) {
          this._utilities.ToggleLoader(false);
          console.log("Error while linking retail items to booking" + error)
        }
      }
    }
  }

  LoadLinkedItemsIntoCart() {
    if (this._shopservice.ProductId == GlobalConst.Product.SPA) {
      this.showReturn = !this._shopservice.addRetailItemToSource;
      this.addOrUpdateBtnLabel = this.localization.captions.shop.AddToAppointment;
      this.updateItemsToAppt = false;
      if ((this._shopservice.LinkedRetailItemDetails && this._shopservice.addRetailItemToSource)
        || (this._shopservice.LinkedRetailItemDetails && this._shopservice.isFromAppointment)) {
        const linkedItems = JSON.parse(this._shopservice.LinkedRetailItemDetails.linkedRetailItemDetails);
        this.linkedRetailItem = this._shopservice.LinkedRetailItemDetails;
        if (linkedItems?.length > 0) {
          this.addOrUpdateBtnLabel = this.localization.captions.shop.UpdateToAppointment;
          this.updateItemsToAppt = true;
          this._shopservice.selectedProducts = this._shopservice.selectedProducts.concat(linkedItems);
          this._shopservice.LinkedRetailItemDetails = !this._shopservice.addRetailItemToSource ? null : this._shopservice.LinkedRetailItemDetails;
        }
      }
    }
    else if (this._shopservice.ProductId == GlobalConst.Product.GOLF) {
      console.log('LoadLinkedItemsIntoCart', this.from)
      //player details from golf
      this.playerDetails = this._shopservice.golfPlayerInfo ? this._shopservice.golfPlayerInfo : [];
      this.playerDetails = cloneDeep(this.playerDetails);
      if (this.from == ItemType.retailItem) {
        this.playerDetails?.forEach(element => {
          if (this._shopservice.groupedRetailItems?.find(x => x.groupId == element.scheduledTeeTimePlayerFee.scheduledTeeTimePlayerId)) {
            if (element.isPaidPlayer || element.isCheckedOut) {
              let groupRetailItem = this._shopservice.groupedRetailItems?.find(x => x.groupId == element.scheduledTeeTimePlayerFee.scheduledTeeTimePlayerId);
              groupRetailItem.isCheckedOut = true;
              this.checkedOutGroupListCount++;
            }
            else {
              element.isClicked = true;
              this.selectedPlayer.push(element);
            }
          }
          else
            element.isClicked = false;
        });
      }
      if (this.from == ItemType.PMSAddOnsRentalItem && this.playerDetails.length > 0 && this._shopservice.groupedRentalItems?.length > 0) {
        this.playerDetails?.forEach(element => {
          if (this._shopservice.groupedRentalItems?.findIndex(x => x.groupId == element.scheduledTeeTimePlayerFee.scheduledTeeTimePlayerId) != -1) {
            if (element.isPaidPlayer || element.isCheckedOut) {
              let groupRetailItem = this._shopservice.groupedRentalItems?.find(x => x.groupId == element.scheduledTeeTimePlayerFee.scheduledTeeTimePlayerId);
              groupRetailItem.isCheckedOut = true;
              this.checkedOutGroupListCount++;
            }
          }
        });
      }
      this.addOrUpdateBtnLabel = this.localization.captions.shop.AddToTeeTime;
      this.updateItemsToAppt = false;
      if (this._shopservice.groupedRetailItems?.length > 0 || this._shopservice.groupedRentalItems?.length > 0) {
        this.updateItemsToAppt = true;
        this.addOrUpdateBtnLabel = this.localization.captions.shop.UpdateToTeeTime;
        if (this.from == ItemType.PMSAddOnsRentalItem && this._shopservice.groupedRentalItems.length > 0) {
          this.groupList = cloneDeep(this._shopservice.groupedRentalItems);
          this._shopservice.rentalItemGroupList = cloneDeep(this._shopservice.groupedRentalItems);
          this._shopservice.selectedProducts = this._shopservice.rentalItemGroupList.flatMap(x => x.retailItems);

        } else if (this.from == ItemType.retailItem && this._shopservice.groupedRetailItems.length > 0) {
          this.groupList = this._shopservice.groupedRetailItems;
          this._shopservice.selectedProducts = this.groupList.flatMap(x => x.retailItems);
        }
      }
    }
  }

  async ApplyMemberDiscountIfApplicable() {
    try {
      const itemEligibleForMemberDiscount = this._shopservice.selectedProducts.filter(x => x.DiscountTypeId > 0 && x.DiscountPercentage > 0);
      if ((this._shopservice.isFromAppointment || this._shopservice.isFromEditTeeTime) && itemEligibleForMemberDiscount.length > 0 && this.miscSetting.length > 0) {
        const activeDiscountTypes = await this.applyDiscountService.getActiveDiscountTypes();
        const discountReasonSetting = this.miscSetting.find(x => x.switch == MiscellaneousSwitch.DISCOUNT_REASON_REQUIRED);
        const discountReasonRequiredTypes = {
          All: "1",
          PercentageOnly: "2"
        }
        const MapDiscountProps = (memberDiscountReasonId) => {
          itemEligibleForMemberDiscount.map(x => {
            if (x.DiscountTypeId > 0 && x.DiscountPercentage > 0) {
              if (activeDiscountTypes.some(d => d.id == x.DiscountTypeId)) {
                x.discountComments = this._retailFeatureFlagInfo.MemberDiscountComment ?? "";
                x.discountReason = memberDiscountReasonId;
                x.Discount = this._utilities.MidPointRoundOffTwo(x.Noofitems * x.ProductPrice * x.DiscountPercentage / 100)
              } else {
                x.DiscountTypeId = 0;
                x.DiscountPercentage = 0;
              }
            }
          })
        }

        if (this._retailFeatureFlagInfo.MemberDiscountReason && discountReasonSetting.isActive
          && (discountReasonSetting.value == discountReasonRequiredTypes.All
            || discountReasonSetting.value == discountReasonRequiredTypes.PercentageOnly)
        ) {
          this.applyDiscountService.getActiveDiscountReasons().then(res => {
            this._utilities.ToggleLoader(false);
            if (res && res.length > 0) {
              const memberDiscountReasonId = res.find(x => x.description.trim().toLowerCase() == this._retailFeatureFlagInfo.MemberDiscountReason.trim().toLowerCase())?.id ?? 0
              MapDiscountProps(memberDiscountReasonId);
            }
          }).catch(err => {
            console.log("Error while fetching discount reason: " + err);
          });
        } else {
          MapDiscountProps(0);
        }
      }
    } catch (error) {
      console.log("Error while applying member discount:" + error)
    }
  }

  async PostToFolio() {
    if (this._retailService.isFromAppointment) {
      let payeeId = this._retailService.payeeId, guestId = this._retailService.selectedappointments.find(x => x.id == payeeId)?.guestId;
      this.payeeInfo = await RetailDataAwaiters.getPayeeInfo(payeeId);
      let data = {
        payeeInfo: this.payeeInfo,
        isGuest: payeeId ? true : false,
        memberGuestId: this.payeeInfo?.guesttype == GlobalConst.ClientType.Member ? this.payeeInfo?.guestId : "",
      }
      this.PostToFolioDialog(data);
    }
    else {
      this.PostToFolioDialog();
    }
  }

  PostToFolioDialog(dataForFolio: any = null) {
    const dialogRef = this.dialog.open(ShopDialogPopUp, {
      width: '700px',
      height: '600px',
      maxHeight: '700px',
      disableClose: true,
      hasBackdrop: true,
      data: {
        isEdit: false,
        headername: this.captions.hdr_post_to_folio,
        closebool: true,
        templatename: 'folio',
        data: dataForFolio ? dataForFolio : {},
        isFromShop: true
      },
      panelClass: 'shop-payment'
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result && result.from == 'save') {
        this.PostFolioFromShop(result)
      }
    });
  }

  IsTherapistAssociatedWithService(selectedProducts: SelectedProducts[]) {
    let isNoTherapist: boolean = false;
    for (let element of selectedProducts) {
      if (element.Gratuity && element.Gratuity.length > 0) {
        for (let gr of element.Gratuity) {
          if (gr.gratuity != 0 && !gr.TherapistId) {
            isNoTherapist = true;
            break;
          }
        }
      }
      if (isNoTherapist) break;
      if (element.ServiceCharge && element.ServiceCharge.length > 0) {
        for (let sc of element.ServiceCharge) {
          if (sc.ServiceCharge != 0 && !sc.TherapistId) {
            isNoTherapist = true;
            break;
          }
        }
      }
    }
    return isNoTherapist;
  }

  async PostFolioFromShop(folioResult: any) {
    let selectedProducts: any = this._shopservice.selectedProducts;
    let emailIds: string[] = folioResult?.selectedGuest?.emailId ? [folioResult.selectedGuest.emailId] : [];
    this._utilities.ToggleLoader(true);
    let request: AutoRetailTransactionRequestModel[] = [];


    if (this.IsTherapistAssociatedWithService(selectedProducts)) {
      this._utilities.ShowErrorMessage(
        this.localization.captions.common.Error,
        this.localization.captions.shop.NoTherapistAssociated
      );
      return;
    }

    let clientInfo: ClientInformation = {
      clientId: this.payeeInfo ? this.payeeInfo.id : folioResult.selectedGuest.guestInfoId,
      guestId: folioResult.selectedGuest.guestId
    }
    const isReturn: boolean = selectedProducts && selectedProducts.some(x => x.isReturn);
    const data = this._autoRetailTransactionBusiness.MapRetailItems(selectedProducts, clientInfo);
    const CheckOutAppointmentsList: AppointmentLineNumber[] = data[1];
    const appointmentIds = _.uniq(CheckOutAppointmentsList.map(x => x.appointmentId));

    let autoRetailRequest: AutoRetailTransactionRequestModel;
    if (this._shopservice.isFromAppointment) {
      autoRetailRequest = {
        outletId: this._shopservice.SelectedOutletId,
        sourceId: appointmentIds[0],
        sourceType: GlobalConst.RetailInterfaceSourceTypes.SPA,
        autoRetailTransactionComments: 'Checkout Post To Folio for Appointment ' + `${appointmentIds[0]}`,
        autoRetailTransactionType: GlobalConst.AutoRetailTransactionType.SPAAppoinmentCheckout,
        isTraceRequired: false,
        autoRetailTransactionRequest: {
          memberId: folioResult.selectedFolio.sourceType == SourceType.AddOnMember ? folioResult.selectedFolio?.interfaceGuestId ? folioResult.selectedFolio?.interfaceGuestId : 0 : 0,
          clerkId: Number(this.localization.GetUserInfo('userId')),
          autoRetailItems: data[0],
          userId: Number(this.localization.GetUserInfo('userId')),
          saleType: "",
          guestGuid: clientInfo.guestId
        },
        autoRetailTransactionReason: 'Checkout Post To Folio for Appointment' + `${appointmentIds[0]}`,
        DependentTransactionIds: this._autoRetailTransactionBusiness.serializeDependentTransactions(this._shopservice.dependentTransactions),
        autoRetailPaymentDetails: {
          autoRetailPaymentMethodIDs: [PaymentMethods.PostToFolio],
          folioPostingDetail: {
            folioInvoiceNumber: folioResult.selectedFolio.folioInvoiceNumber,
            resortFinanceSourceType: folioResult.selectedFolio.sourceType,
            resortFinanceSourceTypeId: clientInfo.guestId,
            guestId: clientInfo.guestId,
            paymentMethodId: PaymentMethods.PostToFolio
          }
        }
      }
    }
    else {
      autoRetailRequest = {
        outletId: this._shopservice.SelectedOutletId,
        sourceId: this._shopservice.SelectedOutletId,
        sourceType: GlobalConst.RetailInterfaceSourceTypes.RETAIL,
        autoRetailTransactionComments: 'Post To Folio from Shop Screen ',
        autoRetailTransactionType: GlobalConst.AutoRetailTransactionType.RetailShopCreateTransaction,
        isTraceRequired: false,
        autoRetailTransactionRequest: {
          memberId: folioResult.selectedFolio.sourceType == SourceType.AddOnMember ? folioResult.selectedFolio?.interfaceGuestId ? folioResult.selectedFolio?.interfaceGuestId : 0 : 0,
          clerkId: Number(this.localization.GetUserInfo('userId')),
          autoRetailItems: data[0],
          userId: Number(this.localization.GetUserInfo('userId')),
          saleType: "",
          guestGuid: folioResult.selectedGuest.guestId
        },
        autoRetailTransactionReason: 'Post To Folio from Shop Screen',
        autoRetailPaymentDetails: {
          autoRetailPaymentMethodIDs: [PaymentMethods.PostToFolio],
          folioPostingDetail: {
            folioInvoiceNumber: folioResult.selectedFolio.folioInvoiceNumber,
            resortFinanceSourceType: folioResult.selectedFolio.sourceType,
            resortFinanceSourceTypeId: folioResult.selectedGuest.guestId,
            guestId: folioResult.selectedGuest.guestId,
            paymentMethodId: PaymentMethods.PostToFolio
          }
        }
      }
    }
    request.push(autoRetailRequest);
    await this._autoRetailTransactionBusiness.InitiateAutoRetailTransaction(request).then(async result => {
      if (result && result.length && result[0].isAutoRetailTransactionSuccess) {
        this._utilities.ToggleLoader(false);
        const dialogRef = this.dialog.open(AlertMessagePopupComponent, {
          width: '305px',
          height: '300px',
          hasBackdrop: true,
          panelClass: 'small-popup',
          data: {
            headername: this.captions.lbl_posting_success,
            headerIcon: 'icon-success-icon', headerMessage: "Ticket# " + result[0].retailTicketNumber,
            buttonName: 'Okay', type: 'message'
          },
          disableClose: true
        });
        if (CheckOutAppointmentsList && CheckOutAppointmentsList.length > 0) {
          result[0].transactionData = {
            id: result[0].transactionId,
            retailTicketNumber: result[0].retailTicketNumber
          }
          this._rs.CreateTransLog(result[0].transactionId, "EventEmittedForAppointmentCheckout", { newValue: CheckOutAppointmentsList });
          if (selectedProducts?.every(data => data.sourceType !== GlobalConst.CustomFeeSourceType.Classes)) {
            this._shopservice.CheckOutCallback(result[0], _.cloneDeep(CheckOutAppointmentsList));
            await RetailDataAwaiters.ReleaseAppointmentLock(CheckOutAppointmentsList.map(x => x.appointmentId));
          } else {
            //need to configure for classes.
          }
        }
        const folioInfo: FolioInfo = {
          sourceType: autoRetailRequest.autoRetailPaymentDetails.folioPostingDetail.resortFinanceSourceType,
          sourceTypeId: autoRetailRequest.autoRetailPaymentDetails.folioPostingDetail.resortFinanceSourceTypeId,
          folioInvoiceNumber: autoRetailRequest.autoRetailPaymentDetails.folioPostingDetail.folioInvoiceNumber
        }
        this._autoRetailTransactionBusiness.PostResortFinanceFolioPostDetails(selectedProducts, result[0].autoPaymentInfos[0].postIds, Number(result[0].autoPaymentInfos[0].paymentAmount), folioInfo);
        this._autoRetailTransactionBusiness.printReceiptAndSendNotification(result[0], CheckOutAppointmentsList.map(x => x.appointmentId), emailIds, folioResult.selectedGuest.name, folioResult.selectedFolio.sourceType == SourceType.AddOnMember ? folioResult.selectedFolio?.interfaceGuestId : '', false, false, null, isReturn);

      }
      else {
        this._utilities.ToggleLoader(false);
        const dialogRef = this.dialog.open(AlertMessagePopupComponent, {
          width: '305px',
          height: '300px',
          hasBackdrop: true,
          panelClass: 'small-popup',
          data: {
            headername: result[0].errorMessage,
            headerIcon: 'icon-error-icon',
            buttonName: 'Okay', type: 'message'
          },
          disableClose: true
        });
        if (this._shopservice.isFromAppointment) {
          await RetailDataAwaiters.ReleaseAppointmentLock(CheckOutAppointmentsList.map(x => x.appointmentId));
        }
      }
      this._shopservice.selectedProducts = [];
      this._shopservice.selectedappointments = [];
      this._retailService.selectedappointments = [];
      this._retailService.isFromAppointment = false;
      this.payeeInfo = null;
    }).catch(async error => {
      console.log(error);
      this._utilities.ToggleLoader(false);
      if (this._shopservice.isFromAppointment) {
        await RetailDataAwaiters.ReleaseAppointmentLock(CheckOutAppointmentsList.map(x => x.appointmentId));
      }
    });;
  }

  async SetFolioConfigurationSwitches() {
    let config;
    config = await this._folioBusiness.fetchFolioDefaultsSettings(SettingModule.FolioSetup, SettingScreen.ConfigurationSwitches);
    if (config?.configValue) {
      sessionStorage.setItem("FolioConfigurationSwitches", JSON.stringify(config));
    }
  }

  async OpenRedeemMultiPackDialog() {
    let bpAccess = await this._facadeService.getUserAccess(UserAccessBreakPoints.REDEEMRETAILMULTIPACK, false);
    if (!bpAccess || !bpAccess?.isAllow) {
      this._shopservice.showBPMessage(GlobalConst.RetailBreakPoint.REDEEMRETAILMULTIPACK)
      return;
    }
    const dialogRef = this.dialog.open(ShopDialogPopUp, {
      width: "70%",
      height: "95%",
      maxHeight: "720px",
      maxWidth: "1000px",
      disableClose: true,
      hasBackdrop: true,
      data: {
        headername: this.localization.captions.shop.MultiPackRedeemPopup.lbl_RedeemMultiPack,
        closebool: true,
        templatename: "MultiPackRedeem",
        data: []
      },
      panelClass: "small-popup"
    });
    dialogRef.afterClosed().pipe(takeUntil(this.$destroyed)).subscribe((selectedMultiPacks: RedeemMultiGridData[]) => {
      if (selectedMultiPacks) {
        console.log(selectedMultiPacks);
        this.AddMultiPackRedeemItemsToCart(selectedMultiPacks);
      } else {
        this._rs.labelRecords = [];
        this._rs.recordsArray = [];
        this._rs.multiClientInfo = [];
        this._rs.clientsearchArray = [];
        this._shopservice.selectedPayeeId = 0;
        this._shopservice.memberCardNumber = "0";
        this._shopservice.memberArNumber = "0";
      }
    });
  }

  async AddMultiPackRedeemItemsToCart(selectedMultiPacks: RedeemMultiGridData[]) {
    if (selectedMultiPacks?.length > 0) {
      let retailItemsTobeFetched = [];
      let retailItemsAvailableAlready = [];
      let multipackPriceInfo = [];

      this._utilities.ToggleLoader(true, this.localization.captions.shop.lbl_processing);
      multipackPriceInfo = await this.GetMultipackPrice(selectedMultiPacks.map(x => x.transactionDetailId));
      this._utilities.ToggleLoader(false);

      //Scanning through exising UI object to avoid unnecessary data fetch from API      
      selectedMultiPacks.map(s => {
        const multiPackItemInfo = this.AllShopItems?.find(x => x.id === s?.retailItemId);
        if (multiPackItemInfo) {
          retailItemsAvailableAlready.push(multiPackItemInfo)
        } else {
          retailItemsTobeFetched.push(s?.retailItemId);
        }
        s.multipackData.clientMultiPacks.map(item => {
          const itemInfo = this.AllShopItems?.find(x => x.id === item?.linkedRetailItemId);
          if (itemInfo) {
            retailItemsAvailableAlready.push(itemInfo)
          } else {
            retailItemsTobeFetched.push(item?.linkedRetailItemId)
          }
        })
      });

      if (retailItemsTobeFetched.length > 0) {
        retailItemsTobeFetched = Array.from(new Set(retailItemsTobeFetched?.map(x => x)));
        this._utilities.ToggleLoader(true, this.localization.captions.shop.lbl_processing);
        const response = await this.InvokeServiceCallAsync('GetRetailItemDetailedInfoList', GlobalConst.Host.retailManagement, HttpMethod.Put, '', retailItemsTobeFetched);
        this._utilities.ToggleLoader(false);
        retailItemsAvailableAlready = retailItemsAvailableAlready.concat(response.result);
      }

      const getRedemptionPrice = (multipack: RedeemMultiGridData, retailItem) => {
        const multipackTransaction = multipackPriceInfo.find(x => x.multiPackTransactionDetailId == multipack.transactionDetailId);
        let persalevalue = multipackTransaction.multiPackPerSalePrice;
        const totalAmount = Number(persalevalue);
        const isUnlimited = (multipack.multipackData.clientMultiPacks[0].quanitity == -1)
        return isUnlimited ? this.localization.currencyToSQLFormat(retailItem.retailItemDetail.multiSalesPrice) * -1 : totalAmount * -1;
      }

      const addItemToCart = (retailItems, linkedMultipackRetailItem) => {
        linkedMultipackRetailItem.map((multipackItem: any) => {
          const item = retailItems.find(x => x.id == multipackItem.linkedRetailItemId);
          const product: SelectedProducts = {
            ItemId: item.retailItemDetail.id,
            ExternalPOSItemId: item.retailItemDetail.externalPOSId,
            ItemDescription: item.retailItemDetail.itemDescription,
            ItemType: item.retailItemDetail.itemType,
            ItemNumber: item.retailItemDetail.itemNumber,
            SalesPrice: 0,
            ServiceId: 0,
            ProductName: item.retailItemDetail.itemDescription,
            MemberPrice: multipackItem.salePrice,
            ProductPrice: multipackItem.salePrice,
            Noofitems: 1,
            Discount: 0,
            DiscountPercentage: 0,
            DiscountTypeId: 0,
            category: item.retailItemDetail.category,
            isCommissionable: item.retailItemDetail.isCommissionable,
            isCommissionRequired: item.retailItemDetail.isCommissionRequired,
            Commission: [],
            isGroupingKey: item.retailItemDetail.isGroupingKey,
            isPackagedItem: true,
            isModificationRestricted: true,
            PackageItemId: 0,
            MultiPack: false,
            ClientMultiPackId: multipackItem.id,
            PackageGroupId: this._shopservice.PackageGroupId,
            isOpenPricedItem: item.retailItemDetail.isOpenItem,
            scaledUnits: item.retailItemDetail.scaledUnit,
            isReturn: false,
            LineNumber: this._shopservice.GetNextLineNumber(),
            costPrice: item.retailItemDetail.costPrice,
            marginPercentage: item.retailItemDetail.marginPercentage,
            allowEarn: item.retailItemDetail.allowEarn,
            discountComments: '',
            discountReason: 0,
            GroupingParentId: 0,
            appliedCustomFee: []
          };
          this._shopservice.selectedProducts.push(product);
        })
      }

      if (retailItemsAvailableAlready.length > 0) {
        console.log(retailItemsAvailableAlready);
        selectedMultiPacks.map(multipack => {
          const item = retailItemsAvailableAlready.find(x => x.id == multipack.retailItemId);
          const redemptionPrice = getRedemptionPrice(multipack, item);
          const product: SelectedProducts = {
            ItemId: item.retailItemDetail.id,
            ExternalPOSItemId: item.retailItemDetail.externalPOSId,
            ItemDescription: this.localization.captions.shop.RedeemingMultipack + item.retailItemDetail.itemDescription,
            ItemType: item.retailItemDetail.itemType,
            ItemNumber: item.retailItemDetail.itemNumber,
            SalesPrice: 0,
            ServiceId: 0,
            ProductName: this.localization.captions.shop.RedeemingMultipack + item.retailItemDetail.itemDescription,
            ProductPrice: redemptionPrice,
            MemberPrice: redemptionPrice,
            Noofitems: 1,
            Discount: 0,
            DiscountPercentage: 0,
            DiscountTypeId: 0,
            category: item.retailItemDetail.category,
            isCommissionable: item.retailItemDetail.isCommissionable,
            isCommissionRequired: item.retailItemDetail.isCommissionRequired,
            Commission: [],
            isGroupingKey: item.retailItemDetail.isGroupingKey,
            isPackagedItem: true,
            isModificationRestricted: true,
            PackageItemId: 0,
            MultiPack: true,
            retailMultipackRedemption: true,
            ClientMultiPackId: multipack.multipackData.clientMultiPacks?.[0]?.id,
            PackageGroupId: this._shopservice.PackageGroupId,
            isOpenPricedItem: item.retailItemDetail.isOpenItem,
            scaledUnits: item.retailItemDetail.scaledUnit,
            isReturn: false,
            LineNumber: this._shopservice.GetNextLineNumber(),
            costPrice: item.retailItemDetail.costPrice,
            marginPercentage: item.retailItemDetail.marginPercentage,
            allowEarn: item.retailItemDetail.allowEarn,
            multiPackTransactionDetailId: multipack.multipackData.transactionDetailId,
            discountComments: '',
            discountReason: 0,
            GroupingParentId: 0,
            appliedCustomFee: []
          };
          this._shopservice.selectedProducts.push(product);

          const linkedRetailItemIds = Array.from(new Set(...[multipack.multipackData.clientMultiPacks.map(x => x.linkedRetailItemId)]));
          const itemnfo = retailItemsAvailableAlready.filter(x => linkedRetailItemIds.some(r => r == x.id));
          addItemToCart(itemnfo, multipack.multipackData.clientMultiPacks);
        });
      }
    }
  }

  async GetMultipackPrice(multipackIds: number[]) {
    let result = await this.http.CallApiAsync<any>({
      host: GlobalConst.Host.retailPOS,
      callDesc: RetailRoutes.GetMultiPackInfo,
      method: HttpMethod.Put,
      uriParams: null,
      body: multipackIds
    });
    return result.result;
  }
  onItemCountChange(e,availableQty){
    if(this.from == this.itemTypeEnum.PMSAddOnsRentalItem){
      if(Number(e.target.value) > availableQty){
        e.target.value = availableQty
      }
    }
  }
  async GetCourseDetails() {
    const response: BaseResponse<Course> = await this.InvokeServiceCallAsync(RetailRoutes.GetCourse, GlobalConst.Host.golfManagement, HttpMethod.Get, { "id": this._shopservice.golfPlayerInfo[0].courseId });
    this.courses = response.result;
  }

  public openCashDrawer() {
    let selectedOutlet = this._shopservice.AllOutlets.find(x => x.subPropertyID === this._shopservice.SelectedOutletId);
    let cashDrawerDetails: CashDrawerDetails = {
      cashDrawerConnectionType: CashDrawerConnectionType[selectedOutlet.cashDrawerPort],
      drawerKickCommand: selectedOutlet.cashDrawerKickOutCommand
    }
    this._receiptService.openCashDrawer(cashDrawerDetails)
  }

  editClick(prodDetails, index) {
    if (!this.quickLoginPopupEnabled && this.isQuickLoginEnabledForOverride) {
      const quickLoginDialogRef = this._commonUtils.QuickLogin();
      quickLoginDialogRef.afterClosed().pipe(takeUntil(this.$destroyed)).subscribe((quickLoginDialogResult: QuickLoginDialogResult) => {
        if (quickLoginDialogResult.isLoggedIn) {
          prodDetails['isEditTriggered'] = true;
          prodDetails['backupPrice'] = _.cloneDeep(prodDetails.price);
          this.quickLoginPopupEnabled = true;
        }
        else {
          const unitPriceInput = document.getElementById('unitpricefield(' + index + ')');
          unitPriceInput.blur();
        }
      });
    } else {
      prodDetails['isEditTriggered'] = true;
      prodDetails['backupPrice'] = _.cloneDeep(prodDetails.price);
    }
  }

}


@Pipe({
  name: 'subtotalprice',
  pure: false
})
export class SubtotalPricePipe implements PipeTransform {
  constructor(public localization: RetailLocalization, public utilities: RetailUtilities) { }

  transform(products: SelectedProducts[], from?: ItemType, groupList: GroupRetailItems[] = []) {
    let subtotal = 0;
    let obj = {
      label: this.localization.captions.shop.SubtotalToBePaid,
      subtot: ''
    }
    products = this.utilities.filterRetailRentalItems(products, from, groupList);
    if (products && products.length > 0) {
      products = products.filter(p => !p.isGroupingKey || p.isPackagedItem || (p.PackageItemId) > 0)
      products.forEach((product) => {
        subtotal = subtotal + this.getProductPrice(product);
      })
    }

    if (subtotal >= 0) {
      obj = {
        label: this.localization.captions.shop.SubtotalToBePaid,
        subtot: this.localization.localizeCurrency(subtotal)
      }
    } else {
      obj = {
        label: this.localization.captions.shop.SubtotalToBeRefunded,
        subtot: this.localization.localizeCurrency(subtotal * -1)
      }
    }
    return obj;
  }

  getProductPrice(product) {
    let orgAmount = 0;
    let price: number = 0;
    const totalprice = (product.Noofitems * product.ProductPrice)
    if (product.Discount) {
      if (product.isReturn) {
        let perItemDiscount = product.DiscountPercentage > 0 ? this.utilities.MidPointRoundOffTwo(product.DiscountPercentage * Math.abs(totalprice) / 100) : product.Discount;
        perItemDiscount = (perItemDiscount > Math.abs(totalprice)) ? totalprice : perItemDiscount;
        orgAmount = totalprice + perItemDiscount;
      }
      else {
        let perItemDiscount = product.DiscountPercentage > 0 ? this.utilities.MidPointRoundOffTwo(product.DiscountPercentage * totalprice / 100) : product.Discount;
        perItemDiscount = (perItemDiscount > totalprice) ? totalprice : perItemDiscount;
        orgAmount = totalprice - perItemDiscount;
      }
    }
    else {
      orgAmount = totalprice;
    }
    return orgAmount;
  }

}
@Pipe({
  name: 'showitemprice',
  pure: false
})
export class ShowItemPricePipe implements PipeTransform {
  constructor(public localization: RetailLocalization, public utilities: RetailUtilities) { }

  transform(product: SelectedProducts) {
    let orgAmount = 0;
    let price: string = '';
    const totalprice = (product.Noofitems * product.ProductPrice)
    if (product.Discount) {
      if (product.isReturn) {
        let perItemDiscount = product.DiscountPercentage > 0 ? this.utilities.MidPointRoundOffTwo(product.DiscountPercentage * Math.abs(totalprice) / 100) : product.Discount;
        perItemDiscount = (perItemDiscount > Math.abs(totalprice)) ? totalprice : perItemDiscount;
        orgAmount = totalprice + perItemDiscount;
      }
      else {
        let perItemDiscount = product.DiscountPercentage > 0 ? this.utilities.MidPointRoundOffTwo(product.DiscountPercentage * totalprice / 100) : product.Discount;
        perItemDiscount = (perItemDiscount > totalprice) ? totalprice : perItemDiscount;
        orgAmount = totalprice - perItemDiscount;
      }
    }
    else {
      orgAmount = totalprice;
    }

    if (orgAmount < 0) {
      price = `(${this.localization.localizeCurrency(orgAmount * -1)})`;
    }
    else {
      price = this.localization.localizeCurrency(orgAmount);
    }
    return price;
  }

}

@Pipe({
  name: 'cartcount',
  pure: false
})
export class CartCountPipe implements PipeTransform {
  constructor(public utilities: RetailUtilities) { }

  transform(products: SelectedProducts[], from?: ItemType, groupList: GroupRetailItems[] = []) {
    let cartCount = 0;
    products = this.utilities.filterRetailRentalItems(products, from, groupList);
    let items = products.filter(ordersummary => !ordersummary.isGroupingKey || ordersummary.isPackagedItem || (ordersummary['packageItemId'] | ordersummary.PackageItemId) > 0);

    items.forEach((product) => {
      cartCount += Number(product.Noofitems);
    })
    return cartCount;
  }

}

@Pipe({
  name: 'showoriginalprice'
})
export class showOriginalPricePipe implements PipeTransform {
  constructor(public localization: RetailLocalization, public utilities: RetailUtilities) { }

  transform(product: SelectedProducts) {
    let orgAmount = 0;
    let price: string = '';
    const totalprice = (product.Noofitems * product.ProductPrice)
    orgAmount = totalprice;

    if (orgAmount < 0) {
      price = `(${this.localization.localizeCurrency(orgAmount * -1)})`;
    }
    else {
      price = this.localization.localizeCurrency(orgAmount);
    }
    return price;
  }

}

@Pipe({
  name: 'showdiscountprice'
})
export class ShowDiscountPricePipe implements PipeTransform {
  constructor(public localization: RetailLocalization, public utilities: RetailUtilities) { }

  transform(product: SelectedProducts) {
    let orgAmount = 0;
    let price: string = '';
    let perItemDiscount
    const totalprice = (product.Noofitems * product.ProductPrice)
    if (product.Discount) {
      if (product.isReturn) {
        perItemDiscount = product.DiscountPercentage > 0 ? this.utilities.MidPointRoundOffTwo(product.DiscountPercentage * Math.abs(totalprice) / 100) : product.Discount;
        perItemDiscount = (perItemDiscount > Math.abs(totalprice)) ? totalprice : perItemDiscount;
        // orgAmount = totalprice + perItemDiscount;
      }
      else {
        perItemDiscount = product.DiscountPercentage > 0 ? this.utilities.MidPointRoundOffTwo(product.DiscountPercentage * totalprice / 100) : product.Discount;
        perItemDiscount = (perItemDiscount > totalprice) ? totalprice : perItemDiscount;
        // orgAmount = totalprice - perItemDiscount;
      }
    }
    else {
      orgAmount = totalprice;
    }

    if (perItemDiscount < 0) {
      price = `(${this.localization.localizeCurrency(perItemDiscount * -1)})`;
    }
    else {
      price = this.localization.localizeCurrency(perItemDiscount);
    }
    return price;
  }

}

@Pipe({
  name: 'getTimeFromDate'
})
export class GetTimefromDatePipe implements PipeTransform {
  constructor(public localization: RetailLocalization) { }

  transform(datetime) {
    return this.localization.getTime(this.localization.getDate(datetime), this.localization.getTimeFormat());
  }

}
