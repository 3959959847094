import { Injectable } from "@angular/core";
import { EmailProcessingDataService } from "src/app/common/dataservices/notification/email-processing-data.service";
import { Localization } from "src/app/common/localization/localization";
import { EmailSetting } from "../email-logs/email-logs.model";

@Injectable()
export class emailLogConfigurationBusiness {
    captions: any;
    constructor(private localization: Localization, private emailProcessingDataService: EmailProcessingDataService) {
        this.captions = this.localization.captions;
    }

    async getEmailSetting(): Promise<EmailSetting>{
        return await this.emailProcessingDataService.getEmailSetting();
    }

    async createEmailSetting(saveObj): Promise<EmailSetting>{
        let emailSetting = this.mapEmailSettingAPI(saveObj);
        return await this.emailProcessingDataService.createEmailSetting(emailSetting);
    }

    async updateEmailSetting(saveObj): Promise<EmailSetting>{
        let emailSetting = this.mapEmailSettingAPI(saveObj);
        return await this.emailProcessingDataService.updateEmailSetting(emailSetting);
    }

    mapEmailSettingAPI(saveObj): EmailSetting{
        return {
            id: saveObj.id,
            sentEmail: saveObj.sentMail,
            receiveMail: saveObj.receiveMail
        } as EmailSetting
    }
}