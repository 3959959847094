import { Component, OnInit, ViewEncapsulation, ViewChild, Input, OnDestroy } from '@angular/core';
import { SlideInformationService } from '../../../slide-information/slide-information.service';
import { HttpServiceCall, HttpMethod } from '../../../service/http-call.service';
import * as GlobalConst from '../../../globalsContant';
import { SpaUtilities } from '../../../utilities/spa-utilities';
import { appointmentService } from '../../../service/appointment.service';
import { AppointmentpopupService } from '../../../service/appointmentpopup.service';
import { History } from '../../../business/new-booking.model';
import { SpaLocalization } from '../../../../core/localization/spa-localization';
import * as _ from 'lodash';
import { NgForm, UntypedFormGroup, UntypedFormBuilder } from '@angular/forms';
import { UserName, BaseResponse } from '../../../business/shared.modals';
import { SPAConfig } from '../../../../core/config/SPA-config';
import { SpaPropertyInformation } from 'src/app/core/services/spa-property-information.service';
import { takeUntil } from 'rxjs/operators';
import { ReplaySubject } from 'rxjs';
import { MatDialog } from '@angular/material/dialog';
import { AppointmentActionsDialogComponent } from 'src/app/shared/appointment-actions/appointment-actions-dialog/appointment-actions-dialog.component';
import { AppointmentRetailItem } from 'src/app/appointment/slide-information/slide-information.component';
import { LockerAssignmentDetails, LockerBookingSourceType, LockerInformation } from 'src/app/shared/add-lockers/lockers.model';
import { RetailRoutes } from 'src/app/retail/retail-route';
import { RetailFeatureFlagInformationService } from 'src/app/retail/shared/service/retail.feature.flag.information.service';

declare var $: any;

@Component({
  selector: 'app-appointment-recap',
  templateUrl: './appointment-recap.component.html',
  styleUrls: ['./appointment-recap.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class AppointmentRecapComponent implements OnInit, OnDestroy {
  @Input() popupData: any;
  @Input() aptSummaryData: any;
  linkcodeDesc: any = [];
  linkCode: any = {};
  tableContentArr: any = [];
  tablehdrArray: any = [];
  recapAppointments: any = [];
  scheduledAppointmentIds: any = [];
  recapAppointmentInfo: any = [];
  isShowAll = false;
  clientId: number;
  intakeForm: boolean;
  appointmentId: number;
  recapResultArray: any = [];
  allcustomFees: any = [];
  clientInfo: any = {};
  comments: any = {};
  rowID: number;
  recapForm: UntypedFormGroup;
  date: any;
  captions: any;
  showAll = true;
  TherapistList: any[] = [];
  DefaultCancelationPolicy: string;
  @ViewChild('otherDetails') recapFormGroup: NgForm;

  currentIdx = 0;
  sBGclr: string;
  checkinBGclr: string;
  checkoutBGclr: string;
  closeBGclr: string;
  nsBGclr: string;
  appointmentRetailItems: any;
  destroyed$: ReplaySubject<boolean> = new ReplaySubject(1);
  showCustomFee: boolean = false;
  customFeeTaxAdded: boolean = false;
  timeformat: number;
  appointmentLockerItems : any;
  constructor(public SlideService: SlideInformationService,
    public spaConfig: SPAConfig,
    private fb: UntypedFormBuilder,
    public apptService: appointmentService,
    private http: HttpServiceCall, private utils: SpaUtilities,
    public appointmentpopservice: AppointmentpopupService,
    public localization: SpaLocalization, private PropertyInfo: SpaPropertyInformation,
    private retailFeatureFlag: RetailFeatureFlagInformationService,
    private dialog: MatDialog) {
    this.clientInfo = apptService.clientInfo;
    this.comments = apptService.comments;
    this.captions = this.localization.captions.bookAppointment;
    this.recapForm = this.fb.group({
      isShowAll: false
    });
    this.tablehdrArray = {
      hdrArray: [{ 'title': this.captions.Service, 'jsonkey': 'serviceName' },
        { 'title': this.captions.ConfirmnationId, 'jsonkey': 'confirmationId'},
      { 'title': this.captions.AppointmentTime, 'jsonkey': 'appointmentStartTime' },
      { 'title': this.captions.Therapists, 'jsonkey': 'therapistFirstName' },
      { 'title': this.captions.Location, 'jsonkey': 'locationName' },
      { 'title': this.captions.TotalAmount, 'jsonkey': 'totalAmount', 'align': 'right' },
      { 'title': this.captions.Deposit, 'jsonkey': 'deposit', 'align': 'right' },
      { 'title': '', 'jsonkey': '' },
      { 'title': this.captions.TransactionNo, 'jsonkey': 'transactionId' },
      { 'title': this.captions.Status, 'jsonkey': 'status' }],
    };
    this.TherapistList = this.apptService.managementData['Therapist'];
  }

  async LoadTherapist() {
    const result = await this.http.CallApiAsync({
      callDesc: "AccesibleTherapist",
      method: HttpMethod.Get,
      host: GlobalConst.Host.spaManagement,
    });
    const response: any = result && result.result ? result.result : [];
    return response;
  }

  async ngOnInit() {
    if (!this.TherapistList || (this.TherapistList && this.TherapistList.length == 0)) {
      this.TherapistList = await this.LoadTherapist();
    }
    this.appointmentId = this.SlideService.fieldData.appointmentId;
    this.clientId = this.SlideService.fieldData.clientId;
    this.intakeForm = this.SlideService.fieldData.intakeForm;
    this.date = this.utils.convertDateFormat(this.SlideService.fieldData.dateField);
    this.tableContentArr = ['1', '2', '3', '4', '5', '6'];
    if (this.popupData && this.popupData.AppointmentId) {
      this.popupData.clientArray = this.popupData.clientArray && this.popupData.clientArray.length > 0 ? this.popupData.clientArray.filter(x => { return x != 0 }) : [];
      if (this.popupData.clientArray.length > 1 && this.popupData.clientArray[0] != 0) {
        this.apptService.clientInfo = {
          firstName: this.captions.MultipleClients,
          lastName: "",
          multiple: true
        };
        this.showAll = false;
        this.apptService.comments = "";
      } else {
        this.apptService.BindClientInfo(this.popupData.clientId, true);
      }
      this.clientId = this.popupData.clientId;
      this.intakeForm = this.popupData.intakeForm;
      this.appointmentId = this.popupData.AppointmentId;
      this.date = this.popupData.AppointmentDate;
      this.RecapAppointmentDetails(this.popupData.AppointmentId, this.popupData.clientId, this.popupData.AppointmentDate, this.isShowAll);
    } else if (this.aptSummaryData) {
      if (this.aptSummaryData.clientArray.length > 1 && this.aptSummaryData.clientArray[0] != 0) {
        this.apptService.clientInfo = {
          firstName: this.captions.MultipleClients,
          lastName: "",
          multiple: true
        };
        this.showAll = false;
        this.apptService.comments = "";
      } else {
        this.apptService.BindClientInfo(this.aptSummaryData.clientId, true);
      }

      this.appointmentId = this.aptSummaryData.AppointmentId;
      this.date = this.aptSummaryData.AppointmentDate;
      this.clientId = this.aptSummaryData.clientId;
      this.RecapAppointmentDetails(this.aptSummaryData.AppointmentId, this.aptSummaryData.clientId, this.aptSummaryData.AppointmentDate, this.isShowAll);
    } else {
      this.apptService.BindClientInfo(this.clientId, true);
      this.RecapAppointmentDetails(this.appointmentId, this.clientId, this.date, this.isShowAll);
      this.apptService.clientId = this.clientId;
    }

    this.apptService.activeFormGroup = this.recapFormGroup;
    this.GetLinkCodes();
    this.GetAllCustomFees();
    this.utils.getConfiguration(this.successCallback.bind(this), this.utils.errorCallback.bind(this), "Appointment", "APPOINMENT_SYSTEMDEFAULTCANCELLATIONPOLICY");

    this.PropertyInfo._appointmentConfigurations$.pipe(takeUntil(this.destroyed$)).subscribe(result => {
      if (result) {
        this.setStatusColors();
      }
    });
    this.timeformat = this.localization.getTimeFormat();
  }

  setStatusColors() {
    this.sBGclr = this.utils.getOnlyBackgoundColor('RESV');
    this.checkinBGclr = this.utils.getOnlyBackgoundColor('CKIN');
    this.checkoutBGclr = this.utils.getOnlyBackgoundColor('CKOUT');
    this.closeBGclr = this.utils.getOnlyBackgoundColor('CLOSED');
    this.nsBGclr = this.utils.getOnlyBackgoundColor('NOSHOW');
  }

  ngOnDestroy(): void {
    this.apptService.clientInfo = {};
    this.destroyed$.next(true);
    this.destroyed$.complete();
  }

  RecapAppointmentDetails(id, clientId, date, isShowAll) {
    this.http.CallApiWithCallback<any>({
      host: GlobalConst.Host.schedule,
      success: this.successCallback.bind(this),
      error: this.utils.errorCallback.bind(this),
      callDesc: "GetAllRecapAppointments",
      uriParams: { id: id, clientId: clientId, date: this.utils.formatDate(date), isShowAll: isShowAll },
      method: HttpMethod.Get,
      showError: true,
      extraParams: [isShowAll]
    });
    //this.AppointmentRetailItemsByAppointmentId(id).then(x => console.log(x));
  }

  GetAllCustomFees() {
    this.http.CallApiWithCallback<any>({
      host: GlobalConst.Host.retailManagement,
      success: this.successCallback.bind(this),
      error: this.utils.errorCallback.bind(this),
      callDesc: "GetCustomFeeConfiguration",
      uriParams: null,
      method: HttpMethod.Get,
      showError: true,
      extraParams: [false]
    });
    //this.AppointmentRetailItemsByAppointmentId(id).then(x => console.log(x));
  }

  async AppointmentRetailItemsByAppointmentId(id): Promise<BaseResponse<AppointmentRetailItem>> {
    return await this.http.CallApiAsync<any>({
      callDesc: "GetAppointmentRetailItemByAppointmentId",
      host: GlobalConst.Host.schedule,
      method: HttpMethod.Get,
      uriParams: { appointmentid: id }
    })
  }

  async AppointmentLockerByAppointmentId(appointmentIds ): Promise<BaseResponse<LockerAssignmentDetails[]>> {
    return this.http.CallApiAsync<LockerAssignmentDetails[]>({
      callDesc: RetailRoutes.GetLockerAssignmentBySourceTypeIds,
      host: GlobalConst.Host.retailPOS,
      method: HttpMethod.Post,
      body: { bookingType: LockerBookingSourceType.SpaAppointment, BookingTypeIds: appointmentIds }
    })
  }

  async getAllLockerInformation(): Promise<BaseResponse<any>> {
    return this.http.CallApiAsync<any>({
      callDesc: RetailRoutes.GetLockersInformation,
      host: GlobalConst.Host.retailManagement,
      method: HttpMethod.Get,
      uriParams : { includeInactive: false }
    })
  }
  
  AppointmentRecapInfo(id) {
    this.http.CallApiWithCallback<any>({
      host: GlobalConst.Host.schedule,
      success: this.successCallback.bind(this),
      error: this.utils.errorCallback.bind(this),
      callDesc: "GetRecapAppointmentInfo",
      uriParams: { id: id },
      method: HttpMethod.Get,
      showError: true,
      extraParams: []
    });
  }

  successCallback<T>(result: BaseResponse<T>, callDesc: string, extraParams: any[]): void {
    switch (callDesc) {
      case 'GetAllRecapAppointments': {
        this.recapAppointments = <any>result.result;
        this.scheduledAppointmentIds = this.recapAppointments.filter(x => x.status.toUpperCase() === GlobalConst.AppointmentStatus.Scheduled).map(y => y.appointmentId);
        for (let i = 0; i < this.recapAppointments.length; i++) {
          let additionalTherapist = "";
          if (this.popupData && (this.popupData.AppointmentId || this.popupData.appointmentId) &&
            this.recapAppointments[i].appointmentId == (this.popupData.AppointmentId || this.popupData.appointmentId) &&
            this.popupData.therapistArray && this.popupData.therapistArray.length > 0) {

            this.popupData.therapistArray = this.popupData.therapistArray.filter(function (value, index, self) {
              return self.indexOf(value) === index;
            });
            additionalTherapist = this.popupData.therapistArray.length > 1 ? "+" + (this.popupData.therapistArray.length - 1).toString() : "";
          }
          else if (this.aptSummaryData && this.aptSummaryData.AppointmentId == this.recapAppointments[i].appointmentId && this.aptSummaryData.therapistArray && this.aptSummaryData.therapistArray.length > 0) {
            const count = this.aptSummaryData.therapistArray.map(t => t.TherapistId).filter(function (value, index, self) {
              return self.indexOf(value) === index;
            });
            additionalTherapist = count.length > 1 ? "+" + (count.length - 1).toString() : "";
          } else if (this.recapAppointments[i].therapistCount > 0) {
            additionalTherapist = this.recapAppointments[i].therapistCount > 1 ? "+" + (this.recapAppointments[i].therapistCount - 1).toString() : "";
          }
          const appTime = this.utils.FormatAppointmentDateTime(this.recapAppointments[i].appointmentStartTime, this.recapAppointments[i].appointmentEndTime);
          const status = this.recapAppointments[i].status.toLowerCase();
          this.recapAppointments[i].appointmentStartTime = appTime;
          this.recapAppointments[i].confirmationId =  this.recapAppointments[i].confirmationId;
          this.recapAppointments[i].therapistCount = this.recapAppointments[i].therapistCount - 1;
          this.recapAppointments[i].therapistFirstName = this.recapAppointments[i].therapistFirstName + " " + this.recapAppointments[i].therapistLastName + additionalTherapist;
          this.recapAppointments[i].transactionId = this.recapAppointments[i].transactionId ? this.recapAppointments[i].transactionId : "";
          this.recapAppointments[i].expanded = false;
          this.recapAppointments[i].locationName = this.recapAppointments[i].locationName ? this.recapAppointments[i].locationName : this.localization.captions.setting.Offsite;
          switch (status) {
            case "resv":
              this.recapAppointments[i].status = this.captions.Scheduled;
              break;
            case "noshow":
              this.recapAppointments[i].status = this.captions.NoShow;
              break;
            case "ckin":
              this.recapAppointments[i].status = this.captions.CheckedIn;
              break;
            case "ckout":
              this.recapAppointments[i].status = this.captions.CheckedOut;
              break;
            case "canc":
              this.recapAppointments[i].status = this.captions.Cancelled;
              break;
          }
        }
      }
        break;
      case 'GetRecapAppointmentInfo': {
        this.recapAppointmentInfo = <any>result.result;
        let appointmentCustomFee =this.recapAppointmentInfo.appointment.appointmentCustomFee.filter(t=>!t.isVoided &&  t.refundTransactionId==0);
        this.showCustomFee = appointmentCustomFee?appointmentCustomFee.length>0 && appointmentCustomFee.find(a=>a.amount>0):false;
        this.customFeeTaxAdded = appointmentCustomFee?appointmentCustomFee.length>0 && appointmentCustomFee.find(a=>a.tax>0):false;
       //this.recapAppointmentInfo.clientType = this.apptService.clientInfo?.clientType;
        this.recapAppointmentInfo.service.cancellationPolicy = this.recapAppointmentInfo.service.cancellationPolicy ? this.recapAppointmentInfo.service.cancellationPolicy : this.DefaultCancelationPolicy;
        this.recapAppointments[this.currentIdx].info = this.recapAppointmentInfo;
        this.formdispContent(this.recapAppointmentInfo);
      }
        break;
      case 'GetAllLinkCodes': {
        this.linkcodeDesc = result.result;
        this.linkcodeDesc.map(sc => { this.linkCode[sc.id] = sc.description });
      }
        break;
      case 'GetConfiguration': {
        if (result.result) {
          const res = <any>result.result;
          this.DefaultCancelationPolicy = res && res.value ? res.value : '';
        }
      }
        break;
      case 'GetCustomFeeConfiguration': {
        this.allcustomFees = <any>result.result;
      }
        break;
    }
  }

  async ToggleDetailSec(i?: any, id?: any) {
    this.currentIdx = i;
    const panelState = this.recapAppointments[i].expanded;
    this.recapAppointments[i].expanded = !this.recapAppointments[i].expanded;
    if (!panelState) {
      await this.AppointmentRetailItemsByAppointmentId(id).then(x => {
        this.recapAppointments[this.currentIdx]['retailItems'] = x.result != null ? JSON.parse(x.result.retailItems) : null;
      });
      this.AppointmentRecapInfo(id);
      if (this.retailFeatureFlag.IsLockersEnabled) {
        let lockerAssignmentDetail = (await this.AppointmentLockerByAppointmentId([this.appointmentId])).result;
        if (lockerAssignmentDetail.length > 0) {
          let LockerInforamtion = (await this.getAllLockerInformation()).result;
          this.recapAppointments[this.currentIdx]['lockerItems'] = lockerAssignmentDetail.map(assignment => {
            const lockerInfo = LockerInforamtion.find(locker => locker.id === assignment.lockerId && locker.name);
              return {
                lockerName: lockerInfo ? lockerInfo.name : null,
                lockerNumber: assignment.lockerNumber,
                amount: this.localization.localizeCurrency(assignment.amount),
                lockerUsageTime: this.localization.getTime(this.localization.ConvertStringDateTimeToDate(assignment.lockerBookingStartTime, this.localization.LocalizeTime(assignment.lockerBookingStartTime)), this.timeformat) + ' - ' + this.localization.getTime(this.localization.ConvertStringDateTimeToDate(assignment.lockerBookingEndTime, this.localization.LocalizeTime(assignment.lockerBookingEndTime)), this.timeformat),
              };
          });
        }
      }
    }
  }
  GetLinkCodes() {
    this.http.CallApiWithCallback<number>({
      host: GlobalConst.Host.spaManagement,
      success: this.successCallback.bind(this),
      error: this.utils.errorCallback.bind(this),
      callDesc: "GetAllLinkCodes",
      method: HttpMethod.Get,
      showError: true,
      extraParams: []
    });
  }

  formdispContent(recapInfo) {
    this.recapAppointments[this.currentIdx]['history'] = this.formHistoryInfo(recapInfo.history);
    recapInfo.discountValue = this.localization.localizeCurrency(recapInfo.discountValue);
    let apptCustomFee: number = 0;
    
    recapInfo.appointment.appointmentCustomFee.forEach(data => {
      if(!data.isVoided &&  data.refundTransactionId==0){
        apptCustomFee += (Number(data.amount) + Number(data.tax)),
        recapInfo.customFeeNames = recapInfo?.customFeeNames?.length >0 ? recapInfo.customFeeNames.concat(', ', this.allcustomFees.find(t => t.id == data.customFeeId)?.name)
          :this.allcustomFees.find(t => t.id == data.customFeeId)?.name
      }    
    });
    recapInfo.total = this.localization.localizeCurrency(Number(recapInfo.total) + Number(apptCustomFee));
    recapInfo.subTotal = this.localization.localizeCurrency(recapInfo.subTotal);
    recapInfo.linkCode = recapInfo.appointment.appointmentDetail.linkCodeId ? this.linkCode[recapInfo.appointment.appointmentDetail.linkCodeId] : this.captions.NoLinkCodeAddedToService;
    if (recapInfo.addOnNames == "") {
      recapInfo.addOnNames = this.captions.NoAddonAddedToService;
    }
    if (recapInfo.package == null) {
      recapInfo.cancellationPolicy = (recapInfo.isCancellationNoShowFeeEnabled) ? recapInfo.cancellationPolicy : recapInfo.service.cancellationPolicy;
    } else {
      recapInfo.cancellationPolicy = (recapInfo.isCancellationNoShowFeeEnabled) ? recapInfo.cancellationPolicy : recapInfo.package.cancellationPolicy;
    }
    if (this.aptSummaryData && this.aptSummaryData.AppointmentId == recapInfo.appointment.appointmentDetail.id && this.aptSummaryData.therapistArray && this.aptSummaryData.therapistArray.length > 1) {
      let therpistName = "";
      for (const element of this.aptSummaryData.therapistArray) {
        const therap = this.TherapistList.find(x => x.id == element.TherapistId);
        therpistName += therap ? (therap.firstName + " " + therap.lastName + ",") : ""
      }
      if (therpistName != "") {
        recapInfo.therpistNames = therpistName.substr(0, therpistName.length - 1);
      }
    } else if (this.popupData && (this.popupData.AppointmentId || this.popupData.appointmentId) &&
      recapInfo.appointment.appointmentDetail.id == (this.popupData.AppointmentId || this.popupData.appointmentId) &&
      this.popupData.therapistArray && this.popupData.therapistArray.length > 1) {
      let therpistName = "";
      for (const element of this.popupData.therapistArray) {
        const therap = this.TherapistList.find(x => x.id == element);
        therpistName += therap ? (therap.firstName + " " + therap.lastName + ",") : "";
      }
      if (therpistName != "") {
        recapInfo.therpistNames = therpistName.substr(0, therpistName.length - 1);
      }
    }
  }

  formHistoryInfo(historyData: Array<History>) {
    let html = [];
    if (!(history || history.length) || historyData == null) {
      return html;
    } else {
      let ids: any;
      ids = _.uniq(historyData.map(x => x.modifiedBy));
      const users = this.GetUserIds(ids);
      for (let i = 0; i < historyData.length; i++) {
        const hist = historyData[i];
        const modifiedUser = users.find(x => x.id === hist.modifiedBy);
        const formattedDate = this.localization.LocalizeShortDateTime(this.utils.getDate(hist.modifiedAt));
        html.push(`${hist.description} ${this.captions.by} ${modifiedUser?.firstName} ${modifiedUser?.lastName} ${this.captions.on} ${formattedDate}`);
      }
      return html;
    }
  }
  public GetUserIds(ids: number[]): UserName[] {
    const userNames: UserName[] = [];
    let res;
    let token = sessionStorage.getItem("_jwt");
    $.ajax({
      url: this.GetUserIdsParam(ids),
      async: false,
      headers: { "Authorization": 'Bearer ' + token },
      success: function (result) {
        res = result.result;
        if (res != null && res.length != 0) {
          for (let item of res) {
            let user: UserName;
            user = {
              id: item.userId,
              firstName: item.firstName,
              lastName: item.lastName
            };
            userNames.push(user);
          }
        }
      },
      error: function (result) {
        console.error(result);
      }
    });
    return userNames;
  }

  GetUserIdsParam(ids: number[]) {
    const route: string = this.spaConfig.getUrl("host." + GlobalConst.Host.authentication) + this.spaConfig.getUrl("GetAllUsersbyUserIds");
    const tenantId = this.utils.GetPropertyInfo('TenantId');
    return route.replace("{tenantId}", tenantId) + '?ids=' + ids.join('&ids=');
  }
  toggleConfirmationProcess(e?: any) {
    this.isShowAll = !this.isShowAll;
    this.RecapAppointmentDetails(this.appointmentId, this.clientId, this.date, this.isShowAll);
  }

  closeExpandedRecaps() {
    const expandedSlides = [].slice.call(document.getElementsByClassName('slideOutDown'));
    const labelArrows = [].slice.call(document.querySelectorAll('.transfor180'));
    expandedSlides.forEach(element => {
      element.parentNode.removeChild(element);
    });
    labelArrows.forEach(ele => { ele.classList.remove('transfor180'); });
  }

  /**
   * @function sendMail()
   * @method Click method action
   * @inputparams null
   * @outputparams null
   */
  sendMail(id: number, appointment: any, isSendConfirmation: boolean) {
    this.apptService.status = "RESV";
    this.apptService.appointmentIdLst = [];
    if (isSendConfirmation) {
      if (this.scheduledAppointmentIds.length > 0) {
        this.apptService.appointmentIdLst = _.cloneDeep(this.scheduledAppointmentIds);
      }
      else {
        return;
      }
    }
    else {
      this.apptService.appointmentIdLst.push(appointment.appointmentId);
    }
    this.apptService.isConfirmationRecapSearchScreen = true;
    this.apptService.clientId = this.clientId;
    this.apptService.intakeForm = id == 0 ? this.intakeForm : appointment.intakeForm;
    this.openDialog(24, this.captions.notify, appointment);
  }
  openDialog(actionId, popupheader, appointment) {
    let panelClass = 'action-dialog-overlay';
    let width = '90%';
    let height = 'auto';
    let maxwidth = '1000px;';

    if (actionId == GlobalConst.ActionType.notify) {
      height = '70%';
    }

    let dialogRef = this.dialog.open(AppointmentActionsDialogComponent, {
      width: width,
      maxWidth: maxwidth,
      height: height,
      hasBackdrop: true,
      data: {
        id: actionId,
        headername: this.captions.notify,
        closebool: true,
        appointmentInfomation: appointment ? appointment : {
          startDTTM: this.date,
          appointmentId: this.appointmentId
        }
      },
      panelClass: panelClass
    });
    dialogRef.afterClosed().pipe(takeUntil(this.destroyed$)).subscribe(() => {
      this.apptService.isConfirmationRecapSearchScreen = false;
    });
  }
}

