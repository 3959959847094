import { Injectable } from '@angular/core';
import { ActionTypeEnum, aligment, HdrActionTypeEnum, SorTypeEnum } from 'src/app/common/components/cdkvirtual/cdkvirtual.model';
import { EmailProcessingDataService } from 'src/app/common/dataservices/notification/email-processing-data.service';
import { TableActions } from 'src/app/common/enums/shared-enums';
import { Localization } from 'src/app/common/localization/localization';
import { TableHeaderOptions, TableOptions } from 'src/app/common/Models/ag-models';
import { emailInitial, EmailLogs, EmailLogsUI } from './email-logs.model';
@Injectable()
export class emailLogsBusiness {
    captions: any;
    constructor(private localization: Localization, private emailProcessingDataService: EmailProcessingDataService) {
        this.captions = this.localization.captions;
    }

    getTableOptions(): TableOptions {
        return {
            defaultSortOrder: SorTypeEnum.asc,
            defaultsortingColoumnKey: 'subject',
            isHeaderCheckboxAllowed: true,
            isRowClick: true,
            uniqueKey: 'id',
            columnFreeze: {
                firstColumn: false,
                lastColumn: true
            },
            actions:
                [{
                    type: TableActions.delete,
                    disabled: false
                },

                ]
        }
    }

    getTableHeaderOptions(): TableHeaderOptions[] {
        return [

            {
                key: 'checked',
                displayNameId: '',
                displayName: '',
                hdrtemplateName: HdrActionTypeEnum.hdrCheckbox,
                templateName: ActionTypeEnum.checkbox,
            },
            {
                key: 'emailSubject',
                displayNameId: 'lbl_subject',
                displayName: this.captions.lbl_subject,
                sorting: true,
                sortingKey: 'emailSubject',
                templateName: ActionTypeEnum.custom
            },
            {
                key: 'fromAddress',
                displayNameId: 'lbl_fromAddress',
                displayName: this.captions.lbl_fromAddress,
                sorting: true,
                sortingKey: 'fromAddress'
            },
            {
                key: 'toAddress',
                displayNameId: 'lbl_toAddress',
                displayName: this.captions.lbl_toAddress,
                sorting: true,
                sortingKey: 'toAddress',
                templateName: ActionTypeEnum.custom
            },
            {
                key: 'emailDateTime',
                displayNameId: 'lbl_emailDateTime',
                displayName: this.captions.lbl_emailDateTime,
                sorting: true,
                sortingKey: 'emailDateTime'
            },
            {
                key: ActionTypeEnum.action,
                displayName: this.captions.tbl_hdr_actions,
                templateName: ActionTypeEnum.action,
                alignment: aligment.center
            }
        ]
    }

    async getTableContent(emailRefNumber: string){
        let result : EmailLogs[] = await this.emailProcessingDataService.getEmailLogs(emailRefNumber);
        return result.map(x => this.mapTableContent(x));
    }

    mapTableContent(emailLog: EmailLogs) : EmailLogsUI{
        return {
            id: emailLog.id,
            emailReferenceDetailId: emailLog.emailReferenceDetailId,
            emailSubject: emailLog.subject,
            fromAddress: emailLog.fromEmailAddress,
            toAddress: this.mapToEmailAddress(emailLog.toEmailAddress),
            checked: false,
            messageId: emailLog.messageId,
            conversionId: emailLog.conversionId,
            emailInitial: this.extractEmailInitial(emailLog.fromEmailAddress),
            emailDateTime: this.localization.localizeUTCDate(emailLog.emailDate, true) ,
            attachmentList: emailLog.attachments,
            bodyContent: emailLog.bodyContent
        } as EmailLogsUI
    }

    mapToEmailAddress(emailAddress: string) : string[]{
        let emails : string[] = emailAddress.split(',').filter(email => email !== '');
        return emails
    }

    extractEmailInitial(data): emailInitial{
        return {
            initial: data[0] + data[1]
        }
    }

    async refreshEmailLogs(){
        return await this.emailProcessingDataService.refreshEmailLogs();
    }

    async deleteEmailLogs(ids: number[]){
        return await this.emailProcessingDataService.deleteEmailLogs(ids);
    }

}
