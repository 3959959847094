import { Component, EventEmitter, Input, OnInit, Output, ViewChild, ViewEncapsulation } from '@angular/core';
import { UntypedFormArray, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDatepicker } from '@angular/material/datepicker';
import { MatDialog } from '@angular/material/dialog';
import _, { cond } from 'lodash';
import { cloneDeep } from 'lodash';
import { ReplaySubject } from 'rxjs';
import { pairwise, startWith, takeUntil } from 'rxjs/operators';
import { AlertAction, AlertType, ButtonType } from 'src/app/common/enums/shared-enums';
import { AgDropdownConfig, ButtonValue } from 'src/app/common/Models/ag-models';
import { CommonUtilities } from 'src/app/common/shared/shared/utilities/common-utilities';
import { SpaLocalization } from 'src/app/core/localization/spa-localization';
import { SpaPropertyInformation } from 'src/app/core/services/spa-property-information.service';
import { BaseResponse } from 'src/app/shared/business/shared.modals';
import { API, DaysOfWeek, RuleType,DynamicPricingStatus, DynamicPricingRuleCategory, ActionMode, MapUI, DynamicPricingCondition, DynamicPricingRulesBy } from '../dynamic-yielding.model';
import { ViewYieldSetupComponent } from '../view-yield-setup/view-yield-setup.component';
import { CreateYieldSetupBusiness } from './create-yield-setup.business';
import { MatSelectChange } from '@angular/material/select';

@Component({
  selector: 'app-create-yield-setup',
  templateUrl: './create-yield-setup.component.html',
  styleUrls: ['./create-yield-setup.component.scss'],
  encapsulation: ViewEncapsulation.None,
  providers: [CreateYieldSetupBusiness]
})
export class CreateYieldSetupComponent implements OnInit {

  captions: any;
  yieldSetupForm: UntypedFormGroup;
  actionButton: ButtonValue;
  cancelButton: ButtonValue;
  dayPartButton: ButtonValue;
  dayButton: ButtonValue;
  @Output() handleClickEvent = new EventEmitter();
  @Output() saveClickEvent = new EventEmitter();
  floatLabel: string;
  floatLabelNever: string;
  ruleTypeEnum = RuleType;
  serviceYieldArray: UntypedFormArray;
  isClicked: boolean;
  serviceGroupConfig:AgDropdownConfig;
  conditionValues: any[];
  serviceGroups = [];
  services = [];
  destroyed$: ReplaySubject<boolean> = new ReplaySubject(1);
  yieldStartDate: any;
  yieldEndDate: any;
  yieldDays = [];
  weekDays: any;
  actionTypeEnum = ActionMode;
  overrideDays: any = [];
  model = [];
  @ViewChild('multiDatePicker') _picker: MatDatepicker<Date>;
  closeOnSelected = false;
  init = new Date();
  resetModel = new Date(0);
  readonlyoverridenDates: string = '';
  inputValue;
  editData;
  overrideData;
  @Input() inputs;
  @Input() editDataArr;
  DEFAULT_GUID = '00000000-0000-0000-0000-000000000000';
  timeformat: number;
  seasonDays = [];
  propertyDate: Date;
  servicesOptions;
  minDate;
  maxDate;
  previousendDateValue: any;
  oldendDateValue: any;
  oldStartDateValue: any;
  previousStartDateValue: any;
  infoMessage:string= ''
  
  constructor(public dialog:MatDialog, public fb: UntypedFormBuilder,private PropertyInfo: SpaPropertyInformation,
    private localization: SpaLocalization,private utils: CommonUtilities, private _utilities: CommonUtilities, private _createYieldSetupBusiness: CreateYieldSetupBusiness) { 
      this.captions = this.localization.captions;
      this.floatLabel = this.localization.setFloatLabel;
      this.floatLabelNever = this.localization.setFloatLabelNever;
    }

  ngOnInit(): void {
    this.propertyDate = this.PropertyInfo.CurrentDate;
    this.formGenerator();
    this.conditionValues = this._createYieldSetupBusiness.getConditionValues();
    this.weekDays = this._createYieldSetupBusiness.getWeekList();
    if(this.inputs.mode == ActionMode.SingleDateOverride || this.inputs.mode == ActionMode.MultiDateOverride){
      this.getOverrideArray();
    }
    this.initializeData();
    if(this.inputs){
      this.infoMessage = this.inputs?.infoMessage ? this.inputs?.infoMessage : ''
    }
  }

  formGenerator(){
    this.yieldSetupForm = this.fb.group({
       startDate: ['',Validators.required],
       endDate: ['',Validators.required],
       isActive: true,
       name: ['',Validators.required],
       singleOverrideDate: '',
       selectDatesbyRange: true,
       multiOverrideDate:'',
       overrideDates: [''],
       rules: this.fb.array([
       ]),
       overRide: this.fb.array([
        this.fb.group({         
          maxOccupancy:0,
          availability: false,
          overrideValue: 0,
          serviceGroup: '',
          services: '',
          overRideDaysOfWeek:[this._createYieldSetupBusiness.getWeekList()],
          daysOfWeek: ''  ,
          type:''     
        })
      ])
    })
    this.actionButton = {
      type: "primary",
      label: this.inputs.mode === ActionMode.Edit || this.inputs.mode === ActionMode.EditOverride ? this.captions.btn_update : this.captions.btn_create,
      disabledproperty: true,
    };
    this.cancelButton = {
      type: "tertiary",
      label: this.captions.btn_cancel,
    };
    this.dayPartButton = {
      type: "secondary",
      label: this.captions.btn_DayPart,
    };
    this.dayButton = {
      type: "secondary",
      label: this.captions.btn_Day,
    };
    this.yieldSetupForm.controls.startDate.setValue(this.localization.getDate(this.propertyDate));
    this.yieldSetupForm.controls.endDate.setValue(this.localization.getDate(this.propertyDate));
    if (this.inputs.mode === ActionMode.SingleDateOverride || this.inputs.mode === ActionMode.MultiDateOverride || this.inputs.mode === ActionMode.Create) {
      this.startDateChange({value: this.propertyDate});
      this.endDateChange({value: this.propertyDate});
    }   
    this.yieldSetupForm.valueChanges.pipe(takeUntil(this.destroyed$)).subscribe(val => {
      this.enableSave();
      
    });
    this.yieldSetupForm.controls.startDate.valueChanges.pipe(startWith(this.yieldSetupForm.controls.startDate.value),pairwise()).subscribe(
      ([old,value])=>{
        this.oldStartDateValue = old;
        if(old == ''){
         this.previousStartDateValue=value;
        }else
        this.previousStartDateValue = this.localization.LocalizeDate(old);
      }
    )
    this.yieldSetupForm.controls.endDate.valueChanges.pipe(startWith(this.yieldSetupForm.controls.endDate.value),pairwise()).subscribe(
      ([old,value])=>{
        this.oldendDateValue = old;
        if(old == ''){
         this.previousendDateValue=value;
        }else if(!value)
        this.previousendDateValue = this.localization.LocalizeDate(old);
      }
    )  
  }

  async initializeData() {
    this.utils.ToggleLoader(true);
    this.timeformat = this.localization.getTimeFormat();
    this.conditionValues = this._createYieldSetupBusiness.getConditionValues();
    this.minDate = this.propertyDate;
    await this._createYieldSetupBusiness.getServiceGroupsWithService().then(x => {
      this.serviceGroups = x;
    });
    if(this.inputs.mode === ActionMode.Edit || this.inputs.mode === ActionMode.Copy) {
      this.minDate = this.propertyDate;
      this.editData = this.inputs.form;
      console.log(this.editData);
      this.bindFormData();
      this.enableSave();
    } else if(this.inputs.mode === ActionMode.SingleDateOverride || this.inputs.mode === ActionMode.MultiDateOverride ||
      this.inputs.mode === ActionMode.EditOverride) {
      if(this.inputs.mode === ActionMode.EditOverride){
        this.overrideData = [...this.inputs.form];
      }else{
        this.overrideData = [...[this.inputs.form]];
      }
      
      this.bindOverrideData();
    }
  }    


  createDayPartFormArray(){
    return  this.fb.group({
      type: RuleType.dayPart,
      startTime:['',Validators.required],
      endTime: ['',Validators.required],
      minEndTime: this.localization.getDefaultTime(),
      maxStartTime: null,
      minStartTime: this.localization.getDefaultTime(),
      maxEndTime: null,
      serviceGroup:[this.serviceGroups,Validators.required],
      services:['',Validators.required],
      serviceOptions: [''],
      maxOccupancy:[0,Validators.required],
      allDays:false,
      daysOfWeek: [this.setYieldDaysForgrid()],
      availability:false,
      conditionArray: this.fb.array([
      ])
     })
  }

  createDayFormArray(){
   return  this.fb.group({
      type: RuleType.day,
      serviceGroup:[this.serviceGroups,Validators.required],
      services:['',Validators.required],
      serviceOptions: [''],
      maxOccupancy:[0,Validators.required],
      allDays:false,
      daysOfWeek: [this.setYieldDaysForgrid()],
      availability:false,
      conditionArray: this.fb.array([
      ])
     })
  }

 
  addDayPart(){
    this.serviceYieldArray = this.yieldSetupForm.get('rules') as UntypedFormArray;
    this.serviceYieldArray.push(this.createDayPartFormArray());
    this.yieldSetupForm.markAsDirty();
    this.enableSave();
  }

  addDay(){
    this.serviceYieldArray = this.yieldSetupForm.get('rules') as UntypedFormArray;
    this.serviceYieldArray.push(this.createDayFormArray());
    this.yieldSetupForm.markAsDirty();
    this.enableSave();
  }

  addRule(index){
    let array = this.yieldSetupForm.get('rules')['controls'][index];
    if(array.controls.type.value == RuleType.day){
      this.addDay();
    }
    else{
      this.addDayPart();
    }
  }

  addConditionArray(ruleIndex){
    const controls =  this.fb.group({
      condition: ['',Validators.required],
      occupancy:['',Validators.required],
      stopAvailability:false,
      rateChange:0,
      priceType:0,
      priceValue:['',Validators.required]
    })
    this.serviceYieldArray = (controls == null ? this.yieldSetupForm.get('rules') as UntypedFormArray : this.yieldSetupForm.get('rules')['controls'][ruleIndex].controls.conditionArray as UntypedFormArray);
    this.serviceYieldArray.push(controls);
    this.yieldSetupForm.markAsDirty();
    this.enableSave();
  }

  removeConditionArray(index,ruleIndex){
    this.serviceYieldArray = (index == null ? this.yieldSetupForm.get('rules') as UntypedFormArray : this.yieldSetupForm.get('rules')['controls'][ruleIndex].controls.conditionArray as UntypedFormArray);
    this.serviceYieldArray.removeAt(index);
    this.serviceYieldArray.markAsDirty();
    this.enableSave();
  }

  removeArray(index){
    const control = this.yieldSetupForm.get('rules') as UntypedFormArray;
    control.removeAt(index);
    this.allGridValidation();
    this.serviceYieldArray.markAsDirty();
    this.enableSave();
  }

  timeChanged(e,idx,from){
    let arr = this.yieldSetupForm.get('rules') as UntypedFormArray;
      if (from === 'fromTime') {
        if (arr.controls[idx]['controls']['startTime'].value!='') {
          arr.controls[idx]['controls'].minEndTime.setValue(arr.controls[idx]['controls']['startTime'].value)
        }
      } else if(from === 'toTime') {
          if(arr.controls[idx]['controls']['endTime'].value!=''){
        }
      }
      this.checkTime(arr,idx);
      this.validation(idx);
      this.yieldSetupForm.markAsDirty();
      this.enableSave();
  }

  checkTime(arr,idx){
    const st=(this.localization.TimeToDate(arr.controls[idx]['controls']['startTime'].value)).getTime();
    const et=(this.localization.TimeToDate(arr.controls[idx]['controls']['endTime'].value)).getTime();

    if(st > et || st == et){
      arr.controls[idx]['controls']['endTime'].setValue('');
    }
  } 

  startDateChange(event){
    this.yieldStartDate = this.localization.LocalizeDate(event.value);
   
  }

  endDateChange(event){
    this.yieldEndDate = this.localization.LocalizeDate(event.value);
    this.yieldDays=[];
    let dateObj = [];
    let stdate = this.localization.delocalizeDisplayDate(this.yieldStartDate);
    let enDate = this.localization.delocalizeDisplayDate(this.yieldEndDate);
    if (stdate && event.value != null) {
      let dateRange = this.localization.getDatesForGivenRange(
        stdate,
        enDate
      );
      if(dateRange.length > 0){
        dateRange.forEach(x => {
          dateObj.push(x.dateObj)
          this.yieldDays.push(
            this.localization.getDate(x.dateObj).toLocaleDateString('en-us', {weekday : 'short'})
          );
        });
        this.yieldDays=_.uniqBy(this.yieldDays,x=>x);
      }
      this.weekDays = this.setYieldDaysForgrid();
      if(this.inputs.mode == ActionMode.MultiDateOverride){
        let drange = dateRange.map(x => x.dateObj);
        this.yieldSetupForm.controls.overrideDates.setValue(drange);
        this.overrideDays=this.getDaysBetweenDates(stdate,enDate)
        this.selectOverrideDays();     
      }
    } 
    if(stdate && event.value != null){
      if(this.inputs.mode == ActionMode.Create){
        const controls = this.yieldSetupForm.get('rules') as UntypedFormArray;
        let controlsLength:number = controls['controls'].length;
        for(let i=0; i < controlsLength ; i++){
         controls.removeAt(0)
        }
        this.enableSave();
      }
      else{
        if(this.inputs.mode == ActionMode.Copy || (this.inputs.mode == ActionMode.Edit && this.yieldSetupForm.dirty)){
          this.clearDaysOnDateChange();
          this.allGridValidation();
        }
      } 
    }
    this.enableSave();
  }

  clearDaysOnDateChange(){
    // let existingSeasonDays = this.inputs.mode == ActionMode.Copy? '' : 
    // this.listSeasonDays(this.previousStartDateValue,this.previousendDateValue);
    // let valid = true;
    // if(existingSeasonDays.length != 7 || this.yieldDays.length != 7){
      // valid = false;
      this._utilities.showAlert(this.captions.lbl_showCopyInfo, AlertType.Info, ButtonType.Ok, async (res) => {
          if (res == AlertAction.YES) {
          }
      })
    // }
      let utilizationArr = this.editData.filter(x => x.ruleCategoryId === DynamicPricingRuleCategory.Utilization)
      let rules = this.yieldSetupForm.get('rules') as UntypedFormArray;
          // if(!valid){
          rules.controls.forEach(rules => {
            rules['controls']['allDays'].setValue(false);
            rules['controls']['daysOfWeek'].setValue(this.setYieldDaysForgrid());
         });
        // }
      //   else{
      //     if(utilizationArr.length > 0){
      //     let controlsLength:number = rules['controls'].length;
      //     for(let i=0; i < controlsLength ; i++){
      //       rules.removeAt(0)
      //     }
      //    this.serviceYieldArray = this.yieldSetupForm.get('rules') as UntypedFormArray;
      //    this.bindUtilArr(this.editData.filter(x => x.ruleCategoryId === DynamicPricingRuleCategory.Utilization));
      //   }
      // }
  }

  listSeasonDays(startDate,endDate){
    let days = []
    let stdate = this.localization.delocalizeDisplayDate(startDate);
    let enDate = this.localization.delocalizeDisplayDate(endDate);
    let dateRange = this.localization.getDatesForGivenRange(
      stdate,
      enDate
    );
    if(dateRange.length > 0){
      dateRange.forEach(x => {
        days.push(
          this.localization.getDate(x.dateObj).toLocaleDateString('en-us', {weekday : 'short'})
        );
      });
    return days=_.uniqBy(days,x=>x);
  }
  }

  getDaysBetweenDates(startDate,endDate) {
    let sDate = new Date(startDate);
    var diff = Math.round((endDate-startDate)/(1000*60*60*24));
    var dayMap = ["Sun","Mon","Tue","Wed","Thu","Fri","Sat","Sun"];
    var dates = [];
    while(diff > -1) {
        dates.push(dayMap[new Date(sDate).getDay()]);
        sDate.setDate(sDate.getDate()+1);
        diff--;
    }
    return dates;
  }
  
  setYieldDaysForgrid(){
    let days=this._createYieldSetupBusiness.getWeekList();
    days.forEach(ele =>ele.isDisabled=true);
    days.forEach(element => {
      if(this.yieldDays.filter(ele => ele == element.viewVal).length > 0){
        element.isDisabled = false
        element.isAvailable = true
      }
     else{
      element.isDisabled = true ;
      element.isAvailable = false 
     }
    });
    return days;
  }

  servicesSelection(index){
    this.validation(index);
    this.yieldSetupForm.markAsDirty();
    this.enableSave();
  }
  enableSave(){
    let isNoConditionValid = true;
    if (this.inputs.mode === ActionMode.Create || this.inputs.mode === ActionMode.Edit || this.inputs.mode === ActionMode.Copy) {
      this.serviceYieldArray = this.yieldSetupForm.get('rules') as UntypedFormArray;
    } 
    else if (this.inputs.mode === ActionMode.MultiDateOverride || this.inputs.mode === ActionMode.SingleDateOverride || this.inputs.mode === ActionMode.EditOverride) {
      this.serviceYieldArray = this.yieldSetupForm.get('overRide') as UntypedFormArray;
    }    
    if(this.inputs.mode == ActionMode.Create || this.inputs.mode == ActionMode.Edit || this.inputs.mode === ActionMode.Copy){
      this.serviceYieldArray.controls.forEach(element => {
        let isValid = (element['controls']['daysOfWeek'].value.some(x => x.isClicked==true) && element['controls']['services'].value && element['controls']['serviceGroup'].value);
        if(!isValid){
          element.setErrors({'invalid': true})
        }
        else{
          element.setErrors(null);
        }
      });
      isNoConditionValid = this.serviceYieldArray.controls.some(x=>x['controls']['conditionArray'].controls.length == 0 && (x['controls']['maxOccupancy'].value == 0 && !x['controls']['availability'].value));
    }
    this.actionButton.disabledproperty = (this.inputs.mode === ActionMode.Create || this.inputs.mode === ActionMode.Edit || this.inputs.mode === ActionMode.Copy) ?
                                      !(this.yieldSetupForm.valid && this.yieldSetupForm.dirty && this.serviceYieldArray.value.length > 0 && !isNoConditionValid) :
                                      !(this.yieldSetupForm.valid && this.yieldSetupForm.dirty && this.serviceYieldArray.value.length > 0);
    this.actionButton = {...this.actionButton};
  }

  allDayChangeEvent(event,array,index){
    let arr=array.value['daysOfWeek']
    arr.forEach(ele => {
      if(!ele.isDisabled){
        ele.isClicked=event[0]
      }
    });
    this.validation(index);
    this.yieldSetupForm.markAsDirty();
    this.enableSave();
  }

  onDayClick(index,array,arrayIndex){
    let arr=array.value['daysOfWeek']
    arr[index].isClicked=! arr[index].isClicked
    let filteredDays=arr.filter(x=> x.isClicked==true)
    let availableDays = arr.filter(x => x.isDisabled==false)
    if(filteredDays.length==availableDays.length){
      array.controls['allDays'].setValue(true);
    }
    else{
      array.controls['allDays'].setValue(false);
    }
    this.validation(arrayIndex);
    this.yieldSetupForm.markAsDirty();
    this.enableSave();
  }

  copyRule(index){
    let data;
    let copyData=this.yieldSetupForm.get('rules')['controls'][index];
    data=this.createCopyRule(index);
     const controls=this.fb.group({
      condition: ['',Validators.required],
      occupancy:['',Validators.required],
      stopAvailability:false,
      rateChange:0,
      priceType:0,
      priceValue:['',Validators.required]
     })
     let condArr=copyData.controls.conditionArray.controls.length;
     copyData.controls.conditionArray.enable();
     if(condArr>1){
       for(let i=1; i <= condArr-1; i++){
         data.controls.conditionArray.controls.push(cloneDeep(controls))
       }
     }
     data.patchValue(copyData.value);
     data.controls.startTime?.setValue('');
     data.controls.endTime?.setValue('');
     data.controls.allDays.setValue(false);
     data.controls.daysOfWeek.setValue(this.setYieldDaysForgrid());
     data.controls.services.setValue(copyData.controls.services.value);
     this.serviceYieldArray = this.yieldSetupForm.get('rules') as UntypedFormArray;
     this.serviceYieldArray.push(cloneDeep(data));
     this.serviceYieldArray.controls.forEach((controls,ruleIndex)=>{
      controls['controls'].conditionArray.controls.forEach((element,condIndex) => {
        if(controls['controls'].availability.value){
          this.disableAvailabilityControls(ruleIndex,condIndex,true);
        }
        else{
          if(element.controls.stopAvailability.value)
          this.disableAvailabilityControls(ruleIndex,condIndex);
       }
      });
     });
     this.yieldSetupForm.get('rules')['controls'] = [...this.yieldSetupForm.get('rules')['controls']];
     this.serviceGroupSelection({value: data.controls.serviceGroup.value},index+1);
     this.yieldSetupForm.get('rules').updateValueAndValidity();
     this.allGridValidation();
     this.yieldSetupForm.markAsDirty();
     this.enableSave();
  }

  createCopyRule(index){
    let data=this.yieldSetupForm.get('rules')['controls'][index];
    if(data.controls.type.value == RuleType.day){
       return this.fb.group({
        type: RuleType.day,
        serviceGroup:[this.serviceGroups,Validators.required],
        services:['',Validators.required],
        serviceOptions: [''],
        maxOccupancy:[0,Validators.required],
        allDays:false,
        daysOfWeek: [this._createYieldSetupBusiness.getWeekList()],
        availability:false,
        conditionArray: this.fb.array([
          this.fb.group({
            condition: ['',Validators.required],
            occupancy:['',Validators.required],
            stopAvailability:false,
            rateChange:0,
            priceType:0,
            priceValue:['',Validators.required]
          })
        ])
       })
    }
    else{
      return  this.fb.group({
        type: RuleType.dayPart,
        startTime:['',Validators.required],
        endTime: ['',Validators.required],
        minEndTime: this.localization.getDefaultTime(),
        maxStartTime: null,
        minStartTime: this.localization.getDefaultTime(),
        maxEndTime: null,
        serviceGroup:[this.serviceGroups,Validators.required],
        services:['',Validators.required],
        serviceOptions: [''],
        maxOccupancy:[0,Validators.required],
        allDays:false,
        daysOfWeek: [this._createYieldSetupBusiness.getWeekList()],
        availability:false,
        conditionArray: this.fb.array([
          this.fb.group({
            condition: ['',Validators.required],
            occupancy:['',Validators.required],
            stopAvailability:false,
            rateChange:0,
            priceType:0,
            priceValue:['',Validators.required]
          })
        ])
       })
      
    }
  }

  clearAllRules(){
    this._utilities.showAlert(this.captions.lbl_showclearMsg, AlertType.Warning, ButtonType.YesNo, async (res) => {
      if (res == AlertAction.YES) {
           const controls = this.yieldSetupForm.get('rules') as UntypedFormArray;
           let controlsLength:number = controls['controls'].length;
           for(let i=0; i < controlsLength ; i++){
            controls.removeAt(0)
        }
      }
    });
    this.yieldSetupForm.markAsDirty();
    this.enableSave();
  }

  stopAllAvailability(index){
    const controls = this.yieldSetupForm.get('rules')['controls'][index].controls as UntypedFormArray;
    controls['availability'].setValue(!controls['availability'].value);
    let conditionArr = controls['conditionArray'].controls;
    if(controls['availability'].value){
      controls['maxOccupancy'].disable();
    }
    else{
      controls['maxOccupancy'].enable();
    }
    for(let i=0; i < conditionArr.length ; i++){
      if(controls['availability'].value){
        this.disableAvailabilityControls(index,i,true);
      }
      else{
        this.enableAvailabilityControls(index,i,true);
      }
     }
     this.yieldSetupForm.markAsDirty();
     this.enableSave();
  }

  stopAvailability(ruleIndex,conditionIndex){
    const controls = this.yieldSetupForm.get('rules')['controls'][ruleIndex].controls['conditionArray'].controls;
    controls[conditionIndex].controls.stopAvailability.setValue(!controls[conditionIndex].controls.stopAvailability.value);
    if(controls[conditionIndex].controls.stopAvailability.value){
      this.disableAvailabilityControls(ruleIndex,conditionIndex);
    }
    else{
      this.enableAvailabilityControls(ruleIndex,conditionIndex);
    }
  }

  enableAvailabilityControls(ruleIndex,conditionIndex,fromAll?){
    const controls = this.yieldSetupForm.get('rules')['controls'][ruleIndex].controls['conditionArray'].controls;
    let totalArr = controls;
    if(!fromAll){
     totalArr = controls.filter((x,ind,array) => array.indexOf(x) !=conditionIndex);
      controls[conditionIndex].controls.rateChange.enable();
      controls[conditionIndex].controls.priceType.enable();
      controls[conditionIndex].controls.priceValue.enable();
      controls[conditionIndex].controls.stopAvailability.patchValue(false);
    }
    for(let i=conditionIndex; i < totalArr.length ; i++){
      totalArr[i].controls.stopAvailability.patchValue(false);
      totalArr[i].controls.stopAvailability.enable();
      totalArr[i].controls.condition.enable();
      totalArr[i].controls.occupancy.enable();
      totalArr[i].controls.rateChange.enable();
      totalArr[i].controls.priceType.enable();
      totalArr[i].controls.priceValue.enable();
    }
    this.yieldSetupForm.markAsDirty();
    this.enableSave();
  }

  disableAvailabilityControls(ruleIndex,conditionIndex,fromAll?){
    const controls = this.yieldSetupForm.get('rules')['controls'][ruleIndex].controls['conditionArray'].controls;
    let totalArr = controls;
    if(!fromAll){
     totalArr = controls.filter((x,ind,array) => array.indexOf(x) !=conditionIndex);
    controls[conditionIndex].controls.rateChange.disable();
    controls[conditionIndex].controls.priceType.disable();
    controls[conditionIndex].controls.priceValue.disable();
    controls[conditionIndex].controls.stopAvailability.patchValue(true);
    }

    for(let i=conditionIndex; i < totalArr.length ; i++){
      totalArr[i].controls.stopAvailability.patchValue(true);
      totalArr[i].controls.stopAvailability.disable();
      totalArr[i].controls.condition.disable();
      totalArr[i].controls.occupancy.disable();
      totalArr[i].controls.rateChange.disable();
      totalArr[i].controls.priceType.disable();
      totalArr[i].controls.priceValue.disable();
    }
    this.yieldSetupForm.markAsDirty();
    this.enableSave();
  }

  onConditionChange(fromEdit,ruleInd?,condInd?){
     const controls = this.yieldSetupForm.get('rules')['controls'][ruleInd].controls['conditionArray'].controls;
     let conditionArray = controls[condInd];
    if (!fromEdit){
      conditionArray.controls.occupancy.setValue('');
      conditionArray.controls.rateChange.setValue(0);
      conditionArray.controls.rateChange.enable();
      conditionArray.controls.priceType.setValue(0);
      conditionArray.controls.priceType.enable();
      conditionArray.controls.stopAvailability.setValue(false);
      conditionArray.controls.priceValue.setValue('');
      conditionArray.controls.priceValue.enable();
    this.checkOverallConditionValue(ruleInd,condInd);
    this.enableAvailabilityControls(ruleInd,condInd);
    }
    conditionArray.controls.priceValue.setValidators(Validators.required);
    conditionArray.controls.occupancy.setValidators(Validators.required);
    if(fromEdit){
      if(this.yieldSetupForm.get('rules')['controls'][ruleInd].controls.availability.value){
        this.disableAvailabilityControls(ruleInd,condInd,true);
      }
      else{
        if(conditionArray.controls.stopAvailability.value){
          this.disableAvailabilityControls(ruleInd,condInd);
        }
      }
    }   
    this.enableSave();
  }

  rateTypeChange(e){
    this.enableSave();
  }

  priceTypeToggleChange(array){
    array.controls.priceValue.setValue('');
    this.enableSave();
  }

  checkConditionValueValidation(ruleIndex,index){
    this.checkConditionValue(ruleIndex,index);
    this.checkOverallConditionValue(ruleIndex,index);
    this.enableSave();
  }

  inputChange(){
    this.enableSave();
  }

  checkOverallConditionValue(ruleIndex,condInd){
    let overallArr= this.yieldSetupForm.get('rules') as UntypedFormArray;
    let conditionArr;
    conditionArr=overallArr['controls'][ruleIndex]['controls']['conditionArray']
    conditionArr.controls.forEach((ele,currCondindex) => {
          if(condInd!=currCondindex){
            ele.controls['occupancy'].setErrors(null);
            this.checkConditionValue(ruleIndex,currCondindex);
          }

    })
  }

  checkConditionValue(ruleIndex,index){
    let overallArr= this.yieldSetupForm.get('rules') as UntypedFormArray
    let conditionArr;
    conditionArr=overallArr['controls'][ruleIndex]['controls']['conditionArray']
    let currIndex = conditionArr.controls[index].controls;
    let totalArr = conditionArr.controls.filter((x,ind,array) => array.indexOf(x) !=index)
    if((currIndex['occupancy'].value.toString()=='' || currIndex['occupancy'].value.toString()==null)){
     currIndex['occupancy'].setErrors({ required: true });
   } 
   else if(this.localization.currencyToSQLFormat(currIndex['occupancy'].value) > 100){
     currIndex['occupancy'].setErrors({ max: true });
   }
   else{
     currIndex['occupancy'].setErrors(null);
     if(totalArr.length>0){
       totalArr.forEach(ele => {
         if((ele.controls['condition'].value === currIndex['condition'].value) && (this.localization.currencyToSQLFormat(ele.controls['occupancy'].value) === this.localization.currencyToSQLFormat(currIndex['occupancy'].value))){
          currIndex['occupancy'].setErrors({ invalid: true });
        }
       })
      }
    }
   this.enableSave();
  }

  bindFormData() {
    if (this.inputs.mode === ActionMode.Edit) {
      if(this.editData.length > 0){
        this.minDate = this.localization.compareDates(this.localization.getDate(this.editData[0].startDateTime),this.propertyDate) ? this.localization.getDate(this.editData[0].startDateTime):this.propertyDate;
        this.yieldSetupForm.controls.startDate.setValue(this.editData[0].startDateTime);
        this.yieldSetupForm.controls.endDate.setValue(this.editData[0].endDateTime);
        this.yieldSetupForm.controls.name.setValue(this.editData[0].name);
        this.yieldSetupForm.controls.isActive.setValue(this.editData[0].isActive);
        this.oldStartDateValue = '';
        this.startDateChange({value: this.editData[0].startDateTime});
        this.endDateChange({value: this.editData[0].endDateTime});
        this.yieldSetupForm.controls.name.disable();
      }
    }    
    if (this.inputs.mode === ActionMode.Copy) {
      this.yieldSetupForm.controls.startDate.setValue(this.localization.getDate(this.propertyDate));
      this.yieldSetupForm.controls.endDate.setValue(this.localization.getDate(this.propertyDate));
      this.startDateChange({value: this.localization.getDate(this.propertyDate)});
    }
    let utilizationArr = this.editData.filter(x => x.ruleCategoryId === DynamicPricingRuleCategory.Utilization);
    
    if (utilizationArr.length > 0) {
      this.bindUtilArr(utilizationArr);
    }
    this.yieldSetupForm.markAsPristine();
  }

  bindOverrideData() {
    this.utils.ToggleLoader(true);
    let overridedataObj;
      if (this.inputs.mode === ActionMode.SingleDateOverride) {        
        overridedataObj = this.overrideData.length > 0 ? this.overrideData[0] : this.overrideData;
        this.minDate = this.localization.compareDates(this.inputs.startDate,this.propertyDate) ? this.propertyDate:this.inputs.startDate;
        this.maxDate = this.inputs.endDate;
        this.yieldSetupForm.controls.singleOverrideDate.setValue(overridedataObj?.date);
      } else if (this.inputs.mode === ActionMode.MultiDateOverride) {
        this.minDate = this.localization.compareDates(this.inputs.startDate,this.propertyDate) ? this.propertyDate:this.inputs.startDate;
        this.maxDate = this.inputs.endDate;
        overridedataObj = this.overrideData.length > 0 ? this.overrideData[0] : this.overrideData;
      } else if(this.inputs.mode === ActionMode.EditOverride) {
        this.composeOverriddenDates();
        overridedataObj = this.overrideData[0];
      }
      
      this.yieldSetupForm.controls.name.setValue(overridedataObj.name);
      this.yieldSetupForm.controls.isActive.setValue(overridedataObj.isActive);
      let uniqService = [...new Set(this.overrideData.flatMap(od => od.serviceId)).values()]
      let servicesOptions = this.serviceOptionsMapper(uniqService);
      if(servicesOptions?.length > 0) {
        this.servicesOptions = servicesOptions.flatMap(so => so);
      }     
      
      const overrideArr = this.yieldSetupForm.get('overRide') as UntypedFormArray;
      
      
      let uniqServiceGroup = [...new Set(this.overrideData.flatMap(od => od.serviceGroupId)).values()]
      let serviceOption = this.serviceMapper(uniqService);
      if (this.inputs.mode === ActionMode.SingleDateOverride || this.inputs.mode === ActionMode.MultiDateOverride) {
        overrideArr.controls[0].get('serviceGroup').setValue(overridedataObj.serviceGroup);
        this.serviceGroups =  this.serviceGroups.filter(x => overridedataObj.serviceGroup.includes(x.id));
      }
      if (this.inputs.mode === ActionMode.EditOverride) {
        overrideArr.controls[0].get('serviceGroup').setValue(uniqServiceGroup);
        this.serviceGroups =  this.serviceGroups.filter(x => uniqServiceGroup.includes(x.id));
      }
     
      overrideArr.controls[0].get('services').setValue(serviceOption);
      if (this.inputs.mode === ActionMode.EditOverride) {
        if(overridedataObj.dynamicPricingStrategy?.length > 0){
          overrideArr.controls[0].get('overrideValue').setValue(this.localization.localizeCurrency(overridedataObj.dynamicPricingStrategy[0].dynamicPricingStrategyValue,false));
          overrideArr.controls[0].get('availability').setValue(overridedataObj.dynamicPricingStrategy[0].dynamicAvailability);
          if(overridedataObj.dynamicPricingStrategy[0].dynamicAvailability){
            overrideArr.controls[0].get('overrideValue').disable();
            overrideArr.controls[0].get('maxOccupancy').disable();
          }
        }else{
          overrideArr.controls[0].get('availability').setValue(overridedataObj.dynamicAvailability);
          overrideArr.controls[0].get('overrideValue').disable();
          overrideArr.controls[0].get('maxOccupancy').disable();
        }
        overrideArr.controls[0].get('maxOccupancy').setValue(overridedataObj.limits);    
      }
      let day = this.localization.getDayForDate(overridedataObj.date); 
      
      this.weekDays.filter(x => {
        if (x.viewVal.toUpperCase() === day.toUpperCase()) {
          x.isClicked = true;          
        } else {
          x.isClicked = false;
        }
      });
      overrideArr.controls[0].get('daysOfWeek').setValue(this.weekDays);
      overrideArr.controls[0].get('type').setValue(RuleType.day);
      
     
      //this.servicesOptions = overridedataObj.serviceOptions;
      // overrideArr.controls[0].get('serviceGroup').disable();
      // overrideArr.controls[0].get('services').disable();
      this.yieldSetupForm.controls.name.disable();
      this.yieldSetupForm.updateValueAndValidity();
      this.utils.ToggleLoader(false);
  }

  composeOverriddenDates(){
    this.readonlyoverridenDates = '';
    let dateArray = [];
    if(this.overrideData && this.overrideData.length > 0){
      for (let index = 0; index < this.overrideData.length; index++) {
        const prevVal = ((index - 1)>=0)? this.overrideData[index - 1]: null;
        const currentVal = this.overrideData[index];
        const nextVal = ((index + 1)<this.overrideData.length)? this.overrideData[index + 1]: null;
        if(this.inputs.mode === ActionMode.EditOverride){
          const currentDateVal = this.localization.getDate( this.localization.getDate(currentVal.startDateTime).setHours(0,0,0,0));
          if(!dateArray.find(x=> this._utilities.ValidateDatesAreEqual(x, currentDateVal))){
            dateArray.push(currentDateVal);
          }
        }
        // if(index == 0){          
        //   this.readonlyoverridenDates += this.localization.LocalizeDate(this.localization.getDate( this.localization.getDate(currentVal.startDateTime).setHours(0,0,0,0)));
        // } else if(index == this.overrideData.length-1){
        //   let symbol = (this.readonlyoverridenDates[this.readonlyoverridenDates.length - 1] == '-')? '':',';
        //   this.readonlyoverridenDates += symbol + this.localization.LocalizeDate(this.localization.getDate( this.localization.getDate(currentVal.startDateTime).setHours(0,0,0,0)));;
        // }else{
        //   if(this.checkIfContinuousFromPrevDate(prevVal, currentVal) && this.checkIfContinuousToNextDate(currentVal, nextVal)){
        //     let symbol = (this.readonlyoverridenDates[this.readonlyoverridenDates.length - 1] == '-')? '':'-';
        //     this.readonlyoverridenDates += symbol;
        //   }
        //   if((this.checkIfContinuousFromPrevDate(prevVal, currentVal) && !this.checkIfContinuousToNextDate(currentVal, nextVal)) || (!this.checkIfContinuousFromPrevDate(prevVal, currentVal) && this.checkIfContinuousToNextDate(currentVal, nextVal))){
        //     let symbol = (this.readonlyoverridenDates[this.readonlyoverridenDates.length - 1] == '-')? '':',';
        //     this.readonlyoverridenDates += symbol + this.localization.LocalizeDate(this.localization.getDate( this.localization.getDate(currentVal.startDateTime).setHours(0,0,0,0)));;
        //   }
        //   if(!this.checkIfContinuousFromPrevDate(prevVal, currentVal) && !this.checkIfContinuousToNextDate(currentVal, nextVal)){
        //     let symbol = (this.readonlyoverridenDates[this.readonlyoverridenDates.length - 1] == ',')? '':',';
        //     this.readonlyoverridenDates += symbol + this.localization.LocalizeDate(this.localization.getDate( this.localization.getDate(currentVal.startDateTime).setHours(0,0,0,0)));;
        //   }
        // }
      }
    }
    if(this.inputs.mode === ActionMode.EditOverride){
      this.yieldSetupForm.controls.overrideDates.setValue(dateArray);
      this.readonlyoverridenDates = dateArray.sort((a,b) => a - b).map(d => this.localization.GetFormattedDateDDMMYYYY(d)).join(', ')
    }
  }
  checkIfContinuousFromPrevDate(prevVal, currentVal){
    let isContinuous = false;
    if(this._utilities.ValidateDatesAreEqual(this.localization.SubDays(this.localization.getDate(this.localization.getDate(currentVal.startDateTime).setHours(0,0,0,0)), 1),this.localization.getDate(this.localization.getDate(prevVal.startDateTime).setHours(0,0,0,0)))){
      isContinuous = true;
    } else {
      isContinuous = false;
    }
    return isContinuous;
  }
  checkIfContinuousToNextDate(currentVal, nextVal){
    let isContinuous = false;
    if(this._utilities.ValidateDatesAreEqual(this.localization.AddDays(this.localization.getDate(this.localization.getDate(currentVal.startDateTime).setHours(0,0,0,0)), 1), this.localization.getDate(this.localization.getDate(nextVal.startDateTime).setHours(0,0,0,0)))){
      isContinuous = true;
    } else {
      isContinuous = false;
    }
    return isContinuous;
  }

  bindUtilArr(array) {
    this.serviceYieldArray = this.yieldSetupForm.get('rules') as UntypedFormArray;
    this.serviceYieldArray.removeAt(0);
    array.forEach((res,index) => {
      let data = cloneDeep(this.createUtilValues(index, res));
      this.serviceYieldArray.push(data);
      this.serviceGroupIdSelection({value: data.controls.services.value}, index);
      res.dynamicPricingStrategy.forEach((x,condInd)=> {
        this.onConditionChange(true,index,condInd); 
      })
      this.validation(index);
    });
  }
  createUtilValues(_index: number,_data: API.DynamicPricingRule): UntypedFormGroup {
    let serviceGroupId = this.serviceGroupMapper(_data.serviceId);
    return this.fb.group({
      type: _data.ruleType,
      startTime: this.localization.getTime(this.localization.ConvertStringDateTimeToDate(_data.startDateTime, this.localization.LocalizeTime(_data.startDateTime)), this.timeformat) ,
      endTime: this.localization.getTime(this.localization.ConvertStringDateTimeToDate(_data.endDateTime, this.localization.LocalizeTime(_data.endDateTime)), this.timeformat),
      serviceGroup: serviceGroupId && serviceGroupId.length > 0 ? [serviceGroupId] : 0,
      serviceOptions: serviceGroupId && serviceGroupId.length > 0 ?  this.serviceOptionsMapper(_data.serviceId) : null,
      services: [this.serviceMapper(_data.serviceId)],     
      maxOccupancy: _data.limits,
      allDays: this.applyForAllDays(_data.daysOfWeek),
      daysOfWeek:  [this.daysMapper(_data.daysOfWeek)],
      availability: _data.dynamicAvailability,
      conditionArray: this.createStrategyValues(_index, _data.dynamicPricingStrategy),
      minStartTime: this.localization.getDefaultTime(),
      maxStartTime: null,
      minEndTime: this.localization.getDefaultTime(),
      maxEndTime: null
    });
  }

  createStrategyValues(_index: number, _data: API.DynamicPricingStrategy[]) {
    let strategyArr = this.fb.array([]);
    _data.forEach((res, index) => {
       let arr = this.fb.group({
        condition: res.dynamicPricingCondition,
        occupancy: res.conditionValue,
        rateChange: (res.isIncrement)? 0 : 1,
        priceType: res.isPercent ? 0 : 1,
        priceValue: res.dynamicPricingStrategyValue,
        stopAvailability: res.dynamicAvailability ? res.dynamicAvailability: false
      });
      strategyArr.push(arr);
    }); 
    return strategyArr;
  }  

  serviceGroupMapper(serviceList) {
    const res = this.serviceGroups
              .filter(item => item.services.some(x => serviceList.includes(x.id)))
              .map(item => item.id);
    return res;
  }

  serviceOptionsMapper(serviceList) {
    const res = this.serviceGroups
    .filter(item => item.services.some(service => serviceList.includes(service.id)))
    .map(item => item.services);
    return res;
  }

  serviceMapper(serviceList) {
    let result = [];
    this.serviceGroups.forEach(item => {
      item.services.forEach(service => {
        if (serviceList.includes(service.id)) {
          result.push(service.id);
        }
      });
    });
    return result;
  }

  serviceGroupSelection(event, index){
    let serviceGroups = this.serviceGroups.filter(x => event.value.includes(x.id)).flatMap(x => x.services);
    let yieldArr = this.yieldSetupForm.get('rules') as UntypedFormArray;
    yieldArr.controls[index]['controls'].serviceOptions?.setValue(null);
    yieldArr.controls[index]['controls'].serviceOptions?.setValue(serviceGroups);   
    this.yieldSetupForm = this.yieldSetupForm as UntypedFormGroup;
    this.validation(index)
    this.yieldSetupForm.updateValueAndValidity();
    this.enableSave();
  }

  serviceGroupIdSelection(event, index){
    let serviceGroups = this.getServiceGroupId(event.value);
    let yieldArr = this.yieldSetupForm.get('rules') as UntypedFormArray;
    yieldArr.controls[index]['controls'].serviceOptions?.setValue(serviceGroups[0].services);    
    this.yieldSetupForm = this.yieldSetupForm as UntypedFormGroup;
    // this.yieldSetupForm.updateValueAndValidity();
  }

  getServiceGroupId(services) {
    let result = [];
    this.serviceGroups.forEach(item => {
      item.services.forEach(service => {
        if (services.includes(service.id)) {
          result.push(item);
        }
      });
    });
    return result;
  }

  daysMapper(daysOfWeek: DaysOfWeek) {
      this.weekDays = this.setYieldDaysForgrid();
      this.weekDays.forEach(wd=>wd.isClicked = ((daysOfWeek & wd.id) == wd.id));
      return this.weekDays;
  }

  applyForAllDays(daysOfWeek) {
      this.weekDays = this.setYieldDaysForgrid();
      let arr = this.weekDays.filter(x => x.isClicked);
      return arr.length >= this.yieldDays.length ?  true : false;     
  }

  ok(eve) {
    this.isClicked = true;
  }

  cancel(eve) {
    this.isClicked = false;
  }

  getServiceGroupIdMapping(services) : number[] {
   let id;
   this.serviceGroups.forEach(item => {
      item.services.forEach(service => {
        if (services === service.id) {
          id = item.id;
        }
      });
    });    
    return id;
  }

  async onAction(e) {
    try{
    this.utils.ToggleLoader(true);  
    let formData = this.yieldSetupForm.getRawValue();
    await this.saveCall(formData);
    } finally{
      this.utils.ToggleLoader(false);
    }
  }

  async saveCall(formData) {
    let saveObj: API.DynamicPricingRule[] = [];
    if (this.inputs.mode === ActionMode.Create || this.inputs.mode === ActionMode.Copy) {
      formData.rules.forEach((x,i) => {
        let ruleGroupGuid =  this.utils.generateGUID();
          x.services.forEach(y => {
            let obj: API.DynamicPricingRule = {
              id: 0,
              startDateTime: x.type === this.ruleTypeEnum.dayPart ? this.timeDateMapper((formData.startDate), x.startTime) : this.timeDateMapper((formData.startDate), '12:00 AM'),
              endDateTime: x.type === this.ruleTypeEnum.dayPart ? this.timeDateMapper((formData.endDate), x.endTime) : this.timeDateMapper((formData.endDate), '11:59 PM'),
              serviceId: y,
              serviceGroupId: this.getServiceGroupIdMapping(y),
              ruleCategoryId: DynamicPricingRuleCategory.Utilization,
              ruleType: x.type,
              groupId: this.DEFAULT_GUID,
              overrideGroupId: this.DEFAULT_GUID,
              ruleGroupId: ruleGroupGuid,
              limits: x.maxOccupancy,
              daysOfWeek: this.weekCalculation(x.daysOfWeek),
              dynamicAvailability: x.availability,
              isDeleted: false,
              isOverride: false,     
              isActive: formData.isActive,   
              name: formData.name,
              dynamicPricingStrategy: this.strategyMapping(x.conditionArray),
            }
            saveObj.push(obj);
          });  
      });
      
      await this._createYieldSetupBusiness.save(saveObj, this.successCallback.bind(this), this.errorCallback.bind(this)).then(x => {
        
      }).catch(e => {
        this.GetErrorMessage(e);
      });
        
    } else if(this.inputs.mode === ActionMode.Edit) {
      formData.rules.forEach(x => {
        let ruleGroupId = this.bindRuleGroupId(formData,x,x.services);
          x.services.forEach(y => {
            let obj: API.DynamicPricingRule = {
              id: this.bindId(formData,x,y),
              startDateTime: x.type === this.ruleTypeEnum.dayPart ? this.timeDateMapper((formData.startDate), x.startTime) : this.timeDateMapper((formData.startDate), '12:00 AM'),
              endDateTime: x.type === this.ruleTypeEnum.dayPart ? this.timeDateMapper((formData.endDate), x.endTime) : this.timeDateMapper((formData.endDate), '11:59 PM'),
              serviceId: y,
              serviceGroupId: this.getServiceGroupIdMapping(y),
              ruleCategoryId: DynamicPricingRuleCategory.Utilization,
              ruleType: x.type,
              groupId: this.editData[0].groupId ,
              overrideGroupId: this.DEFAULT_GUID,
              ruleGroupId:  ruleGroupId != this.DEFAULT_GUID ? ruleGroupId : this.utils.generateGUID(),
              limits: x.maxOccupancy,
              daysOfWeek: this.weekCalculation(x.daysOfWeek),
              dynamicAvailability: x.availability,
              isDeleted: false,
              isOverride: false,      
              isActive: formData.isActive,  
              name: formData.name,
              dynamicPricingStrategy: this.strategyMapping(x.conditionArray),
            }
            ruleGroupId = obj.ruleGroupId;
            saveObj.push(obj);
          }); 
      });
      
      await this._createYieldSetupBusiness.update(saveObj,this.successCallback.bind(this), this.errorCallback.bind(this)).then(x => {

      }).catch(e => {
        this.GetErrorMessage(e);
      });
    }  else if(this.inputs.mode === ActionMode.SingleDateOverride) {
      formData.overRide.forEach((x,i) => {
          x.services.forEach(y => {
            let obj: API.DynamicPricingRule = {
              id: 0,
              startDateTime: this.timeDateMapper((formData.singleOverrideDate), '12:00 AM'),
              endDateTime: this.timeDateMapper((formData.singleOverrideDate), '11:59 PM'),
              serviceId: y,
              serviceGroupId: this.getServiceGroupIdMapping(y),
              ruleCategoryId: DynamicPricingRuleCategory.Override,
              ruleType: DynamicPricingRulesBy.Day,
              groupId: this.overrideData.length > 0 ? this.overrideData[0].strategyId : this.overrideData?.strategyId,
              overrideGroupId: this.DEFAULT_GUID,
              ruleGroupId: this.utils.generateGUID(),
              limits: x.maxOccupancy,
              daysOfWeek: this.weekCalculation(x.overRideDaysOfWeek),
              dynamicAvailability: false,
              isDeleted: false,
              isOverride: true,     
              isActive: formData.isActive,   
              name: formData.name,
              dynamicPricingStrategy: this.overrideStrategyMapping(x),
            }
            saveObj.push(obj);
          });  
      });
      
      await this._createYieldSetupBusiness.save(saveObj).then(x => {
        this.utils.ToggleLoader(false);
        this.utils.showAlert(this.captions.lbl_overrideCreateSuccessfulMsg,AlertType.Success,ButtonType.Ok,(res) => {          
          if (res == AlertAction.CONTINUE) {
            this.saveClickEvent.emit(true);
          }
        });   
      }).catch(e => {
        this.GetErrorMessage(e);
      });
    }
    else if(this.inputs.mode === ActionMode.MultiDateOverride) {
      formData.overRide.forEach((x,i) => {
        formData.overrideDates.forEach((z,j) => {
          x.services.forEach(y => {
            let obj: API.DynamicPricingRule = {
              id: 0,
              startDateTime: this.timeDateMapper(z, '12:00 AM'),
              endDateTime: this.timeDateMapper(z, '11:59 PM'),
              serviceId: y,
              serviceGroupId: this.getServiceGroupIdMapping(y),
              ruleCategoryId: DynamicPricingRuleCategory.Override,
              ruleType: DynamicPricingRulesBy.Day,
              groupId: this.overrideData.length > 0 ? this.overrideData[0].strategyId : this.overrideData?.strategyId,
              overrideGroupId: this.DEFAULT_GUID,
              ruleGroupId: this.utils.generateGUID(),
              limits: x.maxOccupancy,
              daysOfWeek: this.weekCalculation(x.overRideDaysOfWeek),
              dynamicAvailability: false,
              isDeleted: false,
              isOverride: true,     
              isActive: formData.isActive,   
              name: formData.name,
              dynamicPricingStrategy: this.overrideStrategyMapping(x),
            }
            saveObj.push(obj);
          }); 
        }); 
      });
      
      await this._createYieldSetupBusiness.save(saveObj).then(x => {
        this.utils.ToggleLoader(false);
        this.utils.showAlert(this.captions.lbl_overrideCreateSuccessfulMsg,AlertType.Success,ButtonType.Ok,(res) => {          
          if (res == AlertAction.CONTINUE) {
            this.saveClickEvent.emit(true);
          }
        });   
      }).catch(e => {
        this.GetErrorMessage(e);
      });
    } else if (this.inputs.mode == ActionMode.EditOverride) {
      formData.overRide.forEach((x,i) => {
        formData.overrideDates.forEach((z,j) => {          
          x.services.forEach(y => {
            let ruleGroupId = this.bindOverrideId(y,false,z,true)
            let obj: API.DynamicPricingRule = {
              id: this.bindOverrideId(y,false,z,false),
              startDateTime: this.timeDateMapper(z, '12:00 AM'),
              endDateTime: this.timeDateMapper(z, '11:59 PM'),
              serviceId: y,
              serviceGroupId: this.getServiceGroupIdMapping(y),
              ruleCategoryId: DynamicPricingRuleCategory.Override,
              ruleType: DynamicPricingRulesBy.Day,
              groupId: this.overrideData[0].groupId,
              overrideGroupId: this.overrideData[0].overrideGroupId,
              ruleGroupId: ruleGroupId === 0 ? this.bindId(formData,x,y) : ruleGroupId,
              limits: x.maxOccupancy,
              daysOfWeek: this.weekCalculation(x.daysOfWeek),
              dynamicAvailability: false,
              isDeleted: false,
              isOverride: true,     
              isActive: formData.isActive,   
              name: formData.name,
              dynamicPricingStrategy: this.overrideStrategyMapping(x),
            }
            saveObj.push(obj);
          }); 
        }); 
      });
      
      await this._createYieldSetupBusiness.update(saveObj).then(x => {
        this.utils.ToggleLoader(false);
        this.utils.showAlert(this.captions.lbl_overrideUpdateSuccessfulMsg,AlertType.Success,ButtonType.Ok,(res) => {          
          if (res == AlertAction.CONTINUE) {
            this.saveClickEvent.emit(true);
          }
        });   
      }).catch(e => {
        this.GetErrorMessage(e);
      });
    }
  }
  strategyMapping(conditionArr): API.DynamicPricingStrategy[] {
    let strategyArr = [];
    if (conditionArr && conditionArr.length > 0) {
      conditionArr.forEach(res => {
        if(res.condition != "" && res.condition != DynamicPricingCondition.NoCondition)
        {
          strategyArr.push({
            id: 0,
            dynamicPricingCondition: res.condition,
            conditionValue: this.localization.currencyToSQLFormat(res.occupancy),
            isIncrement: res.rateChange == 0 ? true : false,
            isPercent: res.priceType == 0 ? true : false,
            dynamicPricingStrategyValue: this.localization.currencyToSQLFormat(res.priceValue),
            dynamicPricingRuleId: 0,
            dynamicAvailability: res.stopAvailability
          });
        }
         
      })
    }
    return strategyArr;
  }
  overrideStrategyMapping(conditionArr): API.DynamicPricingStrategy[] {
    let strategyArr = [];
    if (conditionArr) {
      if (conditionArr.overrideValue || conditionArr.availability) {
        strategyArr.push({
          id: 0,
          dynamicPricingCondition: DynamicPricingCondition.NoCondition,
          conditionValue: 0,
          isIncrement: false,
          isPercent: false,
          dynamicPricingStrategyValue: this.localization.currencyToSQLFormat(conditionArr.overrideValue),
          dynamicPricingRuleId: 0,
          dynamicAvailability: conditionArr.availability
        })
      }
    }
    return strategyArr;
  }

  successCallback<T>(result: BaseResponse<T>, callDesc: string, msg) {
    switch (callDesc) {
      case 'CreateDynamicPricing': {
        this.utils.ToggleLoader(false);
        this.utils.showAlert(this.captions.lbl_createSuccessfullMsg,AlertType.Success,ButtonType.Ok,(res) => {          
          if (res == AlertAction.CONTINUE) {
            this.saveClickEvent.emit(true);
          }
        });   
        break;
      }
      case 'UpdateDynamicPricing': {
        this.utils.ToggleLoader(false);
          this.utils.showAlert(this.captions.lbl_updateSuccessfullMsg,AlertType.Success,ButtonType.Ok,(res) => {
            if (res == AlertAction.CONTINUE) {
              this.saveClickEvent.emit(true);
            }
          });  
        break;
      }
    }
  }

  GetErrorMessage(error) {
    if (!error.result) {
      let errorMsg = this.localization.getError(error?.errorCode);
        this.utils.showAlert(errorMsg, AlertType.Error);    
      }
  }

  errorCallback<T>(result: BaseResponse<T>, callDesc: string) {

  }

  bindId(formdata,rule, serviceId) {
    let id = 0;    
    let st = this.timeDateUIMapper(formdata.startDate, rule.startTime);
    let et = this.timeDateUIMapper(formdata.endDate, rule.endTime);
    let res = this.editDataArr.filter(x => x.serviceId == serviceId && this.utils.ValidateDatesAreEqualWithTime(st,this.localization.getDate(x.startDateTime)) && this.utils.ValidateDatesAreEqualWithTime(et,this.localization.getDate(x.endDateTime)));
    if (res.length > 0) {     
        id = res[0]['id'] ? res[0]['id'] : 0;
    }
    return id;
  }  

  bindRuleGroupId(formdata,rule, serviceIds) {
    let id = this.DEFAULT_GUID;    
    let st = this.timeDateUIMapper(formdata.startDate, rule.startTime);
    let et = this.timeDateUIMapper(formdata.endDate, rule.endTime);
    let res = this.editDataArr.filter(x => serviceIds.includes(x.serviceId) && this.utils.ValidateDatesAreEqualWithTime(st,this.localization.getDate(x.startDateTime)) && this.utils.ValidateDatesAreEqualWithTime(et,this.localization.getDate(x.endDateTime)));
   
    if(res && res.length > 0){
      let availableRuleGroup = this.editDataArr.filter(x => x.ruleGroupId && rule.services?.includes(x.serviceId) && this.utils.ValidateDatesAreEqualWithTime(st,this.localization.getDate(x.startDateTime)) && this.utils.ValidateDatesAreEqualWithTime(et,this.localization.getDate(x.endDateTime)))
      if(availableRuleGroup && availableRuleGroup?.length > 0){
        id = availableRuleGroup[0]['ruleGroupId'] ? availableRuleGroup[0]['ruleGroupId']: 0
      }
    }
    return id;
  }

  bindOverrideId(serviceId, isGroupId, date, isRulegroupId) {
    let id;
    let res = this.overrideData.filter(x => x.serviceId.includes(serviceId) && this.utils.ValidateDatesAreEqual(new Date(x.startDateTime), date) && this.utils.ValidateDatesAreEqual(new Date(x.endDateTime), date));
    if (res.length > 0) {
      if (isGroupId) {
        id = res[0]['groupId'] ? res[0]['groupId'] : this.DEFAULT_GUID;
      } else if (isRulegroupId) {
        id = res[0]['ruleGroupId'] ? res[0]['ruleGroupId'] : this.DEFAULT_GUID;
      } else {
        id = res[0]['id'] ? res[0]['id'] : 0;
      }
    } else {
      if (!isGroupId) {
        id = 0;
      }
    }
    return id;
  }

  timeDateMapper(date, time) {
    let time_calc = this.localization.TimeToDate(time);
    let res = this.localization.AddTimeToDate(date, time_calc);
    return this.localization.convertDateTimeToAPIDateTime(res);
  }

  timeDateUIMapper(date, time) {
    let time_calc = this.localization.TimeToDate(time);
    let res = this.localization.AddTimeToDate(date, time_calc);
    return res;
  }

  weekCalculation(days) {
    let checked = days.filter(wd => wd.isClicked);
    let daysOfWeek: DaysOfWeek;
    checked.forEach(r => {
      daysOfWeek = r.id | daysOfWeek
    })
    return daysOfWeek;
  }

  onCancel(eve) {
    const clickReturnValue = {
      from: ActionMode.Cancel,
      formValues: this.yieldSetupForm.value,
      form: this.yieldSetupForm
    };
    if (this.yieldSetupForm.dirty) {
      this.utils.showAlert(this.captions.lbl_Cancel_Msg, AlertType.Warning, ButtonType.YesNo, (res) => {
        if (res == AlertAction.YES) {
          this.saveClickEvent.emit(clickReturnValue);
        }
      })
    }
    else {
      this.saveClickEvent.emit(clickReturnValue);
    }
  }

  getOverrideArray(){
    this.yieldSetupForm.removeControl('rules');
    this.yieldSetupForm.get('overRide')['controls'][0].controls.serviceGroup.setValidators(Validators.required);
    this.yieldSetupForm.get('overRide')['controls'][0].controls.services.setValidators(Validators.required);
    this.yieldSetupForm.get('overRide')['controls'][0].controls.overrideValue.setValidators(Validators.required);
    this.yieldSetupForm.get('overRide')['controls'][0].controls.maxOccupancy.setValidators(Validators.required);
    if(this.inputs.mode === this.actionTypeEnum.SingleDateOverride){
      this.yieldSetupForm.controls.startDate.clearValidators();
      this.yieldSetupForm.controls.endDate.clearValidators();
      this.yieldSetupForm.controls.singleOverrideDate.setValidators(Validators.required);
      this.yieldSetupForm.controls.singleOverrideDate.updateValueAndValidity();
      this.yieldSetupForm.controls.singleOverrideDate.setValue(this.inputs.gridDate);
      this.singleDateChanged(this.yieldSetupForm.controls.singleOverrideDate.value,true);
    }
    else if(this.inputs.mode === this.actionTypeEnum.MultiDateOverride){
      this.onSelectDateByRange(true);
      this.yieldSetupForm.controls.overrideDates.setValidators(Validators.required);
      this.yieldSetupForm.controls.singleOverrideDate.clearValidators();
    }
  }

  singleDateChanged(event,fromPatch = false){
    this.overrideDays = [];
    let date = fromPatch ?event : event.value;
    this.overrideDays.push(this.localization.getDate(date).toLocaleDateString('en-us', {weekday : 'short'}))
    this.selectOverrideDays();
    this.enableSave();
  }

  selectOverrideDays(){
    let control=this.yieldSetupForm.get('overRide')['controls'][0];
    control.controls.overRideDaysOfWeek.setValue(this.setYieldDaysForgrid())
    control.controls.overRideDaysOfWeek.value.forEach(x=>{x.isDisabled=true})
    control.controls.overRideDaysOfWeek.value.forEach(days => {
          this.overrideDays.forEach(element => {
            if(element==days.viewVal){
               days.isClicked=true
            }
          });
     });
  }

  onSelectDateByRange(e){
    this.overrideDays=[];
    this.model=[];
    if(e){
      this.yieldSetupForm.controls.startDate.setValue('')
      this.yieldSetupForm.controls.startDate.setValidators(Validators.required);
      this.yieldSetupForm.controls.startDate.updateValueAndValidity();
      this.yieldSetupForm.controls.endDate.setValue('')
      this.yieldSetupForm.controls.endDate.setValidators(Validators.required);
      this.yieldSetupForm.controls.endDate.updateValueAndValidity();
      this.yieldSetupForm.controls.multiOverrideDate.setValue('');
      this.yieldSetupForm.controls.multiOverrideDate.clearValidators();
      this.yieldSetupForm.controls.multiOverrideDate.updateValueAndValidity();
    }
    else{
      this.yieldSetupForm.controls.startDate.setValue('')
      this.yieldSetupForm.controls.startDate.clearValidators();
      this.yieldSetupForm.controls.startDate.updateValueAndValidity();
      this.yieldSetupForm.controls.endDate.setValue('')
      this.yieldSetupForm.controls.endDate.clearValidators();
      this.yieldSetupForm.controls.endDate.updateValueAndValidity();
      this.yieldSetupForm.controls.overrideDates.setValue('');
      this.yieldSetupForm.controls.multiOverrideDate.setValue('');
      this.yieldSetupForm.controls.multiOverrideDate.setValidators(Validators.required);
      this.yieldSetupForm.controls.multiOverrideDate.updateValueAndValidity();
    }
  }

  public dateClass = (date: Date) => {
    if (this._findDate(date) !== -1) {
      return [ 'selected' ];
    }
    return [ ];
  }

  public dateChanged(event, fromPatch = false): void {
    if (event) {
      const date = fromPatch ? event :event.value;
      const index = this._findDate(date);
      this.overrideDays.push(this.localization.getDate(date).toLocaleDateString('en-us', {weekday : 'short'}))
      this.selectOverrideDays()
      if (index === -1) {
        this.model.push(date);
      } else {
        this.model.splice(index, 1)
      }
      this.yieldSetupForm.controls.overrideDates.setValue(this.model);
      this.resetModel = new Date(0);
      if (!this.closeOnSelected && !fromPatch) {
        const closeFn = this._picker.close;
        this._picker.close = () => { };
        this._picker['_componentRef'].instance._calendar.monthView._createWeekCells()
        setTimeout(() => {
          this._picker.close = closeFn;
        });
      }     
    }
  }

  public remove(date: Date): void {
    const index = this._findDate(date);
    this.model.splice(index, 1)
    this.removeDays(this.localization.getDayForDate(date))
    this.selectOverrideDays();
    this.yieldSetupForm.controls.overrideDates.setValue(this.model);
  }

  private _findDate(date: Date): number {
    return this.model.map((m) => +m).indexOf(+date);
  }

  removeDays(day){
    let index = this.overrideDays.map(m=>m.indexOf(day))
    this.overrideDays.splice(index, 1)
  }

  stopAvailabilityForOverride(index){
   let control = this.yieldSetupForm.get('overRide') as UntypedFormArray;
    control['controls'][index]['controls'].availability.setValue(!control['controls'][index]['controls'].availability.value);
    if(control['controls'][index]['controls'].availability.value){
      control['controls'][index]['controls'].overrideValue.disable();
      control['controls'][index]['controls'].maxOccupancy.disable();
    }
    else{
      control['controls'][index]['controls'].overrideValue.enable();
      control['controls'][index]['controls'].maxOccupancy.enable();
    }
    this.yieldSetupForm.markAsDirty();
    this.enableSave();
  }

  onIsActiveChange(e){
    
  }

  validation(index){
    this.gridValidation(index);
    this.allGridValidation();
  }

  allGridValidation(){
    let overallArr = this.yieldSetupForm.get('rules') as UntypedFormArray;
    overallArr.controls.forEach((element,index) => {
      this.gridValidation(index);
    });
  }

  gridValidation(index){
    let overallArr;
    let currentArr;
    let totalArr;
    let selectedDays=[];
    overallArr = this.yieldSetupForm.get('rules') as UntypedFormArray;
    currentArr = overallArr.controls[index];
    totalArr = overallArr.controls.filter((x,ind,array) => array.indexOf(x) !=index);

    //dayPart validations
    if(currentArr['controls']['type'].value == this.ruleTypeEnum.dayPart){

      const currentStartTime = (this.localization.TimeToDate(currentArr['controls']['startTime'].value)).getTime() ;
      const currentEndTime = (this.localization.TimeToDate(currentArr['controls']['endTime'].value)).getTime();
      let currentServiceGroup = currentArr['controls']['serviceGroup'].value;
      let currentServices = currentArr['controls']['services'].value;

      totalArr.forEach(arr=>{
        let serviceValid = true;
        let services = arr['controls']['services'].value;
        let serviceGroup = arr['controls']['serviceGroup'].value;

        if(arr['controls']['type'].value == this.ruleTypeEnum.day){
            serviceValid = !services.some(value => currentServices.includes(value));
            if(!serviceValid)
            selectedDays=[...selectedDays,...arr['controls']['daysOfWeek'].value.filter(x => x.isClicked==true)]
        }
      })
    
        totalArr.forEach(arr=>{
        let isTimeValid = true;
        let startValid = true;
        let endValid = true;
        let serviceValid = true;
        //checking grid'services st and et 
        if(arr['controls']['type'].value == this.ruleTypeEnum.dayPart){
          let st=(this.localization.TimeToDate(arr['controls']['startTime'].value)).getTime();
          let et=(this.localization.TimeToDate(arr['controls']['endTime'].value)).getTime();
  
          if(st <= currentStartTime && et >= currentStartTime ){
            isTimeValid=false
            startValid = false
          }
          else if(st <= currentEndTime && et >= currentEndTime){
            isTimeValid=false
            endValid = false
          }
          else if(st == currentStartTime && et == currentEndTime){
            isTimeValid=false
          }
          else if(st >= currentStartTime && st <= currentEndTime){
            isTimeValid=false
            startValid = false
          }
          else if(et >= currentStartTime && et <= currentEndTime){
            isTimeValid=false
            endValid = false
          }
          else if((st <= currentStartTime && et >= currentEndTime) || !startValid || !endValid){
            isTimeValid=false;
          }
        }
       
        // checking grid'services service and service group
        let serviceGroup = arr['controls']['serviceGroup'].value;
        let services = arr['controls']['services'].value;

          serviceValid = !services.some(value => currentServices.includes(value));

        if(!isTimeValid && !serviceValid){
          selectedDays=[...selectedDays,...arr['controls']['daysOfWeek'].value.filter(x => x.isClicked==true)]
        }
        if(selectedDays.length == this.yieldDays.length && !startValid && !serviceValid){
          currentArr['controls']['startTime'].setErrors({ invalid: true });
        }
        else if(selectedDays.length==this.yieldDays.length && !endValid && !serviceValid){
          currentArr['controls']['endTime'].setErrors({ invalid: true });
        }
      })
    }
    //day validations
    else{
      let currentServiceGroup = currentArr['controls']['serviceGroup'].value;
      let currentServices = currentArr['controls']['services'].value;
      
      totalArr.forEach(arr=>{
        let serviceValid = true;
        let serviceGroup = arr['controls']['serviceGroup'].value;
        let services = arr['controls']['services'].value;
          serviceValid = !services?.some(value => currentServices.includes(value));
        if(!serviceValid){
          selectedDays=[...selectedDays,...arr['controls']['daysOfWeek'].value.filter(x => x.isClicked==true)]
        }
        if(!serviceValid && selectedDays.length == this.yieldDays.length){
          currentArr['controls']['services'].setErrors({ invalid: true });
        }
       
     });
    }
    currentArr['controls']['daysOfWeek'].value.forEach(x=>{
      let days= selectedDays.filter(day =>  day.id == x.id)

      if(days.length > 0){
        x.isDisabled=true
        x.isClicked=false
       }
       else{
        x.isDisabled=false 
       }

       if(!x.isAvailable)
         x.isDisabled = true;
    })
    let allDays=currentArr['controls']['daysOfWeek'].value.every(x=> x.isDisabled==true)
    if(allDays){
      currentArr['controls']['allDays'].disable();
    }
    else{
      currentArr['controls']['allDays'].enable();
    }
    let filteredDays=currentArr['controls']['daysOfWeek'].value.filter(x=> x.isClicked==true)
    let availableDays = currentArr['controls']['daysOfWeek'].value.filter(x => x.isDisabled==false)
    if(filteredDays.length==availableDays.length && (filteredDays.length!=0 && availableDays.length!=0)){
      currentArr['controls']['allDays'].setValue(true);
    }
    else{
      currentArr['controls']['allDays'].setValue(false)
    }

  }

  previewPricing(event){
    this.dialog.open(ViewYieldSetupComponent, {
      panelClass: 'ag_dialog--lg',
      data: {
        viewData: null,
        setupForm: this.yieldSetupForm
      }
    });
  }

  onServiceGroupChange(event:MatSelectChange,index:number)
  {
    let overrideArr = this.yieldSetupForm.get('overRide') as UntypedFormArray
     if(event && event?.value?.length > 0){
      this.servicesOptions = this.serviceGroups.filter(sg => event.value.includes(sg.id)).flatMap(s => s.services);
      const existingService = overrideArr.controls[index].get('services').value;
      const availableService = this.servicesOptions.filter(so => existingService.includes(so.id)).map(s => s.id)
      overrideArr.controls[index].get('services').setValue(availableService);
     }else{
      this.servicesOptions = [];
      overrideArr.controls[index].get('services').setValue([]);
     }
  }

}
