import { Component, OnInit, ViewEncapsulation, ViewChild, OnDestroy, ViewContainerRef, ElementRef, HostListener, Input, ChangeDetectorRef } from '@angular/core';
import { AppointmentpopupService } from '../../../service/appointmentpopup.service';
import { MatSelect } from '@angular/material/select';
import { HttpServiceCall, HttpMethod } from '../../../service/http-call.service';
import { AppointmentFilters } from '../../../business/new-booking.model';
import { UntypedFormBuilder, UntypedFormGroup, Validators, UntypedFormArray } from '@angular/forms';
import * as global from '../../../globalsContant';
import { SpaUtilities } from '../../../utilities/spa-utilities';
import * as _ from 'lodash'
import * as moment from 'moment';
import { SPAConfig } from '../../../../core/config/SPA-config';
import { SlideInformationService } from '../../../../shared/slide-information/slide-information.service';
import { ClientScreenProperties, BaseResponse, appointment, TempHoldApp } from '../../../business/shared.modals';
import { ReplaySubject } from "rxjs";
import { AppointmentStatus, AppointmentUtilities } from '../../../utilities/appointment-utilities';
import { SpaLocalization } from '../../../../core/localization/spa-localization';
import { appointmentService } from '../../../service/appointment.service';
import { SwiperConfigInterface } from 'ngx-swiper-wrapper';
import { BreakPointAccess } from '../../../service/breakpoint.service';
import { SpaPropertyInformation } from '../../../../core/services/spa-property-information.service';
import { CommonService } from '../../../service/common-service.service';
import { ViewMoreServiceService } from '../../../view-more/view-more-service.service';
import { takeUntil } from 'rxjs/operators';
import { PMSAction } from 'src/app/common/external-request/pms-request.model';
import { AppointmentdetailsService } from './appointmentdetails.service';
import { QuickLoginUtilities } from 'src/app/common/shared/shared/utilities/quick-login-utilities';
import { QuickLoginDialogResult } from 'src/app/common/shared/shared/quick-login/quick-login.component';
import { SelectedData } from 'src/app/shared/scheduling-assistant/scheduling-assistant.modal';
import { cloneDeep } from 'lodash';
import { CartUtilities } from 'src/app/common/components/menu/vcart/cart.utilities';

@Component({
    selector: 'app-appointmentdetails',
    templateUrl: './appointmentdetails.component.html',
    styleUrls: ['./appointmentdetails.component.scss'],
    encapsulation: ViewEncapsulation.None,
    providers: [SlideInformationService, AppointmentdetailsService]
})
export class AppointmentdetailsComponent implements OnInit, OnDestroy {

    ServiceGroups: any[] = [];
    serviceTime: any = [];
    selectedService: string;
    location: any = [];
    therapist: any = [];
    services: any = [];
    locationoverbook: boolean = false;
    allServices: any = [];
    resultArray: any[];
    date: string;
    serviceDetails: any = [];
    FormGrp: UntypedFormGroup;
    TimeArr: any = [];
    ServiceTypeArr: any = [];
    price: number = 0;
    duration: any = this.localization.captions.common.Mins;
    setupTime: any = this.localization.captions.common.Mins;
    breakDownTime: any = this.localization.captions.common.Mins;
    minDate: Date = this.PropertyInfo.CurrentDate;
    maxDate: Date;
    public selecteLinkName: any;
    public therapistArray: any = [];
    therapistCanBeAdded: boolean;
    otherdetailsgender: number = 0;
    maxTher: number;
    minTher: number = 1;
    minClient: number;
    enableIcon: boolean = false;
    IsTherapistSelectionValid: boolean;
    isPriceReadOnly: boolean = true;
    locationoccupiedtime: any;
    filters: AppointmentFilters = {
        Date: null,
        Time: "",
        LocationId: 0,
        ServiceGroupId: 0,
        ServiceId: 0,
        TherapistIdList: []
    }
    isEditFill: boolean = false;
    timePattern = { 0: { pattern: new RegExp('\[a-zA-Z\]') } };
    DisableValidation: boolean;
    IsReadOnly: boolean = false;
    isconvertTime: any;
    IsBookingLocked: boolean = false;
    tempRecordCreated: boolean = false;
    public config: SwiperConfigInterface = {
        spaceBetween: 10,
        direction: 'horizontal',
        slidesPerView: 1,
        keyboard: {
            enabled: true,
        },
        mousewheel: true,
        navigation: {
            nextEl: '.swiper-button-next',
            prevEl: '.swiper-button-prev',
        },
        slideToClickedSlide: true,
        loop: false,
        observer: true,
        width: 75
    };
    @ViewChild('TherapistList') TherapistList: MatSelect;
    @ViewChild('LocationList') LocationList: MatSelect;
    @ViewChild('ServiceList') ServiceList: MatSelect;
    @ViewChild('buttonView') btnView: ElementRef;
    placeholderFormat: string;
    commonCaptions: any;
    isEnableServicesError: boolean;
    isEnableTherapistError: boolean;
    isEnableLocationsError: boolean;
    @HostListener('window:resize', ['$event'])
    onResize(event) {

            this.setSelectedTimeSlot();

    }
    totalbtnWidth: any;
    totalScrollWidth: any;
    availableBtnSpace: any;
    appDurationIsReadOnly: boolean;
    appSetupTimeIsReadOnly: boolean;
    appBreakDownIsReadOnly: boolean;
    localisation: any = {};
    timelineData: { "id": number; "description": string; "timestampstart": number; "timestampend": number; }[];
    public showViewMore: boolean = true;
    GlobalLocation: any;
    GlobalTherapist: any;
    GlobalService: any;
    cancelBool: boolean = true;
    GlobalAllFilters: any;
    chosenTherapistList: any[] = [];
    isDefaultLoaded: boolean = false;
    helperText: any = 'Mins';
    captions: any = this.localization.captions.bookAppointment;
    public viewText: string = this.captions.ViewFullItenerary;
    allCaptions: any = this.localization.captions;
    //isTherapistAddedWhileLoad: boolean = false;
    noOfButtons: number;
    curtime: any;
    CurTimeIndex: any;
    genderEnum = global.GenderPreference;

    NoofItinerary: number = 2;
    selectedStaffDetails: any = [];
    therapistValueChangeEventEnabled: boolean = true;
    SelectedService: number = 0;
    private destroyed$: ReplaySubject<boolean> = new ReplaySubject(1);
    minDuration: number = 1;
    isOffsiteLocation = false;
    isTherapistDirty = false;
    isServiceDirty = false;
    poppedTherapistIndex = 0;
    afterViewinterval;
    showSchedulingAssistant = true;
    floatLabel:string;
    floatLabelNever:string;
    previousTherapistDetails: any;

    @Input("clientBlockInfos")
    set ClientBlockInformation(clientBlockInfo: any) {
        if (clientBlockInfo && clientBlockInfo.length > 0) {
            this.ClientBlockValidationTrigger();
        }
    }

    @Input("selectedTabindex")
    set tabFunv(value)
    {
      if(value==0)
      {
        this.setSelectedTimeSlot();
      }
    }
    inputSearch: string;
    initialServiceList = [];
    @ViewChild('myInput') myInput: ElementRef;
    showIdDescription : any;
    disableServiceForPackageAppointment = false;
    public get CanUpdateTemp() {
        return (this.apptService.TempHoldArr != null &&
            this.apptService.TempHoldArr.length > 0 &&
            this.apptService.TempHoldArr[0].appointmentId != 0) ||
            this.apptService.recreateTempAppointments;
    }

    constructor(public apptService: AppointmentpopupService, private appUtils: AppointmentUtilities, private http: HttpServiceCall, private Form: UntypedFormBuilder,
        public utils: SpaUtilities, private spaConfig: SPAConfig, public SlideService: SlideInformationService, private PropertyInfo: SpaPropertyInformation,
        private aptService: appointmentService, private cdr: ChangeDetectorRef,
        public localization: SpaLocalization, private breakpoint: BreakPointAccess, public _cs: CommonService, public _ViewMoreServiceService: ViewMoreServiceService,
        public _vcr: ViewContainerRef, private _quickLoginUtil: QuickLoginUtilities,
        private appointmentdetailsService: AppointmentdetailsService,
        private cartUtils: CartUtilities) {
        this.placeholderFormat = this.localization.inputDateFormat;
        this.commonCaptions = this.localization.captions.common;
        this.floatLabel = this.localization.setFloatLabel;
        this.floatLabelNever = this.localization.setFloatLabelNever;
        if (this.apptService.popupTitle == this.captions.NEWAPPOINTMENTOverbook) {
            this.apptService.isOverbook = true;
            this.apptService.popupTitle = this.captions.NEWAPPOINTMENT
        }
        this.apptService.cancelBool = true;
        this.localisation = spaConfig.captions.bookAppointment;
        document.getElementsByClassName('goldtheme')[0].classList.remove('slideInfoDisplayed');
        this.SlideService.slideInfo = false;
        this.selecteLinkName = "1";
       
        this.FormGrp = this.Form.group({
            dateField: ['', Validators.required],
            time: '',
            serviceType: '',
            service: ['', Validators.required],
            location: ['', Validators.required],
            therapist: ['', Validators.required],
            comments: [''],
            slipperSize: '',
            robeSize: '',
            clientLocation: '',
            Duration: ['', [Validators.required, Validators.min(this.minDuration)]],
            SetupTime: '',
            BreakdownTime: '',
            Price: 0,
            DoNotMove: false,
            RequestStaff: false,
            IsBookingLocked: false,
            isServiceCodeEnable: false,
            StaffDropDownFormArr: this.Form.array([this.createTherapistDropDown()]),
            linkcode: ''
        });
        this.apptService.appoinmentdetailArray = this.FormGrp;
        this.apptService.appoinmentDetailFormArray = this.FormGrp;
        this.showIdDescription = sessionStorage.getItem('showCodeforService') === "true" ? true : false;
        this.FormGrp.controls['isServiceCodeEnable'].setValue(this.showIdDescription)
        this.validateForm();
        this.apptService.appointmentBreakPoints = {
            AllowAppointmentComments: true,
            AllowAppointmentDuration: true,
            AllowAppointmentServicePrice: true,
            AllowBreakdownTime: true,
            AllowSetupTime: true,
            AllowCustomField1: true,
            AllowCustomField2: true,
            AllowCustomField3: true,
            AllowCustomField4: true,
            AllowCustomField5: true,
            AllowRemoveDoNotMoveFlag: true
        }
        this.apptService.IsAppointmentDisabled = false;
    }

    PriceControlOnBlur($event) {
        this.serviceDetails.price = this.localization.currencyToSQLFormat($event.target.value);
        this.apptService.updateMultiClientInfoPriceDetails();

        if (this.apptService.multiClientInfo.length > 0) {
            this.sercivePriceChange(this.apptService.multiClientInfo[0].id);
        }
    }

    public sercivePriceChange(clientId: number): void {
        let activeClient = this.apptService.multiClientInfo.find(client => client.id == clientId);
        let priceValue = this.apptService.isEditAppointment && activeClient ? this.apptService.resultExistingAppointment.appointmentDetail.price : this.apptService.resultNewAppointment.price;
        //setting dropdown selection
        this.apptService.clientInfopriceTypeModel = activeClient ? activeClient.priceTypeId : 0;
        //setting price value input
        this.apptService.selectedClientPriceValue = activeClient ? activeClient.price : priceValue;
    }

    async LoadLinkCodes(appDate: any) {
        let startTime = appDate ? this.utils.getDate(appDate) : null;
        let result: any;
        if (startTime != null) {
            result = await this.aptService.GetLinkCodesWithDate(startTime);
        } else {
            result = await this.aptService.GetLinkCodes();
        }
        if (!this.apptService.value_holder) this.apptService.value_holder = {}
        this.apptService.value_holder.linkcode = result;
    }

    //formarray
    createTherapistDropDown() {
        return this.Form.group({
            therapist: '',
        });
    }

    getTherapists(DDdata, Curpost) {
        let selectedTherapistId = this.FormGrp.value.StaffDropDownFormArr[Curpost].therapist;
        let listOfselectedTherapist = this.FormGrp.value.StaffDropDownFormArr;
        return DDdata.filter(x => {
            return (x.id == selectedTherapistId || !_.some(listOfselectedTherapist, { 'therapist': x.id.toString() }));
        });
    }
    StaffDropDownFormArr: any = [];
    addTherapistDropDown() {
        this.StaffDropDownFormArr = this.FormGrp.get('StaffDropDownFormArr') as UntypedFormArray;
        let _existingSelectionCount = this.StaffDropDownFormArr.controls.length;
        if (_existingSelectionCount < this.maxTher) {
            this.StaffDropDownFormArr.push(this.createTherapistDropDown());
        }
        if (!this.maxTher) {
            this.StaffDropDownFormArr.push(this.createTherapistDropDown());
        }
        if (this.IsReadOnly) {
            this.StaffDropDownFormArr.disable();
        }
    }
    removeTherapistDropDown(i) {
        this.poppedTherapistIndex = 0;
        this.StaffDropDownFormArr = this.FormGrp.get('StaffDropDownFormArr') as UntypedFormArray;
        let staffId: number = Number(this.StaffDropDownFormArr.value[1].therapist) ? this.StaffDropDownFormArr.value[1].therapist : 0;
        let changedTherapist = this.apptService.clientScreenProperties?.selectedStaff[i].id;
        this.removeChangedTherapistAddons(changedTherapist);
        this.StaffDropDownFormArr.removeAt(i);
        this.RemoveMappedTherapistWithMultiClient(staffId);
        if (this.apptService.isEditAppointment && staffId > 0) {
            this.StaffDropDownFormArr.markAsDirty();
            this.validateForm();
        }
    }
    removeTherapistValue(value: any) {

        this.StaffDropDownFormArr = this.FormGrp.get('StaffDropDownFormArr') as UntypedFormArray;
        let therpistResponse = this.StaffDropDownFormArr.value;
        if (therpistResponse && therpistResponse.length > 0) {
            therpistResponse.map(x => {
                if (Number(x.therapist) == Number(value)) {
                    x.therapist = "";
                }
            });
            this.FormGrp.get('StaffDropDownFormArr').setValue(therpistResponse);
            this.apptService.selectedIndex = 0;
        }
    }


    setTherapistDropDown(value) {
        this.StaffDropDownFormArr = this.FormGrp.get('StaffDropDownFormArr') as UntypedFormArray;
        this.isTherapistDirty = true;
        if (this.StaffDropDownFormArr.controls.length != 0) {
            this.StaffDropDownFormArr.at(0).setValue({ therapist: value });
        } else {
            this.addTherapistDropDown();
            this.StaffDropDownFormArr.at(0).setValue({ therapist: value });
        }
    }

    AddTherapistDropdownForUpdateLoad(i, value, addnewDropDownElement: any = true) {
        //this.isTherapistAddedWhileLoad = true;
        if (addnewDropDownElement) {
            this.addTherapistDropDown();
        }
        else {
            this.StaffDropDownFormArr = this.FormGrp.get('StaffDropDownFormArr') as UntypedFormArray;
            if (this.IsReadOnly) {
                this.StaffDropDownFormArr.disable();
            }
        }
        this.StaffDropDownFormArr.at(i).setValue({ therapist: value });
    }

    staffSelectionValueChanged() {
        this.apptService.therapistNotSelected = false;
        this.StaffDropDownFormArr = this.FormGrp.get('StaffDropDownFormArr') as UntypedFormArray;
        let staffArr: any[] = this.StaffDropDownFormArr.value;
        let selectedTherapistObj: any = [];
        for (const _selctd of staffArr) {
            for (let therapistItem of this.therapist) {
                const element = therapistItem;
                if (element.id == _selctd.therapist) {
                    selectedTherapistObj.push(element);
                }
            }
        }
        this.previousTherapistDetails = this.apptService.clientScreenProperties.selectedStaff ;
        this.apptService.clientScreenProperties.selectedStaff = selectedTherapistObj;
        this.apptService.setGenderPreferenceOnLoad();
        this.selectedStaffDetails.push(selectedTherapistObj[0]);
        this.validateForm();
    }

    appointmentServiceTimeClick() {
        if (this.appDurationIsReadOnly) {
            return;
        }
        this.DisableValidation = false;
        if (this.serviceDetails.duration == this.helperText) {
            this.serviceDetails.duration = '';
        } else if (this.serviceDetails.duration == '') {
            this.serviceDetails.duration = 0;
        }
    }

    appointmentSetupTimeClick() {
        if (this.appSetupTimeIsReadOnly) {
            return;
        }
        this.DisableValidation = false;
        if (this.serviceDetails.setupTime == this.helperText) {
            this.serviceDetails.setupTime = '';
        } else if (this.serviceDetails.setupTime == '') {
            this.serviceDetails.setupTime = 0;
        }
    }

    appointmentBreakDownClick() {
        if (this.appBreakDownIsReadOnly) {
            return;
        }
        this.DisableValidation = false;
        if (this.serviceDetails.breakDownTime == this.helperText) {
            this.serviceDetails.breakDownTime = '';
        } else if (this.serviceDetails.breakDownTime == '') {
            this.serviceDetails.breakDownTime = 0;
        }
    }

    durationOnBlur(controlName: string) {
        if (controlName == 'Duration') {
            this.appointmentServiceTimeClick();
        }
        else if (controlName == 'SetupTime') {
            this.appointmentSetupTimeClick();
        }
        else if (controlName == 'BreakdownTime') {
            this.appointmentBreakDownClick();
        }
        // Filter therapist drop down based on new duration's availability
        if (this.GlobalAllFilters) {
            this.filterTherapist(this.GlobalAllFilters, true);
        }
    }


    checkForAlreadySelected(): boolean {

        return false;
    }

    async LoadAppointmentConfiguration() {
        let appointmentConfig = await this.http.CallApiAsync<any>({
            host: global.Host.spaManagement,
            callDesc: "GetAppointmentConfiguration",
            uriParams: { module: global.Module.appointment },
            method: HttpMethod.Get,
            showError: false
        });
        return appointmentConfig;
    }
    ngOnDestroy() {
        this.aptService.isViewOnly = false;
        this.apptService.serviceChanged = false;
        this.aptService.tempRecordCreated = false;
        this.destroyed$.next(true);
        this.destroyed$.complete();
        this.apptService.priceTypes = [];
        this.apptService.selectedClientPriceValue = 0;
        this.apptService.clientInfoPriceType = [];
        this.apptService.activeSelectedClient = 0;
        this.apptService.selectedServicePriceTypes = [];
    }

    async ngOnInit() {
        // Return if add client mode
        if (this.apptService.add_client)
            return;
        this.apptService.getServiceDetailsJson();
        this.serviceDetails = this.apptService.resultNewAppointment;
        this.serviceDetails.CreateFormValid = false;
        this.getAllResources();
        this.OnFormChanges();

        if(this.apptService.isEditAppointment)
        {
            this.serviceDetails.time = this.apptService?.resultExistingAppointment?.appointmentDetail.startTime ? this.localization.LocalizeISOTime(this.utils.getTime(this.apptService?.resultExistingAppointment?.appointmentDetail.startTime, 24)) : this.serviceDetails.time;
        }


        const time = this.serviceDetails.time ? this.serviceDetails.time : this.curtime;
        const serviceTimeCheck = this.serviceTime.find(x => x == time);
        this.TimeArr[0] = serviceTimeCheck ? time : this.serviceTime[0];
        this.serviceDetails.duration = this.duration;
        this.serviceDetails.breakDownTime = this.breakDownTime;
        this.serviceDetails.setupTime = this.setupTime;
        if (this.apptService.isEditAppointment || this.apptService.isPreviousDayAppt) {
            this.breakpoint.CheckForAccess([global.SPAScheduleBreakPoint.EditAppointment], false)
            this.aptService.isViewOnly = this.breakpoint.IsViewOnly(global.SPAScheduleBreakPoint.EditAppointment);       
            if (this.aptService.isViewOnly || this.apptService.isPreviousDayAppt) {
                this.IsReadOnly = true;
                this.apptService.IsAppointmentDisabled = true;
                this.utils.disableControls(this.FormGrp);
                this.utils.disableControls(this.apptService.otherDetailsFormGroup);
                this.utils.disableControls(this.apptService.clientInformationForm.form);
                this.apptService.appointmentBreakPoints = {
                    AllowAppointmentComments: false,
                    AllowAppointmentDuration: false,
                    AllowAppointmentServicePrice: false,
                    AllowBreakdownTime: false,
                    AllowSetupTime: false,
                    AllowCustomField1: false,
                    AllowCustomField2: false,
                    AllowCustomField3: false,
                    AllowCustomField4: false,
                    AllowCustomField5: false,
                    AllowRemoveDoNotMoveFlag: false
                }
            

            } else {
                this.apptService.getEditAppointmentBreakPoints();

                this.apptService.appointmentBreakPoints.AllowAppointmentComments ? this.FormGrp.controls['comments'].enable() : this.FormGrp.controls['comments'].disable();
                this.apptService.appointmentBreakPoints.AllowAppointmentDuration ? this.FormGrp.controls['Duration'].enable() : this.FormGrp.controls['Duration'].disable();
                this.apptService.appointmentBreakPoints.AllowAppointmentServicePrice ? this.FormGrp.controls['Price'].enable() : this.FormGrp.controls['Price'].disable();
                this.apptService.appointmentBreakPoints.AllowBreakdownTime ? this.FormGrp.controls['BreakdownTime'].enable() : this.FormGrp.controls['BreakdownTime'].disable();
                this.apptService.appointmentBreakPoints.AllowSetupTime ? this.FormGrp.controls['SetupTime'].enable() : this.FormGrp.controls['SetupTime'].disable();

            }
        }
        else {
            let pmsData = this.apptService.getPMSDataForAction(PMSAction.Reservation);
            if (pmsData && pmsData.stayFromDate && pmsData.stayToDate) {
                this.minDate = this.utils.getDate(pmsData.stayFromDate);
                this.maxDate = this.utils.getDate(pmsData.stayToDate);
            }
        }
        this.apptService.hasAccessForClientPreference = !this.apptService.disablePreference ? this.breakpoint.CheckForAccess([global.SPAManagementBreakPoint.EditClientPreferences], false) : true;
    }

    loadAvailableData() {
        if (this.apptService.add_client)
            return;

        if (this.serviceDetails && Object.keys(this.serviceDetails).length > 0) {
            if (this.apptService.resultExistingAppointment) {
                this.LoadExistingAppointment(this.apptService.resultExistingAppointment);
            }
            else {
                this.setDefaultValues();
            }
            this.checkServiceGrpWidth();

                this.setSelectedTimeSlot();


        }
    }
    async getAllResources() {
        const [allServicesGroup, allServices, allTherapists, allLocations, allPriceTypes,allGuestTypes, allAppointmentConfig] = await Promise.all([
            this.LoadServiceGroup(),
            this.LoadServices(),
            this.LoadTherapists(),
            this.LoadLocations(),
            this.getAllpriceTypes(),
            this.GetAllGuestType(),
            this.LoadAppointmentConfiguration()
        ]);
        this.ServiceGroups = allServicesGroup.result ? <any>allServicesGroup.result : [];
        this.apptService.allPriceTypes = allPriceTypes.result ? <any>allPriceTypes.result : [];
        this.apptService.allGuestTypes = allGuestTypes.result ? <any>allGuestTypes.result : [];
        this.aptService.appointmentConfiguration = allAppointmentConfig.result ? allAppointmentConfig.result : [];
        this.updateServiceTime();
        this.allServices = allServices.result ? allServices.result : [];
        this.setServiceData(allServices);
        this.setTherapistData(allTherapists);
        this.setLocationData(allLocations);
        this.cdr.detectChanges();
        this.loadAvailableData();
    }

    setLocationData(result) {
        this.location = result.result ? result.result : [];
        this.aptService.managementData['UserAccessLocations'] = _.cloneDeep(this.location);
        this.isEnableLocationsError = true;
        this.GlobalLocation = _.cloneDeep(this.location);
        if (this.location.length == 0) {
            this.serviceDetails.location = "";
            this.serviceDetails.LocationId = "";
        }
        let locationDropDown = this.FormGrp.controls["location"];

        if (this.serviceDetails.LocationId) {
            locationDropDown.setValue(this.serviceDetails.LocationId.toString());
            this.serviceDetails.location = this.serviceDetails.LocationId;
        }
    }

    setTherapistData(result) {
        this.therapist = result.result ? result.result : [];
        this.aptService.managementData['ActiveTherapists'] = _.cloneDeep(this.therapist);
        this.isEnableTherapistError = true;
        this.GlobalTherapist = _.cloneDeep(this.therapist);
        this.apptService.therapistArray = this.GlobalTherapist;
        if (this.therapist.length == 0) {
            this.serviceDetails.therapist = "";
            this.serviceDetails.TherapistId = "";
        }
        let therapistDropDown = this.FormGrp.controls["therapist"];
        therapistDropDown.setValue(this.serviceDetails.TherapistId);
        this.serviceDetails.therapist = this.serviceDetails.TherapistId;
    }

    setServiceData(result) {
        this.services = result.result ? result.result : [];
        this.aptService.managementData['UserAccessServices'] = _.cloneDeep(this.services);
        this.isEnableServicesError = true;
        this.GlobalService = _.cloneDeep(this.services);
        this.apptService.aptPriceTypeGlobalService = this.GlobalService;
        this.apptService.serviceArray = this.GlobalService;
        // this.services.sort((a, b) => a.tenantName.localeCompare(b.tenantName))
        this.initialServiceList = this.services;
        if (this.services.length == 0) {
            this.serviceDetails.service = "";
            this.serviceDetails.ServiceId = "";
            this.setServiceGroupId();
        }
        if (this.serviceDetails.ServiceId) {
            this.serviceDetails.service = this.serviceDetails.ServiceId;
            if (this.apptService.resultExistingAppointment) {
                this.isEditFill = true;
            }
            this.FormGrp.controls["service"].setValue(this.serviceDetails.ServiceId.toString());
            this.SelectedService = Number(this.serviceDetails.ServiceId);
            this.setServiceGroupId();
        }
    }

    updateServiceTime() {
        const tempTime = this.apptService.resultNewAppointment ? this.apptService.resultNewAppointment.time : '';
        let time = this.apptService.resultExistingAppointment ?
            this.utils.formatAMPM(this.utils.getDate(this.apptService.resultExistingAppointment.appointmentDetail.startTime), false, false)
            : tempTime;

        const appField = this.apptService.resultNewAppointment ? this.apptService.resultNewAppointment.dateField : '';
        let appointmentDateField = this.apptService.resultExistingAppointment ?
            this.utils.getDate(this.apptService.resultExistingAppointment.appointmentDetail.startTime)
            : appField;

        let gridInterval: number = this.aptService.appointmentConfiguration.length != 0 ? this.aptService.appointmentConfiguration[0].APPOINMENT_GRIDTIMEINTERVAL : 15; //setting grid interval timings from config. if not, it will take 15 mins by default.
        let startTime = this.aptService.appointmentConfiguration.length != 0 ? this.localization.ToDate(this.utils.getDayTimeForTheDate(JSON.parse(this.aptService.appointmentConfiguration[0].APPOINTMENT_OPERATING_HOURS), appField ? appField : appointmentDateField)['StartTime'], "HH:mm") : this.localization.ToDate("00:00", "HH:mm");
        let endTime = this.aptService.appointmentConfiguration.length != 0 ? this.localization.ToDate(this.utils.getDayTimeForTheDate(JSON.parse(this.aptService.appointmentConfiguration[0].APPOINTMENT_OPERATING_HOURS), appField ? appField : appointmentDateField)['EndTime'], "HH:mm") : this.localization.ToDate("23:59", "HH:mm");
        let propertyStartTime = this.aptService.appointmentConfiguration.length != 0 ? this.localization.ToDate(this.utils.getDayTimeForTheDate(JSON.parse(this.aptService.appointmentConfiguration[0].APPOINTMENT_OPERATING_HOURS), appField ? appField : appointmentDateField)['StartTime'], "HH:mm") : this.localization.ToDate("00:00", "HH:mm");
        let propertyCurDateTime = this.utils.getPropertyTime();
        let currentTimeinMins = Number(moment(propertyCurDateTime).format("mm")) % gridInterval
        let RemainingMins = currentTimeinMins > 0 ? gridInterval - (currentTimeinMins) : currentTimeinMins;
        this.curtime = this.formatTime(moment(propertyCurDateTime).add(RemainingMins, 'minutes').format("hh:mm a"));

        if (time && appointmentDateField) {
            let sTime = this.localization.combineDateAndTime(appointmentDateField, startTime);
            let eTime = this.localization.combineDateAndTime(appointmentDateField, endTime);
            this.serviceTime = this.localization.generateTimeRange(sTime, eTime, gridInterval);
            this.CurTimeIndex = this.serviceTime.findIndex(res => res == time);
        } else {
            this.serviceTime = this.localization.generateTimeRange(propertyStartTime, endTime, gridInterval);
        }
        if (!this.serviceDetails.time) {
            this.serviceDetails.time = this.curtime;
        }
    }

    LoadExistingAppointment(existingAppointment: any) {
        let appointmentDetail = existingAppointment.appointmentDetail;
        let startTime = this.utils.getDate(appointmentDetail.startTime);
        this.FormGrp.controls["dateField"].setValue(startTime);
        let curtime = "";
        if (appointmentDetail.startTime) {
            const serviceTimeCheck = this.serviceTime.find(x => x == this.localization.LocalizeTime(startTime));
            this.TimeArr = !serviceTimeCheck ? this.serviceTime[0] : [this.localization.LocalizeTime(startTime)];
            curtime = _.cloneDeep(this.TimeArr);
            this.filters.Time = _.cloneDeep(this.TimeArr)
        } else {
            curtime = moment().startOf('hours').format(this.localization.timeFormat);
        }

        this.FormGrp.controls["time"].setValue(curtime);

        this.serviceDetails.doNotMove = appointmentDetail.doNotMove;
        if (this.serviceDetails.doNotMove) {
            this.apptService.appointmentBreakPoints.AllowRemoveDoNotMoveFlag ? this.FormGrp.controls['DoNotMove'].enable() : this.FormGrp.controls['DoNotMove'].disable();
        }
        this.serviceDetails.requestStaff = appointmentDetail.requestStaff;
        this.serviceDetails.isBookingLocked = appointmentDetail.isBookingLocked;

        this.FormGrp.controls.DoNotMove.setValue(this.serviceDetails.doNotMove);
        this.FormGrp.controls.RequestStaff.setValue(this.serviceDetails.requestStaff);
        this.FormGrp.controls.IsBookingLocked.setValue(this.serviceDetails.isBookingLocked);
        this.disableServiceForPackageAppointment = this.setDisableServiceForPackageAppointment(); 
        this.serviceDetails.comments = appointmentDetail.comments;
        this.apptService.otherdetails.linkcode = appointmentDetail.linkCodeId;
        this.otherdetailsgender = appointmentDetail.genderPreference;
        this.LoadLinkCodes(appointmentDetail.startTime);

        // Check appointment has been booked with offset location - if yes set location as non-required field
        let allService = this.aptService.managementData['Service'];
        let therapist = this.aptService.managementData['ActiveTherapists'];

        if (allService && allService.length > 0) {
            let aptService = allService.find(r => r.id == appointmentDetail.serviceId);
            this.isOffsiteLocation = aptService && aptService.isOffsite;
            if (this.isOffsiteLocation) {
                this.FormGrp.controls['location'].setValidators(null);
                this.FormGrp.controls['location'].setErrors(null);
            }
        }

        if(appointmentDetail.isBookingLocked){
            this.FormGrp.controls['dateField'].disable();
            this.IsBookingLocked = true;
        }
    }

    setDisableServiceForPackageAppointment() {
        const disableServiceForPackageAppointmentSwitch = this.aptService.appointmentConfiguration[0]?.DISABLE_SERVICE_IN_PACKAGE_APPOINTMENT;
        if (!disableServiceForPackageAppointmentSwitch)
            return false;

        let isPackageAppointment = this.apptService.resultExistingAppointment.appointmentDetail.packageId > 0;
        return isPackageAppointment;
    }

    formatTime(drivensttime) {
        return this.localization.LocalizeTime(moment(drivensttime, "h:mm:ss a").toDate());
    }

    selectedBtn: any; selectedBtn1: any;

    checkServiceGrpWidth() {
        let servicegrpdiv = document.getElementsByClassName('servicegroup-width');
        if (servicegrpdiv.length > 0) {
            let servicegrpWidth = servicegrpdiv[0]['offsetWidth'];
            let buttonWidth = 100;
            this.noOfButtons = Math.floor(servicegrpWidth / buttonWidth) - 1;
        }

    }

    setDefaultValues() {
        console.log("this.serviceDetails.dateField", this.serviceDetails.dateField);
        if (this.serviceDetails.dateField) {
            this.filters.Date = this.utils.getDate(this.serviceDetails.dateField);
            this.FormGrp.controls["dateField"].setValue(this.filters.Date);
        }

        let curtime = "";
        if (this.serviceDetails.time && this.serviceDetails.time != "") {
            const serviceTimeCheck = this.serviceTime.find(x => x == this.serviceDetails.time);
            if (!serviceTimeCheck) {
                this.serviceDetails.time = this.serviceTime[0];
            }
            this.TimeArr = [this.serviceDetails.time];
            curtime = _.cloneDeep(this.serviceDetails.time);
            this.filters.Time = this.serviceDetails.time;
            this.CurTimeIndex = this.serviceTime.findIndex(res => res == this.serviceDetails.time);
        } else {
            const serviceTimeCheck = this.serviceTime.find(x => x == this.curtime);
            if (!serviceTimeCheck) {
                this.curtime = this.serviceTime[0];
            }
            curtime = this.curtime;
            this.CurTimeIndex = this.serviceTime.findIndex(res => res == curtime);
        }

        this.FormGrp.controls["time"].setValue(curtime);
        this.TimeArr[0] = curtime;
        // set default therapist
        if (this.serviceDetails.TherapistId && this.serviceDetails.TherapistId > 0) {
            this.filters.TherapistIdList = [this.serviceDetails.TherapistId]
            this.therapist = [{ firstName: this.serviceDetails.firstName, lastName: this.serviceDetails.lastName, id: this.serviceDetails.TherapistId, gender: this.serviceDetails.gender }]
            let setVal: any = this.serviceDetails.TherapistId;
            this.serviceDetails.therapist = setVal;
            this.setTherapistDropDown(setVal.toString());
        }
        // Set default location
        if (this.serviceDetails.LocationId && this.serviceDetails.LocationId > 0) {
            this.filters.LocationId = this.serviceDetails.LocationId
            this.location = [{ description: this.serviceDetails.description, id: this.serviceDetails.LocationId }]
            let setVal: any = this.serviceDetails.LocationId;
            this.FormGrp.controls["location"].setValue(setVal.toString());
            this.serviceDetails.service = setVal;
            if (!this.serviceDetails.ServiceGroupId)
                this.setServiceGroupId();
        }
        // Set default Service groups
        if (this.serviceDetails.ServiceGroupId && this.serviceDetails.ServiceGroupId.length > 0) {
            if (this.serviceDetails.ServiceGroupId.includes(0)) {
                this.serviceDetails.ServiceGroupId.splice(this.serviceDetails.ServiceGroupId.indexOf(0), 1);
            }
            this.ServiceTypeArr = this.serviceDetails.ServiceGroupId;
            this.filters.ServiceGroupId = this.ServiceTypeArr;
        }
        // set default service
        if (this.serviceDetails.ServiceId && this.serviceDetails.ServiceId > 0) {
            this.LoadServices(this.allServices);
        }
    }

    getActualPriceTypeIDForClients() {
        this.apptService.multiClientInfo.forEach(x => {
            let actualClientInfo = this.apptService.recordsArray.find(y => y.id == x.id);
            if (actualClientInfo) {
                let selectedpriceTypeCode = this.apptService.selectedServicePriceTypes.find(y => y.priceTypeId == actualClientInfo.clientDetail.priceTypeId);
                x.priceTypeId = selectedpriceTypeCode ? selectedpriceTypeCode.priceTypeId : 0
            }
        });
    }

    OnFormChanges() {
        this.FormGrp.get('dateField').valueChanges.pipe(takeUntil(this.destroyed$)).subscribe(res => {
            this.showSchedulingAssistant = false;
            this.OnElementValueChange({ value: res }, "dateField");
            this.filterAllFields();
            this.GetClientItinerary();
            this.GetConflictingActivities();
            this.refreshPMSStayDetail();
        });
        this.FormGrp.get('service').valueChanges.pipe(takeUntil(this.destroyed$)).subscribe(async res => {
            this.apptService.makeAddOnsCall = true;
            this.setServiceTime(res);
            if (this.GlobalAllFilters) {
                this.filterDropDown(this.GlobalAllFilters);
            }
            let service = this.clientCountValidation(res);
            this.GetConflictingActivities();

            this.AdjustTherapistCountBasedOnServiceChange(service);

        });
        this.FormGrp.get('location').valueChanges.pipe(takeUntil(this.destroyed$)).subscribe(res => {
            if (this.GlobalAllFilters) {
                this.filterDropDown(this.GlobalAllFilters);
            }
            this.setMaxGuestLocation();

        });
        this.FormGrp.get('therapist').valueChanges.pipe(takeUntil(this.destroyed$)).subscribe(res => {
            if (this.GlobalAllFilters)
                this.filterDropDown(this.GlobalAllFilters);

        });

        this.FormGrp.get('StaffDropDownFormArr').valueChanges.pipe(takeUntil(this.destroyed$)).subscribe(res => {
            this.apptService.makeAddOnsCall = true;
            this.staffSelectionValueChanged();
            if (this.GlobalAllFilters)
                this.filterDropDown(this.GlobalAllFilters);

            if (!this.therapistValueChangeEventEnabled) {
                return;
            }
            if (this.apptService.TempHoldArr != null && this.apptService.TempHoldArr.length > 0 && this.apptService.TempHoldArr[0].appointmentId != 0 )//&& !this.isTherapistAddedWhileLoad 
            {
                if ((this.apptService.TempHoldArr[0].therapist.length !== this.FormGrp.controls["StaffDropDownFormArr"].value.length ||
                    !this.ElementsSame()) || this.apptService.recreateTempAppointments) {
                    this.UpdateTempHold();

                    // let update: boolean = true;
                    // for (let staffItem of this.FormGrp.controls['StaffDropDownFormArr'].value) {
                    //     const element = staffItem;
                    //     if (element.therapist == "") {
                    //         update = false;
                    //     }
                    // }
                    // if (update) {
                    //     this.UpdateTempHold();
                    // }

                }

            }
            //this.isTherapistAddedWhileLoad = false;
        });


    }

    ElementsSame(): boolean {
        let elementsSame = true;
        this.apptService.TempHoldArr[0].therapist.forEach(x => {
            if (this.FormGrp.controls['StaffDropDownFormArr'].value.map(k => k.therapist).indexOf(x.toString()) == -1) {
                elementsSame = false;
                return elementsSame;
            }
        })
        return elementsSame

    }

    updateActualPriceAndPriceTypeForClient(serviceId: number) {
        if (this.apptService.multiClientInfo.length > 0 && this.SelectedService && this.apptService.recordsArray.length > 0) {
            this.getActualPriceTypeIDForClients();
            this.apptService.updateMultiClientInfoPriceDetails();
            let clientId = (this.apptService.multiClientInfo && this.apptService.multiClientInfo.length > 0) ? this.apptService.multiClientInfo[0].id : 0;
            this.apptService.setPriceTypeUIforMutliClient(clientId);
        }
    }

    setServiceTime(serviceId) {
        let service = _.cloneDeep(this.allServices).filter(val => { return val.id == Number(serviceId) });
        this.isPriceReadOnly = this.apptService.isPriceTypeApplicable();

        if (service && service.length > 0) {
            let filteredService;

            if (!this.isEditFill) {
                filteredService = this.GlobalAllFilters ? this.GlobalAllFilters.serviceViewModels.find(r => r.id == serviceId) : null;
                this.duration = service[0].duration;
                this.serviceDetails.duration = service[0].duration;
                this.setupTime = service[0].setupTime;
                this.serviceDetails.setupTime = service[0].setupTime;
                this.serviceDetails.servicePoints = service[0].points;
                this.breakDownTime = service[0].breakDownTime;
                this.serviceDetails.breakDownTime = service[0].breakDownTime;
                this.apptService.selectedServicePriceTypes = service[0].servicePriceType;
                this.apptService.setPriceTypeCodeUI();
                if (this.apptService.isEditAppointment && this.isUpdatedWithSameService()) {
                    let yieldPrice = this.apptService.resultExistingAppointment.appointmentDetail.yieldPrice;
                    this.serviceDetails.price = yieldPrice ? yieldPrice : this.apptService.resultExistingAppointment.appointmentDetail.price;
                    this.apptService.resultNewAppointment.price = this.serviceDetails.price;
                    service[0].price = this.serviceDetails.price;
                }
                else {
                    service[0].price = filteredService ? filteredService.price : service[0].price;
                    this.serviceDetails.price = service[0].price;
                    this.apptService.resultNewAppointment.price = service[0].price;
                }

                this.updateActualPriceAndPriceTypeForClient(serviceId);
                this.calculateAndSetValidPriceForClient(service[0].price);
                this.UpdateMultiClientInfoPriceForSelectedService();
                let clientId = (this.apptService.multiClientInfo && this.apptService.multiClientInfo.length > 0) ? this.apptService.multiClientInfo[0].id : 0;

                if (this.apptService.isPriceTypeApplicable()) {
                    this.apptService.resultNewAppointment.service = serviceId;
                    let hasOriginalServiceAndDate: boolean = this.isUpdatedWithSameService();
                    this.clientServicePriceCalc(hasOriginalServiceAndDate);
                }
                this.apptService.setPriceTypeUIforMutliClient(clientId);
            }
            else {
                this.isEditFill = false;
                let existingAppointment = this.apptService.resultExistingAppointment.appointmentDetail;
                this.serviceDetails.duration = existingAppointment.duration;
                this.setupTime =existingAppointment.setUpTime;
                this.serviceDetails.setupTime = existingAppointment.setUpTime;
                this.breakDownTime = existingAppointment.breakDownTime;
                this.serviceDetails.breakDownTime = existingAppointment.breakDownTime;
                
                this.isPriceReadOnly = this.apptService.isPriceTypeApplicable();
            }

            if (this.apptService.isPriceTypeApplicable()) {
                this.isPriceReadOnly = this.apptService.isPriceTypeApplicable();
                if (this.apptService.isEditAppointment && this.isUpdatedWithSameService()) {
                    let actualYieldPrice = this.apptService.resultExistingAppointment.appointmentDetail.yieldPrice;
                    this.serviceDetails.price = actualYieldPrice ? actualYieldPrice : this.apptService.resultExistingAppointment.appointmentDetail.price;
                }
                else {
                    filteredService = this.GlobalAllFilters ? this.GlobalAllFilters.serviceViewModels.find(r => r.id == serviceId) : null;
                    this.serviceDetails.price = filteredService ? filteredService.price : service[0].price;
                }
            }

            this.apptService.otherdetails.comments = service[0].serviceComment;
            this.apptService.otherdetails.policy = service[0].servicePolicy;
        }
        else {
            this.isPriceReadOnly = this.apptService.isPriceTypeApplicable();
            this.price = 0;
            this.duration = this.allCaptions.common.Mins;
            this.serviceDetails.duration = this.allCaptions.common.Mins;
            this.setupTime = this.allCaptions.common.Mins;
            this.serviceDetails.setupTime = this.allCaptions.common.Mins;
            this.breakDownTime = this.allCaptions.common.Mins;
            this.serviceDetails.breakDownTime = this.allCaptions.common.Mins;
            this.serviceDetails.price = "";
        }
        this.setDefaultClientInfo();
    }

    async clientServicePriceCalc(hasOriginalServiceAndDate = false) {
        if (this.apptService.multiClientInfo.length > 0) {
            await this.apptService.clientServicePriceTypeCalc(this.apptService.multiClientInfo[0], hasOriginalServiceAndDate);
        }
    }

    setDefaultClientInfo() {
        if (this.apptService.multiClientInfo.length > 0) {
            let ClientInfo = this.apptService.recordsArray.find(y => y.id == this.apptService.multiClientInfo[0].id);
            this.apptService.activeBorder(0, ClientInfo);
        }
    }

    private UpdateMultiClientInfoPriceForSelectedService() {
        this.apptService.multiClientInfo.forEach(element => {
            if (this.apptService.selectedServicePriceTypes.length > 0) {
                let selectedpriceTypeCode = this.apptService.selectedServicePriceTypes.find(y => y.priceTypeId == element.priceTypeId);
                if (selectedpriceTypeCode && element.priceTypeId != 0) {
                    element.price = selectedpriceTypeCode.price;
                } else {
                    element.price = this.apptService.resultNewAppointment.price;
                }
            }
        });
    }

    private calculateAndSetValidPriceForClient(price): void {

        for (let apptServiceItem of this.apptService.multiClientInfo) {
            if (this.IsServicePriceOverrideable(apptServiceItem)) {
                apptServiceItem.price = price;
            }
        }
    }

    //Overrides client price value for the currently selected service if client doesnt associated with valid price type.
    private IsServicePriceOverrideable(clientWithServiceType): boolean {
        let activeServicePriceTypesIds: number[] = this.apptService.selectedServicePriceTypes.map(pt => pt.priceTypeId);
        return this.apptService.selectedServicePriceTypes.length == 0
            || !activeServicePriceTypesIds.includes(clientWithServiceType.priceTypeId)
    }

    private getPriceTypeFromMulticlientInfo(): number {
        let priceTypeNumber: number = 0;
        if (this.apptService.multiClientInfo.length > 0) {
            let clientId = this.apptService.multiClientInfo[0].id;
            priceTypeNumber = this.apptService.multiClientInfo.find(client => client.id == clientId).priceTypeId;
        }
        return priceTypeNumber;
    }

    private getPriceFromMulticlientInfo(): number {
        let price: number = 0;
        if (this.apptService.multiClientInfo.length > 0) {
            let clientId = (this.apptService.activeSelectedClient && this.apptService.activeSelectedClient != 0) ? this.apptService.activeSelectedClient : this.apptService.multiClientInfo[0].id;
            price = this.apptService.multiClientInfo.find(client => client.id == clientId).price;
        }
        return price;
    }


    therapistHelperText: string;


    SetServicePrice(price: any, serviceId: any) {
        let date: Date = this.FormGrp.get("dateField").value;
        let formattedDate = this.localization.convertDateObjToAPIdate(date);

        this.http.CallApiWithCallback<any>({
            host: global.Host.schedule,
            success: this.successCallback.bind(this),
            error: this.utils.errorCallback.bind(this),
            callDesc: "GetServicePriceBasedOnYield",
            method: HttpMethod.Get,
            uriParams: { appointmentDate: formattedDate, serviceId: serviceId, originalPrice: Number(price) },
            showError: true,
            extraParams: [serviceId]
        });

    }

    AdjustTherapistCountBasedOnServiceChange(service: any) {
        let maxtherapistReq: number;
        if (service && service.length > 0) {
            maxtherapistReq = service[0].maximumStaff;

            let selectedTherapist = this.FormGrp.get('StaffDropDownFormArr') as UntypedFormArray;
            if (selectedTherapist && selectedTherapist.length > 0) {
                let therapistToRemove: number = selectedTherapist.length - maxtherapistReq;
                for (let i = 1; i <= therapistToRemove; i++) {
                    this.removeTherapistDropDown(selectedTherapist.length - i);
                }
            }
        }
    }


    clientCountValidation(serviceId): any {
        let clientValMsg = '';
        let minGuestReq;
        let maxGuestReq;
        let minTherapistReq;
        let maxtherapistReq;
        let maxGuestLocation = 0;
        let service: any;
        //set all the properties needed for validation and other helper text in client tab while booking an appointment.
        let clientScreenProp: ClientScreenProperties;
        if (this.GlobalAllFilters) {
            service = this.GlobalAllFilters["serviceViewModels"].filter(s => { return s.id == serviceId });
        }
        else {
            service = _.cloneDeep(this.services).filter(val => { return val.id == Number(serviceId) });
        }
        if (service.length > 0) {
            minGuestReq = service[0].minimumGuest;
            maxGuestReq = service[0].maximumGuest;

            if (this.location && this.hasValue(this.serviceDetails.location)) {
                const currentLocation = this.location.find(x => x.id === Number(this.serviceDetails.location));
                if (currentLocation && currentLocation.maxGuests > 0 && currentLocation.maxGuests < maxGuestReq) {
                    maxGuestLocation = currentLocation.maxGuests;
                }
            }

            minTherapistReq = service[0].minimumStaff;
            maxtherapistReq = service[0].maximumStaff;
            this.maxTher = service[0].maximumStaff;
            this.minTher = service[0].minimumStaff;
            this.minClient = service[0].minimumGuest;
            clientValMsg = this.captions.Min + " " + minGuestReq + ", " + this.captions.Max + " " + maxGuestReq + " " + this.captions.tobeselected;
            this.therapistHelperText = "(" + this.captions.Min + " " + minTherapistReq + ", " + this.captions.Max + " " + maxtherapistReq + " " + this.captions.tobeselected + ")";
            clientScreenProp = {
                helperText: clientValMsg,
                minGuest: minGuestReq,
                maxGuest: maxGuestReq,
                maxGuestLocation: maxGuestLocation
            }
            if (maxtherapistReq == 1) {
                this.therapistCanBeAdded = false;
            }
            else {
                this.therapistCanBeAdded = true;
            }
            if (this.apptService.clientScreenProperties.selectedStaff) {
                clientScreenProp.selectedStaff = this.apptService.clientScreenProperties.selectedStaff;
            }
            this.apptService.clientScreenProperties = clientScreenProp;
        }
        return service;
    }

    refreshTherapistSelectionDropDown() {

        if (!this.FormGrp.touched) {
            return;
        }
        this.resetTherapistDropDown();
        this.addTherapistDropDown();
    }

    resetTherapistDropDown() {
        let _control: any = this.FormGrp.controls.StaffDropDownFormArr;
        _control["controls"] = [];
    }


    OnElementValueChange($event, type) {
        switch (type) {
            case "dateField":
                this.serviceDetails.dateField = $event.value;
                this.apptService.guaranteeMethodConfig.bookingStartDate = $event.value;
                this.apptService.guaranteeMethodConfig.bookingEndDate = $event.value;
                this.updateServiceTime();
                this.LoadLinkCodes(this.serviceDetails.dateField);
                break;
            case "service":
                this.isServiceDirty = true;
                this.ClientBlockvalidateValidation($event.value, 'service');
                this.serviceDetails.service = $event.value;
                this.setServiceGroupId();
                if (this.serviceDetails && this.serviceDetails.service != "" && this.serviceDetails.service != 0) {
                    if (this.apptService.recordsArray != null && this.apptService.recordsArray.length > 0) {
                        let clientIdsList = this.apptService.recordsArray != null && this.apptService.recordsArray.length > 0 ?
                            _.uniq(this.apptService.recordsArray.map(({ id }) => id)) : null;
                        this.appUtils.CheckMedicalWarning(this.serviceDetails.service, clientIdsList,
                            this.successCallback.bind(this), this.utils.errorCallback.bind(this));
                    }
                    else {
                        this.getMedicalCondition();
                    }
                }
                break;
            case "location":
                this.serviceDetails.location = $event.value;
                if (this.hasValue(this.serviceDetails.service)) {
                    this.clientCountValidation(this.serviceDetails.service);
                }
                break;
            case "therapist":
                this.isTherapistDirty = true;
                this.ClientBlockvalidateValidation($event.value, 'therapist');             
                this.serviceDetails.therapist = $event.value;
                break;
            default:
                break;
        }

    }

    ClientBlockValidationTrigger() {
        if (this.isServiceDirty) {
            this.ClientBlockvalidateValidation(this.FormGrp.controls["service"].value, "service");
        }
        if (this.isTherapistDirty) {
            const temptherapistFalse = this.FormGrp.controls["therapist"].value ? [this.FormGrp.controls["therapist"].value] : [];
            const therapists = this.FormGrp.value.StaffDropDownFormArr && this.FormGrp.value.StaffDropDownFormArr.length
                ? this.FormGrp.value.StaffDropDownFormArr.map(x => x.therapist) : temptherapistFalse;

            therapists.map((x, i) => {
                this.ClientBlockvalidateValidation(x, "therapist", i);
            });
        }
    }

    ClientBlockvalidateValidation(value: any, type: string, index?: number) {
        if (!value)
            return;


        if (type === 'service' && this.apptService.clientBlockInfos && this.apptService.clientBlockInfos.find(x => !x.blockClient)) {

            const blockedService = this.apptService.clientBlockInfos.find(x =>
                !x.blockClient
                && x.serviceBlock
                && x.serviceBlock.find(y => y === Number(value))
                && (!x.overidedServices || !x.overidedServices.find(y => y === Number(value))));

            if (blockedService) {
                const serviceInfo = this.apptService.serviceArray.find(x => value == x.id);

                const message = this.captions.ClientBlockedForService.interpolate({ service: serviceInfo.description });

                this.utils.showAlert(message, global.AlertType.Warning,
                    this.apptService.clientBlockOverrideAccess ? global.ButtonType.Ok : global.ButtonType.OKOverride, async (res) => {
                        if (this.apptService.clientBlockOverrideAccess) {
                            this.apptService.clientBlockInfos.map(x => {
                                x.overidedServices = x.overidedServices ? x.overidedServices.concat([Number(value)]) : [Number(value)];
                            });
                        }
                        else {
                            if (res === global.AlertAction.CONTINUE) {
                                this.FormGrp.controls["service"].setValue("");
                                this.apptService.selectedIndex = 0;
                            }
                            else {
                                const quickLoginDialogRef = this._quickLoginUtil.QuickLogin({ breakPointNumber: global.SPAScheduleBreakPoint.overrideClientBlock });
                                quickLoginDialogRef.afterClosed().pipe(takeUntil(this.destroyed$)).subscribe(async (quickLoginDialogResult: QuickLoginDialogResult) => {

                                    if (quickLoginDialogResult.isLoggedIn) {
                                        this.apptService.clientBlockInfos.map(x => {
                                            x.overidedServices = x.overidedServices ? x.overidedServices.concat([Number(value)]) : [Number(value)];

                                        });
                                    }
                                    else {
                                        this.FormGrp.controls["service"].setValue("");
                                        this.apptService.selectedIndex = 0;
                                    }

                                });
                            }

                        }
                    });
            }
        }

        else if (type === 'therapist' && this.apptService.clientBlockInfos && this.apptService.clientBlockInfos.find(x => !x.blockClient)) {

            const blockedTherapist = this.apptService.clientBlockInfos.find(x =>
                !x.blockClient
                && x.therapistBlock
                && x.therapistBlock.find(y => y === Number(value))
                && (!x.overidedTherapists || !x.overidedTherapists.find(y => y === Number(value))));

            if (blockedTherapist) {
                const therapistInfo = this.apptService.therapistArray.find(x => value == x.id);

                const message = this.captions.ClientBlockedForTherapist.interpolate({ therapist: therapistInfo.firstName + " " + therapistInfo.lastName });
                this.poppedTherapistIndex = value;
                this.utils.showAlert(message, global.AlertType.Warning,
                    this.apptService.clientBlockOverrideAccess ? global.ButtonType.Ok : global.ButtonType.OKOverride, async (res) => {
                        if (this.apptService.clientBlockOverrideAccess) {
                            this.apptService.clientBlockInfos.map(x => {
                                x.overidedTherapists = x.overidedTherapists ? x.overidedTherapists.concat([Number(value)]) : [Number(value)];
                            });
                            this.poppedTherapistIndex = 0;
                        }
                        else {
                            if (res === global.AlertAction.CONTINUE) {
                                this.removeTherapistValue(this.poppedTherapistIndex);
                            }
                            else {
                                const quickLoginDialogRef = this._quickLoginUtil.QuickLogin({ breakPointNumber: global.SPAScheduleBreakPoint.overrideClientBlock });
                                quickLoginDialogRef.afterClosed().pipe(takeUntil(this.destroyed$)).subscribe(async (quickLoginDialogResult: QuickLoginDialogResult) => {

                                    if (quickLoginDialogResult.isLoggedIn) {
                                        this.apptService.clientBlockInfos.map(x => {
                                            x.overidedTherapists = x.overidedTherapists ? x.overidedTherapists.concat([Number(value)]) : [Number(value)];
                                        });
                                        this.poppedTherapistIndex = 0;
                                    }

                                    else {
                                        this.removeTherapistValue(this.poppedTherapistIndex);
                                    }


                                });
                            }

                        }
                    });
            }
        }


    }

    setServiceGroupId() {
        let ServGrp = this.allServices.filter(res => { return res.id == this.serviceDetails.service });
        this.serviceDetails.ServiceGroupId = ServGrp && ServGrp.length > 0 ? ServGrp[0].serviceGroupId : 0;
    }


    getMedicalCondition() {
        this.http.CallApiWithCallback<any>({
            host: global.Host.spaManagement,
            success: this.successCallback.bind(this),
            error: this.utils.errorCallback.bind(this),
            callDesc: "GetMedicalCondition",
            method: HttpMethod.Get,
            showError: true,
            uriParams: { id: this.serviceDetails.service },
            extraParams: []
        });
    }

    setServiceDetails(val: string) {
        this.apptService.resultNewAppointment.serviceType = val;
        for (let holderItem of this.apptService.value_holder.service) {
            if (holderItem.serviceName == val) {
                this.therapist = holderItem.therapists;
            }
        }
        this.validateForm();


    }

    async LoadServices(ismanual: boolean = false) {
        let services = await this.http.CallApiAsync<any>({
            host: global.Host.spaManagement,
            callDesc: "GetAllSpaServiceByUserId",
            method: HttpMethod.Get,
            showError: true
        });
        return services;
    }



    async LoadTherapists(ismanual: boolean = false) {
        let therapists = await this.http.CallApiAsync<any>({
            host: global.Host.spaManagement,
            callDesc: "getActiveTherapist",
            method: HttpMethod.Get,
            showError: true
        });
        return therapists;
    }

    async getAllpriceTypes() {
        let allPriceTypes = await this.http.CallApiAsync<any>({
            host: global.Host.spaManagement,
            callDesc: "GetAllPriceTypes",
            uriParams: {},
            method: HttpMethod.Get,
            showError: true
        });
        return allPriceTypes;
    }

    async GetAllGuestType() {
        let allGuestTypes = await this.http.CallApiAsync<any>({
            host: global.Host.spaManagement,
            callDesc: "GetAllGuestType",
            uriParams: { "isIncludeInactive": false },
            method: HttpMethod.Get,
            showError: true
        });
        return allGuestTypes;
    }


    async LoadLocations(ismanual: boolean = false) {
        let locations = await this.http.CallApiAsync<any>({
            host: global.Host.spaManagement,
            callDesc: "GetAllLocByUserId",
            method: HttpMethod.Get,
            showError: true
        });
        return locations;
    }

    async LoadServiceGroup(ismanual: boolean = false) {
        let services = await this.http.CallApiAsync<any>({
            host: global.Host.spaManagement,
            callDesc: "GetAllServiceGrpByUserId",
            method: HttpMethod.Get,
            showError: true
        });
        return services;
    }
    emitedValue(event) {
        this.toggleButtonClick(event[1], event[1], event[2].id);
    }
    toggleButtonClick = (e, frm, Driven) => {
        let arrayValue;
        this.utils.setLastVisitedApptSlot(Driven)
        if (frm == 'Time') {
            this.FormGrp.controls["time"].setValue(Driven);
            this.FormGrp.get('time').markAsDirty();
            arrayValue = this.TimeArr;
            this.PushPopOne(arrayValue, Driven);
            this.serviceDetails.time = this.TimeArr && this.TimeArr.length > 0 ? this.TimeArr[0] : 0;
            this.filterClick('', 'time');
            this.filterAllFields();
            this.GetClientItinerary();
            this.GetConflictingActivities();
            this.refreshPMSStayDetail();
        }
        else if (frm == 'ServiceType') {
            this.FormGrp.controls["serviceType"].setValue(Driven);
            this.FormGrp.get('serviceType').markAsDirty();
            arrayValue = this.ServiceTypeArr;
            this.PushMultiple(arrayValue, Driven);
            this.serviceDetails.ServiceGroupId = this.ServiceTypeArr && this.ServiceTypeArr.length > 0 ? this.ServiceTypeArr[0] : 0;
            this.filterClick('', 'servicegroup');
            this.setFilteredValues(this.GlobalAllFilters);
        }
        this.validateForm();
    }
    ButtonSelect(Arr, value) {
        Arr.splice(0, 1);
        Arr.push(value);
    }

    PushMultiple(ga, gv) {
        if (ga.indexOf(gv) == -1) {
            ga.push(gv);
        } else {
            ga.splice(ga.indexOf(gv), 1);
        }
    }


    PushPopOne(ga, gv) {
        if (ga.indexOf(gv) == -1) {
            ga.splice(0, 1);
            ga.push(gv);
        } else {
            ga.splice(0, 1);
        }
    }
    PushPopData(ga, gv) {
        if (ga.indexOf(gv) == -1) {
            ga.push(gv);
        } else {
            ga.splice(ga.indexOf(gv), 1);
        }
    }

    CheckClientPreference(e: any, type: string, serviceId?: number) {
        if (this.apptService.recordsArray != null && this.apptService.recordsArray.length == 1 && this.apptService.recordsArray[0].clientPreferences &&
            this.apptService.recordsArray[0].clientPreferences.length > 0 && this.apptService.recordsArray[0].clientDetail.id != null && this.apptService.recordsArray[0].clientDetail.id > 0) {
            let clientLength: number = this.apptService.recordsArray.length;
            let matchLength: number = 0;
            this.setControlValue(type, false);
            let clientPreferences = this.apptService.recordsArray[0].clientPreferences ? this.apptService.recordsArray[0].clientPreferences : [];
            if (!clientPreferences.filter(x => x.typeName == type) || clientPreferences.filter(x => x.typeName == type).length < 1) return
            for (let clientPreferencesItem of clientPreferences) {
                if (clientPreferencesItem.typeName == type && clientPreferencesItem.typeId == ((serviceId && serviceId != 0) ? serviceId : e.value)) {
                    matchLength++;
                    this.setControlValue(type, true);
                    break;
                }
            }
            if (clientLength == matchLength)
                this.utils.ShowErrorMessage(this.localization.captions.common.Information, this.localization.getError(-181), global.ButtonType.Ok)
            else
                this.utils.ShowErrorMessage(this.localization.captions.common.Information, this.localization.getError(-182), global.ButtonType.Ok)
        }
    }

    private setControlValue(type: string, value: any) {
        if (type.toLowerCase() == "therapist") {
            this.FormGrp.controls['RequestStaff'].setValue(value);
        }
    }

    successCallback<T>(result: BaseResponse<T>, callDesc: string, extraParams: any[]): void {
        if (["GetAllTherapist", "getActiveTherapist"].includes(callDesc)) {
            this.therapist = result.result ? result.result : [];
            this.isEnableTherapistError = true;
            this.GlobalTherapist = _.cloneDeep(this.therapist);
            this.apptService.therapistArray = this.GlobalTherapist;
            if (this.therapist.length == 0) {
                this.serviceDetails.therapist = "";
                this.serviceDetails.TherapistId = "";
            }
            if (!extraParams[0])
                this.OpenDropDown("therapist");
            else {
                let therapistDropDown = this.FormGrp.controls["therapist"];
                therapistDropDown.setValue(this.serviceDetails.TherapistId);
                this.serviceDetails.therapist = this.serviceDetails.TherapistId;
            }

        }
        if (["GetAllLocByUserId"].includes(callDesc)) {
            this.location = result.result ? result.result : [];
            this.isEnableLocationsError = true;
            this.GlobalLocation = _.cloneDeep(this.location);
            if (this.location.length == 0) {
                this.serviceDetails.location = "";
                this.serviceDetails.LocationId = "";
            }
            if (!extraParams[0])
                this.OpenDropDown("location");
            else {
                let locationDropDown = this.FormGrp.controls["location"];
                setTimeout(() => {
                    if (this.serviceDetails.LocationId) {
                        locationDropDown.setValue(this.serviceDetails.LocationId.toString());
                        this.serviceDetails.location = this.serviceDetails.LocationId;
                    }
                }, 500);
            }
        }

        if (callDesc == "ValidateLocation") {
            if (!result.result) {
                this.serviceDetails.location = "";
                this.serviceDetails.LocationId = "";
                this.utils.ShowErrorMessage(this.captions.LocationUnavailable, this.localization.getError(-117));
            }
        }
        if (callDesc == "getAppointmentFilters") {
            this.utils.ToggleLoader(false);
            this.handleAppointmentFilterResponse(result.result);
        }

        if (callDesc == "GetItineraryForAppointment") {
            let GetItenerary = <any>result.result;
            this.apptService.resultNewAppointment.timelineData = cloneDeep(GetItenerary);
            this.apptService.resultNewAppointment.clientFullItinerary = cloneDeep(GetItenerary);
            this.apptService.subscribeStayDetail(GetItenerary, this.getAppointmentDateTime());
        }


        if (callDesc == "CreateTempHoldAppointment") {
            if (result.result) {
                let newTempApt = _.cloneDeep(this.apptService.tempHoldValues);
                newTempApt.appointmentId = result.result[0];
                let apiBody: appointment = extraParams[0];
                if (apiBody) {
                    newTempApt.clientId = apiBody.appointmentDetail.clientId;
                }
                this.apptService.TempHoldArr.push(newTempApt);
                this.aptService.tempRecordCreated = true;

                if (!this.aptService.hasActiveTempHold) {
                    this.appUtils.deleteTempHold([newTempApt], this.successCallback.bind(this), null);
                }
                this.filterAllFields();
            }
        }
        if (callDesc == "GetServicePriceBasedOnYield") {
            this.serviceDetails.price = result.result ? result.result : 0;
            this.apptService.resultNewAppointment.price = this.serviceDetails.price;
            this.setServiceChargeGratuity(this.serviceDetails.price, extraParams[0]);
            this.apptService.setPriceTypeUIforMutliClient(this.apptService.multiClientInfo.length > 0 ? this.apptService.multiClientInfo[0].id : 0);
        }
        if (callDesc == "GetMedicalCondition") {
            this.MedicalCondition(<any>result.result);
        }
        if (callDesc == "GetMedicalWarning") {
            if (<any>result.result != null) {
                this.appUtils.GetMedicalWarning(<any>result.result, this._vcr, 'appointment', '', this.MedicalWarningPopUpCallback.bind(this))

            }
            else {
                this.CheckClientPreference('', 'SERVICE', this.serviceDetails.service)
            }
        }
        if (callDesc == "DeleteTempHold") {
            const tempIds = extraParams;
            for (let idItem of tempIds) {
                if (idItem > 0) {
                    const tempHoldIndex = this.apptService.TempHoldArr.findIndex(function (tempobj) {
                        return tempobj.appointmentId == idItem;
                    });

                    if (tempHoldIndex !== -1) {
                        this.apptService.TempHoldArr.splice(tempHoldIndex, 1);
                    }
                }
            }
            this.apptService.showDetails = false;
            this.apptService.clientPreferedGender = "";
            this.apptService._appointmentConfigurations$.next(true);
        }

        setTimeout(() => { this.validateForm() }, 500);
    }

    errorCallback<T>(result: BaseResponse<T>, callDesc: string, extraParams: any[]): void {
        if(callDesc == 'getAppointmentFilters'){
            this.utils.ToggleLoader(false);
            console.error(result.result);
        }
    }

    handleAppointmentFilterResponse(result: any) {
        if (result) {
            this.GlobalAllFilters = result;

            let isSetExistingValue = (this.apptService.resultExistingAppointment && !this.isDefaultLoaded);
            this.setFilteredValues(result, !isSetExistingValue);

            if (isSetExistingValue) {
                this.SetExistingAppointmentDefault();
            }
            if (this.serviceDetails.ServiceId && this.GlobalAllFilters.serviceViewModels) {
                this.apptService.isPriceTypeApplicable();
                if (this.apptService.isEditAppointment && !this.apptService.ValidatePriceTypeAvailable(this.apptService.multiClientInfo[0].id) && !this.apptService.serviceChanged) {
                    this.apptService.setPriceTypeCodeForSelectedClient(this.apptService.multiClientInfo[0].id, this.serviceDetails.ServiceId);
                }
                else {
                    let service = this.GlobalAllFilters.serviceViewModels.filter(x => x.id == this.serviceDetails.ServiceId);
                    this.apptService.selectedServicePriceTypes = service && service.length > 0 ? service[0].servicePriceType : '';
                    this.apptService.setPriceTypeCodeUI();
                }
            }

            if (this.serviceDetails.ServiceId && this.GlobalAllFilters.serviceViewModels &&
                this.GlobalAllFilters.serviceViewModels.filter(x => x.id == this.serviceDetails.ServiceId).length == 0) {
                this.FormGrp.controls["service"].setValue(this.serviceDetails.ServiceId.toString());
                this.SelectedService = Number(this.serviceDetails.ServiceId);
                this.setServiceGroupId();
            }
        }

        let selectedServiceId = this.FormGrp.controls["service"].value;
        if (this.serviceDetails && selectedServiceId > 0) {
            let hasOriginalServiceAndDate: boolean = this.isUpdatedWithSameService();
            if (hasOriginalServiceAndDate) { // setting original appointmentprice when date/service are not changed in edit
                let yieldPrice = this.apptService.resultExistingAppointment.appointmentDetail.yieldPrice;
                this.serviceDetails.price = yieldPrice ? yieldPrice : this.apptService.resultExistingAppointment.appointmentDetail.price;
                this.apptService.resultNewAppointment.price = this.serviceDetails.price;
            }
            else {
                let filteredService = this.GlobalAllFilters ? this.GlobalAllFilters.serviceViewModels.find(r => r.id == selectedServiceId) : null;
                if (filteredService) {
                    this.serviceDetails.price = filteredService ? filteredService.price : '';
                    this.apptService.resultNewAppointment.price = filteredService ? filteredService.price : '';
                }
            }
            this.updateActualPriceAndPriceTypeForClient(selectedServiceId);
            if (this.apptService.isPriceTypeApplicable()) {
                this.clientServicePriceCalc(hasOriginalServiceAndDate);
            }
        }
    }

    private isUpdatedWithSameService() {
        let appointmentWithOriginalService = false;
        let selectedServiceId = this.FormGrp.controls["service"].value;
        let selectedDate: Date = this.FormGrp.get("dateField").value;
        if (this.apptService.isEditAppointment && this.apptService.resultExistingAppointment &&
            this.apptService.resultExistingAppointment.appointmentDetail.serviceId == selectedServiceId &&
            this.utils.GetDateWithoutTime(selectedDate).getTime() == this.utils.GetDateWithoutTime(this.apptService.resultExistingAppointment.appointmentDetail.startTime).getTime()) {
            appointmentWithOriginalService = true;
        }

        return appointmentWithOriginalService;
    }
    private setPriceTypeUIControlValues(price: number): void {
        this.apptService.selectedClientPriceValue = price;
    }

    MedicalCondition(result: any) {
        let clientMedicalCondition = [];
        let serviceMedicalCondition = result;
        if (serviceMedicalCondition != null && serviceMedicalCondition.medicalCondition != null) {
            serviceMedicalCondition.medicalCondition.forEach(e => {
                clientMedicalCondition.push({
                    "id": e.MedicalConditionCode,
                    "name": e.description,
                    "description": e.warning
                })
            });
            this.appUtils.openMedicalWarning(clientMedicalCondition, true, this._vcr);
        }
    }
    MedicalWarningPopUpCallback(result: any, extraParams: any[]) {
        if (result && result.popupCloseInfo.toLowerCase() == global.ButtonOptions.No.toLowerCase() && result && result.clientId > 0) {
            this.apptService.selectedIndex = 1;
            this.apptService.recordsArray = this.apptService.recordsArray.filter(r => r.clientDetail.id != result.clientId);
            this.apptService.multiClientInfo = this.apptService.multiClientInfo.filter(r => r.id != result.clientId);
            this.apptService.clientsearchArray = this.apptService.clientsearchArray.filter(r => r.id != result.clientId);
            this.apptService.labelRecords = this.apptService.labelRecords.filter(r => r.Id != result.clientId);
            this.apptService.clientGuestMap = this.apptService.clientGuestMap.filter(r => r.ClientId != result.clientId);
            this.UpdateTempHold(result.clientId);
        }
        else {
            this.CheckClientPreference('', 'SERVICE', this.serviceDetails.service)
        }
    }

    SetExistingAppointmentDefault() {

        if (this.serviceDetails.TherapistId && this.serviceDetails.TherapistId > 0) {
            this.filters.TherapistIdList = [this.serviceDetails.TherapistId]
            this.therapist = [{ firstName: this.serviceDetails.firstName, lastName: this.serviceDetails.lastName, id: this.serviceDetails.TherapistId, gender: this.serviceDetails.gender }]
            let setVal: any = this.serviceDetails.TherapistId;
            this.serviceDetails.therapist = setVal;
            //this.isTherapistAddedWhileLoad = true;
            let listOfTherapistToBeAdded: number[] = [];

            this.apptService.resultExistingAppointment.appointmentTherapists.forEach(res => {
                if (!listOfTherapistToBeAdded.includes(res.therapistId)) {
                    listOfTherapistToBeAdded.push(res.therapistId);
                }
            });
            if (listOfTherapistToBeAdded.length > 0) {
                this.therapistValueChangeEventEnabled = false;
                for (let i = 1; i <= listOfTherapistToBeAdded.length; i++) {
                    this.AddTherapistDropdownForUpdateLoad(i - 1, listOfTherapistToBeAdded[i - 1].toString(), i > 1);
                }
            }
            this.therapistValueChangeEventEnabled = true;

            //if (this.apptService.resultExistingAppointment.appointmentDetail.status == 'CANC' || this.apptService.resultExistingAppointment.appointmentDetail.status == 'NOSHOW') {
            //    this.appBreakDownIsReadOnly = true;
            //    this.appDurationIsReadOnly = true;
            //    this.appSetupTimeIsReadOnly = true;
            //}
            let yieldPrice = this.apptService.resultExistingAppointment.appointmentDetail.yieldPrice;
            this.serviceDetails.price = yieldPrice ? yieldPrice : this.apptService.resultExistingAppointment.appointmentDetail.price;
            this.serviceDetails.duration = this.apptService.resultExistingAppointment.appointmentDetail.duration;
            this.serviceDetails.setupTime = this.apptService.resultExistingAppointment.appointmentDetail.setUpTime;
            this.serviceDetails.breakDownTime = this.apptService.resultExistingAppointment.appointmentDetail.breakDownTime;

            this.isDefaultLoaded = true;
        }
    }


    OpenDropDown(type: string) {
        let serviceDropDown = this.ServiceList;
        let therapistDropDown = this.TherapistList;
        let locationDropDown = this.LocationList;
        setTimeout(() => {
            if (type == "service")
                serviceDropDown.open();
            if (type == "therapist")
                therapistDropDown.open();
            if (type == "location")
                locationDropDown.open();
        }, 300);

    }

    scrollstart: boolean = false;
    scrollValue: any = 0;
    tempScrollPost: any = 0;

    mouseDown(e) {
        this.scrollstart = true;
        document.getElementById('TimeID').offsetLeft;
        this.tempScrollPost = e.pageX
        this.validateForm();
    }

    mouseUp(e) {
        this.scrollstart = false;
        this.tempScrollPost = e.pageX
    }
    mouseLeave(e) {
        this.scrollstart = false;
        this.tempScrollPost = e.pageX
    }

    mouseMove(e) {
        if (this.scrollstart) {
            if (this.tempScrollPost > e.pageX) {
                document.getElementById("TimeID").scrollLeft = this.scrollValue += 15;
            } else {
                document.getElementById("TimeID").scrollLeft = this.scrollValue -= 15;
            }
        }
    }



    filterClick(e: any, type: string, therapistIndex?: number) {
        switch (type) {
            case "date":
                this.filters.Date = e;
                if (this.CanUpdateTemp) {
                    this.UpdateTempHold(e, '', '', 0, '');
                }
                break;
            case "time":
                if (this.TimeArr && this.TimeArr.length > 0)
                    this.filters.Time = this.TimeArr[0];
                if (this.CanUpdateTemp) {
                    this.UpdateTempHold('', this.TimeArr[0], '', 0, '');
                }
                else
                    this.filters.Time = "";
                break;
            case "service":
                this.OnElementValueChange(e, 'service');
                this.apptService.recordsArray.forEach(x => x.clientDetail['selectedAddOns'] = []);
                this.filters.ServiceId = this.serviceDetails.service;
                this.apptService.disableRadioButton();
                if (this.serviceDetails.location && this.hasValue(e.value)) {
                    let currentService = this.services.find(x => x.id === Number(e.value));
                    let selectedLocationInfo = this.location.find(x => x.id === Number(this.serviceDetails.location));
                    if (selectedLocationInfo && currentService && selectedLocationInfo.maxGuests > 0 && selectedLocationInfo.maxGuests < currentService.minimumGuest) {
                        this.FormGrp.controls['service'].setValue(null);
                        const message = this.captions.LocationMaxGuestServiceMinGuestConflict.interpolate({ location: selectedLocationInfo.maxGuests, service: currentService.minimumGuest });
                        this.utils.showAlert(message, global.AlertType.Warning, global.ButtonType.Ok);
                        break;
                    }
                }

                if (this.FormGrp.controls['service'].hasError('required') && this.FormGrp.controls['service'].touched && !this.FormGrp.controls['service'].valid) {
                    this.isPriceReadOnly = this.apptService.isPriceTypeApplicable();
                    this.price = 0;
                    this.duration = this.allCaptions.common.Mins;
                    this.serviceDetails.duration = this.allCaptions.common.Mins;
                    this.setupTime = this.allCaptions.common.Mins;
                    this.serviceDetails.setupTime = this.allCaptions.common.Mins;
                    this.breakDownTime = this.allCaptions.common.Mins;
                    this.serviceDetails.breakDownTime = this.allCaptions.common.Mins;
                    this.serviceDetails.price = "";
                }
                else {
                    this.isPriceReadOnly = this.apptService.isPriceTypeApplicable();
                    let service = this.services.find(s => s.id == e.value);
                    this.isOffsiteLocation = !!(service && service.isOffsite);
                    if (this.isOffsiteLocation) {
                        this.FormGrp.controls['location'].setValue(null);
                        this.FormGrp.controls['location'].setValidators(null);
                        this.FormGrp.controls['location'].setErrors(null);
                    }
                    else {
                        this.FormGrp.controls['location'].setValidators(Validators.required);
                        if ((this.serviceDetails.LocationId == undefined || Number(this.serviceDetails.LocationId) == 0) && this.aptService.appointmentConfiguration && this.aptService.appointmentConfiguration.length > 0 && this.aptService.appointmentConfiguration[0]['AUTO_PICK_LOCATION']) {

                            this.filterLocation(this.GlobalAllFilters);
                            if (this.location.length > 0) {
                                this.location = _.cloneDeep(this.location.sort((a, b) => a.listOrder != b.listOrder ? a.listOrder < b.listOrder ? -1 : 1 : 0));

                                const locationId = this.loadDefaultLocation();
                                this.serviceDetails.location = locationId;
                                this.filters.LocationId = this.serviceDetails.location;
                                this.FormGrp.controls['location'].setValue(this.serviceDetails.location.toString(), { emitEvent: false });
                                this.setMaxGuestLocation();
                                if (this.CanUpdateTemp) {
                                    this.UpdateTempHold('', '', '', this.filters.LocationId, '');
                                }
                            }
                            this.validateForm();
                        }
                    }
                }
                if (this.CanUpdateTemp) {
                    this.UpdateTempHold('', '', this.filters.ServiceId, 0, '');
                }
                break;
            case "location":
                let currentLocation = this.serviceDetails.location;

                if (this.serviceDetails.service && this.hasValue(e.value)) {
                    let currentService = this.services.find(x => x.id === Number(this.serviceDetails.service));
                    let selectedLocationInfo = this.location.find(x => x.id === Number(e.value));
                    if (currentService && selectedLocationInfo && selectedLocationInfo.maxGuests > 0 && selectedLocationInfo.maxGuests < currentService.minimumGuest) {
                        this.FormGrp.controls['location'].setValue(null);
                        const message = this.captions.ServiceMinGuestLocationMaxGuestConflict.interpolate({ location: selectedLocationInfo.maxGuests, service: currentService.minimumGuest });
                        this.utils.showAlert(message, global.AlertType.Warning, global.ButtonType.Ok);
                        break;
                    }
                }

                this.filters.LocationId = this.serviceDetails.location;
                this.OnElementValueChange(e, 'location');
                if (this.CanUpdateTemp) {
                    this.UpdateTempHold('', '', '', this.filters.LocationId, '');
                }
                break;
            case "servicegroup":
                this.filters.ServiceGroupId = this.ServiceTypeArr[0];
                this.FormGrp.controls["service"].setValue("");
                this.SelectedService = 0;
                this.FormGrp.controls["location"].setValue("");
                this.FormGrp.controls["therapist"].setValue("");
                this.serviceDetails.LocationId = "";
                this.serviceDetails.TherapistId = "";
                this.serviceDetails.ServiceId = "";
                break;
            case "therapist":
                let previousTherap = this.filters.TherapistIdList[therapistIndex];
                this.OnElementValueChange(e, 'therapist');
                if(this.previousTherapistDetails?.length != therapistIndex){
                let changedTherapist = this.previousTherapistDetails?.[therapistIndex].id;
                this.removeChangedTherapistAddons(changedTherapist);
                }
                this.filters.TherapistIdList = [this.serviceDetails.therapist];
                this.apptService.therapistidArray.push(e.value)


                let otherdetailsgendervalue = '';
                if (this.apptService.otherdetails.gender) {
                    if (this.apptService.otherdetails.gender == this.genderEnum.preferFemale) {
                        otherdetailsgendervalue = 'Female';
                    }
                    else if (this.apptService.otherdetails.gender == this.genderEnum.preferMale) {
                        otherdetailsgendervalue = 'Male';
                    }
                }

                if (this.chosenTherapistList.map(a => a.index == therapistIndex).length > 0)
                    this.chosenTherapistList = this.chosenTherapistList.filter(a => { return a.index != therapistIndex });
                this.chosenTherapistList.push({ index: therapistIndex, id: parseInt(e.value) });
                let selectedTherapist = _.find(this.therapist, (x) => x.id == e.value);
                if (this.apptService.recordsArray.length == 1) {
                    if (this.apptService.recordsArray[0].clientDetail.genderPreference != '') {
                        if (selectedTherapist && selectedTherapist.gender == this.apptService.recordsArray[0].clientDetail.genderPreference) {
                            this.apptService.otherdetails.gender = this.genderEnum.enforceGender;
                        } else if (this.apptService.recordsArray[0].clientDetail.genderPreference == 'Male') {
                            this.apptService.otherdetails.gender = this.genderEnum.preferMale;
                        } else if (this.apptService.recordsArray[0].clientDetail.genderPreference == 'Female') {
                            this.apptService.otherdetails.gender = this.genderEnum.preferFemale;
                        }
                        else {
                            this.apptService.otherdetails.gender = this.genderEnum.none;
                        }
                    }
                    else {
                        if ((this.apptService.otherdetails.gender == this.genderEnum.preferMale || this.apptService.otherdetails.gender == this.genderEnum.preferFemale)
                            && (selectedTherapist.gender == otherdetailsgendervalue)) {
                            this.apptService.otherdetails.gender = this.genderEnum.enforceGender;
                        }
                        else if ((this.apptService.otherdetails.gender == this.genderEnum.enforceGender)
                            && (this.apptService.otherdetails.gender != this.otherdetailsgender)) {
                            this.apptService.otherdetails.gender = this.otherdetailsgender;
                        }
                        else if (this.apptService.otherdetails.gender == 0) {
                            this.apptService.otherdetails.gender = this.genderEnum.none;
                        }
                    }
                }
                let genderPref = '';
                if (selectedTherapist) {
                    if (selectedTherapist.gender == 'Male') {
                        genderPref = 'Female';
                    }
                    else if (selectedTherapist.gender == 'Female') {
                        genderPref = 'Male';
                    }
                }
                this.apptService.clientPreferedGender = genderPref;

                this.RemoveMappedTherapistWithMultiClient(previousTherap);
                break;
            default:
                break;
        }
        this.validateForm();
    }

    setMaxGuestLocation() {
        if (this.location && this.hasValue(this.FormGrp.controls['location'].value)) {
            const currentLocation = this.location.find(x => x.id === Number(this.FormGrp.controls['location'].value));
            if (currentLocation) {
                this.apptService.clientScreenProperties.maxGuestLocation = currentLocation.maxGuests;
            }
        }
        else {
            this.apptService.clientScreenProperties.maxGuestLocation = 0;
        }
    }
    hasValue(input) {
        return input && input != "" && input != "0";
    }
    loadDefaultLocation() {
        let locationId = 0;
        if (this.location && this.services && this.hasValue(this.serviceDetails.service)) {

            let currentService = this.services.find(x => x.id === Number(this.serviceDetails.service));


            for (let i = 0; i < this.location.length; i++) {
                let currentLocation = this.location[i];
                if (currentService && currentLocation.maxGuests == 0 || currentLocation.maxGuests >= currentService.minimumGuest) {
                    locationId = currentLocation.id;
                    break;
                }
            }
        }
        return locationId;
    }
    private RemoveMappedTherapistWithMultiClient(therapistId: number) {
        if (this.apptService.multiClientInfo.length > 0) {
            this.apptService.multiClientInfo.forEach(e => {
                if (therapistId == e.TherapistId)
                    e.TherapistId = 0;
            });
        }
    }

    private updatePriceTypeForAppointment() {
        if (this.apptService.multiClientInfo.length > 0 && this.apptService.selectedServicePriceTypes.length > 0) {
            this.apptService.multiClientInfo.forEach(client => {
                let selectedpriceTypeCode = this.apptService.selectedServicePriceTypes.find(y => y.priceTypeId == client.priceTypeId);
                client.priceTypeId = selectedpriceTypeCode ? selectedpriceTypeCode.priceTypeId : 0
            });
        }
    }

    checkTherapistChosen(index: number, id: number) {
        let chosen = this.chosenTherapistList.filter(a => { return a.index == index && a.id == id });
        return (this.chosenTherapistList && this.chosenTherapistList.length == 0) ||
            (chosen.length > 0);
    }

    checkForMinStaffValidation(): boolean {
        let uniqueTherapistArr = [];
        if (this.apptService.clientScreenProperties.selectedStaff) {
            if (this.apptService.clientScreenProperties.selectedStaff.length == 0 && this.therapist.length > 0 && this.FormGrp.value.StaffDropDownFormArr?.some(x => Number(x.therapist) > 0)) {
                this.apptService.clientScreenProperties.selectedStaff = this.FormGrp.value.StaffDropDownFormArr?.map(x => this.therapist.find(y => y.id == Number(x.therapist)));
            }

            this.apptService.clientScreenProperties.selectedStaff = this.therapist.length > 0 ? (this.apptService.clientScreenProperties.selectedStaff.filter(x => x && this.therapist.find(y => y.id == x.id))) : this.apptService.clientScreenProperties.selectedStaff;

            uniqueTherapistArr = this.utils.removeDuplicates(this.apptService.clientScreenProperties.selectedStaff);
            return uniqueTherapistArr.length >= this.minTher ? true : false;
        } else {
            return false;
        }
    }

    validateLocation() {
        let date = (this.filters.Date && this.filters.Time) ? this.utils.formatDate(this.filters.Date) + "T" + this.localization.LocalizedTimeToISOTime(this.filters.Time) : "";
        this.http.CallApiWithCallback({
            host: global.Host.schedule,
            success: this.successCallback.bind(this),
            error: this.utils.errorCallback.bind(this),
            callDesc: "ValidateLocation",
            method: HttpMethod.Get,
            uriParams: { id: this.filters.LocationId, date: date },
            showError: true,
            extraParams: []
        });
    }


    viewMore(e) {
        if (e) {
            this.NoofItinerary = this.serviceDetails.timelineData.length;
            this.showViewMore = false;
            this.viewText = this.spaConfig.captions.common.Less
        } else {
            this.NoofItinerary = 2;
            this.showViewMore = true;
            this.viewText = this.localisation.ViewFullItenerary
        }

    }

    getFullItenerary() {
        let dateTime = this.getAppointmentDateTime();
        let guestId = this.apptService.clientGuestMap && this.apptService.clientGuestMap.length > 0 ?
            this.apptService.clientGuestMap.map(y => y.GuestId)[0] : global.DefaultGUID;
        if (guestId && guestId != global.DefaultGUID && dateTime) {
            this.http.CallApiWithCallback({
                host: global.Host.schedule,
                success: this.successCallback.bind(this),
                error: this.utils.errorCallback.bind(this),
                callDesc: "GetItineraryForAppointment",
                method: HttpMethod.Get,
                uriParams: { clientId: guestId, dateTime: dateTime },
                showError: true,
                extraParams: [dateTime]
            });
        }
    }

    private getAppointmentDateTime(): string {
        let date: Date = this.FormGrp.get("dateField").value;
        let time: string = this.serviceDetails.time ? this.serviceDetails.time : "";
        return date ? this.utils.convertDateFormat(this.utils.getDate(
            date.getFullYear() +
            "-" +
            this.utils.getFullMonth(date) +
            "-" +
            this.utils.getFullDate(date) +
            "T" +
            this.localization.DeLocalizeTime(time))
        ) : "";
    }

    private refreshPMSStayDetail() {
        if (this.apptService.resultNewAppointment.clientFullItinerary && this.apptService.resultNewAppointment.clientFullItinerary.length > 0) {
            this.apptService.subscribeStayDetail(this.apptService.resultNewAppointment.clientFullItinerary, this.getAppointmentDateTime());
        } else {
            this.getFullItenerary();
        }
    }

    toggleEvent(e: boolean, type: string) {
        if (type == 'DNM') {
            this.apptService.resultNewAppointment.doNotMove = e;
        }
        else if (type == 'RS') {
            this.apptService.resultNewAppointment.requestStaff = e;
        }
        else if (type == 'LB') {
            this.apptService.resultNewAppointment.isBookingLocked = e;
        }
        this.validateForm();
    }
    validateForm() {
        this.IsTherapistSelectionValid = this.checkForMinStaffValidation();
        this.apptService.therapistNotSelected = !this.IsTherapistSelectionValid;
        if ((this.isOffsiteLocation || (this.serviceDetails.location && this.serviceDetails.location != "" && this.FormGrp.controls["location"].value != '')) &&
            this.serviceDetails.service && this.serviceDetails.service != "" &&
            this.serviceDetails.therapist && this.serviceDetails.therapist != "" &&
            this.serviceDetails.ServiceGroupId && this.serviceDetails.ServiceGroupId != "" &&
            this.serviceDetails.time && this.serviceDetails.time != "" &&
            this.serviceDetails.dateField && this.serviceDetails.dateField != "" &&
            this.serviceDetails.duration && this.serviceDetails.duration >= 1 &&
            this.IsTherapistSelectionValid
        ) {
            this.serviceDetails.CreateFormValid = true;
        }
        else
            this.serviceDetails.CreateFormValid = false;
    }

    durationChange() {
        this.GetConflictingActivities();
        this.validateForm()
    }

    filterAllFields(): void {

        if (!this.apptService.resultExistingAppointment && !this.aptService.tempRecordCreated) {
            this.CreateTempHold();
        }
        else {
            this.showSchedulingAssistant = true;
            let date: Date = this.FormGrp.get("dateField").value;
            let time: string = this.serviceDetails.time ? this.serviceDetails.time : "";
            let dateTime = date ? this.utils.convertDateFormat(this.utils.getDate(
                date.getFullYear() +
                "-" +
                this.utils.getFullMonth(date) +
                "-" +
                this.utils.getFullDate(date) +
                "T" +
                this.localization.DeLocalizeTime(time))
            ) : "";

            let isTherapistOverBook: boolean = false;
            let isLocationOverBook: boolean = false;
            if (this.apptService.isOverbook) {
                isTherapistOverBook = this.apptService.isTherapistView;
                isLocationOverBook = !isTherapistOverBook;
                if (this.apptService.isFromAppointmentSearch) {
                    isTherapistOverBook = true;
                    isLocationOverBook = true;
                }
            }
            else if (this.apptService.resultExistingAppointment) {
                isLocationOverBook = this.apptService.resultExistingAppointment.appointmentDetail.isLocationOverbook;
                isTherapistOverBook = this.apptService.resultExistingAppointment.appointmentDetail.isTherapistOverbook;
                let unassignedTherapist = this.therapist.find(t => t.id == this.apptService.resultExistingAppointment.appointmentTherapists[0].therapistId);
                if(unassignedTherapist && unassignedTherapist.code =="UNASSIGNED"){
                    isTherapistOverBook = true;
                    isLocationOverBook = true;
                }
            }

            let view = 0;
            let id = this.apptService.resultExistingAppointment ? this.apptService.resultExistingAppointment.appointmentDetail.id : 0;

            let currentTempHoldIds: any = [];
            if (this.apptService.TempHoldArr && this.apptService.TempHoldArr.length > 0) {
                for (let apptServiceItem of this.apptService.TempHoldArr) {
                    currentTempHoldIds.push(apptServiceItem.appointmentId);
                }
            }

            if(this.cartUtils.isEmbed() && this.apptService.resultExistingAppointment)
                this.apptService.resultExistingAppointment.appointmentTherapists.forEach(appt => currentTempHoldIds.push(appt.appointmentId));


            let UriParams = { isTherapistOverbook: isTherapistOverBook, isLocationOverbook: isLocationOverBook, id: id, timeAvailable: dateTime && time ? "true" : "false", date: date ? dateTime : null, tempIds: currentTempHoldIds.length > 0 ? JSON.stringify(currentTempHoldIds) : null }
            this.locationoverbook = isLocationOverBook;
            this.GetOccupiedLocationDetails(date, currentTempHoldIds);
            this.utils.ToggleLoader(true);
            this.http.CallApiWithCallback<any>({
                host: global.Host.schedule,
                success: this.successCallback.bind(this),
                error: this.errorCallback.bind(this),
                callDesc: "getAppointmentFilters",
                uriParams: UriParams,
                method: HttpMethod.Get,
                showError: true,
                extraParams: []
            });
        }

    }

    getTempholdIds(): number[] {
        let currentTempHoldIds: number[] = [];
        if (this.apptService.TempHoldArr && this.apptService.TempHoldArr.length > 0) {
            for (let apptServiceItem of this.apptService.TempHoldArr) {
                currentTempHoldIds.push(apptServiceItem.appointmentId);
            }
        }
        return currentTempHoldIds
    }

    async GetOccupiedLocationDetails(date: any, currentTempHoldIds: any[]) {
        this.locationoccupiedtime = await this.apptService.GetOccupiedLocationDetails(date, currentTempHoldIds);
    }

    filterDropDown(result) {
        if (!result) return;
        this.filterLocation(result);
        this.filterTherapist(result);
        this.filterService(result);
    }

    setFilteredValues(result: any, filterDropDropdown = true): void {
        if (!result)
            return;
        if (filterDropDropdown) {
            this.filterDropDown(result)
        }
        let locSetVal: any = _.cloneDeep(this.serviceDetails.LocationId);
        let therSetVal = _.cloneDeep(this.serviceDetails.TherapistId);
        let ServiceId = _.cloneDeep(this.serviceDetails.ServiceId);

        let ther = this.FormGrp.controls["therapist"];
        let serv = this.FormGrp.controls["service"];
        let loc = this.FormGrp.controls["location"];


        setTimeout(() => {
            if (locSetVal && !loc.value) {
                loc.setValue(locSetVal.toString());
                if (this.hasValue(ServiceId)) {
                    this.clientCountValidation(ServiceId);
                }
            }
            if (therSetVal && !ther.value)
                ther.setValue(therSetVal.toString());
            if (ServiceId && !serv.value) {
                serv.setValue(ServiceId.toString());
                this.SelectedService = Number(ServiceId);
            }
        }, 500);
    }

    filterService(result) {
        let serviceGroup = this.ServiceTypeArr
        let location: number[] = this.FormGrp.get("location").value && this.FormGrp.get("location").value != "0" ? [Number(this.FormGrp.get("location").value)] : [];
        let therapist: number[] = [];
        if (this.StaffDropDownFormArr.length > 0) {
            for (let i = 0; i < this.StaffDropDownFormArr.length; i++) {
                if (this.StaffDropDownFormArr.controls[i].value.therapist > 0) {
                    therapist.push(Number(this.StaffDropDownFormArr.controls[i].value.therapist));
                }
            }
        }
        let allFilters = _.cloneDeep(result);
        if (!allFilters || !this.GlobalService)
            return;

        /* Group filtered */
        let tempService = _.cloneDeep(this.GlobalService).filter(res => {
            return serviceGroup.length == 0 || serviceGroup.includes(res.serviceGroupId);
        });

        /* Location filtered */
        tempService = tempService.filter(res => {
            let isMatch = false;
            allFilters.locationViewModels.some(a => {
                isMatch = res.id == a.serviceId && (location.length == 0 || location.includes(a.locationId)) ? true : false;
                return isMatch;
            });
            return isMatch;
        });
        tempService = tempService.filter(res => {
            let isMatch = false;
            allFilters.therapistViewModels.some(a => {
                isMatch = res.id == a.serviceId && (therapist.length == 0 || therapist.includes(a.therapistId)) ? true : false;
                return isMatch;
            });
            return isMatch;
        });

        const appointmentDate = this.apptService.resultNewAppointment.dateField ? this.utils.getDate(this.apptService.resultNewAppointment.dateField) : this.PropertyInfo.CurrentDate;
        tempService = tempService.filter(x => this.utils.getDate(x.effectiveFromDate) <= appointmentDate && this.utils.getDate(x.effectiveToDate) >= appointmentDate);
        if (!this.apptService.isOverbook && therapist && therapist.length > 0 && this.therapist && this.therapist.length > 0 && allFilters.therapistLocationModels && allFilters.therapistLocationModels.length > 0) {
            let availableselectedTherapist = this.therapist.filter(e => therapist.includes(e.id)).map(r => r.id);
            if (availableselectedTherapist && availableselectedTherapist.length > 0) {
                let stime: string = this.serviceDetails.time ? this.serviceDetails.time : "";
                let sDate: Date = this.getSlotTimeWithDate(stime);
                let selTherapist = availableselectedTherapist.map(x => {
                    let therapistAvailStartEndDate = this.getTherapistEndDate(allFilters, x, sDate);
                    return { id: x, avilSDate: therapistAvailStartEndDate[0], eDate: therapistAvailStartEndDate[1] };
                });
                selTherapist.sort((a, b) => a.eDate.getTime() > b.eDate.getTime() ? -1 : 1)
                let eDate: Date = selTherapist[0].eDate;
                let avilSDate: Date = selTherapist[0].avilSDate;
                var diff = Math.abs(sDate.getTime() - eDate.getTime());
                var remainMinAvilable = Math.floor((diff / 1000) / 60);

                var preDiff = Math.abs(avilSDate.getTime() - sDate.getTime());
                var remainPreMinAvilable = Math.floor((preDiff / 1000) / 60);

                tempService = tempService.filter((f) => ((f.duration + f.breakDownTime) <= remainMinAvilable) && f.setupTime <= remainPreMinAvilable);
            }
        }
        this.services = tempService;
        this.initialServiceList = tempService;
    }

    getTherapistEndDate(allFilters, id, sDate) {
        let therapistAvailStartEndDate: Date[] = [sDate, sDate];
        let therapistdetail = allFilters.therapistLocationModels.find(x => x.therapistId == id);
        if (therapistdetail && therapistdetail.scheduleTimes && therapistdetail.scheduleTimes.length > 0) {
            let breakTimes = therapistdetail.occupiedTimes;
            let availableTimes = therapistdetail.scheduleTimes;
            if (this.slotWithinInBreakTime(breakTimes, sDate)) {
                return therapistAvailStartEndDate;
            }
            if (availableTimes && availableTimes.length > 0) {
                let avilTime = availableTimes.filter(r => sDate.getTime() >= (new Date(r.fromTime)).getTime() && sDate.getTime() <= (new Date(r.toTime)).getTime())
                if (avilTime && avilTime.length > 0) {
                    therapistAvailStartEndDate[0] = new Date(avilTime[0].fromTime);
                    therapistAvailStartEndDate[1] = new Date(avilTime[0].toTime);
                }
                if (breakTimes && breakTimes.length > 0) {
                    let brkTimes = breakTimes.filter(d => (new Date(d.startTime)).getTime() >= sDate.getTime());
                    if (brkTimes && brkTimes.length > 0) {
                        brkTimes.sort((a, b) => (new Date(a.startTime)).getTime() - (new Date(b.startTime)).getTime());
                        therapistAvailStartEndDate[1] = new Date(brkTimes[0].startTime);
                    }

                    let sbrkTimes = breakTimes.filter(d => (new Date(d.endTime)).getTime() <= sDate.getTime());
                    if (sbrkTimes && sbrkTimes.length > 0) {
                        sbrkTimes.sort((a, b) => (new Date(a.endTime)).getTime() > (new Date(b.endTime)).getTime() ? -1 : 1);
                        therapistAvailStartEndDate[0] = new Date(sbrkTimes[0].endTime);
                    }
                }
            }
        }
        return therapistAvailStartEndDate;
    }

    getSlotTimeWithDate(time: string): Date {
        let date: Date = this.FormGrp.get("dateField").value;
        let dateTime = date ? this.utils.convertDateFormat(this.utils.getDate(
            date.getFullYear() +
            "-" +
            this.utils.getFullMonth(date) +
            "-" +
            this.utils.getFullDate(date) +
            "T" +
            this.localization.DeLocalizeTime(time))
        ) : "";
        return new Date(dateTime);
    }

    filterLocation(result) {
        let serviceGroup = this.ServiceTypeArr
        let service: number = this.FormGrp.get("service").value ? Number(this.FormGrp.get("service").value) : 0;
        let therapistArray: number[] = [];
        if (this.FormGrp.controls['StaffDropDownFormArr'].value != null && this.FormGrp.controls['StaffDropDownFormArr'].value.length > 0) {
            for (let staffFormItem of this.FormGrp.controls['StaffDropDownFormArr'].value) {
                if (staffFormItem.therapist > 0) {
                    therapistArray.push(Number(staffFormItem.therapist));
                }
            }
        }
        let allFilters = _.cloneDeep(result);
        if (!allFilters || !this.GlobalLocation)
            return;
        this.location = [];

        /* Selected Service Group */
        let availableService = allFilters.serviceViewModels.filter(a => {
            return serviceGroup.length == 0 || serviceGroup.includes(a.serviceGroupId);
        });

        /* Selected Service  */
        let filteredService = availableService.filter(a => a.id == service || service == 0);

        //MatchingLocationForTherapist
        let singleTherapistLocation = 0;
        if (allFilters.therapistLocationModels && therapistArray.length == 1) {
            var therapLocation = allFilters.therapistLocationModels.find(x => therapistArray[0] == x.therapistId);
            if (therapLocation) {
                singleTherapistLocation = therapLocation.locationId;
            }
            else {
                singleTherapistLocation = -1; // No location available for selected Therapist
            }
        }

        this.location = _.cloneDeep(this.GlobalLocation).filter(res => {
            let isMatch = false;
            /* Location Match? */
            if (filteredService && filteredService.length > 0) {
                allFilters.locationViewModels.some(b => {
                    if (filteredService.some(x => x.id == b.serviceId) && b.locationCode == res.code &&
                        (therapistArray.length < 2 ? (singleTherapistLocation == 0 || res.id == singleTherapistLocation) :
                            this.GetLocationMapped(res.id, therapistArray, allFilters.therapistLocationModels)
                        )
                    ) {
                        isMatch = true;
                    }
                });
            }
            return isMatch;
        });

        this.FilterLocationData();
    }

    private FilterLocationData() {
        let finallocation = [];
        let selServce: number = this.FormGrp.get("service").value ? Number(this.FormGrp.get("service").value) : 0;
        if (!this.locationoverbook && this.GlobalService && this.GlobalService.length > 0 && selServce > 0) {

            let _selService = this.GlobalService.find(x => { return x.id == selServce; });


            let durationInControl = this.apptService.isEditAppointment?  Number(this.serviceDetails.duration) : _selService.duration;
            let breakDownTimeInControl = this.apptService.isEditAppointment? Number(this.serviceDetails.breakDownTime) : _selService.breakDownTime;
            let setUpTimeInControl =this.apptService.isEditAppointment? Number(this.serviceDetails.setupTime): _selService.setupTime;


            // let durationInControl = this.serviceDetails.duration && !isNaN(this.serviceDetails.duration) ? Number(this.serviceDetails.duration) : _selService.duration;
            // let breakDownTimeInControl = this.serviceDetails.breakDownTime && !isNaN(this.serviceDetails.breakDownTime) ? Number(this.serviceDetails.breakDownTime) : _selService.breakDownTime;
            // let setUpTimeInControl = this.serviceDetails.setupTime && !isNaN(this.serviceDetails.setupTime) ? Number(this.serviceDetails.setupTime) : _selService.setupTime;

            let stime: string = this.serviceDetails.time ? this.serviceDetails.time : "";
            let sDate = this.getSlotTimeWithDate(stime);
            let eDate: Date = this.utils.AddMinutes(this.getSlotTimeWithDate(stime), breakDownTimeInControl);
            eDate = this.utils.AddMinutes(eDate, durationInControl);
            if (setUpTimeInControl && setUpTimeInControl > 0) {
                sDate = this.utils.AddMinutes(sDate, -setUpTimeInControl);
            }
            this.location.forEach(element => {
                const occupieddata = this.locationoccupiedtime && this.locationoccupiedtime.find(t => t.location.id == element.id);
                if (occupieddata) {
                    if (this.slotNotInBreakTime(occupieddata.occupiedTime, sDate, eDate)) {
                        finallocation.push(element);
                    }
                } else {
                    finallocation.push(element);
                }
            });
            this.location = finallocation;
        }
    }

    GetLocationMapped(locationId: any, therapistArray: any, filters: any): boolean {
        let isMatch = true;
        therapistArray.forEach(x => {
            let matchingLocation = filters.filter(y => y.therapistId == x);
            if (matchingLocation && matchingLocation.length > 0 && Number(matchingLocation[0].locationId) != 0 && Number(matchingLocation[0].locationId) != Number(locationId)) {
                isMatch = false;
            }
        });
        return isMatch;
    }
    minuteToHourMinute(value: any): string {
        let hours: number = Math.floor((value / 60));
        let minutes: number = Math.floor((value % 60));
        return hours.toString().padStart(2, '0') + ':' + minutes.toString().padStart(2, '0');
    }


    filterTherapist(result, isDurationChange = false) {
        let serviceGroup = this.ServiceTypeArr
        let service: number[] = this.FormGrp.get("service").value ? [Number(this.FormGrp.get("service").value)] : [];
        let location: number[] = this.FormGrp.controls["location"] ? [Number(this.FormGrp.get("location").value)] : [];
        let allFilters = _.cloneDeep(result);
        if (!allFilters || !this.GlobalTherapist)
            return;

        this.therapist = _.cloneDeep(this.GlobalTherapist).filter(res => {
            let isMatch = false;
            /* Selected Service Group */
            let availableService = allFilters.serviceViewModels.filter(a => {
                return serviceGroup.length == 0 || serviceGroup.includes(a.serviceGroupId);
            });
            /* Selected Service  */
            let filteredService = availableService.filter(a => {
                return service.length == 0 || service.includes(a.id);
            });

            let filteredTherapist = [];
            if (allFilters.therapistLocationModels) {
                filteredTherapist = allFilters.therapistLocationModels.filter(x => {
                    return location.length == 0 || location[0] == 0 || location.includes(x.locationId) || x.locationId == 0;
                }).map(y => y.therapistId);
            }

            let selServce: number = this.FormGrp.get("service").value ? Number(this.FormGrp.get("service").value) : 0;
            if (!this.apptService.isOverbook && this.GlobalService && this.GlobalService.length > 0 && selServce > 0) {

                let _selService = this.GlobalService.find(x => { return x.id == selServce });

                let durationInControl = this.apptService.isEditAppointment?  Number(this.serviceDetails.duration) : _selService.duration;
                let breakDownTimeInControl = this.apptService.isEditAppointment? Number(this.serviceDetails.breakDownTime) : _selService.breakDownTime;
                let setUpTimeInControl =this.apptService.isEditAppointment? Number(this.serviceDetails.setupTime): _selService.setupTime;

                let stime: string = this.serviceDetails.time ? this.serviceDetails.time : "";
                let sDate = this.getSlotTimeWithDate(stime);
                let eDate: Date = this.utils.AddMinutes(this.getSlotTimeWithDate(stime), breakDownTimeInControl);
                eDate = this.utils.AddMinutes(eDate, durationInControl);
                if (setUpTimeInControl && setUpTimeInControl > 0) {
                    sDate = this.utils.AddMinutes(sDate, -setUpTimeInControl);
                }
                if (allFilters.therapistLocationModels) {
                    filteredTherapist = allFilters.therapistLocationModels.filter(x => {
                        return this.slotWithinAvailbleTime(x.scheduleTimes, sDate, eDate) && this.slotNotInBreakTime(x.occupiedTimes, sDate, eDate);
                    }).map(y => y.therapistId);
                }
            }

            /* Therapist Match? */
            if (!isDurationChange) {
                filteredService.some(function (a) {
                    allFilters.therapistViewModels.some(function (b) {
                        if (a.id == b.serviceId && b.therapistId == res.id && (filteredTherapist == null || filteredTherapist.length == 0 || filteredTherapist.includes(res.id))) {
                            isMatch = true;
                        }
                        return isMatch;
                    });
                    return isMatch;
                });
            }
            else {
                filteredService.some(function (a) {
                    allFilters.therapistViewModels.some(function (b) {
                        if (a.id == b.serviceId && b.therapistId == res.id && filteredTherapist && filteredTherapist.includes(res.id)) {
                            isMatch = true;
                        }
                        return isMatch;
                    });
                    return isMatch;
                });
                let therapist: number[] = [];
                if (this.StaffDropDownFormArr.length > 0) {
                    for (let i = 0; i < this.StaffDropDownFormArr.length; i++) {
                        therapist.push(Number(this.StaffDropDownFormArr.controls[i].value.therapist));
                    }
                }
                if (!isMatch) {
                    isMatch = therapist.some(r => r == res.id); // do not remove already selected therapist
                }
            }
            return isMatch;
        });

        this.manageSelectedTherapists();


    }
    manageSelectedTherapists() {
        if (this.StaffDropDownFormArr.value) {
            let values = this.StaffDropDownFormArr.value.filter(x => Number(x.therapist) > 0 && !this.therapist.some(y => y.id === Number(x.therapist)));
            if (values && values.length > 0) {
                values.forEach((x) => { this.removeTherapistValue(x.therapist); });
            }

        }
    }
    slotWithinAvailbleTime(availableTimes: any, sDate: Date, eDate: Date): boolean {
        let isAvailable = false;
        if (availableTimes && availableTimes.length > 0) {
            for (let availableTimesItem of availableTimes) {
                let availFromTime = new Date(availableTimesItem.fromTime);
                let availEndTime = new Date(availableTimesItem.toTime);
                if (sDate.getTime() >= availFromTime.getTime() && eDate.getTime() <= availEndTime.getTime()) {
                    isAvailable = true;
                    break;
                }
            }
        }
        return isAvailable;
    }

    slotNotInBreakTime(breakTimes: any, sDate: Date, eDate: Date): boolean {
        let slotNotInOccupiedTime = true;
        if (breakTimes && breakTimes.length > 0) {
            for (let breakTimesItem of breakTimes) {
                let availFromTime = new Date(breakTimesItem.startTime).getTime();
                let availEndTime = new Date(breakTimesItem.endTime).getTime();
                if ((sDate.getTime() > availFromTime && sDate.getTime() < availEndTime) ||
                    (eDate.getTime() > availFromTime && eDate.getTime() < availEndTime) ||
                    (sDate.getTime() <= availFromTime && eDate.getTime() >= availEndTime)) {
                        slotNotInOccupiedTime = false;
                    break;
                }
            }
        }
        return slotNotInOccupiedTime;
    }

    slotWithinInBreakTime(breakTimes: any, sDate: Date): boolean {
        let betweenBreaktime = false;
        if (breakTimes && breakTimes.length > 0) {
            for (let breakItem of breakTimes) {
                let availFromTime = new Date(breakItem.startTime).getTime();
                let availEndTime = new Date(breakItem.endTime).getTime();
                if (sDate.getTime() >= availFromTime || sDate.getTime() <= availEndTime) {
                    betweenBreaktime = false;
                    break;
                }
            }
        }
        return betweenBreaktime;
    }

    CreateTempHold() {
        this.aptService.hasActiveTempHold = true;
        let _time = (this.apptService.resultNewAppointment.time ? this.apptService.resultNewAppointment.time : '').toString();
        let _duration = this.apptService.resultNewAppointment.duration && !isNaN(this.apptService.resultNewAppointment.duration) ? Number(this.apptService.resultNewAppointment.duration) : 0;
        let _endTime: any = '';
        let dt: any = this.apptService.resultNewAppointment.dateField ? this.utils.getDate(this.apptService.resultNewAppointment.dateField) : this.PropertyInfo.CurrentDate;
        let startTime: any = '';
        let apptDate: any;
        if (dt != '') {
            apptDate = this.utils.GetFormattedDate(dt);
            startTime = this.utils.convertDateFormat(this.utils.getDate(
                dt.getFullYear() +
                "-" +
                this.utils.getFullMonth(dt) +
                "-" +
                this.utils.getFullDate(dt) +
                "T" +
                this.localization.DeLocalizeTime(_time)
            )
            );
            if (_duration > 0) {
                _endTime = this.utils.AddMins(startTime, _duration);
            } else {
                _endTime = startTime;
            }
        }
        let _serviceGroupId = 0;
        let _service = this.apptService.resultNewAppointment.service ? this.apptService.resultNewAppointment.service : 0;
        let _location = this.apptService.resultNewAppointment.LocationId ? this.apptService.resultNewAppointment.LocationId : 0;
        let _comments = this.apptService.resultNewAppointment.comments ? this.apptService.resultNewAppointment.comments : '';
        let _status = AppointmentStatus.TEMP;

        let _breakDownTime = this.apptService.resultNewAppointment.breakDownTime && !isNaN(this.apptService.resultNewAppointment.breakDownTime) ? Number(this.apptService.resultNewAppointment.breakDownTime) : 0;

        let _setUpTime = this.apptService.resultNewAppointment.setupTime && !isNaN(this.apptService.resultNewAppointment.setupTime) ? Number(this.apptService.resultNewAppointment.setupTime) : 0;
        let _price = this.apptService.resultNewAppointment.price ? this.apptService.resultNewAppointment.price : 0;
        let _doNotMove = this.apptService.resultNewAppointment.doNotMove ? this.apptService.resultNewAppointment.doNotMove : false;
        let _requestStaff = this.apptService.resultNewAppointment.requestStaff ? this.apptService.resultNewAppointment.requestStaff : false;
        let _lockBooking = this.apptService.resultNewAppointment.isBookingLocked ? this.apptService.resultNewAppointment.isBookingLocked : false;
        let _isVIP = this.apptService.resultNewAppointment.isVip ? this.apptService.resultNewAppointment.isVip : false;
        let _guestType = this.apptService.resultNewAppointment.guestTypeId ? this.apptService.resultNewAppointment.guestTypeId : false;
        let _genderPreference = 0;
        let _checkOutComments = "";
        let _noShowComments="";
        let _noShow = false;
        let _packageId = 0;
        let _therapistId = [];

        if (this.apptService.resultNewAppointment.TherapistId != null && this.apptService.resultNewAppointment.TherapistId > 0) {
            _therapistId.push(Number(this.apptService.resultNewAppointment.TherapistId));
        }
        else {
            _therapistId = this.GetSelectedTherapistIds();
        }

        this.setServiceChargeGratuity(_price, _service);
        let tempHoldObj: TempHoldApp = {
            service: _service,
            location: _location,
            startTime: startTime,
            endTime: _endTime,
            serviceGroupId: _serviceGroupId,
            comments: _comments,
            status: _status,
            breakDownTime: _breakDownTime,
            duration: _duration,
            setUpTime: _setUpTime,
            price: this.localization.currencyToSQLFormat(_price),
            doNotMove: _doNotMove,
            requestStaff: _requestStaff,
            isBookingLocked: _lockBooking,
            isVip: _isVIP,
            genderPreference: _genderPreference,
            checkOutComments: _checkOutComments,
            noShowComments:_noShowComments,
            noShow: _noShow,
            packageId: _packageId,
            therapist: _therapistId,
            clientId: this.apptService.recordsArray && this.apptService.recordsArray.length > 0 ? this.apptService.recordsArray[0].clientDetail.id : 0,
            appointmentId: 0,
            cancelId: "",
            appointmentDate: apptDate,
            TempHoldId: '',
            TempHoldLinkId: 0,
            originalClientId: 0,
            multiGroupId: 0,
            ServiceCharge: this.apptService.resultNewAppointment.ServiceCharge,
            Gratuity: this.apptService.resultNewAppointment.Gratuity,
            Tax: 0,
            TotalAmount: this.apptService.resultNewAppointment.totalAmount,
            guestTypeId:0,
            discountType:""
        }
        this.apptService.tempHoldValues = tempHoldObj;
        this.appUtils.CreateTempHoldObject(tempHoldObj, this.successCallback.bind(this), this.utils.errorCallback.bind(this));

        // In case temp is getting recreated, it should created for all the clients selected in client screen.
        if (this.apptService.recreateTempAppointments && this.apptService.recordsArray && this.apptService.recordsArray.length > 1) {
            for (let index = 1; index < this.apptService.recordsArray.length; index++) {
                this.appUtils.CreateTempholdForClientSelected(this.apptService.recordsArray[index].clientDetail.id);
            }
            this.apptService.recreateTempAppointments = false;
        }
    }


    //Update temp hold for dropdown changes
    UpdateTempHold(aptDate?: any, aptTime?: any, serviceId?: any, lcoationId?: any, therapistIds?: any) {

        if (this.apptService.recreateTempAppointments &&
            !this.apptService.resultExistingAppointment &&
            !this.aptService.tempRecordCreated || !this.apptService.TempHoldArr) {
            this.CreateTempHold();
            return;
        }
        let _time = (aptTime ? aptTime : this.FormGrp.controls['time'].value).toString();

        let _duration = this.serviceDetails.duration && !isNaN(this.serviceDetails.duration) ? Number(this.serviceDetails.duration) : 0;
        let _breakDownTime = this.serviceDetails.breakDownTime && !isNaN(this.serviceDetails.breakDownTime) ? Number(this.serviceDetails.breakDownTime) : 0;
        let _setUpTime = this.serviceDetails.setupTime && !isNaN(this.serviceDetails.setupTime) ? Number(this.serviceDetails.setupTime) : 0;
        const tempServiceId = (this.FormGrp.controls['service'].value ? this.FormGrp.controls['service'].value : 0);
        let _service = serviceId ? serviceId : tempServiceId;
        let matchingService: any;
        if (_service > 0 && this.GlobalAllFilters) {
            let filters = _.cloneDeep(this.GlobalAllFilters);
            matchingService = filters.serviceViewModels.filter(x => x.id == _service)[0];
        }
        if (serviceId && matchingService) {
            _duration = Number(matchingService.duration);
            _setUpTime = Number(matchingService.setupTime);
            _breakDownTime = Number(matchingService.breakDownTime);
        }
        else {
            if (matchingService) {

                if (_duration == 0) {
                    _duration = Number(matchingService.duration);
                }
                if (_setUpTime == 0) {
                    _setUpTime = Number(matchingService.setupTime);
                }
                if (_breakDownTime == 0) {
                    _breakDownTime = Number(matchingService.breakDownTime);
                }
            }

        }

        let _endTime: any;
        const tempDt = (this.FormGrp.controls['dateField'].value ? this.FormGrp.controls['dateField'].value : '');
        let dt: any = aptDate ? aptDate : tempDt;
        let apptDate: any;
        let startTime: any;
        if (dt != '') {
            startTime = this.utils.convertDateFormat(this.utils.getDate(
                dt.getFullYear() +
                "-" +
                this.utils.getFullMonth(dt) +
                "-" +
                this.utils.getFullDate(dt) +
                "T" +
                this.localization.DeLocalizeTime(_time)
            )
            );
            if (_duration > 0) {
                _endTime = this.utils.convertDateFormat(this.utils.AddMins(startTime, _duration));
            } else {
                _endTime = startTime;
            }
        }
        else {
            dt = this.PropertyInfo.CurrentDate;
            startTime = this.utils.convertDateFormat(this.utils.getDate(
                dt.getFullYear() +
                "-" +
                this.utils.getFullMonth(dt) +
                "-" +
                this.utils.getFullDate(dt) +
                "T" +
                this.localization.DeLocalizeTime(_time)
            )
            );
            if (_duration > 0) {
                _endTime = this.utils.convertDateFormat(this.utils.AddMins(startTime, _duration));
            } else {
                _endTime = startTime;
            }
        }
        let _serviceGroupId = 0;

        let _location = this.FormGrp.controls['location'].value ? this.FormGrp.controls['location'].value : 0;
        let _comments = this.FormGrp.controls['comments'].value ? this.FormGrp.controls['comments'].value : '';
        let _status = AppointmentStatus.TEMP;

        let _price = this.FormGrp.controls['Price'].value ? this.FormGrp.controls['Price'].value : 0;
        let _doNotMove = this.FormGrp.controls['DoNotMove'].value ? this.FormGrp.controls['DoNotMove'].value : false;
        let _requestStaff = this.FormGrp.controls['RequestStaff'].value ? this.FormGrp.controls['RequestStaff'].value : false;
        let _lockBooking = this.FormGrp.controls['IsBookingLocked'].value ? this.FormGrp.controls['IsBookingLocked'].value : false;
        let _isVIP = this.FormGrp.controls['DoNotMove'].value ? this.FormGrp.controls['DoNotMove'].value : false;        
        let _genderPreference = 0;
        let _checkOutComments = "";
        let _noShowComments= "";
        let _noShow = false;
        let _packageId = 0;
        let _therapistId = [];
        if (this.FormGrp.controls['StaffDropDownFormArr'].value != null && this.FormGrp.controls['StaffDropDownFormArr'].value.length > 0) {
            for (let staffArrItem of this.FormGrp.controls['StaffDropDownFormArr'].value) {
                if (staffArrItem.therapist > 0) {
                    _therapistId.push(Number(staffArrItem.therapist));
                }
            }
        }

        this.setServiceChargeGratuity(_price, _service);

        for (let apptServiceArrItem of this.apptService.TempHoldArr) {
            let _tempholdlinkId: number = this.apptService.resultExistingAppointment ? apptServiceArrItem.appointmentId : 0;
            apptServiceArrItem.service = _service;
            apptServiceArrItem.location = _location;
            apptServiceArrItem.startTime = startTime;
            apptServiceArrItem.endTime = _endTime;
            apptServiceArrItem.serviceGroupId = _serviceGroupId;
            apptServiceArrItem.comments = _comments;
            apptServiceArrItem.status = _status;
            apptServiceArrItem.breakDownTime = _breakDownTime;
            apptServiceArrItem.duration = _duration;
            apptServiceArrItem.setUpTime = _setUpTime;
            apptServiceArrItem.price = _price;
            apptServiceArrItem.doNotMove = _doNotMove;
            apptServiceArrItem.requestStaff = _requestStaff;
            apptServiceArrItem.isBookingLocked = _lockBooking;
            apptServiceArrItem.isVip = _isVIP;
            apptServiceArrItem.genderPreference = _genderPreference;
            apptServiceArrItem.checkOutComments = _checkOutComments;
            apptServiceArrItem.noShowComments = _noShowComments;
            apptServiceArrItem.noShow = _noShow;
            apptServiceArrItem.packageId = _packageId;
            apptServiceArrItem.therapist = _therapistId;
            apptServiceArrItem.cancelId = "";
            apptServiceArrItem.appointmentDate = apptDate;
            apptServiceArrItem.TempHoldId = '';
            apptServiceArrItem.TempHoldLinkId = _tempholdlinkId;
            apptServiceArrItem.multiGroupId = 0;
            apptServiceArrItem.ServiceCharge = this.apptService.resultNewAppointment.ServiceCharge;
            apptServiceArrItem.Gratuity = this.apptService.resultNewAppointment.Gratuity;
            apptServiceArrItem.Tax = 0;
            apptServiceArrItem.TotalAmount = this.apptService.resultNewAppointment.totalAmount;
        }

        let tempValues = _.cloneDeep(this.apptService.tempHoldValues);
        this.apptService.tempHoldValues = _.cloneDeep(this.apptService.TempHoldArr[0]);
        this.apptService.tempHoldValues.clientId = tempValues.clientId;
        this.apptService.tempHoldValues.TempHoldId = tempValues.TempHoldId;
        this.apptService.tempHoldValues.TempHoldLinkId = tempValues.TempHoldLinkId;
        this.apptService.tempHoldValues.price = tempValues.price;
        this.apptService.tempHoldValues.therapist = tempValues.therapist;
        this.apptService.tempHoldValues.appointmentId = tempValues.appointmentId;

        this.showSchedulingAssistant = false;
        this.appUtils.UpdateTempHoldObject(this.apptService.TempHoldArr);
        setTimeout(() => {
            this.showSchedulingAssistant = true;
        }, 500);
    }

    convertTime(ts) {
        let dateCheckBool;
        let datevalue: any = this.utils.getDate(ts);
        let currentdate = this.PropertyInfo.CurrentDate;
        let currentDateTime = this.utils.getDate(this.localization.LocalizeDateTimeFormatSecondsDDMMMYYYYheader(currentdate));
        if (datevalue.getTime() <= currentDateTime.getTime()) {
            dateCheckBool = true;
        }
        return dateCheckBool;
    }

    GetClientItinerary() {
        let date: Date = this.FormGrp.get("dateField").value;
        let time: string = this.serviceDetails.time ? this.serviceDetails.time : "";
        let dateTime = date ? this.utils.convertDateFormat(this.utils.getDate(
            date.getFullYear() +
            "-" +
            this.utils.getFullMonth(date) +
            "-" +
            this.utils.getFullDate(date) +
            "T" +
            this.localization.DeLocalizeTime(time))
        ) : "";
        let clientId = this.apptService.clientGuestMap && this.apptService.clientGuestMap.length > 0 ?
            this.apptService.clientGuestMap.map(y => y.GuestId)[0] : 0
        if (dateTime != "" && clientId && clientId != global.DefaultGUID) {
            this.http.CallApiWithCallback({
                host: global.Host.schedule,
                success: this.successCallback.bind(this),
                error: this.utils.errorCallback.bind(this),
                callDesc: "GetItineraryForAppointment",
                method: HttpMethod.Get,
                uriParams: { clientId: clientId, dateTime: dateTime },
                showError: true,
                extraParams: []
            });
        }

    }

    GetConflictingActivities() {
        if (this.apptService.recordsArray.length) {
            for (let apptServiceRecordItem of this.apptService.recordsArray) {
                if (!apptServiceRecordItem.clientDetail.bypassClientScheduling) {
                    this.apptService.GetConflictingActivities(apptServiceRecordItem.clientDetail.id);
                }
                else {
                    let index = this.apptService.ClientActivities.map(x => x.ClientId).indexOf(apptServiceRecordItem.clientDetail.id);
                    if (index > -1) {
                        this.apptService.ClientActivities.splice(index);
                    }
                }
            }
        }

    }

    setServiceChargeGratuity(price: string, serviceId: number) {
        if (this.SelectedService == 0)
            this.SelectedService = Number(serviceId);
        if (this.SelectedService != Number(serviceId))
            this.apptService.serviceChanged = true;
        if (!price || !serviceId || !this.apptService.serviceChanged || serviceId == 0) return;
        let serviceCharge: number = 0;
        let gratuity: number = 0;
        let totalAmount: number = 0;
        let serviceChargePercent = 0;
        let gratuityPercent = 0;
        if (this.GlobalService && this.GlobalService.length > 0) {
            let service = this.GlobalService.filter(x => { return x.id == serviceId });
            const tempService = service[0].serviceChargePercent > 0 ? (this.localization.currencyToSQLFormat(price) * service[0].serviceChargePercent / 100) : service[0].serviceChargeAmount;
            serviceCharge = service.length > 0 && service[0].isAutoServiceCharge ? (tempService) : 0;
            const tempGratuity = service[0].gratuityPercent > 0 ? (this.localization.currencyToSQLFormat(price) * service[0].gratuityPercent / 100) : service[0].gratuityAmount;
            gratuity = service.length > 0 && service[0].isAutoGratuity ? (tempGratuity) : 0;
            totalAmount = this.localization.currencyToSQLFormat(price) + serviceCharge + gratuity;
            serviceChargePercent = service.length > 0 ? service[0].serviceChargePercent : 0;
            gratuityPercent = service.length > 0 ? service[0].gratuityPercent : 0;
        }

        this.apptService.resultNewAppointment.ServiceCharge = serviceCharge;
        this.apptService.resultNewAppointment.Gratuity = gratuity;
        this.apptService.resultNewAppointment.totalAmount = totalAmount;
        this.apptService.resultNewAppointment.serviceChargePercent = serviceChargePercent;
        this.apptService.resultNewAppointment.gratuityPercent = gratuityPercent;
    }
    noShowIcon: boolean;
    leftArrowDisabled = false;
    rightArrowDisabled = false;

    setSelectedTimeSlot() {
        this.totalbtnWidth = this.btnView.nativeElement.offsetWidth;
        this.totalScrollWidth = this.btnView.nativeElement.scrollWidth;
        this.availableBtnSpace = Math.floor(this.totalbtnWidth / 85);
        let ff = Math.floor(this.btnView.nativeElement.offsetWidth / this.availableBtnSpace);
        let navChildren = this.btnView.nativeElement.querySelectorAll('.time-btn');
        navChildren.forEach(element => {
            element.style.width = ff + 'px';
        });
        let curtime = "";
        if (this.serviceDetails.time && this.serviceDetails.time != "") {
            curtime = _.cloneDeep(this.serviceDetails.time);
        } else {
            const serviceTimeCheck = this.serviceTime.find(x => x == this.curtime);
            if (!serviceTimeCheck) {
                this.curtime = this.serviceTime[0];
            }
            curtime = this.curtime;
        }
        let elms = document.getElementById("time_" + curtime);
        if (elms) {
            let scrolltoPos = elms.offsetLeft;
            this.btnView.nativeElement.scrollTo(scrolltoPos - 10, 0)
        }
    }

    viewPrevMenu() {
        this.rightArrowDisabled = false;
        let element = this.btnView.nativeElement;
        let scrollValue = element.scrollLeft - element.offsetWidth;
        element.scrollLeft = scrollValue;

        if (scrollValue <= 0) {
            this.leftArrowDisabled = true;
        }
    }

    viewNextMenu() {

        this.leftArrowDisabled = false;
        this.rightArrowDisabled = true;
        let element = this.btnView.nativeElement;
        let scrollValue = element.scrollLeft + (this.availableBtnSpace * 75);
        let scrollWidth = element.scrollWidth;
        let offsetWidth = element.offsetWidth;
        element.scrollLeft = scrollValue;

        if ((scrollWidth - (scrollValue + offsetWidth)) > 0) {
            this.rightArrowDisabled = false;
        }

    }


    setPriceTypeServiceChargeGratuity(price: string, serviceId: number) {
        if (this.SelectedService == 0)
            this.SelectedService = Number(serviceId);
        if (this.SelectedService !== serviceId)
            this.apptService.serviceChanged = true;
        if (!price || !serviceId) return;
        let serviceCharge: number = 0;
        let gratuity: number = 0;
        let totalAmount: number = 0;
        if (this.GlobalService && this.GlobalService.length > 0) {
            let service = this.GlobalService.filter(x => { return x.id == serviceId });
            const temp = service[0].serviceChargePercent > 0 ? (this.localization.currencyToSQLFormat(price) * service[0].serviceChargePercent / 100) : service[0].serviceChargeAmount;
            serviceCharge = service.length > 0 && service[0].isAutoServiceCharge ? (temp) : 0;
            const tempGra = service[0].gratuityPercent > 0 ? (this.localization.currencyToSQLFormat(price) * service[0].gratuityPercent / 100) : service[0].gratuityAmount;
            gratuity = service.length > 0 && service[0].isAutoGratuity ? (tempGra) : 0;
            totalAmount = this.localization.currencyToSQLFormat(price) + serviceCharge + gratuity;
        }
        this.apptService.resultNewAppointment.ServiceCharge = serviceCharge;
        this.apptService.resultNewAppointment.Gratuity = gratuity;
        this.apptService.resultNewAppointment.totalAmount = totalAmount;
    }

    GetSelectedTherapistIds(): number[] {
        let therapistId: number[] = [];
        if (this.FormGrp.controls['StaffDropDownFormArr'].value != null && this.FormGrp.controls['StaffDropDownFormArr'].value.length > 0) {
            for (let StaffDropDownItem of this.FormGrp.controls['StaffDropDownFormArr'].value) {
                if (StaffDropDownItem.therapist > 0) {
                    therapistId.push(Number(StaffDropDownItem.therapist));
                }
            }
        }
        return therapistId;
    }

    callSchedulingAssistant() {
        let gridInterval: number = this.aptService.appointmentConfiguration.length != 0 ? this.aptService.appointmentConfiguration[0].APPOINMENT_GRIDTIMEINTERVAL : 15;
        let dateField = this.FormGrp.controls.dateField.value ? this.FormGrp.controls.dateField.value : this.localization.getDate(this.PropertyInfo.CurrentDate);
        let operatingHours = this.aptService.appointmentConfiguration.length != 0 ? JSON.parse(this.aptService.appointmentConfiguration[0].APPOINTMENT_OPERATING_HOURS) : '';
        let startTime = this.aptService.appointmentConfiguration.length != 0 ? this.localization.ToDate(this.utils.getDayTimeForTheDate(JSON.parse(this.aptService.appointmentConfiguration[0].APPOINTMENT_OPERATING_HOURS),dateField)['StartTime'], "HH:mm") : this.localization.ToDate("00:00", "HH:mm");
        let endTime = this.aptService.appointmentConfiguration.length != 0 ? this.localization.ToDate(this.utils.getDayTimeForTheDate(JSON.parse(this.aptService.appointmentConfiguration[0].APPOINTMENT_OPERATING_HOURS),dateField)['EndTime'], "HH:mm") : this.localization.ToDate("23:59", "HH:mm");
        let sTime = this.localization.combineDateAndTime(dateField, startTime);
        let eTime = this.localization.combineDateAndTime(dateField, endTime);
        this.serviceTime = this.localization.generateTimeRange(sTime, eTime, gridInterval);
        let appointmentId = this.apptService.resultExistingAppointment ? this.apptService.resultExistingAppointment.appointmentDetail.id : 0;
        this.appointmentdetailsService.openDialog(this.ServiceGroups, this.ServiceTypeArr, this.GlobalService, this.FormGrp.controls.service.value,
            this.GlobalTherapist, this.GlobalLocation, dateField,
            this.getTempholdIds(), sTime, eTime, appointmentId, this.handleSchedulingAssistantCloseEvent.bind(this), operatingHours);
    }

    handleSchedulingAssistantCloseEvent(filterResponse: any, selectedData: SelectedData, occupiedLocation: any) {
        this.GlobalAllFilters = filterResponse;
        this.locationoccupiedtime = occupiedLocation;
        let currentAppointmentDate: Date = this.utils.getDate(this.getAppointmentDateTime());
        // setting date
        let startTime = this.utils.getDate(selectedData.slotDateTime);
        this.FormGrp.controls["dateField"].setValue(startTime, { emitEvent: false });
        this.serviceDetails.dateField = startTime;
        // setting time
        this.setTimeValue(startTime);

        // setting location
        this.FormGrp.controls['location'].setValue(selectedData.locationId.toString(), { emitEvent: false });
        this.serviceDetails.location = selectedData.locationId;
        this.serviceDetails.LocationId = selectedData.locationId;
        //setting service
        let isTempUpdated = this.setServiceId(selectedData.serviceId);
        // setting therapist
        if (this.setTherapistId(selectedData.therapistId)) {
            isTempUpdated = true;
        }

        if (currentAppointmentDate.getTime() != this.utils.getDate(this.getAppointmentDateTime()).getTime()) {
            this.GetClientItinerary();
            this.GetConflictingActivities();
            this.refreshPMSStayDetail();
        }
        if (!isTempUpdated) {
            this.UpdateTempHold();
        }
        this.handleAppointmentFilterResponse(filterResponse);
        this.apptService.appoinmentDetailFormArray.markAsDirty();
    }

    private setTimeValue(startTime: Date) {
        let curtime = "";
        const serviceTimeCheck = this.serviceTime.find(x => x == this.localization.LocalizeTime(startTime));
        this.TimeArr = !serviceTimeCheck ? [this.serviceTime[0]] : [this.localization.LocalizeTime(startTime)];
        curtime = _.cloneDeep(this.TimeArr);
        this.filters.Time = _.cloneDeep(this.TimeArr);
        this.FormGrp.controls["time"].setValue(curtime, { emitEvent: false });
        this.serviceDetails.time = curtime[0];

            this.setSelectedTimeSlot();

        this.OnElementValueChange({ value: startTime }, "dateField");
    }

    private setServiceId(serviceId: number): boolean {
        let isServiceUpdated = false;
        if (serviceId != this.FormGrp.controls["service"].value) {
            this.FormGrp.controls["service"].setValue(serviceId.toString(), { emitEvent: false });

            this.setServiceTime(serviceId);

            let service = this.clientCountValidation(serviceId);
            this.AdjustTherapistCountBasedOnServiceChange(service);
            this.filterClick({ value: serviceId }, 'service');
            isServiceUpdated = true;
            this.setServiceGroupId();
        }
        return isServiceUpdated;
    }




    serviceErrorMessage() {
        if (!this.GlobalService || this.GlobalService.length === 0) {
            return this.captions.NoServiceAvailableForTime;
        }

        let availableLocations = [];
        let availableTerapists = [];
        let locationId = 0;
        let therapists = [];

        if (this.FormGrp.controls["location"].value) {
            locationId = Number(this.FormGrp.controls["location"].value);

            if (locationId > 0) {
                availableLocations = this.GlobalAllFilters.locationViewModels.filter(x => x.locationId == 0 || x.locationId == locationId)
                if (availableLocations && availableLocations.length === 0) {
                    return this.captions.NoServiceAvailableForLocation;
                }
            }
        }

        if (this.StaffDropDownFormArr.value && this.StaffDropDownFormArr.value.length > 0) {
            therapists = this.StaffDropDownFormArr.value.map(x => Number(x.therapist));
            therapists = therapists.filter(x => x > 0);
            if (therapists.find(x => x > 0)) {
                availableTerapists = this.GlobalAllFilters.therapistViewModels.filter(x => therapists.includes(x.therapistId));
                if (availableTerapists && availableTerapists.length === 0) {
                    return this.captions.NoServiceAvailableForTherapist;
                }
            }
        }

        if (availableLocations.length > 0 || availableTerapists.length > 0) {
            if (therapists.length > 0 && locationId > 0) {
                return this.captions.NoServiceAvailableForTherapistAndLocation;
            }
            else if (therapists.length > 0) {
                return this.captions.NoServiceAvailableForAvailableLocation;
            }
            else if (locationId > 0) {
                return this.captions.NoServiceAvailableForAvailableTherapist;
            }
            else {
                return this.captions.NoServiceAvailableForAvailableTherapistAndLocation;
            }
        }


    }



    therapistErrorMessage() {
        if (!this.GlobalTherapist || this.GlobalTherapist.length === 0) {
            return this.captions.NoTherapistAvailableForTime;
        }
        let availableServices = [];
        let serviceId = 0;
        if (this.FormGrp.controls["service"].value) {
            serviceId = Number(this.FormGrp.controls["service"].value);

            if (serviceId > 0) {
                availableServices = this.GlobalAllFilters.therapistViewModels.filter(x => x.serviceId == serviceId)
                if (availableServices && availableServices.length === 0) {
                    return this.captions.NoTherapistAvailableForService;
                }
            }
        }
        let availableLocations = [];
        let locationId = 0;
        if (this.FormGrp.controls["location"].value) {
            locationId = Number(this.FormGrp.controls["location"].value);

            if (locationId > 0) {
                availableLocations = this.GlobalAllFilters.therapistLocationModels.filter(x => x.locationId == 0 || x.locationId == locationId)
                if (availableLocations && availableLocations.length === 0) {
                    return this.captions.NoTherapistAvailableForLocation;
                }
            }
        }

        if (serviceId == 0) {
            return this.captions.NoTherapistAvailableForAvailableServiceAndLocation;
        }
        else if (availableServices.length > 0 || availableLocations.length > 0) {
            if (locationId > 0 && serviceId > 0) {
                return this.captions.NoTherapistsAvailableForServiceAndLocation;
            }
            else if (serviceId > 0) {
                return this.captions.NoTherapistAvailableForAvailableLocation;
            }
        }
        return this.captions.NoTherapistAvailableForTime;

    }


    locationErrorMessage() {
        if (!this.GlobalLocation || this.GlobalLocation.length === 0) {
            return this.captions.NoLocationAvailableForTime;
        }


        if (this.FormGrp.controls["location"].value) {
            let locationId = Number(this.FormGrp.controls["location"].value);
            const selectedLocation = this.location?.find(x => x.id === locationId);
            if (!selectedLocation) {
                this.FormGrp.controls["location"].setValue("");
                this.validateForm();
            }
        }

        let availableServices = [];
        let serviceId = 0;
        if (this.FormGrp.controls["service"].value) {
            serviceId = Number(this.FormGrp.controls["service"].value);

            if (serviceId > 0) {
                availableServices = this.GlobalAllFilters.locationViewModels.filter(x => x.serviceId == serviceId)
                if (availableServices && availableServices.length === 0) {
                    return this.captions.NoLocationAvailableForService;
                }
            }
        }

        let availableTerapists = [];
        let therapists = [];
        if (this.StaffDropDownFormArr.value && this.StaffDropDownFormArr.value.length > 0) {
            therapists = this.StaffDropDownFormArr.value.map(x => Number(x.therapist));
            therapists = therapists.filter(x => x > 0);
            if (therapists.find(x => x > 0)) {
                availableTerapists = this.GlobalAllFilters.therapistLocationModels.filter(x => therapists.includes(x.therapistId));
                if (availableTerapists && availableTerapists.length === 0) {
                    return this.captions.NoLocationAvailableForTherapist;
                }
            }
        }

        if (availableServices.length > 0 || availableTerapists.length > 0) {
            if (therapists.length > 0 && serviceId > 0) {
                return this.captions.NoLocationAvailableForTherapistAndService;
            }
            else if (therapists.length > 0) {
                return this.captions.NoLocationAvailableForAvailableService;
            }
            else if (serviceId > 0) {
                return this.captions.NoLocationAvailableForAvailableTherapist;
            }
            else {
                return this.captions.NoLocationAvailableForAvailableTherapistAndService;
            }
        }


    }



    private setTherapistId(therapistId: number): boolean {
        let isTherapistUpdated = false;
        this.StaffDropDownFormArr = this.FormGrp.get('StaffDropDownFormArr') as UntypedFormArray;
        if (this.StaffDropDownFormArr.controls.length > 0) {
            let therapist: number[] = [];
            if (this.StaffDropDownFormArr.length > 0) {
                for (let i = 0; i < this.StaffDropDownFormArr.length; i++) {
                    therapist.push(Number(this.StaffDropDownFormArr.controls[i].value.therapist));
                }
            }
            if (therapist[therapist.length - 1] != therapistId) {
                this.StaffDropDownFormArr.at(this.StaffDropDownFormArr.controls.length - 1).setValue({ therapist: therapistId.toString() }, { emitEvent: false });
                this.serviceDetails.therapist = this.serviceDetails.TherapistId = therapistId;
                this.staffSelectionValueChanged();
                this.filterClick({ value: therapistId }, 'therapist', therapist.length - 1);
                isTherapistUpdated = true;
            }
        }
        return isTherapistUpdated;
    }

    private _filter(value: string) {
        if(value){
          const filterValue = value.toLowerCase();
          if(this.showIdDescription) {
            return this.services = this.initialServiceList.filter(x => (x.code.toLowerCase().includes(filterValue)) || (x.description.toString().toLowerCase().includes(filterValue)))
          } else {
            return this.services = this.initialServiceList.filter(x => (x.description.toString().toLowerCase().includes(filterValue)))
          }
          
        } else {
          return this.services = this.initialServiceList
        }
    
      }
      filterOptions(event) {
        this._filter(event.target.value);
      }

      openedChange(opened: boolean) {
        this.myInput.nativeElement.focus()
          if (!opened) {
          this.inputSearch = ''
          this._filter("");
    
        }
      }

      changeDropdownView(eve) {
        if(eve) {
            this.showIdDescription = true
            sessionStorage.setItem('showCodeforService',"true");
        } else {
            this.showIdDescription = false
            sessionStorage.setItem('showCodeforService',"false");
        }
      }

    removeChangedTherapistAddons(changedTherapist) {
        this.apptService.recordsArray.forEach(x => {
            if (x.clientDetail.selectedAddOns != undefined)
                x.clientDetail.selectedAddOns = x.clientDetail.selectedAddOns?.filter(y => y.therapistId != changedTherapist);
            else
                x.clientDetail['selectedAddOns'] = [];
        });
    }
}
