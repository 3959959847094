export const recurringTypeConst = {
    daily: 1,
    weekly: 2,
    monthly: 3,
    yearly: 4
};

export enum YearlyType {
    onDay = 0,
    onThe = 1
}
export enum monthlyType {
    day = 1,
    lastDay,
    onThe
}
export enum DayType {
    Every = 1,
    EveryWeekDay
}

export enum DiaryOccurrenceType {
    Every = 1,
    EveryWeekDay,
    day,
    lastDay,
    onThe,
    onDay
}

export interface RecurringType {
    description: string;
    disabled?: boolean;
    id: number;
    name: string;
}


export interface RecurringDetails {
    recurringType: SchedulerInterval;
    dayType?: DayType;
    monthlyType?: monthlyType;
    every?: string | number;
    date?: string | number;
    month?: string | number;
    specificDays?: boolean[];
    onday?: number;
    yearlyType?: YearlyType;
    onEvery ?: number;
    onMonth?: number;
}

export enum SchedulerInterval {
    daily = 1,
    //everyWeekDay,
    weekly,
    monthly,
    yearly
}