import { Component, Inject, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { MatTabChangeEvent } from '@angular/material/tabs';
import { RetailLocalization } from '../common/localization/retail-localization';
import { HttpServiceCall, HttpMethod } from '../shared/service/http-call.service';
import { RetailUtilities } from '../shared/utilities/retail-utilities';
import { Host } from '../shared/globalsContant';
import { SubPropertyModel } from '../retail.modals';
import { AddTransferDataService } from './add-transfer-inventory-popoup-data.services';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { RetailScreen } from '../inventory-staging/inventory-staging.modal';
@Component({
  selector: 'app-add-transfer-inventory-popup',
  templateUrl: './add-transfer-inventory-popup.component.html',
  styleUrls: ['./add-transfer-inventory-popup.component.scss'],
  providers:[AddTransferDataService],
  encapsulation: ViewEncapsulation.None
})
export class AddTransferInventoryPopupComponent implements OnInit {
  selectedTabIndex: number = 0;
  selectedIndex: number = 0;
  captions: any;
  outlets: { "id": number; "name": string; }[] = [];
  from : string;
  selectedRow: any[]=[];
  screen = RetailScreen
 
  constructor(public _Localization:RetailLocalization,private http: HttpServiceCall , private utils: RetailUtilities,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) {
    this.from = this.data.from;
    this.selectedRow = this.data.datarecord.selectedRow;
    this.selectedTabIndex = this.from == RetailScreen.inventoryStaging && this.selectedRow.length == 1 ? 1 : 0;
   }

  ngOnInit() {
    this.captions = this._Localization.captions.retailsetup;
    this.GetPropertyOutlet();
  }

  handleSelectedTabChange(event: MatTabChangeEvent): void {
    this.selectedTabIndex = event.index;
    this.selectedIndex = event.index;
  }

  async GetPropertyOutlet() {
    await this.GetOutletsByPropertyId();
  }


  async GetOutletsByPropertyId() {
    let result = await this.http.CallApiAsync<SubPropertyModel[]>({
      host: Host.retailManagement,
      callDesc: "GetOutletsByProperty",
      method: HttpMethod.Get,
      uriParams: { PropertyId: Number(this._Localization.GetPropertyInfo('PropertyId')) }
    });
    let outlets: SubPropertyModel[] = result.result ? result.result : [];
    outlets = outlets.filter(x => x.isActive);
    this.outlets = outlets.map(x => { return { id: x.subPropertyID, name: x.subPropertyName } });
    if(this.data.from == RetailScreen.inventoryStaging){
      this.data.datarecord.fromOutlet = this.outlets.find(x=>x.id == this.data.datarecord.fromOutletId)?.id ?? 0;
      this.outlets = [...this.outlets];
    }
  }

}
