import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import * as _ from 'lodash'; // STORAGE THE BACK ARRAY
import { RetailService } from '../../../retail.service';
import { ButtonOptions, ButtonType, DefaultGUID, MiscellaneousSwitch, Product, ReservationStatus, RetailTransactions } from '../../../shared/globalsContant';
import { CommonVariablesService } from '../../../shared/service/common-variables.service';
import { RedirectToModules, RetailUtilities } from '../../../shared/utilities/retail-utilities';
import { ShopBussinessService } from '../../shop-business.service';
import { RetailTransactionService } from '../../view-categories/retail-transactions/retail.transactions.service';
import { Location } from '@angular/common';
import { RetailLocalization } from 'src/app/retail/common/localization/retail-localization';
import { ClientCreditCardInfo, PayeeInfo, PaymentMethods } from 'src/app/retail/shared/business/shared.modals';
import { RetailDataAwaiters } from 'src/app/retail/shared/events/awaiters/retail.data.awaiters';
import { MemberBusinessService } from 'src/app/retail/shared/business/Member-business.service';
import { RetailPropertyInformation } from 'src/app/retail/common/services/retail-property-information.service';
import { GuestStayDetail, PaymentMethod } from 'src/app/retail/shared/service/payment/payment-model';
import { DepositEventModel } from 'src/app/retail/shared/events/event.model'; 
import { RouteGuardService } from 'src/app/retail/shared/service/route-gaurd.service';
import { RetailValidationService } from 'src/app/retail/shared/retail.validation.service';
import { RetailFeatureFlagInformationService } from 'src/app/retail/shared/service/retail.feature.flag.information.service';
import { EmailReceiptComponent } from '../../email-receipt/email-receipt.component';
import { MatDialog } from '@angular/material/dialog';
import { takeUntil } from 'rxjs/operators';
import { ReplaySubject } from 'rxjs';
import { RetailSharedVariableService } from 'src/app/retail/shared/retail.shared.variable.service';
import { RetailItemType } from 'src/app/retail/retail.modals';


@Component({
  selector: 'app-shop-item-details',
  templateUrl: './shop-item-details.component.html',
  styleUrls: ['./shop-item-details.component.scss']
})
export class ShopItemDetailsComponent implements OnInit, OnDestroy {
  ticketNumber: any;
  //Change for 44290
  public beforeClinet: boolean = true;
  currentProductId: number;
  ProductSpaId: Product;
  ProductSNCId: Product;
  public payeeInfo: PayeeInfo;
  clientInfo: PayeeInfo;
  canEnableGuestEditOption = false;
  AddGuest: string = '';
  selectedClientId: number = 0;
  selectedBookingId: string | number;
  roomDetailsArray: Array<GuestStayDetail>;
  depositArray: DepositEventModel[] = [];
  CardOnFileforSelectedGuest: ClientCreditCardInfo;
  paymentMethods: Array<PaymentMethod> = [];
  paymentMethodsClone: Array<PaymentMethod> = [];
  initalSelectedItems:any;
  surcharge = 0;
  hideGuestSearchforBeo:boolean=false;
  destroyed$: ReplaySubject<boolean> = new ReplaySubject(1);
  selectedDataForEmailReceipt: any = null;
  clientTypes : any[];

  get IsAccurualEnabled() {
    return this._featureInfo.AllowAccrualPoints && this.payeeInfo?.patronId
  }
  //EOC for 44290

  constructor(public router: Router, 
    public _ss: CommonVariablesService,
    private transactionService: RetailTransactionService,
    public _ams: RetailService,
		public _retailService: RetailSharedVariableService,
    private _sbs: ShopBussinessService,    
    private _utils: RetailUtilities,
    private _location: Location,
    public localization: RetailLocalization,
    public _memberService: MemberBusinessService,
    public PropertyInfo: RetailPropertyInformation,
    public routeGuardService:RouteGuardService,
    public utils: RetailUtilities,
    private retailValidationService: RetailValidationService,
    private _featureInfo: RetailFeatureFlagInformationService,
    public dialog: MatDialog,
  ) {
    if (this.PropertyInfo.IsAcesMembershipEnabled) {
      this.clientTypes = [
        { id: 1, type: this.localization.captions.shop.Member },
        { id: 2, type: this.localization.captions.shop.Guest }
      ];
    }else{
      this.clientTypes = [
        { id: 2, type: this.localization.captions.shop.Guest }
      ];
    }
  }
  get isEmailOnDemandProduct():boolean 
	{
		return (this.currentProductId == Product.SPA || this.currentProductId == Product.GOLF || this.currentProductId == Product.RETAIL)
	}

  async ngOnInit() {
    this.initalSelectedItems = _.cloneDeep(this._ss.selectedProducts);
    this._ss.returnWithticketItems = _.cloneDeep(this._ss.selectedProducts);
    sessionStorage.setItem('isChangesMade', 'true');
    
    let miscSetting = this._ss.GetMiscConfig();
    
    // Remove already returned items from transaction
    if (this._ss.returnWithticketItems && this._ss.returnWithticketItems.length > 0) {
      this._ss.returnWithticketItems = this._ss.returnWithticketItems.filter(r => !r.isReturn);
    }
    this.ticketNumber = this._ss.selectedRetailItem && this._ss.selectedRetailItem.ticketNumber ? this._ss.selectedRetailItem.ticketNumber : '';
    if (!this._ss.selectedRetailItem) {
      this.GoBack("RTID");
    }
    //Change for 44290
    this.GetPayeeInfo(this._ss.selectedPayeeId);
    this.currentProductId = Number(this.utils.GetPropertyInfo('ProductId'));
    this.ProductSNCId = Product.SNC;
    //EOC for 44290
    if (this._ss.isFromSncBeo || (this._ss.returnWithticketItems && this._ss.returnWithticketItems.length > 0 && this.currentProductId == this.ProductSNCId && this._ss.selectedProducts.some((x) => x.ItemType == RetailItemType.RevenueItem))) {
      this.hideGuestSearchforBeo = true;
    }
    else {
      this.hideGuestSearchforBeo = false;
    }
    if (this._ss.selectedPayeeId <= 0 && this._ss.memberCardNumber == "0") {
      let DefaultCategorySwitch = (await (miscSetting)).find(x => x.switch == MiscellaneousSwitch.DEFAULT_GUEST_CATEGORY_IN_SHOP);
      if (this.PropertyInfo.IsAcesMembershipEnabled && Number(DefaultCategorySwitch.value) == 1) {
        this._ams.clientSelect = 1;
      } else {
        this._ams.clientSelect = 2;
      }
    }
  }

  GoBack(frmSec) {
    this._ams.paymentProcessing = false;
    if (this._ss.isFromRefund) {
      const refundTranIdTemp = _.cloneDeep(this._ss.RefundTicketTransactionIDs);
      if (this._ss.RefundTicketTransactionIDs.length == 0) {
        if(this.currentProductId === Product.PMS){
          this.routeGuardService.backTrack();
        } else {
          this._location.back();
        }
      } else {
        this.utils.RedirectTo(RedirectToModules.ReturnWithTicket);
      }
      this.ResetShopItems();
      this._ss.isFromRefund = true;
      this._ss.RefundTicketTransactionIDs = refundTranIdTemp;
      return;
    }
    switch (frmSec) {
      case "RTID":
        this.GotoTransactionPage();
        break;
      case "RI":
        this._ss.sectionName = 'RTID';
        break;
      case "RIS":
        this._ss.selectedTransactionRetailProducts =  _.cloneDeep(this.initalSelectedItems);
        this._ss.selectedTransactionRetailProducts.forEach(x => { x.isServiceChargeReturn = x.isGratuityReturn = false; })
        this.GotoPreviousPage();
        break;
    }
  }

  GotoTransactionPage() {
    this.router.navigate(['/shop/viewshop/retailtransactions/returnwithticket']);
    this.ResetShopItems();
    this._ss.retailTransaction.tabState = 1;
    this._ss.returnTicket.fromReturnTicket = true;
    this._ss.returnTicket.dropdownState = RetailTransactions.returnwithticket;
  }

  ResetShopItems() {
    this.initalSelectedItems = null;
    this._ss.syncOpenTrasactionList = false;
    this._ss.GoToRetailTransaction = true;
    this._ss.selectedProducts = [];
    this._ss.ticketDiscount = null;
    this._ss.transactionTicketDiscount = [];
    this._ss.SelectedItemDiscount = [];
    this._ss.destroy();
    this._ss.selectedTransactionRetailProducts = [];
    this._ss.selectedReturnedRetailProducts = [];
    this._ss.selectedExchangeRetailProducts = [];
    this._ss.selectedRetainedRetailProducts = [];
    this._ss.retainedGiftCards = [];
    this._ss.selectedPayeeId = 0;
    this._ss.selectedclient = null;
    this._ss.memberCardNumber = "0";
    this._ss.memberArNumber = "0";
    this._ams.labelRecords = [];
    this._ams.clientsearchArray = [];
    this._retailService.manualEmailId = [];
  }

  GotoPreviousPage() {
    if (this._ss.returnExchangeType == 'item_WE') {

      if (this.PropertyInfo.UseRetailInterface && this._ss.Ticket) {
        this.transactionService.DiscardCheck(this._ss.Ticket.checkData);        
      }
      this._ss.Ticket = null;
      this._ss.selectedProducts = this._ss.selectedExchangeRetailProducts;
      this._ss.sectionName = 'RI';
    } else if (this._ss.returnExchangeType == 'item_WOE') {
      this._ss.sectionName = 'RTID';
    }
  }

  ngOnDestroy() {
    this._sbs.ResetReturnObjects();
    if (this.retailValidationService.transactionLockId > 0) {
      this.retailValidationService.RemoveTransactionLock(this.retailValidationService.transactionLockId);
    }  
  }

  editClient = (id, guestId) => { 
    this.openDialog('ordersummaryedit', id, guestId);
  };

  async openDialog(e, id?: any, guestId?: any) {
    await RetailDataAwaiters.openAddPayeePopup(e, this.afterAddPayee.bind(this), id, guestId);
  }

  afterAddPayee(payeeInfo: PayeeInfo) {
    if (payeeInfo) {
      this.payeeInfo = payeeInfo;
      this.clientInfo = this.payeeInfo;
      this._sbs.CheckForCardOnFile(payeeInfo);
      this.selectedClientId = payeeInfo.id;
      
      // To update name in search box
      this._ams.labelRecords = [
        {
          Id: payeeInfo.id,
          Name: payeeInfo.name
        }
      ];
      this.beforeClinet = false;
    }
  }

  receiveMessage($event) {
    this.makeSearchCall($event.searchValue, $event.requestUid);
  }

  async makeSearchCall(name: string, requestUid: string) {
    let guestInfo;
    if (this.currentProductId == this.ProductSNCId) {
      guestInfo = await RetailDataAwaiters.GetCateringBooking(name, false);
      this._ams.clientsearchArray = guestInfo;
    }
    else {
      if (this._ams.clientSelect == 1) {
        guestInfo = await this._memberService.searchGuest(name, this._ams.clientSelect);
      } else {
        guestInfo = await RetailDataAwaiters.searchPayee(name, this._ams.clientSelect, requestUid);
      }
      this._ams.clientsearchArray = guestInfo[1];
      this._ams.guestInfo = guestInfo[0];
    }


  }

  async selectPayeeFromSearch(payeeInfo: PayeeInfo) {
    this._ss.memberCardNumber = "0";
    this._ss.memberArNumber = "0";
    this.selectedDataForEmailReceipt = payeeInfo;
    if (payeeInfo.emailId != undefined && payeeInfo.emailId != "" && this._retailService.manualEmailId.filter(x => x == payeeInfo.emailId).length == 0) {
      if (this._retailService.manualEmailId.length < 3)
        this._retailService.manualEmailId.push(payeeInfo.emailId);
      else
        this._retailService.manualEmailId[2] = payeeInfo.emailId;
    }
    if (this._ams.clientSelect == 1 && payeeInfo.guestProfileId && 
      (this._ss.ProductId == Product.GOLF || this._ss.ProductId == Product.RETAIL)) {
      const _memberInfo: PayeeInfo = await this._memberService.getMemberInfo(
        payeeInfo.guestProfileId,
        this.PropertyInfo.CurrentDate.toISOString()
      );

      if (_memberInfo == null) {
        this._ams.labelRecords = [];
        this._ams.recordsArray = [];
        this._ams.multiClientInfo = [];
        this.removeFromSearchBar();
        this._ss.memberCardNumber = "0";
        this._ss.memberArNumber = "0";
        return;
      } else {
        let extraRetailItems = this._ss.selectedProducts.filter(
          (x) => x.payeeId == undefined && x.playerName == undefined
        );
        _memberInfo.id = payeeInfo.id;
        payeeInfo = _memberInfo;
        this._ss.memberCardNumber = payeeInfo.guestProfileId;
        this._ss.memberArNumber = payeeInfo.arAccountNumber.toString();
        this._memberService.setAvalableRounds(_memberInfo);
        this._ss.selectedPayeeId = payeeInfo && payeeInfo.id;
        this._ss.memberImageUrl = payeeInfo.photoUrl;	
      }
    }
    this._ss.selectedPayeeId = payeeInfo && payeeInfo.id;
    this._ss.selectedclient = payeeInfo;
    this._sbs.ValidatePaymentMethodActivationBasedOnComponentAccessLink.call(this._sbs, payeeInfo, true);
    this._sbs.AdjustPaymentMethods();
    this._sbs.CheckForCardOnFile(payeeInfo);
    this.payeeInfo = null;
    this.beforeClinet = false;
    this._sbs.PaymentBeforeClinet = false;
    this.selectedClientId = payeeInfo.id;
    this.selectedBookingId = payeeInfo.id;  
    if (!this.PropertyInfo.UseRetailInterface && this._ams.clientSelect != 1) {
      this.GetAllRoomsForGuest(payeeInfo.guestProfileId);
    }
    if (this._ss.ProductId == this.ProductSNCId && this.selectedBookingId != null && this.selectedBookingId) {
      if (this._ss.selectedProducts.every((x) => x.ItemType == 6)) {
        this._ss.depositFlag = true;
        let depositAll: DepositEventModel[] = [];
        this._ss.selectedProducts.forEach((product) => {
          depositAll.push({
            typeId: Number(this.selectedBookingId),
            amount: product.ProductPrice,
            depositTransactionId: 0
          } as DepositEventModel);
        });
        this.depositArray = depositAll;
        this._ss.RedirectModule = RedirectToModules.retail;
      }

    }
    this.payeeInfo = payeeInfo;
    this.clientInfo = payeeInfo;
    if (this._ss.ProductId == Product.RETAIL) {
      if (this._ams.clientSelect == 1) {
        this.canEnableGuestEditOption = false;
      } else {
        this.canEnableGuestEditOption = true;
        this.CheckEmailSMSConfigured(payeeInfo);
      }
    }
  }

  deleteCompleteSearch() {
    this.removeFromSearchBar();
  }

  async removeFromSearchBar() {
    if (this._ss.memberCardNumber != "0" && this._ss.ProductId == Product.GOLF) {
      let extraRetailItems = this._ss.selectedProducts.filter(
        (x) => x.payeeId == undefined && x.playerName == undefined
      );
      
    }
    this.payeeInfo = null;
    this._ss.selectedclient = null;
    this.roomDetailsArray = [];
    this._ss.selectedPayeeId = 0;
    this.beforeClinet = true;
    this._sbs.PaymentBeforeClinet = true;
    this.selectedClientId = 0;                                                                            
    this.selectedBookingId = "0";     
    this._ss.memberCardNumber = "0";
    this._ss.memberArNumber = "0";
    this._retailService.manualEmailId = this._retailService.manualEmailId.filter(x => x != this.selectedDataForEmailReceipt.emailId);
    this._sbs.ValidatePaymentMethodActivationBasedOnComponentAccessLink.call(this._sbs, this.payeeInfo, true);
    this._sbs.AdjustPaymentMethods();
    this._sbs.CheckForCardOnFile(null);
    this._ss.memberImageUrl = "";
  } 
  

  CheckEmailSMSConfigured(payeeInfo: PayeeInfo) {
    if (this._ss.isAutoTriggerNotificationEnabled && (!payeeInfo.emailId || !payeeInfo.phoneNumber)) {
      let extraParams: any = { payeeInfo: payeeInfo };
      const clientName = payeeInfo.name;
      this.utils.ShowErrorMessage(this.localization.captions.alertPopup.info,
        this.localization.replacePlaceholders(this.localization.captions.shop.emailConfirmation, ["Client"], [clientName]),
        ButtonType.YesNo, this.EamilSmsPopupCallback.bind(this), extraParams);
    }
  }
  async GetAllRoomsForGuest(guestId: string) {
    this.roomDetailsArray = await this._ss.GetAllRoomsForGuest(guestId);
  }

  EamilSmsPopupCallback(result, extraParams) {
    const payeeInfo: PayeeInfo = extraParams["payeeInfo"];
    if (result.toLowerCase() === ButtonOptions.No) {
      this.editClient(payeeInfo.id, payeeInfo.guestProfileId);
    }
  }

  async GetPayeeInfo(payeeId: number) {
		if (!payeeId) {
			return;
		}
		this.payeeInfo = await RetailDataAwaiters.getPayeeInfo(payeeId);
		this.clientInfo = this.payeeInfo;
		if (this.payeeInfo) {
			this.selectedClientId = this.payeeInfo.id;
      this.selectedDataForEmailReceipt = this.payeeInfo;
      if (this.payeeInfo.emailId != undefined && this._retailService.manualEmailId.filter(x => x == this.payeeInfo.emailId).length == 0)
        this._retailService.manualEmailId.push(this.payeeInfo.emailId);
      if(this.ProductSNCId==this._ss.ProductId)
			{
				this.payeeInfo.id=this.payeeInfo.bookingId?Number(this.payeeInfo.bookingId):this.payeeInfo.id;
			}
			this._ams.labelRecords = [
				{
					Id: this.payeeInfo.id,
					Name: this.payeeInfo.name
				}
			];
			if (this._ams.clientSelect != 1 && this.payeeInfo.guestProfileId && this.payeeInfo.guestProfileId != DefaultGUID) {
				this.GetAllRoomsForGuest(this.payeeInfo.guestProfileId);
			}
		}
  }  
	openEmailReceipt(){
		this.dialog.open(EmailReceiptComponent, {
		  height: '450px',
		  width: '500px',
		  panelClass: 'ag_dialog--lg',
		  data: this._retailService.manualEmailId
	  }).afterClosed().pipe(takeUntil(this.destroyed$)).subscribe(res => {
      this._retailService.manualEmailId = res.controls['mail'].value.map(x => x.emailValue);
	  });
	  }
}
