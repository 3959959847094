import { SortOrderPipe } from '../pipes/sort-order.pipe';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AgAddressComponent } from './components/ag-address/ag-address.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MaterialModule } from '../material-module';
import { AgCurrencyComponent } from './components/ag-currency/ag-currency.component';
import { AgDatePickerComponent } from './components/ag-date-picker/ag-date-picker.component';
import { AgMailIdComponent } from './components/ag-mail-id/ag-mail-id.component';
import { AgPercentageComponent } from './components/ag-percentage/ag-percentage.component';
import { AgPhoneNumberComponent } from './components/ag-phone-number/ag-phone-number.component';
import { AgPostalCodeComponent } from './components/ag-postal-code/ag-postal-code.component';
import { AgTimePickerComponent } from './components/ag-time-picker/ag-time-picker.component';
import { AgToggleComponent } from './components/ag-toggle/ag-toggle.component';
import { AlertPopupComponent } from './components/alert-popup/alert-popup.component';
import { AsideFilterComponent } from './components/aside-filter/aside-filter.component';
import { ButtonComponent } from './components/button/button.component';
import { CheckboxComponent } from './components/checkbox/checkbox.component';
import { ChipSearchComponent } from './components/chip-search/chip-search.component';
import { DialogFooterComponent } from './components/dialog-footer/dialog-footer.component';
import { DialogHeaderComponent } from './components/dialog-header/dialog-header.component';
import { AgImageUploaderComponent } from './components/image-uploader/image-uploader.component';
import { ImgThumbnailComponent } from './components/img-thumbnail/img-thumbnail.component';
import { MoreComponent } from './components/more/more.component';
import { MoreLegendComponent } from './components/more-legend/more-legend.component';
import { MultiSelectButtonComponent } from './components/multi-select-button/multi-select-button.component';
import { NoDataFoundComponent } from './components/no-data-found/no-data-found.component';
import { NoteComponent } from './components/note/note.component';
import { RecurringComponent } from './components/recurring/recurring.component';
import { SimpleSearchComponent } from './components/simple-search/simple-search.component';
import { TableSearchHeaderComponent } from './components/table-search-header/table-search-header.component';
import { CurrencyFormatterDirective } from './directives/currency.directive';
import { DisableDoubleClickDirective } from './directives/disable-double-click.directive';
import { InputtypeDirective } from './directives/inputtype.directive';
import { IsEllipsisDirective } from './directives/isellipsis.directive';
import { TextMaskDirective } from './directives/mask.directive';
import { no_white_space_Directive } from './directives/nowhitespace.directive';
import { NumFormatDirective } from './directives/num-formatter.directive';
import { NumMaxLengthDirective } from './directives/num-maxlength.directive';
import { ViewmoreDirective } from './directives/viewmore.directive';
import { ColorCheckerPipe } from './pipes/color-checker.pipe';
import { DataFilterPipe } from './pipes/data-filter.pipe';
import { FormatTimePipe } from './pipes/format-time.pipe';
import { GetTimePipe } from './pipes/get-time.pipe';
import { LoadDecimalValuePipe } from './pipes/load-decimal-value.pipe';
import { LocalizeDatePipe } from './pipes/localize-date.pipe';
import { LocalizeDateTimePipe } from './pipes/localize-dateTime.pipe';
import { LocalizeTimePipe } from './pipes/localize-time.pipe';
import { DuplicateCheckDirective } from './components/ag-phone-number/ag-duplicate-contact-directive';
import { FilterLengthPipe } from './components/aside-filter/aside-filter.pipe';
import { NgxMaterialTimepickerModule } from 'ngx-material-timepicker';
import { CustomCurrencyPipe } from './pipes/currency.pipe';
import { SwiperModule, SwiperConfigInterface, SWIPER_CONFIG } from 'ngx-swiper-wrapper';
import { NgxImageCompressService } from 'ngx-image-compress';
import { PopoverModule } from 'ngx-smart-popover';
import { ObjectindexofPipe } from './components/multi-select-button/objectindexof.pipe';
import { AgDropdownComponent } from './components/ag-dropdown/ag-dropdown.component';
import { InputHeaderActionComponent } from './components/input-header-action/input-header-action.component';
import { HttpClientModule } from '@angular/common/http';
import { AgNumberComponent } from './components/ag-number/ag-number.component';
import { AgImageUploadComponent } from './components/ag-image-upload/ag-image-upload.component';
import { MinNumberValidator, MaxNumberValidator } from './directives/custom-max-validator.directive';
import { SpecialCharacterDirective } from './directives/noSpecialCharacter.directive';
import { SingleInputHeaderActionComponent } from './components/single-input-header-action/single-input-header-action.component';
import { AgIncrementalComponent } from './components/ag-incremental/ag-incremental.component';
import { ShowmoreDirective } from './directives/show-more.directive';
import { AgDurationComponent } from './components/ag-duration/ag-duration.component';
import { AgImageMultiUploadComponent } from './components/ag-image-multi-upload/ag-image-multi-upload.component';
import { DurationFormatterDirective } from './components/ag-duration/ag-duration-formatter';


const DEFAULT_SWIPER_CONFIG: SwiperConfigInterface = {
  direction: 'horizontal',
  slidesPerView: 'auto',
  keyboard: {
    enabled: true,
  },
  mousewheel: true,
  navigation: {
    nextEl: '.swiper-button-next',
    prevEl: '.swiper-button-prev',
  },
  slideToClickedSlide: true,
  loop: false,
  observer: true
};

@NgModule({
    declarations: [
        AgAddressComponent,
        AgCurrencyComponent,
        AgDatePickerComponent,
        AgMailIdComponent,
        AgPercentageComponent,
        AgPhoneNumberComponent,
        AgPostalCodeComponent,
        AgTimePickerComponent,
        AgToggleComponent,
        AlertPopupComponent,
        AsideFilterComponent,
        AgImageUploaderComponent,
        AgImageUploadComponent,
        ButtonComponent,
        CheckboxComponent,
        ChipSearchComponent,
        DialogFooterComponent,
        DialogHeaderComponent,
        ImgThumbnailComponent,
        MoreComponent,
        MoreLegendComponent,
        MultiSelectButtonComponent,
        NoDataFoundComponent,
        NoteComponent,
        RecurringComponent,
        SimpleSearchComponent,
        TableSearchHeaderComponent,
        InputHeaderActionComponent,
        SingleInputHeaderActionComponent,
        CurrencyFormatterDirective,
        DisableDoubleClickDirective,
        InputtypeDirective,
        MinNumberValidator,
        MaxNumberValidator,
        IsEllipsisDirective,
        TextMaskDirective,
        no_white_space_Directive,
        NumFormatDirective,
        NumMaxLengthDirective,
        ViewmoreDirective,
        DuplicateCheckDirective,
        SpecialCharacterDirective,
        ShowmoreDirective,
        DurationFormatterDirective,
        ColorCheckerPipe,
        CustomCurrencyPipe,
        DataFilterPipe,
        FormatTimePipe,
        FilterLengthPipe,
        GetTimePipe,
        LoadDecimalValuePipe,
        LocalizeDatePipe,
        LocalizeDateTimePipe,
        LocalizeTimePipe,
        ObjectindexofPipe,
        AgDropdownComponent,
        AgNumberComponent,
        SortOrderPipe,
        AgIncrementalComponent,
        AgImageMultiUploadComponent,
        AgDurationComponent
    ],
    imports: [
        CommonModule,
        FormsModule,
        //HttpClientModule,
        ReactiveFormsModule,
        MaterialModule,
        NgxMaterialTimepickerModule,
        SwiperModule,
        PopoverModule,
        //HttpClientModule
    ],
    exports: [
        ReactiveFormsModule,
        SwiperModule,
        AgAddressComponent,
        AgCurrencyComponent,
        AgDatePickerComponent,
        AgDurationComponent,
        AgIncrementalComponent,
        AgMailIdComponent,
        AgPercentageComponent,
        AgPhoneNumberComponent,
        AgPostalCodeComponent,
        AgTimePickerComponent,
        AgToggleComponent,
        AlertPopupComponent,
        AsideFilterComponent,
        AgImageUploaderComponent,
        AgImageUploadComponent,
        ButtonComponent,
        CheckboxComponent,
        ChipSearchComponent,
        DialogFooterComponent,
        DialogHeaderComponent,
        ImgThumbnailComponent,
        MoreComponent,
        MoreLegendComponent,
        MultiSelectButtonComponent,
        NoDataFoundComponent,
        NoteComponent,
        RecurringComponent,
        SimpleSearchComponent,
        TableSearchHeaderComponent,
        AgDropdownComponent,
        InputHeaderActionComponent,
        SingleInputHeaderActionComponent,
        AgImageMultiUploadComponent,
        CurrencyFormatterDirective,
        DisableDoubleClickDirective,
        InputtypeDirective,
        MinNumberValidator,
        MaxNumberValidator,
        IsEllipsisDirective,
        TextMaskDirective,
        no_white_space_Directive,
        NumFormatDirective,
        NumMaxLengthDirective,
        ViewmoreDirective,
        DuplicateCheckDirective,
        SpecialCharacterDirective,
        ShowmoreDirective,
        ColorCheckerPipe,
        CustomCurrencyPipe,
        DataFilterPipe,
        FormatTimePipe,
        FilterLengthPipe,
        GetTimePipe,
        LoadDecimalValuePipe,
        LocalizeDatePipe,
        LocalizeDateTimePipe,
        LocalizeTimePipe,
        ObjectindexofPipe,
        ImgThumbnailComponent,
        AgNumberComponent,
        SortOrderPipe
    ],
    providers: [
        NgxImageCompressService,
        {
            provide: SWIPER_CONFIG,
            useValue: DEFAULT_SWIPER_CONFIG
        }
    ]
})
export class AgCommonModule { }
