import { Component } from '@angular/core';
import { menuTypes } from 'src/app/shared/shared.modal';
import { RouteLoaderService } from 'src/app/core/services/route-loader.service';

@Component({
  selector: 'app-spa-add-deposit',
  templateUrl: './spa-add-deposit.component.html',
  styleUrls: ['./spa-add-deposit.component.scss']
})
export class SpaAddDepositComponent {
  menuList: any;
  menuType = menuTypes;
  constructor(private routeDataService: RouteLoaderService) {
    const value = this.routeDataService.GetChildMenu('/settings/utilities/depositpolicy', menuTypes.lowerLevel);
    this.menuList = {
      menu: value.linkedElement,
      menuType : menuTypes.lowerLevel
    };
  }
}

