import { Component, OnInit, ViewEncapsulation, Input, Inject } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ContactType, DialogCloseEnum } from 'src/app/common/enums/shared-enums';
import { Localization } from 'src/app/common/localization/localization';
import { AgContactConfig, ButtonValue, MultiEmail } from 'src/app/common/Models/ag-models';
import { MailTypes, PhoneTypes } from 'src/app/common/shared/shared/enums/enums';
// import { API, UI, CustomerMaintenanceAggregate, CustomerMaintenance, Phone, Email, SecondaryAddress, ARAccountSearchResponse, Types } from "src/app/accounting/receivable/customer-maintenance/customer-maintenance.model";

@Component({
  selector: 'app-send-email',
  templateUrl: './send-email.component.html',
  styleUrls: ['./send-email.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class SendEmailComponent implements OnInit {
  mailInputs: AgContactConfig;
  mailForm: UntypedFormGroup;
  captions;
  actionButton: ButtonValue;
  cancelButton: ButtonValue;
  guestEmailId?: string;
  guestEmailType?: MailTypes;
  DialogCloseEnum = DialogCloseEnum;
  mailInfo: MultiEmail[] = [];
  additionalGuestInfo: any[] = [];
  showEmailCustomLabel: boolean = false;
  isADMEnabled: boolean;

  @Input('data')
  set setValue(value) {
    if (value) {
      this.guestEmailId = value.guestEmailId;
      this.guestEmailType = value.guestEmailType;
      this.additionalGuestInfo = value.additionalGuestInfo;
      this.showEmailCustomLabel = value.showEmailCustomLabel;
    }
  }
  nameListArray: MultiEmail[] = [];
  mailtochip: any;
  removefromChip: any;
  constructor(private fb: UntypedFormBuilder, private localization: Localization, private dialogRef: MatDialogRef<SendEmailComponent>, @Inject(MAT_DIALOG_DATA) public dialogData: any) {
    this.guestEmailId = this.dialogData.guestEmailId;
    this.guestEmailType = this.dialogData.guestEmailType;
    this.additionalGuestInfo = this.dialogData.additionalGuestInfo;
    this.showEmailCustomLabel = this.dialogData.showEmailCustomLabel;
  }

  ngOnInit(): void {
    this.captions = this.localization.captions;
    this.isADMEnabled = (this.localization.GetADMSession() === 'true');
    this.buildForm();
    this.initialize();
    
    if (this.validateEmail(this.guestEmailId)) {
      this.setMailInfoValue(this.guestEmailId);
      if(this.isADMEnabled){
        this.mailForm.controls.singleEmailInput.patchValue(this.guestEmailId);
      }
    }
    this.enableSendEmail();
  }
  
  buildForm() {
    this.mailForm = this.fb.group({
      guestName: '',
      mail: this.fb.array([
        this.fb.group({
          emailType: this.guestEmailType,
          //contactType: ContactType.email,
          emailId: ['', Validators.required],
          //primaryEmail: false,
          //privateInformtionEmail: false
        })
      ]),
      singleEmailInput: ''
    });
  }

  initialize() {
    this.mailInputs = {
      form: this.mailForm,
      formArrayName: 'mail',
      showSwitches: false
    };
    this.actionButton = {
      type: 'primary',
      label: this.captions.btn_sendEmail,
      disabledproperty: true
    }
    this.cancelButton = {
      type: 'tertiary',
      label: this.captions.btn_cancel
    }
    this.populateNameListArray();
  }

  closePopUp(from) {
    if (from == 2) {
      this.dialogRef.close({
        from: from,
        data: this.formContactEmail(this.mailInfo)
      })
    } else {
      this.dialogRef.close();
    }
  }

  onAction(e) {
    this.closePopUp(DialogCloseEnum.Action);
  }

  onCancel(e) {
    this.closePopUp(DialogCloseEnum.Cancel);
  }

  removedatafromChip(eve) {
    let removedArray = this.nameListArray;
    removedArray.forEach(x => {
      if (x.mail == eve.mail) {
        if (eve.isDrivenData) {
          x.checked = false;
        }
      }
    })
    this.nameListArray = [];
    this.nameListArray = [...removedArray]
    this.enableSendEmail();
  }
  AddDatatoChip(eve) {
    this.enableSendEmail();
  }

  onChangeSingleEmailInput(event: any){
    if(this.validateEmail(event?.target?.value)){
      this.setMailInfoValue(event?.target?.value);
    }
    else{
      this.mailInfo = [];
    }
    this.enableSendEmail();
  }


  setMailInfoValue(value: string){
      this.mailInfo = [];
      let guestEmail: MultiEmail = {
        name: '',
        mail: value,
        checked: false,
        isDrivenData: false
      };
      this.mailInfo.push(guestEmail);
  }

  onCheckSelection(eve, mail) {
    if (eve.checked) {
      this.mailtochip = {};
      mail.checked = true;
      mail.isDrivenData = true;
      this.mailtochip = mail;
      this.mailtochip = { ...this.mailtochip };
    }
    else {
      mail.checked = false;
      this.removefromChip = mail;
      this.removefromChip = { ...this.removefromChip };
    }
    this.enableSendEmail();
  }

  populateNameListArray() {
    if (this.additionalGuestInfo && this.additionalGuestInfo.length > 0) {
      this.additionalGuestInfo.forEach(data => {
        if (this.validateEmail(data.emailId)) {
          let guestEmail: MultiEmail = {
            name: data.firstName + ' ' + data.lastName,
            mail: data.emailId,
            checked: false,
            isDrivenData: true
          };
          this.nameListArray.push(guestEmail);
        }
      })
    }
  }

  private formContactEmail(mailInfo: any[]): string {
    let mail: string;
    if (mailInfo && mailInfo.length > 1) {
      let count = 1;
      mailInfo.forEach(data => {
        if (count == 1) {
          mail = data.mail;
        }
        else {
          mail = mail + ';' + data.mail;
        }
        count++;
      });
    }
    else {
      mail = mailInfo && mailInfo.length == 1 ? mailInfo[0].mail : '';
    }
    return mail;
  }

  validateEmail(email): boolean {
    if (email && email !== '') {
      const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(String(email));
    }
    return false;
  }

  enableSendEmail() {
    this.actionButton.disabledproperty = true;
    if (this.mailInfo.length > 0) {
      this.actionButton.disabledproperty = false;
    }
    this.actionButton = { ...this.actionButton }
  }

  validateScreen(e) {
    this.actionButton.disabledproperty = e;
    this.actionButton = { ...this.actionButton }
  }

}
