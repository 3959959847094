import { Injectable } from '@angular/core';
import { AutoRetailTransactionDataService } from '../service/data- services/autoRetailTransaction.data.service';
import { AutoRetailItemDetails, AutoRetailTransactionRequestModel, AutoRetailTransactionResponse, AutoServiceChargeGratuity, ClientInfo, ClientInformation, RetailItemType, SelectedProducts } from '../shared.modal';
import { AppointmentLineNumber } from './shared.modals';
import { ButtonOptions, CustomFeeSourceType, ReceiptType} from '../globalsContant';
import { ReceiptBusinessService } from "src/app/retail/shop/receipt/business/receipt-business.service"
import { RetailSharedVariableService } from '../retail.shared.variable.service';
import { ReportAPIOptions } from '../../retail.modals';
import { FastReportBusinessService } from '../../retail-reports/fast-report/fastreport.business.service';
import { RetailUtilities } from '../utilities/retail-utilities';
import { RetailDataAwaiters } from '../events/awaiters/retail.data.awaiters';
import { MatDialogRef } from '@angular/material/dialog';
import { DialogOverviewExampleDialog } from '../dialog-popup/dialogPopup-componenet';
import { CommonVariablesService, FolioInfo} from '../service/common-variables.service';
import * as _ from 'lodash';
import { FolioBusiness } from '../../Folio/folio-business';
@Injectable()

export class AutoRetailTransactionBusiness {
  constructor(private dataService: AutoRetailTransactionDataService, private receiptService: ReceiptBusinessService
    , private retailSharedService: RetailSharedVariableService, private _fastReportBusinessService: FastReportBusinessService,
    public utils: RetailUtilities, public _ss: CommonVariablesService, private folioBusiness: FolioBusiness) {
  }

  public async InitiateAutoRetailTransaction(model: AutoRetailTransactionRequestModel[]): Promise<AutoRetailTransactionResponse[]> {
    return await this.dataService.InitiateAutoRetailTransaction(model);
  }

  async CheckForRequireComments(selectedProducts: SelectedProducts[], callback) {
    if (selectedProducts.length > 0 && selectedProducts.some(x => x.isRequireComments)) {
      var requireCommentsProducts = selectedProducts.filter(x => x.isRequireComments && (x.itemComments || '').trim() == '');
      this.utils.ToggleLoader(false);
      const productLength = requireCommentsProducts.length;
      let count = 0;
      requireCommentsProducts.forEach((x) => {
        const dialogRef: MatDialogRef<DialogOverviewExampleDialog> = this._ss.openEnterCommentDialog(x);
        dialogRef.afterClosed().subscribe(res => {
          count++;
          if (res.res === ButtonOptions.Save && res.comment) {
            x.itemComments = res.comment;
          }
          if (productLength == count) {
            callback(selectedProducts);
          }
        });
      });

    } else {
      callback(selectedProducts);
    }

  }

  public IsTherapistAssociatedWithService(selectedProducts: SelectedProducts[]) {
    const gratuity = _.flatten(selectedProducts?.map(x => x.Gratuity))?.filter(gr => gr?.gratuity > 0);
    const isGratuityHasTherapist = gratuity && gratuity.length ? gratuity.every(gr => gr.gratuity != 0 && gr.TherapistId && gr.TherapistId != 0) : true;
    const serviceCharge = _.flatten(selectedProducts.map(x => x.ServiceCharge))?.filter(sr => sr?.ServiceCharge > 0);
    const isserviceChargeHasTherapist = serviceCharge && serviceCharge.length ? serviceCharge.every(sv => sv.ServiceCharge != 0
      && sv.TherapistId && sv.TherapistId != 0) : true;
    return isGratuityHasTherapist && isserviceChargeHasTherapist;
  }


  public MapRetailItems(selectedProducts: SelectedProducts[], clientInfo: ClientInformation): [AutoRetailItemDetails[], AppointmentLineNumber[]] {
    const items: AutoRetailItemDetails[] = [];
    const CheckOutAppointmentsList: AppointmentLineNumber[] = [];
    let LineNumber: number = 1;
    if (selectedProducts && selectedProducts.length > 0) {
      for (const x of selectedProducts) {
        x?.Commission?.forEach(f => f.id = "0");
        const item: AutoRetailItemDetails = {
          retailItemId: x.ItemId,
          guestId: clientInfo.clientId,
          lineNumber: LineNumber,
          guestGuId: clientInfo.guestId,
          itemDescription: x.ItemDescription,
          retailItemPrice: x.ProductPrice,
          quantity: x.Noofitems,
          itemComment: x.itemComments,
          isOpenItem: x.isOpenPricedItem,
          isReturn: x.isReturn,
          IsRetained: false,
          isMultiPackItem: x.MultiPack,
          clientMultiPackId: x.ClientMultiPackId,
          autoServiceChargeGratuity: this.MapServiceChargeGratuity(x),
          Commission: x?.Commission,
          sourceTypeId: x?.CheckOutAppointmentId? x.CheckOutAppointmentId : 0,
          sourceType: x?.CheckOutAppointmentId? CustomFeeSourceType.Appointment : CustomFeeSourceType.None
        }
        items.push(item);
        if (x.CheckOutAppointmentId && !x.IsAppointmentNotCheckedOut)
          CheckOutAppointmentsList.push({
            appointmentId: x.CheckOutAppointmentId,
            LineNumber: LineNumber,
            addOnId: x.addOnId,
            appointmentAddonId: x.addOnId > 0 ? x.sourceTypeId : 0
          });

        if (!x.isGroupingKey || x.isPackagedItem) {
          LineNumber++;
        }
      }
    }
    return [items, CheckOutAppointmentsList];
  }

  MapServiceChargeGratuity(element: SelectedProducts): AutoServiceChargeGratuity[] {
    const result: AutoServiceChargeGratuity[] = [];
    if (element.Gratuity && element.Gratuity.length > 0) {
      const gratuities: AutoServiceChargeGratuity[] = [];
      for (const grat of element.Gratuity) {
        if (grat.gratuity != 0) {
          gratuities.push({
            gratuityAmount: grat.gratuity,
            gratuityPercentage: grat.Percentage ? grat.Percentage : 0,
            gratuityStaffID: grat.TherapistId,
            gratuityStaffType: element.ItemType != RetailItemType.RevenueItem ? grat.StaffType : "USER",
          });
        }
      }
      result.push(...gratuities);
    }
    if (element.ServiceCharge && element.ServiceCharge.length > 0) {
      const serviceCharges: AutoServiceChargeGratuity[] = [];
      for (const svc of element.ServiceCharge) {
        if (svc.ServiceCharge != 0) {
          serviceCharges.push({
            serviceChargeAmount: svc.ServiceCharge,
            serviceChargePercentage: svc.Percentage ? svc.Percentage : 0,
            serviceChargeStaffID: svc.TherapistId,
            serviceChargeStaffType: element.ItemType != RetailItemType.RevenueItem ? svc.StaffType : "USER",
          });
        }
      }
      result.push(...serviceCharges);
    }
    return result;
  }

  async printReceiptAndSendNotification(response: AutoRetailTransactionResponse, appointmentId: number[], 
    emailId: string[], clientName: string, memberCardNumber?: string , isTaxExempt?: boolean, isMemberTransaction?: boolean, receiptComment?: string, isReturn?: boolean) {
    this.receiptService.GenerateReceipt(
      response.retailTicketNumber,
      this.retailSharedService.SelectedOutletId,
      isReturn ? ReceiptType.returnExchange :  ReceiptType.sales,
      response.transactionId,
      memberCardNumber,
      clientName,
      null,
      false,
      isReturn
    );
    if (emailId && emailId.length) {
      const clientInfo = {} as ClientInfo;
      const clerkName = this.utils.GetPropertyInfo("userName");
      const isEnableMachineTransaction = sessionStorage.getItem('enableMachineTransaction') == "true" ? true : false;
      const memberCardNumber = '';
      const options: ReportAPIOptions = await this.receiptService.formReportAPIOptions(response.retailTicketNumber, this.retailSharedService.SelectedOutletId, ReceiptType.sales, response.transactionId, clientName, '', false,isReturn, 0, isTaxExempt, isMemberTransaction, 0, receiptComment, clerkName, isEnableMachineTransaction, memberCardNumber, '', '', false, '', []);
      clientInfo.transactionId = response.transactionId;
      clientInfo.reportQuery = this.receiptService.reportQuery ? this._fastReportBusinessService.createAPIOptions(this.receiptService.reportQuery) : this._fastReportBusinessService.createAPIOptions(options);
      clientInfo.appointmentId = appointmentId;
      RetailDataAwaiters.SendNotification(clientInfo, emailId, true);
    }
  }


  async PostResortFinanceFolioPostDetails(selectedProducts: SelectedProducts[],  posts: number[], saleAmount: number,folioInfo: FolioInfo) {
    await this.folioBusiness.PostResortFinanceFolioPostDetails(selectedProducts, posts, saleAmount, folioInfo);
  }

  public serializeDependentTransactions(map: Map<number, number[]>): Record<number, number[]> {
    const obj: Record<number, number[]> = {};
    map.forEach((value, key) => {
      obj[key] = value;
    });
    return obj;
  }
}