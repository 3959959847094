import { Injectable } from "@angular/core";
import { TableHeaderOptions, TableOptions } from "src/app/common/Models/ag-models";
import { ActionTypeEnum, SorTypeEnum } from "src/app/common/components/cdkvirtual/cdkvirtual.model";
import { TableActions } from "src/app/common/enums/shared-enums";
import { Localization } from "src/app/common/localization/localization";

@Injectable({ providedIn: 'root' })
export class DepositPolicyBusiness {
  captions: any;
  constructor(private localization: Localization){
      this.captions = this.localization.captions;
  }
    getSearchOptions(){
       return {
            searchPlaceHolder : this.captions.lbl_searchByPolicyCodeName,
            createBtnLabel: this.captions.btn_newCancellationPolicy,
            automationId: "cancellationPolicy",
            showInactive: true,
            toggleLabel: this.captions.lbl_showInactive,
        }
    }

    getHeaderOptions(): TableHeaderOptions[] {
    return [
      {
        key: 'code',
        displayName: this.captions.tbl_hdr_policyCode,
        displayNameId: "tbl_hdr_policyCode",
        sorting: true,
        searchable: true,
      },
      {
        key: 'name',
        displayName: this.captions.tbl_hdr_policyName,
        displayNameId: 'tbl_hdr_policyName',
        sorting: true,
        searchable: true,
      },
      {
        key: 'description',
        displayName: this.captions.tbl_hdr_description,
        displayNameId: 'tbl_hdr_properties',
        sorting: true,
        searchable: true,

      },
      {
        key: 'dateRange',
        displayName: this.captions.tbl_hdr_dateRange,
        displayNameId: 'tbl_hdr_dateRange',
        sorting: true

      },
      {
        key: 'isActive',
        displayName: this.captions.tog_lbl_active,
        displayNameId: 'tog_lbl_active',
        templateName: ActionTypeEnum.toggle
      },
      {
        key: ActionTypeEnum.action,
        displayName: "Actions",
        displayNameId: 'tbl_hdr_actions',
        templateName: ActionTypeEnum.action,
      },
    ];
  }

  getTableOptions(): TableOptions {
    return {
      defaultSortOrder: SorTypeEnum.asc,
      defaultsortingColoumnKey: 'code',
      showTotalRecords: true,
      columnFreeze: {
        firstColumn: false,
        lastColumn: true,
      },
      enablePagination: true,
      actions: [
        {
          type: TableActions.edit,
          disabled: false
        },
        {
            type: TableActions.copy,
            disabled: false
        },
        {
            type: TableActions.delete,
            disabled: false
        }
      ],

    };
  }
  getTableContent(){
      return[
          {
            name: "Test",
            code: "1234",
            description : "Test test",
            dateRange: "12 Jan 2024 - 15 Jan 2024",
            isActive: false
          }
      ]
  }

}