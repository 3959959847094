import { ButtonValue, AgInputFieldConfig } from 'src/app/common/Models/ag-models';
import { Component, OnInit, Inject } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Localization } from 'src/app/common/localization/localization';
import { ActionMode } from 'src/app/common/shared/shared/enums/enums';
import { ReplaySubject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { CommonUtilities } from 'src/app/common/shared/shared/utilities/common-utilities';
import { GuestTypeBusiness } from '../common-guest-type.business';
import { InputTypeText } from 'src/app/common/shared/shared/utilities/common-utilities';
import { InputTypeNumbers } from 'src/app/common/shared/shared/utilities/common-utilities';;
;

@Component({
  selector: 'app-create-guest-type',
  templateUrl: './create-guest-type.component.html',
  styleUrls: ['./create-guest-type.component.scss']
})
export class CreateGuestTypeComponent implements OnInit {
  guestTypeForm: UntypedFormGroup;
  actionButton: ButtonValue;
  cancelButton: ButtonValue;
  captions: any;
  destroyed$: ReplaySubject<boolean> = new ReplaySubject(1);
  codeMinLength: number;
  codeMaxLength: number = 10;
  codeTextInputConfig: AgInputFieldConfig;
  nameMinLength: number;
  nameMaxLength: number = 50;
  descriptionInputConfig: AgInputFieldConfig;
  listOrderInput: AgInputFieldConfig;
  listOrderMinLength = 1;
  listOrderMaxLength = 5;
  listOrderMaxValue = 99999;
  isEdit = false;
  isCopy = false;
  nextListOrder: number;
  message: string;
  showClose : boolean = true;
  constructor(private localization: Localization,
    private fb: UntypedFormBuilder,
    @Inject(MAT_DIALOG_DATA) public dialogData: any,
    private dialogRef: MatDialogRef<CreateGuestTypeComponent>,
    private utils: CommonUtilities,
    private business: GuestTypeBusiness) { }

  ngOnInit(): void {
    this.captions = this.localization.captions;
    this.isEdit = this.dialogData.mode == ActionMode.update;
    this.isCopy = this.dialogData.mode == ActionMode.copy;
    this.nextListOrder = this.dialogData.nextListOrder;
    this.formGenerator();
    this.guestTypeForm.valueChanges.pipe(takeUntil(this.destroyed$)).subscribe(val => {
      this.actionButton.disabledproperty = !(this.guestTypeForm.dirty && this.guestTypeForm.valid)
    });
  }

  formGenerator() {
    this.guestTypeForm = this.fb.group({
      id: 0,
      code: ['', Validators.required],
      name: ['', Validators.required],
      listOrder: this.nextListOrder,
      isActive: true,
    })
    this.actionButton = {
      type: "primary",
      label: (this.dialogData.mode == ActionMode.create) || (this.dialogData.mode == ActionMode.copy) ?
        this.captions.btn_createVIPType : this.captions.btn_updateVIPType,
      disabledproperty: true,
    };
    this.cancelButton = {
      type: "tertiary",
      label: this.captions.btn_cancel,
    };
    this.codeTextInputConfig = {
      className: 'ag_form-control--2',
      form: this.guestTypeForm,
      formControlName: 'code',
      placeHolder: this.captions.GuestTypeCode,
      maxlength: this.codeMaxLength,
      showRequired: true,
      errorMessage: this.captions.MissingGuestTypeCode,
      changeLabelOnEdit: true,
      disabled: this.isEdit,
      inputType : InputTypeText.CODEINPUTTYPE.concat(',',InputTypeText.NOSPL),
      hideplaceholder : true
    };
    this.descriptionInputConfig = {
      className: 'ag_form-control--2',
      form: this.guestTypeForm,
      formControlName: 'name',
      placeHolder: this.captions.GuestTypeName,
      maxlength: this.nameMaxLength,
      showRequired: true,
      errorMessage: this.captions.MissingGuestTypeName,
      disabled : this.dialogData.isViewOnly,
      inputType : InputTypeText.NOPRESPACE,
      hideplaceholder : true
    };
    this.listOrderInput = {
      className: 'ag_form-control--2',
      form: this.guestTypeForm,
      formControlName: 'listOrder',
      placeHolder: this.captions.VIPType_grid_hdr_ListOrder,
      maxlength: this.listOrderMaxLength,
      showRequired: false,
      isRequired:false,
      disabled: this.dialogData.isViewOnly,
      maxValue: this.listOrderMaxValue,
      minValue: this.listOrderMinLength,
      customErrorMessage: this.captions.VIPTypeerr_missing_ListOrder,
      inputType: InputTypeNumbers.ONLYPOSITIVE.concat(',',InputTypeNumbers.NODECIMAL),
      hideplaceholder : true
    };
    if (this.isEdit) {
      this.patchValueFormEdit();
    } else if (this.isCopy) {
      this.message = this.localization.replacePlaceholders(this.captions.lbl_copyText, ['code'], [this.dialogData.data.code] )
      this.patchValueFormCopy();
    }
  }

  patchValueFormEdit() {
    var editData = this.dialogData.data;
    this.guestTypeForm.controls.id.setValue(editData.id);
    this.guestTypeForm.controls.code.setValue(editData.code);
    this.guestTypeForm.controls.name.setValue(editData.name);
    this.guestTypeForm.controls.isActive.setValue(editData.isActive);
    this.guestTypeForm.controls.listOrder.setValue(editData.listOrder);
    if(this.dialogData.isViewOnly) {
      this.utils.disableControls(this.guestTypeForm);
    }
  }

  patchValueFormCopy() {
    var copyData = this.dialogData.data;
    this.guestTypeForm.controls.id.setValue(0);
    this.guestTypeForm.controls.name.setValue(copyData.name);
    this.guestTypeForm.controls.isActive.setValue(copyData.isActive);
    this.guestTypeForm.controls.listOrder.setValue(this.nextListOrder);
  }

  onAction(e){
    this.dialogRef.close(this.guestTypeForm.getRawValue());
  }

  close(e) {
    this.dialogRef.close();
  }

  onCancel(eve) {
    this.dialogRef.close();
  }

  hideWarning(){
    this.showClose = false;
  }
  switchActive() {

  }

}
