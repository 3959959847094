import { Injectable, Injector } from '@angular/core';
import { Router } from '@angular/router';
import { menuTypes } from 'src/app/shared/shared.modal';
import { menuTypes as newMenuTypes } from 'src/app/common/components/menu/menu.constant';
import { TenantManagementCommunication } from 'src/app/common/communication/services/tenantmanagement-communication-service';
import { CommonControllersRoutes } from 'src/app/common/communication/common-route';
import { AppService } from 'src/app/common/app-service';
import { Observable, of } from 'rxjs';
import { RetailPropertyInformation } from 'src/app/retail/common/services/retail-property-information.service';
import { RetailConstants, RetailFeatureFlagInformationService } from 'src/app/retail/shared/service/retail.feature.flag.information.service';
import * as _ from 'lodash';
import { AuthorizeTokenBySession } from 'src/app/common/shared/retail.modals';
import { Localization } from 'src/app/common/localization/localization';

@Injectable()
export class RouteLoaderService {

    currentSettings: any;
    menu = '';
    private readonly ProductID: number;

    constructor(
        private injector: Injector,
        private _appservice: AppService,
        private _tenantMngmt: TenantManagementCommunication,
        private propertyInfo: RetailPropertyInformation,
        private localization: Localization,
        public featureFlagInfo: RetailFeatureFlagInformationService
    ) {
        this.ProductID = this._appservice.productId;
    }

    loadSettings(): Promise<any> {
        return new Promise((resolve, reject) => {
            setTimeout(() => {
                const router = this.injector.get(Router);
                console.log(router);
                this.getProductMenus().then(
                    // this.getMenus().subscribe(
                    response => {
                        let isUserTherapist = sessionStorage.getItem('UserTherapist');
                        if (isUserTherapist) {
                            this.currentSettings = _.cloneDeep(response.filter(x => x.elementID == 1002));
                            this.currentSettings.forEach(x => x.linkedElement = []);
                        }
                        else {
                            this.currentSettings = response;
                        }
                        if (!this.localization.GetPropertyInfo('PlatFormExtendedSearchRequired')) {
                            let settingsMenu = this.currentSettings.find(x => x.text.includes("SETTINGS"))
                            if (settingsMenu) {
                                let utilitiesMenu = settingsMenu.linkedElement?.find(x => x.text.includes("UTILITIES"))

                                if (utilitiesMenu) {
                                    let cgpsFailedMenu = utilitiesMenu.linkedElement?.find(x => x.text.includes("CGPS Failed Profile"))

                                    if (cgpsFailedMenu) {
                                        cgpsFailedMenu.visibility = false;
                                    }
                                }
                            }
                        }
                        let allowACRSetup = this.localization.GetPropertyConfig('AllowACRSetupScreen')
                       
                        let settingsMenu = this.currentSettings.find(x => x.text.includes("SETTINGS"))
                        if (settingsMenu) {
                            let systemConfigMenu = settingsMenu.linkedElement?.find(x => x.text.includes("SYSTEM CONFIG"))

                            if (systemConfigMenu) {
                                let acrSetupMenu = systemConfigMenu.linkedElement?.find(x => x.text.toUpperCase().includes("ACR SETUP"))

                                if (acrSetupMenu) {
                                    acrSetupMenu.visibility = (allowACRSetup ? allowACRSetup : 'false').toLowerCase() === "true" ? true : false;
                                }
                            }
                        }
                        
                        this.featureFlagInfo.GetFeaturesCompleted.subscribe(x => {
                            if (!this.featureFlagInfo.IsLockersEnabled) {
                                let settingsMenu = this.currentSettings.find(x => x.text.includes("SETTINGS"))
                                if (settingsMenu) {
                                    let utilMenu = settingsMenu.linkedElement?.find(x => x.text.includes("UTILITIES"))
                                    if (utilMenu) {
                                        let lockerType = utilMenu.linkedElement?.find(x => x.text.includes("Locker Type"))
                                        if (lockerType) {
                                            lockerType.visibility = false;
                                        }
                                    }
                                }
                            }
                        });
                        this.featureFlagInfo.GetFeaturesCompleted.subscribe(x => {
                            if (!this.featureFlagInfo.IsLockersEnabled) {
                                let shopMenu = this.currentSettings.find(x => x.text.includes("SHOP"))
                                if (shopMenu) {
                                    let rentalItemControl = shopMenu.linkedElement?.find(x => x.text.includes("LOCKER CONTROL"))
                                    if (rentalItemControl) {
                                        rentalItemControl.visibility = false;
                                    }
                                }
                            }
                            if (!this.featureFlagInfo.IsRetailIcEnabled) {
                                let settingsMenu = this.currentSettings.find(x => x.text.toLowerCase().includes("settings"));
                                let utilsMenu = settingsMenu?.linkedElement?.find(x => x.text.toLowerCase().includes("utilities"));
                                let InventoryMenu = utilsMenu?.linkedElement?.find(x => x.text.toLowerCase().includes("inventory"));
                                let stagingMenu = InventoryMenu?.linkedElement?.find(x => x.text.toLowerCase().includes("inventory staging"))
                                if (stagingMenu) {
                                    stagingMenu.visibility = false;
                                }
                            }
                        }
                        );

                        resolve(true);
                    },
                    err => {
                        console.log(err + ' and so, loading default menu');
                        reject(false);
                    }
                );
            });
        });
    }

    public GetChildMenu(currentRoute, menuType?: menuTypes | newMenuTypes) {
        const enableResortFinance = sessionStorage.getItem(RetailConstants.EnableResortFinance);
        if (enableResortFinance?.toLowerCase() == 'false') {
            const configsToRemove = ['/folio', '/accounting'];
            this.currentSettings = this.currentSettings.filter(r => !configsToRemove.includes(r.routePath));
        } else {
            const folioEnabled = sessionStorage.getItem(AuthorizeTokenBySession.FolioUserSession);
            if (!folioEnabled || folioEnabled == '') {
                const configsToRemove = ['/folio'];
                this.currentSettings = this.currentSettings.filter(r => !configsToRemove.includes(r.routePath));
            }
            const accEnabled = sessionStorage.getItem(AuthorizeTokenBySession.AccUserSession);
            if (!accEnabled || accEnabled == '') {
                const configsToRemove = ['/accounting'];
                this.currentSettings = this.currentSettings.filter(r => !configsToRemove.includes(r.routePath));
            }
        }

        const menuList = this.currentSettings;
        if (menuList) {
            if (currentRoute === '/') {
                return menuList;
            }
            let currentMenu = menuList.find(x => x.routePath === currentRoute);
            if (currentMenu) {
                return currentMenu;
            } else {
                for (const x of menuList) {
                    const obj: any[] = x.linkedElement ? x.linkedElement : null;
                    currentMenu = obj && obj.find(sub => sub.routePath === currentRoute);
                    if (currentMenu) {
                        return currentMenu;
                    }
                    if ((menuType === menuTypes.lowerLevel || menuType === newMenuTypes.vertical) && obj) {
                        for (const childMenu of obj) {
                            const childObj: any[] = childMenu.linkedElement ? childMenu.linkedElement : null;
                            const currentChildMenu = childObj && childObj.find(sub => sub.routePath === currentRoute);
                            if (currentChildMenu) {
                                return currentChildMenu;
                            }

                        }
                    }
                }
            }
        }

    }

    async getProductMenus(): Promise<MenuObj[]> {
        let menus: MenuObj[] = [];
        menus = await this._tenantMngmt.getPromise({
            route: CommonControllersRoutes.GetMenus,
            uriParams: ""
        });
        this.propertyInfo.setICRoutes(menus.find(x => x.routePath == '/settings')?.linkedElement);
        return menus.filter(x => x.productID == this.ProductID);
    }

    public getMenus(): Observable<any> {
        let menuObj = [
            {
                "elementID": 1002,
                "tenantID": 0,
                "propertyID": 0,
                "productID": 1,
                "textID": 1003,
                "text": "APPOINTMENT_DA",
                "routePath": "/appointment",
                "imgPath": "icon-appointments",
                "order": 2,
                "visibility": true,
                "disable": false,
                "parentID": 0,
                "menuPosition": "Primary",
                "menuAlignment": "Horizontal",
                "externalLink": false,
                "linkedElement": [],
                "breakPointNumber": 0
            }];
        let menuObjOld = [
            {
                "elementID": 1001,
                "tenantID": 0,
                "propertyID": 0,
                "productID": 1,
                "textID": 1001,
                "text": "HOME_DA",
                "routePath": "/home",
                "imgPath": "icon-home",
                "order": 1,
                "visibility": true,
                "disable": false,
                "parentID": 0,
                "menuPosition": "Primary",
                "menuAlignment": "Horizontal",
                "externalLink": false,
                "linkedElement": [],
                "breakPointNumber": 0
            },
            {
                "elementID": 6256,
                "tenantID": 0,
                "propertyID": 0,
                "productID": 1,
                "textID": 6256,
                "text": "EXPORT/IMPORT",
                "routePath": "/exportimport",
                "imgPath": "icon-Export-Import",
                "order": 12,
                "visibility": true,
                "disable": false,
                "parentID": 0,
                "menuPosition": "Primary",
                "menuAlignment": "Horizontal",
                "externalLink": false,
                "linkedElement": [{
                    "elementID": 1001,
                    "tenantID": 0,
                    "propertyID": 0,
                    "productID": 1,
                    "textID": 1001,
                    "text": "HOME_DA",
                    "routePath": "/exportimport/export",
                    "imgPath": "",
                    "order": 1,
                    "visibility": true,
                    "disable": false,
                    "parentID": 0,
                    "menuPosition": "Primary",
                    "menuAlignment": "Horizontal",
                    "externalLink": false,
                    "linkedElement": [],
                    "breakPointNumber": 0
                },
                {
                    "elementID": 1001,
                    "tenantID": 0,
                    "propertyID": 0,
                    "productID": 1,
                    "textID": 1001,
                    "text": "IMPORT",
                    "routePath": "/exportimport/import",
                    "imgPath": "",
                    "order": 1,
                    "visibility": true,
                    "disable": false,
                    "parentID": 0,
                    "menuPosition": "Primary",
                    "menuAlignment": "Horizontal",
                    "externalLink": false,
                    "linkedElement": [],
                    "breakPointNumber": 0
                },
                {
                    "elementID": 1001,
                    "tenantID": 0,
                    "propertyID": 0,
                    "productID": 1,
                    "textID": 1001,
                    "text": "FILE IMPORT",
                    "routePath": "/exportimport/fileimport",
                    "imgPath": "",
                    "order": 1,
                    "visibility": true,
                    "disable": false,
                    "parentID": 0,
                    "menuPosition": "Primary",
                    "menuAlignment": "Horizontal",
                    "externalLink": false,
                    "linkedElement": [],
                    "breakPointNumber": 0
                }],
                "breakPointNumber": 0
            },
            {
                "elementID": 1002,
                "tenantID": 0,
                "propertyID": 0,
                "productID": 1,
                "textID": 1003,
                "text": "APPOINTMENT_DA",
                "routePath": "/appointment",
                "imgPath": "icon-appointments",
                "order": 2,
                "visibility": true,
                "disable": false,
                "parentID": 0,
                "menuPosition": "Primary",
                "menuAlignment": "Horizontal",
                "externalLink": false,
                "linkedElement": [
                    {
                        "elementID": 1003,
                        "tenantID": 0,
                        "propertyID": 0,
                        "productID": 1,
                        "textID": 1085,
                        "text": "BOOK APPOINTMENT_DA",
                        "routePath": "/appointment/newappointment",
                        "imgPath": "",
                        "order": 1,
                        "visibility": true,
                        "disable": false,
                        "parentID": 1002,
                        "menuPosition": "Secondary",
                        "menuAlignment": "Horizontal",
                        "externalLink": false,
                        "linkedElement": [],
                        "breakPointNumber": 100
                    },
                    {
                        "elementID": 1004,
                        "tenantID": 0,
                        "propertyID": 0,
                        "productID": 1,
                        "textID": 1086,
                        "text": "APPOINTMENT SEARCH_DA",
                        "routePath": "/appointment/appointmentsearch",
                        "imgPath": "",
                        "order": 2,
                        "visibility": true,
                        "disable": false,
                        "parentID": 1002,
                        "menuPosition": "Secondary",
                        "menuAlignment": "Horizontal",
                        "externalLink": false,
                        "linkedElement": [],
                        "breakPointNumber": 400
                    },
                    {
                        "elementID": 1005,
                        "tenantID": 0,
                        "propertyID": 0,
                        "productID": 1,
                        "textID": 1087,
                        "text": "APPOINTMENT CONFIRMATION_DA",
                        "routePath": "/appointment/appointmentconfirmation",
                        "imgPath": "",
                        "order": 3,
                        "visibility": true,
                        "disable": false,
                        "parentID": 1002,
                        "menuPosition": "Secondary",
                        "menuAlignment": "Horizontal",
                        "externalLink": false,
                        "linkedElement": [],
                        "breakPointNumber": 420
                    },
                    {
                        "elementID": 1006,
                        "tenantID": 0,
                        "propertyID": 0,
                        "productID": 1,
                        "textID": 1088,
                        "text": "WAITLIST_DA",
                        "routePath": "/appointment/waitlist",
                        "imgPath": "",
                        "order": 4,
                        "visibility": true,
                        "disable": false,
                        "parentID": 1002,
                        "menuPosition": "Secondary",
                        "menuAlignment": "Horizontal",
                        "externalLink": false,
                        "linkedElement": [],
                        "breakPointNumber": 415
                    },
                    {
                        "elementID": 1006,
                        "tenantID": 0,
                        "propertyID": 0,
                        "productID": 1,
                        "textID": 1088,
                        "text": "LOST/DENIED BUSINESS",
                        "routePath": "/appointment/lostDeniedBusiness",
                        "imgPath": "",
                        "order": 4,
                        "visibility": true,
                        "disable": false,
                        "parentID": 1002,
                        "menuPosition": "Secondary",
                        "menuAlignment": "Horizontal",
                        "externalLink": false,
                        "linkedElement": [],
                        "breakPointNumber": 415
                    }
                ],
                "breakPointNumber": 0
            },
            {
                "elementID": 1007,
                "tenantID": 0,
                "propertyID": 0,
                "productID": 1,
                "textID": 1005,
                "text": "CLIENT_DA",
                "routePath": "/client",
                "imgPath": "icon-guest",
                "order": 3,
                "visibility": true,
                "disable": false,
                "parentID": 0,
                "menuPosition": "Primary",
                "menuAlignment": "Horizontal",
                "externalLink": false,
                "linkedElement": [
                    {
                        "elementID": 1008,
                        "tenantID": 0,
                        "propertyID": 0,
                        "productID": 1,
                        "textID": 1081,
                        "text": "ALL CLIENTS_DA",
                        "routePath": "/client/allclients",
                        "imgPath": "",
                        "order": 1,
                        "visibility": true,
                        "disable": false,
                        "parentID": 1007,
                        "menuPosition": "Secondary",
                        "menuAlignment": "Horizontal",
                        "externalLink": false,
                        "linkedElement": [],
                        "breakPointNumber": 600
                    },
                    {
                        "elementID": 1009,
                        "tenantID": 0,
                        "propertyID": 0,
                        "productID": 1,
                        "textID": 1082,
                        "text": "RECENTS_DA",
                        "routePath": "/client/recents",
                        "imgPath": "",
                        "order": 2,
                        "visibility": true,
                        "disable": false,
                        "parentID": 1007,
                        "menuPosition": "Secondary",
                        "menuAlignment": "Horizontal",
                        "externalLink": false,
                        "linkedElement": [],
                        "breakPointNumber": 0
                    },
                    {
                        "elementID": 1010,
                        "tenantID": 0,
                        "propertyID": 0,
                        "productID": 1,
                        "textID": 1083,
                        "text": "VIP_DA",
                        "routePath": "/client/vip",
                        "imgPath": "",
                        "order": 3,
                        "visibility": true,
                        "disable": false,
                        "parentID": 1007,
                        "menuPosition": "Secondary",
                        "menuAlignment": "Horizontal",
                        "externalLink": false,
                        "linkedElement": [],
                        "breakPointNumber": 0
                    },
                    {
                        "elementID": 1103,
                        "tenantID": 0,
                        "propertyID": 0,
                        "productID": 1,
                        "textID": 11361,
                        "text": "MEMBERS_DA",
                        "routePath": "/client/member",
                        "imgPath": "",
                        "order": 4,
                        "visibility": true,
                        "disable": false,
                        "parentID": 1007,
                        "menuPosition": "Secondary",
                        "menuAlignment": "Horizontal",
                        "externalLink": false,
                        "linkedElement": [
                            {
                                "elementID": 1105,
                                "tenantID": 0,
                                "propertyID": 0,
                                "productID": 1,
                                "textID": 11406,
                                "text": "Members_DA",
                                "routePath": "/home/Members",
                                "imgPath": "",
                                "order": 1,
                                "visibility": true,
                                "disable": false,
                                "parentID": 1103,
                                "menuPosition": "Ternary",
                                "menuAlignment": "Vertical",
                                "externalLink": false,
                                "linkedElement": [],
                                "breakPointNumber": 0
                            },
                            {
                                "elementID": 1106,
                                "tenantID": 0,
                                "propertyID": 0,
                                "productID": 1,
                                "textID": 11411,
                                "text": "Member Renewal Report_DA",
                                "routePath": "/home/MemberRenewalReport",
                                "imgPath": "",
                                "order": 3,
                                "visibility": true,
                                "disable": false,
                                "parentID": 1103,
                                "menuPosition": "Ternary",
                                "menuAlignment": "Vertical",
                                "externalLink": false,
                                "linkedElement": [],
                                "breakPointNumber": 0
                            },
                            {
                                "elementID": 1107,
                                "tenantID": 0,
                                "propertyID": 0,
                                "productID": 1,
                                "textID": 11416,
                                "text": "MembershipType_DA",
                                "routePath": "/home/MembershipType",
                                "imgPath": "",
                                "order": 2,
                                "visibility": true,
                                "disable": false,
                                "parentID": 1103,
                                "menuPosition": "Ternary",
                                "menuAlignment": "Vertical",
                                "externalLink": false,
                                "linkedElement": [],
                                "breakPointNumber": 0
                            },
                            {
                                "elementID": 1108,
                                "tenantID": 0,
                                "propertyID": 0,
                                "productID": 1,
                                "textID": 11426,
                                "text": "Credit Book Report_DA",
                                "routePath": "/home/CreditBookReport",
                                "imgPath": "",
                                "order": 4,
                                "visibility": true,
                                "disable": false,
                                "parentID": 1103,
                                "menuPosition": "Ternary",
                                "menuAlignment": "Vertical",
                                "externalLink": false,
                                "linkedElement": [],
                                "breakPointNumber": 0
                            },
                            {
                                "elementID": 1109,
                                "tenantID": 0,
                                "propertyID": 0,
                                "productID": 1,
                                "textID": 11431,
                                "text": "Price Type_DA",
                                "routePath": "/home/PriceType",
                                "imgPath": "",
                                "order": 5,
                                "visibility": true,
                                "disable": false,
                                "parentID": 1103,
                                "menuPosition": "Ternary",
                                "menuAlignment": "Vertical",
                                "externalLink": false,
                                "linkedElement": [],
                                "breakPointNumber": 0
                            },
                            {
                                "elementID": 1110,
                                "tenantID": 0,
                                "propertyID": 0,
                                "productID": 1,
                                "textID": 11436,
                                "text": "Fixed Charge_DA",
                                "routePath": "/home/FixedCharge",
                                "imgPath": "",
                                "order": 6,
                                "visibility": true,
                                "disable": false,
                                "parentID": 1103,
                                "menuPosition": "Ternary",
                                "menuAlignment": "Vertical",
                                "externalLink": false,
                                "linkedElement": [],
                                "breakPointNumber": 0
                            },
                            {
                                "elementID": 1111,
                                "tenantID": 0,
                                "propertyID": 0,
                                "productID": 1,
                                "textID": 11441,
                                "text": "Members Adjust points_DA",
                                "routePath": "/home/MembersAdjustPoints",
                                "imgPath": "",
                                "order": 7,
                                "visibility": true,
                                "disable": false,
                                "parentID": 1103,
                                "menuPosition": "Ternary",
                                "menuAlignment": "Vertical",
                                "externalLink": false,
                                "linkedElement": [],
                                "breakPointNumber": 0
                            }
                        ],
                        "breakPointNumber": 0
                    }
                ],
                "breakPointNumber": 0
            },
            {
                "elementID": 1011,
                "tenantID": 0,
                "propertyID": 0,
                "productID": 1,
                "textID": 1091,
                "text": "SHOP_DA",
                "routePath": "/shop/viewshop",
                "imgPath": "icon-shop",
                "order": 4,
                "visibility": true,
                "disable": false,
                "parentID": 0,
                "menuPosition": "Primary",
                "menuAlignment": "Horizontal",
                "externalLink": false,
                "linkedElement": [
                    {
                        "elementID": 1012,
                        "tenantID": 0,
                        "propertyID": 0,
                        "productID": 1,
                        "textID": 1092,
                        "text": "RETAIL ITEMS_DA",
                        "routePath": "/shop/viewshop/retailitems",
                        "imgPath": "",
                        "order": 1,
                        "visibility": true,
                        "disable": false,
                        "parentID": 1011,
                        "menuPosition": "Secondary",
                        "menuAlignment": "Horizontal",
                        "externalLink": false,
                        "linkedElement": [],
                        "breakPointNumber": 0
                    },
                    {
                        "elementID": 1013,
                        "tenantID": 0,
                        "propertyID": 0,
                        "productID": 1,
                        "textID": 1093,
                        "text": "RETAIL TRANSACTIONS_DA",
                        "routePath": "/shop/viewshop/retailtransactions",
                        "imgPath": "",
                        "order": 2,
                        "visibility": true,
                        "disable": false,
                        "parentID": 1011,
                        "menuPosition": "Secondary",
                        "menuAlignment": "Horizontal",
                        "externalLink": false,
                        "linkedElement": [
                            {
                                "elementID": 1014,
                                "tenantID": 0,
                                "propertyID": 0,
                                "productID": 1,
                                "textID": 1094,
                                "text": "Open Transactions_DA",
                                "routePath": "/shop/viewshop/retailtransactions/opentransactions",
                                "imgPath": "",
                                "order": 1,
                                "visibility": true,
                                "disable": false,
                                "parentID": 1013,
                                "menuPosition": "Ternary",
                                "menuAlignment": "Vertical",
                                "externalLink": false,
                                "linkedElement": [],
                                "breakPointNumber": 5000
                            },
                            {
                                "elementID": 1015,
                                "tenantID": 0,
                                "propertyID": 0,
                                "productID": 1,
                                "textID": 1095,
                                "text": "Correct/Void_DA",
                                "routePath": "/shop/viewshop/retailtransactions/correctvoid",
                                "imgPath": "",
                                "order": 2,
                                "visibility": true,
                                "disable": false,
                                "parentID": 1013,
                                "menuPosition": "Ternary",
                                "menuAlignment": "Vertical",
                                "externalLink": false,
                                "linkedElement": [],
                                "breakPointNumber": 0
                            },
                            {
                                "elementID": 1016,
                                "tenantID": 0,
                                "propertyID": 0,
                                "productID": 1,
                                "textID": 1096,
                                "text": "Return with Ticket_DA",
                                "routePath": "/shop/viewshop/retailtransactions/returnwithticket",
                                "imgPath": "",
                                "order": 3,
                                "visibility": true,
                                "disable": false,
                                "parentID": 1013,
                                "menuPosition": "Ternary",
                                "menuAlignment": "Vertical",
                                "externalLink": false,
                                "linkedElement": [],
                                "breakPointNumber": 5010
                            },
                            {
                                "elementID": 1017,
                                "tenantID": 0,
                                "propertyID": 0,
                                "productID": 1,
                                "textID": 1097,
                                "text": "Return without Ticket_DA",
                                "routePath": "/shop/viewshop/retailtransactions/returnwithoutticket",
                                "imgPath": "",
                                "order": 4,
                                "visibility": true,
                                "disable": false,
                                "parentID": 1013,
                                "menuPosition": "Ternary",
                                "menuAlignment": "Vertical",
                                "externalLink": false,
                                "linkedElement": [],
                                "breakPointNumber": 5015
                            },
                            {
                                "elementID": 1018,
                                "tenantID": 0,
                                "propertyID": 0,
                                "productID": 1,
                                "textID": 1098,
                                "text": "Modify Posted Commissions_DA",
                                "routePath": "/shop/viewshop/retailtransactions/modifypostedcommission",
                                "imgPath": "",
                                "order": 5,
                                "visibility": true,
                                "disable": false,
                                "parentID": 1013,
                                "menuPosition": "Ternary",
                                "menuAlignment": "Vertical",
                                "externalLink": false,
                                "linkedElement": [],
                                "breakPointNumber": 5020
                            },
                            {
                                "elementID": 1019,
                                "tenantID": 0,
                                "propertyID": 0,
                                "productID": 1,
                                "textID": 1099,
                                "text": "Reprint Ticket_DA",
                                "routePath": "/shop/viewshop/retailtransactions/reprintticket",
                                "imgPath": "",
                                "order": 6,
                                "visibility": true,
                                "disable": false,
                                "parentID": 1013,
                                "menuPosition": "Ternary",
                                "menuAlignment": "Vertical",
                                "externalLink": false,
                                "linkedElement": [],
                                "breakPointNumber": 5025
                            },
                            {
                                "elementID": 6736,
                                "tenantID": 0,
                                "propertyID": 0,
                                "productID": 1,
                                "textID": 10036,
                                "text": "Transactions Log_DA",
                                "routePath": "/shop/viewshop/retailtransactions/transactionslog",
                                "imgPath": "",
                                "order": 7,
                                "visibility": true,
                                "disable": false,
                                "parentID": 1013,
                                "menuPosition": "Ternary",
                                "menuAlignment": "Vertical",
                                "externalLink": false,
                                "linkedElement": [],
                                "breakPointNumber": 0
                            },
                            {
                                "elementID": 6740,
                                "tenantID": 0,
                                "propertyID": 0,
                                "productID": 1,
                                "textID": 10041,
                                "text": "Revenue Postings Log_DA",
                                "routePath": "/shop/viewshop/retailtransactions/revenuepostingslog",
                                "imgPath": "",
                                "order": 8,
                                "visibility": true,
                                "disable": false,
                                "parentID": 1013,
                                "menuPosition": "Ternary",
                                "menuAlignment": "Vertical",
                                "externalLink": false,
                                "linkedElement": [],
                                "breakPointNumber": 0
                            },
                            {
                                "elementID": 6740,
                                "tenantID": 0,
                                "propertyID": 0,
                                "productID": 1,
                                "textID": 10041,
                                "text": "Signature Capture",
                                "routePath": "/shop/viewshop/retailtransactions/signaturecap",
                                "imgPath": "",
                                "order": 8,
                                "visibility": true,
                                "disable": false,
                                "parentID": 1013,
                                "menuPosition": "Ternary",
                                "menuAlignment": "Vertical",
                                "externalLink": false,
                                "linkedElement": [],
                                "breakPointNumber": 0
                            }
                        ],
                        "breakPointNumber": 0
                    },
                    {
                        "elementID": 1020,
                        "tenantID": 0,
                        "propertyID": 0,
                        "productID": 1,
                        "textID": 1008,
                        "text": "GIFT CARDS_DA",
                        "routePath": "/shop/viewshop/giftcards",
                        "imgPath": "",
                        "order": 3,
                        "visibility": true,
                        "disable": false,
                        "parentID": 1011,
                        "menuPosition": "Secondary",
                        "menuAlignment": "Horizontal",
                        "externalLink": false,
                        "linkedElement": [],
                        "breakPointNumber": 0
                    },
                    {
                        "elementID": 1012,
                        "tenantID": 0,
                        "propertyID": 0,
                        "productID": 1,
                        "textID": 1092,
                        "text": "LOCKER CONTROL",
                        "routePath": "/shop/viewshop/lockercontrol",
                        "imgPath": "",
                        "order": 4,
                        "visibility": true,
                        "disable": false,
                        "parentID": 1011,
                        "menuPosition": "Secondary",
                        "menuAlignment": "Horizontal",
                        "externalLink": false,
                        "linkedElement": [],
                        "breakPointNumber": 0
                    }
                ],
                "breakPointNumber": 0
            },
            {
                "elementID": 1021,
                "tenantID": 0,
                "propertyID": 0,
                "productID": 1,
                "textID": 1002,
                "text": "REPORTS_DA",
                "routePath": "/reports",
                "imgPath": "icon-reports",
                "order": 5,
                "visibility": true,
                "disable": false,
                "parentID": 0,
                "menuPosition": "Primary",
                "menuAlignment": "Horizontal",
                "externalLink": false,
                "linkedElement": [
                    {
                        "elementID": 1022,
                        "tenantID": 0,
                        "propertyID": 0,
                        "productID": 1,
                        "textID": 1003,
                        "text": "APPOINTMENT_DA",
                        "routePath": "/reports/appointment",
                        "imgPath": "",
                        "order": 1,
                        "visibility": true,
                        "disable": false,
                        "parentID": 1021,
                        "menuPosition": "Secondary",
                        "menuAlignment": "Horizontal",
                        "externalLink": false,
                        "linkedElement": [],
                        "breakPointNumber": 0
                    },
                    {
                        "elementID": 1023,
                        "tenantID": 0,
                        "propertyID": 0,
                        "productID": 1,
                        "textID": 1004,
                        "text": "RETAIL_DA",
                        "routePath": "/reports/retail",
                        "imgPath": "",
                        "order": 3,
                        "visibility": true,
                        "disable": false,
                        "parentID": 1021,
                        "menuPosition": "Secondary",
                        "menuAlignment": "Horizontal",
                        "externalLink": false,
                        "linkedElement": [],
                        "breakPointNumber": 0
                    },
                    {
                        "elementID": 1024,
                        "tenantID": 0,
                        "propertyID": 0,
                        "productID": 1,
                        "textID": 1005,
                        "text": "CLIENT_DA",
                        "routePath": "/reports/client",
                        "imgPath": "",
                        "order": 4,
                        "visibility": true,
                        "disable": false,
                        "parentID": 1021,
                        "menuPosition": "Secondary",
                        "menuAlignment": "Horizontal",
                        "externalLink": false,
                        "linkedElement": [],
                        "breakPointNumber": 0
                    },
                    {
                        "elementID": 1025,
                        "tenantID": 0,
                        "propertyID": 0,
                        "productID": 1,
                        "textID": 1006,
                        "text": "COMMISSION/GRATUITY/SERVICE CHARGE_DA",
                        "routePath": "/reports/commission",
                        "imgPath": "",
                        "order": 5,
                        "visibility": true,
                        "disable": false,
                        "parentID": 1021,
                        "menuPosition": "Secondary",
                        "menuAlignment": "Horizontal",
                        "externalLink": false,
                        "linkedElement": [],
                        "breakPointNumber": 1245
                    },
                    {
                        "elementID": 1026,
                        "tenantID": 0,
                        "propertyID": 0,
                        "productID": 1,
                        "textID": 1007,
                        "text": "TRANSACTION LOG_DA",
                        "routePath": "/reports/transactionlog",
                        "imgPath": "",
                        "order": 6,
                        "visibility": true,
                        "disable": false,
                        "parentID": 1021,
                        "menuPosition": "Secondary",
                        "menuAlignment": "Horizontal",
                        "externalLink": false,
                        "linkedElement": [],
                        "breakPointNumber": 8005
                    },
                    {
                        "elementID": 1027,
                        "tenantID": 0,
                        "propertyID": 0,
                        "productID": 1,
                        "textID": 1008,
                        "text": "GIFT CARDS_DA",
                        "routePath": "/reports/giftcards",
                        "imgPath": "",
                        "order": 7,
                        "visibility": true,
                        "disable": false,
                        "parentID": 1021,
                        "menuPosition": "Secondary",
                        "menuAlignment": "Horizontal",
                        "externalLink": false,
                        "linkedElement": [],
                        "breakPointNumber": 0
                    },
                    {
                        "elementID": 1104,
                        "tenantID": 0,
                        "propertyID": 0,
                        "productID": 1,
                        "textID": 11386,
                        "text": "MEMBER_DA",
                        "routePath": "/reports/member",
                        "imgPath": "",
                        "order": 2,
                        "visibility": true,
                        "disable": false,
                        "parentID": 1021,
                        "menuPosition": "Secondary",
                        "menuAlignment": "Horizontal",
                        "externalLink": false,
                        "linkedElement": [],
                        "breakPointNumber": 0
                    },
                    {
                        "elementID": 6727,
                        "tenantID": 0,
                        "propertyID": 0,
                        "productID": 1,
                        "textID": 10006,
                        "text": "SECURITY_DA",
                        "routePath": "/reports/security",
                        "imgPath": "",
                        "order": 8,
                        "visibility": true,
                        "disable": false,
                        "parentID": 1021,
                        "menuPosition": "Secondary",
                        "menuAlignment": "Horizontal",
                        "externalLink": false,
                        "linkedElement": [],
                        "breakPointNumber": 0
                    }
                ],
                "breakPointNumber": 0
            },
            {
                "elementID": 1028,
                "tenantID": 0,
                "propertyID": 0,
                "productID": 1,
                "textID": 1089,
                "text": "AUDIT_DA",
                "routePath": "/audit",
                "imgPath": "icon-Audit",
                "order": 6,
                "visibility": true,
                "disable": false,
                "parentID": 0,
                "menuPosition": "Primary",
                "menuAlignment": "Horizontal",
                "externalLink": false,
                "linkedElement": [
                    {
                        "elementID": 1029,
                        "tenantID": 0,
                        "propertyID": 0,
                        "productID": 1,
                        "textID": 1090,
                        "text": "MANUAL AUDIT_DA",
                        "routePath": "/audit/dayend",
                        "imgPath": "",
                        "order": 1,
                        "visibility": true,
                        "disable": false,
                        "parentID": 1028,
                        "menuPosition": "Secondary",
                        "menuAlignment": "Horizontal",
                        "externalLink": false,
                        "linkedElement": [],
                        "breakPointNumber": 2430
                    },
                    {
                        "elementID": 1029,
                        "tenantID": 0,
                        "propertyID": 0,
                        "productID": 1,
                        "textID": 1090,
                        "text": "NIGHT AUDIT_DA",
                        "routePath": "/audit/nightaudit",
                        "imgPath": "",
                        "order": 1,
                        "visibility": true,
                        "disable": false,
                        "parentID": 1028,
                        "menuPosition": "Secondary",
                        "menuAlignment": "Horizontal",
                        "externalLink": false,
                        "linkedElement": [],
                        "breakPointNumber": 16025
                    }
                ],
                "breakPointNumber": 0
            },
            {
                "elementID": 1030,
                "tenantID": 0,
                "propertyID": 0,
                "productID": 1,
                "textID": 1009,
                "text": "SETTINGS_DA",
                "routePath": "/settings",
                "imgPath": "icon-settings",
                "order": 7,
                "visibility": true,
                "disable": false,
                "parentID": 0,
                "menuPosition": "Primary",
                "menuAlignment": "Horizontal",
                "externalLink": false,
                "linkedElement": [
                    {
                        "elementID": 1031,
                        "tenantID": 0,
                        "propertyID": 0,
                        "productID": 1,
                        "textID": 1010,
                        "text": "SETUP_DA",
                        "routePath": "/settings/setup",
                        "imgPath": "",
                        "order": 1,
                        "visibility": true,
                        "disable": false,
                        "parentID": 1030,
                        "menuPosition": "Secondary",
                        "menuAlignment": "Horizontal",
                        "externalLink": false,
                        "linkedElement": [
                            {
                                "elementID": 1032,
                                "tenantID": 0,
                                "propertyID": 0,
                                "productID": 1,
                                "textID": 1011,
                                "text": "Service Group_DA",
                                "routePath": "/settings/setup/servicegroup",
                                "imgPath": "",
                                "order": 1,
                                "visibility": true,
                                "disable": false,
                                "parentID": 1031,
                                "menuPosition": "Ternary",
                                "menuAlignment": "Vertical",
                                "externalLink": false,
                                "linkedElement": [],
                                "breakPointNumber": 2000
                            },
                            {
                                "elementID": 1032,
                                "tenantID": 0,
                                "propertyID": 0,
                                "productID": 1,
                                "textID": 1011,
                                "text": "Class Group",
                                "routePath": "/settings/setup/classgroup",
                                "imgPath": "",
                                "order": 1,
                                "visibility": true,
                                "disable": false,
                                "parentID": 1031,
                                "menuPosition": "Ternary",
                                "menuAlignment": "Vertical",
                                "externalLink": false,
                                "linkedElement": [],
                                "breakPointNumber": 2000
                            },
                            {
                                "elementID": 1038,
                                "tenantID": 0,
                                "propertyID": 0,
                                "productID": 1,
                                "textID": 1018,
                                "text": "Class Setup",
                                "routePath": "/settings/setup/classsetup",
                                "imgPath": "",
                                "order": 7,
                                "visibility": true,
                                "disable": false,
                                "parentID": 1031,
                                "menuPosition": "Ternary",
                                "menuAlignment": "Vertical",
                                "externalLink": false,
                                "linkedElement": [],
                                "breakPointNumber": 2030
                            },
                            {
                                "elementID": 1038,
                                "tenantID": 0,
                                "propertyID": 0,
                                "productID": 1,
                                "textID": 1018,
                                "text": "Instructor Setup",
                                "routePath": "/settings/setup/instructorsetup",
                                "imgPath": "",
                                "order": 7,
                                "visibility": true,
                                "disable": false,
                                "parentID": 1031,
                                "menuPosition": "Ternary",
                                "menuAlignment": "Vertical",
                                "externalLink": false,
                                "linkedElement": [],
                                "breakPointNumber": 2030
                            },
                            {
                                "elementID": 1038,
                                "tenantID": 0,
                                "propertyID": 0,
                                "productID": 1,
                                "textID": 1018,
                                "text": "Class Location",
                                "routePath": "/settings/setup/classlocation",
                                "imgPath": "",
                                "order": 7,
                                "visibility": true,
                                "disable": false,
                                "parentID": 1031,
                                "menuPosition": "Ternary",
                                "menuAlignment": "Vertical",
                                "externalLink": false,
                                "linkedElement": [],
                                "breakPointNumber": 2030
                            },
                            {
                                "elementID": 1038,
                                "tenantID": 0,
                                "propertyID": 0,
                                "productID": 1,
                                "textID": 1018,
                                "text": "Class Equipment",
                                "routePath": "/settings/setup/classequipment",
                                "imgPath": "",
                                "order": 7,
                                "visibility": true,
                                "disable": false,
                                "parentID": 1031,
                                "menuPosition": "Ternary",
                                "menuAlignment": "Vertical",
                                "externalLink": false,
                                "linkedElement": [],
                                "breakPointNumber": 2030
                            },
                            {
                                "elementID": 1033,
                                "tenantID": 0,
                                "propertyID": 0,
                                "productID": 1,
                                "textID": 1012,
                                "text": "Service Location_DA",
                                "routePath": "/settings/setup/servicelocation",
                                "imgPath": "",
                                "order": 2,
                                "visibility": true,
                                "disable": false,
                                "parentID": 1031,
                                "menuPosition": "Ternary",
                                "menuAlignment": "Vertical",
                                "externalLink": false,
                                "linkedElement": [],
                                "breakPointNumber": 2005
                            },
                            {
                                "elementID": 1034,
                                "tenantID": 0,
                                "propertyID": 0,
                                "productID": 1,
                                "textID": 1014,
                                "text": "Therapist Setup_DA",
                                "routePath": "/settings/setup/therapistsetup",
                                "imgPath": "",
                                "order": 3,
                                "visibility": true,
                                "disable": false,
                                "parentID": 1031,
                                "menuPosition": "Ternary",
                                "menuAlignment": "Vertical",
                                "externalLink": false,
                                "linkedElement": [],
                                "breakPointNumber": 2010
                            },
                            {
                                "elementID": 1035,
                                "tenantID": 0,
                                "propertyID": 0,
                                "productID": 1,
                                "textID": 1015,
                                "text": "Medical Condition_DA",
                                "routePath": "/settings/setup/medicalcondition",
                                "imgPath": "",
                                "order": 4,
                                "visibility": true,
                                "disable": false,
                                "parentID": 1031,
                                "menuPosition": "Ternary",
                                "menuAlignment": "Vertical",
                                "externalLink": false,
                                "linkedElement": [],
                                "breakPointNumber": 2015
                            },
                            {
                                "elementID": 1036,
                                "tenantID": 0,
                                "propertyID": 0,
                                "productID": 1,
                                "textID": 1016,
                                "text": "Service Add On_DA",
                                "routePath": "/settings/setup/serviceaddon",
                                "imgPath": "",
                                "order": 5,
                                "visibility": true,
                                "disable": false,
                                "parentID": 1031,
                                "menuPosition": "Ternary",
                                "menuAlignment": "Vertical",
                                "externalLink": false,
                                "linkedElement": [],
                                "breakPointNumber": 2020
                            },
                            {
                                "elementID": 1037,
                                "tenantID": 0,
                                "propertyID": 0,
                                "productID": 1,
                                "textID": 1017,
                                "text": "Service Equipment_DA",
                                "routePath": "/settings/setup/serviceequipment",
                                "imgPath": "",
                                "order": 6,
                                "visibility": true,
                                "disable": false,
                                "parentID": 1031,
                                "menuPosition": "Ternary",
                                "menuAlignment": "Vertical",
                                "externalLink": false,
                                "linkedElement": [],
                                "breakPointNumber": 2025
                            },
                            {
                                "elementID": 1038,
                                "tenantID": 0,
                                "propertyID": 0,
                                "productID": 1,
                                "textID": 1018,
                                "text": "Spa Service_DA",
                                "routePath": "/settings/setup/spaservice",
                                "imgPath": "",
                                "order": 7,
                                "visibility": true,
                                "disable": false,
                                "parentID": 1031,
                                "menuPosition": "Ternary",
                                "menuAlignment": "Vertical",
                                "externalLink": false,
                                "linkedElement": [],
                                "breakPointNumber": 2030
                            },
                            {
                                "elementID": 1039,
                                "tenantID": 0,
                                "propertyID": 0,
                                "productID": 1,
                                "textID": 1019,
                                "text": "Spa Package_DA",
                                "routePath": "/settings/setup/spapackage",
                                "imgPath": "",
                                "order": 8,
                                "visibility": true,
                                "disable": false,
                                "parentID": 1031,
                                "menuPosition": "Ternary",
                                "menuAlignment": "Vertical",
                                "externalLink": false,
                                "linkedElement": [],
                                "breakPointNumber": 2035
                            },
                            {
                                "elementID": 1040,
                                "tenantID": 0,
                                "propertyID": 0,
                                "productID": 1,
                                "textID": 1020,
                                "text": "Cancel Reason_DA",
                                "routePath": "/settings/setup/cancelreason",
                                "imgPath": "",
                                "order": 9,
                                "visibility": true,
                                "disable": false,
                                "parentID": 1031,
                                "menuPosition": "Ternary",
                                "menuAlignment": "Vertical",
                                "externalLink": false,
                                "linkedElement": [],
                                "breakPointNumber": 2040
                            },
                            {
                                "elementID": 1041,
                                "tenantID": 0,
                                "propertyID": 0,
                                "productID": 1,
                                "textID": 1021,
                                "text": "Package Classes_DA",
                                "routePath": "/settings/setup/packageclasses",
                                "imgPath": "",
                                "order": 10,
                                "visibility": true,
                                "disable": false,
                                "parentID": 1031,
                                "menuPosition": "Ternary",
                                "menuAlignment": "Vertical",
                                "externalLink": false,
                                "linkedElement": [],
                                "breakPointNumber": 2045
                            },
                            {
                                "elementID": 1042,
                                "tenantID": 0,
                                "propertyID": 0,
                                "productID": 1,
                                "textID": 1022,
                                "text": "Lunch Setup_DA",
                                "routePath": "/settings/setup/lunchsetup",
                                "imgPath": "",
                                "order": 11,
                                "visibility": true,
                                "disable": false,
                                "parentID": 1031,
                                "menuPosition": "Ternary",
                                "menuAlignment": "Vertical",
                                "externalLink": false,
                                "linkedElement": [],
                                "breakPointNumber": 2050
                            },
                            {
                                "elementID": 1043,
                                "tenantID": 0,
                                "propertyID": 0,
                                "productID": 1,
                                "textID": 1023,
                                "text": "Color Linking_DA",
                                "routePath": "/settings/setup/colorlinking",
                                "imgPath": "",
                                "order": 12,
                                "visibility": true,
                                "disable": false,
                                "parentID": 1031,
                                "menuPosition": "Ternary",
                                "menuAlignment": "Vertical",
                                "externalLink": false,
                                "linkedElement": [],
                                "breakPointNumber": 2055
                            },
                            {
                                "elementID": 1044,
                                "tenantID": 0,
                                "propertyID": 0,
                                "productID": 1,
                                "textID": 1024,
                                "text": "Break Type_DA",
                                "routePath": "/settings/setup/breaktype",
                                "imgPath": "",
                                "order": 13,
                                "visibility": true,
                                "disable": false,
                                "parentID": 1031,
                                "menuPosition": "Ternary",
                                "menuAlignment": "Vertical",
                                "externalLink": false,
                                "linkedElement": [],
                                "breakPointNumber": 2060
                            },
                            {
                                "elementID": 1045,
                                "tenantID": 0,
                                "propertyID": 0,
                                "productID": 1,
                                "textID": 1025,
                                "text": "Price Type_DA",
                                "routePath": "/settings/setup/pricetype",
                                "imgPath": "",
                                "order": 14,
                                "visibility": true,
                                "disable": false,
                                "parentID": 1031,
                                "menuPosition": "Ternary",
                                "menuAlignment": "Vertical",
                                "externalLink": false,
                                "linkedElement": [],
                                "breakPointNumber": 2065
                            },
                            {
                                "elementID": 1046,
                                "tenantID": 0,
                                "propertyID": 0,
                                "productID": 1,
                                "textID": 1026,
                                "text": "Custom Fields_DA",
                                "routePath": "/settings/setup/customfields",
                                "imgPath": "",
                                "order": 15,
                                "visibility": true,
                                "disable": false,
                                "parentID": 1031,
                                "menuPosition": "Ternary",
                                "menuAlignment": "Vertical",
                                "externalLink": false,
                                "linkedElement": [],
                                "breakPointNumber": 2070
                            },
                            {
                                "elementID": 7603,
                                "tenantID": 0,
                                "propertyID": 0,
                                "productID": 1,
                                "textID": 11661,
                                "text": "Lost/Denied Reason",
                                "routePath": "/settings/setup/lostdeniedreasons",
                                "imgPath": "",
                                "order": 16,
                                "visibility": true,
                                "disable": false,
                                "parentID": 1031,
                                "menuPosition": "Ternary",
                                "menuAlignment": "Vertical",
                                "externalLink": false,
                                "linkedElement": [],
                                "breakPointNumber": 0
                            },
                            {
                                "elementID": 1032,
                                "tenantID": 0,
                                "propertyID": 0,
                                "productID": 1,
                                "textID": 1011,
                                "text": "Log Type_DA",
                                "routePath": "/settings/setup/logtype",
                                "imgPath": "",
                                "order": 17,
                                "visibility": true,
                                "disable": false,
                                "parentID": 1031,
                                "menuPosition": "Ternary",
                                "menuAlignment": "Vertical",
                                "externalLink": false,
                                "linkedElement": [],

                            },
                            {
                                "elementID": 1032,
                                "tenantID": 0,
                                "propertyID": 0,
                                "productID": 1,
                                "textID": 1011,
                                "text": "Guest Type_DA",
                                "routePath": "/settings/setup/guestType",
                                "imgPath": "",
                                "order": 17,
                                "visibility": true,
                                "disable": false,
                                "parentID": 1031,
                                "menuPosition": "Ternary",
                                "menuAlignment": "Vertical",
                                "externalLink": false,
                                "linkedElement": [],

                            },
                            {
                                "elementID": 1033,
                                "tenantID": 0,
                                "propertyID": 0,
                                "productID": 1,
                                "textID": 1011,
                                "text": "Relationship_DA",
                                "routePath": "/settings/setup/relationship",
                                "imgPath": "",
                                "order": 18,
                                "visibility": true,
                                "disable": false,
                                "parentID": 1031,
                                "menuPosition": "Ternary",
                                "menuAlignment": "Vertical",
                                "externalLink": false,
                                "linkedElement": [],

                            },
                            {
                                "elementID": 1034,
                                "tenantID": 0,
                                "propertyID": 0,
                                "productID": 1,
                                "textID": 1011,
                                "text": "VIP TYPE_DA",
                                "routePath": "/settings/setup/viptype",
                                "imgPath": "",
                                "order": 19,
                                "visibility": true,
                                "disable": false,
                                "parentID": 1031,
                                "menuPosition": "Ternary",
                                "menuAlignment": "Vertical",
                                "externalLink": false,
                                "linkedElement": [],
                            },
                            {
                                "elementID": 1034,
                                "tenantID": 0,
                                "propertyID": 0,
                                "productID": 1,
                                "textID": 1011,
                                "text": "Cancellation/No Show Policy",
                                "routePath": "/settings/setup/cancellationOrNoShowPolicy",
                                "imgPath": "",
                                "order": 19,
                                "visibility": true,
                                "disable": false,
                                "parentID": 1031,
                                "menuPosition": "Ternary",
                                "menuAlignment": "Vertical",
                                "externalLink": false,
                                "linkedElement": [],
                            },
                            {
                                "elementID": 1034,
                                "tenantID": 0,
                                "propertyID": 0,
                                "productID": 1,
                                "textID": 1011,
                                "text": "Cancellation/No Show Policy Mapping",
                                "routePath": "/settings/setup/cancellationOrNoShowPolicyMapping",
                                "imgPath": "",
                                "order": 19,
                                "visibility": true,
                                "disable": false,
                                "parentID": 1031,
                                "menuPosition": "Ternary",
                                "menuAlignment": "Vertical",
                                "externalLink": false,
                                "linkedElement": [],
                            }
                        ],
                        "breakPointNumber": 0
                    },
                    {
                        "elementID": 1047,
                        "tenantID": 0,
                        "propertyID": 0,
                        "productID": 1,
                        "textID": 1027,
                        "text": "STAFF SCHEDULE_DA",
                        "routePath": "/settings/staffSchedule",
                        "imgPath": "",
                        "order": 2,
                        "visibility": true,
                        "disable": false,
                        "parentID": 1030,
                        "menuPosition": "Secondary",
                        "menuAlignment": "Horizontal",
                        "externalLink": false,
                        "linkedElement": [],
                        "breakPointNumber": 2215
                    },
                    {
                        "elementID": 1047,
                        "tenantID": 0,
                        "propertyID": 0,
                        "productID": 1,
                        "textID": 1027,
                        "text": "INSTRUCTOR SCHEDULE",
                        "routePath": "/settings/instructorSchedule",
                        "imgPath": "",
                        "order": 2,
                        "visibility": true,
                        "disable": false,
                        "parentID": 1030,
                        "menuPosition": "Secondary",
                        "menuAlignment": "Horizontal",
                        "externalLink": false,
                        "linkedElement": [],
                        "breakPointNumber": 2215
                    },
                    {
                        "elementID": 1048,
                        "tenantID": 0,
                        "propertyID": 0,
                        "productID": 1,
                        "textID": 1028,
                        "text": "COMMISSION SETUP_DA",
                        "routePath": "/settings/commissionsetup",
                        "imgPath": "",
                        "order": 3,
                        "visibility": true,
                        "disable": false,
                        "parentID": 1030,
                        "menuPosition": "Secondary",
                        "menuAlignment": "Horizontal",
                        "externalLink": false,
                        "linkedElement": [],
                        "breakPointNumber": 800
                    },
                    {
                        "elementID": 1049,
                        "tenantID": 0,
                        "propertyID": 0,
                        "productID": 1,
                        "textID": 1029,
                        "text": "PACKAGE YIELDING_DA",
                        "routePath": "/settings/packageyielding",
                        "imgPath": "",
                        "order": 4,
                        "visibility": true,
                        "disable": false,
                        "parentID": 1030,
                        "menuPosition": "Secondary",
                        "menuAlignment": "Horizontal",
                        "externalLink": false,
                        "linkedElement": [],
                        "breakPointNumber": 2075
                    },
                    {
                        "elementID": 1050,
                        "tenantID": 0,
                        "propertyID": 0,
                        "productID": 1,
                        "textID": 1030,
                        "text": "SERVICE YIELDING_DA",
                        "routePath": "/settings/serviceyielding",
                        "imgPath": "",
                        "order": 5,
                        "visibility": true,
                        "disable": false,
                        "parentID": 1030,
                        "menuPosition": "Secondary",
                        "menuAlignment": "Horizontal",
                        "externalLink": false,
                        "linkedElement": [],
                        "breakPointNumber": 2080
                    },
                    {
                        "elementID": 1051,
                        "tenantID": 0,
                        "propertyID": 0,
                        "productID": 1,
                        "textID": 1031,
                        "text": "UTILITIES_DA",
                        "routePath": "/settings/utilities",
                        "imgPath": "",
                        "order": 6,
                        "visibility": true,
                        "disable": false,
                        "parentID": 1030,
                        "menuPosition": "Secondary",
                        "menuAlignment": "Horizontal",
                        "externalLink": false,
                        "linkedElement": [
                            {
                                "elementID": 1052,
                                "tenantID": 0,
                                "propertyID": 0,
                                "productID": 1,
                                "textID": 1032,
                                "text": "Spa Occupancy",
                                "routePath": "/settings/utilities/spaOccupancy",
                                "imgPath": "",
                                "order": 1,
                                "visibility": true,
                                "disable": false,
                                "parentID": 1051,
                                "menuPosition": "Ternary",
                                "menuAlignment": "Vertical",
                                "externalLink": false,
                                "linkedElement": [],
                                "breakPointNumber": 2400
                            },
                            {
                                "elementID": 1052,
                                "tenantID": 0,
                                "propertyID": 0,
                                "productID": 1,
                                "textID": 1032,
                                "text": "Equipment Maintenance_DA",
                                "routePath": "/settings/utilities/equipmentmaintenance",
                                "imgPath": "",
                                "order": 1,
                                "visibility": true,
                                "disable": false,
                                "parentID": 1051,
                                "menuPosition": "Ternary",
                                "menuAlignment": "Vertical",
                                "externalLink": false,
                                "linkedElement": [],
                                "breakPointNumber": 2400
                            },
                            {
                                "elementID": 1199,
                                "tenantID": 0,
                                "propertyID": 0,
                                "productID": 1,
                                "textID": 1032,
                                "text": "Config Validation_DA",
                                "routePath": "/settings/utilities/configValidation",
                                "imgPath": "",
                                "order": 1,
                                "visibility": true,
                                "disable": false,
                                "parentID": 1051,
                                "menuPosition": "Ternary",
                                "menuAlignment": "Vertical",
                                "externalLink": false,
                                "linkedElement": [],
                                "breakPointNumber": 2409
                            },
                            {
                                "elementID": 1053,
                                "tenantID": 0,
                                "propertyID": 0,
                                "productID": 1,
                                "textID": 1033,
                                "text": "Location Maintenance_DA",
                                "routePath": "/settings/utilities/locationmaintenance",
                                "imgPath": "",
                                "order": 2,
                                "visibility": true,
                                "disable": false,
                                "parentID": 1051,
                                "menuPosition": "Ternary",
                                "menuAlignment": "Vertical",
                                "externalLink": false,
                                "linkedElement": [],
                                "breakPointNumber": 2405
                            },
                            {
                                "elementID": 1054,
                                "tenantID": 0,
                                "propertyID": 0,
                                "productID": 1,
                                "textID": 1034,
                                "text": "Therapist Unavailability_DA",
                                "routePath": "/settings/utilities/therapistunavailability",
                                "imgPath": "",
                                "order": 3,
                                "visibility": true,
                                "disable": false,
                                "parentID": 1051,
                                "menuPosition": "Ternary",
                                "menuAlignment": "Vertical",
                                "externalLink": false,
                                "linkedElement": [],
                                "breakPointNumber": 2410
                            },
                            {
                                "elementID": 1055,
                                "tenantID": 0,
                                "propertyID": 0,
                                "productID": 1,
                                "textID": 1035,
                                "text": "Move Multiple Appointments_DA",
                                "routePath": "/settings/utilities/movemultipleappointments",
                                "imgPath": "",
                                "order": 4,
                                "visibility": true,
                                "disable": false,
                                "parentID": 1051,
                                "menuPosition": "Ternary",
                                "menuAlignment": "Vertical",
                                "externalLink": false,
                                "linkedElement": [],
                                "breakPointNumber": 2415
                            },
                            {
                                "elementID": 1056,
                                "tenantID": 0,
                                "propertyID": 0,
                                "productID": 1,
                                "textID": 1036,
                                "text": "Receipt Configuration_DA",
                                "routePath": "/settings/utilities/receiptconfiguration",
                                "imgPath": "",
                                "order": 5,
                                "visibility": true,
                                "disable": false,
                                "parentID": 1051,
                                "menuPosition": "Ternary",
                                "menuAlignment": "Vertical",
                                "externalLink": false,
                                "linkedElement": [],
                                "breakPointNumber": 7080
                            },
                            {
                                "elementID": 1057,
                                "tenantID": 0,
                                "propertyID": 0,
                                "productID": 1,
                                "textID": 1037,
                                "text": "User/Machine Configuration_DA",
                                "routePath": "/settings/utilities/usermachineconfiguration",
                                "imgPath": "",
                                "order": 6,
                                "visibility": true,
                                "disable": false,
                                "parentID": 1051,
                                "menuPosition": "Ternary",
                                "menuAlignment": "Vertical",
                                "externalLink": false,
                                "linkedElement": [],
                                "breakPointNumber": 7085
                            },
                            {
                                "elementID": 1058,
                                "tenantID": 0,
                                "propertyID": 0,
                                "productID": 1,
                                "textID": 1038,
                                "text": "Templates_DA",
                                "routePath": "/settings/utilities/templates",
                                "imgPath": "",
                                "order": 7,
                                "visibility": true,
                                "disable": false,
                                "parentID": 1051,
                                "menuPosition": "Ternary",
                                "menuAlignment": "Vertical",
                                "externalLink": false,
                                "linkedElement": [
                                    {
                                        "elementID": 1059,
                                        "tenantID": 0,
                                        "propertyID": 0,
                                        "productID": 1,
                                        "textID": 1039,
                                        "text": "Email Templates_DA",
                                        "routePath": "/settings/utilities/templates/email",
                                        "imgPath": "",
                                        "order": 1,
                                        "visibility": true,
                                        "disable": false,
                                        "parentID": 1058,
                                        "menuPosition": "Ternary",
                                        "menuAlignment": "Vertical",
                                        "externalLink": false,
                                        "linkedElement": [],
                                        "breakPointNumber": 0
                                    },
                                    {
                                        "elementID": 1060,
                                        "tenantID": 0,
                                        "propertyID": 0,
                                        "productID": 1,
                                        "textID": 1040,
                                        "text": "SMS Templates_DA",
                                        "routePath": "/settings/utilities/templates/sms",
                                        "imgPath": "",
                                        "order": 2,
                                        "visibility": true,
                                        "disable": false,
                                        "parentID": 1058,
                                        "menuPosition": "Ternary",
                                        "menuAlignment": "Vertical",
                                        "externalLink": false,
                                        "linkedElement": [],
                                        "breakPointNumber": 0
                                    },
                                    {
                                        "elementID": 1102,
                                        "tenantID": 0,
                                        "propertyID": 0,
                                        "productID": 1,
                                        "textID": 11206,
                                        "text": "Report Templates_DA",
                                        "routePath": "/settings/utilities/templates/report",
                                        "imgPath": "",
                                        "order": 3,
                                        "visibility": true,
                                        "disable": false,
                                        "parentID": 1058,
                                        "menuPosition": "Ternary",
                                        "menuAlignment": "Vertical",
                                        "externalLink": false,
                                        "linkedElement": [],
                                        "breakPointNumber": 0
                                    }
                                ],
                                "breakPointNumber": 0
                            },
                            {
                                "elementID": 1061,
                                "tenantID": 0,
                                "propertyID": 0,
                                "productID": 1,
                                "textID": 1041,
                                "text": "Distribution List_DA",
                                "routePath": "/settings/utilities/distributionlist",
                                "imgPath": "",
                                "order": 8,
                                "visibility": true,
                                "disable": false,
                                "parentID": 1051,
                                "menuPosition": "Ternary",
                                "menuAlignment": "Vertical",
                                "externalLink": false,
                                "linkedElement": [],
                                "breakPointNumber": 0
                            },
                            {
                                "elementID": 1098,
                                "tenantID": 0,
                                "propertyID": 0,
                                "productID": 1,
                                "textID": 1123,
                                "text": "Quick ID Config_DA",
                                "routePath": "/settings/utilities/quickidconfig",
                                "imgPath": "",
                                "order": 9,
                                "visibility": true,
                                "disable": false,
                                "parentID": 1051,
                                "menuPosition": "Ternary",
                                "menuAlignment": "Vertical",
                                "externalLink": false,
                                "linkedElement": [],
                                "breakPointNumber": 0
                            },
                            {
                                "elementID": 1099,
                                "tenantID": 0,
                                "propertyID": 0,
                                "productID": 1,
                                "textID": 1124,
                                "text": "Combine Guest Records_DA",
                                "routePath": "/settings/utilities/combineguest",
                                "imgPath": "",
                                "order": 10,
                                "visibility": true,
                                "disable": false,
                                "parentID": 1051,
                                "menuPosition": "Ternary",
                                "menuAlignment": "Vertical",
                                "externalLink": false,
                                "linkedElement": [],
                                "breakPointNumber": 0
                            },
                            {
                                "elementID": 1099,
                                "tenantID": 0,
                                "propertyID": 0,
                                "productID": 1,
                                "textID": 1124,
                                "text": "Dedupe Guest Profiles_DA",
                                "routePath": "/settings/utilities/dedupeguest",
                                "imgPath": "",
                                "order": 10,
                                "visibility": true,
                                "disable": false,
                                "parentID": 1051,
                                "menuPosition": "Ternary",
                                "menuAlignment": "Vertical",
                                "externalLink": false,
                                "linkedElement": [],
                                "breakPointNumber": 0
                            },
                            {
                                "elementID": 1099,
                                "tenantID": 0,
                                "propertyID": 0,
                                "productID": 1,
                                "textID": 1124,
                                "text": "Player Type/ Discount Type",
                                "routePath": "/settings/utilities/serviceDiscountType",
                                "imgPath": "",
                                "order": 10,
                                "visibility": true,
                                "disable": false,
                                "parentID": 1051,
                                "menuPosition": "Ternary",
                                "menuAlignment": "Vertical",
                                "externalLink": false,
                                "linkedElement": [],
                                "breakPointNumber": 0
                            },
                            {
                                "elementID": 1099,
                                "tenantID": 0,
                                "propertyID": 0,
                                "productID": 1,
                                "textID": 1124,
                                "text": "Other Components/ Discount Mapping",
                                "routePath": "/settings/utilities/discountMapping",
                                "imgPath": "",
                                "order": 10,
                                "visibility": true,
                                "disable": false,
                                "parentID": 1051,
                                "menuPosition": "Ternary",
                                "menuAlignment": "Vertical",
                                "externalLink": false,
                                "linkedElement": [],
                                "breakPointNumber": 0
                            },
                            {
                                "elementID": 1101,
                                "tenantID": 0,
                                "propertyID": 0,
                                "productID": 1,
                                "textID": 1124,
                                "text": "CGPS Failed Profile",
                                "routePath": "/settings/utilities/cgpsFailedProfile",
                                "imgPath": "",
                                "order": 12,
                                "visibility": true,
                                "disable": false,
                                "parentID": 1051,
                                "menuPosition": "Ternary",
                                "menuAlignment": "Vertical",
                                "externalLink": false,
                                "linkedElement": [],
                                "breakPointNumber": 0
                            },
                            {
                                "elementID": 1100,
                                "tenantID": 0,
                                "propertyID": 0,
                                "productID": 1,
                                "textID": 10791,
                                "text": "Manager Utilities_DA",
                                "routePath": "/settings/utilities/managerUtilities",
                                "imgPath": "",
                                "order": 1,
                                "visibility": true,
                                "disable": false,
                                "parentID": 1051,
                                "menuPosition": "Ternary",
                                "menuAlignment": "Vertical",
                                "externalLink": false,
                                "linkedElement": [
                                    {
                                        "elementID": 1101,
                                        "tenantID": 0,
                                        "propertyID": 0,
                                        "productID": 1,
                                        "textID": 10796,
                                        "text": "Printer Default Configuration_DA",
                                        "routePath": "/settings/utilities/managerUtilities/printerDefaultConfiguration",
                                        "imgPath": "",
                                        "order": 1,
                                        "visibility": true,
                                        "disable": false,
                                        "parentID": 1100,
                                        "menuPosition": "Ternary",
                                        "menuAlignment": "Vertical",
                                        "externalLink": false,
                                        "linkedElement": [],
                                        "breakPointNumber": 0
                                    }
                                ],
                                "breakPointNumber": 0
                            },
                            {
                                "elementID": 1111,
                                "tenantID": 0,
                                "propertyID": 0,
                                "productID": 1,
                                "textID": 1038,
                                "text": "Job Scheduler",
                                "routePath": "/settings/utilities/jobscheduler",
                                "imgPath": "",
                                "order": 7,
                                "visibility": true,
                                "disable": false,
                                "parentID": 1051,
                                "menuPosition": "Ternary",
                                "menuAlignment": "Vertical",
                                "externalLink": false,
                                "linkedElement": [
                                    {
                                        "elementID": 1103,
                                        "tenantID": 0,
                                        "propertyID": 0,
                                        "productID": 1,
                                        "textID": 1039,
                                        "text": "Job Scheduler Configuration",
                                        "routePath": "/settings/utilities/jobscheduler/jobschedulerconfiguration",
                                        "imgPath": "",
                                        "order": 1,
                                        "visibility": true,
                                        "disable": false,
                                        "parentID": 1111,
                                        "menuPosition": "Ternary",
                                        "menuAlignment": "Vertical",
                                        "externalLink": false,
                                        "linkedElement": [],
                                        "breakPointNumber": 0
                                    },
                                    {
                                        "elementID": 1104,
                                        "tenantID": 0,
                                        "propertyID": 0,
                                        "productID": 1,
                                        "textID": 1040,
                                        "text": "SFTP",
                                        "routePath": "/settings/utilities/jobscheduler/sftp",
                                        "imgPath": "",
                                        "order": 2,
                                        "visibility": true,
                                        "disable": false,
                                        "parentID": 1111,
                                        "menuPosition": "Ternary",
                                        "menuAlignment": "Vertical",
                                        "externalLink": false,
                                        "linkedElement": [],
                                        "breakPointNumber": 0
                                    },
                                    {
                                        "elementID": 1105,
                                        "tenantID": 0,
                                        "propertyID": 0,
                                        "productID": 1,
                                        "textID": 11206,
                                        "text": "Job Scheduler",
                                        "routePath": "/settings/utilities/jobscheduler/jobscheduler",
                                        "imgPath": "",
                                        "order": 3,
                                        "visibility": true,
                                        "disable": false,
                                        "parentID": 1111,
                                        "menuPosition": "Ternary",
                                        "menuAlignment": "Vertical",
                                        "externalLink": false,
                                        "linkedElement": [],
                                        "breakPointNumber": 0
                                    }
                                ],
                                "breakPointNumber": 0
                            },
                            {
                                "elementID": 1111,
                                "tenantID": 0,
                                "propertyID": 0,
                                "productID": 1,
                                "textID": 1038,
                                "text": "Lockers",
                                "routePath": "/settings/utilities/lockers",
                                "imgPath": "",
                                "order": 13,
                                "visibility": true,
                                "disable": false,
                                "parentID": 1051,
                                "menuPosition": "Ternary",
                                "menuAlignment": "Vertical",
                                "externalLink": false,
                                "linkedElement": [],
                                "breakPointNumber": 0
                            },
                            {
                                "elementID": 8447,
                                "tenantID": 0,
                                "propertyID": 0,
                                "productID": 1,
                                "textID": 102526,
                                "text": "Deposit Policy",
                                "routePath": "/settings/utilities/depositpolicy",
                                "imgPath": "",
                                "order": 11,
                                "visibility": true,
                                "disable": false,
                                "parentID": 1051,
                                "menuPosition": "Ternary",
                                "menuAlignment": "Vertical",
                                "externalLink": false,
                                "linkedElement": [
                                  {
                                    "elementID": 8448,
                                    "tenantID": 0,
                                    "propertyID": 0,
                                    "productID": 1,
                                    "textID": 102531,
                                    "text": "Policy",
                                    "routePath": "/settings/utilities/depositpolicy/policy",
                                    "imgPath": "",
                                    "order": 1,
                                    "visibility": true,
                                    "disable": false,
                                    "parentID": 8447,
                                    "menuPosition": "Ternary",
                                    "menuAlignment": "Vertical",
                                    "externalLink": false,
                                    "linkedElement": [],
                                    "breakPointNumber": 2000151,
                                    "keyboardShortcut": null
                                  },
                                  {
                                    "elementID": 8449,
                                    "tenantID": 0,
                                    "propertyID": 0,
                                    "productID": 1,
                                    "textID": 102536,
                                    "text": "Policy Mapping",
                                    "routePath": "/settings/utilities/depositpolicy/mapping",
                                    "imgPath": "",
                                    "order": 2,
                                    "visibility": true,
                                    "disable": false,
                                    "parentID": 8447,
                                    "menuPosition": "Ternary",
                                    "menuAlignment": "Vertical",
                                    "externalLink": false,
                                    "linkedElement": [],
                                    "breakPointNumber": 2000152,
                                    "keyboardShortcut": null
                                  }
                                ],
                                "breakPointNumber": 2000151,
                                "keyboardShortcut": null
                              },
                        ],
                        "breakPointNumber": 0
                    },
                    {
                        "elementID": 1062,
                        "tenantID": 0,
                        "propertyID": 0,
                        "productID": 1,
                        "textID": 1042,
                        "text": "RETAIL SETUP_DA",
                        "routePath": "/settings/retailsetup",
                        "imgPath": "",
                        "order": 7,
                        "visibility": true,
                        "disable": false,
                        "parentID": 1030,
                        "menuPosition": "Secondary",
                        "menuAlignment": "Horizontal",
                        "externalLink": false,
                        "linkedElement": [
                            {
                                "elementID": 1063,
                                "tenantID": 0,
                                "propertyID": 0,
                                "productID": 1,
                                "textID": 1043,
                                "text": "Code Setup_DA",
                                "routePath": "/settings/retailsetup/codesetup",
                                "imgPath": "",
                                "order": 1,
                                "visibility": true,
                                "disable": false,
                                "parentID": 1062,
                                "menuPosition": "Ternary",
                                "menuAlignment": "Combo",
                                "externalLink": false,
                                "linkedElement": [
                                    {
                                        "elementID": 1064,
                                        "tenantID": 0,
                                        "propertyID": 0,
                                        "productID": 1,
                                        "textID": 1044,
                                        "text": "Credit Card Terminals_DA",
                                        "routePath": "/settings/retailsetup/codesetup/creditcardterminals",
                                        "imgPath": "",
                                        "order": 1,
                                        "visibility": true,
                                        "disable": false,
                                        "parentID": 1063,
                                        "menuPosition": "Ternary",
                                        "menuAlignment": "Vertical",
                                        "externalLink": false,
                                        "linkedElement": [],
                                        "breakPointNumber": 7025
                                    },
                                    {
                                        "elementID": 1065,
                                        "tenantID": 0,
                                        "propertyID": 0,
                                        "productID": 1,
                                        "textID": 1045,
                                        "text": "Outlets_DA",
                                        "routePath": "/settings/retailsetup/codesetup/outlets",
                                        "imgPath": "",
                                        "order": 2,
                                        "visibility": true,
                                        "disable": false,
                                        "parentID": 1063,
                                        "menuPosition": "Ternary",
                                        "menuAlignment": "Vertical",
                                        "externalLink": false,
                                        "linkedElement": [],
                                        "breakPointNumber": 7000
                                    },
                                    {
                                        "elementID": 1066,
                                        "tenantID": 0,
                                        "propertyID": 0,
                                        "productID": 1,
                                        "textID": 1079,
                                        "text": "Store Terminals_DA",
                                        "routePath": "/settings/retailsetup/codesetup/storeterminals",
                                        "imgPath": "",
                                        "order": 3,
                                        "visibility": true,
                                        "disable": false,
                                        "parentID": 1063,
                                        "menuPosition": "Ternary",
                                        "menuAlignment": "Vertical",
                                        "externalLink": false,
                                        "linkedElement": [],
                                        "breakPointNumber": 7090
                                    },
                                    {
                                        "elementID": 1067,
                                        "tenantID": 0,
                                        "propertyID": 0,
                                        "productID": 1,
                                        "textID": 1046,
                                        "text": "Category Groups_DA",
                                        "routePath": "/settings/retailsetup/codesetup/categorygroups",
                                        "imgPath": "",
                                        "order": 4,
                                        "visibility": true,
                                        "disable": false,
                                        "parentID": 1063,
                                        "menuPosition": "Ternary",
                                        "menuAlignment": "Vertical",
                                        "externalLink": false,
                                        "linkedElement": [],
                                        "breakPointNumber": 7030
                                    },
                                    {
                                        "elementID": 1068,
                                        "tenantID": 0,
                                        "propertyID": 0,
                                        "productID": 1,
                                        "textID": 1047,
                                        "text": "Retail Categories_DA",
                                        "routePath": "/settings/retailsetup/codesetup/retailcategories",
                                        "imgPath": "",
                                        "order": 5,
                                        "visibility": true,
                                        "disable": false,
                                        "parentID": 1063,
                                        "menuPosition": "Ternary",
                                        "menuAlignment": "Vertical",
                                        "externalLink": false,
                                        "linkedElement": [],
                                        "breakPointNumber": 7035
                                    },
                                    {
                                        "elementID": 1069,
                                        "tenantID": 0,
                                        "propertyID": 0,
                                        "productID": 1,
                                        "textID": 1048,
                                        "text": "Retail Sub Categories_DA",
                                        "routePath": "/settings/retailsetup/codesetup/retailsubcategories",
                                        "imgPath": "",
                                        "order": 6,
                                        "visibility": true,
                                        "disable": false,
                                        "parentID": 1063,
                                        "menuPosition": "Ternary",
                                        "menuAlignment": "Vertical",
                                        "externalLink": false,
                                        "linkedElement": [],
                                        "breakPointNumber": 7040
                                    },
                                    {
                                        "elementID": 1070,
                                        "tenantID": 0,
                                        "propertyID": 0,
                                        "productID": 1,
                                        "textID": 1049,
                                        "text": "Unit of Measure_DA",
                                        "routePath": "/settings/retailsetup/codesetup/unitofmeasure",
                                        "imgPath": "",
                                        "order": 7,
                                        "visibility": true,
                                        "disable": false,
                                        "parentID": 1063,
                                        "menuPosition": "Ternary",
                                        "menuAlignment": "Vertical",
                                        "externalLink": false,
                                        "linkedElement": [],
                                        "breakPointNumber": 7015
                                    },
                                    {
                                        "elementID": 1071,
                                        "tenantID": 0,
                                        "propertyID": 0,
                                        "productID": 1,
                                        "textID": 1050,
                                        "text": "Taxes_DA",
                                        "routePath": "/settings/retailsetup/codesetup/taxes",
                                        "imgPath": "",
                                        "order": 8,
                                        "visibility": true,
                                        "disable": false,
                                        "parentID": 1063,
                                        "menuPosition": "Ternary",
                                        "menuAlignment": "Vertical",
                                        "externalLink": false,
                                        "linkedElement": [],
                                        "breakPointNumber": 7050
                                    },
                                    {
                                        "elementID": 1072,
                                        "tenantID": 0,
                                        "propertyID": 0,
                                        "productID": 1,
                                        "textID": 1051,
                                        "text": "Discount Types_DA",
                                        "routePath": "/settings/retailsetup/codesetup/discounttypes",
                                        "imgPath": "",
                                        "order": 9,
                                        "visibility": true,
                                        "disable": false,
                                        "parentID": 1063,
                                        "menuPosition": "Ternary",
                                        "menuAlignment": "Vertical",
                                        "externalLink": false,
                                        "linkedElement": [],
                                        "breakPointNumber": 7060
                                    },
                                    {
                                        "elementID": 1073,
                                        "tenantID": 0,
                                        "propertyID": 0,
                                        "productID": 1,
                                        "textID": 1052,
                                        "text": "Payment Methods_DA",
                                        "routePath": "/settings/retailsetup/codesetup/paymentmethods",
                                        "imgPath": "",
                                        "order": 10,
                                        "visibility": true,
                                        "disable": false,
                                        "parentID": 1063,
                                        "menuPosition": "Ternary",
                                        "menuAlignment": "Vertical",
                                        "externalLink": false,
                                        "linkedElement": [],
                                        "breakPointNumber": 7070
                                    },
                                    {
                                        "elementID": 1074,
                                        "tenantID": 0,
                                        "propertyID": 0,
                                        "productID": 1,
                                        "textID": 1053,
                                        "text": "Quick Sale Categories_DA",
                                        "routePath": "/settings/retailsetup/codesetup/quicksalecategories",
                                        "imgPath": "",
                                        "order": 11,
                                        "visibility": true,
                                        "disable": false,
                                        "parentID": 1063,
                                        "menuPosition": "Ternary",
                                        "menuAlignment": "Vertical",
                                        "externalLink": false,
                                        "linkedElement": [],
                                        "breakPointNumber": 7010
                                    },
                                    {
                                        "elementID": 1075,
                                        "tenantID": 0,
                                        "propertyID": 0,
                                        "productID": 1,
                                        "textID": 1054,
                                        "text": "VAT Configuration_DA",
                                        "routePath": "/settings/retailsetup/codesetup/vatconfiguration",
                                        "imgPath": "",
                                        "order": 12,
                                        "visibility": true,
                                        "disable": false,
                                        "parentID": 1063,
                                        "menuPosition": "Ternary",
                                        "menuAlignment": "Vertical",
                                        "externalLink": false,
                                        "linkedElement": [],
                                        "breakPointNumber": 0
                                    },
                                    {
                                        "elementID": 1076,
                                        "tenantID": 0,
                                        "propertyID": 0,
                                        "productID": 1,
                                        "textID": 1055,
                                        "text": "Retail Feature Configuration_DA",
                                        "routePath": "/settings/retailsetup/codesetup/retailfeatureconfiguration",
                                        "imgPath": "",
                                        "order": 13,
                                        "visibility": true,
                                        "disable": false,
                                        "parentID": 1063,
                                        "menuPosition": "Ternary",
                                        "menuAlignment": "Vertical",
                                        "externalLink": false,
                                        "linkedElement": [],
                                        "breakPointNumber": 0
                                    },
                                    {
                                        "elementID": 1077,
                                        "tenantID": 0,
                                        "propertyID": 0,
                                        "productID": 1,
                                        "textID": 1056,
                                        "text": "Credit Cards_DA",
                                        "routePath": "/settings/retailsetup/codesetup/creditcards",
                                        "imgPath": "",
                                        "order": 14,
                                        "visibility": true,
                                        "disable": false,
                                        "parentID": 1063,
                                        "menuPosition": "Ternary",
                                        "menuAlignment": "Vertical",
                                        "externalLink": false,
                                        "linkedElement": [],
                                        "breakPointNumber": 0
                                    },
                                    {
                                        "elementID": 1078,
                                        "tenantID": 0,
                                        "propertyID": 0,
                                        "productID": 1,
                                        "textID": 1057,
                                        "text": "Gift Cards_DA",
                                        "routePath": "/settings/retailsetup/codesetup/giftcards",
                                        "imgPath": "",
                                        "order": 15,
                                        "visibility": true,
                                        "disable": false,
                                        "parentID": 1063,
                                        "menuPosition": "Ternary",
                                        "menuAlignment": "Vertical",
                                        "externalLink": false,
                                        "linkedElement": [],
                                        "breakPointNumber": 0
                                    },
                                    {
                                        "elementID": 1097,
                                        "tenantID": 0,
                                        "propertyID": 0,
                                        "productID": 1,
                                        "textID": 1102,
                                        "text": "Vendor Type_DA",
                                        "routePath": "/settings/retailsetup/codesetup/vendortypes",
                                        "imgPath": "",
                                        "order": 15,
                                        "visibility": true,
                                        "disable": false,
                                        "parentID": 1063,
                                        "menuPosition": "Ternary",
                                        "menuAlignment": "Vertical",
                                        "externalLink": false,
                                        "linkedElement": [],
                                        "breakPointNumber": 0
                                    },
                                    {
                                        "elementID": 2145,
                                        "tenantID": 0,
                                        "propertyID": 0,
                                        "productID": 1,
                                        "textID": 1509,
                                        "text": "Machine Names_DA",
                                        "routePath": "/settings/retailsetup/codesetup/machinename",
                                        "imgPath": "",
                                        "order": 17,
                                        "visibility": true,
                                        "disable": false,
                                        "parentID": 1063,
                                        "menuPosition": "Ternary",
                                        "menuAlignment": "Vertical",
                                        "externalLink": false,
                                        "linkedElement": [],
                                        "breakPointNumber": 0
                                    },
                                    {
                                        "elementID": 4080,
                                        "tenantID": 0,
                                        "propertyID": 0,
                                        "productID": 1,
                                        "textID": 4080,
                                        "text": "Post Type Mapping_DA",
                                        "routePath": "/settings/retailsetup/codesetup/posttypemapping",
                                        "imgPath": "",
                                        "order": 16,
                                        "visibility": true,
                                        "disable": false,
                                        "parentID": 1063,
                                        "menuPosition": "Ternary",
                                        "menuAlignment": "Vertical",
                                        "externalLink": false,
                                        "linkedElement": [],
                                        "breakPointNumber": 0
                                    },
                                    {
                                        "elementID": 6558,
                                        "tenantID": 0,
                                        "propertyID": 0,
                                        "productID": 1,
                                        "textID": 8714,
                                        "text": "Discount Reason_DA",
                                        "routePath": "/settings/retailsetup/codesetup/discountreason",
                                        "imgPath": "",
                                        "order": 16,
                                        "visibility": true,
                                        "disable": false,
                                        "parentID": 1063,
                                        "menuPosition": "Ternary",
                                        "menuAlignment": "Vertical",
                                        "externalLink": false,
                                        "linkedElement": [],
                                        "breakPointNumber": 7055
                                    },
                                    {
                                        "elementID": 4080,
                                        "tenantID": 0,
                                        "propertyID": 0,
                                        "productID": 1,
                                        "textID": 4080,
                                        "text": "Member Accrual Mapping_DA",
                                        "routePath": "/settings/retailsetup/codesetup/memberAccrualMapping",
                                        "imgPath": "",
                                        "order": 16,
                                        "visibility": true,
                                        "disable": false,
                                        "parentID": 1063,
                                        "menuPosition": "Ternary",
                                        "menuAlignment": "Vertical",
                                        "externalLink": false,
                                        "linkedElement": [],
                                        "breakPointNumber": 0
                                    },
                                    {
                                        "elementID": 4080,
                                        "tenantID": 0,
                                        "propertyID": 0,
                                        "productID": 1,
                                        "textID": 4080,
                                        "text": "Custom Fee",
                                        "routePath": "/settings/retailsetup/codesetup/customfee",
                                        "imgPath": "",
                                        "order": 16,
                                        "visibility": true,
                                        "disable": false,
                                        "parentID": 1063,
                                        "menuPosition": "Ternary",
                                        "menuAlignment": "Vertical",
                                        "externalLink": false,
                                        "linkedElement": [],
                                        "breakPointNumber": 0
                                    },
                                    {
                                        "elementID": 1072,
                                        "tenantID": 0,
                                        "propertyID": 0,
                                        "productID": 1,
                                        "textID": 1051,
                                        "text": "Tier Level",
                                        "routePath": "/settings/retailsetup/codesetup/tierLevel",
                                        "imgPath": "",
                                        "order": 17,
                                        "visibility": true,
                                        "disable": false,
                                        "parentID": 1063,
                                        "menuPosition": "Ternary",
                                        "menuAlignment": "Vertical",
                                        "externalLink": false,
                                        "linkedElement": [],
                                        "breakPointNumber": 7060
                                    },
                                ],
                                "breakPointNumber": 0
                            },
                            {
                                "elementID": 1079,
                                "tenantID": 0,
                                "propertyID": 0,
                                "productID": 1,
                                "textID": 1058,
                                "text": "Retail Setup_DA",
                                "routePath": "/settings/retailsetup/retailsetup",
                                "imgPath": "",
                                "order": 2,
                                "visibility": true,
                                "disable": false,
                                "parentID": 1062,
                                "menuPosition": "Ternary",
                                "menuAlignment": "Combo",
                                "externalLink": false,
                                "linkedElement": [],
                                "breakPointNumber": 0
                            },
                            {
                                "elementID": 1080,
                                "tenantID": 0,
                                "propertyID": 0,
                                "productID": 1,
                                "textID": 1059,
                                "text": "Quick Sale Setup_DA",
                                "routePath": "/settings/retailsetup/quicksalesetup",
                                "imgPath": "",
                                "order": 3,
                                "visibility": true,
                                "disable": false,
                                "parentID": 1062,
                                "menuPosition": "Ternary",
                                "menuAlignment": "Combo",
                                "externalLink": false,
                                "linkedElement": [],
                                "breakPointNumber": 7010
                            },
                            {
                                "elementID": 1081,
                                "tenantID": 0,
                                "propertyID": 0,
                                "productID": 1,
                                "textID": 1060,
                                "text": "Commission Setup_DA",
                                "routePath": "/settings/retailsetup/commissionsetup",
                                "imgPath": "",
                                "order": 4,
                                "visibility": true,
                                "disable": false,
                                "parentID": 1062,
                                "menuPosition": "Ternary",
                                "menuAlignment": "Combo",
                                "externalLink": false,
                                "linkedElement": [],
                                "breakPointNumber": 7020
                            },
                            {
                                "elementID": 1082,
                                "tenantID": 0,
                                "propertyID": 0,
                                "productID": 1,
                                "textID": 1061,
                                "text": "Discount Configuration_DA",
                                "routePath": "/settings/retailsetup/discountconfiguration",
                                "imgPath": "",
                                "order": 5,
                                "visibility": true,
                                "disable": false,
                                "parentID": 1062,
                                "menuPosition": "Ternary",
                                "menuAlignment": "Combo",
                                "externalLink": false,
                                "linkedElement": [],
                                "breakPointNumber": 7061
                            },
                            {
                                "elementID": 1083,
                                "tenantID": 0,
                                "propertyID": 0,
                                "productID": 1,
                                "textID": 1062,
                                "text": "Category Linking_DA",
                                "routePath": "/settings/retailsetup/categorylinking",
                                "imgPath": "",
                                "order": 6,
                                "visibility": true,
                                "disable": false,
                                "parentID": 1062,
                                "menuPosition": "Ternary",
                                "menuAlignment": "Combo",
                                "externalLink": false,
                                "linkedElement": [],
                                "breakPointNumber": 7041
                            },
                            {
                                "elementID": 1096,
                                "tenantID": 0,
                                "propertyID": 0,
                                "productID": 1,
                                "textID": 1101,
                                "text": "Vendor Setup_DA",
                                "routePath": "/settings/retailsetup/vendorsetup",
                                "imgPath": "",
                                "order": 7,
                                "visibility": true,
                                "disable": false,
                                "parentID": 1062,
                                "menuPosition": "Ternary",
                                "menuAlignment": "Combo",
                                "externalLink": false,
                                "linkedElement": [],
                                "breakPointNumber": 0
                            },
                            {
                                "elementID": 6729,
                                "tenantID": 0,
                                "propertyID": 0,
                                "productID": 1,
                                "textID": 9976,
                                "text": "Letter Setup_DA",
                                "routePath": "/settings/retailsetup/lettersetup",
                                "imgPath": "",
                                "order": 6,
                                "visibility": false,
                                "disable": false,
                                "parentID": 1062,
                                "menuPosition": "Ternary",
                                "menuAlignment": "Vertical",
                                "externalLink": false,
                                "linkedElement": [
                                    {
                                        "elementID": 6730,
                                        "tenantID": 0,
                                        "propertyID": 0,
                                        "productID": 1,
                                        "textID": 9981,
                                        "text": "Club Agreement_DA",
                                        "routePath": "/settings/retailsetup/lettersetup/retailsalechit",
                                        "imgPath": "",
                                        "order": 1,
                                        "visibility": false,
                                        "disable": false,
                                        "parentID": 6729,
                                        "menuPosition": "Ternary",
                                        "menuAlignment": "Vertical",
                                        "externalLink": false,
                                        "linkedElement": [],
                                        "breakPointNumber": 0
                                    }
                                ],
                                "breakPointNumber": 0
                            },
                            {
                                elementID: 4043,
                                tenantID: 0,
                                propertyID: 0,
                                productID: 2,
                                textID: 4043,
                                text: "Payment Manager",
                                routePath: "/settings/retailsetup/paymentmanager",
                                imgPath: "",
                                order: 7,
                                visibility: true,
                                disable: false,
                                parentID: 4020,
                                menuAlignment: 'Combo',
                                menuPosition: 'Secondary',
                                externalLink: false,
                                linkedElement: [
                                    {
                                        elementID: 4022,
                                        tenantID: 0,
                                        propertyID: 0,
                                        productID: 2,
                                        textID: 4022,
                                        text: "Outlets",
                                        routePath: "/settings/retailsetup/paymentmanager/outlets",
                                        imgPath: "",
                                        order: 1,
                                        visibility: true,
                                        disable: false,
                                        parentID: 4021,
                                        menuPosition: "Ternary",
                                        menuAlignment: "Vertical",
                                        externalLink: false,
                                        linkedElement: [

                                        ],
                                        breakPointNumber: 7025
                                    },
                                    {
                                        elementID: 4022,
                                        tenantID: 0,
                                        propertyID: 0,
                                        productID: 2,
                                        textID: 4022,
                                        text: "Pay Agents",
                                        routePath: "/settings/retailsetup/paymentmanager/payagents",
                                        imgPath: "",
                                        order: 1,
                                        visibility: true,
                                        disable: false,
                                        parentID: 4021,
                                        menuPosition: "Ternary",
                                        menuAlignment: "Vertical",
                                        externalLink: false,
                                        linkedElement: [

                                        ],
                                        breakPointNumber: 7025
                                    },
                                    {
                                        elementID: 4022,
                                        tenantID: 0,
                                        propertyID: 0,
                                        productID: 2,
                                        textID: 4022,
                                        text: "Settlers",
                                        routePath: "/settings/retailsetup/paymentmanager/settlers",
                                        imgPath: "",
                                        order: 1,
                                        visibility: true,
                                        disable: false,
                                        parentID: 4021,
                                        menuPosition: "Ternary",
                                        menuAlignment: "Vertical",
                                        externalLink: false,
                                        linkedElement: [

                                        ],
                                        breakPointNumber: 7025
                                    },
                                    // {
                                    //   elementID: 4022,
                                    //   tenantID: 0,
                                    //   propertyID: 0,
                                    //   productID: 2,
                                    //   textID: 4022,
                                    //   text: "History",
                                    //   routePath: "/settings/retailsetup/paymentmanager/history",
                                    //   imgPath: "",
                                    //   order: 1,
                                    //   visibility: true,
                                    //   disable: false,
                                    //   parentID: 4021,
                                    //   menuPosition: "Ternary",
                                    //   menuAlignment: "Vertical",
                                    //   externalLink: false,
                                    //   linkedElement: [

                                    //   ],
                                    //   breakPointNumber: 7025
                                    // },
                                ],
                                breakPointNumber: 0
                            },
                            {
                                elementID: 4043,
                                tenantID: 0,
                                propertyID: 0,
                                productID: 2,
                                textID: 4043,
                                text: "Eatec Integration",
                                routePath: "/settings/retailsetup/eatecintegration",
                                imgPath: "",
                                order: 7,
                                visibility: true,
                                disable: false,
                                parentID: 4020,
                                menuAlignment: 'Combo',
                                menuPosition: 'Secondary',
                                externalLink: false,
                                linkedElement: [
                                    {
                                        elementID: 4022,
                                        tenantID: 0,
                                        propertyID: 0,
                                        productID: 2,
                                        textID: 4022,
                                        text: "Item Mapping",
                                        routePath: "/settings/retailsetup/eatecintegration/itemmapping",
                                        imgPath: "",
                                        order: 1,
                                        visibility: true,
                                        disable: false,
                                        parentID: 4021,
                                        menuPosition: "Ternary",
                                        menuAlignment: "Vertical",
                                        externalLink: false,
                                        linkedElement: [

                                        ],
                                        breakPointNumber: 7025
                                    },
                                    {
                                        elementID: 4022,
                                        tenantID: 0,
                                        propertyID: 0,
                                        productID: 2,
                                        textID: 4022,
                                        text: "Sale Depletion Log",
                                        routePath: "/settings/retailsetup/eatecintegration/saledepletion",
                                        imgPath: "",
                                        order: 1,
                                        visibility: true,
                                        disable: false,
                                        parentID: 4021,
                                        menuPosition: "Ternary",
                                        menuAlignment: "Vertical",
                                        externalLink: false,
                                        linkedElement: [

                                        ],
                                        breakPointNumber: 7025
                                    }
                                ],
                                breakPointNumber: 0
                            }
                        ],
                        "breakPointNumber": 0
                    },
                    {
                        "elementID": 1084,
                        "tenantID": 0,
                        "propertyID": 0,
                        "productID": 1,
                        "textID": 1063,
                        "text": "INVENTORY_DA",
                        "routePath": "/settings/inventory",
                        "imgPath": "",
                        "order": 7,
                        "visibility": true,
                        "disable": false,
                        "parentID": 1030,
                        "menuPosition": "Secondary",
                        "menuAlignment": "Horizontal",
                        "externalLink": false,
                        "linkedElement": [],
                        "breakPointNumber": 8722
                    },
                    {
                        "elementID": 1085,
                        "tenantID": 0,
                        "propertyID": 0,
                        "productID": 1,
                        "textID": 1064,
                        "text": "SYSTEM CONFIG_DA",
                        "routePath": "/settings/systemconfig",
                        "imgPath": "",
                        "order": 8,
                        "visibility": true,
                        "disable": false,
                        "parentID": 1030,
                        "menuPosition": "Secondary",
                        "menuAlignment": "Horizontal",
                        "externalLink": false,
                        "linkedElement": [
                            {
                                "elementID": 1086,
                                "tenantID": 0,
                                "propertyID": 0,
                                "productID": 1,
                                "textID": 1065,
                                "text": "Property Information_DA",
                                "routePath": "/settings/systemconfig/propertyinformation",
                                "imgPath": "",
                                "order": 1,
                                "visibility": true,
                                "disable": false,
                                "parentID": 1085,
                                "menuPosition": "Ternary",
                                "menuAlignment": "Vertical",
                                "externalLink": false,
                                "linkedElement": [],
                                "breakPointNumber": 0
                            },
                            {
                                "elementID": 1087,
                                "tenantID": 0,
                                "propertyID": 0,
                                "productID": 1,
                                "textID": 1066,
                                "text": "Appointment_DA",
                                "routePath": "/settings/systemconfig/appointment",
                                "imgPath": "",
                                "order": 2,
                                "visibility": true,
                                "disable": false,
                                "parentID": 1085,
                                "menuPosition": "Ternary",
                                "menuAlignment": "Vertical",
                                "externalLink": false,
                                "linkedElement": [],
                                "breakPointNumber": 0
                            },
                            {
                                "elementID": 1088,
                                "tenantID": 0,
                                "propertyID": 0,
                                "productID": 1,
                                "textID": 1067,
                                "text": "Miscellaneous_DA",
                                "routePath": "/settings/systemconfig/miscellaneous",
                                "imgPath": "",
                                "order": 3,
                                "visibility": true,
                                "disable": false,
                                "parentID": 1085,
                                "menuPosition": "Ternary",
                                "menuAlignment": "Vertical",
                                "externalLink": false,
                                "linkedElement": [],
                                "breakPointNumber": 0
                            },
                            {
                                "elementID": 1089,
                                "tenantID": 0,
                                "propertyID": 0,
                                "productID": 1,
                                "textID": 1069,
                                "text": "rGuest Book Setup_DA",
                                "routePath": "/settings/systemconfig/rguestbooksetup",
                                "imgPath": "",
                                "order": 4,
                                "visibility": true,
                                "disable": false,
                                "parentID": 1085,
                                "menuPosition": "Ternary",
                                "menuAlignment": "Vertical",
                                "externalLink": false,
                                "linkedElement": [],
                                "breakPointNumber": 0
                            },
                            {
                                "elementID": 1090,
                                "tenantID": 0,
                                "propertyID": 0,
                                "productID": 1,
                                "textID": 1073,
                                "text": "Notify Configurations_DA",
                                "routePath": "/settings/systemconfig/notifyconfigurations",
                                "imgPath": "",
                                "order": 5,
                                "visibility": false,
                                "disable": true,
                                "parentID": 1085,
                                "menuPosition": "Ternary",
                                "menuAlignment": "Vertical",
                                "externalLink": false,
                                "linkedElement": [],
                                "breakPointNumber": 0
                            },
                            {
                                "elementID": 1091,
                                "tenantID": 0,
                                "propertyID": 0,
                                "productID": 1,
                                "textID": 1074,
                                "text": "Notifications_DA",
                                "routePath": "/settings/systemconfig/notifications",
                                "imgPath": "",
                                "order": 6,
                                "visibility": true,
                                "disable": false,
                                "parentID": 1085,
                                "menuPosition": "Ternary",
                                "menuAlignment": "Vertical",
                                "externalLink": false,
                                "linkedElement": [],
                                "breakPointNumber": 0
                            },
                            {
                                "elementID": 1091,
                                "tenantID": 0,
                                "propertyID": 0,
                                "productID": 1,
                                "textID": 1074,
                                "text": "Report Schedular_DA",
                                "routePath": "/settings/systemconfig/reportSchedular",
                                "imgPath": "",
                                "order": 7,
                                "visibility": true,
                                "disable": false,
                                "parentID": 1085,
                                "menuPosition": "Ternary",
                                "menuAlignment": "Vertical",
                                "externalLink": false,
                                "linkedElement": [],
                                "breakPointNumber": 0
                            },
                        ],
                        "breakPointNumber": 2085
                    },
                    {
                        "elementID": 1092,
                        "tenantID": 0,
                        "propertyID": 0,
                        "productID": 1,
                        "textID": 1075,
                        "text": "USER CONFIG_DA",
                        "routePath": "/settings/userconfig",
                        "imgPath": "",
                        "order": 9,
                        "visibility": true,
                        "disable": false,
                        "parentID": 1030,
                        "menuPosition": "Secondary",
                        "menuAlignment": "Horizontal",
                        "externalLink": false,
                        "linkedElement": [
                            {
                                "elementID": 1093,
                                "tenantID": 0,
                                "propertyID": 0,
                                "productID": 1,
                                "textID": 1076,
                                "text": "User Setup_DA",
                                "routePath": "/settings/userconfig/usersetup",
                                "imgPath": "",
                                "order": 1,
                                "visibility": true,
                                "disable": false,
                                "parentID": 1092,
                                "menuPosition": "Ternary",
                                "menuAlignment": "Vertical",
                                "externalLink": false,
                                "linkedElement": [],
                                "breakPointNumber": 2300
                            },
                            {
                                "elementID": 1094,
                                "tenantID": 0,
                                "propertyID": 0,
                                "productID": 1,
                                "textID": 1077,
                                "text": "Role Setup_DA",
                                "routePath": "/settings/userconfig/rolesetup",
                                "imgPath": "",
                                "order": 2,
                                "visibility": true,
                                "disable": false,
                                "parentID": 1092,
                                "menuPosition": "Ternary",
                                "menuAlignment": "Vertical",
                                "externalLink": false,
                                "linkedElement": [],
                                "breakPointNumber": 2305
                            },
                            {
                                "elementID": 1095,
                                "tenantID": 0,
                                "propertyID": 0,
                                "productID": 1,
                                "textID": 1078,
                                "text": "User Role Configuration_DA",
                                "routePath": "/settings/userconfig/userroleconfiguration",
                                "imgPath": "",
                                "order": 3,
                                "visibility": true,
                                "disable": false,
                                "parentID": 1092,
                                "menuPosition": "Ternary",
                                "menuAlignment": "Vertical",
                                "externalLink": false,
                                "linkedElement": [],
                                "breakPointNumber": 2310
                            }
                        ],
                        "breakPointNumber": 0
                    },
                    {
                        "elementID": 2129,
                        "tenantID": 0,
                        "propertyID": 0,
                        "productID": 1,
                        "textID": 1103,
                        "text": "INVENTORY CONTROL_DA",
                        "routePath": "/settings/enhancedInventory",
                        "imgPath": "",
                        "order": 11,
                        "visibility": true,
                        "disable": false,
                        "parentID": 1030,
                        "menuPosition": "Ternary",
                        "menuAlignment": "Horizontal",
                        "externalLink": false,
                        "linkedElement": [
                            {
                                "elementID": 2130,
                                "tenantID": 0,
                                "propertyID": 0,
                                "productID": 1,
                                "textID": 1104,
                                "text": "Inventory Management_DA",
                                "routePath": "/settings/enhancedInventory/inventory",
                                "imgPath": "",
                                "order": 1,
                                "visibility": true,
                                "disable": false,
                                "parentID": 2129,
                                "menuPosition": "Ternary",
                                "menuAlignment": "Combo",
                                "externalLink": false,
                                "linkedElement": [
                                    {
                                        "elementID": 2132,
                                        "tenantID": 0,
                                        "propertyID": 0,
                                        "productID": 1,
                                        "textID": 1105,
                                        "text": "Inventory_DA",
                                        "routePath": "/settings/enhancedInventory/masterlist/inventorylist",
                                        "imgPath": "",
                                        "order": 1,
                                        "visibility": true,
                                        "disable": false,
                                        "parentID": 2130,
                                        "menuPosition": "Ternary",
                                        "menuAlignment": "Vertical",
                                        "externalLink": false,
                                        "linkedElement": [],
                                        "breakPointNumber": 8731
                                    },
                                    {
                                        "elementID": 2133,
                                        "tenantID": 0,
                                        "propertyID": 0,
                                        "productID": 1,
                                        "textID": 1106,
                                        "text": "Transfer_DA",
                                        "routePath": "/settings/enhancedInventory/transfer/transfer",
                                        "imgPath": "",
                                        "order": 2,
                                        "visibility": true,
                                        "disable": false,
                                        "parentID": 2130,
                                        "menuPosition": "Ternary",
                                        "menuAlignment": "Vertical",
                                        "externalLink": false,
                                        "linkedElement": [],
                                        "breakPointNumber": 8732
                                    },
                                    {
                                        "elementID": 2136,
                                        "tenantID": 0,
                                        "propertyID": 0,
                                        "productID": 1,
                                        "textID": 1110,
                                        "text": "Requisitions_DA",
                                        "routePath": "/settings/enhancedInventory/requisition/viewrequisition",
                                        "imgPath": "",
                                        "order": 3,
                                        "visibility": false,
                                        "disable": false,
                                        "parentID": 2130,
                                        "menuPosition": "Ternary",
                                        "menuAlignment": "Vertical",
                                        "externalLink": false,
                                        "linkedElement": [],
                                        "breakPointNumber": 0
                                    },
                                    {
                                        "elementID": 2137,
                                        "tenantID": 0,
                                        "propertyID": 0,
                                        "productID": 1,
                                        "textID": 1111,
                                        "text": "Physical Inventory_DA",
                                        "routePath": "/settings/enhancedInventory/transaction/view-physicalinventory",
                                        "imgPath": "",
                                        "order": 4,
                                        "visibility": true,
                                        "disable": false,
                                        "parentID": 2130,
                                        "menuPosition": "Ternary",
                                        "menuAlignment": "Vertical",
                                        "externalLink": false,
                                        "linkedElement": [],
                                        "breakPointNumber": 8733
                                    }
                                ],
                                "breakPointNumber": 0
                            },
                            {
                                "elementID": 2131,
                                "tenantID": 0,
                                "propertyID": 0,
                                "productID": 1,
                                "textID": 1107,
                                "text": "Procurement_DA",
                                "routePath": "/settings/enhancedInventory/procurement",
                                "imgPath": "",
                                "order": 2,
                                "visibility": true,
                                "disable": false,
                                "parentID": 2129,
                                "menuPosition": "Ternary",
                                "menuAlignment": "Combo",
                                "externalLink": false,
                                "linkedElement": [
                                    {
                                        "elementID": 2138,
                                        "tenantID": 0,
                                        "propertyID": 0,
                                        "productID": 1,
                                        "textID": 1112,
                                        "text": "Blanket Purchase Order_DA",
                                        "routePath": "/settings/enhancedInventory/blanketpurchaseorder/bpolanding",
                                        "imgPath": "",
                                        "order": 1,
                                        "visibility": false,
                                        "disable": false,
                                        "parentID": 2131,
                                        "menuPosition": "Ternary",
                                        "menuAlignment": "Vertical",
                                        "externalLink": false,
                                        "linkedElement": [],
                                        "breakPointNumber": 0
                                    },
                                    {
                                        "elementID": 2139,
                                        "tenantID": 0,
                                        "propertyID": 0,
                                        "productID": 1,
                                        "textID": 1109,
                                        "text": "Receiving_DA",
                                        "routePath": "/settings/enhancedInventory/transaction/view-receiving",
                                        "imgPath": "",
                                        "order": 2,
                                        "visibility": true,
                                        "disable": false,
                                        "parentID": 2131,
                                        "menuPosition": "Ternary",
                                        "menuAlignment": "Vertical",
                                        "externalLink": false,
                                        "linkedElement": [],
                                        "breakPointNumber": 8734
                                    },
                                    {
                                        "elementID": 2140,
                                        "tenantID": 0,
                                        "propertyID": 0,
                                        "productID": 1,
                                        "textID": 1108,
                                        "text": "Purchase Order_DA",
                                        "routePath": "/settings/enhancedInventory/transaction/view-purchaseorder",
                                        "imgPath": "",
                                        "order": 3,
                                        "visibility": true,
                                        "disable": false,
                                        "parentID": 2131,
                                        "menuPosition": "Ternary",
                                        "menuAlignment": "Vertical",
                                        "externalLink": false,
                                        "linkedElement": [],
                                        "breakPointNumber": 8735
                                    },
                                    {
                                        "elementID": 2141,
                                        "tenantID": 0,
                                        "propertyID": 0,
                                        "productID": 1,
                                        "textID": 1113,
                                        "text": "Purchase Requests_DA",
                                        "routePath": "/settings/enhancedInventory/purchaserequest/pr-landing",
                                        "imgPath": "",
                                        "order": 4,
                                        "visibility": false,
                                        "disable": false,
                                        "parentID": 2131,
                                        "menuPosition": "Ternary",
                                        "menuAlignment": "Vertical",
                                        "externalLink": false,
                                        "linkedElement": [],
                                        "breakPointNumber": 0
                                    }
                                ],
                                "breakPointNumber": 0
                            },
                            {
                                "elementID": 2134,
                                "tenantID": 0,
                                "propertyID": 0,
                                "productID": 1,
                                "textID": 1114,
                                "text": "Reports_DA",
                                "routePath": "/settings/enhancedInventory/reports",
                                "imgPath": "",
                                "order": 3,
                                "visibility": false,
                                "disable": false,
                                "parentID": 2129,
                                "menuPosition": "Ternary",
                                "menuAlignment": "Vertical",
                                "externalLink": false,
                                "linkedElement": [
                                    {
                                        "elementID": 2142,
                                        "tenantID": 0,
                                        "propertyID": 0,
                                        "productID": 1,
                                        "textID": 1114,
                                        "text": "Reports_DA",
                                        "routePath": "/settings/enhancedInventory/reports",
                                        "imgPath": "",
                                        "order": 1,
                                        "visibility": false,
                                        "disable": false,
                                        "parentID": 2134,
                                        "menuPosition": "Ternary",
                                        "menuAlignment": "Vertical",
                                        "externalLink": false,
                                        "linkedElement": [],
                                        "breakPointNumber": 0
                                    }
                                ],
                                "breakPointNumber": 0
                            },
                            {
                                "elementID": 2135,
                                "tenantID": 0,
                                "propertyID": 0,
                                "productID": 1,
                                "textID": 1115,
                                "text": "Pending Approvals_DA",
                                "routePath": "/settings/enhancedInventory/pendingapproval/allpendingapprovals",
                                "imgPath": "",
                                "order": 4,
                                "visibility": false,
                                "disable": false,
                                "parentID": 2129,
                                "menuPosition": "Ternary",
                                "menuAlignment": "Vertical",
                                "externalLink": false,
                                "linkedElement": [
                                    {
                                        "elementID": 2143,
                                        "tenantID": 0,
                                        "propertyID": 0,
                                        "productID": 1,
                                        "textID": 1115,
                                        "text": "Pending Approvals_DA",
                                        "routePath": "/settings/enhancedInventory/pendingapproval/allpendingapprovals",
                                        "imgPath": "",
                                        "order": 1,
                                        "visibility": false,
                                        "disable": false,
                                        "parentID": 2135,
                                        "menuPosition": "Ternary",
                                        "menuAlignment": "Vertical",
                                        "externalLink": false,
                                        "linkedElement": [],
                                        "breakPointNumber": 0
                                    }
                                ],
                                "breakPointNumber": 0
                            }
                        ],
                        "breakPointNumber": 0
                    },
                    {
                        "elementID": 2045,
                        "tenantID": 0,
                        "propertyID": 0,
                        "productID": 3,
                        "textId": 2035,
                        "text": "INTERFACES",
                        "routePath": "/settings/interfaces",
                        "imgPath": "",
                        "order": 2,
                        "visibility": true,
                        "disable": false,
                        "parentID": 2034,
                        "menuPosition": "Secondary",
                        "menuAlignment": "Horizontal",
                        "externalLink": false,
                        "linkedElement": [
                            {
                                "elementID": 2045,
                                "tenantID": 0,
                                "propertyID": 0,
                                "productID": 3,
                                "textId": 2035,
                                "text": "ADM INTEGRATION",
                                "routePath": "/settings/interfaces/admIntegration",
                                "imgPath": "",
                                "order": 2,
                                "visibility": true,
                                "disable": false,
                                "parentID": 2034,
                                "menuPosition": "Ternary",
                                "menuAlignment": "Combo",
                                "externalLink": false,
                                "linkedElement": [
                                    {
                                        "elementID": 2045,
                                        "tenantID": 0,
                                        "propertyID": 0,
                                        "productID": 3,
                                        "textId": 2035,
                                        "text": "ADM INTEGRATION SETUP",
                                        "routePath": "/settings/interfaces/admIntegration/admIntegrationSetup",
                                        "imgPath": "",
                                        "order": 2,
                                        "visibility": true,
                                        "disable": false,
                                        "parentID": 2034,
                                        "menuPosition": "Ternary",
                                        "menuAlignment": "Vertical",
                                        "externalLink": false,
                                        "linkedElement": [],
                                        "breakPointNumber": 0
                                    }
                                ],
                                "breakPointNumber": 0
                            },
                            {
                                "elementID": 2045,
                                "tenantID": 0,
                                "propertyID": 0,
                                "productID": 3,
                                "textId": 2035,
                                "text": "SFTP INTEGRATION",
                                "routePath": "/settings/interfaces/sftpIntegration",
                                "imgPath": "",
                                "order": 2,
                                "visibility": true,
                                "disable": false,
                                "parentID": 2034,
                                "menuPosition": "Ternary",
                                "menuAlignment": "Combo",
                                "externalLink": false,
                                "linkedElement": [
                                    {
                                        "elementID": 2045,
                                        "tenantID": 0,
                                        "propertyID": 0,
                                        "productID": 3,
                                        "textId": 2035,
                                        "text": "SFTP",
                                        "routePath": "/settings/interfaces/sftpIntegration/sftp",
                                        "imgPath": "",
                                        "order": 2,
                                        "visibility": true,
                                        "disable": false,
                                        "parentID": 2034,
                                        "menuPosition": "Ternary",
                                        "menuAlignment": "Vertical",
                                        "externalLink": false,
                                        "linkedElement": [],
                                        "breakPointNumber": 0
                                    }
                                ],
                                "breakPointNumber": 0
                            },
                            {
                                "elementID": 2046,
                                "tenantID": 0,
                                "propertyID": 0,
                                "productID": 1,
                                "textId": 2035,
                                "text": "Authorize Integration",
                                "routePath": "/settings/interfaces/authorizeIntegration",
                                "imgPath": "",
                                "order": 3,
                                "visibility": true,
                                "disable": false,
                                "parentID": 2034,
                                "menuPosition": "Ternary",
                                "menuAlignment": "Combo",
                                "externalLink": false,
                                "linkedElement": [
                                    {
                                        "elementID": 2047,
                                        "tenantID": 0,
                                        "propertyID": 0,
                                        "productID": 1,
                                        "textId": 2035,
                                        "text": "Authorize Integration Setup",
                                        "routePath": "/settings/interfaces/authorizeIntegration/authorizeSetup",
                                        "imgPath": "",
                                        "order": 2,
                                        "visibility": true,
                                        "disable": false,
                                        "parentID": 2046,
                                        "menuPosition": "Ternary",
                                        "menuAlignment": "Vertical",
                                        "externalLink": false,
                                        "linkedElement": [],
                                        "breakPointNumber": 0
                                    }
                                ],
                                "breakPointNumber": 0
                            },
                            {
                                "elementID": 2046,
                                "tenantID": 0,
                                "propertyID": 0,
                                "productID": 1,
                                "textId": 2035,
                                "text": "PMS Integration",
                                "routePath": "/settings/interfaces/pmsIntegration",
                                "imgPath": "",
                                "order": 3,
                                "visibility": true,
                                "disable": false,
                                "parentID": 2034,
                                "menuPosition": "Ternary",
                                "menuAlignment": "Combo",
                                "externalLink": false,
                                "linkedElement": [
                                    {
                                        "elementID": 2047,
                                        "tenantID": 0,
                                        "propertyID": 0,
                                        "productID": 1,
                                        "textId": 2035,
                                        "text": "PMS Integration Setup",
                                        "routePath": "/settings/interfaces/pmsIntegration/pmsIntegrationSetup",
                                        "imgPath": "",
                                        "order": 2,
                                        "visibility": true,
                                        "disable": false,
                                        "parentID": 2046,
                                        "menuPosition": "Ternary",
                                        "menuAlignment": "Vertical",
                                        "externalLink": false,
                                        "linkedElement": [],
                                        "breakPointNumber": 0
                                    }
                                ],
                                "breakPointNumber": 0
                            },
                        ]
                    },
                ],
                "breakPointNumber": 0
            },
            {

                tenantID: 0,
                propertyID: 0,
                productID: 6,
                textId: 6281,
                elementId: 6281,
                text: 'ACCOUNTING',
                routePath: '/accounting',
                imgPath: 'icon-Accounting',
                order: 6,
                visibility: true,
                disable: false,
                parentID: 0,
                menuPosition: 'Primary',
                menuAlignment: 'Horizontal',
                externalLink: false,
                linkedElement: [
                    {
                        tenantID: 0,
                        propertyID: 0,
                        productID: 6,
                        textId: 6286,
                        elementId: 6286,
                        text: 'CUSTOMER MAINTENANCE',
                        routePath: '/accounting/receivable',
                        imgPath: '',
                        order: 1,
                        visibility: true,
                        disable: false,
                        parentID: 6003,
                        menuPosition: 'Secondary',
                        menuAlignment: 'Horizontal',
                        externalLink: false,
                        linkedElement: []
                    },
                    {
                        tenantID: 0,
                        propertyID: 0,
                        productID: 6,
                        textId: 6286,
                        elementId: 6286,
                        text: 'CHARGES',
                        routePath: '/accounting/charges',
                        imgPath: '',
                        order: 1,
                        visibility: true,
                        disable: false,
                        parentID: 6003,
                        menuPosition: 'Secondary',
                        menuAlignment: 'Horizontal',
                        externalLink: false,
                        linkedElement: [{
                            elementId: 6184,
                            tenantID: 0,
                            propertyID: 0,
                            productID: 6,
                            textId: 6912,
                            text: "APPLY CREDIT CARD CHARGES",
                            routePath: "/accounting/charges/applycreditcharges",
                            imgPath: "",
                            order: 1,
                            visibility: true,
                            disable: false,
                            parentID: 6005,
                            menuPosition: "Ternary",
                            menuAlignment: "Combo",
                            externalLink: false,
                            breakPointNumber: 0,
                            linkedElement: []
                        },
                        {
                            elementId: 6184,
                            tenantID: 0,
                            propertyID: 0,
                            productID: 6,
                            textId: 6912,
                            text: "FINANCE CHARGES",
                            routePath: "/accounting/charges/financecharges",
                            imgPath: "",
                            order: 1,
                            visibility: true,
                            disable: false,
                            parentID: 6005,
                            menuPosition: "Ternary",
                            menuAlignment: "Combo",
                            externalLink: false,
                            breakPointNumber: 0,
                            linkedElement: []
                        }
                        ]
                    },
                    {

                        tenantID: 0,
                        propertyID: 0,
                        textId: 6286,
                        elementId: 6286,
                        text: 'GENERAL',
                        routePath: '/accounting/general',
                        imgPath: '',
                        order: 2,
                        visibility: true,
                        disable: false,
                        parentID: 6003,
                        menuPosition: 'Secondary',
                        menuAlignment: 'Horizontal',
                        externalLink: false,
                        linkedElement: [
                            {
                                elementId: 6184,
                                tenantID: 0,
                                propertyID: 0,
                                productID: 10,
                                textId: 6912,
                                text: "VIEW ALL UNPOSTED BATCHES",
                                routePath: "/accounting/general/viewAllUnpostedBatches",
                                imgPath: "",
                                order: 1,
                                visibility: true,
                                disable: false,
                                parentID: 6005,
                                menuPosition: "Ternary",
                                menuAlignment: "Combo",
                                externalLink: false,
                                breakPointNumber: 0,
                                linkedElement: [
                                ]
                            },
                            {
                                elementId: 6184,
                                tenantID: 0,
                                propertyID: 0,
                                productID: 10,
                                textId: 6912,
                                text: "DELETE INACTIVE/TEMPORARY ACCOUNTS",
                                routePath: "/accounting/general/deleteInactive",
                                imgPath: "",
                                order: 2,
                                visibility: true,
                                disable: false,
                                parentID: 6005,
                                menuPosition: "Ternary",
                                menuAlignment: "Combo",
                                externalLink: false,
                                breakPointNumber: 0,
                                linkedElement: []
                            },
                            {
                                elementId: 6184,
                                tenantID: 0,
                                propertyID: 0,
                                productID: 10,
                                textId: 6912,
                                text: "BANK ACCOUNT MAINTENANCE",
                                routePath: "/accounting/general/bankAccountMaintenance",
                                imgPath: "",
                                order: 3,
                                visibility: true,
                                disable: false,
                                parentID: 6005,
                                menuPosition: "Ternary",
                                menuAlignment: "Combo",
                                externalLink: false,
                                breakPointNumber: 0,
                                linkedElement: []
                            },
                            {
                                elementId: 6184,
                                tenantID: 0,
                                propertyID: 0,
                                productID: 10,
                                textId: 6912,
                                text: "VIEW UNCREATED SCHEDULED BATCHES",
                                routePath: "/accounting/general/viewuncreatedschedulebatches",
                                imgPath: "",
                                order: 5,
                                visibility: true,
                                disable: false,
                                parentID: 6005,
                                menuPosition: "Ternary",
                                menuAlignment: "Combo",
                                externalLink: false,
                                breakPointNumber: 0,
                                linkedElement: []
                            },
                            {
                                elementId: 6184,
                                tenantID: 0,
                                propertyID: 0,
                                productID: 10,
                                textId: 11516,
                                text: "BACS/EFT ACCOUNTS SETUP",
                                routePath: "/accounting/general/BacsEftAccountSetup",
                                imgPath: "",
                                order: 7,
                                visibility: true,
                                disable: false,
                                parentID: 6286,
                                menuPosition: "Ternary",
                                menuAlignment: "Combo",
                                externalLink: false,
                                breakPointNumber: 0,
                                linkedElement: []
                            },
                        ],

                        breakPointNumber: 0

                    },
                    {
                        tenantID: 0,
                        propertyID: 0,
                        productID: 6,
                        textId: 6286,
                        elementId: 6286,
                        text: 'TRACE MAINTENANCE',
                        routePath: '/accounting/traceMaintenance',
                        imgPath: '',
                        order: 1,
                        visibility: true,
                        disable: false,
                        parentID: 6003,
                        menuPosition: 'Secondary',
                        menuAlignment: 'Horizontal',
                        externalLink: false,
                        linkedElement: []
                    },
                    {

                        tenantID: 0,
                        propertyID: 0,
                        productID: 10,
                        textId: 6286,
                        elementId: 6286,
                        text: 'CREDIT CARD PAYMENT',
                        routePath: '/accounting/creditcardpayment',
                        imgPath: '',
                        order: 8,
                        visibility: true,
                        disable: false,
                        parentID: 6003,
                        menuPosition: 'Secondary',
                        menuAlignment: 'Horizontal',
                        externalLink: false,
                        linkedElement: [],
                        breakPointNumber: 0
                    },
                    {

                        tenantID: 0,
                        propertyID: 0,
                        productID: 10,
                        textId: 6286,
                        elementId: 6286,
                        text: 'PAYMENT MAINTENANCE',
                        routePath: '/accounting/paymentmaintenance',
                        imgPath: '',
                        order: 7,
                        visibility: true,
                        disable: false,
                        parentID: 6003,
                        menuPosition: 'Secondary',
                        menuAlignment: 'Horizontal',
                        externalLink: false,
                        linkedElement: [],
                        breakPointNumber: 0
                    },
                    {

                        tenantID: 0,
                        propertyID: 0,
                        productID: 10,
                        textId: 6286,
                        elementId: 6286,
                        text: 'TRANSACTION MAINTENANCE',
                        routePath: '/accounting/transactionmaintenance',
                        imgPath: '',
                        order: 2,
                        visibility: true,
                        disable: false,
                        parentID: 6003,
                        menuPosition: 'Secondary',
                        menuAlignment: 'Horizontal',
                        externalLink: false,
                        linkedElement: [],
                        breakPointNumber: 0
                    },
                    {

                        tenantID: 0,
                        propertyID: 0,
                        productID: 6,
                        textId: 6286,
                        elementId: 6286,
                        text: 'VIEW CUSTOMER TRANSACTIONS',
                        routePath: '/accounting/viewcustomertransaction',
                        imgPath: '',
                        order: 1,
                        visibility: true,
                        disable: false,
                        parentID: 6003,
                        menuPosition: 'Secondary',
                        menuAlignment: 'Horizontal',
                        externalLink: false,
                        linkedElement: [],
                        breakPointNumber: 0
                    },
                    {
                        tenantID: 0,
                        propertyID: 0,
                        productID: 6,
                        textId: 6286,
                        elementId: 6286,
                        text: 'DISPUTED TRANSACTIONS',
                        routePath: '/accounting/disputedtransactions',
                        imgPath: '',
                        order: 1,
                        visibility: true,
                        disable: false,
                        parentID: 6003,
                        menuPosition: 'Secondary',
                        menuAlignment: 'Horizontal',
                        externalLink: false,
                        linkedElement: []
                    },
                    {

                        tenantID: 0,
                        propertyID: 0,
                        productID: 10,
                        textId: 6286,
                        elementId: 6286,
                        text: 'A/R CHECK PAYMENT PROCESS',
                        routePath: '/accounting/checkpayment',
                        imgPath: '',
                        order: 8,
                        visibility: true,
                        disable: false,
                        parentID: 6003,
                        menuPosition: 'Secondary',
                        menuAlignment: 'Horizontal',
                        externalLink: false,
                        linkedElement: [],
                        breakPointNumber: 0
                    },
                    {
                        tenantID: 0,
                        propertyID: 0,
                        productID: 6,
                        textId: 6286,
                        elementId: 6286,
                        text: 'ACCOUNTS RECEIVABLE',
                        routePath: '/accounting/accountsreceivable',
                        imgPath: '',
                        order: 1,
                        visibility: true,
                        disable: false,
                        parentID: 6003,
                        menuPosition: 'Secondary',
                        menuAlignment: 'Horizontal',
                        externalLink: false,
                        linkedElement: [
                            {
                                elementId: 6184,
                                tenantID: 0,
                                propertyID: 0,
                                productID: 6,
                                textId: 6912,
                                text: "ACCOUNTS RECEIVABLE CODE SETUP",
                                routePath: "/accounting/accountsreceivable/codesetup",
                                imgPath: "",
                                order: 1,
                                visibility: true,
                                disable: false,
                                parentID: 6005,
                                menuPosition: "Ternary",
                                menuAlignment: "Combo",
                                externalLink: false,
                                breakPointNumber: 0,
                                linkedElement: [
                                    {
                                        elementId: 6184,
                                        tenantID: 0,
                                        propertyID: 0,
                                        productID: 6,
                                        textId: 6912,
                                        text: "A/R Transaction Codes",
                                        routePath: "/accounting/accountsreceivable/codesetup/artransactioncodes",
                                        imgPath: "",
                                        order: 1,
                                        visibility: true,
                                        disable: false,
                                        parentID: 6005,
                                        menuPosition: "Ternary",
                                        menuAlignment: "Vertical",
                                        externalLink: false,
                                        breakPointNumber: 0,
                                        linkedElement: []
                                    },
                                    {
                                        elementId: 6184,
                                        tenantID: 0,
                                        propertyID: 0,
                                        productID: 6,
                                        textId: 6912,
                                        text: "A/R Account Categories",
                                        routePath: "/accounting/accountsreceivable/codesetup/araccountcategories",
                                        imgPath: "",
                                        order: 1,
                                        visibility: true,
                                        disable: false,
                                        parentID: 6005,
                                        menuPosition: "Ternary",
                                        menuAlignment: "Vertical",
                                        externalLink: false,
                                        breakPointNumber: 0,
                                        linkedElement: []
                                    },
                                    {
                                        elementId: 6184,
                                        tenantID: 0,
                                        propertyID: 0,
                                        productID: 6,
                                        textId: 6912,
                                        text: "A/R Account Categories 2",
                                        routePath: "/accounting/accountsreceivable/codesetup/araccountcategories2",
                                        imgPath: "",
                                        order: 1,
                                        visibility: true,
                                        disable: false,
                                        parentID: 6005,
                                        menuPosition: "Ternary",
                                        menuAlignment: "Vertical",
                                        externalLink: false,
                                        breakPointNumber: 0,
                                        linkedElement: []
                                    },
                                    {
                                        elementId: 6184,
                                        tenantID: 0,
                                        propertyID: 0,
                                        productID: 6,
                                        textId: 6912,
                                        text: "A/R Miscellaneous Categories",
                                        routePath: "/accounting/accountsreceivable/codesetup/armiscellaneoustypes",
                                        imgPath: "",
                                        order: 1,
                                        visibility: true,
                                        disable: false,
                                        parentID: 6005,
                                        menuPosition: "Ternary",
                                        menuAlignment: "Vertical",
                                        externalLink: false,
                                        breakPointNumber: 0,
                                        linkedElement: []
                                    },
                                    {
                                        elementId: 6184,
                                        tenantID: 0,
                                        propertyID: 0,
                                        productID: 6,
                                        textId: 6912,
                                        text: "A/R Tax Code Maintenance",
                                        routePath: "/accounting/accountsreceivable/codesetup/artaxcodemaintenance",
                                        imgPath: "",
                                        order: 1,
                                        visibility: true,
                                        disable: false,
                                        parentID: 6005,
                                        menuPosition: "Ternary",
                                        menuAlignment: "Vertical",
                                        externalLink: false,
                                        breakPointNumber: 0,
                                        linkedElement: []
                                    },
                                    {
                                        elementId: 6184,
                                        tenantID: 0,
                                        propertyID: 0,
                                        productID: 10,
                                        textId: 6912,
                                        text: "Credit Card Payment Setup",
                                        routePath: "/accounting/accountsreceivable/codesetup/creditcardpaymentsetup",
                                        imgPath: "",
                                        order: 6,
                                        visibility: true,
                                        disable: false,
                                        parentID: 6005,
                                        menuPosition: "Ternary",
                                        menuAlignment: "Vertical",
                                        externalLink: false,
                                        breakPointNumber: 0,
                                        linkedElement: []
                                    },
                                    {
                                        elementId: 6184,
                                        tenantID: 0,
                                        propertyID: 0,
                                        productID: 10,
                                        textId: 6912,
                                        text: "A/R Discount Categories",
                                        routePath: "/accounting/accountsreceivable/codesetup/ardiscountcategories",
                                        imgPath: "",
                                        order: 5,
                                        visibility: true,
                                        disable: false,
                                        parentID: 6005,
                                        menuPosition: "Ternary",
                                        menuAlignment: "Vertical",
                                        externalLink: false,
                                        breakPointNumber: 0,
                                        linkedElement: []
                                    },
                                ]

                            },
                            {
                                elementId: 6184,
                                tenantID: 0,
                                propertyID: 0,
                                productID: 6,
                                textId: 6912,
                                text: "A/R CONTROL SETUP",
                                routePath: "/accounting/accountsreceivable/arcontrolsetup",
                                imgPath: "",
                                order: 1,
                                visibility: true,
                                disable: false,
                                parentID: 6005,
                                menuPosition: "Ternary",
                                menuAlignment: "Combo",
                                externalLink: false,
                                breakPointNumber: 0,
                                linkedElement: [
                                    {
                                        elementId: 6184,
                                        tenantID: 0,
                                        propertyID: 0,
                                        productID: 6,
                                        textId: 6912,
                                        text: "A/R Option",
                                        routePath: "/accounting/accountsreceivable/arcontrolsetup/aroption",
                                        imgPath: "",
                                        order: 1,
                                        visibility: true,
                                        disable: false,
                                        parentID: 6005,
                                        menuPosition: "Ternary",
                                        menuAlignment: "Vertical",
                                        externalLink: false,
                                        breakPointNumber: 0,
                                        linkedElement: []
                                    },
                                    {
                                        elementId: 6184,
                                        tenantID: 0,
                                        propertyID: 0,
                                        productID: 6,
                                        textId: 6912,
                                        text: "Invoice/Statement and aging",
                                        routePath: "/accounting/accountsreceivable/arcontrolsetup/invoicestatementaging",
                                        imgPath: "",
                                        order: 1,
                                        visibility: true,
                                        disable: false,
                                        parentID: 6005,
                                        menuPosition: "Ternary",
                                        menuAlignment: "Vertical",
                                        externalLink: false,
                                        breakPointNumber: 0,
                                        linkedElement: []
                                    },
                                    {
                                        elementId: 6184,
                                        tenantID: 0,
                                        propertyID: 0,
                                        productID: 6,
                                        textId: 6912,
                                        text: "Other Option and BACS/EFT",
                                        routePath: "/accounting/accountsreceivable/arcontrolsetup/otheroption",
                                        imgPath: "",
                                        order: 1,
                                        visibility: true,
                                        disable: false,
                                        parentID: 6005,
                                        menuPosition: "Ternary",
                                        menuAlignment: "Vertical",
                                        externalLink: false,
                                        breakPointNumber: 0,
                                        linkedElement: []
                                    },
                                ]
                            },
                            {
                                elementId: 6184,
                                tenantID: 0,
                                propertyID: 0,
                                productID: 6,
                                textId: 6912,
                                text: "A/R BATCH TEMPLATES",
                                routePath: "/accounting/accountsreceivable/arbatchtemplates",
                                imgPath: "",
                                order: 1,
                                visibility: true,
                                disable: false,
                                parentID: 6005,
                                menuPosition: "Ternary",
                                menuAlignment: "Combo",
                                externalLink: false,
                                breakPointNumber: 0,
                                linkedElement: [
                                ]
                            },
                            {
                                elementId: 6184,
                                tenantID: 0,
                                propertyID: 0,
                                productID: 10,
                                textId: 6912,
                                text: "A/R LETTERS MAINTENANCE",
                                routePath: "/accounting/accountsreceivable/arlettersmaintenance",
                                imgPath: "",
                                order: 3,
                                visibility: true,
                                disable: false,
                                parentID: 6005,
                                menuPosition: "Ternary",
                                menuAlignment: "Combo",
                                externalLink: false,
                                breakPointNumber: 0,
                                linkedElement: [
                                ]
                            },
                            {
                                elementId: 6184,
                                tenantID: 0,
                                propertyID: 0,
                                productID: 10,
                                textId: 6912,
                                text: "A/R Archiving",
                                routePath: "/accounting/accountsreceivable/ararchiving",
                                imgPath: "",
                                order: 4,
                                visibility: true,
                                disable: false,
                                parentID: 6005,
                                menuPosition: "Ternary",
                                menuAlignment: "Combo",
                                externalLink: false,
                                breakPointNumber: 0,
                                linkedElement: [
                                ]
                            },
                            {
                                elementId: 6184,
                                tenantID: 0,
                                propertyID: 0,
                                productID: 10,
                                textId: 6912,
                                text: "DUNNING LETTERS",
                                routePath: "/accounting/accountsreceivable/ardunningletter",
                                imgPath: "",
                                order: 6,
                                visibility: true,
                                disable: false,
                                parentID: 6005,
                                menuPosition: "Ternary",
                                menuAlignment: "Combo",
                                externalLink: false,
                                breakPointNumber: 0,
                                linkedElement: [
                                ]
                            },
                            {
                                elementId: 6184,
                                tenantID: 0,
                                propertyID: 0,
                                productID: 10,
                                textId: 6912,
                                text: "AR CLIENT LETTERS",
                                routePath: "/accounting/accountsreceivable/arclientletter",
                                imgPath: "",
                                order: 7,
                                visibility: true,
                                disable: false,
                                parentID: 6005,
                                menuPosition: "Ternary",
                                menuAlignment: "Combo",
                                externalLink: false,
                                breakPointNumber: 0,
                                linkedElement: [
                                ]
                            }
                        ],

                        breakPointNumber: 0
                    },
                    {

                        tenantID: 0,
                        propertyID: 0,
                        productID: 6,
                        textId: 6286,
                        elementId: 6286,
                        text: 'VIEW INVOICES',
                        routePath: '/accounting/viewinvoices',
                        imgPath: '',
                        order: 3,
                        visibility: true,
                        disable: false,
                        parentID: 6003,
                        menuPosition: 'Secondary',
                        menuAlignment: 'Horizontal',
                        externalLink: false,
                        linkedElement: [],
                        breakPointNumber: 0
                    },
                    {

                        tenantID: 0,
                        propertyID: 0,
                        productID: 6,
                        textId: 6286,
                        elementId: 6286,
                        text: 'INVOICES',
                        routePath: '/accounting/invoices',
                        imgPath: '',
                        order: 3,
                        visibility: true,
                        disable: false,
                        parentID: 6003,
                        menuPosition: 'Secondary',
                        menuAlignment: 'Horizontal',
                        externalLink: false,
                        linkedElement: [
                            {
                                elementId: 6184,
                                tenantID: 0,
                                propertyID: 0,
                                productID: 6,
                                textId: 6912,
                                text: "GENERATE INVOICE",
                                routePath: "/accounting/invoices/generate",
                                imgPath: "",
                                order: 1,
                                visibility: true,
                                disable: false,
                                parentID: 6005,
                                menuPosition: "Ternary",
                                menuAlignment: "Combo",
                                externalLink: false,
                                breakPointNumber: 0,
                                linkedElement: []
                            },
                            {
                                elementId: 6184,
                                tenantID: 0,
                                propertyID: 0,
                                productID: 6,
                                textId: 6912,
                                text: "Re-Print Invoice",
                                routePath: "/accounting/invoices/reprintInvoice",
                                imgPath: "",
                                order: 1,
                                visibility: true,
                                disable: false,
                                parentID: 6005,
                                menuPosition: "Ternary",
                                menuAlignment: "Combo",
                                externalLink: false,
                                breakPointNumber: 0,
                                linkedElement: [{
                                    elementId: 6184,
                                    tenantID: 0,
                                    propertyID: 0,
                                    productID: 6,
                                    textId: 6912,
                                    text: "Re-Print Invoices",
                                    routePath: "/accounting/invoices/reprintInvoice/reprintInvoices",
                                    imgPath: "",
                                    order: 1,
                                    visibility: true,
                                    disable: false,
                                    parentID: 6005,
                                    menuPosition: "Ternary",
                                    menuAlignment: "Vertical",
                                    externalLink: false,
                                    breakPointNumber: 0,
                                    linkedElement: []
                                }],
                            },
                        ],
                    }
                ],
                breakPointNumber: 0
            },
            {
                elementID: 1030,
                tenantID: 0,
                propertyID: 0,
                productID: 1,
                textID: 1009,
                text: "Folio",
                routePath: "/folio",
                imgPath: "icon-folio",
                order: 8,
                visibility: true,
                disable: false,
                parentID: 0,
                menuPosition: "Primary",
                menuAlignment: "Horizontal",
                externalLink: false,
                linkedElement: [
                    {
                        elementID: 1031,
                        tenantID: 0,
                        propertyID: 0,
                        productID: 1,
                        textID: 1010,
                        text: "FOLIO SEARCH",
                        routePath: "/folio/foliosearch",
                        imgPath: "",
                        order: 1,
                        visibility: true,
                        disable: false,
                        parentID: 1030,
                        menuPosition: "Secondary",
                        menuAlignment: "Horizontal",
                        externalLink: false,
                        linkedElement: []
                    },
                    {
                        elementID: 1032,
                        tenantID: 0,
                        propertyID: 0,
                        productID: 1,
                        textID: 1011,
                        text: "UNSETTLED FOLIO",
                        routePath: "/folio/unsettled",
                        imgPath: "",
                        order: 1,
                        visibility: true,
                        disable: false,
                        parentID: 1030,
                        menuPosition: "Secondary",
                        menuAlignment: "Horizontal",
                        externalLink: false,
                        linkedElement: []
                    },
                    {
                        elementID: 1031,
                        tenantID: 0,
                        propertyID: 0,
                        productID: 1,
                        textID: 1010,
                        text: "SETTINGS",
                        routePath: "/folio/settings",
                        imgPath: "",
                        order: 1,
                        visibility: true,
                        disable: false,
                        parentID: 1030,
                        menuPosition: "Secondary",
                        menuAlignment: "Horizontal",
                        externalLink: false,
                        linkedElement: [
                            {
                                elementID: 1086,
                                tenantID: 0,
                                propertyID: 0,
                                productID: 1,
                                textID: 1065,
                                text: "POST TYPE",
                                routePath: "/folio/settings/posttypes",
                                imgPath: "",
                                order: 1,
                                visibility: true,
                                disable: false,
                                parentID: 1085,
                                menuPosition: "Ternary",
                                menuAlignment: "Vertical",
                                externalLink: false,
                                linkedElement: [],
                                breakPointNumber: 0
                            },
                            {
                                elementID: 1086,
                                tenantID: 0,
                                propertyID: 0,
                                productID: 1,
                                textID: 1065,
                                text: "DEPARTMENT",
                                routePath: "/folio/settings/department",
                                imgPath: "",
                                order: 2,
                                visibility: true,
                                disable: false,
                                parentID: 1085,
                                menuPosition: "Ternary",
                                menuAlignment: "Vertical",
                                externalLink: false,
                                linkedElement: [],
                                breakPointNumber: 0
                            },
                            {
                                elementID: 1086,
                                tenantID: 0,
                                propertyID: 0,
                                productID: 1,
                                textID: 1065,
                                text: "PAYMENT METHODS",
                                routePath: "/folio/settings/payment",
                                imgPath: "",
                                order: 3,
                                visibility: true,
                                disable: false,
                                parentID: 1085,
                                menuPosition: "Ternary",
                                menuAlignment: "Vertical",
                                externalLink: false,
                                linkedElement: [],
                                breakPointNumber: 0
                            },
                            {
                                elementID: 1086,
                                tenantID: 0,
                                propertyID: 0,
                                productID: 1,
                                textID: 1065,
                                text: "Miscellaneous",
                                routePath: "/folio/settings/miscellaneous",
                                imgPath: "",
                                order: 4,
                                visibility: true,
                                disable: false,
                                parentID: 1085,
                                menuPosition: "Ternary",
                                menuAlignment: "Vertical",
                                externalLink: false,
                                linkedElement: [],
                                breakPointNumber: 0
                            }
                        ]
                    }
                ]
            },
            {
                "elementID": 6256,
                "tenantID": 0,
                "propertyID": 0,
                "productID": 1,
                "textID": 6256,
                "text": "ALL",
                "routePath": "/all",
                "imgPath": "icon-all-menu",
                "order": 12,
                "visibility": true,
                "disable": false,
                "parentID": 0,
                "menuPosition": "Primary",
                "menuAlignment": "Horizontal",
                "externalLink": false,
                "linkedElement": []
            },
            {
                "elementID": 6256,
                "tenantID": 0,
                "propertyID": 0,
                "productID": 1,
                "textID": 6256,
                "text": "SEARCH REPORT",
                "routePath": "/allReport",
                "imgPath": "icon-Search-Reports",
                "order": 12,
                "visibility": true,
                "disable": false,
                "parentID": 0,
                "menuPosition": "Primary",
                "menuAlignment": "Horizontal",
                "externalLink": false,
                "linkedElement": []
            },
            {
                "elementID": 1001,
                "tenantID": 0,
                "propertyID": 0,
                "productID": 1,
                "textID": 1001,
                "text": "CLASS",
                "routePath": "/class",
                "imgPath": "icon-Class",
                "order": 1,
                "visibility": true,
                "disable": false,
                "parentID": 0,
                "menuPosition": "Primary",
                "menuAlignment": "Horizontal",
                "externalLink": false,
                "linkedElement": [
                    {
                        "elementID": 1001,
                        "tenantID": 0,
                        "propertyID": 0,
                        "productID": 1,
                        "textID": 1001,
                        "text": "BOOK CLASSES",
                        "routePath": "/class/bookclass",
                        "imgPath": "",
                        "order": 1,
                        "visibility": true,
                        "disable": false,
                        "parentID": 0,
                        "menuPosition": "Primary",
                        "menuAlignment": "Horizontal",
                        "externalLink": false,
                        "linkedElement": [],
                        "breakPointNumber": 0
                    },
                    {
                        "elementID": 1001,
                        "tenantID": 0,
                        "propertyID": 0,
                        "productID": 1,
                        "textID": 1001,
                        "text": "SEARCH CLASSES",
                        "routePath": "/class/searchclass",
                        "imgPath": "",
                        "order": 1,
                        "visibility": true,
                        "disable": false,
                        "parentID": 0,
                        "menuPosition": "Primary",
                        "menuAlignment": "Horizontal",
                        "externalLink": false,
                        "linkedElement": [],
                        "breakPointNumber": 0
                    },
                ],
                "breakPointNumber": 0
            }
        ]
        return of(menuObjOld);
    }
}

export interface MenuObj {
    elementID: number; //guid
    textType?: string; //Primary
    textId?: number | string; //guid
    text?: string; //guid
    routePath?: string;
    imgPath?: string;
    order?: number;
    visibility?: boolean;
    disable?: boolean;
    parentId?: string; //guid
    linkedElement?: MenuObj[] | any[];
    routeTree?: string;
    tenantID?: number;
    propertyID?: number;
    productID?: number;
    parentID?: number;
    menuPosition?: string;
    menuAlignment?: string;
    externalLink?: boolean;
    breakPointNumber?: number;
    parentElementText?: string;
}
