import { StickyDirection } from '@angular/cdk/table';
import { UntypedFormGroup } from '@angular/forms';
import { StringIterator } from 'lodash';
import { DropdownOptions } from '../common/Models/ag-models';
import { SourceSystem } from './shared/globalsContant';

export interface popupConfig {
  operation: string;
  maxListOrder?: number;
}

export interface Outlet {
  id: number;
  externalPOSId?: number;
  outletName: string;
  outletNumber: number;
  isActive: boolean;
  terminalId?: number;
  productId?: number;
  autoReceipt?: boolean;
  profitCenter? : string;
  isDefault : boolean;
  includeOutletAddress: boolean;
  outletAddresses?: OutletAddress[];
  outletContacts?: OutletContact[];
  cashDrawerPort?:string;
  cashDrawerKickOutCommand?:string;
}
export interface InventorySyncIdType{
  id : string,
  type : string
}
export interface OutletUIMapper {
  id: number;
  externalPOSId?: number;
  outletName: string;
  outletNumber: number;
  isActive: boolean;
  terminalId?: number;
  productId?: number;
  productName?: string;
  autoReceipt?:boolean;
  profitCenter? : string;
  cashDrawerPort?:string;
  cashDrawerKickOutCommand?:string;
}

export interface PackageItemInfo {
  "Id": number;
  "itemNumber": string;
  "itemDescription": string;
  "price": string;
  "Category": string;
  "Subcategory1": string;
  "Subcategory2": string;
  "Subcategory3": string;
  "Subcategory4": string;
  "Subcategory5": string;
  "itemPrice"?: number;
  "memberPrice"?: number;
  "startDate": Date;
  "endDate": Date;
}

export interface SubPropertyModel {
  subPropertyID: number;
  subPropertyCode?: string;
  subPropertyName: string;
  propertyID: number;
  isActive: boolean;
  CCTerminalId?: string;
  terminalLinkId?: number;
  terminalID?: number;
  productId?: number;
  autoReceipt?:boolean;
  profitCenter? : string;
  isDefault?: boolean;
  includeOutletAddress: boolean;
  outletAddresses?: OutletAddress[];
  outletContacts?: OutletContact[];
  cashDrawerPort?:string;
  cashDrawerKickOutCommand?:string;

}

export interface OutletContact {
  contactTypeId: number;
  number: string;
  outletId?: number;
}

export class OutletAddress{
  outletId?: number;
  address1?: string;
  address2?: string;
  address3?: string;
  zip?: string;
  state?: string;
  city?: string;
  country?: string;
}

export interface OutletsCRUD {
  getOutlets(): Promise<OutletUIMapper[]>;
  updateOutlet(outlet: SubPropertyModel): Promise<Outlet[]>;
  deleteOutlet(outlet: SubPropertyModel);
  createOutlet(body: SubPropertyModel): Promise<Outlet[]>;
  validateOutletDelete(outletId: number): Promise<boolean>;
}

export interface ReceiptModel {
  id: number;
  outletId: number;
  numberOfReceipts: number;
  displayDiscount: boolean;
  addSecondLine: boolean;
  displayOnlyPackageItem: boolean;
  suppressClerkId: boolean;
  suppressPrintedBy?: boolean;
  displayPackageDescription: boolean;
  displayPackagePrice: boolean;
  displayPackageAppointmentID: boolean;
  displayPackageStaffCode: boolean;
  serviceChargeGratuityDisplay: number;
  gratuityLine: string;
  receiptNote: string;
  displayGroupedItem?: boolean;
  clerkIdPrintValue?: number;
  printedByPrintValue?: number;
  displayDetailedCustomFee?: boolean;
}
export interface PropertyReceiptModel {
  id: number;
  screenName: string;
  moduleName: string;
  configValue: any;
  defaultValue: any;
}
export const enum ImgType {
  receiptHeader = 'receiptHeader',
  receiptFooter = 'receiptFooter',
}
export const enum RetailImgRefType {
  receiptHeader = 'RECEIPTHEADER',
  receiptFooter = 'RECEIPTFOOTER',
}

export interface receiptImageConfiguration {
  imageReferenceId?: string;
  propertyImageAlign:DropdownOptions;
  url?: string;
  imageId?: number;
}
export interface ImageData {
  id: number;
  referenceType: string;
  referenceId: number;
  sequenceNo: number;
  contentType: string;
  data: string;
  thumbnailData: string;
  imageReferenceId: string;
}
export interface PropertyConfigurationModel {
  displayAuthCode: boolean;
  AuthCodeReceiptName: string;
  displayChangeDue?: boolean;
  displayTeeTimeInfoInDeposit?: boolean;
  customFeeReceiptName?: string;
  receiptFooterNote?: string;
  printGiftReceipt?: boolean;
  printPendingSettlementReceipt?: boolean;
  displayImageInReceiptHeader?:boolean,
  displayImageInReceiptFooter?: boolean,
  headerimagedata?: boolean,
  footerimagedata?: boolean,
  headerImageReferenceId?: string,
  footerImageReferenceId?:string,
  receiptImageFooterNote?:string
}

export interface QuickSaleCategory {
  id: number;
  quickSaleCategory: string;
  isActive: boolean;
  listOrder: number;
  quickSaleCategoryOutlets: QuickSaleCategoryOutlets[]
}

export interface QuickSaleData{
  category: QuickSaleCategory[];
  items: QuickSaleItem[];
}

export interface QuickSaleCategoryOutlets{
 id: number;
 outletId: number;
 quickSaleCategoryId: number;
 listOrder: number;
}

export interface PaymentMethod {
  id: number;
  paymentTypeId: number;
  paymentMethod: string;
  isActive: boolean;
  requireComments: boolean;
  listOrder: number;
  isDefault: boolean;
  allowEarn: boolean;
  financialBinLevel?: number;
  noOfReceipts?:number;
  requireReceipts?: boolean;
  parentMethodId?: number;
  parentTypeId?: number;
  isCloned?: boolean;
  additionalConfigurations?: string;
  InitiateFormControlsAndVariables?: any;
  requireSignatureCapture?: boolean;
  includeSignatureLine?: boolean;
  isAutoRemoveTax?: boolean;
  postToPMS?: boolean;
  tenderReducesDiscount?: boolean
  requireCashDrawer?:boolean
}

export interface QuickSaleItem {
  id: number;
  quickSaleCategoryId: number;
  itemId: number;
  categoryName?: string;
  outletId?: number;
  isRemoved: boolean;
  listOrder?:number
}

export interface QuickSaleCategoryChange
{
  outletId: number;
  fromCategoryId: number;
  toCategoryId: number;
  quickSaleCategoryId: number;
  fromOrder: number;
  toOrder: number;
}

export interface QuickSale {
  category: QuickSaleCategory[];
  items: QuickSaleItem[];
}

export interface ItemDetailInfo {
  id: number;
  summaryId: number;
  itemNumber: string;
  status: number;
  retailImportItemInfo: RetailImportItemInfo;
  errorMessage: string;
  errorCode: string;
}
export interface SyncDetailInfo {
  id: number;
  userId: number;
  status: string;
  inventorySyncDetails: InventorySyncDetails[];
  request: string;
  createdDate: Date;
  sessionId: number;
}

export interface CancelInventorySync {
  summaryId: number;
  detailId: number;
  isSummaryCancelEvent: boolean;
}

export class InventorySyncDetails {
  public id: number;
  public inventorySyncSummaryId: number;
  public enhancedInventoryIntegrationLogId: number;
  public type: string;
  public typeId: number;
  public status: string;
  public request: string;
  public response: string;
  public errorMessage: string;
  public errorCode: string;
}
export interface UserInfo {
  id: number;
  firstName: string;
  lastName: string;
  userName: string;
}
export enum ImportItemStatus
{
  NotProcessed = 0,
  Processed = 1,
  Failed = 2
}


export interface RetailMenu {
  id: any;
  viewValue: string;
  callDesc: string;
  IsAuthorized: boolean;
  breakPointNumber?: number;
  isViewOnly?: boolean;
}
export interface Claims {
  userClaimId: number,
  userRoleId: number,
  breakPointNumber: number,
  description: string,
  allow: boolean,
  view: boolean,
  viewOnlyAllowed: boolean
}

export interface RoleConfiguration {
  id?: number;
  description: string;
  localeId: number;
  count?: number;
  userClaims?: Claims[];
}


export class Commission {
  public id: number;
  public threshold: number;
  public flatAmount: number;
  public percentage: number;
  public itemId: number;
  public classId: number;
  public isAfterDiscount: boolean;
  public isTotalRevenue: boolean;
  public isItemOnly: boolean;
  public staffId: number;
  public staffType: string;
  public propertyId: number;
  public subPropertyId: number;
  public productId: number;
  public outletId: number;
}
export class RetailCommission extends Commission {
  public itemDescription: string;
  public locationDescription: string;
  public categoryId: number;
}
export class CommissionableRetailItem {
  public id: number;
  public categoryId: number;
  public description: string;
  public isCommissionable: boolean;
}

export enum RetailItemType {
  RetailItemRetailPOSOnly = 1,
  RetailItemAvailableForSpaPackages,
  SpaServices,
  SpaPackage,
  AppointmentAddon,
  Deposit,
  RentalItem,
  InternalGiftCard,
  ExternalGiftCard,
  RevenueItem,
  CustomFee,
  CancellationFee,
  NoShowFee,
  PMSAddOnsRentalItem,
  Classes,
  Lockers
}
export class RetailItemDetail {
  public id: number;
  public externalPOSId: number;
  public itemNumber: string;
  public itemDescription: string;
  public salesPrice: number;
  public memberPrice?: number;
  public suggestedPrice?: number;
  public costPrice: number;
  public marginPercentage: number;
  public barcode: string;
  public secondaryBarcode: string;
  public category?: number;
  public isCommissionable: boolean;
  public commissionPercentage?: number;
  public isCommissionRequired: boolean;
  public itemType: number;
  public isGroupingKey: boolean;
  public isScaledItem: boolean;
  public unitOfMeasure: number;
  public isTeeTimeTrigger: boolean;
  public isCartTrigger: boolean;
  public isActive: boolean;
  public isRequestName: boolean;
  public isAllowPriceOverride?: boolean;
  public isPMSPackageItem: boolean;
  public isRequireComments: boolean;
  public isOpenItem: boolean;
  public isMultiPack: boolean;
  public isGratuityAllowed: boolean;
  public gratuity?: number;
  public isServiceChargeAllowed: boolean;
  public serviceCharge?: number;
  public isPrintCartAgreement: boolean;
  public isPrintClubAgreement: boolean;
  public isPrintShoeAgreement: boolean;
  public isPrintToCaddyShack: boolean;
  public isPrintOnlyOnePerTansaction: boolean;
  public isHangingTicket: boolean;
  public isSmallTicket: boolean;
  public isPrintRetailSaleChit: boolean;
  public linkedItemId?: number;
  public multiStartDate?: string;
  public multiEndDate?: string;
  public multiExpDays?: number;
  public multiSalesPrice?: number;
  public multiMemberPrice?: number;
  public multiCostPrice?: number;
  public multiGratuity?: number;
  public multiServiceCharge?: number;
  public multiPerSale?: number;
  public isMultiUnlimited: boolean;
  public seasonalStartDate?: string;
  public seasonalEndDate?: string;
  public seasonalPrice?: number;
  public seasonalMemberPrice?: number;
  public useInventory: boolean;
  public subCategory1: number;
  public subCategory2: number;
  public subCategory3: number;
  public subCategory4: number;
  public subCategory5: number;
  public isItemNumberAutogenerate?: boolean;
  public allowEarn: boolean;
  public disposableItem: boolean;
  public perHourRentalRate: number;
  public perDayRentalRate: number;
  public isMultipleLinkedItem: boolean;
  public isExternal: boolean;
  public sourceSystem: SourceSystem;
}

export class UpdateCategoryLinking {
  public retailItemId: number;
  public categoryId: number;
  public subCategoryId1: number;
  public subCategoryId2: number;
  public subCategoryId3: number;
  public subCategoryId4: number;
  public subCategoryId5: number;
}

export interface dateRangeUIModel {
  dateRange: { startDate: Date, endDate: Date };
  value: Date;
  controlchanged: string;
}

export interface RetailItemViewModel {
  id: number;
  itemNumber: string;
  itemName: string;
  salesPrice: number;
  memberPrice: number;
  isActive: boolean;
  category: number;
  categoryName: string;
  subCategory1: number;
  subCategory1Name: string;
  subCategory2: number;
  subCategory2Name: string;
  subCategory3: number;
  subCategory3Name: string;
  subCategory4: number;
  subCategory4Name: string;
  subCategory5: number;
  subCategory5Name: string;
  quantityOnHand: number;
  itemPar: number;
  costPrice: number;
}

export class RetailItemTax {
  public id: number;
  public retailItemId: number;
  public taxName: string;
  public taxId: number;
  public outletId: number;
}

export class RetailItemBarCode {
  public id: number;
  public retailItemId: number;
  public barCode: string;
  public isPrimary: boolean;
  public isSecondary: boolean;
}

export class RetailItemVendor {
  public id: number;
  public retailItemId: number;
  public vendorId: number;
  public vendorCode?: string;
  public vendorName?: string;
  public unitQuantity?: number;
  public leadDays?: number;
  public minOrder?: number;
  public supplierItemId?: string;
  public quantiyOnHand?: number;
}

export class ConfiguredTax {
  public id: number;
  public outletId: number;
  public taxName: string;
  public startDate?: Date;
  public endDate?: Date;
  public isDefaultTax: Boolean;
}

export class SelectedTaxForRetailItem {
  public id?: number;
  public outletId?: number;
  public taxName: string;
  public isSelected: boolean;
}

export class PackagedItem {
  public retailItemId: number;
  public parentItemId: number;
  public startDate: string;
  public endDate: string;
  public price: number;
  public memberPrice: number;
}

export class OutletItem {
  public retailItemId: number;
  public outletId: number;
  public syncStatus:string;
}

export class MultiPackDetail {
  public retailItemId: number;
  public multiStartDate: string;
  public multiEndDate:string;
  public multiExpDays:number;
  public multiSalesPrice:number;
  public multiMemberPrice:number;
  public multiCostPrice:number;
  public multiGratuity:number;
  public multiServiceCharge:number;
  public multiPerSale:number;
  public isMultiUnlimited:boolean;
}

export class RetailItem {
  public retailItemDetail: RetailItemDetail;
  public outletItem: OutletItem[];
  public packagedItem: PackagedItem[];
  public retailItemTax: RetailItemTax[];
  public retailItemBarCode: RetailItemBarCode[];
  public retailItemVendor: RetailItemVendor[];
  public retailItemTemplateConfig : RetailItemTemplateConfig[];
  public roomTypeAssignmentRetailItemMapping?: RoomTypeAssignmentRetailItemMapping[];
  public multipackDetail : MultiPackDetail[];
}

export class UnitOfMeasure {
  public id: number;
  public name: string;
  public isActive: boolean;
  public listOrder: number;
}

export class InventoryOutlet {
  public id: number;
  public inventoryItemId: number;
  public itemId: number;
  public outletId: number;
  public quantity: number;
  public updatedQuantity: number;
  public itemPar: number;
  public unitCost: number;
  public weightedAverageCost: number;
  public reason: string;
  public mtd: number;
  public ytd: number;
}

export class InventoryInfo {
  public id: number;
  public retailItemId: number;
  public outlets: InventoryOutlet[];
}

export class RetailItemInfo {
  public retailItem: RetailItem;
  public inventoryInfo: InventoryInfo;
  public rentalItemInformation: RentalItemInformation;
}

export class RetailImportItemInfo {
  public retailItem: RetailItem;
  public inventoryInfo: InventoryInfo;
  public errorMessage: string;
  public errorCode: string;
}
export class RetailItemDesc {
  public retailItemId: number;
  public itemDescription: string;
}


export interface CCTerminal {
  id?: number;
  terminalId: string;
  merchantNumber: string;
  isActive: boolean;
  listOrder?: number;
  isDefault?: boolean;
}

export class ItemInventoryTransfer {
  public itemId: number;
  public reason: string;
  public outlets: ItemInventoryTransferOutlets[];
}

export class ItemInventoryTransferOutlets {
  public fromOutletId: number;
  public toOutletId: number;
  public quantity: number;
}

export interface Translog {
  TypeId: number,
  LogType: string,
  Description: string,
  OldValue: string,
  NewValue: string
}


export interface OutletTerminal {
  id?: number;
  subPropertyId: number;
  ccTerminalId: string;
  isActive?: boolean;
}
export interface OutelTerminalHeaderDropDown {
  id: string;
  viewValue: string;
}

export interface Outlet {
  id: number;
  isActive: boolean;
  outletNumber: number;
  outletName: string;
  CCTerminalId: string;
  outletTerminalId: number;
  productId?: number;
  subPropertyID?: number;
  profitCenter?: string;
  isDefault : boolean;
  address1?: string;
  address2?: string;
  address3?: string;
  zip?: string;
  state?: string;
  city?: string;
  country?: string;
  includeOutletAddress: boolean;
  cashDrawerPort?:string;
  cashDrawerKickOutCommand?:string;
}

export class TaxConfiguration {
  public id: number;
  public outletId: number;
  public outletName?: string;
  public taxName: string;
  public isAmount: boolean;
  public taxAmount: number;
  public taxAmountFormatted?: string;
  public startDate: string;
  public endDate: string;
  public allowTaxOnServiceCharge:boolean;
  public allowTaxOnGratuity: boolean;
  public isDefaultTax: boolean;
  public hasTaxOnTax: boolean;
  public linkedTaxes: LinkedTax[];
  public isTaxRevenueToProperty: boolean;
  public taxUniqueId: number;
  public allowRemovalForPaymentMethod: boolean;
}

export class LinkedTax {
  public id: number;
  public isFlatAmount: boolean;
  public linkedTaxValue: number;
  public linkedTaxName: string;
  public isActive: boolean;
  public isLinkedTaxRevenueToProperty: boolean
  public linkedTaxUniqueId: number;
  public allowRemovalForPaymentMethod: boolean;
}

export class Tax {
  public id: number;
  public outletId: number;
  public outletName?: string;
  public taxName: string;
  public archive: boolean;
  public taxMaintenances: TaxMaintenance[];
  public allowTaxOnServiceCharge:boolean;
  public allowTaxOnGratuity: boolean;
}

export class TaxMaintenance {
  public taxId: number;
  public isAmount: boolean;
  public taxAmount: number;
  public taxAmountFormatted?: string;
  public startDate: string;
  public endDate: string;
}

export class OutletTerminalUIMapper {
  public id: number;
  public outletId: number;
  public outletName?: string;
  public storeId: number;
  public isActive: boolean;
  public terminalId: string;
  public terminalName: string;
  public retailUserId: string
  public retailUserPassword: string;
  public rRetailJWT?: string;
}

export class OutletAPIModel {
  public subPropertyID: number;
  public subPropertyCode: string;
  public subPropertyName: string;
  public productId: number;
  public propertyID: number;
  public isActive: boolean;
  public storeId: string;
}


export interface NextId {
  id: number;
}

export class InventoryOutletToDisplay {
  public id: number;
  public name: string;
  public inventoryItemId?: number = 0;
  public outletId?: number = 0;
  public quantity?: number = 0;
  public updatedQuantity?: number;
  public itemPar?: number = 0;
  public unitCost?: number = 0;
  public weightedAverageCost?: number = 0;
  public reason?: string = '';
  public mtd?: number = 0;
  public ytd?: number = 0;
}

export namespace ApiGateWay {

  export interface GetOutletTerminal {
    getOutletTerminal: GetOutletTerminal;
    getSubProperty: GetSubProperty;
  }

  export interface GetSubProperty {
    result: ApiModel.SubPropertyApiModel[];
    errorCode: number;
    errorDescription?: any;
    successStatus: boolean;
    propertyId: number;
    subPropertyId: number;
  }



  export interface GetOutletTerminal {
    result: ApiModel.OutletTerminal[];
    errorCode: number;
    warnings?: any;
    successStatus: boolean;
  }

}

export namespace ApiModel {
  export interface OutletTerminal {
    id: number;
    subPropertyId: number;
    ccTerminalId: string;
  }
  export interface SubPropertyApiModel {
    subPropertyName: string;
    subPropertyCode?: any;
    subPropertyId: number;
    propertyCode: string;
    propertyName: string;
    productId: number;
    propertyId: number;
    languageCode?: any;
    currencyCode?: any;
    tenantId: number;
    userId: number;
    roleId: number;
    propertyDate?: any;
    timeZone?: any;
    autoLogOff: boolean;
    logOffAfter: number;
    isActive: boolean;
  }
}

export namespace Inventory {
  export interface InventoryOutletItemCount {
    id: number;
    inventoryOutletCountId: number;
    itemId: number;
    frozenQuantity: number;
    countedQuantity: number;
  }

  export interface InventoryOutletCount {
    id: number;
    outletId: number;
    countStatus: string;
    countType: string;
    updateType: string;
    dateStarted: Date | string;
    dateCompleted: Date | string;
    itemCount: InventoryOutletItemCount[];
  }

  export interface InventoryOutletItemInfo {
    id: number;
    itemId: number;
    outletId: number;
    quantity: number;
    itemPar: number;
    unitCost: number;
  }

  export interface InventoryItemModel {
    item: InventoryOutletItemInfo[];
    outletCount: InventoryOutletCount;
    outletStatus: InventoryOutletStatus;
    itemInfo: RetailItemViewModel[];
  }
  export interface InventoryOutletStatus {
    id: number;
    outletId: number;
    status: string;
    updatedDate: Date | string;
    userId: number;
  }

  export interface InventoryCountUpdate {
    updateType: string;
    itemQuantity: InventoryCountUpdateQuantity[];

  }

  export interface InventoryCountUpdateQuantity {
    itemId: number;
    updatedQuantity: number;

  }

  export interface InventoryStatus {
    outletId: number;
    status: string;
  }

}

export class ZebraPrinters {
  printer: ZebraPrinter[];
}

export class ZebraPrinter {
  name: string;
  uid: string;
  connection?: string;
  deviceType?: string;
  version?: number;
  provider?: string;
  manufacturer?: string;
}

export class BarcodePrintRequest {
  device: ZebraPrinter;
  data: string;
}

export class BarcodePrintData {
  itemNumber?: number;
  itemDescription?: string;
  itemPrice?: number;
  outletName?: string;
  barcode?: string;
  isHangingTicket: boolean;
  isSmallSticker: boolean;
  printQuantity?: number;
}

export class PrintLabelsData {
  outletName?: string;
  itemDescription?: string;
  itemPrice?: number;
  barcode?: string;
  printQuantity?: number;
}

export interface BaseResponse<T> {
  result: T;
  errorCode: number;
  errorDescription: string;
  successStatus: boolean;
  propertyId: number;
  outletId: number;
}

export interface UserAlertsModal {
  id: number;
  message: string;
  priority: number;
}


export interface GlobalConfiguration {
  Switches: SwitchConfig;
}

export interface SwitchConfig {
  GuestProfileRequired: boolean
}

export interface localizationJSON {
  common: any;
  login: any;
  calendar: Calendar;
  home: any;
  header: any;
  bookAppointment: any;
  shop: any;
  client: any;
  reports: any;
  receipt: any;
  appointmentSearch: any;
  appointmentConfirmation: any;
  setting: any;
  staffSchedule: any;
  utilities: any;
  retailsetup: any;
  userConfig: any;
  breakpoint: any;
  dayEnd: any;
  None?: any;
  globalSearch: any;
  inventory: any;
  retailInventory: any;
  teetime: any;
  alertPopup: any;
  settings: any;
  lessonBooking: any;
  vendors: any;
}

export interface Calendar {
  Sun: string;
  Mon: string;
  Tue: string;
  Wed: string;
  Thu: string;
  Fri: string;
  Sat: string;
  Sunday: string;
  Monday: string;
  Tuesday: string;
  Wednesday: string;
  Thursday: string;
  Friday: string;
  Saturday: string;
  January: string;
  February: string;
  March: string;
  April: string;
  May: string;
  June: string;
  July: string;
  August: string;
  September: string;
  October: string;
  November: string;
  December: string;
  Jan: string;
  Feb: string;
  Mar: string;
  Apr: string;
  Jun: string;
  Jul: string;
  Aug: string;
  Sep: string;
  Oct: string;
  Nov: string;
  Dec: string;
}

export interface DaysModel {
  id: number;
  short: string;
  long: string;
  code: string;
}

export interface LocalizedMonthsModel {
  id: number;
  short: string;
  long: string;
  code: string;
}

export interface UserAlertsModal {
  id: number;
  message: string;
  priority: number;
}

export interface CommissionTemplate {
  id: number;
  templateCode: string;
  templateName: string;
  commissionClassId: number;
  productId: number;
  splitDetail: CommissionTemplateSplit[];
}

export interface CommissionTemplateSplit {
  id: number;
  commissionTemplateId: number;
  levelDescription: string;
  percentage: any;
}

export interface DaysLocaleSorted_app_multiple {
  id?: number,
  name: string;
  setSelected: boolean;
}

export interface SystemConfig {
  id: number;
  switch: string;
  value: string | boolean;
  moduleId: number;
}

export interface MoreFilterOptions {
  ShowMoreFilters: boolean;
  moreData: any;
  selectedMoreData: any;
  top: number;
  left: number;
  belongto: string;
  fromPage: string;
}

export interface UserBreakPoint {
  userRoleId: number;
  breakPointNumber: number;
  allow: boolean;
  view: boolean;
}

export interface ListOrder {
  listOrder: number;
}


export interface PropertySetting {
  id: number;
  propertyId: number;
  propertyDate: string;
  productVersion: string;
  userProductVersion: string;
  activateRetailInterface: boolean;
  vatEnabled: boolean;
  taxId: string;
}

export interface PaymentConfiguration {
  propertyId: number;
  subPropertyId: number;
  outletId: number;
  configKey: string;
  configValue: string;
  isActive: boolean;
}

export interface EatecConfiguration {
  eatecEnabled: boolean;
  eatecConfigurationURI: string;
}

export class UserSessionConfiguration {
  id?: number;
  userId: number;
  defaultOutletId?: number;
  defaultPaymentId?: number;
  defaultTerminalId?: number;
  defaultCourseId?: number;
  defaultMachineId?: number;
  defaultPaymentDevice?: string;
  defaultDeviceName?: string;
  isIdtechSred: boolean;
  smallStickersPrinter?: string;
  hangingTicketsPrinter?: string;
  propertyId: number;
}

export interface SpaServiceLocation {
  id: number;
  code: string;
  description: string;
  listOrder: number;
  selected?: boolean;
}

export interface TherapistDetails {
  code: string;
  firstName: string;
  lastName: string;
  gender: string;
  dateOfHire: string;
  seniorityLevel: string;
  alsoKnownAs: string;
  allowGratuity: boolean;
  allowServiceCharge: boolean;
  allowCommission: boolean;
  listOrder: number;
  id: number;
  isActive: boolean;

}

export interface PackageDetail {
  id: number;
  code: string;
  description: string;
  startDate: string;
  endDate: string;
  packagePrice: number;
  maxDays: any;
  maxPeople: any;
  isSelectCompAtBooking: boolean;
  numberOfItems: number;
  isAutomaticRecap: boolean;
  isBookLunch: boolean;
  isActive: boolean;
  packagePolicy: string;
  cancellationPolicy: string;
  isDefaultCancelPolicy: boolean;
  autopick: any;
  emailCommunicationType: any;
  retailItemId: number;
}

export interface SpaServices {
  id: number,
  code: string;
  description: string;
  serviceGroupDescription?: string;
}

export interface ServiceAddOns {
  id?: number;
  addOnName: string;
  price: number;
  retailCategoryId: number;
  commissionable: boolean;
  retailItemId: number;
}

export interface LinkCode {
  Id: number;
  Code: string;
  Description: string;
  Color: string;
  ListOrder: number;
  Status: any;
  StartDate: any;
  EndDate: any;
}

export interface BreakType {
  Code: string;
  Description: string;
  Color: string;
  IsUtilized: boolean;
}

export interface SystemConfiguration {
  id: number;
  switchId: number;
  switch: string;
  switchType: string;
  value: string | boolean;
  isActive: boolean;
  moduleId: number;
}

export interface PackageAvailableDays {
  packageId: number;
  typeName: string;
  isAvailableOnSunday: boolean;
  isAvailableOnMonday: boolean;
  isAvailableOnTuesday: boolean;
  isAvailableOnWednesday: boolean;
  isAvailableOnThursday: boolean;
  isAvailableOnFriday: boolean;
  isAvailableOnSaturday: boolean;
}

export interface PackageYield {
  id: number;
  code: string;
  description: string;
  available: number;
  sold: number;
  remaining: number;
  packageClass: number;
  yieldDate: string;
  IsAvailableReadOnly?: boolean;
}

export class ReportAPIOptions {
  code: string;
  format?: ReportDownloadFormat = 'HTML';
  params: ReportParams[];
  URIParams: ReportParams[];
  filters: any;
  pageBreak: boolean;
  downloadFileName?: string;
}

export interface ReportParams {
  [parameter: string]: string | number | boolean;
}

export type ReportDownloadFormat = 'PDF' | 'WORD' | 'EXCEL' | 'IMAGE' | 'HTML' | 'TEXT'| 'RAWDATA';

export enum AllReports {
  AppointmentListingByLocation = 'AppointmentByLocation',
  AppointmentListing = 'AppointmentListing',
  AppointmentByStaffMember = 'AppointmentByStaffMember',
  AppointmentByLinkCode = 'AppointmentByLinkCode',
  Itinerary = 'Itinerary',
  CommissionReport = 'CommissionReport',
  Gratuity = 'Gratuity',
  CustomField1 = 'CustomField1',
  CustomField2 = 'CustomField2',
  CustomField3 = 'CustomField3',
  CustomField4 = 'CustomField4',
  CustomField5 = 'CustomField5',
  Cancellation = 'Cancellation',
  NoShowAppointment = 'NoShowAppointment',
  ServiceCharge = 'ServiceCharge',
  StaffMemberSummary = 'StaffMemberSummary',
  MultiPackItems = 'MultiPackItems',
  ReturnedItems = 'ReturnedItems',
  InventorySummary = 'InventorySummary',
  InventoryOnHand= 'InventoryOnHand',
  InventoryDetails = 'InventoryDetails',
  InventoryTransfer = 'InventoryTransfer',
  InventoryWash = 'InventoryWash',
  InventoryAudit = 'InventoryAudit',
  SalesByDiscountTypes = 'SalesByDiscountTypes',
  SalesBySubCategory = 'SalesBySubCategory',
  SalesByCategory = 'SalesByCategory',
  SaleByItem = 'SaleByItem',
  SalesByVendor = 'SalesByVendor',
  CorrectVoid = 'CorrectVoid',
  Shift = 'Shift',
  RevenueSummary = 'RevenueSummary',
  TopSpenders = 'TopSpenders',
  Transaction = 'Transaction',
  Receipt = 'Receipt'
}

export interface SaleRequest {
  handle: string; //(Required)(Encrypted data)
  amount: Amount; //(Required)
  inquirerInfo: InquirerInformation; //(Required)
}

export interface Amount {
  requestAmount: number; // (Required)
  authorisedAmount?: number;
  tipAmount?: number;
  taxAmount?: number;
  saleAmount?: number;
}

export interface InquirerInformation {
  terminalId: string; // (Required)
  store?: string;
  division?: string;
  enterprise?: string;
  customer?: string;
  mealPeriod?: string;
  coverCount?: string;
  orderType?: string;
  orderNumber: string; // (Required)
  employeeId?: string;
  profitCenter: string; //(Required)
  tenderId: string; //(Required)
  zipcode?: string;
  isPartialTenderAllowed: boolean;
  financialBins?: Array<FinancialBin>
}

export interface FinancialBin {
  Id: string,
  type: string,
  amt: string
}

export interface ReportAPIModel {
  code: string;
  format: ReportDownloadFormat;
  downloadFileName: string;
  parameters: { [key: string]: string };  //Dictionary<string,string>
  uRIParams: { [key: string]: string };   //Dictionary<string,string>
  filterBody: any;
  language: string;
  dateFormat: string;
}

export const HttpResponseStatus = {
  Success: "success",
  Failure: "failure"
}

export interface ClientLabel {
  Id: number;
  Name: string;
  vip? : string;
}

export interface ServicePriceType {
  price: number
  serviceId: number
  priceTypeId: number
}

export interface clientRecentAppsResponse {
  serviceName: string
  therapistNames: string[]
  date: Date
  status: string
}

export interface RecentAppointment {
  appointmentDetails: string;
  action: string;
}

export interface ServicePriceTypeWithCode extends ServicePriceType {
  code: string
}

export interface ClientScreenProperties {
  minGuest: number;
  maxGuest: number;
  helperText: string;
  selectedStaff?: any;
}

export interface ClientSOAPNote {
  id: number;
  clientId: number;
  userId: number;
  userName: string;
  note: string;
  loggedTime: string;
}

export interface ClientCreditCardInfo {
  id: number;
  clientId: number;
  tokenTransId: number;
  isActive: boolean;
  createdTime: Date | string | null;
}

export interface clientInfoDisplay {
  name: any;
  age: any;
  gender: any;
  email: any[];
  phone: any[];
  address: any;
}

export interface appointment {
  appointmentDetail: appointmentDetail;
  appointmentTherapists: appointmentTherapist[];
  appointmentAddOn: appointmentAddOn[];
}

export interface appointmentDetail {
  id?: any;
  startTime: any;
  endTime: any;
  clientId: number;
  serviceGroupId: number;
  serviceId: number;
  locationId: number;
  comments: string;
  status: string,
  cancelId: any;
  duration: any;
  setUpTime: any;
  breakDownTime: any;
  price: any;
  doNotMove: boolean;
  requestStaff: boolean;
  isVip: boolean;
  genderPreference: any;
  checkOutComments: string;
  noShow: boolean;
  packageId: number;
  appointmentType: string;
  cancelReason: number;
  cancelComments: string;
  cancelFee: any;
  customField1?: number;
  customField2?: number;
  customField3?: number;
  customField4: string;
  customField5: string;
  tempHoldId: string;
  tempHoldLinkId: number;
  isTherapistOverbook: boolean;
  isLocationOverbook: boolean;
  isClientOverbook?: boolean;
  linkCodeId?: number;
  guestId?: string;
  clientComments: string;
  activityId?: any;
  clientMultiPackId?: any;
  transactionId?: number;
  transactionDetailId?: number;
  ServiceCharge?: number;
  Gratuity?: number;
  Tax?: number;
  TotalAmount?: number;
  multiGroupId?: string;
  userSessionId?: number;
  packageGroupId?: string;
  priceTypeId?: number;
  serviceChargePercent?: number;
  gratuityPercent?: number;
}
export interface appointmentTherapist {
  therapistId: number;
  appointmentId: number;
  id?: number
}

export interface appointmentAddOn {
  id?: number;
  appointmentId: number;
  addOnId: number;
  therapistId: number;
  price: number;
}

export interface BreakTypeDetail {
  id: number;
  code: string;
  description: string;
  color: string;

}

export interface CalendarGridBodyProps extends appointment {

  Icons: any[];
  clientDetails: any;
  appointmentIconDetail: any;
  breakTypeDetail: BreakTypeDetail;
  deposit: any[];
  retailTransactionStatus: string;


  setCss: boolean;
  overLapped: number;
  multiGroupOverlap: number;
  overlappedFlag: boolean;
  statusClass: string;
  setupColorClass: string;
  breakdownColorClass: string;
  cellClass: string;
  cellBDClass: string;
  cellSUClass: string;
  cellPTDClass: string;
  startTimewithsetuptime: string;
  endTimewithbreakdowntime: string;
  serviceName: string | number;
  locationName: string | number;
  therapistName: string;
  appointmentAddOns: string;
}

export interface DateInput {
  className?: string;
  errorMessage?: string;
  form?: UntypedFormGroup;
  formControlName?: string;
  hidden?: boolean;
  maxDate?: Date | string;
  minDate?: Date | string;
  placeHolder?: string;
  value?: Date | string;
  automationId?: string;
}

export interface ButtonValue {
  label: string;
  type: string;
  disabledproperty?: boolean;
  customclass?: string;
}

export interface ReturnButtonValue {
  event: object;
  buttontype?: string;
  value?: number;
}

export interface PlayerTransaction {
  id: number;
  playerId: number;
  transactionId: number;
  greenFeeTax: number;
  cartFeeTax: number;
  amountPaid: number;
  remainingDue: number;
  status: PlayerPaymentstatus;
}

export interface PlayerTransactionDetail {
  id: number;
  playerId: number;
  playerName: string;
  transactionDetailId: number;
}

export enum PlayerPaymentstatus {
  booked = 1,
  noShow = 2,
  cancelled = 4,
  paid = 8,
  unPaid = 16,
  refund = 32,
  checkIn = 64,
  checkOut=128
}

export interface OutletSubProperty {
  subPropertyID: number
  subPropertyCode: string
  subPropertyName: string
  propertyID: number
  isActive: boolean
  profitCenter?: string
  autoReceipt?:boolean
  roomChargePostingPayMethodId?: number;
  cashDrawerPort?: string;
  cashDrawerKickOutCommand?: string;
}

export interface TransactionLock {
  id: number;
  transactionId: number;
  lockedBy: number;
  createdAt: Date | string;
}

export enum TransactionState {
  Valid = 0,
  Invalid,
  Locked
}

export interface VATConfiguration {
  id: number;
  propertyId: number;
  productId: number;
  vatNumber: string;
  serviceChargeOnGross: boolean;
  serviceChargeOnNet: boolean;
  vatEnbledForServiceCharge: boolean;
  serviceChargeVATId: number;
  gratuityOnGross: boolean;
  gratuityOnNet: boolean;
  itemCommissionOnGross: boolean;
  itemCommissionOnNet: boolean;
  revenueCommissionOnGross: boolean;
  revenueCommissionOnNet: boolean;
  noTaxExempt: boolean;
  addOnVatDetails:AddOnVatDetails[];
}

export interface AddOnVatDetails{
  id: number;
  propertyVATConfigurationId: number;
  vatId: number;
  type: number;
}

export enum GrossNetEnum {
  Gross = 1,
  Net = 2
}

export interface ModifyItemQuantity {
  ticketNumber: string;
  checkHandleGuid: string;
  index: number;
  newQuantity: number;
  outletId: number;
  terminalId: number;
}

export interface Product {
  id: number;
  productName: string;
  checked?: boolean;
}

export const RETAILSETUP = "retailsetup";

export enum menuTypes {
  primary,
  secondary,
  tertiary,
  lowerLevel
}

export enum RevenuePostTypes {
  Settlement = 1,
  Discount,
  PaymentMethod,
  Tax,
  ServiceCharge,
  Gratuity,
  Card,
  Surcharge
}
export enum MappingScreen {
 PostTypeMapping = "POSTTYPEMAPPING",
 MemberBucketMapping = "MEMBERACCRUALMAPPING",
 ResortFinancePostTypeMapping = "RESORTFINANCEPOSTTYPEMAPPING",
 GeneralLedgerMapping = "GENERALLEDGERMAPPING"
}

export enum MemberBucketTypes {
  Settlement = "SETTLEMENT",
  Discount = "DISCOUNT",
  PaymentMethod = "PAYMENTMETHOD",
  Tax = "TAX",
  ServiceCharge = "SERVICECHARGE",
  Gratuity = "GRATUITY",
  Card = "CARD"
}

export enum AccrualConstants {
  Bucket = "Bucket"
}

export interface PostTypeLinking {
  id: number;
  outletId: number;
  postCode: string;
  linkingId: number;
  linkType: number;
  isUpdated?: boolean;
  propCode: string;
  postingType : PostingType
}

export enum PostingType{
  Default = 0,
  ResortFinance = 1,
  GeneralLedger = 2
}

export interface GeneralLedgerLinking {
  id: number;
  outletId: number;
  linkId: number;
  linkType: number;
  glAccountId: string;
  departmentId: string;
  businessUnit: string;
  operatingUnit: string;
  isActive: boolean;
  isUpdated?: boolean;
}

export interface MemberBucketLinking {
  id: number;
  outletId: number;
  bucketName: string;
  bucketCode: string;
  linkingId: number;
  linkType: number;
  isUpdated?: boolean;
}

export interface APIMemberBucketLinking {
  id: number;
  outletId: number;
  bucketName: string;
  bucketCode: string;
  linkingId: number;
  linkType: string;
  isUpdated?: boolean;
}

export interface PostTypeDetails {
  id: number;
  description: string;
  postCode: string;
  department: string;
  pmsSystem: number;
  isActive: boolean;
  isAllCategory?:boolean;
  propCode: string;
}

export interface RevenuePostingPrimaryType {
  id: number;
  name: string;
  linkType: RevenuePostTypes;
  outletId: number;
}

export interface MemberBucketPrimaryType {
  id: number;
  name: string;
  linkType: MemberBucketTypes;
  outletId: number;
}

export interface CreditCardIssuer{
     id: number;
     issuerType: string;
     displayName: string;
}

export interface RoomTypeAssignmentRetailItemMapping{
  id: number;
  retailItemId: number;
  roomTypeId: number;
}

export interface RoomType {
  id: number;
  code: string;
  name: string;
  listOrder: any;
  isActive: boolean;
  buildingId: number;
  noOfRooms: number;
  countRoomAs: number;
  noOfPets: number;
  maximumGuests: number;
  maximumNights: number;
  isBedded: boolean;
  isPerm: boolean;
  isUnblockPasscode: boolean;
  isBlockRequired: boolean;
  isAllowOversell: boolean;
  isOccToInspOnCheckOutRequired: boolean;
  isPetAllowed: boolean;
  isBlockingReminder: boolean;
  isSuite: boolean;
  iDeaSExtract: boolean;
  amount: any;
  resortFeeType: any;
  feeFrequency: number;
  postType: number;
  adult: boolean;
  teen: boolean;
  child: boolean;
  todlers: boolean;
  senior: boolean;
  isNightly: boolean;
  isHourly: boolean;
  youth: boolean;
  minimumOccupancy: number;
  maximumOccupancy: number;
  gapTime: number;
  isROH: boolean;
  type: number;
  villaHomeTemplateId: number;
  linenCount: number;
  houseKeepingPeriod: number;
  linenChange: number;
  noOfBeds: number;
}
export interface RoomTypeAssignmentRetailItem{
   id: number;
   retailItemId : number;
   roomTypeId: number;
   code: string | number;
   description: string;
   selected?: boolean;
  }

  export interface PostError {
    id: number;
    logDate: string;
    roomNumber: string;
    postTypeId: number;
    subType: string;
    resolved: boolean;
    amount: number;
    stayId: string;
    guestId: string;
    errorMessage: string;
    reference: string;
    comment: string;
    guest: string;
}

export interface ClientMultipack{
  id:number;
  retailItemId:number;
  transactionId:number;
  transactionDetailId:number;
  clientId:number;
  quanitity:number;
  remaining:number;
  linkedRetailItemId:number;
  soldOn:Date;
  startDate:Date;
  expirationDate:Date;
  salePrice:number;
  gratuity:number;
  serviceCharge:number;
  tax:number;
  isUnlimited:boolean;
  isReturned:boolean;
  isExpired:boolean;
  multiPackLinkId : number;

}
export interface MultiPackDetails {
  multiPackTransactionDetailId: number;
  multiPackRetailItemId: number;
  clientMultiPacks: ClientMultipack[];
  multiPackPrice: number;
  quantityPerSale: number;
  multiPackPerSalePrice: number;
}
export interface MultiPackReturn
{
  updateMultiPacks: ClientMultipack[];
  addMultiPacks: ClientMultipack[];
}

export interface SplitValue {
  equalSplit: number;
  upScaleValue: number;
}

export enum PrintType {
  Html,
  Pdf,
  Word
}
export enum LetterTemplateType {
  CartAgreement=1,
  ClubAgreement,
  ShoeAgreement ,
  RetailSaleChit
}
export enum PrintInformationType {
  CartAgreement = 1,
  ClubAgreement,
  ShoeAgreement,
  RetailSaleChit,
  CaddyShack,
  HangingTicket,
  SmallSticker
}
export interface RetailItemTemplateConfig
{
 id:number,
 retailItemId:number,
 templateId:number,
 templateType : LetterTemplateType
}
export interface RetailItemTemplateUI
{
 id:number,
 code: string;
 name: string;
 type : LetterTemplateType,
 isDefault:boolean
}

export interface PropertyConfig<T> {
  id: number;
  productId: number;
  configurationName: string;
  configValue: T;
  defaultValue: string;
  propertyId: number;
}

export interface TenantConfig<T> {
  id: number;
  tenantId: number;
  configurationName: string;
  configValue: T;
  defaultValue: string;
}
export interface DefaultUserConfigurationTenant<T> {
  id: number;
  propertyId: number;
  productId: number;
  configurationName: string;
  configValue: DefaultUserConfigurationTenantModel
  defaultValue: T;
  lastModifiedBy: T;
  lastModifiedDate: Date;
  userId?: number;
}

export interface DefaultUserConfigurationTenantModel
{
  defaultMachineId : number
}

export enum FinancialBinLevel {
  Category = 1,
  Class,
  Transaction
}
export interface enhancedInventoryMaster {
  TenantId: number;
  TenantName: String;
  Properties:Properties;
}
export interface Properties {
  PropertyId: number;
  PropertyName: string;
  Outlets: Outlet[];
}
export interface Outlets {
  OutletId: number;
  OutletName: string;
}

export interface InventorySyncParams {
  parameter: InventorySyncIdType[];
}

export interface OrphanPayments {
  typeId?: number;
  tenderId?: number;
  type?: IntegrationOperationsTransactionType;
  status?: string;
  hostName?: string;
  agentVersion?: string;
  iPAddress?: string;
  ticketNumber?: string;
  amount?: string;
  amountFormatted?: string;
  reversedDTM?: string;
  createdBy?: string;
  createdTime?: string;
  userName: string;
  transactionType: string;
  origin: string;
  paymentMethod: string;
}

export enum IntegrationOperationsTransactionType
{
    //Retail Transactions
    CreditCardSalePaymentForRetailTransaction = 1,
    CreditCardCreditPaymentForRetailTransaction,
    CreditCardRefundPaymentForRetailTransaction,
    CreditCardVoidPaymentForRetailTransaction,
    GiftCardTopupForRetailTransaction,
    GiftCardRedemptionForRetailTransaction,
    GiftCardIssueForRetailTransaction,
    CreditCardGetHandlesForRetailTransaction,
    GiftcardCashBackForRetailTransaction,

    //Folio Postings
    CreditCardSalePaymentForFolioPosting = 101,
    CreditCardCreditPaymentForFolioPosting,
    CreditCardRefundPaymentForFolioPosting,
    CreditCardVoidPaymentForFolioPosting,
    GiftCardTopupForFolioPosting,
    GiftCardRedemptionForFolioPosting,
    GiftCardIssueForFolioPosting,
    CreditCardAuthForFolioPosting,
    CreditCardVoidAuthForFolioPosting,
    GiftcardCashBackForFolioPosting,

    //Token Transactions
    CreditCardSaleByToken = 1001,
    CreditCardAuthByToken,
    CreditCardIncrementalAuth,
    CreditCardDecreaseAuth,
    CreditCardSettle,
    CreditCardCreditByToken,
    CreditCardVoidPayment,
    CreditCardRefundPayment,
    DefaultIntegrationLog,

    //TerminalInfo
    TerminalInfo = 10001
}

export enum IntegrationOperationsStatus {
  Initiated = 1,
  Completed,
  Realized,
  Rejected
}

export interface ExternalPostTypeMapping{
  OutletId?:Number,
  OutletName?:string,
  ProfitCenter?:string,
  Mappings?:any[],
  PaymentMappings?:any[]
  HostId?:Number
}

export interface OperaMapping{
  POS_FinancialBinType?:Number,
  POS_FinancialBinId?:Number,
  OPERA_TransactionCode?:Number
}

export interface PaymentMappings{
  POS_TenderId?:Number,
  OPERA_PaymentId?:Number,
  OPERA_PostingMaster?:string,
  POS_CC_IssuerType?:string,
  LinkingId?:Number
}
export class RentalItemInformation {
  public id: number;
  public retailItemId: number;
  public rateType: RentalItemRateType;
  public rate: number;
  public bufferTime: number;
  public isQuantityAtPropertyLevel: boolean;
  public availableOnWeb: boolean;
  public rentalItemQuantity: RentalItemQuantity[];
  public rentalItemSlabRates?: RentalItemSlabRates[];
}
export class RentalItemQuantity {
  public id: number;
  public rentalItemInformationId: number;
  public outletId: number;
  public quantity: number;
  public outofOrderQuantity: number;
}
export class RentalItemSlabRates {
  public id: number;
  public rentalItemInformationId: number;
  public slabStart: number;
  public slabEnd: number;
  public amount: number;
}
export enum RentalItemRateType {
  Hourly = 0,
  Fixed = 1
}

export class RetailItemExportInput {
  public selectedOutlet: OutletDetail;
}

export class OutletDetail {
  public id: number;
  public name: string;
}

export enum CashDrawerConnectionType {
  PrintKickOut = "1"
}
export enum CashDrawerConnectionTypeName{
  PrintKickOut = "Print Kick Out"
}


