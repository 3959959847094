import { Component, OnInit } from '@angular/core';
import { Localization } from 'src/app/common/localization/localization';
import * as GlobalConst from 'src/app/retail/shared/globalsContant';

@Component({
  selector: 'app-deposity-policy-mapping-wrapper',
  templateUrl: './deposity-policy-mapping-wrapper.component.html',
  styleUrls: ['./deposity-policy-mapping-wrapper.component.scss']
})
export class DeposityPolicyMappingWrapperComponent implements OnInit {

  policyOptions = [];
  cancellationPolicyOptions = [];
  captions: any;
  applyToOptions = [];
  playerTypeArray = [];
  productId  = GlobalConst.Product.SPA;
  searchPlaceholder: any;
  tableOptions;
  tableContent = [];

  constructor(private localization: Localization) {
    this.captions = this.localization.captions;
   }

  ngOnInit(): void {
    this.searchPlaceholder = this.captions.lbl_searchByPlayerType;
    this.policyOptions = this.policyOptionList();
    this.cancellationPolicyOptions = this.getcancellationPolicyOptions();
    this.applyToOptions = this.getapplyToTypes();
    this.playerTypeArray = this.getPlayerTypes();
    this.tableContent = this.getTableContent();
    this.getTableOptions();
  }
  getTableOptions() {
    let arrDataHeader = [
      { 'title': this.captions.tbl_hdr_policyCode, 'jsonkey': 'code', 'searchable': true, 'sortable': true },
      { 'title': this.captions.tbl_hdr_policyName, 'jsonkey': 'name', 'searchable': true, 'sortable': true },
      { 'title': this.captions.lbl_applyTo, 'jsonkey': 'applyTo', 'searchable': false, 'sortable': true },
      { 'title': this.captions.tbl_hdr_dateRange, 'jsonkey': 'dateRange', 'searchable': false, 'sortable': true},
    ]
    this.tableOptions = [
      {
        TableHdrData: arrDataHeader,
        TablebodyData: this.tableContent,
        pagination: false,
        sortable: true,
        editable: true,
        CustomColumn: true,
        PlaceHoldertext: this.captions.lbl_searchByPolicyCodeName,
        EnableActions: true,
        SelectRows: false,
        Searchable: true,
        EditMoreOption: false,
        Sortable: 'code',
        TableId: '',
        disableDelete: false,
        SelectRow: true,
        SelectOnlyRow: true,
        dropdownOptions: '',
        waitlistIcon: false,
        TableDraggable: false,
        sticky: false,
        DoneCancel: false,
        IsViewOnly: false,
        disableEditButton: false,
        isCopyEnabled : false,
        ServiceId : 'viptype'
      }
    ];
  }
  
  getTableContent(){
     return[
         {
           name: "Test",
           code: "1234",
           applyTo : "Test test",
           dateRange: "12 Jan 2024 - 15 Jan 2024",
         }
     ]
  }
  
    policyOptionList(){
      return[
          {
              id: 0,
              value: "cancellation",
              viewValue: this.captions.lbl_cancellation 
          },
          {
              id: 1,
              value: "noShow",
              viewValue: this.captions.lbl_noShow
          },
      ]
    }
  
    getcancellationPolicyOptions(){
      return [
        {
          id: 0,
          viewValue: 'test'
        },
        {
          id: 1,
          viewValue: 'test1'
        }
      ]
    }
  
    getapplyToTypes(){
      return[
          {
              id: 0,
              name: this.captions.lbl_existingPlayer,
              selected: false
          },
          {
              id: 1,
              name: this.captions.lbl_hotelReservation,
              selected: false
          },
          {
              id: 3,
              name: this.captions.lbl_member,
              selected: false
          },
          {
            id: 4,
            name: this.captions.lbl_player,
            selected: false
        }
      ]
    }
  
    getPlayerTypes(){
      return[
        {
            id: 0,
            name: "Vip player",
            selected: false,
            rateTypes: [
                {
                  playerId: 0,
                  rateTypeId: 0,
                  rateTypeName: "Vip Rate",
                  selected: false
                },
                {
                  playerId: 0,
                  rateTypeId: 1,
                  rateTypeName: "Resort Rate",
                  selected: false
                }
            ]
        },
        {
            id: 1,
            name: "Resort player",
            selected: false,
            rateTypes: [
              {
                playerId: 1,
                rateTypeId: 0,
                rateTypeName: "Resort Rate 1",
                selected: false
              },
              {
                playerId: 1,
                rateTypeId: 1,
                rateTypeName: "Resort Rate 2",
                selected: false
              }
            ]
        },
        {
            id: 2,
            name: "Guest player",
            selected: false,
            rateTypes: [
              {
                playerId: 2,
                rateTypeId: 0,
                rateTypeName: "Guest Rate 1",
                selected: false
              },
              {
                playerId: 2,
                rateTypeId: 1,
                rateTypeName: "Guest Rate 2",
                selected: false
              }
            ]
        },
        {
          id: 3,
          name: "Player Type 1",
          selected: false,
          rateTypes: [
              
          ]
      }
    ]
    }

}

