
export enum CodeSetups {
    CurrencyCode
  }

export enum AlertType {
    Success = 1,
    Warning = 2,
    Error = 3,
    WellDone = 4,
    Info = 5,
    AccessDenied = 6,
    Done = 7,
    Confirmation = 8,
    CustomDefault = 9,
    Attention = 10
}

export enum KeepReleaseSelection{
    KeepUntilCutoff = 0,
    ReleaseImmediately = 1,
    ShowKeepReleasePrompt = 2
}

export enum AlertResult{
    YES = "YES",
    NO = "NO",
    CANCEL = "CANCEL",
    CONTINUE = 'CONTINUE'
}

export enum ActionMode {
    create,
    update,
    cancel,
    delete,
    add,
    view,
    copy,
    back
  }

export enum ContactType {
    email = 'Email',
    phone = 'Phone'
}
			
export enum GuestProfileMailTypes {
    personal = 9,
    office = 10,
    home = 14,
    business = 15
}

export enum PhoneTypes {
    mobile = 1,
    home = 2,
    office = 3,
    fax = 4,
    others = 11,
    business = 12, 
    work = 13,
}

export enum MailTypes {
    personal = 9,
    office = 10,
    home = 14,
    business = 15
}

export enum ContactReferenceType {
    MANAGER = 1,
    CONTACT = 2,
    ACCOUNT = 3,
    INQUIRY = 4
}
export enum ContactInformationReferenceType {
    CONTACT = 1,
    ACCOUNT
}
export enum ContactAddressReferenceType {
    CONTACT = 1,
    ACCOUNT
}

export enum DisplayLine {
    all = 0,
    holiday = 1,
    hotDates = 2
}

export enum ManagerType {
    Sales = 1,
    ConferenceService,
    Catering
}
export enum InquiryDeniedReportType {
    Inquiry = 1,
    Denied = 2,
    Both = 3,
    BookingCreated = 4
}

export enum SalesCallFilterType {
    Account = 1,
    Manager = 2,
    CallType = 3,
    NextStep = 4,
    CallDate = 5
}

export enum AssociateType {
    Building = 1,
    SalesManager,
    ConferenceManager,
    CateringManager

}

export enum DialogViewOnly {
    print = 'print',
    cancel = 'cancel'
}

export enum SorTypeEnum {
    asc = 'asc',
    desc = 'desc'
}

export enum SnackBarType {
    Info = 'info',
    Error = 'error',
    Success = 'success',
    PartialSuccess = 'partialsuccess',
    Warning = 'warn'
}


export enum PaymentMethods {
    Cash = 1,
    CreditCard = 2,
    RoomCharge = 3,
    PendingSettlement = 4,
    IDTECH = 5,
    RainCheck = 6,
    CardOnFile = 22,
    ARPost = 7,
    RedeemPoint = 8,
    CreditBook = 9,
    CompRedemption = 10,
    OfferRedemption = 11,
    V1GiftCard = 12,
    ExternalGiftCard = 13,
    V1GiftCardIdTech = 14,
    ExternalGiftCardIdTech = 15,
    ARAcctPost = 16,
    AgilysysGiftCard = 17,
    AgilysysGiftCardIdTech = 18,
    CompSlipRedemption = 19,
    PostToFolio = 21,
    AuthorizePayment = 23,
    GuaranteeMethod = 99,
    Other = 44,
    NONINTEGRATEDCREDITCARD = 26
}

export enum DefaultOutletType {
    user = 1,
    course = 2
}

export enum SystemPasscodeTag {
    OverSell = 'OVERSELLRMTYPE',
    OverRide = 'OVERRIDEDONOTMOVE',
    FORCECLOSEBOOKING = 'FORCECLOSEBOOKING',
    HOTELOVERSELL ='OVERSELLHOTEL',
    OVERRIDEAUTHORIZEDEPOSIT='OVERRIDEDEPOSIT',
    ALLOWNEGATIVEFOLIOPOSTING = 'ALLOWNEGATIVEPOSTING'
}
