export namespace API {
    export interface StaffReaderBoard {
        id: number;
        notes: string;
        priority: number;
        isShowNoteAsPopup: boolean;
        validFromDate: Date | string;
        validToDate?: Date | string;
        isSentToAllProducts: boolean;
        staffReaderBoardDetails: StaffReaderBoardDetails[];
    }

    export interface StaffReaderBoardDetails {
        id: number;
        receivedByUserId: number;
        isViewed: boolean;
        viewedOn?: Date;
        isAcknowledged: boolean;
        acknowledgedOn?: Date;
        isSnoozed: boolean;
        intervalTime: number;
    }

    export interface StaffReaderBoardFilterRequest {
        userId: number;
        fromDate: Date | string;
        toDate: Date | string;
        priority: number;
        isNotAcknowledged: boolean;
    }
}

export namespace UI {
    export interface StaffReaderBoard {
        id: number;
        notedetails: string;
        priority: number;
        shownote: boolean;
        popupValidFromDate: Date | string;
        popupValidFromTime: Date | string;
        popupValidToDate: Date | string;
        popupValidToTime: Date | string;
        mode: number;
        selectedData: StaffReaderBoardDetails[];
    }

    export interface StaffReaderBoardDetails {
        id: number;
        receivedByUserId: number;
        receivedByUserName: string;
        isViewed: boolean;
        viewedOn?: Date;
        isAcknowledged: boolean;
        acknowledgedOn?: Date;
        isSnoozed: boolean;
        intervalTime: number;
    }

    export interface ViewAllStaffReaderBoard {
        id: number;
        notes: string;
        priority: number;
        priorityName: string;
        noOfSentUsers: number;
        noOfAcknowledgedUsers: number;
    }

    export interface ViewAllStaffReaderBoardDetails extends ViewAllStaffReaderBoard
    {
        validFromDate: Date | string;
        validToDate ?: Date | string;
        userNameAndRoles: UserNameAndRoles[];
    }

    export interface UserNameAndRoles {
        roleId: number;
        roleName: string;
        users: Users[];
    }

    export interface Users {
        userId: number;
        userName: string;
        isAcknowledged: boolean;
        acknowledgedOn?: Date | string;
    }

    export interface ViewAllNotes {
        id: number;
        notes: string;
        priority: number;
        priorityName: string;
        sentBy: string;
        sentOn: Date | string;
        isAcknowledged: boolean;
        acknowledgedOn?: Date | string;
        isSnoozed: boolean;
        intervalTime: number;
        isViewed: boolean;
        isShowNoteAsPopup: boolean;
        selected: boolean;
        isMore: boolean;
        isClicked: boolean;
        sNo: number;
        snoozeTime: string;

        isAccountingFiscal?: boolean;
    }
}

  export enum NoteSendToSelect {
    roles = 1,
    user
}