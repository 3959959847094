import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MaterialModule } from '../material-module';
import { ScrollbarModule } from 'ngx-scrollbar';
import { AsideFilterSectionComponent, checkIndexPipe } from '../shared/aside-filter/aside-filter.component';
import { FilterPipe } from '../shared/pipes/filter-pipe.pipe';
import { IsEllipsisDirective } from '../shared/utilities/isellipsis.directive';
import { RetailMoreSectionComponent } from '../shared/more-section/more-section.component';
import { SwiperModule, SwiperConfigInterface, SWIPER_CONFIG } from 'ngx-swiper-wrapper';
import { DialogOverviewExampleDialog } from '../shared/dialog-popup/dialogPopup-componenet';
import { IsExist, FilterisExist, IsbuttonValid } from './pipes/custom-filter-pipe.pipe';
import { RetailViewMoreComponent } from './view-more/view-more.component';
import { sortPipe } from './pipes/sort-pipe.pipe';
import { EnablerowPipe } from './pipes/enablerow.pipe';
import { SPAConfig } from '../common/config/SPA-config';
import { RetailLocalization } from '../common/localization/retail-localization';
import { HttpServiceCall } from './service/http-call.service';
import { HttpRequestService } from './service/http-request.service';
import { RetailPropertyInformation } from '../common/services/retail-property-information.service';
import { SessionManagerService } from '../common/services/session-manager.service';
import { MoreSectionServiceService } from './more-section/more-section-service.service';
import { FormatText } from './pipes/formatText-pipe.pipe';
import { BreakPointAccess } from './service/breakpoint.service';
import { RetailService } from '../retail.service';
import { MessageService } from './service/messageservice.service';
import { UserAlerts } from '../common/config/alerts-config';
import { AlertMessagePopupComponent } from './alert-message-popup/alert-message-popup.component';
import { SettingDialogPopupComponent } from '../shared/setting-dialog-popup/setting-dialog-popup.component';
import { LoadDecimalValuePipe } from './pipes/load-decimal-value.pipe';
import { CustomCurrencyPipe } from '../common/localization/currency.pipe';
import { LocalizeDatePipe } from '../common/localization/localize-date.pipe';
import { DateAdapter, MAT_DATE_FORMATS } from '@angular/material/core';
import { CustomDateAdapter, MY_DATE_FORMATS } from '../common/localization/custom.dateAdapter';
import { inputtypeDirective } from '../shared/utilities/inputtype.directive';
import { RetailCommissionValuesComponent } from './commission-values/commission-values.component';
import { ViewSettingClientBusiness } from './business/view-settings.business';
import { RetailCreateNewTaxComponent } from '../retail-code-setup/retail-create-new-tax/retail-create-new-tax.component';
import { RetailViewmoreDirective } from './directives/retailviewmore.directive';
import { RetailCurrencyFormatterDirective } from '../common/localization/currency.directive';
import { RetailToggleSwitchModule } from '../retail-toggle-switch/retail-toggle-switch.module';
import { RetailNoDataFoundComponent } from './no-data-found/no-data-found.component';
import { NummaxLength } from '../shared/utilities/num-maxlength.directive';
import { GiftCardsSearchComponent } from './gift-cards-search/gift-cards-search.component';
import { GiftCardBusiness } from '../sytem-config/gift-cards/gift-cards.business';
import { GiftCardsService } from '../sytem-config/gift-cards/gift-cards.data.service';
import { GiftcardSwipeComponent } from './giftcard-swipe/giftcard-swipe.component';
import { AlertPopupComponent } from './alert-popup/alert-popup.component';
import { CreateOutletTerminalComponent } from '../retail-code-setup/retail-outlet-terminal/create-outlet-terminal/create-outlet-terminal.component';
import { NotUploadedPopupComponent } from './not-uploaded-popup/not-uploaded-popup.component';
import { RetailInputTableComponent } from './not-uploaded-popup/retail-input-table/retail-input-table.component';
import { RetailTableInlineEditComponent } from '../retail-table-inline-edit/retail-table-inline-edit.component';
import { SetcolumnvaluePipe } from './pipes/setcolumnvalue.pipe';
import { CardSwipePopupComponent } from './card-swipe-popup/card-swipe-popup.component';
import { LimitExceedDirective } from './directives/limit-exceed.directive';
import { AccountLookupComponent } from './account-lookup/account-lookup.component';
import { ARPostingBusinessService } from './business/AR-Posting.business.service';
import { CommonVariablesService } from './service/common-variables.service';
import { CheckboxcheckedPipe } from './pipes/checkboxchecked.pipe';
import { TeetimePaymentBusinessService } from './business/Teetime-Payment.business.service';
import { CreateRetailVendorSetupComponent } from '../retail-vendor-setup/create-retail-vendor-setup/create-retail-vendor-setup.component';
import { AgysAddressComponent } from './agys-address/agys-address.component';
import { AgysMailIdComponent } from './agys-mail-id/agys-mail-id.component';
import { AgysPhoneNumberComponent, FlagSelectorRetailPipe } from './agys-phone-number/agys-phone-number.component';
import { DuplicateCheckDirective } from './agys-phone-number/agys-duplicate-contact-directive';
import { TextMaskDirective } from './utilities/mask.directive';
import { VirtualScrollerComponent } from './virtual-scroller/virtual-scroller.component';
import { VirtualScrollerModule } from 'ngx-virtual-scroller';
import { checkorderExistPipe } from './virtual-scroller/pipes/checkobjexist.pipe';
import { NumMinMaxLengthDirective } from './directives/num-minmaxlength.directive';
import { InternalGiftCardBusinessService } from './business/Giftcard/Internal-Giftcard.business.service';
import { ExternalGiftcardBusinessService } from './business/Giftcard/External-Giftcard.business.service';
import { rGuestPayBaseService } from './business/Giftcard/rGuestPay-base.service';
import { SearchLinkedRetailItemsComponent } from './search-linked-retail-items/search-linked-retail-items.component';
import { GiftCardPINPopupComponent } from './gift-card-pinpopup/gift-card-pinpopup.component';
import { GuestRoomLookupComponent } from './guest-room-lookup/guest-room-lookup.component';
import { CMSBusinessService } from './business/CMS-business.service';
import { SettleRefundTransactionBusiness } from './business/Settle-Refund-Transaction-business.service';
import { OfferRankDialogComponent } from './offer-rank-dialog/offer-rank-dialog.component';
import { CompSlipLookupComponent } from './comp-slip-lookup/comp-slip-lookup.component';
import { PaymentBusinessLogicHandler } from '../payment/PaymentBusinessLogicHandler';
import { CardEntryModeComponent } from './card-entry-mode/card-entry-mode.component';
import { RetailCaptureCardComponent } from './capture-card/capture-card.component';
import { CardTypeComponent } from './card-type/card-type.component';
import { MemberBusinessService } from './business/Member-business.service';
import { CKEditorModule } from '@ckeditor/ckeditor5-angular';
import { CommonSharedModule } from 'src/app/common/shared/shared/shared.module';
import { PercentInputtypeDirective } from './utilities/percentage.directive';
import { WalletBusinessService } from './business/Wallet-business.service';
import { AutoRetailTransactionBusiness } from './business/auto-retailtransaction.business';
import { CelopayPaymentDetailComponent } from './celopay-payment-detail/celopay-payment-detail.component';
import { CarddetailsPopupComponent } from './carddetails-popup/carddetails-popup.component';
import {MatGoogleMapsAutocompleteModule} from '@angular-material-extensions/google-maps-autocomplete';
import { GuestTypeBusiness } from '../common/services/guest-type.service';

const DEFAULT_SWIPER_CONFIG: SwiperConfigInterface = {
  direction: 'horizontal',
  slidesPerView: 'auto',
  keyboard: {
    enabled: true,
  },
  mousewheel: true,
  navigation: {
    nextEl: '.swiper-button-next',
    prevEl: '.swiper-button-prev',
  },
  slideToClickedSlide: true,
  loop: false,
  observer: true
};
@NgModule({
    declarations: [
        AsideFilterSectionComponent,
        checkIndexPipe,
        FilterPipe,
        IsEllipsisDirective,
        RetailMoreSectionComponent,
        DialogOverviewExampleDialog,
        IsExist,
        RetailViewMoreComponent,
        sortPipe,
        EnablerowPipe,
        AlertMessagePopupComponent,
        LoadDecimalValuePipe,
        CustomCurrencyPipe,
        LocalizeDatePipe,
        FilterisExist,
        IsbuttonValid,
        inputtypeDirective,
        PercentInputtypeDirective,
        SettingDialogPopupComponent,
        RetailCommissionValuesComponent,
        RetailCreateNewTaxComponent,
        RetailViewmoreDirective,
        RetailCurrencyFormatterDirective,
        RetailNoDataFoundComponent,
        NummaxLength,
        GiftCardsSearchComponent,
        GiftcardSwipeComponent,
        CardSwipePopupComponent,
        AlertPopupComponent,
        CreateOutletTerminalComponent,
        NotUploadedPopupComponent,
        RetailInputTableComponent,
        RetailTableInlineEditComponent,
        SetcolumnvaluePipe,
        LimitExceedDirective,
        AccountLookupComponent,
        CreateRetailVendorSetupComponent,
        AgysAddressComponent,
        AgysMailIdComponent,
        AgysPhoneNumberComponent,
        DuplicateCheckDirective,
        TextMaskDirective,
        VirtualScrollerComponent,
        checkorderExistPipe,
        NumMinMaxLengthDirective,
        CheckboxcheckedPipe,
        SearchLinkedRetailItemsComponent,
        GiftCardPINPopupComponent,
        GuestRoomLookupComponent,
        CompSlipLookupComponent,
        OfferRankDialogComponent,
        RetailCaptureCardComponent,
        CardTypeComponent,
        CardEntryModeComponent,
        FlagSelectorRetailPipe,
        CelopayPaymentDetailComponent,
        CarddetailsPopupComponent
    ],
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        MaterialModule,
        ScrollbarModule,
        SwiperModule,
        RetailToggleSwitchModule,
        MatGoogleMapsAutocompleteModule,
        VirtualScrollerModule,
        CKEditorModule,
        CommonSharedModule
    ],
    exports: [
        ScrollbarModule,
        SwiperModule,
        RetailMoreSectionComponent,
        AsideFilterSectionComponent,
        checkIndexPipe,
        FilterPipe,
        IsEllipsisDirective,
        DialogOverviewExampleDialog,
        IsExist,
        RetailViewMoreComponent,
        sortPipe,
        EnablerowPipe,
        AlertMessagePopupComponent,
        LoadDecimalValuePipe,
        CustomCurrencyPipe,
        LocalizeDatePipe,
        FilterisExist,
        IsbuttonValid,
        CheckboxcheckedPipe,
        SettingDialogPopupComponent,
        RetailCommissionValuesComponent,
        RetailCreateNewTaxComponent,
        RetailViewmoreDirective,
        RetailCurrencyFormatterDirective,
        RetailNoDataFoundComponent,
        NummaxLength,
        CreateOutletTerminalComponent,
        RetailTableInlineEditComponent,
        SetcolumnvaluePipe,
        GiftCardsSearchComponent,
        GiftcardSwipeComponent,
        CardSwipePopupComponent,
        LimitExceedDirective,
        AccountLookupComponent,
        CreateRetailVendorSetupComponent,
        AgysAddressComponent,
        AgysMailIdComponent,
        AgysPhoneNumberComponent,
        DuplicateCheckDirective,
        TextMaskDirective,
        VirtualScrollerComponent,
        VirtualScrollerModule,
        NumMinMaxLengthDirective,
        SearchLinkedRetailItemsComponent,
        GiftCardPINPopupComponent,
        GuestRoomLookupComponent,
        CompSlipLookupComponent,
        OfferRankDialogComponent,
        RetailCaptureCardComponent,
        CardTypeComponent,
        CardEntryModeComponent,
        PercentInputtypeDirective
    ],
    providers: [
        { provide: SWIPER_CONFIG, useValue: DEFAULT_SWIPER_CONFIG },
        MoreSectionServiceService,
        CommonVariablesService,
        MemberBusinessService,
        SPAConfig,
        HttpServiceCall,
        HttpRequestService,
        RetailPropertyInformation,
        SessionManagerService,
        FormatText,
        BreakPointAccess,
        RetailService,
        MessageService,
        UserAlerts,
        RetailLocalization,
        LoadDecimalValuePipe,
        CustomCurrencyPipe,
        LocalizeDatePipe,
        FilterisExist,
        IsbuttonValid,
        {
            provide: DateAdapter, useClass: CustomDateAdapter
        },
        {
            provide: MAT_DATE_FORMATS, useValue: MY_DATE_FORMATS
        },
        ViewSettingClientBusiness,
        FilterPipe,
        GiftCardBusiness,
        GiftCardsService,
        ARPostingBusinessService,
        TeetimePaymentBusinessService,
        InternalGiftCardBusinessService,
        ExternalGiftcardBusinessService,
        rGuestPayBaseService,
        CMSBusinessService,
        SettleRefundTransactionBusiness,
        PaymentBusinessLogicHandler,
        WalletBusinessService,
        AutoRetailTransactionBusiness,
        GuestTypeBusiness
    ]
})
export class RetailSharedModule {
  constructor(private adapter: DateAdapter<any>, public localization: RetailLocalization) {
    this.adapter.setLocale(localization.localeCode);
  }
}
