import { Component, OnInit } from '@angular/core';
import { menuTypes } from 'src/app/common/components/menu/menu.constant';
import { RouteLoaderService } from 'src/app/core/services/route-loader.service';


@Component({
  selector: 'app-inventory-landing',
  templateUrl: './inventory-landing.component.html',
  styleUrls: ['./inventory-landing.component.scss']
})
export class InventoryLandingComponent{

  menuList: any;
  menuType = menuTypes;
   constructor(private routeDataService: RouteLoaderService) {
    const value = this.routeDataService.GetChildMenu('/settings/utilities/inventory', menuTypes.vertical);
    this.menuList = {
      menu: value.linkedElement,
      menuType: value.linkedElement[0].menuAlignment
    };
  }

}
