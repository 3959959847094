import { Injectable } from "@angular/core";
import { HttpServiceCall, HttpMethod } from "../../shared/service/http-call.service";
import { RetailUtilities } from "../../shared/utilities/retail-utilities";
import { RetailLocalization } from "../../common/localization/retail-localization";
import { OutletTerminal, Outlet, SubPropertyModel, OutletsCRUD } from "../../retail.modals";
import { Host } from "../../shared/globalsContant";
import { BaseResponse } from "../../shared/business/shared.modals";


@Injectable()

export class RetailOutletsDataService implements OutletsCRUD {

    constructor(private http: HttpServiceCall, private utils: RetailUtilities, private localization: RetailLocalization) { }

    public async getOutlets(): Promise<Outlet[]> {
        let results: [SubPropertyModel[], OutletTerminal[]] = await this.invokeServiceCallsParallel(HttpMethod.Get);
        let subProp: SubPropertyModel[] = results[0];  //from auth
        let outletTerminals: OutletTerminal[] = results[1];   //from RetMgmt
        return this.linkSubPropertyOutletTerminal(subProp, outletTerminals);
    }

    public async updateOutlet(outlet: SubPropertyModel): Promise<Outlet[]> {
        let results: [SubPropertyModel[], OutletTerminal[]] = await this.invokeServiceCallsParallel(HttpMethod.Put, outlet);
        let subProp: SubPropertyModel[] = results[0];  //from auth
        let outletTerminals: OutletTerminal[] = results[1];   //from RetMgmt
        return this.linkSubPropertyOutletTerminal(subProp, outletTerminals);

    }

    public async deleteOutlet(outlet: SubPropertyModel) {
        let results: [SubPropertyModel[], OutletTerminal[]] = await this.invokeServiceCallsParallel(HttpMethod.Delete, outlet);
        let subProp: SubPropertyModel[] = results[0];  //from auth
        let outletTerminals: OutletTerminal[] = results[1];   //from RetMgmt
        return this.linkSubPropertyOutletTerminal(subProp, outletTerminals);
    }

    public async createOutlet(body: SubPropertyModel): Promise<Outlet[]> {

        const outletRoute_Rtmgmt = "AddOutletsToProperty";
        const outletTerminalRoute_Rtmgmt = "createOutletTerminal";
        const subPropBody_Auth: SubPropertyModel = { ...body };
        subPropBody_Auth.subPropertyName = subPropBody_Auth.subPropertyName.trim();
        delete subPropBody_Auth.CCTerminalId;

        let subPropResp: SubPropertyModel[] = await this.invokeOutletTerminalCalls<SubPropertyModel[]>(outletRoute_Rtmgmt, Host.retailManagement, HttpMethod.Post, subPropBody_Auth);

        const outletTerminalBody_Rtmgmt: OutletTerminal = {
            subPropertyId: subPropResp.find(subP => (subP.subPropertyName).trim().toLowerCase() == (body.subPropertyName).trim().toLowerCase()).subPropertyID,
            ccTerminalId: body.CCTerminalId
        };

        let outletTerminalResp: OutletTerminal[] = await this.invokeOutletTerminalCalls<OutletTerminal[]>(outletTerminalRoute_Rtmgmt, Host.payment, HttpMethod.Post, outletTerminalBody_Rtmgmt);
        return this.linkSubPropertyOutletTerminal(subPropResp, outletTerminalResp);

    }


    private linkSubPropertyOutletTerminal(subP: SubPropertyModel[], outletT: OutletTerminal[]): Outlet[] {
        return subP.map(s => {
            let terId = outletT.find(t => t.subPropertyId == s.subPropertyID);
            return <Outlet>{
                id: s.subPropertyID,
                isActive: s.isActive,
                outletNumber: s.subPropertyID,
                outletName: s.subPropertyName,
                CCTerminalId: terId ? terId.ccTerminalId : "",
                outletTerminalId: terId ? terId.id : 0,
                productId: s.productId ? s.productId : 0,
                autoReceipt: s.autoReceipt || false,
                profitCenter : s.profitCenter ? s.profitCenter : "",
                isDefault: s.isDefault,
                outletAddresses: s.outletAddresses,
                includeOutletAddress: s.includeOutletAddress,
                outletContacts: s.outletContacts,
                cashDrawerPort:s.cashDrawerPort,
                cashDrawerKickOutCommand:s.cashDrawerKickOutCommand
            }
        });
    }

    private async invokeServiceCallsParallel(operation: HttpMethod, body?: SubPropertyModel): Promise<[SubPropertyModel[], OutletTerminal[]]> {
        let outletRoute_Rtmgmt:string;
        let outletTerminalRoute_Rtmgmt: string;
        let uriParams_Auth: any;
        let uriParams_Rtmgmt: any;
        let outletTerminalBody_Rtmgmt: OutletTerminal;
        let subPropBody_Auth: SubPropertyModel;
        let operation_Rtmgmt: HttpMethod = operation;
        switch (operation) {
            case HttpMethod.Get:
                outletRoute_Rtmgmt = "GetOutletsByProperty";
                outletTerminalRoute_Rtmgmt = "getAllOutletTerminals";
                uriParams_Auth = { propertyId: Number(this.utils.GetPropertyInfo('PropertyId')) }
                break;

            case HttpMethod.Put:
                outletRoute_Rtmgmt = "UpdateOutletsOfProperty";
                outletTerminalRoute_Rtmgmt = "updateOutletTerminal";
                uriParams_Auth = { id: Number(body.subPropertyID)};
                subPropBody_Auth = body;
                outletTerminalBody_Rtmgmt = {
                    id: body.terminalLinkId,
                    ccTerminalId: body.CCTerminalId,
                    subPropertyId: body.subPropertyID,
                    isActive: body.isActive
                }
                uriParams_Rtmgmt = { id: body.terminalLinkId }
                break;

            case HttpMethod.Delete:
                outletRoute_Rtmgmt = "RemoveOutletsOfProperty";
                outletTerminalRoute_Rtmgmt = "deleteOutletTerminal";
                uriParams_Auth = { id: Number(body.subPropertyID)};
                uriParams_Rtmgmt = { id: body.terminalLinkId };
                break;
            default:
                break;
        }

        //from Authentication Micro service
        let outlet: Promise<SubPropertyModel[]> = this.invokeOutletTerminalCalls(outletRoute_Rtmgmt, Host.retailManagement, operation_Rtmgmt, subPropBody_Auth, uriParams_Auth);
        //from Retail Mgmt Micro service
        let outletTerminals: Promise<OutletTerminal[]> = this.invokeOutletTerminalCalls(outletTerminalRoute_Rtmgmt, Host.payment, operation_Rtmgmt, outletTerminalBody_Rtmgmt, uriParams_Rtmgmt);
        //wait for both and Joins the result
        return Promise.all([outlet, outletTerminals]);
    }

    private async invokeOutletTerminalCalls<T>(callDesc: string, host: Host, callType: HttpMethod, body?: OutletTerminal | SubPropertyModel, uRIParams?: any): Promise<T> {
        if ((callDesc == "updateOutletTerminal" || callDesc == "deleteOutletTerminal") && uRIParams.id == 0) {
            // Prevents Update or Delete when Id is '0'( exceptional Case )
            callDesc = "getAllOutletTerminals";
            callType = HttpMethod.Get;
        }

        let response: BaseResponse<T> = await this.http.CallApiAsync<T>({
            callDesc: callDesc,
            host: host,
            method: callType,
            body: body,
            uriParams: uRIParams
        });

        if (!response.successStatus) {
            this.showError(response.errorCode);
        }
        return response.result;
    }

    private showError(errorCode: number) {
        let errMsg = this.localization.getError(errorCode);
        this.utils.ShowErrorMessage(this.localization.captions.common.Error, errMsg);
    }

    public async validateOutletDelete(outletId: number): Promise<boolean> {
        // Check if any transactions are associated with the outlet
        const transactionCount = await this.invokeOutletTerminalCalls('GetTransactionCount', Host.retailPOS, HttpMethod.Get, null, { outletId: outletId });
        return transactionCount === 0;
    }
}
