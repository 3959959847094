import { AbstractControl,UntypedFormArray, UntypedFormGroup, ValidationErrors, ValidatorFn } from '@angular/forms';

export function guidValidator(): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const value = control.value?.trim();

    if (!value) {
      return null; 
    }

    const guidRegex = /^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}$/;

    if (!guidRegex.test(value)) {
      return { invalidGuid: true }; 
    }

    return null; 
  };
}

export function drawerCommandValidator(): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    if (!control.value) return null;

    const pattern = /^0x[0-9A-Fa-f]{2}(, 0x[0-9A-Fa-f]{2})*$/;
    const isValid = pattern.test(control.value);
    return isValid ? null : { invalidDrawerCommand: true };
  };
}

export function uniqueFormGroupValidator(): (formArray: AbstractControl) => ValidationErrors | null {
  return (formArray: AbstractControl): ValidationErrors | null => {
    if (!formArray || !(formArray instanceof UntypedFormArray)) return null;

    const groups = formArray.controls; // All form groups in the array

    // Extract group values without 'id'
    const groupValues = groups.map((group) => {
      const { id, ...rest } = group.getRawValue(); // Exclude 'id'
      return rest;
    });

    const duplicateIndexes = new Set<number>();

    // Compare each group with others (excluding 'id')
    groupValues.forEach((value, index) => {
      const isDuplicate = groupValues.some(
        (otherValue, otherIndex) =>
          otherIndex !== index && JSON.stringify(value) === JSON.stringify(otherValue)
      );
      if (isDuplicate) duplicateIndexes.add(index);
    });

    // Reset errors on all groups
    groups.forEach((group) => group.setErrors(null));

    // Mark errors on the specific groups
    duplicateIndexes.forEach((index) => {
      groups[index].setErrors({ duplicateGroup: true });
    });

    return duplicateIndexes.size > 0 ? { duplicateGroup: true } : null;
  };
}



