import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { Localization } from 'src/app/common/localization/localization';
// import { CancellationNoShowPolicyBusiness } from './cancellation-no-show-policy.business';
import { MatDialog } from '@angular/material/dialog';
import { ActionMode, AlertType, ButtonType } from 'src/app/common/enums/shared-enums';
import { createModal } from 'src/app/common/components/create-modal/create-model';
import { CommonUtilities } from '../utilities/common-utilities';
import { DepositPolicyBusiness } from './deposit-policy.business';


@Component({
  selector: 'app-deposit-policy',
  templateUrl: './deposit-policy.component.html',
  styleUrls: ['./deposit-policy.component.scss'],
  encapsulation: ViewEncapsulation.None

})
export class DepositPolicyComponent implements OnInit {

  captions : any;
  @Input() tableContent;
  originalcontent = [];
  @Input() tableOptions;
  searchText = '';
  IsEditModeEnabledFlag: boolean = false;
  showInactivetog:boolean=false;
  createToggler = false;
  createModalData: createModal;
  crudActionInput;
  isViewOnly = false;
  onEditDisableFunction = true;

  constructor(private localization: Localization,private business: DepositPolicyBusiness,public dialog: MatDialog,public utils: CommonUtilities) {
    this.captions = this.localization.captions;
   }

  ngOnInit(): void {
    this.loadTableData();
  }

  loadTableData() {
    this.onEditDisableFunction = true;
    this.originalcontent = this.tableContent;
    console.log(this.tableOptions);
  }

  createCancellation() {
    this.crudActionInput = {
      mode: ActionMode.create,
      actionButton: this.captions.btn_save,
      form: undefined
    };
    this.createToggler = true;
    this.createModalData = {
      title: this.captions.hdr_createDepositPolicy
    };
  }

  showInactive(eve) {
    this.showInactivetog = !this.showInactivetog;
    this.showInactivetoggle();
  }

  showInactivetoggle(){
    if(this.showInactivetog==true)
      this.tableContent = this.originalcontent;
    else
     this.tableContent = this.originalcontent.filter(x=>x.isActive.value == true);
    this.tableContent = [...this.tableContent];
  }

  inactiveSliderChange(e){

  }

  CancelEvent(event) {
    this.IsEditModeEnabledFlag = false;
  }


  async DoneEvent(event) {
    this.IsEditModeEnabledFlag = false;
  }

  searchTextChange(eve) {
    this.searchText = eve;
  }

  handleClick(event){
    switch (event.from) {
      case ActionMode.create:
      case ActionMode.copy:
          this.utils.showAlert(this.captions.alertPopup.successfullysaved, AlertType.WellDone, ButtonType.Ok);
          this.backAction();
        break;
      case ActionMode.cancel:
        this.back(event);
        break;
      case ActionMode.update:
          this.utils.showAlert(this.captions.alertPopup.successfullysaved, AlertType.WellDone, ButtonType.Ok);
          this.backAction();
        break;

    }
  }

  back(e) {
    this.backAction();
  }

  backAction(form?) {
    this.createToggler = false;
    this.IsEditModeEnabledFlag = false;
  }

  edit(rowData){
    this.createToggler = true;
    this.createModalData = {
      title: this.captions.hdr_editDepositPolicy
    };
    this.crudActionInput = {
      mode: ActionMode.update,
      actionButton: this.captions.btn_update,
      form: rowData
    };
  }
  copyRow(rowData){
    this.createToggler = true;
    this.createModalData = {
      title: this.captions.hdr_createDepositPolicy
    };
    this.crudActionInput = {
      mode: ActionMode.create,
      actionButton: this.captions.btn_update,
      form: rowData
    };

  }
  deleteRow(rowItem){

  }
  formReady(eve) {

  }

}
