import { Injectable } from "@angular/core";
import { Localization } from "src/app/common/localization/localization";
import { DiscountMappingService } from "./discount-mapping.service";

@Injectable()
export class DiscountMappingBusiness {
    captions: any;

    constructor(private localization: Localization, private discountMappingService : DiscountMappingService) {
        this.captions = this.localization.captions;
    }

    getTierOptions(){
        return[
            {
                id: 1,
                value: "Gold",
                viewValue: "Gold" ,
                checked: false
            },
            {
                id: 2,
                value: "Silver",
                viewValue: "Silver",
                checked: false
            },
            {
                id: 3,
                value: "Diamond",
                viewValue: "Diamond",  
                checked: false              
            
            }
        ]
    }

    async getDiscountTypes() {
        const allDiscountTypes = await this.discountMappingService.GetDiscountTypes();
        let discountTypes = [];
        allDiscountTypes.filter(x => x.isActive).forEach(discount => {
            let discountType: any = {
                id: discount.id,
                name: discount.type,
                checked: false
            }
            discountTypes.push(discountType);
        });
        return discountTypes;
    }
    
    async getMembershipTypes() {
        const allMembershipTypes = await this.discountMappingService.GetMembershipTypes();
        let membershipTypes = [];
        allMembershipTypes.forEach(type => {
            let membershipType: any = {
                id: type.id,
                value: type.type,
                viewValue: type.type,
                checked: false
            }
            membershipTypes.push(membershipType);
        });
        return membershipTypes;
    }

    async getDiscountTypeLinking() {
        return await this.discountMappingService.GetDiscountTypeLinking();
    }

    async CreateDiscountTypeLinking(linkData: any) {
        return await this.discountMappingService.CreateDiscountTypeLinking(linkData);
    }

    async CreateBulklDiscountTypeLinking(linkData: any) {
        return await this.discountMappingService.CreateBulkDiscountTypeLinking(linkData);
    }

}