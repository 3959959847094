import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { CommonUtilities } from '../../shared/shared/utilities/common-utilities';
import { Localization } from '../../localization/localization';
import { CommonPropertyInformation } from '../../shared/services/common-property-information.service';
import { ServiceParams, BaseResponse } from '../../Models/http.model';
import { HttpCallService } from './http-call.service';
import { AppService } from '../../app-service';
import { Observable, Subject } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { throwError } from 'rxjs';
import { EasyQueryResponse } from './easy-query-helper/easy-query-response.model';

export class RguestCommunication extends HttpCallService {
    constructor(route: string, httpclient: HttpClient, localization: Localization, utilities: CommonUtilities, PropertyInfo: CommonPropertyInformation, appService: AppService) {
        super(route, httpclient, localization, utilities, PropertyInfo, appService);
    }

    public getObservable<T>(params: ServiceParams, handleErr: boolean = true): Observable<T> {
        return super.get<BaseResponse<T>>(params).pipe(
            map(response => response.result),
            catchError(err => {
                console.log(err);
                return throwError(err);
            })
        );
    }

    public async getPromise<T>(params: ServiceParams, handleErr: boolean = true, isLoaderRequired:boolean = false, isSessionCheckNotRequired:boolean = false, delayLoader: number = 0): Promise<T> {
        const response$: Promise<BaseResponse<T>> = super.getPromise<BaseResponse<T>>(params, isLoaderRequired, isSessionCheckNotRequired, handleErr, delayLoader);

        // on error =>
        response$.catch(err => this.error(err, handleErr));

        // on success =>
        const response: BaseResponse<T> = await response$;
        return response?.result;
    }

    public async getPromiseWithoutBaseResponse<T>(params: ServiceParams, handleErr: boolean = true, isLoaderRequired:boolean = false, isSessionCheckNotRequired:boolean = false): Promise<T> {
        const response$: Promise<T> = super.getPromise<T>(params, isLoaderRequired, isSessionCheckNotRequired);

        // on error =>
        response$.catch(err => this.error(err, handleErr));
        // on success =>
        return await response$;
    }

    // public async getPromiseWithCustomHeaders<T>(params: ServiceParams, handleErr: boolean = true): Promise<T> {
    //     const response$: Promise<T> = super.getPromiseWithCustomHeaders<T>(params);

    //     // on error =>
    //     response$.catch(err => this.error(err, handleErr));

    //     // on success =>
    //     const response: T = await response$;
    //     return response;
    // }
    

    public async getPromiseWithPropertyId<T>(params: ServiceParams, handleErr: boolean = true,propertyId:number = 0): Promise<T> {
        const response$: Promise<BaseResponse<T>> = super.getPromiseWithProperty<BaseResponse<T>>(params,propertyId);

        // on error =>
        response$.catch(err => this.error(err, handleErr));

        // on success =>
        const response: BaseResponse<T> = await response$;
        return response?.result;
    }

    public async putPromise<T>(params: ServiceParams, handleErr: boolean = true, isLoaderRequired:boolean = false, delayLoader?: number, isSessionCheckNotRequired?: boolean) {
        const response$: Promise<BaseResponse<T>> = super.putPromise<BaseResponse<T>>(params, isLoaderRequired, isSessionCheckNotRequired, delayLoader);

        // on error =>
        response$.catch(err => this.error(err, handleErr));

        // on success =>
        const response: BaseResponse<T> = await response$;
        return response?.result;
    }

    public async putPromiseWithoutBaseResponse<T>(params: ServiceParams, handleErr: boolean = true, notifier: Subject<void>): Promise<T> {
        const response$: Promise<T> = super.putCancellablePromise<T>(params, notifier);

        // on error =>
        response$.catch(err => this.error(err, handleErr));

        // on success =>
        return await response$;
    }
    public async putPromiseWithPropertyId<T>(params: ServiceParams, handleErr: boolean = true,propertyId:number) {
        const response$: Promise<BaseResponse<T>> = super.putPromiseWithProperty<BaseResponse<T>>(params,propertyId);

        // on error =>
        response$.catch(err => this.error(err, handleErr));

        // on success =>
        const response: BaseResponse<T> = await response$;
        return response?.result;
    }

    public async putPromiseWithCustomHeaders<T>(params: ServiceParams, handleErr: boolean = true): Promise<T> {
        const response$: Promise<T> = super.putPromiseWithCustomHeaders<T>(params);

        // on error =>
        response$.catch(err => this.error(err, handleErr));

        // on success =>
        const response: T = await response$;
        return response;
    }

    public async postPromise<T>(params: ServiceParams, handleErr: boolean = true, isLoaderRequired:boolean = false, isSessionCheckNotRequired:boolean = false, delayLoader?: number): Promise<T> {
        const response$: Promise<BaseResponse<T>> = super.postPromise<BaseResponse<T>>(params,isLoaderRequired, isSessionCheckNotRequired, handleErr, delayLoader);

        // on error =>
        response$.catch(err => this.error(err, handleErr));

        // on success =>
        const response: BaseResponse<T> = await response$;
        return response.result;
    }
    public async postPromiseWithPropertyId<T>(params: ServiceParams, handleErr: boolean = true, isLoaderRequired:boolean = false,propertyId:number): Promise<T> {
        const response$: Promise<BaseResponse<T>> = super.postPromiseWithProperty<BaseResponse<T>>(params,propertyId,isLoaderRequired);

        // on error =>
        response$.catch(err => this.error(err, handleErr));

        // on success =>
        const response: BaseResponse<T> = await response$;
        return response.result;
    }

    public async postPromiseWithResultSet<T>(params: ServiceParams, handleErr: boolean = true): Promise<T> {
        const response$: Promise<BaseResponse<T>> = super.postPromise<BaseResponse<T>>(params);

        // on error =>
        response$.catch(err => this.error(err, handleErr));

        // on success =>
        const response: BaseResponse<T> = await response$;
        return response["resultSet"];
    }

    public async postPromiseWithCustomHeaders<T>(params: ServiceParams, handleErr: boolean = true): Promise<T> {
        const response$: Promise<T> = super.postPromiseWithCustomHeaders<T>(params);

        // on error =>
        response$.catch(err => this.error(err, handleErr));

        // on success =>
        const response: T = await response$;
        return response;
    }

    public async deletePromise<T>(params: ServiceParams, handleErr: boolean = true, isLoaderRequired:boolean = false, delayLoader?: number): Promise<T> {
        const response$: Promise<BaseResponse<T>> = super.deletePromise<BaseResponse<T>>(params, isLoaderRequired, handleErr, delayLoader);

        // on error =>
        response$.catch(err => this.error(err, handleErr));

        // on success =>
        const response: BaseResponse<T> = await response$;
        return response ? response.result : undefined;
    }

    public async patchPromise<T>(params: ServiceParams, handleErr: boolean = true, isLoaderRequired:boolean = false, delayLoader?: number): Promise<T> {
        const response$: Promise<BaseResponse<T>> = super.patchPromise<BaseResponse<T>>(params,isLoaderRequired, handleErr, delayLoader);

        //on error =>
        response$.catch(err => this.error(err, handleErr));

        //on success =>
        const response: BaseResponse<T> = await response$;
        return response.result;
    }

    public async putDownloadPromise(params: ServiceParams) {
        const response$ = super.excelPut(params).toPromise();
        return await response$;
    }

    public error(err: HttpErrorResponse, handleErr: boolean) {
        if (handleErr) {
            super.errorHandler(err);
        } else {
            throw err;
        }
    }

    public async getLetterPromise<T>(params: ServiceParams, handleErr: boolean = true): Promise<T> {
        const response$: Promise<BaseResponse<T>> = super.getLetterPromise<BaseResponse<T>>(params);

        // on error =>
        response$.catch(err => this.error(err, handleErr));

        // on success =>
        const response: BaseResponse<T> = await response$;
        return response.result;
    }

    public async postEasyQueryPromise<T>(params: ServiceParams, handleErr: boolean = true, isLoaderRequired:boolean = true) {
        const response$: Promise<EasyQueryResponse<T>> = super.postPromise<EasyQueryResponse<T>>(params, isLoaderRequired);

        // on error =>
        response$.catch(err => this.error(err, handleErr));

        // on success =>
        const response: EasyQueryResponse<T> = await response$;
        return response;
    }

}