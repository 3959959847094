import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RetailReportRoutingModule } from './reports-routing.module';
import { ReportsComponenet } from './reports.component';
import { RetailViewReportsComponent } from './view-reports/view-reports.component';
import { RetailFastReportComponent } from './fast-report/fastreport.component';
import { RetailReportControl } from './basereport/base-report.component';
import { ReportService } from './report-service.service'; 
import { RetailInventorySummaryComponent } from './report-filters-section/retail-reports/inventory-summary/inventory-summary.component';
import { RetailMultipackItemComponent } from './report-filters-section/retail-reports/multipack-item/multipack-item.component';
import { RetailInventoryDetailsComponent } from './report-filters-section/retail-reports/inventory-details/inventory-details.component';
import { RetailInventoryTransferComponent } from './report-filters-section/retail-reports/inventory-transfer/inventory-transfer.component';
import { RetailCategoryComponentComponent } from './report-filters-section/retail-reports/category-component/category-component.component';
import { RetailInventoryWashComponent } from './report-filters-section/retail-reports/inventory-wash/inventory-wash.component';
import { RetailTransactionLogReportComponent } from './report-filters-section/retail-reports/transaction-log-report/transaction-log-report.component';
import { RetailInventoryAuditComponent } from './report-filters-section/retail-reports/inventory-audit/inventory-audit.component';
import { RetailSalesByDiscountypeComponent } from './report-filters-section/retail-reports/sales-by-discountype/sales-by-discountype.component';
import { RetailSalesBySubcategoryComponent } from './report-filters-section/retail-reports/sales-by-subcategory/sales-by-subcategory.component';
import { RetailSalesByCategoryComponent } from './report-filters-section/retail-reports/sales-by-category/sales-by-category.component';
import { RetailSaleByItemComponent } from './report-filters-section/retail-reports/sale-by-item/sale-by-item.component';
import { RetailCorrectVoidComponent } from './report-filters-section/retail-reports/correct-void/correct-void.component';
import { RetailRevenueSummaryComponent } from './report-filters-section/retail-reports/revenue-summary/revenue-summary.component';
import { RetailTransactionComponent } from './report-filters-section/retail-reports/transaction/transaction.component';
import { RetailShiftComponent } from './report-filters-section/retail-reports/shift/shift.component';
import { RetailInventoryReportComponent } from './report-filters-section/retail-reports/inventory-report/inventory-report.component';

import { MultiSelectDropdownComponent } from './multi-select-dropdown/multi-select-dropdown.component';
import { FastReportBusinessService } from './fast-report/fastreport.business.service';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ScrollbarModule } from 'ngx-scrollbar';
import { MaterialModule } from '../material-module';
import { NgxMaterialTimepickerModule } from 'ngx-material-timepicker';
import { RetailSharedModule } from '../shared/retail-shared.module';
import { RetailToggleSwitchModule } from '../retail-toggle-switch/retail-toggle-switch.module';
import { GiftCardRedeemComponent } from './gift-cards-reports/gift-card-redeem/gift-card-redeem.component';
import { GiftCardIssuedComponent } from './gift-cards-reports/gift-card-issued/gift-card-issued.component';
import { RetailTransactionDetailComponent } from './report-filters-section/retail-reports/transaction-detail/transaction-detail/transaction-detail.component';
import { RetailTopSpendersComponent } from './report-filters-section/retail-reports/top-spenders/top-spenders.component';
import { RetailTaxExemptSalesComponent } from './report-filters-section/retail-reports/taxexempt-sales/taxexempt-sales..component';
import { StaffMemberSummaryComponent } from './report-filters-section/retail-reports/staff-member-summary/staff-member-summary.component';
import { SurchargesComponent } from './report-filters-section/retail-reports/surcharges/surcharges.component';
import { AccrualComponent } from './report-filters-section/retail-reports/accrual/accrual.component';
import { RetailSalesMixComponent } from './report-filters-section/retail-reports/sales-mix/sales-mix.component';
import { InventoryPickupDeliveryReportComponent } from './report-filters-section/retail-reports/inventory-pickup-delivery-report/inventory-pickup-delivery-report.component';
import { RetailItemsExtractComponent } from './report-filters-section/retail-reports/itemsextract/itemsextract.component';
import { SharedModule } from 'src/app/shared/shared.module';
import { DateAdapter, MAT_DATE_FORMATS } from '@angular/material/core';
import { CustomDateAdapter, MY_DATE_FORMATS } from 'src/app/core/localization/custom.dateAdapter';
import { Platform } from '@angular/cdk/platform';
import { Localization } from 'src/app/common/localization/localization';
import { RetailVendorInventoryDetailComponent } from './report-filters-section/retail-reports/vendor-inventory-detail/vendor-inventory-detail.component';
import { SalesByVendorComponent } from './report-filters-section/retail-reports/sales-by-vendor/sales-by-vendor/sales-by-vendor.component';
import { RetailInventorySummaryByVendorComponent } from './report-filters-section/retail-reports/inventory-summary-By-Vendor/inventory-summary-By-Vendor.component';
import { InventoryOnHandComponent } from './report-filters-section/retail-reports/inventory-on-hand/inventory-on-hand.component';
import { InventoryVarianceComponent } from './inventory-control-reports/inventory-variance/inventory-variance/inventory-variance.component';
import { InventoryReceivingComponent } from './inventory-control-reports/inventory-receiving/inventory-receiving.component';
import { InventoryPurchaseOrdersComponent } from './inventory-control-reports/inventory-purchase-orders/inventory-purchase-orders.component';
import { InventoryValueandActivityComponent } from './inventory-control-reports/inventory-value-and-activity/inventory-value-and-activity/inventory-value-and-activity.component';
import { TransactionExtractComponent } from './report-filters-section/retail-reports/transaction-extract/transaction-extract.component';
import { InventoryAvailabilityComponent } from './report-filters-section/retail-reports/Inventory-Availability/inventory-Availability.component';
import { OutletSalesComponent } from './fiscal-reports/outlet-sales/outlet-sales.component';
import { SalesSummaryComponent } from './fiscal-reports/sales-summary/sales-summary.component';
import { OutletSalesBackofficeComponent } from './fiscal-reports/outlet-sales/outlet-sales-backoffice/outlet-sales-backoffice.component';
import { OutletSalesZreadingComponent } from './fiscal-reports/outlet-sales/outlet-sales-zreading/outlet-sales-zreading.component';
import { VatClosedCheckComponent } from './fiscal-reports/vat-closed-check/vat-closed-check.component';
@NgModule({
  imports: [
    CommonModule,
    RetailReportRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    ScrollbarModule,
    MaterialModule,
    NgxMaterialTimepickerModule,
    RetailSharedModule,
    RetailToggleSwitchModule,
    SharedModule
  ],

  declarations: [
    ReportsComponenet,
    RetailViewReportsComponent,
    RetailReportControl,
    RetailFastReportComponent,
    MultiSelectDropdownComponent,
     RetailInventorySummaryComponent,
     RetailMultipackItemComponent,
     RetailInventoryDetailsComponent,
     RetailInventoryTransferComponent,
     RetailCategoryComponentComponent,
     RetailInventoryWashComponent,
     RetailTransactionLogReportComponent,
     RetailInventoryAuditComponent,
     RetailSalesByDiscountypeComponent,
     RetailSalesBySubcategoryComponent,
     RetailSalesByCategoryComponent,
     RetailSaleByItemComponent,
     RetailCorrectVoidComponent,
     RetailRevenueSummaryComponent,
     RetailTopSpendersComponent,
     RetailTransactionComponent,
     RetailShiftComponent,
     GiftCardRedeemComponent,
     GiftCardIssuedComponent,
     RetailTransactionDetailComponent,
     RetailTaxExemptSalesComponent,
     StaffMemberSummaryComponent,
     SurchargesComponent,
     AccrualComponent,
     RetailSalesMixComponent,
     RetailInventoryReportComponent,
     InventoryPickupDeliveryReportComponent,
     RetailItemsExtractComponent,
     SalesByVendorComponent,
	RetailVendorInventoryDetailComponent,     
     RetailInventorySummaryByVendorComponent, InventoryOnHandComponent,
     InventoryVarianceComponent,
     InventoryReceivingComponent,
     InventoryPurchaseOrdersComponent,
     TransactionExtractComponent,
     SurchargesComponent,
     InventoryValueandActivityComponent,
     InventoryAvailabilityComponent,
     OutletSalesComponent,
     SalesSummaryComponent,
     OutletSalesBackofficeComponent,
     OutletSalesZreadingComponent,
     VatClosedCheckComponent
     
    ],
  providers: [
    ReportService,
    FastReportBusinessService,
    {
      provide: DateAdapter,
      useClass: CustomDateAdapter,
      deps: [Localization,
        Platform]
    },
    {
      provide: MAT_DATE_FORMATS,
      useValue: MY_DATE_FORMATS
    },
  ],
  exports: [
    ReportsComponenet,
    RetailViewReportsComponent,
    RetailReportControl,
    RetailFastReportComponent,
    MultiSelectDropdownComponent,
     RetailInventorySummaryComponent,
     RetailMultipackItemComponent,
     RetailInventoryDetailsComponent,
     RetailInventoryTransferComponent,
     RetailCategoryComponentComponent,
     RetailInventoryWashComponent,
     RetailTransactionLogReportComponent,
     RetailInventoryAuditComponent,
     RetailSalesByDiscountypeComponent,
     RetailSalesBySubcategoryComponent,
     RetailSalesByCategoryComponent,
     RetailSaleByItemComponent,
     RetailCorrectVoidComponent,
     RetailRevenueSummaryComponent,
     RetailTopSpendersComponent,
     RetailTransactionComponent,
     RetailShiftComponent,
     RetailTransactionDetailComponent,
     RetailTaxExemptSalesComponent,
     RetailInventoryReportComponent,
     InventoryPickupDeliveryReportComponent,
     RetailItemsExtractComponent,
     RetailVendorInventoryDetailComponent,   
     RetailInventorySummaryByVendorComponent,
     InventoryVarianceComponent,
     InventoryReceivingComponent,
     InventoryPurchaseOrdersComponent,
     InventoryValueandActivityComponent,
     InventoryAvailabilityComponent,
     OutletSalesComponent,
     SalesSummaryComponent
  ]
})
export class RetailReportModule { }
 
