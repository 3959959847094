import { Injectable } from '@angular/core';
import { DiaryDataService } from '../dataservices/diary.data.service';
import { Localization } from '../localization/localization';
import { MultiImageUpload } from '../Models/ag-models';
import { API,UI ,DiarySearchFilter, DocumentModel, DocumentResponse, DocumentModelResponse, CalenderCount} from '../Models/diary.model';


@Injectable()
export class DiaryBusiness {

    captions: any;
    constructor(private localization: Localization,private _diaryDataService : DiaryDataService){
        this.captions = this.localization.captions; 
  }
 
  public async CreateDiaryEvent(id: number,subject: string,notes:string,date:string,propDateTime:string,tags:string[],imgs : MultiImageUpload[], recurringInfo : API.DiaryRecurringInfo): Promise<boolean> {
    
    let diaryDocuments : UI.DiaryDocument[] =[];
    if(imgs.length > 0){
       const docres = await this.UploadImages(imgs);
       diaryDocuments = docres.map(x=>this.DiaryDocumentAPIMapper(x,imgs));
    }
    const apiModel: API.Diary = this.DiaryUIToApiMapper(id,subject,notes,date,propDateTime,tags,diaryDocuments);
    apiModel.diaryRecurringInfo = recurringInfo;
    return await this._diaryDataService.CreateDiaryEvent(apiModel);
  };
  public async UpdateDiaryEvent(id: number,subject: string,notes:string,date:string,propDateTime:string,tags:string[],imgs : MultiImageUpload[],modifiedDocs : UI.DiaryDocument[]): Promise<boolean> {
    let diaryDocuments : UI.DiaryDocument[] =[];
    const modifiednames = modifiedDocs.map(x=>x.fileName);
    const newimages = imgs.filter(x=>!modifiednames.includes(x.filename));
    if(newimages.length > 0){
       const docres = await this.UploadImages(newimages);
       diaryDocuments = docres.map(x=>this.DiaryDocumentAPIMapper(x,newimages));
    }
    const oldimgs = imgs.filter(x=>modifiednames.includes(x.filename)).map(x=>x.filename);

    diaryDocuments = diaryDocuments.concat(modifiedDocs.filter(x=>oldimgs.includes(x.fileName)));
    const apiModel: API.Diary = this.DiaryUIToApiMapper(id,subject,notes,date,propDateTime,tags,diaryDocuments);
    return await this._diaryDataService.UpdateDiaryEvent(apiModel);
  };

  public async DeleteDiaryEvent(diaryId: number) {
    return await this._diaryDataService.DeleteDiaryEvent(diaryId);
  }

  public async CopyEvents(copyFromYear: number, copyToYear: number) {
    return await this._diaryDataService.CopyEvents(copyFromYear, copyToYear);
  }

public DiaryDocumentAPIMapper(docres : DocumentModelResponse, imgs : MultiImageUpload[]) : UI.DiaryDocument{
    const img = imgs.find(x=>x.filename == docres.fileName);
    const diarydoc : UI.DiaryDocument = {
        id: 0,
        docId: docres.docId,
        fileName : img.filename,
        contentType : img.image.split(',')[0]
      };
      return diarydoc;
    
}

public async UploadImages(imgs : MultiImageUpload[]) : Promise<DocumentModelResponse[]>{
    const docs : DocumentModel[] = imgs.map(x=>this.DocumentAPItoUIMapper(x));
    const docres = await this._diaryDataService.UploadImages(docs);
    return docres;
}

public async GetAllImages(docs : UI.DiaryDocument[]) : Promise<DocumentResponse[]>{
    const docids : string[] = docs.map(x=>x.docId);
    const response : DocumentResponse[] = await this._diaryDataService.GetAllImages(docids);
    
    return response;
}
public DocumentUItoAPIMapper(docs : DocumentResponse[],diaryDocs : UI.DiaryDocument[]) : MultiImageUpload[] {

    const imgs  : MultiImageUpload[]=docs.map(x=>{
        const diaryDoc = diaryDocs.find(f=>f.docId == x.docId);
        return {
            image : diaryDoc.contentType + ',' + x.documentBytes,
            imageSize : 0,
            thumbnailSize: 0,
            thumbnail: diaryDoc.contentType + ',' + x.documentBytes,
            filename: diaryDoc.fileName,
        
        }  as MultiImageUpload
    });
    return imgs;
   
}
public DocumentAPItoUIMapper(img : MultiImageUpload) : DocumentModel{
    const doc : DocumentModel ={
        fileData : img.image.split(',')[1],
        fileName : img.filename
    }
return doc;
}
public DiaryUIToApiMapper(id: number,subject: string,notes:string,date:string,propDateTime:string,tags:string[],docs:UI.DiaryDocument[]): API.Diary{
    let uimodel : API.Diary  =
    {
        id:id,
        subject : subject,
        notes : notes,
        date : date,
        isShowtoOthrProperty :false,
        createdPDTM : propDateTime,//get datetime
        diaryDocuments : docs,
        diaryTagMappings : tags.map(x=>{return {tagName:x} as API.DiaryTagMapping})
    } as  API.Diary;
    return uimodel;
  }
  public async GetDiaryEventByDate(diaryDate : string) : Promise<UI.DiarySearchResult[]>{
    const result = await this._diaryDataService.GetDiaryEventByDate(diaryDate);
    return this.DiarySearchResultMapper(result);

  }
  public async SearchDiary(startDate : string,endDate : string,tags:string[]) : Promise<UI.DiarySearchResult[]>{
    const filter : DiarySearchFilter = {
        startDate : startDate,
        endDate : endDate,
        tagNames : tags,
    };
    const result = await this._diaryDataService.SearchDiary(filter);
    return this.DiarySearchResultMapper(result);

  }
  public async GetDiaryCount(startDate : string,endDate : string) : Promise<CalenderCount[]>{
    const result = await this._diaryDataService.GetDiaryCount(startDate,endDate);
    return result;

  }

  public async GetPastDiaryEventYears() : Promise<string[]>{
    const result = await this._diaryDataService.GetPastDiaryEventYears();
    return result;

  }

  public DiarySearchResultMapper(model : API.DiarySearchResult[]) : UI.DiarySearchResult[]{
    let result : UI.DiarySearchResult[] = model.map(x=> {
      const createdInfo : UI.DiaryAuditInfo={
        firstName : x.diaryInfo.firstName,
        lastName : x.diaryInfo.lastName,
        modifiedDateTime : x.diaryInfo.createdPDTM,
      }
      let diaryAuditInfos =x.diaryAuditInfos.map(a=>this.diaryAuditInfoMapper(a));
      diaryAuditInfos.push(createdInfo);
      return {
          diaryInfo : this.diaryInfoMapper(x.diaryInfo),
          diaryAuditInfos : diaryAuditInfos
      } as UI.DiarySearchResult
    });
    return result;
  }
  public diaryInfoMapper(model : API.DiaryInfo): UI.DiaryInfo{
    const info :UI.DiaryInfo = {
        id:model.id,
        subject:model.subject,
        notes:model.notes,
        date:model.date,
        isShowtoOthrProperty:model.isShowtoOthrProperty,
        createdPDTM:model.createdPDTM,
        userName :  model.firstName + ' ' + model.lastName,
        diaryDocuments : model.diaryDocuments.map(x=>{return {id : x.id,docId: x.docId,fileName : x.fileName,contentType:x.contentType} as UI.DiaryDocument}),
        diaryTagMappings : model.diaryTagMappings.map(x=>{return {tagName : x.tagName} as UI.DiaryTagMapping})
    }
    return info;
  }
  public diaryAuditInfoMapper(model : API.DiaryAuditInfo): UI.DiaryAuditInfo{
    const info : UI.DiaryAuditInfo={
        firstName : model.firstName,
        lastName : model.lastName,
        modifiedDateTime : model.modifiedDateTime,
    }
    return info;
  }
  public async getTagBySearchParams(searchParam: string): Promise<UI.DiaryTag[]> {

    const apitags: API.DiaryTag[] = await this._diaryDataService.Searchtag(searchParam);

    const returnArray: UI.DiaryTag[] = apitags.map(x=>{return {id:x.id,name:x.tagName} as UI.DiaryTag});
    return returnArray;
  }
  getModifiedList(){
    return[{
        firstName : "John",
        lastName : "Smith",
        modifiedDateTime : "Jan 02 2022 4:30pm",
        status : false
    },
    {
        firstName : "Mary",
        lastName : "Stewart",
        modifiedDateTime : "Jan 02 2022 4:30pm",
        status: true
    },
    {
        firstName : "Mary",
        lastName : "Stewart",
        modifiedDateTime : "Jan 02 2022 4:30pm",
        status : false
    }]
  }
}
