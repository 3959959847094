import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { ActionMode, AlertAction, AlertType, ButtonType } from 'src/app/common/enums/shared-enums';
import { TableHeaderOptions, TableOptions, TableSearchHeader } from '../../Models/ag-models';
import { Localization } from 'src/app/common/localization/localization';
import { CommonUtilities } from 'src/app/common/shared/shared/utilities/common-utilities';
import { MatDialog } from '@angular/material/dialog';
import { GuestTypeBusiness } from './common-guest-type.business';
import { CreateGuestTypeComponent } from './create-guest-type/create-guest-type.component';
import { UserAccessBusiness } from '../../dataservices/authentication/useraccess.business';
import _ from 'lodash';

@Component({
  selector: 'app-common-guest-type',
  templateUrl: './common-guest-type.component.html',
  styleUrls: ['./common-guest-type.component.scss'],
  providers: [GuestTypeBusiness],
  encapsulation: ViewEncapsulation.None
})
export class CommonGuestTypeComponent implements OnInit {
  searchHeaderOption: TableSearchHeader;
  captions: any;
  options: TableOptions;
  headerOptions: TableHeaderOptions[];
  tableContent = [];
  originalTableContent = [];
  searchText = '';
  originalData: [];
  IsEditModeEnabledFlag = false;
  isViewOnly = false;
  defaultSortingKey = 'listOrder';
  deleteMsgKey = 'name';
  includeInactive = false;
  tableoptions;
  onEditDisableFunction = true;
  nextListOrder: number = 1;

  constructor(private localization: Localization, private business: GuestTypeBusiness,
    private utils: CommonUtilities, private dialog: MatDialog, private _userAccessBusiness: UserAccessBusiness) { }

  async ngOnInit() {
    this.captions = this.localization.captions;
    await this.validateBreakPoint();
    this.generateTableData();
  }

  async validateBreakPoint() {
    let guestTypeBp = await this._userAccessBusiness.getUserAccess(await this.business.getGuestTypeBreakpoint());
    if (guestTypeBp)
      this.isViewOnly = guestTypeBp.isViewOnly;
  }

  async generateTableData() {
    this.onEditDisableFunction = true;
    let arrDataHeader = [
      { 'title': this.captions.GuestTypeCode, 'jsonkey': 'code', 'searchable': true, 'sortable': true },
      { 'title': this.captions.GuestTypeName, 'jsonkey': 'name', 'searchable': true, 'sortable': true },
      { 'title': this.captions.VIPType_grid_hdr_ListOrder, 'jsonkey': 'listOrder', 'searchable': false, 'sortable': true },
      { 'title': this.captions.VIPType_grid_hdr_IsActive, 'jsonkey': 'isActive', 'searchable': false, 'sortable': true, "type": "toggle" },
    ]
    this.tableContent = await this.business.GetAllGuestTypes(true);
    this.nextListOrder = this.tableContent.length > 0 ? Math.max(...this.tableContent.map(t => t.listOrder)) + 1 : 1;
    this.originalTableContent = _.cloneDeep(this.tableContent);
    this.tableoptions = [
      {
        TableHdrData: arrDataHeader,
        TablebodyData: this.tableContent,
        pagination: false,
        sortable: true,
        editable: true,
        CustomColumn: true,
        PlaceHoldertext: this.captions.GuestTypeCodeDescription,
        EnableActions: true,
        SelectRows: false,
        Searchable: true,
        EditMoreOption: false,
        Sortable: 'listOrder',
        TableId: '',
        disableDelete: false,
        SelectRow: true,
        SelectOnlyRow: true,
        dropdownOptions: '',
        waitlistIcon: false,
        TableDraggable: true,
        sticky: false,
        DoneCancel: false,
        IsViewOnly: this.isViewOnly,
        disableEditButton: this.isViewOnly,
        isCopyEnabled: true,
      }
    ];
    this.onToggleChange(this.includeInactive);
  }

  deleteGuestType(eve) {
    this.utils.showAlert(this.captions.lbl_deletemsgsetup, AlertType.Warning, ButtonType.YesNo, async (res) => {
      if (res == AlertAction.YES && eve.length > 0 && eve[0].id > 0) {
        await this.business.deleteGuestType(eve[0].id).catch(x => this.catchError(x.error.errorCode));
        this.generateTableData();
      }
    });
  }

  openDialog() {
    this.dialog.open(CreateGuestTypeComponent, {
      width: '40%',
      maxWidth: "1000px;",
      height: "575px",
      hasBackdrop: true,
      disableClose: true,
      panelClass: 'ag_dialog--lg',
      data: {
        header: this.captions.CreateGuestType,
        mode: ActionMode.create,
        nextListOrder: this.nextListOrder
      }
    }).afterClosed().subscribe(async result => {
      this.IsEditModeEnabledFlag = false;
      if (result) {
        await this.business.createGuestType(result).catch(x => this.catchError(x.error.errorCode));
      }
      this.generateTableData();
    });
  }

  EditRecords(rowData) {
    this.dialog.open(CreateGuestTypeComponent, {
      width: '40%',
      maxWidth: "1000px;",
      height: "575px",
      hasBackdrop: true,
      panelClass: 'ag_dialog--lg',
      data: {
        header: this.captions.hdr_editGuestType,
        data: rowData[0],
        mode: ActionMode.update,
        isViewOnly: this.isViewOnly
      }
    }).afterClosed().subscribe(async result => {
      this.IsEditModeEnabledFlag = false;
      if (result) {
        await this.business.updateGuestType(result).catch(x => this.catchError(x.error.errorCode));
      }
      this.generateTableData();
    });
  }

  copyEvent(rowData) {
    this.dialog.open(CreateGuestTypeComponent, {
      width: '40%',
      maxWidth: "1000px;",
      height: "575px",
      hasBackdrop: true,
      panelClass: 'ag_dialog--lg',
      data: {
        header: this.captions.CreateGuestType,
        data: rowData,
        mode: ActionMode.copy,
        nextListOrder: this.nextListOrder
      }
    }).afterClosed().subscribe(async result => {
      if (result) {
        await this.business.createGuestType(result).catch(x => this.catchError(x.error.errorCode));
        this.generateTableData();
      }
    });
  }

  CancelEvent(event) {
    this.IsEditModeEnabledFlag = false;
    this.generateTableData();
  }

  async DoneEvent(event) {
    this.IsEditModeEnabledFlag = false;
    if (event)
      await this.business.updateGuestType(event).catch(x => this.catchError(x.error.errorCode));
    this.generateTableData();
  }

  searchChange(e) {
    this.searchText = e;
  }

  inactiveSliderChange(event) {
  }
  
  onToggleChange(e) {
    this.includeInactive = Array.isArray(e) ? e[0] : e;
    let originalTableContent = _.cloneDeep(this.originalTableContent);
    this.tableContent = this.includeInactive ? originalTableContent : originalTableContent.filter(o => o.isActive);
    var tableData = [... this.tableoptions];
    tableData[0].TablebodyData = this.tableContent;
    this.tableoptions = tableData;
  }

  async dragDrop(e) {
    await this.business.dragDropGuestType(e[0], e[1]).catch(x => this.catchError(x.error.errorCode));
    this.generateTableData()
  }

  catchError(errorCode){
    this.utils.showError(this.localization.getError(errorCode));
  }
}
