import { HttpService } from 'src/app/eatecui/source/shared/services/http.service';
import {
    CalcultionField,
    CustomFilterNavigationUrl,
    CustomToolTipConfig,
    GridCustomColExpand,
    GridFilterAction,
    GridHeaderConfig,
    GridTableCard, MasterInventoryManagement, NestedBuilderData, NestedDataBuilder, SubFormConfigs, TitleType, TransactionMasterData
} from '../master.interface';
import { GridLoaderModel, MasterDetailTabChangeConfiguration } from './master-detail-config.interface';
import { InfiniteScrollItemCount } from 'src/app/eatecui/source/shared/constant/GlobalConstants';
import { QueryParams } from 'src/app/eatecui/source/shared/models/api-query-params.interface';
import {
    GridActionsConfig, GridColumnReorder, GridConfig, GridFilter, GridFilterData, GridFilterValue, GridInfiniteScrollConfig,
    GridPaginationConfig, GridResizeRows, GridSearchConfig
} from 'src/app/eatecui/dist/AgilysysDynamicGrid';
import { TranslateService } from '@ngx-translate/core';
import { GridInterface } from '../grid-interface';
import { DetailInterface } from '.';
import { Formaters } from '../../formaters';
import _ from 'lodash';
// import * as TransactionMaster from '../../../../../assets/json/master/transaction-master.json';
import { MasterManagementService } from '../../service/master.service';
import { Formater } from 'src/app/eatecui/source/shared/models/formaters/formater.interface';
import { ProductDataRemap } from 'src/app/eatecui/source/shared/models/product-data-remap';
import { GlobalCustomFormAction } from 'src/app/eatecui/source/shared/models/custom-action/global-custom-action.interface';
import { ActionTypes } from 'src/app/eatecui/source/shared/models/custom-action/custom-enum.interface';
import { MasterName, PackageTypes } from '../../enum/master-enum';
import { ENDPOINTURL } from 'src/app/eatecui/source/shared/constant/APIURL-CONSTANT';
// import * as CrossReference from '../../../../../assets/json/master/cross-reference-master.json';


export const MasterNestedDetailTabChange: MasterDetailTabChangeConfiguration =
    (ViewModelEntity: any, MasterDataCollection: MasterInventoryManagement,
        httpService: HttpService, GridTableCardDataObject: any, ResponseData: any,
        SubForm: SubFormConfigs, translateService: TranslateService, AllViewModelEntity: any,
        masterManagementService: MasterManagementService, transaactionMasterData ?: Array<TransactionMasterData>,
        crossReferenceData ?: Array<TransactionMasterData>, gridLoaderModel ?: GridLoaderModel): any => {
        try {
            // const TransactionMasterJson: TransactionMasterData[] = (TransactionMaster as any).default;
            const TransactionMasterJson: TransactionMasterData[] = transaactionMasterData;
            if (ViewModelEntity.length > 0 && MasterDataCollection) {
                const Url = ViewModelEntity[0]['$']['Name'];
                const ParentEnitityName = ViewModelEntity[0]['$']['Name'];
                const productremapJson = JSON.parse(sessionStorage.getItem('ProductRemap'));
                const params: QueryParams = {
                    count: true,
                    top: InfiniteScrollItemCount,
                    skip: 0,
                    expand: '',
                    select: '',
                    filter: '',
                    orderby: '',
                    isactive: false,
                    search: '',
                    filterQuery: '',
                    searchtext: ''
                };
                const dataObject = ResponseData['value'];
                if (MasterDataCollection?.NestedFormConfig?.ViewFormConfig) {
                    // MasterDataCollection.NestedFormConfig.ViewFormConfig.forEach((SubForm: SubFormConfigs) => {
                        if (SubForm.Type === 'listinggrid' && SubForm.OdataKey !== 'ProductItemDetails') {
                            SubForm.NestedGridData.GridData = [];
                            params.expand = 'IngredientsBarCodes($select=Barcode),IngRetailDescriptors1($select=id,name),IngRetailDescriptors2($select=id,name),IngRetailDescriptors3($select=id,name),IngRetailDescriptors4($select=id,name),IngRetailDescriptors5($select=id,name),IngRetailDescriptors6($select=id,name)';
                            params.filterQuery = `Parent eq ${GridTableCardDataObject}`;
                            const getQueryParam = formQueryParams(params);
                            const GetDataByIdUrls = `/inventory/api/${Url + getQueryParam}`;
                            let dataGridResponse = [];
                            gridLoaderModel.commonService.enableGridLoader(gridLoaderModel.gridApi, true)
                            httpService.GethttpMethod(GetDataByIdUrls).subscribe(val => {
                                setTimeout(() => {
                                    gridLoaderModel.commonService.enableGridLoader(gridLoaderModel.gridApi, false);
                                }, 10);
                                dataGridResponse = val['value'];
                                if (typeof dataGridResponse === 'string') {
                                    const resposeGridConvertArray = [];
                                    const dataGridResponseObject = JSON.parse(val['value']);
                                    resposeGridConvertArray.push(dataGridResponseObject);
                                    dataGridResponse = resposeGridConvertArray;
                                }
                                dataGridResponse.forEach(element => {
                                    element.IngredientsBarCodes = (element.IngredientsBarCodes &&
                                        element.IngredientsBarCodes.length > 0) ? element.IngredientsBarCodes[0].Barcode : '';
                                });
                                const ChildNavigationProperty = AllViewModelEntity.
                                    filter(view => view['$']['Name'] === SubForm.OdataKey)[0];
                                SubForm.NestedGridData = AssignGridData(dataGridResponse,
                                    SubForm, ViewModelEntity, translateService, ChildNavigationProperty);
                                SubForm.NestedGridData.GridData = assignRowData(dataGridResponse, ChildNavigationProperty);
                            },err => {
                                setTimeout(() => {
                                    gridLoaderModel.commonService.enableGridLoader(gridLoaderModel.gridApi, false);
                                }, 10);
                              });
                            SubForm.data.forEach((nestedBuilder: NestedDataBuilder) => {
                                nestedBuilder.NestedData.forEach((NestedData: NestedBuilderData) => {
                                    if (NestedData.hasOwnProperty('CustomOdataUrl')) {
                                        NestedData.Value = DetailInterface.MasterCustomNavigation(
                                            dataObject[0][NestedData.OdataKey], NestedData.CustomOdataUrl, httpService, NestedData,
                                            ViewModelEntity, dataGridResponse);
                                    } else {
                                        NestedData.Value = assignNavigationProperity(dataObject[0],
                                            NestedData, ViewModelEntity) ? assignNavigationProperity(dataObject[0],
                                                NestedData, ViewModelEntity) : '-';
                                    }
                                    NestedData.Visible = ProductDataRemap.FieldVisibleRemapEngine(productremapJson,
                                        ParentEnitityName.toString() + nestedBuilder.NestedName, NestedData.DisplayName);
                                });
                            });
                        } else if (SubForm.Name === 'Transactions') {
                            SubForm.NestedGridData.GridData = [];
                            const MasterData = TransactionMasterJson[0];
                            const ExpandQuery = MasterData.ExpandName;
                            const orderby = MasterData.GridConfiguration.GridSort[0].SortingOdataKey + ' '
                                + MasterData.GridConfiguration.GridSort[0].SortingType;
                            const DetailName = MasterData.DetailsName;
                            let loggedInSiteId: any = sessionStorage.getItem('LoggedInSiteId');
                            loggedInSiteId = loggedInSiteId ? parseInt(loggedInSiteId, 10) : 1;
                            let MasterUrl;
                            // `/inventory/api/${DetailName}?$filter=IngredientId eq 
                            // ${GridTableCardDataObject}&$expand=${ExpandQuery}&$orderby=${orderby}`;
                            switch (DetailName) {
                                case 'IngredientUsages':
                                  MasterUrl = `/reportdata/api/IngredientUsages?IngredientId=${GridTableCardDataObject}&loggedInSiteId=${loggedInSiteId}&$orderby=${orderby}`;
                                  break;
                                case 'ItemTransferList':
                                  MasterUrl = `/inventory/api/${DetailName}/${GridTableCardDataObject}`;
                                  break;
                                case 'ReqDetails':
                                    MasterUrl = `/inventory/api/GetItemTransactions/${GridTableCardDataObject}/4`;
                                    break;
                                case 'PurchaseOrderDetails':
                                    MasterUrl = `/inventory/api/GetItemTransactions/${GridTableCardDataObject}/2`;
                                    break;
                                case 'ReceivingDetails':
                                    MasterUrl = `/inventory/api/GetItemTransactions/${GridTableCardDataObject}/3`;
                                    break;
                                default:
                                    MasterUrl = 
                                    `/inventory/api/${DetailName}?$filter=IngredientId eq ${GridTableCardDataObject} and IsPosted eq true&$expand=${ExpandQuery}&$orderby=${orderby}`;
                                    break;
                            }
                            httpService.GethttpMethod(MasterUrl).subscribe((res: any) => {
                                let response = [];
                                if (res?.ResultData !== null) {
                                    switch(DetailName) {
                                        case 'ReqDetails':
                                        case 'PurchaseOrderDetails':
                                        case 'ReceivingDetails':
                                            response = res.ResultData;
                                            break;
                                        case 'IngredientUsages':
                                            response = res;
                                            break;                                    
                                        case 'ItemTransferList':
                                            const resultData = (typeof res['value'] === 'string' ? JSON.parse(res['value']) : res['value']);
                                            response = resultData['ItemTransfers'];
                                    }
                                }                                
                                // if (res === null) {
                                //     response = [];
                                // } else {
                                //     response = DetailName === 'IngredientUsages' ? res :
                                //     (typeof res['value'] === 'string' ? JSON.parse(res['value']) : res['value']);
                                //     if (DetailName === 'ItemTransferList') {
                                //     response = response['ItemTransfers'];
                                //     }
                                // }
                                if (MasterData.GridConfiguration.GridHeaderDefination.
                                    filter(y => y.field === 'QtyBU')[0]) {
                                    MasterData.GridConfiguration.GridHeaderDefination.
                                        filter(y => y.field === 'QtyBU')[0]['valueGetter'] = paramsValue => {
                                            paramsValue.data.QtyBU =
                                                paramsValue.data.Qty && paramsValue.data.Qty !== 0 &&
                                                    paramsValue.data.Conversion && paramsValue.data.Conversion !== 0
                                                    ? parseFloat(paramsValue.data.Conversion) * parseFloat(paramsValue.data.Qty) : 0;
                                            return paramsValue.data.QtyBU;
                                        };
                                }
                                SubForm.NestedGridData.GridSort = MasterData.GridConfiguration.GridSort;
                                SubForm.NestedGridData.GridSearch = MasterData.GridConfiguration.GridSearch;
                                if (SubForm.NestedGridData.GridActions && SubForm.NestedGridData.GridActions.FilterConfig) {
                                    SubForm.NestedGridData.GridActions.FilterConfig.EnableFilter = false;
                                    SubForm.NestedGridData.GridActions.FilterConfig.FilterOptions = [];
                                }
                                SubForm.NestedGridData.GridFilter = MasterData.GridConfiguration.GridFilter;
                                SubForm.NestedGridData.GridHeaderDefination =
                                    MasterData.GridConfiguration.GridHeaderDefination as unknown as GridHeaderConfig[];
                                SubForm.NestedGridData.GridTitleType = MasterData.GridConfiguration.GridTitleType;
                                const ChildNavigationProperty = AllViewModelEntity
                                    .filter(view => view['$']['Name'] === MasterData.GridConfiguration.GridOdataKey)[0];
                                SubForm.NestedGridData = AssignGridData(response,
                                    SubForm, ViewModelEntity, translateService, ChildNavigationProperty, httpService);
                                masterManagementService.TransactionCloneGridData = SubForm.NestedGridData.GridData;
                            });
                        } else if (SubForm.Name === 'CrossReference') {
                            SubForm.NestedGridData.GridData = [];
                            // Only Products is applicable for IC in V1. Hence it is always default to 2 
                            if (SubForm.NestedGridData.GridTitleType) {
                                SubForm.NestedGridData.GridTitleType.SelectedTypeId = 2;
                            } else {
                                SubForm.NestedGridData.GridActions.GridTitleType.SelectedTypeId = 2;
                            }
                            
                            let TypeId;
                            switch (Url) {
                                case MasterName.MasterIngredients: TypeId = PackageTypes.Inventory; break;
                                case MasterName.MasterProducts: TypeId = PackageTypes.Product; break;
                                case MasterName.MasterReceipe: TypeId = PackageTypes.Recipe; break;
                            }
                            // if (SubForm.NestedGridData.GridTitleType) {
                            //     SubForm.NestedGridData.GridTitleType.SelectedTypeId = 2;
                            // } else {
                            //     SubForm.NestedGridData.GridActions.GridTitleType.SelectedTypeId = 2
                            // }
                            const selectedTypeId = SubForm.NestedGridData.GridTitleType ?
                            SubForm.NestedGridData.GridTitleType.SelectedTypeId :
                            SubForm.NestedGridData.GridActions.GridTitleType.SelectedTypeId;
                            const CrossReferenceInfo = crossReferenceData.filter(x => x.Id === selectedTypeId)[0];
                            let MasterUrl: string;
                            switch (CrossReferenceInfo.GroupName) {
                                case 'Packages':
                                  MasterUrl = `/inventory/api/Packages?$filter=TypeId eq ${TypeId} and IsActive eq true and PackLists/any(PackLists: PackLists/Item eq ${GridTableCardDataObject} and PackLists/IsActive eq true)&$expand=PackagesTypes&$orderby=Name asc`;
                                  break;
                                case 'Products':
                                  MasterUrl = `/inventory/api/Products?$ItemId=${GridTableCardDataObject}&LinkItemType=${TypeId}`;
                                  break;
                                case 'Recipes':
                                  MasterUrl = `/inventory/api/Recipes?$filter=RecipeIngredients/any (RecipeIngredients: RecipeIngredients/InventoryLinkId eq ${TypeId} and RecipeIngredients/ItemId eq ${GridTableCardDataObject})&$orderby=Name asc&$expand=RcpTypeId($select=Id,Name)`;
                                  break;
                                case 'Ingredients':
                                  MasterUrl = `/inventory/api/Ingredients?$filter=Id gt 0 and IngredientsOtherDetails/ProdRecipeId eq ${GridTableCardDataObject} or IngredientsOtherDetails/AltProdrecipeId eq ${GridTableCardDataObject} or IngredientsOtherDetails/Prodrecipe3 eq ${GridTableCardDataObject}&$expand=IngredientTypes&$orderby=Name asc`;
                                  break;
                            }
                            gridLoaderModel.commonService.enableGridLoader(gridLoaderModel.gridApi, true)                            
                            httpService.GethttpMethod(MasterUrl).subscribe(res => {
                                setTimeout(() => {
                                    gridLoaderModel.commonService.enableGridLoader(gridLoaderModel.gridApi, false)
                                }, 10);
                                let response = [];
                                if (res === null) {
                                    response = [];
                                } else {
                                    response = res['value'];
                                }
                                console.log('CrossReferenceData', response);
                                const ChildNavigationProperty = AllViewModelEntity
                                    .filter(view => view['$']['Name'] === CrossReferenceInfo.GridConfiguration.GridOdataKey)[0];
                                SubForm.NestedGridData.GridHeaderDefination =
                                CrossReferenceInfo.GridConfiguration.GridHeaderDefination as unknown as GridHeaderConfig[];
                                SubForm.NestedGridData.GridSort = CrossReferenceInfo.GridConfiguration.GridSort;
                                // SubForm.NestedGridData.GridTitleType = crossReferenceData[0].GridConfiguration.GridTitleType;
                                SubForm.NestedGridData = AssignGridData(response,
                                    SubForm, ViewModelEntity, translateService, ChildNavigationProperty, httpService);
                                const sort = [{
                                    sort: SubForm.NestedGridData.GridSort[0].SortingType,
                                    colId: SubForm.NestedGridData.GridSort[0].SortingOdataKey
                                }];
                                const data = GridInterface.GridSortConfig(SubForm,
                                    MasterDataCollection, SubForm.NestedGridData.GridData, sort);
                                SubForm.NestedGridData.GridData = data;
                                masterManagementService.TransactionCloneGridData = SubForm.NestedGridData.GridData;
                            }, err => {
                                setTimeout(() => {                                    
                                    gridLoaderModel.commonService.enableGridLoader(gridLoaderModel.gridApi, false)
                                }, 10);
                              });
                        } else if (SubForm.Name === 'History') {
                            SubForm.NestedGridData.GridData = [];
                            let TableName: string;
                            switch (Url) {
                                case
                                    MasterName.MasterIngredients:
                                    TableName = MasterName.MasterIngredients;
                                    break;
                                case MasterName.MasterProducts:
                                    TableName = MasterName.MasterProducts;
                                    break;
                                case MasterName.MasterReceipe:
                                    TableName = MasterName.MasterReceipe;
                                    break;
                            }
                            const MasterUrl = `/ReportData/api/AuditLog/${TableName}/${GridTableCardDataObject}?$orderby=ChangedOn desc`;
                            httpService.GethttpMethod(MasterUrl).subscribe(res => {
                                let response = [];
                                if (res === null) {
                                    response = [];
                                } else {
                                    response = res['value'];
                                }
                                // eslint-disable-next-line max-len
                                const ChildNavigationProperty = AllViewModelEntity.filter(view => view['$']['Name'] === SubForm.OdataKey)[0];
                                SubForm.NestedGridData = AssignGridData(response, SubForm, ViewModelEntity,
                                    translateService, ChildNavigationProperty, httpService);
                                masterManagementService.TransactionCloneGridData = SubForm.NestedGridData.GridData;
                            });
                        } else if (SubForm.Name === 'Activity') {
                            SubForm.NestedGridData.GridData = [];
                            const TableName = 'ProductionDetailsbyRecipe';
                            const MasterUrl = `/inventory/api/${TableName}?$filter=RecipeId eq ${GridTableCardDataObject}&$expand=UnitDetail($select=id,name),Locations($select=id,name)`;
                            httpService.GethttpMethod(MasterUrl).subscribe(res => {
                                let response = [];
                                if (res === null) {
                                    response = [];
                                } else {
                                    response = res['value'];
                                }
                                // eslint-disable-next-line max-len
                                const ChildNavigationProperty = AllViewModelEntity.filter(view => view['$']['Name'] === SubForm.OdataKey)[0];
                                SubForm.NestedGridData = AssignGridData(response, SubForm, ViewModelEntity,
                                    translateService, ChildNavigationProperty, httpService);
                                masterManagementService.TransactionCloneGridData = SubForm.NestedGridData.GridData;
                            });
                        } else if (SubForm.Name === 'ItemSales') {
                            SubForm.NestedGridData.GridData = [];
                            let TableName;
                            switch (Url) {
                                case MasterName.MasterProducts: TableName = 'ItemSalesDetails'; break;
                            }
                            const MasterUrl = `/inventory/api/${TableName}/${GridTableCardDataObject}?$orderby=PostDate desc`;
                            httpService.GethttpMethod(MasterUrl).subscribe(res => {
                                let response = [];
                                if (res === null) {
                                    response = [];
                                } else {
                                    response = res;
                                }
                                const ChildNavigationProperty = AllViewModelEntity
                                    .filter(view => view['$']['Name'] === SubForm.OdataKey)[0];
                                SubForm.NestedGridData = AssignGridData(response,
                                    SubForm, ViewModelEntity, translateService, ChildNavigationProperty, httpService);
                                masterManagementService.TransactionCloneGridData = SubForm.NestedGridData.GridData;
                            });
                        } else if (SubForm.Name === 'PunchoutConfiguration') {
                            const ApiUrl = ENDPOINTURL['InvertoryManagement']['GetVendorPunchPutConfiguration'];
                            httpService.GethttpMethod(`${ApiUrl}/${GridTableCardDataObject}`).
                                subscribe(res => {
                                    SubForm.data.forEach(nestedData => {
                                        if (nestedData.NestedData.length === 0) {
                                            mappingPuchoutData(nestedData, res);
                                        }
                                        nestedData.NestedData.forEach(nestedFields => {
                                            const value = res['PunchoutFieldMapping'].findIndex(x => x.ConfigMappingKey === nestedFields.OdataKey);
                                            if (value !== -1) {
                                                const configValue = res['PunchoutFieldMapping'][value];
                                                if (nestedFields.OdataKey === 'InventoryType' || nestedFields.OdataKey
                                                === 'Division') {
                                                    nestedFields.Value = getDropdownValues(nestedFields, httpService, configValue.ConfigValues);
                                                } else {
                                                    nestedFields.Value = (configValue.ConfigValues) ? configValue.ConfigValues : '-';
                                                }
                                                
                                            }
                                        });
                                    });
                                });
                        }
                    // });
                }

                return MasterDataCollection.NestedFormConfig;
            }
        } catch (error) {
            console.log('error occured EnableGridFilter', error);
        }
    };

    function mappingPuchoutData(nestedBuilder: NestedDataBuilder, responaseData: any): any {
        try{
            if( nestedBuilder && responaseData.PunchoutMapping){
                responaseData.PunchoutMapping.forEach((punchout: any )=>{
                   if( punchout.ConfigGroup === nestedBuilder.NestedName ){
                      const nestedBuilderData = {} as NestedBuilderData;
                      nestedBuilderData.OdataKey = punchout.ConfigMappingKey;
                      nestedBuilderData.Name = punchout.ConfigMappingKey;
                      nestedBuilderData.DisplayName = punchout.ConfigMappingKey;
                      nestedBuilderData.EdmType = "Edm.String"
                      nestedBuilderData.Visible = true;
                      nestedBuilderData.Value = '-'
                      const toolTip = {} as CustomToolTipConfig;
                      toolTip.EnableToolTip = true;
                      toolTip.ToolTipText = '';
                      toolTip.ToolTipKey = `@${punchout.ConfigMappingKey}`;
                      nestedBuilderData.CustomToolTip = toolTip;
                      nestedBuilder.NestedData.push(nestedBuilderData);
                   }
                });
            }
        } catch(error){
            console.error(error);
        }
    }

function getDropdownValues (nestedFields: NestedBuilderData, httpService: HttpService, value: any): any {
    try {
        if (value && !isNaN(value)) {
            const EntityName = nestedFields.OdataKey === 'InventoryType' ? 'IngredientTypes' : 'InventoryDivisions';
            httpService.GethttpMethod(`/inventory/api/${EntityName}?$filter=Id eq ${value}`).subscribe(res => {
                if (res['value'].length > 0) {
                    nestedFields.Value = res['value'][0].Name;
                } else {
                    nestedFields.Value = '-';
                }
                return res['value'][0].Name;
            });
        } else {
            nestedFields.Value = '-';
        }
    } catch (error) {
        console.error('Error occurred in getDropdownValues', error);
    }
}

function getCustomNavigationData(UniqueId: any, customUrl: string, httpService: HttpService) {
    let customValue = UniqueId;
    // eslint-disable-next-line radix
    const id = parseInt(UniqueId);
    const seleId = `id eq ${id}`;
    const GetDataFilter = encodeURIComponent(`${seleId}`).replace(/[!'()*]/g, function (c) {
        return '%' + c.charCodeAt(0).toString(16);
    });
    const params: QueryParams = {
        count: true,
        top: InfiniteScrollItemCount,
        skip: 0,
        expand: '',
        select: '',
        filter: '',
        orderby: '',
        isactive: false,
        search: '',
        filterQuery: GetDataFilter,
        searchtext: ''
    };
    const getQueryParams = formQueryParams(params);
    const GetDataByIdUrl = `${customUrl + getQueryParams}`;
    httpService.GethttpMethod(GetDataByIdUrl).subscribe(x => {
        if (x['value'].length > 0) {
            customValue = x['value'][0]['Name'];
        }
    });
    return customValue;

}

function assignNavigationProperity(DataObject: object, OdataKeyFieldKey: NestedBuilderData, ViewModelEntityColl: any) {
    let entityObject = '';
    const key = OdataKeyFieldKey.OdataKey;
    if (OdataKeyFieldKey.Formatter) {
        entityObject = DataObject[OdataKeyFieldKey.OdataKey];
    } else if (OdataKeyFieldKey.hasOwnProperty('DynamicParentKey') && OdataKeyFieldKey.hasOwnProperty('DynamicDisplayKey')) {
        if (DataObject.hasOwnProperty(OdataKeyFieldKey.DynamicParentKey) && DataObject[OdataKeyFieldKey.DynamicParentKey].length > 0) {
            const getParentArray = DataObject[OdataKeyFieldKey.DynamicParentKey].filter(
                (x: any) => x[OdataKeyFieldKey.DynamicDisplayKey] === OdataKeyFieldKey.DisplayName)[0];
            if (getParentArray) {
                entityObject = getParentArray[OdataKeyFieldKey.DynamicValueKey];
            }
        } else {
            entityObject = '-';
        }

    } else {
        const ModelType = ViewModelEntityColl[0].Property.filter(x => x['$']['Name'] === OdataKeyFieldKey.OdataKey)[0];
        const Type = ModelType && ModelType['$'].Type === 'Edm.Boolean' ? 'Boolean' : 'Others';
        entityObject = DataObject[OdataKeyFieldKey.OdataKey];
        const BooleanObject = entityObject !== null && entityObject !== undefined && entityObject !== ''
            && typeof (entityObject) === 'boolean' ? true : false;
        if (Type === 'Boolean' || BooleanObject) {
            entityObject = DataObject[OdataKeyFieldKey.OdataKey] ? 'Yes' : 'No';
        }
    }
    if (
        ViewModelEntityColl &&
        ViewModelEntityColl[0].hasOwnProperty('NavigationProperty') &&
        ViewModelEntityColl[0]['NavigationProperty'].length > 0) {
        ViewModelEntityColl[0]['NavigationProperty'].forEach((element: any) => {
            const navProperiperityName = element['$']['Name'];
            if (element.hasOwnProperty('ReferentialConstraint')) {
                const referelcontraintName = element['ReferentialConstraint'][0]['$']['Property'].toLowerCase().toString();
                if (key && referelcontraintName === key.toLowerCase().toString()) {
                    const checkNull = DataObject[navProperiperityName];
                    if (checkNull) {
                        entityObject = DataObject[navProperiperityName]['Name'] ? DataObject[navProperiperityName]['Name'] :
                            DataObject[navProperiperityName]['Code'];

                        const OdataKey = ViewModelEntityColl[0]['$']['Name'].toLowerCase().toString();
                        const mergedValue = GlobalCustomFormAction(
                            referelcontraintName, OdataKey, referelcontraintName, 'GridAction',
                            ActionTypes.CUSTOMMERGE, null, null, null, null, null, null, DataObject,
                            null, null, null);
                        if (mergedValue && mergedValue !== undefined && mergedValue !== null) {
                            entityObject = mergedValue;
                        }
                    }

                }
            }
        });
    }
    return entityObject;
}

/**
    *@method GetMasterJsonData()
    *@params jsonData and odatakey MasterData['default']
    *@description getjsonresponseform the data
    * */
function GetMasterJsonData(MasterJson: MasterInventoryManagement[], key: string): MasterInventoryManagement {
    try {
        let masterJsonData = [];
        const ReturnMasterData = MasterJson.filter((MasterDatav: MasterInventoryManagement) => MasterDatav.Key === key);
        if (ReturnMasterData && ReturnMasterData.length > 0) {
            masterJsonData = ReturnMasterData;
        }
        return masterJsonData[0];
    } catch (error) {
        console.error(error);
    }
}


function NavigationProperty(ViewModelEntity: any, MasterDataCollection: MasterInventoryManagement,
    AllViewModelEntity: any, ParentEnitityName: any) {
    try {
        if (ViewModelEntity && ViewModelEntity.length > 0) {
            if (ViewModelEntity[0]['NavigationProperty'] && ViewModelEntity[0]['NavigationProperty'].length > 0) {
                const navigationPrperity = [];
                ViewModelEntity[0]['NavigationProperty'].forEach(element => {
                    const navigationName = element['$'].Name;
                    const referentialRelationId = element['ReferentialConstraint'] && element['ReferentialConstraint'][0]['$'].Property
                        ? element['ReferentialConstraint'][0]['$'].Property : '';
                    if (ViewModelEntity[0]['Property'] && ViewModelEntity[0]['Property'].length > 0) {
                        const properityIndex = ViewModelEntity[0]['Property']
                            .findIndex(x => x['$'].Name === referentialRelationId);
                        if (properityIndex > -1) {
                            let expandQuery = '($select=id,name)';
                            if (MasterDataCollection && MasterDataCollection.GridConfig.GridCustomExpand &&
                                MasterDataCollection.GridConfig.GridCustomExpand.length > 0) {
                                MasterDataCollection.GridConfig.GridCustomExpand.forEach((GridCustomExpandKey: GridCustomColExpand) => {
                                    if (GridCustomExpandKey && GridCustomExpandKey.IsCustomExpand && GridCustomExpandKey.IsCustomExpand
                                        && GridCustomExpandKey.PrimaryExpand === navigationName) {
                                        const expandCode = GridCustomExpandKey.SelectQuery;
                                        expandQuery = `($select=id,${expandCode})`;
                                    }
                                });
                            }
                            const properityString = `${navigationName}` + `${expandQuery}`;
                            navigationPrperity.push(properityString);
                        } else {
                            // Added for supplier supplier bids name change
                            const properityString = navigationName === 'SupplierBids' ? 'IngredientsBids' : navigationName;
                            if (MasterDataCollection.NestedFormConfig.EditFormConfig &&
                                MasterDataCollection.NestedFormConfig.EditFormConfig.findIndex(x => x.OdataKey === navigationName) > -1) {
                                const ChildNavigation = [];
                                if (AllViewModelEntity.filter(view => view['$']['Name'] === properityString).length > 0) {
                                    const ChildNavigationProperty = AllViewModelEntity.
                                        filter(view => view['$']['Name'] === properityString)[0];
                                    ChildNavigationProperty['NavigationProperty'].forEach(elements => {
                                        if (elements['$'].Name !== ParentEnitityName) {
                                            let ChildNavigationPropertys = `${elements['$'].Name}($select=id,name)`;
                                            if (MasterDataCollection && MasterDataCollection.GridConfig.GridCustomExpand &&
                                                MasterDataCollection.GridConfig.GridCustomExpand.length > 0) {
                                                MasterDataCollection.GridConfig.GridCustomExpand.forEach((GridCustomExpandKey:
                                                    GridCustomColExpand) => {
                                                    if (GridCustomExpandKey && GridCustomExpandKey.IsCustomExpand &&
                                                        GridCustomExpandKey.IsCustomExpand
                                                        && GridCustomExpandKey.PrimaryExpand === elements['$'].Name) {
                                                        const expandCode = GridCustomExpandKey.SelectQuery;
                                                        ChildNavigationPropertys = `${elements['$'].Name}($select=id,${expandCode})`;
                                                    }
                                                });
                                            }
                                            ChildNavigation.push(ChildNavigationPropertys);
                                        }
                                    });
                                }
                                const data = navigationName + '($expand=' + ChildNavigation.join() + ')';
                                // properityString = properityString + '($expand=' + ChildNavigation.join() + ')';
                                navigationPrperity.push(data);
                            }
                        }
                    }
                });
                return navigationPrperity;
            }
        }
    } catch (error) {
        console.log('error occured NavigationProperty', error);
    }
}

function formQueryParams(params: QueryParams) {
    params.filter = '';
    const keys = Object.keys(params);
    let query = '';
    keys.forEach((data, index) => {
        if (data !== 'isactive' && data !== 'search' && data !== 'searchtext' && data !== 'filterQuery') {
            if (params[data] !== '' && data !== 'filter') {
                if (index === 0) {
                    query = query + '?$';
                } else {
                    if (query.length > 0) {
                        query = query + '&$';
                    } else {
                        query = query + '?$';
                    }
                }
                query = query + data + '=' + params[data];
            } else if (data === 'filter') {
                if (params.isactive) {
                    params[data] = 'IsActive eq true';
                }
                if (params.searchtext && params.searchtext.length > 0 && params.search) {
                    if (params[data].length > 0) {
                        params[data] = params[data] + ' and ' + params.search;
                    } else {
                        params[data] = params.search;
                    }
                }
                if (params.filterQuery && params.filterQuery.length > 0) {
                    if (params[data].length > 0) {
                        params[data] = params[data] + ' and ' + params.filterQuery;
                    } else {
                        params[data] = params.filterQuery;
                    }
                }

                if (params[data] !== '') {
                    if (index === 0) {
                        query = query + '?$';
                    } else {
                        query = query + '&$';
                    }
                    query = query + data + '=' + params[data];
                }
            }
        }
    });
    return query;
}

function AssignGridData(dataObject, SubForm: SubFormConfigs, ViewModelEntity,
    translateService: TranslateService, ChildNavigationProperty: any, httpService?: HttpService): any {
    try {
        const EnitityName = ViewModelEntity[0]['$']['Name'].toString();
        const productremapJson = JSON.parse(sessionStorage.getItem('ProductRemap'));
        const gridData = {} as GridConfig;
        gridData['GridFilter'] = SubForm.NestedGridData.GridFilter;
        gridData['GridSearch'] = SubForm.NestedGridData.GridSearch;
        gridData['GridSort'] = SubForm.NestedGridData.GridSort;
        if (dataObject && dataObject[0]) {
            if (SubForm.Name === 'Transactions') {
                gridData.GridData = assignRowData(dataObject, ChildNavigationProperty);
            } else if (SubForm.Name === 'CrossReference') {
                gridData.GridData = assignRowData(dataObject, ChildNavigationProperty);
            } else if (SubForm.Name === 'History') {
                gridData.GridData = dataObject;
            } else if (SubForm.Name === 'ItemSales') {
                gridData.GridData = dataObject;
            } else if (SubForm.Name === 'Activity') {
                dataObject.forEach(element => {
                    if (element.LocationId && element.LocationId > 0) {
                        element.Locations = element.Locations ? element.Locations.Name : '';
                    } else {
                        element.Locations = '';
                    }
                    if (element.UnitId && element.UnitId > 0) {
                        element.UnitDetail = element.UnitDetail ? element.UnitDetail.Name : '';
                    } else {
                        element.UnitDetail = '';
                    }
                }); gridData.GridData = dataObject;
            } else if (SubForm.OdataKey === 'ProductItemDetails') {
                gridData.GridData = assignProductItemDetails(dataObject[0]['ProductItemDetails']);
            } else {
                gridData.GridData = assignRowData(dataObject[0][SubForm.OdataKey], ChildNavigationProperty,
                    SubForm.NestedGridData.GridHeaderDefination);
                if (SubForm.Name !== 'SupplierBids') {
                    gridData.GridData = gridData.GridData.filter(x => x.IsActive === true);
                }

            }
        } else {
            gridData.GridData = [];
        }
        if (SubForm.data) {
            SubForm.data.forEach(data => {
                data.NestedData.forEach(formdata => {
                    if (formdata.Calculation) {
                        gridData.GridData.forEach(rowData => {
                            const returnValue = calculationOperation(formdata.Calculation, rowData);
                            rowData[formdata.OdataKey] = returnValue;
                            // rowData[formdata.OdataKey] = Formater(formdata.OdataKey, returnValue.toString(), null, ViewModelEntity);
                        });
                    }
                });
            });
        }
        let headerDefs = [];
        if (SubForm.NestedGridData) {
            const DetailName = ChildNavigationProperty ? ChildNavigationProperty['$']['Name'].toString() : '';
            const SubFormName = SubForm.Name === 'Transactions' || SubForm.Name === 'CrossReference' ? DetailName : SubForm.Name;
            headerDefs = SubForm.NestedGridData.GridHeaderDefination.map(values => {
                values.sortDirection = '';
                if (SubForm.NestedGridData.GridSort && SubForm.NestedGridData.GridSort.length > 0) {
                    if (SubForm.NestedGridData.GridSort[0].SortingOdataKey === values.field &&
                        SubForm.NestedGridData.GridSort[0].DefaultSorting) {
                        values.sortDirection = SubForm.NestedGridData.GridSort[0].SortingType.toLowerCase();
                    }
                }
                const text = SubFormName + '.' + values.field;
                translateService.get(text).subscribe(res => {
                    values.headerName = res;
                });
                values.headerName = ProductDataRemap.FieldDisplayNameEngine(
                    productremapJson, SubForm.OdataKey, values.headerName);

                if (values.type === 'dateColumn') {
                    values.valueFormatter = Formaters.dateformater;
                } else if (values.columnType === 'decimal') {
                    values.valueFormatter = Formaters.decimalformater;
                } else if (values.columnType === 'currency') {
                    values.valueFormatter = Formaters.currencyFormater;
                }
                if (values.cellRenderer !== 'actionRenderer') {
                    values.resizable = true;
                    values.minWidth = '75';
                    values.tooltipField = values.field;
                }
                const columnHide = ProductDataRemap.FieldVisibleRemapEngine(
                    productremapJson, SubForm.OdataKey, values.field, values.hide);
                values.hide = !columnHide;
                return values;
            });
        }
        gridData.GridHeaderDefination = headerDefs;
        gridData.TotalRecords = gridData.GridData ? gridData.GridData.length : 0;
        gridData.GridActions = {} as GridActionsConfig;
        const GridTittleName = SubForm.DisplayName;
        gridData.GridActions.GridTitle = ProductDataRemap.FieldDisplayNameEngine(
            productremapJson, SubForm.OdataKey, SubForm.DisplayName, `GridTittle-${GridTittleName}`, translateService);
        if (SubForm.NestedGridData && SubForm.NestedGridData.GridTitleType) {
            gridData.GridActions.GridTitleType = {} as TitleType;
            gridData.GridActions.GridTitleType.Type = SubForm.NestedGridData.GridTitleType.Type;
            gridData.GridActions.GridTitleType.TypeConfig = SubForm.NestedGridData.GridTitleType.TypeConfig;
            gridData.GridActions.GridTitleType.SelectedTypeId = SubForm.NestedGridData.GridTitleType.SelectedTypeId;
        } else if (SubForm.NestedGridData.GridActions.GridTitleType) {
            gridData.GridActions.GridTitleType = SubForm.NestedGridData.GridActions.GridTitleType;
            gridData.GridActions.GridTitleType.SelectedTypeId = SubForm.NestedGridData.GridActions.GridTitleType.SelectedTypeId;
        }
        gridData.GridActions.TotalItems = gridData.GridData ? gridData.GridData.length : 0;
        gridData.GridActions.ShowTextWithDropdown = SubForm.NestedGridData.ShowTextWithDropdown !== undefined ?
            SubForm.NestedGridData.ShowTextWithDropdown : (SubForm.NestedGridData.GridActions && SubForm.NestedGridData.
                GridActions.ShowTextWithDropdown ? SubForm.NestedGridData.GridActions.ShowTextWithDropdown : false);
        gridData.GridActions.InfiniteScrollConfig = {} as GridInfiniteScrollConfig;
        gridData.GridActions.InfiniteScrollConfig.EnableInfiniteScroll = false;
        gridData.GridActions.ResizeRowsConfig = {} as GridResizeRows;
        gridData.GridActions.ResizeRowsConfig.EnableResizeRows = false;
        gridData.GridActions.SearchConfig = {} as GridSearchConfig;
        gridData.GridActions.SearchConfig.EnabledSearch = SubForm.NestedGridData.GridSearch &&
            SubForm.NestedGridData.GridSearch.length > 0 ? true : (SubForm.NestedGridData.GridActions && SubForm.NestedGridData.
                GridActions.SearchConfig ? SubForm.NestedGridData.GridActions.SearchConfig.EnabledSearch : false);
        if (SubForm.Name === 'Wizard' || SubForm.Name === 'Pricing') {
            gridData.GridActions.SearchConfig.EnabledSearch = false;
        }
        if (gridData.GridActions.SearchConfig.EnabledSearch) {
            gridData.GridActions.SearchConfig.SearchPlaceholder = SubForm.NestedGridData.GridSearch &&
                SubForm.NestedGridData.GridSearch.length > 0 ?
                SubForm.NestedGridData.GridSearch[0].SearchPlaceholderName : SubForm.NestedGridData.GridActions.SearchConfig.SearchKey;
            gridData.GridActions.SearchConfig.SearchKey = SubForm.NestedGridData.GridSearch &&
                SubForm.NestedGridData.GridSearch.length > 0 ?
                SubForm.NestedGridData.GridSearch[0].SearchKey : SubForm.NestedGridData.GridActions.SearchConfig.SearchKey;
            gridData.GridActions.SearchConfig.SearchPlaceholder = ProductDataRemap.FieldDisplayNameEngine(
                productremapJson, SubForm.OdataKey, `Search ${gridData.GridActions.SearchConfig.SearchPlaceholder}`,
                `Search ${gridData.GridActions.SearchConfig.SearchPlaceholder}`, translateService);
        }
        gridData.GridActions.FilterConfig = {} as GridFilter;
        gridData.GridActions.FilterConfig.EnableFilter = SubForm.NestedGridData.GridFilter &&
            SubForm.NestedGridData.GridFilter.length > 0 ? true : (SubForm.NestedGridData.GridActions && SubForm.NestedGridData.
                GridActions.FilterConfig ? SubForm.NestedGridData.GridActions.FilterConfig.EnableFilter : false);
        if (gridData.GridActions.FilterConfig.EnableFilter) {
            getFilterOptions(SubForm.NestedGridData, ChildNavigationProperty,
                httpService).then(x => {
                    gridData.GridActions.FilterConfig.FilterOptions = x;
                    gridData.GridActions.FilterConfig.EnableFilter = x && x.length > 0 ? true : false;
                });
        } else {
            gridData.GridActions.FilterConfig.FilterOptions = [];
        }
        gridData.GridActions.PaginationConfig = {} as GridPaginationConfig;
        gridData.GridActions.PaginationConfig.EnabledPaginationation = false;
        gridData.GridActions.ShuffleColumnConfig = {} as GridColumnReorder;
        gridData.GridActions.ShuffleColumnConfig.EnableColumnShuffle = false;
        gridData.dropdownChange = SubForm.NestedGridData.dropdownChange;
        gridData.searchTextChanged = SubForm.NestedGridData.searchTextChanged;
        gridData.filterApplied = SubForm.NestedGridData.filterApplied;
        gridData.rowClicked = SubForm.NestedGridData.rowClicked;
        gridData.rowNameInfoClicked = SubForm.NestedGridData.rowNameInfoClicked;
        gridData.sortChanged = SubForm.NestedGridData.sortChanged;
        return gridData;
    } catch (error) {
        console.error('Error occurred in AssignGridData', error);
    }
}

function assignRowData(ResponseData, ViewModelEntity, GridHeaderDefinition?: Array<GridHeaderConfig>) {
    try {
        if (ResponseData) {
            ResponseData.forEach(dataobject => {
                for (const prop in dataobject) {
                    if (prop) {
                        // dataobject[prop] = Formater(prop, dataobject[prop], null, ViewModelEntity);
                        if (dataobject[prop] && dataobject[prop] !== null) {
                            const propType = typeof dataobject[prop];
                            const isArrayprop = Array.isArray(dataobject[prop]);
                            if (propType === 'object' && !isArrayprop) {
                                assignGridNavigationProperity(prop, dataobject[prop], dataobject, ViewModelEntity);
                            } else if (isArrayprop) {
                                assignGridNavigationArrayProperty(prop, dataobject[prop], dataobject, ViewModelEntity,
                                    GridHeaderDefinition);
                            }
                        }
                    }
                }
            });
            return ResponseData;
        } else {
            return [];
        }
    } catch (error) {
        console.log('error occured assignRowData', error);
    }
}

function assignGridNavigationArrayProperty(ProperityName: any, ProperirtyObject: Array<any>,
    PrimaryProperity: object, ViewModelEntityColl: any, GridHeaderDefinition?: Array<GridHeaderConfig>) {
    try {
        const entityObject = PrimaryProperity;
        const MultiArrayGridHeader = GridHeaderDefinition.filter(x => x['cellEditorParams'] &&
            x['cellEditorParams'].UpdatedProperty === ProperityName);
        if (MultiArrayGridHeader.length > 0) {
            const GridViewData = [];
            const GridPropertyName = MultiArrayGridHeader[0].field;
            ProperirtyObject.forEach(data => {
                let GridValue: any;
                if (MultiArrayGridHeader[0]['cellEditorParams'].MultiGridValue) {
                    MultiArrayGridHeader[0]['cellEditorParams'].MultiGridValue.forEach((element, index) => {
                        data = data[element];
                        if (index === (MultiArrayGridHeader[0]['cellEditorParams'].MultiGridValue.length - 1)) {
                            GridValue = data;
                        }
                    });
                    GridViewData.push(GridValue);
                } else {
                    GridViewData.push(data);
                }
            });
            entityObject[GridPropertyName] = GridViewData.join(', ');
        } else {
            entityObject[ProperityName] = ProperirtyObject.join(', ');
        }
        return entityObject;
    } catch (error) {
        console.error('Error occurred in assignGridNavigationArrayProperty', error);
    }
}

function assignGridNavigationProperity(ProperityName: any, ProperirtyObject: object, PrimaryProperity: object, ViewModelEntityColl: any) {
    const entityObject = PrimaryProperity;
    if (
        ViewModelEntityColl &&
        ViewModelEntityColl.hasOwnProperty('NavigationProperty') &&
        ViewModelEntityColl['NavigationProperty'].length > 0) {
        ViewModelEntityColl['NavigationProperty'].forEach((element: any) => {
            const navProperiperityName = element['$']['Name'].toLowerCase().toString();
            if (navProperiperityName.toLowerCase() === ProperityName.toLowerCase()) {
                //  if (element['ReferentialConstraint']) {
                //     const referelcontraintName = element['ReferentialConstraint'][0]['$']['Property'].toString();
                if (navProperiperityName.toLowerCase() !== 'ingredientsbarcodes') {
                    entityObject[ProperityName] = ProperirtyObject['Name'];
                } else {
                    entityObject[ProperityName] = ProperirtyObject && ProperirtyObject[0] ? ProperirtyObject[0].Barcode : '-';
                }
                //  }
            }
        });
    }
    return entityObject;
}


function calculationOperation(calcField: CalcultionField, FormData: any) {
    try {
        let returnValue = 0;
        let FirstOperand = isNaN(parseFloat(FormData[calcField.OperandFields[0].OdataKey]))
            ? 0 : parseFloat(FormData[calcField.OperandFields[0].OdataKey]);
        let SecondOperand = isNaN(parseFloat(FormData[calcField.OperandFields[1].OdataKey]))
            ? 0 : parseFloat(FormData[calcField.OperandFields[1].OdataKey]);
        if (isNaN(FirstOperand)) {
            FirstOperand = 0;
        }
        if (isNaN(SecondOperand)) {
            SecondOperand = 0;
        }
        switch (calcField.OperationType) {
            case 'Add':
                returnValue = FirstOperand + SecondOperand;
                break;
            case 'Multiply':
                returnValue = FirstOperand * SecondOperand;
                break;
            case 'Divide':
                if (FirstOperand !== 0 && SecondOperand !== 0) {
                    returnValue = FirstOperand / SecondOperand;
                    if (isNaN(returnValue)) {
                        returnValue = 0;
                    }
                }
                break;
        }
        return returnValue;
    } catch (error) {
        console.error('Error occurred in calculationOperation', error);
    }
}

function getFilterOptions(MasterDataCollection, ViewModelEntity, httpService): any {
    // eslint-disable-next-line @typescript-eslint/no-unused-expressions
    return new Promise<any>((resolve, reject) => {
        const GridFilterRowData = [];
        MasterDataCollection.GridFilter.forEach((GridFilterDataObj: GridFilterAction) => {
            const navigationProp = {} as CustomFilterNavigationUrl;
            let originalkeyname = '';
            const Navigation = ViewModelEntity['NavigationProperty']
                .filter((NavProperity: Array<any>) => NavProperity['$']['Name'] === GridFilterDataObj.FilterOdataKey);
            if (Navigation.length > 0) {
                // eslint-disable-next-line @typescript-eslint/no-shadow
                ViewModelEntity['NavigationProperty'].forEach((NavigationProperty: Array<any>) => {
                    const NavigationProperityName = NavigationProperty['$']['Name'];
                    if (NavigationProperityName === GridFilterDataObj.FilterOdataKey) {
                        let NavigationReferentialConstraint = '';
                        if (NavigationProperty && NavigationProperty['ReferentialConstraint']) {
                            NavigationReferentialConstraint = NavigationProperty['ReferentialConstraint'][0]['$']['ReferencedProperty'];
                            originalkeyname = NavigationProperty['ReferentialConstraint'][0]['$']['Property'];
                        }
                        const EntityNameList = NavigationProperty['$']['Type'].split('.');
                        let EntityName = '';
                        if (EntityNameList.length > 0) {
                            EntityName = EntityNameList[EntityNameList.length - 1];
                            navigationProp.RequestUrl = `/inventory/api/${EntityName}`;
                            navigationProp.NavigationContraintName = NavigationReferentialConstraint;
                            navigationProp.EnabledCustomField = false;
                        }
                    }
                });
            } else {
                if (GridFilterDataObj && GridFilterDataObj.hasOwnProperty('CustomFilters') &&
                    GridFilterDataObj.CustomFilters.EnableCustomFilter) {
                    navigationProp.RequestUrl = GridFilterDataObj.CustomFilters.CustomNavigationUrl;
                    navigationProp.EnabledCustomField = true;
                    navigationProp.NavigationContraintName = GridFilterDataObj.FilterOdataKey;
                }
            }
            if (navigationProp && navigationProp.RequestUrl !== null) {
                httpService.GethttpMethod(navigationProp.RequestUrl).subscribe((response: any) => {
                    if (response && response.value && response.value.length > 0) {
                        const filterKeyData = response.value.map((data: any) => {
                            const filterKey = {} as GridFilterValue;
                            filterKey.FilterId = GridInterface.GridFilterKeyMapping(GridFilterDataObj, data);
                            filterKey.FilterName = GridInterface.GridFilterLabelMapping(GridFilterDataObj, data);
                            filterKey.Checked = false;
                            return filterKey;
                        });
                        const filterDisplay = {} as GridFilterData;
                        filterDisplay.FilterKey = GridFilterDataObj.FilterDisplayName;
                        filterDisplay.FilterValue = filterKeyData;
                        filterDisplay.FilterOriginalKey = originalkeyname;
                        filterDisplay.FilterNavigationKey = navigationProp.NavigationContraintName;
                        filterDisplay.Type = 'multiselect';
                        GridFilterRowData.push(filterDisplay);
                    }
                    resolve(GridFilterRowData);
                },
                    (responseError) => {
                        console.error('error caught in component', responseError);
                    });
            }
        });
    });
}


function assignProductItemDetails(responseData) {
    try {
        responseData.forEach(element => {
            element.UnitCode = element.ProductUnits ? element.ProductUnits.Name + ` (${element.ProductUnits.Code})` : '';
            element.UnitCost = (element.Price && element.Price > 0 && element.Qty && element.Qty > 0) ? element.Price / element.Qty : 0;
            element.Name = element.ItemName;
        });
        return responseData;
    } catch (error) {
        console.error('error occured in assignProductItemDetails', error);
    }
}