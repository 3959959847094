import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { UntypedFormArray, UntypedFormBuilder, UntypedFormGroup,Validators } from '@angular/forms';
import { AgDropdownConfig, ButtonValue } from "src/app/common/Models/ag-models";
import { Localization } from "src/app/common/localization/localization";
import { takeUntil,debounceTime } from 'rxjs/operators';
import { ReplaySubject } from 'rxjs';
import { AlertAction, AlertType, DiscountMapping, DiscountMappingsSections, DiscountMappingType } from '../shared/shared.modal';
import { ButtonTypes } from '../payment-manager/shared/shared-model';
import { CommonUtilities } from 'src/app/common/shared/shared/utilities/common-utilities';
import { OtherComponentsDiscountMappingBusiness } from './other-components-discount-mapping.business';
import { TierLevelDataService } from '../retail-code-setup/retail-tier-level/retail-tier-level.data.service';
import { uniqueFormGroupValidator } from '../shared/Validators/GuidValidator';
import { cloneDeep } from 'lodash';


@Component({
  selector: 'app-other-components-discount-mapping',
  templateUrl: './other-components-discount-mapping.component.html',
  styleUrls: ['./other-components-discount-mapping.component.scss'],
  providers: [OtherComponentsDiscountMappingBusiness,TierLevelDataService],
  encapsulation: ViewEncapsulation.None
})
export class OtherComponentsDiscountMappingComponent implements OnInit {

  constructor(  private localization: Localization,private fb: UntypedFormBuilder,private utilities: CommonUtilities,
    private business: OtherComponentsDiscountMappingBusiness) {
    this.captions = this.localization.captions.retailsetup.DiscountMapping;
    this.floatLabel = this.localization.setFloatLabel;
  }
  captions: any;
  discountMappingForm: UntypedFormGroup;
  actionButton: ButtonValue;
  cancelButton: ButtonValue;
  destroyed$: ReplaySubject<boolean> = new ReplaySubject(1);
  mappingTypes = [];
  playerTypes= [];
  playerTypeConfig: AgDropdownConfig;
  floatLabel: string;
  discountMappingType = DiscountMappingType;
  playerTypeOptions = [];
  playerTypeOptionsClone = [];
  vipTypeOptions = [];
  vipTypeOptionsClone = [];
  guestTypeOptions = [];
  guestTypeOptionsClone = [];
  tierLevelOptions = [];
  tierLevelOptionsClone =[];
  discountTypeOptions = [];
  discountTypeOptionsClone = [];
  discountTypeLinking = [];

  ngOnInit(): void {
    this.initializeForm();
    this.getFormData();
    this.discountMappingForm.valueChanges.pipe(debounceTime(300),takeUntil(this.destroyed$)).subscribe(val => {
      this.updateValidators();
      this.updateActionButtonsState();
    });
  }

  initializeForm() {
    this.mappingTypes = this.business.getmappingTypes();
    this.discountMappingForm = this.fb.group({
      mappingFormArray: this.fb.array(
        [
          this.addGroup()
        ],
        { validators: uniqueFormGroupValidator() } // Validate duplicate groups
      ) as UntypedFormArray
    });
    this.actionButton = {
      type: "primary",
      label: this.captions.btn_save,
      disabledproperty: true,
    };
    this.cancelButton = {
      type: "secondary",
      label: this.captions.btn_cancel,
      disabledproperty: true,
    };
  }

  async getFormData() {
    const [
      playerTypeOptions,
      vipTypeOptions,
      guestTypeOptions,
      tierLevelOptions,
      discountTypeOptions,
      discountTypeLinking
    ] = await Promise.all([
      this.business.getPlayerTypes(),
      this.business.getVIPTypes(),
      this.business.getGuestypes(),
      this.business.getTierLevel(),
      this.business.getDiscountTypes(),
      this.business.getDiscountTypeLinking()
    ]);


    this.playerTypeOptions = playerTypeOptions;
    this.vipTypeOptions = vipTypeOptions;
    this.guestTypeOptions = guestTypeOptions;
    this.tierLevelOptions = tierLevelOptions;
    this.discountTypeOptions = discountTypeOptions;
    this.discountTypeLinking = discountTypeLinking;
    if (this.discountTypeLinking && this.discountTypeLinking.length > 0)
      this.InitialpatchValue();
  }

  InitialpatchValue() {
    const discountTypeLinkingData = cloneDeep(this.discountTypeLinking);
    const masterLinkDatas = discountTypeLinkingData.filter(x => x.recursiveLinkId == 0);
    let formArray = this.discountMappingForm.get('mappingFormArray') as UntypedFormArray;
    formArray.clear();
    masterLinkDatas.forEach(x => {
      let rowData = [];
      rowData.push(x);
      const recursiveData = discountTypeLinkingData.filter(link => link.recursiveLinkId == x.id);
      rowData.push(...recursiveData);
      let playerType = rowData.find(x => x.masterParentTypeId == DiscountMappingsSections.PlayerTypes)?.parentId ?? 0;
      let vipType = rowData.find(x => x.masterParentTypeId == DiscountMappingsSections.VipTypes)?.parentId ?? 0;
      let guestType = rowData.find(x => x.masterParentTypeId == DiscountMappingsSections.GuestTypes)?.parentId ?? 0;
      let tierLevel = rowData.find(x => x.masterParentTypeId == DiscountMappingsSections.TierLevels)?.parentId ?? 0;
      let discountType = rowData.find(x => x.masterChildTypeId == DiscountMappingsSections.DiscountTypes)?.childId ?? 0;
      const mappingTypeData = this.getMappingTypesBasedonData(playerType, vipType, guestType, tierLevel);  
      let rowGroup = this.fb.group({
        playerType: playerType,
        vipType: vipType,
        guestType: guestType,
        tierLevel: tierLevel,
        discountType: discountType,
        selectAll: mappingTypeData.filter(x => x.checked).length == 4,
        mappingList: [mappingTypeData],
        id:x.id,
      })
      
      formArray.push(rowGroup);
     
    });
    formArray.setValidators(uniqueFormGroupValidator());
    formArray.updateValueAndValidity();
  }

  getMappingTypesBasedonData(playerType, vipType, guestType, tierLevel) {
    let mappingTypeData = cloneDeep(this.mappingTypes);
    if (playerType > 0 && mappingTypeData.find(x => x.id == this.discountMappingType.playerType))
      mappingTypeData.find(x => x.id == this.discountMappingType.playerType).checked = true;
    if (vipType > 0)
      mappingTypeData.find(x => x.id == this.discountMappingType.vipType).checked = true;
    if (guestType > 0)
      mappingTypeData.find(x => x.id == this.discountMappingType.guestType).checked = true;
    if (tierLevel > 0)
      mappingTypeData.find(x => x.id == this.discountMappingType.tierLevel).checked = true;
    return mappingTypeData;
  }

  addRow(){
   let formArray = this.discountMappingForm.get('mappingFormArray') as UntypedFormArray;
   formArray.push(this.addGroup());
  }

  addGroup() {
    return this.fb.group({
      playerType: '',
      vipType:'',
      guestType:'',
      tierLevel: '',
      discountType: [],
      selectAll:false,
      mappingList: [this.business.getmappingTypes()],
    })
  }

  deleteRow(index){
    let formArray = this.discountMappingForm.get('mappingFormArray') as UntypedFormArray; 
    formArray.removeAt(index);
    this.discountMappingForm.markAsDirty();
    this.discountMappingForm.updateValueAndValidity();
  }

  resetRow(index){
    this.discountMappingForm.get('mappingFormArray')['controls'][index].reset({ playerType: '', vipType: '', guestType: '', tierLevel: '', discountType: '' });
    this.discountMappingForm.get('mappingFormArray')['controls'][index].controls.mappingList.setValue(this.business.getmappingTypes());
  }

  copyRow(index){
    let copyData = this.discountMappingForm.get('mappingFormArray')['controls'][index] as UntypedFormArray;
    let array = this.addGroup();
    array.patchValue(copyData.value);
    this.discountMappingForm.get('mappingFormArray')['controls'].push(cloneDeep(array));
    this.discountMappingForm.get('mappingFormArray')['controls'] = [...this.discountMappingForm.get('mappingFormArray')['controls']]
    this.discountMappingForm.markAsDirty();
    this.discountMappingForm.updateValueAndValidity();
  }

  selectMappingType(eve,item,index){
    item.checked = eve.checked;
    let formArray = this.discountMappingForm.get('mappingFormArray')['controls'][index] as UntypedFormArray;
    const checkboxes = formArray.get('mappingList').value;
    const allSelected = checkboxes.every((value) => value.checked);
    formArray.get('selectAll')?.setValue(allSelected, { emitEvent: false });
  }
  selectAll(e,index){
    let formArray = this.discountMappingForm.get('mappingFormArray')['controls'][index] as UntypedFormArray;
    const checkboxes = formArray.get('mappingList').value;
    checkboxes.forEach((control) => control.checked = e.checked);
  }

  checkSelectedType(index,type){
    let formArray = this.discountMappingForm.get('mappingFormArray')['controls'][index];
    let isSelected = formArray.controls.mappingList.value?.find(x=> (x.id == type))?.checked;
    return isSelected;
  }

  async onAction(e) {

    //Master section ids
    let masterPlayerTypeId = DiscountMappingsSections.PlayerTypes;
    let masterVipTypeId = DiscountMappingsSections.VipTypes;
    let masterGuestTypeId = DiscountMappingsSections.GuestTypes;
    let masterTierLevelId = DiscountMappingsSections.TierLevels;
    let masterDiscountTypeId = DiscountMappingsSections.DiscountTypes;

    let formArray = this.discountMappingForm.get('mappingFormArray') as UntypedFormArray;
    let listofAPIData: DiscountMapping[][] = [];

    formArray.value.forEach(x => {
      let APIData: DiscountMapping[] = [];
      let selectedPlayertypeId: number = x.playerType ? x.playerType : 0;
      let selectedVipTypeId: number = x.vipType ? x.vipType : 0;
      let selectedGuestTypeId: number = x.guestType ? x.guestType : 0;
      let selectedTierLevelId: number = x.tierLevel ? x.tierLevel : 0;
      let selectedDiscountTypeId: number = x.discountType ? x.discountType : 0;
      APIData.push(
        {
          id: 0,
          parentId: selectedPlayertypeId,
          childId: selectedVipTypeId,
          masterParentTypeId: masterPlayerTypeId,
          masterChildTypeId: masterVipTypeId,
          recursiveLinkId: 0
        });

      APIData.push(
        {
          id: 0,
          parentId: selectedVipTypeId,
          childId: selectedGuestTypeId,
          masterParentTypeId: masterVipTypeId,
          masterChildTypeId: masterGuestTypeId,
          recursiveLinkId: 1
        });

      APIData.push(
        {
          id: 0,
          parentId: selectedGuestTypeId,
          childId: selectedTierLevelId,
          masterParentTypeId: masterGuestTypeId,
          masterChildTypeId: masterTierLevelId,
          recursiveLinkId: 1
        });

      APIData.push(
        {
          id: 0,
          parentId: selectedTierLevelId,
          childId: selectedDiscountTypeId,
          masterParentTypeId: masterTierLevelId,
          masterChildTypeId: masterDiscountTypeId,
          recursiveLinkId: 1
        }
      )
      listofAPIData.push(APIData)
      
    }
    );
    this.utilities.ToggleLoader(true);
    await this.business.CreateBulkDiscountTypeLinking(listofAPIData);
    this.discountTypeLinking = await this.business.getDiscountTypeLinking();
    if (this.discountTypeLinking && this.discountTypeLinking.length > 0) {
      this.InitialpatchValue();
    }
    this.utilities.ToggleLoader(false);
    this.discountMappingForm.markAsPristine();
      
  }


  onCancel(e) {
    this.utilities.showCommonAlert(this.captions.warn_datalostChanges, AlertType.Warning, ButtonTypes.YesNo,
      (res) => {
        if (res === AlertAction.YES) {          
        }
      });
  }

  updateValidators() {
    const mappingFormArray = this.discountMappingForm.get('mappingFormArray') as UntypedFormArray;

    mappingFormArray.controls.forEach((group: UntypedFormGroup, i: number) => {
      const playerTypeControl = group.get('playerType');
      const vipTypeControl = group.get('vipType');
      const guestTypeControl = group.get('guestType');
      const tierLevelControl = group.get('tierLevel');
      const discountTypeControl = group.get('discountType');
      const mappingListControl = group.get('mappingList');

      // Update validators based on mapping type selections
      if (this.checkSelectedType(i, this.discountMappingType.playerType)) {
        playerTypeControl?.setValidators(Validators.required);
      } else {
        playerTypeControl?.clearValidators();
      }

      if (this.checkSelectedType(i, this.discountMappingType.vipType)) {
        vipTypeControl?.setValidators(Validators.required);
      } else {
        vipTypeControl?.clearValidators();
      }

      if (this.checkSelectedType(i, this.discountMappingType.guestType)) {
        guestTypeControl?.setValidators(Validators.required);
      } else {
        guestTypeControl?.clearValidators();
      }

      if (this.checkSelectedType(i, this.discountMappingType.tierLevel)) {
        tierLevelControl?.setValidators(Validators.required);
      } else {
        tierLevelControl?.clearValidators();
      }

      // Discount type is always required
      discountTypeControl?.setValidators(Validators.required);

      // Update validity for all controls
      playerTypeControl?.updateValueAndValidity({ emitEvent: false });
      vipTypeControl?.updateValueAndValidity({ emitEvent: false });
      guestTypeControl?.updateValueAndValidity({ emitEvent: false });
      tierLevelControl?.updateValueAndValidity({ emitEvent: false });
      discountTypeControl?.updateValueAndValidity({ emitEvent: false });

      // Explicitly update mapping list validity
      mappingListControl?.updateValueAndValidity({ emitEvent: false });
    });

    // Trigger update for parent array
    mappingFormArray.updateValueAndValidity();
  }

  updateActionButtonsState() {
    const isValidAndDirty = this.discountMappingForm.valid && this.discountMappingForm.dirty;
    this.actionButton = { ...this.actionButton, disabledproperty: !isValidAndDirty };
    this.cancelButton = { ...this.cancelButton, disabledproperty: !isValidAndDirty };
  }
}
