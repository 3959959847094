import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { UntypedFormGroup, UntypedFormControl, Validators } from '@angular/forms';
import { PropertySettingDataService } from '../property-setting.data.service';
import { RetailLocalization } from '../../common/localization/retail-localization';
import { PropertySetting, SystemConfiguration } from '../../retail.modals';
import { RetailPropertyInformation } from '../../common/services/retail-property-information.service';
import { RetailUtilities } from '../../shared/utilities/retail-utilities';
import { MiscellaneousSwitch, Product } from '../../shared/globalsContant';
import { UserAccessBreakPoints } from '../../shared/constants/useraccess.constants';
import { FacadeService } from 'src/app/common/services/facade.service';
import { UserAccessService } from 'src/app/common/services/user-access.service';
import { cloneDeep } from 'lodash';
import { PropertySettingDataService as CommonPropertySettingDataService } from 'src/app/common/dataservices/authentication/propertysetting.data.service';
import { API } from 'src/app/common/Models/property-settings.model';
import { MISCELLANEOUSCONFIGURATION } from 'src/app/common/constants';
import { MiscellaneousConfiguration } from 'src/app/common/shared/shared.modal';
import { AgInputFieldConfig } from 'src/app/common/Models/ag-models';
import * as GlobalConst from '../../shared/globalsContant';
import { RetailFunctionalityBusiness } from '../../shared/business/retail-functionality.business';
import { RetailFeatureFlagInformationService } from '../../shared/service/retail.feature.flag.information.service';

@Component({
  selector: 'app-miscellaneous',
  templateUrl: './miscellaneous.component.html',
  styleUrls: ['./miscellaneous.component.scss'],
  providers: [PropertySettingDataService, FacadeService, UserAccessService, CommonPropertySettingDataService],
  encapsulation: ViewEncapsulation.None
})
export class MiscellaneousComponent implements OnInit {

  miscellaneousFormGrp: UntypedFormGroup;
  enableSave: boolean = false;
  captions: any;
  pmsEndPointConfig: any;
  ShopItemShowCountConfig: any;
  DiscountServiceChargeConfig: SystemConfiguration;
  CashPopUpZeroDollarPopulateConfig: SystemConfiguration;
  ItemNameDeletePopulateConfig: SystemConfiguration;
  DiscountGrtauityConfig: SystemConfiguration;
  allowTaxOnServiceChargeConfig:SystemConfiguration;
  allowTaxOnGratuityConfig:SystemConfiguration;
  enableAutoRetailTransactionLogsConfig:SystemConfiguration;    
  AllowPaymentSurchargesToggleConfig:SystemConfiguration; 
  isRetailStandalone: boolean;
  returnOriginalPayment: any;
  enableRetailConfirmation: any;
  isEmbededRetail = false;
  discountcommentsrequired: any;
  discountreasonrequired: any;
  radioOptions: any;
  enableLoginSelectionvisible: boolean;
  enableTransactionByMachine: any;
  enableSelectionOnLogin: any;
  roomKeySwipeCaptions: any;
  RequestPresettlementReceiptOnPendingSettlementConfig: any;
  isSpaProduct: boolean = false;
  cardSourceTypes: any;
  isSNC: boolean = false;
  EnableRoomKeySwipeWithMSRConfig: SystemConfiguration;
  RoomKeyCardSourceTypeConfig: SystemConfiguration;
  CardDataReadStartPositionConfig: SystemConfiguration;
  CardDataReadEndPositionConfig: SystemConfiguration;
  EnableCheckZoom: SystemConfiguration;
  DefaultGuestCategoryConfig : SystemConfiguration;
  EnableRevenuePostingsOnDayEnd: SystemConfiguration;
  enableAutoCloseTransaction: SystemConfiguration;
  validatePayAgentAndSettlerOnRefund: SystemConfiguration;
  defaultReturnToOriginalSettlement: SystemConfiguration;
  DisplayImagesInShop: SystemConfiguration;
  EnablePostRevenueOnPropertyDate : SystemConfiguration;
  AllowFutureDateConfig: SystemConfiguration;
  ExpandPaymentMethodsConfig: SystemConfiguration;
  InhouseGratuityConfigValue: SystemConfiguration;
  InhouseServiceChargeConfigValue: SystemConfiguration;
  isDisabled: boolean = false;
  isViewOnly: boolean = false;
  printerManagerURI: string;
  machineBasedConfigurationFromTenant: API.MiscellaneousConfigurationSettings<any>;
  showRevenuePostings: boolean = false;
  isRevenuePostingEnabled: boolean = false;
  floatLabel: string;
  InhouseGratuityConfig: AgInputFieldConfig;
  InhouseServiceChargeConfig : AgInputFieldConfig;
  businessUnit: SystemConfiguration;
  operatingUnit: SystemConfiguration;
  showGLPostings: boolean = false;
  requireReturnReasonConfig: SystemConfiguration;
  excludeDiscountOnTaxConfig: SystemConfiguration;
  enableExtendedProfileSearchByDefault: SystemConfiguration;
  functionalities: { [key: string]: boolean } = {};
  showExtendedProfileSearchByDefault : boolean = false;
  isRentalAtOutletLevelConfig: SystemConfiguration;
  showRentalsRetailItemType : boolean = false;
  isRentalEnabledProperty : boolean = false;
  isEngageProperty : boolean = false;
  DefaultGuestCategories : any[];
  enableCashDrawerConfig: SystemConfiguration;
  cashDrawerURIConfig: SystemConfiguration;
  inventoryScannerConfig: SystemConfiguration;
  inventoryScannerOptions: { id: number; value: any; }[];
  isICEnabled: boolean = false;
  textCaptions: any;
  displayItemNumberInShopConfig: SystemConfiguration;
  constructor(private data: PropertySettingDataService, private localization: RetailLocalization,
    private _commonPropertySettingDataService: CommonPropertySettingDataService,    private retailFunc: RetailFunctionalityBusiness,
    private propertyInfo: RetailPropertyInformation, private utils: RetailUtilities, private facadeService: FacadeService,
        public featureFlagInfo: RetailFeatureFlagInformationService) {
      this.localization.SetLocaleBasedProperties();
    this.showRevenuePostings = !this.propertyInfo.UseRetailInterface && this.propertyInfo.HasRevenuePostingEnabled;
    this.isRevenuePostingEnabled = this.propertyInfo.HasRevenuePostingEnabled;
    const pmsSystem = sessionStorage.getItem('pmsSystem');
    this.showRevenuePostings = pmsSystem != null && pmsSystem.toLowerCase() === 'visualone';
    this.showGLPostings = this.propertyInfo.IsGLExtractEnabled;
    this.miscellaneousFormGrp = new UntypedFormGroup({
      retailInterfaceToggle: new UntypedFormControl(false),   // default value set to false
      discountOnServiceChargeToggle: new UntypedFormControl(false),
      discountOnGratuityToggle: new UntypedFormControl(false),
      allowTaxOnServiceChargeToggle: new UntypedFormControl(false),
      allowTaxOnGratuityToggle: new UntypedFormControl(false),
      allowDateToFutureToggle: new UntypedFormControl(false),
      pmsproxyendpoint: new UntypedFormControl(''),
      itemCount: new UntypedFormControl('', Validators.min(1)),
      returnOriginalPaymentOnly: new UntypedFormControl(false),
      enableRetailConfirmationDialog: new UntypedFormControl(false),
      discountreasonrequired: new UntypedFormControl(false),
      discountcommentsrequired: new UntypedFormControl(false),
      discountReasonRadio: new UntypedFormControl(1),
      discountCommentsRadio: new UntypedFormControl(1),
      enableTransactionByMachineName: new UntypedFormControl(false),
      enableSelectionOnLoginIn: new UntypedFormControl(false),
      enableRoomKeySwipeWithMSR: new UntypedFormControl(false),
      roomKeyCardSourceType: new UntypedFormControl(2),
      cardDataReadStartPosition: new UntypedFormControl(0),
      cardDataReadEndPosition: new UntypedFormControl(0),
      enableCheckZoom: new UntypedFormControl(false),
      defaultGuestCategoryConfig : new UntypedFormControl(''),
      expandPaymentMethodsToggle: new UntypedFormControl(false),
      printerManagerURI: new UntypedFormControl(''),
      enableRevenuePostingsOnDayEnd: new UntypedFormControl(false),
      enableAutoCloseTransaction: new UntypedFormControl(false),
      validatePayAgentAndSettlerOnRefund: new UntypedFormControl(false),
      defaultReturnToOriginalSettlement: new UntypedFormControl(false),
      displayImagesInShop: new UntypedFormControl(false),
      enablePostRevenueOnPropertyDate: new UntypedFormControl(false),
      cashPopUpZeroDollarPopulateToggle: new UntypedFormControl(false),
      ItemNameDeletePopulateToggle: new UntypedFormControl(false),
      RequestPresettlementReceiptOnPendingSettlementToggle: new UntypedFormControl(false),
      EnableAutoRetailTransactionLogsToggle: new UntypedFormControl(false),
      AllowPaymentSurchargesToggle: new UntypedFormControl(false),
      defaultRefundToOriginalSettlement: new UntypedFormControl(false),
      inhousegratuity : new UntypedFormControl(false),
      inhouseservicecharge : new UntypedFormControl(false),
      businessUnit : new UntypedFormControl(''),
      operatingUnit : new UntypedFormControl(''),
      requireReturnReason : new UntypedFormControl(true),
      excludeDiscountOnTax: new UntypedFormControl(true),
      enableExtendedProfileSearchByDefault: new UntypedFormControl(false),
      isRentalAtOutletLevel: new UntypedFormControl(false),
      enableCashDrawer:new UntypedFormControl(false),
      cashDrawerURI:new UntypedFormControl(''),
      inventoryScannerConfig: new UntypedFormControl(1),
      displayItemNumberInShop : new UntypedFormControl(false),
    });
    this.captions = this.localization.captions.setting;
    this.floatLabel = this.localization.setFloatLabel;
    this.roomKeySwipeCaptions = this.captions.RoomKeySwipe;
    this.enableLoginSelectionvisible = false;
    this.miscellaneousFormGrp.valueChanges.subscribe(val => {
      if (this.miscellaneousFormGrp.valid && this.miscellaneousFormGrp.dirty)
        this.enableSave = true;
      else
        this.enableSave = false;
    });
    this.radioOptions = [
      {
        id: 1,
        value: this.captions.All
      },
      {
        id: 2,
        value: this.captions.PercentageOnly
      },
      {
        id: 3,
        value: this.captions.FlatAmountOnly
      }];
    this.inventoryScannerOptions = [
      { id: 1, value: this.captions.inventoryScannerPostType_askeverytime },
      { id: 2, value: this.captions.inventoryScannerPostType_override },
      { id: 3, value: this.captions.inventoryScannerPostType_cumulative }
    ]
    this.cardSourceTypes = [
      {
        id: 1,
        value: this.roomKeySwipeCaptions.Track1
      },
      {
        id: 2,
        value: this.roomKeySwipeCaptions.Track2
      }];
    this.DefaultGuestCategories = [
      { id: 1, type: this.localization.captions.shop.Member },
			{ id: 2, type: this.localization.captions.shop.Guest }
    ]; 
  }

  ngOnInit() {
    this.utils.ToggleLoaderWithMessage(true,this.localization.captions.lbl_processing)
    this.isSNC = Number(this.localization.GetPropertyInfo("ProductId")) == 5;
    this.isRentalEnabledProperty = this.propertyInfo.IsRentalEnabledProperty;
    this.isEngageProperty = this.propertyInfo.IsAcesMembershipEnabled;
    this.setInitialFormData();
    this.validateBreakPoints();
    this.MapFunctionality();
    this.isICEnabled = this.featureFlagInfo.IsRetailIcEnabled;
  }


  //UI actions
  async save() {
    let setting: Partial<PropertySetting> = {
      activateRetailInterface: this.miscellaneousFormGrp.controls['retailInterfaceToggle'].value
    }
    this.pmsEndPointConfig.value = this.miscellaneousFormGrp.controls['pmsproxyendpoint'].value;
    this.ShopItemShowCountConfig.value = this.miscellaneousFormGrp.controls['itemCount'].value;
    this.DiscountServiceChargeConfig.value = this.miscellaneousFormGrp.controls['discountOnServiceChargeToggle'].value;
    this.DiscountGrtauityConfig.value = this.miscellaneousFormGrp.controls['discountOnGratuityToggle'].value;    
    this.allowTaxOnServiceChargeConfig.value = this.miscellaneousFormGrp.controls['allowTaxOnServiceChargeToggle'].value;
    this.allowTaxOnGratuityConfig.value = this.miscellaneousFormGrp.controls['allowTaxOnGratuityToggle'].value;
    this.returnOriginalPayment.value = this.miscellaneousFormGrp.controls['returnOriginalPaymentOnly'].value;
    this.enableRetailConfirmation.value = this.miscellaneousFormGrp.controls['enableRetailConfirmationDialog'].value;
    this.enableTransactionByMachine.value = this.miscellaneousFormGrp.controls['enableTransactionByMachineName'].value;
    this.enableSelectionOnLogin.value = this.miscellaneousFormGrp.controls['enableSelectionOnLoginIn'].value;
    this.printerManagerURI = this.miscellaneousFormGrp.controls['printerManagerURI'].value;
    this.EnableRoomKeySwipeWithMSRConfig.value = this.miscellaneousFormGrp.controls['enableRoomKeySwipeWithMSR'].value;
    this.RoomKeyCardSourceTypeConfig.value = this.miscellaneousFormGrp.controls['roomKeyCardSourceType'].value;
    this.CardDataReadStartPositionConfig.value = this.miscellaneousFormGrp.controls['cardDataReadStartPosition'].value;
    this.CardDataReadEndPositionConfig.value = this.miscellaneousFormGrp.controls['cardDataReadEndPosition'].value;
    this.EnableCheckZoom.value = this.miscellaneousFormGrp.controls['enableCheckZoom'].value;
    this.DefaultGuestCategoryConfig.value = this.miscellaneousFormGrp.controls['defaultGuestCategoryConfig'].value;
    this.EnableRevenuePostingsOnDayEnd.value = this.miscellaneousFormGrp.controls['enableRevenuePostingsOnDayEnd'].value;
    this.DisplayImagesInShop.value = this.miscellaneousFormGrp.controls['displayImagesInShop'].value;
    this.EnablePostRevenueOnPropertyDate.value = this.miscellaneousFormGrp.controls['enablePostRevenueOnPropertyDate'].value;
    this.AllowFutureDateConfig.value = this.miscellaneousFormGrp.controls['allowDateToFutureToggle'].value;
    this.ExpandPaymentMethodsConfig.value = this.miscellaneousFormGrp.controls['expandPaymentMethodsToggle'].value;
    this.CashPopUpZeroDollarPopulateConfig.value = this.miscellaneousFormGrp.controls['cashPopUpZeroDollarPopulateToggle'].value;
    this.ItemNameDeletePopulateConfig.value = this.miscellaneousFormGrp.controls['ItemNameDeletePopulateToggle'].value;
    this.RequestPresettlementReceiptOnPendingSettlementConfig.value = this.miscellaneousFormGrp.controls['RequestPresettlementReceiptOnPendingSettlementToggle'].value;
    this.enableAutoRetailTransactionLogsConfig.value = this.miscellaneousFormGrp.controls['EnableAutoRetailTransactionLogsToggle'].value;
    this.AllowPaymentSurchargesToggleConfig.value = this.miscellaneousFormGrp.controls['AllowPaymentSurchargesToggle'].value;
    this.enableAutoCloseTransaction.value = this.miscellaneousFormGrp.controls['enableAutoCloseTransaction'].value;
    this.validatePayAgentAndSettlerOnRefund.value = this.miscellaneousFormGrp.controls['validatePayAgentAndSettlerOnRefund'].value;
    this.defaultReturnToOriginalSettlement.value = this.miscellaneousFormGrp.controls['defaultRefundToOriginalSettlement'].value;
    this.businessUnit.value = this.miscellaneousFormGrp.controls['businessUnit'].value;
    this.operatingUnit.value = this.miscellaneousFormGrp.controls['operatingUnit'].value;
    this.InhouseGratuityConfigValue.value = this.localization.currencyToSQLFormat(this.miscellaneousFormGrp.controls['inhousegratuity'].value).toString();
    this.InhouseServiceChargeConfigValue.value = this.localization.currencyToSQLFormat(this.miscellaneousFormGrp.controls['inhouseservicecharge'].value).toString();
    this.requireReturnReasonConfig.value = this.miscellaneousFormGrp.controls['requireReturnReason'].value;
    this.excludeDiscountOnTaxConfig.value = this.miscellaneousFormGrp.controls['excludeDiscountOnTax'].value;
    this.enableExtendedProfileSearchByDefault.value = this.miscellaneousFormGrp.controls['enableExtendedProfileSearchByDefault'].value;
    this.enableCashDrawerConfig.value = this.miscellaneousFormGrp.controls['enableCashDrawer'].value;
    this.cashDrawerURIConfig.value = this.miscellaneousFormGrp.controls['cashDrawerURI'].value;
    this.inventoryScannerConfig.value = this.miscellaneousFormGrp.controls['inventoryScannerConfig'].value;
    this.displayItemNumberInShopConfig.value = this.miscellaneousFormGrp.controls['displayItemNumberInShop'].value;
    this.isRentalAtOutletLevelConfig.value = this.miscellaneousFormGrp.controls['isRentalAtOutletLevel'].value;
    const settingsTobeUpdated = [
      this.pmsEndPointConfig
      , this.ShopItemShowCountConfig
      , this.returnOriginalPayment
      , this.DiscountServiceChargeConfig
      , this.DiscountGrtauityConfig
      ,this.allowTaxOnGratuityConfig
      ,this.allowTaxOnServiceChargeConfig
      , this.enableRetailConfirmation
      , this.discountreasonrequired
      , this.discountcommentsrequired
      , this.enableTransactionByMachine
      , this.enableSelectionOnLogin
      , this.EnableRoomKeySwipeWithMSRConfig
      , this.RoomKeyCardSourceTypeConfig
      , this.CardDataReadStartPositionConfig
      , this.CardDataReadEndPositionConfig
      , this.EnableCheckZoom
      , this.DefaultGuestCategoryConfig
      , this.AllowFutureDateConfig
      , this.ExpandPaymentMethodsConfig
      , this.EnableRevenuePostingsOnDayEnd
      , this.DisplayImagesInShop
      , this.EnablePostRevenueOnPropertyDate
      , this.CashPopUpZeroDollarPopulateConfig
      , this.ItemNameDeletePopulateConfig
      , this.RequestPresettlementReceiptOnPendingSettlementConfig
      , this.enableAutoRetailTransactionLogsConfig
      , this.AllowPaymentSurchargesToggleConfig
      , this.enableAutoCloseTransaction
      , this.validatePayAgentAndSettlerOnRefund
      , this.defaultReturnToOriginalSettlement
      , this.InhouseGratuityConfigValue
      , this.InhouseServiceChargeConfigValue
      , this.businessUnit
      , this.operatingUnit
      ,this.requireReturnReasonConfig
      , this.excludeDiscountOnTaxConfig
      , this.enableExtendedProfileSearchByDefault
      ,this.isRentalAtOutletLevelConfig
      ,this.enableCashDrawerConfig
      , this.cashDrawerURIConfig
      , this.inventoryScannerConfig
      ,this.displayItemNumberInShopConfig
    ];
    await this.data.UpdateSettings(settingsTobeUpdated);
    this.propertyInfo.SetShopItemLoadCount(this.ShopItemShowCountConfig.value);
    this.propertyInfo.SetEnableMachineTransaction(this.enableTransactionByMachine.value);
    let tenantMiscConfig: MiscellaneousConfiguration = {
      enableTransactionByMachineName: this.enableTransactionByMachine.value,
      promptOnLogin: this.enableSelectionOnLogin.value,
      printerManagerURI: this.printerManagerURI
    }
    sessionStorage.setItem("RequirePreSettlement", this.RequestPresettlementReceiptOnPendingSettlementConfig.value)
    this.savemachineBasedConfigurationtoTenant(tenantMiscConfig);
    await !this.data.patchPropertySetting(setting);
    this.miscellaneousFormGrp.markAsPristine();
    await this.SetPropertyConfiguration();
    this.enableSave = false;
    this.setInitialFormData();
  }

  async SetPropertyConfiguration() {
    let propertityConfig = await this.data.getAllPropertySetting();
    this.propertyInfo.SetPropertySetting(propertityConfig);
  }
  async MapFunctionality() {
    this.functionalities = await this.retailFunc.getRetailFunctionality();
    this.showRentalsRetailItemType = this.functionalities[GlobalConst.RetailFunctionalities.ShowRentalsRetailItemType]? true : false;
    this.validateExtendedProfileSearchByDefault(this.functionalities);
  }
  onRetailInterfaceChanged(event) {
    if (event && event.length === 1 && event[0] === true) {
      this.isEmbededRetail = false;
      this.miscellaneousFormGrp.controls['discountOnServiceChargeToggle'].setValue(false);
      this.miscellaneousFormGrp.controls['discountOnGratuityToggle'].setValue(false);
      this.miscellaneousFormGrp.controls['allowTaxOnServiceChargeToggle'].setValue(false);
      this.miscellaneousFormGrp.controls['allowTaxOnGratuityToggle'].setValue(false);
      this.miscellaneousFormGrp.controls['returnOriginalPaymentOnly'].setValue(false);
      this.miscellaneousFormGrp.controls['enableCheckZoom'].setValue(false);
      this.miscellaneousFormGrp.controls['enableRevenuePostingsOnDayEnd'].setValue(false);
      this.miscellaneousFormGrp.controls['EnableAutoRetailTransactionLogsToggle'].setValue(false);
      this.miscellaneousFormGrp.controls['AllowPaymentSurchargesToggle'].setValue(false);
      this.miscellaneousFormGrp.controls['enableAutoCloseTransaction'].setValue(false);
      this.miscellaneousFormGrp.controls['validatePayAgentAndSettlerOnRefund'].setValue(false);
      this.miscellaneousFormGrp.controls['defaultRefundToOriginalSettlement'].setValue(false);
    } else {
      this.isEmbededRetail = true;
    }
  }
  cancel() {
    this.miscellaneousFormGrp.reset();
    this.setInitialFormData();
  }

  private async setInitialFormData() {
    this.InhouseGratuityConfig = {
      className: '',
      form: this.miscellaneousFormGrp,
      formControlName: 'inhousegratuity',
      maxlength: 3,
      placeHolderId: 'lbl_inHouseGratuity',
      placeHolder: this.captions.lbl_inHouseGratuity,
      minValue: 0,
      maxValue: 100,
      value: 0
    };
    this.InhouseServiceChargeConfig = {
      className: '',
      form: this.miscellaneousFormGrp,
      formControlName: 'inhouseservicecharge',
      maxlength: 3,
      placeHolderId: 'lbl_inHouseServiceCharge',
      placeHolder: this.captions.lbl_inHouseServiceCharge,
      minValue: 0,
      maxValue: 100,
      value: 0
    }
    let setting = await this.data.getAllPropertySetting();
    const miscSettings = await this.data.GetMiscConfigurationSetting();
    this.DiscountServiceChargeConfig = miscSettings.find(x => x.switch == MiscellaneousSwitch.EXCLUDE_DISCOUNT_ON_SERVICE_CHARGE);
    this.DiscountGrtauityConfig = miscSettings.find(x => x.switch == MiscellaneousSwitch.EXCLUDE_DISCOUNT_ON_GRATUITY);
    this.allowTaxOnGratuityConfig =  miscSettings.find(x => x.switch == MiscellaneousSwitch.ALLOW_TAX_ON_GRATUITY);
    this.allowTaxOnServiceChargeConfig =  miscSettings.find(x => x.switch == MiscellaneousSwitch.ALLOW_TAX_ON_SERVICECHARGE);
    this.discountreasonrequired = miscSettings.find(x => x.switch == MiscellaneousSwitch.DISCOUNT_REASON_REQUIRED);
    this.discountcommentsrequired = miscSettings.find(x => x.switch == MiscellaneousSwitch.DISCOUNT_COMMENTS_REQUIRED);

    this.InhouseGratuityConfigValue = miscSettings.find(x => x.switch == MiscellaneousSwitch.HOUSE_GRATUITY_PERCENTAGE);
    this.InhouseServiceChargeConfigValue = miscSettings.find(x => x.switch == MiscellaneousSwitch.HOUSE_SERVICECHARGE_PERCENTAGE);

    this.EnableRoomKeySwipeWithMSRConfig = miscSettings.find(x => x.switch == MiscellaneousSwitch.ENABLE_ROOM_KEY_SWIPE_WITH_MSR);
    this.RoomKeyCardSourceTypeConfig = miscSettings.find(x => x.switch == MiscellaneousSwitch.ROOM_KEY_CARD_DATA_SOURCE_TYPE);
    this.CardDataReadStartPositionConfig = miscSettings.find(x => x.switch == MiscellaneousSwitch.ROOM_KEY_CARD_DATA_START_POSITION);
    this.CardDataReadEndPositionConfig = miscSettings.find(x => x.switch == MiscellaneousSwitch.ROOM_KEY_CARD_DATA_END_POSITION);
    this.EnableCheckZoom = miscSettings.find(x => x.switch == MiscellaneousSwitch.ENABLE_CHECKZOOM);
    this.DefaultGuestCategoryConfig = miscSettings.find(x=> x.switch == MiscellaneousSwitch.DEFAULT_GUEST_CATEGORY_IN_SHOP);
    this.EnableRevenuePostingsOnDayEnd = miscSettings.find(x => x.switch
      === MiscellaneousSwitch.RESTRICT_DAYENDPROCESSWITHFAILEDREVENUEPOSTINGS);
    this.DisplayImagesInShop = miscSettings.find(x => x.switch == MiscellaneousSwitch.DISPLAY_IMAGES_IN_SHOP);
    this.EnablePostRevenueOnPropertyDate = miscSettings.find(x =>x.switch == MiscellaneousSwitch.POST_REVENUE_ON_PROPERTY_DATE);
    this.CardDataReadEndPositionConfig = miscSettings.find(x => x.switch == MiscellaneousSwitch.ROOM_KEY_CARD_DATA_END_POSITION);
    this.AllowFutureDateConfig = miscSettings.find(x => x.switch == MiscellaneousSwitch.ALLOW_DATE_FOR_FUTURE);
    this.ExpandPaymentMethodsConfig = miscSettings.find(x => x.switch == MiscellaneousSwitch.EXPAND_PAYMENT_METHODS);
    this.CashPopUpZeroDollarPopulateConfig = miscSettings.find(x => x.switch == MiscellaneousSwitch.CASH_POPUP_ZERO_DOLLAR_POPULATE);
    this.ItemNameDeletePopulateConfig = miscSettings.find(x => x.switch == MiscellaneousSwitch.ITEM_NAME_DELETE_OPENITEM_POPULATE);
    this.RequestPresettlementReceiptOnPendingSettlementConfig = miscSettings.find(x => x.switch == MiscellaneousSwitch.REQUEST_PRESETTLEMENT_RECEIPT_ON_PENDING_SETTLEMENT);

    this.enableAutoRetailTransactionLogsConfig =  miscSettings.find(x => x.switch == MiscellaneousSwitch.ENABLE_AUTO_RETAIL_TRANSACTION_LOG);
    this.AllowPaymentSurchargesToggleConfig =  miscSettings.find(x => x.switch == MiscellaneousSwitch.ALLOW_PAYMENT_SURCHARGE);
    this.enableAutoCloseTransaction =  miscSettings.find(x => x.switch === MiscellaneousSwitch.ENABLE_AUTO_CLOSE_TRANSACTION);
    this.validatePayAgentAndSettlerOnRefund =  miscSettings.find(x => x.switch === MiscellaneousSwitch.VALIDATE_PAYAGENT_AND_SETTLER_VERSION_MISMATCH_ON_REFUND);
    this.defaultReturnToOriginalSettlement = miscSettings.find(x => x.switch === MiscellaneousSwitch.DEFAULT_REFUND_TO_ORIGINAL_SETTLEMENTS_ONLY);

    this.businessUnit = miscSettings.find(x => x.switch === MiscellaneousSwitch.BUSINESS_UNIT_VALUE);
    this.operatingUnit = miscSettings.find(x => x.switch === MiscellaneousSwitch.OPERATING_UNIT_VALUE);
    this.requireReturnReasonConfig = miscSettings.find(x => x.switch == MiscellaneousSwitch.REQUIRE_RETURN_REASON);
    this.excludeDiscountOnTaxConfig = miscSettings.find(x => x.switch == MiscellaneousSwitch.EXCLUDE_DISCOUNT_ON_TAX);
    this.isRentalAtOutletLevelConfig = miscSettings.find(x => x.switch == MiscellaneousSwitch.RENTAL_ITEM_QUANTITY_AT_OUTLETLEVEL);

    this.enableExtendedProfileSearchByDefault = miscSettings.find(x => x.switch === MiscellaneousSwitch.ENABLE_EXTENDED_PROFILE_SEARCH_BY_DEFAULT);
    this.enableCashDrawerConfig = miscSettings.find(x => x.switch === MiscellaneousSwitch.ENABLE_CASH_DRAWER_FUNCTIONALITY);
    this.cashDrawerURIConfig = miscSettings.find(x => x.switch === MiscellaneousSwitch.CASH_DRAWER_URI);
    this.inventoryScannerConfig = miscSettings.find(x=>x.switch === MiscellaneousSwitch.INVENTORY_STAGING_POST_TYPE)
    this.displayItemNumberInShopConfig = miscSettings.find(x => x.switch == MiscellaneousSwitch.DISPLAY_ITEMNUMBER_IN_SHOP);
    let patch = {
      // patching values from API
      retailInterfaceToggle: setting.activateRetailInterface,
      discountOnServiceChargeToggle: this.DiscountServiceChargeConfig && this.DiscountServiceChargeConfig.value === "true" ? true : false,
      discountOnGratuityToggle: this.DiscountGrtauityConfig && this.DiscountGrtauityConfig.value === "true" ? true : false,
      allowTaxOnServiceChargeToggle: this.allowTaxOnServiceChargeConfig && this.allowTaxOnServiceChargeConfig.value === "true" ? true : false,
      allowTaxOnGratuityToggle: this.allowTaxOnGratuityConfig && this.allowTaxOnGratuityConfig.value === "true" ? true : false,
      enableRoomKeySwipeWithMSR: this.EnableRoomKeySwipeWithMSRConfig && this.EnableRoomKeySwipeWithMSRConfig.value === "true" ? true : false,
      roomKeyCardSourceType: Number(this.RoomKeyCardSourceTypeConfig.value),
      cardDataReadStartPosition: this.CardDataReadStartPositionConfig.value,
      cardDataReadEndPosition: this.CardDataReadEndPositionConfig && this.CardDataReadEndPositionConfig.value,
      allowDateToFutureToggle: this.AllowFutureDateConfig && this.AllowFutureDateConfig.value === "true" ? true : false,
      expandPaymentMethodsToggle: this.ExpandPaymentMethodsConfig && this.ExpandPaymentMethodsConfig.value === "true" ? true : false,
      cashPopUpZeroDollarPopulateToggle: this.CashPopUpZeroDollarPopulateConfig && this.CashPopUpZeroDollarPopulateConfig.value === "true" ? true : false,
      ItemNameDeletePopulateToggle: this.ItemNameDeletePopulateConfig && this.ItemNameDeletePopulateConfig.value == "true" ? true : false,
      RequestPresettlementReceiptOnPendingSettlementToggle: this.RequestPresettlementReceiptOnPendingSettlementConfig && this.RequestPresettlementReceiptOnPendingSettlementConfig.value == "true" ? true : false,
      
      EnableAutoRetailTransactionLogsToggle: this.enableAutoRetailTransactionLogsConfig && this.enableAutoRetailTransactionLogsConfig.value === "true" ? true : false,      
      AllowPaymentSurchargesToggle: this.AllowPaymentSurchargesToggleConfig && this.AllowPaymentSurchargesToggleConfig.value === "true" ? true : false,
      defaultReturnToOriginalSettlement: this.defaultReturnToOriginalSettlement && this.defaultReturnToOriginalSettlement.value == "true" ? true :false,
      businessUnit: this.businessUnit && this.businessUnit.value,
      operatingUnit : this.operatingUnit && this.operatingUnit.value,
      inhousegratuity: this.InhouseGratuityConfigValue && this.localization.localizePercentage(this.InhouseGratuityConfigValue.value.toString()),
      inhouseservicecharge: this.InhouseServiceChargeConfigValue && this.localization.localizePercentage(this.InhouseServiceChargeConfigValue.value.toString()),
      defaultGuestCategoryConfig: Number(this.DefaultGuestCategoryConfig.value),
      cashDrawerURI: this.cashDrawerURIConfig.value
    }
    this.miscellaneousFormGrp.patchValue(patch);
    this.pmsEndPointConfig = miscSettings.find(x => x.switch == MiscellaneousSwitch.WEBCOMMUNICATION_PROXY_ENDPOINT);
    this.ShopItemShowCountConfig = miscSettings.find(x => x.switch == MiscellaneousSwitch.SHOP_SCREEN_ITEM_LOAD_COUNT);
    this.returnOriginalPayment = miscSettings.find(x => x.switch == MiscellaneousSwitch.RETURN_ORIGINAL_PAYMENT_ONLY);
    this.enableRetailConfirmation = miscSettings.find(x => x.switch == MiscellaneousSwitch.ENABLE_CONFIRMATION_ON_DELETE);
    this.enableTransactionByMachine = miscSettings.find(x => x.switch == MiscellaneousSwitch.TRANSACTION_BY_MACHINENAME);
    this.enableLoginSelectionvisible = this.enableTransactionByMachine.value == "true" ? true : false;
    this.enableSelectionOnLogin = miscSettings.find(x => x.switch == MiscellaneousSwitch.SELECTION_ON_LOGIN);
    this.miscellaneousFormGrp.controls.pmsproxyendpoint.setValue(this.pmsEndPointConfig.value);
    this.miscellaneousFormGrp.controls.itemCount.setValue(this.ShopItemShowCountConfig.value);
    this.miscellaneousFormGrp.controls.returnOriginalPaymentOnly.setValue(this.returnOriginalPayment.value === "true" ? true : false);
    this.miscellaneousFormGrp.controls.enableRetailConfirmationDialog.setValue(this.enableRetailConfirmation.value === "true" ? true : false);
    this.cashDrawerURIConfig = miscSettings.find(x => x.switch == MiscellaneousSwitch.CASH_DRAWER_URI);
    if (this.discountreasonrequired.value == "0") {
      this.miscellaneousFormGrp.controls.discountreasonrequired.setValue(false);
    } else {
      this.miscellaneousFormGrp.controls.discountreasonrequired.setValue(true);
      this.miscellaneousFormGrp.controls.discountReasonRadio.setValue(Number(this.discountreasonrequired.value));
    }
    if (this.discountcommentsrequired.value == "0") {
      this.miscellaneousFormGrp.controls.discountcommentsrequired.setValue(false);
    } else {
      this.miscellaneousFormGrp.controls.discountcommentsrequired.setValue(true);
      this.miscellaneousFormGrp.controls.discountCommentsRadio.setValue(Number(this.discountcommentsrequired.value));
    }

    this.miscellaneousFormGrp.controls.enableTransactionByMachineName.setValue(this.enableTransactionByMachine.value === "true" ? true : false);
    this.miscellaneousFormGrp.controls.enableSelectionOnLoginIn.setValue(this.enableSelectionOnLogin.value === "true" ? true : false);
    this.miscellaneousFormGrp.controls.enableCheckZoom.setValue(this.EnableCheckZoom.value === "true" ? true : false);
    this.miscellaneousFormGrp.controls.enableRevenuePostingsOnDayEnd.setValue(this.EnableRevenuePostingsOnDayEnd.value === 'true' ? true : false);
    this.miscellaneousFormGrp.controls.enableAutoCloseTransaction.setValue(this.enableAutoCloseTransaction?.value === 'true' ? true : false);
    this.miscellaneousFormGrp.controls.validatePayAgentAndSettlerOnRefund.setValue(this.validatePayAgentAndSettlerOnRefund?.value === 'true' ? true : false);
    this.miscellaneousFormGrp.controls.defaultRefundToOriginalSettlement.setValue(this.defaultReturnToOriginalSettlement?.value === 'true' ? true : false);
    this.miscellaneousFormGrp.controls.displayImagesInShop.setValue(this.DisplayImagesInShop.value === 'true' ? true : false);
    this.miscellaneousFormGrp.controls.enablePostRevenueOnPropertyDate.setValue(this.EnablePostRevenueOnPropertyDate.value === 'true' ? true : false);
    this.onRetailInterfaceChanged([this.miscellaneousFormGrp.controls['retailInterfaceToggle'].value]);
    this.roomKeySwipeToggleChange([this.EnableRoomKeySwipeWithMSRConfig.value]);
    this.setmachineBasedConfigurationFromTenant();
    this.utils.ToggleLoaderWithMessage(false);
    this.businessUnit.value = this.businessUnit.value.toString();
    this.operatingUnit.value = this.operatingUnit.value.toString();
    this.miscellaneousFormGrp.controls.requireReturnReason.setValue(this.requireReturnReasonConfig.value === "true" ? true : false);
    this.miscellaneousFormGrp.controls.excludeDiscountOnTax.setValue(this.excludeDiscountOnTaxConfig.value === "true" ? true : false);
    this.miscellaneousFormGrp.controls.enableExtendedProfileSearchByDefault.setValue(this.enableExtendedProfileSearchByDefault && this.enableExtendedProfileSearchByDefault.value === "true" ? true : false);
    this.miscellaneousFormGrp.controls.isRentalAtOutletLevel.setValue(this.isRentalAtOutletLevelConfig.value === "true" ? true : false);
    this.miscellaneousFormGrp.controls.enableCashDrawer.setValue(this.enableCashDrawerConfig.value === "true" ? true : false);
    this.miscellaneousFormGrp.controls.displayItemNumberInShop.setValue(this.displayItemNumberInShopConfig.value === "true" ? true : false);
  }
  discReasonChange(e) {
    if (e.value) {
      this.discountreasonrequired.value = e.value.toString();
    } else {
      if (!e[0]) {
        this.discountreasonrequired.value = "0";
      } else {
        this.discountreasonrequired.value = "1";
        this.miscellaneousFormGrp.controls.discountReasonRadio.setValue(1);
      }
    }
  }

  onInventoryOptionChange(eve){
    console.log(eve);
    this.miscellaneousFormGrp.controls.inventoryScannerConfig.setValue(eve.value);
  }

  discCommentsChange(e) {
    if (e.value) {
      this.discountcommentsrequired.value = e.value.toString();
    } else {
      if (!e[0]) {
        this.discountcommentsrequired.value = "0";
      } else {
        this.discountcommentsrequired.value = "1";
        this.miscellaneousFormGrp.controls.discountCommentsRadio.setValue(1);
      }
    }
  }

  toggleChange(arg) {
    this.enableLoginSelectionvisible = arg[0];
    if (!arg[0]) {
      this.miscellaneousFormGrp.controls.enableSelectionOnLoginIn.setValue(arg[0]);
    }
  }

  roomKeySwipeToggleChange(event) {
    if (event && event[0]) {
      this.miscellaneousFormGrp.controls.cardDataReadStartPosition.setValidators(Validators.required);
      this.miscellaneousFormGrp.controls.cardDataReadEndPosition.setValidators(Validators.required);
    } else {
      this.miscellaneousFormGrp.controls.cardDataReadStartPosition.setValidators(null);
      this.miscellaneousFormGrp.controls.cardDataReadEndPosition.setValidators(null);
    }
  }

  startPosChange($event) {
    const startPos = this.miscellaneousFormGrp.controls.cardDataReadStartPosition.value;
    if (startPos > 0) {
      this.miscellaneousFormGrp.controls.cardDataReadEndPosition.setValidators(Validators.min(startPos + 1));
      this.miscellaneousFormGrp.controls.cardDataReadEndPosition.updateValueAndValidity();
    }
  }

  endPosChange($event) {
    const endPos = this.miscellaneousFormGrp.controls.cardDataReadEndPosition.value;
    if (endPos > 0) {
      this.miscellaneousFormGrp.controls.cardDataReadStartPosition.setValidators(Validators.max(endPos - 1));
      this.miscellaneousFormGrp.controls.cardDataReadStartPosition.updateValueAndValidity();
    }
  }

  public async validateBreakPoints(): Promise<boolean> {
    let productId = Number(this.localization.GetPropertyInfo('ProductId'));
    this.isSpaProduct = (productId == Product.SPA) ? true : false;
    let breakpointNumber: number = (productId == Product.GOLF) ? UserAccessBreakPoints.GOLFSYSTEMSETUP
      : ((productId == Product.SPA) ? UserAccessBreakPoints.SPASYSTEMSETUP : 0);
    if (breakpointNumber > 0) {
      let result = await this.facadeService.getUserAccess(breakpointNumber, true);
      this.isViewOnly = result.isViewOnly;
      if (this.isViewOnly) {
        this.disableControls();
      }
      return result.isAllow;
    }
    return true;
  }
  
  disableControls() {
    if (this.isViewOnly) {
      this.utils.disableControls(this.miscellaneousFormGrp);
      this.enableSave = false;
      this.isDisabled = true;
    }
  }
  setmachineBasedConfigurationFromTenant() {
    let propertyMiscConfig: API.MiscellaneousConfigurationSettings<any> = {
      configurationName: MISCELLANEOUSCONFIGURATION,
      propertyId: Number(this.localization.GetPropertyInfo("PropertyId")),
      productId: Number(this.localization.GetPropertyInfo("ProductId"))
    } as API.MiscellaneousConfigurationSettings<any>;

    this._commonPropertySettingDataService.GetMiscConfigurationSettingTenantByPropertyId(propertyMiscConfig).then(res => {
      this.machineBasedConfigurationFromTenant = res;
      let miscConfig: MiscellaneousConfiguration = this.machineBasedConfigurationMapper(res.configValue, res.defaultValue);
      this.enableTransactionByMachine.value = miscConfig.enableTransactionByMachineName;
      this.enableLoginSelectionvisible = miscConfig.enableTransactionByMachineName;
      this.enableSelectionOnLogin.value = miscConfig.promptOnLogin;
      this.printerManagerURI = miscConfig.printerManagerURI;
      this.miscellaneousFormGrp.controls.enableTransactionByMachineName.setValue(this.enableTransactionByMachine.value);
      this.miscellaneousFormGrp.controls.enableSelectionOnLoginIn.setValue(this.enableSelectionOnLogin.value);
      this.miscellaneousFormGrp.controls.printerManagerURI.setValue(this.printerManagerURI);
    })
  }
  savemachineBasedConfigurationtoTenant(configData: MiscellaneousConfiguration) {
    this.localization.SetPrinterManagerURI(configData.printerManagerURI);
    let machineBasedConfiguration: any = cloneDeep(this.machineBasedConfigurationFromTenant);
    machineBasedConfiguration.configValue = JSON.stringify(configData);
    machineBasedConfiguration.defaultValue = JSON.stringify(machineBasedConfiguration.defaultValue);
    this._commonPropertySettingDataService.saveMiscConfigurationSettingTenantByPropertyId(machineBasedConfiguration).then(res => {
      if (res)
        this.setmachineBasedConfigurationFromTenant();
    })
  }
  machineBasedConfigurationMapper(configvalue, defaultvalue): MiscellaneousConfiguration {
    return {
      enableTransactionByMachineName: configvalue ? configvalue.enableTransactionByMachineName : defaultvalue.enableTransactionByMachineName,
      promptOnLogin: configvalue ? configvalue.promptOnLogin : defaultvalue.promptOnLogin,
      printerManagerURI: configvalue ? configvalue.printerManagerURI : defaultvalue.printerManagerURI
    }
  }
  private validateExtendedProfileSearchByDefault(functionalities: { [key: string]: boolean })
  {
    let isPlatformSearchEnabled = this.localization.IsPlatformGuestSearchConfigured();
    this.showExtendedProfileSearchByDefault = isPlatformSearchEnabled && functionalities[GlobalConst.RetailFunctionalities.ShowEnableExtendedProfileSearchByDefault];
  }
 
}