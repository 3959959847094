import { AfterViewInit, Directive, ElementRef, Input, Renderer2, HostListener } from '@angular/core';
import { JWT_TOKEN, PAYMENT_CONFIGURATION, PROPERTY_DATE, PROPERTY_INFO, REMEMBER_INFO, USERS_SESSSIONS_INFO, USER_INFO, USER_SESSION, USER_SETTINGS, USE_RETAIL_INTERFACE, VAT_ENABLED } from '../../app-constants';


@Directive({
  selector: "[ag_newtab]" 
})
export class NewTabDirective {
  @Input() newtab: any;

  constructor(private elementRef: ElementRef, private renderer: Renderer2) { }

  @HostListener('click', ['$event']) onClick(event) {
    if (event.ctrlKey) {
      sessionStorage.setItem('isnewtab','true');
      for (let i = 0; i < sessionStorage.length; i++) {
        const key = sessionStorage.key(i); 
        const value = sessionStorage.getItem(key); 
        localStorage.setItem(key, value);
      }

      localStorage.setItem('fromURLPath',JSON.stringify([]));
      localStorage.setItem('fromURLRouteState',JSON.stringify([]));
        window.open(this.newtab);

    }
  }
}