
import { Component, OnDestroy, OnInit,Input } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { Localization} from 'src/app/common/localization/localization';
import {TherapistLog} from 'src/app/shared/business/view-settings.modals';
import {MatDialogRef , MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Inject } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { HttpMethod, HttpServiceCall } from 'src/app/shared/service/http-call.service';
import {  Host } from 'src/app/shared/globalsContant'; 
import { BaseResponse } from 'src/app/shared/business/shared.modals';
import { LogApplicableType } from 'src/app/common/enums/shared-enums';


@Component({
  selector: 'app-therapistlog-popup',
  templateUrl: './therapistlog-popup.component.html',
  styleUrls: ['./therapistlog-popup.component.scss']
})

export class TherapistlogPopupComponent implements OnInit, OnDestroy {
  @Input() dataInput: any;
  therapistLogs: TherapistLog[];
  clientContactLogs; 
  enableSave : boolean = false ;
  currentTherapistId;
  caption;
  applicableType;
  constructor
 (public dialogRef: MatDialogRef<TherapistlogPopupComponent>,
  @Inject(MAT_DIALOG_DATA) public data: any,
  public dialog: MatDialog,  
  public fb: UntypedFormBuilder,
  public localization: Localization,
  private http: HttpServiceCall)
   {
      this.caption = this.localization.captions.bookAppointment;
      this.clientContactLogs = this.data.therapistLog;
      this.currentTherapistId = this.data.currentTherapistId;
   }

  ngOnInit(): void {
    this.clientContactLogs = this.dataInput?.therapistLog;
    this.applicableType=LogApplicableType.Staff;
  }

  createRecord(therapistLogs) {  
    this.therapistLogs = therapistLogs;
    this.enableSave = true;
  }

  editRecord(therapistLogs) {
    this.therapistLogs = therapistLogs;
    this.enableSave = true;
  }

  deleteRecord(therapistLogs) {
    this.therapistLogs = therapistLogs;
    this.enableSave = true;
  }

  FormSubmit(e: any): void {
    const body =   this.therapistLogs;
    this.http.CallApiWithCallback<any>({
      host: Host.spaManagement,
      success: this.successCallback.bind(this),
      error: this.errorCallback.bind(this),
      callDesc: 'UpdateTherapistLog',
      method: HttpMethod.Put,
      uriParams: { id: this.currentTherapistId},
      body: body,
      showError: true,
      extraParams: []
    });
      
  }

  ngOnDestroy(): void {
    this.therapistLogs = [];
  }

  successCallback<T>(result: BaseResponse<T>, callDesc: string, extraParams?: any[]): void {
    this.dialogRef.close();
  }

  close(){
    this.dialogRef.close();
  }

  errorCallback<T>(result: BaseResponse<T>, callDesc: string, extraParams: any[]): void {
    console.error(result.result);
  }

}

