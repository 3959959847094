import { Component, Inject, ViewEncapsulation } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ReportAPIOptions } from './preview-report.model';
import * as saveAs from 'file-saver'
import { Localization } from '../../localization/localization';
import { AdditionalGuestInfo } from '../../Models/ag-models';

export const saveFile = (blobContent: Blob, fileName: string) => {
  const blob = new Blob([blobContent], { type: 'application/octet-stream' });
  saveAs(blob, fileName);
};

@Component({
  selector: 'ag-preview-report',
  templateUrl: './preview-report.component.html',
  styleUrls: ['./preview-report.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class AgPreviewReportComponent {
  captions: any;
  options: ReportAPIOptions;
  optionValues;
  downloadFileName?: string;
  title: string;
  additionalGuestInfo: AdditionalGuestInfo[] = [];
  preferredLanguageId: number;
  constructor(
    private localization: Localization,
    @Inject(MAT_DIALOG_DATA) data: any,
    private dialogRef: MatDialogRef<AgPreviewReportComponent>
  ) {
    this.captions = this.localization.captions;
    this.options = data?.reportOptions;
    this.title = data?.title ? data?.title : this.captions.pg_title_printpreview;
    this.downloadFileName = data && data?.downloadFileName ? data?.downloadFileName : "";
    this.optionValues = {
      options: this.options, 
      downloadFileName: this.downloadFileName,
      previewReportPrintOptions: data?.folioPrintOptions ? data?.folioPrintOptions : null,
      guestEmailId: data?.guestEmailId,
      enableEmail: data?.enableEmail,
      guestEmailType: data?.guestEmailType,
      stayId: data?.stayId,
      additionalGuestInfo: data?.additionalGuestInfo,
      preferredLanguageId: data?.preferredLanguageId,
      jasperReportRequestData: data?.jasperReportData,
      jasperReportDetails: data?.jasperReportDetails,
      isAccounting: data?.isAccounting ?? false,
      isADMAndFolioEventEnabled: data?.isADMAndFolioEventEnabled ?? false
    };
  }
  close(e) {
    this.dialogRef.close();
  }


}

