import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';
import * as myGlobals from '../constant/GlobalConstants';
import * as CONSTANT from '../constant/APIURL-CONSTANT';
import { HttpService } from './http.service';
import { StorageService } from './storage.service';
import jwt_decode from 'jwt-decode';
import { HttpClient } from '@angular/common/http';
import { ToastrService } from 'ngx-toastr';
import { IntegrationService } from 'src/app/eatecui/source/shared/services/integration.service';
import { AgilysysButtonModel } from '../../agilysys-buttons/models/agilysys-buttons.interface';
import { AgilysysButtonModel as TransactionButtonModel } from 'src/app/eatecui/dist/agilysys-button';
import { AuthSubModule, AuthorizationModule, AuthoriztionKey } from 'src/app/eatecui/source/shared/models/ui-controls/authorization.enum';
import { AuthorizationSubModule, AuthorizedDataModel, PermissionResource } from 'src/app/eatecui/source/shared/models/ui-controls/ui-controls.interface';
import { UtilityService } from 'src/app/eatecui/source/shared/services/formatters/utility.service';
import { ConversionValue } from 'src/app/eatecui/source/shared/enum/global.enum';
import * as constants from '../constant/GlobalConstants';
import { GridTableComponent } from 'src/app/eatecui/dist/AgilysysDynamicGrid';

@Injectable({
  providedIn: 'root'
})
export class CommonService {
  public SaveBeforeLogOut = new Subject<boolean>();
  public errorMessage: string;
  public InactivityTimeout = new BehaviorSubject<number>(this.GetSessionInactivityTime());
  public enableIdleTimeOut = false;
  public productVersion: any;
  public authGuardValue = false;
  public previousURL: any;
  public currentURL = '';
  public urlTampered: boolean;
  public messageTotalCount: any = 0;
  public interval: any;
  public healthCheckInterval: any;
  public static ServiceChargeRate: any = 1;
  selectedCellIndex: any = 0;
  constructor(
    private httpService: HttpService,
    private httpClient: HttpClient,
    private toastrService: ToastrService,
    private integrationService: IntegrationService,
    private utilityService: UtilityService
  ) {
    this.urlTampered = false;
  }

  GetSessionInactivityTime() {    
    let sessionTimeout = sessionStorage.getItem('SessionInactivityTime') ?
      JSON.parse(sessionStorage.getItem('SessionInactivityTime')) :
      myGlobals.Default_SessionInactivityTime;
    const checkTenant = sessionStorage.getItem('RGuestTenantId');
    if (checkTenant && JSON.parse(checkTenant)) {
      sessionTimeout = 0;
    }
    return sessionTimeout;
  }

  GetLoggedInUserId() {
    const UserId = sessionStorage.getItem('UserId') ?
      parseInt(JSON.parse(sessionStorage.getItem('UserId')), 10) : 1;
    return UserId;
  }
  resizeRows(gridInput: any) {
    const defaultrowheight = JSON.parse(localStorage.getItem('defaultRowHeightData')); 
    localStorage.setItem('rowHeightData', localStorage.getItem('defaultRowHeightData'));   
    gridInput?.rearrangeHeight(defaultrowheight);    
    gridInput?.dynamicGridService?.resizeRows.next(defaultrowheight);
  }
  GetLoggedInSiteId() {
    const LoggedInSiteId = sessionStorage.getItem('LoggedInSiteId') ?
      parseInt(JSON.parse(sessionStorage.getItem('LoggedInSiteId')), 10) : 1;
    return LoggedInSiteId;
  }

  GetLoadMoreCount() {
    const LoadMoreCount = sessionStorage.getItem('appsetting') ?
      JSON.parse(sessionStorage.getItem('appsetting')).InfinteItemCountForGrid :
      myGlobals.InfiniteScrollItemCount;
    return LoadMoreCount;
  }

  getPreviousURL() {
    return this.previousURL;
  }
  getErrorMsg() {
    return this.errorMessage;
  }

  SetBannerNotificationInterval() {
    if (this.interval) clearInterval(this.interval);
    this.interval = setInterval(() => {
      const isLoggedIn = (StorageService.GetSessionStorage(myGlobals.IsloggedIn) &&
        StorageService.GetSessionStorage(myGlobals.IsloggedIn) !== null) ? true : false;
      const showMessageNotification = window.sessionStorage.getItem('ShowMessageNotification');
      if (showMessageNotification === 'true' && isLoggedIn) {
        this.GetBannerNotificationCount();
      } else {
        clearInterval(this.interval);
      }
    }, 60000); // For 1 minute
  }

  setHealthCheckInterval() {
    try {
      const moduleKeys = ['reportdata', 'report', 'notification', 'inventory', 'usermgmt', 'publisher', 'wexflow'];
      // if (this.healthCheckInterval) clearInterval(this.healthCheckInterval);
      // this.healthCheckInterval = setInterval(() => {
      moduleKeys.forEach(key => {
        this.httpService.GetHttpHealthCheckMethod(CONSTANT.ENDPOINTURL['HealthCheck'][key], key).subscribe(res => {
          console.log(`${key}-` + res);
        }, (error) => {
          console.log(error);
        });
      });
      // }, 60000);
    } catch (error) {
      console.log(error);
    }    
  }

  GetBannerNotificationCount() {
    this.httpService.GethttpMethod('/inventory/api/BannerNotificationMessage').subscribe(res => {
      if (res) {
        this.messageTotalCount = res;
      }
    });
  }
  UpdateLogoutDetails() {
    try {
      const token = StorageService.GetSessionStorage(myGlobals.AccessToken);
      if (!token) return;
      const decodeToken = jwt_decode(token);
      const EndPointUrl = `/usermgmt/api/Login/UpdateLogoutDetails/${decodeToken['userid']}/${decodeToken['SessionId']}`;
      this.httpService.PosthttpMethod(EndPointUrl, null).subscribe(_ => {
        console.log('UpdateLogoutDetails Success')
      });
    } catch (error) {
      console.log(error);
    }
  }
  GetWholeNumberLength(IsCurrency = false) {
    try {
      const propertyName = IsCurrency ? 'WholeNumberCurrencyLength' : 'WholeNumberLength';
      const WholeDigit = sessionStorage.getItem('appsetting') ?
      JSON.parse(sessionStorage.getItem('appsetting'))[propertyName] :
         myGlobals[propertyName];
      return WholeDigit;
    } catch (error) { 
      console.error('Error occurred in GetWholeNumberLength', error);
    }
  }

  GetVisibleNumberLength() {
    try {
      const VisibleDigit = sessionStorage.getItem('INIDecimalValue') ? 
      parseInt(sessionStorage.getItem('INIDecimalValue'), 10) :
       sessionStorage.getItem('appsetting') ?
      JSON.parse(sessionStorage.getItem('appsetting')).VisibleNumberLength :
         myGlobals.VisibleNumberLength;
      return VisibleDigit;
    } catch (error) { 
      console.error('Error occurred in GetVisibleNumberLength', error);
    }
  }

  GetFocusNumberLength(IsCurrency = false) {
    try {
      const propertyName = IsCurrency ? 'FocusNumberCurrencyLength' : 'FocusNumberLength';
      const FocusDigit = sessionStorage.getItem('appsetting') ?
      JSON.parse(sessionStorage.getItem('appsetting'))[propertyName] :
         myGlobals[propertyName];
      return FocusDigit;
    } catch (error) { 
      console.error('Error occurred in GetFocusNumberLength', error);
    }
  }

  public static GetCurrencyVisible() {
    try {
      const VisibleDigit = sessionStorage.getItem('INICurrencyValue') ? 
      parseInt(sessionStorage.getItem('INICurrencyValue'), 10) :
       (sessionStorage.getItem('appsetting') ?
      JSON.parse(sessionStorage.getItem('appsetting')).VisibleNumberCurrencyLength :
         myGlobals.VisibleNumberCurrencyLength);
      return VisibleDigit;
    } catch (error) { 
      console.error('Error occurred in GetVisibleNumberLength', error);
    }
  }

  public static getCurrencyCode(CurrencyId: number): string {
    try {
       const currencyList = JSON.parse(sessionStorage.getItem('CurrencyList'));
       const Currencies = constants.currencySymbol;
       const currencyCode = Currencies.Symbols?.filter(x => x.Id === CurrencyId)[0];
       return (currencyCode ? currencyCode.Code : 'USD');
    } catch (error) {
        console.error('Error occurred in triggerBlurEvent', error);
    }
  } 

  public static triggerBlurEvent(ElementName: string): void {
    try {
       if (document.getElementById(ElementName)) {
        document.getElementById(ElementName).dispatchEvent(new CustomEvent('bluraction', {'detail': 'customaction'}));
    }
    } catch (error) {
        console.error('Error occurred in triggerBlurEvent', error);
    }
  } 

  IsRguestEnabled(): boolean {    
    let rguestTenantId;
    const checkTenant = sessionStorage.getItem('RGuestTenantId');
    const isEatecUser = sessionStorage.getItem('isEatecUser');
    if (checkTenant && JSON.parse(checkTenant)) {
      rguestTenantId = true;
    }
    return (rguestTenantId || (isEatecUser && !JSON.parse(isEatecUser)));
  }

  deserializeData ( responseData: any, TextCasing: string)  {
    try {
      return this.convertCaseForProperty(responseData, TextCasing);
    } catch (error) {
      console.error('Error occurred in deserializeData', error);
    }
  }

  convertCaseForProperty(o: any, TextCasing: string) {
    const _that = this;
    let newO, origKey, newKey, value;
    if (o instanceof Array) {
      return o.map(function(value) {
          if (typeof value === 'object') {
            value = _that.convertCaseForProperty(value, TextCasing);
          }
          return value;
      })
    } else {
      newO = {}
      for (origKey in o) {
        if (o.hasOwnProperty(origKey)) {
          if (TextCasing === 'camelCase') {
            newKey = (origKey.charAt(0).toLowerCase() + origKey.slice(1) || origKey).toString();
          } else {
            newKey = (origKey.charAt(0).toUpperCase() + origKey.slice(1) || origKey).toString();
          }
          value = o[origKey];
          if (value instanceof Array || (value !== null && value.constructor === Object)) {
            value = _that.convertCaseForProperty(value, TextCasing);
          }
          newO[newKey] = value;
        }
      }
    }
    return newO;
  }

  async getPrintId(PrintKey: string): Promise<any> {
    try {
      const PrintList = [];
      const response = await fetch('./app/eatecui/assets/json/report/report-print.json');
      const PrintReportJson = await response.json();
      const printList = PrintReportJson.filter(x => x.Key.toString().toLowerCase() === PrintKey.toString().toLowerCase());
      if (printList && printList.length > 0) {
        const printId = printList[0].Id;
        return printId;
      }
    } catch (error) {
      console.log('Error occurred in getPrintId', error);
    }
  }

  async getPrintListById(PrintId: number): Promise<Array<any>> {
    try {
      if (PrintId > 0) {        
        const Printenpoint = CONSTANT.ENDPOINTURL['Report']['GetReportModulePrint'] + '?id=' + PrintId;
        const getPrintList = await this.httpService.GethttpMethod(Printenpoint).toPromise();
        return getPrintList[0].ChildModules;
      } else {
        return [];
      }
    } catch (error) {
      console.log('Error occurred in getPrintById', error);
    }
  }

  /**
   * @method syncCheck
   * @params null
   * @description IG Integration sync check
   */
  async syncCheck(selectedDivisionId: number) {
    try {
      return new Promise<boolean>(resolve => {
        const igIntegrationUrl = CONSTANT.ENDPOINTURL['IGIntegration']['GetAllProductClasses'] + '/' + selectedDivisionId.toString();
        this.httpService.GethttpMethod(igIntegrationUrl).subscribe(res => {
          if (res && res['Success']) {
            let ToastMessage = res['Message'];
            this.toastrService.success(ToastMessage, '', {
              timeOut: 3000,
              closeButton: true
            });
            return resolve(true);
          } else {
            this.toastrService.error('Sync is failed.', '', {
              timeOut: 3000
            });
            return resolve(false);
          }
        });
      });
    } catch (error) {
      console.error('Error occured in IG Sync Check - ', error);
    }
  }

  /**
   * @method verifyIntegrationConfig
   * @params selected module name
   * @description To return the button model if Integration is true in tenant
   */
  verifyIntegrationConfig(selectedModuleName: string): any {
    try {
      let integrationButton: Array<AgilysysButtonModel | TransactionButtonModel> = [];
      const integrationConfigDetails = this.integrationService?.IntegrationConfig?.IntegrationCollection;
      integrationConfigDetails.forEach((integrationName: any) => {
        switch (integrationName.IntegratonName) {
          case 'InfoGenesis':
            if (integrationName.IsEnabled && selectedModuleName === 'Products') {
              const infogenesis: AgilysysButtonModel  = this.igIntegrationConfig();
              integrationButton.push(infogenesis);
            }
            break;
          case 'ARGLExport':
            /**
             * Export ARGL permission is on both Tenant Level and Role Permission Level 
             */
            if (integrationName.IsEnabled && selectedModuleName === 'quickorders') {
              const createPermissionEnabled = this.exportARGLRolePermission();              
              if (createPermissionEnabled) {
                const exportARGLButton: TransactionButtonModel  = this.exportARGLConfig();
                integrationButton.push(exportARGLButton);
              }
            }
            break;
          default:
            break;
        }
      });
      return integrationButton;
    } catch (error) {
      console.error('Error occured in Verify Integration Config - ', error);
    }
  }

  /**
   * @method igIntegrationConfig
   * @params null
   * @description To Form Button Model for Sync Button
   */
  igIntegrationConfig() {
    try {
      let buttonModel = {} as AgilysysButtonModel;
      buttonModel = {
          Id: 2,
          Name: 'Sync',
          DisableProperity: false,
          DisplayName: 'Sync',
          Class: 'agilysys-button-primary',
      };
      return buttonModel;
    } catch (error) {
      console.error('Error occured in IG Integration Config Method - ', error);
    }
  }

  /**
   * @method exportARGLConfig
   * @params null
   * @description To Form Button Model For Export ARGL Button in Quick Orders Screen
   * @returns button model
   */
  exportARGLConfig() {
    try {
      let buttonModel = {} as TransactionButtonModel;
      buttonModel = {
        displayName: 'Export AR',
        buttonType: 'agilysys-button-primary',
        displayProperity: false,
        buttonKey: 'export-argl'
      };
      return buttonModel;
    } catch (error) {
      console.error('Error occured in IG Integration Config Method - ', error);
    }
  }
  /**
   * @method exportARGLRolePermission
   * @params null
   * @description Export ARGL permission is based on Create Quick Order Permission in Roles
   * @returns boolean
   */
  exportARGLRolePermission() {
    let createPermissionEnabled = false;
    const AuthorizationData = JSON.parse(window.sessionStorage.getItem('AuthorizationData'));
    const GetAuthorizationModuleName = AuthorizationModule.TRANSACTION;
    const authDataModel: Array<AuthorizedDataModel> = AuthorizationData.
    filter((x: AuthorizedDataModel) => x.Name === GetAuthorizationModuleName);
    const subModuleIndex = authDataModel[0].SubModules.findIndex(x => x.Name === AuthSubModule.SALES);
    const createPermission: PermissionResource = authDataModel[0].SubModules[subModuleIndex].PermissionResource
    .filter(y => y.Name === AuthoriztionKey.CREATEQUICKORDER)[0];
    if (createPermission) {
       createPermissionEnabled = createPermission.IsActive;
    }
    return createPermissionEnabled;
  }
  
  setCurrencyInUtility() {
    try {
      const localCurrencyCode = sessionStorage.getItem('LocalCurrencyCode');
      const INICurrencyValue = sessionStorage.getItem('INICurrencyValue');
      let decimalValue = INICurrencyValue ? parseInt(INICurrencyValue, 10) : sessionStorage.getItem('appsetting') ? 
        JSON.parse(sessionStorage.getItem('appsetting')).VisibleNumberCurrencyLength : ConversionValue.ConversionTwo;
      this.utilityService.CurrencyFormatter = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: localCurrencyCode,
        minimumFractionDigits: decimalValue,
        maximumFractionDigits: decimalValue,
        minimumIntegerDigits: 1
      });
      this.utilityService.CurrencyFormatterWithOutStyle = new Intl.NumberFormat('en-US', {
        currency: localCurrencyCode,
        minimumFractionDigits: decimalValue,
        maximumFractionDigits: decimalValue,
        minimumIntegerDigits: 1
      });
    } catch (error) {
      console.log('Error occurred in setCurrencyInUtility', error);
    }
  }

  public static SetCurrencySymbolInUtility(Code?: String, Value?, TakeSymbolOnly?: boolean) {
    try {
      let symbol;
      const Currencies = constants.currencySymbol;
      symbol = Currencies.Symbols.filter(curr => curr.Code === Code)[0].Symbol;
      if(Value && Code && Value.includes(Code)) {
        Value = Value.replace(Code, symbol);
        return TakeSymbolOnly ? symbol : Value;
      } else {
        return TakeSymbolOnly ? symbol : Value;
      }
    } catch(error) {
      console.log(error);
      return Value;
    }
  }
  
  GetCurrencies() {
    if (!sessionStorage.getItem('CurrencyList')) {
      const CurrencyGetUrl = CONSTANT.ENDPOINTURL['InvertoryManagement']['GetCurrencies'];
      this.httpService.GethttpMethod(CurrencyGetUrl).subscribe(res => {
          const currencyList = res['ResultData'] ? res['ResultData'] : res;
          sessionStorage.setItem('CurrencyList', JSON.stringify(currencyList));
          const CurrencyList : any = JSON.parse(sessionStorage.getItem('CurrencyList'));
          const PropertyCurrencyCode = sessionStorage.getItem('LocalCurrencyCode');
          const BaseCurrencyId = CurrencyList.filter(x=>x.Code == PropertyCurrencyCode)[0]?.Id;
          sessionStorage.setItem('BaseCurrency', (BaseCurrencyId ? BaseCurrencyId : 164));
      });
    }
    } 

/**
* @method GetPrinterLabell
* @input ModuleName
* @description Inventory GetprintLabel 
*/
    async GetPrinterLabell(ModuleName: string): Promise<Array<any>> {
        try {
            if (ModuleName) {
              const AuthorizationData = JSON.parse(window.sessionStorage.getItem('AuthorizationData'));
              const authReportDataModel: Array<AuthorizedDataModel> = AuthorizationData?.filter((x: AuthorizedDataModel) => x.Name === AuthorizationModule.REPORTSMANGEMENT);
              let PrintCheck = [] as AuthorizationSubModule[];
              if (authReportDataModel && authReportDataModel.length > 0) {
                authReportDataModel.forEach((DataModel: AuthorizedDataModel) => {
                  if (DataModel && DataModel.hasOwnProperty('SubModules') && DataModel.SubModules.length > 0) {
                    PrintCheck = DataModel.SubModules.filter(x =>
                      x.Name.includes('reports')).filter(e =>
                        e.PermissionResource.filter(f =>
                          (f.Name === 'Reports') && f.IsActive).length > 0);
                  }
                });
              }
              if (!PrintCheck?.length) return [];
              const printLabelUrl = CONSTANT.ENDPOINTURL['Report']['GetModuleTemplate'] + '?moduleName=' + ModuleName;
              const getPrintLabelList = await this.httpService.GethttpMethod(printLabelUrl).toPromise();
              return getPrintLabelList;
            }
        } catch (error) {
            console.log('Error occurred in getPrintById', error);
        }
    }

    /**
     * @method GetLocationsBySiteId
     * @input SiteId
     * @description GetLocation Id Based on Site Id
     */
    GetLocationsBySiteId(sitesId: number) {
      try {
          let endpointurl = '';
          if (sitesId !== 1) {
              endpointurl = `/inventory/api/Locations?$filter=(SiteId eq ${sitesId} or (LocationTypeId eq 10 and SiteId ne null ) or (Id eq -1)) and IsActive eq true&$select=Id`;
          } else {
              endpointurl = `/inventory/api/Locations?$filter=IsActive eq true and ((LocationTypeId ne 8 and SiteId ne null) or Id eq -1)&$select=Id`;
          }
          sessionStorage.setItem(constants.LoggedInSiteId, sitesId?.toString());
          this.httpService.GethttpMethod(endpointurl).subscribe(result => {
              if ( result['value'].length > 0) {
                  const LocIds = result['value'].map(res => {
                      return res.Id;
                  });
                  sessionStorage.setItem(constants.LocationIds, JSON.stringify(LocIds));
                  this.constractUserLocationId(LocIds);
              }
          });
      } catch (error) {
          console.error('Error occured in GetLocationsBySiteId');
      }
  }

    /**
     * @method constractUserLocationId
     * @description Filtered Site based Location Id with Logged In LocationId
     * @Logeed In Site Base Location ID
     */
    constractUserLocationId(SiteBasedLocationId: Array<number>): void {
      try {
        let GetLoggedLoctionIds: Array<number> = []
        const UserLocation = StorageService.GetSessionStorage('locationId');
        const LoggedInSiteId = sessionStorage.getItem('LoggedInSiteId');
        GetLoggedLoctionIds = SiteBasedLocationId
        if( UserLocation ){
          const LogedInUserLocationId: Array<number> = UserLocation.split(',').map(function (item) { return parseInt(item, 10);}); 
          if( SiteBasedLocationId?.length > 0 &&  LogedInUserLocationId?.length > 0 && LoggedInSiteId !== '1') {
            const FilteredLoggedInLocationId: Array<number> = SiteBasedLocationId.filter((Id: any) => LogedInUserLocationId.some(UserLocationId => UserLocationId === Id)).map((x: any) => x);
            if( FilteredLoggedInLocationId?.length > 0 ){
              GetLoggedLoctionIds = FilteredLoggedInLocationId;
            }
          }
        }
        sessionStorage.setItem(constants.LocationIds, JSON.stringify(GetLoggedLoctionIds)); 
      } catch(error){
        console.error(error);
      }
    }

    /**
     * @method enableGridLoader
     * @input Grid Input
     * @description enableGridLoader
     */
    enableGridLoader(gridTable: GridTableComponent, enableLoader:boolean): void {
      try{
        if( gridTable ) {
          if( enableLoader ){
            gridTable?.gridApi?.showLoadingOverlay();
          } else {
            gridTable?.gridApi?.hideOverlay();
          }
        }
      } catch(error){
        console.error(error);
      }
    }

    /**
     * method @enableIconLoader
     * @input Grid Input
     * @description enableIconLoader
     */
  enableIconLoader(GridApiValue: any,isGridValue:boolean): void {
    try {
      if (GridApiValue) {
        let gridElement = GridApiValue;
        if (isGridValue) {
          Array.from(GridApiValue?.eGridCell?.children[0]?.children[0]?.children).forEach(function (element) {
            if (element['className'].includes('icon-information-eatec')) {
              gridElement = element as HTMLElement;
            }
          });
        }
        gridElement.style.height = gridElement.parentElement.clientHeight + 'px';
        gridElement['classList'].add('spinner');
        gridElement['classList'].remove('icon-information-eatec');
      }
    } catch (error) {
      console.error(error);
    }
  }

  public static  GetsessionStorageValue(key: string, name: string):string {
    try {
    var nameEQ = name + "=";
    var propertyInfo = sessionStorage.getItem(key);
    if (propertyInfo != null) {
      var ca = propertyInfo.split(";");

      for (let property of ca) {
        var c = property.trim();
        while (c.charAt(0) == ' ') c = c.substring(1, c.length);
        if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length, c.length);
      }
    }
    return null;
  }
  catch (error) {
    console.error('Error occurred in GetsessionStorageValue', error);
}

  }
}


