import { Component, OnInit, ViewEncapsulation } from "@angular/core";
import { Localization } from "src/app/common/localization/localization";
import { CommonUtilities } from "../../utilities/common-utilities";
import { UntypedFormBuilder, UntypedFormGroup, Validators } from "@angular/forms";
import { takeUntil } from "rxjs/operators";
import { ReplaySubject } from "rxjs";
import { emailLogConfigurationBusiness } from "./email-log-configuration.business";
import { EmailProcessingDataService } from "src/app/common/dataservices/notification/email-processing-data.service";
import { EmailSetting } from "../email-logs/email-logs.model";
import { AlertType } from "src/app/common/Models/common.models";
import { AlertAction, ButtonType } from "src/app/common/enums/shared-enums";
import { ButtonValue } from "src/app/common/Models/ag-models";

@Component({
  selector: 'app-email-log-configuration',
  templateUrl: './email-log-configuration.component.html',
  styleUrls: ['./email-log-configuration.component.scss'],
  encapsulation: ViewEncapsulation.None,
  providers: [emailLogConfigurationBusiness, EmailProcessingDataService]
})
export class EmailLogConfigurationComponent implements OnInit{
    captions: any;
    mailSettingForm: UntypedFormGroup;
    emailSaveButton: ButtonValue;
    emailCancelButton: ButtonValue;
    destroyed$: ReplaySubject<boolean> = new ReplaySubject(1);
    floatLabel: string = '';
    emailSettingId: number = 0;
    emailSetting: EmailSetting = {} as EmailSetting;
    constructor(private localization: Localization, private utils: CommonUtilities,private fb: UntypedFormBuilder, private business: emailLogConfigurationBusiness){
        this.captions = this.localization.captions;
        this.floatLabel = this.localization.setFloatLabel;
    }

    ngOnInit(): void{
        this.initializeForm();
        this.getEmailSetting();
    }

    initializeForm() {
        this.mailSettingForm = this.fb.group({
            sendMail: ['', [Validators.required, Validators.email]],
            receiveMail: ['', [Validators.required, Validators.email]],
        })

        this.emailSaveButton = {
            label: this.captions.btn_save,
            type: 'primary',
            disabledproperty: true
        }

        this.emailCancelButton = {
            label: this.captions.btn_cancel,
            type: 'secondary',
            disabledproperty: false
        }

        this.mailSettingForm.valueChanges.pipe(takeUntil(this.destroyed$)).subscribe(res => {
            this.emailSaveButton.disabledproperty = !(this.mailSettingForm.valid && this.mailSettingForm.dirty);
        })
    }

    async getEmailSetting(){
        this.emailSetting = await this.business.getEmailSetting();
        this.patchForm();
    }

    patchForm(){
        if(this.emailSetting){
            this.mailSettingForm.get('sendMail').setValue(this.emailSetting.sentEmail);
            this.mailSettingForm.get('receiveMail').setValue(this.emailSetting.receiveMail);
            this.emailSettingId = this.emailSetting.id;
        }
    }

    async saveEmailSetting(){
        let saveObj ={
            id: this.emailSettingId,
            sentMail: this.mailSettingForm.get('sendMail').value,
            receiveMail: this.mailSettingForm.get('receiveMail').value
        };
        this.emailSetting = this.emailSettingId > 0 ? await this.business.updateEmailSetting(saveObj) : await this.business.createEmailSetting(saveObj);
        this.emailSettingId = this.emailSetting.id;
        this.utils.showAlert(this.captions.configSaveSuccessFrom, AlertType.Success, ButtonType.Ok);
        this.mailSettingForm.markAsPristine();
        this.disableActionButton();
    }

    cancelEmailSetting(){
        if(this.mailSettingForm.dirty){
            this.utils.showAlert(this.captions.warn_datalost, AlertType.Warning, ButtonType.YesNo, res => {
                if(res === AlertAction.YES){
                    this.mailSettingForm.reset();
                    this.patchForm();
                    this.disableActionButton();
                }
            })
        }
        else{
            this.mailSettingForm.reset();
            this.patchForm();
        }
    }

    disableActionButton(){
        this.emailSaveButton.disabledproperty = true;
        this.emailSaveButton = {...this.emailSaveButton};
    }
}