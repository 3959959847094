import { Component, OnInit } from '@angular/core';
import { Localization } from 'src/app/common/localization/localization';

@Component({
  selector: 'app-deposity-policy-wrapper',
  templateUrl: './deposity-policy-wrapper.component.html',
  styleUrls: ['./deposity-policy-wrapper.component.scss']
})
export class DeposityPolicyWrapperComponent implements OnInit {

  captions: any;
  tableOptions;
  tableContent = [];

  constructor(private localization: Localization) {
    this.captions = this.localization.captions;
   }

  ngOnInit(): void {
    this.tableContent = this.getTableContent();
    this.getTableOptions();
  }

getTableOptions() {
  let arrDataHeader = [
    { 'title': this.captions.tbl_hdr_policyCode, 'jsonkey': 'code', 'searchable': true, 'sortable': true },
    { 'title': this.captions.tbl_hdr_policyName, 'jsonkey': 'name', 'searchable': true, 'sortable': true },
    { 'title': this.captions.tbl_hdr_description, 'jsonkey': 'description', 'searchable': false, 'sortable': true },
    { 'title': this.captions.tbl_hdr_dateRange, 'jsonkey': 'dateRange', 'searchable': false, 'sortable': true},
    { 'title': this.captions.tog_lbl_active, 'jsonkey': 'isActive', 'searchable': false, 'sortable': false, "type": "toggle" },

  ]
  this.tableOptions = [
    {
      TableHdrData: arrDataHeader,
      TablebodyData: this.tableContent,
      pagination: false,
      sortable: true,
      editable: true,
      CustomColumn: true,
      PlaceHoldertext: this.captions.lbl_searchByPolicyCodeName,
      EnableActions: true,
      SelectRows: false,
      Searchable: true,
      EditMoreOption: false,
      Sortable: 'code',
      TableId: '',
      disableDelete: false,
      SelectRow: true,
      SelectOnlyRow: true,
      dropdownOptions: '',
      waitlistIcon: false,
      TableDraggable: false,
      sticky: false,
      DoneCancel: false,
      IsViewOnly: false,
      disableEditButton: false,
      isCopyEnabled : true,
      ServiceId : 'viptype'
    }
  ];
  this.tableOptions = [...this.tableOptions];
}
getTableContent(){
   return[
       {
        code: "1234",
         name: "Test",
         description : "Test test",
         dateRange: "12 Jan 2024 - 15 Jan 2024",
         isActive: false
       }
   ]
}

}

