import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { AgInputFieldConfig, ButtonValue, AgToggleConfig } from 'src/app/common/Models/ag-models';
import { ActionMode } from 'src/app/common/shared/shared/enums/enums';
import { LetterSetupBusiness } from '../../letter-setup/letter-setup.business';
import { CreateLetterInputs, LetterCrudActionInput, LetterForm } from '../../letter-setup/letter.model';
import { Localization } from 'src/app/common/localization/localization';
import { EditorInputs, TemplateTags } from '../../shared/shared.modal';
import { CommonUtilities } from 'src/app/common/shared/shared/utilities/common-utilities';
@Component({
  selector: 'app-create-letter',
  templateUrl: './create-letter.component.html',
  styleUrls: ['./create-letter.component.scss'],
  providers: [LetterSetupBusiness],
  encapsulation: ViewEncapsulation.None
})
export class CreateLetterComponent implements OnInit {
  actionButton: ButtonValue;
  cancelButton: ButtonValue;
  captions: any;
  changeInputValues: LetterForm;
  codeInputs: AgInputFieldConfig;
  editorInput: EditorInputs;
  letterForm: UntypedFormGroup;
  listOrderInputs: AgInputFieldConfig;
  nameInputs: AgInputFieldConfig;
  templateTags: TemplateTags[];
  message: string;
  isCopy: boolean;
  toggleDefaultInput: AgToggleConfig;
  toggleActiveInput: AgToggleConfig;
  copyMessageKey = 'name';
  @Input() crudInput;
  @Input() createInputs: CreateLetterInputs;
  @Input() isDefaultDisable : boolean
  public crudInputObject: LetterCrudActionInput;
  disableToggle = false;
  @Input('crudInput')
  set createCrudInput(value: LetterCrudActionInput) {
    this.crudInputObject = value;
    this.intializePage();
  }

  @Output() formReady = new EventEmitter();
  @Output() handleClickEvent = new EventEmitter();

  constructor(private fb: UntypedFormBuilder, private settingsLettersBusiness: LetterSetupBusiness, private localization: Localization,private _utilities: CommonUtilities) {
    this.captions = this.localization.captions.retailsetup;
   }

  ngOnInit(): void {   
    this.intializePage();
  }


  editAction() {
    if (this.crudInputObject.mode === ActionMode.update) {


      this.codeInputs.isEditMode = true;
      // this.codeInputs.disabled
      //  = true;
      this.codeInputs = { ...this.codeInputs };
    }
  }
  intializePage() {
    this.actionButton = {
      label: this.crudInputObject.actionButton,
      type: 'primary',
      disabledproperty: true
    };
    this.cancelButton = {
      label: this.captions.btn_cancel,
      type: 'tertiary'
    };
    
    this.letterForm = this.fb.group({
      code: ['', Validators.required],
      name: ['', Validators.required],
      listOrder: '',
      active: true,
      templateData: '',
      type: '',
      id: '',
      default: this.isDefaultDisable,
    });
    this.formReady.emit(this.letterForm);
    this.codeInputs = {
      className: 'ag_w--100',
      placeHolderId: 'codePlaceholder',
      placeHolder: this.createInputs.codePlaceholder,
      form: this.letterForm,
      formControlName: 'code',
      showRequired: true,
      minlength: 1,
      maxlength: 12,
      errorMessageId: 'err_missing}  ${this.createInputs.codePlaceholder}`',
      errorMessage: `${this.captions.err_missing}  ${this.createInputs.codePlaceholder}`,
      isEditMode: (this.crudInputObject.mode === ActionMode.update),
      changeLabelOnEdit : true
    };
    this.nameInputs = {
      className: 'ag_w--100',
      placeHolderId: 'namePlaceholder',
      placeHolder: this.createInputs.namePlaceholder,
      form: this.letterForm,
      formControlName: 'name',
      showRequired: true,
      minlength: 1,
      maxlength: 50,
      errorMessageId: '',
      errorMessage: `${this.captions.err_missing}  ${this.createInputs.namePlaceholder}`
    };

    this.listOrderInputs = {
      className: 'ag_form-control--xs',
      placeHolderId: 'lbl_listOrder',
      placeHolder: this.captions.lbl_listOrder,
      form: this.letterForm,
      formControlName: 'listOrder',
      showRequired: true
    };

    this.editorInput = {
      formGroup: this.letterForm,
      formControlName: 'templateData',
      showTags: true,
      showCollapse: false,
      showSearch: true
    };
    this.toggleActiveInput = {
      group: this.letterForm,
      formControlName: 'active',
      disabled: this.disableToggle
    };
    this.toggleDefaultInput = {
      group: this.letterForm,
      formControlName: 'default',
      disabled: this.disableToggle
    };
    this.settingsLettersBusiness.getTemplateTags(this.createInputs.letterType).then(x => {
      this.templateTags = x;
    });

    this.isCopy = this.crudInputObject.isCopy;
    if (this.isCopy) {
      this.message = this.localization.replacePlaceholders(this.captions.lbl_copyText, ['code'],
        [this.crudInputObject.form[this.copyMessageKey]]);
    }

    this.patchFormData();
  }

  patchFormData() {
    switch (this.crudInputObject.mode) {
      case ActionMode.copy:
      case ActionMode.update:
        this.patchFormValue();
        this.editAction();
        if (ActionMode.copy) {
          this.patchListOrder();
        }
        break;
      case ActionMode.create:
        this.patchLetterType();
        this.patchListOrder();
        break;
    }
  }

  async patchListOrder() {
    if (this.crudInputObject && this.letterForm) {
      this.letterForm.get('listOrder').setValue(await this.crudInputObject.listOrder);
    }
  }

  patchLetterType() {
    if (this.createInputs && this.letterForm) {
      this.letterForm.get('type').setValue(this.createInputs.letterType);
    }
  }

  formStatusChangeEvent(e) {
    if (this.crudInputObject.mode === ActionMode.copy) {
      this.actionButton.disabledproperty = !(e.formValid && this.letterForm.dirty);
    } else {
      this.actionButton.disabledproperty = !(e.formValid && this.letterForm.dirty && e.objectNotEqual);
    }
    if (this.crudInputObject.isViewOnly) {
      this.actionButton.disabledproperty = true;
    }
    this.actionButton = { ...this.actionButton };
  }

  patchFormValue() {
    this.changeInputValues = this.crudInputObject.form;
    this.letterForm.patchValue(this.crudInputObject.form);
  }

  onAction(event) {
    const cleanData = this._utilities.sanitizeHTMLTemplate(this.letterForm.get('templateData')?.value);
    this.letterForm.get('templateData').setValue(cleanData);
    const clickReturnValue = {
      from: this.crudInputObject.mode,
      formValues: this.letterForm.value
    };
    this.actionButton.disabledproperty = true;
    this.actionButton = { ...this.actionButton };
    this.handleClickEvent.emit(clickReturnValue);
  }

  onCancel(event) {
    const clickReturnValue = {
      from: ActionMode.cancel,
      formValues: this.letterForm.value
    };
    this.handleClickEvent.emit(clickReturnValue);
  }

  hideWarning() {
    this.isCopy = false;
  }

}
