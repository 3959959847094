
import { Localization } from 'src/app/common/shared/localization/Localization';
import { Injectable } from '@angular/core';

import moment from 'moment';
import { RecurringType } from './recurring-event.model';
import { DropdownOptions } from '../../Models/ag-models';
import { Calendar, DaysModel } from '../../localization/localization.model';
@Injectable()
export class RecurringEventBusiness {
  commonCaptions: any;
  weeklyData: RecurringType[];
  months: DropdownOptions[];

  constructor(private localization: Localization) {
    this.commonCaptions = this.localization.captions.common;
    this.weeklyData = this.localization.daysArray.filter(x => x.code !== 'All').map(x => { return { id: (x.id == 7) ? 0 : x.id, name: x.short, description: x.code, disabled: false } });
    this.months = this.localization.monthsArray.map(x => { return { id: x.id, value: x.short, viewValue: x.code } });
  }

  getDaysArray() {
      const longWeekArr = this.localization.getLongDaysOfWeek();
      const shortWeekArr = this.localization.getShortDaysOfWeek();
      const returnArr: DaysModel[] = [];
      const localizedCalender: Calendar = this.localization.captions.calendar;
      for (let i = 0; i < longWeekArr.length; i++) {
        const localelongDay = longWeekArr[i];
        const localeShortDay = shortWeekArr[i];
        switch (localelongDay) {
          case localizedCalender.Monday:
            returnArr.push({
              id: 1,
              short: localeShortDay,
              long: localelongDay,
              code: 'Mon',
              longCode: 'Monday'
            });
            break;
          case localizedCalender.Tuesday:
            returnArr.push({
              id: 2,
              short: localeShortDay,
              long: localelongDay,
              code: 'Tue',
              longCode: 'Tuesday'
            });
            break;
          case localizedCalender.Wednesday:
            returnArr.push({
              id: 4,
              short: localeShortDay,
              long: localelongDay,
              code: 'Wed',
              longCode: 'Wednesday'
            });
            break;
          case localizedCalender.Thursday:
            returnArr.push({
              id: 8,
              short: localeShortDay,
              long: localelongDay,
              code: 'Thu',
              longCode: 'Thursday'
            });
            break;
          case localizedCalender.Friday:
            returnArr.push({
              id: 16,
              short: localeShortDay,
              long: localelongDay,
              code: 'Fri',
              longCode: 'Friday'
            });
            break;
          case localizedCalender.Saturday:
            returnArr.push({
              id: 32,
              short: localeShortDay,
              long: localelongDay,
              code: 'Sat',
              longCode: 'Saturday'
            });
            break;
          case localizedCalender.Sunday:
            returnArr.push({
              id: 64,
              short: localeShortDay,
              long: localelongDay,
              code: 'Sun',
              longCode: 'Sunday'
            });
            break;
          default:
            break;
        }
      }
  
      return returnArr;

  }

  everyDD(): DropdownOptions[] {
    const data = [
      { id: 1,  value: 1,  viewValue: this.localization.captions.First },
      { id: 2,  value: 2,  viewValue: this.localization.captions.Second },
      { id: 3,  value: 3,  viewValue: this.localization.captions.Third },
      { id: 4,  value: 4,  viewValue: this.localization.captions.Fourth },
      { id: 5,  value: 5,  viewValue: this.localization.captions.lbl_Last }
    ];
    return data;
  }

  everyDays(): DropdownOptions[] {
    const data =this.getDaysArray();
    const days=[];
    data.forEach(x=>{
      days.push({
        id: x.id, 
        value: x.code, 
        viewValue: x.longCode
      });
    });
    days.push(
    {
      id:128,
      value: this.localization.captions.drp_txt_weekdays,
      viewValue:this.localization.captions.drp_txt_weekdays
    },
    {
      id:256,
      value: this.localization.captions.drp_txt_weekdays,
      viewValue: this.localization.captions.drp_txt_weekdays
    });
    
    return days;
  }


  dateDiff(startDate, endDate) {
    const start = moment(startDate);
    const end = moment(endDate);
    return end.diff(start, 'days');
  }

  monthDiff(startDate, endDate) {
    const start = moment(startDate);
    const end = moment(endDate);
    return end.diff(start, 'month');
  }

  yearDiff(startDate, endDate) {
    const start = moment(startDate);
    const end = moment(endDate);
    return end.diff(start, 'year');
  }
}