import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Localization } from '../../localization/localization';
import { DashboardConfigurationBusiness } from './dashboard-configuration.business';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { ButtonValue } from 'src/app/common/Models/ag-models';
import { Subscription } from 'rxjs';
import { CommonUtilities } from '../../shared/shared/utilities/common-utilities';
import { AlertType, AlertAction, ButtonType } from 'src/app/common/shared/shared.modal';

@Component({
  selector: 'app-dashboard-configuration',
  templateUrl: './dashboard-configuration.component.html',
  styleUrls: ['./dashboard-configuration.component.scss'],
  providers: [DashboardConfigurationBusiness]
})

export class DashboardConfigurationComponent implements OnInit {
  captions: any;
  dashboardViews: any[] = [];
  dashboardConfigurationForm: UntypedFormGroup;
  productConfigurationForm: UntypedFormGroup;
  saveButton: ButtonValue;
  cancelButton: ButtonValue;
  formsubcriber: Subscription;
  dashboardData: any;
  isExpandSection: boolean = true;
  @Input('productDashboardConfig')
  set setProductConfig(value){
    if(value){
      this.productConfigurationForm = value;
      this.productSubscribeForm();
    }
  }
  @Output() handleCancelEvent = new EventEmitter();
  @Output() onSaveSuccess: EventEmitter<any> = new EventEmitter<any>();
  constructor(private localization: Localization, private _fb: UntypedFormBuilder, private business: DashboardConfigurationBusiness, private utils: CommonUtilities) {
    this.captions = this.localization.captions;
  }
  ngOnInit() {
    this.formGenerator();
    this.getCustomizedDashboard();
  }

  formGenerator() {
    this.saveButton = {
      type: 'primary',
      label: this.captions.btn_save,
      disabledproperty: true
    };
    this.cancelButton = {
      type: 'secondary',
      label: this.captions.btn_cancel,
      disabledproperty: false
    };
    this.dashboardConfigurationForm = this._fb.group({    
    });
    this.subscribeForm();
  }

  subscribeForm(){
    this.formsubcriber = this.dashboardConfigurationForm.statusChanges.subscribe(() => {
      let isActionButtonsDisable = this.business.shouldActionButtonsDisable(this.dashboardConfigurationForm);
      this.disableActionButtons(isActionButtonsDisable.isSaveDisable, isActionButtonsDisable.isCancelDisable);
    });
  }

  productSubscribeForm(){
    this.formsubcriber = this.productConfigurationForm.statusChanges.subscribe(() => {
      let isActionButtonsDisable = this.business.shouldActionButtonsDisableProduct(this.productConfigurationForm, this.dashboardConfigurationForm);
      this.disableActionButtons(isActionButtonsDisable.isSaveDisable, isActionButtonsDisable.isCancelDisable);
    });
  }

  async getCustomizedDashboard() {
    this.dashboardData = await this.business.getCustomizedDashboard();
  }

  async onSave(eve) {
    this.dashboardData = await this.business.save(this.dashboardConfigurationForm.value);
    this.dashboardConfigurationForm.markAsPristine();
    this.disableActionButtons();
    this.onSaveSuccess.emit(this.productConfigurationForm);
    let dontShowSuccessAlert = (!this.dashboardData || this.dashboardData.length == 0) && !this.productConfigurationForm
    if(!dontShowSuccessAlert){
      this.utils.showAlert(this.captions.alertPopup.successfullysaved, AlertType.WellDone, ButtonType.Ok);
    }
  }

  onCancel(eve) {

    this.utils.showAlert(this.captions.warn_datalost, AlertType.Warning, ButtonType.YesNo, (res) => {
      if (res === AlertAction.YES) {
        this.dashboardData = [...this.dashboardData];
        this.dashboardConfigurationForm.markAsPristine();
        this.disableActionButtons();
        this.handleCancelEvent.emit();
      }
    });
  }

  private disableActionButtons(isSaveBtnDisable: boolean = true, isCancelBtnDisable: boolean = true) {
    this.saveButton.disabledproperty = isSaveBtnDisable;
    this.cancelButton.disabledproperty = isCancelBtnDisable;
  }

  expandCollapse(){
    this.isExpandSection = !this.isExpandSection;
  }

  dashboardConfigEmit(event){
    this.dashboardConfigurationForm.setControl('dashboardConfigArray', event);
  }
}