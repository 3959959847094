import { Component, OnInit, OnDestroy } from '@angular/core';
import { SpaLocalization } from '../../../core/localization/spa-localization';
import { UntypedFormBuilder, Validators, UntypedFormArray, UntypedFormGroup } from '@angular/forms';
import { SpaUtilities } from '../../../shared/utilities/spa-utilities';
import { Host, Maxlength } from '../../../shared/globalsContant';
import { SpaFormAgent } from '../../../shared/spa-form';
import { BaseResponse, SystemConfig, RGuestBookSwitch, DataTypeConstants, OutletSubProperty, RetailItems } from '../../../shared/business/shared.modals';
import { SystemConfiguration } from '../../../shared/business/view-settings.modals';
import { HttpServiceCall, HttpMethod } from '../../../shared/service/http-call.service';
import { SystemConfigBusinessService } from '../system-config.business.service';
import { StoreTerminal } from 'src/app/retail/shared/business/shared.modals';
import { BreakPointAccess } from 'src/app/shared/service/breakpoint.service';
import * as GlobalConst from '../../../shared/globalsContant';
import { setting } from 'src/app/retail/shared/globalsContant';


@Component({
  selector: 'app-rguest-book-setup',
  templateUrl: './rguest-book-setup.component.html',
  styleUrls: ['./rguest-book-setup.component.scss'],
  providers: [SystemConfigBusinessService]
})
export class RguestBookSetupComponent extends SpaFormAgent implements OnInit, OnDestroy {
  captions: any = this.localization.captions.setting;
  allCaptions: any = this.localization.captions;
  rguestbookFrmGrp: UntypedFormGroup;
  settingInfo: SystemConfiguration[] = [];
  requiredFields: any;
  requiredFieldsInfo: { name: any; requiredInfo: any; }[];
  cancelpolicy: { id: number; name: any; }[];
  pricepercentage: { id: number; name: any; }[];
  therapistdisplay: { id: number; name: any; }[];
  rGuestSetupSubscription$: any;
  appoinmentInfo: SystemConfig[] = [];
  enableSave: boolean;
  customValue: { id: number; value: string; }[];
  selectedValue: any;
  outlets: OutletSubProperty[] = [];
  terminals: StoreTerminal[] = [];
  retailitems: RetailItems[] = [];
  depositMaxLength = 0;
  disableRequireDeposit = false;
  isViewOnly = false;
  isUserAuthorized = true;
  floatLabel:string;
  enableMinutes:boolean=false;
  enableMinimumGapValidation=false;
  showMinGapValidation=false;
  constructor(private localization: SpaLocalization, private fb: UntypedFormBuilder, private systemConfig: SystemConfigBusinessService, 
              private utils: SpaUtilities, public http: HttpServiceCall, private BP: BreakPointAccess) {
    super(http);
    this.floatLabel = this.localization.setFloatLabel;
    this.setDefaultValues();
  }

  setDefaultValues() {
    this.cancelpolicy = [{ id: 1, name: this.captions.ServiceCancelPolicy }, { id: 2, name: this.captions.DefaultSystemCancelPolicy }];
    this.pricepercentage = [{ id: 1, name: this.captions.FullServicePrice }, { id: 2, name: this.captions.CustomPercentage }];
    this.therapistdisplay = [{ id: 1, name: this.captions.StaffCode }, { id: 2, name: this.captions.StaffName }, { id: 3, name: this.captions.StaffAKA }];
    this.customValue = [{ id: 1, value: this.localization.currencySymbol }, { id: 2, value: '%' }];
    this.selectedValue = { id: 2, value: '%' };
  }

  ngOnInit() {
    this.rguestbookFrmGrp = this.fb.group({
      outlet: ['', Validators.required],
      requiredFields: this.fb.array([]),
      bookingdaysout: ['', Validators.required],
      bookingdaysin: ['', Validators.required],
      allowappointmentedit: false,
      allowappointmentcancel: false,
      canceldaysout: '',
      cancelpolicy: 1,
      gridInterval: '',
      enableMinimumInterval: false,
      minimumInterval:['', Validators.required],
      minimumGapValidation: true,
      enableDynamicPricing: false,
      requireappointmentdeposit: '',
      pricepercentage: 1,
      includegratuity: true,
      includeservicecharge: false,
      therapistdisplay: 1,
      therapistdisplayimage: false,
      depositvalue: '',
      terminal: [''],
      retailitem: [''],
      limitAddOns: false,
      autoBookTherapist: false,
      maximumAddOns: ['', Validators.required],
      enablePackageAutoBook: false
    });
    this.GetPropertyOutlet();
    this.GetRetailItems();
    this.setRequiredFields();
    setTimeout(() => this.calculateHeight(), 1);
    this.rGuestSetupSubscription$ = this.rguestbookFrmGrp.valueChanges.subscribe(() => {
      if (this.rguestbookFrmGrp.valid && this.rguestbookFrmGrp.dirty) {
        this.enableSave = true;
      } else {
        this.enableSave = false;
      }
    });
    this.rguestbookFrmGrp.get('maximumAddOns').disable();
    this.ValidateBreakPoint();
  }

  async GetRetailItems()
  {
    await this.GetRetailItemsById();
  }
  async GetRetailItemsById() 
  {
    let result = await this.http.CallApiAsync<any[]>({
      host: Host.retailManagement,
      callDesc: "GetRetailItemByItemType",
      method: HttpMethod.Get,
      uriParams: { type: GlobalConst.CustomFeeRetailItemType }
    });
    let items=result.result ? result.result:[];
    if(items.length>0)
    {
      items = result.result.map((x,i)=>
        {
            return {
              category : x.retailItemDetail.category,
              itemDescription: x.retailItemDetail.itemDescription,
              id: x.id,
              itemNumber: x.retailItemDetail.itemNumber
            } as RetailItems
        });
    }
    this.retailitems=items;
  }

  LimitAddOnsClick(value: boolean) {
    if (value[0]) {
      this.rguestbookFrmGrp.get('maximumAddOns').setValidators([Validators.required]);
      this.rguestbookFrmGrp.get('maximumAddOns').markAsTouched();
      this.rguestbookFrmGrp.get('maximumAddOns').enable();
    }
    else {
      this.rguestbookFrmGrp.controls.maximumAddOns.setValue('');
      this.rguestbookFrmGrp.get('maximumAddOns').setValidators([]);
      this.rguestbookFrmGrp.get('maximumAddOns').markAsTouched();
      this.rguestbookFrmGrp.get('maximumAddOns').disable();
    }
  }

  EnableMinimumIntervalClick(value: boolean) {
    if (value[0]) {
      this.rguestbookFrmGrp.get('enableMinimumInterval').setValue(true);
      this.rguestbookFrmGrp.get('minimumInterval').setValidators([Validators.required]);
      this.rguestbookFrmGrp.get('minimumInterval').markAsTouched();
      this.enableMinutes =true;
      this.rguestbookFrmGrp.get('minimumGapValidation').setValidators([Validators.required]);
      this.rguestbookFrmGrp.get('minimumGapValidation').markAsTouched();
      this.enableMinimumGapValidation =true;
    }
    else {
      this.rguestbookFrmGrp.get('enableMinimumInterval').markAsDirty();
      this.rguestbookFrmGrp.get('enableMinimumInterval').updateValueAndValidity();
      this.rguestbookFrmGrp.get('enableMinimumInterval').setValue(false);
      // this.rguestbookFrmGrp.controls.minimumInterval.setValue('');
      this.rguestbookFrmGrp.get('minimumInterval').setValidators([]);
      this.rguestbookFrmGrp.get('minimumInterval').markAsTouched();
      this.enableMinutes =false;
      this.rguestbookFrmGrp.get('minimumGapValidation').setValidators([]);
      this.rguestbookFrmGrp.get('minimumGapValidation').markAsTouched();
      this.enableMinimumGapValidation =false;
      
    }
  }

  EnableMinimumIntervalValidationClick(value: boolean)
  {
    if (value[0]) {
    this.rguestbookFrmGrp.get('minimumGapValidation').setValue(true);
    }
    else{
    this.rguestbookFrmGrp.get('minimumGapValidation').setValue(false);
    }
  }

  EnableDynamicPricingClick(value: boolean) {
    if (value[0]) {
      this.rguestbookFrmGrp.get('enableDynamicPricing').setValue(true);
    }
    else{
      this.rguestbookFrmGrp.get('enableDynamicPricing').setValue(false);
    }
  }

  EnablePackageAutoBookClick(value: boolean) {
    if (value[0]) {
      this.rguestbookFrmGrp.get('enablePackageAutoBook').setValue(true);
    }
    else{
      this.rguestbookFrmGrp.get('enablePackageAutoBook').setValue(false);
    }
  }

  AppointmentDefaultsSetting() {
    let maxLimitAddOnsValue = this.settingInfo.find(setting => setting.switch == RGuestBookSwitch.RBOOK_MAXIMUM_ADD_ONS).value;
    if (maxLimitAddOnsValue != null && maxLimitAddOnsValue != '' && maxLimitAddOnsValue != undefined) {
      this.rguestbookFrmGrp.get('maximumAddOns').enable();
    }
    this.rguestbookFrmGrp.controls.maximumAddOns.setValue(maxLimitAddOnsValue);
    this.rguestbookFrmGrp.controls.limitAddOns.setValue(this.settingInfo.find(setting => setting.switch == RGuestBookSwitch.RBOOK_LIMIT_ADD_ONS).value);
    this.rguestbookFrmGrp.controls.autoBookTherapist.setValue(this.settingInfo.find(setting => setting.switch == RGuestBookSwitch.RBOOK_AUTOBOOK_THERAPIST).value);
    if (this.settingInfo.find(setting => setting.switch == RGuestBookSwitch.RBOOK_LIMIT_ADD_ONS).value) {
      this.rguestbookFrmGrp.get('maximumAddOns').setValidators([Validators.required]);
    } else {
      this.rguestbookFrmGrp.get('maximumAddOns').setValidators([]);
      this.rguestbookFrmGrp.get('maximumAddOns').disable();
    }
    this.rguestbookFrmGrp.get('maximumAddOns').markAsTouched();

    let minimumIntervalValue = this.settingInfo.find(setting => setting.switch == RGuestBookSwitch.RBOOK_MININMUMINTERVAL).value;
    if (minimumIntervalValue != null && minimumIntervalValue != '' && minimumIntervalValue != undefined) {
      this.rguestbookFrmGrp.get('minimumInterval').enable();
    }
    this.rguestbookFrmGrp.controls.minimumInterval.setValue(minimumIntervalValue);
    this.rguestbookFrmGrp.controls.enableMinimumInterval.setValue(this.settingInfo.find(setting => setting.switch == RGuestBookSwitch.RBOOK_ENABLEMINIMUMINTERVAL).value);
    this.rguestbookFrmGrp.controls.minimumGapValidation.setValue(this.settingInfo.find(setting => setting.switch == RGuestBookSwitch.RBOOK_MINIMUM_GAP_ADDITIONAL_VALIDATAION).value);
    if (this.settingInfo.find(setting => setting.switch == RGuestBookSwitch.RBOOK_ENABLEMINIMUMINTERVAL).value) {
      this.rguestbookFrmGrp.get('minimumInterval').setValidators([Validators.required]);
      this.enableMinutes=true;
      this.rguestbookFrmGrp.get('minimumGapValidation').setValidators([Validators.required]);
      this.enableMinimumGapValidation =true;
    } else {
      this.rguestbookFrmGrp.get('minimumInterval').setValidators([]);
      this.enableMinutes=false;
      this.rguestbookFrmGrp.get('minimumGapValidation').setValidators([]);
      this.enableMinimumGapValidation=false;
    }
    this.rguestbookFrmGrp.get('minimumInterval').markAsTouched();
    this.rguestbookFrmGrp.controls.enableDynamicPricing.setValue(this.settingInfo.find(setting => setting.switch == RGuestBookSwitch.RBOOK_ENABLE_DYNAMIC_PRICING).value);
    this.rguestbookFrmGrp.controls.bookingdaysout.setValue(this.settingInfo.find(setting => setting.switch == RGuestBookSwitch.RBOOK_BOOKINGDAYSOUT).value);
    this.rguestbookFrmGrp.controls.bookingdaysin.setValue(this.settingInfo.find(setting => setting.switch == RGuestBookSwitch.RBOOK_BOOKINGDAYSIN).value);
    this.rguestbookFrmGrp.controls.allowappointmentedit.setValue(this.settingInfo.find(setting => setting.switch == RGuestBookSwitch.RBOOK_ALLOWAPPOINTMENTEDIT).value);
    this.rguestbookFrmGrp.controls.allowappointmentcancel.setValue(this.settingInfo.find(setting => setting.switch == RGuestBookSwitch.RBOOK_ALLOWAPPOINTMENTCANCEL).value);
    this.rguestbookFrmGrp.controls.canceldaysout.setValue(this.settingInfo.find(setting => setting.switch == RGuestBookSwitch.RBOOK_CANCELDAYSOUT).value);
    this.rguestbookFrmGrp.controls.cancelpolicy.setValue(this.settingInfo.find(setting => setting.switch == RGuestBookSwitch.RBOOK_CANCELPOLICY).value);
    this.rguestbookFrmGrp.controls.gridInterval.setValue(this.settingInfo.find(setting => setting.switch == RGuestBookSwitch.RBOOK_GRIDINTERVAL).value );
    this.rguestbookFrmGrp.controls.enableMinimumInterval.setValue(this.settingInfo.find(setting => setting.switch == RGuestBookSwitch.RBOOK_ENABLEMINIMUMINTERVAL).value );
    this.rguestbookFrmGrp.controls.minimumGapValidation.setValue(this.settingInfo.find(setting => setting.switch == RGuestBookSwitch.RBOOK_MINIMUM_GAP_ADDITIONAL_VALIDATAION).value);
    this.rguestbookFrmGrp.controls.minimumInterval.setValue(this.settingInfo.find(setting => setting.switch == RGuestBookSwitch.RBOOK_MININMUMINTERVAL).value );
    this.rguestbookFrmGrp.controls.enableDynamicPricing.setValue(this.settingInfo.find(setting => setting.switch == RGuestBookSwitch.RBOOK_ENABLE_DYNAMIC_PRICING).value );
    this.rguestbookFrmGrp.controls.requireappointmentdeposit.setValue(this.settingInfo.find(setting => setting.switch == RGuestBookSwitch.RBOOK_REQUIREAPPOINTMENTDEPOSIT).value);
    this.rguestbookFrmGrp.controls.pricepercentage.setValue(this.settingInfo.find(setting => setting.switch == RGuestBookSwitch.RBOOK_REQUIREAPPOINTMENTDEPOSITTYPE).value);
    this.rguestbookFrmGrp.controls.includegratuity.setValue(this.settingInfo.find(setting => setting.switch == RGuestBookSwitch.RBOOK_INCLUDEGRATUITY).value);
    this.rguestbookFrmGrp.controls.includeservicecharge.setValue(this.settingInfo.find(setting => setting.switch == RGuestBookSwitch.RBOOK_INCLUDESERVICECHARGE).value);
    this.rguestbookFrmGrp.controls.therapistdisplay.setValue(this.settingInfo.find(setting => setting.switch == RGuestBookSwitch.RBOOK_THERAPISTDISPLAY).value);
    this.rguestbookFrmGrp.controls.therapistdisplayimage.setValue(this.settingInfo.find(setting => setting.switch == RGuestBookSwitch.RBOOK_THERAPISTDISPLAYIMAGE).value);
    this.rguestbookFrmGrp.controls.enablePackageAutoBook.setValue(this.settingInfo.find(setting => setting.switch == RGuestBookSwitch.RBOOK_PACKAGE_AUTOBOOK).value );
    this.rguestbookFrmGrp.controls.retailitem.setValue(this.settingInfo.find(setting => setting.switch == RGuestBookSwitch.RBOOK_RETAIL_ITEM).value);
    if (this.settingInfo.find(setting => setting.switch == RGuestBookSwitch.RBOOK_OUTLET).value) {
      this.rguestbookFrmGrp.controls.outlet.setValue(this.settingInfo.find(setting => setting.switch == RGuestBookSwitch.RBOOK_OUTLET).value);
    }
    this.rguestbookFrmGrp.controls.depositvalue.setValue(this.localization.localizePercentage(this.settingInfo.find(setting => setting.switch == RGuestBookSwitch.RBOOK_DEPOSITVALUE).value.toString()));
    let iscustomdepositpercentage = this.settingInfo.find(setting => setting.switch == RGuestBookSwitch.RBOOK_IS_DEPOSITCUSTOMPERCENTAGE).value;
    this.selectedValue = iscustomdepositpercentage ? this.customValue[1] : this.customValue[0];
    this.depositMaxLength = iscustomdepositpercentage ? Maxlength.PERCENTAGE : Maxlength.FLATAMOUNT;

    if (!this.rguestbookFrmGrp.value.requiredFields[2].RBOOK_CREDIT_CARD) {
      this.disableRequireDeposit = true;
      this.depositMaxLength = Maxlength.PERCENTAGE;
    }
    this.GetDefaultTerminalIds(this.rguestbookFrmGrp.controls['outlet'].value);
  }

  setRequiredFields() {
    const personalInfo = [
      { id: 1, name: this.captions.Title, controlName: 'RBOOK_TITLE' },
      { id: 2, name: this.captions.First_Name, controlName: 'RBOOK_FIRST_NAME' },
      { id: 3, name: this.captions.Last_Name, controlName: 'RBOOK_LAST_NAME' },
      { id: 4, name: this.captions.Birthday, controlName: 'RBOOK_BIRTHDAY' },
      { id: 5, name: this.captions.Gender, controlName: 'RBOOK_GENDER' },
    ];
    const contactInfo = [
      { id: 1, name: this.captions.Address, controlName: 'RBOOK_ADDRESS' },
      { id: 2, name: this.captions.City, controlName: 'RBOOK_CITY' },
      { id: 3, name: this.captions.State, controlName: 'RBOOK_STATE' },
      { id: 4, name: this.captions.Postal_Code, controlName: 'RBOOK_POSTAL_CODE' },
      { id: 5, name: this.captions.Country, controlName: 'RBOOK_COUNTRY' },
      { id: 6, name: this.captions.Phone, controlName: 'RBOOK_PHONE' },
      { id: 7, name: this.captions.Email, controlName: 'RBOOK_EMAIL' },
    ];
    const paymentInfo = [
      { id: 1, name: this.captions.Credit_Card, controlName: 'RBOOK_CREDIT_CARD' }
    ];
    this.requiredFieldsInfo = [
      {
        name: this.captions.personalInformation,
        requiredInfo: personalInfo
      },
      {
        name: this.captions.contactDetails,
        requiredInfo: contactInfo
      },
      {
        name: this.captions.paymentDetails,
        requiredInfo: paymentInfo
      }
    ];
    this.GetAllSetting();
    this.requiredFields = this.rguestbookFrmGrp.get('requiredFields') as UntypedFormArray;
    for (let i = 0; i < this.requiredFieldsInfo.length; i++) {
      switch (this.requiredFieldsInfo[i].name) {
        case this.captions.personalInformation:
          this.requiredFields.push(this.addPersonalDetails());
          break;
        case this.captions.contactDetails:
          this.requiredFields.push(this.addContactDetails());
          break;
        case this.captions.paymentDetails:
          this.requiredFields.push(this.addPaymentDetails());
          break;
      }
    }
  }

  addPersonalDetails(): UntypedFormGroup {
    return this.fb.group({
      RBOOK_TITLE: '',
      RBOOK_FIRST_NAME: '',
      RBOOK_LAST_NAME: '',
      RBOOK_BIRTHDAY: '',
      RBOOK_GENDER: ''
    });
  }



  addContactDetails(): UntypedFormGroup {
    return this.fb.group({
      RBOOK_ADDRESS: '',
      RBOOK_CITY: '',
      RBOOK_STATE: '',
      RBOOK_POSTAL_CODE: '',
      RBOOK_COUNTRY: '',
      RBOOK_PHONE: '',
      RBOOK_EMAIL: ''
    });
  }

  addPaymentDetails(): UntypedFormGroup {
    return this.fb.group({
      RBOOK_CREDIT_CARD: '',
    });
  }

  calculateHeight() {
    try {
      let col1 = document.getElementById('required-0').offsetHeight;
      let col2 = document.getElementById('required-1').offsetHeight;
      let col3 = document.getElementById('required-2').offsetHeight;
      let maxHeight = Math.max(col1, col2, col3)
      this.setHeight(maxHeight);
    }
    catch (e) { }
  }

  setHeight(height) {
    try {
      document.getElementById('required-0').style.height = height + 'px'
      document.getElementById('required-1').style.height = height + 'px'
      document.getElementById('required-2').style.height = height + 'px'
    }
    catch (e) { }
  }

  AllowAppointmentCancelEmit(event) {
    this.rguestbookFrmGrp.controls.canceldaysout.setValue('');
    this.rguestbookFrmGrp.controls.cancelPolicy.setValue(1);
    this.rguestbookFrmGrp.controls.canceldaysout.setValidators(event[0] ? [Validators.required] : []);
    this.rguestbookFrmGrp.controls.canceldaysout.updateValueAndValidity();
  }

  AllowAppointmentDepositEmit() {
    this.rguestbookFrmGrp.controls.pricepercentage.setValue(1);
    this.rguestbookFrmGrp.controls.depositvalue.setValue(0);
    this.selectedValue = this.customValue[1];
  }

  save() {
    this.enableSave = false;
    let SettingData: SystemConfig[] = this.formSettingBody().concat(this.formAppointmentBody());
    this.UpdateSetting(SettingData);
  }
  formSettingBody(): SystemConfig[] {
    let allControls = [this.requiredFields.controls];
    let con = allControls[0];
    let body: SystemConfig[] = [];
    for (let systemControl of con) {
      let formControl = systemControl as UntypedFormGroup;
      let controls: string[] = Object.keys(formControl.controls);
      for (let control of controls) {
        let systemConfig: SystemConfig;
        systemConfig = {
          id: this.settingInfo.find(setting => setting.switch == control).id,
          moduleId: this.settingInfo.find(s => s.switch == control).moduleId,
          switch: control,
          value: formControl.controls[control].value
        }
        body.push(systemConfig);
      }
    }
    return body;
  }

  AddAppointmentData(uiSwitchName: string, apiSwitchName: string): void {
    let con = this.rguestbookFrmGrp.value;
    let body: SystemConfig;
    if (con) {
      const tempValue = apiSwitchName == RGuestBookSwitch.RBOOK_DEPOSITVALUE ?
        this.localization.currencyToSQLFormat(con[uiSwitchName]).toString() : con[uiSwitchName];
      body = {
        id: this.settingInfo.find(setting => setting.switch == apiSwitchName).id,
        moduleId: this.settingInfo.find(s => s.switch == apiSwitchName).moduleId,
        switch: apiSwitchName,
        value: apiSwitchName == RGuestBookSwitch.RBOOK_IS_DEPOSITCUSTOMPERCENTAGE ?  this.selectedValue.id == 2 : tempValue
      }
      body.value = body.value === undefined ? "" : body.value;
      this.appoinmentInfo.push(body);
    }
  }

  formAppointmentBody(): SystemConfig[] {
    this.AddAppointmentData('bookingdaysout', RGuestBookSwitch.RBOOK_BOOKINGDAYSOUT);
    this.AddAppointmentData('bookingdaysin', RGuestBookSwitch.RBOOK_BOOKINGDAYSIN);
    this.AddAppointmentData('allowappointmentedit', RGuestBookSwitch.RBOOK_ALLOWAPPOINTMENTEDIT);
    this.AddAppointmentData('allowappointmentcancel', RGuestBookSwitch.RBOOK_ALLOWAPPOINTMENTCANCEL);
    this.AddAppointmentData('gridInterval', RGuestBookSwitch.RBOOK_GRIDINTERVAL);
    this.AddAppointmentData('enableMinimumInterval', RGuestBookSwitch.RBOOK_ENABLEMINIMUMINTERVAL);
    this.AddAppointmentData('minimumGapValidation', RGuestBookSwitch.RBOOK_MINIMUM_GAP_ADDITIONAL_VALIDATAION);
    this.AddAppointmentData('minimumInterval', RGuestBookSwitch.RBOOK_MININMUMINTERVAL);
    this.AddAppointmentData('enableDynamicPricing', RGuestBookSwitch.RBOOK_ENABLE_DYNAMIC_PRICING);
    this.AddAppointmentData('canceldaysout', RGuestBookSwitch.RBOOK_CANCELDAYSOUT);
    this.AddAppointmentData('cancelpolicy', RGuestBookSwitch.RBOOK_CANCELPOLICY);
    this.AddAppointmentData('requireappointmentdeposit', RGuestBookSwitch.RBOOK_REQUIREAPPOINTMENTDEPOSIT);
    this.AddAppointmentData('pricepercentage', RGuestBookSwitch.RBOOK_REQUIREAPPOINTMENTDEPOSITTYPE);
    this.AddAppointmentData('includegratuity', RGuestBookSwitch.RBOOK_INCLUDEGRATUITY);
    this.AddAppointmentData('includeservicecharge', RGuestBookSwitch.RBOOK_INCLUDESERVICECHARGE);
    this.AddAppointmentData('therapistdisplay', RGuestBookSwitch.RBOOK_THERAPISTDISPLAY);
    this.AddAppointmentData('therapistdisplayimage', RGuestBookSwitch.RBOOK_THERAPISTDISPLAYIMAGE);
    this.AddAppointmentData('outlet', RGuestBookSwitch.RBOOK_OUTLET);
    this.AddAppointmentData('depositvalue', RGuestBookSwitch.RBOOK_DEPOSITVALUE);
    this.AddAppointmentData('iscustompercent', RGuestBookSwitch.RBOOK_IS_DEPOSITCUSTOMPERCENTAGE);
    this.AddAppointmentData('terminal', RGuestBookSwitch.RBOOK_TERMINAL);
    this.AddAppointmentData('retailitem', RGuestBookSwitch.RBOOK_RETAIL_ITEM);
    this.AddAppointmentData('maximumAddOns', RGuestBookSwitch.RBOOK_MAXIMUM_ADD_ONS);
    this.AddAppointmentData('limitAddOns', RGuestBookSwitch.RBOOK_LIMIT_ADD_ONS);
    this.AddAppointmentData('autoBookTherapist', RGuestBookSwitch.RBOOK_AUTOBOOK_THERAPIST);
    this.AddAppointmentData('enablePackageAutoBook', RGuestBookSwitch.RBOOK_PACKAGE_AUTOBOOK);
    return this.appoinmentInfo;
  }

  cancel() {
    if (this.isViewOnly) {
      return;
    }
    this.RequiredFieldsSetting();
    this.AppointmentDefaultsSetting();
    this.enableSave = false;
  }

  ValidateBreakPoint(): void {
    this.isUserAuthorized = this.BP.CheckForAccess([GlobalConst.SPAScheduleBreakPoint.SettingSystemSettings]);
    this.isViewOnly = this.BP.IsViewOnly(GlobalConst.SPAScheduleBreakPoint.SettingSystemSettings);
    if (this.isViewOnly) {
      this.utils.disableControls(this.rguestbookFrmGrp);
    }
  }

  GetAllSetting() {
    this.makeGetCall("GetAllSetting", Host.spaManagement, { Id: this.utils.GetPropertyInfo('PropertyId') });
  }

  async GetPropertyOutlet() {
    await this.GetOutletsByPropertyId();
  }


  async GetOutletsByPropertyId() {
    let result = await this.http.CallApiAsync<OutletSubProperty[]>({
      host: Host.retailManagement,
      callDesc: "GetOutletsByPropertyAndProduct",
      method: HttpMethod.Get,
      uriParams: { propertyId: Number(this.localization.GetPropertyInfo('PropertyId')), productId: Number(this.localization.GetPropertyInfo('ProductId')) }
    });
    this.outlets = result.result ? result.result : [];
    this.outlets = this.outlets.filter(x => x.isActive);
  }

  async GetDefaultTerminalIds(outletId: number) {
    let result = await this.http.CallApiAsync<StoreTerminal[]>({
      host: Host.retailManagement,
      callDesc: "GetStoreTerminal",
      method: HttpMethod.Get,
      uriParams: { outletId: outletId }
    });
    this.terminals = result.result ? result.result : [];
    if (this.terminals.length > 0) {
      this.rguestbookFrmGrp.controls.terminal.setValue(this.settingInfo.find(setting => setting.switch == RGuestBookSwitch.RBOOK_TERMINAL).value.toString());
    }
  }

  GetDefaultTerminals($event) {
    this.GetDefaultTerminalIds($event.value);
  }


  UpdateSetting(bodyData) {
    this.makePutCall("UpdateSetting", Host.spaManagement, bodyData);
  }

  ngOnDestroy() {
    this.rGuestSetupSubscription$.unsubscribe();
  }

  RequiredFieldsSetting() {
    let propJSON: any = {};
    this.settingInfo.forEach(sc => {
      if (sc.switchType == DataTypeConstants.Boolean) {
        sc.value = this.convertStringToBoolean(sc.value as string);
      }
      else if (sc.switchType == DataTypeConstants.Int32) {
        sc.value = this.ConvertStringToInt(sc.value as string);
      }
      propJSON[sc.switch] = sc.value
      propJSON[sc.id] = sc.id
    });
    this.systemConfig.systemConfigValues = propJSON;
    this.formAndPatchRequiredFieldsData(propJSON);
    this.enableSave = false;
  }

  convertStringToBoolean(value: string): boolean {
    return value.toString().toLowerCase() == "true" ? true : false;
  }

  ConvertStringToInt(value: string): any {
    return value != null ? parseInt(value) : value;
  }


  formAndPatchRequiredFieldsData(appJSON) {
    appJSON['requiredFields'] = [appJSON, appJSON, appJSON];
    this.rguestbookFrmGrp.reset();
    this.rguestbookFrmGrp.patchValue(appJSON);
  }

  successCallback<T>(result: BaseResponse<T>, callDesc: string, extraParams?: any[]): void {
    if (callDesc == "GetAllSetting") {
      this.settingInfo = <any>result.result;
      this.RequiredFieldsSetting();
      this.AppointmentDefaultsSetting();
      this.enableSave = false;
    }
    else if (callDesc == "UpdateSetting") {
      this.GetAllSetting();
      this.appoinmentInfo = [];
      this.enableSave = false;
    }
  }

  errorCallback<T>(error: BaseResponse<T>, callDesc: string, extraParams: any[]): void {
    if (callDesc == "GetAllSetting") {
      this.systemConfig.systemConfigValues = this.settingInfo;
      console.log(error);
    }
    else if (callDesc == "UpdateSetting") {

      this.appoinmentInfo = [];
      console.log(error);
    }
  }


  radioChange(event) {
    if (this.rguestbookFrmGrp.value.pricepercentage == 2) {
      this.rguestbookFrmGrp.get('depositvalue').setValidators(Validators.required);
    } else {
      this.rguestbookFrmGrp.get('depositvalue').setValidators(null);
      this.rguestbookFrmGrp.controls.depositvalue.setValue(0);
    }
    this.rguestbookFrmGrp.get('depositvalue').updateValueAndValidity();
  }

  chooseValue(event, value) {
    this.selectedValue = value;
    if (this.selectedValue.id == 2) {
      this.rguestbookFrmGrp.controls.depositvalue.setValue(0);
      this.depositMaxLength = Maxlength.PERCENTAGE;
    }
    else {
      this.rguestbookFrmGrp.controls.depositvalue.setValue(0);
      this.depositMaxLength = Maxlength.FLATAMOUNT;
    }
    this.rguestbookFrmGrp.markAsDirty();
    this.rguestbookFrmGrp.updateValueAndValidity();
  }

  approximateZero($event) {
    if ($event.target.value == 0) {
      $event.target.value = "0";
      let formControlName: string = $event.target.getAttribute('formcontrolname');
      if (formControlName) {
        this.rguestbookFrmGrp.controls[formControlName].setValue(0);
      }
    }
  }

  AllowCreditCard(controlName: string, isEnable: boolean) {

    if (controlName == 'RBOOK_CREDIT_CARD') {
      this.disableRequireDeposit = !isEnable;
      if (this.disableRequireDeposit) {
        this.rguestbookFrmGrp.controls.requireappointmentdeposit.setValue(false);
      } 
    }
  }
}
