import { Injectable } from '@angular/core';
 
@Injectable({
  providedIn: 'root',
})
export class SessionLoaderService {
  private apiKey: string | null = null;
 
  initializeSession(): Promise<void> {
    return new Promise((resolve, reject) => {
      const key = sessionStorage.getItem('googleMapsApiKey');
      const isLoggedin = sessionStorage.getItem('_jwt');
      if(isLoggedin){
        if (key) {
            this.apiKey = key;
            resolve(); // Successfully loaded the key
          } else {
            resolve(); // No key found
          }
      } else {
        resolve();
      }
    });
  }
}