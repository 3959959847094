import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ReplaySubject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { Localization } from '../../localization/localization';
import { CommonPropertyInformation } from '../../shared/services/common-property-information.service';
import { DialogCloseEnum } from '../../enums/shared-enums';

@Component({
  selector: 'app-copy-diary',
  templateUrl: './copy-diary.component.html',
  styleUrls: ['./copy-diary.component.scss']
})
export class CopyDiaryComponent implements OnInit {
  captions: any;
  copyButton: { label: any; type: string; disabledproperty: boolean; };
  cancelButton: { type: string; label: any; };
  copyFromoptions: { id: number; value: string; viewValue: string; }[];
  copyTooptions: { id: number; value: string; viewValue: string; }[];
  form: FormGroup;
  destroyed$: ReplaySubject<boolean> = new ReplaySubject(1);
  selectedFromYear: number;
  selectedToYear: number;


  constructor(private localization:Localization,private fb:FormBuilder, private propertyInfo: CommonPropertyInformation,private dialogRef: MatDialogRef<CopyDiaryComponent>,
    @Inject(MAT_DIALOG_DATA) private data) { 
    this.captions=this.localization.captions
  }

  ngOnInit(): void {
    this.formGenerator()
    this.copyButton = {
      label:this.captions.btn_copy,
      type: 'primary',
      disabledproperty: true
    };
    this.cancelButton = {
      type: 'tertiary',
      label: this.captions.btn_cancel
    };
    this.copyFromoptions= this.copyFromOptionsMapper();
    this.copyTooptions= this.copyToOptionsMapper();
  }

  formGenerator()
  {
    this.form = this.fb.group({
      copyFrom:'',
      copyTo:''
    })

    this.form.valueChanges.pipe(takeUntil(this.destroyed$)).subscribe(x => {
      this.copyButton.disabledproperty = !( this.form.valid && this.form.dirty);
      this.copyButton = { ...this.copyButton };
    });
  }

  copy()
  {
    this.dialogRef.close({
      from: DialogCloseEnum.Action,
      copyFromYear: this.selectedFromYear,
      copyToYear: this.selectedToYear,
    });
  }

  copyFromOptionsMapper() {
    let options = [];
    let id = 0
    this.data.copyFromYears.forEach(x => {
      let opt = {
        id: id,
        value: x,
        viewValue: x
      }
      options.push(opt);
      id = id + 1;
    });
    return options;
  }

  copyToOptionsMapper() {
    let currentYear = this.propertyInfo.CurrentDTTM.getFullYear();
    let options = [];

    options = Array.from({ length: 5 }, (_, i) => ({
      id: i,
      value: currentYear + i + 1,
      viewValue: currentYear + i + 1
    }));
    return options
  }

  closePopUp()
  {
   this.dialogRef.close();
  }

  OnSelectfrom(e)
  {
    this.selectedFromYear = Number(this.copyFromoptions.find(x => x.id == e.value).value);
  }
  OnSelectto(e)
  {
    this.selectedToYear = Number(this.copyTooptions.find(x => x.id == e.value).value);
  }

  ngOnDestroy() {
    if (this.destroyed$) {
      this.destroyed$.next(true);
      this.destroyed$.complete();
    }
  }

}
