import { BaseReport } from "../common/base-report";
import { ReportAPIOptions, ReportUIConfig, ReportParams, AllReports } from "../report.modals";
import { UntypedFormGroup } from "@angular/forms";
import { ReportDataService } from "../../data/report-data.services";
import { ReportBusinessService } from "../report-business.service";

/**
 *StaffLog Report
 * @export
 * @class StaffLog
 * @extends {BaseReport}
 */
export class StaffLog extends BaseReport {

    constructor(public reportFormGrp: UntypedFormGroup,public business: ReportBusinessService, public data: ReportDataService, public patcheableValue) {
        super(reportFormGrp);
    }

    generateReportAPIOptions(): ReportAPIOptions {
        return {
            code: AllReports.StaffLog,
            params: this.formReportParams(),
            URIParams: this.formURIParams(),
            filters: this.formFilters(),
            pageBreak: true
        };
    }

     getReportUIConfig(): ReportUIConfig {
        if (this.commonLocalization.isFromJobScheduler) {
            this.MappatchFormValues(this.patcheableValue);
        }
         return {
             startDatePicker: true,
             endDatePicker: true,
             dropDownFilterName: "",
             inActiveToggle: false,
             inActiveToggleName: "",
             pageBreakToggle: false,
             layout: 'LANDSCAPE'
         };
    }


    protected formReportParams(): ReportParams[] {
        let toApi = this.localization.convertDateObjToAPIdate;
        return [{ "pStartDate": toApi(this.startDate) }, { "pEndDate": toApi(this.endDate) },
        { "pPageBreak": this.pageBreakToggle },
        { "pPropertyName": this.propertyName }, {"pDate": this.printedDate},
        {"pFilterCaption": this.business.getFilterCaption(this.business.FilterDataSource['therapists'],this.reportFormGrp.controls['customFilterFormGrp'].value.therapists.map(s=>s.id))},
        { "pInactiveTherapist" : this.reportFormGrp.controls['customFilterFormGrp'].value.includeInactiveTherapists ? this.captions.Yes : this.captions.No},
        { "pIsClass" : this.reportFormGrp.controls['customFilterFormGrp'].value.reportTypes==1}];
    }

    protected formURIParams(): ReportParams[] {
        let toApi = this.localization.convertDateObjToAPIdate;
        return [{ "StartDate": toApi(this.startDate) }, { "EndDate": toApi(this.endDate) }];
    }


    protected formFilters(): any {
        let toApi = this.localization.convertDateObjToAPIdate;
        let reporttype: boolean = (this.reportFormGrp.controls['customFilterFormGrp'].value.radioFilter==1)? false:true;
        let ids: number[] = this.reportFormGrp.controls['customFilterFormGrp'].value.therapists.map(x=>x.id);

        if(this.commonLocalization.isFromJobScheduler){
            const fromWildCard = this.formGrp.get('timelineFrom').value;
            const toWildCard = this.formGrp.get('timelineTo').value;
            const fromIncrement = this.formGrp.get('timelineCountFrom').value;
            const toIncrement = this.formGrp.get('timelineCountTo').value;
            return JSON.stringify({
                fromDate: toApi(this.formGrp.get('startDate').value),
                  toDate: toApi(this.formGrp.get('endDate').value),
                Data:{ 'therapistIds': ids },
                fromWildCard,
                toWildCard,
                fromIncrement,
                toIncrement
            });
        }

        return {
            staffIds: ids,
            reportType: reporttype,
            StartDate: toApi(this.startDate),
			EndDate: toApi(this.endDate)
        };
    }

    patchReportValues(patchItem){
        setTimeout(()=>{
            this.formGrp.patchValue(patchItem);
            this.commonLocalization.scheduleJobWildCards[1].id;
            this.formGrp.controls.timelineFrom.patchValue(this.commonLocalization.scheduleJobWildCards.find(x=> x.id == patchItem.fromWildCard).id);
            this.formGrp.controls.timelineCountFrom.patchValue(patchItem.fromIncrement);
        });
    }

    MappatchFormValues(patchItem) {
        setTimeout(() => {
            if(patchItem.fromWildCard != undefined)
                this.formGrp.controls.timelineFrom.patchValue(this.commonLocalization.scheduleJobWildCards.find(x => x.id == patchItem.fromWildCard)?.id);

            if(patchItem.toWildCard != undefined)
            this.formGrp.controls.timelineTo.patchValue(this.commonLocalization.scheduleJobWildCards.find(x => x.id == patchItem.toWildCard)?.id);

            if(patchItem.fromIncrement != undefined)
                this.formGrp.controls.timelineCountFrom.patchValue(patchItem.fromIncrement);

            if(patchItem.toIncrement != undefined)
            this.formGrp.controls.timelineCountTo.patchValue(patchItem.toIncrement);

        }, 1);
    }

}
