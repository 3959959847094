import { AfterViewInit, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { RouteLoaderService } from 'src/app/core/services/route-loader.service';
import { ConfigValidationService } from '../../services/config/config-validation.service';
import { Localization } from '../../localization/localization';
import { ConfigUrlItem, ConfigValidateItem, Configuration, MasterConfig } from '../../services/config/config-constants';
import { AgDropdownConfig, ButtonValue, DropdownOptions, TableHeaderOptions, TableOptions } from '../../Models/ag-models';
import { ConfigValidationBusinessService } from './config-validation.business.service';
import { ConfigValidationUIModel } from './config-validation.model';
import { ConfigValidationStatus } from '../../enums/shared-enums';
import { FormArray, UntypedFormArray, UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { takeUntil } from 'rxjs/operators';
import { Observable, ReplaySubject } from 'rxjs';
import { menuTypes } from '../../components/menu/menu.constant';
import { CommonUtilities } from '../../shared/shared/utilities/common-utilities';


@Component({
  selector: 'app-config-validation',
  templateUrl: './config-validation.component.html',
  styleUrls: ['./config-validation.component.scss']
})
export class ConfigValidationComponent implements OnInit {

  menuList: any;
  menuType = menuTypes;
  displayedColumns: string[] = ['id', 'result', 'status'];
  gridData: Products[] = [];
  dataSource= new MatTableDataSource<Products>();
  textCaptions: any;
  masterConfig: MasterConfig;
  RevalidateButton: ButtonValue;
  configurations: Configuration[];
  tableContent: ConfigValidationUIModel[];
  allTableContent: ConfigValidationUIModel[];
  selectedOption:any;
  searchText = '';
  tableOptions: TableOptions;
  headerOptions: TableHeaderOptions[];
  selectedConfig:Configuration;
  ConfigValidationStatus:ConfigValidationStatus;
  configurationForm: UntypedFormGroup
  destroyed$: ReplaySubject<boolean> = new ReplaySubject(1);
  selectedConfigs: any;
  proceedBtn: ButtonValue;
  configs: any[] = [];
  checkboxes = [];
  mainFilterList=[]
  selectallcheckboxEnable:boolean
  ViewOptions:Observable<DropdownOptions[]> | Promise<DropdownOptions[]>;
  invoiceForm: UntypedFormGroup;
  categoryInput: AgDropdownConfig;
  selectedViewOptions: number[]
  RevalidateConfigInput: AgDropdownConfig;
  selectedReVaidateOptions: number[]




  constructor(private business:ConfigValidationBusinessService ,private fb: UntypedFormBuilder,private routeDataService: RouteLoaderService,private configValidationService: ConfigValidationService,private localization:Localization,private changeDetectorRef: ChangeDetectorRef, private utilities: CommonUtilities) {
    const value = this.routeDataService.GetChildMenu('/settings/utilities/configValidation',menuTypes.vertical);
    this.menuList = {
      menu: value.linkedElement,
      menuType : menuTypes.vertical
    };
    this.textCaptions = this.localization.captions;
    this.proceedBtn = {
      type: 'secondary',
      label:'proceed',
      disabledproperty: true
    }

    // this.configurationForm = this.fb.group({
    //   configs: this.fb.array([])
    // });
    // this.configurationForm.valueChanges.pipe(takeUntil(this.destroyed$)).subscribe(value => {
    //   this.selectedConfigs = value.configs.filter(item => item.checked);
    //   this.proceedBtn.disabledproperty = !(this.selectedConfigs.length);
    //   this.proceedBtn = { ...this.proceedBtn };
    // })
    
  }
  

  // ngAfterViewInit(): void {
  //   // this.intializeForm();
  //   // this.MasterFilterList();
  // }

  ngOnInit(): void {
    
    this.intializeForm();
    this.MasterFilterList();
    this.initializeTable();
    //this.ViewOptions=this.getViewSelectOptions()
    this.getConfigurationKeyData();
    
    //this.getConfigurationResults();
    // let content:any[]=[{id:'1',config:'masterconfig',configkey:'childconfig',reason:'reason',status:'y'},{id:'2',config:'masterconfig',configkey:'childconfig',reason:'reason',status:'y'}]
    // this.tableContent=content;
    //working one below
    //     this.business.getValidationJson().then(data=>{
    //   console.log('getConfigurationKeyData',data)
    //   this.tableContent = data;
    // });
 
    //console.log('error')
  }
  ngOnDestroy() {
    if (this.destroyed$) {
      this.destroyed$.next(true);
      this.destroyed$.complete();
    }
  }
  
  initializeTable() {
    this.RevalidateButton = {
      type: 'primary',
      label: 'Re-Validate',
      disabledproperty : true

    }
    this.tableOptions = this.business.getTableOptions(true);
    this.headerOptions = this.business.getHeaderOptions();
    
  }

  getViewSelectOptions(): DropdownOptions[] {
    return [
      {
        id: 1,
        value: '1st option',
        viewValue: 'viewoption1'
      },
      {
        id: 2,
        value: '2st option',
        viewValue: 'viewoption2'
      }
      
    ]
  }

  intializeForm() {
    
    this.invoiceForm = this.fb.group({
      reValidateConfigs:[],
      category: []
    });
    this.configurationForm = this.fb.group({
      configs: this.fb.array([])
    });
    this.configurationForm.valueChanges.pipe(takeUntil(this.destroyed$)).subscribe(value => {
      this.selectedConfigs = value.configs.filter(item => item.checked);
      this.proceedBtn.disabledproperty = !(this.selectedConfigs.length);
      this.proceedBtn = { ...this.proceedBtn };
    })
    this.invoiceForm.valueChanges.pipe(takeUntil(this.destroyed$)).subscribe(value => {
      console.log(this.invoiceForm.valid && this.invoiceForm.dirty);
      // this.showInvoiceBtn.disabledproperty = !(this.invoiceForm.valid && this.arBatchId == null);
      // this.showInvoiceBtn = { ...this.showInvoiceBtn };
    })
    this.checkboxes=[]
    
    
    this.mainFilterList = [
      {
        'id' : 1,
        'name' : 'PAYMENT METHODS',
        'checked' : false
      },
      {
        'id' : 2,
        'name' : 'PAYMENT MANAGERS',
        'checked' : false
      },
      {
        'id' : 3,
        'name' : 'Gift Card',
        'checked' : false
      },
      {
        'id' : 4,
        'name' : 'Credit Card',
        'checked' : false
      },
      {
        'id' : 5,
        'name' : 'Group Charge',
        'checked' : false
      },
      {
        'id' : 6,
        'name' : 'PMS Revenue Posting',
        'checked' : false
      },
      {
        'id' : 7,
        'name' : 'Tenant-product-Configuration',
        'checked' : false
      },
      {
        'id' : 8,
        'name' : 'COMP',
        'checked' : false
      },
      {
        'id' : 9,
        'name' : 'Interface-PMSConfigurtation',
        'checked' : false
      },
      {
        'id' : 10,
        'name' : 'TENANTSETUPCONFIGURATION',
        'checked' : false
      },
      {
        'id' : 11,
        'name' : 'Interface-AuthorizeConfiguration',
        'checked' : false
      },
      {
        'id' : 12,
        'name' : 'Interface-DataMagine',
        'checked' : false
      },
      {
        'id' : 13,
        'name' : 'OfferManagementSystem',
        'checked' : false
      },
      {
        'id' : 14,
        'name' : 'Multiple Properties',
        'checked' : false
      }
    ]
  } 

  checkBoxClick(e,idx) {
    this.checkboxes[idx].checked = e.checked;
    this.checkboxes = [...this.checkboxes]
    if(!this.checkboxes[idx].checked && this.selectallcheckboxEnable)
      {
        this.selectallcheckboxEnable=false
      }
    for(let i=0 ;i<this.checkboxes.length;i++)
    {
      this.proceedBtn.disabledproperty = true;
      
      
      if(this.checkboxes[i].checked)
      {
        this.proceedBtn.disabledproperty = false;
        this.proceedBtn = { ...this.proceedBtn };
        break;
      }
     
    }
    this.selectedConfigs = this.checkboxes.filter(item => item.checked==false);
    if(this.selectedConfigs.length===0)
    {
      this.selectallcheckboxEnable=true
    }
    this.changeDetectorRef.detectChanges();

    
    
  }

  onRevalidate(e){
            

        console.log('onRevalidate-this.selectedReVaidateOptions',this.selectedReVaidateOptions)
        this.business.getValidatedRetailConfigurationUIResultPMS(true,this.selectedReVaidateOptions).then(data=>{
          console.log('onRevalidate-getValidatedRetailConfigurationUIResultPMS',data)
          console.log('this.allTableContent',this.allTableContent)
          console.log('this.tableContent',this.tableContent)
        
        // Create a map for fast lookup based on a unique key
        const contentMap = new Map<string, any>();

        // Populate the map with objects from allTableContent using a unique key
        this.allTableContent.forEach(a => {
          const key = `${a.configkey}-${a.uiHeader}-${a.config}`;
          contentMap.set(key, a); // The map holds references to objects in allTableContent
        });

        // Iterate over the data array to update matching objects in allTableContent
        let skippedUiheader:any[]=[]
        data.forEach(item => {
         
          let incomeUIheader=item.uiHeader
          if(!skippedUiheader.includes(item.uiHeader) )
            {
              if(this.allTableContent.filter(a=>a.uiHeader==item.uiHeader)!=data.filter(b=>b.uiHeader==incomeUIheader))
                {
                  
                      skippedUiheader.push(item.uiHeader)
                      this.allTableContent=this.allTableContent.filter(a=>a.uiHeader!=item.uiHeader)
                      console.log('not eqaul income')
                      this.allTableContent=[...this.allTableContent,...data.filter(b=>b.uiHeader==incomeUIheader)]
                    
                }

                if (this.selectedReVaidateOptions.includes(item.uiHeader) && !skippedUiheader.includes(item.uiHeader)) {
                  const key = `${item.configkey}-${item.uiHeader}-${item.config}`;
                  const tableItem = contentMap.get(key); // Retrieve the object directly from the map
      
                  if (tableItem) {
                    // Update the properties directly in the original allTableContent array
                    tableItem.status = item.status; 
                    tableItem.reason = item.reason; 
                  }
                }
            }
          
        });

        this.allTableContent.forEach((item, index) => {
          item.id = index + 1; // Assign new ID starting from 1
        });

          console.log('altered this.allTableContent',this.allTableContent)
          //this.tableContent = data;
          //this.allTableContent=data
          
          this.selectedViewOptions = this.invoiceForm.controls.category?.value?.map(o => o.id);
        console.log('selectedViewOptions',this.selectedViewOptions)
          let revalidateselecttedUIModel:ConfigValidationUIModel[]=[]
          if(this.selectedViewOptions!=undefined)
          {
            if(this.selectedViewOptions?.length<=0)
              {
                this.tableContent=this.allTableContent
              }
              else
              {
                for(let i=0 ;i<this.selectedViewOptions?.length;i++)
                  {
                    
                    
                      
                    revalidateselecttedUIModel=[...revalidateselecttedUIModel,...this.allTableContent.filter(content=>content.uiHeader==this.selectedViewOptions[i])]
            
                    
                  
                  }
                  console.log('revalidateselecttedUIModel',revalidateselecttedUIModel)
                  this.tableContent=revalidateselecttedUIModel
              }
          }
          else
          {
            this.tableContent=this.allTableContent
          }
            
            

              
          this.dynamicCheckList()
          this.tableContent = [...this.tableContent]
          this.changeDetectorRef.detectChanges();
        })
        .catch((error)=>{
          console.log('errorgoing');
          console.log(error);
        });

  }
  searchFilter(e){
    this.searchText = e;
  }
  validateConfig(){
    this.selectedConfig = this.configurations.find(item=>item['configKey'] == this.selectedOption);
    let configUrl = this.selectedConfig.configUrl[0];
    var valueToBereplaced;
    var uriParams;
    switch(configUrl.get[0].masterUrl){
      case 'GetPropertyFeatures':
        let propertyId = parseInt(this.localization.GetPropertyInfo('PropertyId'));
        let productId = parseInt(this.localization.GetPropertyInfo('ProductId'));
        uriParams = { propertyId: propertyId, productId: productId };
        break;
      default:
        uriParams = undefined;
        break;
    }
    this.configValidationService.getDataFromConfigUrl(configUrl.host,configUrl.get[0].masterUrl,uriParams).then((data)=>{
      this.validateEmptyData(data);
      if(configUrl.get[0].childUrl != ''){
        this.validateChildUrl(configUrl,data,this.selectedConfig.configValidate);
        return;
      }
      if(this.selectedConfig.configValidate != null && this.selectedConfig.configValidate != undefined){
        this.validateData(this.selectedConfig.configValidate,data);
      }
    }).catch((error)=>{
      console.log(error);
    })
  }

  MasterFilterList()
  {
    const formArray = this.configurationForm.controls['configs'] as UntypedFormArray;
    //this.setprintOptionsForm();
    if (formArray) {
      (formArray).clear();
    }
    //this.configs = await this.business.getArAccounts(this.invoiceForm.controls.invoiceDate.value, this.selectedcategories,this.arBatchId);
    //this.configs.sort((a, b) => a.accCodeName.localeCompare(b.accCodeName));
    // this.configs.forEach(account => {
    //   this.addAccountControl(this.configurationForm.controls.configs);
    // });

    
   
    this.addConfigControl(this.configurationForm.controls.configs);
    this.changeDetectorRef.detectChanges();

    console.log('confiyy',this.configs)
  }

  addConfigControl(arConfigArrayControl) {
    return arConfigArrayControl.push(
      this.fb.group({
      
        accId: 1,
        accName: 'configutation test name',
        checked: false
      
      }));
  }

  selectAllConfigs(event) {
    //this.checkboxes[2].checked=true
    this.checkboxes.forEach(a=>a.checked=event.checked)
    this.proceedBtn.disabledproperty = !event.checked;
      this.proceedBtn = { ...this.proceedBtn };
    //this.checkboxes = [...this.checkboxes]
    if(event.checked)
    {
      this.selectallcheckboxEnable=true
    }
    else{
      this.selectallcheckboxEnable=false
    }
    

    
  }

  onProceed()
  {
    console.log('onproceed click')
    let selecttedUIModel:ConfigValidationUIModel[]=[]
    for(let i=0 ;i<this.checkboxes.length;i++)
      {
        
        if(this.checkboxes[i].checked)
        {
          
          selecttedUIModel=[...selecttedUIModel,...this.allTableContent.filter(content=>content.uiHeader==this.checkboxes[i].id)]

        }
       
      }
      console.log('selecttedUIModel',selecttedUIModel)
      this.tableContent=selecttedUIModel
  }


  validateEmptyData(data:any){
    let product:Products;
    if(Array.isArray(data)){
      if(data.length == 0){
        product = {
          id:this.gridData.length + 1,
          result:'Validation Failed. Data is Empty',
          status: this.decodeHtmlEntity('&#10007;')
        }
      }
    }
    else{
      if(data == null || data == undefined){
        product = {
          id:this.gridData.length + 1,
          result:'Validation Failed. Data is Empty',
          status: this.decodeHtmlEntity('&#10007;')
        }
      }
    }
    this.gridData.push(product);
    console.log(this.gridData);
    this.dataSource.data = this.gridData;
    this.changeDetectorRef.detectChanges();
  }

  validateData(configs:ConfigValidateItem[],data:any){
    let value:object = data;
    let isValidDataType:boolean = true;
    let isRequired:boolean;
    console.log('Into the Validate Data');
    console.log(value);
    for (const iterator of configs) {
      let product:Products;
      console.log(iterator);
      let configValue = this.getParameterCaseInsensitive(value,iterator.configurationName);
      console.log(configValue);
      if(configValue == undefined && iterator.validationConfig[0].required)
        isRequired = true;
      switch(iterator.validationConfig[0].dataType){
        case 'int':
          isValidDataType = typeof configValue === 'number';
          break;
        case 'string':
          isValidDataType = typeof configValue === 'string';
          break;
        case 'bool':
          isValidDataType = typeof configValue === 'boolean';
          break;
        default:
          break;
      }
      if (isRequired) {
        product = {
          id:this.gridData.length + 1,
          result:`Validation Failed. ${iterator.configurationName} is Mandatory`,
          status: this.decodeHtmlEntity('&#10007;')
        }
        this.gridData.push(product);
      }
      if (!isValidDataType) {
        product = {
          id:this.gridData.length + 1,
          result:`Validation Failed. ${iterator.validationConfig[0].dataType} is Incorrect`,
          status: this.decodeHtmlEntity('&#10007;')
        }
        this.gridData.push(product);
      }
      if ((iterator.validationConfig[0].host == '' && iterator.validationConfig[0].routeUrl != '') || (iterator.validationConfig[0].host != '' && iterator.validationConfig[0].routeUrl == '')) {
        product = {
          id:this.gridData.length + 1,
          result:'Validation Failed Either Host or RouteUrl is Empty',
          status: this.decodeHtmlEntity('&#10007;')
        }
        this.gridData.push(product);
      }
      if (iterator.validationConfig[0].host != '' && iterator.validationConfig[0].routeUrl != '') {
        console.log(iterator.validationConfig[0].routeUrl);
        console.log(iterator.validationConfig[0].host);
        this.configValidationService.getDataFromConfigUrl(iterator.validationConfig[0].host,"GetHealthStatusFromUri",{uri : "{"+ iterator.validationConfig[0].routeUrl+"}"}).then((data)=>{
          console.log(data)
        }).catch((error)=>{
          product = {
            id:this.gridData.length + 1,
            result: `Validation Failed due to Error ${error}`,
            status: this.decodeHtmlEntity('&#10007;')
          }
          this.gridData.push(product);
        })
      }
    }
  }

  validateChildUrl(url:ConfigUrlItem,data:any,configs:ConfigValidateItem[]){
    var childData;
    var uriParams;
    var uri = url.get[0].childUrl;
    var host = url.host;
    switch(uri){
      case 'GetPropertyFeatureConfiguration':
        childData = data.find(item=>item['featureName'] == this.selectedOption);
        uriParams = { PropertyFeatureId: childData.id };
        host = 'Payment';
        break;
      default:
        break;
    }
    this.configValidationService.getDataFromConfigUrl(host,uri,uriParams).then((data)=>{
      this.validateEmptyData(data);
      this.validateData(configs,data);
    }).catch((error)=>{
      console.log(error);
    })
  }

  decodeHtmlEntity(html: string): string {
    const textarea = document.createElement('textarea');
    textarea.innerHTML = html;
    return textarea.value;
  }

  getParameterCaseInsensitive(object, key) {
    console.log(key);
    console.log(object);
    if (true) {
      let obj = object.find(obj=>obj.configurationKey == key);
      return obj.configurationValue
    }
  }
/////////////////////////////////////////////////////////////////////////////////
  async getConfigurationResults()
  {
    this.business.getValidatedUIResult().then(data=>{
      console.log('getConfigurationResults',data)
      //this.tableContent = data;
    })
    .catch((error)=>{
      console.log('errorgoing')
      console.log(error)
    });
  }
//////////////////////////////////////////////////////////////////
   async getConfigurationKeyData() {
    this.utilities.ToggleLoaderWithMessage(true,'Validations on Progress please wait');
    this.business.getValidatedRetailConfigurationUIResultPMS(false,[]).then(data=>{
      this.utilities.ToggleLoaderWithMessage(true,'Validations on Progress please wait');
      console.log('getValidationJson-getConfigurationKeyData',data)
      this.tableContent = data;
      this.allTableContent=data
      this.dynamicCheckList()
      this.changeDetectorRef.detectChanges();
      this.utilities.ToggleLoaderWithMessage(false,'Validations on Progress please wait');
      //this.utilities.ShowError(this.localization.captions.common.Error , 'kindly check the config json and console');

    })
    .catch((error)=>{
      console.log('errorgoing');
      console.log(error);
      this.utilities.ToggleLoaderWithMessage(false,'Validations on Progress please wait');
          //this.utilities.ShowError(this.localization.captions.common.Error , this.localization.captions.shop.PayAgentNotInstalledError.replace("{{version}}", version));
          this.utilities.ShowError(this.localization.captions.common.Error , 'kindly check the config json and console');

    });
    
    //this.tableContent =data;

    //let content:any[]=[{id:'1',config:'masterconfig',configkey:'childconfig',reason:'reason',status:'y'},{id:'2',config:'masterconfig',configkey:'childconfig',reason:'reason',status:'y'}]
    //console.log('content',content)
    // content?.forEach( x => {
    //   this.tableContent?.push(x);
    // });
    //this.tableContent=content;
    //this.tableContent =content;
    //console.log('hedcodecontent',content)

    console.log('tableContent',this.tableContent)
    
  }

   async getViewOptions(checklistarrived:number[]): Promise<DropdownOptions[]> {
   
      return this.AccountCategoryDropDownMapper(checklistarrived);
}
private AccountCategoryDropDownMapper(checklistarrived:number[]): DropdownOptions[] {
    
  
  const result: DropdownOptions[] = []

  for(let chk=0;chk<checklistarrived.length;chk++)
    {

      let selchk=this.mainFilterList.find(a=>a.id==checklistarrived[chk])

      const account: DropdownOptions = {
        id: selchk.id,
        value: selchk.name,
        viewValue: selchk.name
       }
      
       result.push(account)
     
    }


    return result;

}

onCategorySelect(event) {
  console.log('event',event)
  this.selectedViewOptions = this.invoiceForm.controls.category.value.map(o => o.id);
console.log('selectedViewOptions',this.selectedViewOptions)
  let selecttedUIModel:ConfigValidationUIModel[]=[]
    for(let i=0 ;i<this.selectedViewOptions?.length;i++)
      {
        
        
          
          selecttedUIModel=[...selecttedUIModel,...this.allTableContent.filter(content=>content.uiHeader==this.selectedViewOptions[i])]

        
       
      }
      console.log('selecttedUIModel',selecttedUIModel)
      this.tableContent=selecttedUIModel

      if(this.selectedViewOptions.length==0)
      {
        this.tableContent=this.allTableContent
      }
}
  
onRevalidateConfigSelect(event) {
  console.log('event',event)
  this.selectedReVaidateOptions = this.invoiceForm.controls.reValidateConfigs.value.map(o => o.id);
console.log('selectedReVaidateOptions',this.selectedReVaidateOptions)
if(this.selectedReVaidateOptions?.length>0)
{
  this.RevalidateButton.disabledproperty =false
  
}
else
{
  this.RevalidateButton.disabledproperty =true
}
}
  dynamicCheckList()
  {
    let ChecklistArrived:number[] =Array.from(new Set(this.allTableContent.map(content=>content.uiHeader)))
    console.log('ChecklistArrived',ChecklistArrived)
    this.checkboxes = [];
    let viewOptions:DropdownOptions[]=[]
    
    for(let chk=0;chk<ChecklistArrived.length;chk++)
    {

      let selchk=this.mainFilterList.find(a=>a.id==ChecklistArrived[chk])

      const account: DropdownOptions = {
        id: selchk.id,
        value: selchk.name,
        viewValue: selchk.name
       }
      viewOptions.push(account)
      
       this.checkboxes.push(
        selchk
        )

    }

    this.checkboxes = [...this.checkboxes];

    this.checkboxes.forEach(a=>a.checked=true)
    this.proceedBtn.disabledproperty = false;
      this.proceedBtn = { ...this.proceedBtn };
      this.selectallcheckboxEnable=true


      this.ViewOptions = this.getViewOptions(ChecklistArrived);
      this.categoryInput = {
        form: this.invoiceForm,
        formControlName: 'category',
        placeHolder: 'View Options',
        selectOptions: this.ViewOptions ,
        isAll: true,
        isMultiSelect: true,
        showRequired:false,
        automationId : "'Dd_generateInvoice_category'"
      }
      this.RevalidateConfigInput= {
        form: this.invoiceForm,
        formControlName: 'reValidateConfigs',
        selectOptions: this.ViewOptions ,
        isAll: true,
        isMultiSelect: true,
        showRequired:false,
        automationId : "'Dd_generateInvoice'"
      }
  }
  

}

export interface Products {
  id: number;
  result: string;
  status: string;
}
