import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormArray, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { ReplaySubject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { Localization } from '../../localization/localization';
import { AgDateConfig, DropdownOptions } from '../../Models/ag-models';
import { DaysModel } from '../../shared/shared.modal';
import { RecurringEventBusiness } from './recurring-event.business';
import { DayType, monthlyType, RecurringDetails, RecurringType, recurringTypeConst, YearlyType } from './recurring-event.model';
import { CommonPropertyInformation } from '../../shared/services/common-property-information.service';

@Component({
  selector: 'app-recurring-event',
  templateUrl: './recurring-event.component.html',
  styleUrls: ['./recurring-event.component.scss'],
  providers: [RecurringEventBusiness]
})
export class RecurringEventComponent implements OnInit {

  captions: any;
  days: DaysModel[];
  dayCaption: string;
  dayError: string;
  isAllSelected: boolean;
  allSelectedValue: number[] = [];
  singleSelectedValue: number[] = [];
  daily: boolean;
  dayType = DayType;
  isDayMissing: boolean;
  monthlyTypeEnum = monthlyType;
  months: DropdownOptions[];
  yearlyTypeEnum = YearlyType;
  everyDD: DropdownOptions[];
  daysDD : DropdownOptions[];
  recurringFormGroup: UntypedFormGroup;
  recurringType: number;
  recurringTypeConst: any;
  weeklyData: RecurringType[];
  destroyed$: ReplaySubject<boolean> = new ReplaySubject(1);
  disableEvery:boolean;
  specificDaysList:any[];
  startDateInput: AgDateConfig;
  endDateInput: AgDateConfig;
  @Input('recurringInput')
  set recurringValue(value: RecurringDetails) {
    if (value) {
      this.patchRecurringData(value);
    }
  }
  @Input() recurringData: RecurringType[];
  @Input() startDate: Date;
  @Input() endDate: Date;
  @Output() recurringSelected: EventEmitter<any> = new EventEmitter();


  constructor(private FormBuilder: UntypedFormBuilder, private business: RecurringEventBusiness, private localization: Localization, private propertyInfo: CommonPropertyInformation) {
    this.recurringTypeConst = recurringTypeConst;
    this.captions = this.business.commonCaptions;
    this.recurringFormGroup = this.FormBuilder.group({
      recurringType: recurringTypeConst.daily,
      dayType: DayType.Every,
      monthlyType: monthlyType.day,
      every: '',
      date: '',
      month: '',
      yearlyType: YearlyType.onDay,
      onEvery: '',
      onday:'',
      onMonth:'',
      specificDays: this.FormBuilder.array([]),
      startDate: this.propertyInfo.CurrentDate,
      endDate: this.propertyInfo.CurrentDate,
      selectedDays: []
    });

    this.recurringFormGroup.valueChanges.pipe(takeUntil(this.destroyed$)).subscribe(value => {
      this.emitData();
    })
  }

  ngOnChanges(): void {
    //this.enableDefaultRecurringType(this.startDate, this.endDate);
  }

  ngOnInit() {
    this.startDateInput = {
      className: 'ag_pl--8',
      form: this.recurringFormGroup,
      formControlName: 'startDate',
      placeHolderId: 'lbl_startDate',
      placeHolder: this.localization.captions.ReportSchedular.lbl_startDate,
      isDateRequired: true,
      errorMessage: this.localization.captions.ReportSchedular.MissingStartDate,
      minDate: this.localization.getDate(this.propertyInfo.CurrentDate)
    };
    this.endDateInput = {
      className: 'ag_pl--8',
      form: this.recurringFormGroup,
      formControlName: 'endDate',
      placeHolderId: 'lbl_endDate',
      placeHolder: this.localization.captions.ReportSchedular.lbl_endDate,
      isDisabled: false,
      isDateRequired: true,
      errorMessage: this.localization.captions.ReportSchedular.MissingEndDate,
      minDate: this.localization.getDate(this.propertyInfo.CurrentDate)
    };
    this.weeklyData = this.business.weeklyData;
    this.months = this.business.months;
    this.everyDD = this.business.everyDD();
    this.recurringType = recurringTypeConst.daily;
    this.dayCaption = this.captions.everyDays;
    this.dayError = this.captions.MissingRepeatDays;
    this.emitData();
    this.getDaysList();
   
  }

  patchRecurringData(value) {
    this.specificDaysList = value.specificDays;
    this.buttonSelectionChange(value.recurringType);
    this.recurringFormGroup.patchValue(value);
    if(value.dayType==DayType.EveryWeekDay){
      this.disableEvery = true;
    }
  }

  getDaysList() {
    this.days = this.business.getDaysArray();
    this.daysDD =  this.business.everyDays();
    const formArray = this.recurringFormGroup.get('specificDays') as UntypedFormArray;
    if(this.specificDaysList&&this.specificDaysList.length>0)

    {

      this.specificDaysList.forEach(x => formArray.push(new UntypedFormControl(x)));

      this.isAllSelected=this.specificDaysList.every(x=>x);

    }else{

      this.days.forEach(x => formArray.push(new UntypedFormControl(false)));

    }
  }

  startDateChange(e) {
    if (this.isStartDateExceedsEndDate()) {
      this.recurringFormGroup.controls.endDate.setValue(this.recurringFormGroup.controls.startDate.value);
    }
    this.endDateInput.minDate = this.recurringFormGroup.controls.startDate.value;
    this.endDateInput = { ...this.endDateInput };
  }
  isStartDateExceedsEndDate(): boolean {
    const startDate = this.recurringFormGroup.controls.startDate.value;
    const endDate = this.recurringFormGroup.controls.endDate.value;
    return (endDate && startDate) ? this.resetTime(startDate) > this.resetTime(endDate) : true;
  }
  resetTime(date: Date): Date {
    return new Date(date.setHours(0, 0, 0, 0));
  }
  endDateChange(e) {
    if (this.isStartDateExceedsEndDate()) {
      this.recurringFormGroup.controls.startDate.setValue(this.recurringFormGroup.controls.endDate.value);
    }
    this.startDateInput.maxDate = this.recurringFormGroup.controls.endDate.value;
    this.startDateInput = { ...this.startDateInput };
  }

  buttonSelectionChange(event) {
    switch (event) {
      case 1:
        if (this.recurringType !== recurringTypeConst.daily) {
          this.recurringType = recurringTypeConst.daily;
          this.resetValues();
          this.dayCaption = this.captions.everyDays;
          this.dayError = this.captions.MissingRepeatDays;
          this.recurringFormGroup.controls.every.setValidators([Validators.required]);
        }
        break;
      case 2:
        this.disableEvery = false;
        if (this.recurringType !== recurringTypeConst.weekly) {
          this.recurringType = recurringTypeConst.weekly;
          this.resetValues();
          this.dayCaption = this.captions.everyWeeksOn;
          this.dayError = this.captions.MissingRepeatWeeks;
          this.recurringFormGroup.controls.every.setValidators([Validators.required]);
          this.recurringFormGroup.controls.specificDays.setValidators([Validators.required]);
        }
        break;
      case 3:
        this.disableEvery = false;
        if (this.recurringType !== recurringTypeConst.monthly) {
          this.recurringType = recurringTypeConst.monthly;
          this.resetValues();
          this.dayCaption = this.captions.everyMonths;
          this.dayError = this.captions.MissingRepeatDays;
          this.recurringFormGroup.controls.every.setValidators([Validators.required]);
          this.recurringFormGroup.controls.date.setValidators([Validators.required]);

        }
        break;
      case 4:
        this.disableEvery = false;
        if (this.recurringType !== recurringTypeConst.yearly) {
          this.recurringType = recurringTypeConst.yearly;
          this.resetValues();
          this.dayCaption = this.captions.days;
          this.recurringFormGroup.controls.every.setValidators([Validators.required]);
          this.recurringFormGroup.controls.month.setValidators([Validators.required]);
        }
        break;
    }
    // this.recurringFormGroup.reset();
    this.recurringFormGroup.controls.recurringType.setValue(event);
  }

  clearValidators() {
    this.recurringFormGroup.controls.dayType.setValidators(null);
    this.recurringFormGroup.controls.monthlyType.setValidators(null);
    this.recurringFormGroup.controls.every.setValidators(null);
    this.recurringFormGroup.controls.date.setValidators(null);
    this.recurringFormGroup.controls.month.setValidators(null);
    this.recurringFormGroup.controls.onEvery.setValidators(null);
    this.recurringFormGroup.controls.onMonth.setValidators(null);
    this.recurringFormGroup.controls.onday.setValidators(null);
    this.recurringFormGroup.controls.specificDays.setValidators(null);
    this.recurringFormGroup.controls.dayType.updateValueAndValidity();
    this.recurringFormGroup.controls.monthlyType.updateValueAndValidity();
    this.recurringFormGroup.controls.every.updateValueAndValidity();
    this.recurringFormGroup.controls.date.updateValueAndValidity();
    this.recurringFormGroup.controls.month.updateValueAndValidity();
    this.recurringFormGroup.controls.specificDays.updateValueAndValidity();
  }
  resetValues() {
    this.clearValidators();
    this.recurringFormGroup.reset();
    this.recurringFormGroup.controls.dayType.setValue(DayType.Every);
    this.recurringFormGroup.controls.monthlyType.setValue(monthlyType.day);
    this.recurringFormGroup.controls.yearlyType.setValue(YearlyType.onDay);
    this.recurringFormGroup.controls.recurringType.setValue(this.recurringType);
  }





  emitData() {
    this.recurringSelected.emit(this.recurringFormGroup as UntypedFormGroup);
  }

  enableDefaultRecurringType(selectedStartDate, selectedEndDate) {
    const dayDiff = this.business.dateDiff(selectedStartDate, selectedEndDate);
    const monthDiff = this.business.monthDiff(selectedStartDate, selectedEndDate);
    const yearDiff = this.business.yearDiff(selectedStartDate, selectedEndDate);
    this.recurringData.forEach(repeatType => {
      switch (repeatType.id) {
        case 1:
          repeatType.disabled = !(dayDiff >= 1);
          break;
        case 2:
          repeatType.disabled = !(dayDiff >= 8);
          break;
        case 3:
          repeatType.disabled = !(monthDiff >= 1);
          break;
        case 4:
          repeatType.disabled = !(yearDiff >= 1);
          break;
      }
    });
    const selectedOccuranceValues = this.recurringData.filter(selectedOccurances =>
      this.recurringFormGroup.controls.recurring.value && selectedOccurances.id === this.recurringFormGroup.controls.recurring.value.id
    );
    if (selectedOccuranceValues && selectedOccuranceValues.length > 0 && selectedOccuranceValues[0].disabled) {
      this.recurringType = recurringTypeConst.daily;
      this.recurringData = [...this.recurringData];
    }
  }

  onAllCheck(event) {
    this.isAllSelected = event.checked;
    this.recurringFormGroup.controls.specificDays['controls'].forEach(control => control.setValue(event.checked))
    if (this.isAllSelected) {
      this.days.forEach(x => {
        this.allSelectedValue.push(x.id)
      })
    }
    else {
      this.allSelectedValue = [];
    }
    this.recurringFormGroup.controls.selectedDays.setValue(this.allSelectedValue);
  }

  updateAll(eve, i) {
    this.isAllSelected = this.recurringFormGroup.controls.specificDays.value.every(value => value);
    let selectedId = this.days[i].id;
    if (eve.checked) {
      this.singleSelectedValue.push(selectedId);
    }
    else {
      this.singleSelectedValue.splice(i, 1);
    }

    if (this.isAllSelected) {
      this.allSelectedValue = this.singleSelectedValue;
    }

    this.recurringFormGroup.controls.selectedDays.setValue(this.singleSelectedValue);
  }

  onMonthlyTypeChange(event) {
    this.recurringFormGroup.controls.every.setValidators([Validators.required]);
    if (this.recurringFormGroup.value.monthlyType === monthlyType.day) {
      this.recurringFormGroup.controls.date.setValidators([Validators.required]);
    } else {
      this.recurringFormGroup.controls.date.setValidators(null);
    }
    this.recurringFormGroup.controls.date.reset();
    this.recurringFormGroup.controls.every.reset();
    this.recurringFormGroup.controls.onEvery.reset();
    this.recurringFormGroup.controls.onday.reset();
    this.recurringFormGroup.controls.every.updateValueAndValidity();
    this.recurringFormGroup.controls.date.updateValueAndValidity();
  }

  dayTypeChange(e){
    
    if(e.value == DayType.EveryWeekDay){
      this.recurringFormGroup.controls.every.setValue(1);
      this.recurringFormGroup.controls.every.setValidators(null);
      this.disableEvery = true;
    }
    else{
      this.recurringFormGroup.controls.every.reset();
      this.recurringFormGroup.controls.every.setValidators(Validators.required);
      this.disableEvery = false;
    }
    
  }
  yearlyChange(e){
    if(e.value == YearlyType.onDay){
      this.dayCaption = this.captions.days;
      this.recurringFormGroup.controls.month.setValidators(Validators.required);
      this.recurringFormGroup.controls.onEvery.reset();
      this.recurringFormGroup.controls.onEvery.setValidators(null);
      this.recurringFormGroup.controls.onday.reset();
      this.recurringFormGroup.controls.onday.setValidators(null);
      this.recurringFormGroup.controls.onMonth.reset();
      this.recurringFormGroup.controls.every.reset();
      this.recurringFormGroup.controls.month.reset();
      this.recurringFormGroup.controls.onMonth.setValidators(null);
      this.disableEvery = false;
    }else if(e.value == YearlyType.onThe){
      this.dayCaption = this.captions.everyDays;
      this.recurringFormGroup.controls.month.reset();
      this.recurringFormGroup.controls.month.setValidators(null);
      this.recurringFormGroup.controls.onEvery.setValidators(Validators.required);
      this.recurringFormGroup.controls.onday.setValidators(Validators.required);
      this.recurringFormGroup.controls.onMonth.setValidators(Validators.required);
      this.recurringFormGroup.controls.every.setValidators(null);
      this.disableEvery = false;
    }
  }
}
