import { Injectable } from '@angular/core';
import { ActionTypeEnum, SorTypeEnum } from 'src/app/common/components/cdkvirtual/cdkvirtual.model';
import { DropdownOptions, TableHeaderOptions, TableOptions } from 'src/app/common/Models/ag-models';
import { Localization } from 'src/app/common/localization/localization'
import * as FileSaver from 'file-saver';
import { DataUtilityDataService } from '../../dataservices/datautility.data.service';
import { API, UI } from 'src/app/common/Models/datautilitydetails-model';
import { UserAccessBreakPoints } from '../../constants/useraccess.constants';
import { UserAccessBusiness } from '../../dataservices/authentication/useraccess.business';

@Injectable()
export class ImportBusiness {
  captions: any;
  public isViewOnly: boolean = false;
  public isAllow: boolean = false;
  constructor(private _localization: Localization,
    private _dataservice: DataUtilityDataService,
    private _userAccessBusiness: UserAccessBusiness) {
    this.captions = this._localization.captions;
  }

  public async getModuleOptions(): Promise<DropdownOptions[]> {
    let apiModels = await this._dataservice.getImportModules();
    let id = 1;
    let value: DropdownOptions[] = apiModels.map(x => { return { value: x, viewValue: x, id: id++ }; }); return value;

  }

  public async postDataUtilityDetails(dataUtilityDetails: API.DataUtilityDetails, dataUtilityType: API.DataUtilityType): Promise<UI.DataUtilityDetails[]> {
    let apiModels: API.DataUtilityDetails[] = await this._dataservice.postDataUtilityDetails(dataUtilityDetails);
    apiModels = apiModels.filter(x => x.utilityType == dataUtilityType);
    return apiModels.map(x => this.uiMapper(x));

  }

  getHeaderOptions(): TableHeaderOptions[] {
    return [
      {
        key: 'id',
        displayName: this.captions.tabl_hdr_sNo,
        displayNameId: 'tabl_hdr_sNo',
        sorting: true
      },
      // {
      //   key: 'date',
      //   displayName: this.captions.tbl_hdr_date,
      //   displayNameId: 'tbl_hdr_date',
      //   sorting: true
      // },
      {
        key: 'fileName',
        displayName: this.captions.lbl_fileName,
        displayNameId: 'lbl_fileName',
        sorting: false
      },
      {
        key: 'totalRows',
        displayName: this.captions.lbl_totalRows,
        displayNameId: 'lbl_totalRows',
        sorting: false
      },
      {
        key: 'completedRows',
        displayName: this.captions.lbl_completedRows,
        displayNameId: 'lbl_completedRows',
        sorting: false
      },
      {
        key: 'status',
        displayName: this.captions.tbl_hdr_status,
        displayNameId: 'tbl_hdr_status',
        templateName: ActionTypeEnum.custom
      },
      {
        key: 'exportFileStream',
        displayName: this.captions.lbl_import,
        displayNameId: 'lbl_import',
        templateName: ActionTypeEnum.custom,
        sorting: false
      },
    ]
  }

  getTableOptions(): TableOptions {
    return {
      defaultSortOrder: SorTypeEnum.desc,
      defaultsortingColoumnKey: 'id',
    }
  }

  public async getAllEntries(serviceName: string, dataUtilityType: API.DataUtilityType): Promise<UI.DataUtilityDetails[]> {
    let apiModels: API.DataUtilityDetails[] = await this._dataservice.getAllDataUtilityImportDetails(serviceName);
    apiModels = apiModels.filter(x => x.utilityType == dataUtilityType);
    return apiModels.map(x => this.uiMapper(x));
  }
  public async getDataUtilityErrors(dataUtilityDetailId: number): Promise<UI.DataUtilityErrors[]> {
    let apiModels: API.DataUtilityErrors[] = await this._dataservice.getAllDataUtilityErrors(dataUtilityDetailId);
    return apiModels ? apiModels.map(x => ({ screenName: x.screenName, errorRowNo: x.errorRowNo, errorMessage: x.errorMessage, errorCode: x.errorCode } as UI.DataUtilityErrors)) : [];
  }

  private uiMapper(DataUtilityDetailsAPIModel: API.DataUtilityDetails): UI.DataUtilityDetails {
    return {
      id: DataUtilityDetailsAPIModel.id,
      fileName: DataUtilityDetailsAPIModel.fileName,
      status: DataUtilityDetailsAPIModel.status,
      dataUtilityErrors: DataUtilityDetailsAPIModel.dataUtilityErrors ? DataUtilityDetailsAPIModel.dataUtilityErrors.map(x => ({ errorRowNo: x.errorRowNo, errorMessage: x.errorMessage, screenName: x.screenName })) : [],
      totalRows: DataUtilityDetailsAPIModel.totalRows,
      completedRows: DataUtilityDetailsAPIModel.completedRows,
    } as UI.DataUtilityDetails;
  }

  public async getAllImportByScreenEntries(serviceName: string, screenName: string, fromScreen: boolean): Promise<UI.DataImportDetails[]> {
    let apiModels: API.DataImportDetails[] = await this._dataservice.getDataUtilityDetailsByScreen(serviceName, screenName, fromScreen);
    apiModels = apiModels;
    return apiModels.map(x => this.uiMapperForScreen(x));
  }

  private uiMapperForScreen(DataUtilityDetailsAPIModel: API.DataImportDetails): UI.DataImportDetails {
    return {
      serialNumber: DataUtilityDetailsAPIModel.serialNumber,
      fileName: DataUtilityDetailsAPIModel.fileName,
      status: DataUtilityDetailsAPIModel.status,
      totalRows: DataUtilityDetailsAPIModel.totalRows,
      completedRows: DataUtilityDetailsAPIModel.completedRows,
      id: DataUtilityDetailsAPIModel.datautilityId,
      date: this._localization.LocalizeDate(DataUtilityDetailsAPIModel.date) + ' ' + this._localization.LocalizeTime(DataUtilityDetailsAPIModel.date),
      userName: DataUtilityDetailsAPIModel.userName
    } as UI.DataImportDetails;
  }

  getTableOptionsByScreenImport(): TableOptions {
    return {
      defaultSortOrder: SorTypeEnum.desc,
      defaultsortingColoumnKey: 'serialNumber',
    }
  }

  getHeaderOptionsByrScreenImport(): TableHeaderOptions[] {
    return [
      {
        key: 'serialNumber',
        displayName: this.captions.lbl_serialNumber,
        displayNameId: 'lbl_serialNumber',
        sorting: true
      },
      {
        key: 'fileName',
        displayName: this.captions.lbl_fileName,
        displayNameId: 'lbl_fileName',
        sorting: false
      },
      {
        key: 'totalRows',
        displayName: this.captions.lbl_totalRows,
        displayNameId: 'lbl_totalRows',
        sorting: false
      },
      {
        key: 'completedRows',
        displayName: this.captions.lbl_completedRows,
        displayNameId: 'lbl_completedRows',
        sorting: false
      },
      {
        key: 'status',
        displayName: this.captions.tbl_hdr_status,
        displayNameId: 'tbl_hdr_status',
        templateName: ActionTypeEnum.custom
      },
      {
        key: 'exportFileStream',
        displayName: this.captions.lbl_FileDownload,
        displayNameId: 'lbl_FileDownload',
        templateName: ActionTypeEnum.custom,
        sorting: false
      },
      {
        key: 'date',
        displayName: this.captions.tbl_hdr_date,
        displayNameId: 'tbl_hdr_date',
        sorting: true
      },
      {
        key: 'userName',
        displayName: this.captions.lbl_username,
        displayNameId: 'lbl_username',
        sorting: false
      },
    ]
  }


  async download(dataUtilityId: number, fileName: string) {
    let file = await this._dataservice.downloadExcel(dataUtilityId);
    FileSaver.saveAs(file, fileName);
    return true;
  }
  async ValidateBreakPoints(): Promise<boolean> {
    const result = await this._userAccessBusiness.getUserAccess(UserAccessBreakPoints.IMPORT, true);
    this.isViewOnly = result.isViewOnly;
    this.isAllow = result.isAllow;
    return result.isAllow;
  }

  getCheckBoxList(moduleName: string) {
    let value = [{ value: 'TherapistCommision', checked: false, id: 1 },
    { value: 'Appointments', checked: false, id: 2 },
    { value: 'Therapist', checked: false, id: 1 }]
    return value;
  }

  async getFilesImportList(isFastTrack: boolean, accountingConfig: any = {}) {
    let apiModels: API.ModuleFile[] = await this._dataservice.getBulkImportDataUtilityEntityNames(isFastTrack, accountingConfig?.isVersaAccounting ?? false);
    var membershipFileNames = ['MembershipFacility', 'Main Club Information', 'Additional Family Other Info'];
    
    if (accountingConfig?.isSkipCondo ?? false) {
      const condoFileNames = ['PercentCategory', 'Condo Accounting Setup', 'PMS Codes', 'Seasons', 'OwnerPoolingGroup', 'Owner Analysis Setup', 'Default Check Run', 'Condo Pooling Factor Setup', 'Owner Transaction Code Setup', 
        'Condo Owner', 'Associate Condo Owner Contact', 'Rental and Statistics', 
      ];
      apiModels = apiModels.filter(x => !condoFileNames.includes(x.fileName));
    }
    //Temporarily removing membership releated files
    apiModels = apiModels.filter(x => !membershipFileNames.includes(x.fileName));
    if (apiModels.length > 0) {
      apiModels = apiModels.sort(({ listOrder: a }, { listOrder: b }) => a - b);
    }
    return apiModels.map(x => this.mapModuleFilestoUI(x, isFastTrack));
  }

  mapModuleFilestoUI(moduleFile: API.ModuleFile, isFastTrack: boolean) {
    return {
      moduleName: moduleFile.moduleName,
      value: moduleFile.fileName,
      checked: isFastTrack ? true : false,
      disabled: isFastTrack ? true : false
    } as UI.BulkImportModuleFiles;
  }

  public async PostDataFilesForBulkImport(selectedPackages: UI.BulkImportModuleFiles[], isFastTrack: boolean): Promise<API.BulkImport[]> {
    let apiSelectedPackages = selectedPackages.map(x => this.mapModuleFilestoAPI(x))
    let response = await this._dataservice.PostDataFilesForBulkImport(apiSelectedPackages, isFastTrack);
    return response;
  }

  mapModuleFilestoAPI(moduleFile: UI.BulkImportModuleFiles) {
    return {
      moduleName: moduleFile.moduleName,
      fileName: moduleFile.value
    } as API.ModuleFile;
  }
}
