import { T } from '@angular/cdk/keycodes';
import { Component, Inject, OnInit, ViewEncapsulation } from '@angular/core';
import { UntypedFormArray, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ReplaySubject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { AgInputFieldConfig } from 'src/app/common/Models/ag-models';
import { RetailLocalization } from '../../common/localization/retail-localization';
import { ButtonValue, FinancialBinLevel } from '../../retail.modals';
import { PaymentMethods } from '../../shared/business/shared.modals';
import { ButtonType, Product } from '../../shared/globalsContant';
import { RetailFeatureFlagInformationService } from '../../shared/service/retail.feature.flag.information.service';
import { RetailUtilities } from '../../shared/utilities/retail-utilities';
import { PaymentMethodBusiness } from '../payment-methods.business';
import { AlertType } from '../../shared/shared.modal';
import { RetailPropertyInformation } from '../../common/services/retail-property-information.service';

@Component({
  selector: 'app-create-cash-eqivalent-payment-method',
  templateUrl: './create-cash-eqivalent-payment-method.component.html',
  styleUrls: ['./create-cash-eqivalent-payment-method.component.scss'],
  encapsulation: ViewEncapsulation.None,
  providers:[PaymentMethodBusiness]
})
export class CreateCashEqivalentPaymentMethodComponent implements OnInit {
  captions: any;
  cashEQForm: UntypedFormGroup;
  isDefault: boolean = false;
  actionButton: ButtonValue;
  cancelButton: ButtonValue;
  paymentMethodNameFieldConfig: AgInputFieldConfig;
  dataInput: any;
  destroyed$: ReplaySubject<boolean> = new ReplaySubject(1);
  radioOptions: {id: number, value :string} [];
  enableFinancialBin = false;
  financialBinApplicablePayMenthod = [PaymentMethods.ARPost, PaymentMethods.RoomCharge, PaymentMethods.GroupCharge, PaymentMethods.ARAcctPost, PaymentMethods.HotelComp];
  floatLabel: string;
  additionalInfoForm: UntypedFormGroup;
  isClonnedPayment: boolean;
  showSignatureCapture: boolean;
  isPendingSettlement: boolean;
  isVatEnabled = false;
  showCashDrawer: boolean = false;
  isCashDrawerEnabled:boolean = false;

  constructor(
    private fb: UntypedFormBuilder,
    private localization: RetailLocalization,
    private retailUtilities: RetailUtilities,
    private propertyInformation: RetailPropertyInformation,
    @Inject(MAT_DIALOG_DATA) data: any,
    private dialogRef: MatDialogRef<CreateCashEqivalentPaymentMethodComponent>,
    public featureFlagInfo: RetailFeatureFlagInformationService, private paymentMethodBusiness: PaymentMethodBusiness
  ) {
    this.dataInput = data;
    this.floatLabel = this.localization.setFloatLabel;
    this.isVatEnabled = this.propertyInformation.IsVATEnabled;
  }

  ngOnInit(): void {
    this.captions = this.localization.captions.retailsetup;
    this.showSignatureCapture =  this.dataInput?.isSigCapEnabled;
    this.isCashDrawerEnabled = !!sessionStorage.getItem('CashDrawerURL');
    this.InitiateFormControlsAndVariables();
    if (this.dataInput?.datarecord?.form) {
      const editdata = this.dataInput?.datarecord?.form;
      this.isDefault = editdata.isDefault;
      this.enableFinancialBin = this.financialBinApplicablePayMenthod.includes(this.paymentMethodBusiness.getOriginalTenderId(editdata.paymentTypeId, editdata.parentTypeId));
      this.cashEQForm.patchValue({
        isActive: editdata?.isActive,
        allowEarn: editdata?.allowEarn,
        requireComments: editdata?.requireComments,
        requireSignatureCapture: editdata?.requireSignatureCapture,
        paymentMethodName: editdata?.paymentMethod,
        listOrder: editdata?.listOrder,
        requireReceipts: editdata?.requireReceipts,
        noOfReceipts: editdata?.noOfReceipts,
        financialBinLevel: editdata.financialBinLevel || FinancialBinLevel.Category,
        includeSignatureLine: editdata?.includeSignatureLine,
        isAutoRemoveTax: editdata?.isAutoRemoveTax,
        tenderReducesDiscount: editdata?.tenderReducesDiscount,
        postToPMS: editdata?.postToPMS,
        requireCashDrawer:editdata?.requireCashDrawer
      });
      this.IsRequireReceipts([editdata?.requireReceipts]);
      this.IsRequireComments(editdata);
      this.isPendingSettlement = editdata.paymentTypeId  == PaymentMethods.PendingSettlement
      this.patchAdditionalConfigValue(JSON.parse(editdata.additionalConfigurations));
    }
  }
  
  InitiateFormControlsAndVariables() {
    this.radioOptions = [
      {
        id: FinancialBinLevel.Category,
        value: this.captions.CategoryLevel
      },
      {
        id: FinancialBinLevel.Class,
        value: this.captions.ClassLevel
      },
      {
        id: FinancialBinLevel.Transaction,
        value: this.captions.TransactionLevel
      }];

    this.cashEQForm = this.fb.group({
      isActive: true,
      allowEarn: false,
      requireComments: true,
      requireSignatureCapture: false,
      paymentMethodName: ["", Validators.required],
      listOrder: [''],
      requireReceipts: true,
      noOfReceipts: this.dataInput?.datarecord?.noOfReceipts ? this.dataInput?.datarecord?.noOfReceipts : 1,
      financialBinLevel: FinancialBinLevel.Category,
      includeSignatureLine: true,
      isAutoRemoveTax: false,
      tenderReducesDiscount: false,
      postToPMS: true,
      requireCashDrawer:false
    });
    this.actionButton = {
      type: 'primary',
      label: this.captions.SAVE,
      disabledproperty: true
    };
    this.cancelButton = {
      type: 'tertiary',
      label: this.captions.Cancel,
    };
    this.paymentMethodNameFieldConfig = {
      className: '',
      errorMessageId: 'err_paymentMethodName',
      errorMessage: this.captions.err_paymentMethodName,
      form: this.cashEQForm,
      formControlName: 'paymentMethodName',
      placeHolderId: 'lbl_paymentMethodName',
      placeHolder: this.captions.lbl_paymentMethodName,
      maxlength: 50,
      showRequired: true,
      inputType: 'noprespace,nospecailchar',
      focus: false,
      automationId: 'Txt_createCashEqivalentPayment_paymentMathodName'
    };
    this.cashEQForm.valueChanges.pipe(takeUntil(this.destroyed$)).subscribe(val => {
      this.actionButton.disabledproperty = !(this.cashEQForm.dirty && this.cashEQForm.valid);
    }); 
    this.additionalInfoForm = this.fb.group({
      additionalInfoFormArray: this.fb.array([
        this.fb.group({
          Key:'',
          Value:''
        })
      ]),
      data: {}
    });
    this.additionalInfoForm.valueChanges.pipe(takeUntil(this.destroyed$)).subscribe(() => {
      this.actionButton.disabledproperty = !(this.additionalInfoForm.dirty && this.additionalInfoForm.valid);
    });
  }

  onAction(e) {
    this.actionButton.disabledproperty = true;
    if (!this.featureFlagInfo.AllowAccrualPoints) {
      this.cashEQForm.controls.allowEarn.setValue(false);
    }
    const clickReturnValue = {
      from: this.dataInput?.datarecord?.mode,
      formValues: this.cashEQForm.value,
      additionalConfig: this.additionalInfoForm.controls.additionalInfoFormArray.value
    };
    this.dialogRef.close(clickReturnValue);
  }

  onCancel(e) {
    this.dialogRef.close(undefined);
  }

  close(e) {
    this.dialogRef.close(undefined);
  }

  IsRequireReceipts(e) {
    if (e[0]){
      this.cashEQForm?.controls["noOfReceipts"].enable();
      var noOfReceipts=this.cashEQForm.controls["noOfReceipts"].value;
      this.cashEQForm.controls["noOfReceipts"].setValue(noOfReceipts?noOfReceipts:this.paymentMethodBusiness.defaultNoOfReceipt);
    }
    else {
      this.cashEQForm?.controls["noOfReceipts"].disable();
      this.cashEQForm.controls["noOfReceipts"].setValue(0);
    }
  }

  IsRequireComments(editdata) {
    this.isClonnedPayment = editdata?.parentTypeId > 0 || this.dataInput?.isClonnedPayment;
  }

  validateSigCap(event: any) {
    let isSigCapEnabled: boolean = event[0]
    let isIncludeSignatureLineEnabled: boolean = this.cashEQForm?.controls["includeSignatureLine"].value;
    if (!isIncludeSignatureLineEnabled && isSigCapEnabled) {
      this.retailUtilities.showAlert(this.captions.warningEnableSigCap, AlertType.Warning, ButtonType.Ok, x => {
        setTimeout(() => { this.cashEQForm?.controls["requireSignatureCapture"].setValue(false); }, 300);
      });
    }
  }

  validateSignature(event: any) {
    let isSigCapEnabled: boolean = this.cashEQForm?.controls["requireSignatureCapture"].value;
    let isIncludeSignatureLineEnabled: boolean = event[0];
    if (!isIncludeSignatureLineEnabled && (this.showSignatureCapture && isSigCapEnabled)) {
      this.retailUtilities.showAlert(this.captions.warningDisableIncludeSign, AlertType.Warning, ButtonType.Ok, x => {
        setTimeout(() => { this.cashEQForm?.controls["includeSignatureLine"].setValue(true); }, 300);
      });
    }
  }

  patchAdditionalConfigValue(data : any){
    if(data && data?.length > 0){
      const control = this.additionalInfoForm.get('additionalInfoFormArray') as UntypedFormArray;
      let index = control.value.findIndex(x => x.Key === '' &&  x.Value ==='');
      if(index >= 0){
        control.removeAt(index);
      }
      data.forEach(x => {
        const form = this.fb.group(x);
        control.push(form);
      });
    }
  }

  addArray(e) {
    const form = this.fb.group({
    Key:'',
    Value:''
    });
    const control = this.additionalInfoForm.get('additionalInfoFormArray') as UntypedFormArray;
    control.push(form);
  }

  removeArray(e) {
    const control = this.additionalInfoForm.get('additionalInfoFormArray') as UntypedFormArray;
    control.removeAt(e)
  }

}
