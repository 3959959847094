import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { ReportAPIModel, ReportAPIOptions, ReportDownloadFormat } from 'src/app/common/shared/retail.modals';
import { Localization } from 'src/app/common/localization/localization';
import { saveFile } from 'src/app/common/components/preview-report/preview-report.component';
import { CommonUtilities } from 'src/app/common/shared/shared/utilities/common-utilities';
import { ButtonType } from "src/app/common/shared/shared/globalsContant";
import { ReportCommunication } from '../communication/services/report.service';
import { RoutesConstant } from '../constants/reportcode.constants';
import { AlertType } from '../enums/shared-enums';

@Injectable()
export class DownloadReportService {
    captions;
    constructor(
        private http: ReportCommunication,
        private localization: Localization,
        private utils: CommonUtilities,
    ) {
        this.captions = this.localization.captions;
    }

    public DownloadReport(type: ReportDownloadFormat, options: ReportAPIOptions, downloadFileName?: string, noOfPrintCopies: number = 1, showPrintDialog: boolean = false, callBack?: any, isDownloadWithPreview?: boolean): void {
        let fileName: string;
        let downloadname: string = downloadFileName ? downloadFileName : options.code;
        if (type == "PDF") {
            fileName = `${downloadname}.pdf`
        }
        else if (type == "WORD") {
            fileName = `${downloadname}.docx`
        } else if (type == "EXCEL" || type == "RAWDATA") {
            fileName = `${downloadname}.xlsx`
        } else if (type == "IMAGE") {
            fileName = `${downloadname}.jpeg`
        }
        options.format = type;
        let apiModel: ReportAPIModel = this.createAPIOptions(options);
        this.http.putHTTPBlobData({
            route: environment["report"] + '/' + RoutesConstant.GetReport,
            body: apiModel
        }, 'blob').subscribe(
            res => {
                if (showPrintDialog || isDownloadWithPreview) {
                    this.showFile(res);
                    if (callBack) {
                        callBack();
                    }
                }
                if (!showPrintDialog || isDownloadWithPreview) {
                    for (let index = 0; index < noOfPrintCopies; index++) {
                        saveFile(res, fileName);
                    }
                }
            },
            async err => {
                let error = await err.error.text();
                let errorCode = JSON.parse(error).errorCode;
                const errorTxt = this.localization.getError(errorCode);
                if (errorCode.toString() == "-118") {
                    this.utils.showAlert(this.captions.lbl_noDataFound, AlertType.Info, ButtonType.Ok);
                } else {
                    this.utils.showError(errorTxt);
                }
            });
    }

    private showFile(blob) {
        var newBlob = new Blob([blob], { type: "application/pdf" })
        if (window.navigator && (window.navigator as any).msSaveOrOpenBlob) {
            (window.navigator as any).msSaveOrOpenBlob(newBlob);
            return;
        }
        const data = window.URL.createObjectURL(newBlob);
        let newTab: Window = window.open(data);
        newTab.focus();
        newTab.print();
        setTimeout(function () {
            // For Firefox it is necessary to delay revoking the ObjectURL
            window.URL.revokeObjectURL(data);
        }, 100);
    }


    private createAPIOptions(options: ReportAPIOptions): ReportAPIModel {

        let apiRequestModel: ReportAPIModel = {
            code: options.code,
            format: options.format ? options.format : "HTML",
            downloadFileName: "Download",
            parameters: this.arrayToObject(options.params),
            uRIParams: this.arrayToObject(options.URIParams),
            filterBody: options.filters,
            dateFormat: this.localization.dateFormat,
            language: "",
            reportData: options?.reportData
        }
        return apiRequestModel;
    }

    private arrayToObject(objectArr: any[]): { [key: string]: string } {
        var result = {};
        if (objectArr && objectArr.length > 0) {
            objectArr.forEach(o => {
                result[Object.keys(o)[0]] = Object.values(o)[0];
            });
        }
        return result;   //Dictionary<string,string>
    }
}

