import { Component, OnInit, Input, Output, EventEmitter, ViewChild, ElementRef, HostListener, ChangeDetectorRef, ViewEncapsulation } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, UntypedFormBuilder, Validators, UntypedFormArray } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatMenuTrigger } from '@angular/material/menu';
import { ReplaySubject } from 'rxjs';
import { UserAlerts } from '../../common/config/alerts-config';
import { RetailLocalization } from '../../common/localization/retail-localization';
import { RetailUtilities } from '../../shared/utilities/retail-utilities';
import { LoadDecimalValuePipe } from '../../shared/pipes/load-decimal-value.pipe';
import { CustomCurrencyPipe } from '../../common/localization/currency.pipe';
import { LocalizeDatePipe } from '../../common/localization/localize-date.pipe';
import { AlertMessagePopupComponent } from '../../shared/alert-message-popup/alert-message-popup.component';
import * as myGlobals from '../../shared/globalsContant'; //CONSTANT FILE ADD ANY CONSTANT VALUE
import * as _ from 'lodash';
import { takeUntil } from 'rxjs/operators';
import { FilterPipe } from '../../shared/pipes/filter-pipe.pipe';
import { RetailFunctionalityBusiness } from 'src/app/retail/shared/business/retail-functionality.business';
import { AgToggleConfig } from 'src/app/common/Models/ag-models';

@Component({
  selector: 'app-retail-commission-setup-table',
  templateUrl: './commission-setup-table.component.html',
  styleUrls: ['./commission-setup-table.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class RetailCommissionSetupTableComponent implements OnInit {
  @ViewChild('scroll') scrollvalue;
  RadiobuttonOption: boolean = false;
  sortableHeader: any;
  maxCol: number;
  HighlightRow: boolean = false;
  SelectRow: boolean = false;
  commissionTableOptions: boolean = false;
  SelectedData: any = [];
  sortingColoumn: any;
  setinterscroll: any;
  checkAll: boolean = false;
  editEvent: any;
  enableToggleButton: boolean = false;
  IfBooleanCheck: boolean = false;
  SetColumnGridValue: boolean = false;
  CheckInnerBoolValue: boolean = false;
  enableRowCheck: boolean = false;
  inActiveTherapist: UntypedFormControl;
  inActiveService: UntypedFormControl;
  public indeterminate: any;
  isDataLoading: boolean = false;
  @Input() options;
  @Input() isRoleSetUpReadOnly;
  @Input() scrollPositionReset;
  @Input() showOutletInGrid;
  @Output() afterEditClose: EventEmitter<any> = new EventEmitter();
  @Output() dragDropEvt: EventEmitter<any> = new EventEmitter();
  @Output() deleteEvt: EventEmitter<any> = new EventEmitter();
  @Output() editEvt: EventEmitter<any> = new EventEmitter();
  @Output() InActiveTherapistEvt: EventEmitter<any> = new EventEmitter();
  @Output() RowSelectEmitter: EventEmitter<any> = new EventEmitter();
  @Output() userActionEvt: EventEmitter<any> = new EventEmitter();
  @Output() CurrentRowSelectedEmitter: EventEmitter<any> = new EventEmitter();
  @Output() toggleEvtEmitter: EventEmitter<any> = new EventEmitter();
  @Output() addClick: EventEmitter<any> = new EventEmitter();
  @Output() printEvt: EventEmitter<any> = new EventEmitter();
  @Output() inactiveToggleEvtEmitter: EventEmitter<any> = new EventEmitter();
  @Output() doneEvtEmitter: EventEmitter<any> = new EventEmitter();
  @Output() LBLClickEvtEmitter: EventEmitter<any> = new EventEmitter();
  @Output() radioClickEvtEmitter: EventEmitter<any> = new EventEmitter();
  @Output() openWaitlist: EventEmitter<any> = new EventEmitter();
  @Output() scrollEmitter: EventEmitter<any> = new EventEmitter();
  @Output() sortEmitter: EventEmitter<any> = new EventEmitter();
  @ViewChild(MatMenuTrigger) trigger: MatMenuTrigger;
  @ViewChild('tableInput') tableInput: ElementRef;
  IsViewOnly: boolean = false;
  IsAccessAllowed: boolean = false;
  IsCheckAll: boolean = false;
  InActiveTherapistChkBoxEvt: any;
  orderTypearr: any = [];
  orderType: any = 'asc';
  currentIndex: any;
  hdrArray: any = [];
  bodyArray: any = [];
  selectedDefaultHeader: any;
  searchText: any;
  hdrkeyArray: any = [];
  originalHdrKeyArray: any = [];
  PlaceHoldertext: string;
  EnableActions: boolean;
  currentPage: any = 1;
  EnablePagination: boolean = true;
  InactiveTherapists: boolean = false;
  ChkInactiveService: boolean = false;
  InactiveRoles: boolean = false;
  SelectedSettingId: number = 1;
  blnDraggable: boolean = true;
  remailLength: any = [];
  Sortable: any;
  highlight: any;
  NewerData: any;
  previousSelectedColumn: any;
  givenOrderType: any;
  EditMoreOption: any;
  SelectOnlyRow: boolean = false;
  defaulSortColumn: any;
  overriddenSortColumn: string;
  sortColumnDataType: string;
  tableId: any;
  editable: boolean = true;
  waitlistIcon: boolean = false;
  deletable: boolean = true;
  deleteIcon: boolean = true;
  customHeader: boolean = false;
  pageTitle: string;
  customeHeaderOptions: any = [];
  quicksale: UntypedFormGroup;
  customHeaderButton: string;
  dropdownOptions: any = []
  captions: any = {};
  sticky: boolean = false;
  table: UntypedFormGroup;
  ArrayList = [];
  disableDelete: any;
  showToggle: any;
  toggleDisplayText: any;
  roleSetup: UntypedFormGroup;
  SearchKeyArr: any;
  DoneCancel: boolean = false;
  viewCheckedFlag: boolean = false;
  userAction: boolean = false;
  IsRetailCodeSetup: boolean;
  disableEditButton: boolean; //this boolean will prevent certain screens to show Edit button during view only break point applied.
  isValidRoleName: boolean = false;
  isEdit: boolean;
  editableRow: any;
  selectedrowId: number;
  tempTableData: any;
  editRecordsArray: any = [];
  activeIndex: any;
  isEditOptionRemove: boolean = false;
  isReadOnly: boolean = false;
  IsMoreOptionReadOnly: boolean = false;
  $destroyed: ReplaySubject<boolean> = new ReplaySubject(1);
  GridType = myGlobals.GridType;
  RetailTransactions = myGlobals.RetailTransactions;
  functionalities: { [key: string]: boolean } = {};
  ShowNoOfRecordInGrid = myGlobals.RetailFunctionalities.ShowNoOfRecordInGrid;
  hideScroll: boolean;
  bufferCount: any;
  floatLabelNever: string;
  totalMsg: string;
  customToggle: AgToggleConfig;

  @HostListener('mousedown', ['$event', '$event.target'])
  onMousedown(e: MouseEvent, element: any) {
    const isDraggable = element.classList.contains('draggable');
    if (!isDraggable && element.closest('td')) {
      e.preventDefault();
    }
  }

  constructor(public dialog: MatDialog, private userAlerts: UserAlerts, public el: ElementRef, public fb: UntypedFormBuilder, public localization: RetailLocalization, private utils: RetailUtilities, private _cdRef: ChangeDetectorRef, private loaddecimalvalue: LoadDecimalValuePipe, private currency: CustomCurrencyPipe, private localizedate: LocalizeDatePipe, private _filterPipe: FilterPipe, private func: RetailFunctionalityBusiness) {
    this.captions = this.localization.captions;
    this.customHeaderButton = this.localization.captions.setting.Add;
    this.floatLabelNever = this.localization.setFloatLabelNever;
    for (let i = 1; i <= this.ArrayList.length; i++) {
      this.ArrayList.push(`item ${i}`);
    }
    this.table = this.fb.group({
      IsCheckAll: false,
      tablebody: this.fb.array([this.fb.group({ id: '', activetoggle: false, donecancel: false, category: "" })])
    });
    this.roleSetup = this.fb.group({
      roleName: ['', Validators.required],
      activetoggle: [false]
    });
    this.customToggle = {
      group: this.table,
      formControlName: 'customToggle',
      automationId:"'Tog_table_customToggle'"
    }
    this.inActiveTherapist = new UntypedFormControl(false);
    this.inActiveService = new UntypedFormControl(false);
  }

  checkValid(event) {
    if (event && event.target && event.target.value.trim() !== '') {
      this.isValidRoleName = true;
    }
    else {
      this.isValidRoleName = false;
    }
  }

  CreateTablerowFormGroup(optionsData) {
    let bodyArr: any = this.table.get('tablebody') as UntypedFormArray;
    bodyArr.controls = [];
    bodyArr.value = [];
    if (optionsData) {
      for (let data of optionsData[0].TablebodyData) {
        let bodyData = data;
        const tempActiveFalse = (bodyData.active ? bodyData.active : false);
        bodyArr.push(this.fb.group({
          id: bodyData.id,
          activetoggle: bodyData.isActive ? bodyData.isActive : tempActiveFalse,
          donecancel: this.fb.control(false),
          category: this.fb.control(bodyData.category),
          rowDisabled: this.editEvent && this.editEvent.id == bodyData.id ? this.editEvent.rowDisabled : false
        })
        );
      }
    }
    return bodyArr;
  }

  UpdateTableFormGroup(optionsData) {
    let bodyArr: any = this.table.get('tablebody') as UntypedFormArray;
    if (optionsData) {
      optionsData[0].TablebodyData.forEach(res => {
        let index = bodyArr.value.findIndex(x => x.id == res.id);
        if (index != -1)
          bodyArr.value[index].activetoggle = res.active;
      });
    }
    return bodyArr;
  }

  onDragOver(event, ele, newarr) {
    event.preventDefault();  
  }

  onItemDrop(event, ele, newarr) { 
    let draggeddata = event.dragData;
    let droppeddata = ele;
    let dragindex = newarr.indexOf(draggeddata);
    let dropindex = newarr.indexOf(droppeddata);
    let dragLstOrder: listOrder = draggeddata;
    let dropLstOrder: listOrder = droppeddata;
    this.dragDropEvt.emit([dragLstOrder.listOrder, dropLstOrder.listOrder, this.SelectedSettingId, this.InActiveTherapistChkBoxEvt]);
    newarr.splice(dragindex, 1);
    newarr.splice(dropindex, 0, draggeddata);
  }

  ngOnInit() {
    if (typeof this.options == "undefined") { return; }
    this.defaulSortColumn = this.options[0].Sortable;
    this.showToggle = this.options[0].showToggle;
    this.toggleDisplayText = this.options[0].toggleDisplayText;
    const temp = (this.options[0].Sortable ? this.options[0].Sortable : 'id');
    this.Sortable = this.selectedDefaultHeader ? this.selectedDefaultHeader : temp;
    this.sortingColoumn = this.hdrkeyArray.indexOf(this.Sortable);
    this.selectedDefaultHeader = this.Sortable;
    this.orderTypearr = [];
    this.userAction = this.options[0].userAction;
  }

  ngOnDestroy() {
    this.$destroyed.next(true);
    this.$destroyed.complete();
  }

  setMatformWidth(myElement) {
    if (this.tableInput) {
      let minWidth = myElement.parentElement.parentElement.getElementsByClassName('actionitems')[0] ? myElement.parentElement.parentElement.getElementsByClassName('actionitems')[0]['offsetWidth'] : 300; //min-300 max-470
      minWidth += myElement.getElementsByClassName('search-container')[0] ? myElement.getElementsByClassName('search-container')[0]['offsetWidth'] : 0;
      minWidth += myElement.getElementsByClassName('table-toggle-switches')[0] ? myElement.getElementsByClassName('table-toggle-switches')[0]['offsetWidth'] : 0;
      minWidth += myElement.getElementsByClassName('custom-retail-inputs')[0] ? myElement.getElementsByClassName('custom-retail-inputs')[0]['offsetWidth'] : 0;
      return minWidth;
    }
  }

  scrollEndHandler(arg) {
    if (!this.bodyArray || !this.bodyArray.length || arg.endIndex !== this.bodyArray.length - 1) { return; }
    this.scrollEmitter.emit({
      searchText: this.searchText,
      scrollArg: arg,
      viewPortItems: this.scrollvalue.viewPortItems
    });
  }

  calculateWidth() {
    Array.from(document.querySelectorAll('#SPACommissionSetupCustomTable>ng-scrollbar>.ng-scrollbar-container>.ng-scrollbar-view>table')).forEach((table, index) => {
      if (table) {
        let tableHeight = table['offsetHeight'];
        let parentHeight = table.closest("#fixed-CommissionSetup-container")['offsetHeight'];
        if (parentHeight <= 93) { parentHeight = 193 }
        if (parentHeight > tableHeight) {
          table.closest("#SPACommissionSetupCustomTable")['style']['height'] = tableHeight + 2 + 'px';
        }
        else if (parentHeight < tableHeight) {
          table.closest("#SPACommissionSetupCustomTable")['style']['height'] = (parentHeight - 60) + 'px';
        }
      }
    });
    let searchClass = document.getElementsByClassName('CustomDataTable');
    for (let i = 0; i < searchClass.length; i++) {
      let pageHeader = searchClass[i].getElementsByClassName('page-header')[0] ? searchClass[i].getElementsByClassName('page-header')[0]['offsetWidth'] : 0;
      let searchInput = searchClass[i].getElementsByClassName('searchpt')[0];
      if (pageHeader > 0) {
        pageHeader = pageHeader - this.setMatformWidth(searchClass[i]) - 60;
      }
      let inputLength = this.tableInput ? this.tableInput.nativeElement.getAttribute('data-placeholder').length : 1;
      let inputWidth = inputLength <= 30 ? inputLength * 10 : inputLength * 7.5 + 20;
      if (searchInput && pageHeader > 0) {
        searchInput['style'].width = (pageHeader > inputWidth) ? inputWidth + 'px' : pageHeader + 'px';
      }
    }
  }

  ngAfterViewChecked() {
    if (this.tableInput && !this.viewCheckedFlag) {
      this.viewCheckedFlag = true;
      this.calwidthwithtimeout();
    }
    this._cdRef.detectChanges();
  }

  async ngAfterViewInit() {
    this.functionalities = await this.func.getRetailFunctionality();
    this.calwidthwithtimeout();
    this._cdRef.detectChanges();
  }

  ngOnChanges() {
    this.editRecordsArray = [];
    this.viewCheckedFlag = false;
    this.table.value.tablebody = this.CreateTablerowFormGroup(this.options);
    if(this.functionalities[myGlobals.RetailFunctionalities.ShowNoOfRecordInGrid]){
      this.totalMsg = this.localization.replacePlaceholders(this.captions.lbl_totalRecordsFoundMsg, ['count'], [this.table.value.tablebody.length]);
    }
    this.tempTableData = _.cloneDeep(this.options);
    this.IsCheckAll = false;
    if (typeof this.options == "undefined") { this.isDataLoading = true; return; }
    this.isDataLoading = false;
    this.orderTypearr = [];
    this.bufferCount = this.options[0].bufferCount;
    this.defaulSortColumn = this.options[0].Sortable;
    const temp = (this.options[0].Sortable ? this.options[0].Sortable : 'id');
    this.Sortable = (this.selectedDefaultHeader && !this.options[0].isInitial) ? this.selectedDefaultHeader : temp;
    this.isReadOnly = this.options[0].IsReadOnly ? true : false;
    this.IsMoreOptionReadOnly = this.options[0].IsMoreOptionReadOnly ? true : false;
    this.selectedDefaultHeader = this.Sortable;
    this.SelectRow = this.options[0].SelectRows;
    this.DoneCancel = this.options[0].DoneCancel;
    this.toggleDisplayText = this.options[0].toggleDisplayText;
    this.showToggle = this.options[0].showToggle;
    this.blnDraggable = this.options[0].TableDraggable != undefined ? this.options[0].TableDraggable : true;
    this.editable = this.options[0].Editable != undefined ? this.options[0].Editable : true;
    this.waitlistIcon = this.options[0].waitlistIcon != undefined ? this.options[0].waitlistIcon : false;
    this.deletable = this.options[0].deletable != undefined ? this.options[0].deletable : true;
    this.deleteIcon = this.options[0].DeleteIcon != undefined ? this.options[0].DeleteIcon : true;
    this.maxCol = 4;
    this.SelectedSettingId = this.options[0].SelectedSettingId;
    let minarrlength = this.options[0].TableHdrData.length <= this.maxCol ? this.maxCol - this.options[0].TableHdrData.length : 0;
    this.sticky = this.options[0].sticky ? this.options[0].sticky : false;
    this.remailLength = [];
    for (let m = 0; m < minarrlength; m++) {
      this.remailLength.push(m);
    }
    this.hdrArray = this.options[0].TableHdrData;
    this.originalHdrKeyArray = this.options[0].TableHdrData;
    this.SearchKeyArr = this.options[0].TableHdrData.filter(res => {
      return res.searchable != undefined ? res.searchable : true;
    });
    this.bodyArray = [];
    this.bodyArray = this.options[0].TablebodyData;
    this.SelectedData = [];
    //Set Checked items on load - based on the checked field in body data
    this.bodyArray.forEach(element => {
      if (element.checked != null && element.checked)
        this.SelectedData.push(element);
    });
    this.NewerData = this.options[0].NewData;
    this.NewerData = this.bodyArray.filter(o => {
      if (this.NewerData) {
        return ((o.code && o.code == this.NewerData.code) || (o.name && o.name == this.NewerData.name) || (o.addOnName && o.addOnName == this.NewerData.addOnName))
      }
    });
    if (this.NewerData[0]) {
      this.setinterscroll = setInterval(() => {
        const tempItem = (this.NewerData[0] ? this.NewerData[0].id : '');
        let curiddata = this.NewerData ? tempItem : '';
        this.autoscrolltocurrtime(curiddata);
      }, 500);

    }



    this.searchText = (this.options[0].TableSearchText || this.options[0].TableSearchText == '') ? this.options[0].TableSearchText : this.searchText;
    this.hdrkeyArray = [];
    if (this.hdrArray) {
      for (let item of  this.hdrArray) {
        if (item.searchable != undefined ? item.searchable : true)
          this.hdrkeyArray.push(item.jsonkey);

      }
    }

    this.PlaceHoldertext = this.options[0].PlaceHoldertext;
    this.EnableActions = this.options[0].EnableActions;
    this.disableDelete = this.options[0].disableDelete;
    this.EnablePagination = this.options[0].pagination;
    this.InactiveTherapists = this.options[0].InactiveTherapists;
    this.ChkInactiveService = this.options[0].InactiveService;
    this.InactiveRoles = this.options[0].InactiveRoles;
    this.EditMoreOption = this.options[0].EditMoreOption;
    this.SelectOnlyRow = this.options[0].SelectOnlyRow;
    this.customHeader = this.options[0].customHeader;
    this.pageTitle = this.options[0].pageTitle;
    this.customeHeaderOptions = this.options[0].headerOptions;
    this.dropdownOptions = this.options[0].dropdownOptions;
    this.RadiobuttonOption = this.options[0].RadiobuttonOption;
    // Load Only active services
    if (this.ChkInactiveService)
      this.InactiveService({ checked: false });
    this.sortingColoumn = this.hdrkeyArray.indexOf(this.Sortable);
    this.table.controls['IsCheckAll'].setValue(this.bodyArray && this.bodyArray.length > 0 && (_.difference(this.bodyArray, this.SelectedData).length === 0));
    this.IsViewOnly = this.options[0].IsViewOnly;
    this.disableEditButton = this.options[0].disableEditButton;
    this.IsRetailCodeSetup = (this.options[0].ServiceId == 'quicksale' || this.options[0].ServiceId == 'measures' || this.options[0].ServiceId == 'outlets');
    this.IsAccessAllowed = this.options[0].IsAccessAllowed;
    this.isEditOptionRemove = this.options[0].isEditOptionRemove;
    if (this.SelectedSettingId == myGlobals.GridType.waitlist) {
      this.orderType = '';
    }
    this.calwidthwithtimeout();
  }

  waitlist(cnt): void {
    this.openWaitlist.emit(cnt);
  }

  customtablealignment() {
    let dropdown: any = [];
    let groupArray: any = {
      "commission": {
        "checkbox": "1",
        "desc": "20",
        "location": "10",
        "threshold": "10",
        "flatamount": "8",
        "percentage": "8",
        "dummy1": "5",
        "commissionOn": "10",
        "afterDisc": "10"
      },
    };

    if (this.SelectedSettingId == myGlobals.GridType.commission) {
      dropdown = (Object.values(groupArray.commission));
    }
    else if (this.SelectedSettingId == myGlobals.GridType.appointmentsearch) {
      dropdown = (Object.values(groupArray.appointmentsearch));
    }
    let coloumncount = document.getElementsByTagName('th').length;
    let overallgivenwidth = 0;
    let ga;
    if (dropdown) {
      let parentWidth = document.getElementById('SPACommissionSetupCustomTable') && document.getElementById('SPACommissionSetupCustomTable').offsetWidth;
      for (ga = 0; ga < dropdown.length; ga++) {
        if (document.getElementsByTagName('th')[ga]) {
          document.getElementsByTagName('th')[ga].style.width = Object.values(dropdown)[ga] + '%';
        }
        overallgivenwidth += Number(dropdown[ga]);
      }
      if (dropdown && this.bodyArray.length > 0) {
        let tablerow = document.getElementsByTagName('tr');
        for (let i = 1; i <= this.bodyArray.length; i++) {
          if (tablerow[i]) {
            for (let j = 0; j < dropdown.length; j++) {
              if (document.getElementsByTagName('tr')[i].cells) {
                if (document.getElementsByTagName('tr')[i].cells[j].querySelectorAll("#content").length > 0) {
                  document.getElementsByTagName('tr')[i].cells[j].querySelectorAll("#content")[0]['style'].maxWidth = parentWidth * (Number(Object.values(dropdown)[j]) / 100) + 'px';
                }
              }
            }
          }
        }
      }
      for (let remCol = ga; remCol < coloumncount; remCol++) {
        if (document.getElementsByTagName('th')[coloumncount - 2] && document.getElementsByTagName('td')[ga]) {
          document.getElementsByTagName('th')[coloumncount - 2].style.width = (92 - overallgivenwidth) + '%';
          document.getElementsByTagName('td')[coloumncount - 2].style.maxWidth = parentWidth * (Number((92 - overallgivenwidth)) / 100) + 'px';
          if (this.EditMoreOption) {
            document.getElementsByTagName('th')[coloumncount - 1].style.width = '3%';
            document.getElementsByTagName('td')[coloumncount - 1].style.maxWidth = '100px';
          }
          else {
            document.getElementsByTagName('th')[coloumncount - 1].style.width = '3%';
            document.getElementsByTagName('td')[coloumncount - 1].style.maxWidth = '150px';
          }
        }
      }
    }
  }
  sliderChange(event: any, rowData?: any) {
    let data = { 'value': event.checked, 'data': rowData }
    this.toggleEvtEmitter.emit(data);
  }

  showInactiveRoles(event, rowData, index) {
    let tablebody = this.table.controls['tablebody'] as UntypedFormArray;
    let tableFormGroup = tablebody.controls[index] as UntypedFormGroup;
    tableFormGroup.controls.activetoggle.setValue(rowData.active);
    this.editRecordsArray.push({ index: index, data: rowData });
    this.activeIndex = index;
    this.hideScroll = true;
    let doneCancelCount = _.reduce(this.table.value.tablebody, (acc, val) => {
      acc = val.donecancel ? (acc + 1) : acc;
      return acc;
    }, 0);
    let doneCancelRecords: any = _.filter(this.table.value.tablebody, (data) => {
      return data.donecancel;
    });

    let editRecordList = document.getElementsByClassName("rowDisabled");
    if ((editRecordList.length > 0 || doneCancelCount > 0) && this.isValidRoleName) {

      let editableRecord = editRecordList.length > 0 ? _.filter(this.bodyArray, data => data.id == editRecordList[0].id) : _.filter(this.bodyArray, data => data.id == doneCancelRecords[0].id);
      let currentRecord = rowData;
      this.openAlertDialog(editableRecord, currentRecord, 'toggle', index, event, doneCancelCount)
    }
    else if (editRecordList.length > 0) {
      document.getElementsByClassName("rowDisabled")[0].classList.remove("highlight");
      document.getElementsByClassName('rowDisabled')[0].classList.remove("rowDisabled");
      this.resetForm();
      this.enableDoneCancel(index, rowData);
    }
    else {
      this.enableDoneCancel(index, rowData);
    }
  }

  resetForm() {
    this.roleSetup.reset();
    this.customHeaderButton = this.localization.captions.setting.Add;
    this.enableToggleButton = false;
  }

  enableDoneCancel(index, rowData) {
    this.table.value.tablebody[index].donecancel = true;
    let data = { 'value': event, 'data': rowData }
    this.inactiveToggleEvtEmitter.emit(data);
    this.isValidRoleName = true;
  }

  Done(rowData, index) {
    this.hideScroll = false;
    this.editRecordsArray = [];
    this.editEvent = {};
    let currentData = this.options[0].TablebodyData[index];
    if (currentData.hasOwnProperty('isActive'))
      currentData.isActive = this.table.value.tablebody[index].activetoggle;
    else
      currentData.active = this.table.value.tablebody[index].activetoggle;
    this.table.value.tablebody[index].donecancel = false;
    this.doneEvtEmitter.emit(rowData);
    this.resetForm();
  }

  lblclick(rowData, index, clickable) {
    if (clickable == 'clickable') {
      this.LBLClickEvtEmitter.emit(rowData);
      return;
    } else {
      return false;
    }
  }

  radioClick(rowData, index) {
    this.radioClickEvtEmitter.emit(rowData);
  }

  Cancel(rowData, index) {
    let enableToggle;
    this.hideScroll = false;
    _.forEach(this.tempTableData[0].TablebodyData, (data) => {
      if (data.id == rowData.id) {
        enableToggle = data.hasOwnProperty('isActive') ? data.isActive : data.active;
      }
    });
    if (this.options[0].TablebodyData[index].hasOwnProperty('isActive')) {
      this.options[0].TablebodyData[index].isActive = enableToggle;
    }
    else {
      this.options[0].TablebodyData[index].active = enableToggle;
    }
    this.table.value.tablebody[index].activetoggle = enableToggle;
    this.table.value.tablebody[index].donecancel = false;
  }

  onButtonClick(event, type, belonTo) {
    this.editEvent = {};
    this.editRecordsArray = [];
    let data = {}
    if (belonTo == "roleSetup") {
      data = { 'value': this.roleSetup, 'type': type }
    } else {
      data = { 'value': '', 'type': type }
    }

    this.addClick.emit(data);
    /*Reset Form values to Default*/
    this.resetForm();
  }

  onButtonCancelClick(event) {
    if (document.getElementsByClassName('rowDisabled').length > 0) {
      document.getElementsByClassName("rowDisabled")[0].classList.remove("highlight");
      document.getElementsByClassName('rowDisabled')[0].classList.remove("rowDisabled");
    }
    this.resetForm();
  }

  RowSelect(event, SelectedRow, Frm) {
    if (Frm == 'All') {
      if (event.checked) {
        this.SelectedData = [];
        SelectedRow = this._filterPipe.transform(this.bodyArray, this.searchText, this.hdrkeyArray);
        for (let item of SelectedRow) {
          this.SelectedData.push(item);
        }
      }
      else {
        this.SelectedData = _.difference(this.SelectedData, this.bodyArray);
      }
    }
    else {
      if (this.SelectedData.indexOf(SelectedRow) == -1) {
        this.SelectedData.push(SelectedRow);
      } else {
        this.SelectedData.splice(this.SelectedData.indexOf(SelectedRow), 1);
        let checkedRow = this.bodyArray.findIndex(item => item.id == SelectedRow.id);
        this.bodyArray[checkedRow].checked = false;
      }
      this.table.controls['IsCheckAll'].setValue(this.bodyArray && this.bodyArray.length > 0 && (_.difference(this.bodyArray, this.SelectedData).length === 0));
    }
    this.RowSelectEmitter.emit(this.SelectedData);
    this.CurrentRowSelectedEmitter.emit({ "event": event, "SelectedRow": SelectedRow, "From": Frm });
  }

  InactiveService(e) {
    if (e[0]) {
      this.bodyArray = [];
      this.bodyArray = this.options[0].TablebodyData;
    } else {
      let tempArr = [];
      this.bodyArray.forEach(row => {
        if (row.isActive)
          tempArr.push(row);
      });
      this.bodyArray = tempArr;
    }
  }

  InactiveTherapist(e) {
    this.InActiveTherapistChkBoxEvt = e[0];
    this.InActiveTherapistEvt.emit(e[0]);
    let Checkbool = e[0];
    this.givenOrderType = this.orderType;
    if (!Checkbool) {
      this.bodyArray = [];
      this.bodyArray = this.options[0].TablebodyData;
    }
  }

  openAlertPopup() {
    return this.dialog.open(AlertMessagePopupComponent, {
      width: '305px',
      height: '300px',
      hasBackdrop: true,
      panelClass: 'small-popup',
      data: {
        headername: this.captions.common.Warning, headerIcon: 'icon-warning-icon', headerMessage: this.captions.common.saveChangesMessage, buttonName: this.captions.common.Yes, noButton: true, noButtonName: this.captions.common.No, type: 'message'
      },
      disableClose: true,
    }); 
  }
  sortingFunc(dh, i, from, overriddenSortColumn, sortColumnDataType) {
    if (this.editRecordsArray.length > 0) {
      let dialogRef = this.openAlertPopup();
      dialogRef.afterClosed().subscribe(result => {
        if (result == 'Yes') {
          this.Cancel(this.editRecordsArray[0].data, this.editRecordsArray[0].index);
          this.editRecordsArray = [];
          this.sortEmitter.emit({
            header: dh,
            overriddenSortColumn,
            sortColumnDataType
          });
        }
      });
    }
    else {
      this.sortEmitter.emit({
        header: dh,
        overriddenSortColumn,
        sortColumnDataType
      });
    }
  }

  sortHeader(dh, overriddenSortColumn, sortColumnDataType, i, from) {
    this.selectedDefaultHeader = dh;
    this.overriddenSortColumn = overriddenSortColumn;
    this.sortColumnDataType = sortColumnDataType;
    if (!this.options[0].isInitial && this.defaulSortColumn == this.selectedDefaultHeader && from == "change") {
      if (this.orderTypearr.length > 0) {
        this.orderTypearr.splice(this.orderTypearr.indexOf(dh), 1);
      }
      else {
        this.orderTypearr.push(dh);
      }
    }
    else {
      if (this.givenOrderType == 'desc') {
        this.orderTypearr = [];
        this.orderTypearr.push(dh);
        this.givenOrderType = '';
      } else {
        this.givenOrderType = '';
      }
      if (this.orderTypearr.indexOf(dh) == -1) {
        this.orderTypearr = [];
        this.orderTypearr.push(dh);
        this.orderType = 'asc';
      } else {
        this.orderTypearr.splice(this.orderTypearr.indexOf(dh), 1);
        this.orderType = 'desc';
      }
    }
  }

  sortArrowFunction(i, eventType) {
    let element = document.getElementById("sortArrow" + i);
    if (element)
      eventType == 'remove' ? element.classList.remove("IC6") : element.classList.add("IC6");
    let headerElement = document.getElementById("header" + i);
    if (headerElement)
      eventType == 'remove' ? headerElement.classList.remove("IC6") : headerElement.classList.add("IC6");
  }

  DeleteRecords(e) {
    this.userAlerts.showPrompt(myGlobals.PromptType.Delete, this.PopupCallback.bind(this), e)
  }

  PopupCallback(result: string, extraParams?: any) {
    if (result.toLowerCase() == "ok") {
      this.deleteEvt.emit([extraParams, this.options[0].ServiceId, this.InActiveTherapistChkBoxEvt]);
      this.givenOrderType = this.orderType;
    }
  }

  autoscrolltocurrtime(curid) {
    if (document.getElementsByClassName('highlight').length > 0) {
      let curRow = curid.toString();
      let elms = document.getElementById(curRow);
      if (elms) {

        let scrolltoPos = (document.getElementById(curRow).offsetTop);
        let thPos = (document.getElementById('SPAHeaderRow').offsetHeight);
        document.getElementById("SPACommissionSetupCustomTable").scrollTop = scrolltoPos - thPos;
        clearInterval(this.setinterscroll);
      }
    }
  }

  changeUserAction(event, e, belonTo, index?) {
    this.userActionEvt.emit([e]);
  }

  openAlertDialog = (editableRecord, currentRecord, eventType, index, event, cancelCount) => {
    let dialogRef = this.openAlertPopup();
    dialogRef.afterClosed().pipe(takeUntil(this.$destroyed)).subscribe(result => {
      if (result == 'Yes') {
        if (document.getElementsByClassName("rowDisabled").length > 0) {
          document.getElementsByClassName("rowDisabled")[0].classList.remove("highlight");
          document.getElementsByClassName("rowDisabled")[0].classList.remove("rowDisabled");
        }
        if (cancelCount > 0) {
          let recordArray = this.editRecordsArray;
          recordArray.length == 1 ? this.Cancel(recordArray[0].data, recordArray[0].index) : this.Cancel(recordArray[recordArray.length - 2].data, recordArray[recordArray.length - 2].index)
        }
        if (eventType == 'toggle') {
          this.table.value.tablebody[index].donecancel = true;
          _.forEach(this.table.value.tablebody, (res, i) => {
            if (res.donecancel && res.id != this.table.value.tablebody[index].id) {
              this.table.value.tablebody[i].donecancel = !this.table.value.tablebody[i].donecancel;
            }
          })
          let data = { 'value': event, 'data': currentRecord }
          this.inactiveToggleEvtEmitter.emit(data);
          this.resetForm();
        }
        else {
          this.editEvent = { id: currentRecord.id, rowDisabled: true };
          _.forEach(this.table.value.tablebody, (data, i) => {
            if (data.donecancel) {
              data.donecancel = !data.donecancel;
            }
          });
          this.editRow(event, currentRecord, 'edit', this.options[0].ServiceId)
        }
      }
      else {
        if(this.editRecordsArray.length > 0) this.editRecordsArray.pop();
        if (eventType == 'toggle') {
          this.Cancel(currentRecord, index)
        }
      }
    })
  }

  editRow(event, e, type, belonTo, index?) {
    /*Updated Edit for Retail*/
    let doneCancelCount = _.reduce(this.table.value.tablebody, (acc, val) => {
      acc = val.donecancel ? (acc + 1) : acc;
      return acc;
    }, 0);
    let doneCancelRecords: any = _.filter(this.table.value.tablebody, (data) => {
      return data.donecancel
    });
    if (doneCancelCount > 0 && this.editRecordsArray.length > 0) {
      let lastRecord = this.editRecordsArray.pop();
      this.editRecordsArray = [];
      this.editRecordsArray.push(lastRecord);
    }
    else {
      this.editRecordsArray = [];
    }
    let editRecordList = document.getElementsByClassName("rowDisabled");
    if ((editRecordList.length > 0 || doneCancelCount > 0) && this.isValidRoleName) {
      let editableRecord = editRecordList.length > 0 ? _.filter(this.options[0].TablebodyData, data => data.id == editRecordList[0].id) : _.filter(this.options[0].TablebodyData, data => data.id == doneCancelRecords[0].id);

      let currentRecord = e;
      this.openAlertDialog(editableRecord, currentRecord, 'edit', index, event, doneCancelCount);
    } else {
      if (this.customHeader) {
        this.customHeaderButton = this.localization.captions.setting.UPDATE;
        this.enableToggleButton = true;
        if (belonTo == 'roleSetup') {
          this.roleSetup.controls['roleName'].setValue(e.description);
          this.roleSetup.controls['activetoggle'].setValue(e.active)
          this.isValidRoleName = false;
          if (typeof event == 'object' && event.target.parentElement.parentElement.classList.contains("rowDisabled")) {
            this.roleSetup.controls['roleName'].setValue("");
            this.roleSetup.controls['activetoggle'].setValue(false);
            this.customHeaderButton = this.localization.captions.setting.Add;
          }
        }
        if (typeof event == 'object') {
          event.target.parentElement.parentElement.classList.toggle("rowDisabled");
          if (document.getElementsByClassName("rowDisabled").length > 1) {
            let classList = document.getElementsByClassName("rowDisabled");
            for (let i = 0; i < classList.length; i++) {
              if (classList[i].id != e.id) {
                document.getElementsByClassName("rowDisabled")[i].classList.remove("highlight");
                document.getElementsByClassName("rowDisabled")[i].classList.remove("rowDisabled");
              }
            }
          }
        }
        this.EditRecords(e, type, index);
      }
      else {
        if (document.getElementsByClassName("highlight").length > 0) {
          document.getElementsByClassName("highlight")[0].classList.remove("highlight");
        }
        event.target.parentElement.parentElement.classList.add("highlight");
        this.EditRecords(e, type, index);
      }
    }
  }

  EditRecords(e, type, index?) {
    if (this.editEvt)
      if (this.SelectedSettingId == myGlobals.GridType.commission) {
        this.editEvt.emit([e, type, index]);
      } else {
        this.editEvt.emit([e, this.options[0].ServiceId, type]);
        this.givenOrderType = this.orderType;
      }
  }

  PrintRecord(e) {
    if (this.printEvt) {
      this.printEvt.emit([e]);
    }
  }

  SetColumnValue(bodyCnt: any, jsonkey: string): string {
    jsonkey = jsonkey ? jsonkey : "";
    if (jsonkey.includes("+")) {
      let result = "";
      let arrKey = jsonkey.split("+");
      for (let i = 0; i < arrKey.length; i++) {
        let val = bodyCnt[arrKey[i]];
        val = val ? val : "";
        result = result + (i == 0 ? "" : " ") + val;
      }
      return result;
    }
    return bodyCnt[jsonkey];
  }

  printQtyColor(event) {
    if (event.target.value > 0) {
      event.target.style.fontFamily = "LatoWeb";
    }
  }

  calwidthwithtimeout() {
    setTimeout(() => {
      this.calculateWidth();
      this.customtablealignment();
      if (this.scrollPositionReset) this.scrollvalue.scrollToIndex(-1);
    }, 1);
  }

  public clearSearchText(): void {
    this.searchText = "";
  }

  trackByFn(index) {
    return index;
  }

  checkBool(IfBoolean: any): boolean {
    let ReturnBool: boolean;
    if (typeof IfBoolean === "boolean") {
      ReturnBool = true;
    } else {
      ReturnBool = false;
    }
    return ReturnBool;
  }

  checkInnerBool(paramsinner: any): boolean {
    let ReturnBool: boolean;
    if (typeof paramsinner === "object") {
      ReturnBool = true;
    } else {
      ReturnBool = false;
    }
    return ReturnBool;
  }
}

export interface listOrder {
  listOrder: number;
}


