import { Formatter } from 'src/app/eatecui/source/shared/models/formater.interface';
import { CommonService } from 'src/app/eatecui/source/shared/services/common.service';

export const CurrencyFormater: Formatter = (gridData: any, globalConfiguration?: any, setupConfiguration?: any, ResponseList?: Array<any>): any => {
    try {
        const INICurrencyValue = sessionStorage.getItem('INICurrencyValue');
        const visibleNumberCurrencyLength = sessionStorage.getItem('appsetting');
        const VisibleDigit = INICurrencyValue ? parseInt(INICurrencyValue, 10) :
        visibleNumberCurrencyLength ? JSON.parse(sessionStorage.getItem('appsetting')).VisibleNumberCurrencyLength : 3;
        const showCurrencySymbol = ['POItemTotalInFC', 'PoTotalInFC', 'MiscTotalInFC'];
        if (showCurrencySymbol.findIndex(x => x.toLowerCase() === gridData.colDef.field) !== -1) {
            if (!isNaN(parseFloat(gridData.value))) {
                const BaseCurrencyId = parseInt(sessionStorage.getItem('BaseCurrency'), 10);
                const CurrencyId = gridData.data.purchaseorderheadtranscurrency ? gridData.data.purchaseorderheadtranscurrency?.Id : BaseCurrencyId;
                const CurrencyCode = CommonService.getCurrencyCode(CurrencyId);
                const RawValue = new Intl.NumberFormat('en-US', {
                    style: 'currency',
                    currency: CurrencyCode,
                    minimumFractionDigits: VisibleDigit,
                    maximumFractionDigits: VisibleDigit,
                    minimumIntegerDigits: 1
                }).format(gridData.value);
                const returnValue = CommonService.SetCurrencySymbolInUtility(CurrencyCode, RawValue);
                return returnValue;
            } else {
                return gridData.value;
            }
        } else {
            if ((gridData.value && !isNaN(parseFloat(gridData.value))) || gridData.value === 0) {
                return parseFloat(gridData.value.toString()).toFixed(VisibleDigit);
            } else {
                return gridData.value;
            }
        }
    } catch (error) {
        console.log('error occured CustomValidation', error);
    }
}