import { Injectable } from "@angular/core";
import { HttpMethod, HttpServiceCall } from "./http-call.service";
import * as GlobalConst from 'src/app/shared/globalsContant';

@Injectable(
    { providedIn: "root" }
)
export class SpaGuestTypeBusiness {
    constructor(public http: HttpServiceCall) {
    }

    async getAllGuestTypes(includeInactive) {
        const results = await this.makeHttpCall("GetAllGuestType", null, HttpMethod.Get, { "isIncludeInactive": includeInactive });
        return results.result;
    }

    async createGuestType(guestType) {
        const results = await this.makeHttpCall("CreateGuestType", guestType, HttpMethod.Post, null);
        return results.result;
    }

    async updateGuestType(guestType) {
        const results = await this.makeHttpCall("UpdateGuestType", guestType, HttpMethod.Patch, null);
        return results.result;
    }

    async deleteGuestType(id) {
        const results = await this.makeHttpCall("DeleteGuestType", null, HttpMethod.Delete, { "id": id });
        return results.result;
    }

    async dragDropGuestType(fromOrder, toOrder) {
        const results = await this.makeHttpCall("GuestTypeDragDrop", null, HttpMethod.Patch, { "fromListOrder": fromOrder, "toListOrder": toOrder });
        return results.result;
    }

    async makeHttpCall(callDesc, body, method: HttpMethod, uriParams) {
        return await this.http.CallApiAsync<any[]>({
            host: GlobalConst.Host.spaManagement,
            callDesc: callDesc,
            method: method,
            body: body,
            uriParams: uriParams,
            showError: true
        });
    }

}