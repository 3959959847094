import { Component, Inject, OnInit, ViewEncapsulation } from '@angular/core';
import { PayeeInfo, PaymentMethods } from '../../shared/business/shared.modals';
import { PaymentMethod } from '../../shared/service/payment/payment-business.model';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ButtonValue } from '../../retail.modals';
import { RetailLocalization } from '../../common/localization/retail-localization';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';

@Component({
  selector: 'app-deposit-pms-post',
  templateUrl: './deposit-pms-post.component.html',
  styleUrls: ['./deposit-pms-post.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class DepositPmsPostComponent implements OnInit {
  isDefaultRoomLookUpByName: boolean = false;
  form: UntypedFormGroup;
  payeeInfo: PayeeInfo;
  proceedButton: ButtonValue;
  cancelButton: ButtonValue;
  profitCenter: string = '';
  roomChargePostingPayMethodId: number = 0;
  selectedPaymentMethod;
  selectRoomFromResult;
  AmountEntered: number;
  SelectedRoomNo: string = '';
  IsResortFinanceMakePaymentFlow: boolean = false;
  lookUpByData;
  groupCharge;
  roomCharge;
  pmsBookingId;
  pmsInfo;
  selectedpayment: PaymentMethod = {
    id: 0,
    paymentTypeId: 0,
    paymentMethodId: 0,
    postTypeId: 0,
  };
  captions;
  constructor(public dialogRef: MatDialogRef<DepositPmsPostComponent>, @Inject(MAT_DIALOG_DATA) public data: any, public localization: RetailLocalization
    , private fb: UntypedFormBuilder) { 
    this.captions = this.localization.captions;
  }

  ngOnInit(): void {
    this.form = this.fb.group({
      lookupBy:PaymentMethods.GroupCharge
    });
    this.proceedButton = {
      label: this.captions.btn_proceed,
      type: "primary",
      disabledproperty: true
    };
    this.cancelButton = {
      label: this.captions.cancel,
      type: "secondary",
    };
    this.roomCharge = this.data.roomCharge;
    this.groupCharge = this.data.groupCharge;
    this.isDefaultRoomLookUpByName = false;
    this.payeeInfo = this.data.payeeInfo;
    this.profitCenter = this.data.profitCenter;
    this.roomChargePostingPayMethodId = this.data.roomChargePostingPayMethodId;
    this.selectedPaymentMethod = this.data.selectedPaymentMethod;
    this.selectRoomFromResult = this.data.selectRoomFromResult;
    this.AmountEntered = this.data.AmountEntered;
    this.SelectedRoomNo = this.data.SelectedRoomNo;
    this.IsResortFinanceMakePaymentFlow = this.data.IsResortFinanceMakePaymentFlow;
    this.selectedpayment = this.data.selectedpayment;
    this.pmsBookingId = this.data?.payeeInfo?.pmsBookingId
    this.lookUpByData = this.getLookupBy();
  }
  public getLookupBy() {
    let lookUpRes= [
      {
        id: PaymentMethods.GroupCharge,
        viewValue: this.groupCharge.paymentMethod
      }];
    if (this.roomCharge) {
      lookUpRes.push({
        id: PaymentMethods.RoomCharge,
        viewValue: this.roomCharge.paymentMethod
      });
    }
    return lookUpRes;
  }
  onlookupTypeChange(event)
  {
    if (event && event.value == PaymentMethods.RoomCharge)
    {
      this.selectedPaymentMethod = PaymentMethods.RoomCharge;
      this.selectedpayment = this.roomCharge;
    } else {
      this.selectedPaymentMethod = PaymentMethods.GroupCharge;
      this.selectedpayment = this.groupCharge;
    }

  }
  onNoClick()
  {
    this.dialogRef.close();
  }
  onAction() {
    this.dialogRef.close(this.pmsInfo); 
  }
  onCancel()
  {
    this.dialogRef.close();
  }
  SelectGuestRoom(event)
  {
    if (event && event.handle) {
      this.pmsInfo = event;
      this.proceedButton.disabledproperty = false;
      
    } else {
      this.proceedButton.disabledproperty = true;
    }
    this.proceedButton = { ...this.proceedButton };
  }
 
}
