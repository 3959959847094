import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AlertAction, AlertType, ButtonType } from 'src/app/common/enums/shared-enums';
import { Localization } from 'src/app/common/localization/localization';
import { CommonUtilities } from '../../utilities/common-utilities';
import { Location } from '@angular/common'

@Component({
  selector: 'app-view-email-logs',
  templateUrl: './view-email-logs.component.html',
  styleUrls: ['./view-email-logs.component.scss']
})
export class ViewEmailLogsComponent implements OnInit {
  
  captions: any;
  mailLogData: any;
  isViewOnly: boolean = false;
  @Output() deleteLogEmit = new EventEmitter();

  @Input('isViewOnly')
  set setViewOnly(value){
    if(value){
      this.isViewOnly = value;
    }
  }
  @Input('mailLogData')
  set setMailLogData(value){
    if(value){
      this.mailLogData = value;
    }
  }
  constructor(private localization: Localization,private utils: CommonUtilities, private location: Location) {
    this.captions = this.localization.captions;
   }

  ngOnInit(): void {
  }

  deleteLog(event){
    this.utils.showAlert(this.captions.warn_delete_emailLog, AlertType.Warning, ButtonType.YesNo, async res => {
      if(res === AlertAction.YES){
        this.deleteLogEmit.emit(event);
      }
    })

  }

  downloadFile(attachment,index){
    this.downloadLogicFunction(attachment,index)
  }

  downloadAllFile(attachment){
    
    for(var attachIndex = 0;attachIndex <attachment.length;attachIndex++){
      this.downloadLogicFunction(attachment,attachIndex)
    }
  }
    downloadLogicFunction(file,index){
      const base64Data = atob(file[index].contentBytes); // Example base64 data
      const byteCharacters = base64Data; // Decode base64 to binary
      const byteArray = new Uint8Array(byteCharacters.length);
      for (let i = 0; i < byteCharacters.length; i++) {
          byteArray[i] = byteCharacters.charCodeAt(i);
      }
      const blob = new Blob([byteArray]);

      // Create a temporary object URL for the Blob
      const url = window.URL.createObjectURL(blob);
    
      // Create an anchor element to trigger the download
      const anchor = document.createElement('a');
      anchor.href = url;
      anchor.download = file[index].name;  // Set the filename for the download
    
      // Trigger the download
      anchor.click();
    
      // Clean up: revoke the object URL after download
      window.URL.revokeObjectURL(url);
    }
    


}
