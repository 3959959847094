import { Injectable } from '@angular/core';
import { Localization } from 'src/app/common/shared/localization/Localization';
import { API, UI } from './retail-tier-level.model';
import { TierLevelDataService } from './retail-tier-level.data.service';
import { of } from 'rxjs';
import { HttpMethod, HttpServiceCall } from '../../shared/service/http-call.service';
import { BaseResponse } from '../../retail.modals';
import { Host } from '../../shared/globalsContant';
import { RetailUtilities } from '../../shared/utilities/retail-utilities';

@Injectable()
export class TierLevelBusiness {
  captions: any;
  discountTypes: any[] = [];

  constructor(
    private _localization: Localization,
    private _TierLevelDataService: TierLevelDataService,
    private http: HttpServiceCall,
    private utils: RetailUtilities
  ) {
    this.captions = this._localization.captions;
  }

  public async getAllTierLevel(): Promise<UI.TierLevel[]> {
    let apiModels: API.TierLevel[] = await this._TierLevelDataService.getAllTierLevel();
    return apiModels.map(x => this.uiMapper(x));
  }

  public async createTierLevel(TierLevel: UI.TierLevel): Promise<UI.TierLevel[]> {
    let apiModels: API.TierLevel[] = await this._TierLevelDataService.createTierLevel(this.APIMapper(TierLevel));
    return apiModels.map(x => this.uiMapper(x));
  }

  public async updateTierLevel(TierLevel: UI.TierLevel): Promise<UI.TierLevel[]> {
    let apiModels: API.TierLevel[] = await this._TierLevelDataService.updateTierLevel(this.APIMapper(TierLevel));
    return apiModels.map(x => this.uiMapper(x));
  }

  public async DeleteValidation(id: number): Promise<boolean> {
   
    return true;
  }

  public async deleteTierLevel(id: number): Promise<UI.TierLevel[]> {
    let apiModels: API.TierLevel[] = await this._TierLevelDataService.deleteTierLevel(id);
    return apiModels.map(x => this.uiMapper(x));
  }

  public async getActiveTierLevel(_includeInactive: boolean) {
    let apiModels: API.TierLevel[] = await this._TierLevelDataService.getAllTierLevel();
    let result = !apiModels ? [] : apiModels.map(x => { return { id: x.id, viewValue: x.code } });
    return of(result);
  }
  private uiMapper(TierLevelAPIModel: API.TierLevel): UI.TierLevel {
    return {
      id: TierLevelAPIModel.id,
      code: TierLevelAPIModel.code,
      name: TierLevelAPIModel.name,
      description: TierLevelAPIModel.description,
      discountTypeId: TierLevelAPIModel.discountTypeId,
      discountTypeName: this.discountTypes.find(d => d.id == TierLevelAPIModel.discountTypeId)?.name ?? ''
    } as UI.TierLevel;
  }


  private APIMapper(TierLevel: UI.TierLevel): API.TierLevel {
    return {
      id: TierLevel.id,
      code: TierLevel.code,
      name: TierLevel.name,
      description: TierLevel.description,
      discountTypeId: TierLevel.discountTypeId,
    } as API.TierLevel;
  }

}
