import { Component, OnInit, EventEmitter, Output, ViewContainerRef } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, UntypedFormControl } from '@angular/forms';
import { ReportDataService } from 'src/app/retail/retail-reports/data/report-data.services';
import { ReportBusinessService } from 'src/app/retail/retail-reports/business/report-business.service';

import { DropDownData } from 'src/app/retail/retail-reports/business/report.modals';
import { RetailLocalization } from 'src/app/retail/common/localization/retail-localization';
import { RetailPropertyInformation } from 'src/app/retail/common/services/retail-property-information.service';
import { RetailReportControl } from 'src/app/retail/retail-reports/basereport/base-report.component';

@Component({
  selector: 'app-retail-transaction-detail',
  templateUrl: './transaction-detail.component.html',
  styleUrls: ['./transaction-detail.component.scss']
})
export class RetailTransactionDetailComponent implements OnInit {
  @Output() formReady = new EventEmitter<UntypedFormGroup>();
  TransactionDetailFormGrp: UntypedFormGroup;
  public captions: any = this.localization.captions.reports;
  VoidedTransactionLabelText: string = '';
  IncludeReceiptCommentsText: string = '';
  paymentMethods: DropDownData[] = [];
  machineName: DropDownData[] = [];
  defaultData: DropDownData = {
    id: 0,
    code: 'ALL',
    description: 'All',
    showInDropDown: true
  };
  validSelection: boolean = true;
  validSelectionErrMsg: string;
  useRetailInterface: boolean = false;
  showMachineDropDown: boolean = false; 
  editPatchValue : any;
  constructor(private fb: UntypedFormBuilder, public business: ReportBusinessService, private dataService: ReportDataService, public localization: RetailLocalization,
    private propertyInfo: RetailPropertyInformation,private container: ViewContainerRef) {
    this.VoidedTransactionLabelText = this.captions.IncludeVoidedTransaction;
    this.IncludeReceiptCommentsText = this.captions.IncludeReceiptComments;
    this.useRetailInterface = this.propertyInfo.UseRetailInterface;
  }

  async ngOnInit() {
    this.showMachineDropDown = this.propertyInfo.GetEnablemachineTransaction() == 'true' ? true : false;    
    this.TransactionDetailFormGrp = this.fb.group({
      paymentMethods: new UntypedFormControl([]),
      machineName: new UntypedFormControl([this.defaultData]),
      IncludeVoidedTransaction: false,
      IncludeReceiptComments: false
    });
    this.formReady.emit(this.TransactionDetailFormGrp);
    if (this.container) {
      const _parentInjector = this.container.injector;
      const _parent: RetailReportControl = _parentInjector.get<RetailReportControl>(RetailReportControl);
      this.editPatchValue = _parent.editableValue;
      if(!_parent.edit)
      {
        await this.onLoad();
      }
      if (this.localization.isFromJobScheduler && _parent.edit) {  
        let patchItem =  this.editPatchValue;
        
        if(patchItem.PaymentMethodIds != undefined){
          this.dataService.GetPaymentMethodByProduct().then(res => {
              let arr = res.filter((item) => {
                return this.editPatchValue.PaymentMethodIds.find(x => x == item.id);
            });
            this.paymentMethods = arr;     
          });
        } 
        if(patchItem.MachineNameId != undefined){
          this.dataService.GetMachineName().then(res => {
              let arr = res.filter((item) => {
                return this.editPatchValue.MachineNameId.find(x => x == item.id);
            });
            this.machineName = arr;     
          });
        } 
        if(patchItem.IncludeVoidedTransaction != undefined){
  this.TransactionDetailFormGrp.controls['IncludeVoidedTransaction'].patchValue(patchItem.IncludeVoidedTransaction);
        } 
        if(patchItem.IncludeReceiptComments != undefined){
  this.TransactionDetailFormGrp.controls['IncludeReceiptComments'].patchValue(patchItem.IncludeReceiptComments);
        } 
        }
    } 
    
  }

  async onLoad() {
    this.paymentMethods = await this.dataService.GetPaymentMethodByProduct();
    this.machineName = await this.dataService.GetMachineName();
    this.business.FilterDataSource["allPaymentMethod"] = this.paymentMethods.filter(u => u.isActive);
    this.business.FilterDataSource["allMachineNames"] = this.machineName;
  }

  IsSelectionValid(validity: boolean) {
    this.validSelection = validity;
    if (!validity) {
      const caption: any = this.localization.captions.reports.PleaseSelectAtleastOneOption;
      this.validSelectionErrMsg = this.localization.replacePlaceholders(caption, ["option"], [this.captions.PaymentMethod]);
    }
  }

  getFormControlValue(event) {
    if (event[0] === 'paymentMethods') {
      this.TransactionDetailFormGrp.setControl('paymentMethods', event[1]);
    }    
    else if (event[0] === 'machineName') {
      this.TransactionDetailFormGrp.setControl('machineName', event[1]);
      }
  }
}
