import { Component, OnInit, ViewEncapsulation  } from '@angular/core';
import { Localization } from "src/app/common/localization/localization";
import { CommonUtilities } from "src/app/common/shared/shared/utilities/common-utilities";
import { UntypedFormBuilder, UntypedFormGroup, UntypedFormArray } from '@angular/forms';
import { ButtonValue, AgFieldConfig } from 'src/app/common/Models/ag-models';
import { takeUntil } from 'rxjs/operators';
import { of, ReplaySubject } from 'rxjs';
import { AlertType } from 'src/app/common/shared/shared/enums/enums';
import { ButtonType, Product } from 'src/app/common/enums/shared-enums';

import { UI } from './common-authorize-integration-setup.model';
import { AuthorizeIntegrationSetupBusiness } from './common-authorize-integration-setup.business';
import { FacadeService } from 'src/app/common/services/facade.service'
import { UserAccessBreakPoints } from '../constants/useraccess.constants';
import { BreakPointAccess } from '../shared/shared/service/breakpoint.service';

@Component({
  selector: 'app-common-authorize-integration-setup',
  templateUrl: './common-authorize-integration-setup.component.html',
  styleUrls: ['./common-authorize-integration-setup.component.scss'],
  providers: [AuthorizeIntegrationSetupBusiness, FacadeService],
  encapsulation: ViewEncapsulation.None
})
export class CommonAuthorizeIntegrationSetupComponent implements OnInit {

  captions: any;
  saveButton: ButtonValue;
  cancelButton: ButtonValue;
  AuthorizeConfig: UntypedFormGroup;
  items: UntypedFormArray;
  data: UI.AuthorizeConfig[] = [];
  id = 0;
  destroyed$: ReplaySubject<boolean> = new ReplaySubject(1);
  removeItems: number[];
  floatLabel: string;
  productId: number;
  hasErrorStatus:boolean[] = [];


  get count(): number {
    return this.items?.length || 0;
  }

  constructor(
    private localization: Localization,
    private fb: UntypedFormBuilder,
    private utilities: CommonUtilities,
    private AuthorizeIntegrationBusiness: AuthorizeIntegrationSetupBusiness,private breakpointAccess: BreakPointAccess) {
    this.captions = this.localization.captions;
    this.floatLabel = this.localization.setFloatLabel;
    this.productId = Number(this.utilities.GetPropertyInfo('ProductId'));
  }

  ngOnInit(): void {
    if(this.productId == Product.GOLF || this.productId == Product.SPA){
      this.breakpointAccess.CheckForAccess([UserAccessBreakPoints.AUTHORIZE_CONFIGURATION]);
    }
    this.formGenerator();
    this.initializeinputs();
    this.loadAuthorizeConfig().then(() => {
      this.formGenerator();
      this.disableSave();
      this.AuthorizeConfig.valueChanges.pipe(takeUntil(this.destroyed$)).subscribe(val => {
       this.hasError();
        this.saveButton.disabledproperty = !(this.AuthorizeConfig.valid && this.AuthorizeConfig.dirty);
        this.saveButton = { ... this.saveButton };
      });
    });
  }

  formGenerator() {
    if (this.items) {
      this.items.clear();
      this.removeItems = [];
    }

    const f = this.getConfigsAsFormArray();
    this.AuthorizeConfig = this.fb.group({
      items: f
    });

    this.items = f;
  }

 async loadAuthorizeConfig() {
     let config = await this.AuthorizeIntegrationBusiness.GetAuthorizeConfig();
     const data = config.filter(x => x.isSetupScreen == false);
      if (data && data !== null  && data.length > 0) {
        data.forEach(x => {
          const defaultAuthorizeConfig: UI.AuthorizeConfig = {
           configurationKey: x.configurationKey,
            configurationValue: x.configurationValue,
            id: x.id
          };
         this.data.push(defaultAuthorizeConfig);
        });
      }

     
  }

  ngOnDestroy() {
    if (this.destroyed$) {
      this.destroyed$.next(true);
      this.destroyed$.complete();
    }
  }


  private getConfigsAsFormArray() {

    if (this.data.length > 0) {
      return this.fb.array([
        ...this.getConfigItems()
      ]);
    } else {
      return this.fb.array([
        this.getConfigItem()
      ]);
    }
  }

  private getConfigItem() {
    const next = this.count + 1;
    return this.fb.group({
      ConfigValue: '',
      ConfigKey: '',
      id: 0
    });
  }

  private getConfigItems() {
    const item = [];
    let i = 1;
    for (const f of this.data) {
      item.push(this.fb.group({
        ConfigValue: f.configurationValue,
        ConfigKey: f.configurationKey,
        id: f.id
        //isDefault: f.isDefault || i === 1
      }));
      i++;
    }
    return item;
  }

  initializeinputs() {
    this.saveButton = {
      type: 'primary',
      label: this.captions.btn_save,
      disabledproperty: false
    };
    this.cancelButton = {
      type: 'tertiary',
      label: this.captions.btn_cancel,
      disabledproperty: false
    };
  }

  removeItem(e, i: number, item: any) {

    this.AuthorizeConfig.markAsDirty();
    this.items.removeAt(i);
    if (item.value.id) {
      this.removeItems.push(item.value.id);
    }
    // for (let k = 0; k < this.count; k++) {
    //   this.items.controls[k]['controls']['ConfigKey'].setValue(k + 1);
    // }

    this.validate();
  }

  private hasError() {
    const ConfigKeys = this.items.controls.map((x: UntypedFormGroup) => x.value.ConfigKey);
    this.items.controls.forEach((control, index) => {
      const ConfigKey = control.value.ConfigKey;
      this.hasErrorStatus[index] = ConfigKeys.indexOf(ConfigKey) !== ConfigKeys.lastIndexOf(ConfigKey);
    });
  }
  validate() {
    const ConfigKeys = this.items.controls.map((x: UntypedFormGroup) => x.controls.ConfigKey.value);
    for (let k = 0; k < this.count; k++) {
      const ConfigKey = this.items.controls[k]['controls']['ConfigKey'].value;
      if (ConfigKeys.indexOf(ConfigKey) !== ConfigKeys.lastIndexOf(ConfigKey)) {
        this.items.controls[k]['controls']['ConfigKey'].setErrors('duplicate');
      } else {
        this.items.controls[k]['controls']['ConfigKey'].setErrors(null);
      }

      this.items.controls[k]['controls']['ConfigKey'].markAsDirty();
      this.items.controls[k]['controls']['ConfigKey'].markAsTouched();
    }
  }

  public onChange(event) {
    this.validate();
  }

  add(e, i) {
    this.AuthorizeConfig.markAsDirty();
    this.items = this.AuthorizeConfig.get('items') as UntypedFormArray;
    const data = this.getConfigItem();
    this.items.push(data);

    this.validate();
  }

  async onSave(e) {

    if (this.AuthorizeConfig) {

      this.disableSave();   
      this.AuthorizeConfig.markAsPristine();   
      await this.AuthorizeIntegrationBusiness.saveDefaultAuthorizeConfig(this.AuthorizeConfig.getRawValue().items, this.removeItems).then(x => {   
        this.utilities.showAlert(this.captions.success_msg, AlertType.Success, ButtonType.Ok,(res =>
        {
            if(res == ButtonType.Ok ){
              
            }

        }));
      }, () => {
       this.enableSave();   
       this.removeItems = [];  
      });
       

     }
  }

  private disableSave() {
    this.saveButton.disabledproperty = true;
    this.saveButton = { ...this.saveButton };
  }

  private enableSave() {
    this.saveButton.disabledproperty = false;
    this.saveButton = { ...this.saveButton };
  }

  onCancel(e) {
    this.formGenerator();
    this.disableSave();
  }

}
