import { BaseReport } from '../common/base-report';
import { ReportAPIOptions, ReportUIConfig, ReportParams, AllReports } from '../report.modals';
import { UntypedFormGroup } from '@angular/forms';
import { ReportDataService } from '../../data/report-data.services';
import { DropDownData } from 'src/app/reports/report.model';
import { AppointmentStatusCodes } from 'src/app/shared/globalsContant';
import { StatusCode } from 'src/app/retail/shared/service/http-call.service';

/**
 *Appointment By User Report
 * @export
 * @class AppointmentByUser
 * @extends {BaseReport}
 */
export class AppointmentByUser extends BaseReport {
	constructor(public reportFormGrp: UntypedFormGroup, public data: ReportDataService,public patcheableValue) {
		super(reportFormGrp);
	}
	statusCount: number;
	generateReportAPIOptions(): ReportAPIOptions {
		return {
			code: AllReports.AppointmentByUser,
			params: this.formReportParams(),
			URIParams: this.formURIParams(),
			filters: this.formFilters(),
			pageBreak: true
		};
	}

	getReportUIConfig(): ReportUIConfig {
		if (this.commonLocalization.isFromJobScheduler) {
            //this.patchReportValues(this.patcheableValue);
            this.MappatchFormValues(this.patcheableValue);
        }
		return {
			startDatePicker: true,
			endDatePicker: true,
			dropDownFilters: this.data.GetUsersByPropertyId(false),
			dropDownStatusFilterData: this.getStatusCodeDropDown(),
			dropDownFilterName: this.captions.Users,
			dropDownStatusFilter: this.captions.Status,
			inActiveToggle: true,
			inActiveToggleName: this.captions.IncludeInactiveUsers,
			excludeGratuity: true,
			excludeServiceCharge:true,
			pageBreakToggle: true,
			layout: 'LANDSCAPE',
			statusToggle: true
		};
	}

	private getStatusCodeDropDown():DropDownData[]
	{
		let statusCodeCaptions = [];
		let id=1;
		for (let key of Object.keys(AppointmentStatusCodes)) {
			statusCodeCaptions.push({
				id: id++, 
				code: AppointmentStatusCodes[key],
				showInDropDown: true,
				description: this.captions[key]
			} as DropDownData);
		}
		this.statusCount=id-1;
		return statusCodeCaptions;
	}
	protected formReportParams(): ReportParams[] {
		let toApi = this.localization.convertDateObjToAPIdate;
		let isByAppointmentDate = this.GetReportType();
		return [
			{ pStartDate: toApi(this.startDate) },
			{ pEndDate: toApi(this.endDate) },
			{ pPageBreak: this.pageBreakToggle },
			{ pPropertyName: this.propertyName },
			{ pFilterCaption: this.getFilterCaption },
			{ pExcludeGratuity: this.getExcludeGratuity},
			{ pExcludeServiceCharge: this.getExcludeServiceCharge},
			{ pDate: this.printedDate },
			{ pOffsite: this.localization.captions.reports.Offsite },
			{ pInactiveUser: this.includeInactiveToggle ? this.captions.Yes : this.captions.No },
			{ pIsByAppointmentDate : isByAppointmentDate},
			{ pFilteredBy : isByAppointmentDate ? this.localization.captions.reports.AppointmentDate : this.localization.captions.reports.AppointmentBookedDate}
		];
	}

	protected GetReportType(){
        const id: number = this.reportFormGrp.controls['customFilterFormGrp'].value.radioFilter;
		return id == 0 ? true : false;
    }

	protected formURIParams(): ReportParams[] {
		let toApi = this.localization.convertDateObjToAPIdate;
		return [ { StartDate: toApi(this.startDate) }, { EndDate: toApi(this.endDate) } ];
	}

	protected formFilters(): any {
		let toApi = this.localization.convertDateObjToAPIdate;
		if(this.commonLocalization.isFromJobScheduler){
            const fromWildCard = this.formGrp.get('timelineFrom').value;
            const toWildCard = this.formGrp.get('timelineTo').value;
            const fromIncrement = this.formGrp.get('timelineCountFrom').value;
            const toIncrement = this.formGrp.get('timelineCountTo').value;
            return JSON.stringify({
                fromWildCard,
                toWildCard,
                fromIncrement,
                toIncrement,
                StartDate:(this.formGrp.get('startDate').value),
                EndDate: (this.formGrp.get('endDate').value),
                Data: {UserIds: this.selectedFilterOptions.filter(x => x.showInDropDown).map((s) => s.id)},
				StatusCode: {StatusCodes: this.selectedStatusFilter}
            });
            //return obj;
        }
		let userId = this.selectedFilterOptions.filter(x => x.showInDropDown).map((s) => s.id);
		let statusCodesFilter = this.selectedStatusFilter;
		let isAllStatusSelected= (statusCodesFilter.length==this.statusCount);
		return {
			 userIds: userId ,  isByAppointmentDate: this.GetReportType(), statusCodes: statusCodesFilter, excludeGratuity: this.getExcludeGratuity, excludeServiceCharge: this.getExcludeServiceCharge, isAllStatusSelected: isAllStatusSelected
		}
	}

	patchReportValues(patchItem){
        setTimeout(()=>{
            this.formGrp.patchValue(patchItem);
            this.commonLocalization.scheduleJobWildCards[1].id
            this.formGrp.controls.timelineFrom.patchValue(this.commonLocalization.scheduleJobWildCards.find(x=> x.id == patchItem.fromWildCard).id);
            this.formGrp.controls.timelineCountFrom.patchValue(patchItem.fromIncrement);
        });
    }

	MappatchFormValues(patchItem) {
        setTimeout(() => {
            if(patchItem.fromWildCard != undefined)
                this.formGrp.controls.timelineFrom.patchValue(this.commonLocalization.scheduleJobWildCards.find(x => x.id == patchItem.fromWildCard)?.id);

            if(patchItem.toWildCard != undefined)
            this.formGrp.controls.timelineTo.patchValue(this.commonLocalization.scheduleJobWildCards.find(x => x.id == patchItem.toWildCard)?.id);

            if(patchItem.fromIncrement != undefined)
                this.formGrp.controls.timelineCountFrom.patchValue(patchItem.fromIncrement);

            if(patchItem.toIncrement != undefined)
            this.formGrp.controls.timelineCountTo.patchValue(patchItem.toIncrement);

        }, 1);
    }
}
