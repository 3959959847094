import { Component, OnInit, ViewEncapsulation, Inject,  Input } from '@angular/core';
import { RetailLocalization } from '../../common/localization/retail-localization';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { RetailPopupComponent } from '../../retail-popup/retail-popup.component';
import { UntypedFormGroup, UntypedFormBuilder, Validators, UntypedFormArray } from '@angular/forms';
import * as _ from "lodash";
import { ItemInventoryTransfer, ItemInventoryTransferOutlets, BaseResponse } from '../../retail.modals';
import { ButtonType, Host } from '../../shared/globalsContant';
import { HttpMethod, HttpServiceCall } from '../../shared/service/http-call.service';
import { AlertMessagePopupComponent } from '../../shared/alert-message-popup/alert-message-popup.component';
import { AddTransferDataService } from '../add-transfer-inventory-popoup-data.services';
import { RetailService } from '../../retail.service';
import { TransferInventoryUIRequest, InventoryTransferOutletDetails, RetailScreen } from '../../inventory-staging/inventory-staging.modal';
import { RetailRoutes } from '../../retail-route';
import { element } from 'protractor';
import { RetailUtilities } from '../../shared/utilities/retail-utilities';
import { AlertType } from '../../shared/shared.modal';
import { AlertPopupComponent } from '../../shared/alert-popup/alert-popup.component';


@Component({
  selector: 'app-single-to-multiple',
  templateUrl: './single-to-multiple.component.html',
  styleUrls: ['./single-to-multiple.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class SingleToMultipleComponent implements OnInit {
  outlets: { "id": number; "name": string; }[];
  @Input('outlets')
  set outletValue(value){
    if(value) {
      this.outlets = value;
      this.InitializeOutlets();
    }
  }
  screen = RetailScreen
  singletomultipleFG: UntypedFormGroup;
  entiretablerow: any;
  selectedtablerow: any = [];
  Reason: any = [];
  TableHdrData: any;
  searchTxt: any;
  searchFilter: any;
  transferquantity: any;
  checkedTableRowBool:boolean;
  itemsData: string;
  isExcessQuantity: boolean = false;
  allowTransfer: boolean = false;
  missingQty: boolean = false;
  checkedarrcount: number;
  selectedOutlet: any;
  transferItemData: ItemInventoryTransfer[] = [];
  transferItems: ItemInventoryTransferOutlets[] = [];
  fromOutlets: { "id": number; "name": string; }[];
  toOutlets: { "id": number; "name": string; }[];
  message: string = "";
  reasonArr: any;
  multipleOutlet: { 'tooutletdisplay': { 'outlet': { "id": number; "name": string; }[]; 'transferqty': any; }[]; 'thisselectedoutlet': any[]; };
  captions: any = this._Localization.captions;
  status: any = this._Localization.captions.bookAppointment.Status;
  disableChange: boolean = true;
  selectedValueObject: any;
  floatLabel: string;
  from: string;

  get isZeroQuantity(){
    return this.singletomultipleFG.controls.Reason.value.some(x => Number(x.quantity) == 0);
  }

  constructor(public _Localization: RetailLocalization, private ad: AddTransferDataService, private dialog: MatDialog, private dialogRef: MatDialogRef<RetailPopupComponent>,
    private _FormBuilder: UntypedFormBuilder, @Inject(MAT_DIALOG_DATA) public data: any, private http: HttpServiceCall, private retailService: RetailService, private utils: RetailUtilities) { 
      this.floatLabel = this._Localization.setFloatLabel;
      this.from = this.data.from;
    }

  ngOnInit() {
    this.singletomultipleFG = this._FormBuilder.group({
      fromOutlet: [this.data.datarecord.fromOutlet], 
      transferReason: ['', Validators.required], 
      Reason: this._FormBuilder.array([this.createReason('', '', 0, true)]),
    });

    this.InitializeComponent();
  }


  ngOnChange(): void {
    this.InitializeComponent();
  }
  modifiedoutlet: any;
  InitializeComponent(): void {
    this.transferquantity = 0.00;
    this.InitializeOutlets();
    if(this.from == RetailScreen.inventoryStaging){
      this.TableHdrData = [{ "title": this.captions.retailsetup.Item, "jsonkey": "itemNumber", "sortcolumndatatype": "number", "searchable": true },
        { "title": this.captions.retailsetup.ItemDescription, "jsonkey": "itemName", "searchable": true },
        { "title": this.captions.retailsetup.QuantityAvailable, "jsonkey": "quantity", "sortcolumndatatype": "number", "searchable": false },
        { "title": this.captions.retailsetup.Barcode, "jsonkey": "barcode", "sortcolumndatatype": "barcode", "searchable": false },
      ];
      this.data.datarecord.selectedRow = _.orderBy(this.data.datarecord.selectedRow, "itemNumber", 'asc');
      if (this.data.datarecord.selectedRow.length > 0) {
        this.selectedtablerow.push(this.data.datarecord.selectedRow[0]);  
      }
    }
    if (this.from != RetailScreen.inventoryStaging) {
      this.TableHdrData = [{ "title": this.captions.retailsetup.Item, "jsonkey": "itemNumberColumn", "sortcolumndatatype": "number", "searchable": true },
      { "title": this.captions.retailsetup.ItemDescription, "jsonkey": "itemName", "searchable": true },
      { "title": this.captions.retailsetup.Category, "jsonkey": "categoryName", "searchable": true },
      { "title": this.captions.retailsetup.SalesPrice + " (" + this._Localization.currencySymbol + ")", "jsonkey": "salesPrice", "alignType": "right", "sortcolumndatatype": "number", "datatype": "money", "searchable": false },
      { "title": this.captions.retailsetup.QuantityAvailable, "jsonkey": "quantityOnHand", "sortcolumndatatype": "number", "searchable": false },
      { "title": this.captions.retailsetup.ItemPar, "jsonkey": "itemPar", "sortcolumndatatype": "number", "searchable": false },
      { "title": this.captions.retailsetup.Inactive, "jsonkey": "isInActive", "type": "toggle", "displayType": "icon", "sortable": false, "searchable": false },
      { "title": this.captions.retailsetup.UnitCost + " (" + this._Localization.currencySymbol + ")", "jsonkey": "unitCost", "alignType": "right", "sortcolumndatatype": "number", "datatype": "money", "searchable": false },
      ];
      this.data.datarecord.selectedRow = this.data.datarecord.selectedRow.filter(x => x.checkedFlag && x.useInventory)
      this.data.datarecord.selectedRow = _.orderBy(this.data.datarecord.selectedRow, "itemNumberColumn", 'asc')
      this.entiretablerow = this.data.datarecord.entireTabledata.filter(x => x.useInventory === true && !x.isInActive);
      if (this.data.datarecord.selectedRow.length > 0) {
        this.selectedtablerow.push(this.data.datarecord.selectedRow[0]);  
      }
      if (this.ad.singletomultipleFG) {
        this.singletomultipleFG.patchValue(this.ad.singletomultipleFG.value);
        this.BindReason();
      }
    }
    this.checkedTableRowBool = this.selectedtablerow.length > 0;
  }

  InitializeOutlets() {
    this.fromOutlets = _.cloneDeep(this.outlets);
    let modifiedoutlet = this.fromOutlets.filter(x => { return x.id != this.data.datarecord.fromOutlet });
    this.toOutlets = _.cloneDeep(modifiedoutlet);
    if(this.singletomultipleFG)
      this.singletomultipleFG.controls.fromOutlet.setValue(this.data.datarecord.fromOutlet);
    console.log("this.ad.singletomultipleFG", this.ad.singletomultipleFG);
    this.multipleOutlet = { 'tooutletdisplay': [{ 'outlet': this.toOutlets, 'transferqty': 0 }], 'thisselectedoutlet': [''] };
  }

  BindReason() {
    this.reasonArr = [];
    let data = this.ad.singletomultipleFG.value.Reason;
    if (data.length === 0) {
      return;
    }
    for (let i = 0; i < data.length; i++) {
      const element = data[i];
      const reasonObj = {
        id: element.id,
        outlet: [element.outlet, Validators.required],
        quantity: element.quantity,
      };
      this.reasonArr.push(reasonObj);
      this.addReason(i, element.outlet, element.quantity, false);
    }

    this.Reason = this.singletomultipleFG.get('Reason') as UntypedFormArray;
    if (this.Reason.controls.length > 1) {
      this.Reason.removeAt(0);
    }

    this.multipleOutlet = { 'tooutletdisplay': [], 'thisselectedoutlet': [] };
    this.ad.singletomultipleFG.value.Reason.forEach((element, index) => {
      this.multipleOutlet.tooutletdisplay.push({ 'outlet': this.toOutlets, 'transferqty': element.quantity });
      this.multipleOutlet.thisselectedoutlet.push(element.outlet);
      this.Reason = this.singletomultipleFG.get('Reason') as UntypedFormArray;
      !element.outlet && this.Reason.controls[index].controls.quantity.disable();
    });
    this.outletsSelected('', '');
    this.updateoverallqty();


  }
  createReason(_outlet?: any, _quantity?: any, _id?: number, disabledProp?: boolean): UntypedFormGroup {
    return this._FormBuilder.group({
      outlet: [_outlet, Validators.required],
      quantity: [{ value: _quantity, disabled: disabledProp }, Validators.required],
      id: _id
    });
  }

  addReason(_id?: number, _outlet?: any, _quantity?: any, disabledProp?: boolean): void {
    this.Reason = this.singletomultipleFG.get('Reason') as UntypedFormArray;
    this.Reason.push(this.createReason(_outlet, _quantity, ++_id, disabledProp));
  }


  close() {
    this.dialogRef.close();
  }
  filterFucntion($event) {
    let data: string = $event.target && $event.target.value ? $event.target.value : '';
    let newarr = [];
    newarr = this.entiretablerow.filter(x => {
      let selectedID = _.map(this.selectedtablerow, 'id');
      if (!_.includes(selectedID, x.id)) {
        let check1 = _.includes(x.itemNumberColumn.toString().toLowerCase(), data.toLowerCase());
        let check2 = _.includes(x.itemName.toLowerCase(), data.toLowerCase());
        let check3 = _.includes(x.categoryName.toLowerCase(), data.toLowerCase());
        return (check1 || check2 || check3)
      }
    });
    this.searchFilter = newarr;
  }
  SelectedRecord(data) {
    this.selectedValueObject = data.value;
    const searchTxtValue = `${data.value.itemNumberColumn} - ${data.value.itemName}`;
    this.searchTxt = searchTxtValue;
    this.disableChange = false; 
  }
  addItemToTable(){
    this.selectedtablerow = [];
    this.selectedtablerow.push(this.selectedValueObject);
    this.checkedTableRowBool=this.selectedtablerow.length>0;
    this.clearSearch();
    this.updateoverallqty();
  }
 
  transferdata() {
    if(this.from != RetailScreen.inventoryStaging)
      {
    this.CheckExcessQuantityTransfer(this.multipleOutlet.tooutletdisplay);
    this.multipleOutlet.tooutletdisplay.forEach((ele, index) => {
      ele['selectedOutletId'] = this.multipleOutlet.thisselectedoutlet[index];
    });
  }
  else{
    this.FormTransferDataForInventoryStaging()
  }
  }

  
  async FormTransferDataForInventoryStaging() {
    let quantityTotal = 0;
    let outlets: InventoryTransferOutletDetails[] = [];
    let outletQuantity = this.singletomultipleFG.controls.Reason.value;
    outletQuantity.forEach(element => {
      quantityTotal += Number(element.quantity);
      outlets.push({
        outletId: element.outlet,
        quantity: Number(element.quantity)
      })
    })
    if (this.selectedtablerow[0].quantity < quantityTotal) {
      let distinctselectedData = _.uniqBy(this.selectedtablerow, 'itemNumber');
      this.openAlertPopupForInventory(distinctselectedData);
    }
    else{
      let body: TransferInventoryUIRequest[] = [{
        inventoryScannerStagingId: this.selectedtablerow[0].id,
        newQuantity: this.selectedtablerow[0].quantity - quantityTotal,
        oldQuantity: this.selectedtablerow[0].quantity,
        oldOutletId: this.selectedtablerow[0].outletId,
        transferReason: this.singletomultipleFG.value.transferReason,
        outletDetails: outlets
      }];
      let apiResponse: BaseResponse<boolean> = await this.retailService.InvokeServiceCallAsync(RetailRoutes.TransferInventory, Host.retailManagement, HttpMethod.Post, '', body);
      if (apiResponse.result) {
        this.utils.showAlert(this.captions.retailsetup.inventoryTransferSucceeded, AlertType.Success, ButtonType.Ok);
        this.dialogRef.close();
      }
      else {
        this.utils.showAlert(this.captions.retailsetup.inventoryTransferFailed, AlertType.Error, ButtonType.Ok);
      }
    }
  }

  CheckExcessQuantityTransfer(checkedrow: any) {

    for (let row of checkedrow) {
      if (this.selectedtablerow[0].quantityOnHand < row.transferqty) {
        this.itemsData = this.selectedtablerow[0].itemName;
        this.isExcessQuantity = true;
        break;
      }
    }
    if (this.isExcessQuantity) {
      let dialogRef = this.openAlertPopup();
      dialogRef.afterClosed().subscribe(result => {
        if (result.toLowerCase() == this.captions.common.Yes.toLowerCase()) {
          this.allowTransfer = true;
          this.FormData();
        }
        else
        {
          this.transferItemData = [];
          this.transferItems = [];
          this.itemsData="";
          this.message="";
        }
      });
    }
    else {
      this.FormData();
    }
  }

  openAlertPopup() {
    this.message = this._Localization.replacePlaceholders(this.captions.retailsetup.excessQuantityWarning, ["items"], [this.itemsData]);
    return this.dialog.open(AlertMessagePopupComponent, {
      width: '550px',
      height: '300px',
      hasBackdrop: true,
      panelClass: 'small-popup',
      data: {
        headername: this.captions.common.Warning, headerIcon: 'icon-warning-icon', headerMessage: this.message, buttonName: this.captions.common.Yes, noButton: true, noButtonName: this.captions.common.No, type: 'message'
      },
      disableClose: true,
    });
  }

  openAlertPopupForInventory(items) {
    let itemName = items.map(x=>x.itemName);
    this.message = this._Localization.replacePlaceholders(this.captions.retailsetup.excessQuantityWarningInventory, ["items"], itemName);
    return this.utils.showAlert(this.message, AlertType.Warning, ButtonType.Ok)
  }

  async FormData() {
    this.multipleOutlet.tooutletdisplay.forEach((element, index) => {
      this.transferItems = [];
      this.transferItems.push({ fromOutletId: this.singletomultipleFG.value.fromOutlet, toOutletId: this.multipleOutlet.thisselectedoutlet[index], quantity: element.transferqty });
      this.transferItemData.push({ itemId: this.selectedtablerow[0].id, reason: this.singletomultipleFG.value.transferReason, outlets: this.transferItems });
    });

    if (this.transferItemData.length > 0) {
      let body = this.retailService.BuildItemOutletMappingForTransfer(this.transferItemData);
      let apiResponse: BaseResponse<boolean> = await this.retailService.InvokeServiceCallAsync('LinkOutletsWithRetailItem', Host.retailManagement, HttpMethod.Put, '', body);
      if (apiResponse && apiResponse.successStatus) {
        this.CreateTransfer(this.transferItemData);
      }
    }
  }
  updateoverallqty() {
    let total = 0;
    this.transferquantity = 0;
    let Reason: any = [];
    Reason = this.singletomultipleFG.get('Reason') as UntypedFormArray;
    this.multipleOutlet.tooutletdisplay.forEach((ele, i) => {
      ele.transferqty = Reason.controls[i].controls.quantity.value ? parseInt(Reason.controls[i].controls.quantity.value) : 0
      const tempRow = this.selectedtablerow[0].unitCost ? this.selectedtablerow[0].unitCost : 0;
      let transferedCost = (this.selectedtablerow.length > 0) ? tempRow : 0;
      let transferquantityCost = transferedCost * (ele.transferqty ? ele.transferqty : 0);
      total += parseFloat(transferquantityCost.toString());

    });
    this.transferquantity = total;
  }

  outletsSelected(e, i) {
    if (e.value) {
      this.selectedOutlet = e.value;
      let thisSelected = e.value;
      this.multipleOutlet.thisselectedoutlet[i] = thisSelected;
      this.Reason = this.singletomultipleFG.get('Reason') as UntypedFormArray;
      this.Reason.controls[i] && this.Reason.controls[i].controls.quantity.enable();
    }
    this.multipleOutlet.tooutletdisplay.forEach((x, index) => {
      x.outlet = this.toOutlets.filter(y => {
        return y.id == this.multipleOutlet.thisselectedoutlet[index] || this.multipleOutlet.thisselectedoutlet.indexOf(y.id) == -1;
      })

    }) 
  }
  addremove(type, index) {
    if (type == 'A') {
      this.multipleOutlet.tooutletdisplay.push({ 'outlet': this.toOutlets, 'transferqty': 0 });
      this.multipleOutlet.thisselectedoutlet.push('');
      this.addReason(index, '', '', true);
    } else if (type == 'R') {
      this.multipleOutlet.tooutletdisplay.splice(index, 1);
      this.multipleOutlet.thisselectedoutlet.splice(index, 1);
      this.Reason = this.singletomultipleFG.get('Reason') as UntypedFormArray;
      this.Reason.removeAt(index);
    }
    this.outletsSelected('', '');
    this.updateoverallqty();
  }

  CreateTransfer(transferItemData: ItemInventoryTransfer[]) {
    this.http.CallApiWithCallback({
      host: Host.retailManagement,
      success: this.successCallback.bind(this),
      error: this.errorCallback.bind(this),
      callDesc: "TransferInventoryItem",
      method: HttpMethod.Post,
      body: transferItemData,
      showError: true,
      extraParams: []
    });
  }

  successCallback<T>(result: BaseResponse<T>, callDesc: string, extraParams: any[]): void {
    if (callDesc == "TransferInventoryItem" ) {
        const dialogRef = this.OpenProcessCompletePopUp();
        dialogRef.afterClosed().subscribe(data => {
          if (data.toLowerCase() == this.captions.common.OK.toLowerCase()) {
            this.dialogRef.close();
          }
          else {
            this.transferItemData = [];
            this.transferItems = [];
            this.itemsData="";
            this.message="";
          }
        });
        this.dialogRef.close();
    }
  }

  OpenProcessCompletePopUp() {
    return this.dialog.open(AlertMessagePopupComponent, {
      width: '305px',
      height: '300px',
      hasBackdrop: true,
      panelClass: 'small-popup',
      data: { headername: this.captions.retailsetup.processComplete, headerIcon: 'icon-success-icon', buttonName: this.captions.common.OK, type: 'message' },
      disableClose: true
    }); 
  }


  errorCallback<T>(error: BaseResponse<T>, callDesc: string, extraParams: any[]): void {
    if (callDesc == "TransferInventoryItem") {
        this.transferItemData = [];
        this.transferItems = [];
    }
  }

  clearSearch() {
    this.searchTxt = '';
    this.searchFilter = [];
  }
  ngOnDestroy() {
    this.ad.singletomultipleFG = _.cloneDeep(this.singletomultipleFG);
  }
}
