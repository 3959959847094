export class PMSRequestInfo {
  stayFromDate: Date | string | undefined;
  stayToDate: Date | string | undefined;
  gpsId: string;
  activityId: string | undefined;
  action: PMSAction;
  userId?: string;
  tenantCode?: string;
  token?: string;
  propertyCode?: string;
  isEdit?: boolean = false;
  activityDate?: Date | string | undefined;
  pmsBookingId?: string;
}

export enum PMSAction {
  Reservation = 1,
  CancelReservation,
  ReInstateReservation,
  MoveReservation,
  RescheduleReservation,
  DashBoard
}

export interface LoginResponse {
  result: TokenLoginModel;
  successStatus: boolean;
}

export interface TokenLoginModel extends LoginModel, LoginStatus {
  token: string;
  propertycode: string;
  loginDuration: number;
}

export interface LoginStatus {
  loginStatus: boolean;
  errorCode: number | undefined;
  loginErrorMessage: string;
}


export interface LoginModel {
  userProperties: PropertyModel[];
  userLoginInfo: UserLoginModel;
}

export interface PropertyModel {
  subPropertyName: string;
  subPropertyCode: string;
  subPropertyId: number;
  propertyCode: string;
  propertyName: string;
  productId: number;
  propertyId: number;
  languageCode: string;
  minCharSearch: number;
  propDateFormat: string;
  currencyCode: string;
  tenantId: number;
  userId: number;
  roleId: number;
  roleName: string;
  propertyDate: string;
  timeZone: string;
  autoLogOff: boolean;
  logOffAfter: number;
  isActive: boolean;
  platformTenantId: string;
  profitCenter: string;
  platformPropertyId: string;

}

export interface UserLoginModel {
  tenantId: number;
  userId: number;
  userName: string;
  firstName: string;
  lastName: string;
  password: string;
  isNewUser: boolean;
  isPasswordExpired: boolean;
  passwordExpireDate: string;
  languageCode: string;
  productId: number;
  propertyId: number;
}