import { BaseReport } from '../common/base-report';
import { ReportAPIOptions, ReportUIConfig, ReportParams, AllReports } from '../report.modals';
import { UntypedFormGroup } from '@angular/forms';
import { ReportDataService } from '../../data/report-data.services';
import { ReportBusinessService } from '../report-business.service';
import { RetailUtilities } from '../../../shared/utilities/retail-utilities';
import { PaymentMethods } from 'src/app/retail/shared/business/shared.modals';
import { RetailPropertyInformation } from "src/app/retail/common/services/retail-property-information.service";
import { RetailLocalization } from 'src/app/retail/common/localization/retail-localization';
export class TransactionDetail extends BaseReport {
    constructor(
        public reportFormGrp: UntypedFormGroup,
        public data: ReportDataService,
        public business: ReportBusinessService,
        public utils: RetailUtilities,
        private propertyInfo: RetailPropertyInformation,
        public retailLocalization : RetailLocalization
    ) {
        super(reportFormGrp);
    }

    generateReportAPIOptions(): ReportAPIOptions {
        return {
            code: AllReports.TransactionDetail,
            params: this.formReportParams(),
            URIParams: this.formURIParams(),
            filters: this.formFilters(),
            pageBreak: true
        }; 
    }

    getReportUIConfig(): ReportUIConfig {
        return {
            startDatePicker: true,
            endDatePicker: true,
            inActiveToggle: false,
            pageBreakToggle: true,
            layout: 'PORTRAIT',
            allowFutureDate: false
        }; 
    }

    protected formReportParams(): ReportParams[] {
        let toApi = this.localization.convertDateObjToAPIdate,
            shiffReportFilterData: any = this.reportFormGrp.controls['customFilterFormGrp'].value,
            paymentMethodCaption: string = this.business.getFilterCaption(
                this.business.FilterDataSource['allPaymentMethod'].filter((u)=>u.showInDropDown),
                this.fillFilter(shiffReportFilterData.paymentMethods.filter((u) => u.showInDropDown))
            );
            let machineCaption:string = this.business.getFilterCaption(this.business.FilterDataSource["allMachineNames"],shiffReportFilterData.machineName.map(x=>x.id));    
        return [
            { pStartDate: toApi(this.startDate) },
            { pEndDate: toApi(this.endDate) },
            { pPageBreak: this.pageBreakToggle },
            { pIncludeReceiptComments: shiffReportFilterData.IncludeReceiptComments },
            { pPropertyName: this.propertyName },
            { pDate: this.printedDate },
            { pPaymentFilter: paymentMethodCaption },
            {pMachineName: machineCaption},
            {pPrintMachineName: this.propertyInfo.GetEnablemachineTransaction() == 'true' ? true : false}
        ];
    }

    protected formURIParams(): ReportParams[] {
        return [{ StartDate: this.localization.convertDateObjToAPIdate(this.startDate) },
        { EndDate: this.localization.convertDateObjToAPIdate(this.endDate) }];
    }

    protected formFilters() {
        let toApi = this.localization.convertDateObjToAPIdate;
        let shiffReportFilterData: any = this.reportFormGrp.controls['customFilterFormGrp'].value;
        let showMachineDropDown = this.propertyInfo.GetEnablemachineTransaction() == 'true' ? true : false;
        let isAllMachineSelected: boolean = this.business.checkAllSelected(this.business.FilterDataSource["allMachineNames"],shiffReportFilterData.machineName.map(x=>x.id));      

        const tempMachineNammeId = isAllMachineSelected ? [] : shiffReportFilterData.machineName.map(x => x.id);
        if(this.retailLocalization.isFromJobScheduler){
            const fromWildCard = this.reportFormGrp.controls['timelineFrom'].value;
            const toWildCard = this.reportFormGrp.controls['timelineTo'].value;
            const fromIncrement = this.reportFormGrp.controls['timelineCountFrom'].value;
            const toIncrement = this.reportFormGrp.controls['timelineCountTo'].value;
            return {
                StartDate: toApi(this.startDate),
            EndDate: toApi(this.endDate),
            PaymentMethodIds: this.IncludeInternalPaymentMethods(this.fillFilter(shiffReportFilterData.paymentMethods.filter((u) => u.showInDropDown))),
            MachineNameId: showMachineDropDown ? tempMachineNammeId : [],
            IncludeVoidedTransaction: shiffReportFilterData.IncludeVoidedTransaction,
                fromWildCard,
                toWildCard,
                fromIncrement,
                toIncrement 

            };
        }
        return {
            StartDate: toApi(this.startDate),
            EndDate: toApi(this.endDate),
            PaymentMethodIds: this.IncludeInternalPaymentMethods(this.fillFilter(shiffReportFilterData.paymentMethods.filter((u) => u.showInDropDown))),
            MachineNameId: showMachineDropDown ? tempMachineNammeId : [],
            IncludeVoidedTransaction: shiffReportFilterData.IncludeVoidedTransaction
        }; 
    }
    fillFilter(customFilterData: any): number[] {
        return customFilterData.length === 0 ? [] : customFilterData.map((x) => x.id);
    }

    IncludeInternalPaymentMethods(arr: number[]): number[] {
        if (arr && Array.isArray(arr) && arr.length) {
            if (arr.includes(PaymentMethods.CreditCard))
                arr.push(PaymentMethods.IDTECH)
            if (arr.includes(PaymentMethods.V1GiftCard))
                arr.push(PaymentMethods.V1GiftCardIdTech)
            if (arr.includes(PaymentMethods.ExternalGiftCard))
                arr.push(PaymentMethods.ExternalGiftCardIdTech)
            if (arr.includes(PaymentMethods.AgilysysGiftCard))
                arr.push(PaymentMethods.AgilysysGiftCardIdTech)
        }
        return arr;
    }
}
