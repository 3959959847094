import {
  Directive,
  ElementRef,
  HostListener,
  Input,
  OnInit,
  Renderer2,
} from "@angular/core";
import { MultiCurrencyConverterService } from "../services/multi-currency-converter.service";

@Directive({
  selector: "[appMultiCurrencyConverter]",
})
export class CurrencyConverterDirective implements OnInit {
  private defaultCurrency!: string; // Default currency
  private targetCurrency!: string; // Target currency from dropdown

  @Input() originalValue: number = 0;
  isInputElement: boolean = false;

  constructor(
    private el: ElementRef,
    private renderer: Renderer2,
    private currencyConverterService: MultiCurrencyConverterService
  ) {
    this.defaultCurrency = sessionStorage.getItem("defaultCurrency");
  }

  ngOnInit(): void {
    this.isInputElement =
      this.el.nativeElement.tagName.toLowerCase() === "input";
    this.currencyConverterService.selectedCurrency$.subscribe((currency) => {
      if (this.originalValue) {
        const checkValid = this.cleanString(this.originalValue)
        this.updateDisplay(checkValid);
      }
    });
  }
  @HostListener("input", ["$event"]) onInputChange(event: any) {
    if (this.isInputElement) {
      this.originalValue = parseFloat(event.target.value);
      if (this.originalValue) {
        const checkValid = this.cleanString(this.originalValue)
        this.updateDisplay(checkValid);
      }
    }
  }
  private updateDisplay(inputValue): void {
    this.targetCurrency =
      sessionStorage.getItem("selectedCurrency") ?? this.defaultCurrency;
      
    const convertedValue = this.currencyConverterService.convert(
      inputValue,
      this.defaultCurrency,
      this.targetCurrency
    );
    let decimalDigits = Number(sessionStorage.getItem("noOfDecimalDigits"));
    const value = this.currencyConverterService.GetCurrencySymbol(
      convertedValue.toFixed(decimalDigits),
      this.targetCurrency
    );
    if (this.isInputElement) {
      // For input fields, update the value
      this.renderer.setProperty(
        this.el.nativeElement,
        'value',
        `${value}`
      );
    } else {
      // For table cells or non-input elements, update the inner text
      this.renderer.setProperty(
        this.el.nativeElement,
        'innerText',
        `${value}`
      );
    }

  }
  cleanString(value) {
    const cleanStr = value as string;
    var result = '';
    if(cleanStr.includes('-'))
    {
      result = cleanStr?.replace(/^(-?\d*\.?\d+)/g, "$1");
    }
    else
    {
      result = cleanStr?.replace(/[^\d.]/g, "");
    }
    if (result) {
      return parseFloat(result);
    } else {
      return 0
    }
  }
}
