import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RetailToggleSwitchComponent } from './retail-toggle-switch.component';
import { MaterialModule } from 'src/app/common/material-module';



@NgModule({
  declarations: [RetailToggleSwitchComponent],
  imports: [
    CommonModule,
    MaterialModule
  ],
  exports: [RetailToggleSwitchComponent],
})
export class RetailToggleSwitchModule { }
