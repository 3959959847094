import { Injectable } from "@angular/core";
import { Localization } from "src/app/common/localization/localization";
import { DiscountMappingType } from "../shared/shared.modal";
import { DiscountMappingService } from "../discount-mapping/discount-mapping.service";
import { Product } from "src/app/common/Models/common.models";
import { TierLevelDataService } from "../retail-code-setup/retail-tier-level/retail-tier-level.data.service";
import { RetailDataAwaiters } from "../shared/events/awaiters/retail.data.awaiters";
import { CommonDataAwaiters } from "src/app/common/shared/events/awaiters/common.data.awaiters";

@Injectable()
export class OtherComponentsDiscountMappingBusiness {
    captions: any;
    discountMappingType = DiscountMappingType;

    constructor(private localization: Localization, private discountMappingService : DiscountMappingService,private _TierLevelDataService: TierLevelDataService) {
        this.captions = this.localization.captions.retailsetup.DiscountMapping;;
    }

    getmappingTypes() {
        let productId = parseInt(this.localization.GetPropertyInfo('ProductId'));
        let allMappings = [
            {
                id: this.discountMappingType.vipType,
                value: "vip type",
                viewValue: this.captions.lbl_vipType,
                checked: false
            },
            {
                id: this.discountMappingType.guestType,
                value: "guest type",
                viewValue: this.captions.lbl_guestType,
                checked: false
            },
            {
                id: this.discountMappingType.tierLevel,
                value: "tier level",
                viewValue: this.captions.tierLevel,
                checked: false
            }
        ];

        if (productId === Product.GOLF) {
            allMappings.unshift({
                id: this.discountMappingType.playerType,
                value: "player type",
                viewValue: this.captions.playerType,
                checked: false
            });
        }
        return allMappings;
    }

    async getPlayerTypes() {
        let playerTypesResponse = await RetailDataAwaiters.getPlayerTypes();
        let playerTypes = []
        playerTypesResponse?.filter(x => x.isActive).forEach(player => {
            let playerType: any = {
                id: player.id,
                value: player.type,
                viewValue: player.type,
                checked: false
            }
            playerTypes.push(playerType);
        });
        return playerTypes;
    }

    async getTierLevel(){
       var tierLevelResponse = await this._TierLevelDataService.getAllTierLevel();
       let tierLevels = [];
       tierLevelResponse.forEach(tier => {
        let tierLevel: any = {
            id: tier.id,
            value: tier.name,
            viewValue: tier.name,
            checked: false
        }
        tierLevels.push(tierLevel);
    });
        return tierLevels;
    }

    async getVIPTypes(){
        let vipTypesResponse = await CommonDataAwaiters.GetAllVipType(false);
        let vipTypes = []
        vipTypesResponse.forEach(vip => {
            let vipType: any = {
                id: vip.id,
                value: vip.name,
                viewValue: vip.name,
                checked: false
            }
            vipTypes.push(vipType);
        });
        return vipTypes;

    }

   async getGuestypes(){
        let guestTypesResponse = await CommonDataAwaiters.GetAllGuestTypes(false);
        let guestTypes = []
        guestTypesResponse.forEach(guest => {
            let guestType: any = {
                id: guest.id,
                value: guest.name,
                viewValue: guest.name,
                checked: false
            }
            guestTypes.push(guestType);
        });
        return guestTypes;
        
     }

     async getDiscountTypes() {
        const allDiscountTypes = await this.discountMappingService.GetDiscountTypes();
        let discountTypes = [];
        allDiscountTypes.filter(x => x.isActive).forEach(discount => {
            let discountType: any = {
                id: discount.id,
                value: discount.type,
                viewValue: discount.type,
                checked: false
            }
            discountTypes.push(discountType);
        });
        return discountTypes;
    }

    async getDiscountTypeLinking() {
        return await this.discountMappingService.GetDiscountTypeLinking();
    }

    
    async CreateDiscountTypeLinking(linkData: any) {
        return await this.discountMappingService.CreateDiscountTypeLinking(linkData);
    }

    async CreateBulkDiscountTypeLinking(linkData: any) {
        return await this.discountMappingService.CreateBulkDiscountTypeLinking(linkData);
    }

}