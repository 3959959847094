import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { Localization } from '../../localization/localization';
import { GridType } from '../../shared/shared/globalsContant';
import { DistributionListDataService } from '../../dataservices/notification/distributionList.service';
import { DistributionListBusiness } from './distribution-list.business';
import { DistributionListApi } from '../../Models/notification.model';
import { cloneDeep } from 'lodash';
import { EventDataServices } from '../../dataservices/notification/event.data.service';
import { ActivatedRoute } from '@angular/router';
import { takeUntil } from 'rxjs/operators';
import { ReplaySubject } from 'rxjs';
import { AlertAction, AlertType, ButtonTypes } from '../../Models/common.models';
import { CommonUtilities } from '../../shared/shared/utilities/common-utilities';
import { UserAccessBusiness } from 'src/app/common/dataservices/authentication/useraccess.business';
import { UserAccessBreakPoints } from 'src/app/common/constants/useraccess.constants';

@Component({
  selector: 'app-distribution-list',
  templateUrl: './distribution-list.component.html',
  styleUrls: ['./distribution-list.component.scss'],
  encapsulation: ViewEncapsulation.None,
  providers: [DistributionListDataService, DistributionListBusiness, EventDataServices]
})
export class DistributionListComponent implements OnInit {
  captions: any;
  commoncaptions: any;
  tableoptions: any;
  tabledata: any[];
  IsViewOnly = false;
  hideCrudDLWindow = true;
  editDistributionListData: DistributionListApi;
  showTableRecords: boolean = false;
  destroyed$: ReplaySubject<boolean> = new ReplaySubject(1);
  isViewOnly = true;
  IsEditMode = true;
  @Input() showTableRecord: boolean;

  constructor(private localization: Localization, private _distributionListBusiness: DistributionListBusiness,
              private route: ActivatedRoute, private commonUtils: CommonUtilities,
              private _userAccessBusiness: UserAccessBusiness) {
    this.captions = this.localization.captions.settings.utilities.distributionlist;
    this.commoncaptions = this.localization.captions.common;
  }

  ngOnInit() {
    this.route.data.pipe(takeUntil(this.destroyed$)).subscribe((data) => {
      this.showTableRecords = data.showtableRecords;
    });
    this.getDLData();
  }

  async getDLData() {
    await this.ValidateBreakPoint();
    const distributionList: DistributionListApi[] = await this._distributionListBusiness.getDistributionList();
    this.tabledata = distributionList;
    this.BindsmsTableData(distributionList);
    return true;
  }
  async ValidateBreakPoint() {
    const result = await this._userAccessBusiness.getUserAccess(UserAccessBreakPoints.DistributionList, true);
    this.IsViewOnly = result.isViewOnly;
  }
  BindsmsTableData(bodyData) {
    const options = [{
      TableHdrData: this.GetDLHeader(),
      TablebodyData: bodyData,
      pagination: false,
      sortable: true,
      CustomColumn: true,
      PlaceHoldertext: this.captions.searchDistributionName,
      EnableActions: true,
      SelectRows: false,     
      Searchable: GridType.customfield,
      EditMoreOption: true,
      Sortable: 'distributionName',     
      disableDelete: false,
      showToggle: false,
      IsViewOnly: this.IsViewOnly,
      disableEditButton: false,
      TableDraggable: false,
      showTableRecords: this.showTableRecords
    }];
    this.tableoptions = cloneDeep(options);
  }
  private GetDLHeader() {
    return [
      {
        title: this.captions.DistributionListName, jsonkey: 'distributionName',
        sortcolumndatatype: 'string', searchable: true, sortable: true
      },
      { title: this.captions.From, jsonkey: 'fromAddress', searchable: false, sortable: false },
      { title: this.captions.To, jsonkey: 'eventActorsTo', searchable: false, sortable: false },
      { title: this.captions.CC, jsonkey: 'eventActorsCC', searchable: false, sortable: false }
     
    ];    
  }
  cruddistributionList() {
    this.IsEditMode = false;
    this.hideCrudDLWindow = false;  
    document.getElementsByClassName('wrapper-container')[0].classList?.add('hideSubnavs');
    this.editDistributionListData = null;
  }
  async returnedDLdata(eve) {
    await this.getDLData();
    this.hideCrudDLWindow = eve.CrudDLWindow;
   
    document.getElementsByClassName('wrapper-container')[0].classList?.remove('hideSubnavs');

  }

  EditDistributionList(value: [DistributionListApi]) {
    this.IsEditMode = true;
    this.editDistributionListData = value[0];
    this.hideCrudDLWindow = false;    
    document.getElementsByClassName('wrapper-container')[0].classList?.add('hideSubnavs');
  }

  async DeleteDistributionList(value: [DistributionListApi]) {
    if (this.showTableRecords) {
      const deleteWarningMessage = `${this.commoncaptions.Delete} ${value[0].distributionName}`;
      this.commonUtils.showCommonAlert(deleteWarningMessage, AlertType.Warning, ButtonTypes.YesNo, (res) => {
        if (res === AlertAction.YES) {
          this.delete(value);
        }
        else {
          return;
        }
      });
    }
    else {
      this.delete(value);
    }
  }

  async delete(value) {
    await this._distributionListBusiness.deleteDistributionList(value[0].id);
    this.getDLData();
  }
}
