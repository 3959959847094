import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { UntypedFormArray, UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { environment } from 'src/environments/environment';
import _ from 'lodash';
import { ReplaySubject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { Localization } from 'src/app/common/localization/localization';

import { PMSIntegrationHostConfiguration } from './pms-integration-setup.model';
import { AgInputFieldConfig, AgToggleConfig, ButtonValue } from 'src/app/common/Models/ag-models';
import { HttpMethod, HttpServiceCall } from 'src/app/common/shared/shared/service/http-call.service';
import { CommonPropertyInformation } from 'src/app/common/shared/services/common-property-information.service';
import { CommonUtilities } from 'src/app/common/shared/shared/utilities/common-utilities';
import { CommonApiRoutes } from 'src/app/common/common-route';
import { PmsIntegrationSetupWrapperModalComponent } from '../pms-integration-setup-wrapper-modal/pms-integration-setup-wrapper-modal.component';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-pms-integration-setup',
  templateUrl: './pms-integration-setup.component.html',
  styleUrls: ['./pms-integration-setup.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class PmsIntegrationSetupComponent implements OnInit {

  pmsIntegrationHostSetupFormGroup: UntypedFormGroup;
  viewOnly: boolean = false;
  captions: any;
  floatLabel: string;
  ConfigPairs: any = [];
  paymentFeatureConfig: UntypedFormGroup;
  hostConfig: AgInputFieldConfig;
  actionButton: ButtonValue;
  cancelButton: ButtonValue;
  propConfig: any = [];
  showbuttons: boolean = true;
  destroyed$: ReplaySubject<boolean> = new ReplaySubject(1);
  selectedRowindex: any;
  constructor(private fb: UntypedFormBuilder,
    private localization: Localization,
    public dialog: MatDialog,
    private http: HttpServiceCall,
    public PropertyInfo: CommonPropertyInformation,
    private utils: CommonUtilities) {
    this.captions = this.localization.captions;
    this.floatLabel = this.localization.setFloatLabel;
    this.propConfig = JSON.parse(sessionStorage.getItem('propConfig'));
  }

  async ngOnInit() {
    await this.initialize();
    this.pmsIntegrationHostSetupFormGroup.statusChanges.pipe(takeUntil(this.destroyed$)).subscribe(res => {
      this.actionButton.disabledproperty = !(this.pmsIntegrationHostSetupFormGroup.valid && this.pmsIntegrationHostSetupFormGroup.dirty);
      this.actionButton = { ...this.actionButton }
    });
  }

  async initialize() {
    this.pmsIntegrationHostSetupFormGroup = this.fb.group({
      pmsIntegrationHostConfigs: this.fb.array([this.createPMSHostConfigurationGroup(1, '', '')])
    });

    this.actionButton = {
      label: this.captions.btn_save,
      type: 'primary',
      disabledproperty: true
    };
    this.cancelButton = {
      label: this.captions.btn_cancel,
      type: 'tertiary'
    };
    let pmsIntegrationHostConfigurations = await this.InvokeGetAllPMSIntegrationHostConfigurationsAPICall();
    await this.initializePMSIntegrationHostConfigurationForm(pmsIntegrationHostConfigurations);
  }

  async initializePMSIntegrationHostConfigurationForm(pmsIntegrationHostConfigurations: PMSIntegrationHostConfiguration[]) {
    if (pmsIntegrationHostConfigurations) {
      const hostIds = [...new Set(pmsIntegrationHostConfigurations.map(config => config.hostId))];
      const pmsIntegrationHostConfigPairs = [];
      hostIds.forEach(hostId => {
        const currentHostConfigs = pmsIntegrationHostConfigurations.filter(f => f.hostId == hostId);
        if (currentHostConfigs) {
          const currentHostConfigPairs = [];
          currentHostConfigs.forEach(e => {
            currentHostConfigPairs.push(this.createPMSIntegrationHostConfigPairs(e.hostId, e.configurationKey, e.configurationValue));
          });

          pmsIntegrationHostConfigPairs.push(this.fb.group({
            hostNumber: hostId,
            switch:true,
            tableData:'',
            ConfigPairs: this.fb.array(currentHostConfigPairs)
          }));
        }
      });

      this.pmsIntegrationHostSetupFormGroup = this.fb.group({
        pmsIntegrationHostConfigs: this.fb.array(pmsIntegrationHostConfigPairs)
      });
      this.pmsIntegrationHostSetupFormGroup = _.cloneDeep(this.pmsIntegrationHostSetupFormGroup);
    }
  }

  createPMSHostConfigurationGroup(_hostId: Number, _key: any, _value: any): UntypedFormGroup {
    return this.fb.group({
      hostNumber: _hostId,
      switch:true,
      tableData:'',
      ConfigPairs: this.fb.array([this.createPMSIntegrationHostConfigPairs(_hostId, _key, _value)])
    });
  }

  createPMSIntegrationHostConfigPairs(_hostId: Number, _key: any, _value: any): UntypedFormGroup {
    return this.fb.group({
      hostId: _hostId,
      configurationKey: _key,
      configurationValue: _value
    });
  }

  createPMSHostConfigPairItem(_index: number, _key: any, _value: any, _id: number): UntypedFormGroup {
    return this.fb.group({
      pmsHost_index: _index,
      configurationKey: _key,
      configurationValue: _value,
      hostId: _id
    });
  }

  onAddConfigPairFromPMSHostConfigGroup(formGrp: UntypedFormGroup, arrayName: string, _index: number, _key: any, _value: any, id: number, addedfromUI: boolean = false, arrayIndex?) {
    // if (!this.isUserAuthorized || this.isViewOnly)
    //   return;

    if (!addedfromUI) {
      arrayIndex = arrayIndex - 1;
    }
    const controls = arrayIndex == null ? null : formGrp.get(arrayName).get(arrayIndex.toString());
    this.ConfigPairs = (controls == null ? formGrp.get(arrayName) as UntypedFormArray : formGrp.get(arrayName)['controls'][arrayIndex].controls.ConfigPairs as UntypedFormArray);
    this.ConfigPairs.push(controls == null ? this.createPMSHostConfigurationGroup(1, _key, _value)
      : this.createPMSIntegrationHostConfigPairs(1, _key, _value));
  }

  onRemoveConfigPairFromPMSHostConfigGroup(formGrp: UntypedFormGroup, arrayName: string, _index: any, arrayIndex?) {
    // if (!this.isUserAuthorized || this.isViewOnly)
    //   return;

    this.ConfigPairs = (arrayIndex == null ? formGrp.get(arrayName) as UntypedFormArray : formGrp.get(arrayName)['controls'][arrayIndex].controls.ConfigPairs as UntypedFormArray);
    this.ConfigPairs.removeAt(_index);
    formGrp.markAsDirty();
  }

  async savePMSIntegrationHostConfiguration() {
    let pmsIntegrationHostConfiguration: PMSIntegrationHostConfiguration[] = [];
    let configPairsTemp = this.pmsIntegrationHostSetupFormGroup.controls['pmsIntegrationHostConfigs'].value.map(x => x);
    configPairsTemp.forEach(x => {
      x.ConfigPairs.forEach(ConfigPairs => {
        let currentConfig: PMSIntegrationHostConfiguration = {
          hostId: x.hostNumber,
          configurationKey: ConfigPairs.configurationKey,
          configurationValue: ConfigPairs.configurationValue
        }
        pmsIntegrationHostConfiguration.push(currentConfig);
      });
    });

    let pmsIntegrationHostConfigurations = await this.InvokeSavePMSIntegrationHostConfigurationApiCall(pmsIntegrationHostConfiguration);
    await this.initializePMSIntegrationHostConfigurationForm(pmsIntegrationHostConfigurations);
  }

  async InvokeGetAllPMSIntegrationHostConfigurationsAPICall(): Promise<PMSIntegrationHostConfiguration[]> {
    this.utils.ToggleLoader(true);
    let response: PMSIntegrationHostConfiguration[] = [];
    if (this.propConfig?.PMSCommunicationKey) {
      this.utils.ToggleLoader(true);
      let URI = environment["CommonGateway"] + "/" + this.propConfig?.PMSCommunicationKey + CommonApiRoutes.GetAllPMSIntegrationHostConfigurations;
      await this.http.InvokeApiAsync<any>(URI, HttpMethod.Get).then(t => {
        response = t.result;
      });
      this.utils.ToggleLoader(false);
    }
    else {
      this.showbuttons = false;
      this.utils.showError(this.captions.Error_PMSCommunication_Not_Configured);
    }
    this.utils.ToggleLoader(false);
    return response;
  }

  async InvokeSavePMSIntegrationHostConfigurationApiCall(pmsIntegrationHostConfigurations: PMSIntegrationHostConfiguration[]): Promise<PMSIntegrationHostConfiguration[]> {
    this.utils.ToggleLoader(true);
    let response: PMSIntegrationHostConfiguration[] = [];
    if (this.propConfig?.PMSCommunicationKey) {
      this.utils.ToggleLoader(true);
      let URI = environment["CommonGateway"] + "/" + this.propConfig?.PMSCommunicationKey + CommonApiRoutes.SavePMSIntegrationHostConfiguration;
      await this.http.InvokeApiAsync<any>(URI, HttpMethod.Post, pmsIntegrationHostConfigurations).then(t => {
        response = t.result;
      });
      this.utils.ToggleLoader(false);
    }
    else {
      this.utils.showError(this.captions.Error_PMSCommunication_Not_Configured);
    }
    this.utils.ToggleLoader(false);
    return response;
  }

  hostNumberChange(value, index) {
    let formValue = this.pmsIntegrationHostSetupFormGroup.controls['pmsIntegrationHostConfigs'].value;
    formValue[index].hostNumber = Number(value);
    this.pmsIntegrationHostSetupFormGroup.controls['pmsIntegrationHostConfigs'].setValue(formValue);
    this.pmsIntegrationHostSetupFormGroup = _.cloneDeep(this.pmsIntegrationHostSetupFormGroup);
  }

  onAction(eve) {

  }

  openModal(formIndex,toggleVal,formObj)
  {
    this.selectedRowindex=formIndex;
    const dialogRef = this.dialog.open(PmsIntegrationSetupWrapperModalComponent, {
      height: '100%',
      width: '100%',
      maxWidth:'100%',
      data: {
        formIndex,
        toggleVal
       },
      panelClass: 'small-popup',
      disableClose: true,
  });
    dialogRef.afterClosed().subscribe(res => {
      console.log("res",res)
      if(res)
      {
       
        formObj['controls']['tableData'].setValue(res.data);
        console.log("formObj['controls']['tableData']",this.pmsIntegrationHostSetupFormGroup);
     
      }
     
   });
  }

  async onCancel(eve) {
    if (this.actionButton.disabledproperty == false) {      
      let pmsIntegrationHostConfigurations = await this.InvokeGetAllPMSIntegrationHostConfigurationsAPICall();
      await this.initializePMSIntegrationHostConfigurationForm(pmsIntegrationHostConfigurations);
    }
  }

  keyPress(event: any) {
    if (event.target.value.length >= 7000) {
      event.preventDefault();
    }
  }
}

