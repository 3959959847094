import { Injectable } from "@angular/core";
import { CommonDataAwaiters } from "../../shared/events/awaiters/common.data.awaiters";
import { GuestType } from "../../shared/shared/business/shared.modals";

@Injectable({
    providedIn: "root"
})
export class GuestTypeBusiness {

    async GetAllGuestTypes(includeInActive) {
        return await CommonDataAwaiters.GetAllGuestTypes(includeInActive);
    }

    async createGuestType(guestType) {
        let guestTypeObj = this.mapGuestType(guestType, false);
        return await CommonDataAwaiters.CreateGuestType(guestTypeObj);
    }

    async updateGuestType(guestType) {
        let guestTypeObj = this.mapGuestType(guestType, true);
        return await CommonDataAwaiters.UpdateGuestType(guestTypeObj);
    }

    async deleteGuestType(id) {
        return await CommonDataAwaiters.DeleteGuestType(id);
    }

    async dragDropGuestType(fromOrder, toOrder) {
        return await CommonDataAwaiters.DragDropGuestType(fromOrder, toOrder);
    }

    async getGuestTypeBreakpoint() {
        return await CommonDataAwaiters.GetGuestTypeBreakpoint();
    }

    mapGuestType(guestType, isUpdate): GuestType {

        if (guestType == null)
            return;

        const guestTypeObj: GuestType = {
            id: isUpdate ? guestType.id : 0,
            code: guestType.code,
            name: guestType.name,
            listOrder: guestType.listOrder,
            isActive: guestType.isActive
        };

        return guestTypeObj;
    }
}