import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { Localization } from 'src/app/common/localization/localization';
import { MatDialog } from '@angular/material/dialog';
import { ActionMode, AlertAction, AlertType, ButtonType, ButtonTypes } from 'src/app/common/enums/shared-enums';
import { createModal } from 'src/app/common/components/create-modal/create-model';
import { UntypedFormGroup } from '@angular/forms';
import { CommonUtilities } from '../utilities/common-utilities';

@Component({
  selector: 'app-deposit-policy-mapping',
  templateUrl: './deposit-policy-mapping.component.html',
  styleUrls: ['./deposit-policy-mapping.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class DepositPolicyMappingComponent implements OnInit {

  captions : any;
  @Input() tableContent;
  originalcontent = [];
  @Input() tableOptions;
  searchText = '';
  IsEditModeEnabledFlag: boolean;
  showInactivetog:boolean=false;
  createToggler = false;
  createModalData: createModal;
  crudActionInput;
  createForm: UntypedFormGroup;
  @Input() policyOptions;
  @Input() cancellationPolicyOptions;
  @Input() applyToOptions;
  @Input() mainGroupList;
  @Input() productId;
  @Input() searchPlaceholder;
  isViewOnly = false;
  onEditDisableFunction = true;
  constructor(private localization: Localization,public dialog: MatDialog,public utils: CommonUtilities) {
    this.captions = this.localization.captions;
   }

  ngOnInit(): void {
    this.loadTableData();
  }

  loadTableData() {
    this.onEditDisableFunction = true;
    this.originalcontent = this.tableContent;
  }

  createCancellation() {
    this.crudActionInput = {
      mode: ActionMode.create,
      actionButton: this.captions.btn_save,
      form: undefined
    };
    this.createToggler = true;
    this.createModalData = {
      title: this.captions.hdr_createDepositPolicyMapping
    };
  }

  showInactive(eve) {
    this.showInactivetog = !this.showInactivetog;
    this.showInactivetoggle();
  }

  showInactivetoggle(){
    if(this.showInactivetog==true)
      this.tableContent = this.originalcontent;
    else
     this.tableContent = this.originalcontent.filter(x=>x.isActive.value == true);
    this.tableContent = [...this.tableContent];
  }

  searchTextChange(eve) {
    this.searchText = eve;
  }

  formReady(event) {
    this.createForm = event;
  }

  backAction(form?) {
    this.createToggler = false;
    this.IsEditModeEnabledFlag = false;
  }

  CancelEvent(event) {
    this.IsEditModeEnabledFlag = false;
  }


  async DoneEvent(event) {
    this.IsEditModeEnabledFlag = false;
  }

  inactiveSliderChange(e){

  }


  handleClick(event){
    switch (event.from) {
      case ActionMode.create:
      case ActionMode.copy:
          this.utils.showAlert(this.captions.alertPopup.successfullysaved, AlertType.WellDone, ButtonType.Ok);
          this.backAction();
        break;
      case ActionMode.cancel:
        this.back(event);
        break;
      case ActionMode.update:
          this.utils.showAlert(this.captions.alertPopup.successfullysaved, AlertType.WellDone, ButtonType.Ok);
          this.backAction();
        break;

    }
  }

  back(e) {
    this.backAction();
  }

  edit(rowData){
    this.createToggler = true;
    this.createModalData = {
      title: this.captions.hdr_editDepositPolicyMapping
    };
    this.crudActionInput = {
      mode: ActionMode.update,
      actionButton: this.captions.btn_update,
      form: rowData
    };
  }
  deleteRow(rowItem){

  }

}

