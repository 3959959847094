import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReportRoutingModule } from './reports-routing.module';
import { ReportsComponenet } from './reports.component';
import { ViewReportsComponent } from './view-reports/view-reports.component';
import { SharedModule } from '../shared/shared.module';
import { FastReportComponent } from './fast-report/fastreport.component';
import { ReportControl } from './basereport/base-report.component';
import { TransactionLogComponent } from './transaction-log/transaction-log.component';
import { ReportService } from './report-service.service';
import { InventorySummaryComponent } from './report-filters-section/retail-reports/inventory-summary/inventory-summary.component';
import { MultipackItemComponent } from './report-filters-section/retail-reports/multipack-item/multipack-item.component';
import { InventoryDetailsComponent } from './report-filters-section/retail-reports/inventory-details/inventory-details.component';
import { InventoryTransferComponent } from './report-filters-section/retail-reports/inventory-transfer/inventory-transfer.component';
import { CategoryComponentComponent } from './report-filters-section/retail-reports/category-component/category-component.component';
import { InventoryWashComponent } from './report-filters-section/retail-reports/inventory-wash/inventory-wash.component';
import { TransactionLogReportComponent } from './report-filters-section/retail-reports/transaction-log-report/transaction-log-report.component';
import { InventoryAuditComponent } from './report-filters-section/retail-reports/inventory-audit/inventory-audit.component';
import { SalesByDiscountypeComponent } from './report-filters-section/retail-reports/sales-by-discountype/sales-by-discountype.component';
import { SalesBySubcategoryComponent } from './report-filters-section/retail-reports/sales-by-subcategory/sales-by-subcategory.component';
import { SalesByCategoryComponent } from './report-filters-section/retail-reports/sales-by-category/sales-by-category.component';
import { SaleByItemComponent } from './report-filters-section/retail-reports/sale-by-item/sale-by-item.component';
import { CorrectVoidComponent } from './report-filters-section/retail-reports/correct-void/correct-void.component';
import { RevenueSummaryComponent } from './report-filters-section/retail-reports/revenue-summary/revenue-summary.component';
import { TransactionComponent } from './report-filters-section/retail-reports/transaction/transaction.component';
import { ShiftComponent } from './report-filters-section/retail-reports/shift/shift.component';

import { MultiSelectDropdownComponent } from './multi-select-dropdown/multi-select-dropdown.component';
import { FastReportBusinessService } from './fast-report/fastreport.business.service';
import { RetailReportModule } from '../retail/retail-reports/reports.module'
import { StaffScheduleComponent } from './report-filters-section/retail-reports/staff-schedule.component';
import { ReportAppointmentComponent } from './report-appointment/report-appointment.component';
import { ReportRetailComponent } from './report-retail/report-retail.component';
import { ReportPosComponent } from './report-pos/report-pos.component';
import { ReportGiftcardsComponent } from './report-giftcards/report-giftcards.component';
import { ReportClientComponent } from './report-client/report-client.component';
import { ReportTransactionLogComponent } from './report-transaction-log/report-transaction-log.component';
import { ProjectedRevenueComponent } from './report-filters-section/retail-reports/projected-revenue/projected-revenue.component';
import { UtilizationComponent } from './report-filters-section/spa-reports/Utilization/utilization.component';
import { ClientListingComponent } from './report-filters-section/retail-reports/client-listing/client-listing.component';
import { AppointmentListingComponent } from './report-filters-section/spa-reports/appointment-listing/appointment-listing.component';
import { ReportSecurityComponent } from './report-security/report-security.component';
import { AppointmentByStaffMemberComponent } from './report-filters-section/spa-reports/appointment-by-staff-member/appointment-by-staff-member.component';
import { ReportMemberComponent } from './report-member/report-member.component';
import { ReportInventorycontrolComponent } from './report-inventorycontrol/report-inventorycontrol.component';
import {DepositComponent} from './report-filters-section/spa-reports/Deposit-Appointment/deposit.component';
import { AppointmentByUserComponent } from './report-filters-section/spa-reports/appointment-by-user/appointment-by-user.component';
import { EasypopupComponent } from './querybuilder/easypopup/easypopup.component';
import { SaveEasyqueryPopupComponent } from './querybuilder/save-easyquery-popup/save-easyquery-popup.component';
import { QuerybuilderComponent } from './querybuilder/querybuilder.component';
import { UserAccessService } from '../common/services/user-access.service';
import { LockerUtilizationComponent } from './report-filters-section/spa-reports/Locker-Utilization/Locker-Utilization.component';
import { PackageSoldComponent } from './report-filters-section/spa-reports/package-sold/package-sold.component';
import { StaffLogReportComponent } from './report-filters-section/spa-reports/staff-log-report/staff-log-report.component';

@NgModule({
  imports: [
    CommonModule,
    ReportRoutingModule,
    SharedModule,
    RetailReportModule
  ],

  declarations: [ReportsComponenet, ViewReportsComponent, ReportControl, FastReportComponent, TransactionLogComponent,
       MultiSelectDropdownComponent,ReportSecurityComponent,ReportMemberComponent,
     InventorySummaryComponent, MultipackItemComponent, InventoryDetailsComponent, InventoryTransferComponent, CategoryComponentComponent, InventoryWashComponent, TransactionLogReportComponent, InventoryAuditComponent, SalesByDiscountypeComponent, SalesBySubcategoryComponent, SalesByCategoryComponent, SaleByItemComponent, CorrectVoidComponent, RevenueSummaryComponent, TransactionComponent, ShiftComponent,StaffScheduleComponent, UtilizationComponent, ReportAppointmentComponent, ReportRetailComponent, ReportPosComponent, ReportGiftcardsComponent, ReportClientComponent, ReportTransactionLogComponent,ProjectedRevenueComponent,ClientListingComponent, AppointmentListingComponent,AppointmentByStaffMemberComponent, ReportInventorycontrolComponent,DepositComponent, AppointmentByUserComponent,
     QuerybuilderComponent,
     SaveEasyqueryPopupComponent,
     EasypopupComponent,LockerUtilizationComponent, PackageSoldComponent, StaffLogReportComponent],
  providers:[ReportService, FastReportBusinessService, UserAccessService],

  exports: [ReportControl]
})
export class ReportModule { }
