import exp from "constants";

export class PMSIntegrationHostConfiguration {   
    hostId: number;   
    configurationKey: string;
    configurationValue: string;  
}

export const PMSCommunicationReceiverGateway = 'commongateway';

export const MultiPropertyDefaultToggle = {
    DefaultRoomCharge: "defaultRoomCharge",
    DefaultGroupCharge: "defaultGroupCharge",
    DefaultHotelComp: "defaultHotelComp",
    DefaultCityLedger: "defaultCityLedger",
    DefaultRevenuePosting: "defaultRevenuePosting"
}
export const ConfigurationKey = {
    ActivityLocationTypeKey : "ExternalActivityLocationMap",
    ActivityStatusKey :"ExternalActivityStatusMap",
    NameTitleKey : "NameTitleMapping",
    VIPCodeKey : "VIPCodeMapping"   ,
    GenderKey :"ExternalGenderMapping",
    ContactTypeKey : "ExternalContactTypeMapping",
    CountryKey :"CountryCodeMapping",
    StateKey : "StateCodeMapping",
}
export enum FieldMap {
    All = 0,
    ActivityLocation = 1,
    ActivityStatus,
    ActivityType,
    NameTitle,
    VIPCode,
    Gender,
    ContactType,
    ContactRole,
    Country,
    State,
    PostingTransactionCode,
    PaymentTransactionCode
}
export class FieldMapMasterData {
    field: FieldMap;
    internalCode: InternalCode[];
    externalCode: ExternalCode[];
}
export class ExternalCode{
    code: string;
    description: string;
    id: string;
    hidden : boolean = false;
} export class InternalCode {
    code: string;
    description: string;
    id: string;
    hidden: boolean = false;
}
export class ActivityLocation {
    V1Activity: string;
    ActivityType: string;
    Location: string;
}
export class ActivityStatus {
    V1ActivityStatus: number;
    ExternalActivityStatus: string;
}
export class NameTitle {
    V1Name: string[];
    Code: string;
}
export class VIPCode {
    ExternalCode: string;
    V1Code: string
}
export class GenderMap {
    GenderMapping: Gender[];
    UseGender: boolean = true;
}
export class Gender {
    ExternalGender: string;
    V1Gender: string
}
export class ContactType {
    PhoneRole: string;
    PhoneType: string;
    V1ContactType: number;
}
export class Country {
    Name: string;
    Code: string;
}