import { Injectable } from '@angular/core';
import { HttpRequest, HttpResponse } from '@angular/common/http';
abstract class HttpCache {
  abstract get(req: HttpRequest<any>, data: any): HttpResponse<any> | null;
}
@Injectable({
  providedIn: 'root'
})
export class HttpCacheService implements HttpCache {
  private outercache = new Map<string, Map<string, [Date, HttpResponse<any>]>>();
  private innercache = new Map<string, [Date, HttpResponse<any>]>();
  constructor() { }

  set(outerkey, innerkey, value, timeToLive) {
    if (timeToLive) {
      const expiresIn = new Date();
      expiresIn.setSeconds(expiresIn.getSeconds() + timeToLive);
      if (this.outercache.has(outerkey)) {
        this.outercache.get(outerkey).set(innerkey, [expiresIn, value]);
      }
      else {
        this.innercache = new Map<string, [Date, HttpResponse<any>]>();
        this.outercache.set(outerkey, this.innercache.set(innerkey, [expiresIn, value]));
      }
    } else {
      if (this.outercache.has(outerkey)) {
        this.outercache.get(outerkey).set(innerkey, [null, value]);
      }
      else {
        this.innercache = new Map<string, [Date, HttpResponse<any>]>();
        this.outercache.set(outerkey, this.innercache.set(innerkey, [null, value]));
      }
    }
  }

  get(innerkey) {
    let foundOuterKey = this.findOuterKeyByInnerKey(this.outercache, innerkey);
    if (foundOuterKey) {
      let innerMap = this.outercache.get(foundOuterKey);
      // Check if the inner map exists
      if (innerMap) {
        // Alternatively, you can iterate over inner map values
        let innerCache = innerMap.get(innerkey)
        if (innerCache) {
          let tuple = innerCache;
          if (!tuple) return null;
          let expiresIn = tuple[0];
          let httpSavedResponse = tuple[1];
          if (expiresIn && expiresIn.getTime() <= new Date().getTime()) {
            this.outercache.delete(foundOuterKey);
            return null;
          }
          return httpSavedResponse;
        }
      }
    }
    return null;
  }

  findOuterKeyByInnerKey(outerMap: any, innerKey: string): string {
    for (const [outerKey, nestedMap] of outerMap.entries()) {
      if (nestedMap instanceof Map && nestedMap.has(innerKey)) {
        return outerKey;
      }
    }
    return null;
  }


  cacheDelete(name: string) {
    let outerKey = this.outercache.get(name)
    if (outerKey) {
      this.outercache.delete(name);
    }
  }
}
