import { Component, ElementRef, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { RetailLocalization } from 'src/app/retail/common/localization/retail-localization';
import { takeUntil } from 'rxjs/operators';
import { ReplaySubject } from 'rxjs';
import { RevenuePostTypes } from '../../retail.modals';
import { FieldMapMasterData } from 'src/app/common/components/pms-integration-setup/pms-integration-setup.model';


@Component({
  selector: 'app-external-pms-post-type-mapping',
  templateUrl: './external-pms-post-type-mapping.component.html',
  styleUrls: ['./external-pms-post-type-mapping.component.scss']
})
export class ExternalPmsPostTypeMappingComponent implements OnInit,OnDestroy {
  commonCaptions: any;
  captions: any;
  externalpmsposttypemappingform: UntypedFormGroup;
  isValid: boolean = false;
  destroyed$: ReplaySubject<boolean> = new ReplaySubject(1);
  isFromResortFinance: boolean = false;
  postTypeCaption: string;
  initialtransactionCodeList: any[] = [];
  transactionCodeOptions: any[] = [];
  inputSearchTransactioncode: string;
  showDropdown: boolean = false;
  @ViewChild('transactionCodeInput') transactionCodeInput: ElementRef;
  @Input() dataInput: any;
  @Input() LinkType: number;
  @Input() PostType: number;
  @Input() transactionCode: FieldMapMasterData;
  constructor(private fb: UntypedFormBuilder, private localization: RetailLocalization, private dialogRef: MatDialogRef<ExternalPmsPostTypeMappingComponent>) {
  }

  ngOnInit() {
    this.initializeForm();
    this.isFromResortFinance = this.dataInput[0]?.isFromResortFinance ?? false;
    this.captions = this.localization.captions.retailsetup;
    this.commonCaptions = this.localization.captions.common;
    this.postTypeCaption = this.GetCaptionByPostType();
    this.externalpmsposttypemappingform.valueChanges.pipe(takeUntil(this.destroyed$)).subscribe(val => {
      this.isValid = (this.externalpmsposttypemappingform.dirty)
    });
    if (this.dataInput[0][this.dataInput[1]] != '—') {
      this.patchBucketValue(this.dataInput);
    }
    this.initialtransactionCodeList = [...this.transactionCode.externalCode];
    this.transactionCodeOptions = [...this.initialtransactionCodeList];
    this.showDropdown = this.transactionCodeOptions.length > 0;
  }

  ngOnDestroy(): void {
    
  }

  initializeForm() {
    this.externalpmsposttypemappingform = this.fb.group({
      transactioncode: [''],
      applyForOutletsForTheSamePaymentMethod: false,
      applyForAllPaymentMethodsForTheSameOutlet: false
    })
  }

  patchBucketValue(data) {
    this.externalpmsposttypemappingform.controls.transactioncode.setValue(data[0][data[1]]);
    this.externalpmsposttypemappingform.controls.applyForOutletsForTheSamePaymentMethod.setValue(false);
    this.externalpmsposttypemappingform.controls.applyForAllPaymentMethodsForTheSameOutlet.setValue(false);
  }

  saveMappingType() {
    this.dialogRef.close(this.externalpmsposttypemappingform)
  }

  onClick() {
    this.dialogRef.close()
  }

  applyForOutletsForTheSamePaymentMethod(event) {
    this.externalpmsposttypemappingform.controls.applyForOutletsForTheSamePaymentMethod.setValue(event);
  }

  applyForAllPaymentMethodsForTheSameOutlet(event) {
    this.externalpmsposttypemappingform.controls.applyForAllPaymentMethodsForTheSameOutlet.setValue(event);
  }
  
  GetCaptionByPostType(value: number = this.PostType) {
    switch (value) {
      case RevenuePostTypes.Settlement:
      case RevenuePostTypes.Discount:
      case RevenuePostTypes.ServiceCharge:
      case RevenuePostTypes.Gratuity:
        return this.captions.lbl_applyToAllRetailCategories;
      case RevenuePostTypes.PaymentMethod:
        return this.captions.lbl_applyForAllPaymentMethodsForTheSameOutlet;
      case RevenuePostTypes.Tax:
        return this.captions.lbl_applyToAllTaxes;
      case RevenuePostTypes.Surcharge:
        return this.captions.lbl_applyToAllSurCharges;
    }
  }
  openedTransactionCodeChange(opened: boolean) {
    this.transactionCodeInput.nativeElement.focus()
    if (!opened) {
      this.inputSearchTransactioncode = ''
      this._transactionCodeFilter("");
    }
  }
  private _transactionCodeFilter(value: string) {
    if (value) {
      const filterValue = value.toLowerCase();
      return this.transactionCodeOptions = this.initialtransactionCodeList.filter(x => (x.description.toString().toLowerCase().includes(filterValue)))
    } else {
      return this.transactionCodeOptions = this.initialtransactionCodeList
    }
  }
  transactionCodeFilterOptions(event) {
    this._transactionCodeFilter(event.target.value);
  }
}
