import { Component, OnInit, ViewEncapsulation, Input, OnDestroy, Output, EventEmitter, OnChanges, ViewChild, ViewContainerRef, ElementRef } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSelectChange } from '@angular/material/select';
import { AppointmentpopupService } from '../../../service/appointmentpopup.service';
import { HttpServiceCall, HttpMethod } from '../../../service/http-call.service';
import { SpaUtilities } from '../../../utilities/spa-utilities';
import { BaseResponse, Email, PhoneNumber } from '../../../../shared/business/shared.modals';
import { Host, ImgRefType, SPAManagementBreakPoint, GenderPreference, DefaultGUID, ButtonType, ButtonOptions, AlertType, AlertAction, SPAScheduleBreakPoint, ClientType, clientSearchType, InternalPMS } from '../../../globalsContant';
import { appointmentService, PackageBook } from '../../../service/appointment.service';
import { FormControl, NgForm, UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { ClientInfo, TempHoldApp } from '../../../business/shared.modals';
import { AppointmentUtilities } from '../../../utilities/appointment-utilities';
import * as _ from 'lodash'
import { SpaLocalization } from '../../../../core/localization/spa-localization';
import { ImageProcessorService } from '../../../service/image-processor-service';
import { UserAlerts } from '../../../../core/config/alerts-config';
import { AlertMessagePopupComponent } from '../../../alert-message-popup/alert-message-popup.component';
import { BreakPointAccess } from '../../../service/breakpoint.service';
import { Subscription, ReplaySubject, Subject } from 'rxjs';
import { AppointmentPopupComponent } from '../../appointment-popup.component';
import { ClientService } from '../../../service/client-service.service';
import { SpaPropertyInformation } from '../../../../core/services/spa-property-information.service';
import { ClientLabel, ServicePriceTypeWithCode } from '../../../business/new-booking.model';
import { debounceTime, distinctUntilChanged, takeUntil } from 'rxjs/operators';
import { PMSActiviyStatus, StaySearchFilter } from 'src/app/shared/shared.modal';
import { ClientCommonService } from 'src/app/client/client.service';
import { CMSPlayerInfo } from 'src/app/common/Models/common.models';
import { CMSBusinessService } from 'src/app/retail/shared/business/CMS-business.service';
import { RetailFeatureFlagInformationService } from 'src/app/retail/shared/service/retail.feature.flag.information.service';
import { QuickLoginUtilities } from 'src/app/common/shared/shared/utilities/quick-login-utilities';
import { QuickLoginDialogResult } from 'src/app/common/shared/shared/quick-login/quick-login.component';
import { cloneDeep, forEach, truncate, uniqBy } from 'lodash';
import { FindRelationshipComponent } from 'src/app/shared/find-relationship/find-relationship.component';
import { IcartDataService } from 'src/app/common/dataservices/icart.data.service';
import { CartUtilities } from 'src/app/common/components/menu/vcart/cart.utilities';
import { ChipsAutocompleteExample } from 'src/app/shared/multiplesearch/multiplesearch.component';
import { GuestSearch } from './GuestSearch-model';
@Component({
  selector: 'app-client-information',
  templateUrl: './client-information.component.html',
  styleUrls: ['./client-information.component.scss'],
  encapsulation: ViewEncapsulation.None,
  providers: [ChipsAutocompleteExample]

})
export class ClientInformationComponent implements OnInit, OnDestroy, OnChanges {
  imageContent: any;
  @ViewChild('frm', { static: true }) clientForm: NgForm;
  @Input() isMultipleSearch: boolean;
  @Input() searchLimit = -1;
  @Input() activateClient: any;
  @Input() showCartGuests: boolean = false;
  @Input() fromPackage: boolean = false;
  actionDialogSubscription: Subscription;
  cartGuests: { id: number; value: string; viewValue: string; disabled: boolean; clientId?: number }[] = [];
  cartGuest: any;
  selectedTherapist: any;
  isEditAppointment = false;
  hideCartGuests = false;
  isPlatformGuestSearch: boolean = false;
  clientInformationDynamicHeight = 'calc( 100% - 140px)';
  clientSearchFilter: GuestSearch[];
  clientSearchValue: string;
  @ViewChild('chipdataInput') chipdataInput: ElementRef;
  clientswitchvalue: number;
  showExternalSearch: boolean;
  isFromClientInfo: boolean = true;
  @Input("clientBlockInfos")
  set ClientBlockInformation(clientBlockInfo: any) {
    if (!this.isLocalChange && clientBlockInfo && clientBlockInfo.length > 0) {
      this.validateTrigger();
    }
  }
  clientSearchForm : UntypedFormGroup;

  validateTrigger() {
    if (this.appointmentservice.multiClientInfo && this.appointmentservice.multiClientInfo.length > 0) {
      const cllientIds = this.appointmentservice.multiClientInfo.map(x => x.id);
      this.validateClientBlockInformation(cllientIds);
    }
    else {
      setTimeout(() => {
        if (!this.isLocalChange) {
          this.validateTrigger();
        }
      }, 500);
    }
  }

  @Output() ClientAddedEvent: EventEmitter<any> = new EventEmitter();

  selectedClientPriceValue: number;
  public activeBorderId = 0;
  public therapistArray: any = [];
  clientInfo: any;
  validTherapist = [];
  activeClient: number;
  clientactivated = false;
  selectedPriceType: number;
  selectedPrice: number;
  toAddClient: number;
  captions: any = this.localization.captions.bookAppointment;
  allCaptions: any = this.localization.captions;
  callBack = false;
  isClientEditFromPackage: boolean;
  imgSubscription: Subscription;
  isZero: any = "0";
  priceTypeModel: any;
  requestUid = "";
  poppedClientId = [];
  isLocalChange: boolean;
  clearSearchText: boolean = false;
  isEmbed: boolean = false;
  priceType: ServicePriceTypeWithCode[] = []
  amount: number;
  PMSActiviyStatus = PMSActiviyStatus;
  private destroyed$: ReplaySubject<boolean> = new ReplaySubject(1);
  ItineraryData: any;
  ItinerarySubscriber: Subscription;
  nsBDRclr: any;
  closeBDRclr: any;
  checkinBDRclr: any;
  checkoutBDRclr: any;
  sBDRclr: any;
  isPlayerFound = false;
  isCMSConfigured: boolean = false;
  defaulthotelresvvalue: number = 1;
  defaultClientSearchValue: number = 0;  
  hotelReservationTypes: any;
  searchHotelReservLength: number = 50;
  originalHotelReservData: any[] = [];
  hotelReservAllData: any[] = [];
  hotelReservSelectedData: any[] = [];
  hotelReservSearchKey: string[] = ['id', 'name'];
  hotelReservAutoCompleteKeys: string[] = ['name'];
  hotelReservSelectedChipKey: string[] = ['name'];
  hotelReservIsChipDisabled: boolean;
  hotelReservChipPlaceholder: string;
  searchTextPlaceHolder: string;
  selectedTherapistId: number = 0;
  floatLabel: string;
  clientSearchTypes: any;
  selectedClientSearchType: number;
  searchText = '';
  existingClientPlaceholder: string;
  isContactLogShow: boolean = false;
  currentguestInfo: any[] = [];
  selectedClientInfo: any = [];
  clientToRemove: any;
  locationBlockEdit: boolean = false;
  isExternalPMSIntegrated: boolean
  clientType: ClientType;
  platFormExtendedSearchRequired: boolean = false;
  pmsSystem: boolean = false;
  pmsSystemValue: string;
  searchButton: { type: string; label: any; disabledproperty: boolean; };
  searchTextclient: any;
  platFormsearchDebounce= new Subject<any>();
  findRelationshipDebounce = new Subject<any>();
  externalSearch: boolean;
  addclientInfo: ClientInfo;
  memberConfiguration: any;

  constructor(public dialog: MatDialog, private _vcr: ViewContainerRef, private appUtils: AppointmentUtilities,
    public appointmentServiceObject: appointmentService,
    public appointmentservice: AppointmentpopupService, private http: HttpServiceCall,
    public utils: SpaUtilities, public localization: SpaLocalization,
    private imgService: ImageProcessorService, private userAlert: UserAlerts, private breakPoint: BreakPointAccess, private clientService: ClientService, private PropertyInfo: SpaPropertyInformation,
    private clientCommonService: ClientCommonService,
    //Added for add client to Appointment screen (validating with CMS value )
    private _cmsBusiness: CMSBusinessService,
    private _featureSwitch: RetailFeatureFlagInformationService,
    private _quickLoginUtil: QuickLoginUtilities,
    private IcartDataService: IcartDataService,
    private cartUtilities: CartUtilities,
    private chipsAutoComplete: ChipsAutocompleteExample,private Form: UntypedFormBuilder
  ) {
    this.floatLabel = this.localization.setFloatLabel;
    this.appointmentservice.cancelBool = true;
    this.PropertyInfo._appointmentConfigurations$.pipe(takeUntil(this.destroyed$)).subscribe(result => {
      if (result) {
        this.setStatusColors();
      }
    });
    this.isEmbed = this.cartUtilities.isEmbed();
    this.isCMSConfigured = this._featureSwitch.IsCMSConfigured;
    this.isExternalPMSIntegrated = this.localization.GetExternalPMSIntegrated();
    const jsonPropConfig = sessionStorage.getItem('propConfig');
    this.pmsSystem = jsonPropConfig ? JSON.parse(jsonPropConfig)?.PMSSystem : null;
    this.selectedClientSearchType = clientSearchType.firstName;
    let propConfig = JSON.parse(sessionStorage.getItem("propConfig")); 
    this.pmsSystemValue = propConfig?.PMSSystem ? propConfig?.PMSSystem :null;;
    this.pmsSystem = this.pmsSystemValue ? true : false;
    this.appointmentservice.isInternalPMSIntegrated = this.pmsSystemValue?.toLowerCase() == InternalPMS.LMS.toLowerCase() || this.pmsSystemValue?.toLowerCase() == InternalPMS.Stay.toLowerCase()? true : false;
    //this.getClientSearchTypes();
  }
  
  async ngOnInit() {
    this.clientSearchForm = this.Form.group({
      isExternalSearch: this.Form.control(''),
      isPlatformGuestSearch:this.Form.control('')
    });
    this.platFormsearchDebounce
    .pipe(debounceTime(700), distinctUntilChanged())
    .subscribe(($event) => {
      console.log("$event.searchValue",$event.searchValue);
      this.makeSearchCall($event.searchValue, this.selectedClientSearchType, $event.requestUid)
      
    });
    this.findRelationshipDebounce
    .pipe(debounceTime(700), takeUntil(this.destroyed$))
    .subscribe(() => this.openFindRelationship());

    this.platFormExtendedSearchRequired = this.localization.IsPlatformGuestSearchConfigured();
    this.showExternalSearch = this.localization.IsExternalGuestSearchConfigured();
    if( this.showExternalSearch && !this.appointmentservice.isEditClient && !this.appointmentservice.add_client)
    {
      this.appointmentservice.isExternalSearch = true;
      this.clientSearchForm.controls.isExternalSearch.setValue(true);
      this.clientSearchForm.controls.isExternalSearch.updateValueAndValidity();
    }
    this.externalSearch = this.localization.ShowExternalGuestSearch();
    this.appointmentservice.isExternalIntergrated = (this.externalSearch || this.showExternalSearch) ? true : false;
    await this.getClientSearchTypes();
    this.isEditAppointment = this.appointmentservice.isEditAppointment;
    this.ItinerarySubscriber = this.appointmentservice.PMSClientItineraryData.subscribe(
      x => {
        if (x) {
          x.start = this.localization.LocalizeDate(x.start);
          x.end = this.localization.LocalizeDate(x.end);
        }
        this.ItineraryData = x;
      }
    );
    if (!this.fromPackage) {
      await this.GetClientTypes();
      if(this.appointmentservice.isLoadedfromPMS)
      {
        this.clientswitchvalue = ClientType.HotelReservation
      }
      await this.clientTypeChange(this.clientswitchvalue);
    } else {
      let clientswitchvalue = this.appointmentservice.clientTypes.find(t => t.checked == true);
      this.setSearchText(clientswitchvalue?.id);
    }
    await this.getClientSearchTypes();
    let clientSearchswitchvalue = this.clientSearchTypes.find(t => t.checked == true);
    this.setSearchText(clientSearchswitchvalue?.id);
    this.appointmentservice.multipleRecords = false;
    const _that = this;
    this.imgSubscription = this.appointmentservice.clientImageObj.subscribe(x => {
      const imageObj = x;
      if (imageObj && imageObj.length > 0 && this.appointmentservice.guestId == imageObj[0].imageReferenceId) {
        _that.imageContent = imageObj[0];
        _that.appointmentservice.clientImageUrl = _that.imageContent.contentType && _that.imageContent.data ?
          `data:${imageObj[0].contentType};base64,${imageObj[0].data}` :
          '';
        let temp = _that.appointmentservice.imageArray.find(arr => {
          return arr.referenceId === _that.imageContent.referenceId && arr.referenceType === _that.imageContent.referenceType;
        });
        if (_that.appointmentservice.imageArray.length === 0 || !temp) {
          _that.appointmentservice.imageArray.push(imageObj[0]);
        }
      } else {
        _that.imageContent = {};
      }
    });
    if (this.appointmentservice.recordsArray && this.appointmentservice.recordsArray.length > 0) {
      this.GetMultiPacksForClients(this.appointmentservice.recordsArray.map(x => x.clientDetail.id));
    }

    if (this.appointmentservice.resultExistingAppointment && this.ClientAddedEvent) {
      this.appointmentservice.multipleRecords = this.appointmentservice.resultExistingClients.length > 1;
      for (let i = 0; i < this.appointmentservice.resultExistingClients.length; i++) {
        this.ClientAddedEvent.emit(this.appointmentservice.resultExistingClients[i]);
      }

    } else if (this.appointmentservice.multiClientInfo && this.appointmentservice.multiClientInfo.length > 0) {
      for (let i = 0; i < this.appointmentservice.multiClientInfo.length; i++) {
        this.ClientAddedEvent.emit(this.appointmentservice.multiClientInfo[i].id);
      }
    }
    else if(this.appointmentservice.dayPassClientIds && this.appointmentservice.dayPassClientIds.length>0){
      for (let i = 0; i < this.appointmentservice.dayPassClientIds.length; i++) {
        this.ClientAddedEvent.emit(this.appointmentservice.dayPassClientIds[i]);
      }
    }

    this.appointmentservice.clientInformationForm = this.clientForm;
    if (this.appointmentservice.fromClientModule) // For Setting up Appointment's Guest frome external module
    {
      let unassignedTherapistId = 0;
      this.appointmentservice.multiClientInfo = [];
      if (!this.appointmentservice.resultExistingAppointment) // For Setting up temphold only for create appointment scenario
      {
        this.appointmentservice.TempHoldArr = [];
        this.appointmentServiceObject.tempRecordCreated = true;
        this.appointmentservice.tempHoldValues.startTime = this.PropertyInfo.CurrentDTTM;
        this.appointmentservice.tempHoldValues.endTime = this.PropertyInfo.CurrentDTTM;
      }
      this.appointmentservice.multipleRecords = this.appointmentservice.recordsArray.length > 1;
      for (let i = 0; i < this.appointmentservice.recordsArray.length; i++) {
        let clientDataArray: any = [];
        clientDataArray.push(this.appointmentservice.recordsArray[i]);
        this.appointmentservice.clientVipType = this.appointmentservice.GetVipTypeName(this.appointmentservice.recordsArray[i].clientDetail.vip);
        this.appointmentservice.multiClientInfo.push({ "id": this.appointmentservice.recordsArray[i].clientDetail.id, "comments": "", "TherapistId": unassignedTherapistId, "priceTypeId": this.appointmentservice.recordsArray[i].clientDetail.priceTypeId, "price": 0, "discountType": this.appointmentservice.recordsArray[i].clientDetail?.discountType ? this.appointmentservice.recordsArray[i].clientDetail.discountType : "" });
        await this.clientSelected(clientDataArray);
      }
      if (this.appointmentservice.recordsArray.length == 1) {
        this.appointmentservice.getRecentAppointments(this.appointmentservice.recordsArray[0].clientDetail.id);
      }
      this.appointmentservice.updateMultiClientInfoPriceDetails();
      this.appointmentservice.fromClientModule = false;
    }
    else if (this.appointmentservice.addFromClientModule) {
      this.openAddClient('NEW', this.appointmentservice.clientId);
    }

    this.IcartDataService.CartItinerarySelected.pipe(takeUntil(this.destroyed$)).subscribe(async x => {
      await this.SelectClientForVcart(CartAction.ACTIVE);
    });
    await this.SelectClientForVcart(CartAction.EDITORCREATE);
    if(this.platFormExtendedSearchRequired)
    {
      await this.appointmentservice.GetExtendedProfileSearchSetting();
      if(this.appointmentservice.ExtendedSearchConfiguration[0].value == "true"){
      this.clientSearchForm.controls.isPlatformGuestSearch.setValue(true);
      this.isPlatformGuestSearch=true;
      if(this.isPlatformGuestSearch){
        this.appointmentservice.isExternalSearch = false;
        this.clientSearchForm.controls.isExternalSearch.setValue(false);
         this.clientSearchForm.controls.isExternalSearch.updateValueAndValidity();
       }
      } 
    }
    this.memberConfiguration = JSON.parse(sessionStorage.getItem('memberConfiguration'));
  }

  async clientTypeChange(id) {
    this.appointmentservice.selectedClientType = id;
    this.clientType = id;
    await this.getClientSearchTypes();
    switch (id) {
      case ClientType.Guest:
        this.clientInformationDynamicHeight = 'calc( 100% - 140px)';
        this.hideCartGuests = false;
        this.clientType = ClientType.Guest;
        this.clientSearchTypeChange(this.defaultClientSearchValue);
        break;
      case ClientType.Member:
        this.clientInformationDynamicHeight = 'auto';
        this.hideCartGuests = true;
        this.clientType = ClientType.Member;
        this.clearSearchText = !this.clearSearchText;
        break;
      case ClientType.HotelReservation:
        this.clientInformationDynamicHeight = 'calc( 100% - 160px)';
        this.hideCartGuests = true;
        this.clientType = ClientType.HotelReservation;
          var externalSearchSelectedValue =this.appointmentservice.hotelReservationTypes.filter(a=>a.checked==true);
          this.hotelReservationTypeChange(this.defaulthotelresvvalue==1 || this.defaulthotelresvvalue==2 ||this.defaulthotelresvvalue==0?this.defaulthotelresvvalue:externalSearchSelectedValue[0].id);
        break;
      default:
        break;
    }
    this.SetClientTypeValue(id);
  }

  clientSearchTypeChange(id) {
    this.clearSearchTextValue();
    this.clientSearchChange(id);
    this.selectedClientSearchType = id;
  }

  clientSearchChange(clientSearchswitchvalue) {
    this.clientSearchTypes.forEach(c => c.checked == false);
    if (this.clientSearchTypes.find(c => c.id == clientSearchswitchvalue))
    {
      this.clientSearchTypes.find(c => c.id == clientSearchswitchvalue).checked = true
    }
    else {
      this.clientSearchTypes[0].checked = true;
      clientSearchswitchvalue = this.clientSearchTypes[0].id
    }
    switch (clientSearchswitchvalue) {
      case clientSearchType.firstName:
        this.selectedClientSearchType = clientSearchType.firstName;
        this.existingClientPlaceholder = this.localization.captions.searchByFirstName;
        break;
      case clientSearchType.lastName:
        this.selectedClientSearchType = clientSearchType.lastName;
        this.existingClientPlaceholder = this.localization.captions.searchByLastName;
        break;
      case clientSearchType.name:
        this.selectedClientSearchType = clientSearchType.name;
        this.existingClientPlaceholder = this.localization.captions.searchByFullName;
        break;
      case clientSearchType.phone:
        this.selectedClientSearchType = clientSearchType.phone;
        this.existingClientPlaceholder = this.localization.captions.searchByPhoneNumber;
        break;
      case clientSearchType.email:
        this.selectedClientSearchType = clientSearchType.email;
        this.existingClientPlaceholder = this.localization.captions.searchByEmail;
        break;
      default:
        this.selectedClientSearchType = clientSearchType.firstName;
        this.existingClientPlaceholder = this.localization.captions.searchByFirstName;
        break;
    }
  }


  clearSearchTextValue() {
    this.searchText = '';
    this.clearSearchText = !this.clearSearchText;
  }


  hotelReservationTypeChange(id) {
    switch (id) {
      case 1: {
        this.hotelReservChipPlaceholder = this.localization.captions.searchByConfirmation;
        break;
      }
      case 2: {
        this.hotelReservChipPlaceholder = this.localization.captions.searchByRoomNumber;
        break;
      }
      case 0: {
        this.hotelReservChipPlaceholder = this.localization.captions.searchByGuestName;
        break;
      }
      case 3 && !this.appointmentservice.isExternalSearch && !this.appointmentservice.isInternalPMSIntegrated: {
        this.hotelReservChipPlaceholder = this.localization.captions.searchByLinkCode;
        break;
      }
      case 4 && !this.appointmentservice.isExternalSearch: {
        this.hotelReservChipPlaceholder = this.localization.captions.searchByContactDetail;
        break;
      }
     default:
      this.hotelReservChipPlaceholder = this.localization.captions.searchByConfirmation;
      break;
    }
    this.clearSearchText = !this.clearSearchText;
  }



  setStatusColors() {
    this.nsBDRclr = this.utils.getBorderColor('NOSHOW');
    this.closeBDRclr = this.utils.getBorderColor('CLOSED');
    this.checkinBDRclr = this.utils.getBorderColor('CKIN');
    this.checkoutBDRclr = this.utils.getBorderColor('CKOUT');
    this.sBDRclr = this.utils.getBorderColor('RESV');
  }

  private async SelectClientForVcart(action: CartAction) {
    if (!this.cartUtilities?.isEmbed())
      return;
    let guestInformation = this.cartUtilities.getCartGuests();
    this.cartGuests = guestInformation?.map(x => {
      return {
        id: 0,
        viewValue: x.firstName + '  ' + x.lastName,
        value: x.guestId,
        disabled: false
      }
    });
    this.SetClientTypeValue(ClientType.Guest);
    switch (action) {
      case CartAction.ACTIVE:
        if (this.appointmentservice?.recordsArray?.length == 0) {
          this.cartGuest = [this.cartGuests[0]];
          let guestId = guestInformation[0].guestId;
          await this.setClientInfobyGuid(guestId);
        } else if (this.appointmentservice?.existingAppointmentsRef?.length > 0) {
          let guestIds = this.GetAppointmentClientInfosForVcart(); //To mark Check the Cart Guest who matches with appointment guest
          this.cartGuest = this.cartGuests.filter(x => guestIds.map(y => y.guestId).includes(x.value));
          this.cartGuests.forEach(guest => {
            let spaGuest = guestIds.find(x => x.guestId == guest.value);
            if (spaGuest)
              guest.clientId = spaGuest['clientId']
          })
          this.cartGuest = [...this.cartGuest];
        }
        break;
      case CartAction.EDITORCREATE:
        if (this.appointmentservice?.existingAppointmentsRef?.length > 0) {
          let guestIds = this.GetAppointmentClientInfosForVcart(); //To mark Check the Cart Guest who matches with appointment guest
          this.cartGuest = this.cartGuests.filter(x => guestIds.map(y => y.guestId).includes(x.value));
          this.cartGuests.forEach(guest => {
            let spaGuest = guestIds.find(x => x.guestId == guest.value);
            if (spaGuest)
              guest.clientId = spaGuest['clientId']
          })
          this.cartGuest = [...this.cartGuest];
        }
        else if (this.appointmentservice.existingAppointmentsRef == undefined || this.appointmentservice.existingAppointmentsRef == null && this.cartGuests?.length > 0) {
          this.cartGuest = [this.cartGuests[0]];
          let guestId = guestInformation[0]?.guestId;
          // Client Guest Type default for Vcart - Phase 1: TBD for future scope
          await this.setClientInfobyGuid(guestId);
          console.log("this.cartGuest", this.cartGuest);
        }
        break;
    }
  }
  private GetAppointmentClientInfosForVcart(): any[] {
    var guestIds: any = [];
    this.appointmentservice?.existingAppointmentsRef?.forEach((appointment, index, collection) => {
      if (this.appointmentservice.existingAppointmentsRef.length > 0)
        guestIds.push({ 'guestId': appointment.appointmentDetail['guestId'], 'clientId': appointment.appointmentDetail['clientId'] });
    });
    return guestIds = uniqBy(guestIds, 'guestId');
  }
  async setClientInfo(event: string, clientDetails: any[]) {
    let unassignedTherapistId = 0;   // included to make a validation during create new appointment
    this.appointmentservice.isClientCompleted = false;
    if (this.appointmentservice.clientTypes[2].checked == true) {
      clientDetails = clientDetails.filter(client => client.clientDetail.clientLinkId === event);
    } else {
      if (this.cartUtilities.isEmbed()) {
        this.cartGuests.forEach(guest => {
          if (guest.value == event)
            guest.clientId = clientDetails.find(client => client.clientDetail.guestId == event).id;
        });
      }
      clientDetails = clientDetails.filter(client => client.clientDetail.guestId === event);
    }

    let scheduleDt = new Date();
    let memberInfo = clientDetails && clientDetails.length > 0 && clientDetails[0].clientDetail.clientLinkId != '' && clientDetails[0].clientDetail.clientLinkId != null ? await this.appointmentservice.getMemberInfo(clientDetails[0].clientDetail.clientLinkId, scheduleDt.toISOString(), clientDetails[0].clientDetail.corpId) : null;
    if (memberInfo != null && memberInfo.membershipStatus?.toLowerCase() != 'active' && memberInfo.cardStatus?.toLowerCase() != 'active') {
      return;
    } 
    if (clientDetails && clientDetails.length > 0) {
      var clientData: ClientLabel = {
        Id: clientDetails[0].clientDetail.id,
        FirstName: clientDetails[0].clientDetail.firstName,
        LastName: clientDetails[0].clientDetail.lastName,
        clientType: clientDetails[0].clientDetail.clientType
      };
      clientData = clientData ? clientData : { Id: 0, FirstName: '', LastName: '', clientType: 0, };
      this.appointmentservice.labelRecords.push(clientData);
    }

    this.appointmentservice.getRecentAppointments(clientDetails[0]?.clientDetail.id);
    this.appointmentservice.recordsArray.push(clientDetails[0] ?? {
      Id: clientDetails[0].clientDetail.id,
      FirstName: clientDetails[0].clientDetail.firstName,
      LastName: clientDetails[0].clientDetail.lastName,
      clientType: clientDetails[0].clientDetail.clientType
    });

    let priceTypeId = clientDetails[0]?.clientDetail.priceTypeId;
    if (this.appointmentservice.clientInfoPriceType && memberInfo && memberInfo.priceType) {
      let selectedPriceTypeObject = this.appointmentservice.clientInfoPriceType.find(x => x.code.toLowerCase() == memberInfo.priceType.toLowerCase())
      if (selectedPriceTypeObject) {
        priceTypeId = selectedPriceTypeObject.priceTypeId;
      }

    }
    const selectedPriceType = this.appointmentservice.selectedServicePriceTypes.find(x => x.priceTypeId === priceTypeId)
    const selectedDiscountType = memberInfo ? memberInfo.discountType : "";
    this.appointmentservice.clientVipType = this.appointmentservice.GetVipTypeName(clientDetails[0]?.clientDetail.vip);
    this.appointmentservice.multiClientInfo.push({ "id": clientDetails[0]?.clientDetail.id, "comments": "", "TherapistId": unassignedTherapistId, "priceTypeId": priceTypeId, "price": selectedPriceType ? selectedPriceType.price : 0, "discountType": selectedDiscountType ? selectedDiscountType : ''});
    this.appointmentservice.isPriceTypeApplicable();
    this.appointmentservice.activeBorderId = 0;
    if (this.appointmentservice.labelRecords.length > 1) {
      this.appointmentservice.multipleRecords = true;
      this.appointmentservice.showDetails = false;
    } else {
      this.appointmentservice.multipleRecords = false;
      this.appointmentservice.showDetails = true;
      this.appointmentservice.clientId = clientDetails[0]?.clientDetail.id;
      this.appointmentservice.guestId = clientDetails[0]?.clientDetail.guestId;
    }
    this.appointmentservice.clientsearchArray = [];
    if (this.appointmentservice.labelRecords.length > 0) {
      this.appointmentservice.isClientCompleted = true;
    }

  }


  async setClientInfobyGuid(guestId: string) {
    if (guestId && guestId.length > 0) {
      let clientInfo: BaseResponse<any> = await this.http.CallApiAsync({
        host: Host.spaManagement,
        callDesc: "getClientInfoByGuid",
        method: HttpMethod.Get,
        uriParams: { guid: guestId },
        showError: false,
      });
      if (clientInfo.result.clientDetail.id == 0) {
        clientInfo = await this.http.CallApiAsync({
          host: Host.spaManagement,
          callDesc: "createClientByGuestId",
          method: HttpMethod.Put,
          body: clientInfo.result,
          uriParams: { guid: clientInfo.result.clientDetail.guestId },
        });
      }
      this.utils.ToggleLoader(true);
      await this.setClientInfo(guestId, [clientInfo.result]);
      this.clientSelected([clientInfo.result]);
    }
    else {
      //this.utils.ToggleLoader(true);
      //await this.setClientInfo('', []);
      //this.clientSelected({});
    }
    if (this.appointmentservice.recordsArray.length >= this.appointmentservice.clientScreenProperties.maxGuest)
      this.cartGuest.forEach(guest => guest.disabled = true);
    // else
    //     this.cartGuest.forEach(guest => guest.disabled = false );
    //this.utils.ToggleLoader(false)
  }

  private async GetClientTypes() {
    if (this.appointmentservice.isLoadedfromPMS) {
      this.appointmentservice.isLoadedfromPMS = this.appointmentservice.labelRecords && this.appointmentservice.labelRecords.length > 0;
    }

    if (this.appointmentservice.isLoadedfromPMS) {
      this.appointmentservice.clientTypes[0].checked = false;
      this.appointmentservice.clientTypes[1].checked = true;
      this.appointmentservice.clientTypes[2].checked = false;
      this.hotelReservationTypeChange(this.defaulthotelresvvalue);
    }
    else {
      let defaultvalues = await this.appointmentservice.GetDefaultClientCategory();
      let defaultclientcategory = defaultvalues.result[0];
      let defaulthotelreservation = defaultvalues.result[1];
      let defaultexistingclient = defaultvalues.result[2];
      this.clientswitchvalue = defaultclientcategory.value != "" ? (Number)(defaultclientcategory.value) : 0;
      this.defaulthotelresvvalue = defaulthotelreservation.value != "" ? (Number)(defaulthotelreservation.value) : 1;
      this.appointmentservice.defaulthotelresvvalue = this.defaulthotelresvvalue;
      this.defaultClientSearchValue = defaultexistingclient!="" ? (Number)(defaultexistingclient.value) : 0;
      this.appointmentservice.clientTypes = [{ id: 0, name: this.localization.captions.existingClients, checked: true }, { id: 3, name: this.localization.captions.hotelReservation, checked: false }, { id: 2, name: this.localization.captions.members, checked: false }];

      this.SetClientTypeValue(this.clientswitchvalue);
    }
  }

  SetClientTypeValue(clientswitchvalue) {
    switch (clientswitchvalue) {
      case ClientType.Guest:
        this.appointmentservice.clientTypes[0].checked = true;
        this.appointmentservice.clientTypes[1].checked = false;
        this.appointmentservice.clientTypes[2].checked = false;
        this.appointmentservice.selectedClientType = ClientType.Guest;
        this.searchTextPlaceHolder = this.localization.captions.searchByClient;
        this.clientSearchChange(this.defaultClientSearchValue);
        break;
      case ClientType.Member:
        this.appointmentservice.clientTypes[0].checked = false;
        this.appointmentservice.clientTypes[1].checked = false;
        this.appointmentservice.clientTypes[2].checked = true;
        this.appointmentservice.selectedClientType = ClientType.Member;
        this.searchTextPlaceHolder = this.memberConfiguration.isExternalMembershipEnabled ? this.localization.captions.searchByMemberNum :this.localization.captions.searchByMember;
        break;
      case ClientType.HotelReservation:
        this.appointmentservice.clientTypes[0].checked = false;
        this.appointmentservice.clientTypes[1].checked = true;
        this.appointmentservice.clientTypes[2].checked = false;
        this.appointmentservice.selectedClientType = ClientType.HotelReservation;
        this.hotelReservationTypeChange(this.defaulthotelresvvalue);
        break;
      default:
          this.appointmentservice.selectedClientType = ClientType.Guest;
          this.searchTextPlaceHolder = this.localization.captions.searchByClient;
        break;
    }
  }

  setSearchText(clientSearchswitchvalue) {
    switch (clientSearchswitchvalue) {
      case clientSearchType.firstName:
        this.existingClientPlaceholder = this.localization.captions.searchByFirstName;
        break;
      case clientSearchType.lastName:
        this.existingClientPlaceholder = this.localization.captions.searchByLastName;
        break;
      case clientSearchType.name:
        this.existingClientPlaceholder = this.localization.captions.searchByFullName;
        break;  
      case clientSearchType.phone:
        this.existingClientPlaceholder = this.localization.captions.searchByPhoneNumber;
        break;
      case clientSearchType.email:
        this.existingClientPlaceholder = this.localization.captions.searchByEmail;
        break;
      default:
        this.existingClientPlaceholder = this.localization.captions.searchByFirstName;
        break;
    }
  }


  getDate(dateField: any) {
    if (dateField) {
      return this.localization.LocalizeDate(dateField);
    }
    return "-";
  }

  getCurrency(value: any) {
    if (value) {
      return this.localization.localizeCurrency(value);
    }
    return "-";
  }


  ngOnChanges() {
    if (this.activateClient && !this.clientactivated) {
      this.ItineraryData = null;
      this.activeBorder(0, this.activateClient);
      this.clientactivated = true;
    }
  }

  ngOnDestroy(): void {
    if (this.appointmentservice.isFromLostDeniedBusiness || this.appointmentservice.isFromWaitlist || this.appointmentservice.isLocationBlock)
      return;
    this.appointmentservice.clientComments = '';
    this.appointmentservice.clientVipType = '';
    this.appointmentservice.clientVipType = '';
    this.appointmentservice.clientScreenProperties['maxGuest'] = 1;
    this.appointmentservice.isRadioDisable = false;
    this.appointmentservice.clientsearchArray = [];
    if (!this.appointmentServiceObject.isAppointmentFromPackage) {
      this.appointmentservice.imageArray = [];
    }
    this.imgSubscription.unsubscribe();
    this.destroyed$.next(true);
    this.destroyed$.complete();
    this.ItinerarySubscriber.unsubscribe();
    this.appointmentservice.isFromLostDeniedBusiness = false;
    this.appointmentservice.isFromWaitlist = false;
    this.appointmentservice.clientScreenProperties.minGuest = 0;
    this.appointmentservice.clientScreenProperties.maxGuest = 1;
    this.appointmentservice.clientScreenProperties.selectedStaff = null;
    this.appointmentservice.clientScreenProperties.helperText = '';
    this.appointmentservice.currentClient = [];
    this.appointmentservice.guestContact = [];
    this.appointmentservice.relatedClient = [];
  }

  /***
   * @function receiveMessage
   * @input Params <Array>
   * EventEmiter Form the Multi-search Components
   */

  receiveMessage($event) {
    this.clientSearchValue = $event.searchValue;
    if($event.searchValue.length >= 3)
    {
      if (this.validateLocationMaxGuest()) {
        this.makeSearchCall($event.searchValue, this.selectedClientSearchType, $event.requestUid)
      }
    }    
  }

  validateLocationMaxGuest() {
    if (this.appointmentservice.recordsArray && this.appointmentservice.clientScreenProperties.maxGuestLocation && this.appointmentservice.clientScreenProperties.maxGuestLocation > 0
      && this.appointmentservice.recordsArray.length >= this.appointmentservice.clientScreenProperties.maxGuestLocation) {
      let message = this.captions.LocationMaxGuestClientConflict.interpolate({ location: this.appointmentservice.clientScreenProperties.maxGuestLocation });
      this.utils.showAlert(message, AlertType.Warning, ButtonType.Ok);
      return false;
    }
    return true;

  }

  platFormsearch($event) {
    if (this.validateLocationMaxGuest()) {
    
      this.platFormsearchDebounce.next($event)
    
    }
  }

  makeSearchCall(name: string, searchType: any, requestUid: string) {
    name = name.trim();
    if (name.length > 2) {
    this.appointmentservice.clientsearchArray = [];
    this.appointmentservice.showNodata = false;
    this.appointmentservice.showLoading = true;
    if (this.appointmentservice.selectedClientType == 3) {
      this.requestUid = "";
      let date: Date = this.appointmentservice.resultNewAppointment.dateField;
      const searchParams: StaySearchFilter = {
        reservationSearchCategory: this.appointmentservice.hotelReservationType,
        activityStartDate: this.utils.formatDate(date),
        activityEndDate: this.utils.formatDate(date),
        searchKey: name,
        product: this.pmsSystemValue ? this.pmsSystemValue : ""
      }
      if (!date) {
        //this.utils.showAlert(this.captions.GuaranteeMethod.MissingServiceDate, AlertType.Info);
        this.appointmentservice.showLoading = false;
        this.appointmentservice.clientsearchArray = [];
        this.appointmentservice.showNodata = true;
        return;
      }
      this.http.CallApiWithCallback<any[]>({
        host: Host.spaManagement,
        success: this.successCallback.bind(this),
        error: this.utils.errorCallback.bind(this),
        callDesc: "StayClientSearch",
        method: HttpMethod.Put,
        body: searchParams,
        showError: false,
        extraParams: ['dataBelongTo']
      });
    }
    else {
      this.requestUid = requestUid;
      this.http.CallApiWithCallback<any[]>({
        host: Host.spaManagement,
        success: this.successCallback.bind(this),
        error: this.utils.errorCallback.bind(this),
        callDesc: "extendedClientSearch",
        method: HttpMethod.Get,
        uriParams: { key: encodeURIComponent(name), searchType: searchType, requestUid: requestUid, clientType: this.appointmentservice.selectedClientType, isExternalGuestSearch: this.appointmentservice.isExternalSearch,isPlatformGuestSearch: this.isPlatformGuestSearch },
        showError: false,
        extraParams: ['dataBelongTo']
      });
    }
  }
  }

  GetMultiPacksForClients(clientIds) {
    if (clientIds && clientIds.length > 0) {
      this.appointmentservice.GetMultiPacksForClients(clientIds)
    }
  }


  /***
   * @function successCallback
   * @input Params <Array>
   * Server Call From the Server
   */

  async successCallback<T>(result: BaseResponse<T>, callDesc: string, extraParams?: any[]) {
    switch (callDesc) {
      case "v2GetImagesByReferenceId":
        this.appointmentservice.clientHasPic = result.result[0] ? true : false;
        this.appointmentservice.clientImageChange(result.result);
        this.appointmentServiceObject.clientImageChange(result.result);
        if (this.appointmentservice.clientHasPic) {
          let client = this.appointmentservice.recordsArray.find(x => x.clientDetail.guestId == result.result[0].imageReferenceId);
          if (client) {
            client['imageObj'] = result.result;
          }
          if (!this.appointmentservice.imageArray.find(x => x['imageReferenceId'] === result.result[0].imageReferenceId)) {
            this.appointmentservice.imageArray.push(result.result[0]);
          }
        }
        break;
      case "StayClientSearch":
      case "extendedClientSearch": {
        let searchApiResponse = <any>result.result;
        if (searchApiResponse?.length > 0) {
          this.appointmentservice.showLoading = false;
          var responseUid = "";
          if (searchApiResponse != null && searchApiResponse.length > 0 && this.appointmentservice.selectedClientType != 3)
            responseUid = searchApiResponse[0].requestUid

          if ((this.requestUid != "" && responseUid != "" && this.requestUid == responseUid) || (this.requestUid == "" || responseUid == "")) {
            this.appointmentservice.clientsearchArray = this.checkForClientAlreadyAdded(searchApiResponse);
          }
        }
        else {
          this.appointmentservice.showLoading = false;
          this.appointmentservice.clientsearchArray = [];
          this.appointmentservice.showNodata = true;
        }
      }
        break;
      case "getClientInfo": {
        let clientDetail = <any>result.result;
        this.appointmentservice.isExternalClient = (clientDetail.clientDetail && clientDetail.clientDetail.isExternalGuest)??false;
        this.appointmentservice.isExternalClientLinked = (clientDetail.clientDetail && clientDetail.clientDetail.isExternalGuestLinked)??false;
        this.clientType = clientDetail.clientDetail.clientType;
        //this.SetClientTypeValue(clientDetail.clientDetail.clientType);
        clientDetail.clientDetail.pmsActivityId = this.appointmentservice.pmsActivityId;
        this.appointmentservice.currentClient = clientDetail.length ? clientDetail[0] : clientDetail;
        if (extraParams && extraParams.length > 0 && extraParams[0] == "FromClientSearch") {
          //Get client info for client preferences
          this.appointmentservice.add_client = false;
        } else {
          //edit client scope
          this.appointmentservice.add_client = true;
          this.appointmentservice.currentClient = clientDetail.length ? clientDetail[0] : clientDetail;
          this.appointmentservice.disablePreference = false;
          this.callBack = false;
        }
        if (clientDetail?.clientDetail?.clientType == ClientType.Member)
          this.appointmentservice.memberImageUrl = clientDetail?.clientDetail?.photoUrl;
        else
          this.appointmentservice.memberImageUrl = null;

        this.appointmentservice.currentClient.guestContact = clientDetail.guestContact;
        this.appointmentservice.clientEditData = clientDetail;

        if (!this.appointmentservice.isEditAppointment) {
          this.appointmentservice.setGenderPreference();
        }

        // Set Spa informations for Guestprofile Guest
        let isClientEditFromPackage: boolean = extraParams && extraParams.length > 0 && extraParams[0] == "PackageEdit"
        // Open pop up if the edit call comes from package pop up
        if (isClientEditFromPackage || (this.appointmentservice.isLocationBlock && this.locationBlockEdit)) {
          this.openPopupForClientEdit(clientDetail);
        }
        this.locationBlockEdit = false;
        if (this.callBack) {
          this.appointmentservice.disablePreference = true;

          // let showPopup = (this.appointmentservice.clientEditData && (this.appointmentservice.clientEditData.clientPreferences && this.appointmentservice.clientEditData.clientPreferences.length > 0) ||
          //   (this.appointmentservice.clientEditData.clientDetail && this.appointmentservice.clientEditData.clientDetail.genderPreference))

          let filteredContactLog: any[] = []; //= this.appointmentservice.clientEditData.clientContactLogs;
          this.appointmentservice.clientEditData.clientContactLogs.forEach((contactLog, i) => {
            let productId = parseInt(this.localization.GetPropertyInfo('ProductId'));
            let dueDate = contactLog.dueDate ? this.utils.GetDateWithoutTime(this.utils.getDate(contactLog.dueDate)) : null;
            let expiryDate = contactLog.expiryDate ? this.utils.GetDateWithoutTime(this.utils.getDate(contactLog.expiryDate)) : null;
            let propertyDate = this.utils.GetDateWithoutTime(this.PropertyInfo.CurrentDate);
            if (contactLog.isAlert && dueDate && (expiryDate >= propertyDate || expiryDate == null)) {
              if (contactLog.isPrivate && contactLog.productId == productId && dueDate.toDateString() == propertyDate.toDateString()) {//&& contactLog.productId != 1) || !contactLog.isPrivate
                filteredContactLog.push(contactLog);
              }
              else if (!contactLog.isPrivate && dueDate.toDateString() == propertyDate.toDateString()) {
                filteredContactLog.push(contactLog);
              }
            }
            else if (contactLog.isAlert && contactLog.dueDate == null && (expiryDate >= propertyDate || expiryDate == null)) {
              if (contactLog.isPrivate && contactLog.productId == productId) {// && contactLog.productId != 1
                filteredContactLog.push(contactLog);
              }
              else if (!contactLog.isPrivate) {
                filteredContactLog.push(contactLog);
              }
            }
          });
          this.isContactLogShow = filteredContactLog.length > 0;
          this.appointmentservice.clientEditData.clientContactLogs = this.isContactLogShow ? filteredContactLog : [];
          if (this.isContactLogShow) {
            this.loadClientContactLogs(clientDetail);
          }

          //this.isContactLogShow = !this.isContactLogShow ? true : this.isContactLogShow;
          if (!this.isContactLogShow) {
            await this.PrioritizedPopupShow(clientDetail);
            // if (this.appointmentservice.clientEditData.clientDetail.genderPreference == '') {
            //     this.appointmentservice.otherdetails.gender = GenderPreference.none;
            // }
            // let medicalConditionExists: boolean = false;
            // if (!this.isClientEditFromPackage && !this.appointmentservice.add_client) {
            //     if (this.appointmentservice != null && this.appointmentservice.resultNewAppointment != null &&
            //         this.appointmentservice.resultNewAppointment.service != null && this.appointmentservice.resultNewAppointment.service != 0) {
            //         this.appUtils.CheckMedicalWarning(this.appointmentservice.resultNewAppointment.service, [clientDetail.id],
            //             this.successCallback.bind(this), this.utils.errorCallback.bind(this));
            //         medicalConditionExists = true;
            //     }
            // }
            // if (!medicalConditionExists && !this.appointmentservice.add_client) {
            //     await this.SetGuestInfomationsForClient(this.appointmentservice.clientEditData, this.isClientEditFromPackage);
            //     this.loadClientPreference();
            // }
          }
        }
      }
        break;
      case "GetItenerary": {
        let clientIteneraryByDate = <any>result.result;
        this.appointmentservice.resultNewAppointment.timelineData = clientIteneraryByDate;
      }
        break;

      case "GetMedicalWarning": {
        this.appUtils.GetMedicalWarning(<any>result.result, this._vcr, 'client', '', this.MedicalWarningPopUpCallback.bind(this));
        let medicalwarning = <any>result.result;
        if (medicalwarning == undefined || medicalwarning == null || medicalwarning.length == 0 || (medicalwarning.length > 0 && medicalwarning[0].clientId == 0)) {
          await this.SetGuestInfomationsForClient(this.appointmentservice.clientEditData, this.isClientEditFromPackage);
          this.loadClientPreference();
        }
      }
        break;
      case "GetServicePriceBasedOnYield": {
        let price = result.result ? result.result : 0;
        this.editAppointment_UpdatePrice(price, extraParams[0]);
      }
        break;

      case "GetClientBlockById": {
        const response = result.result ? <any>result.result : null;
        this.appointmentservice.ClientBlockInformation = response;
        if (!this.appointmentservice.clientBlockInfos)
          this.appointmentservice.clientBlockInfos = [];
        if (response) {
          this.appointmentservice.clientBlockInfos = this.appointmentservice.clientBlockInfos.filter(y => y.clientId != response.clientId);
          this.appointmentservice.clientBlockInfos.push(response);
        }
      }
        break;
      case "GetClientblockdetails": {
        let clientBlockInfos = result.result ? <any>result.result : [];
        if (!this.appointmentservice.clientBlockInfos)
          this.appointmentservice.clientBlockInfos = [];
        if (clientBlockInfos && clientBlockInfos.length > 0) {

          clientBlockInfos.map(x => {
            this.appointmentservice.clientBlockInfos = this.appointmentservice.clientBlockInfos.filter(y => y.clientId != x.clientId);
            this.appointmentservice.clientBlockInfos.push(x);
          });
          this.isLocalChange = true;
          this.validateClientBlockInformation(clientBlockInfos[0].clientId);
        }

        break;

      }
    }
  }
  //This method wrote beacause of, to show contact log information at first and consecutive popup next next.
  async PrioritizedPopupShow(clientDetail) {
    if (this.appointmentservice.clientEditData.clientDetail.genderPreference == '') {
      this.appointmentservice.otherdetails.gender = GenderPreference.none;
    }
    let medicalConditionExists: boolean = false;
    if (!this.isClientEditFromPackage && !this.appointmentservice.add_client) {
      if (this.appointmentservice != null && this.appointmentservice.resultNewAppointment != null &&
        this.appointmentservice.resultNewAppointment.service != null && this.appointmentservice.resultNewAppointment.service != 0) {
        this.appUtils.CheckMedicalWarning(this.appointmentservice.resultNewAppointment.service, [clientDetail.id],
          this.successCallback.bind(this), this.utils.errorCallback.bind(this));
        medicalConditionExists = true;
      }
    }
    if (!medicalConditionExists && !this.appointmentservice.add_client) {
      await this.SetGuestInfomationsForClient(this.appointmentservice.clientEditData, this.isClientEditFromPackage);
      this.loadClientPreference();
    }
  }

  validateClientBlockInformation(clientId: any) {

    const clientIds = clientId.length ? clientId : [clientId];


    const clientBlock = this.appointmentservice.clientBlockInfos ?
      this.appointmentservice.clientBlockInfos.filter(x => clientIds.find(z => z === x.clientId) && x.blockClient && !x.clientBlockOverrideAccess) : null;

    if (clientBlock && clientBlock.length > 0) {
      this.isLocalChange = true;
      this.poppedClientId = clientBlock.map(x => x.clientId);
      this.utils.showAlert(this.captions.ClientBlockedForAppointment, AlertType.Warning,
        this.appointmentservice.clientBlockOverrideAccess ? ButtonType.Ok : ButtonType.OKOverride, async (res) => {
          if (this.appointmentservice.clientBlockOverrideAccess) {
            this.appointmentservice.clientBlockInfos.map(x => {
              if (x.clientId === clientId) {
                x.clientBlockOverrideAccess = true;
              }
            });
            this.poppedClientId = [];
          }
          else {
            if (res === AlertAction.CONTINUE) {
              this.removeClient(this.poppedClientId);
            }
            else {
              const quickLoginDialogRef = this._quickLoginUtil.QuickLogin({ breakPointNumber: SPAScheduleBreakPoint.overrideClientBlock });
              quickLoginDialogRef.beforeClosed().pipe(takeUntil(this.destroyed$)).subscribe(async (quickLoginDialogResult) => {
                console.log("quickLoginDialogResult");
              });
              quickLoginDialogRef.afterClosed().pipe(takeUntil(this.destroyed$)).subscribe(async (quickLoginDialogResult: QuickLoginDialogResult) => {

                if (quickLoginDialogResult.isLoggedIn) {
                  this.appointmentservice.clientBlockInfos.map(x => {
                    if (x.clientId === clientId) {
                      x.clientBlockOverrideAccess = true;
                    }
                  });
                  this.poppedClientId = [];
                }
                else {
                  this.removeClient(this.poppedClientId);
                }
              });
            }

          }
        })
    }





  }

  removeClient(clientIds: any) {
    for (let clientId of clientIds) {
      if (this.appointmentservice.recordsArray && this.appointmentservice.recordsArray.length > 0) {
        this.appointmentservice.labelRecords = this.appointmentservice.labelRecords.filter(r => r.Id != clientId);
        this.appointmentservice.recordsArray = this.appointmentservice.recordsArray.filter(r => r.clientDetail.id != clientId);
        this.appointmentservice.clientBlockInfos = this.appointmentservice.clientBlockInfos.filter(r => r.clientId != clientId);
        this.isLocalChange = true;
      }
    }
    if (this.appointmentservice.selectedIndex != 1) {
      this.appointmentservice.selectedIndex = 1;
    }
    this.appointmentservice.disableRadioButton(this.appointmentServiceObject.isAppointmentFromPackage, this.appointmentServiceObject.PackageMaxPeople);
  }

  editAppointment_UpdatePrice(price, clientId) {
    this.appointmentservice.multiClientInfo.forEach(element => {
      if (element.id == clientId) {
        element.price = price;
      }
    });
  }

  async MedicalWarningPopUpCallback(result: any, extraParams: any[]) {
    if (result && result.popupCloseInfo.toLowerCase() == ButtonOptions.No.toLowerCase() && result && result.clientId > 0) {
      this.appointmentservice.recordsArray = this.appointmentservice.recordsArray.filter(r => r.clientDetail.id != result.clientId);
      this.appointmentservice.multiClientInfo = this.appointmentservice.multiClientInfo.filter(r => r.id != result.clientId);
      this.appointmentservice.labelRecords = this.appointmentservice.labelRecords.filter(r => r.Id != result.clientId);
      this.appointmentservice.clientGuestMap = this.appointmentservice.clientGuestMap.filter(r => r.ClientId != result.clientId);
      this.appointmentservice.clientsearchArray = this.appointmentservice.clientsearchArray.filter(r => r.id != result.clientId);
      this.UpdateTemphold(result.clientId);
    }
    else {
      await this.SetGuestInfomationsForClient(this.appointmentservice.clientEditData, this.isClientEditFromPackage);
      this.loadClientPreference();
    }
  }

  async SetGuestInfomationsForClient(clientDetail, packageEdit: boolean = false) {
    if (clientDetail.clientDetail.clientType != ClientType.Member) {
      await this.clientService.GetClientMandatoryandCustomFields();
      let isAllMandatoryFieldsFilled =await this.clientService.MandatoryFieldsValidation(clientDetail) //extraParams[1]
      if (!isAllMandatoryFieldsFilled) {
        let extraParams = { "clientDetail": clientDetail, "packageEdit": packageEdit }
        this.utils.ShowErrorMessage(this.captions.ErrorinCreatingAppointment, this.localization.replacePlaceholders(this.localization.getError(100005), ["clientName",], [clientDetail.clientDetail.firstName + ' ' + clientDetail.clientDetail.lastName]), ButtonType.Ok, this.SetGuestInformationCallback.bind(this), extraParams);
      }
    }
  }

  SetGuestInformationCallback(response: string, extraParams) {
    if (response.toLowerCase() == ButtonOptions.Ok.toLowerCase()) {
      this.appointmentservice.clientEditData = extraParams.clientDetail;
      this.appointmentservice.popupTitle = this.captions.EditClient;
      this.appointmentservice.clientWidowActionType = "EDIT";
      this.appointmentservice.isEditClient = true;
      this.appointmentservice.isExternalClient = this.appointmentservice.clientEditData?.clientDetail?.isExternalGuest;
      this.appointmentservice.isExternalClientLinked = this.appointmentservice.clientEditData?.clientDetail?.isExternalGuestLinked;
      this.appointmentservice.saveText = this.allCaptions.common.Update;
      this.appointmentservice.add_client = true;
      this.appointmentservice.checkwhetherfromClient = true;
      if ((this.appointmentServiceObject.isAppointmentFromPackage && !extraParams.packageEdit) || this.appointmentServiceObject.isFromLocationBlock) {
        this.appointmentservice.editClientFromPackage = true;
        this.openPopupForClientEdit(extraParams.clientDetail);
      }
      else {
        this.appointmentservice.disablePreference = false;
        this.callBack = false;
      }
    }
  }

  openPopupForClientEdit(clientDetail?) {

    this.appointmentservice.isExternalClient = (clientDetail?.isExternalGuest)??false;
    this.appointmentservice.isExternalClientLinked = (clientDetail?.isExternalGuestLinked)??false;
    
    let data = {
      closebool: true,
      action: 'Edit',
      clientInfo: clientDetail,
      fromPackage: true
    };
    let dialogRef = this.dialog.open(AppointmentPopupComponent, {
      width: '85%',
      height: '85%',
      disableClose: true,
      hasBackdrop: true,
      data: data
    });
    dialogRef.afterClosed().pipe(takeUntil(this.destroyed$)).subscribe(res => {
      this.appointmentservice.editClientFromPackage = false;
      if (this.ClientAddedEvent) {
        this.ClientAddedEvent.emit(res);
      }
      if (this.activateClient) {
        this.loadClientBlockInformation([this.activateClient]);
      }


    });
  }


  loadClientPreference() {
    let showPopup = (this.appointmentservice.clientEditData && (this.appointmentservice.clientEditData.clientPreferences && this.appointmentservice.clientEditData.clientPreferences.length > 0) ||
      (this.appointmentservice.clientEditData.clientDetail && this.appointmentservice.clientEditData.clientDetail.genderPreference))
    if (this.appointmentservice.clientEditData.clientDetail.genderPreference == '') {
      this.appointmentservice.otherdetails.gender = GenderPreference.none;
    }
    if (showPopup && this.appointmentservice.hasAccessForClientPreference) {
      this.appointmentservice.add_client = false;
      this.openClientPreference();
    }
  }

  loadClientContactLogs(clientDetail) {
    let showPopup = (this.appointmentservice.clientEditData && (this.appointmentservice.clientEditData.clientContactLogs && this.appointmentservice.clientEditData.clientContactLogs.length > 0))
    if (showPopup) {
      this.appointmentservice.add_client = false;
      this.openClientContactLogs(clientDetail);
    }
  }

  openClientPreference() {
    this.appointmentservice.disablePreference = true;
    this.appointmentservice.clientWidowActionType = "EDIT";
    let data;
    data = { headername: this.captions.KindInformation, headerIcon: 'icon-info-icon', buttonName: this.localization.captions.common.OK, type: 'CP' }

    const dialogRef = this.dialog.open(AlertMessagePopupComponent, {
      width: '60%',
      height: '80%',
      hasBackdrop: true,
      panelClass: 'small-popup',
      data: data,
      disableClose: true
    });
    dialogRef.afterClosed().pipe(takeUntil(this.destroyed$)).subscribe(() => {
      this.appointmentservice.disablePreference = false;
    })
  }

  openClientContactLogs(clientDetail) {
    this.chipdataInput['dataInput'].nativeElement.blur();
    //this.appointmentservice.disablePreference = true;
    this.appointmentservice.clientWidowActionType = "EDIT";
    let data;
    data = { headername: this.captions.KindInformation, headerIcon: 'icon-info-icon', buttonName: this.localization.captions.common.OK, type: 'CL', isPurged: this.appointmentservice.clientEditData?.clientDetail?.isPurged, contactLogs: this.appointmentservice.clientEditData.clientContactLogs }

    const dialogRef = this.dialog.open(AlertMessagePopupComponent, {
      width: '60%',
      height: '80%',
      hasBackdrop: true,
      panelClass: 'small-popup',
      data: data,
      disableClose: true
    });
    dialogRef.afterClosed().pipe(takeUntil(this.destroyed$)).subscribe(async () => {
      this.isContactLogShow = false;
      this.PrioritizedPopupShow(clientDetail);
      // if (this.appointmentservice.clientEditData.clientDetail.genderPreference == '') {
      //     this.appointmentservice.otherdetails.gender = GenderPreference.none;
      // }
      // let medicalConditionExists: boolean = false;
      // if (!this.isClientEditFromPackage && !this.appointmentservice.add_client) {
      //     if (this.appointmentservice != null && this.appointmentservice.resultNewAppointment != null &&
      //         this.appointmentservice.resultNewAppointment.service != null && this.appointmentservice.resultNewAppointment.service != 0) {
      //         this.appUtils.CheckMedicalWarning(this.appointmentservice.resultNewAppointment.service, [clientDetail.id],
      //             this.successCallback.bind(this), this.utils.errorCallback.bind(this));
      //         medicalConditionExists = true;
      //     }
      // }
      // if (!medicalConditionExists && !this.appointmentservice.add_client) {
      //     await this.SetGuestInfomationsForClient(this.appointmentservice.clientEditData, this.isClientEditFromPackage);
      //     this.loadClientPreference();
      // }
      //this.appointmentservice.disablePreference = false;
    })
  }


  /***
  * @function getMail
  * @input Params <Array>
  * Return the Mail From the Mail Array
  */
  getMail(e) {
    if (e.length > 0) {
      let emailId
      emailId = e[0].emailId;
      return emailId;
    }
  }

  /***
  * @function getPhone
  * @input Params <Array>
  * Return the Phone From the Phone Array
  */

  getPhone(p) {
    if (p.length > 0) {
      let phonenumber
      phonenumber = p[0].number;
      return phonenumber;
    }
  }

  getPriceTypeText(PriceTypeId) {
    if (PriceTypeId > 0) {
      let clientPriceType
      if (this.appointmentservice.allPriceTypes.find(x => x.id == PriceTypeId)) {
        clientPriceType = this.appointmentservice.allPriceTypes.find(x => x.id == PriceTypeId).code;
      }
      return clientPriceType;
    }
  }

  changeComment(e) {
    this.appointmentservice.multiClientInfo.forEach(element => {
      if (element.id == this.appointmentservice.clientId) {
        element.comments = e.target.value;
      }
    });
  }

  async changeTherapist(e) {
    this.appointmentservice.multiClientInfo.forEach(element => {
      if (element.id == this.appointmentservice.clientId) {
        element.TherapistId = e.value;
        this.appointmentservice.recordsArray.forEach(x => {
          if(x.clientDetail.id == element.id)
          x.clientDetail['selectedAddOns'] = [];
        })
      }
    });

    //await this.appUtils.CreateTempholdForClientSelected(this.appointmentservice.clientId);
  }

  async setSelectedTherapist() {
    if (this.appointmentServiceObject.isAppointmentFromPackage) {
      if (this.appointmentservice.recordsArray.length == 1) {
        this.selectedTherapist = this.appointmentservice.recordsArray[0].clientDetail;
      }
      if (this.appointmentservice.multiClientInfo && this.appointmentservice.multiClientInfo.length > 0) {
        this.selectedTherapist = this.appointmentservice.multiClientInfo.find(element => element.id == this.appointmentservice.clientId);
      }
      if (this.selectedTherapist == null || this.selectedTherapist == undefined || (this.appointmentservice.multiClientInfo && this.selectedTherapist.id != this.appointmentservice.clientId && this.appointmentservice.labelRecords.length > 1)) {
        this.selectedTherapist = this.appointmentservice.activateClientSelected?.clientDetail ? this.appointmentservice.activateClientSelected : this.appointmentservice.labelRecords[0].Id;
      }
      else {
        this.selectedTherapist = this.appointmentservice.labelRecords[0].Id;
      }
      if ((this.selectedTherapist.id ? this.selectedTherapist.id : this.selectedTherapist) != this.appointmentservice.clientId && this.appointmentservice.labelRecords.length > 1) {
        this.selectedTherapist = this.appointmentservice.currentClient?.clientDetail;
      }
    }
    else if (this.appointmentservice.multiClientInfo && this.appointmentservice.multiClientInfo.length > 0) {
      this.selectedTherapist = this.appointmentservice.multiClientInfo.find(element => element.id == this.appointmentservice.clientId);
      if (this.appointmentservice.recordsArray.length == 1) {
        this.selectedTherapist = this.appointmentservice.recordsArray[0].clientDetail;
      }
      if (this.selectedTherapist == null || this.selectedTherapist == undefined) {
        this.selectedTherapist = this.appointmentservice.labelRecords[0].Id;
      }
      this.selectedTherapistId = this.selectedTherapist ? this.selectedTherapist.TherapistId : 0;
    }

  }

  activeBorder(i, clientinfo) {
    this.appointmentservice.activeBorder(i, clientinfo);
    this.setSelectedTherapist();
  }


  openAddClient(e, id?: number, guestid?: any) {
    if (e == 'NEW' && !this.validateLocationMaxGuest()) {
      return;
    }
    this.toAddClient = this.utils.getRandomDecimal();
    if (this.appointmentservice.popupTitle === this.captions.NEWAPPOINTMENT) {
      this.appointmentservice.checkwhetherfromClient = true;

    }
    else {
      this.appointmentservice.checkwhetherfromClient = false;
    }
    let firstName = "";
    let lastName = "";
    let email : Email[]= [];
    let phone : PhoneNumber[] = [];
    this.appointmentservice.clientWidowActionType = e;
    if (e.toLowerCase() == 'new' && this.breakPoint.CheckForAccess([SPAManagementBreakPoint.AddNewClientProfile])) {
      if (this.searchLimit && this.searchLimit > 0 && this.appointmentservice.recordsArray.length >= this.searchLimit) {
        return;
      }
      if (this.clientSearchValue)
      {
       
        switch(this.selectedClientSearchType)
        {
          case clientSearchType.firstName:
            firstName = this.clientSearchValue;
          break;
          case clientSearchType.lastName:
            lastName = this.clientSearchValue;
            break;
            case clientSearchType.name:
              let name  =  this.clientSearchValue.split(" ");
                        if(name.length > 1)
                        {
                            lastName = name[name.length -1];
                            name.splice(-1, 1);
                            firstName = name.join(' ');
                        }
                        else{
                            firstName = name[0]; 
                        }                                              
                        break;
            case clientSearchType.email:
              let emailsearch : Email = {
                id: 0,
                emailId : this.clientSearchValue,
                contactTypeId: 9,
                clientId: 0,
                isPrimary: false,
                isPrivate: false,
                propertyId: 0,
                subPropertyId: 0,
                platformContactUuid: '',
              };
              email.push(emailsearch);
              break;
              case clientSearchType.phone:
                let number = Number(this.clientSearchValue);
                let phoneSearch: any = {
                    clientId: 0,
                    contactTypeId: 1,
                    extension: null,
                    id: 0,
                    isPrimary: false,
                    isPrivate: false,
                    number:  isNaN(number) ? "" : this.clientSearchValue,
                    platformContactUuid: "00000000-0000-0000-0000-000000000000"
                };
                phone.push(phoneSearch);
              break;

        }      

      }
      this.appointmentservice.popupTitle = this.captions.NewClient;
      this.appointmentservice.add_client = true;
      this.appointmentservice.ClientSelectedTab = 0;
      this.appointmentservice.saveText = this.allCaptions.common.Save;
      this.appointmentservice.clientEditData = null;
      this.appointmentservice.clientNameInfo.firstName = firstName;
      this.appointmentservice.clientNameInfo.lastName = lastName;
      this.appointmentservice.clientNameInfo.email = email;
      this.appointmentservice.clientNameInfo.phone = phone;
      this.appointmentservice.clientPreferences = null;
      this.appointmentservice.clientCreditCardInfo = null;
      this.appointmentservice.ImgTempHolder = {};
      if (!this.isMultipleSearch) {
        let data = {
          closebool: true,
          fromPackage: true,
        };
        let dialogRef = this.dialog.open(AppointmentPopupComponent, {
          width: '85%',
          height: '85%',
          disableClose: true,
          hasBackdrop: true,
          data: data
        });

        dialogRef.afterClosed().pipe(takeUntil(this.destroyed$)).subscribe(res => {
          if (this.ClientAddedEvent)
            this.ClientAddedEvent.emit(res);
          if (this.activateClient) {
            this.loadClientBlockInformation([this.activateClient]);
          }
        });
      }
    }
    else if (e.toLowerCase() == 'edit' && this.breakPoint.CheckForAccess([SPAManagementBreakPoint.EditClientProfile])) {
      const tempClientId = (this.appointmentservice.recordsArray && this.appointmentservice.recordsArray.length > 0 ? this.appointmentservice.recordsArray[0].clientDetail.id : 0);
      let clientId = id ? id : tempClientId;
      const tempGuest = (this.appointmentservice.recordsArray && this.appointmentservice.recordsArray.length > 0) ? this.appointmentservice.recordsArray[0].clientDetail.guestId : DefaultGUID;
      let guestId = guestid && guestid != DefaultGUID ? guestid : tempGuest;
      this.appointmentservice.clientId = _.cloneDeep(clientId);
      this.appointmentservice.guestId = _.cloneDeep(guestId);
      //this.appointmentservice.isEditAppointment = true;
      this.appointmentservice.isEditClient = true;
      if (clientId) {
        if (this.appointmentservice.imageArray && this.appointmentservice.imageArray.length > 0 && this.clientType != ClientType.Member) {
          this.appointmentservice.clientImageChange(this.appointmentservice.imageArray.filter(x => x && x.imageReferenceId == guestId && x.referenceType == ImgRefType.guest));
        }
        else {
          this.imgService.GetImagesByReference(guestId, ImgRefType.client, this.successCallback.bind(this), this.utils.errorCallback.bind(this), [], true);
        }
        this.appointmentservice.popupTitle = this.captions.EditClient;
        this.appointmentservice.clientWidowActionType = "EDIT";
        if (this.appointmentservice.isLocationBlock) {
          this.locationBlockEdit = true;
        }
        this.http.CallApiWithCallback({
          host: Host.spaManagement,
          success: this.successCallback.bind(this),
          error: this.utils.errorCallback.bind(this),
          callDesc: "getClientInfo",
          method: HttpMethod.Get,
          showError: true,
          uriParams: { id: clientId },
          extraParams: [this.appointmentServiceObject.isAppointmentFromPackage ? 'PackageEdit' : '']
        });
      }
    }
  }
  async clientSelected(clientObj) {
    if (clientObj == null) {
      this.utils.ShowErrorMessage(this.localization.captions.common.Warning, this.localization.captions.common.InActiveMember, ButtonType.Ok);
      return;
    }
    this.http.CallApiWithCallback({
      host: Host.spaManagement,
      success: this.successCallback.bind(this),
      error: this.utils.errorCallback.bind(this),
      callDesc: "getClientInfo",
      method: HttpMethod.Get,
      showError: true,
      uriParams: { id: clientObj[0].clientDetail.id },
      extraParams: ["FromClientSearch"]
    });

    this.appointmentservice.currentClient = clientObj.length ? clientObj[0] : clientObj;
    this.appointmentservice.clientCreditCardInfo = this.appointmentservice.currentClient.clientCreditCardInfo;
    this.appointmentservice.disableRadioButton(this.appointmentServiceObject.isAppointmentFromPackage, this.appointmentServiceObject.PackageMaxPeople);
    this.loadClientBlockInformation(clientObj);
    this.clientForm.control.markAsDirty();
    this.appointmentservice.attachClient = false;
    this.selectedClientInfo = this.appointmentservice.recordsArray;
    this.appointmentservice.pmsActivityId = this.appointmentservice.recordsArray[0].clientDetail.pmsActivityId;
    this.appointmentservice.externalPMSConfNo = this.appointmentservice.recordsArray[0].clientDetail.confirmationNumber;
    let lastAddedClient = this.selectedClientInfo[0]
    this.CopyCustomFieldFromClient(clientObj);
    if (this.appointmentservice.recordsArray.length >= 1) {
      let clientFormatedName: string = lastAddedClient.clientDetail.firstName + " " + lastAddedClient.clientDetail.lastName;
      this.appointmentservice.commentPlaceHolder = this.appointmentservice.commentPlaceHolder = this.localization.replacePlaceholders(this.captions.Commentsaddedfor, ['clientName'], [clientFormatedName]);

    } else {
      this.appointmentservice.commentPlaceHolder = ""
    }

    this.appointmentservice.recordsArray.forEach(x => this.appointmentservice.getFormattedPhone(x));
    this.GetMultiPacksForClients(this.selectedClientInfo.map(x => x.clientDetail.id));
    let matchingClientMap = this.appointmentservice.clientGuestMap.filter(x => x.ClientId == clientObj[0].clientDetail.id)
    if (!matchingClientMap || matchingClientMap.length == 0) {
      this.appointmentservice.clientGuestMap.push({ "ClientId": clientObj[0].clientDetail.id, "GuestId": clientObj[0].clientDetail.guestId });
    }

    if (this.cartUtilities.isEmbed())
      this.cartGuest = this.cartGuests.filter(guest => this.appointmentservice.recordsArray.map(x => x.clientDetail.guestId).includes(guest.value));
    if (this.clientType != ClientType.Member)
      await this.imgService.GetImagesByReference(clientObj[0].clientDetail.guestId, ImgRefType.client, this.successCallback.bind(this), this.utils.errorCallback.bind(this), [], true);
    await this.appUtils.CreateTempholdForClientSelected(clientObj[0].clientDetail.id);

    this.callBack = true;
    this.appointmentservice.clientWidowActionType = "EDIT";
    //Open pop up to show the client preferences



    if (!clientObj[0].clientDetail.bypassClientScheduling) {
      this.appointmentservice.GetConflictingActivities(clientObj[0].id);
    }
    if (this.appointmentservice.isEditAppointment) {
      this.appointmentservice.setPriceTypeCodeUI();
    }

    //calculate Yielding price based on the priceType price
    this.ItineraryData = null;
    this.activeBorder(0, this.appointmentservice.recordsArray[0]);
    this.appointmentservice.updatePriceTypeForAppointment(clientObj[0].id);
    /*
    * While editing appointment with priceType and ServiceYielding
    * Adding new client in that appointment should not affect the exisiting one
    */
    if (this.appointmentservice.isEditAppointment) {
      this.appointmentservice.updateClientInfoPriceDetails(clientObj[0].clientDetail);
      this.calculateNewClientYield(clientObj[0].clientDetail);
    }
    else {
      await this.appointmentservice.clientServicePriceTypeCalc(clientObj[0].clientDetail);
    }
    this.appointmentservice.setPriceTypeUIforMutliClient(this.appointmentservice.multiClientInfo[0].id);


    //Added to get CMS Values
    if ((this.isCMSConfigured && clientObj && clientObj[0].clientDetail && clientObj[0].clientDetail.loyaltyDetail && clientObj[0].clientDetail.loyaltyDetail.length > 0) && (clientObj[0].clientDetail.loyaltyDetail[0].patronId != null)) {
      let patronId = clientObj[0].clientDetail.loyaltyDetail[0].patronId;
      if (patronId) {
        let playerInfo: CMSPlayerInfo = await this._cmsBusiness.GetCMSPlayerInfo(patronId);
        if (playerInfo) {
          let cmsHasChange: boolean = false;
          cmsHasChange = this.appointmentservice.isCMSDataChanged(clientObj[0], playerInfo.personalDetails);

          if (cmsHasChange) {
            if ((this.appointmentservice.labelRecords && this.appointmentservice.labelRecords.length > 0) && (clientObj[0].clientDetail.firstName !== playerInfo.personalDetails.firstName ||
              clientObj[0].clientDetail.lastName !== playerInfo.personalDetails.lastName)) {

              let clientName = this.appointmentservice.labelRecords.find(c => c.Id == clientObj[0].clientDetail.id);
              if (clientName) {
                clientName.FirstName = playerInfo.personalDetails.firstName;
                clientName.LastName = playerInfo.personalDetails.lastName;
              }
            }
            await this.appointmentservice.UpdateCMSDetailOnExistingGuest(patronId, clientObj[0]);

            if (clientObj[0].emails && clientObj[0].emails.length > 0) {
              clientObj[0].emails.forEach(element => {
                element.contactTypeId = element.contactTypeId == 0 ? 10 : element.contactTypeId;
              });
            }
            if (clientObj[0].phoneNumbers && clientObj[0].phoneNumbers.length > 0) {
              this.appointmentservice.recordsArray.forEach(x => this.appointmentservice.getFormattedPhone(x));
            }

            let callDesc = 'updateClient';
            let uriParams = { id: clientObj[0].clientDetail.id };
            this.http.CallApiWithCallback({
              host: Host.spaManagement,
              success: this.successCallback.bind(this),
              error: this.utils.errorCallback.bind(this),
              callDesc: callDesc,
              uriParams: uriParams,
              method: HttpMethod.Put,
              body: clientObj[0],
              showError: true,
              extraParams: []
            });
          }
        }
        else {
          this.openAddClient('edit', clientObj[0].clientDetail.id, clientObj[0].clientDetail.guestId);
        }
      } else {
        this.openAddClient('edit', clientObj[0].clientDetail.id, clientObj[0].clientDetail.guestId);
      }
    }
  }
  async loadClientBlockInformation(clientObj: any) {
    if (clientObj && clientObj.length > 0) {

      this.http.CallApiWithCallback<any>({
        host: Host.spaManagement,
        success: this.successCallback.bind(this),
        error: this.utils.errorCallback.bind(this),
        callDesc: "GetClientblockdetails",

        method: HttpMethod.Put,
        body: clientObj.map(x => x.id),
        showError: true,
        extraParams: null
      });
    }
  }

  calculateNewClientYield(clientDetails: any) {
    let servicePrice = this.appointmentservice.multiClientInfo.find(x => x.id == clientDetails.id).price;
    let serviceId = this.appointmentservice.resultExistingAppointment.appointmentDetail.serviceId;
    this.SetServicePrice(servicePrice, serviceId, clientDetails.id);
  }

  SetServicePrice(price: any, serviceId: any, clientId: any) {
    let date: Date = this.appointmentservice.resultExistingAppointment.appointmentDetail.startTime;
    let formattedDate = this.localization.convertDateObjToAPIdate(date);

    this.http.CallApiWithCallback<any>({
      host: Host.schedule,
      success: this.successCallback.bind(this),
      error: this.utils.errorCallback.bind(this),
      callDesc: "GetServicePriceBasedOnYield",
      method: HttpMethod.Get,
      uriParams: { appointmentDate: formattedDate, serviceId: serviceId, originalPrice: Number(price) },
      showError: true,
      extraParams: [clientId]
    });

  }

  async removeFromSearchBar(id = 0) {
    this.clientSearchValue = "";
    this.poppedClientId = [];

    if (this.appointmentservice.clientBlockInfos && this.appointmentservice.clientBlockInfos.length > 0) {
      this.appointmentservice.clientBlockInfos = this.appointmentservice.clientBlockInfos.filter(x => x.clientId != id);
    }

    this.clientForm.control.markAsDirty();
    this.appointmentservice.setGenderPreference();
    let clientId = this.appointmentservice.multiClientInfo && this.appointmentservice.multiClientInfo.length > 0 ? this.appointmentservice.multiClientInfo[0].id : 0;
    this.appointmentservice.setPriceTypeUIforMutliClient(clientId);

    if (this.appointmentservice.isEditAppointment && !this.appointmentservice.ValidatePriceTypeAvailable(clientId) && !this.appointmentservice.serviceChanged) {
      this.appointmentservice.setPriceTypeCodeForSelectedClient(clientId, this.appointmentservice.resultExistingAppointment.appointmentDetail.serviceId);
    }
    else {
      this.appointmentservice.setPriceTypeCodeUI();
    }

    this.activeBorderId = 0;

    if (id == 0) { // All clients has been removed from master cancel icon - from client chip
      this.RemoveAllClients();
      this.appointmentservice.multiClientInfo = [];
      this.appointmentservice.clientVipType = '';
      this.appointmentservice.clientVipType = '';
      this.appointmentservice.currentClient = null;
      this.appointmentservice.disableRadioButton(this.appointmentServiceObject.isAppointmentFromPackage,this.appointmentServiceObject.PackageMaxPeople);
      return;
    }

    this.UpdateTemphold(id);
    this.appointmentservice.currentClient = this.appointmentservice.recordsArray && this.appointmentservice.recordsArray.length > 0 ? this.appointmentservice.recordsArray[0] : null;
    this.appointmentservice.activeBorderId = 0;
    this.appointmentservice.clientVipType = clientId != 0 ? this.appointmentservice.GetVipTypeName(this.appointmentservice.currentClient.clientDetail.vip) : '';
    this.appointmentservice.disableRadioButton(this.appointmentServiceObject.isAppointmentFromPackage,this.appointmentServiceObject.PackageMaxPeople);
    
    if (this.cartUtilities.isEmbed()) {
      let data = this.appointmentservice.recordsArray.map(x => x.clientDetail.guestId);
      this.cartGuest = this.cartGuest.filter(x => data.includes(x.value));
      //await this.setClientInfobyGuid('');
      if (this.appointmentservice.recordsArray.length < this.appointmentservice.clientScreenProperties.maxGuest)
        this.cartGuest.forEach(guest => guest.disabled = false);
    }
  }

  private RemoveAllClients() {
    let clientLength = this.appointmentservice.recordsArray.length;
    if (clientLength > 0) {
      let client = this.appointmentservice.recordsArray[this.appointmentservice.recordsArray.length - 1];
      this.appointmentservice.recordsArray = this.appointmentservice.recordsArray.filter(r => {
        return r.clientDetail.id != client.clientDetail.id
      });
      this.UpdateTemphold(client.clientDetail.id);
      this.RemoveAllClients(); // Recurrsive call to remove client temp hold
    }
  }
  private UpdateTemphold(id) {
    this.removeClientMultipack(id);
    if (this.appointmentservice.recordsArray.length == 1) {
      this.appointmentservice.commentPlaceHolder = ""
      if (this.appointmentservice.imageArray) {
        _.remove(this.appointmentservice.imageArray, (x) => x && x['referenceId'] == id && x['referenceType'] === ImgRefType.client)
        this.appointmentservice.clientImageChange(this.appointmentservice.imageArray.filter(x => x && x.referenceId == this.appointmentservice.recordsArray[0].clientDetail.id && x.referenceType == ImgRefType.client))
      }
      else {
        this.appointmentservice.clientImageChange([]);
      }
    }

    if (this.appointmentservice.clientGuestMap.length > 0) {
      this.appointmentservice.clientGuestMap = this.appointmentservice.clientGuestMap.filter(x => x.ClientId != id);
    }

    if (id > 0 && this.appointmentservice.ClientActivities && this.appointmentservice.ClientActivities.length > 0) {
      let index = this.appointmentservice.ClientActivities.map(x => x.ClientId).indexOf(id);
      if (index != -1) {
        this.appointmentservice.ClientActivities.splice(index);
      }
    }

    //TEMP HOLD CHANGES
    //remove selected client logic
    if (!this.appointmentservice.resultExistingAppointment) //new appointment
    {
      if (this.appointmentservice.recordsArray.length == 0) {
        if (this.appointmentservice.TempHoldArr.length > 0) {
          this.appointmentservice.TempHoldArr[this.appointmentservice.recordsArray.length].clientId = 0;
        }
        let tempArr: TempHoldApp[] = [];
        tempArr.push(this.appointmentservice.TempHoldArr[this.appointmentservice.recordsArray.length]);
        this.appUtils.UpdateTempHoldObject(tempArr);
      }
      else  //recordsarray length > 0
      {
        let selectedTempHold = this.appointmentservice.TempHoldArr.find(function (tempobj) {
          return tempobj.clientId == id;
        });
        //delete from DB
        if (selectedTempHold != null && selectedTempHold.appointmentId > 0) {
          this.appUtils.deleteTempHold([{ appointmentId: selectedTempHold.appointmentId }]);
        }
      }
    }
    else //Edit appointment
    {
      // let tempHoldIndex = this.appointmentservice.TempHoldArr.findIndex(function (tempobj) {
      //   return tempobj.originalClientId == id;
      // });
      // if (tempHoldIndex != -1) {
      //   this.appointmentservice.TempHoldArr[tempHoldIndex].clientId = 0;
      //   let tempArr: TempHoldApp[] = [];
      //   tempArr.push(this.appointmentservice.TempHoldArr[tempHoldIndex]);
      //   this.appUtils.UpdateTempHoldObject(tempArr);
      // }
      // else {
      //   if(this.appointmentservice.TempHoldArr.length > 1){
      //     let tempholdToDelete = this.appointmentservice.TempHoldArr.find(function (tempobj) {
      //       return tempobj.clientId == id;
      //     });
      //     if (tempholdToDelete != null && tempholdToDelete.appointmentId > 0) {
      //       this.appUtils.deleteTempHold([{ appointmentId: tempholdToDelete.appointmentId }]);
      //     //}
      //   }
      // }
      // else{
      let tempHoldIndex = this.appointmentservice.TempHoldArr.findIndex(function (tempobj) {
        return tempobj.originalClientId == id;
      });
      if (tempHoldIndex != -1) {
        this.appointmentservice.TempHoldArr[tempHoldIndex].clientId = 0;
        let tempArr: TempHoldApp[] = [];
        tempArr.push(this.appointmentservice.TempHoldArr[tempHoldIndex]);
        this.appUtils.UpdateTempHoldObject(tempArr);
      }
      else {
        let tempholdToDelete = this.appointmentservice.TempHoldArr.find(function (tempobj) {
          return tempobj.clientId == id;
        });
        if (tempholdToDelete != null && tempholdToDelete.appointmentId > 0) {
          this.appUtils.deleteTempHold([{ appointmentId: tempholdToDelete.appointmentId }]);
        }
      }
    }
    if (Object.keys(this.appointmentservice.resultNewAppointment).length > 0) {
      this.appointmentservice.resultNewAppointment.timelineData = [];
      this.appointmentservice.resultNewAppointment.clientFullItinerary = [];
    }

  }

  removeClientMultipack(id: any) {
    if (this.appointmentservice.clientMultipacks && this.appointmentservice.clientMultipacks.length > 0 && id > 0) {
      _.remove(this.appointmentservice.clientMultipacks, { clientId: id });
    }
  }

  CopyCustomFieldFromClient(clientObj) {

    if (this.appointmentservice.IsCopyFromClient[0]) {
      this.appointmentservice.otherdetails.customField1 = clientObj[0].clientDetail.customField1 && clientObj[0].clientDetail.customField1 != 0 ? clientObj[0].clientDetail.customField1 : this.appointmentservice.otherdetails.customField1;
    }
    if (this.appointmentservice.otherdetails.customField1 == 0) {
      this.appointmentservice.otherdetails.customField1 = null;
    }
    if (this.appointmentservice.IsCopyFromClient[1]) {
      this.appointmentservice.otherdetails.customField2 = clientObj[0].clientDetail.customField2 && clientObj[0].clientDetail.customField2 != 0 ? clientObj[0].clientDetail.customField2 : this.appointmentservice.otherdetails.customField2;
    }
    if (this.appointmentservice.otherdetails.customField2 == 0) {
      this.appointmentservice.otherdetails.customField2 = null;
    }
    if (this.appointmentservice.IsCopyFromClient[2]) {
      this.appointmentservice.otherdetails.customField3 = clientObj[0].clientDetail.customField3 && clientObj[0].clientDetail.customField3 != 0 ? clientObj[0].clientDetail.customField3 : this.appointmentservice.otherdetails.customField3;
    }
    if (this.appointmentservice.otherdetails.customField3 == 0) {
      this.appointmentservice.otherdetails.customField3 = null;
    }
    if (this.appointmentservice.IsCopyFromClient[3]) {
      this.appointmentservice.otherdetails.customField4 = clientObj[0].clientDetail.customField4 ? clientObj[0].clientDetail.customField4 : this.appointmentservice.otherdetails.customField4;
    }
    if (this.appointmentservice.IsCopyFromClient[4]) {
      this.appointmentservice.otherdetails.customField5 = clientObj[0].clientDetail.customField5 ? clientObj[0].clientDetail.customField5 : this.appointmentservice.otherdetails.customField5;
    }
  }
  loadValidTherapist() {

    if (typeof this.appointmentservice.clientScreenProperties.selectedStaff != "undefined") {
      this.validTherapist = this.appointmentservice.clientScreenProperties.selectedStaff;
    } else {
      this.validTherapist = [];
    }
    if (this.validTherapist.length == 0) {
      this.appointmentservice.selectedIndex = 0;
      this.appointmentservice.therapistNotSelected = true;
    } else {
      this.appointmentservice.selectedIndex = 1
      this.appointmentservice.therapistNotSelected = false;
    }
  }


  checkForClientAlreadyAdded(client: ClientInfo[]): ClientInfo[] {
    let alreadyAdded: ClientInfo[] = this.appointmentservice.recordsArray as ClientInfo[]; //this collection contains already selected client items in client screen.
    let clientSearchResArr: ClientInfo[] = client;
    let indexOfExisting: number;
    alreadyAdded.forEach(alreadyAddedCli => {
      indexOfExisting = clientSearchResArr.findIndex(c => c.clientDetail.id == alreadyAddedCli.clientDetail.id);
      if (indexOfExisting != -1) {
        clientSearchResArr.splice(indexOfExisting, 1);  //remove from the search result collection if the item matches.
      }
    }
    )
    return clientSearchResArr.sort((a, b) => (a.clientDetail.firstName +' '+ a.clientDetail.lastName) > (b.clientDetail.firstName +' '+b.clientDetail.lastName) ? 1 : -1);
  }

  async updatePriceTypeForClient(e: MatSelectChange) {
    this.appointmentservice.multiClientInfo.forEach(element => {
      if (element.id == this.appointmentservice.clientId) {
        element.priceTypeId = e.value;
        if (this.appointmentservice.selectedServicePriceTypes.length > 0) {
          let selectedpriceTypeCode = this.appointmentservice.selectedServicePriceTypes.find(y => y.priceTypeId == element.priceTypeId);
          if (selectedpriceTypeCode && element.priceTypeId != 0) {
            element.price = selectedpriceTypeCode.price;
          } else {
            element.price = this.appointmentservice.resultNewAppointment.price;
          }
        }
      }
    });

    /*
    *To Update remaining normal PriceTypePrice and sent to Yield Calculation
    */
    this.appointmentservice.multiClientInfo.forEach(element => {
      if (this.appointmentservice.selectedServicePriceTypes.length > 0) {
        let selectedpriceTypeCode = this.appointmentservice.selectedServicePriceTypes.find(y => y.priceTypeId == element.priceTypeId);
        if (selectedpriceTypeCode && element.priceTypeId != 0) {
          element.price = selectedpriceTypeCode.price;
        } else {
          element.price = this.appointmentservice.resultNewAppointment.price;
        }
      }
    });

    await this.getServicePriceTypePriceBasedOnYield();
    let clientId = (this.appointmentservice.activeSelectedClient && this.appointmentservice.activeSelectedClient != 0) ? this.appointmentservice.activeSelectedClient : this.appointmentservice.multiClientInfo.length > 0 ? this.appointmentservice.multiClientInfo[0].id : 0;
    this.appointmentservice.setPriceTypeUIforMutliClient(clientId);
    this.appointmentservice.isPriceTypeApplicable();
  }

  public async getServicePriceTypePriceBasedOnYield() {
    if (this.appointmentservice.multiClientInfo.length > 0) {
      let apiClientBody = this.appointmentservice.multiClientInfo.filter(x => x.priceTypeId > 0);
      let date: Date = this.appointmentservice.resultNewAppointment.dateField;
      let formattedDate = this.localization.convertDateObjToAPIdate(date);

      let result = await this.http.CallApiAsync<any>({
        host: Host.schedule,
        callDesc: "GetServicePriceBasedOnPriceTypeYield",
        method: HttpMethod.Put,
        body: apiClientBody,
        uriParams: { serviceId: this.appointmentservice.resultNewAppointment.service, appointmentDate: formattedDate }
      });

      let selectedClientPriceType: any = result.result;

      this.appointmentservice.multiClientInfo.forEach(client => {
        let clientUpdatedPrice = selectedClientPriceType.find(y => y.id == client.id && y.priceTypeId == client.priceTypeId);
        if (clientUpdatedPrice) {
          client.price = clientUpdatedPrice.price
        }
        else {
          client.price = this.appointmentservice.resultNewAppointment.price;
        }
      });
    }
  }

  updatePriceForClient(event) {
    this.appointmentservice.selectedClientPriceValue = Number(event.target.value);
    this.appointmentservice.multiClientInfo.forEach(element => {
      if (element.id == this.appointmentservice.clientId) {
        element.price = this.localization.currencyToSQLFormat(this.appointmentservice.selectedClientPriceValue.toString());
      }
    });
  }

  playerWorthDetails(patronId: string) {
    this.clientCommonService.openDialogPopup(patronId);
  }



  hotelReservSearchTextHandler(pattern: string) {
    let searchText = pattern ? pattern.toLowerCase().trim() : '';
    if (searchText) {
      this.hotelReservAllData = this.originalHotelReservData.filter(x => x.name.toLowerCase().includes(searchText));
    } else {
      this.hotelReservAllData = cloneDeep(this.originalHotelReservData);
    }
  }

  onChipRemoveEventHotelReserv() {
    this.hotelReservAllData = [];
  }
  
  findRelationshipClicked() {
    this.findRelationshipDebounce.next();
  }

  async openFindRelationship() {
    this.setSelectedTherapist();
    let currentClientId = this.selectedTherapist?.id ? this.selectedTherapist.id : this.selectedTherapist;
    let clientDetail = await this.http.CallApiAsync<any>({
      host: Host.spaManagement,
      callDesc: "GetClientRelationships",
      method: HttpMethod.Get,
      showError: true,
      uriParams: { id: currentClientId}
    });
    this.currentguestInfo = clientDetail.result;
    let swapClient = this.swapOrAddClient();
    let dialogRef = this.dialog.open(FindRelationshipComponent, {
      width: '40%',
      height: '60%',
      hasBackdrop: true,
      disableClose: true,
      data: {
        form: this.currentguestInfo,
        swapClient: swapClient,
        isEdit: true,
        closebool: true
      }
    });
    this.actionDialogSubscription = dialogRef.afterClosed().subscribe(result => {
      if (result) {
          result?.data.forEach(e => {
          result.relatedClient.forEach(x => {
            if (e.clientDetail.guestId == x.contactGuestId) {
              if (x.checked) {
                if (this.appointmentservice.recordsArray.map(b => b.id).includes(e.id)) {
                  return;
                }
                else {
                  if(swapClient){
                    this.appointmentservice.recordsArray.forEach(arr => {
                      if (arr.id === currentClientId) {
                        this.removeRelatedClient(arr.id);
                      }
                    })
                  }
                  this.setClientInfo(e.clientDetail.guestId, [e]);
                  this.clientSelected([e]);
                }
              }
              else {
                this.appointmentservice.recordsArray.forEach(arr => {
                  if (arr.id === e.clientDetail.id) {
                    this.removeRelatedClient(arr.id);
                  }
                })
              }
            }

          })
        });
      }
    });
  }

  removeRelatedClient(clientId){
    this.clientToRemove = this.appointmentservice.recordsArray.find(a => a.id == clientId);
    this.appointmentservice.multiClientInfo = this.appointmentservice.multiClientInfo.filter(r => r.id != this.clientToRemove.id);
    this.appointmentservice.labelRecords = this.appointmentservice.labelRecords.filter(r => r.Id != this.clientToRemove.id);
    this.appointmentservice.recordsArray = this.appointmentservice.recordsArray.filter(r => r.clientDetail.id != this.clientToRemove.id);
  }

  swapOrAddClient(): boolean{
    let isMaxGuestReached = this.appointmentservice.recordsArray.length >= this.appointmentservice.clientScreenProperties.maxGuest;
    if(this.appointmentServiceObject.isAppointmentFromPackage){
      return (this.appointmentServiceObject.PackageBookingType == 'Express') ? true : (this.appointmentServiceObject.PackageMaxPeople !=-1 && isMaxGuestReached);
    }
    else{
      return isMaxGuestReached;
    }
  }

  removeClientInfo() {
    this.appointmentservice.labelRecords = [];
    this.removeFromSearchBar();
  }

  async isPlatformGuestSearchChanged(e){
    this.isPlatformGuestSearch = Boolean(e[0]);
    if(this.isPlatformGuestSearch){
      this.appointmentservice.isExternalSearch = false;
      this.clientSearchForm.controls.isExternalSearch.setValue(false);
       this.clientSearchForm.controls.isExternalSearch.updateValueAndValidity();
    }
    await this.getClientSearchTypes();
    this.setSearchText(this.selectedClientSearchType);
  }

  async IsExternalChanged(e: boolean) {
    this.appointmentservice.isExternalSearch = e[0];
    await this.getClientSearchTypes();
    this.setSearchText(this.selectedClientSearchType);
    if (this.appointmentservice.isExternalSearch) {
        this.isPlatformGuestSearch = false;
        this.clientSearchForm.controls.isPlatformGuestSearch.setValue(false);
        this.clientSearchForm.controls.isPlatformGuestSearch.updateValueAndValidity();
        this.clientSearchTypeChange(this.defaultClientSearchValue);
    }
  }

  async getClientSearchTypes() {
    await this.appointmentservice.getDefaultHotelReservationTypes(this.pmsSystemValue);
    await this.getDefaultClientSearchFilter();
  }

  getDefaultClientSearchFilter() {
    this.clientSearchFilter = this.appointmentservice.fetchGuestSearchJson();
    let clientSearchDetail = this.clientSearchFilter?.find(p => p.integratedPms == this.pmsSystemValue);
    if (this.appointmentservice.isExternalSearch && this.pmsSystemValue && clientSearchDetail) {
      this.clientSearchTypes = this.appointmentservice.MapSearchData(clientSearchDetail,ClientType.Guest);
    }
    else {
      this.clientSearchTypes = [{ id: 0, name: this.localization.captions.firstName, checked: false },
      { id: 1, name: this.localization.captions.lastName, checked: false },
      { id: 2, name: this.localization.captions.name, checked: false },
      { id: 3, name: this.localization.captions.phone, checked: false },
      { id: 4, name: this.localization.captions.email, checked: false }];
    }
    this.selectedClientSearchType = this.defaultClientSearchValue;
    if (this.clientSearchTypes && this.clientSearchTypes.find(s => s.id == this.selectedClientSearchType))
    {
      this.clientSearchTypes.forEach(s => s.checked = false);
      this.clientSearchTypes.find(s => s.id == this.selectedClientSearchType).checked = true;
    }             
  }

  async selectionChange(e, selectedItem, isChecked) {
    if (!isChecked) {
      let clientId = this.cartGuests.find(guest => guest?.value == selectedItem?.value?.value)?.clientId;
      if (clientId != 0) {
        this.chipsAutoComplete.remove(clientId);
        this.removeFromSearchBar(clientId);
      }
    }
    else if (isChecked) {
      await this.setClientInfobyGuid(selectedItem.value.value);
    }
  }
  
}
export enum CartAction {
  EDITORCREATE,
  ACTIVE
}


