import * as GlobalConst from 'src/app/retail/shared/globalsContant';
import { BaseResponse } from '../../retail.modals';
import { HttpMethod, HttpServiceCall } from '../../shared/service/http-call.service';
import { RetailRoutes } from "../../retail-route";
import { Injectable } from '@angular/core';

@Injectable()
export class GuestTypeBusiness {
  constructor( public http: HttpServiceCall) {
  }

   async getAllGuestTypes(includeInactive) {
      const results = await this.http.CallApiAsync<BaseResponse<any[]>>({
        host: GlobalConst.Host.retailPOS,
        callDesc: RetailRoutes.GetAllGuestTypes,
        method: HttpMethod.Get,
        uriParams: { "isIncludeInactive": includeInactive }
        });
      return results.result;
    }
  
    async createGuestType(guestType) {
      return await this.http.CallApiAsync<BaseResponse<number>>({
        host: GlobalConst.Host.retailPOS,
        callDesc: RetailRoutes.CreateGuestType,
        method: HttpMethod.Post,
        body: guestType,
        showError: true
      });
    }
  
    async updateGuestType(guestType) {
      return await this.http.CallApiAsync<BaseResponse<any[]>>({
        host: GlobalConst.Host.retailPOS,
        callDesc: RetailRoutes.UpdateGuestType,
        method: HttpMethod.Put,
        body: guestType,
        showError: true
      });
    }
  
    async deleteGuestType(id) {
      return await this.http.CallApiAsync<BaseResponse<any[]>>({
        host: GlobalConst.Host.retailPOS,
        callDesc: RetailRoutes.DeleteGuestType,
        method: HttpMethod.Delete,
        uriParams: { "id": id },
        showError: true
      });
    }
  
    async dragDropGuestType(fromOrder, toOrder){
      var response = await this.http.CallApiAsync<BaseResponse<any[]>>({
        host: GlobalConst.Host.retailPOS,
        callDesc: RetailRoutes.GuestTypeDrag,
        method: HttpMethod.Patch,
        uriParams: { "fromOrder": fromOrder, "toOrder": toOrder}
      });
      return response.result;
    }
  
}