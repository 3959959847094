import { Component, EventEmitter, Input, OnInit, Output, ViewContainerRef } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { ReportDataService } from '../../../data/report-data.services';
import { RetailLocalization } from 'src/app/retail/common/localization/retail-localization';
import { ReportBusinessService } from '../../../business/report-business.service';
import { RetailPropertyInformation } from 'src/app/retail/common/services/retail-property-information.service';

@Component({
  selector: 'app-outlet-sales-zreading',
  templateUrl: './outlet-sales-zreading.component.html',
  styleUrls: ['./outlet-sales-zreading.component.scss']
})
export class OutletSalesZreadingComponent implements OnInit {

  outletSalesZReadingFrmGrp: UntypedFormGroup;
  @Input() customReport: any;
  @Output() formReady = new EventEmitter();

  constructor(private fb: UntypedFormBuilder, public localization: RetailLocalization, public business: ReportBusinessService, 
    private dataService: ReportDataService, private propertyInfo: RetailPropertyInformation,private container: ViewContainerRef) { }

  ngOnInit(): void {
    this.outletSalesZReadingFrmGrp = this.fb.group({});
    this.formReady.emit(this.outletSalesZReadingFrmGrp);
  }

}
