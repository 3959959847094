import { Injectable } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { CustomizableDashboardDataService } from 'src/app/common/dataservices/authentication/customizable-dashbard.data.service';
import { Localization } from '../../localization/localization';
import { UI } from '../../Models/property-information.model';
import { CommonUtilities } from '../../shared/shared/utilities/common-utilities';
import { CustomizableDashboardDetails, CustomType } from '../dashboard-jasper-config/dashboard-jasper-config.model';

@Injectable()
export class DashboardConfigurationBusiness{
    captions: any;
    propertyId: number;
    productId: number;
    constructor(private _localization: Localization, private customizableDashboardService: CustomizableDashboardDataService, private utils: CommonUtilities){
        this.captions = this._localization.captions;
        this.setPropertyInfoDetails();
    }

    setPropertyInfoDetails(){
        this.productId = Number(this.utils.GetPropertyInfo('ProductId'));
        this.propertyId = Number(this.utils.GetPropertyInfo('PropertyId'));
    }

    getDashboardViewsFormControl() {
        return [{
            header: this.captions.lbl_Dasboard1,
            formControlName: 'dashboard1Name',
            formControlUrl: 'dashboard1URL',
            dashboardName: '',
            dashboardUrl: ''
        },
        {
            header: this.captions.lbl_Dasboard2,
            formControlName: 'dashboard2Name',
            formControlUrl: 'dashboard2URL',
            dashboardName: '',
            dashboardUrl: ''
        },
        {
            header: this.captions.lbl_Dasboard3,
            formControlName: 'dashboard3Name',
            formControlUrl: 'dashboard3URL',
            dashboardName: '',
            dashboardUrl: ''
        }
        ]
    }

    public shouldActionButtonsDisable(form: UntypedFormGroup): UI.ActionButtonState {
        return {
            isSaveDisable: !(form.dirty && form.valid),
            isCancelDisable: !(form.dirty)
        } as UI.ActionButtonState;
    }

    public shouldActionButtonsDisableProduct(productForm: UntypedFormGroup, dashboardConfigForm: UntypedFormGroup): UI.ActionButtonState{
        return {
            isSaveDisable: !(productForm.dirty && productForm.valid && dashboardConfigForm.valid),
            isCancelDisable: !(productForm.dirty || dashboardConfigForm.dirty)
        } as UI.ActionButtonState;
    }

    async getCustomizedDashboard(){
        let customDashboard: CustomizableDashboardDetails[] = await this.customizableDashboardService.getCustomizableDashboardDetailsByPropertyId(this.propertyId, this.productId);
        return customDashboard;
    }

    mapFormValue(customDashboard :CustomizableDashboardDetails[]){
        if(customDashboard && customDashboard.length > 0){
            let dashboard1 = customDashboard.find(x => x.customType == CustomType.Dashboard1);
            let dashboard2 = customDashboard.find(x => x.customType == CustomType.Dashboard2);
            let dashboard3 = customDashboard.find(x => x.customType == CustomType.Dashboard3);
            return {
                dashboard1Name: dashboard1?.dashboardName,
                dashboard1URL: dashboard1?.dashboardURL,
                dashboard2Name: dashboard2?.dashboardName,
                dashboard2URL: dashboard2?.dashboardURL,
                dashboard3Name: dashboard3?.dashboardName,
                dashboard3URL: dashboard3?.dashboardURL
            }
        }
        else{
            return {
                dashboard1Name: '',
                dashboard1URL: '',
                dashboard2Name: '',
                dashboard2URL: '',
                dashboard3Name: '',
                dashboard3URL: ''
            }
        }

    }

    async save(formValue){
        let customDashboards = []
        let customDashboard : CustomizableDashboardDetails[] = this.mapSaveData(formValue?.dashboardConfigArray);
        if(customDashboard && customDashboard.length > 0){
            customDashboards = await this.customizableDashboardService.UpdateCustomizableDashboardDetails(customDashboard);
        }
        return customDashboards;
    }

    mapSaveData(dashboardConfigArray: any[]): CustomizableDashboardDetails[] {
        let customDashboard: CustomizableDashboardDetails[] = [];
        dashboardConfigArray.forEach((x, i) => {
            let dashboard: CustomizableDashboardDetails = {
                dashboardName: x.dashboardName,
                dashboardURL: x.dashboardUrl,
                productId: this.productId,
                propertyId: this.propertyId,
                userId: 0,
                customType: i + 1
            }
            customDashboard.push(dashboard);
        })
        return customDashboard;
    }
}