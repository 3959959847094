import { Component, Inject, OnInit, ViewEncapsulation } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AppointmentView, TherapistServiceInfo } from 'src/app/appointment/spa-appointment/spa-appointment.model';
import { SPAConfig } from 'src/app/core/config/SPA-config';
import { SpaLocalization } from 'src/app/core/localization/spa-localization';
import { MoreFilterOptions, NoDataModel } from '../business/shared.modals';
import { ServiceGroup } from '../business/view-settings.modals';
import { SpaUtilities } from '../utilities/spa-utilities';
import * as GlobalConst from 'src/app/shared/globalsContant';
import { ImageData } from 'src/app/shared/business/shared.modals';

@Component({
  selector: 'app-service-list',
  templateUrl: './service-list.component.html',
  styleUrls: ['./service-list.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ServiceListComponent implements OnInit {

  localize: any;
  TherSerGrp: ServiceGroup[];
  filterOptions: MoreFilterOptions;
  DataArray: any;
  filterSerGrpArr: number[] = [];
  selectedArr: any;
  selectedSerGrp: any;
  originalData: any;
  header: string;
  title: string;
  dataContent: any;
  selectedAddon: any = [];
  captions: any = this.spaconfig.captions;
  TherSerGrpDatas: TherapistServiceInfo[];
  allTherSerGrpDatas: TherapistServiceInfo[];
  noDataOptions: NoDataModel;
  isInactiveInculde = false;
  imageContent: ImageData;
  viewType = AppointmentView;
  viewMode: AppointmentView;
  floatLabel: string;
  isDisplayAddOn: boolean;
  therAddOnData: any;
  constructor(
    public spaconfig: SPAConfig,
    private utilities: SpaUtilities,
    public localization: SpaLocalization,
    public dialogRef: MatDialogRef<ServiceListComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.localize = this.localization.captions.setting;
    this.floatLabel = this.localization.setFloatLabel;
    this.originalData = this.data;
    this.dataContent = this.originalData.viewData;
    this.therAddOnData = this.dataContent.data.addOns;
    console.log(this.data);
  }

  ngOnInit(): void {
    this.header = this.originalData.headername.title;
    this.viewMode = this.originalData.viewData.view;
    if (this.viewMode === AppointmentView.TherapistView) {
      this.title = this.dataContent.data.name + ' (' + this.dataContent.data.gender + ')';
    } else {
      this.title = this.dataContent.data.name;
    }
    this.TherSerGrp = this.dataContent.data.serviceGroup;
    this.selectedSerGrp = [];
    this.allTherSerGrpDatas = this.dataContent.data.services;
    this.imageContent = this.dataContent.data.image;
    this.filteredData();
    this.noDataOptions = { inputText: this.localization.captions.common.NoDataFound, noDataId: GlobalConst.noRecordsType.noRecords, noDataFontSize: 10 };
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  close(): void {
    this.dialogRef.close();
  }

  updateSerGrpArr(ga: any, gv: any): void {
    if (gv === 'Add-ons') {
      this.isDisplayAddOn = !this.isDisplayAddOn;
    }
    if (ga.indexOf(gv) === -1) {
      ga.push(gv);
    } else {
      ga.splice(ga.indexOf(gv), 1);
    }
    this.filterSerGrpArr = ga;
    this.updateFilterData();
  }

  filteredDataResponse(event: any) {
    let result: any = event[0];
    if (!result) { return; }
    result = result ? result : [];
    const ids = result.map(x => x.id ? x.id : x);
    this.filterSerGrpArr = ids;
    this.selectedSerGrp = ids;
    this.updateFilterData();
  }

  openFilterDialog(ArrType): void {
    const popupLeft = document.getElementById('TherapistSetup');
    const d = document.getElementById(ArrType);
    let topPos = d.offsetTop - 34, leftPos = popupLeft.offsetLeft + 16;
    this.DataArray = this.TherSerGrp;
    this.selectedArr = this.selectedSerGrp;
    this.filterOptions = this.utilities.getFilterPopUpOption(true, this.DataArray, this.selectedArr, topPos, leftPos, ArrType, 'TS');
  }

  inactiveServiceToggle(event) {
    console.log(event);
    this.isInactiveInculde = event.checked;
    this.filteredData();
  }

  updateFilterData() {
    this.allTherSerGrpDatas = [];
    if (this.filterSerGrpArr.length > 0) {
      this.allTherSerGrpDatas = this.dataContent.data.services.filter(x => {
        return this.filterSerGrpArr.some(y => y === x.serviceGroupId)
      });
    } else {
      this.allTherSerGrpDatas = this.dataContent.data.services;
    }
    this.filteredData();
  }

  filteredData() {
    this.TherSerGrpDatas = [];
    if (this.isInactiveInculde) {
      this.TherSerGrpDatas = this.allTherSerGrpDatas;
    } else {
      this.TherSerGrpDatas = this.allTherSerGrpDatas.filter(x => x.isActive);
    }
  }

}
