import { Injectable } from '@angular/core';
import { Subject, ReplaySubject } from 'rxjs';
import { HttpRequestService } from '../../shared/service/http-request.service';
import { HttpServiceCall, HttpMethod, KeyValuePair } from '../../shared/service/http-call.service';
import * as GlobalConst from '../../shared/globalsContant';
import { SpaUtilities } from '../utilities/spa-utilities';
import { UserAlerts } from '../../core/config/alerts-config';
import { UntypedFormBuilder, UntypedFormGroup, Validators, NgForm } from '@angular/forms';
import { BreakPointAccess } from '../../shared/service/breakpoint.service';
import { SpaPropertyInformation } from '../../core/services/spa-property-information.service';
import { linkMultipackData, BaseResponse, appointment, Service, TransactionDetail, AppointmentCheckoutWithTransaction, AppointmentCustomFee, DepositAPIModel } from '../../shared/business/shared.modals';
import { FormatText } from '../pipes/formatText-pipe.pipe';
import { SpaLocalization } from '../../core/localization/spa-localization';
import { PackageYield, PackageAvailableDays, TherapistDetails } from '../business/view-settings.modals';
import * as _ from "lodash";
import { CalendarGridBodyProps } from '../calendar-grid/calendar-grid-models/calendar-grid.models';
import { Appoinment, CancellationNoShowFeeRequest } from '../appointment-actions/appoinment.model';
import { AppointmentActionFilter,AppointmentFilterRequest } from '../shared.modal';
import { ApplyPolicy } from 'src/app/common/consent-management/consent-management.model';
import { ClientType } from '../../shared/globalsContant';
import { ServiceGroupsWithServices } from 'src/app/common/shared/shared/cancellation-no-show-policy/cancellation-no-show-policy.model';
import { SelectedProducts } from 'src/app/retail/shared/shared.modal';
import { CancellationNoShowChargeEventModel, CancellationNoShowChargeStatus } from 'src/app/retail/shared/events/event.model';
import { RetailItemType } from 'src/app/retail/retail.modals';
import { CustomFeeSourceType } from 'src/app/retail/shared/globalsContant';
import { DepositTransactionStatus } from 'src/app/retail/shared/service/payment/payment-business.model';

export enum PackageBook {
    Express = 'Express',
    Standard = 'Standard',
    None='None',
}

export enum SelectedSession {
    ClientAppointment = 0
}

@Injectable({
    providedIn: 'root'
})
export class appointmentService {

    public showGroupAppointments: Subject<boolean> = new Subject<boolean>();
    activeFormGroup: UntypedFormGroup | NgForm;
    appoinmentEditbool = false;
    add_client: boolean = false;
    isAppoinment: boolean;
    SelectedSession: SelectedSession;
    PackageTempHoldIdList: number[] = [];
    isAppointmentFromPackage: boolean;
    ValidServicesForPackageAppt: any[] = [];
    cellcontentArrService: any = [];
    managementData: any[] = [];
    standardPackSelectedClient: any;
    PackageAppointmentServices: any[];
    PackageRetailServices: any[];
    actionData: any;
    PackageBookingType: PackageBook = PackageBook.Express;
    PackageMaxPeople: number = 1;
    intakeForm: boolean = false;
    newAppointmentData: any = {
        serviceInfo: []
    }

    clientId: any = 0;
    appointmentId: any = 0;
    status: any = '';
    isConfirmationRecapSearchScreen = false;
    appointmentIdLst: any = [];

    appointmentConfiguration: any = [];
    serviceInfo: any = [];
    clientInfo: any = {};
    retailInfo:any={};
    PackageDetail: any;
    packageInfo: any;
    PackageSelectedServices: any;
    PackageGuaranteeConfig: any;
    packageArray: any = [];
    packageArrayCopy:any=[];
    packageYields: PackageYield[] = [];
    packageAvailableDays: PackageAvailableDays[] = [];
    comments: any;
    packageDate: any;
    otherinfo: any = `by ${this.serviceInfo.therapistname} at ${this.serviceInfo.location}`;
    PackageClient: any;
    clientScreenProperties: any = {};
    appointmentConfig: {
        startTime: "12:00 am",
        endTime: "11:59 pm"
    };
    refreshSearchAppointmentGrid: boolean = false;
    warningMessage: string;
    reassignMessage: string;
    reinstateType: string;
    selectedTabIndex: number = 0;
    unAssignedTherapistId: number = 0;
    private _clientImageObj = new ReplaySubject<number>(0);
    private _priceTypeYieldPrice: number = 0;
    clientImageObj: any = this._clientImageObj.asObservable();
    isaddClientFromClientInfo: boolean = false;
    isPurchase:boolean = false;

    clientImageChange(img: any) {
        this._clientImageObj.next(img)
    }

    tempAppointmentId: number = 0;
    clientSelect: any = 1;
    cancelAll: UntypedFormGroup = this.Form.group({
        reason: ['', Validators.required],
        comments: ''
    });
    reinstateAll: UntypedFormGroup = this.Form.group({
        toggle: false
    });
    tempRecordCreated: boolean = false;
    canceledAppointmentDate: Date[] = [];
    slideServiceappoinementDate: any;
    allTherapistDetail: TherapistDetails[] = [];
    isViewOnly: boolean = false;
    hasActiveTempHold = false;
    isFromWaitlist: boolean = false;
    isFromLostDeniedBusiness: boolean = false;
    isFromLocationBlock: boolean = false;
    memberImageUrl: string;
    PackageServices: any[];
    PackageStandardService:any[];
    isGroupNotify: boolean = false;
    isShowList: boolean = false;
    noOfCompPriceChange:any;
    noOfCompServiceDetails:any;
    noOfCompRetailDetails:any;
    componentInitialized:boolean=false;
    packageType:string;
    isVipClient = false;
    cancelGridData: any[] = []; 
    isDayPass = false;
    dayPassId:any;
    isDayPassPaid= false;
    appointmentCancelledWithCharge = false;

    constructor(private httpRequestService: HttpRequestService, private http: HttpServiceCall, private utils: SpaUtilities, private userAlerts: UserAlerts,
        private Form: UntypedFormBuilder, private breakPoint: BreakPointAccess, private PropertyInfo: SpaPropertyInformation, public formatphno: FormatText, public localization: SpaLocalization) {
    }
    getappointmentList(url: string) {
        return this.httpRequestService.GetHttpRequest(url);
    }

    BindClientInfo(clientId: number, hidePersonalInfo: boolean = false) {

        this.http.CallApiWithCallback<any[]>({
            host: GlobalConst.Host.spaManagement,
            success: this.successCallback.bind(this),
            error: this.utils.errorCallback.bind(this),
            callDesc: "getClientInfo",
            uriParams: { id: clientId },
            method: HttpMethod.Get,
            showError: false,
            extraParams: [hidePersonalInfo]
        });
    }

    async BindClientBlocksInfo(clientId: number) {
        try {
            return await this.http.CallApiAsync<any[]>({
                host: GlobalConst.Host.spaManagement,
                callDesc: "GetClientBlockById",
                uriParams: { id: clientId },
                method: HttpMethod.Get,
                showError: false,
            });
        } catch (e) {
            this.http.exceptionHandle(e);
        }
    }
    async GetLinkCodes() {
        let result = await this.http.CallApiAsync<any>({
            host: GlobalConst.Host.spaManagement,
            callDesc: "GetLinkCodes",
            method: HttpMethod.Get,
            uriParams: { propertyDate: this.utils.convertDateFormat(this.PropertyInfo.CurrentDate), showInActive: false },
        });
        if (result && result.result) return result.result;
        return null;
    }

    async GetLinkCodesWithDate(appDate: any) {
        let result = await this.http.CallApiAsync<any>({
            host: GlobalConst.Host.spaManagement,
            callDesc: "GetLinkCodes",
            method: HttpMethod.Get,
            uriParams: { propertyDate: this.utils.convertDateFormat(appDate), showInActive: false },
        });
        if (result && result.result) return result.result;
        return null;
    }

    async GetBreakTypes() {
        let result = await this.http.CallApiAsync<any>({
            host: GlobalConst.Host.spaManagement,
            callDesc: "GetAllBreakTypes",
            method: HttpMethod.Get
        });
        if (result && result.result) return result.result;
        return null;
    }

    /**
     * @function GetClientAppointmentsByStatus
     * @description Fetch all appointments of the client by status and date
     * @param clientId
     * @param status
     * @param date
     * @param successCallback
     * @param errorCallback
     */
    GetClientAppointmentsByStatus(clientId, status, date, successCallback, errorCallback, outletId = 0) {
        this.http.CallApiWithCallback<any>({
            host: GlobalConst.Host.schedule,
            success: successCallback,
            error: errorCallback,
            callDesc: "GetAppointmentsByStatus",
            uriParams: { clientId: clientId, status: status, date: date, outletId: outletId},
            method: HttpMethod.Get,
            showError: true,
            extraParams: []
        });
    }

    /**
     * @function GetShopItemByName
     * @param itemName
     * @param successCallback
     * @param errorCallback
     */
    GetShopItemByName(itemName: any, successCallback, errorCallback) {
        this.http.CallApiWithCallback<any>({
            host: GlobalConst.Host.retailManagement,
            success: successCallback,
            error: errorCallback,
            callDesc: "GetShopItemByName",
            method: HttpMethod.Get,
            uriParams: { itemName: itemName },
            showError: true,
            extraParams: []
        });
    }

    /**
     * @function GetShopItemByType
     * @param itemType
     * @param successCallback
     * @param errorCallback
     */
    GetShopItemByType(itemType: number, successCallback, errorCallback) {
        this.http.CallApiWithCallback<any>({
            host: GlobalConst.Host.retailManagement,
            success: successCallback,
            error: errorCallback,
            callDesc: "GetRetailItemByItemType",
            method: HttpMethod.Get,
            uriParams: {
                type: itemType
            },
            showError: true,
            extraParams: []
        });
    }

    async GetShopItemsAsync(itemType: number) {
        let result = await this.http.CallApiAsync<any>({
          host: GlobalConst.Host.retailManagement,
          callDesc: "GetRetailItemByItemType",
          method: HttpMethod.Get,
          uriParams: {
            type: itemType
          }
        });
        return result.result;
      }

    async CheckIfItemIsAvailableOnTheSelectedOutlet(outletId: number, itemType: number): Promise<boolean> {
        let shopItems = await this.GetShopItemsAsync(itemType);
        const selectedOutletItems = shopItems.filter(si => si.outletItem.some(o => o.outletId == outletId));
        let depositItemInSelectedOutletItem = selectedOutletItems.filter(x => x.retailItemDetail.itemType == itemType);
        return depositItemInSelectedOutletItem.length > 0;
    }


    /**
     * @function CancelAppointment
     * @description cancels the given appointment
     * @param appointment
     * @param successCallback
     * @param errorCallback
     */
    CancelAppointment(appointmentData: any, isCancelWithAutoTransaction: boolean, successCallback, errorCallback) {
        this.http.CallApiWithCallback<boolean>({
            host: GlobalConst.Host.schedule,
            success: successCallback,
            error: errorCallback,
            callDesc: (isCancelWithAutoTransaction) ? "CancelAllAppointmentsWithCharge" : "CancelAppointment",
            method: HttpMethod.Put,
            body: appointmentData,
            showError: true,
            extraParams: []
        });
    }

    // GetAppointmentConfiguration() {
    //     this.http.CallApiWithCallback<any[]>({
    //         host: GlobalConst.Host.spaManagement,
    //         success: this.successCallback.bind(this),
    //         error: this.utils.errorCallback.bind(this),
    //         callDesc: "GetAppointmentConfiguration",
    //         uriParams: { module: GlobalConst.Module.appointment },
    //         method: HttpMethod.Get,
    //         showError: false,
    //         extraParams: ["dataBelongTo"]
    //     });
    // }

    // async GetAppointmentConfigurationAsync() {
    //     let result = await this.http.CallApiAsync<any>({
    //         host: GlobalConst.Host.spaManagement,
    //         callDesc: "GetAppointmentConfiguration",
    //         method: HttpMethod.Get,
    //         uriParams: { module: GlobalConst.Module.appointment },
    //     });
    //     if (result && result.result) return result.result;
    //     return null;
    // }

    /**
     * @function GetMultiPacksForClients
     * @description Get multipacks by ClientId
     * @param clientIds
     */
    GetMultiPacksForClients(clientIds: any, successCallback, errorCallback) {
        this.http.CallApiWithCallback<any>({
            host: GlobalConst.Host.retailPOS,
            success: successCallback,
            error: errorCallback,
            callDesc: "GetMultiPacks",
            body: clientIds,
            method: HttpMethod.Put,
            showError: true,
            extraParams: []
        });
    }

    /**
     * @function LinkMultiPack - Spa schedule method
     * @description links client multipaack with appointment by Id
     * @param appointmentId - Appointment Id to be linked with clientMultiPack
     * @param clientMultiPackId
     */
    LinkMultiPack = (appointmentId, clientMultiPackId, multipackPrice, successCallback, errorCallback) => {
        this.http.CallApiWithCallback<any>({
            host: GlobalConst.Host.schedule,
            success: successCallback,
            error: errorCallback,
            callDesc: "LinkMultiPack",
            uriParams: {
                id: appointmentId,
                multipackId: clientMultiPackId,
                multipackPrice: multipackPrice
            },
            method: HttpMethod.Put,
            showError: true,
            extraParams: []
        });
    }

    /**
     * @function LinkMultiPacks - Spa schedule method
     * @description links client multipaack with appointment by Id
     * @param listOfMultipacks - key value pair of appointment and clientmultipackid
     */
    LinkMultiPacks = (listOfMultipacks: linkMultipackData[], successCallback, errorCallback) => {
        this.http.CallApiWithCallback<any>({
            host: GlobalConst.Host.schedule,
            success: successCallback,
            error: errorCallback,
            callDesc: "LinkMultiPacks",
            body: listOfMultipacks,
            method: HttpMethod.Put,
            showError: true,
            extraParams: []
        });
    }

    /**
     * @function UnlinkMultipack - Spa schedule method
     * @description unlinks client multipaack with appointment by Ids
     * @param appointmentId - Appointment Id to be linked with clientMultiPack
     */
    UnlinkMultipack = (multipackData, successCallback, errorCallback) => {
        this.http.CallApiWithCallback<any>({
            host: GlobalConst.Host.schedule,
            success: successCallback,
            error: errorCallback,
            callDesc: "UnlinkMultiPack",
            body: multipackData,
            method: HttpMethod.Put,
            showError: true,
            extraParams: []
        });
    }

    /**
     * @function UnlinkMultiPacks - Spa schedule method
     * @description links client multipack with appointment by Ids
     * @param appointmentId - Appointment Id to be linked with clientMultiPack
     */
    UnlinkMultipacks = (appointmentIds, successCallback, errorCallback) => {
        this.http.CallApiWithCallback<any>({
            host: GlobalConst.Host.schedule,
            success: successCallback,
            error: errorCallback,
            callDesc: "UnlinkMultiPacks",
            body: appointmentIds,
            method: HttpMethod.Put,
            showError: true,
            extraParams: []
        });
    }


    /**
     * @function redeemMultiPack - spa retail pos method
     * @description redeems client multipack i.e reduces remaining sessions and  logs
     * @param clientMultiPackId
     */
    redeemMultiPack = (clientMultiPackId: any) => {
        this.http.CallApiWithCallback<any>({
            host: GlobalConst.Host.retailPOS,
            success: this.successCallback.bind(this),
            error: this.utils.errorCallback.bind(this),
            callDesc: "RedeemMultiPack",
            body: clientMultiPackId,
            method: HttpMethod.Put,
            showError: true,
            extraParams: []
        });
    }

    /**
     * @function reinstateMultiPack - spa retail pos method
     * @description reintate client multipack i.e increases remaining sessions and  logs
     * @param clientMultiPackId
     */
    reinstateMultiPack = (clientMultiPackId: any) => {
        this.http.CallApiWithCallback<any>({
            host: GlobalConst.Host.retailPOS,
            success: this.successCallback.bind(this),
            error: this.utils.errorCallback.bind(this),
            callDesc: "ReinstateMultiPack",
            body: clientMultiPackId,
            method: HttpMethod.Put,
            showError: true,
            extraParams: []
        });
    }

    /**
     * @function GetAllTherapist
     * @description get the unassigned therapist Id
     * @param clientMultiPackId
     */
    LoadTherapists() {
        const result = this.http.CallApiAsync({
            host: GlobalConst.Host.spaManagement,
            callDesc: "GetAllTherapist",
            method: HttpMethod.Get,
            showError: true
        });
        return result.then((res: any) => {
            const allTherapists = <any>res.result;
            this.allTherapistDetail = allTherapists;
            const therapistDetail = allTherapists.filter(t => t.code == 'UNASSIGNED');
            if (therapistDetail && therapistDetail.length > 0) {
                this.unAssignedTherapistId = therapistDetail[0].id;
            }
            return allTherapists;
        });
    }

    successCallback<T>(result: BaseResponse<T>, callDesc: string, extraParams: any[]): void {
        if (callDesc == "getClientInfo") {
            let displayContactInfo: boolean = true;
            if (extraParams[0]) { // RBAC change
                displayContactInfo = this.breakPoint.CheckForAccess([GlobalConst.SPAScheduleBreakPoint.PersonalInfoVisibleOnRecap], false);
            }
            let resultData = <any>result.result;
            if(resultData.clientDetail.clientType == ClientType.Member){
              this.memberImageUrl = resultData.clientDetail.photoUrl;
            }
            else{
              this.memberImageUrl = null;
            }
            let formattedPhoneNo, formattedEmail;
            let dob = resultData.clientDetail.dateOfBirth;
            let age, address, emails, phones;
            if (dob) {
                age = this.utils.getAge(this.utils.getDate(dob))
            }
            if (resultData.addresses && resultData.addresses.length > 0 && displayContactInfo) {
                let adrs = resultData.addresses[0];
                address = `${adrs.line1 ? adrs.line1 + "," : ""}
            ${adrs.line2 ? adrs.line2 + "," : ""}
            ${adrs.line3 ? adrs.line3 + "," : ""}
            ${adrs.city ? adrs.city + "," : ""}
            ${adrs.state ? adrs.state + "," : ""}
            ${adrs.country ? adrs.country : ""}
            ${adrs.zip ? " " + adrs.zip : ""}`
            }
            if (resultData.phoneNumbers && resultData.phoneNumbers.length > 0 && displayContactInfo) {
                phones = resultData.phoneNumbers.map(element => { return element.number });
                let PhnoExt = [];
                let primaryPhone = resultData.phoneNumbers.find(x => x.isPrimary);
                if (primaryPhone) {
                    //ADDING FOR EXTENSION FIELD
                    formattedPhoneNo = this.utils.getFormattedPhNo(primaryPhone);
                }
                else {
                    formattedPhoneNo = resultData.phoneNumbers.map(x => {
                        //ADDING FOR EXTENSION FIELD
                        return this.utils.getFormattedPhNo(x);
                    }).join(' | ')
                }
            }
            if (resultData.emails && resultData.emails.length > 0 && displayContactInfo) {
                emails = resultData.emails.map(element => { return element.emailId });

                let primaryEmail = resultData.emails.find(x => x.isPrimary);
                if (primaryEmail) {
                    formattedEmail = primaryEmail.emailId
                }
                else {
                    formattedEmail = resultData.emails.map(x => {
                        return x.emailId;
                    }).join(' | ')
                }
            }

            this.clientInfo = {
                firstName: resultData.clientDetail.firstName,
                lastName: resultData.clientDetail.lastName,
                age: age ? age : "",
                gender: resultData.clientDetail.gender,
                address: address ? address.trim() : "",
                mail: emails ? emails : [],
                phone: phones ? phones : [],
                formattedEmail: formattedEmail,
                formattedPhoneNo: formattedPhoneNo,
                priceTypeId: resultData.clientDetail.priceTypeId,
                clientType: resultData.clientDetail.clientType,
                genderPreference: resultData.clientDetail.genderPreference,
                isPurged: resultData.clientDetail.isPurged,
                consentPolicyId:resultData.clientDetail.consentPolicyId,
                consentExpiryDate:resultData.clientDetail.consentExpiryDate,
                consent:resultData.clientDetail.consent,
                guestId: resultData.clientDetail.guestId,
                vip: resultData.clientDetail.vip,
                clientLinkId: resultData.clientDetail.clientLinkId
            }
            this.comments = resultData.clientDetail.comments;
        }
        if (callDesc == "GetAppointmentConfiguration") {
            this.appointmentConfiguration = <any>result.result;
        }
        if (callDesc == "GetAllTherapist") {
            let allTherapists = <any>result.result;
            this.allTherapistDetail = allTherapists;
            let therapistDetail = allTherapists.filter(t => t.code == 'UNASSIGNED')
            if (therapistDetail && therapistDetail.length > 0) {
                this.unAssignedTherapistId = therapistDetail[0].id
            }
        }
    }

    /**
 * This will prompt for unsaved changes.
 * will happen only if the form is dirty.
 * @param Dialog Current active Dialog reference.
 */
    promptUnsavedChangesDialog(Dialog: any) {
        if (this.activeFormGroup.dirty) {
            this.userAlerts.showPrompt(GlobalConst.PromptType.UnsavedChanges, this.PopupCallback.bind(this), Dialog)
        } else {
            Dialog.close();
        }
    }

    private PopupCallback(result: string, extraParams?: any) {
        if (result.toLowerCase() === GlobalConst.ButtonOptions.Yes) {
            extraParams.close();
        }
    }

    public async CheckPackageYield(packageId: number, date: Date): Promise<boolean> {
        let result = await this.http.CallApiAsync<boolean>({
            callDesc: "IsPackageYieldAvailable",
            uriParams: { packageId: packageId, date: this.utils.formatDate(date) },
            host: GlobalConst.Host.spaManagement,
            method: HttpMethod.Get
        });

        return result.result;
    }

    async InvokeServiceCallAsync(route: string, domain: GlobalConst.Host, callType: HttpMethod, uriParams?: any, body?: any, queryString?: any): Promise<BaseResponse<any>> {
        try {
            return await this.http.CallApiAsync({
                host: domain,
                callDesc: route,
                method: callType,
                body: body,
                uriParams: uriParams,
                queryString: queryString
            });
        } catch (e) {
            this.http.exceptionHandle(e);
        }
    }

    public getServicePriceWithPriceType_Move_Copy(serviceArray: any, clientPriceTypeId: number, serviceAggregate: Service, bookingDate: Date) {
        let serviceFinalPrice: number = this.utils.GetServiceBasePrice(serviceAggregate.serviceDetail, serviceAggregate.serviceSeasonalDates, bookingDate);
        let servicePriceTypeArray: any = serviceArray.servicePriceType
        if (clientPriceTypeId > 0 && serviceArray && servicePriceTypeArray && servicePriceTypeArray.length > 0 && servicePriceTypeArray.filter(y => y.priceTypeId == clientPriceTypeId).length > 0) {
            serviceFinalPrice = servicePriceTypeArray.filter(y => y.priceTypeId == clientPriceTypeId)[0].price;
            return serviceFinalPrice;
        }
        return serviceFinalPrice;
    }

    public async getServicePriceWithPriceType(serviceArray: any, clientPriceTypeId: number): Promise<number> {
        let serviceFinalPrice: number = serviceArray.price;
        let servicePriceTypeArray: any = serviceArray.servicePriceType
        if (clientPriceTypeId > 0 && serviceArray && servicePriceTypeArray && servicePriceTypeArray.length > 0 && servicePriceTypeArray.filter(y => y.priceTypeId == clientPriceTypeId).length > 0) {
            this._priceTypeYieldPrice = 0;
            let actualPrice = servicePriceTypeArray.filter(y => y.priceTypeId == clientPriceTypeId)[0].price;
            this._priceTypeYieldPrice = await this.CalculateYieldPrice(actualPrice, serviceArray.id, this.slideServiceappoinementDate);
            return this._priceTypeYieldPrice;
        }
        return serviceFinalPrice;
    }

    public async CalculateYieldPrice(price: any, serviceId: any, appointmentDate): Promise<number> {
        let yieldPrice = price;
        let result = await this.http.CallApiAsync<any>({
            host: GlobalConst.Host.schedule,
            callDesc: "GetServicePriceBasedOnYield",
            method: HttpMethod.Get,
            uriParams: { appointmentDate: appointmentDate, serviceId: serviceId, originalPrice: Number(price) },
        });

        if (result && result.result) {
            yieldPrice = result.result;
        }
        return yieldPrice;
    }

    public AddMultiTherapistAppointmentToGrid(appoinments: appointment[]): appointment[] | CalendarGridBodyProps[] {
        /*  Implementation for multiple therapist to single appointment*/
        appoinments.forEach(x => {
            x.appointmentDetail.startTimeObj = this.localization.getDate(x.appointmentDetail.startTime)
            x.appointmentDetail.endTimeObj = this.localization.getDate(x.appointmentDetail.endTime)
            if (x.appointmentTherapists.length > 1) {
                x.appointmentTherapists.forEach((y, index) => {
                    if (index > 0) {
                        let temp = _.cloneDeep(x);
                        let pulledtherapist = temp.appointmentTherapists.slice(0, index);
                        temp.appointmentTherapists.splice(0, index);
                        pulledtherapist.forEach(o => {
                            temp.appointmentTherapists.push(o);
                        })
                        appoinments.push(temp);
                    }
                });
            }
        });
        /* */
        return appoinments;
    }

    public async GetAppointmentsByFilter(actionfilterReq: AppointmentActionFilter): Promise<Appoinment[]> {
        const response = await this.http.CallApiAsync<Appoinment[]>({
            callDesc: 'GetAppointmentsByFilter',
            method: HttpMethod.Put,
            host: GlobalConst.Host.schedule,
            body: actionfilterReq
        });
        return response.result;
    }

    public async GetAppointmentConfigAsync(): Promise<any> {
        const response = await this.http.CallApiAsync<any>({
            callDesc: 'GetAppointmentConfiguration',
            method: HttpMethod.Get,
            host: GlobalConst.Host.spaManagement
        });
        return response.result;
    }

    public async GetServiceAggregate(serviceId: number[]): Promise<Service[]> {
        let keyValue: any = { key: "id", value: serviceId };

        let result = await this.http.CallApiAsync<Service[]>({
            callDesc: "GetServices",
            method: HttpMethod.Get,
            host: GlobalConst.Host.spaManagement,
            queryString: keyValue
        });
        return result.result;
    }

    public async GetSwitchConfigurations() {
        if (this.managementData && this.managementData["AppointmentConfigurations"]) {
            return this.managementData["AppointmentConfigurations"];
        } else {
            let response = await this.GetAppointmentConfigAsync();
            this.appointmentConfiguration = response.result;
            this.managementData["AppointmentConfigurations"] = this.appointmentConfiguration;
            return this.managementData["AppointmentConfigurations"];
        }
    }

    public async IsGuaranteePaymentRequired(): Promise<boolean> {
        let isGuaranteePaymentRequired = false;
        let appointmentConfig = await this.GetSwitchConfigurations();
        if (appointmentConfig) {
            isGuaranteePaymentRequired = appointmentConfig.APPOINTMENT_REQUIRE_GUARANTEE_METHOD;
        }
        return isGuaranteePaymentRequired;
    }

    public async searchAppointmentClients(name: string, requestUid: string): Promise<any> {
        if (!name) {
            return null;
        }
        let keyValue: KeyValuePair = { key: 'requestUid', value: [requestUid] };
        let appointmentResult = this.http.CallApiAsync<any[]>({
            host: GlobalConst.Host.schedule,
            callDesc: "AppointmentSearchClientFilter",
            method: HttpMethod.Get,
            uriParams: { searchValue: name },
            showError: false,
            queryString: keyValue
        });
        if (name.length < 3) {
            let appointmentSearchResponse = await appointmentResult;
            return [appointmentSearchResponse];
        }
        let clientResult = this.http.CallApiAsync<any[]>({
            host: GlobalConst.Host.spaManagement,
            callDesc: "clientSearch",
            method: HttpMethod.Get,
            uriParams: { name: encodeURIComponent(name),searchType:GlobalConst.clientSearchType.All, requestUid: requestUid },
            showError: false
        });
        return await Promise.all([appointmentResult, clientResult]);
    }

    public async getAvailableTherapistAndLocations(serviceId, filterDate, isAllService = false): Promise<any> {
        let keyValue: any = { key: "isAllService", value:  [isAllService]};
        const response = await this.http.CallApiAsync<any>({
            callDesc: 'MoveCopyFilter',
            method: HttpMethod.Get,
            host: GlobalConst.Host.schedule,
            uriParams: { serviceId: serviceId, date: filterDate },
            showError: true,
            queryString: keyValue
        });
        return response.result;
    }

    public async getAppointmentByDate(filterDate): Promise<any> {
        const fromDate = this.utils.formatDate(filterDate);
        const toDate = this.utils.formatDate(filterDate);
        const response = await this.http.CallApiAsync<any>({
            callDesc: 'GetAppointments',
            method: HttpMethod.Get,
            host: GlobalConst.Host.schedule,
            uriParams: { FromDate: fromDate, ToDate: toDate },
            showError: true
        });
        return response.result;
    }
    public async getDataforEFormManualNotify(serviceId: number){
        const response = await this.http.CallApiAsync<any>({
            callDesc:"GetDataForEformManualNotify",
            method: HttpMethod.Get,
            host: GlobalConst.Host.schedule,
            uriParams:{serviceId,productId: 1},
            showError: true
        });
        return response.result;
    }
    public async updateConsentPolicyDetailsForGuestId(applyPolicy: ApplyPolicy){
        this.http.CallApiAsync<any[]>({
            host: GlobalConst.Host.spaManagement,
            callDesc: "UpdateConsentPolicyDetailsForGuestId",
            method: HttpMethod.Post,
            body: applyPolicy,
            showError: false
          });
    }

    public insertIntoSelectedProducts(shopItemData: any, amount: number, selectedProducts: SelectedProducts[], isDeposit: boolean){
        selectedProducts.push({
          ItemId: shopItemData[0].retailItemDetail.id,
          ExternalPOSItemId: shopItemData[0].retailItemDetail.externalPOSId,
          ItemDescription: shopItemData[0].retailItemDetail.itemDescription,
          ItemType: shopItemData[0].retailItemDetail.itemType,
          retailItemType: shopItemData[0].retailItemDetail.itemType,
          ProductName: shopItemData[0].retailItemDetail.itemDescription,
          ServiceId: 0,
          ProductPrice: amount,
          SalesPrice: amount,
          Noofitems: 1,
          Discount: 0,
          DiscountPercentage: 0,
          DiscountTypeId: 0,
          isReturn: isDeposit ? true : false,
          category: shopItemData[0].retailItemDetail.category,
          isEditDisabled: true,
          isModificationRestricted: true,
          isGroupingKey: shopItemData[0].retailItemDetail.isGroupingKey,
          isPackagedItem: shopItemData[0].retailItemDetail.isPackagedItem,
          PackageItemId: 0,
          MultiPack: shopItemData[0].retailItemDetail.isMultiPack,
          ClientMultiPackId: 0,
          PackageGroupId: 0,
          isOpenPricedItem: true,
          costPrice: shopItemData[0].retailItemDetail.costPrice,
          marginPercentage: shopItemData[0].retailItemDetail.marginPercentage,
          allowEarn: shopItemData[0].retailItemDetail.allowEarn,
          discountComments: '',
          discountReason: 0
        });
      }

    public async waiveOffCancellationOrNoShowFee(appointmentId: number): Promise<any> {
        let result = await this.http.CallApiAsync<any[]>({
            host: GlobalConst.Host.schedule,
            callDesc: "WaiveOffCancellationOrNoShowFee",
            method: HttpMethod.Put,
            uriParams: { appointmentId: appointmentId },    
            showError: true
        });
        return result.result;
    }

    public async updateAppointmentWithcharge(appointments: any[], outletId: number): Promise<any> {
        let body = this.convertToCancellationNoShowCharge(appointments, outletId);
        let result = await this.http.CallApiAsync<any[]>({
            host: GlobalConst.Host.schedule,
            callDesc: "UpdateAppointmentWithcharge",
            method: HttpMethod.Put,
            body: body,    
            showError: false
        });
        return result.result;
    }

    public async getAllServicesGroupsWithServices(isClass: boolean): Promise<ServiceGroupsWithServices[]>{
        let response: any = await this.http.CallApiAsync({
            callDesc: "GetAllServicesGroupsWithServices",
            host: GlobalConst.Host.spaManagement,
            method: HttpMethod.Get,
            showError: true,
            queryString: { key: "isClass", value: [isClass] }
        });
        return this.mapIsSelected(response.result);
    }

    mapIsSelected(data: ServiceGroupsWithServices[]): ServiceGroupsWithServices[] {
        if (data && data.length > 0) {
            data.forEach(group => {
                group.selected = false;
                group.services.forEach(service => {
                    service.groupId = group.id;
                    service.selected = false;
                });
            });
        }
        return data;
    }

    async getCancellationNoShowFeeForAppointments(appointmentIds: number[], outletId: number, isCancellation: boolean){
        let response: any = await this.http.CallApiAsync({
            callDesc: "GetCancellationNoShowCharge",
            host: GlobalConst.Host.schedule,
            method: HttpMethod.Put,
            showError: false,
            body: this.formCancelaltionNoShowFeeRequest(appointmentIds, outletId, isCancellation)
        });
        return response.result;
    }

    async getAppointmentByClient(data: AppointmentFilterRequest) {
        let response: any = await this.http.CallApiAsync({
            callDesc: "GetAppointmentsByClient",
            host: GlobalConst.Host.schedule,
            method: HttpMethod.Put,
            showError: true,
            body: data
        })
        return response.result;
    }

    formCancelaltionNoShowFeeRequest(appointmentIds: number[], outletId: number, isCancellation: boolean): CancellationNoShowFeeRequest {
        return {
            appointmentIds: appointmentIds,
            outletId: outletId,
            isCancellation: isCancellation
        };
    }

    convertToCancellationNoShowCharge(appointments: any, outletId: number): CancellationNoShowChargeEventModel[]{
        let cancellationNoShowWaiveOffAppointments = [];
        appointments?.forEach(data => {
            let cancellationNoShowAppointments: CancellationNoShowChargeEventModel = {
                id: 0,
                appointmentId: data.id,
                appointmentDate: data.appointmentDate,
                transactionId: 0,
                cancelReason: data.cancelReason,
                cancelComments: data.cancelComments,
                isCancellation: data.isCancellation,
                actualAmount: data.cancelFee,
                chargedAmount: 0,
                status: CancellationNoShowChargeStatus.WaivedOff,
                outletId: outletId,
                ticketNumber: '',
                policyId: data.policyId
            }
            cancellationNoShowWaiveOffAppointments.push(cancellationNoShowAppointments);
        });
        return cancellationNoShowWaiveOffAppointments;
    }
    public async GetDayPassDetailsByIds(ids): Promise<any[]> {
        const response = await this.http.CallApiAsync<any[]>({
            callDesc: 'GetDayPassDetailsByIds',
            method: HttpMethod.Put,
            host: GlobalConst.Host.schedule,
            body: ids
        });
        return response.result;
    }
    public async GetRetailItemsByDayPassForCancel(body): Promise<any[]> {
        const response = await this.http.CallApiAsync<any[]>({
            callDesc: 'GetRetailItemsByDayPassForCancel',
            method: HttpMethod.Put,
            host: GlobalConst.Host.schedule,
            body: body
        });
        return response.result;
    }

    public buildCheckoutAppointmentBody(transactionDetail: TransactionDetail[], transactionId: number, ticketNumber: string): AppointmentCheckoutWithTransaction[] {
        let checkoutBody: AppointmentCheckoutWithTransaction[] = [];
        if (transactionDetail?.length == 0 || transactionId == 0 || ticketNumber?.length == 0) {
            return checkoutBody;
        }
        let checkoutItems = new Set(transactionDetail.filter(t => t.itemType == RetailItemType.SpaServices || t.itemType == RetailItemType.AppointmentAddon).map(t => t.sourceTypeId));
        checkoutItems?.forEach(itemId => {

            let appointmentTransactionDetail = transactionDetail.filter(t => t.sourceTypeId == itemId);
            let appointmentDetails = appointmentTransactionDetail.filter(t => (t?.customFeeId ?? 0) == 0);
            let customFeeDetails = appointmentTransactionDetail.filter(t => (t?.customFeeId ?? 0) != 0);
            let appointmentCustomFee: AppointmentCustomFee[] = [];

            customFeeDetails.forEach(customFee => {
                appointmentCustomFee.push({
                    customFeeId: customFee.customFeeId,
                    appointmentId: customFee.sourceTypeId,
                    amount: customFee.unitPrice,
                    taxAmount: customFee.tax
                });
            });

            appointmentDetails.forEach(appointment => {
                let gratuity: number = 0, serviceCharge: number = 0;
                if (appointment.serviceChargeGratuity && appointment.serviceChargeGratuity.length > 0) {
                    let gratuityObj = appointment.serviceChargeGratuity.find(x => x.totalGratuity > 0)
                    gratuity = gratuityObj && gratuityObj.totalGratuity > 0 ? gratuityObj.totalGratuity : 0;
                    let serviceChargeObj = appointment.serviceChargeGratuity.find(x => x.totalServiceCharge > 0)
                    serviceCharge = serviceChargeObj && serviceChargeObj.totalServiceCharge > 0 ? serviceChargeObj.totalServiceCharge : 0;
                }

                checkoutBody.push({
                    appointmentId: appointment.itemType == RetailItemType.AppointmentAddon ? 0 : appointment.sourceTypeId,
                    transactionId: transactionId,
                    transactionDetailId: appointment.id,
                    serviceCharge: serviceCharge,
                    gratuity: gratuity,
                    tax: appointment.tax,
                    retailTicketNumber: ticketNumber,
                    appointmentCustomFee: appointmentCustomFee,
                    appointmentAddonId: appointment.itemType == RetailItemType.AppointmentAddon ? appointment.sourceTypeId : 0,
                    isAppointmentCheckedOut: true
                });
            });
        });

        return checkoutBody;
    }

    buildDepositModelForAppointment(depositDetails: TransactionDetail[], transactionId: number): DepositAPIModel[] {
        let depositObj: DepositAPIModel[] = [];
        
        depositDetails.forEach(deposit => {
            let gratuity: number = 0, serviceCharge: number = 0;

            if (deposit.serviceChargeGratuity && deposit.serviceChargeGratuity.length > 0) {
                let gratuityObj = deposit.serviceChargeGratuity.find(x => x.totalGratuity > 0)
                gratuity = gratuityObj && gratuityObj.totalGratuity > 0 ? gratuityObj.totalGratuity : 0;
                let serviceChargeObj = deposit.serviceChargeGratuity.find(x => x.totalServiceCharge > 0)
                serviceCharge = serviceChargeObj && serviceChargeObj.totalServiceCharge > 0 ? serviceChargeObj.totalServiceCharge : 0;
            }

            depositObj.push({
                id: 0,
                appointmentId: deposit.sourceType == CustomFeeSourceType.Appointment ? deposit.sourceTypeId : 0,
                amount: deposit.totalAmount,
                gratuity: gratuity,
                serviceCharge: serviceCharge,
                refundAmount: 0,
                depositTransactionId: transactionId,
                depositTransactionDetailId: deposit.id,
                refundTransactionId: 0,
                isVoided: false,
                status: DepositTransactionStatus.Closed,
                retailItemId: 0,
                packageGroupId: null,
                typeMappingId: deposit.sourceType == CustomFeeSourceType.RetailItem ? deposit.sourceTypeId : 0
            });
        }
        );
        return depositObj;
    }
}
