import { Component, OnInit, Output, EventEmitter,ViewContainerRef, Input } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, UntypedFormControl } from '@angular/forms';
import { RetailLocalization } from 'src/app/retail/common/localization/retail-localization';
import { ReportBusinessService } from '../../business/report-business.service';
import { ReportDataService } from '../../data/report-data.services';
import { RetailPropertyInformation } from 'src/app/retail/common/services/retail-property-information.service';
import { RetailReportControl, ValidationMessage } from '../../basereport/base-report.component';
import { RetailFunctionalityBusiness } from 'src/app/retail/shared/business/retail-functionality.business';
import { DropDownData } from '../../business/report.modals';


@Component({
  selector: 'app-outlet-sales',
  templateUrl: './outlet-sales.component.html',
  styleUrls: ['./outlet-sales.component.scss']
})
export class OutletSalesComponent implements OnInit {

  terminalSalesFrmGrp: UntypedFormGroup;
  @Input() customReport: any;
  @Output() formReady = new EventEmitter();
  @Output() IsValidSelection = new EventEmitter<ValidationMessage>();
  public captions: any = this.localization.captions.reports;
  validateOutlets: ValidationMessage = {
    dropDownName: this.localization.captions.reports.PleaseSelectAnyOutlet,
    validity: false
  };
  defaultData: DropDownData = {
    id: 0,
    code: 'ALL',
    description: 'All',
    showInDropDown: true
  };
  outlets: DropDownData[];
  functionalities: { [key: string]: boolean} = {};
  editPatchValue : any;

  constructor(private fb: UntypedFormBuilder, public localization: RetailLocalization, public business: ReportBusinessService, 
    private dataService: ReportDataService, private propertyInfo: RetailPropertyInformation,private container: ViewContainerRef) { }

  ngOnInit(): void {
    this.terminalSalesFrmGrp = this.fb.group({
      outlets: []
    });
    this.formReady.emit(this.terminalSalesFrmGrp);
    if(this.container) {
      const _parentInjector = this.container.injector;
      const _parent: RetailReportControl = _parentInjector.get<RetailReportControl>(RetailReportControl);
      this.editPatchValue = _parent.editableValue;
      if(!_parent.edit)
      {
        this.getOutlets();
      }
    }
  }

  getFormControlValue(event) {
    if (event[0] === 'outlets') {
      this.terminalSalesFrmGrp.setControl('outlets', event[1]);
    }
  }

  async getOutlets() {
    this.outlets = await this.dataService.getAllOutletsByUser();
  }

}
