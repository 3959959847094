import { AgDropdownConfig, DropdownOptions } from './../../Models/ag-models';
import { Component, OnInit, Input, Output, EventEmitter, ViewEncapsulation, ChangeDetectionStrategy, AfterViewInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validator } from '@angular/forms';
import { from, isObservable, Observable } from 'rxjs';
import { Localization } from '../../localization/localization';
import { MatDialog } from '@angular/material/dialog';
import { AgMenuEditComponent } from '../ag-menu-edit/ag-menu-edit.component';
import { cloneDeep, uniqWith, differenceBy, intersectionBy, isEqual } from 'lodash';
@Component({
  selector: "app-ag-dropdown",
  templateUrl: "./ag-dropdown.component.html",
  styleUrls: ["./ag-dropdown.component.scss"],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AgDropdownComponent implements OnInit, AfterViewInit {
  @Input() useVirtualScroll: boolean = false;
  className: string;
  errorMessage: string;
  form: UntypedFormGroup;
  formControlName: string;
  placeHolder: string;
  selectOptions: Observable<DropdownOptions[]> | Promise<DropdownOptions[]>;
  isFirstEmpty: boolean;
  isSelect: boolean;
  showRequired: boolean;
  isDisabled = false;
  selectedData: any;
  @Output() selectChange = new EventEmitter();
  captions: any;
  isMultiple: boolean;
  customErrorMessage: string;
  defaultSelectededOptionValue: DropdownOptions;
  isAll = false;
  dropdownWithSearch = false;
  isReportDropdown = false;
  inputSearch: string;
  automation: string;
  Selectedfilteredlistcount: number;
  dataSourse: DropdownOptions[] = [];
  field: {
    label?: string;
    name?: string;
    inputType?: string;
    options?: any;
    collections?: any;
    type: string;
    value?: any;
    validations?: Validator[];
    maxDate?: Date;
    minDate?: Date;
    // Required field in case of multiselect drop down
    // Text will be displayed in place of all option
    allDisplayText?: string;
    // Required field in case of multiselect drop down
    // text to be suffixed in case of multiple options selected in drop down
    moreDisplayText?: string;
    maxlength?: number;
  };
  more: string;
  valuesSelected;
  defaultData: any;
  placeHolderId: string;
  customErrorMessageId: string;
  errorMessageId: string;
  isAllSelected: boolean;
  valueSelectionLogic: boolean;
  selectOptions$: DropdownOptions[];
  filteredOptions: DropdownOptions[];
  floatLabel: string;
  @Input("automationId")
  set automationID(value: string) {
    this.automation = value;
  }
  @Input("dispPlaceholder")
  set dispPlaceholder(value: string) {
    this.floatLabel = value && value.trim() != "" ? value : this._localization.setFloatLabel;
  }
  @Input("inputs")
  set inputOptions(value: AgDropdownConfig) {
    if (value) {
      this.className = value.className;
      this.errorMessage = value.errorMessage;
      this.form = value.form;
      this.formControlName = value.formControlName;
      this.placeHolder = value.placeHolder;
      this.selectOptions = value.selectOptions;
      this.automation = value.automationId;
      if (isObservable(this.selectOptions)) {
        this.selectOptions.subscribe((x) => {
          this.selectOptions$ = x;
          this.filteredOptions = x;
          if(this.useVirtualScroll) this.moveObjInArray();
        });
      } else {
        this.selectOptions?.then((x) => {
          this.selectOptions$ = x;
          this.filteredOptions = x;
          if(this.useVirtualScroll) this.moveObjInArray();
        });
      }
      if (this.dropdownWithSearch) {
        this.selectOptions$.map((x) => (x["checked"] = false));
        this.filteredOptions.map((x) => (x.checked = false));
      }
      this.isFirstEmpty = value.isFirstEmpty ? value.isFirstEmpty : false;
      this.isSelect = value.isSelect ? value.isSelect : false;
      this.showRequired = value.showRequired ? value.showRequired : false;
      this.isDisabled = value.disabled ? value.disabled : false;
      this.isMultiple = value.isMultiSelect ? value.isMultiSelect : false;
      this.defaultSelectededOptionValue = value.defaultSelectededOptionValue;
      this.customErrorMessage = value.customErrorMessage;
      this.customErrorMessage = value.customErrorMessage;
      this.customErrorMessageId = value.customErrorMessageId;
      this.errorMessageId = value.errorMessageId;
      this.placeHolderId = value.placeHolderId ? value.placeHolderId : value.formControlName;
      this.isAll = value.isAll ? value.isAll : false;
      this.isReportDropdown = value.isReportDropdown ? value.isReportDropdown : false;
      this.isAllSelected = value.isAllSelected ? value.isAllSelected : false;
      this.dropdownWithSearch = value.dropdownWithSearch == false ? value.dropdownWithSearch : true;
      this.valueSelectionLogic = value.isFormLogicApplied ? false : true;
      if (this.isAll && this.selectOptions) {
        this.isAllconfig();
      }
      if (this.defaultSelectededOptionValue) {
        this.form.controls[this.formControlName].setValue(this.defaultSelectededOptionValue);
        this.valuesSelected = this.defaultSelectededOptionValue;
      } 
      else {
        this.valuesSelected = this.form.controls[this.formControlName].value;
      }
    }
    this.getSelectedlistcount();
  }

  constructor(private _localization: Localization, public dialog: MatDialog) {
    this.floatLabel = this._localization.setFloatLabel;
  }
 
  ngOnInit() {
    this.captions = this._localization.captions;
    this.defaultData = {
      id: 0,
      value: 0,
      viewValue: this.captions.lbl_all,
    };
    this.more = this.captions.lbl_more;
  }

  ngAfterViewInit(): void {
    if(this.useVirtualScroll && !this.isMultiple)
    this.selectedData = this.form.controls[this.formControlName].value.viewValue;
  }

  isAllconfig() {
    from(this.selectOptions).subscribe((result) => {
      this.dataSourse = result;
      if (this.isAllSelected) {
        if (this.valueSelectionLogic) {
          this.valuesSelected = result?.slice(0, result.length);
          this.getSelectedlistcount();
        } else {
          this.form.controls[this.formControlName].setValue(result?.slice(0, result.length));
        }
      }
    });
    this.form.addControl(`isAllSelected`, new UntypedFormControl(true));
    this.addIsAllReportSelectedFormControl();
  }

  /**
   * Method used to compare id of the option object
   * and returns true if the selected option matches
   * **/
  compareSelect = (val1, val2) => {
    return val1 && val2 && val1.id === val2.id;
  };
  private _filter(value: string) {
    if (value) {
      const filterValue = value.toLowerCase();
      return (this.filteredOptions = this.selectOptions$.filter((x) =>
        x.viewValue.toLowerCase().includes(filterValue)
      ));
    } else {
      this.filteredOptions = [...this.selectOptions$];
      this.moveObjInArray();
    }
  }
  trackByFn(index: number, item: DropdownOptions): any {
    return item.id;
  }
  moveObjInArray() {
    if (this.useVirtualScroll && ((!this.isMultiple) || (this.valuesSelected.length > 0 && this.selectOptions$.length !== this.valuesSelected.length)))
    {
      if (this.isMultiple) {
        this.valuesSelected.forEach((element) => {
          let index = -1;
          index = this.filteredOptions.findIndex((x) => x.id === element.id);
          const item = this.filteredOptions.splice(index, 1)[0];
          this.filteredOptions.splice(0, 0, item);
        });
      }
      else
      {
          let index = -1;
          index = this.filteredOptions.findIndex((x) => x.viewValue === this.selectedData);
          if(index != -1)
          {
            const item = this.filteredOptions.splice(index, 1)[0];
            this.filteredOptions.splice(0, 0, item);
          }
      }
      this.useVirtualScroll = false;
      setTimeout(() => {
        this.useVirtualScroll = true;
      }, 0);
    }
  }
  filterOptions(event) {
    this._filter(event.target.value);
    this.getSelectedlistcount();
  }

  changedropdown(event: any, name: string) {
    let obj = {
      checked: true,
    };
    let selectedValue = this.form.value[name];
    if (!this.isMultiple) {
      this.filterDropDownSelected(obj, selectedValue, name);
    } else {
      if (this.valueSelectionLogic) {
        this.valuesSelected = selectedValue;
      }
      this.selectChange.emit(selectedValue);
    }
  }

  allcheckbox(e) {
    let pusdataset;
    if (e.checked) {
      if (this.form.controls[this.formControlName].value != null){
        let duplicate = [...this.form.controls[this.formControlName].value,...this.filteredOptions];
        pusdataset = Array.from(
        new Map(duplicate.map(item => [JSON.stringify(item), item])).values()
      );
      } else {
        pusdataset = this.filteredOptions;
      }
      this.form.controls[this.formControlName].setValue(pusdataset);
      this.valuesSelected = pusdataset;
    } else {
      let popdataset = differenceBy(this.form.controls[this.formControlName].value, this.filteredOptions,"id");
      this.form.controls[this.formControlName].setValue(popdataset);
      this.valuesSelected = popdataset;
    }
    this.form.controls[this.formControlName].markAsDirty();
    this.form.controls[this.formControlName].updateValueAndValidity();
    this.getSelectedlistcount();
    if (this.isAll && this.filteredOptions && this.selectOptions$ && this.filteredOptions != undefined && this.selectOptions$ != undefined){
      this.form.get(`isAllSelected`).setValue(this.filteredOptions.length === this.selectOptions$.length);
      if (this.isReportDropdown) {
        this.form .get(`isAllSelected_${this.formControlName}`).setValue(this.filteredOptions.length === this.selectOptions$.length);
      }
    }
    this.selectChange.emit(this.form.controls[this.formControlName].value);
  }
  getSelectedlistcount() {
    let availableseldata = intersectionBy(this.form?.controls[this.formControlName].value,this.filteredOptions,"id");
    this.Selectedfilteredlistcount = availableseldata.length == 0 ? -1 : availableseldata.length;
  }

  filterDropDownSelected(event: any, data: any, name: string, selected?: boolean) {
    if (this.dropdownWithSearch) {
      if (!this.isMultiple) {
        this.selectedData = data.viewValue;
      } else {
        if (this.valueSelectionLogic) {
          if (data && data.viewValue === this.captions.lbl_all) {
            this.valuesSelected = this.toggleClickbtn(this.defaultData, this.valuesSelected, event.checked);
          } else if (data && data.viewValue === "TBD" && data.id == 0) {
            this.valuesSelected = this.toggleClickbtn(data, this.valuesSelected, null, true);
          } else {
            this.valuesSelected = this.toggleClickbtn(data,this.valuesSelected);
          }
          this.form.controls[this.formControlName].setValue(this.valuesSelected);
          if (this.isAll) {
            this.form.get(`isAllSelected`).setValue(this.filteredOptions.length === this.valuesSelected.length);
            if (this.isReportDropdown) {
              this.form.get(`isAllSelected_${this.formControlName}`).setValue(this.filteredOptions.length === this.valuesSelected.length);
            }
          }
        } else {
          if (data && data.viewValue === this.captions.lbl_all) {
            if (data.id === this.defaultData.id) {
              if (event.checked) {
                this.form.controls[this.formControlName].setValue(this.filteredOptions.map((x) => x));
              } else {
                this.form.controls[this.formControlName].setValue([]);
              }
            }
          }
          if (this.isAll) {
            this.form.get(`isAllSelected`).setValue(this.filteredOptions.length === this.form.controls[this.formControlName].value.length);
            if (this.isReportDropdown) {
              this.form.get(`isAllSelected_${this.formControlName}`).setValue(this.filteredOptions.length === this.form.controls[this.formControlName].value.length);
            }
          }
        }
      }
      this.form.controls[this.formControlName].markAsDirty();
      this.getSelectedlistcount();
      this.selectChange.emit(data);
    } else {
      this.selectedData = data.viewValue;
      this.form.controls[this.formControlName].markAsDirty();
      this.getSelectedlistcount();
      this.selectChange.emit(data);
    }
  }

  private toggleClickbtn(data: any, selectedDataArray: any[], allselectedCheck?: boolean, isTBD: boolean = false): any[] {
    let selectedArray = selectedDataArray ? cloneDeep(selectedDataArray) : [];
    const currentlySelectedOption = data;
    // if (currentlySelectedOption.id === this.defaultData.id) { /* For all button click */
    //   if (allselectedCheck) {
    //     selectedArray = this.filteredOptions.map(x => x);
    //   } else {
    //     selectedArray = [];
    //   }
    // } else { /* For other than all button click */
    const currentDataIdx = selectedArray.findIndex((x) => x.id == currentlySelectedOption.id);
    if (currentDataIdx === -1) {
      selectedArray.push(currentlySelectedOption);
    } else {
      const defaultDataIdx = selectedArray.findIndex((x) => x.id == this.defaultData.id);
      selectedArray.splice(currentDataIdx, 1);

      if (defaultDataIdx !== -1) {
        selectedArray.splice(defaultDataIdx, 1);
      }
    }
    // }
    return selectedArray;
  }

  openedChange(opened: boolean) {
    if (!opened) {
      this.inputSearch = "";
      this._filter("");
      this.getSelectedlistcount();
    }
  }

  onclick() {
    if (document.designMode == "on") {
      this.dialog
        .open(AgMenuEditComponent, {
          width: "700px",
          height: "700px",
          data: {
            oldPlaceHolder: this.placeHolder,
            oldErrorMessage: this.errorMessage,
            oldCustomErrorMessage: this.customErrorMessage,
          },
          disableClose: true,
        })
        .afterClosed()
        .subscribe((result) => {
          if (result != undefined) {
            this.placeHolder = result.newplaceholder;
            this.errorMessage = result.newErrorMessage;
            this.customErrorMessage = result.newCustomErrorMessage;
          }
        });
    }
  }
  dropDownSearchClear(value) {
    if (value == "icon-close") {
      this.inputSearch = "";
      this._filter("");
      this.getSelectedlistcount();
    }
  }

  addIsAllReportSelectedFormControl() {
    if (this.isReportDropdown) {
      this.form.addControl(`isAllSelected_${this.formControlName}`, new UntypedFormControl(true));
    }
  }

  filterValueSelectedEmpty() {
    this.selectChange.emit(null);
  }
}