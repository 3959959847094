import { Component, OnInit, Input, ViewEncapsulation, OnDestroy, ViewChild, Pipe, PipeTransform } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators, UntypedFormControl, UntypedFormArray, FormArray } from '@angular/forms';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatDatepicker } from '@angular/material/datepicker';
import {
  SpaServiceLocation,
  ServiceGroup,
  ServiceEquipments,
  ServiceAddOns,
  MedicalConditions,
  AvailableDays,
  SpaServiceMedicalCondition,
  SpaServiceTherapist,
  SpaServiceAddOn,
  SpaServiceAvailableDays,
  SpaServiceEquipment,
  SpaServiceLinkLocation, SpaServicePriceType, SpaService, AllPriceType, ServicePriceTypeConfig, CascadeDropDownConfig, CascadeDropDownInput, ItemSearchRequest, ItemSearchBaseResponse, ServiceEquipmentTypeConfig
} from '../../../shared/business/view-settings.modals';
import { ViewSettingClientBusiness } from '../../../shared/common-functionalities/business/view-settings.business';
import { HttpMethod, HttpServiceCall } from '../../../shared/service/http-call.service';
import { SpaUtilities } from '../../../shared/utilities/spa-utilities';
import * as _ from 'lodash'; // STORAGE THE BACK ARRAY
import { Host, ButtonType, SPAScheduleBreakPoint, Maxlength } from '../../../shared/globalsContant';
import { ReplaySubject, Subscription } from 'rxjs';
import { SpaLocalization } from '../../../core/localization/spa-localization';
import { DaysModel, SystemConfig, BaseResponse, popupConfig } from '../../../shared/business/shared.modals';
import { BreakPointAccess } from '../../../shared/service/breakpoint.service';
import { SpaPropertyInformation } from '../../../core/services/spa-property-information.service';
import { EmptyValueValidator } from '../../../shared/Validators/EmptyValueValidator';
import { ViewMoreServiceService } from '../../../shared/view-more/view-more-service.service';
import { Days } from 'src/app/appointment/spa-wizard/spa-wizard.modal';
import { DmEformsSpaComponent } from 'src/app/common/components/dm-eforms-spa/dm-eforms-spa.component';
import { takeUntil } from 'rxjs/operators';
import { DmConfig, OverrideDetail } from 'src/app/common/Models/common.models';
import { DMConfigDataService } from 'src/app/common/dataservices/datamagine-config.data.service';
import { AgFieldConfig } from 'src/app/common/Models/ag-models';
import { Localization } from 'src/app/common/localization/localization';

@Component({
  selector: 'app-spa-services',
  templateUrl: './spa-services.component.html',
  styleUrls: ['./spa-services.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class SpaServicesComponent implements OnInit, OnDestroy {

  @Input() popupConfigs: popupConfig;
  destroyed$: ReplaySubject<boolean> = new ReplaySubject(1);
  FormArrControlSubscribed: Subscription;
  mcCount: number;
  aoCount: number;
  ptCount: number;
  eqCount: number;
  sdCount: number;
  selectedOtherTabIndex = false;
  selectedSeasonalTabIndex = false;
  OtherSetupFormGrp: UntypedFormGroup;
  ServiceLocationsFormGrp: UntypedFormGroup;
  SeasonalSetupFormGrp: UntypedFormGroup;
  searchTextPlaceHolder: string;
  @Input() searchLimit = -1;
  @Input() dataInput: any;
  isRetailItemSelected = false;
  FormGrp: UntypedFormGroup;
  TherapistsFormGrp: UntypedFormGroup;
  private serviceGroupId: number;
  SpaServiceId: number;
  serviceGroupDirty = false;
  defaultEffectiveFromDt: Date;
  defaultEffectiveToDt: Date;
  minFromDate: any;
  minToDate: any;
  availableDaysChanged = false;
  IAGButtonValue: any = this.localization.currencySymbol;
  ASCButtonValue: any = this.localization.currencySymbol;
  chargeTypeChanged: boolean;
  captions: any;
  commonCaptions: any = this.localization.captions.common;
  appointmentConfiguration: any;
  SYSTEMDEFAULTCANCELLATIONPOLICY: any;
  isUserAuthorized = true;
  selectedRetailItemId: number;
  isViewOnly = true;
  isRetailItemChanged = false;
  retailItemMiss = true;
  retailItemDirty = false;
  isAssociatedWithPackage = false;
  allData: any[] = [];
  selectedData: any[] = [];
  searchKey: string[] = ['id', 'name'];
  autoCompleteKeys: string[] = ['name'];
  selectedChipKey: string[] = ['name'];
  selectedNewChipKey: string[] = ['name'];
  setChipError: boolean;
  isFocusSearchEmitRequired: boolean = true;
  searchPostTypeLength: number = 50;

  effectiveDateSubscription: Subscription;
  minStaffSubscription: Subscription;
  maxstaffSubscription: Subscription;
  minGuestSubscription: Subscription;
  maxGuestSubscription: Subscription;
  seasonalSetupSubscription: Subscription;
  date: any = new UntypedFormControl(this.PropertyInfo.CurrentDate);
  serializedDate: any = new UntypedFormControl(this.PropertyInfo.CurrentDate.toISOString());
  sgDataInput: any = [];
  firstFormGroup: UntypedFormGroup;
  sysDate: Date = this.PropertyInfo.CurrentDate;
  DayArr: any[] = [];
  clickbutton: any;
  isLinear = false;
  Groups: any[] = [];
  serviceYieldJson = [];
  selectedserviceGrpArr: any[] = [];
  Time: any[] = [{ minutes: 15, caption: `15 ${this.commonCaptions.Minutes}` },
  { minutes: 30, caption: `30 ${this.commonCaptions.Minutes}` },
  { minutes: 45, caption: `45 ${this.commonCaptions.Minutes}` },
  { minutes: 60, caption: `60 ${this.commonCaptions.Minutes}` }];
  BreakdownTimeArr: any[] = [];
  ServiceTimeArr: any[] = [15];
  SetupTimeArr: any[] = [];
  Days: DaysModel[] = [];
  Noof: any[] = [1, 2, 3, 4, 5];
  TherapistsNonEdited: any[] = [];
  Therapists: any[] = [];
  originalTherapists: any[] = [];
  AllServiceData: any;
  ServiceLocations: SpaServiceLocation[] = [];
  originalServiceLocations: SpaServiceLocation[] = [];
  ServiceLocationsNonEdited: SpaServiceLocation[] = [];
  Equipments: any[] = [];
  SelectedEquipmentArr: any[] = [];
  EquipmentArrNonEdited: any[] = [];
  PriceTypes: any[] = [];
  SelectedPriceTypeArr: any[] = [];
  PriceTypeArrNonEdited: any[] = [];
  AddOns: any[] = [];
  SelectedAddonArr: any[] = [];
  AddonArrNonEdited: any[] = [];
  MedicalConditions: any[] = [];
  SelectedMedicalConditionArr: any[] = [];
  MedicalConditionArrNonEdited: any[] = [];
  availableDays: AvailableDays = {
    IsAvailableOnSunday: false,
    IsAvailableOnMonday: false,
    IsAvailableOnTuesday: false,
    IsAvailableOnWednesday: false,
    IsAvailableOnThursday: false,
    IsAvailableOnFriday: false,
    IsAvailableOnSaturday: false
  };
  MinimumGuestsSelectedValue: any = 1;
  MaximumGuestsSelectedValue: any = 1;
  MinimumStaffSelectedValue: any = 1;
  MaximumStaffSelectedValue: any = 1;
  codeDisabled = false;
  serviceOverAllFormGroup: any;
  isEditService = false;
  activeSeasonalSetupFormGrp: number = 0;
  servicePriceType = [];
  servicePriceType1 = [];
  pricetypeArr: any = [];
  currentIndexemail: any = 0;
  selectedPriceTypeArray: any = [];
  PriceTypesData: ServicePriceTypeConfig[] = [{ 'belongto': 1, 'NumofPriceTypesAdded': [], 'selectedItems': [] }];
  EquipmentTypesData: ServiceEquipmentTypeConfig[] = [{ 'belongto': 1, 'NumofEquipTypesAdded': [], 'selectedItems': [] }];
  selectedGratuity: any = {};
  isServiceGroupFilled: boolean = false;
  cascadeData: CascadeDropDownConfig;
  serviceEquipmentData: any;
  PriceTypeFormGroup: UntypedFormGroup;
  EquipmentFormGroup: UntypedFormGroup;
  inputData: CascadeDropDownInput;
  serviceEquipmentInputData: any;
  minDuration: number = 1;
  gratuityMaxlength: number;
  ServiceChargeMaxlength: number;
  seletedColorCode = '#fff';
  retailItemType = 3;
  searchFilter: ItemSearchBaseResponse[] = [];
  guid: string;
  days = ['sun', 'mon', 'tue', 'wed', 'thu', 'fri', 'sat'];
  placeholderFormat: string;
  maxListOrder: number;
  serviceGroup: UntypedFormGroup;
  floatLabel: string;
  linkText: string;
  eFormsList: any[] = [];
  eFormsList$: any[] = [];
  eForms;
  dmConfig: DmConfig;
  dmResponse: any;
  showEformLink: boolean = false;
  servicePointsInput: AgFieldConfig;
  isReadOnly = false;
  throttleTime: number = 1000;
  resetModel = new Date(0);
  enableOverrideArray: boolean[] = [];
  showSeasonalOverrideDate: boolean = false;


  constructor(private BP: BreakPointAccess,
    public dialogRef: MatDialogRef<SpaServicesComponent>,
    private Form: UntypedFormBuilder, private business: ViewSettingClientBusiness,
    private http: HttpServiceCall,
    private utilities: SpaUtilities,
    public localization: SpaLocalization,
    private PropertyInfo: SpaPropertyInformation,
    public _viewmoreserviceservice: ViewMoreServiceService,
    public dialog: MatDialog,
    private dmConfigDataService: DMConfigDataService) {
    this.placeholderFormat = this.localization.inputDateFormat;
    this.floatLabel = this.localization.setFloatLabel;

  }

  seasonalSetActiveIndex(val: any): void {
    this.activeSeasonalSetupFormGrp = val;
  }

  setDaysValue(value: any): void {
    const seasonalSetupControls = this.SeasonalSetupFormGrp.controls.seasonalSetups as UntypedFormArray;
    const daysFormArr = seasonalSetupControls['controls'][this.activeSeasonalSetupFormGrp]['controls'];
    this.days.forEach(val => {
      if (daysFormArr[val].disabled) {
        daysFormArr[val].setValue('', { emitEvent: false });
      } else if (daysFormArr[val].enabled) {
        const delocalizedNumber = this.localization.currencyToSQLFormat(value);
        const currencyValue = this.localization.localizeCurrency(delocalizedNumber, false)
        daysFormArr[val].setValue(currencyValue, { emitEvent: false });
      }
    });
  }

  setAllDaysValue(event: any): void {
    const val = event.target.value;
    const seasonalSetupControls = this.SeasonalSetupFormGrp.controls.seasonalSetups as UntypedFormArray;
    const seasonalSetupArray = seasonalSetupControls.at(this.activeSeasonalSetupFormGrp) as UntypedFormGroup;
    let daysCount = 0;
    Object.keys(seasonalSetupArray.controls).forEach((key) => {
      if (key != "endDate" && key != 'startDate') {
        if (seasonalSetupArray.get(key).value == val) {
          daysCount++;
        }
      }
    });
    if (daysCount == 7) {
      seasonalSetupArray.patchValue({
        allDays: val
      }, { emitEvent: false });
    } else {
      seasonalSetupArray.patchValue({
        allDays: ''
      }, { emitEvent: false });
    }
  }

  ngOnInit() {
    this.captions = this.localization.captions.setting;
    this.defaultEffectiveFromDt = this.PropertyInfo.CurrentDate;
    this.defaultEffectiveToDt = this.utilities.getDate(this.defaultEffectiveFromDt)
    this.defaultEffectiveToDt.setDate(this.defaultEffectiveToDt.getDate() + 364);
    this.DayArr = [1, 2, 3, 4, 5, 6, 7];
    this.searchTextPlaceHolder = "Eforms for Services"
    this.getAllConfigList();
    this.gratuityMaxlength = Maxlength.FLATAMOUNT;
    this.ServiceChargeMaxlength = Maxlength.FLATAMOUNT;
    this.minFromDate = this.defaultEffectiveFromDt;
    this.minToDate = this.defaultEffectiveFromDt;
    this.FormGrp = this.Form.group({
      serviceGroupId: ['', Validators.required],
      code: ['', Validators.required],
      description: ['', [Validators.required, EmptyValueValidator]],
      effectiveFromDate: this.defaultEffectiveFromDt,
      effectiveToDate: [this.defaultEffectiveToDt],
      price: ['', Validators.required],
      availableDays: '',
      time: ['', [Validators.required, Validators.min(this.minDuration)]],
      setupTime: '',
      breakDownTime: '',
      listOrder: '',
      minimumAge: '',
      minimumGuest: [1],
      maximumGuest: [1],
      minimumStaff: [1],
      maximumStaff: [1],
      isOffsite: false,
      isAutoGratuity: false,
      autoGratuityText: '',
      isAutoServiceCharge: false,
      autoServiceChargeText: '',
      isCommissionable: false,
      points: ''
    });

    this.TherapistsFormGrp = this.Form.group({
      therapists: ['', [Validators.required, Validators.min(1)]],
    });
    this.ServiceLocationsFormGrp = this.Form.group({
      serviceLocations: ['', Validators.required],
    });
    this.OtherSetupFormGrp = this.Form.group({
      equipments: '',
      priceTypes: '',
      addOns: '',
      medicalConditions: '',
      comments: '',
      policy: '',
      cancellationPolicy: '',
      requireStaffAtCheckin: false,
      isAvailableOnWeb: false,
      isInActive: false,
      pricetype: this.Form.array([this.createpriceTypeItem('', 0)]),
      linkedItem: ['', Validators.required],
      isRequireColorCode: false
    });
    this.SeasonalSetupFormGrp = this.Form.group({
      seasonalSetups: this.Form.array([this.createSeasonalSetupFormGroup('')])
    });
    this.serviceOverAllFormGroup = this.Form.group({
      detailForm: this.FormGrp,
      therapistsForm: this.TherapistsFormGrp,
      locationForm: this.ServiceLocationsFormGrp,
      otherForm: this.OtherSetupFormGrp,
      seasonalFrom: this.SeasonalSetupFormGrp
    });


    this.business.activeFormGroup = this.FormGrp;
    this.ValidateBreakPoint();
    if (this.popupConfigs.operation == "create") {
      this.maxListOrder = this.popupConfigs.maxListOrder;
      this.FormGrp.controls["listOrder"].setValue(this.maxListOrder);
    }
    else if (this.popupConfigs.operation == "edit") {
      this.maxListOrder = this.dataInput.listOrder;
      this.FormGrp.patchValue(this.dataInput);
      this.isReadOnly = true;
    }
    this.Days = this.localization.getDaysModel(false);
    this.clickbutton = typeof this.dataInput === 'undefined' ? this.captions.SAVE : this.captions.UPDATE;
    this.dataInputChanges();
  }

  searchValueChange(eve, type) {
    if (type == 'therapist') {
      eve == '' ? this.Therapists = this.originalTherapists : this.Therapists = this.originalTherapists.filter(x => x.firstName.toLowerCase().includes(eve.toLowerCase()) || x.lastName.toLowerCase().includes(eve.toLowerCase()) || (x.firstName.toLowerCase() + " " + x.lastName.toLowerCase()).includes(eve.toLowerCase()));
    } else {
      eve == '' ? this.ServiceLocations = this.originalServiceLocations : this.ServiceLocations = this.originalServiceLocations.filter(x => x.description.toLowerCase().includes(eve.toLowerCase()) || x.code.toLowerCase().includes(eve.toLowerCase()));
    }
  }

  dataInputChanges() {
    if (typeof this.dataInput !== 'undefined') {
      this.isEditService = true;
      this.AllServiceData = _.cloneDeep(this.dataInput);
      this.dataInput = this.AllServiceData.serviceDetail;
      const serviceStartDate = this.utilities.getDate(this.AllServiceData.serviceDetail.effectiveFromDate);
      if (this.defaultEffectiveFromDt > serviceStartDate) {
        this.minFromDate = serviceStartDate;
      } else {
        this.minFromDate = this.defaultEffectiveFromDt;
      }
      this.minToDate = this.dataInput.effectiveFromDate;
      this.getLinkedItemDesc(this.dataInput.retailItemId);
      this.setEditValues();
      this.codeDisabled = true;
      this.SpaServiceId = this.dataInput.id;
      this.serviceGroupId = this.dataInput.serviceGroupId;
      this.selectedserviceGrpArr = [this.dataInput.serviceGroupId];
      this.selectedRetailItemId = this.dataInput.retailItemId;
      this.isAssociatedWithPackage = this.dataInput.isAssociatedWithPackage;
      this.retailItemMiss = this.selectedRetailItemId > 0 ? false : true;
      this.FormGrp.patchValue(this.dataInput);
      this.FormGrp.get('points').setValue(this.FormGrp.get('points').value ? this.FormGrp.get('points').value : '');
      this.FormGrp.get('effectiveFromDate').patchValue(this.utilities.getDate(this.dataInput.effectiveFromDate));
      this.setLocationValue();
      this.setAmountorPercent(this.dataInput);
      this.OtherSetupFormGrp.patchValue({
        requireStaffAtCheckin: this.dataInput.requireStaffAtCheckin,
        isAvailableOnWeb: this.dataInput.isAvailableOnWeb,
        isInActive: this.dataInput.isInActive,
        comments: this.dataInput.comments,
        policy: this.dataInput.policy,
        color: this.dataInput.colorCode,
        isRequireColorCode: this.dataInput.colorCode == '' || this.dataInput.colorCode == null ? false : true
      });
      this.eFormsList = this.dataInput.eFormDatas;
      this.eFormsList$ = this.dataInput.eFormDatas;
      this.tooltipChange();
      this.ServiceTimeArr = [this.dataInput.time];
      this.SetupTimeArr = [this.dataInput.setupTime];
      this.BreakdownTimeArr = [this.dataInput.breakDownTime];
      if (this.dataInput.colorCode != null && this.dataInput.colorCode != '') {
        this.seletedColorCode = this.dataInput.colorCode;
      }
    }
    else {
      this.FormGrp.controls["time"].setValue(this.ServiceTimeArr[0]);
    }
    this.onChanges();

    this.cascadeData = {
      label: this.captions.PriceTypes,
      placeholder: this.captions.PriceType,
      dropdownError: `${this.commonCaptions.Missing} ${this.captions.PriceType}`,
      extendedInput: true,
      inputRequired: false,

      displayProperty: 'code',
      addedItems: []
    };

    this.serviceEquipmentData = {
      label: 'Service Equipment',
      placeholder: this.captions.ServiceEquip,
      availablePlaceholder: this.captions.AvailQty,
      dropdownError: `${this.commonCaptions.Missing} ${this.captions.PriceType}`,
      extendedInput: true,
      inputRequired: false,

      displayProperty: 'code',
      addedItems: []
    };

    this.servicePointsInput = {
      className: 'width165',
      form: this.FormGrp,
      formControlName: 'points',
      placeHolderId: this.captions.ServicePoints,
      placeHolder: this.captions.ServicePoints,
      maxlength: 1,
      disabled: false,
      maxValue: 999,
      minValue: 1,
      maximumValueErrorMsg: 'Maximum error',
      minimumValueErrorMsg: 'Minimum error'
      // errorMessageId :
    };

    this.getDmconfig();
  }

  async getDmconfig() {
    this.dmConfig = await this.dmConfigDataService.getDataMagineConfigSession();
    if (this.dmConfig && this.dmConfig.enableDataMagine) {
      let isEformsEnabled = this.dmConfig?.dmEformsConfig?.enableEforms ?? false;
      if (isEformsEnabled) {
        this.showEformLink = true;
      }
    }
  }


  ngOnDestroy(): void {
    if (typeof this.FormArrControlSubscribed != "undefined") { this.FormArrControlSubscribed.unsubscribe(); }
    if (this.effectiveDateSubscription) { this.effectiveDateSubscription.unsubscribe(); }
    if (this.minStaffSubscription) { this.minStaffSubscription.unsubscribe(); }
    if (this.maxstaffSubscription) { this.maxstaffSubscription.unsubscribe(); }
    if (this.minGuestSubscription) { this.minGuestSubscription.unsubscribe(); }
    if (this.maxGuestSubscription) { this.maxGuestSubscription.unsubscribe(); }
    if (this.seasonalSetupSubscription) { this.seasonalSetupSubscription.unsubscribe(); }
    if (typeof this.FormArrControlSubscribed != "undefined") { this.FormArrControlSubscribed.unsubscribe(); }
  }

  ValidateBreakPoint(): void {
    this.isUserAuthorized = this.BP.CheckForAccess([SPAScheduleBreakPoint.SettingSpaService]);
    this.isViewOnly = this.BP.IsViewOnly(SPAScheduleBreakPoint.SettingSpaService);
    if (this.isViewOnly) {
      this.utilities.disableControls(this.FormGrp);
      this.utilities.disableControls(this.TherapistsFormGrp);
      this.utilities.disableControls(this.ServiceLocationsFormGrp);
      this.utilities.disableControls(this.OtherSetupFormGrp);
      this.utilities.disableControls(this.business.activeFormGroup);
    }
  }

  // Handles onchange event of the form
  onChanges(): void {
    this.effectiveDateSubscription = this.FormGrp.get('effectiveFromDate').valueChanges.subscribe(val => {
      this.minToDate = val;
    });
    this.minStaffSubscription = this.FormGrp.get('minimumStaff').valueChanges.subscribe(val => {
      this.ValidateMinMax("Staff");
    });
    this.maxstaffSubscription = this.FormGrp.get('maximumStaff').valueChanges.subscribe(val => {
      this.ValidateMinMax("Staff");
    });
    this.minGuestSubscription = this.FormGrp.get('minimumGuest').valueChanges.subscribe(val => {
      this.ValidateMinMax("Guest");
    });
    this.maxGuestSubscription = this.FormGrp.get('maximumGuest').valueChanges.subscribe(val => {
      this.ValidateMinMax("Guest");
    });
    const seasonalSetupControls: any = this.SeasonalSetupFormGrp.controls.seasonalSetups;
    this.seasonalSetupSubscription = seasonalSetupControls.controls.forEach((seasonalSetupControl, index) => {
      seasonalSetupControl.controls["allDays"].valueChanges.subscribe(val => {
        this.setDaysValue(val);
      });
    });
  }

  startDateValueChange(index: number) {
    const seasonalSetup = this.SeasonalSetupFormGrp.get('seasonalSetups') as UntypedFormArray;
    seasonalSetup['controls'][index]['controls'].minEndDate.setValue(seasonalSetup['controls'][index]['controls'].startDate.value);
    const seasonalSetupDateValues = seasonalSetup.value.map(res => {
      return {
        startDate: res.startDate,
        endDate: res.endDate
      }
    });
    const startDate = seasonalSetupDateValues[index].startDate;
    const endDate = seasonalSetupDateValues[index].endDate;
    seasonalSetupDateValues.forEach((value, idx) => {
      if (index != idx
        && (((value.startDate && startDate >= value.startDate) && (value.endDate && startDate <= value.endDate))
          || ((value.startDate && endDate >= value.startDate) && (value.endDate && endDate <= value.endDate))
          || (startDate && endDate && startDate <= value.startDate && endDate >= value.endDate))) {
        seasonalSetup['controls'][index]['controls'].startDate.setValue('');
        seasonalSetup['controls'][index]['controls'].endDate.setValue('');
        this.utilities.ShowErrorMessage(this.localization.captions.common.Error, this.localization.captions.common.DateOverlap, ButtonType.Ok);
      }
    });
    if (endDate && endDate < startDate) {
      seasonalSetup['controls'][index]['controls'].endDate.setValue('');
    }
    this.enableOrDisableDays(index);
  }

  endDateValueChange(index) {
    const seasonalSetup = this.SeasonalSetupFormGrp.get('seasonalSetups') as UntypedFormArray;
    const seasonalSetupDateValues = seasonalSetup.value.map(res => {
      return {
        startDate: res.startDate,
        endDate: res.endDate
      }
    });
    const startDate = seasonalSetupDateValues[index].startDate;
    const endDate = seasonalSetupDateValues[index].endDate;
    seasonalSetupDateValues.forEach((value, idx) => {
      if (index != idx
        && (((value.startDate && startDate >= value.startDate) && (value.endDate && startDate <= value.endDate))
          || ((value.startDate && endDate >= value.startDate) && (value.endDate && endDate <= value.endDate))
          || (startDate && endDate && startDate <= value.startDate && endDate >= value.endDate))) {
        seasonalSetup['controls'][index]['controls'].startDate.setValue('');
        seasonalSetup['controls'][index]['controls'].endDate.setValue('');
        this.utilities.ShowErrorMessage(this.localization.captions.common.Error, this.localization.captions.common.DateOverlap, ButtonType.Ok);
      }
    });
    this.enableOrDisableDays(index);  this.markOverridePriceAsTouched(seasonalSetup);
    this.enableOrDisableDays(index);
  }

  markOverridePriceAsTouched(OuterFormArray) {
    OuterFormArray.controls.forEach(val => {
      val['controls']['specificDays']['controls'].forEach(x => {
        x['controls']['overrideDate'].markAsTouched();
      })
    })
    
  }

  ValidateMinMax(type: any) {
    if (type == "Guest") {
      const MinValue = this.FormGrp.get('minimumGuest').value;
      const MaxValue = this.FormGrp.get('maximumGuest').value;
      if (MaxValue == 0) {
        return;
      }
      if (MinValue > MaxValue) {
        this.FormGrp.get('maximumGuest').setErrors({ incorrect: true });
      } else {
        this.FormGrp.get('maximumGuest').setErrors(null);
      }
    }
    if (type == "Staff") {
      const MinValue = this.FormGrp.get('minimumStaff').value;
      const MaxValue = this.FormGrp.get('maximumStaff').value;
      if (MaxValue == 0) {
        return;
      }
      if (MinValue > MaxValue) {
        this.FormGrp.get('maximumStaff').setErrors({ incorrect: true });
      } else {
        this.FormGrp.get('maximumStaff').setErrors(null);
      }
    }
  }

  validateSave() {
    return (this.FormGrp.valid && !this.retailItemMiss &&
      this.TherapistsFormGrp.valid && this.ServiceLocationsFormGrp.valid &&
      this.OtherSetupFormGrp.valid && this.SeasonalSetupFormGrp.valid) &&
      (this.retailItemDirty || this.TherapistsFormGrp.dirty ||
        this.ServiceLocationsFormGrp.dirty || this.OtherSetupFormGrp.dirty ||
        this.FormGrp.dirty || this.SeasonalSetupFormGrp.dirty);
  }

  setEditValues() {
    this.setLinkValues("GetSpaServiceEquipmentLink", this.AllServiceData.serviceEquipments);
    this.setLinkValues("GetSpaServiceAddOnLink", this.AllServiceData.serviceAddOns);
    this.setLinkValues("GetSpaServiceMedConLink", this.AllServiceData.serviceMedicalConditions);
    this.setLinkValues("GetSpaServiceAvailableDaysLink", this.AllServiceData.serviceAvailableDays);
    this.setLinkValues("GetSpaServicePriceTypeLink", this.AllServiceData.servicePriceTypes);
    this.setLinkValues("GetSpaServiceSeasonalDaysLink", this.AllServiceData.serviceSeasonalDates);
  }


  async selectTherapists(event: any, index: any, therapistSelected?: boolean) {
    if (therapistSelected) {
      const isTherapistInUse = await this.IsTherapistInUse(this.Therapists[index].id);
      if (isTherapistInUse) {
        this.utilities.ShowErrorMessage(this.captions.Error, this.captions.TherapistUsedInAppointment, ButtonType.Ok);
        return;
      }
    }
    this.originalTherapists.find(x => x.id == this.Therapists[index].id).selected = !this.originalTherapists.find(x => x.id == this.Therapists[index].id).selected;
    this.Therapists[index].selected = this.originalTherapists.find(x => x.id == this.Therapists[index].id).selected;
    const therapistCount = this.originalTherapists.filter(therapist => therapist.selected).length;
    if (therapistCount > 0) {
      this.TherapistsFormGrp.controls.therapists.setValue(therapistCount);
    } else {
      this.TherapistsFormGrp.controls.therapists.setValue('');
    }

  }

  async selectLocations(event: any, index: any) {
    if (this.codeDisabled && this.ServiceLocations[index].selected) {
      const isUsedInAppointment = await this.IsLocationInUse(this.ServiceLocations[index].id);
      if (isUsedInAppointment) {
        this.utilities.ShowErrorMessage(this.captions.Error, this.captions.LocationUsedInAppointment, ButtonType.Ok);
        return;
      }
    }
    this.originalServiceLocations.find(x => x.id == this.ServiceLocations[index].id).selected = !this.originalServiceLocations.find(x => x.id == this.ServiceLocations[index].id).selected;
    this.ServiceLocations[index].selected = this.originalServiceLocations.find(x => x.id == this.ServiceLocations[index].id).selected;
    const serviceLocationCount = this.originalServiceLocations.filter(value => value.selected).length;
    if (serviceLocationCount > 0) {
      this.ServiceLocationsFormGrp.controls.serviceLocations.setValue(serviceLocationCount);
    } else {
      this.ServiceLocationsFormGrp.controls.serviceLocations.setValue(0);
      this.setLocationValue();
    }
    this.ServiceLocationsFormGrp.markAsDirty();
  }

  async IsTherapistInUse(therapistId: number): Promise<boolean> {
    const results = await this.http.CallApiAsync<any>({
      host: Host.schedule,
      callDesc: 'IsTherapistServiceInUse',
      method: HttpMethod.Get,
      uriParams: { serviceId: this.SpaServiceId, therapistId: therapistId }
    });
    return results.result;
  }

  async IsLocationInUse(locationId: number): Promise<boolean> {
    const results = await this.http.CallApiAsync<any>({
      host: Host.schedule,
      callDesc: 'IsLocationInUse',
      method: HttpMethod.Get,
      uriParams: { serviceId: this.SpaServiceId, locationId: locationId }
    });
    return results.result;
  }

  createSeasonalSetupFormGroup(setup: any) {
    if (this.enableOverrideArray) {
      this.enableOverrideArray.push(false);
    }
    console.log(this.minFromDate);
    return this.Form.group({
      id: 0,
      startDate: '',
      endDate: '',
      minStartDate: this.minFromDate,
      minEndDate: '',
      overrideDates: [{ dates: [] }],
      overridePrice: [{ value: '', disabled: true }],
      allDays: [{ value: '', disabled: true }],
      mon: [{ value: '', disabled: true }],
      tue: [{ value: '', disabled: true }],
      wed: [{ value: '', disabled: true }],
      thu: [{ value: '', disabled: true }],
      fri: [{ value: '', disabled: true }],
      sat: [{ value: '', disabled: true }],
      sun: [{ value: '', disabled: true }],
      specificDays: this.Form.array([this.createOverrideDateGroup()]),
    });
  }

  addSeasonalSetup() {
    const arr = this.SeasonalSetupFormGrp.get('seasonalSetups') as UntypedFormArray;
    arr.push(this.createSeasonalSetupFormGroup(''));
    for (let cnt of arr.controls) {
      const FormArrControl: any = cnt;
      this.FormArrControlSubscribed = FormArrControl.controls["allDays"].valueChanges.subscribe(res =>
        this.setDaysValue(res));
    }
  }

  removeSeasonalSetup(idx: any) {
    const arr = this.SeasonalSetupFormGrp.get('seasonalSetups') as UntypedFormArray;
    if (this.isEditService && arr['controls'][idx].value.id) {
      this.serviceOverAllFormGroup.markAsDirty();
    }
    arr.removeAt(idx);
    this.enableOverrideArray.splice(idx, 1);
  }

  Save() {
    this.FormGrp.value.serviceGroupId = this.serviceGroupId;
    this.FormGrp.value.AvailableDays = this.DayArr;
    this.FormGrp.value.ServiceTime = this.ServiceTimeArr;
    this.FormGrp.value.SetupTime = this.SetupTimeArr;
    this.FormGrp.value.BreakdownTime = this.BreakdownTimeArr;
    this.FormGrp.value.Therapists = this.Therapists;
    this.FormGrp.value.ServiceLocations = this.ServiceLocations;
    this.FormGrp.value.Equipments = this.SelectedEquipmentArr;
    this.FormGrp.value.PriceTypes = this.SelectedPriceTypeArr;
    this.FormGrp.value.AddOns = this.SelectedAddonArr;
    this.FormGrp.value.MedicalConditions = this.SelectedMedicalConditionArr;
    const service: SpaService = this.BuildServiceData();
    this.http.CallApiWithCallback<number>({
      host: Host.spaManagement,
      success: this.successCallback.bind(this),
      error: this.utilities.errorCallback.bind(this),
      callDesc: this.isEditService ? "updateSpaService" : "CreateSpaService",
      method: this.isEditService ? HttpMethod.Put : HttpMethod.Post,
      body: service,
      showError: true,
      uriParams: this.isEditService ? { id: this.SpaServiceId } : '',
      extraParams: []
    });
  }

  private markOverAllFormGroupAsDirty(): void {
    this.serviceOverAllFormGroup.markAsDirty();
  }

  async CheckIfDayHasAppointment(arrayValue: any, element: any) {
    const isUsedInAppointment = await this.IsServiceInUseOnDay(element);
    if (isUsedInAppointment) {
      this.utilities.ShowErrorMessage(this.captions.Error, this.captions.ServiceUsedInAppointment, ButtonType.Ok);
      return;
    } else {
      this.PushPopData(arrayValue, element);
    }

  }


  async IsServiceInUseOnDay(element: any): Promise<boolean> {
    let dt: Date = this.utilities.getDate(this.FormGrp.controls["effectiveFromDate"].value);
    let dateStr = `${dt.getFullYear()}-${(dt.getMonth() + 1)}-${dt.getDate()}`;
    const startDate = dateStr;
    dt = this.utilities.getDate(this.FormGrp.controls["effectiveToDate"].value);
    dateStr = `${dt.getFullYear()}-${(dt.getMonth() + 1)}-${dt.getDate()}`;
    const endDate = dateStr;

    const results = await this.http.CallApiAsync<any>({
      host: Host.schedule,
      callDesc: 'IsServiceInUse',
      method: HttpMethod.Get,
      uriParams: { serviceId: this.SpaServiceId, startDate: startDate, endDate: endDate, dayOfWeek: element == 7 ? 0 : element },
    });
    return results.result;
  }

  emitedValue(event) {
    this.toggleButtonClick(event[0], event[1], event[2].id);
  }

  toggleButtonClick = ($event: any, frm: any, Driven: any) => {
    let arrayValue: any;
    switch (frm) {
      case 'ServiceDetails': {
        this.serviceGroupDirty = true;
        arrayValue = this.selectedserviceGrpArr;
        this.serviceGroupId = Driven;
        this.ButtonSelect(arrayValue, Driven);
        this.FormGrp.controls.serviceGroupId.setValue(this.selectedserviceGrpArr);
      }
        break;
      case 'Day': {
        this.availableDaysChanged = true;
        arrayValue = this.DayArr;
        if (this.SpaServiceId > 0 && arrayValue.indexOf(Driven) > -1) {
          this.CheckIfDayHasAppointment(arrayValue, Driven);
        } else {
          this.PushPopData(arrayValue, Driven);
        }
      }
        break;
      case 'BreakdownTime': {
        this.FormGrp.controls["breakDownTime"].setValue(Driven);
        this.FormGrp.get('breakDownTime').markAsDirty();
        arrayValue = this.BreakdownTimeArr;
        this.ButtonSelect(arrayValue, Driven);
      }
        break;
      case 'SetupTime': {
        this.FormGrp.controls["setupTime"].setValue(Driven);
        this.FormGrp.get('setupTime').markAsDirty();
        arrayValue = this.SetupTimeArr;
        this.ButtonSelect(arrayValue, Driven);
      }
        break;
      case 'ServiceTime': {
        this.FormGrp.controls["time"].setValue(Driven);
        this.FormGrp.get('time').markAsDirty();
        arrayValue = this.ServiceTimeArr;
        this.ButtonSelect(arrayValue, Driven);
      }
        break;
      case 'Equipment': {
        arrayValue = this.SelectedEquipmentArr;
        this.PushPopData(arrayValue, Driven);
      }
        break;
      case 'PriceType': {
        arrayValue = this.SelectedPriceTypeArr;
        this.PushPopData(arrayValue, Driven);
      } break;
      case 'AddOn': {
        arrayValue = this.SelectedAddonArr;
        this.PushPopData(arrayValue, Driven);
      } break;
      case 'MedicalCondition': {
        arrayValue = this.SelectedMedicalConditionArr;
        this.PushPopData(arrayValue, Driven);
      }
    }
    this.OtherSetupFormGrp.markAsDirty();
  }

  TimeKeyUp($event: any, frm: any) {
    let arrayValue: any;
    const value: number = parseInt($event.target.value);
    switch (frm) {
      case "ServiceTime":
        arrayValue = this.ServiceTimeArr;
        break;
      case "SetupTime":
        arrayValue = this.SetupTimeArr;
        break;
      case "BreakdownTime":
        arrayValue = this.BreakdownTimeArr;
        break;
    }
    this.ButtonSelect(arrayValue, value);
  }


  ButtonSelect(Arr: any, value: any) {
    Arr.splice(0, 1);
    if (!isNaN(value)) {
      Arr.push(value);
    }
  }

  /**
   * array push
   * @param ga
   * @param gv
   */
  PushPopData(ga: any, gv: any) {
    if (ga.indexOf(gv) == -1) {
      ga.push(gv);
    } else {
      ga.splice(ga.indexOf(gv), 1);
    }
  }

  getAllConfigList() {
    // Service Locations
    this.business.getServiceData<SpaServiceLocation>(HttpMethod.Get, "GetAllLoc", this.successCallback.bind(this), this.utilities.errorCallback.bind(this), true);
    // Service Group
    this.business.getServiceData<ServiceGroup>(HttpMethod.Get, "GetAllServiceGrp", this.successCallback.bind(this), this.utilities.errorCallback.bind(this), true);
    // Service list
    this.business.getServiceData<any>(HttpMethod.Get, "GetAllSpaService", this.successCallback.bind(this), this.utilities.errorCallback.bind(this), true);
    // Therapist list
    this.business.getServiceData<any>(HttpMethod.Get, "GetAllTherapist", this.successCallback.bind(this), this.utilities.errorCallback.bind(this), true);
    //Price type list
    this.business.getServiceData<any>(HttpMethod.Get, "GetAllPriceTypes", this.successCallback.bind(this), this.utilities.errorCallback.bind(this), true);
    // Equipment list
    this.business.getServiceData<ServiceEquipments>(HttpMethod.Get, "GetAllEquip", this.successCallback.bind(this), this.utilities.errorCallback.bind(this), true);
    // Add-Ons
    this.business.getServiceData<ServiceAddOns>(HttpMethod.Get, "GetAllAddOns", this.successCallback.bind(this), this.utilities.errorCallback.bind(this), true);
    // Medical Condition
    this.business.getServiceData<MedicalConditions>(HttpMethod.Get, "GetAllMC", this.successCallback.bind(this), this.utilities.errorCallback.bind(this), true);
    //getting cancellation policy configuration
    this.utilities.GetAllSettingByModule(this.successCallback.bind(this), this.utilities.errorCallback.bind(this), "Appointment");
  }

  spaServiceControls = ["code", "description", "serviceGroupId", "effectiveFromDate", "effectiveToDate", "price", "time", "setupTime", "breakDownTime",
    "listOrder", "minimumAge", "minimumGuest", "maximumGuest", "minimumStaff", "maximumStaff", "isAutoGratuity", "isAutoServiceCharge", "isCommissionable",
    "comments", "policy", "cancellationPolicy", "isOffsite", "requireStaffAtCheckin", "isAvailableOnWeb", "isInActive"];


  Done(selectedRetailItemId: any) {
    if (selectedRetailItemId > 0) {
      this.selectedRetailItemId = selectedRetailItemId;
      this.isRetailItemChanged = true;
      this.retailItemMiss = false;
      this.retailItemDirty = true;
    } else {
      this.retailItemMiss = true;
    }
  }

  private BuildServiceData(): SpaService {
    return {
      id: this.SpaServiceId,
      serviceDetail: this.BuildServiceDetail(),
      serviceAddOns: this.BuildLinkedAddOn(),
      serviceLocations: this.BuildLinkedLocation(),
      serviceEquipments: this.BuildLinkedEquipment(),
      serviceTherapists: this.BuildLinkedTherpist(),
      serviceMedicalConditions: this.BuildLinkedMedicalCondition(),
      servicePriceTypes: this.BuildLinkedPriceType(),
      serviceAvailableDays: this.BuildAvailableDays(),
      serviceSeasonalDates: this.BuildSeasonalSetup()
    };
  }

  BuildServiceDetail(): any {
    const object: any = {};
    object["code"] = this.FormGrp.controls["code"].value;
    object["description"] = this.FormGrp.controls["description"].value;
    object["points"] = this.FormGrp.controls["points"].value ? this.FormGrp.controls["points"].value : 0;
    object["serviceGroupId"] = this.serviceGroupId;
    let dt: Date = this.FormGrp.controls["effectiveFromDate"].value
    object["effectiveFromDate"] = this.localization.convertDateObjToAPIdate(dt);
    dt = this.FormGrp.controls["effectiveToDate"].value
    object["effectiveToDate"] = this.localization.convertDateObjToAPIdate(dt);
    object["price"] = this.FormGrp.controls["price"].value ? this.localization.currencyToSQLFormat(this.FormGrp.controls["price"].value) : 0;
    object["time"] = this.ServiceTimeArr.length > 0 ? Number(this.ServiceTimeArr[0]) : 0;
    object["setupTime"] = this.SetupTimeArr.length > 0 ? Number(this.SetupTimeArr[0]) : 0;
    object["breakDownTime"] = this.BreakdownTimeArr.length > 0 ? Number(this.BreakdownTimeArr[0]) : 0;
    object["listOrder"] = Number(this.FormGrp.controls["listOrder"].value) ? Number(this.FormGrp.controls["listOrder"].value) : 0;
    object["minimumAge"] = Number(this.FormGrp.controls["minimumAge"].value) ? Number(this.FormGrp.controls["minimumAge"].value) : 0;
    object["minimumGuest"] = Number(this.FormGrp.controls["minimumGuest"].value) ? Number(this.FormGrp.controls["minimumGuest"].value) : 0;
    object["maximumGuest"] = Number(this.FormGrp.controls["maximumGuest"].value) ? Number(this.FormGrp.controls["maximumGuest"].value) : 0;
    object["minimumStaff"] = Number(this.FormGrp.controls["minimumStaff"].value) ? Number(this.FormGrp.controls["minimumStaff"].value) : 0;
    object["maximumStaff"] = Number(this.FormGrp.controls["maximumStaff"].value) ? Number(this.FormGrp.controls["maximumStaff"].value) : 0;
    object["isAutoGratuity"] = this.FormGrp.controls["isAutoGratuity"].value;
    object["isAutoServiceCharge"] = this.FormGrp.controls["isAutoServiceCharge"].value;
    object["GratuityAmount"] = object["isAutoGratuity"] && this.IAGButtonValue == this.localization.currencySymbol ? this.localization.currencyToSQLFormat(this.FormGrp.controls["autoGratuityText"].value) : 0;
    object["GratuityPercent"] = object["isAutoGratuity"] && this.IAGButtonValue == "%" ? this.localization.currencyToSQLFormat(this.FormGrp.controls["autoGratuityText"].value) : 0;
    object["ServiceChargeAmount"] = object["isAutoServiceCharge"] && this.ASCButtonValue == this.localization.currencySymbol ? this.localization.currencyToSQLFormat(this.FormGrp.controls["autoServiceChargeText"].value) : 0;
    object["ServiceChargePercent"] = object["isAutoServiceCharge"] && this.ASCButtonValue == "%" ? this.localization.currencyToSQLFormat(this.FormGrp.controls["autoServiceChargeText"].value) : 0;
    object["isCommissionable"] = this.FormGrp.controls["isCommissionable"].value;
    object["comments"] = this.OtherSetupFormGrp.controls["comments"].value;
    object["policy"] = this.OtherSetupFormGrp.controls["policy"].value;
    object["cancellationPolicy"] = this.OtherSetupFormGrp.controls["cancellationPolicy"].value;
    object["isOffsite"] = this.FormGrp.controls["isOffsite"].value;
    object["requireStaffAtCheckin"] = this.OtherSetupFormGrp.controls["requireStaffAtCheckin"].value;
    object["isAvailableOnWeb"] = this.OtherSetupFormGrp.controls["isAvailableOnWeb"].value;
    // Inactive - to be changed
    object["isInActive"] = this.OtherSetupFormGrp.controls["isInActive"].value;
    object.retailItemId = this.selectedRetailItemId;
    object["colorCode"] = this.OtherSetupFormGrp.controls["isRequireColorCode"].value ? this.seletedColorCode : "";
    object["eFormDatas"] = this.mapper();
    return object;
  }

  mapper() {
    let res = [];
    this.eFormsList.map(x => {
      let obj = {
        eFormId: x.eFormId,
        eformName: x.eFormName
      }
      res.push(obj);
    });
    return res;
  }

  BuildAvailableDays(): SpaServiceAvailableDays {
    let id = 0;
    if (this.isEditService) {
      id = this.AllServiceData.serviceAvailableDays ? this.AllServiceData.serviceAvailableDays.id : 0;
    }
    const availableDays: SpaServiceAvailableDays = {
      id: id,
      typeName: 'SERVICE',
      serviceId: this.SpaServiceId,
      isAvailableOnSunday: false,
      isAvailableOnMonday: false,
      isAvailableOnTuesday: false,
      isAvailableOnWednesday: false,
      isAvailableOnThursday: false,
      isAvailableOnFriday: false,
      isAvailableOnSaturday: false
    };
    for (const element of this.DayArr) {
      switch (element) {
        case 1:
          availableDays.isAvailableOnMonday = true;
          break;
        case 2:
          availableDays.isAvailableOnTuesday = true;
          break;
        case 3:
          availableDays.isAvailableOnWednesday = true;
          break;
        case 4:
          availableDays.isAvailableOnThursday = true;
          break;
        case 5:
          availableDays.isAvailableOnFriday = true;
          break;
        case 6:
          availableDays.isAvailableOnSaturday = true;
          break;
        case 7:
          availableDays.isAvailableOnSunday = true;
          break;
      }
    }
    return availableDays;
  }

  private BuildLinkedTherpist(): SpaServiceTherapist[] {
    var therapist: SpaServiceTherapist[] = [];
    if (this.Therapists.length > 0) {
      const previousTherapist: SpaServiceTherapist[] = this.isEditService && this.AllServiceData.serviceTherapists ? this.AllServiceData.serviceTherapists : [];
      for (let TherapistsTempItem of this.Therapists) {
        if (TherapistsTempItem.selected) {
          const selected: SpaServiceTherapist = previousTherapist.find(x => x.therapistId == TherapistsTempItem.id);
          therapist.push({
            id: selected ? selected.id : 0,
            serviceId: this.SpaServiceId,
            therapistId: TherapistsTempItem.id,
            seniorityLevel: selected ? selected.seniorityLevel : null,
            maximumNumberOfServices: selected ? selected.maximumNumberOfServices : 0
          });
        }
      }
    }
    therapist = therapist.sort((a, b) => a.id - b.id);
    return therapist;
  }

  private BuildLinkedLocation(): SpaServiceLinkLocation[] {
    const locations: any[] = this.ServiceLocations.filter(value => value.selected);
    const loc: SpaServiceLinkLocation[] = [];
    const prevLocations: SpaServiceLinkLocation[] = this.isEditService && this.AllServiceData.serviceLocations ? this.AllServiceData.serviceLocations : [];
    if (locations.length > 0 && locations[0].id !== 0) {
      for (let locationsTempItem of locations) {
        const selected: SpaServiceLinkLocation = prevLocations.find(x => x.locationId == locationsTempItem.id);
        loc.push({
          id: selected ? selected.id : 0,
          serviceId: this.SpaServiceId,
          locationId: locationsTempItem.id
        });
      }
    }
    return loc;
  }

  dataEquipment: any[] = [];
  serviceGroupChange(value: any) {
    console.log(value);
    this.dataEquipment.push(value);
  }

  private BuildLinkedEquipment(): SpaServiceEquipment[] {
    const equip: SpaServiceEquipment[] = [];
    if (this.EquipmentTypesData[0].NumofEquipTypesAdded.length > 0) {
      const prevEquipments: SpaServiceEquipment[] = this.isEditService && this.AllServiceData.serviceEquipments ? this.AllServiceData.serviceEquipments : [];
      for (let item of this.EquipmentTypesData[0].NumofEquipTypesAdded) {
        let selectedValue = item.selectedValue
        if (selectedValue && selectedValue.id) {
          const selected: SpaServiceEquipment = prevEquipments.find(x => x.equipmentId == selectedValue.id);
          equip.push({
            id: selected ? selected.id : 0,
            serviceId: this.SpaServiceId,
            equipmentId: selectedValue.id,
            quantity: this.localization.currencyToSQLFormat(item.value)
          });
        }
      }
    }
    return equip;
  }

  private BuildLinkedAddOn(): SpaServiceAddOn[] {
    const addon: SpaServiceAddOn[] = [];
    if (this.SelectedAddonArr.length > 0) {
      const previousAddOn: SpaServiceAddOn[] = this.isEditService && this.AllServiceData.serviceAddOns ? this.AllServiceData.serviceAddOns : [];
      for (let SelectedAddonArrItem of this.SelectedAddonArr) {
        const selected: SpaServiceAddOn = previousAddOn.find(x => x.addOnId == SelectedAddonArrItem);
        addon.push({
          id: selected ? selected.id : 0,
          serviceId: this.SpaServiceId,
          addOnId: SelectedAddonArrItem
        });
      }
    }
    return addon;
  }

  private BuildLinkedPriceType(): SpaServicePriceType[] {
    const price: SpaServicePriceType[] = [];
    if (this.PriceTypesData[0].NumofPriceTypesAdded.length > 0) {
      for (let PriceTypesDataItem of this.PriceTypesData[0].NumofPriceTypesAdded) {
        let selectedValue = PriceTypesDataItem.selectedValue
        if (selectedValue && selectedValue.id) {
          price.push({
            id: 0,
            serviceId: this.SpaServiceId,
            priceTypeId: selectedValue.id,
            price: this.localization.currencyToSQLFormat(PriceTypesDataItem.value)
          });
        }
      }
    }
    return price;
  }
  serviceCodeSelection(item) {
    let id: number;
    for (let serviceYieldJsonItem of this.serviceYieldJson) {
      if (serviceYieldJsonItem.code === item) {
        id = serviceYieldJsonItem.id;
        break;
      }
    }
    this.CopyService(id);
  }
  CopyService(id: number): any {
    this.http.CallApiWithCallback<number>({
      host: Host.spaManagement,
      success: this.successCallback.bind(this),
      error: this.utilities.errorCallback.bind(this),
      callDesc: 'GetSpecificService',
      method: HttpMethod.Get,
      uriParams: { id: id },
      showError: true,
      extraParams: []
    });
  }
  private BuildLinkedMedicalCondition(): SpaServiceMedicalCondition[] {
    const medData: SpaServiceMedicalCondition[] = [];
    if (this.SelectedMedicalConditionArr.length > 0) {
      const prevMedCondition: SpaServiceMedicalCondition[] = this.isEditService && this.AllServiceData.serviceMedicalConditions ? this.AllServiceData.serviceMedicalConditions : [];
      for (let mediCondItem of this.SelectedMedicalConditionArr) {
        const selected: SpaServiceMedicalCondition = prevMedCondition.find(x => x.medicalConditionId == mediCondItem);
        medData.push({
          id: selected ? selected.id : 0,
          serviceId: this.SpaServiceId,
          medicalConditionId: mediCondItem
        });
      }
    }
    return medData;
  }

  BuildSeasonalSetup() {
    let seasonalArr = this.SeasonalSetupFormGrp.get('seasonalSetups') ? (this.SeasonalSetupFormGrp.get('seasonalSetups') as UntypedFormArray).controls : [];
    let seasonlObjArr = [];
    seasonalArr = seasonalArr.filter(obj => {
      const grp = obj as UntypedFormGroup;
      return (grp.controls["startDate"].value !== '' && grp.controls["endDate"].value !== '')
    });
    if (seasonalArr && seasonalArr.length > 0) {
      seasonlObjArr = seasonalArr.map((obj,i) => {
        const grp = obj as UntypedFormGroup;
        let seasonDays = {
          id: this.isEditService ? grp.controls["id"].value : 0,
          serviceId: this.SpaServiceId,
          fromDate: this.localization.convertDateObjToAPIdate(grp.controls["startDate"].value),
          toDate: this.localization.convertDateObjToAPIdate(grp.controls["endDate"].value),
          allDays: grp.controls["allDays"].value,
          priceMon: grp.controls["mon"].value ? this.localization.currencyToSQLFormat(grp.controls["mon"].value) : grp.controls["mon"].value,
          priceTue: grp.controls["tue"].value ? this.localization.currencyToSQLFormat(grp.controls["tue"].value) : grp.controls["tue"].value,
          priceWed: grp.controls["wed"].value ? this.localization.currencyToSQLFormat(grp.controls["wed"].value) : grp.controls["wed"].value,
          priceThu: grp.controls["thu"].value ? this.localization.currencyToSQLFormat(grp.controls["thu"].value) : grp.controls["thu"].value,
          priceFri: grp.controls["fri"].value ? this.localization.currencyToSQLFormat(grp.controls["fri"].value) : grp.controls["fri"].value,
          priceSat: grp.controls["sat"].value ? this.localization.currencyToSQLFormat(grp.controls["sat"].value) : grp.controls["sat"].value,
          priceSun: grp.controls["sun"].value ? this.localization.currencyToSQLFormat(grp.controls["sun"].value) : grp.controls["sun"].value,
          seasonalDaysOverrideDetail: this.enableOverrideArray[i] && grp.controls["specificDays"].value && grp.controls["specificDays"].value.length > 0 ? grp.controls["specificDays"].value.filter(s => this.isDate(s.overrideDate)).map(a => { return { OverrideDate: this.localization.convertDateObjToAPIdate(a.overrideDate), Price: a.overridePrice } }) : []
          
        }
        for (var propName in seasonDays) {
          if (seasonDays[propName] === null || seasonDays[propName] === undefined || seasonDays[propName] === '') {
            delete seasonDays[propName];
          }
        }
        return seasonDays;
      });
    }

    if (seasonlObjArr && seasonlObjArr.length > 0) {     
      seasonlObjArr = seasonlObjArr.filter(r => r.priceMon >= 0 || r.priceTue >= 0 || r.priceWed >= 0 ||
        r.priceThu >= 0 || r.priceFri >= 0 || r.priceSat >= 0 || r.priceSun >= 0);
    }

    return seasonlObjArr;
  }

  isDate(value: any): boolean {
    return Object.prototype.toString.call(value) === '[object Date]';
  }

  copyServicesBindings() {

    this.setLinkValues("GetSpaServiceTherapistLink", this.AllServiceData == null ? "" : this.AllServiceData.serviceTherapists);
    this.setLinkValues("GetSpaServiceLocationLink", this.AllServiceData == null ? "" : this.AllServiceData.serviceLocations);
    this.maxListOrder = this.popupConfigs.maxListOrder;
    this.FormGrp.controls["listOrder"].setValue(this.maxListOrder);
    this.codeDisabled = false;
    this.FormGrp.get('effectiveToDate').patchValue(this.utilities.getDate(this.dataInput.effectiveToDate));
    this.FormGrp.controls["code"].setValue("");
    this.minFromDate = this.defaultEffectiveFromDt;
    this.isEditService = false;
    this.SpaServiceId = undefined;
    if (this.AllServiceData) {
      this.AllServiceData.servicePriceTypes = this.AllServiceData.servicePriceTypes ? this.AllServiceData.servicePriceTypes : [];
    }
    this.PriceTypesData[0].NumofPriceTypesAdded = [];
    if (this.AllServiceData.servicePriceTypes.length > 0) {
      for (let dataItem of this.AllServiceData.servicePriceTypes) {
        this.PriceTypesData[0].NumofPriceTypesAdded.push({
          'options': this.servicePriceType,
          'selectedValue': this.servicePriceType.find(x => x.id == dataItem.priceTypeId),
          value: dataItem.price
        });
      }
      this.PriceTypesData[0].selectedItems = this.servicePriceType.filter(x => this.AllServiceData.servicePriceTypes.map(y => y.priceTypeId).includes(x.id));
    }
    else {
      this.PriceTypesData[0].NumofPriceTypesAdded.push({
        'options': this.servicePriceType,
        'selectedValue': '',
        value: ''
      });
      this.PriceTypesData[0].selectedItems = [];
    }

    let SG_NumofStaffAdded = this.PriceTypesData[0].NumofPriceTypesAdded;
    let SelectedPriceTypeArr = this.PriceTypesData[0].selectedItems;
    let selectedIds = SelectedPriceTypeArr.map(y => y.id);
    SG_NumofStaffAdded.forEach(x => {
      let selectedPriceType = x.selectedValue;
      let NewArr = this.servicePriceType.filter(a => !selectedIds.includes(a.id) || (selectedPriceType && a.id == selectedPriceType.id));
      x.options = [...NewArr];
    });

    this.cascadeData.addedItems = [...SG_NumofStaffAdded];
    this.EquipmentTypesData[0].NumofEquipTypesAdded = [];
    if (this.AllServiceData.serviceEquipments.length > 0) {
      for (let item of this.AllServiceData.serviceEquipments) {
        this.EquipmentTypesData[0].NumofEquipTypesAdded.push({
          'options': this.Equipments,
          'selectedValue': this.Equipments.find(x => x.id == item.equipmentId),
          value: item.quantity
        });
      }
      this.EquipmentTypesData[0].selectedItems = this.Equipments.filter(x => this.AllServiceData.serviceEquipments.map(y => y.id).includes(x.id));
    } else {
      this.EquipmentTypesData[0].NumofEquipTypesAdded.push({
        'options': this.Equipments,
        'selectedValue': '',
        value: ''
      });
      this.EquipmentTypesData[0].selectedItems = [];
    }

    let SG_NumofStaffAddedEquip = this.EquipmentTypesData[0].NumofEquipTypesAdded;
    let SelectedPriceTypeArrEquip = this.EquipmentTypesData[0].selectedItems;
    let selectedIdsEquip = SelectedPriceTypeArrEquip.map(y => y.id);
    SG_NumofStaffAddedEquip.forEach(x => {
      let selectedPriceTypeEquip = x.selectedValue;
      let NewArrEquip = this.Equipments.filter(a => !selectedIdsEquip.includes(a.id) || (selectedPriceTypeEquip && a.id == selectedPriceTypeEquip.id));
      x.options = [...NewArrEquip];
    });
    this.serviceEquipmentData.addedItems = [...SG_NumofStaffAddedEquip];
    this.selectedOtherTabIndex = true;
    if (typeof this.dataInput !== 'undefined') {
      this.OtherSetupFormGrp.controls.cancellationPolicy.setValue(this.dataInput.cancellationPolicy && this.dataInput.cancellationPolicy != '' ? this.dataInput.cancellationPolicy : this.SYSTEMDEFAULTCANCELLATIONPOLICY);
    }

  }

  successCallback<T>(result: BaseResponse<T>, callDesc: string, extraParams: any[]): void {
    if (callDesc == "GetAvailableDays") {
      //client side
      this.Days = <any>result.result;
    }
    if (callDesc == "GetAllLoc") {
      this.ServiceLocations = <any>result.result
      this.ServiceLocations = this.utilities.sortObj(this.ServiceLocations, 'listOrder');
      this.setLinkValues("GetSpaServiceLocationLink", this.AllServiceData == null ? "" : this.AllServiceData.serviceLocations);
      this.originalServiceLocations = _.cloneDeep(this.ServiceLocations);
    }
    if (callDesc == "GetAllServiceGrp") {
      this.Groups = <any>result.result
    }
    if (callDesc == "GetAllSpaService") {
      this.serviceYieldJson = <any>result.result
    }
    if (callDesc == "GetAllAddOns") {
      this.AddOns = <any>result.result
    }
    if (callDesc == "GetSpecificService") {
      this.dataInput = <any>result.result
      this.SeasonalSetupFormGrp = this.Form.group({
        seasonalSetups: this.Form.array([this.createSeasonalSetupFormGroup('')])
      });
      this.dataInputChanges();
      this.copyServicesBindings();

    }
    if (callDesc == "GetAllMC") {
      this.MedicalConditions = <any>result.result
    }
    if (callDesc == "GetAllTherapist") {
      this.Therapists = <any>result.result;
      this.Therapists = this.utilities.sortObj(this.Therapists, 'lastName');
      this.Therapists = this.Therapists.filter(itemResult => {
        return itemResult.isActive;
      });
      this.setLinkValues("GetSpaServiceTherapistLink", this.AllServiceData == null ? "" : this.AllServiceData.serviceTherapists);
      this.originalTherapists = _.cloneDeep(this.Therapists)
    }
    if (callDesc == "GetAllEquip") {
      this.Equipments = <any>result.result
      let allEquipment = this.Equipments;
      if (this.AllServiceData) {
        this.AllServiceData.serviceEquipments = this.AllServiceData.serviceEquipments ? this.AllServiceData.serviceEquipments : [];
      }
      if (this.isEditService && this.AllServiceData.serviceEquipments.length > 0) {
        for (let item of this.AllServiceData.serviceEquipments) {
          this.EquipmentTypesData[0].NumofEquipTypesAdded.push({
            'options': allEquipment,
            'selectedValue': this.Equipments.find(x => x.id == item.equipmentId),
            value: item.quantity
          });
        }
        this.EquipmentTypesData[0].selectedItems = this.Equipments.filter(x => this.AllServiceData.serviceEquipments.map(y => y.id).includes(x.id));
      } else {
        this.EquipmentTypesData[0].NumofEquipTypesAdded.push({
          'options': allEquipment,
          'selectedValue': '',
          value: ''
        });
      }

      let SG_NumofStaffAdded = this.EquipmentTypesData[0].NumofEquipTypesAdded;
      let SelectedPriceTypeArr = this.EquipmentTypesData[0].selectedItems;
      let selectedIds = SelectedPriceTypeArr.map(y => y.id);
      SG_NumofStaffAdded.forEach(x => {
        let selectedPriceType = x.selectedValue;
        let NewArr = this.Equipments.filter(a => !selectedIds.includes(a.id) || (selectedPriceType && a.id == selectedPriceType.id));
        x.options = [...NewArr];
      });
      this.serviceEquipmentData.addedItems = [...SG_NumofStaffAdded];
    }

    if (callDesc == "GetAllPriceTypes") {
      let allPriceTypes: AllPriceType[] = <any>result.result as AllPriceType[];
      this.servicePriceType = allPriceTypes ? allPriceTypes : [];
      if (this.AllServiceData) {
        this.AllServiceData.servicePriceTypes = this.AllServiceData.servicePriceTypes ? this.AllServiceData.servicePriceTypes : [];
      }
      if (this.isEditService && this.AllServiceData.servicePriceTypes.length > 0) {
        for (let dataItem of this.AllServiceData.servicePriceTypes) {
          this.PriceTypesData[0].NumofPriceTypesAdded.push({
            'options': allPriceTypes,
            'selectedValue': this.servicePriceType.find(x => x.id == dataItem.priceTypeId),
            value: dataItem.price
          });
        }
        this.PriceTypesData[0].selectedItems = this.servicePriceType.filter(x => this.AllServiceData.servicePriceTypes.map(y => y.priceTypeId).includes(x.id));
      }
      else {
        this.PriceTypesData[0].NumofPriceTypesAdded.push({
          'options': allPriceTypes,
          'selectedValue': '',
          value: ''
        });
      }

      let SG_NumofStaffAdded = this.PriceTypesData[0].NumofPriceTypesAdded;
      let SelectedPriceTypeArr = this.PriceTypesData[0].selectedItems;
      let selectedIds = SelectedPriceTypeArr.map(y => y.id);
      SG_NumofStaffAdded.forEach(x => {
        let selectedPriceType = x.selectedValue;
        let NewArr = this.servicePriceType.filter(a => !selectedIds.includes(a.id) || (selectedPriceType && a.id == selectedPriceType.id));
        x.options = [...NewArr];
      });

      this.cascadeData.addedItems = [...SG_NumofStaffAdded];
    }

    if (callDesc == "CreateSpaService") {
      this.dialogRef.close();
    }

    if (callDesc == "updateSpaService") {
      this.dialogRef.close();
      if (this.isAssociatedWithPackage)
        this.utilities.ShowErrorMessage(this.localization.captions.common.Warning, this.captions.ServiceLinkedWithPackage, ButtonType.Ok);
    }

    if (callDesc == "GetConfiguration") {
      const res: SystemConfig = <any>result.result;
      this.SYSTEMDEFAULTCANCELLATIONPOLICY = res.value;
      if (typeof this.dataInput !== 'undefined') {
        this.OtherSetupFormGrp.controls.cancellationPolicy.setValue(this.dataInput.cancellationPolicy && this.dataInput.cancellationPolicy != '' ? this.dataInput.cancellationPolicy : this.SYSTEMDEFAULTCANCELLATIONPOLICY);
      } else {
        this.OtherSetupFormGrp.controls.cancellationPolicy.setValue(this.SYSTEMDEFAULTCANCELLATIONPOLICY);
      }
    }
    if (callDesc == "GetAllSettingByModule") {
      const miscConfig: Object = result.result && result.result[0];
      console.log(miscConfig);
      if (miscConfig.hasOwnProperty('SEASONALPRICE_OVERRIDE')) {
        this.showSeasonalOverrideDate = miscConfig['SEASONALPRICE_OVERRIDE'];
      }
      if (miscConfig.hasOwnProperty('APPOINMENT_SYSTEMDEFAULTCANCELLATIONPOLICY')) {
        this.SYSTEMDEFAULTCANCELLATIONPOLICY = miscConfig['APPOINMENT_SYSTEMDEFAULTCANCELLATIONPOLICY'];
        if (typeof this.dataInput !== 'undefined') {
          this.OtherSetupFormGrp.controls.cancellationPolicy.setValue(this.dataInput.cancellationPolicy && this.dataInput.cancellationPolicy != '' ? this.dataInput.cancellationPolicy : this.SYSTEMDEFAULTCANCELLATIONPOLICY);
        } else {
          this.OtherSetupFormGrp.controls.cancellationPolicy.setValue(this.SYSTEMDEFAULTCANCELLATIONPOLICY);
        }
      }
      if (miscConfig.hasOwnProperty('APPOINTMENT_REQUIRE_SERVICE_POINTS')) {
        if (miscConfig['APPOINTMENT_REQUIRE_SERVICE_POINTS']) {
          this.servicePointsInput.isRequired = true;
          this.servicePointsInput = { ...this.servicePointsInput };
        } else {
          this.servicePointsInput.disabled = true;
          this.servicePointsInput = { ...this.servicePointsInput };
        }
      }
    }
  }

  setLinkValues(type: any, result: any) {
    const loc = result;
    let count: any;
    switch (type) {
      case "GetSpaServiceLocationLink": {
        if (loc && loc.length > 0) {
          for (let locItem of loc) {
            for (let ServiceLocationsItem of this.ServiceLocations) {
              if (ServiceLocationsItem.id == locItem.locationId) {
                ServiceLocationsItem.selected = true;
              }
            }
          }
        }
        this.ServiceLocationsNonEdited = _.cloneDeep(this.ServiceLocations);
        count = this.ServiceLocations.filter(value => value.selected).length;
        if (count > 0) {
          this.ServiceLocationsFormGrp.controls.serviceLocations.setValue(count);
        } else {
          this.setLocationValue();
        }
      } break;
      case "GetSpaServiceEquipmentLink": {
        const equip = result;
        if (equip && equip.length > 0) {
          for (let equipItem of equip) {
            this.SelectedEquipmentArr.push(equipItem.equipmentId);
          }
        }
        this.EquipmentArrNonEdited = _.cloneDeep(this.SelectedEquipmentArr);
      } break;
      case "GetSpaServiceAddOnLink": {
        const addon = result;
        if (addon && addon.length > 0) {
          for (let addonItem of addon) {
            this.SelectedAddonArr.push(addonItem.addOnId);
          }
        }
        this.AddonArrNonEdited = _.cloneDeep(this.SelectedAddonArr);
      } break;
      case "GetSpaServiceMedConLink": {
        const medCon = result;
        if (medCon && medCon.length > 0) {
          for (let medConItem of medCon) {
            this.SelectedMedicalConditionArr.push(medConItem.medicalConditionId);
          }
        }
        this.MedicalConditionArrNonEdited = _.cloneDeep(this.SelectedMedicalConditionArr);
      } break;
      case "GetSpaServiceTherapistLink": {
        const therapist = result;
        if (therapist && therapist.length > 0) {
          for (let therapisttempItem of therapist) {
            for (let TherapistsTempItem of this.Therapists) {
              if (TherapistsTempItem.id == therapisttempItem.therapistId) {
                TherapistsTempItem.selected = true;
              }
            }
          }
          this.TherapistsNonEdited = _.cloneDeep(this.Therapists);
          const therapistCount = this.Therapists.filter(x => x.selected).length;
          if (therapistCount > 0) {
            this.TherapistsFormGrp.controls.therapists.setValue(therapistCount);
          } else {
            this.TherapistsFormGrp.controls.therapists.setValue('');
          }
        }
      } break;
      case "GetSpaServiceAvailableDaysLink": {
        const availDays = result;

        if (availDays) {
          this.DayArr = [];
          if (availDays.isAvailableOnMonday) {
            this.DayArr.push(1);
          }
          if (availDays.isAvailableOnTuesday) {
            this.DayArr.push(2);
          }
          if (availDays.isAvailableOnWednesday) {

            this.DayArr.push(3);
          }
          if (availDays.isAvailableOnThursday) {

            this.DayArr.push(4);
          }
          if (availDays.isAvailableOnFriday) {
            this.DayArr.push(5);
          }
          if (availDays.isAvailableOnSaturday) {
            this.DayArr.push(6);
          }
          if (availDays.isAvailableOnSunday) {
            this.DayArr.push(7);
          }
        }
      } break;
      case "GetSpaServicePriceTypeLink": {
        this.PriceTypeArrNonEdited = _.cloneDeep(this.SelectedPriceTypeArr);
      } break;
      case "GetSpaServiceSeasonalDaysLink": {
        this.patchSeasonDaysForm(result);
      }
    }

  }

  togglebuttonVal(s) {

    if (s == 'IAGButtonValue') {
      let IAGValue = this.FormGrp.controls["autoGratuityText"].value;
      if (this.IAGButtonValue == this.localization.currencySymbol) {
        const autoGratuityTextEle: any = document.getElementById('autoGratuityText');
        IAGValue = autoGratuityTextEle.value;
        this.IAGButtonValue = '%';
        if (IAGValue.toString().trim().length > 0) {
          this.FormGrp.controls["autoGratuityText"].setValue(0);
          this.FormGrp.controls["autoGratuityText"].markAsDirty();
          this.gratuityMaxlength = Maxlength.PERCENTAGE;
        }
      } else {
        this.IAGButtonValue = this.localization.currencySymbol;
        if (IAGValue.toString().trim().length > 0) {
          this.FormGrp.controls["autoGratuityText"].setValue(0);
          this.FormGrp.controls["autoGratuityText"].markAsDirty();
          this.gratuityMaxlength = Maxlength.FLATAMOUNT;
        }
      }
    }
    if (s == 'ASCButtonValue') {
      let AGCharge = this.FormGrp.controls["autoServiceChargeText"].value;
      if (this.ASCButtonValue == this.localization.currencySymbol) {
        const autoServiceChargeTextEle: any = document.getElementById('autoServiceChargeText');
        AGCharge = autoServiceChargeTextEle.value;
        this.ASCButtonValue = '%';
        if (AGCharge.toString().trim().length > 0) {
          this.FormGrp.controls["autoServiceChargeText"].setValue(0);
          this.FormGrp.controls["autoServiceChargeText"].markAsDirty();
          this.ServiceChargeMaxlength = Maxlength.PERCENTAGE;
        }
      } else {
        this.ASCButtonValue = this.localization.currencySymbol;
        if (AGCharge.toString().trim().length > 0) {
          this.FormGrp.controls["autoServiceChargeText"].setValue(0);
          this.FormGrp.controls["autoServiceChargeText"].markAsDirty();
          this.ServiceChargeMaxlength = Maxlength.FLATAMOUNT;
        }
      }
    }
    this.FormGrp.markAsDirty();
  }

  setFormControlValue(control: any, attributes: any) {
    control.setValue(attributes.value);
  }

  FormatPercentage(action: string) {
    if (action == 'Gratuity') {
      const IAGValue = this.FormGrp.controls["autoGratuityText"].value;
      if (this.IAGButtonValue === '%' && IAGValue.toString().trim().length > 0) {
        const percent = this.localization.currencyToSQLFormat(IAGValue);
        this.FormGrp.controls["autoGratuityText"].setValue(this.localization.localizePercentage(percent.toString()));
      }
    } else if (action == 'ServiceCharge') {
      const AGCharge = this.FormGrp.controls["autoServiceChargeText"].value;
      if (this.ASCButtonValue === '%' && AGCharge.toString().trim().length > 0) {
        const percent = this.localization.currencyToSQLFormat(AGCharge);
        this.FormGrp.controls["autoServiceChargeText"].setValue(this.localization.localizePercentage(percent.toString()));
      }
    }
  }

  setAmountorPercent(data: any): any {
    let gratAmtPerc: any;
    let ServAmtPerc: any;

    if (data.gratuityAmount && data.gratuityAmount > 0) {
      this.IAGButtonValue = this.localization.currencySymbol;
      gratAmtPerc = data.gratuityAmount && data.gratuityAmount > 0 ? data.gratuityAmount : 0;
      this.gratuityMaxlength = Maxlength.FLATAMOUNT;
    } else {
      this.IAGButtonValue = '%';
      gratAmtPerc = data.gratuityPercent && data.gratuityPercent > 0 ? this.localization.localizePercentage(data.gratuityPercent) : this.localization.localizePercentage('0');
      this.gratuityMaxlength = Maxlength.PERCENTAGE;
    }

    if (data.serviceChargeAmount && data.serviceChargeAmount > 0) {
      this.ASCButtonValue = this.localization.currencySymbol;
      ServAmtPerc = data.serviceChargeAmount && data.serviceChargeAmount > 0 ? data.serviceChargeAmount : 0;
      this.ServiceChargeMaxlength = Maxlength.FLATAMOUNT;
    } else {
      this.ASCButtonValue = '%';
      ServAmtPerc = data.serviceChargePercent && data.serviceChargePercent > 0 ? this.localization.localizePercentage(data.serviceChargePercent) : this.localization.localizePercentage('0');
      this.ServiceChargeMaxlength = Maxlength.PERCENTAGE;
    }

    this.FormGrp.patchValue({
      autoGratuityText: gratAmtPerc,
      autoServiceChargeText: ServAmtPerc
    });
  }

  close() {
    this.business.promptUserDialog(this.dialogRef);
  }

  bindElements(noOfElements: number, type) {
    switch (type) {
      case 'MC': {
        this.mcCount = noOfElements;
      } break;
      case 'AO': {
        this.aoCount = noOfElements;
      } break;
      case 'PT': {
        this.ptCount = noOfElements;
      } break;
      case 'EQ': {
        this.eqCount = noOfElements;
      } break;
      case 'SD': {
        this.sdCount = noOfElements;
      } break;
    }
  }

  StepperChange($event): void {
    var index = $event.selectedIndex;
    if (index == 3) {
      let defaultPrice = this.FormGrp.controls["price"].value || '0';
      this.inputData = {
        inputType: "text",
        inputPlaceholder: this.captions.price,
        inputDefaultValue: defaultPrice,
        inputError: `${this.commonCaptions.Missing} ${this.captions.price}`,
      }

      this.serviceEquipmentInputData = {
        inputType: "text",
        inputPlaceholder: 'Require Qty',
        inputDefaultValue: 1,
        availableDefaultValue: 10,
        inputError: this.commonCaptions.ValidNumber,
        missingError: this.commonCaptions.MissingRequiredQty
      }

      setTimeout(() => {
        this.selectedOtherTabIndex = true;
      }, 1);
    }
    if (index == 4) {
      setTimeout(() => {
        this.selectedSeasonalTabIndex = true;
      }, 1);
    }
  }

  updateInput(StaffDet, event) {
    StaffDet.price = event.target.value;
    this.markOverAllFormGroupAsDirty();
  }

  optionChangeHandler(arg) {
    if (arg) {
      this.markOverAllFormGroupAsDirty();
      this.PriceTypesData[0].NumofPriceTypesAdded = arg.addedItems;
      this.PriceTypeFormGroup = arg.DropDownControls;
    }
  }

  optionChangeHandlerService(arg) {
    if (arg) {
      this.markOverAllFormGroupAsDirty();
      this.EquipmentTypesData[0].NumofEquipTypesAdded = arg.addedItems;
      this.EquipmentTypesData = { ...this.EquipmentTypesData };
      this.EquipmentFormGroup = arg.DropDownControls;
      console.log("🚀 ~ file: spa-services.component.ts ~ line 1438 ~ SpaServicesComponent ~ optionChangeHandlerService ~ arg", arg);
    }
  }

  createpriceTypeItem(type, value): UntypedFormGroup {
    return this.Form.group({
      'type': type, 'NumofPriceTypesAdded': [], 'selectedPriceTypeArr': []
    });
  }

  addpriceTypeItem(type, staffdata, i): void {
    this.currentIndexemail = i + 1;
    this.pricetypeArr = this.OtherSetupFormGrp.get('pricetype') as UntypedFormArray;
    this.selectedPriceTypeArray.push(this.OtherSetupFormGrp.value.pricetype[i]);

  }

  removepriceTypeItem(i: any, d?: any, f?: any) {
    this.selectedPriceTypeArray.splice(i, 1);
    this.pricetypeArr.removeAt(i);
    this.currentIndexemail = i - 1;
  }

  validateSearchControl(isSearchControlinValid: boolean) {
    this.retailItemMiss = !isSearchControlinValid;
  }

  changeColor($event: any): void {
    this.seletedColorCode = $event.color.hex;
    this.OtherSetupFormGrp.markAsDirty();
  }

  isOffsiteEvent(event) {
    this.FormGrp.controls.isOffsite.setValue(event[0]);
    this.setLocationValue();
  }

  setLocationValue() {
    if (this.FormGrp.controls.isOffsite.value) {
      if ((this.ServiceLocationsFormGrp.controls.serviceLocations.value === '' || this.ServiceLocationsFormGrp.controls.serviceLocations.value === 0)) {
        this.ServiceLocationsFormGrp.controls.serviceLocations.setValue(0);
      }
    } else {
      if ((this.ServiceLocationsFormGrp.controls.serviceLocations.value === '' || this.ServiceLocationsFormGrp.controls.serviceLocations.value === 0)) {
        this.ServiceLocationsFormGrp.controls.serviceLocations.setValue('');
      }
    }
  }

  async getLinkedItemDesc(retailItemId: number) {
    var itemText = await this.business.GetLinkedRetailItemTextByItemId(retailItemId);
    this.OtherSetupFormGrp.controls['linkedItem'].setValue(itemText);
  }

  async searchTextEmit(searchText) {
    this.retailItemMiss = true;
    this.searchFilter = [];
    this.guid = this.utilities.generateGUID();
    let body: ItemSearchRequest = {
      itemType: this.retailItemType,
      searchKey: searchText,
      requestId: this.guid
    };
    var response = await this.business.SearchRetailItem(body);
    if (response && response.requestId === this.guid) {
      this.searchFilter = response.retailItems;
    }
  }

  patchSeasonDaysForm(formValues) {
    if (formValues && formValues.length > 0) {
      const seasonalSetup = this.SeasonalSetupFormGrp.get('seasonalSetups') as UntypedFormArray;
      this.addOVerrideGroupInPatchSeasonalDate(0, formValues[0].seasonalDaysOverrideDetail);
      let i = 1;
      while (i < formValues.length) {
        seasonalSetup.push(this.createSeasonalSetupFormGroup(''));
        this.addOVerrideGroupInPatchSeasonalDate(i, formValues[i].seasonalDaysOverrideDetail);
        i = i + 1;
      }
      const seasonDaysFormValues = formValues.map(val => {
        return {
          id: val.id,
          startDate: new Date(val.fromDate),
          endDate: new Date(val.toDate),
          minStartDate: new Date(val.fromDate) < this.minFromDate ? new Date(val.fromDate) : this.minFromDate,
          minEndDate: new Date(val.fromDate),
          allDays: '',
          sun: val.priceSun !== null ? val.priceSun : '',
          mon: val.priceMon !== null ? val.priceMon : '',
          tue: val.priceTue !== null ? val.priceTue : '',
          wed: val.priceWed !== null ? val.priceWed : '',
          thu: val.priceThu !== null ? val.priceThu : '',
          fri: val.priceFri !== null ? val.priceFri : '',
          sat: val.priceSat !== null ? val.priceSat : '',
          // overridePrice: val.seasonalDaysOverrideDetail !== null ? val.seasonalDaysOverrideDetail.price : ''
          specificDays: this.createSpecificArray(val.seasonalDaysOverrideDetail)
        }
      });

      seasonalSetup.patchValue(seasonDaysFormValues);
      const setupValues = seasonalSetup.value;
      setupValues.forEach((value, index) => {
        this.enableOrDisableDays(index);
      });
      this.addFilterDates();
    }
  }

  addFilterDates() {
    const serviceSeasonalDatesArray = this.SeasonalSetupFormGrp.get('seasonalSetups') as FormArray;

    serviceSeasonalDatesArray.controls.forEach((serviceControl) => {
      const seasonalDaysOverrideDetailArray = serviceControl.get('specificDays') as FormArray;

      seasonalDaysOverrideDetailArray.controls.forEach((overrideControl, index) => {
        const filterDates = this.transform(index, seasonalDaysOverrideDetailArray);
        overrideControl.get('filterDates')?.setValue(filterDates);
      });
    });
  }

  transform(index: number, formArray: FormArray): (date: Date | null) => boolean {
    const disabledDates = formArray.controls
      .map((control, idx) => (idx !== index ? control.get('overrideDate')?.value : null))
      .filter((date: Date | null) => date !== null);

    return (date: Date | null): boolean => {
    //   if (!date) return false;
    //   const time = date.getTime();
    //   // return !disabledDates.some((disabledDate) => new Date(disabledDate).getTime() === time);
    //   return (this.localization.convertDateObjToAPIdate(a.overrideDate)).includes(this.localization.convertDateObjToAPIdate(date)) ? true : false
    // }
    return true
    };
  }
    // 

  createSpecificArray(array) {
    return array?.map(x => {
      return {
        overrideDate:  this.utilities.getDate(x.overrideDate), overridePrice: x.price
      }
    })
  }

  enableOrDisableDays(index) {
    const seasonSetups = this.SeasonalSetupFormGrp.get('seasonalSetups') as UntypedFormArray;
    const seasonSetup = seasonSetups['controls'][index]['controls'];
    this.disableSeasonDays(seasonSetup);
    const startDate = _.cloneDeep(seasonSetup.startDate.value);
    const endDate = _.cloneDeep(seasonSetup.endDate.value);
    if (startDate && endDate) {
      seasonSetup.allDays.enable({ emitEvent: false });
    }
    const diffTime = endDate - startDate;
    let daysDiff = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
    daysDiff = daysDiff + 1;
    this.enableOverrideArray[index] = daysDiff > 7;
    if (daysDiff < 7) {
      for (let i = 0; i < daysDiff; i++) {
        this.enableSeasonDay(startDate.getDay(), seasonSetup);
        startDate.setDate(startDate.getDate() + (i == 0 ? (i + 1) : 1));
      }
    } else {
      seasonSetups['controls'][index].enable({ emitEvent: false });
    }
    this.emptyDisabledSeasonDays(seasonSetup);
    console.log(seasonSetups['controls'][index]);
    console.log(diffTime);
    console.log(daysDiff);
  }

  enableSeasonDay(day: Days, seasonSetup) {
    switch (day) {
      case Days.Sunday:
        seasonSetup.sun.enable({ emitEvent: false });
        break;
      case Days.Monday:
        seasonSetup.mon.enable({ emitEvent: false });
        break;
      case Days.Tuesday:
        seasonSetup.tue.enable({ emitEvent: false });
        break;
      case Days.Wednesday:
        seasonSetup.wed.enable({ emitEvent: false });
        break;
      case Days.Thursday:
        seasonSetup.thu.enable({ emitEvent: false });
        break;
      case Days.Friday:
        seasonSetup.fri.enable({ emitEvent: false });
        break;
      case Days.Saturday:
        seasonSetup.sat.enable({ emitEvent: false });
        break;
    }
  }

  disableSeasonDays(seasonSetup) {
    seasonSetup.allDays.disable({ emitEvent: false });
    seasonSetup.sun.disable({ emitEvent: false });
    seasonSetup.mon.disable({ emitEvent: false });
    seasonSetup.tue.disable({ emitEvent: false });
    seasonSetup.wed.disable({ emitEvent: false });
    seasonSetup.thu.disable({ emitEvent: false });
    seasonSetup.fri.disable({ emitEvent: false });
    seasonSetup.sat.disable({ emitEvent: false });
  }

  emptyDisabledSeasonDays(seasonSetup) {
    const startDate = _.cloneDeep(seasonSetup.startDate.value);
    const endDate = _.cloneDeep(seasonSetup.endDate.value);
    if (startDate && endDate) {
      seasonSetup.allDays.enable({ emitEvent: false });
    }
    this.days.forEach(val => {
      if (seasonSetup[val].disabled) {
        seasonSetup[val].setValue('', { emitEvent: false });
      }
    });
  }

  openEFormsPopup(from) {
    let formData;
    if (from === 'create') {
      formData = null;
    } else if (from === 'edit') {
      formData = this.eFormsList;
    }
    let res = this.dialog.open(DmEformsSpaComponent, {
      width: '50%',
      height: '570px',
      hasBackdrop: true,
      data: {
        guestId: "",
        isFromSpaService: this.isEditService,
        formList: formData
      }
    }).afterClosed().pipe(takeUntil(this.destroyed$)).subscribe(s => {
      //this.stepper.next();
      if (s) {
        console.log(s);
        this.eFormsList = s;
        this.tooltipChange();
        this.serviceOverAllFormGroup.markAsDirty();
      } else {
        // this.eFormsList = this.eFormsList$;
        this.tooltipChange();
      }
    });
  }

  tooltipChange() {
    let res1 = [];
    this.eFormsList.forEach(x => {
      res1.push(x.eFormName);
    })
    this.eForms = JSON.stringify(res1);
    this.eForms = this.eForms.replace(/[\[\]']+/g, '');
    this.eForms = this.eForms.replace(/["']/g, "")
  }

  public dateChanged(outidx: number,inneridx: number, event, fromPatch = false): void {
    if (event) {
    }
    let editedArray = this.SeasonalSetupFormGrp['controls'].seasonalSetups['controls'][outidx]['controls']['specificDays']['controls'][inneridx]['controls'];
    if(!editedArray.overrideDate.value) {
      editedArray.overridePrice.setValue('');
    }
      // this.addFilterDates();
      // this.
  this.SeasonalSetupFormGrp.updateValueAndValidity();
  }

  public overRideDateFilter = (date: Date) => {
    if (date != null && this.SeasonalSetupFormGrp.controls.seasonalSetups['controls'][this.activeSeasonalSetupFormGrp].value.specificDays
      && this.SeasonalSetupFormGrp.controls.seasonalSetups['controls'][this.activeSeasonalSetupFormGrp].value.specificDays.filter(s => s.overrideDate != '').length > 0
      && this.SeasonalSetupFormGrp.controls.seasonalSetups['controls'][this.activeSeasonalSetupFormGrp].value.specificDays.filter(s => s.overrideDate != '').map(a => this.localization.convertDateObjToAPIdate(a.overrideDate)).includes(this.localization.convertDateObjToAPIdate(date))) {
      return false;
    }
    return true;
  }

  overrideFilter(date) {
    if (date != null && this.SeasonalSetupFormGrp.controls.seasonalSetups['controls'][this.activeSeasonalSetupFormGrp].value.specificDays
      && this.SeasonalSetupFormGrp.controls.seasonalSetups['controls'][this.activeSeasonalSetupFormGrp].value.specificDays.filter(s => s.overrideDate != '').length > 0
      && this.SeasonalSetupFormGrp.controls.seasonalSetups['controls'][this.activeSeasonalSetupFormGrp].value.specificDays.filter(s => s.overrideDate != '').map(a => this.localization.convertDateObjToAPIdate(a.overrideDate)).includes(this.localization.convertDateObjToAPIdate(date))) {
      return false;
    }
    return true;
  }

  addSeasonalDatesOverride(outIdx) {
    const arr = this.SeasonalSetupFormGrp.get('seasonalSetups')['controls'][outIdx]['controls']['specificDays'] as UntypedFormArray;
    arr.push(this.createOverrideDateGroup());
  }

  addOVerrideGroupInPatchSeasonalDate(idx: number, formvalue) {
    const overrideGroup = this.SeasonalSetupFormGrp.get('seasonalSetups')['controls'][idx]['controls']['specificDays'] as UntypedFormArray;
    if (formvalue && formvalue.length > 1) {
      let i = 1;
      while (i < formvalue.length) {
        overrideGroup.push(this.createOverrideDateGroup());
        i = i + 1;
      }
    }
  }

  removeSeasonalDatesOverride(outIdx, innerIdx) {
    const arr = this.SeasonalSetupFormGrp.get('seasonalSetups')['controls'][outIdx]['controls']['specificDays'] as UntypedFormArray;
    // if (this.isEditService && arr['controls'][idx].value.id) {
    //   this.serviceOverAllFormGroup.markAsDirty();
    // }
    arr.removeAt(innerIdx);
  }

  createOverrideDateGroup() {
    return this.Form.group({ overrideDate: '', overridePrice: '',filterDates: '' });
  }

}

@Pipe({
  name: 'dateFilter',
})
export class DateFilterPipe implements PipeTransform {
  constructor(private localization: Localization) {} // Inject the localization service

  transform(index: number, formArray: FormArray): (date: Date | null) => boolean {
    

    return (date: Date | null): boolean => {
      const disabledDates = formArray.controls
      .map((control, idx) => (idx !== index ? control.get('overrideDate')?.value : null))
      .filter((date: Date | null) => date !== null);
      console.log(disabledDates)
      if (!date) return false;
      const apiDate = this.localization.convertDateObjToAPIdate(date);
      return !disabledDates.some((disabledDate) =>
        this.localization.convertDateObjToAPIdate(disabledDate) === apiDate
      );
    };
  }
}