import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { Component, ElementRef, ViewChild, Input, OnInit, Output, ViewEncapsulation, EventEmitter } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { MatChipInputEvent } from '@angular/material/chips';
import { Observable } from 'rxjs';
import { AppointmentpopupService } from '../service/appointmentpopup.service';
import { SpaLocalization } from '../../core/localization/spa-localization';
import * as GlobalConst from '../../shared/globalsContant';
import { ClientLabel } from '../business/new-booking.model';
import { FormatText } from '../pipes/formatText-pipe.pipe';
import { SpaUtilities } from '../utilities/spa-utilities';
import { ClientType } from '../globalsContant';
import { HttpMethod, HttpServiceCall } from '../service/http-call.service';

/**
 * @title Chips Autocomplete
 */
@Component({
  selector: 'chips-autocomplete-example',
  templateUrl: 'multiplesearch.component.html',
  styleUrls: ['multiplesearch.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ChipsAutocompleteExample implements OnInit {
  visible = true;
  selectable = true;
  removable = true;
  addOnBlur = false;
  change = true;
  separatorKeysCodes: number[] = [ENTER, COMMA];
  searchCtrl = new UntypedFormControl();
  searchResults: Observable<any[]>;
  expandBool: boolean = false;
  searchValue = '';
  initialValue: number;
  requestUid = '';
  timer = null;
  isClearSearchText: boolean = true;
  CreditBook = 'Credit Book';
  clientType = ClientType;
  floatLabelNever: string;
  isFromClientInfo: boolean = false;
  applicableCorporates: any = {};

  @ViewChild('dataInput') dataInput: ElementRef;
  @Input() DatainputArray: any;
  @Input() DefaultData: any;
  @Input() Datalimit: any;
  @Input() Disabled: boolean;
  @Input() clearInputOnAddClient: number;
  @Input() placeHolder: any;
  @Input() automationId: any;
  @Output() messageEvent = new EventEmitter<any>();
  @Output() selectedEvent = new EventEmitter<any>();
  @Output() removedEvent = new EventEmitter<any>();
  @Output() removedCompleteSearch = new EventEmitter<any>();
  @Input() inputType: string = 'text';
  @Input() spainputtype: any = '';
  @Input() inputfieldtype: any = '';
  @Input('isFromClientInfo')
  set clientInfo(value) {
    if (value) {
      this.isFromClientInfo = value
    }
  }
  public inputIcons: any = false;
  captions: any = this.localization.captions.bookAppointment;
  allCaptions: any = this.localization.captions;
  floatLabel: string;
  constructor(public _as: AppointmentpopupService, public localization: SpaLocalization, public formatphno: FormatText,
    private utils: SpaUtilities, private http: HttpServiceCall) {
    this._as.isClientCompleted = this._as.isClientCompleted ? this._as.isClientCompleted : false;
    this.floatLabel = this.localization.setFloatLabel;
    this.floatLabelNever = this.localization.setFloatLabelNever;
  }

  @Input('inputType')
  set setinputfieldtype(value: string) {
    if (value == "number") {
      this.inputfieldtype = 'onlynumber,nonnegative,allowpaste';
    }
    else {
      this.inputfieldtype = 'allowpaste';
    }
  }


  @Input('isClearSearchText')
  set clearSearchTextField(value: boolean) {
    // this.isClearSearchText = false;
    // if (value) {
    // this.isClearSearchText = true;
    this.clearSearchText();
    // }
  }

  @Input('deleteSelectedRecords')
  set deleteSelectedText(value: boolean) {
    this.deleteaallRecords();
  }

  clearSearchText() {
    this.searchValue = '';
    if (this.dataInput && this.dataInput.nativeElement) {
      this.dataInput.nativeElement.value = '';
    }
  }

  //** @funtion sendMessage()
  //Event Emiter to ClientInfo Component

  sendMessage(e: any) {
    this.inputIcons = true;
    let inputValue = this.dataInput.nativeElement.value;
    if (inputValue.length >= 3 && !this.isFromClientInfo) {
      this.requestUid = Date.now() + "" + this.utils.getRandomDecimal() * 10000;
      if (this.timer) {
        clearTimeout(this.timer); //cancel the previous timer.
        this.timer = null;
      }
      this.timer = setTimeout(this.emitEvent.bind(this), 1000, this.dataInput.nativeElement.value, this.requestUid);
    } else if (this.isFromClientInfo) {
      this.requestUid = Date.now() + "" + this.utils.getRandomDecimal() * 10000;
      if (this.timer) {
        clearTimeout(this.timer); //cancel the previous timer.
        this.timer = null;
      }
      this.timer = setTimeout(this.emitEvent.bind(this), 1000, this.dataInput.nativeElement.value, this.requestUid);
    } else {
      this._as.clientsearchArray = [];
      this.inputIcons = false;
      this._as.showLoading = false;
      this._as.showNodata = false;

    }
  }

  emitEvent(searchValue, requestUid) {
    this.messageEvent.emit({ searchValue: searchValue, requestUid: requestUid })
  }



  add(event: MatChipInputEvent): void {
    this._as.clientsearchArray = [];
    const input = event.input;
    const value = event.value;
    // Reset the input value
    if (input) {
      input.value = value;
    }

    this.searchCtrl.setValue(null);
  }
  /***
   * @function remove
   * @input Params <Array>
   * OutPut Array
   * splice the Array
   */

  remove(clientId: number): void {
    if (this.Disabled) {
      return;
    }
    this._as.isClientCompleted = false;

    this._as.clientsearchArray = [];
    if (this._as.labelRecords.length == 1) {
      this.inputIcons = false;
    }
    if (this._as.labelRecords.length == 2) {
      this._as.multipleRecords = false;
    }
    if (clientId != 0) {
      this._as.labelRecords = this._as.labelRecords.filter(r => r.Id != clientId);
      this._as.recordsArray = this._as.recordsArray.filter(r => r.clientDetail.id != clientId);
      this._as.multiClientInfo = this._as.multiClientInfo.filter(r => r.id != clientId);
      this._as.isAptPriceReadOnly = this._as.isPriceTypeApplicable();
    }
    if (this._as.labelRecords.length > 0) {
      this._as.isClientCompleted = true;
    }
    this.removedEvent.emit(clientId);
    if (this._as.labelRecords.length > 0)
      this._as.currentClient = this.getClientDetails(this._as.labelRecords[0].Id);
  }

  async getClientDetails(clientId) {
    let clientDetail = await this.http.CallApiAsync<any>({
      host: GlobalConst.Host.spaManagement,
      callDesc: "getClientInfo",
      method: HttpMethod.Get,
      showError: true,
      uriParams: { id: clientId }
    });
    return clientDetail.result;
  }


  /***
   * @function deleteaallRecords
   * @input Params <Array>
   * OutPut Array
   * Delete all Records form the Array
   */

  deleteaallRecords() {
    if (this.dataInput) {
      this.dataInput.nativeElement.value = '';
    }
    this._as.clientsearchArray = [];
    this.inputIcons = false;
    this._as.labelRecords = [];
    this._as.recordsArray = [];
    this._as.multiClientInfo = [];
    this.removedEvent.emit();
    this.removedCompleteSearch.emit();
  }

  /***
 * @function selected
 * @input Params <Array>
 * OutPut Array
 * Selected the Particular object form the Listing Array
 */
  async selected(event: MatAutocompleteSelectedEvent) {
    let unassignedTherapistId = 0;   // included to make a validation during create new appointment
    this._as.isClientCompleted = false;
    let clientDetails = [];

    clientDetails[0] = event.option.value;

    let clientInfo = clientDetails;

    let scheduleDt = new Date();
    let memberInfo = (clientDetails && clientDetails.length > 0 && clientDetails[0].clientDetail.clientLinkId != null && clientDetails[0].clientDetail.clientLinkId != '') ? await this._as.getMemberInfo(clientDetails[0].clientDetail.clientLinkId, scheduleDt.toISOString(), clientDetails[0].clientDetail.corpId) : null;
    if (memberInfo != null && (memberInfo.membershipStatus?.toLowerCase() != 'active' || memberInfo.cardStatus?.toLowerCase() != 'active')) {
      this.selectedEvent.emit(null);
    } else {
      if (clientDetails[0].clientDetail.id == 0) {
        let clientDetail = await this._as.CreateClientForGuest(clientDetails[0], memberInfo);
        if (this._as.clientTypes[1].checked == true) {
          clientDetail.clientDetail.confirmationNumber = clientInfo[0].clientDetail.confirmationNumber;
          if (clientInfo[0].clientDetail.isExternalGuest) {
            clientDetail.clientDetail.isExternalGuest = true;
            clientDetail.clientDetail.confirmationNumber = clientInfo[0].clientDetail.confirmationNumber;
          }
          else {
            clientDetail.clientDetail.pmsActivityId = clientInfo[0].clientDetail.pmsActivityId;
          }
        }
        if (clientDetail)
          clientDetails = [clientDetail];
      }
      else if (clientDetails[0].clientDetail.id != 0 && clientDetails[0].clientDetail.clientType == ClientType.Member) {
        if (memberInfo) {
          clientDetails[0].clientDetail.membershipExpiryDate = memberInfo.membershipExpiryDate;
          clientDetails[0].clientDetail.membershipRenewalDate = memberInfo.membershipRenewalDate;
          clientDetails[0].clientDetail.membershipStatus = memberInfo.membershipStatus;
          clientDetails[0].clientDetail.membershipType = memberInfo.membershipType;
          let creditBookBalance = memberInfo.balanceList.find(t => t.BucketName.toLowerCase() == this.CreditBook.toLowerCase());
          clientDetails[0].clientDetail.creditBookBalance = creditBookBalance != null ? creditBookBalance.AccrualCash : 0;
        }
      }
      var clientData: ClientLabel = {
        Id: clientDetails[0].clientDetail.id,
        FirstName: clientDetails[0].clientDetail.firstName,
        LastName: clientDetails[0].clientDetail.lastName,
        clientType: clientDetails[0].clientDetail.clientType
      };
      this._as.labelRecords.push(clientData);
      this.dataInput.nativeElement.value = "";
      this.searchCtrl.setValue(null);
      this.searchRecords(clientDetails[0].clientDetail.firstName + ' ' + clientDetails[0].clientDetail.firstName);
      this._as.getRecentAppointments(clientDetails[0].clientDetail.id);
      this._as.recordsArray.push(clientDetails[0]);

      let priceTypeId = clientDetails[0].clientDetail.priceTypeId;
      if (this._as.clientInfoPriceType && memberInfo && memberInfo.priceType) {
        let selectedPriceTypeObject = this._as.clientInfoPriceType.find(x => x.code.toLowerCase() == memberInfo.priceType.toLowerCase())
        if (selectedPriceTypeObject) {
          priceTypeId = selectedPriceTypeObject.priceTypeId;
        }

      }
      const selectedPriceType = this._as.selectedServicePriceTypes.find(x => x.priceTypeId === priceTypeId)
      const selectedDiscountType = memberInfo ? memberInfo.discountType : "";
      if (clientDetails[0].clientDetail.vip) {
        this._as.clientVipType = this._as.GetVipTypeName(clientDetails[0].clientDetail.vip);
      }
      this._as.multiClientInfo.push({ "id": clientDetails[0].clientDetail.id, "comments": "", "TherapistId": unassignedTherapistId, "priceTypeId": priceTypeId, "price": selectedPriceType ? selectedPriceType.price : 0, "discountType": selectedDiscountType ? selectedDiscountType : '' });
      this._as.isPriceTypeApplicable();
      this._as.activeBorderId = this._as.recordsArray.length > 1 ? this._as.recordsArray.length - 1 : 0;
      if (this._as.labelRecords.length > 1) {
        this._as.multipleRecords = true;
        this._as.showDetails = false;
      } else {
        this._as.multipleRecords = false;
        this._as.showDetails = true;
        this._as.clientId = clientDetails[0].clientDetail.id;
        this._as.guestId = clientDetails[0].clientDetail.guestId;
      }
      this._as.clientsearchArray = [];
      if (this._as.labelRecords.length > 0) {
        this._as.isClientCompleted = true;
      }
      this.selectedEvent.emit(clientDetails);
    }
  }

  expand() {
    this.expandBool = true;
  }

  searchRecords(e) {
    this._as.packageDeepdata.forEach(element => {
      if (element.name.toLowerCase() == e.toLowerCase()) {
        this._as.depositArray.push(element)
      }
    });
  }

  clearInput() {
    if (this.dataInput.nativeElement.value.length <= 2) {
      this._as.clientsearchArray = [];
      this._as.showLoading = false;
      this._as.showNodata = false;
    }
  }

  ngOnInit() {
    this.initialValue = this.clearInputOnAddClient;
    if (!this.placeHolder) {
      this.placeHolder = this.allCaptions.common.search;
    }
    this.applicableCorporates = this._as.getMemberConfiguration();
  }

  ngOnChanges() {
    if (this.initialValue != this.clearInputOnAddClient) {
      this.dataInput.nativeElement.value = '';
      this.initialValue = this.clearInputOnAddClient;
    }
  }

  GetClientPhoneNumber(clientInfo) {
    if (clientInfo && clientInfo.phoneNumbers && clientInfo.phoneNumbers.length) {
      let notPrivate = clientInfo.phoneNumbers.filter(x => !x.isPrivate);
      if (notPrivate && notPrivate.length) {
        let primary = notPrivate.find((x: { isPrimary: any; }) => x.isPrimary);
        if (primary == null || primary == undefined) {
          primary = notPrivate[0];
        }
        return this.getFormattedPhNo(primary);
      }
    }
    return '';
  }

  GetClientEmail(clientInfo) {
    if (clientInfo && clientInfo.emails && clientInfo.emails.length) {
      let notPrivate = clientInfo.emails.filter(x => !x.isPrivate);
      if (notPrivate && notPrivate.length) {
        let primary = notPrivate.find(x => x.isPrimary);
        if (primary && primary.emailId) {
          return primary.emailId;
        }
        return notPrivate[0].emailId;
      }
    }
    return '';
  }

  getFormattedPhNo(PhoneNo: any): any {
    //ADDING FOR EXTENSION FIELD
    let _number: any,
      number = PhoneNo.number,
      countryCode = [];
    countryCode = number.split('|');
    if (countryCode != undefined && countryCode[0] != undefined && countryCode[0] != '' && countryCode[1] != undefined) {
      let extension = [];
      PhoneNo.countryCode = countryCode[0];
      number = countryCode[1];
      extension = countryCode[0].split(':');
      if (extension != undefined && extension[0] != undefined && extension[0] != '' && extension[1] != undefined) {
        countryCode[0] = extension[1];
        number = extension[0] + ":" + number;
      }
    }
    if (number != undefined && number != '') {
      if (PhoneNo.contactTypeId == '3') {
        let PhnoExt = [];
        PhnoExt = number.split(':');
        if (PhnoExt.length > 1) {
          PhoneNo.extension = PhnoExt[0];
          number = PhnoExt[1];
        }
      }
      _number = PhoneNo.extension ? this.formatphno.transform(`${PhoneNo.extension}:${number}`, this.localization.captions.common.ExtensionFormat) : this.formatphno.transform(number, this.localization.captions.common.PhoneFormat);
      if (countryCode != undefined && countryCode[0] != undefined && countryCode[0] != '' && countryCode[1] != undefined) {
        _number = countryCode[0] + " - " + _number;
      }
    }
    return _number;
  }
  GetStayStartDate(clientInfo) {
    let StartDate = this.localization.ConvertDateToISODate(clientInfo.clientDetail.stayStartDate);
    return StartDate;
  }

  GetStayEndDate(clientInfo) {
    let EndDate = this.localization.ConvertDateToISODate(clientInfo.clientDetail.stayEndDate);
    return EndDate;
  }


  getToolTip(clientInfo) {
    const firstName = clientInfo.clientDetail.firstName
    const lastName = clientInfo.clientDetail.lastName;
    const phNo = this.GetClientPhoneNumber(clientInfo) ? `| ${this.GetClientPhoneNumber(clientInfo)}` : ''
    const email = this.GetClientEmail(clientInfo) ? `| ${this.GetClientEmail(clientInfo)}` : '';
    const confirmationNo = clientInfo.clientDetail.confirmationNumber ? `| ${this.localization.captions.confirmationNumber} : ${clientInfo.clientDetail.confirmationNumber}` : '';
    const loyalty = clientInfo.clientDetail.loyaltyDetail[0] ? `| ${this.localization.captions.bookAppointment.PatronID} : ${clientInfo.clientDetail.loyaltyDetail[0].patronId} ` : '';
    const stayStartDate = this.GetStayStartDate(clientInfo) ? `| ${this.allCaptions.stayStartDate} : ${this.GetStayStartDate(clientInfo)}` : '';
    const stayEndDate = this.GetStayEndDate(clientInfo) ? `- ${this.allCaptions.stayEndDate} : ${this.GetStayEndDate(clientInfo)}` : '';
    const corpName = (this._as.selectedClientType == 2 && clientInfo.clientDetail.corpId && this.applicableCorporates && this.applicableCorporates[clientInfo.clientDetail.corpId]) ? `| ${this.applicableCorporates[clientInfo.clientDetail.corpId]}` : '';
    return `${firstName} ${lastName} ${confirmationNo} ${phNo} ${email} ${loyalty} ${stayStartDate} ${stayEndDate} ${corpName}`
  }
}

