import { Component, OnInit } from "@angular/core";
import { MatDialogRef } from "@angular/material/dialog";
import { WebcamImage, WebcamInitError, WebcamUtil } from "ngx-webcam";
import { Observable, Subject } from "rxjs";
import { CommonUtilities } from "../../shared/shared/utilities/common-utilities";
import { Localization } from '../../localization/localization';

@Component({
  selector: "app-capture-image",
  templateUrl: "./capture-image.component.html",
  styleUrls: ["./capture-image.component.scss"],
})
export class CaptureImageComponent implements OnInit {
  public allowCameraSwitch = true;
  public multipleWebcamsAvailable = false;
  private trigger: Subject<void> = new Subject<void>();
  private nextWebcam: Subject<boolean|string> = new Subject<boolean|string>();
  captions: any;
  uploadButton: { type: string; label: any; disabledproperty: boolean };
  cancelButton: { type: string; label: any };

  constructor(
    private utils: CommonUtilities,
    private localization: Localization,
    private dialogRef: MatDialogRef<CaptureImageComponent>
  ) {
    this.captions = this.localization.captions;
  }
  ngOnInit(): void {
    this.uploadButton = {
      type: "primary",
      label: this.captions.lbl_captureImage,
      disabledproperty: true,
    };
    this.cancelButton = {
      type: "tertiary",
      label: this.captions.btn_cancel,
    };
    this.checkforVideoAccess();
  }

  checkforVideoAccess() {
    WebcamUtil.getAvailableVideoInputs().then(
      (mediaDevices: MediaDeviceInfo[]) => {
        if (mediaDevices && mediaDevices.length >= 1) {
          this.uploadButton.disabledproperty = false;
          this.multipleWebcamsAvailable = mediaDevices && mediaDevices.length > 1;
        }
      }
    );
  }

  public triggerSnapshot() {
    this.trigger.next();
  }

  public handleImage(webcamImage: WebcamImage) {
    this.dialogRef.close({
      data: webcamImage.imageAsDataUrl,
    });
  }

  public showNextWebcam(directionOrDeviceId: boolean|string): void {
    this.nextWebcam.next(directionOrDeviceId);
  }

  public cameraWasSwitched(deviceId: string): void {
    console.log('active device: ' + deviceId);
  }
  
  public get triggerObservable(): Observable<void> {
    return this.trigger.asObservable();
  }

  public get nextWebcamObservable(): Observable<boolean|string> {
    return this.nextWebcam.asObservable();
  }

  public handleInitError(error: WebcamInitError) {
    if (error.mediaStreamError && error.mediaStreamError.name === "NotAllowedError") {
      this.uploadButton.disabledproperty = true;
      console.warn("Camera access was not allowed by user!", error.mediaStreamError, error.message);
    } else {
      console.warn(error.mediaStreamError, error.message);
    }
    this.utils.showError(error.message);
    this.dialogRef.close();
  }

  onCancel() {
    this.dialogRef.close();
  }

  ngOnDestroy() {}
}
