import { BrowserModule } from '@angular/platform-browser';
import { NgModule, Injector, ErrorHandler, Inject, APP_INITIALIZER } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppComponent } from './app.component';
import { CoreModule } from './core/core.module';
import { FormsModule } from '@angular/forms';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { SpaLocalization } from './core/localization/spa-localization';
import { SPAConfig } from './core/config/SPA-config';
import { UserAlerts } from './core/config/alerts-config';
import { OAuthModule } from 'angular-oauth2-oidc';
import { AuthGuardService } from './core/services/auth-guard.service';
import { SpaPropertyInformation } from './core/services/spa-property-information.service';
import { IdleTimeoutService } from './shared/service/session-expired.service';
import { AppModuleService } from './app.service';
import { AuditRouterGuard } from './core/services/Route-Guards/audit-route.guard.service';
import { BarcodePrintService } from './retail/retail-print/barcode-print.service';
import { ZebraPrintService } from './retail/retail-print/zebra-print.service';
import { SPAAppService } from './spa-app-service';
import { AppService } from './common/app-service';
import { SpaUtilities } from './shared/utilities/spa-utilities';
import { Localization as CommonLocalization } from 'src/app/common/localization/localization';
import { CommonUtilities } from './common/shared/shared/utilities/common-utilities';
import { CommonPropertyInformation } from './common/shared/services/common-property-information.service';
import { ServiceLocator } from './common/service.locator';
import { RouteReuseStrategy, Router } from '@angular/router';
import { ApmErrorHandler, ApmService } from '@elastic/apm-rum-angular'
import * as GlobalConst from './shared/globalsContant';
import { GlobalErrorHandler } from './shared/service/global-error-handler.service';
import { ADB2CAuthConfiguration } from './login/auth.config';
import { MatTooltipDefaultOptions, MAT_TOOLTIP_DEFAULT_OPTIONS } from '@angular/material/tooltip';
import { StoreModule } from '@ngrx/store';
import { appReducers } from './eatecui/source/store/reducers/app.reducer';
import { clearState } from './eatecui/source/store/reducers/login.reducer';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { TranslateLoader, TranslateModule, TranslateService } from '@ngx-translate/core';
import { ToastrModule } from 'ngx-toastr';
import { EnvService } from './eatecui/source/config.service';
import { environment } from 'src/environments/environment';
import { CustomReuseStrategy } from '@shared/services/reuse-strategy';
import { SessionLoaderService } from './common/services/sessionloader.service';
import { GoogleMapsWrapperModule } from './common/services/googlemapswrapper.module';

export function AppServiceFactory(utilities: SpaUtilities, localizaton: SpaLocalization, spaconfig: SPAConfig) {
  return new SPAAppService(utilities, localizaton, spaconfig);
}
export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, 'app/eatecui/assets/i18n/', '.json');
}
export function appInitializerFactory(translate: TranslateService) {
  return () => {
    translate.setDefaultLang('en');
    return translate.use('en').toPromise();
  };
}

export function initializeApp(sessionService: SessionLoaderService) {
  return () => sessionService.initializeSession();
}
export const OtherOptions: MatTooltipDefaultOptions = {
  showDelay: 0,
  hideDelay: 0,
  touchendHideDelay: 0,
  disableTooltipInteractivity: true,
}

@NgModule({
    declarations: [
        AppComponent,
    ],
    imports: [
        BrowserModule,
        CoreModule,
        BrowserAnimationsModule,
        FormsModule,
        HttpClientModule,
        TooltipModule.forRoot(),
        OAuthModule.forRoot(),
        StoreModule.forRoot(appReducers, { metaReducers: [clearState] }),
        TranslateModule.forRoot({
          loader: {
            provide: TranslateLoader,
            useFactory: createTranslateLoader,
            deps: [HttpClient]
          }
        }),
        ToastrModule.forRoot(),
        GoogleMapsWrapperModule
    ],
    exports: [],
    providers: [
        {
            provide: AppService,
            useFactory: AppServiceFactory,
            deps: [SpaUtilities, SpaLocalization, SPAConfig]
        },
        {
            provide: ApmService,
            useClass: ApmService,
            deps: [Router]
        },
        {
          provide: APP_INITIALIZER,
          useFactory: (envService: EnvService) => () => envService.init(environment['EatecUi']),
          multi: true,
          deps: [EnvService]
        },
        {
          provide: APP_INITIALIZER,
          useFactory: appInitializerFactory,
          deps: [TranslateService, Injector],
          multi: true
        },
        {
          provide: APP_INITIALIZER,
          useFactory: initializeApp,
          deps: [SessionLoaderService],
          multi: true,
        },
        { provide: ErrorHandler, useClass: GlobalErrorHandler },
        {provide: MAT_TOOLTIP_DEFAULT_OPTIONS, useValue: OtherOptions},
        {
          provide: RouteReuseStrategy,
          useClass: CustomReuseStrategy
        },
        // {
        //   provide: ErrorHandler,
        //   useClass: ApmErrorHandler
        // },
        { provide: CommonUtilities, useClass: SpaUtilities },
        SpaLocalization, SPAConfig, UserAlerts, AuthGuardService, AuditRouterGuard, SpaPropertyInformation,
        IdleTimeoutService, AppModuleService, BarcodePrintService, ZebraPrintService,
        { provide: CommonLocalization, useClass: SpaLocalization },
        { provide: CommonPropertyInformation, useClass: SpaPropertyInformation },
        ADB2CAuthConfiguration
    ],
    bootstrap: [AppComponent]
})
export class AppModule { 

  constructor(private injector: Injector, private localization: CommonLocalization, @Inject(ApmService) service: ApmService, translate: TranslateService, sessionLoaderService: SessionLoaderService) {
    translate.setDefaultLang('en');
    translate.use('en');
    ServiceLocator.injector = this.injector;  
    if(sessionStorage.length > 0)
    {   
      let propertyConfig: string = sessionStorage.getItem('propConfig');
      if (propertyConfig && JSON.parse(propertyConfig)) 
      {
        const config = JSON.parse(propertyConfig);
        if(config.UseApmServer != undefined && config.UseApmServer == 'true' && config.ApmServerUrl != undefined && config.DistributedTracingOrigins != undefined)
        {
          const apm = service.init({
            serviceName: GlobalConst.ElasticApm.ServiceName,
            serverUrl: config.ApmServerUrl,
            distributedTracingOrigins: config.DistributedTracingOrigins.split(',')
          })

          const userName = localization.GetUserInfo('userName');
          const propertyId = localization.GetPropertyInfo('PropertyId');
          if(userName != null){
            apm.setUserContext({
              'username': userName,
              'id' : propertyId //PropertyId is set in user Id field of Kibana for Filtering
            });
          }
        }
      }
    }
  }
}
