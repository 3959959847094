import { Component, OnInit, ViewEncapsulation, OnDestroy } from '@angular/core';
import { SettingsService } from '../../settings.service';
import { MatDialog } from '@angular/material/dialog';
import { SpaLocalization } from '../../../core/localization/spa-localization';
import { HttpServiceCall, HttpMethod } from '../../../shared/service/http-call.service';
import { AlertMessagePopupComponent } from '../../../shared/alert-message-popup/alert-message-popup.component';
import { BreakPointAccess } from '../../../shared/service/breakpoint.service';
import * as GlobalConst from '../../../shared/globalsContant';
import { SpaUtilities } from '../../../shared/utilities/spa-utilities';
import { SubscriptionLike as ISubscription } from 'rxjs';
import { BaseResponse } from '../../../shared/business/shared.modals';
import { SettingDialogPopupComponent } from '../../setting-dialog-popup/setting-dialog-popup.component';
import { SpaPropertyInformation } from "../../../core/services/spa-property-information.service";
import {CommonUtilities } from '../../../common/shared/shared/utilities/common-utilities';

@Component({
  selector: 'app-user-role',
  templateUrl: './user-role.component.html',
  styleUrls: ['./user-role.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class UserRoleComponent implements OnInit, OnDestroy {
  arrayData: any;
  selectedOption: any;
  userRoleConfiguration: any = [];
  captions: any = this.localization.captions.userConfig;
  availableOptions: any = [{ "id": 1, "name": "System Administrator" }]
  hasAccess: boolean = true;
  IsReadOnly: boolean;
  dialogSubscription: ISubscription;
  floatLabel:string;
  triggerExpandCollapse: boolean = false;


  constructor(public _settingService: SettingsService, private http: HttpServiceCall, private dialog: MatDialog, public utilities: CommonUtilities,
    private localization: SpaLocalization, private BPoint: BreakPointAccess, private utils: SpaUtilities, private PropInfo: SpaPropertyInformation, private ss: SettingsService) {
      this.floatLabel = this.localization.setFloatLabel;
  }

  ngOnInit() {
    this.utilities.ToggleLoaderWithMessage(true,this.captions.lbl_processing);
    if (!this.BPoint.CheckForAccess([GlobalConst.SPAScheduleBreakPoint.UserRoleConfiguration])) {
      this.hasAccess = false;
      return;
    }
    this.IsReadOnly = this.BPoint.GetBreakPoint([GlobalConst.SPAScheduleBreakPoint.UserRoleConfiguration]).result[0].view;
    if (!this.IsReadOnly) {
      this.hasAccess = true;
    }
    this.getUserRoles();
    this.ss.tabLoaderEnable.next(false);
  }

  async getArrayData() {
    this._settingService.changedBreakPoints = [];
    let result: any;
    result = [{
      headerData: {
        titleData: this.localization.captions.userConfig.SecurityCategories,//"Security Categories",
        titledesc: this.localization.captions.userConfig.Selected,//"Selected ",
        details: this.userRoleConfiguration
      }
    }];
    this.arrayData = result ? result : [];
    this.sortUserClaims();
    this._settingService.roleConfiguration = this.arrayData;
    this.utilities.ToggleLoaderWithMessage(false);
  }

  sortUserClaims()
  {
    if(this.arrayData && this.arrayData.length) {
      this.arrayData.forEach((d) => {
        if(d.headerData && d.headerData.details && d.headerData.details.length) {
          d.headerData.details.forEach((b) =>  {
            b.userClaims = b.userClaims && b.userClaims.length ?  b.userClaims.sort((a,b) => {return a.breakPointNumber - b.breakPointNumber}) : b.userClaims;
          });
        }
      });
    }
  }
  
  openDialog() {
    let dialogRef = this.dialog.open(SettingDialogPopupComponent, {
      width: '650px',
      height: '230px',
      data: { headername: this.captions.copyRoles, closebool: true, templatename: "Fdf", datarecord: "", popupConfig: "" },
      panelClass: 'small-popup',
      disableClose: true,
      hasBackdrop: true
    });
    this.dialogSubscription = dialogRef.afterClosed().subscribe(s => { this.getuserConfig(this.selectedOption); });
  }

  ngOnDestroy() {
    if (this.dialogSubscription) {
      this.dialogSubscription.unsubscribe();
    }
  }

  Save() {
    this.http.CallApiWithCallback<any>({
      host: GlobalConst.Host.authentication,
      success: this.successCallback.bind(this),
      error: this.utils.errorCallback.bind(this),
      callDesc: "UpdateUserRoles",
      method: HttpMethod.Put,
      body: this._settingService.changedBreakPoints,
      showError: true,
      extraParams: [false]
    });
  }

  getUserRoles() {
    this.http.CallApiWithCallback<any>({
      host: GlobalConst.Host.authentication,
      success: this.successCallback.bind(this),
      error: this.utils.errorCallback.bind(this),
      callDesc: "GetActiveUserRolesByPropertyId",
      method: HttpMethod.Get,
      uriParams: { propertyId: Number(this.utils.GetPropertyInfo('PropertyId')), includeInActive: false },
      showError: true,
      extraParams: [false]
    });
  }

  getuserConfig(roleId: number): any {
    this.http.CallApiWithCallback<any>({
      host: GlobalConst.Host.authentication,
      success: this.successCallback.bind(this),
      error: this.utils.errorCallback.bind(this),
      callDesc: "GetUserRoleConfiguration",
      method: HttpMethod.Get,
      uriParams: { userRoleId: roleId },
      showError: true,
      extraParams: [false]
    });
  }

  LoadUserConfig() {
    if (!this.hasAccess) {
      return;
    }
    this.getuserConfig(this.selectedOption);
  }

  successCallback<T>(result: BaseResponse<T>, callDesc: string, extraParams: any[]): void {
    if (callDesc == "GetActiveUserRolesByPropertyId") {
      this.availableOptions = result.result ? result.result : [];
      this.availableOptions = this.availableOptions.filter(x => x.productId.includes(Number(this.utils.GetPropertyInfo('ProductId'))));
      this.selectedOption = this.availableOptions[0].id;
      this.getuserConfig(this.selectedOption);
    }
    else if (callDesc == "GetUserRoleConfiguration") {
      this.userRoleConfiguration = result.result ? result.result : [];
      if (this.userRoleConfiguration != null) {
        this.userRoleConfiguration = this.userRoleConfiguration.filter(r => [GlobalConst.Product.SPA, GlobalConst.Product.RETAIL, GlobalConst.Product.COMMON].includes(r.productId));
        if (this.PropInfo.UseRetailInterface) {
          this.RemoveBreakPoints();
        }
      }
      this.getArrayData();
    }
    else if (callDesc == "UpdateUserRoles") {
      this._settingService.roleConfiguration = [];
      const dialogRef = this.dialog.open(AlertMessagePopupComponent, {
        width: '305px',
        height: '300px',
        hasBackdrop: true,
        panelClass: 'small-popup',
        data: { headername: this.captions.wellDone, headerIcon: 'icon-success-icon', headerMessage: this.captions.configSaveSuccessFrom, buttonName: this.captions.okay, type: 'message' },
        disableClose: true
      });
      dialogRef.afterClosed().subscribe(res => {
        this.getuserConfig(this.selectedOption);
        this.triggerExpandCollapse = false;
      });
    }
  }

  RemoveBreakPoints() {
    this.userRoleConfiguration.forEach((claims, index) => {
      if (this.userRoleConfiguration[index].userClaims.length > 0)
        this.userRoleConfiguration[index].userClaims = this.userRoleConfiguration[index].userClaims.filter(claimsData => {
          return claimsData.breakPointNumber != GlobalConst.RetailBreakPoint.Taxconfiguration &&
            claimsData.breakPointNumber != GlobalConst.RetailBreakPoint.DiscountConfiguration &&
            claimsData.breakPointNumber != GlobalConst.RetailBreakPoint.DiscountType &&
            claimsData.breakPointNumber != GlobalConst.RetailBreakPoint.PaymentMethods
        });
    });
  }
  expandCollapse(e){
    this.triggerExpandCollapse = !this.triggerExpandCollapse; 
  }

  changedData(e) {
    if(e) {
      let isAllExpanded = e.every(item => item.isOpened === true);
      let isAllCollapsed = e.every(item => item.isOpened === false || item.isOpened === undefined);
      if(isAllExpanded) {
        this.triggerExpandCollapse = true;
      } else if (isAllCollapsed) {
        this.triggerExpandCollapse = false;
      } else {
        return;
      }
    }
  }
}
