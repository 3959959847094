import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ReplaySubject, of } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { Localization } from 'src/app/common/localization/localization';
import { AgInputFieldConfig, ButtonValue } from 'src/app/common/Models/ag-models';
import { CommonUtilities } from '../../shared/shared/utilities/common-utilities';
import { FacadeService } from 'src/app/common/services/facade.service';
import { UserAccessService } from 'src/app/common/services/user-access.service';
import { UserAccessBreakPoints } from 'src/app/shared/enums/useraccess.constants';
import { DmConfig, Product } from 'src/app/common/Models/common.models';
import { LetterType, UI } from './data-magine-config.model';
import { AlertAction, ButtonType } from 'src/app/common/enums/shared-enums';
import { AlertType } from 'src/app/common/shared/shared/enums/enums';
import { DatamagineConfigBusiness } from './data-magine-config.business';
import { MatDialog } from '@angular/material/dialog';
import { DmAutomailComponent } from '../dm-automail/dm-automail.component';
import { BaseResponse } from '../data-magine-integration/data-magine-models';
import { SystemConfig } from '../../shared/retail.modals';
import { CommonDataAwaiters } from '../../shared/events/awaiters/common.data.awaiters';
import { EformSignCaptureConfigComponent } from '../eform-sign-capture-config/eform-sign-capture-config.component';
import { SearchEformTemplateComponent } from '../search-eform-template/search-eform-template.component';
import { AgDropdownConfig } from 'src/app/common/Models/ag-models';
import { Router } from "@angular/router";

@Component({
  selector: 'app-data-magine-config',
  templateUrl: './data-magine-config.component.html',
  styleUrls: ['./data-magine-config.component.scss'],
  providers: [DatamagineConfigBusiness, FacadeService, UserAccessService],
  encapsulation: ViewEncapsulation.None
})
export class DataMagineConfigComponent implements OnInit {
  disableAllFields: boolean = false;
  disableDMLinkFields: boolean = false;
  disableIdScannerFields: boolean = false;
  disableEforms: boolean = false;
  disableSignatureCaptureFields: boolean = false;
  showPreview: boolean = false;
  SNCProductId: number = Product.SNC;
  captions: any;
  isViewOnly: boolean;
  form: UntypedFormGroup;
  viewTypeInput: AgDropdownConfig;
  connection: UntypedFormGroup;
  destroyed$: ReplaySubject<boolean> = new ReplaySubject(1);
  saveButton: ButtonValue;
  cancelButton: ButtonValue;
  processButton: ButtonValue;
  configureButton: ButtonValue;
  eformSigCap: ButtonValue;
  DataMagineConfigData: UI.DmConfig;
  propertyIDInputConfig: AgInputFieldConfig;
  tenantIDInputConfig: AgInputFieldConfig;
  floatLabel: string;
  isShowEforms: boolean = true;
  isOnlyForPMS: boolean = false;
  propertyIntakeForm: boolean = false;
  DataMagineLogin : any;
  isDMOverride: boolean = false;
  isFromSnc: boolean = false;
  viewTypes;
  enableAccountView: boolean = true;
  enablebookingView: boolean = true;
  enableproformaView: boolean = true;
  enablebeoView: boolean = true;
  enablebeoCheckView: boolean = true;
  maxLengthViews: number = 120;
  passWordPlaceHolder: string;
  passwordRequiredError: string;
  userNamePlaceHolder: string;
  configExpiryDate : boolean ;
  captionsForConfigExpiryDate: string;
  constructor(private localization: Localization,
    private dialog: MatDialog,
    private fb: UntypedFormBuilder, private utils: CommonUtilities,
    private business: DatamagineConfigBusiness,
    public router: Router,
    private facadeService: FacadeService) {
    this.captions = this.localization.captions;
    this.floatLabel = this.localization.setFloatLabel;

  }

  ngOnInit(): void {
    let productId = Number(this.utils.GetPropertyInfo('ProductId'));
    this.isFromSnc = productId == Product.SNC;
    this.userNamePlaceHolder = this.isFromSnc ? this.captions.lbl_clientId : this.captions.lbl_userName;
    this.passWordPlaceHolder = this.isFromSnc ? this.captions.lbl_clientSecret : this.captions.lbl_password;
    this.passwordRequiredError = this.isFromSnc ? this.captions.lbl_err_clientSecret : this.captions.lbl_err_password;
    if (this.isFromSnc) {
      this.disableDMLinkFields = false;
      this.viewTypes = this.business.GetViewForSNC();
    }
    if(productId == Product.GOLF){
      this.captionsForConfigExpiryDate = this.captions.lbl_ConfigureExpiryDate
    }
    if(productId == Product.SPA){
      this.captionsForConfigExpiryDate = this.captions.setting.EFormExpiry;
    }
    if (productId == Product.PMS){
      this.isOnlyForPMS =  true;
    }
    else{
      this.isOnlyForPMS = false;
    }
    if (productId != Product.RETAIL && productId != Product.SNC) {
      this.isShowEforms = true;
    }
    else{
      this.isShowEforms = false;
      //Do not show E-Forms for Retail Stand alone.
    }
    let propConfig = JSON.parse(sessionStorage.getItem("propConfig")); 
    this.DataMagineLogin = propConfig?.DMServerLogin;
    this.validateUserAccess();
    this.formGenerator();
    this.initializeInputs();
    this.setValues();


    this.form.valueChanges.pipe(takeUntil(this.destroyed$)).subscribe(x => {
      this.saveButton.disabledproperty = !(this.form.valid && this.form.dirty);
      this.cancelButton.disabledproperty = !(this.form.valid && this.form.dirty);
      this.saveButton = { ...this.saveButton };
      this.processButton.disabledproperty = !(this.form.controls.serverurl.valid && this.form.controls.username.valid && this.form.controls.password.valid);
      this.processButton = { ...this.processButton };
    });
  }

  formGenerator() {
    this.form = this.fb.group({
      id: '',
      serverurl: ['', Validators.required],
      username: ['', Validators.required],
      password: ['', Validators.required],
      scannerEndPoint: [''],
      signCapEndPoint: [''],
      signature: false,
      enableEforms: false,
      propertyID: '',
      tenantID: '',
      authurl: '',
      eformurl: '',
      eformlisturl: '',
      checkin: '',
      automail: '',
      enablelink: false,
      enableScanner: false,
      enableSignatureCapture: false,
      postCheckReceiptToDM:false,
      viewName:'',
      enableDatamagine: false,
      showPreview: false,
      showDuringCheckIn: false,
      clientId: '',
      clientSecret: '',
      dmEformConfigId: '',
      eFormConfigurations: '',
      supportedLetter: [],
      bookingViewText: '',
      accountViewText: '',
      proformaViewText: '',
      beoViewText: '',
      beoCheckViewText: '',
      accountDocSignExpiaryDate: 1,
      bookingDocSignExpiaryDate: 1,
      proformaDocSignExpiaryDate: 1,
      beoDocSignExpiaryDate: 1,
      beoCheckDocSignExpiaryDate: 1,
      configExpiryDate : false,
    });
  }
  initializeInputs() {
    this.tenantIDInputConfig = {
      className: 'ag_form-control--1 ag_display--inblock float-label-input',
      form: this.form,
      formControlName: 'tenantID',
      placeHolderId: 'lbl_tenant_ID',
      placeHolder: this.captions.lbl_tenant_ID,
      maxlength: 100,
      showRequired: false,
      errorMessageId: 'err_tenantID',
      errorMessage: this.captions.err_missingtenantId,
      changeLabelOnEdit: true,
      inputType: 'noprespace,notallowspace',
      disabled: this.isViewOnly,
      automationId: 'Txt_dataMagineConfig_tenantId'
    };
    this.propertyIDInputConfig = {
      className: 'ag_form-control--1 ag_display--inblock float-label-input',
      form: this.form,
      formControlName: 'propertyID',
      placeHolderId: 'lbl_propertyID',
      placeHolder: this.captions.lbl_propertyID,
      maxlength: 100,
      showRequired: false,
      errorMessageId: 'err_propertyID',
      errorMessage: this.captions.err_propertyID,
      changeLabelOnEdit: true,
      inputType: 'noprespace,notallowspace',
      disabled: this.isViewOnly,
      automationId: 'Txt_dataMagineConfig_propertyId'
    };
    if (this.isFromSnc) {
      this.viewTypeInput = {
        className: 'ag_form-control--sm',
        form: this.form,
        formControlName: 'supportedLetter',
        placeHolderId: 'lbl_viewType',
        placeHolder: this.captions.lbl_viewType,
        selectOptions: of(this.viewTypes),
        showRequired: true,
        isMultiSelect: true,
        dropdownWithSearch: true,
        isAll: true,
        isAllSelected: true,
        automationId: "'Dd_dataMagineConfig_supportedLetter'",
        errorMessage: this.captions.lbl_missingSupportedletter
      };
      this.form.controls.supportedLetter.setValue(this.viewTypes);
    }
    this.saveButton = {
      type: 'primary',
      label: this.captions.btn_save,
      disabledproperty: true
    }

    this.cancelButton = {
      type: 'tertiary',
      label: this.captions.btn_cancel,
    }
    this.eformSigCap = {
      type: 'secondary',
      label: this.captions.btn_eformSigCap,
      disabledproperty: true
    }
    this.processButton = {
      type: 'secondary',
      label: this.captions.lbl_check_connection,
      disabledproperty: true
    }
    this.configureButton = {
      type: 'secondary',
      label: this.captions.btn_configure,
      disabledproperty: true
    }
  }

  async validateUserAccess() {
    const userAccess = await this.facadeService.getUserAccess(UserAccessBreakPoints.DATAMAGINE_CONFIGURATION, true);
    this.isViewOnly = userAccess?.isViewOnly;
    if (this.isViewOnly) {
      this.facadeService.disableControls(this.form);
      this.saveButton.disabledproperty = true;
      this.saveButton = { ...this.saveButton };
      this.processButton.disabledproperty = true;
      this.processButton = { ...this.processButton };
    }
  }
  configExpiryDateToggleChange(e){
    this.configExpiryDate = e;
  }
  patchValues(value: UI.DmConfig) {
    this.form.patchValue({
      id: value.id,
      serverurl: value.serverurl,
      username: value.username,
      password: value.password,
      scannerEndPoint: value.scannerEndPoint,
      signCapEndPoint: value.signCapEndPoint,
      signature: value.signature,
      enableDatamagine: value.enableDatamagine,
      enableEforms: value.enableEforms,
      propertyID: value.propertyID,
      tenantID: value.tenantID,
      authurl: value.authurl,
      eformurl: value.eformurl,
      eformlisturl: value.eformlisturl,
      enablelink: value.enablelink,
      enableScanner: value.enableScanner,
      showPreview: value.showPreview,
      enableSignatureCapture: value.enableSignatureCapture,
      postCheckReceiptToDM:value.postCheckReceiptToDM,
      viewName:value.viewName,
      showDuringCheckIn: value.showDuringCheckIn,
      clientId: value.clientId,
      clientSecret: value.clientSecret,
      dmEformConfigId: value.dmEformConfigId,
      eFormConfigurations: value.eFormConfigurations,
      supportedLetter: this.isFromSnc ? this.viewTypes.filter(o => value.supportedLetter.includes(o.id)) : [],
      bookingViewText: this.isFromSnc ? value.bookingViewText : '',
      accountViewText: this.isFromSnc ? value.accountViewText : '',
      accountDocSignExpiaryDate: this.isFromSnc ? value.accountDocSignExpiaryDate : 1,
      bookingDocSignExpiaryDate: this.isFromSnc ? value.bookingDocSignExpiaryDate : 1,
      proformaDocSignExpiaryDate: this.isFromSnc ? value.proformaDocSignExpiaryDate : 1,
      beoDocSignExpiaryDate: this.isFromSnc ? value.beoDocSignExpiaryDate : 1,
      beoCheckDocSignExpiaryDate: this.isFromSnc ? value.beoCheckDocSignExpiaryDate : 1,
      proformaViewText: this.isFromSnc ? value.proformaViewText : '',
      beoViewText: this.isFromSnc ? value.beoViewText : '',
      beoCheckViewText: this.isFromSnc ? value.beoCheckViewText : '',
      configExpiryDate: value.configExpiryDate,
    });
    if (this.isFromSnc) {
      this.viewTypeInput = { ...this.viewTypeInput };
      this.ViewChange();
    }
  }

  async onSave(e) {
    this.saveButton.disabledproperty = true;
    this.saveButton = { ...this.saveButton };
    if (this.form) {
      const formData = this.form.getRawValue();
      const apiResponse = formData.id > 0 ? await this.business.updateDMConfig(formData) : await this.business.createDMConfig(formData);
      if (apiResponse && (apiResponse.id || this.isFromSnc)) {
        this.patchValues(apiResponse);
        this.utils.showAlert(this.captions.alertPopup.successfullysaved, AlertType.WellDone, ButtonType.Ok);
        this.form.markAsPristine();
        this.saveButton.disabledproperty = true;
        this.saveButton = { ...this.saveButton };
      }
    }
  }

  enableFormFields() {
    this.disableAllFields = !this.form.controls['enableDatamagine'].value && !this.isFromSnc;
    this.disableEforms = !this.form.controls['enableEforms'].value;
    if (this.disableAllFields || this.disableDMLinkFields) {
      this.form.controls['password'].disable();
      this.form.controls['clientSecret'].disable();
      this.processButton.disabledproperty = true;
    } else {
      this.form.controls['password'].enable();
      if (this.form.controls['enableEforms'].value) {
        this.form.controls['clientSecret'].enable();
      }
      else {
        this.form.controls['clientSecret'].disable();
      }
      this.processButton.disabledproperty = !(this.form.controls.serverurl.valid && this.form.controls.username.valid && this.form.controls.password.valid);
    }
    this.tenantIDInputConfig.disabled = this.disableAllFields || this.disableEforms;
    this.propertyIDInputConfig.disabled = this.disableAllFields || this.disableEforms;
    this.tenantIDInputConfig = { ...this.tenantIDInputConfig };
    this.propertyIDInputConfig = { ...this.propertyIDInputConfig };
    this.processButton = { ...this.processButton };
    this.form.updateValueAndValidity();
    this.enableEformSigCap();
  }

  async enableDatamagine(e) {
    if (e.checked) {

      let productId = Number(this.utils.GetPropertyInfo('ProductId'));
      if (productId == Product.SPA) {
        let isIntakePresent = await CommonDataAwaiters.GetIntakeDetails();
        if (isIntakePresent) {
          this.form.controls['enableDatamagine'].setValue(false);
          this.form.controls['enableEforms'].setValue(false);
          this.utils.showAlert(this.captions.warn_ifIntakeExist, AlertType.Warning, ButtonType.Ok, (res) => {
            if (res) {
              this.saveButton.disabledproperty = true;
              this.saveButton = { ...this.saveButton };
              this.cancelButton.disabledproperty = true;
              return;
            }
          });
        }
      }
      this.patchControls({
        enablelink: true,
        enableScanner: true,
        enableSignatureCapture: true,
        showPreview: true
      });
    } else {
      this.patchControls({
        enablelink: false,
        enableScanner: false,
        enableSignatureCapture: false,
        enableEforms: false,
        showPreview: false,
        postCheckReceiptToDM:false
      });
    }
    this.enableFormFields();
    this.form.controls['clientSecret'].disable();
    this.enableEformSigCap();
  }
  patchControls(values) {
    this.form.patchValue(values);
  }
  enableDMLink(e) {
    this.disableDMLinkFields = !e.checked;
    if (e.checked) {
      this.form.controls['password'].enable();
    } else {
      this.form.controls['password'].disable();
    }
  }
  enableIdScanner(e) {
    this.disableIdScannerFields = !e.checked;
  }
  enableEforms(e) {
    this.disableEforms = !e.checked;
    if (this.disableEforms) {
      this.form.controls['clientSecret'].disable();
    } else {
      this.form.controls['clientSecret'].enable();
    }
    this.tenantIDInputConfig.disabled = !e.checked;
    this.tenantIDInputConfig = { ...this.tenantIDInputConfig };
    this.propertyIDInputConfig.disabled = !e.checked;
    this.propertyIDInputConfig = { ...this.propertyIDInputConfig };
    this.enableEformSigCap();
  }
  enableSignatureCapture(e) {
    this.disableSignatureCaptureFields = !e.checked;
    this.enableEformSigCap();
  }

  async onCancel(e) {
    if (this.form && this.form.dirty) {
      this.utils.showAlert(this.captions.warn_datalost, AlertType.Warning, ButtonType.YesNo, async (res) => {
        if (res === AlertAction.YES) {
          this.form.reset();
          await this.setValues();
          this.cancelButton.disabledproperty = true;
          this.saveButton.disabledproperty = true;
          this.saveButton = { ...this.saveButton };
        }
      });
    }
  }

  async setValues() {
    this.utils.ToggleLoaderWithMessage(true,this.captions.lbl_processing);
    this.DataMagineConfigData = await this.business.GetDMConfig();
    if (this.DataMagineConfigData) {
      this.patchValues(this.DataMagineConfigData);
      this.form.markAsPristine();
    }
    if (!this.isFromSnc) {
      this.enableFormFields();
    }
    this.utils.ToggleLoaderWithMessage(false);
  }

  async connectionAlert() {
    this.utils.ToggleLoader(true);
    let data = this.form.getRawValue();

    if(this.DataMagineLogin)
    {
      data.serverurl = data.serverurl + this.DataMagineLogin;
      this.isDMOverride = true;
    }
    this.business.testConnection(data, this.isDMOverride, this.isFromSnc).then(res => {
      if (res && res.StatusCode === 200) {
        this.utils.ToggleLoader(false);
        this.utils.showAlert(this.captions.lbl_success_connection, AlertType.Done, ButtonType.Ok, async (res) => {
        });
      }
      else {
        this.utils.ToggleLoader(false);
        this.utils.showAlert(this.captions.lbl_failure_connection, AlertType.Error, ButtonType.Ok, async (res) => {
        });
      }
    }).catch(er => {
      this.utils.ToggleLoader(false);
      this.utils.showAlert(this.captions.lbl_failure_connection, AlertType.Error, ButtonType.Ok, async (res) => {
      });
    });
  }

  sendAutoMail(eve) {
    if (eve.checked) {
      this.configureButton.disabledproperty = false;
    } else {
      this.configureButton.disabledproperty = true;
    }
    this.configureButton = { ...this.configureButton };
  }

  ViewChange() {
    if (this.form.controls.supportedLetter.value.length > 0) {
      this.enableAccountView = this.form.controls.supportedLetter.value.find(o => o.id == LetterType.Account);
      this.enablebookingView = this.form.controls.supportedLetter.value.find(o => o.id == LetterType.Booking);
      this.enableproformaView = this.form.controls.supportedLetter.value.find(o => o.id == LetterType.Proforma);
      this.enablebeoView = this.form.controls.supportedLetter.value.find(o => o.id == LetterType.Beo);
      this.enablebeoCheckView = this.form.controls.supportedLetter.value.find(o => o.id == LetterType.Check);
      this.setValidation('accountViewText', this.enableAccountView);
      this.setValidation('bookingViewText', this.enablebookingView);
      this.setValidation('proformaViewText', this.enableproformaView);
      this.setValidation('beoViewText', this.enablebeoView);
      this.setValidation('beoCheckViewText', this.enablebeoCheckView);
      this.setValidationForExpiaryDays('accountDocSignExpiaryDate', this.enableAccountView);
      this.setValidationForExpiaryDays('bookingDocSignExpiaryDate', this.enablebookingView);
      this.setValidationForExpiaryDays('proformaDocSignExpiaryDate', this.enableproformaView);
      this.setValidationForExpiaryDays('beoDocSignExpiaryDate', this.enablebeoView);
      this.setValidationForExpiaryDays('beoCheckDocSignExpiaryDate', this.enablebeoCheckView);
    }
  }

  setValidation(controlName: string, isRequired: boolean) {
    if (isRequired) {
      this.form.get(`${controlName}`).setValidators([Validators.required]);
    } else {
      this.form.get(`${controlName}`).clearValidators();
    }
    this.form.get(`${controlName}`).updateValueAndValidity();
  }
  setValidationForExpiaryDays(controlName: string, isValidationNeeded: boolean) {
    if (isValidationNeeded) {
      this.form.get(`${controlName}`).setValidators([Validators.required, Validators.min(1)]);
    } else {
      this.form.get(`${controlName}`).clearValidators();
    }
    this.form.get(`${controlName}`).updateValueAndValidity();
  }

  configure() {
    this.dialog.open(DmAutomailComponent, {
      width: '50%',
      height: '70%',
      data: {

      }
    }).afterClosed().pipe(takeUntil(this.destroyed$)).subscribe(s => {

    });
  }
  enableEformSigCap() {
    if(this.form.controls.enableEforms.value && !this.form.controls.enableSignatureCapture.value && this.form.controls.authurl.value && this.form.controls.clientId.value
       && this.form.controls.clientSecret.value && this.form.controls.eformlisturl.value && this.form.controls.tenantID.value && this.form.controls.propertyID.value)
    {
      this.eformSigCap.disabledproperty = false;
    }
    else {
      this.eformSigCap.disabledproperty = true;
    }
  }
  onEformSigCap() {
    this.dialog.open(EformSignCaptureConfigComponent, {
      width: '40%',
      height: 'auto',
      maxHeight: '86%',
      data: {
        eformConfiguration: this.form.controls['eFormConfigurations'].value,
        dmConfig: this.DataMagineConfigData
      }
    }).afterClosed().pipe(takeUntil(this.destroyed$)).subscribe(result => {
      if(result?.data?.value)
      {
        this.form.controls.eFormConfigurations.markAsDirty();
        this.form.controls.eFormConfigurations.setValue(result.data.value);
      }
    });
  }
}
