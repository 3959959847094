import { AfterViewInit, Component, ElementRef, Input, OnDestroy, OnInit, ViewChild, ViewEncapsulation, Output, EventEmitter } from '@angular/core';
import { Router, RouterOutlet } from '@angular/router';
import { cloneDeep } from 'lodash';
import { ReplaySubject, Subscription } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { menuTypes } from './menu.constant';
import { SortOrderPipe } from '../../pipes/sort-order.pipe';
import { Localization } from '../../localization/localization';
import { LiteralsUpdatesService } from '../../shared/services/literals-updates.service';
import { MatDialog } from '@angular/material/dialog';
import { ManageSessionService } from 'src/app/login/manage-session.service';
import { MsGraphApiCommunication } from 'src/app/common/communication/services/ms-graph.service';
import { PropertyService } from '../../services/property.service';
import { ChangePropertySevice } from '../../services/change-property.service';
import { ChangePropertyComponent } from '../change-property/change-property.component';
import { routingMenuService } from './menu.service';
import { NotificationFailureType } from './menu.model';
import { Product } from '../../Models/common.models';
import { TooltipPosition } from '@angular/material/tooltip';
import { UntypedFormControl } from '@angular/forms';
import { OAuthService } from 'angular-oauth2-oidc';
import { ADB2CAuthConfiguration } from 'src/app/common/shared/auth.config';
import { CartUtilities } from './vcart/cart.utilities';
import { PropertySettingDataService } from 'src/app/common/dataservices/authentication/propertysetting.data.service';
import { JasperServerCommonDataService } from 'src/app/common/dataservices/jasperServerCommon.data.service';
import { PROPERTY_CONFIGURATION_SETTINGS } from 'src/app/app-constants';
import { API } from 'src/app/common/Models/property-settings.model';
import { StorageService } from 'src/app/common/shared/services/session-utilization.service';
import { Jaspersoftconstants } from '../../JaspersoftReport/jasperreport.model';
import { KeyboardMenuNavigationService } from '../../services/keyboard-menu-navigation.service';
import { MatSelect } from '@angular/material/select';
import { PopoverContentComponent } from 'ngx-smart-popover';
import { MultiCurrencyConverterService } from 'src/app/common/services/multi-currency-converter.service';


@Component({
  selector: "ag-menu",
  templateUrl: "./menu.component.html",
  styleUrls: ["./menu.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class MenuComponent implements OnInit, AfterViewInit, OnDestroy {
  positionOptions: TooltipPosition[] = ["right", "above", "left", "below"];
  position = new UntypedFormControl(this.positionOptions[0]);
  menuList: any;
  menuItems: any;
  isMenu: boolean;
  // position: string;
  moreTextName = "";
  moreListItem: any = [];
  isSubMenu = false;
  searchOpen = false;
  menusearchOpen = false;
  headerPopOver: any;
  logOutPopOver: any;
  lowerLevelSubMenu: any;
  verticalFlag = false;
  levelMenu: menuTypes;
  menuType = menuTypes;
  authenticated: boolean;
  gClient: MsGraphApiCommunication;
  prodId: Product;
  destroyed$: ReplaySubject<boolean> = new ReplaySubject(1);
  cartCount: number = 0;
  @ViewChild('logOutPopOver') logOutpopOver!: PopoverContentComponent;
  @ViewChild('helpPopover') helppopOver!: PopoverContentComponent;
  @ViewChild('headerPopover') headerpopOver!: PopoverContentComponent;
  @ViewChild("userPopOver") userPopUp: ElementRef;
  @ViewChild("logOutPopOver") logPopOver: ElementRef;
  @ViewChild("navBar") navBar: ElementRef;
  @ViewChild("RouterOutlet") outlet: RouterOutlet;
  @ViewChild("notificationPopOver") notificationPopOver;
  @ViewChild("cartPopOver") cartPopOver;
  @Output() logoutEvent = new EventEmitter();
  @Output() helpEmit = new EventEmitter();
  @Output() logViewerEmit = new EventEmitter();
  @Output() aboutEmit = new EventEmitter();
  selectedCurrency = '';
  currencies = [];
  isShopcartopen: boolean;
  selectedItem: any;
  userName: string;
  userText: string; // change after login creation
  userRole: string;
  firstName: string;
  lastName: string;
  sortPipe: SortOrderPipe;
  captions: any;
  disableGlobalSearch: true;
  disableMenuSearch: true;
  disableNotification: true;
  disableOutlookConfig: true;
  disableHelpConfig: true;
  showJasperSoftServerMenu: boolean = false;
  isLogo: false;
  logoName;
  msgTxt: string = "";
  openJaspersoftServer: string = "";
  transactionCountSubscription: Subscription;
  keyBoardSubscription: Subscription;
  notificationCount: number = 0;
  notificationInfo: { id: number; message: string }[] = [];
  commonCaptions: any;
  isChangePropertyEnabled: boolean;
  floatLabel: string;
  @Output() mailEmit = new EventEmitter();
  @Input() logViewer: boolean = false;
  checkoutButton: { type: string; label: any; disabledproperty: boolean };
  productId: string;
  isVcartEnabled: boolean;
  activeConfirmationId: any;
  primarguestName: any;
  activatedCart: string;
  logoSrc: string = "./assets/images/logo.png";
  logoVar: string = "logo-section";
  showCurrencyMenu: boolean;
  showFloatingGuestItinerary: boolean = false;

  @Input('menu')
  set MenuValue(value) {
    if (value) {
      this.menuList = value;
      this.levelMenu = value.menuType;
      this.menuList.menu = this.sortPipe.transform(
        this.menuList.menu,
        "order",
        "aesc"
      );
    }
  }

  @Input("menuConfiguration")
  set headerconfiguration(value) {
    this.disableGlobalSearch = value.disableGlobalSearch;
    this.disableMenuSearch = value.disableMenuSearch;
    this.disableNotification = value.disableNotification;
    this.disableOutlookConfig = value?.disableMicrosoftSignIn;
    this.disableHelpConfig = value.disableHelp;
    this.moreTextName = value.moreText;
    this.logoName = value.logoName;
    if (this.prodId == 5 || value?.notificationInfo) {
      this.notificationInfo = value.notificationInfo;
      this.notificationCount =
        this.notificationInfo && this.notificationInfo.length > 0
          ? this.notificationInfo.length
          : 0;
    }
  }
  @ViewChild(MatSelect) matSelect!: MatSelect;
  constructor(
    public router: Router,
    private _localization: Localization,
    public _literalUpdate: LiteralsUpdatesService,
    private propertyService: PropertyService,
    private changePropertySevice: ChangePropertySevice,
    private sessionExpire: ManageSessionService,
    private graphClient: MsGraphApiCommunication,
    private dialog: MatDialog,
    public _routingMenuService: routingMenuService,
    private manageSessionService: ManageSessionService,
    private oauthService: OAuthService,
    private adb2cAuthConfiguration: ADB2CAuthConfiguration,
    private cartUtilities: CartUtilities,
    private PropertySettingService: PropertySettingDataService,
    private jasperServerCommon: JasperServerCommonDataService,
    public storageservice: StorageService,
    private keyBoardService: KeyboardMenuNavigationService,
    private currencyService: MultiCurrencyConverterService,
  ) {
    this.setCurrencyMenu();
    this.floatLabel = this._localization.setFloatLabel;
    this.gClient = graphClient;
    this.sortPipe = new SortOrderPipe();
    _routingMenuService.triggerADB2CLogOut.subscribe(() => {
      if (this.adb2cAuthConfiguration.ADB2CAuthFeatureEnabled) {
        this.oauthService.logOut(); //ADB2C logout
      }
    });
  }
  ngOnInit() {
    this.router.events.pipe(takeUntil(this.destroyed$)).subscribe((x) => {
      this.setCurrencyMenu();
    });
    this.selectedCurrency = sessionStorage.getItem('selectedCurrency') ?? sessionStorage.getItem('defaultCurrency')
    this.keyBoardService.closeSelect$
    .pipe(takeUntil(this.destroyed$))
    .subscribe(
      (x) => ( this.closeMenuModel())
    ); 
    this.keyBoardService.setShownMenu$
    .pipe(takeUntil(this.destroyed$))
    .subscribe(
      (menu) => (this.menuItems = menu)
    ); 
    let propConfig = JSON.parse(sessionStorage.getItem("propConfig"));
    let prodID = this._localization.GetPropertyInfo("ProductId");
    this.handleShowAndHideJasperSoftStudioMenu();
    if (Number(prodID) == Product.ACCOUNTING) {
      let showAddOn = propConfig?.ShowAddOn;
      let ShowCondo = propConfig?.ShowCondo;
      let condoEnabled: boolean = false;
      let addOnEnabled: boolean = false;
      let propName = this._localization.GetsessionStorageValue(
        "propertyInfo",
        "PropertyName"
      )
        ? this._localization.GetsessionStorageValue(
            "propertyInfo",
            "PropertyName"
          ) + " - "
        : "";
      if (
        ShowCondo &&
        ShowCondo != null &&
        ShowCondo.toString().toLowerCase() == "true"
      )
        condoEnabled = true;
      if (
        showAddOn &&
        showAddOn != null &&
        showAddOn.toString().toLowerCase() == "true"
      )
        addOnEnabled = true;

      if (condoEnabled && !addOnEnabled) {
        this.logoSrc = "./assets/images/logo_old2.png";
        this.logoVar = "logo-section residence";
        if (
          prodID &&
          Number(prodID) == Product.ACCOUNTING &&
          document.getElementById("title_id")
        ) {
          //accounting product only
          document.getElementById("title_id").innerText =
            propName + this._localization.captions["app_title_RM"];
        }
      } else {
        this.logoSrc = "./assets/images/logo.png";
        this.logoVar = "logo-section accounting";
        if (
          prodID &&
          Number(prodID) == Product.ACCOUNTING &&
          document.getElementById("title_id")
        ) {
          //accounting product only
          document.getElementById("title_id").innerText =
            propName + this._localization.captions["app_title"];
        }
      }
    }
    this.captions = this._localization.captions;
    this.commonCaptions = this._localization.captions;
    this.msgTxt = this._localization.captions.header.SignInToMicrosoft;
    this.openJaspersoftServer =
      this._localization.captions.common.lbl_OpenJasperSoft;
    this.prodId = Product.PMS;
    this.isAuthenticated();
    this.gClient.currentUser
      .pipe(takeUntil(this.destroyed$))
      .subscribe(
        (userName) => (this.msgTxt = userName ? userName : this.msgTxt)
      );
    this.userName = this._localization.GetUserInfo("userName");
    this.firstName = this._localization.GetUserInfo("firstName");
    this.lastName = this._localization.GetUserInfo("lastName");
    this.userRole = this._localization.GetUserInfo("roleName");
    var product = this._localization.GetPropertyInfo("ProductId");
    this.productId = this._localization.GetPropertyInfo("ProductId");
    if (Number(product) == Product.SNC) {
      //added condition for snc..other product can use the condition for notification implementation
      this.prodId = Product.SNC;
      this.transactionCountSubscription =
        this.manageSessionService.transactionCount.subscribe((res) => {
          const taskresult =
            res &&
            res.find(
              (x) => x.id === NotificationFailureType.pendingTaskNotification
            );
          const revenueresult =
            res &&
            res.find(
              (x) => x.id === NotificationFailureType.revenuePostingFailure
            );
          const paymentresult =
            res &&
            res.find(
              (x) => x.id === NotificationFailureType.paymentTransactionFailure
            );
          if (taskresult && taskresult.count > 0) {
            if (
              this.notificationInfo &&
              this.notificationInfo.length > 0 &&
              this.notificationInfo.some(
                (x) => x.id === NotificationFailureType.pendingTaskNotification
              )
            ) {
              this.notificationInfo.find(
                (x) => x.id === NotificationFailureType.pendingTaskNotification
              ).message = this._localization.replacePlaceholders(
                this.captions.PendingTaskInfo,
                ["count"],
                [taskresult.count]
              );
            } else {
              this.notificationInfo.push({
                id: NotificationFailureType.pendingTaskNotification,
                message: this._localization.replacePlaceholders(
                  this.captions.PendingTaskInfo,
                  ["count"],
                  [taskresult.count]
                ),
              });
            }
          }
          if (revenueresult && revenueresult.count > 0) {
            if (
              this.notificationInfo &&
              this.notificationInfo.length > 0 &&
              this.notificationInfo.some(
                (x) => x.id === NotificationFailureType.revenuePostingFailure
              )
            ) {
              this.notificationInfo.find(
                (x) => x.id === NotificationFailureType.revenuePostingFailure
              ).message = this._localization.replacePlaceholders(
                this.captions.RevenuePostingInfo,
                ["count"],
                [revenueresult.count]
              );
            } else {
              this.notificationInfo.push({
                id: NotificationFailureType.revenuePostingFailure,
                message: this._localization.replacePlaceholders(
                  this.captions.RevenuePostingInfo,
                  ["count"],
                  [revenueresult.count]
                ),
              });
            }
          }
          if (paymentresult && paymentresult.count > 0) {
            if (
              this.notificationInfo &&
              this.notificationInfo.length > 0 &&
              this.notificationInfo.some(
                (x) =>
                  x.id === NotificationFailureType.paymentTransactionFailure
              )
            ) {
              this.notificationInfo.find(
                (x) =>
                  x.id === NotificationFailureType.paymentTransactionFailure
              ).message = this._localization.replacePlaceholders(
                this.captions.FailedTransLogInfo,
                ["count"],
                [paymentresult.count]
              );
            } else {
              this.notificationInfo.push({
                id: NotificationFailureType.paymentTransactionFailure,
                message: this._localization.replacePlaceholders(
                  this.captions.FailedTransLogInfo,
                  ["count"],
                  [paymentresult.count]
                ),
              });
            }
          }
          this.notificationCount = this.notificationInfo.length;
        });
    }
    if (Number(product) == Product.PMS) {
      this.showFloatingGuestItinerary = true;
      this.transactionCountSubscription =
        this.manageSessionService.transactionCount.subscribe((res) => {
          const cgpsLogResult =
            res && res.find((x) => x.id === NotificationFailureType.cgpsLog);
          if (cgpsLogResult) {
            if (
              this.notificationInfo &&
              !this.notificationInfo.some(
                (x) => x.id === NotificationFailureType.cgpsLog
              )
            ) {
              this.notificationInfo.push({
                id: NotificationFailureType.cgpsLog,
                message: cgpsLogResult.message,
              });
            }
          }
        });        
    }
    if (!this.sessionExpire.tokenTimerSubscription) {
      this.sessionExpire.forceLogOff();
    }
    this.isChangePropertyEnabled =
      this.propertyService.isChangePropertyEnabled();
    if (
      this.firstName == "undefined" ||
      this.lastName == "undefined" ||
      this.firstName == undefined ||
      this.lastName == undefined
    ) {
      this.userText = this.userName
        ? this.userName.charAt(0).toUpperCase()
        : "";
    } else {
      this.userText =
        this.firstName.charAt(0).toUpperCase() +
        this.lastName.charAt(0).toUpperCase();
    }
    sessionStorage.setItem(
      "menuList",
      JSON.stringify(this.menuList.menu)
    ); 
    if (this.levelMenu === menuTypes.combo) {
      this.selectedItem = this.getMenuFromMenuList(this.menuList.menu)
      this.router.events.pipe(takeUntil(this.destroyed$)).subscribe((x) => {
        this.selectedItem = this.getMenuFromMenuList(this.menuList.menu)
        this.selectedItem = { ...this.selectedItem };
      });
    }
 
    this.changePropertySevice.roleName$
      .pipe(takeUntil(this.destroyed$))
      .subscribe((roleName) => {
        if (roleName) {
          this.userRole = roleName;
        }
      });
    this.cartUtilities.isVcartEnabled().then((o) => {
      this.isVcartEnabled = o;
    });
    this.GetCurrencyDetails();
    // this.getPropertyDetails();
    this.propConfig();
    setTimeout(() => {
      if(document.getElementsByClassName("active-link")?.length >= 1){
        document.getElementsByClassName("active-link")[1]?.scrollIntoView({behavior: 'smooth'});
      }
    }, 1)
    
  }

  async GetCurrencyDetails()
  {
    await this.currencyService.GetCurrencyDetails();
    this.currencies = this.currencyService.CurrencyData.map(x => x.code);
    this.selectedCurrency = this._localization.currencyCode;
    this.currencyService.CurrencyCode = this.selectedCurrency;
    this.currencyService.CurrencySymbol = this.currencyService.GetCurrencySymbol("0", this.selectedCurrency)?.split('0')[0] ?? this._localization.currencySymbol;
  }
  closeMenuModel() {
    this.menusearchOpen = false;
    this.searchOpen = false;
    // Hide popovers if they exist
    [this.logOutpopOver, this.helppopOver, this.headerpopOver].forEach(popover => {
      if (popover?.opacity === 1) {
        popover.hide();
      }
    });
    // Close the matSelect if it exists
    if (this.matSelect) {
      this.matSelect.close();
    }
}
  async propConfig() {
    let propertyInfo = sessionStorage.getItem("propertyInfo");
    const propertyId = Number(
      propertyInfo
        .split(";")
        .map((x) => ({ key: x.split("=")[0].trim(), value: x.split("=")[1] }))
        .find((x) => x.key === "PropertyId").value
    );
    let propertyConfig = null;
    let propConfig = sessionStorage.getItem("propConfig")
      ? JSON.parse(sessionStorage.getItem("propConfig"))
      : null;
    if (propConfig != null) {
      propertyConfig = {
        configurationName: PROPERTY_CONFIGURATION_SETTINGS,
        propertyId: Number(propertyId),
        configValue: propConfig,
      } as API.PropertyConfigurationSettings<any>;
    }
    await this.propertyService.SetPropertyApiConfiguration(
      propertyId,
      propertyConfig
    );
  }
  getMenuFromMenuList(menuArray) {
   return menuArray.find(
      (menu) => this.router.url.indexOf(menu.routePath) > -1
    );
  }
  isAuthenticated() {
    this.gClient.isAuthenticated().then((result) => {
      if (result !== undefined) {
        this.authenticated = result;
      } else {
        this.authenticated = false;
      }
    });
  }
  cartCountModified(e) {
    this.cartCount = e.count;
    let splitter = e.primaryguestName ? " - " : "";
    this.activatedCart =
      e.activeConfirmationId + splitter + (e.primaryguestName ?? "");
  }
  async signInSignOut(): Promise<void> {
    if (this.authenticated) {
      this.authenticated = await this.gClient.signOutOfGraph();
    } else {
      this.authenticated = await this.gClient.signInToGraph();
    }
  }

  compareSelect = (val1, val2) => {
    return val1 && val2 && val1.text === val2.text;
  };

  ngAfterViewInit() {
    if (this.levelMenu === menuTypes.initial) {
      setTimeout(() => {
        this.bindHeaderData();
      }, 1);
    }
  }

  ngOnDestroy() {
    if (this.destroyed$) {
      this.destroyed$.next(true);
      this.destroyed$.complete();
    }
  }

  // Calculation for more options
  bindHeaderData() {
    const menuItem = cloneDeep(this.menuList.menu);
    let menuTobeShown = [];
    this.moreListItem = [];
    let headerWidth = 0;
    let moreCalc = false;
    const parentelementWidth = this.navBar.nativeElement.clientWidth;
    for (let menu of menuItem) {
      const elementWidth = this.getTextWidth(menu.text, "100 14px LatoWeb");
      headerWidth += elementWidth + 66;
      if (parentelementWidth - headerWidth >= elementWidth) {
        moreCalc = false;
        menuTobeShown.push(menu);
      } else {
        moreCalc = true;
        break;
      }
    }
    if (moreCalc) {
      menuTobeShown = [];
      headerWidth = 0;
      const moreWidth = this.getTextWidth(
        this.moreTextName,
        "100 14px LatoWeb"
      );
      const LegendsWidth = parentelementWidth - (moreWidth + 66);
      for (let menuData of menuItem) {
        const elementWidth = this.getTextWidth(
          menuData.text,
          "100 14px LatoWeb"
        );
        headerWidth += elementWidth + 90;
        if (LegendsWidth - headerWidth >= elementWidth) {
          menuTobeShown.push(menuData);
        } else {
          this.moreListItem.push({
            imgPath: menuData.imgPath,
            text: menuData.text,
            routePath: menuData.routePath,
            visibility: menuData.visibility,
            elementID: menuData.elementID,
            parentID: menuData.parentID,
            linkedElement: menuData.linkedElement,
          });
          this._routingMenuService.moreselectedText = null;
        }
        if(this.moreListItem.length > 0){
          this._routingMenuService.moreselectedText = this.moreListItem[0];
        }
        sessionStorage.setItem(
          "showedMainMenu",
          JSON.stringify(menuTobeShown)
        );
      }
     
    }
    this.menuItems = menuTobeShown;
    this.onResize();
    const currentMenu = JSON.parse(sessionStorage.getItem("currentMenu"));
    const topMostParent = this._routingMenuService.findTopmostParent(menuItem, currentMenu.elementID);
    const isMenuFound = this.moreListItem?.filter(
      (x) => x.elementID === topMostParent.elementID
    );
    if (isMenuFound.length > 0) {
      this._routingMenuService.setselectedRoutingMenu(currentMenu, topMostParent.text, true , topMostParent);
    } else {
      this._routingMenuService.setselectedRoutingMenu(currentMenu);
    }
  }

  // Text width
  getTextWidth(text, font) {
    const canvas = document.createElement("canvas");
    const context = canvas.getContext("2d");
    context.font = font;
    const metrics = context.measureText(text.toUpperCase());
    if (canvas) {
      canvas.remove();
    }
    return Math.ceil(metrics.width);
  }

  onResize() {
    // For closing the more pop over
    if (this.headerPopOver) {
      this.headerPopOver.hide();
    }

    // For closing the logout pop over
    if (this.logOutPopOver) {
      this.logOutPopOver.hide();
    }
  }

  openPopUp(popOver) {
    this.headerPopOver = popOver;
  }

  logout() {
    this.propertyService.isGoogleApiLoaded = false;
    this.logoutEvent.emit();
    // if(this.adb2cAuthConfiguration.ADB2CAuthFeatureEnabled)
    // {
    //   console.log('adb2c logout');
    //   this.oauthService.logOut(); //ADB2C logout
    // }
  }

  openGlobalSearch(e, type) {
    this.searchOpen = !this.searchOpen;
    this.menusearchOpen = false;
  }

  openMenuSearch() {
    this.menusearchOpen = !this.menusearchOpen;
    this.searchOpen = false;
  }

  openMailIntegrationConfig() {
    this.mailEmit.emit();
  }

  OptionSelected(event) {
    this.searchOpen = false;
  }

  MenuOptionSelected(event) {
    this.menusearchOpen = false;
  }

  navigateTo(option) {
    this._routingMenuService.setselectedRoutingMenu(option);
    this.router.navigate([option.value.routePath], {
      state: {
        ShowPopup: !option.value.linkedElement?.length,
        onSubmoduleChange: true,
        frommenu:false
      },
    });
  }

  download() {
    this._literalUpdate.download();
  }

  changeProperty(e) {
    const dialogRef = this.dialog.open(ChangePropertyComponent, {
      width: "30%",
      height: "35%",
      disableClose: true,
      data: {},
    });
    dialogRef.afterClosed().subscribe(() => {
      console.log("dialog closed");
    });
    this.logOutPopOver.hide();
    e.stopPropagation();
  }

  async iconClick() {
    this.helpEmit.emit();
  }
  downloadshrtcut(){ 
      const anchorElement = document.createElement('a');
      document.body.appendChild(anchorElement);
      anchorElement.href = 'assets/Agilysys Versa Keyboard Shortcut Guide.pdf';
      anchorElement.download = 'Agilysys Versa Keyboard Shortcut Guide.pdf';
      anchorElement.click();
      document.body.removeChild(anchorElement); 
  }

  openLogViewer() {
    this.logViewerEmit.emit();
  }

  openAboutDialog() {
    this.aboutEmit.emit();
  }
  removeRevenuePostInfo() {
    this.notificationInfo = this.notificationInfo?.filter(
      (x) => x.id !== NotificationFailureType.revenuePostingFailure
    );
  }

  removePaymentFailureInfo() {
    this.notificationInfo = this.notificationInfo?.filter(
      (x) => x.id !== NotificationFailureType.paymentTransactionFailure
    );
  }

  removeTaskNotificationInfo() {
    this.notificationInfo = this.notificationInfo?.filter(
      (x) => x.id !== NotificationFailureType.pendingTaskNotification
    );
  }

  removeCgpsFailureInfo() {
    this.notificationInfo = this.notificationInfo?.filter(
      (x) => x.id !== NotificationFailureType.cgpsLog
    );
  }

  routeTransc(id: number) {
    if (id === NotificationFailureType.revenuePostingFailure) {
      this.router.navigate([
        "/shop/viewshop/retailtransactions/revenuepostingslog",
      ]);
      this.removeRevenuePostInfo();
      this.notificationCount = this.notificationInfo?.length;
    } else if (id === NotificationFailureType.paymentTransactionFailure) {
      this.router.navigate([
        "/shop/viewshop/retailtransactions/transactionslog",
      ]);
      this.removePaymentFailureInfo();
      this.notificationCount = this.notificationInfo?.length;
    } else if (id === NotificationFailureType.pendingTaskNotification) {
      //for SNC
      this.router.navigate(["/home/viewtasks"], {
        state: { fromNotification: true },
      });
      //this.removeTaskNotificationInfo();
      this.notificationCount = this.notificationInfo?.length;
    } else if (id === NotificationFailureType.cgpsLog) {
      this.router.navigate(["/settings/utilities/cgpsLogging"]);
      this.removeCgpsFailureInfo();
      this.notificationCount = this.notificationInfo?.length;
    }
    this.notificationPopOver.hide();
  }

  notificationAlert() {
    this._localization.notification$.next(true);
  }

  handleShowAndHideJasperSoftStudioMenu() {
    let sessionValueofJasperStudio = sessionStorage.getItem(
      "showJasperSoftServerMenu"
    );
    if (sessionValueofJasperStudio != null) {
      this.showJasperSoftServerMenu = sessionValueofJasperStudio == "true";
    } else {
      this.showJasperSoftServerMenu = true;
    }
  }

  async openJasperSoftServerLink() {
    const [userattributeupdate,jasperServerURL,headers] = await  Promise.all([
       this.PropertySettingService.UpdateRoleAndAttributeToUser(),
       this.jasperServerCommon.GetJasperServerBaseURL(), 
       this.jasperServerCommon.GetJasperServerHeader()
    ]);
    let xhr = new XMLHttpRequest();
    xhr.withCredentials = true;
    xhr.addEventListener("readystatechange", function () {
      if (this.readyState === 4) {
        var url = jasperServerURL + "/flow.html?_flowId=homeFlow";
        let jaspersoftNavigationUri = url;
        window.open(jaspersoftNavigationUri, "_blank");
      }
    });
    let data = "";
    xhr.open("GET", jasperServerURL + "/rest_v2/serverInfo");
    Object.keys(headers).forEach(key => {
      if (headers[key] !== null) {
          xhr.setRequestHeader(key, headers[key]);
      }
     });
    xhr.send(data);
  }
  FrameRequestConnectorModel(): API.RequestConnector{
    var model : API.RequestConnector = {
      client_id : "",
      displayName : "",
      email : "",
      givenName : "",
      objectId : "",
      step : "",
      surname : "",
      ui_locales : "",
      userToken : Jaspersoftconstants.jwtidentifier + this.storageservice.getstoragekey("_jwt")
    } as API.RequestConnector;
    return model;
  }
   oncloseCartPopOver() {
    document.getElementById("cartPopOverID").classList.remove("newCartstyle");
    const bodyTag = document.getElementsByTagName("body")[0];
    bodyTag.classList.remove("vcart-overlay");
  }
  guestSearch()
  {
    this._routingMenuService.isFloatinguestenabled =!this._routingMenuService.isFloatinguestenabled;
  }

  triggerCurrencyDirective() {
    sessionStorage.setItem('selectedCurrency', this.selectedCurrency);
    this.currencyService.selectedCurrency.next(this.selectedCurrency)
    this.currencyService.GetCurrencyDetails(this.selectedCurrency);
    this.currencyService.CurrencyCode = this.selectedCurrency;
    this.currencyService.CurrencySymbol = this.currencyService.GetCurrencySymbol("0", this.selectedCurrency)?.split('0')[0] ?? this._localization.currencySymbol;
  }
  setCurrencyMenu() {
    const currentMenu = JSON.parse(sessionStorage.getItem("currentMenu"));
    this.showCurrencyMenu = currentMenu?.multicurrencyRequired ?? false;
  }
}
