import { Injectable } from "@angular/core";
import { Localization } from "src/app/common/localization/localization";
import { TableHeaderOptions, TableOptions, DropdownOptions } from 'src/app/common/Models/ag-models';
import { TableActions } from 'src/app/common/enums/shared-enums';
import { ActionTypeEnum, HdrActionTypeEnum, SorTypeEnum } from "src/app/common/components/cdkvirtual/cdkvirtual.model";
import { InventoryStagingService } from "../shared/service/inventory-staging.service";
import { InventoryScannerStaging, InventoryScannerStagingOperation, InventoryScannerStagingUI, InventoryScannerUIRequestModel, InventoryStagingFilter, StagingDetails, StagingState, stagingStatusDesc, Users } from "./inventory-staging.modal";
import { SubPropertyModel } from "../retail.modals";
import { CommonUtilities } from "src/app/common/shared/shared/utilities/common-utilities";

@Injectable()

export class InventoryStagingBusiness {

    captions: any;
    constructor(private localization : Localization, private inventoryStagingService: InventoryStagingService, private utils: CommonUtilities) {
        this.captions = this.localization.captions;
    }

    GetTableHeader(isViewOnly): TableHeaderOptions[] {
        return [
          {
            key: 'checked',
            displayName: '',
            isCheckboxDisabled: isViewOnly,
            templateName: ActionTypeEnum.checkbox,
            hdrtemplateName: HdrActionTypeEnum.hdrCheckbox
          },
          {
            key: 'itemNumber',
            displayName: this.captions.lbl_item_number,
            sorting: true
          },
          {
            key: 'itemName',
            displayName: this.captions.description,
            sorting: true
          },
          {
            key: 'barcode',
            displayName: this.captions.lbl_barCode,
            sorting: true
          },
          {
            key: 'quantity',
            displayName: this.captions.lbl_quantity,
            templateName: ActionTypeEnum.custom,
            inputType: ActionTypeEnum.numberInput
          },
          {
            key: 'stagingStatus',
            displayName: this.captions.lbl_stagingStatus,
            templateName: ActionTypeEnum.custom,
            sorting: true
          },
          {
            key: 'uploadedStaff',
            displayName: this.captions.lbl_userUploaded,
            sorting: true
          },
          {
            key: 'outletName',
            displayName: this.captions.lbl_Outlet,
            sorting: true
          },
          {
            key: 'deviceId',
            displayName: this.captions.lbl_deviceId,
            sorting: true
          },
          {
            key: 'approvedDate',
            displayName: this.captions.lbl_approvedDate,
            sorting: true
          },
          {
            key: 'approvedUser',
            displayName: this.captions.lbl_userApproved,
            sorting: true
          },
          {
            key: 'actions',
            displayName: 'Actions',
            templateName: ActionTypeEnum.action
          },
        ]
    }

    GetTableOptions(isViewOnly): TableOptions {
        return {
          defaultSortOrder: SorTypeEnum.asc,
          defaultsortingColoumnKey: 'itemNumber',
          columnFreeze: {
            firstColumn: true,
            lastColumn: true
          },
          showDeleteMsg: false,
          showTotalRecords: false,
          enablePagination: false,
          isHeaderCheckboxAllowed: !isViewOnly,
          checkboxKey: 'checked',
          deleteMsgKey: 'fee',
          isRowClick: true,
          uniqueKey: 'itemNumberColumn',
          enableServerPagination: true,
          actions: [
            {
              type: TableActions.delete,
              disabled: isViewOnly,
            }
          ]
        }
      }

      async getTableContent(filter:InventoryStagingFilter, users) :Promise<[InventoryScannerStagingUI[],number]> {
       
       let response = await this.inventoryStagingService.getInventoryStaging(filter);
       let apiResponse = response?.result
       let UIRecords: InventoryScannerStagingUI[] = [];
       if(apiResponse && apiResponse.stagedRecords && apiResponse.stagedRecords.length > 0){
        
        UIRecords =  this.MapInventoryStagingAPItoUIMapper(apiResponse.stagedRecords, users);
       }
       
      return [UIRecords,apiResponse?.totalRecords ? apiResponse.totalRecords : 0];
      }
      
      async IsPostingInProgress(){
        let response =  await this.inventoryStagingService.isPostingInProgress();
        return response?.result;

      }

      MapInventoryStagingAPItoUIMapper(inventoryRecordsAPI: InventoryScannerStaging[], users: Users[]){
       let  UIRecords: InventoryScannerStagingUI[] = []
        inventoryRecordsAPI.map(x => {
          let UIRecord: InventoryScannerStagingUI = {
            id: x.id,
            checked: false,
            isCheckboxDisabled: x.status == StagingState.Posted,
            isDeleteDisabled: x.status == StagingState.Posted,
            itemNumber: x.itemNumber,
            itemName: x.itemName,
            barcode:x.barcode,
            quantity: x.scannedQuantity,
            stagingStatus:x.status == StagingState.Posted ? stagingStatusDesc.posted: stagingStatusDesc.notPosted,
            uploadedStaff: x.uploadedStaff,
            outletId: x.outletId,
            deviceId: x.deviceId,
            approvedDate: x.approvedDate ? this.localization.localizeDisplayDate(x.approvedDate) : '-',
            approvedUser: x.approvedUser != 0 ? users.find(y => y.userId == x.approvedUser)?.userName : '-',
            outletName: x.outletName,
            oldQuantity: x.scannedQuantity
          };
          UIRecords.push(UIRecord);
        });
        return UIRecords;

      }

  async PostAndSaveInventoryStagings(data, isCumulativeCount) {
    let requestModel: InventoryScannerUIRequestModel = {
      stagingDetails: [],
      isCumulativeCount: isCumulativeCount
    };
    data.forEach(element => {
      let stagingDetail: StagingDetails = {
        inventoryScannerStagingId: element.id,
        newQuantity: Number(element.quantity),
        oldQuantity: element.oldQuantity,
        operation: InventoryScannerStagingOperation.PostAll
      }
      requestModel.stagingDetails.push(stagingDetail);
    });
    let result = await this.inventoryStagingService.PostAndSaveInventorStagings(requestModel);
    return result;
  }

  async TransferInventory(data){

  }

  async updateInventoryStagingQuantity(data){
    let requestModel: InventoryScannerUIRequestModel = {
      stagingDetails: [],
      isCumulativeCount: false
    };
    data.forEach(element => {
      let stagingDetail: StagingDetails = {
        inventoryScannerStagingId: element.id,
        newQuantity: Number(element.quantity),
        oldQuantity: element.oldQuantity,
        operation: InventoryScannerStagingOperation.QuantityChange
      }
      requestModel.stagingDetails.push(stagingDetail);
    });
    return await this.inventoryStagingService.UpdateInventoryStagings(requestModel); 
  }

  async GetOutletsByPropertyId() {
    let result = await this.inventoryStagingService.GetOutletsByPropertyId()
    let outlets: SubPropertyModel[] = result.result ? result.result : [];
    outlets = outlets.filter(x => x.isActive);
    return outlets.map(x => { return { id: x.subPropertyID, name: x.subPropertyName } });
  }

  async deleteStagings(data) {
    let result = await this.inventoryStagingService.DeleteInventoryStagings(data);
    return result;
  }

  async getUserInfo(propertyId, productId){
    let result = await this.inventoryStagingService.GetAllUsersByPropertyId(propertyId, productId)
    return result;
  }

  async getInventorySetting(){
    let result = await this.inventoryStagingService.getInventorySetting();
    return result.result;
  }
}