import { Injectable } from '@angular/core';
import { StagedRecordsAPI, Users, InventoryStagingFilter, InventoryScannerUIRequestModel } from '../../inventory-staging/inventory-staging.modal';
import * as GlobalConst from 'src/app/retail/shared/globalsContant';
import { HttpMethod, HttpServiceCall } from "./http-call.service";
import { RetailRoutes } from "../../retail-route";
import { SubPropertyModel } from '../../retail.modals';

@Injectable()
export class InventoryStagingService {

  constructor( public http: HttpServiceCall) { }
  async getInventoryStaging(filter: InventoryStagingFilter){
    return await this.http.CallApiAsync<StagedRecordsAPI>({
      host: GlobalConst.Host.retailManagement,
      callDesc: RetailRoutes.GetInventoryScannerStaging,
      method: HttpMethod.Post,
      body: filter,
      showError: true
    });
  }
  async isPostingInProgress(){
    return await this.http.CallApiAsync<boolean>({
      host: GlobalConst.Host.retailManagement,
      callDesc: RetailRoutes.IsPostingInProgress,
      method: HttpMethod.Get,
      showError: true
    });
  }

  async PostAndSaveInventorStagings(request: InventoryScannerUIRequestModel){
    return await this.http.CallApiAsync<boolean>({
      host: GlobalConst.Host.retailManagement,
      callDesc: RetailRoutes.PostInventoryStagings,
      method: HttpMethod.Post,
      body: request,
      showError: true
    });
  }

  async TransferInvetory(request: InventoryScannerUIRequestModel){
    return await this.http.CallApiAsync<boolean>({
      host: GlobalConst.Host.retailManagement,
      callDesc: RetailRoutes.PostInventoryStagings,
      method: HttpMethod.Post,
      body: request,
      showError: true
    });
  }

  async UpdateInventoryStagings(request: InventoryScannerUIRequestModel){
    return await this.http.CallApiAsync<boolean>({
      host: GlobalConst.Host.retailManagement,
      callDesc: RetailRoutes.UpdateInventoryStagings,
      method: HttpMethod.Put,
      body: request,
      showError: true
    });
  }

  async getInventorySetting(){
    return await this.http.CallApiAsync<any>({
      host: GlobalConst.Host.retailManagement,
      callDesc: 'GetConfiguration',
      method: HttpMethod.Get,
      showError: true,
      uriParams: { module: 3, Switch: 'INVENTORY_STAGING_POST_TYPE' }
    });
  }

  async DeleteInventoryStagings(request: number[]){
    return await this.http.CallApiAsync<boolean>({
      host: GlobalConst.Host.retailManagement,
      callDesc: RetailRoutes.DeleteInventoryStagings,
      method: HttpMethod.Delete,
      body: request,
      showError: true
    });
  }
  async GetOutletsByPropertyId() {
    return await this.http.CallApiAsync<SubPropertyModel[]>({
      host: GlobalConst.Host.retailManagement,
      callDesc: "GetOutletsByProperty",
      method: HttpMethod.Get,
      showError: true
    });
  }
  public async GetAllUsersByPropertyId(propertyId, productId): Promise<Users[]> {
    const response = await this.http.CallApiAsync<Users[]>({
      callDesc: 'GetUserInfoByPropertyId',
      method: HttpMethod.Get,
      host: GlobalConst.Host.authentication,
      uriParams: {
        propertyId: propertyId,
        productId: productId
      }
    });
    return response.result;
  }
}
