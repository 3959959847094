import { BaseReport } from "../common/base-report";
import { ReportAPIOptions, ReportUIConfig, ReportParams, AllReports } from "../report.modals";
import { UntypedFormGroup } from "@angular/forms";
import { ReportDataService } from "../../data/report-data.services";
import { ReportBusinessService } from "../report-business.service";
import { RetailUtilities } from "../../../shared/utilities/retail-utilities";
import { RetailFunctionalityBusiness } from "src/app/retail/shared/business/retail-functionality.business";
import { RetailPropertyInformation } from "src/app/retail/common/services/retail-property-information.service";
import { RetailLocalization } from "src/app/retail/common/localization/retail-localization";

export class RevenueSummary extends BaseReport {
    functionalities: { [key: string]: boolean} = {};
    constructor(public reportFormGrp: UntypedFormGroup, public data: ReportDataService,
        public business: ReportBusinessService, private utils: RetailUtilities,private func: RetailFunctionalityBusiness,
         public propertyInformation: RetailPropertyInformation
         , public retailLocalization : RetailLocalization) {
        super(reportFormGrp);
    }

    generateReportAPIOptions(): ReportAPIOptions {
        return {
            code: AllReports.RevenueSummary,
            params: this.formReportParams(),
            URIParams: this.formURIParams(),
            filters: this.formFilters(),
            pageBreak: true
        }; 
    }

    getReportUIConfig(): ReportUIConfig {
        return {
            startDatePicker: true,
            endDatePicker: false,
            dropDownFilters: this.data.getAllOutletsByUser(),
            dropDownFilterName: this.localization.captions.reports.Outlets,
            inActiveToggle: false,
            pageBreakToggle: false,
            layout: 'PORTRAIT',
            allowFutureDate: false
        }; 
    }

    protected formReportParams(): ReportParams[] {
        this.functionalities = this.func.getFunctionality();
        let toApi = this.localization.convertDateObjToAPIdate;
        let customFilterData: any = this.reportFormGrp.controls['customFilterFormGrp'].value;
        let clerkCaption:string = this.business.getFilterCaption(this.business.FilterDataSource["allUsers"],customFilterData.users.map(x=>x.id));
        return [{ "pStartDate": toApi(this.startDate) }, { "pEndDate": toApi(this.endDate) }, {'pDate': this.printedDate},
                { "pPageBreak": this.pageBreakToggle}, {"pPropertyName":this.propertyName},
                 {"pFilterOutlets":  this.functionalities.ShowOutletSelectionFieldInRevenueSummaryReport ? this.getFilterCaption : this.getDefaultOutletCaption}, 
                 {"pFilterClerks":clerkCaption},
                {"pPaymentMethod":this.localization.captions.reports.RetailInterfacePayment},
                {'pShowOutlet': this.functionalities.ShowOutletSelectionFieldInRevenueSummaryReport ? true : false } ];
    }

    protected formURIParams(): ReportParams[] {
        return [{"propertyId": Number(this.utils.GetPropertyInfo('PropertyId'))}];
    }


    protected formFilters() {
        let toApi = this.localization.convertDateObjToAPIdate;
        let correctVoidCustomFilterData: any = this.reportFormGrp.controls['customFilterFormGrp'].value;
        const defaultOutlet = this.propertyInformation.GetDefaultOutlet();
        const tempOutletFalse = defaultOutlet > 0 ? [defaultOutlet] : this.getDefaultOutletId;
        if(this.retailLocalization.isFromJobScheduler){
            const fromWildCard = this.reportFormGrp.controls['timelineFrom'].value;
            const toWildCard = this.reportFormGrp.controls['timelineTo'].value;
            const fromIncrement = this.reportFormGrp.controls['timelineCountFrom'].value;
            const toIncrement = this.reportFormGrp.controls['timelineCountTo'].value;
            return {
                StartDate: toApi(this.startDate),
            EndDate: toApi(this.endDate),
            OutletIds: this.functionalities.ShowOutletSelectionFieldInRevenueSummaryReport ?
                this.fillFilter(this.selectedFilterOptions, false) : tempOutletFalse,
            UserIds: this.fillFilter(correctVoidCustomFilterData.users, true),
                fromWildCard,
                toWildCard,
                fromIncrement,
                toIncrement 

            };
        }
        return {
            StartDate: toApi(this.startDate),
            EndDate: toApi(this.endDate),
            OutletIds: this.functionalities.ShowOutletSelectionFieldInRevenueSummaryReport ?
                this.fillFilter(this.selectedFilterOptions, false) : tempOutletFalse,
            UserIds: this.fillFilter(correctVoidCustomFilterData.users, true)
        };
    }

    fillFilter(customFilterData: any, fromUsers: boolean): number[] {
        const formData: any = this.reportFormGrp.controls['customFilterFormGrp'].value;
        let arr = [];
        arr =  customFilterData.length === 0 ? 0 : customFilterData.map(x => x.id);
        if(fromUsers && formData.users.length > 0 && (formData.users.length === formData.usersOptions.length)) {
            arr = [];
        }
        return arr;
    }
}


