import { Component, EventEmitter, Input, OnInit, Output, ViewContainerRef } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { RetailLocalization } from 'src/app/retail/common/localization/retail-localization';
import { ReportBusinessService } from '../../business/report-business.service';
import { ReportDataService } from '../../data/report-data.services';
import { RetailPropertyInformation } from 'src/app/retail/common/services/retail-property-information.service';

@Component({
  selector: 'app-vat-closed-check',
  templateUrl: './vat-closed-check.component.html',
  styleUrls: ['./vat-closed-check.component.scss']
})
export class VatClosedCheckComponent implements OnInit {

  vatClosedChecksFrmGrp: UntypedFormGroup;
  @Input() customReport: any;
  @Output() formReady = new EventEmitter();

  constructor(private fb: UntypedFormBuilder, public localization: RetailLocalization, public business: ReportBusinessService, 
    private dataService: ReportDataService, private propertyInfo: RetailPropertyInformation,private container: ViewContainerRef) { }

  ngOnInit(): void {
    this.vatClosedChecksFrmGrp = this.fb.group({});
    this.formReady.emit(this.vatClosedChecksFrmGrp);
  }

}
