import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild, ViewEncapsulation } from '@angular/core';
import { MatChipList } from '@angular/material/chips';
import { Localization } from 'src/app/common/localization/localization';
import { cloneDeep } from 'lodash';

@Component({
  selector: 'app-email-chip-selector',
  templateUrl: './email-chip-selector.component.html',
  styleUrls: ['./email-chip-selector.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class EmailChipSelectorComponent implements OnInit {

  visible = true;
  selectable = true;
  removable = true;
  addOnBlur = true;
  setFloatLabelNever: string;
  emailInput: string = ''; // Bind this to the input
  captions: any;
  readonly separatorKeysCodes: number[] = [ENTER, COMMA];
  mailArrayList: any[] = [];
  @Output() updatedEmailListEmit = new EventEmitter();
  @Output() expandCollapseEmit = new EventEmitter();
  chipListWidth: any;
  clonedMailChipListArray: any[] = [];
  isThresholdChipExceeded: boolean = false;
  thresholdExceedIndex: any;
  isExpand: any;
  @ViewChild(MatChipList) chipList: MatChipList;

  @Input('mailChipList')
  set setMailChipListInput(value){
    if(value){
      this.mailArrayList = value;
      this.clonedMailChipListArray = cloneDeep(this.mailArrayList);
    }
  }

  @Input('isExpandable')
  set setIsSectionExpandable(value){
    if(value != undefined){
      this.isExpand = value;
      if(!this.isExpand){
        this.removeMoreAction();
      } else{
        this.updateDisplayedLabels();
        this.updateListLogic();
      }
    }
  }

  removeMoreAction(){
    this.removeDisplayNoneClass();
    this.isThresholdChipExceeded = false
  }


  remove(fruit: any): void {
    const index = this.mailArrayList.indexOf(fruit);

    if (index >= 0) {
      this.mailArrayList.splice(index, 1);
    }
    this.removeMoreAction();

  }

  ngOnInit(): void {
    
  }

  constructor(private localization: Localization,private elementRef: ElementRef){
    this.setFloatLabelNever = this.localization.setFloatLabelNever;
    this.captions = this.localization.captions;
  }

  isValidEmail(email: string): boolean {
    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
    return emailRegex.test(email);
  }

  // Event triggered when a token (chip) is added or entered
  onChipInputEnd(event: any): void {
    if (this.isValidEmail(this.emailInput)) {
      this.mailArrayList.push(
        {
          id: 2,
          value:  this.emailInput,
          viewValue: this.emailInput,
          isVisible: true
        }
      ); // Add the email as a chip
      this.emailInput = ''; // Clear input field after adding chip
    } else {
      this.emailInput = ''; // Clear invalid input immediately
    }
    this.updatedEmailListEmit.emit(this.mailArrayList)
  }

  updateDisplayedLabels(): void {
    const chipListElement = this.elementRef.nativeElement.querySelector('mat-chip-list');
    const chipListArray =  this.elementRef.nativeElement.querySelector('mat-chip-list').querySelectorAll('mat-chip');
    this.calculateAddedChipListWidth(chipListArray,chipListElement)
    // this.displayedLabels = this.allLabels.slice(0, maxLabelsToShow);
  }

  calculateAddedChipListWidth(chipListArray,chipListElement){
    let totalWidth = 0;
    let iteration = 0;
    chipListArray.forEach((chip: HTMLElement,index) => {
      totalWidth += chip.offsetWidth;
      if (chipListElement) {
        this.chipListWidth = chipListElement.offsetWidth;
      }
      console.log(totalWidth," ",chipListElement.offsetWidth)
      // Logic to determine how many labels fit based on width
      if(totalWidth+200 < this.chipListWidth){
        this.isThresholdChipExceeded = false;
      } else {
        if(iteration == 0){
          this.thresholdExceedIndex  = index;
          this.isThresholdChipExceeded = true;
        } 
        iteration++;
        console.log(this.thresholdExceedIndex)
      } // Get the width of each chip and add it to the total
    });
   }

  showMoreChips(){
    this.isThresholdChipExceeded = !this.isThresholdChipExceeded;
    this.removeDisplayNoneClass()
  }

  onChipBlur(eve){
    console.log(eve)
  }

  onChipFocusOut(event: FocusEvent) {
    this.updateDisplayedLabels();
    console.log(this.isThresholdChipExceeded);
    for(var i = 0 ;i<this.mailArrayList.length;i++){
      if(this.thresholdExceedIndex <= i){
        this.mailArrayList[i].isVisible = false
      }
    }
    this.expandCollapseEmit.emit(this.isThresholdChipExceeded)
    // You can add any additional logic here for when the chip loses focus
  }

  removeDisplayNoneClass(){
  for(var i = 0 ;i<this.mailArrayList.length;i++){
      this.mailArrayList[i].isVisible = true
    }
  }

  onChipFocusIn(eve){
    this.removeMoreAction()
  }

  updateListLogic(){
    for(var i = 0 ;i<this.mailArrayList.length;i++){
      if(this.thresholdExceedIndex <= i){
        this.mailArrayList[i].isVisible = false
      }
    }
  }

}
