export const HtmlStringConversion =
{
  pageBreakBeforeTagRegExp: /{{{PageBreak}}}/g,
  pageBreakBeforeHtmlRegExp: /<div style="page-break-before: always;"><span><\/span><\/div>/g,
  pageBreakBeforeTag: '{{{PageBreak}}}',
  pageBreakBeforeHtml: '<div style="page-break-before: always;"><span></span></div>',

  pageNumberTagRegExp: /{{{PageNumber}}}/g,
  pageNumberHtmlRegExp: /<input type="hidden" class="pageNumber">/g,
  pageNumberTag: '{{{PageNumber}}}',
  pageNumberHtml: '<input type="hidden" class="pageNumber">'
};


export enum EmailActions{
    delete,
    attachFile
}

export enum EmailSenderType{
  to,
  cc,
  bcc
}

export enum emailCategory{
  SNCAccount = 0,
  SNCBooking
}