import { Component, OnInit, Input, ElementRef, ViewEncapsulation, Output, EventEmitter, ViewChild, AfterViewInit, ChangeDetectorRef, OnDestroy, HostListener } from '@angular/core';
import { Validators, UntypedFormBuilder, UntypedFormGroup, UntypedFormArray, UntypedFormControl } from '@angular/forms';
import * as myGlobals from '../globalsContant'; //CONSTANT FILE ADD ANY CONSTANT VALUE
import { MatDialog } from '@angular/material/dialog';
import { ReplaySubject } from 'rxjs';
import { MatMenuTrigger } from '@angular/material/menu';
import * as _ from 'lodash';
import { SpaLocalization } from '../../core/localization/spa-localization';
import { UserAlerts } from '../../core/config/alerts-config';
import { SpaUtilities } from '../utilities/spa-utilities';
import { AlertMessagePopupComponent } from '../../shared/alert-message-popup/alert-message-popup.component';
import { CustomCurrencyPipe } from '../../core/localization/currency.pipe';
import { LocalizeDatePipe } from '../../core/localization/localize-date.pipe';
import { LoadDecimalValuePipe } from '../pipes/load-decimal-value.pipe';
import { takeUntil } from 'rxjs/operators';
import { BaseResponse, SystemConfig } from '../business/shared.modals';
import { CommonUtilities } from 'src/app/common/shared/shared/utilities/common-utilities';
import { AgToggleConfig } from 'src/app/common/Models/ag-models';
@Component({
  selector: 'app-table',
  templateUrl: './table.component.html',
  styleUrls: ['./table.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class TableComponent implements OnInit, AfterViewInit, OnDestroy {
  RadiobuttonOption: boolean = false;
  sortableHeader: any;
  maxCol: number;
  HighlightRow: boolean = false;
  SelectRow: boolean = false;
  commissionTableOptions: boolean = false;
  SelectedData: any = [];
  sortingColoumn: any;
  setinterscroll: any;
  checkAll: boolean = false;
  editEvent: any;
  enableToggleButton: boolean = false;
  IfBooleanCheck: boolean = false;
  SetColumnGridValue: boolean = false;
  CheckInnerBoolValue: boolean = false;
  enableRowCheck: boolean = false;
  inActiveTherapist: UntypedFormControl;
  inActiveService: UntypedFormControl;
  inActivePackages: UntypedFormControl;
  inActiveRelationships:UntypedFormControl;
  inActiveInstructor:UntypedFormControl;
  inActiveClass:UntypedFormControl;
  public indeterminate: any;
  isDataLoading: boolean = false;
  @Input() isNonSetting;
  @Input() options;
  @Input() isRoleSetUpReadOnly;
  @Input() maxHeight = 0;
  @Input() isFromLinkAppt = false;
  @Input() automationId = '';
  @Output() afterEditClose: EventEmitter<any> = new EventEmitter();
  @Output() dragDropEvt: EventEmitter<any> = new EventEmitter();
  @Output() deleteEvt: EventEmitter<any> = new EventEmitter();
  @Output() editEvt: EventEmitter<any> = new EventEmitter();
  @Output() InActiveTherapistEvt: EventEmitter<any> = new EventEmitter();
  @Output() RowSelectEmitter: EventEmitter<any> = new EventEmitter();
  @Output() userActionEvt: EventEmitter<any> = new EventEmitter();
  @Output() CurrentRowSelectedEmitter: EventEmitter<any> = new EventEmitter();
  @Output() toggleEvtEmitter: EventEmitter<any> = new EventEmitter();
  @Output() addClick: EventEmitter<any> = new EventEmitter();
  @Output() printEvt: EventEmitter<any> = new EventEmitter();
  @Output() inactiveToggleEvtEmitter: EventEmitter<any> = new EventEmitter();
  @Output() doneEvtEmitter: EventEmitter<any> = new EventEmitter();
  @Output() LBLClickEvtEmitter: EventEmitter<any> = new EventEmitter();
  @Output() radioClickEvtEmitter: EventEmitter<any> = new EventEmitter();
  @Output() openWaitlist: EventEmitter<any> = new EventEmitter();
  @Output() openLostDenied: EventEmitter<any> = new EventEmitter();
  @Output() blockUserEdit: EventEmitter<any> = new EventEmitter();
  @ViewChild(MatMenuTrigger) trigger: MatMenuTrigger;
  @ViewChild('tableInput') tableInput: ElementRef;
  IsViewOnly: boolean = false;
  IsAccessAllowed: boolean = false;
  IsCheckAll: boolean = false;
  InActiveTherapistChkBoxEvt: any;
  orderTypearr: any = [];
  orderType: any = 'asc';
  currentIndex: any;
  hdrArray: any = [];
  bodyArray: any = [];
  selectedDefaultHeader: any;
  searchText: any;
  hdrkeyArray: any = [];
  originalHdrKeyArray: any = [];
  PlaceHoldertext: string;
  EnableActions: boolean;
  currentPage: any = 1;
  EnablePagination: boolean = true;
  InactiveTherapists: boolean = false;
  ChkInactiveService: boolean = false;
  inactivePackage: boolean = false;
  InactiveRoles: boolean = false;
  inactiveRelationship:boolean=false;
  inactiveClass: boolean = false;
  inactiveInstructor: boolean = false;
  SelectedSettingId: number = 1;
  blnDraggable: boolean = true;
  remailLength: any = [];
  Sortable: any;
  highlight: any;
  NewerData: any;
  previousSelectedColumn: any;
  givenOrderType: any;
  EditMoreOption: any;
  SelectOnlyRow: boolean = false;
  defaulSortColumn: any;
  overriddenSortColumn: string;
  sortColumnDataType: string;
  tableId: any;
  editable: boolean = true;
  waitlistIcon: boolean = false;
  lostDeniedIcon: boolean=false;
  deletable: boolean = true;
  deleteIcon: boolean = true;
  customHeader: boolean = false;
  pageTitle: string;
  customeHeaderOptions: any = [];
  quicksale: UntypedFormGroup;
  customHeaderButton: string;
  dropdownOptions: any = []
  captions: any = {};
  sticky: boolean = false;
  table: UntypedFormGroup;
  ArrayList = [];
  disableDelete: any;
  showToggle: any;
  toggleDisplayText: any;
  roleSetup: UntypedFormGroup;
  SearchKeyArr: any;
  DoneCancel: boolean = false;
  viewCheckedFlag: boolean = false;
  userAction: boolean = false;
  IsRetailCodeSetup: boolean;
  disableEditButton: boolean; //this boolean will prevent certain screens to show Edit button during view only break point applied.
  isValidRoleName: boolean = false;
  isEdit: boolean;
  editableRow: any;
  selectedrowId: number;
  tempTableData: any;
  editRecordsArray: any = [];
  activeIndex: any;
  isEditOptionRemove: boolean = false;
  isReadOnly: boolean = false;
  IsMoreOptionReadOnly: boolean = false;
  $destroyed: ReplaySubject<boolean> = new ReplaySubject(1);
  GridType = myGlobals.GridType;
  RetailTransactions = myGlobals.RetailTransactions;
  isEditMode: boolean;
  enableConfirmation: boolean;
  isEllipsis: any;
  floatLabel:string;
  floatLabelNever:string;
  customToggle: AgToggleConfig;
  activeToggleInputs: AgToggleConfig;
  showInactiveToggleInputs: AgToggleConfig;

  @HostListener('mousedown', ['$event', '$event.target'])
  onMousedown(e: MouseEvent, element: any) {
    const isDraggable = element.classList.contains('draggable');
    if (!isDraggable && element.closest('td')) {
      e.preventDefault();
    }
  }

  constructor(public dialog: MatDialog, private userAlerts: UserAlerts, public utilties: CommonUtilities, public el: ElementRef, public fb: UntypedFormBuilder, public localization: SpaLocalization, private utils: SpaUtilities, private _cdRef: ChangeDetectorRef, private loaddecimalvalue: LoadDecimalValuePipe, private currency: CustomCurrencyPipe, private localizedate: LocalizeDatePipe) {
    this.captions = this.localization.captions;
    this.floatLabel = this.localization.setFloatLabel;
    this.floatLabelNever = this.localization.setFloatLabelNever;
    this.customHeaderButton = this.localization.captions.setting.Add;
    for (let i = 1; i <= this.ArrayList.length; i++) {
      this.ArrayList.push(`item ${i}`);
    }
    this.table = this.fb.group({
      IsCheckAll: false,
      tablebody: this.fb.array([this.fb.group({ id: '', activetoggle: false, donecancel: false, category: "" })]),
      showInactiveToggle: ''
    });
    this.roleSetup = this.fb.group({
      roleName: ['', Validators.required],
      roleCode: ['', Validators.required],
      activetoggle: [false]
    });
    this.inActiveTherapist = new UntypedFormControl(false);
    this.inActiveService = new UntypedFormControl(false);
    this.inActivePackages = new UntypedFormControl(false);
    this.inActiveRelationships = new UntypedFormControl(false);
    this.inActiveClass = new UntypedFormControl(false);
    this.inActiveInstructor = new UntypedFormControl(false);
    this.customToggle = {
      group: this.table,
      formControlName: 'customToggle',
      automationId:"'Tog_table_customToggle'"
    }
   
    this.activeToggleInputs = {
      group: this.roleSetup,
      formControlName: 'activetoggle',
      automationId:"'Tog_table_activetoggle'"
    }
    this.showInactiveToggleInputs = {
      group: this.table,
      formControlName: 'showInactiveToggle',
      automationId:"'Tog_table_showInactiveToggle'"
    }
  }

  UpdateValidation() {
    this.roleSetup.markAsDirty();
    this.validateRoleSetup();
  }

  IsRoleSetupValid: boolean = false;

  private validateRoleSetup(): boolean {
    if (this.roleSetup.dirty && this.roleSetup.valid) {
      //case when role-setup form is in create mode or in edit mode after controls are dirty
      this.IsRoleSetupValid = true;
      return;
    }
    this.IsRoleSetupValid = false;
  }

  CreateTablerowFormGroup(optionsData) {
    let bodyArr: any = this.table.get('tablebody') as UntypedFormArray;
    bodyArr.controls = [];
    bodyArr.value = [];
    if (optionsData) {
      for (let i = 0; i < optionsData[0].TablebodyData.length; i++) {
        let bodyData = optionsData[0].TablebodyData[i];
        const tempActiveFalse = (bodyData.active ? bodyData.active : false);
        bodyArr.push(this.fb.group({
          id: bodyData.id,
          activetoggle: bodyData.isActive ? bodyData.isActive : tempActiveFalse,
          donecancel: this.fb.control(false),
          category: this.fb.control(bodyData.category),
          rowDisabled: this.editEvent && this.editEvent.id == bodyData.id ? this.editEvent.rowDisabled : false
        })
        );
      }
    }
    return bodyArr;
  }

  UpdateTableFormGroup(optionsData) {
    let bodyArr: any = this.table.get('tablebody') as UntypedFormArray;
    if (optionsData) {
      optionsData[0].TablebodyData.forEach(res => {
        let index = bodyArr.value.findIndex(x => x.id == res.id);
        if (index != -1)
          bodyArr.value[index].activetoggle = res.active;
      });
    }
    return bodyArr;
  }

  onDragOver(event, ele, newarr) {
    event.preventDefault();
  }

  onItemDrop(event, ele, newarr) {
    const timer = setTimeout(() => {
      const dragElements = document.getElementsByClassName('drag-border');
      Array.from(dragElements).forEach((el) => {
        el.classList.remove('drag-border');
      });
      clearTimeout(timer);
    }, 100);
    let draggeddata = event.dragData;
    let droppeddata = ele;
    let dragindex = newarr.indexOf(draggeddata);
    let dropindex = newarr.indexOf(droppeddata);
    let dragLstOrder: listOrder = draggeddata;
    let dropLstOrder: listOrder = droppeddata;
    this.dragDropEvt.emit([dragLstOrder.listOrder, dropLstOrder.listOrder, this.SelectedSettingId, this.InActiveTherapistChkBoxEvt]);
    newarr.splice(dragindex, 1);
    newarr.splice(dropindex, 0, draggeddata);
  }

  async ngOnInit() {
    if(!this.isFromLinkAppt){
      await this.GetSystemSetting();
    }
    if (typeof this.options == "undefined") { return; }
    this.defaulSortColumn = this.options[0].Sortable;
    this.showToggle = this.options[0].showToggle;
    this.toggleDisplayText = this.options[0].toggleDisplayText;
    const temp = (this.options[0].Sortable ? this.options[0].Sortable : 'id');
    this.Sortable = this.selectedDefaultHeader ? this.selectedDefaultHeader : temp;
    this.sortingColoumn = this.hdrkeyArray.indexOf(this.Sortable);
    this.selectedDefaultHeader = this.Sortable;
    let overriddenSortColumn = this.hdrArray.filter(o => o.jsonkey == this.Sortable).length > 0 ? this.hdrArray.filter(o => o.jsonkey == this.Sortable)[0].sortcolumn : null;
    let SortColumnDataType = this.hdrArray.filter(o => o.jsonkey == this.Sortable).length > 0 ? this.hdrArray.filter(o => o.jsonkey == this.Sortable)[0].sortcolumndatatype : null;
    this.orderTypearr = [];
    this.userAction = this.options[0].userAction;
    this.sortingFunc(this.selectedDefaultHeader, this.sortingColoumn, 'onInit', overriddenSortColumn, SortColumnDataType);
  }

  ngOnDestroy() {
    this.$destroyed.next(true);
    this.$destroyed.complete();
  }

  setMatformWidth(myElement) {
    if (this.tableInput) {
      let minWidth = myElement.parentElement.parentElement.getElementsByClassName('actionitems')[0] ? myElement.parentElement.parentElement.getElementsByClassName('actionitems')[0]['offsetWidth'] : 300; //min-300 max-470
      minWidth += myElement.getElementsByClassName('search-container')[0] ? myElement.getElementsByClassName('search-container')[0]['offsetWidth'] : 0;
      minWidth += myElement.getElementsByClassName('table-toggle-switches')[0] ? myElement.getElementsByClassName('table-toggle-switches')[0]['offsetWidth'] : 0;
      minWidth += myElement.getElementsByClassName('custom-retail-inputs')[0] ? myElement.getElementsByClassName('custom-retail-inputs')[0]['offsetWidth'] : 0;
      return minWidth;
    }
  }

  calculateWidth() {
    Array.from(document.querySelectorAll('#SPACustomTable>ng-scrollbar>.ng-scrollbar-container>.ng-scrollbar-view>table')).forEach((table, index) => {
      if (table) {
        let tableHeight = table['offsetHeight'];
        let parentHeight = (this.maxHeight > table.closest("#fixed-table-container")['offsetHeight'] ? this.maxHeight : table.closest("#fixed-table-container")['offsetHeight']);
        if (parentHeight > tableHeight) {
          table.closest("#SPACustomTable")['style']['height'] = tableHeight + 2 + 'px';
        }
        else if (parentHeight < tableHeight) {
          table.closest("#SPACustomTable")['style']['height'] = (parentHeight - 60) + 'px';
        }
      }
    });
    let searchClass = document.getElementsByClassName('CustomDataTable');
    for (let i = 0; i < searchClass.length; i++) {
      let pageHeader = searchClass[i].getElementsByClassName('page-header')[0] ? searchClass[i].getElementsByClassName('page-header')[0]['offsetWidth'] : 0;
      let searchInput = searchClass[i].getElementsByClassName('searchpt')[0];
      if (pageHeader > 0) {
        pageHeader = pageHeader - this.setMatformWidth(searchClass[i]) - 60;
      }
      let inputLength = this.tableInput ? this.tableInput.nativeElement.attributes['data-placeholder'].value.length : 1;
      let inputWidth = inputLength <= 30 ? inputLength * 10 : inputLength * 7.5 + 20;
      if (searchInput && pageHeader > 0) {
        searchInput['style'].width = (pageHeader > inputWidth) ? inputWidth + 'px' : pageHeader + 'px';
      }
    }
  }

  ngAfterViewChecked() {
    if (this.tableInput && !this.viewCheckedFlag) {
      this.viewCheckedFlag = true;
      this.calwidthwithtimeout();
      this.customtablealignment();
    }
    this._cdRef.detectChanges();
  }

  ngAfterViewInit() {
    this.calwidthwithtimeout();
    this._cdRef.detectChanges();
  }

  ngOnChanges() {
    this.editRecordsArray = [];
    this.viewCheckedFlag = false;
    this.table.value.tablebody = this.CreateTablerowFormGroup(this.options);
    this.tempTableData = _.cloneDeep(this.options);
    this.IsCheckAll = false;
    if (typeof this.options == "undefined") { this.isDataLoading = true;this.utilties.ToggleLoaderWithMessage(true,this.captions.lbl_processing); return; }
    this.isDataLoading = false;
    this.utilties.ToggleLoaderWithMessage(false);
    this.orderTypearr = [];
    this.defaulSortColumn = this.options[0].Sortable;
    const temp = (this.options[0].Sortable ? this.options[0].Sortable : 'id');
    this.Sortable = (this.selectedDefaultHeader && !this.options[0].isInitial) ? this.selectedDefaultHeader : temp;
    this.isReadOnly = this.options[0].IsReadOnly ? true : false;
    this.IsMoreOptionReadOnly = this.options[0].IsMoreOptionReadOnly ? true : false;
    this.selectedDefaultHeader = this.Sortable;
    this.SelectRow = this.options[0].SelectRows;
    this.DoneCancel = this.options[0].DoneCancel;
    this.toggleDisplayText = this.options[0].toggleDisplayText;
    this.showToggle = this.options[0].showToggle;
    this.blnDraggable = this.options[0].TableDraggable != undefined ? this.options[0].TableDraggable : true;
    this.editable = this.options[0].Editable != undefined ? this.options[0].Editable : true;
    this.waitlistIcon = this.options[0].waitlistIcon != undefined ? this.options[0].waitlistIcon : false;
    this.lostDeniedIcon=this.options[0].lostDeniedIcon!=undefined? this.options[0].waitlistIcon:false;
    this.deletable = this.options[0].deletable != undefined ? this.options[0].deletable : true;
    this.deleteIcon = this.options[0].DeleteIcon != undefined ? this.options[0].DeleteIcon : true;
    this.isEllipsis = this.options[0].isEllipsis != undefined ? this.options[0].isEllipsis : true;
    this.maxCol = 4;
    this.SelectedSettingId = this.options[0].SelectedSettingId;
    let minarrlength = this.options[0].TableHdrData.length <= this.maxCol ? this.maxCol - this.options[0].TableHdrData.length : 0;
    this.sticky = this.options[0].sticky ? this.options[0].sticky : false;
    this.remailLength = [];
    for (let m = 0; m < minarrlength; m++) {
      this.remailLength.push(m);
    }
    this.hdrArray = this.options[0].TableHdrData;
    this.originalHdrKeyArray = this.options[0].TableHdrData;
    this.SearchKeyArr = this.options[0].TableHdrData.filter(res => {
      return res.searchable != undefined ? res.searchable : true;
    });
    this.bodyArray = [];
    this.bodyArray = this.options[0].TablebodyData;
    this.SelectedData = [];
    //Set Checked items on load - based on the checked field in body data
    this.bodyArray.forEach(element => {
      if (element.checked != null && element.checked)
        this.SelectedData.push(element);
    });
    this.NewerData = this.options[0].NewData;
    this.NewerData = this.bodyArray.filter(o => {
      if (this.NewerData) {
        return ((o.code && o.code == this.NewerData.code) || (o.name && o.name == this.NewerData.name) || (o.addOnName && o.addOnName == this.NewerData.addOnName))
      }
    });
    if (this.NewerData[0]) {
      this.setinterscroll = setInterval(() => {
        const te = (this.NewerData[0] ? this.NewerData[0].id : '');
        let curiddata = this.NewerData ? te : '';
        this.autoscrolltocurrtime(curiddata);
      }, 500);

    }

    this.searchText = (this.options[0].TableSearchText || this.options[0].TableSearchText == '') ? this.options[0].TableSearchText : this.searchText;
    this.hdrkeyArray = [];
    if (this.hdrArray) {
      for (let item of this.hdrArray) {
        if (item.searchable != undefined ? item.searchable : true)
          this.hdrkeyArray.push(item.jsonkey);
      }
    }

    this.PlaceHoldertext = this.options[0].PlaceHoldertext;
    this.EnableActions = this.options[0].EnableActions;
    this.disableDelete = this.options[0].disableDelete;
    this.EnablePagination = this.options[0].pagination;
    this.InactiveTherapists = this.options[0].InactiveTherapists;
    this.ChkInactiveService = this.options[0].InactiveService;
    this.inactivePackage = this.options[0].InactivePackage;
    this.InactiveRoles = this.options[0].InactiveRoles;
    this.inactiveRelationship=this.options[0].InactiveRelationship;
    this.inactiveInstructor = this.options[0].InactiveInstructor;
    this.inactiveClass = this.options[0].InactiveClass;
    this.EditMoreOption = this.options[0].EditMoreOption;
    this.SelectOnlyRow = this.options[0].SelectOnlyRow;
    this.customHeader = this.options[0].customHeader;
    this.pageTitle = this.options[0].pageTitle;
    this.customeHeaderOptions = this.options[0].headerOptions;
    this.dropdownOptions = this.options[0].dropdownOptions;
    this.RadiobuttonOption = this.options[0].RadiobuttonOption;
    // Load Only active services
    if (this.ChkInactiveService)
      this.InactiveService({ checked: false },this.inActiveService.value);
    if (this.inactivePackage) {
      this.InactiveService([false],this.inActivePackages.value);
    }
    if (this.inactiveRelationship){
      this.InactiveService({ checked: false },this.inActiveRelationships.value);
    }
    if (this.inactiveClass)
      this.InactiveService({ checked: false },this.inActiveClass.value);
    this.sortingColoumn = this.hdrkeyArray.indexOf(this.Sortable);
    let overriddenSortColumn = this.hdrArray.filter(o => o.jsonkey == this.Sortable).length > 0 ? this.hdrArray.filter(o => o.jsonkey == this.Sortable)[0].sortcolumn : null;
    let SortColumnDataType = this.hdrArray.filter(o => o.jsonkey == this.Sortable).length > 0 ? this.hdrArray.filter(o => o.jsonkey == this.Sortable)[0].sortcolumndatatype : null;
    this.sortingFunc(this.selectedDefaultHeader, this.sortingColoumn, 'change', overriddenSortColumn, SortColumnDataType);
    this.table.controls['IsCheckAll'].setValue(this.bodyArray && this.bodyArray.length > 0 && (_.difference(this.bodyArray, this.SelectedData).length === 0));
    setTimeout(this.customtablealignment.bind(this), 1);
    this.IsViewOnly = this.options[0].IsViewOnly;
    this.disableEditButton = this.options[0].disableEditButton;
    this.IsRetailCodeSetup = (this.options[0].ServiceId == 'quicksale' || this.options[0].ServiceId == 'measures' || this.options[0].ServiceId == 'outlets');
    this.IsAccessAllowed = this.options[0].IsAccessAllowed;
    this.isEditOptionRemove = this.options[0].isEditOptionRemove;
    if (this.SelectedSettingId == myGlobals.GridType.waitlist) {
      this.orderType = '';
    }
    this.calwidthwithtimeout();
  }

  waitlist(cnt): void {
    this.openWaitlist.emit(cnt);
  }

  LostDenied(cnt):void{
    this.openLostDenied.emit(cnt);
  }

  customtablealignment() {
    let dropdown: any = [];
    let groupArray: any = {
      "servicegroup": {
        "servicegroupcode": "15",
        "description": "40",
        "listorder": "10",
      },
      "servicelocation": {
        "locationcode": "15",
        "description":"40",
        "comments": "40",
        "listorder": "10",
      },
      "serviceMedical": {
        "locationcode": "15",
        "description": "30",
        "conditionWarning": "40",
        "privateCondition": "2"
      },
      "serviceAddon": {
        "addonName": "10",
        "price": "10",
        "dummy": "2",
        "retailCategory": "40",
        "commisionable": "20"
      }
      ,
      "serviceEquipment": {
        "code": "25",
        "description": "40",
        "listorder": "10",
      },
      "therapist": {
        "code": "15",
        "name": "45",
        "listorder": "5"
      },
      "spaservices": {
        "code": "15",
        "desc": "40",
        "group": "25"
      },
      "commission": {
        "checkbox": "1",
        "desc": "20",
        "location": "10",
        "threshold": "10",
        "flatamount": "8",
        "percentage": "8",
        "dummy1": "5",
        "commissionOn": "10",
        "afterDisc": "10"
      },
      "appointmentsearch": {
        "desc": "5",
        "location": "15",
        "threshold": "10",
        "flatamount": "10",
        "percentage": "10",
        "commissionOn": "15",
        "afterDisc": "5",
        "afterDisc1": "5",
        "afterDisc2": "5",
        "afterDisc3": "5"
      },
      "packageYielding": {
        "checkbox": "0",
        "date": "20",
        "code": "15",
        "desc": "15",
        "class": "10",
        "avail": "10",
        "sold": "10",
        "remain": "10"
      },
      "spaPackage": {
        "code": "15",
        "desc": "20",
        "price": "10",
        "dummy": "10"
      },
      "spaPackageService": {
        "code": "15",
        "desc": "18",
        "price": "18"
      },
      "packageClasses": {
        "packageClassCode": "21",
        "packageName": "18"
      },
      "colorLink": {
        "linkCode": '15',
        "linkdesc": '20',
        "startDate": '15',
        "endDate": '15',
        "color": '15',
        "listOrder": '10',
        "status": '10'
      },
      "breakType": {
        "code": '15',
        "description": '25',
        "isUtilized": '20',
        "color": '15'
      },
      "priceType": {
        "priceCode": "15",
        "priceDescription": "50"
      },
      "guestType": {
        "guesttypecode": "15",
        "description": "40",
        "listorder": "10",
      },
      "relationship": {
        "relationshipcode": "15",
        "name": "40",
        "listorder": "10",
        "active": "10",
      },
      "customfield": {
        "customField": "25",
        "displayField": "35"
      },
      "locationMaintenance":
      {
        "checkbox": "0",
        "locationCode": "15",
        "locationDescription": "18",
        "beginsAt": "18",
        "endsAt": "18",
        "comments": "28"
      },
      "equipmentMaintenance":
      {
        "checkbox": "0",
        "equipmentCode": "15",
        "equipmentDescription": "18",
        "beginsAt": "18",
        "endsAt": "18",
        "comments": "30"
      },
      "therapistUnavailability": {
        "checkbox": "0",
        "therapistCode": "11",
        "therapistName": "12",
        "startDate": "12",
        "endDate": "13",
        "unavailabilityReason": "16",
        "services": "20",

      },
      "passcode": {
        "rule": "50",
        "status": "40"
      },
      "cancelreason": {
        "description": "40",
        "status": "20"
      },
      "outlets": {
        "outletName": "25",
        "terminalId": "25",
        "active": "25",
        "outletNumber": "25"
      },
      "roleSetup": {
        "roleName": "35",
        "active": "25"
      },
      "userSetup": {
        "userId": "10",
        "name": "15",
        "email": "15",
        "applicationAllowed": "15",
        "roles": "15",
        "createdOn": "15",
        "lastAccessedOn": "10",
      },
      "quicksale": {
        "checkbox": "0",
        "desc": "30",
        "itemid": "20",
        "temp": "10",
        "category": "15"
      },
      "retail": {
        // "checkbox": "0",
        "itemId": "10",
        "itemDescription": "30",
        "category": "10",
        "SalesPrice": "10",
        "dummy": "10",
        // "QtyonHand": "7",
        // "ItemPar": "7",
        "Inactive": "10",
        // "Cost": "8",
        // "PrintQty": "7",
      },
      "commissionTemplate": {
        "tempCode": "25",
        "tempName": "25",
        "on": "25",
        "splitLevels": "25"
      },
      "waitlist": {
        "date": "15",
        "time": "10",
        "client": "10",
        "service": "15",
        "location": "15",
        "therapist": "15",
        "priority": "5",
        "comments": "15"
      },
      "exception": {
        "timeStamp": "10",
        "traceId": "10",
        "service": "5",
        "userName": "5",
        "exceptionType": "20",
        "exceptionMessage": "30",
        "stackTracePath": "30"
      },
      "classgroup": {
        "servicegroupcode": "15",
        "description": "40",
        "listorder": "10",
      },
      "classlocation": {
        "locationcode": "15",
        "description":"40",
        "comments": "40",
        "listorder": "10",
      },
      "classEquipment": {
        "code": "25",
        "description": "40",
        "listorder": "10",
      },
      "instructor": {
        "code": "15",
        "name": "45",
        "listorder": "5"
      },
      "classSetup": {
        "code": "15",
        "desc": "40",
        "group": "25"
      }
    };

    switch (this.SelectedSettingId) {
      case myGlobals.GridType.group:
        dropdown = (Object.values(groupArray.servicegroup));
        break;
      case myGlobals.GridType.location:
        dropdown = (Object.values(groupArray.servicelocation));
        break;
      case myGlobals.GridType.medical:
        dropdown = (Object.values(groupArray.serviceMedical));
        break;
      case myGlobals.GridType.addons:
        dropdown = (Object.values(groupArray.serviceAddon));
        break;
      case myGlobals.GridType.equipment:
        dropdown = (Object.values(groupArray.serviceEquipment));
        break;
      case myGlobals.GridType.therapist:
        dropdown = (Object.values(groupArray.therapist));
        break;
      case myGlobals.GridType.spaServices:
        dropdown = (Object.values(groupArray.spaservices));
        break;
      case myGlobals.GridType.commission:
        dropdown = (Object.values(groupArray.commission));
        break;
      case myGlobals.GridType.appointmentsearch:
        dropdown = (Object.values(groupArray.appointmentsearch));
        break;
      case myGlobals.GridType.spaPackage:
        dropdown = (Object.values(groupArray.spaPackage));
        break;
      case myGlobals.GridType.packageYielding:
        dropdown = (Object.values(groupArray.packageYielding));
        break;
      case myGlobals.GridType.spaPackageService:
        dropdown = (Object.values(groupArray.spaPackageService));
        break;
      case myGlobals.GridType.packageClasses:
        dropdown = (Object.values(groupArray.packageClasses));
        break;
      case myGlobals.GridType.lunchsetup:
        dropdown = (Object.values(groupArray.lunchsetup));
        break;
      case myGlobals.GridType.colorLink:
        dropdown = (Object.values(groupArray.colorLink));
        break;
      case myGlobals.GridType.breakType:
        dropdown = (Object.values(groupArray.breakType));
        break;
      case myGlobals.GridType.priceType:
        dropdown = (Object.values(groupArray.priceType));
        break;
      case myGlobals.GridType.customfield:
        dropdown = (Object.values(groupArray.customfield));
        break;
      case myGlobals.GridType.locationMaintenance:
        dropdown = (Object.values(groupArray.locationMaintenance));
        break;
      case myGlobals.GridType.equipmentMaintenance:
        dropdown = (Object.values(groupArray.equipmentMaintenance));
        break;
      case myGlobals.GridType.therapistUnavailability:
        dropdown = (Object.values(groupArray.therapistUnavailability));
        break;
      case myGlobals.GridType.password:
        dropdown = (Object.values(groupArray.passcode));
        break;
      case myGlobals.GridType.cancelReasons:
        dropdown = (Object.values(groupArray.cancelreason));
        break;
      case myGlobals.GridType.outlet:
        dropdown = (Object.values(groupArray.outlets));
        break;
      case myGlobals.GridType.retail:
        dropdown = (Object.values(groupArray.retail));
        break;
      case myGlobals.GridType.quickSale:
        dropdown = (Object.values(groupArray.quicksale));
        break;
      case myGlobals.GridType.commissionTemplate:
        dropdown = (Object.values(groupArray.commissionTemplate));
        break;
      case myGlobals.GridType.roleSetup:
        dropdown = (Object.values(groupArray.roleSetup));
        break;
      case myGlobals.GridType.userSetup:
        dropdown = (Object.values(groupArray.userSetup));
        break;
      case myGlobals.GridType.waitlist:
        dropdown = (Object.values(groupArray.waitlist));
        break;
      case myGlobals.GridType.exception:
        dropdown = (Object.values(groupArray.exception));
        break;
      case myGlobals.GridType.guestType:
        dropdown = (Object.values(groupArray.guestType));
        break;
      case myGlobals.GridType.relationship:
        dropdown = (Object.values(groupArray.relationship));
      break;
      case myGlobals.GridType.classGroup:
        dropdown = (Object.values(groupArray.classGroup));
        break;
      case myGlobals.GridType.classLocation:
        dropdown = (Object.values(groupArray.classLocation));
        break;
        case myGlobals.GridType.classEquipment:
        dropdown = (Object.values(groupArray.classEquipment));
        break;
      case myGlobals.GridType.instructorSetup:
        dropdown = (Object.values(groupArray.instructor));
        break;
      case myGlobals.GridType.classSetup:
        dropdown = (Object.values(groupArray.classSetup));
        break;
    }

    let coloumncount = document.getElementsByTagName('th').length;
    let overallgivenwidth = 0;
    let ga;
    if (dropdown) {
      let parentWidth = document.getElementById('SPACustomTable') && document.getElementById('SPACustomTable').offsetWidth;
      if (this.isNonSetting !== false) {
        for (ga = 0; ga < dropdown.length; ga++) {
          if (document.getElementsByTagName('th')[ga]) {
            document.getElementsByTagName('th')[ga].style.width = Object.values(dropdown)[ga] + '%';
          }
          overallgivenwidth += Number(dropdown[ga]);
        }
      }

      if (this.bodyArray.length > 0) {
        let tablerow = document.getElementsByTagName('tr');
        for (let i = 1; i <= this.bodyArray.length; i++) {
          if (tablerow[i]) {
            for (let j = 0; j < dropdown.length; j++) {
              if (document.getElementsByTagName('tr')[i].cells) {
                if (document.getElementsByTagName('tr')[i].cells[j].querySelectorAll("#content").length > 0) {
                  document.getElementsByTagName('tr')[i].cells[j].querySelectorAll("#content")[0]['style'].maxWidth = parentWidth * (Number(Object.values(dropdown)[j]) / 100) + 'px';
                } 
              }
            }
          }
        }
      }
      for (let remCol = ga; remCol < coloumncount; remCol++) {
        if (document.getElementsByTagName('th')[coloumncount - 2] && document.getElementsByTagName('td')[ga]) {
          document.getElementsByTagName('th')[coloumncount - 2].style.width = (92 - overallgivenwidth) + '%';
          document.getElementsByTagName('td')[coloumncount - 2].style.maxWidth = parentWidth * (Number((92 - overallgivenwidth)) / 100) + 'px';
          if (this.EditMoreOption) {
            document.getElementsByTagName('th')[coloumncount - 1].style.width = '2%';
            document.getElementsByTagName('td')[coloumncount - 1].style.maxWidth = '100px';
          }
          else {
            document.getElementsByTagName('th')[coloumncount - 1].style.width = '3%';
            document.getElementsByTagName('td')[coloumncount - 1].style.maxWidth = '150px';
          }
        }
      }
    }
  }
  sliderChange(event: any, rowData?: any) {
    let data = { 'value': event.checked, 'data': rowData }
    this.toggleEvtEmitter.emit(data);
  }

  showInactiveRoles(event, rowData, index) {
    let tablebody = this.table.controls['tablebody'] as UntypedFormArray;
    let tableFormGroup = tablebody.controls[index] as UntypedFormGroup;
    tableFormGroup.controls.activetoggle.setValue(rowData.active)
    this.editRecordsArray.push({ index: index, data: rowData });
    this.activeIndex = index;
    let doneCancelCount = _.reduce(this.table.value.tablebody, (acc, val) => {
      acc = val.donecancel ? (acc + 1) : acc;
      return acc;
    }, 0);
    let doneCancelRecords: any = _.filter(this.table.value.tablebody, (data) => {
      return data.donecancel;
    });

    let editRecordList = document.getElementsByClassName("rowDisabled");
    if ((editRecordList.length > 0 || doneCancelCount > 0) && this.isValidRoleName) {

      let editableRecord = editRecordList.length > 0 ? _.filter(this.bodyArray, data => data.id == editRecordList[0].id) : _.filter(this.bodyArray, data => data.id == doneCancelRecords[0].id);
      let currentRecord = rowData;
      this.openAlertDialog(editableRecord, currentRecord, 'toggle', index, event, doneCancelCount)
    }
    else if (editRecordList.length > 0) {
      document.getElementsByClassName("rowDisabled")[0].classList.remove("highlight");
      document.getElementsByClassName('rowDisabled')[0].classList.remove("rowDisabled");
      this.resetForm();
      this.enableDoneCancel(index, rowData);
    }
    else {
      this.enableDoneCancel(index, rowData);
    }
  }

  resetForm() {
    this.roleSetup.reset();
    this.validateRoleSetup();
    this.customHeaderButton = this.localization.captions.setting.Add;
    this.enableToggleButton = false;
  }

  enableDoneCancel(index, rowData) {
    this.table.value.tablebody[index].donecancel = true;
    let data = { 'value': event, 'data': rowData }
    this.inactiveToggleEvtEmitter.emit(data);
    this.isValidRoleName = true;
  }

  Done(rowData, index) {
    this.editRecordsArray = [];
    this.editEvent = {};
    let currentData = this.options[0].TablebodyData[index];
    currentData.hasOwnProperty('isActive') ? currentData.isActive : currentData.active;
    this.table.value.tablebody[index].donecancel = false;
    this.doneEvtEmitter.emit(rowData);
    this.resetForm();
  }

  lblclick(rowData, index, clickable) {
    if (clickable == 'clickable') {
      this.LBLClickEvtEmitter.emit(rowData);
      return;
    } else {
      return false;
    }
  }

  radioClick(rowData, index) {
    this.radioClickEvtEmitter.emit(rowData);
  }

  Cancel(rowData, index) {
    let enableToggle;
    _.forEach(this.tempTableData[0].TablebodyData, (data) => {
      if (data.id == rowData.id) {
        enableToggle = data.hasOwnProperty('isActive') ? data.isActive : data.active;
      }
    });
    if (this.options[0].TablebodyData[index].hasOwnProperty('isActive')) {
      this.options[0].TablebodyData[index].isActive = enableToggle;
    }
    else {
      this.options[0].TablebodyData[index].active = enableToggle;
    }
    this.table.value.tablebody[index].activetoggle = enableToggle;
    this.table.value.tablebody[index].donecancel = false;
  }

  onButtonClick(event, type, belonTo) {
    this.editEvent = {};
    this.editRecordsArray = [];
    let data = {}
    if (belonTo == "roleSetup") {
      data = { 'value': this.roleSetup, 'type': type }
    } else {
      data = { 'value': '', 'type': type }
    }

    this.addClick.emit(data);
    /*Reset Form values to Default*/
    this.resetForm();
    this.validateRoleSetup();
    if (document.getElementsByClassName('rowDisabled').length > 0) {
      document.getElementsByClassName("rowDisabled")[0].classList.remove("highlight");
      document.getElementsByClassName('rowDisabled')[0].classList.remove("rowDisabled");
    }
    this.isEditMode = false;
  }

  onButtonCancelClick(event) {
    if (document.getElementsByClassName('rowDisabled').length > 0) {
      document.getElementsByClassName("rowDisabled")[0].classList.remove("highlight");
      document.getElementsByClassName('rowDisabled')[0].classList.remove("rowDisabled");
    }
    this.resetForm();
    this.isEditMode = false;
  }

  RowSelect(event, SelectedRow, Frm) {
    if (Frm == 'All') {
      if (event.checked) {
        this.SelectedData = [];
        for (let item of SelectedRow) {
          this.SelectedData.push(item);
        }
        this.SelectedData = this.SelectedData.filter(x => !x.disabled);
      }
      else {
        this.SelectedData = _.difference(this.SelectedData, this.bodyArray);
      }
    }
    else {
      if (this.SelectedData.indexOf(SelectedRow) == -1) {
        this.SelectedData.push(SelectedRow);
      } else {
        this.SelectedData.splice(this.SelectedData.indexOf(SelectedRow), 1);
        let checkedRow = this.bodyArray.findIndex(item => item.id == SelectedRow.id);
        this.bodyArray[checkedRow].checked = false;
      }
      this.table.controls['IsCheckAll'].setValue(this.bodyArray && this.bodyArray.length > 0 && (_.difference(this.bodyArray, this.SelectedData).length === 0));
    }
    this.RowSelectEmitter.emit(this.SelectedData);
    this.CurrentRowSelectedEmitter.emit({ "event": event, "SelectedRow": SelectedRow, "From": Frm });
  }

  InactiveService(e,inActive:Boolean) {
    if (e[0]||inActive) {
      this.bodyArray = [];
      this.bodyArray = this.options[0].TablebodyData;
    } else {
      let tempArr = [];
      this.bodyArray.forEach(row => {
        if (row.isActive)
          tempArr.push(row);
      });
      this.bodyArray = tempArr;
    }
    this.viewCheckedFlag = false;
  }

  InactiveTherapist(e) {
    this.InActiveTherapistChkBoxEvt = e[0];
    this.InActiveTherapistEvt.emit(e[0]);
    let Checkbool = e[0];
    this.givenOrderType = this.orderType;
    if (!Checkbool) {
      this.bodyArray = [];
      this.bodyArray = this.options[0].TablebodyData;
    }
  }

  openAlertPopup() {
    return this.dialog.open(AlertMessagePopupComponent, {
      width: '305px',
      height: '300px',
      hasBackdrop: true,
      panelClass: 'small-popup',
      data: {
        headername: this.captions.common.Warning, headerIcon: 'icon-warning-icon', headerMessage: this.captions.common.saveChangesMessage, buttonName: this.captions.common.Yes, noButton: true, noButtonName: this.captions.common.No, type: 'message'
      },
      disableClose: true,
    });
  }
  sortingFunc(dh, i, from, overriddenSortColumn, sortColumnDataType) {
    if (this.editRecordsArray.length > 0) {
      let dialogRef = this.openAlertPopup();
      dialogRef.afterClosed().subscribe(result => {
        if (result == 'Yes') {
          this.Cancel(this.editRecordsArray[0].data, this.editRecordsArray[0].index);
          this.editRecordsArray = [];
          this.sortHeader(dh, overriddenSortColumn, sortColumnDataType, i, from);
        }
      });
    }
    else {
      this.sortHeader(dh, overriddenSortColumn, sortColumnDataType, i, from);
    }
  }

  sortHeader(dh, overriddenSortColumn, sortColumnDataType, i, from) {
    this.selectedDefaultHeader = dh;
    this.overriddenSortColumn = overriddenSortColumn;
    this.sortColumnDataType = sortColumnDataType;
    if (!this.options[0].isInitial && this.defaulSortColumn == this.selectedDefaultHeader && from == "change") {
      if (this.orderTypearr.length > 0) {
        this.orderTypearr.splice(this.orderTypearr.indexOf(dh), 1);
      }
      else {
        this.orderTypearr.push(dh);
      }
    }
    else {
      if (this.givenOrderType == 'desc') {
        this.orderTypearr = [];
        this.orderTypearr.push(dh);
        this.givenOrderType = '';
      } else {
        this.givenOrderType = '';
      }
      if (this.orderTypearr.indexOf(dh) == -1) {
        this.orderTypearr = [];
        this.orderTypearr.push(dh);
        this.orderType = 'asc';
      } else {
        this.orderTypearr.splice(this.orderTypearr.indexOf(dh), 1);
        this.orderType = 'desc';
      }
    }
  }

  sortArrowFunction(i, eventType) {
    let element = document.getElementById("sortArrow" + i);
    if (element)
      eventType == 'remove' ? element.classList.remove("IC6") : element.classList.add("IC6");
    let headerElement = document.getElementById("header" + i);
    if (headerElement)
      eventType == 'remove' ? headerElement.classList.remove("IC6") : headerElement.classList.add("IC6");
  }

  async DeleteRecords(e) {
    if (this.enableConfirmation) {
      this.userAlerts.showPrompt(myGlobals.PromptType.Prompt, this.onAlertClose.bind(this), e);
    }
    else {
      this.userAlerts.showPrompt(myGlobals.PromptType.Delete, this.PopupCallback.bind(this), e);
    }
  }

  onAlertClose(res, e) {
    if (res.toLowerCase() == "yes") {
      this.userAlerts.showPrompt(myGlobals.PromptType.Delete, this.PopupCallback.bind(this), e);
    }
  }

  async GetSystemSetting() {
    this.utils.getConfiguration(this.successCallback.bind(this), this.errorCallback.bind(this), "Appointment", "CONFIRMATION_DIALOG");
  }

  successCallback<T>(result: BaseResponse<T>, callDesc: string, extraParams: any[]): void {
    if (callDesc == "GetConfiguration") {
      let res: SystemConfig = <any>result.result;
      this.enableConfirmation = res.value == "true" ? true : false;
    }
  }


  errorCallback<T>(): void {
    console.log('Error');
  }

  PopupCallback(result: string, extraParams?: any) {
    if (result.toLowerCase() == "ok") {
      this.deleteEvt.emit([extraParams, this.options[0].ServiceId, this.InActiveTherapistChkBoxEvt]);
      this.givenOrderType = this.orderType;
    }
  }

  autoscrolltocurrtime(curid) {
    if (document.getElementsByClassName('highlight').length > 0) {
      let curRow = curid.toString();
      let elms = document.getElementById(curRow);
      if (elms) {

        let scrolltoPos = (document.getElementById(curRow).offsetTop);
        let thPos = (document.getElementById('SPAHeaderRow').offsetHeight);
        document.getElementById("SPACustomTable").scrollTop = scrolltoPos - thPos;
        clearInterval(this.setinterscroll);
      }
    }
  }

  changeUserAction(event, e, belonTo, index?) {
    this.userActionEvt.emit([e]);
  }

  openAlertDialog = (editableRecord, currentRecord, eventType, index, event, cancelCount) => {
    let dialogRef = this.openAlertPopup();
    dialogRef.afterClosed().pipe(takeUntil(this.$destroyed)).subscribe(result => {
      if (result == 'Yes') {
        if (document.getElementsByClassName("rowDisabled").length > 0) {
          document.getElementsByClassName("rowDisabled")[0].classList.remove("highlight");
          document.getElementsByClassName("rowDisabled")[0].classList.remove("rowDisabled");
        }
        if (cancelCount > 0) {
          let recordArray = this.editRecordsArray;
          recordArray.length == 1 ? this.Cancel(recordArray[0].data, recordArray[0].index) : this.Cancel(recordArray[recordArray.length - 2].data, recordArray[recordArray.length - 2].index)
        }
        if (eventType == 'toggle') {
          this.table.value.tablebody[index].donecancel = true;
          _.forEach(this.table.value.tablebody, (res, i) => {
            if (res.donecancel && res.id != this.table.value.tablebody[index].id) {
              this.table.value.tablebody[i].donecancel = !this.table.value.tablebody[i].donecancel;
            }
          })
          let data = { 'value': event, 'data': currentRecord }
          this.inactiveToggleEvtEmitter.emit(data);
          this.resetForm();
        }
        else {
          this.editEvent = { id: currentRecord.id, rowDisabled: true };
          _.forEach(this.table.value.tablebody, (data, i) => {
            if (data.donecancel) {
              data.donecancel = !data.donecancel;
            }
          });
          this.editRow(event, currentRecord, 'edit', this.options[0].ServiceId)
        }
      }
      else {
        if (this.editRecordsArray.length > 0) {
          this.editRecordsArray.pop();
        } 
        if (eventType == 'toggle') {
          this.Cancel(currentRecord, index)
        }
      }
    })
  }

  editRow(event, e, type, belonTo, index?) {
    /*Updated Edit for Retail*/
    let doneCancelCount = _.reduce(this.table.value.tablebody, (acc, val) => {
      acc = val.donecancel ? (acc + 1) : acc;
      return acc;
    }, 0);
    let doneCancelRecords: any = _.filter(this.table.value.tablebody, (data) => {
      return data.donecancel
    });
    if (doneCancelCount > 0 && this.editRecordsArray.length > 0) {
      let lastRecord = this.editRecordsArray.pop();
      this.editRecordsArray = [];
      this.editRecordsArray.push(lastRecord);
    }
    else {
      this.editRecordsArray = [];
    }
    let editRecordList = document.getElementsByClassName("rowDisabled");
    if ((editRecordList.length > 0 || doneCancelCount > 0) && this.isValidRoleName) {
      let editableRecord = editRecordList.length > 0 ? _.filter(this.options[0].TablebodyData, data => data.id == editRecordList[0].id) : _.filter(this.options[0].TablebodyData, data => data.id == doneCancelRecords[0].id);

      let currentRecord = e;
      this.openAlertDialog(editableRecord, currentRecord, 'edit', index, event, doneCancelCount);
    } else {
      if (this.customHeader) {
        this.customHeaderButton = this.localization.captions.setting.UPDATE;
        this.validateRoleSetup();
        this.enableToggleButton = true;
        if (belonTo == 'roleSetup') {
          this.roleSetup.controls['roleName'].setValue(e.description);
          this.roleSetup.controls['roleCode'].setValue(e.code);
          this.roleSetup.controls['activetoggle'].setValue(e.active);
          this.isEditMode = true;
          this.isValidRoleName = false;
          if (typeof event == 'object' && event.target.parentElement.parentElement.classList.contains("rowDisabled")) {
            this.roleSetup.controls['roleName'].setValue("");
            this.roleSetup.controls['activetoggle'].setValue(false);
            this.customHeaderButton = this.localization.captions.setting.Add;
          }
        }
        if (typeof event == 'object') {
          event.target.parentElement.parentElement.classList.toggle("rowDisabled");
          if (document.getElementsByClassName("rowDisabled").length > 1) {
            let classList = document.getElementsByClassName("rowDisabled");
            for (let i = 0; i < classList.length; i++) {
              if (classList[i].id != e.id) {
                document.getElementsByClassName("rowDisabled")[i].classList.remove("highlight");
                document.getElementsByClassName("rowDisabled")[i].classList.remove("rowDisabled");
              }
            }
          }
        }
        this.EditRecords(e, type, index);
      }
      else {
        if (document.getElementsByClassName("highlight").length > 0) {
          document.getElementsByClassName("highlight")[0].classList.remove("highlight");
        }
        event.target.parentElement.parentElement.classList.add("highlight");
        this.EditRecords(e, type, index);
      }
    }
  }

  EditRecords(e, type, index?) {
    if (this.editEvt)
      if (this.SelectedSettingId == myGlobals.GridType.commission) {
        this.editEvt.emit([e, type, index]);
      } else {
        this.editEvt.emit([e, this.options[0].ServiceId, type]);
        this.givenOrderType = this.orderType;
      }
  }

  PrintRecord(e) {
    if (this.printEvt) {
      this.printEvt.emit([e]);
    }
  }

  SetColumnValue(bodyCnt: any, jsonkey: string): string {
    jsonkey = jsonkey ? jsonkey : "";
    if (jsonkey.includes("+")) {
      let result = "";
      let arrKey = jsonkey.split("+");
      for (let i = 0; i < arrKey.length; i++) {
        let val = bodyCnt[arrKey[i]];
        val = val ? val : "";
        result = result + (i == 0 ? "" : " ") + val;
      }
      return result;
    }
    return bodyCnt[jsonkey];
  }

  printQtyColor(event) {
    if (event.target.value > 0) {
      event.target.style.fontFamily = "LatoWeb";
    }
  }

  calwidthwithtimeout() {
    setTimeout(() => {
      this.calculateWidth();
    }, 1);
  }

  public clearSearchText(): void {
    this.searchText = "";
  }

  trackByFn(index) {
    return index;
  }

  checkBool(IfBoolean: any): boolean {
    let ReturnBool: boolean;
    if (typeof IfBoolean === "boolean") {
      ReturnBool = true;
    } else {
      ReturnBool = false;
    }
    return ReturnBool;
  }

  checkInnerBool(paramsinner: any): boolean {
    let ReturnBool: boolean;
    if (typeof paramsinner === "object") {
      ReturnBool = true;
    } else {
      ReturnBool = false;
    }
    return ReturnBool;
  }

  emitBlockrowdata(eve, data) {
    console.log("emitDeleterowdata", data);
    this.blockUserEdit.emit([data, 'blockUseredit']);
  }

}

export interface listOrder {
  listOrder: number;
}
