import { ButtonValue, AgInputFieldConfig, AgDateConfig } from 'src/app/common/Models/ag-models';
import { Input, Output, Component, OnInit, Inject, ViewEncapsulation, EventEmitter } from '@angular/core';
import { UntypedFormArray, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Localization } from 'src/app/common/localization/localization';
import { ActionMode, AlertType } from 'src/app/common/shared/shared/enums/enums';
import { ReplaySubject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ButtonType } from 'src/app/common/enums/shared-enums';
import { AlertAction, PolicyType } from 'src/app/common/shared/shared.modal';
import { CommonUtilities } from 'src/app/common/shared/shared/utilities/common-utilities';
import { ApplyWithinType, ChargeType, TeeTimePriceType } from '../../cancellation-no-show-policy/cancellation-no-show-policy.model';
// import { PolicyType,TeeTimePriceType,ChargeType,ApplyWithinType } from '../cancel

@Component({
  selector: 'app-create-deposit-policy',
  templateUrl: './create-deposit-policy.component.html',
  styleUrls: ['./create-deposit-policy.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class CreateDepositPolicyComponent implements OnInit {

  cancellationForm: UntypedFormGroup;
  actionButton: ButtonValue;
  cancelButton: ButtonValue;
  captions: any;
  destroyed$: ReplaySubject<boolean> = new ReplaySubject(1);
  policyCodeConfig: AgInputFieldConfig;
  policyNameConfig: AgInputFieldConfig;
  policyDescriptionConfig: AgInputFieldConfig;
  codeMaxLength: number = 10;
  includeChargeOptions = [];
  PolicyTypeEnum = PolicyType;
  TeeTimePriceEnum = TeeTimePriceType;
  ChargeTypeEnum = ChargeType;
  floatLabel: string;
  addChargeButton: ButtonValue;
  ApplyWithinEnum = ApplyWithinType;
  scheduleTypeOptions : {id:number,value:string,viewValue:string}[] = [];
  @Input() inputs;
  @Output() formReady = new EventEmitter();
  @Output() handleClickEvent = new EventEmitter();
  constructor(private localization: Localization,
    private fb: UntypedFormBuilder, private utils: CommonUtilities,) { }

    ngOnInit(): void {
      this.captions = this.localization.captions;
      this.floatLabel = this.localization.setFloatLabel;
      this.includeChargeOptions = this.getchargeOptions();
      this.scheduleTypeOptions = this.getScheduleOptions();
      this.formGenerator();
      this.cancellationForm.valueChanges.pipe(takeUntil(this.destroyed$)).subscribe(val => {
        this.actionButton.disabledproperty = !(this.cancellationForm.dirty && this.cancellationForm.valid)
      });
    }
  
    formGenerator(){
      this.cancellationForm = this.fb.group({
        policyType: this.PolicyTypeEnum.Cancellation,
        active: false,
        policyCode: '',
        policyName: '',
        policyDescription : '',
        startDate: '',
        endDate: '',
        includeCharges:'',
        teetimePrice: this.TeeTimePriceEnum.FullPrice,
        percentOrAmount: this.ChargeTypeEnum.Percentage,
        percent:'',
        amount:'',
        charges: this.fb.array([
          this.fb.group({
            scheduleType: 0,
            applyWithinHoursDays: '',
            priceType: this.TeeTimePriceEnum.FullPrice,
            chargeType: this.ChargeTypeEnum.Percentage,
            charge: '',
          })
        ])
      })
      this.policyCodeConfig = {
        form: this.cancellationForm,
        formControlName: 'policyCode',
        placeHolder: this.captions.tbl_hdr_policyCode,
        maxlength: this.codeMaxLength,
        showRequired: true,
        errorMessage: this.captions.missing_policyCode,
      };
      this.policyNameConfig = {
        form: this.cancellationForm,
        formControlName: 'policyName',
        placeHolder: this.captions.tbl_hdr_policyName,
        showRequired: true,
        errorMessage: this.captions.missing_policyName,
      };
      this.policyDescriptionConfig = {
        form: this.cancellationForm,
        formControlName: 'policyDescription',
        placeHolder: this.captions.policyDescription,
        showRequired: false,
        className: 'w-100'
      };
      this.actionButton = {
        type: "primary",
        label: this.inputs.mode === ActionMode.update ? this.captions.btn_update : this.captions.btn_save,
        disabledproperty: true,
      };
      this.cancelButton = {
        type: "tertiary",
        label: this.captions.btn_cancel,
      };
      this.addChargeButton = {
        type: "secondary",
        label: this.captions.btn_addCharge,
      }
    }
  
    addCharge(e){
      const controls=this.fb.group({
        scheduleType: 0,
        applyWithinHoursDays: '',
        priceType: this.TeeTimePriceEnum.FullPrice,
        chargeType: this.ChargeTypeEnum.Percentage,
        charge: '',
      })
      let array = this.cancellationForm.controls.charges as UntypedFormArray;
      array.push(controls);
      this.cancellationForm.markAsDirty();
    }
    checkValue(){
  
    }
  
    onDeleteRow(index){
      let array = this.cancellationForm.controls.charges as UntypedFormArray;
      array.removeAt(index);
      this.cancellationForm.markAsDirty();
    }
  
    getchargeOptions(){
      return [
        {
          id: 0,
          viewValue: this.captions.tierLevel
        },
        {
          id: 1,
          viewValue: this.captions.membershipType
        }
      ]
    }

    getScheduleOptions() {
      return [
        {
          id : 1,
          value:this.captions.lbl_timeofBooking,
          viewValue:this.captions.lbl_timeofBooking
        },
        {
          id : 2,
          value:this.captions.lbl_onArrival,
          viewValue:this.captions.lbl_onArrival
        },
        {
          id : 3,
          value:this.captions.lbl_priortoArrival,
          viewValue:this.captions.lbl_priortoArrival
        },
      ]
    }
  
  
    policyTypeChange(e){
  
    }
  
    onActiveChange(e){
  
    }
  
    onAction(e){
      const clickReturnValue = {
        from: this.inputs.mode,
        formValues: this.cancellationForm.getRawValue(),
        form: this.cancellationForm
      };
      this.handleClickEvent.emit(clickReturnValue);
    }
  
    onCancel(eve) {
      const clickReturnValue = {
        from: ActionMode.cancel,
        formValues: this.cancellationForm.getRawValue(),
        form: this.cancellationForm
      };
      if (this.cancellationForm.dirty) {
        this.utils.showAlert(this.captions.lbl_Cancel_Msg, AlertType.Warning, ButtonType.YesNo, (res) => {
          if (res == AlertAction.YES) {
            this.handleClickEvent.emit(clickReturnValue);
          }
        })
      }
      else {
        this.handleClickEvent.emit(clickReturnValue);
      }
    }

    onScheduleChange(eve) {
      
    }

}


