import { Component, OnInit, Input, EventEmitter, Output, ViewChild } from "@angular/core";
import { NgxImageCompressService } from "ngx-image-compress";
import { Localization } from "src/app/common/localization/localization";
import { COMPRESSION_LIMIT, ALLOWED_IMAGE_SIZE } from "../../constants";
import { CommonUtilities } from "../../shared/shared/utilities/common-utilities";
import { AlertType, ButtonTypes, AlertAction} from "../../Models/common.models";
import { ButtonType } from "../../enums/shared-enums";
import { CaptureImageComponent } from 'src/app/common/components/capture-image/capture-image.component';
import { MatDialog } from '@angular/material/dialog';
import { takeUntil } from 'rxjs/operators';
import { ReplaySubject } from 'rxjs';
import { AngularDeviceInformationService } from 'angular-device-information';
import { MatMenuTrigger } from "@angular/material/menu";

@Component({
  selector: "ag-image-uploader",
  templateUrl: "./image-uploader.component.html",
  styleUrls: ["./image-uploader.component.scss"],
})
export class AgImageUploaderComponent implements OnInit {
 
  requiredWarningMsg: string;
  url: any;
  ImageUploaded: boolean;
  captions: any;
  isViewOnly = false; 
  destroyed$: ReplaySubject<boolean> = new ReplaySubject(1);

  @Input() readOnly: boolean;
  @Input() emptyImgCaption: string;
  @Input() requiredWarningPopup: boolean;

  @Input("imgData")
  set ImageData(value) {
    if (value) {
      this.url = value;
      this.ImageUploaded = true;
    } else {
      this.ImageUploaded = false;
      this.url = "";
    }
  }

  @Output() fileDeleted = new EventEmitter();
  @Output() fileUploaded = new EventEmitter();
  @Output() fileSizeExceeded = new EventEmitter();  
  @ViewChild('menuTrigger') menuTrigger: MatMenuTrigger;

  constructor(
    public dialog: MatDialog,
    private imageCompress: NgxImageCompressService,
    private localization: Localization,
    private utilities: CommonUtilities,
    private deviceInformationService: AngularDeviceInformationService
  ) {}

  ngOnInit() {
    this.captions = this.localization.captions.common;
  }

  uploadPhoto(){
    console.log(this.deviceInformationService.getDeviceInfo().os);
    if(this.deviceInformationService.getDeviceInfo().os == 'Windows'){
      this.menuTrigger.openMenu();   
    }else{
      this.compressFile();
    }    
  }

  compressFile() {
    this.imageCompress.uploadFile().then(({ image, orientation }) => {
      const supportedFileFormat = ["jpg", "png", "jpeg", "svg"];
      const uploadFileFormat = image?.split(";")[0]?.split(":")[1]?.split("/")[1];
      const isImageValid = supportedFileFormat.some((v) => uploadFileFormat?.includes(v));
      if (isImageValid) {
        this.validateImage(image, orientation);
      } else {
        this.utilities.showAlert(this.captions.lbl_Image_validation, AlertType.Error, ButtonType.Ok);
      }
    });
  }

  validateImage(image, orientation){
    this.url = image;
    const fileSize = this.imageCompress.byteCount(image) / 1024;
    if (fileSize > COMPRESSION_LIMIT) {
      this.compressImage(image, orientation);
    } else {
      this.compressThumbnail(image);
    }
  }

  compressImage(image, orientation){
    this.imageCompress.compressFile(image, orientation).then((result) => {
      const compressedfileSize = this.imageCompress.byteCount(result) / (1024 * 1024);
      if (compressedfileSize <= ALLOWED_IMAGE_SIZE) {
        this.url = result;
        this.compressThumbnail(result);
      } else {
        this.fileSizeExceeded.emit();
      }
    });
  }

  compressThumbnail(image) {
    this.ImageUploaded = true;
    const imgData = { orgImg: image };
    this.imageCompress.compressFile(image, 1, 30, 30).then((result) => {
      imgData["tmbImg"] = result;
      this.fileUploaded.emit(imgData);
    });
  }

  onFileDelete() {
    if (this.requiredWarningPopup) {
      this.requiredWarningMsg = this.captions.removepopup || "";
      this.utilities.showCommonAlert(this.requiredWarningMsg, AlertType.Confirmation, ButtonTypes.YesNo, (res) => {
          if (res === AlertAction.YES) {
            this.ImageUploaded = false;
            this.fileDeleted.emit();
          }
        });
    } else {
      this.ImageUploaded = false;
      this.fileDeleted.emit();
    }
  }

  captureImage() {
    this.dialog.open(CaptureImageComponent, {
      disableClose: true,
    }).afterClosed().pipe(takeUntil(this.destroyed$)).subscribe(res => {
      if(res){
        this.validateImage(res.data, 1);
      }
    });
  }
}
