import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ReplaySubject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { SpaLocalization } from 'src/app/core/localization/spa-localization';
import { QuickIdConfigBusiness } from './quickid-config.business';
import { ConfigData, QuickIdConfigSetting } from './quickid-config.model';
import { QuickIdConfigService } from './quickid-config.service';

import { BreakPointAccess } from '../../../shared/service/breakpoint.service';
import * as _ from 'lodash'
import { SPAManagementBreakPoint } from 'src/app/shared/globalsContant';
import { AgToggleConfig } from 'src/app/common/Models/ag-models';

@Component({
  selector: 'app-quickid-config',
  templateUrl: './quickid-config.component.html',
  styleUrls: ['./quickid-config.component.scss'],
  encapsulation: ViewEncapsulation.None,
  providers: [QuickIdConfigBusiness, QuickIdConfigService]
})
export class QuickidConfigComponent implements OnInit {
//QUICKIDCONFIG
  toggleGroup: UntypedFormGroup;
  captions: any;
  $destroyed: ReplaySubject<any> = new ReplaySubject(1);
  quickIdConfigOrginialData: ConfigData[];
  quickIdConfigFormValue: ConfigData[];
  enableSave: boolean;
  isViewOnly:boolean=false;
  quickIdConfig = QuickIdConfigSetting;
  public dialog: MatDialog;
  allToggleInputs: AgToggleConfig;
  appointmentBookToggleInputs: AgToggleConfig;
  appointmentEditToggleInputs: AgToggleConfig;
  appointmentMoveToggleInputs: AgToggleConfig;
  appointmentCancelToggleInputs: AgToggleConfig;
  appointmentCopyToggleInputs: AgToggleConfig;
  appointmentReinstateToggleInputs: AgToggleConfig;
  therapistBreakCreateToggleInputs: AgToggleConfig;
  therapistBreakEditToggleInputs: AgToggleConfig;
  therapistBreakDeleteToggleInputs: AgToggleConfig;
  therapistBreakMoveToggleInputs: AgToggleConfig;
  retailtransactionsToggleInputs: AgToggleConfig;
  priceOverrideToggleInputs: AgToggleConfig;
  constructor(
      private formBuilder: UntypedFormBuilder
    , private localization: SpaLocalization,
      private breakPoint: BreakPointAccess
    , private quickidconfigBusiness:QuickIdConfigBusiness,
      ) {
    this.captions = this.localization.captions.settings.utilities.quickConfig;
  }

  ngOnInit() {
    this.breakPoint.CheckForAccess([SPAManagementBreakPoint.QUICKIDCONFIG], false);
      if (this.breakPoint.IsViewOnly(SPAManagementBreakPoint.QUICKIDCONFIG)) {
        this.isViewOnly = true;
      }
    this.toggleGroup = this.formBuilder.group({
      all: false,
      appointmentBook: false,
      appointmentEdit: false,
      appointmentMove: false,
      appointmentCancel: false,
      appointmentCopy: false,
      appointmentReinstate: false,
      therapistBreakCreate: false,
      therapistBreakEdit: false,
      therapistBreakDelete: false,
      therapistBreakMove: false,
      retailtransactions: false,
      priceOverride: false
    });
    this.formInitialize();
    this.toggleGroup.valueChanges.pipe(takeUntil(this.$destroyed)).subscribe(x => {
      if (this.toggleGroup.dirty)
        this.enableSave = this.toggleGroup.valid ? true : false;
    });
    this.initialLoad();
  }

  formInitialize(){
    this.allToggleInputs = {
      group: this.toggleGroup,
      formControlName: 'all',
      automationId:"'Tog_QuickId_all'"
    }
    this.appointmentBookToggleInputs = {
      group: this.toggleGroup,
      formControlName: 'appointmentBook',
      automationId:"'Tog_QuickId_appointmentBook'"
    }
    this.appointmentEditToggleInputs = {
      group: this.toggleGroup,
      formControlName: 'appointmentEdit',
      automationId:"'Tog_QuickId_appointmentEdit'"
    }
    this.appointmentMoveToggleInputs = {
      group: this.toggleGroup,
      formControlName: 'appointmentMove',
      automationId:"'Tog_QuickId_appointmentMove'"
    }
    this.appointmentCancelToggleInputs = {
      group: this.toggleGroup,
      formControlName: 'appointmentCancel',
      automationId:"'Tog_QuickId_appointmentCancel'"
    }
    this.appointmentCopyToggleInputs = {
      group: this.toggleGroup,
      formControlName: 'appointmentCopy',
      automationId:"'Tog_QuickId_appointmentCopy'"
    }
    this.appointmentReinstateToggleInputs = {
      group: this.toggleGroup,
      formControlName: 'appointmentReinstate',
      automationId:"'Tog_QuickId_appointmentReinstate'"
    }
    this.therapistBreakCreateToggleInputs = {
      group: this.toggleGroup,
      formControlName: 'therapistBreakCreate',
      automationId:"'Tog_QuickId_therapistBreakCreate'"
    }
    this.therapistBreakEditToggleInputs = {
      group: this.toggleGroup,
      formControlName: 'therapistBreakEdit',
      automationId:"'Tog_QuickId_therapistBreakEdit'"
    }
    this.therapistBreakDeleteToggleInputs = {
      group: this.toggleGroup,
      formControlName: 'therapistBreakDelete',
      automationId:"'Tog_QuickId_therapistBreakDelete'"
    }
    this.therapistBreakMoveToggleInputs = {
      group: this.toggleGroup,
      formControlName: 'therapistBreakMove',
      automationId:"'Tog_QuickId_therapistBreakMove'"
    }
    this.retailtransactionsToggleInputs = {
      group: this.toggleGroup,
      formControlName: 'retailtransactions',
      automationId:"'Tog_QuickId_retailtransactions'"
    }
    this.priceOverrideToggleInputs ={
      group: this.toggleGroup,
      formControlName: 'priceOverride',
      automationId:"'Tog_QuickId_priceOverride'"
    }
  }
  

  async initialLoad() {
    const quickIdConfigValues:ConfigData[] = await this.quickidconfigBusiness.GetQuickIdConfiguration();
    this.quickIdConfigOrginialData = _.cloneDeep(quickIdConfigValues);
    this.quickIdConfigFormValue = _.cloneDeep(quickIdConfigValues);
    this.patchInitialValues(quickIdConfigValues);
    this.enableSave  = false;   
    const QuickConfigSetting:any = await this.quickidconfigBusiness.GetSettingByModule();
    sessionStorage.setItem('QuickIdConfig',JSON.stringify(QuickConfigSetting[0]));
  }

  patchInitialValues(values: ConfigData[])
  {
    this.toggleGroup.patchValue(this.getPatchValue(values));
  }

  AllToggleChange(event) {
    this.quickIdConfigFormValue.filter(x => 
      !([QuickIdConfigSetting.all, QuickIdConfigSetting.retailtransactions, QuickIdConfigSetting.priceOverride] as string[]).includes(x.switch))
      .forEach(x=>{
        x.value = event.checked;
        return x;
      });
    this.toggleGroup.patchValue({
      appointmentBook: event.checked,
      appointmentEdit: event.checked,
      appointmentMove: event.checked,
      appointmentCancel: event.checked,
      appointmentCopy: event.checked,
      appointmentReinstate:event.checked,
      therapistBreakCreate: event.checked,
      therapistBreakEdit: event.checked,
      therapistBreakDelete: event.checked,
      therapistBreakMove: event.checked
    });
  }
  ToggleChange(event,action)
  {
    this.toggleGroup.patchValue({
      all:false
    });
    if(action == QuickIdConfigSetting.appointmentBook) {
      this.toggleGroup.patchValue({
        appointmentBook :event.checked
      });
      this.quickIdConfigFormValue.find(x=>x.switch === QuickIdConfigSetting.appointmentBook).value = event.checked;
    } else if(action == QuickIdConfigSetting.appointmentEdit) {
      this.toggleGroup.patchValue({
        appointmentEdit: event.checked
      });
      this.quickIdConfigFormValue.find(x=>x.switch === QuickIdConfigSetting.appointmentEdit).value = event.checked;
    } else if(action == QuickIdConfigSetting.appointmentMove) {
      this.toggleGroup.patchValue({
        appointmentMove:event.checked
      });
      this.quickIdConfigFormValue.find(x=>x.switch === QuickIdConfigSetting.appointmentMove).value = event.checked;
    } else if(action == QuickIdConfigSetting.appointmentCancel) {
      this.toggleGroup.patchValue({
        appointmentCancel:event.checked
      });
      this.quickIdConfigFormValue.find(x=>x.switch === QuickIdConfigSetting.appointmentCancel).value = event.checked;
    } else if(action == QuickIdConfigSetting.appointmentCopy) {
      this.toggleGroup.patchValue({
        appointmentCopy:event.checked
      });
      this.quickIdConfigFormValue.find(x=>x.switch === QuickIdConfigSetting.appointmentCopy).value = event.checked;
    } else if(action == QuickIdConfigSetting.therapistBreakCreate) {
      this.toggleGroup.patchValue({
        therapistBreakCreate:event.checked
      });
      this.quickIdConfigFormValue.find(x=>x.switch === QuickIdConfigSetting.therapistBreakCreate).value = event.checked;
    } else if(action == QuickIdConfigSetting.therapistBreakEdit) {
      this.toggleGroup.patchValue({
        therapistBreakEdit:event.checked
      });
      this.quickIdConfigFormValue.find(x=>x.switch === QuickIdConfigSetting.therapistBreakEdit).value = event.checked;
    } else if(action == QuickIdConfigSetting.therapistBreakDelete) {
      this.toggleGroup.patchValue({
        therapistBreakDelete:event.checked
      });
      this.quickIdConfigFormValue.find(x=>x.switch === QuickIdConfigSetting.therapistBreakDelete).value = event.checked;
    } else if(action == QuickIdConfigSetting.retailtransactions) {
      this.toggleGroup.patchValue({
        retailtransactions:event.checked
      });
      this.quickIdConfigFormValue.find(x=>x.switch === QuickIdConfigSetting.retailtransactions).value = event.checked;
    }
    else if(action == QuickIdConfigSetting.appointmentReinstate) {
      this.toggleGroup.patchValue({
        appointmentReinstate:event.checked
      });
      this.quickIdConfigFormValue.find(x=>x.switch === QuickIdConfigSetting.appointmentReinstate).value = event.checked;
    }
    else if(action == QuickIdConfigSetting.therapistBreakMove) {
      this.toggleGroup.patchValue({
        therapistBreakMove:event.checked
      });
      this.quickIdConfigFormValue.find(x=>x.switch === QuickIdConfigSetting.therapistBreakMove).value = event.checked;
    }
    else if (action == QuickIdConfigSetting.priceOverride) {
      this.toggleGroup.patchValue({
        priceOverride: event.checked
      });
      this.quickIdConfigFormValue.find(x => x.switch === QuickIdConfigSetting.priceOverride).value = event.checked;
    }
    if(this.quickIdConfigFormValue.filter(x => (x.value === true && x.switch !== QuickIdConfigSetting.retailtransactions && x.switch !== QuickIdConfigSetting.priceOverride)).length === this.quickIdConfigFormValue.length-1) {
        this.toggleGroup.patchValue({
          all:true
        });
    }
    console.log(this.toggleGroup)
  }

  async OnSave() {
    this.quickidconfigBusiness.UpdateQuickIdConfigSettings(this.quickIdConfigFormValue.map(x => {
      return {
        id: x.id,
        moduleId: x.moduleId,
        switch: x.switch,
        value: x.value
      } as ConfigData
    })).then(res=>{
      this.initialLoad();
      this.enableSave = false;
    });   
  }

  OnCancel() {
    this.patchInitialValues(this.quickIdConfigOrginialData);
  }

  ngOnDestroy(): void {
    this.$destroyed.next(true);
    this.$destroyed.complete();
  }

  getPatchValue(configData: ConfigData[]){    
      return {
        all: configData.filter(x => (x.value === true && x.switch !== QuickIdConfigSetting.retailtransactions)).length === configData.length-1, 
        appointmentBook: configData.find(x => x.switch === QuickIdConfigSetting.appointmentBook).value,
        appointmentEdit: configData.find(x => x.switch === QuickIdConfigSetting.appointmentEdit).value,
        appointmentMove: configData.find(x => x.switch === QuickIdConfigSetting.appointmentMove).value,
        appointmentCancel: configData.find(x => x.switch === QuickIdConfigSetting.appointmentCancel).value,
        appointmentCopy: configData.find(x => x.switch === QuickIdConfigSetting.appointmentCopy).value,
        appointmentReinstate: configData.find(x => x.switch === QuickIdConfigSetting.appointmentReinstate).value,
        therapistBreakCreate: configData.find(x => x.switch === QuickIdConfigSetting.therapistBreakCreate).value,
        therapistBreakEdit: configData.find(x => x.switch === QuickIdConfigSetting.therapistBreakEdit).value,
        therapistBreakDelete: configData.find(x => x.switch === QuickIdConfigSetting.therapistBreakDelete).value,
        therapistBreakMove: configData.find(x => x.switch === QuickIdConfigSetting.therapistBreakMove).value,
        retailtransactions: configData.find(x => x.switch === QuickIdConfigSetting.retailtransactions).value,
        priceOverride: configData.find(x => x.switch === QuickIdConfigSetting.priceOverride).value
      }
  }
}
