import { Injectable } from '@angular/core';
import { HttpMethod, HttpServiceCall } from '../../shared/shared/service/http-call.service';
import { Localization } from '../../localization/localization';
import { Host, Product } from '../../shared/shared/globalsContant';
import { CommonApiRoutes } from '../../common-route';
import { result } from 'lodash';
import { BaseResponse } from '../../shared/shared.modal';
import { ApiCall, ApiCall2, ChildUrl, Configuration, GateWay, MasterConfiguration, MasterconfigurationsSetup, Service, masterConfigDetails } from '../../config-validation/config-validation/config-validation.model';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { ConsoleLogger } from '@microsoft/signalr/dist/esm/Utils';

@Injectable({
  providedIn: 'root'
})
export class ConfigValidationService {


  constructor(private http:HttpServiceCall,private localization:Localization,private httpclient: HttpClient) { }

  public async GetMasterConfig() : Promise<any>{
    let response : any = await this.http.CallApiAsync<any>({
      host:Host.retailManagement,
      callDesc: "GetMasterConfigJson",
      method: HttpMethod.Get,
      uriParams: undefined,
      body: undefined
    });
    return response.result;
  }

  public async getValidationJson():Promise<any>{

    var host:Host=await this.GetHostURL('retail')
    console.log('getValidationJson-host',host)
    console.log('getValidationJson-product',product)

    var product=parseInt(this.localization.GetPropertyInfo('ProductId'));

    const apiResponse: BaseResponse<any[]> = await this.InvokeServiceCallAsync('ConfigurationToolConfigs', host, HttpMethod.Get,{productId:product});

    console.log('getValidationJson-result',apiResponse.result)
    return apiResponse.result;
  }

  public async getValidationJsonPMS():Promise<any>{

    var host:Host=await this.GetHostURL('retail')
    console.log('getValidationJsonPMS-host',host)
    console.log('getValidationJsonPMS-product',product)

    var product=parseInt(this.localization.GetPropertyInfo('ProductId'));

    const apiResponse: BaseResponse<any[]> = await this.InvokeServiceCallAsync('ConfigurationToolConfigs', host, HttpMethod.Get,{productId:product});

    console.log('getValidationJson-result',apiResponse.result)
    return apiResponse.result;
  }

  public async UrlUpCheck(payload:any):Promise<any>{

    var host:Host=await this.GetHostURL('retail')
    console.log('UrlUpCheck-host',host)

    const apiResponse: BaseResponse<any[]> = await this.InvokeServiceCallAsync('Config/urlvalidate', host, HttpMethod.Post,'',
      payload);

      
    console.log('UrlUpCheck-result',apiResponse)
    return apiResponse;
  }

  

  validateUrl(url: string): Observable<boolean> {
    return this.httpclient.get(url, { responseType: 'text' }).pipe(
      map(() => true),  // URL is accessible
      catchError((error: HttpErrorResponse) => {
        console.error(`URL validation failed: ${error.message}`);
        return of(false);  // URL is not accessible
      })
    );
  }


  public async getMasterConfigUrl():Promise<any>{
    let property = parseInt(this.localization.GetPropertyInfo('PropertyId'));
        let product = parseInt(this.localization.GetPropertyInfo('ProductId'));
    const apiResponse: BaseResponse<any[]> = await this.InvokeServiceCallAsync('PropertyFeature/GetPropertyFeatures/property/{propertyId}/product/{productId}', Host.commonGateway, HttpMethod.Get,{ propertyId: property, productId:product});

    // let URI = 'http://localhost:44335/VisualOne/RetailManagementService/Config/GetMasterConfigJson'
    // let result= await this.http.InvokeApiAsync<any>(URI, HttpMethod.Get)
    //.then(data => {
    //      return data;
    // }).catch(error =>{
    //   console.error('CommonGateway services is unreachable.',error)
    // }); 
    
    console.log('getMasterConfigUrl-result',apiResponse.result)
    return apiResponse.result;
  }

  public async getMasterConfigurationAPICall(urlConfiguration:MasterconfigurationsSetup):Promise<any>{
    let property = parseInt(this.localization.GetPropertyInfo('PropertyId'));
    let product = parseInt(this.localization.GetPropertyInfo('ProductId'));
    console.log('generic-getMasterConfigurationAPICall start ',urlConfiguration)
    
    var inputhost:Host= await this.GetHostURL(urlConfiguration.host)
    

    var inputMethod:HttpMethod =await this.GetApiMethod(urlConfiguration.method)
    
    
    var endpoint :string=urlConfiguration.endpoint;
    

    var parameters : string[] = this.GetParametersFromEndpoint(urlConfiguration.endpoint)
    
    var dynamicdata :any[] =[]
    const dynamicObject: { [key: string]: any } = {};

      for(let i=0 ; i<parameters.length;i++)
      {
        console.log('urlConfiguration.parameter.local',urlConfiguration.parameter.local);


          if(urlConfiguration.parameter.local.find(a=>a===parameters[i]))
          {
            
            var propbool:any= parameters[i].toLowerCase()==='propertyid'||'property'
            var prodbool:any= parameters[i].toLowerCase()==='productid'||'product'
            
            if(propbool==true)
              {
                property = parseInt(this.localization.GetPropertyInfo('PropertyId'));
                dynamicdata.push({key:parameters[i],value:property})
              }

              if(prodbool==true)
                {
                  product = parseInt(this.localization.GetPropertyInfo('ProductId'));
                  //dynamicdata.push({[parameters[i]]:product})
                  dynamicdata.push({key:parameters[i],value:product})
                }
              }
      }

      for (const entry of dynamicdata) {
        dynamicObject[entry.key] = entry.value;
    }
     
   
    console.log('generic-endpoint',endpoint);
    console.log('generic-getValidationJson-host',inputhost)
    console.log('generic-getValidationJson-inputMethod',inputMethod);
    //console.log('generic-parameters',parameters);
    console.log('dynamicdata',dynamicdata);
    console.log('dynamicObject',dynamicObject);


    const apiResponse: BaseResponse<any[]> = await this.InvokeServiceCallAsync(endpoint, inputhost, inputMethod,dynamicObject);

    console.log('getMasterConfigurationAPICall-result',apiResponse.result)
    return apiResponse.result;
  }


  public async getChildConfigurationApiCall(feature:any,inputFeature: Configuration ):Promise<any>{
    var childURLConfiguraiton:ChildUrl =inputFeature.configUrl[0].getCall[0].childUrl;

    console.log('generic-getChildConfigurationApiCall start ')
    console.log('feature',feature,'childURLConfiguraiton',childURLConfiguraiton)
//host
    var inputhost:Host= await this.GetHostURL(childURLConfiguraiton.host)
    
//method
    var inputMethod:HttpMethod =await this.GetApiMethod(childURLConfiguraiton.method)
    
    //endpoint
    var endpoint :string=childURLConfiguraiton.endpoint;
    var childfilterby=inputFeature.configValidate.find(a=>a.comparewithchildren);
    

    var parameters : string[] = this.GetParametersFromEndpoint(endpoint)
    
    let property = parseInt(this.localization.GetPropertyInfo('PropertyId'));
    let product = parseInt(this.localization.GetPropertyInfo('ProductId'));
    var dynamicdata :any[] =[]
    const dynamicObject: { [key: string]: any } = {};
    for(let i=0 ; i<parameters.length;i++)
      {
        
        console.log('urlConfiguration.parameter.local',childURLConfiguraiton.parameter.local);


          if(childURLConfiguraiton.parameter.local.find(a=>a===parameters[i]))
          {
            
            var propbool:any= parameters[i].toLowerCase()==='propertyid'||'property'
            var prodbool:any= parameters[i].toLowerCase()==='productid'||'product'
            
            if(propbool==true)
              {
                property = parseInt(this.localization.GetPropertyInfo('PropertyId'));
                dynamicdata.push({key:parameters[i],value:property})
              }

              if(prodbool==true)
                {
                  product = parseInt(this.localization.GetPropertyInfo('ProductId'));
                  //dynamicdata.push({[parameters[i]]:product})
                  dynamicdata.push({key:parameters[i],value:product})
                }
          }

          if(childURLConfiguraiton.parameter.fromMasterResponse.find(x=>x.Name===parameters[i]))
            {
              console.log('get parameter from master response',feature[childfilterby.comparewithchildren])

              dynamicdata.push({key:parameters[i],value:feature[childfilterby.comparewithchildren]})
              
            }
        }
        console.log('generic-getChildConfigurationApiCall-dynamicdata',dynamicdata);

        for (const entry of dynamicdata) {
          dynamicObject[entry.key] = entry.value;
         }

         console.log('generic-getChildConfigurationApiCall-endpoint',endpoint);
    console.log('generic-getChildConfigurationApiCall-getValidationJson-host',inputhost)
    console.log('generic-getChildConfigurationApiCall-getValidationJson-inputMethod',inputMethod);
    console.log('generic-getChildConfigurationApiCall-getValidationJson-parameters',parameters);
    console.log('generic-getChildConfigurationApiCall-getValidationJson-comparewith',childURLConfiguraiton.comparewithmaster);
    console.log('generic-getChildConfigurationApiCall-getValidationJson-dynamicObject',dynamicObject);

    

    //const apiResponse: any =  await this.InvokeServiceCallAsync('FeaturesConfiguration/GetFeaturesConfigurationsForDefaultValues/FeaturesConfigurationWithDefaultValues/{featureId}', Host.reporting, HttpMethod.Get,{ featureId: feature});

    const apiResponse: any =  await this.InvokeServiceCallAsync(endpoint, Host.reporting, inputMethod,dynamicObject);

    
    console.log('generic-getChildConfigurationApiCall-result',apiResponse.result)
    console.log('generic-getChildConfigurationApiCall end ')

    return apiResponse.result;
  }


  public async getChildConfigUrl(feature:any):Promise<any>{
    // let property = parseInt(this.localization.GetPropertyInfo('PropertyId'));
    //     let product = parseInt(this.localization.GetPropertyInfo('ProductId'));
    const apiResponse: any =  await this.InvokeServiceCallAsync('FeaturesConfiguration/GetFeaturesConfigurationsForDefaultValues/FeaturesConfigurationWithDefaultValues/{featureId}', Host.reporting, HttpMethod.Get,{ featureId: feature});

    // let URI = 'http://localhost:44335/VisualOne/RetailManagementService/Config/GetMasterConfigJson'
    // let result= await this.http.InvokeApiAsync<any>(URI, HttpMethod.Get)
    //.then(data => {
    //      return data;
    // }).catch(error =>{
    //   console.error('CommonGateway services is unreachable.',error)
    // }); 
    
    console.log('getChildConfigUrl-result',apiResponse.result)
    return apiResponse.result;
  }

///full retail configuration setup

public async getMasterUrlConfigurationAPICall(urlConfiguration:ApiCall):Promise<any>{
  let property = parseInt(this.localization.GetPropertyInfo('PropertyId'));
  let product = parseInt(this.localization.GetPropertyInfo('ProductId'));
  console.log('generic-getMasterUrlConfigurationAPICall start ',urlConfiguration)
  
  var inputhost:Host= await this.GetHostURL(urlConfiguration.host)
  

  var inputMethod:HttpMethod =await this.GetApiMethod(urlConfiguration.method)
  
  
  var endpoint :string=urlConfiguration.endpoint;
  
//param area
  var parameters : string[] = this.GetParametersFromEndpoint(urlConfiguration.endpoint)
  
  var dynamicdata :any[] =[]
  var dynamicQuerydata :any[] =[]

  const dynamicObject: { [key: string]: any } = {};

    for(let i=0 ; i<parameters.length;i++)
    {
      console.log('urlConfiguration.parameter.local',urlConfiguration.parameter.local);


        if(urlConfiguration.parameter.local.find(a=>a===parameters[i]))
        {
          
          var propbool:any= parameters[i].toLowerCase()==='propertyid'||'property'
          var prodbool:any= parameters[i].toLowerCase()==='productid'||'product'
          
          if(propbool==true)
            {
              property = parseInt(this.localization.GetPropertyInfo('PropertyId'));
              dynamicdata.push({key:parameters[i],value:property})
            }

            if(prodbool==true)
              {
                product = parseInt(this.localization.GetPropertyInfo('ProductId'));
                //dynamicdata.push({[parameters[i]]:product})
                dynamicdata.push({key:parameters[i],value:product})
              }
            }
    }

    for (const entry of dynamicdata) {
      dynamicObject[entry.key] = entry.value;
  }
   
 
  console.log('generic-endpoint',endpoint);
  console.log('generic-getValidationJson-host',inputhost)
  console.log('generic-getValidationJson-inputMethod',inputMethod);
  //console.log('generic-parameters',parameters);
  console.log('dynamicdata',dynamicdata);
  console.log('dynamicObject',dynamicObject);


  const apiResponse: BaseResponse<any[]> = await this.InvokeServiceCallAsync(endpoint, inputhost, inputMethod,dynamicObject);

  console.log('getMasterConfigurationAPICall-result',apiResponse.result)
  return apiResponse.result;
}


public async getChildUrlConfigurationApiCall(feature:any,inputFeature: ApiCall2 ):Promise<any>{
  
var resu:any;
  console.log('generic-getChildUrlConfigurationApiCall start ')
  console.log('feature',feature,'childURLConfiguraiton',inputFeature,'inputFeature.host',inputFeature.host)
//host
var hostadd=environment['commonV1Gateway'];
console.log('hostadd',hostadd)
  var inputhost:Host= await this.GetHostURL(inputFeature.host)
  
//method
  var inputMethod:HttpMethod =await this.GetApiMethod(inputFeature.method)
  
  //endpoint
  var endpoint :string=inputFeature.endpoint;
  //var childfilterby=inputFeature.childApiDataFilterby.find(a=>a.comparewithchildren);
  
  //let uriParam = getRouteUrl();
  var parameters : string[] = this.GetParametersFromEndpoint(endpoint);

  
  
  let property = parseInt(this.localization.GetPropertyInfo('PropertyId'));
  let product = parseInt(this.localization.GetPropertyInfo('ProductId'));
  let tenant=parseInt(this.localization.GetPropertyInfo('TenantId'));
  var dynamicdata :any[] =[]
  let dynamicObject: { [key: string]: any } = {};
  for(let i=0 ; i<parameters.length;i++)
    {
      
      console.log('urlConfiguration.parameter.local',inputFeature.parameter.local);


        if(inputFeature.parameter.local.find(a=>a===parameters[i]))
        {
          
          var propbool:any= parameters[i].toLowerCase()==='propertyid'||'property'
          var prodbool:any= parameters[i].toLowerCase()==='productid'||'product'
          
          if(propbool==true)
            {
              property = parseInt(this.localization.GetPropertyInfo('PropertyId'));
              dynamicdata.push({key:parameters[i],value:property})
            }

            if(prodbool==true)
              {
                product = parseInt(this.localization.GetPropertyInfo('ProductId'));
                //dynamicdata.push({[parameters[i]]:product})
                dynamicdata.push({key:parameters[i],value:product})
              }
        }

        if(inputFeature.parameter.fromMasterResponse.find(x=>x.Name===parameters[i]))
          {
            console.log('get parameter from master response',feature[inputFeature.parameter.fromMasterResponse.find(x=>x.Name===parameters[i]).responsefrom])

            dynamicdata.push({key:parameters[i],value:feature[inputFeature.parameter.fromMasterResponse.find(x=>x.Name===parameters[i]).responsefrom]})
            
          }
      }
      console.log('generic-getChildConfigurationApiCall-dynamicdata',dynamicdata);

      for (const entry of dynamicdata) {
        dynamicObject[entry.key] = entry.value;
       }

       console.log('generic-getChildUrlConfigurationApiCall-endpoint',endpoint);
  console.log('generic-getChildUrlConfigurationApiCall-getValidationJson-host',inputhost)
  console.log('generic-getChildUrlConfigurationApiCall-getValidationJson-inputMethod',inputMethod);
  console.log('generic-getChildUrlConfigurationApiCall-getValidationJson-parameters',parameters);
  //console.log('generic-getChildConfigurationApiCall-getValidationJson-comparewith',childURLConfiguraiton.comparewithmaster);
  console.log('generic-getChildUrlConfigurationApiCall-getValidationJson-dynamicObject',dynamicObject);

  if(parameters.length===0)
  {
    dynamicObject=null;
  }
//payload formation
var payloadDynamicdata :any[] =[]
let payloadDynamicObject: { [key: string]: any } = {};


  if(inputMethod===HttpMethod.Post)
  {
    console.log('payload formation start')
    console.log('payload',inputFeature.postPayload
    )

    if(inputFeature.postPayload[0].type=='object')
    {
      //get paramlist
      console.log('keyValueDetails',inputFeature.postPayload[0].KeyValueDetails)
      let paramlist:any[]=this.PostParameters(inputFeature.postPayload[0].KeyValueDetails)
      console.log('paramlist',paramlist)
      // for(let kvd=0;kvd<inputFeature.postPayload[0].KeyValueDetails.length;kvd++)
      // {
      //   console.log('inputFeature.postPayload[0].KeyValueDetails.kvd',inputFeature.postPayload[0].KeyValueDetails[kvd])
      // }
      var postDynamicdata :any[] =[]
      let postDynamicObject: { [key: string]: any } = {};



      for(let i=0 ; i<paramlist.length;i++)
      {
          if(inputFeature.postPayload[0].parameter.local.find(a=>a===paramlist[i]))
          {
            
              var tenBool:any= paramlist[i].toLowerCase()==='@tenantid'||'@tenant'

              if(tenBool==true)
              {

                tenant = parseInt(this.localization.GetPropertyInfo('TenantId'));
                //dynamicdata.push({[parameters[i]]:product})
                postDynamicdata.push({key:paramlist[i],value:tenant})

              }
   
          }

      }

        for (const entry of postDynamicdata) {
          postDynamicObject[entry.key] = entry.value;
         }

         console.log('postDynamicObject',postDynamicObject)

       //payload formation
       
       for(let kvd=0;kvd<inputFeature.postPayload[0].KeyValueDetails.length;kvd++)
        {

          let splitparam:any[]=inputFeature.postPayload[0].KeyValueDetails[kvd].split("-")
          if(splitparam[1].startsWith('@'))
          {
            
            if(inputFeature.postPayload[0].parameter.local.find(a=>a===splitparam[1]))
            {
              var valueresult=postDynamicObject[splitparam[1]]
              payloadDynamicdata.push({key:splitparam[0],value:valueresult})
            }
             //result.push(splitparam[1]);
           } 
           else
           {
            payloadDynamicdata.push({key:splitparam[0],value:splitparam[1]})
           }


  
          console.log('inputFeature.postPayload[0].KeyValueDetails.kvd',inputFeature.postPayload[0].KeyValueDetails[kvd])
        }
        console.log('payloadDynamicdata',payloadDynamicdata)
        for (const entry of payloadDynamicdata) {
          payloadDynamicObject[entry.key] = entry.value;
         }

         console.log('payloadDynamicObject',payloadDynamicObject)


    }

    
    console.log('payload formation end')

  }

  // //const apiResponse: any =  await this.InvokeServiceCallAsync('FeaturesConfiguration/GetFeaturesConfigurationsForDefaultValues/FeaturesConfigurationWithDefaultValues/{featureId}', Host.reporting, HttpMethod.Get,{ featureId: feature});
  
  //const apiResponse: any =  await this.InvokeServiceCallAsync(endpoint, Host.reporting, inputMethod,dynamicObject);
  const apiResponse: any =  await this.InvokeServiceCallAsync(endpoint,inputhost, inputMethod,dynamicObject,payloadDynamicObject);

  
  // console.log('generic-getChildConfigurationApiCall-result',apiResponse.result)
  // console.log('generic-getChildConfigurationApiCall end ')

  return apiResponse.result;
  //return resu;
}


public async getChildUrlConfigurationApiCallPMS(feature:any,inputFeature: ApiCall2 ):Promise<any>{
  
  var resu:any;
    console.log('generic-getChildUrlConfigurationApiCall start ')
    console.log('feature',feature,'childURLConfiguraiton',inputFeature,'inputFeature.host',inputFeature.host)
  //host
  var hostadd=environment['commonV1Gateway'];
  console.log('hostadd',hostadd)
    var inputhost:Host= await this.GetHostURL(inputFeature.host)
    
  //method
    var inputMethod:HttpMethod =await this.GetApiMethod(inputFeature.method)
    
    //endpoint
    var endpoint :string=inputFeature.endpoint;
    //var childfilterby=inputFeature.childApiDataFilterby.find(a=>a.comparewithchildren);
    
  
    var parameters : string[] = this.GetParametersFromEndpoint(endpoint);
  
    var queryparameters : string[] = this.GetQueryParamFromEndpoint(endpoint);
    var dynamicQuerydata :any[] =[]
    let dynamicqueryObject: { [key: string]: any } = {};

    
    let property = parseInt(this.localization.GetPropertyInfo('PropertyId'));
    let product = parseInt(this.localization.GetPropertyInfo('ProductId'));
    let tenant=parseInt(this.localization.GetPropertyInfo('TenantId'));
    var dynamicdata :any[] =[]
    let dynamicObject: { [key: string]: any } = {};
    var queryvalue=''
    var querykey=''


    if(inputFeature.parameter.fromMasterResponse[0].queryName!=null) 
      {
        console.log('inputFeature.parameter.fromMasterResponse[0].queryName',inputFeature.parameter.fromMasterResponse[0].queryName)
        console.log('queryparameters',queryparameters)

        for(let p=0 ; p<queryparameters.length;p++)

        if(inputFeature.parameter.fromMasterResponse.find(x=>x.queryName===queryparameters[p]))
          {
            console.log('------------------------------------')
            console.log('queryparameters[p]',queryparameters[p],'queryName',feature[inputFeature.parameter.fromMasterResponse.find(x=>x.queryName===queryparameters[p]).responsefrom])
            //dynamicdata.push({key:queryparameters[p],value:feature[inputFeature.parameter.fromMasterResponse.find(x=>x.queryName===queryparameters[p]).responsefrom]})

            dynamicQuerydata.push({key:queryparameters[p],value:feature[inputFeature.parameter.fromMasterResponse.find(x=>x.queryName===queryparameters[p]).responsefrom]})
            console.log('queryName-dynamicdata',dynamicdata)
            //dynamicQuerydata.push(feature[inputFeature.parameter.fromMasterResponse.find(x=>x.queryName===queryparameters[p]).responsefrom])
          }

      } 
      
      for (const entry of dynamicQuerydata) {
        console.log('entry.key',entry.key)
        dynamicqueryObject[entry.key] = entry.value;
         queryvalue= entry.value
         querykey= entry.key
       
       }
    


    for(let i=0 ; i<parameters.length;i++)
      {
        
        console.log('urlConfiguration.parameter.local',inputFeature.parameter.local);
  
  
          if(inputFeature.parameter.local.find(a=>a===parameters[i]))
          {
            
            var propbool:any= parameters[i].toLowerCase()==='propertyid'||'property'
            var prodbool:any= parameters[i].toLowerCase()==='productid'||'product'
            
            if(propbool==true)
              {
                property = parseInt(this.localization.GetPropertyInfo('PropertyId'));
                dynamicdata.push({key:parameters[i],value:property})
              }
  
              if(prodbool==true)
                {
                  product = parseInt(this.localization.GetPropertyInfo('ProductId'));
                  //dynamicdata.push({[parameters[i]]:product})
                  dynamicdata.push({key:parameters[i],value:product})
                }
          }
  
          if(inputFeature.parameter.fromMasterResponse.find(x=>x.Name===parameters[i]))
            {
              console.log('get parameter from master response',feature[inputFeature.parameter.fromMasterResponse.find(x=>x.Name===parameters[i]).responsefrom])
  
              dynamicdata.push({key:parameters[i],value:feature[inputFeature.parameter.fromMasterResponse.find(x=>x.Name===parameters[i]).responsefrom]})
              
            }

         
        }
        console.log('generic-getChildConfigurationApiCall-dynamicdata',dynamicdata);
  
        for (const entry of dynamicdata) {
          dynamicObject[entry.key] = entry.value;
         }
        console.log('querykey-queryvalue',querykey,queryvalue)
         console.log('generic-getChildUrlConfigurationApiCall-endpoint',endpoint);
    console.log('generic-getChildUrlConfigurationApiCall-getValidationJson-host',inputhost)
    console.log('generic-getChildUrlConfigurationApiCall-getValidationJson-inputMethod',inputMethod);
    console.log('generic-getChildUrlConfigurationApiCall-getValidationJson-parameters',parameters);
    //console.log('generic-getChildConfigurationApiCall-getValidationJson-comparewith',childURLConfiguraiton.comparewithmaster);
    console.log('generic-getChildUrlConfigurationApiCall-getValidationJson-dynamicObject',dynamicObject);
    console.log('generic-getChildUrlConfigurationApiCall-getValidationJson-dynamicqueryObject',dynamicqueryObject);

    if(parameters.length===0)
    {
      dynamicObject=null;
    }
  //payload formation
  var payloadDynamicdata :any[] =[]
  let payloadDynamicObject: { [key: string]: any } = {};
  
  
    if(inputMethod===HttpMethod.Post)
    {
      console.log('payload formation start')
      console.log('payload',inputFeature.postPayload
      )
  
      if(inputFeature.postPayload[0].type=='object')
      {
        //get paramlist
        console.log('keyValueDetails',inputFeature.postPayload[0].KeyValueDetails)
        let paramlist:any[]=this.PostParameters(inputFeature.postPayload[0].KeyValueDetails)
        console.log('paramlist',paramlist)
        // for(let kvd=0;kvd<inputFeature.postPayload[0].KeyValueDetails.length;kvd++)
        // {
        //   console.log('inputFeature.postPayload[0].KeyValueDetails.kvd',inputFeature.postPayload[0].KeyValueDetails[kvd])
        // }
        var postDynamicdata :any[] =[]
        let postDynamicObject: { [key: string]: any } = {};
  
  
  
        for(let i=0 ; i<paramlist.length;i++)
        {
            if(inputFeature.postPayload[0].parameter.local.find(a=>a===paramlist[i]))
            {
              
                var tenBool:any= paramlist[i].toLowerCase()==='@tenantid'||'@tenant'
  
                if(tenBool==true)
                {
  
                  tenant = parseInt(this.localization.GetPropertyInfo('TenantId'));
                  //dynamicdata.push({[parameters[i]]:product})
                  postDynamicdata.push({key:paramlist[i],value:tenant})
  
                }
     
            }
  
        }
  
          for (const entry of postDynamicdata) {
            postDynamicObject[entry.key] = entry.value;
           }
  
           console.log('postDynamicObject',postDynamicObject)
  
         //payload formation
         
         for(let kvd=0;kvd<inputFeature.postPayload[0].KeyValueDetails.length;kvd++)
          {
  
            let splitparam:any[]=inputFeature.postPayload[0].KeyValueDetails[kvd].split("-")
            if(splitparam[1].startsWith('@'))
            {
              
              if(inputFeature.postPayload[0].parameter.local.find(a=>a===splitparam[1]))
              {
                var valueresult=postDynamicObject[splitparam[1]]
                payloadDynamicdata.push({key:splitparam[0],value:valueresult})
              }
               //result.push(splitparam[1]);
             } 
             else
             {
              payloadDynamicdata.push({key:splitparam[0],value:splitparam[1]})
             }
  
  
    
            console.log('inputFeature.postPayload[0].KeyValueDetails.kvd',inputFeature.postPayload[0].KeyValueDetails[kvd])
          }
          console.log('payloadDynamicdata',payloadDynamicdata)
          for (const entry of payloadDynamicdata) {
            payloadDynamicObject[entry.key] = entry.value;
           }
  
           console.log('payloadDynamicObject',payloadDynamicObject)
  
  
      }
  
      
      console.log('payload formation end')
  
    }
  
    // //const apiResponse: any =  await this.InvokeServiceCallAsync('FeaturesConfiguration/GetFeaturesConfigurationsForDefaultValues/FeaturesConfigurationWithDefaultValues/{featureId}', Host.reporting, HttpMethod.Get,{ featureId: feature});
  
    //const apiResponse: any =  await this.InvokeServiceCallAsync(endpoint, Host.reporting, inputMethod,dynamicObject);
 // await this.InvokeServiceCallAsync(endpoint,inputhost, inputMethod,dynamicObject,payloadDynamicObject,dynamicqueryObject);
    // if(dynamicQuerydata?.length==1)
    //   {
    //     console.log('lkkwlkf dkdkqdq') 
    //     apiResponse=  await this.InvokeServiceCallAsync(endpoint,inputhost, inputMethod,null,null,'ghj');

    //   }
      ////else{
        if(dynamicQuerydata?.length==1)
          {
            const queryParams = {
              ContextKey:queryvalue
            }
            dynamicObject=queryParams

          }
        
        let apiResponse: any  =  await this.InvokeServiceCallAsync(endpoint,inputhost, inputMethod,dynamicObject,payloadDynamicObject);

      //}
    
    // console.log('generic-getChildConfigurationApiCall-result',apiResponse.result)
    // console.log('generic-getChildConfigurationApiCall end ')
  
    return apiResponse.result;
    //return resu;
  }


public async GateWayHeartBeatCalls(gateWay:GateWay ):Promise<boolean>{
 var gateHBresult:boolean=true;
  var host:Host=await this.GetHostURL(gateWay.host)
    console.log('GateWayHeartBeatCalls-host',host)

    //  var response=await this.InvokeServiceCallAsync(gateWay.route, host, HttpMethod.Get);
     
    //  console.log('response',response)
    
    await  this.InvokeServiceCallAsync(gateWay.route, host, HttpMethod.Get).then(success => {
      console.log('success.result',success.result)

      gateHBresult=true 
  }).catch(error =>{
    gateHBresult=true
  }); 

    //const apiResponse: BaseResponse<any[]> = await this.InvokeServiceCallAsync(gateWay.route, host, HttpMethod.Get);

    //console.log('GateWayHeartBeatCalls-result',apiResponse.result,apiResponse)
    return gateHBresult;

}  


public async ServiceHeartBeatCalls(service:Service ):Promise<boolean>{
  var servieHBresult=false;
  if(service.needNotcheck)
  {
    servieHBresult=true
  }else
  {
        var host:Host=await this.GetHostURL(service.serviceHost)
        console.log('ServiceHeartBeatCalls-host',host)
        
        await this.InvokeServiceCallAsync(service.serviceRoute, host, HttpMethod.Get).then(success => {
        console.log('success.result',success.result)
        servieHBresult=true 
    }).catch(error =>{
      servieHBresult=true
    }); 
  }
   
 
     //const apiResponse: BaseResponse<any[]> = await this.InvokeServiceCallAsync(gateWay.route, host, HttpMethod.Get);
 
     //console.log('GateWayHeartBeatCalls-result',apiResponse.result,apiResponse)
     return servieHBresult;
 
 }  
///////////end

 async InvokeServiceCall<T>(callDesc: string, callType: HttpMethod, host: Host, body?: any, uRIParams?: any): Promise<T> {
  const response: BaseResponse<T> = await this.http.CallApiAsync<T>({
      callDesc,
      host,
      method: callType,
      body,
      uriParams: uRIParams
  });

  console.log('response-gatecal',response)
  // if (!response.successStatus) {
      
  // }
  return response.result;
 }


  

  async InvokeServiceCallAsync(route: string, domain: Host, callType: HttpMethod, uriParams?: any, body?: any,queryString?:any): Promise<BaseResponse<any>> {
    console.log('InvokeServiceCallAsync queryString',queryString)
    const result: BaseResponse<any> = await this.http.CallApiAsync({
      host: domain,
      callDesc: route,
      method: callType,
      body:body,
      uriParams:uriParams,
      queryString: queryString
    });
    console.log('InvokeServiceCallAsync-result',result)
    return result;
  }

  public async checkCommonGatewayHeartBeat():Promise<any>{
    let URI = Host.commonGateway+'/'+ CommonApiRoutes.GetHeartBeat
    await this.http.InvokeApiAsync<any>(URI, HttpMethod.Get).then(success => {
         return success = this.successCallBack.bind(this)
    }).catch(error =>{
      console.error('CommonGateway services is unreachable.',error)
    }); 
  }

  public async checkHeartBeat(): Promise<any> {
    let productId = parseInt(this.localization.GetPropertyInfo('ProductId'));
    switch (productId) {
      case Product.RETAIL:
          let retailURI = Host.retailManagement + '/'+ CommonApiRoutes.GetHeartBeat
          await this.http.InvokeApiAsync<any>(retailURI, HttpMethod.Get).then(success => {
            return success = this.retailServicesPOS.bind(this)
          }).catch(error =>{
            console.error('RetailGateway services is unreachable.',error)
          }); 
        break;

      case Product.PMS:
        let pmsURI = Host.retailManagement + '/'+ CommonApiRoutes.GetHeartBeat
        await this.http.InvokeApiAsync<any>(pmsURI, HttpMethod.Get).then(success => {
          return success = this.retailServicesPOS.bind(this)
        }).catch(error =>{
          console.error('RetailGateway services is unreachable.',error)
        }); 
        break;

      case Product.GOLF:
        let golfURI = Host.golfManagement + '/'+ CommonApiRoutes.GetHeartBeat
        await this.http.InvokeApiAsync<any>(golfURI, HttpMethod.Get).then(success => {
          return success = this.golfserviceGS.bind(this)
        }).catch(error =>{
          console.error('RetailGateway services is unreachable.',error)
        }); 
        break;       
       
      case Product.SPA:
        let spaURI = Host.spaManagement + '/'+ CommonApiRoutes.GetHeartBeat
        await this.http.InvokeApiAsync<any>(spaURI, HttpMethod.Get).then(success => {
          return success = this.spaServices.bind(this)
        }).catch(error =>{
          console.error('RetailGateway services is unreachable.',error)
        }); 
        break;
      default:
        return null;
        break;
    }
  }

  successCallBack(result:string):any   {
    let URI = Host.authentication+'/'+  CommonApiRoutes.GetTenantManagementServiceHeartBeat+'/' + CommonApiRoutes.GetHeartBeat
    this.http.InvokeApiAsync<any>(URI, HttpMethod.Get).then(success => {
         return success = this.checkHeartBeat.bind(this)
    }).catch(error =>{
      console.error('TenantManagement services is unreachable.',error)
    }); 

  }


  retailServicesPOS(result:string):any   {
    let URI = Host.retailPOS+'/'+  CommonApiRoutes.GetPointOfSaleServiceHeartBeat+'/' + CommonApiRoutes.GetHeartBeat
    this.http.InvokeApiAsync<any>(URI, HttpMethod.Get).then(success => {
         return success = this.retailServicesRM.bind(this)
    }).catch(error =>{
      console.error('PointofSale services is unreachable.',error)
    }); 

  }

  retailServicesRM(result:string):any   {
    let URI = Host.retailManagement+'/'+  CommonApiRoutes.GetRetailManagementHeartBeat+'/' + CommonApiRoutes.GetHeartBeat
    this.http.InvokeApiAsync<any>(URI, HttpMethod.Get).then(success => {
         return success = this.retailServicesPayment.bind(this)
    }).catch(error =>{
      console.error('RetailManagement services is unreachable.',error)
    }); 

  }

  retailServicesPayment(result:string):any   {
    let URI = Host.payment+'/'+  CommonApiRoutes.GetPaymentServiceHeartBeat+'/' + CommonApiRoutes.GetHeartBeat
    this.http.InvokeApiAsync<any>(URI, HttpMethod.Get).then(success => {
          return success; 
    }).catch(error =>{
      console.error('Payment services is unreachable.',error)
    }); 

  }
  golfserviceGS(result:string):any   {
    let URI = Host.golfSchedule+'/'+  CommonApiRoutes.GetGolfScheduleServiceHeartBeat+'/' + CommonApiRoutes.GetHeartBeat
    this.http.InvokeApiAsync<any>(URI, HttpMethod.Get).then(success => {
         return success = this.golfserviceGM.bind(this)
    }).catch(error =>{
      console.error('Golf Schedule services is unreachable.',error)
    }); 

  }
  golfserviceGM(result:string):any   {
    let URI = Host.golfManagement+'/'+  CommonApiRoutes.GetGolfManagementServiceHeartBeat+'/' + CommonApiRoutes.GetHeartBeat
    this.http.InvokeApiAsync<any>(URI, HttpMethod.Get).then(success => {
             return success 
    }).catch(error =>{
      console.error('Golf Management services is unreachable.',error)
    }); 

  }
  spaServices(result:string):any   {
    let URI = Host.spaManagement+'/'+  CommonApiRoutes.GetSpaServicesHeartBeat+'/' + CommonApiRoutes.GetHeartBeat
    this.http.InvokeApiAsync<any>(URI, HttpMethod.Get).then(success => {
             return success 
    }).catch(error =>{
      console.error('SPA services is unreachable.',error)
    }); 

  }

  public async getDataFromConfigUrl(host:string,urlKey:string,uriParams:any):Promise<any>{
    var endHost;
    switch(host){
      case "Tenant":
        endHost = Host.authentication;
        break;
      case "Retail":
        endHost = Host.retailManagement;
        break;
      case "POS":
        endHost = Host.retailPOS;
        break;
      case "Payment":
        endHost = Host.payment;
        break;
      case "GolfManagement":
        endHost = Host.golfManagement;
        break;
      case "GolfSchedule":
        endHost = Host.golfSchedule;
        break;
      case "Spa":
        endHost = Host.spaManagement;
        break;
      default:
        break;
    }
    let response : any = await this.http.CallApiAsync<any>({
      host:endHost,
      callDesc: urlKey,
      method: HttpMethod.Get,
      uriParams: uriParams != undefined ? uriParams : undefined,
      body: undefined
    });
    return response.result;
  }

  public async GetHostURL(host:any):Promise<Host>
  {

    var endHost:any;
    switch(host.toLowerCase()){
      case "tenant":
        endHost = Host.authentication;
        break;
        case "authentication":
        endHost = Host.authentication;
        break;
        case "common":
        endHost = Host.commonGateway;
        break;
      case "retail":
        endHost = Host.retailManagement;
        break;
      case "pos":
        endHost = Host.retailPOS;
        break;
      case "payment":
        endHost = Host.payment;
        break;
      case "golfmanagement":
        endHost = Host.golfManagement;
        break;
      case "golfschedule":
        endHost = Host.golfSchedule;
        break;
      case "spa":
        endHost = Host.spaManagement;
        break;
         case "domain":
          endHost=Host.domain;
          break;   
      default:
        break;
    }

    return endHost;
    

  }

  public async GetApiMethod(apiMethod :any):Promise<HttpMethod>
  {

    var apiMethodResult:any;
    switch(apiMethod.toLowerCase()){
      case "get":
        apiMethodResult =HttpMethod.Get;
        break;
      case "post":
        apiMethodResult = HttpMethod.Post;
        break;
      case "put":
        apiMethodResult = HttpMethod.Put;
        break;
      case "delete":
        apiMethodResult = HttpMethod.Delete;
        break;
      default:
        break;
    }

    return apiMethodResult;
    

  }

  public GetQueryParamFromEndpoint(endpoint:string):string[]
  {
    console.log('GetQueryParamFromEndpoint start')
    var allparamsPMS : string[]=[]
    allparamsPMS=endpoint.split("/")
    console.log('params',allparamsPMS)
    

var queryparams : string[]=[]
    for(let i=0;i<allparamsPMS.length;i++)
      {
        let str = allparamsPMS[i];
          let char = "?";
      let index = this.findIndex(str, char);
        if(index>0)
          {
            console.log('allparamsPMS[i]',allparamsPMS[i])
            var querysplit: string[]=allparamsPMS[i].split("?")
            console.log('querysplit',querysplit)
            var queryequalsplit: string[]=querysplit[1].split("=")
            console.log('queryequalsplit',queryequalsplit)
           if(queryequalsplit[1].startsWith('{'))
            {
             var result = queryequalsplit[1].substring(1, queryequalsplit[1].length-1);
            queryparams.push(result)
            }
          }
      }
   

    console.log('queryparams',queryparams)


    console.log('GetQueryParamFromEndpoint end')
    return queryparams;

  }

  public findIndex(str:string, char:any) {
    for (let i = 0; i < str.length; i++) {
        if (str[i] === char) {
            return i;
        }
    }
    return -1; // Character not found
}



  public  GetParametersFromEndpoint(endpoint:string):  string[]
  {
    console.log('GetParametersFromEndpoint-start');
    var allparams : string[]=[]
    allparams=endpoint.split("/")
    console.log('params',allparams)

    var params : string[]=[]
    for(let i=0;i<allparams.length;i++)
      {
        if(allparams[i].startsWith('{'))
          {
            var result = allparams[i].substring(1, allparams[i].length-1);
            params.push(result)
          }
      }
   

    console.log('params',params)

    console.log('GetParametersFromEndpoint-end');

    return params;



  }
  
  public PostParameters(KeyValueDetails:string[]):any[]
  {
    let result:any[]=[]

    for(let kvd=0;kvd<KeyValueDetails.length;kvd++)
      {
        let splitparam:any[]=KeyValueDetails[kvd].split("-")
        if(splitparam[1].startsWith('@'))
         {
          result.push(splitparam[1]);
         } 

        console.log('inputFeature.postPayload[0].KeyValueDetails.kvd',KeyValueDetails[kvd])
      }


    return result
  }
  
}