import { Component, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { AgDateConfig, ButtonValue, ServerPaginationConfiguration, TableHeaderOptions, TableOptions } from 'src/app/common/Models/ag-models';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { Localization } from 'src/app/common/localization/localization';
import { stagingStatus, stagingStatusDesc, InventoryStagingFilter, StagingFilterType, InventoryScannerUIRequestModel, Users, InventoryStagingPostType, RetailScreen } from './inventory-staging.modal';
import { FromTypeEnum } from 'src/app/common/components/cdkvirtual/cdkvirtual.model';
import { AlertAction, AlertType, ButtonType } from 'src/app/common/enums/shared-enums';
import { CommonUtilities } from 'src/app/common/shared/shared/utilities/common-utilities';
import { InventoryStagingBusiness } from './inventory-staging.business';
import { RetailPropertyInformation } from '../common/services/retail-property-information.service';
import { MatDialog } from '@angular/material/dialog';
import { RetailPopupComponent } from '../retail-popup/retail-popup.component';
import { RetailUtilities } from '../shared/utilities/retail-utilities';
import { Subject } from 'rxjs';
import { SEARCH_DEBOUNCE_TIME } from 'src/app/common/shared/shared/setupConstants';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { BreakPointResult } from '../shared/shared.modal';
import { BreakPointAccess } from '../shared/service/breakpoint.service';
import { InventoryBreakPoint } from '../shared/globalsContant';
import { AlertPopupComponent } from 'src/app/common/components/alert-popup/alert-popup.component';
import { RetailLocalization } from '../common/localization/retail-localization';

@Component({
  selector: 'app-inventory-staging',
  templateUrl: './inventory-staging.component.html',
  styleUrls: ['./inventory-staging.component.scss'],
  providers: [InventoryStagingBusiness],
  encapsulation: ViewEncapsulation.None
})
export class InventoryStagingComponent implements OnInit {
  @ViewChild('cdkcomponent', { static: true }) cdkComponent;
  inventoryDateInput: AgDateConfig;
  inventoryStagingForm: UntypedFormGroup;
  captions: any;
  inventoryStagingCaptions:any;
  stagingStatus: stagingStatus[] = [];
  transferInventoryButton: ButtonValue;
  refreshButton: ButtonValue;
  postSaveButton: ButtonValue;
  saveButton: ButtonValue;
  cancelButton: ButtonValue;
  IsEditModeEnabledFlag = false;
  headerOptions: TableHeaderOptions[];
  tableContent: any = [];
  initialTableContent: any = [];
  options: TableOptions;
  IsViewOnly: boolean = false;
  searchText: string = '';
  selectedStatus: any = [];
  serverPageConfig: ServerPaginationConfiguration;
  totalRecordsCount: number = 5;
  stagingStatusDesc = stagingStatusDesc;
  selectedTabledata: any = [];
  pageSize = 20;
  currentPage = 1;
  searchTextChanged = new Subject<string>();
  outlets: Promise<{ id: number; name: string; }[]>;
  users: Users[];
  isPostingInProgress: boolean = false;
  isViewOnlyAccess: boolean = false;
  isAccessAllowed: boolean = false;
  userAccess: BreakPointResult;
  inventorySetting: any;
  isPageInvalid: boolean = false;

  get isRowSelected() {
    return this.selectedTabledata?.length ? this.selectedTabledata.length > 0 : false;
  }

  constructor(private localization: Localization, private fb: UntypedFormBuilder,
    private utils: CommonUtilities, private business: InventoryStagingBusiness,
    private propertyInfo: RetailPropertyInformation, private dialog: MatDialog, private retailUtils: RetailUtilities
    , private breakpoint: BreakPointAccess, private retailLocalization: RetailLocalization

  ) {
    this.captions = this.localization.captions;
    this.inventoryStagingCaptions = this.retailLocalization.captions.inventoryStaging;
  }

  ngOnInit(): void {
    this.initialize();
    this.generateTable();
  }

  async initialize() {
    this.isAccessAllowed = this.breakpoint.CheckForAccess([InventoryBreakPoint.InventoryStaging]); 
    this.isViewOnlyAccess = this.breakpoint.IsViewOnly(InventoryBreakPoint.InventoryStaging); 
    this.IsViewOnly = this.isViewOnlyAccess;
    this.getInventoryPostTypeSetting();
    this.inventoryStagingForm = this.fb.group({
      inventoryDate: this.propertyInfo.CurrentDate,
    });
    this.inventoryDateInput = {
      form: this.inventoryStagingForm,
      formControlName: 'inventoryDate',
      placeHolderId: 'lbl_inventoryDate',
      placeHolder: this.captions.lbl_inventoryDate,
      errorMessage: this.captions.err_MissinginventoryDate,
      automationId: "'Txt_inventoryStaging_inventoryDate'"
    };
    this.transferInventoryButton = {
      type: 'secondary',
      label: this.captions.btn_transferInventory,
      disabledproperty: true
    };
    this.refreshButton = {
      type: 'secondary',
      label: this.captions.lbl_refresh,
      disabledproperty: true
    };
    this.postSaveButton = {
      type: 'primary',
      label: this.captions.btn_postSave,
      disabledproperty: true
    };
    this.saveButton = {
      type: 'secondary',
      label: this.captions.btn_save,
      disabledproperty: true
    };
    this.cancelButton = {
      type: 'tertiary',
      label: this.captions.btn_cancel,
      disabledproperty: false
    };
    this.serverPageConfig = {
      initPageSize: this.pageSize,
      initFrom: 1,
      initTo: this.totalRecordsCount / this.pageSize,
      initPageIndex: 0
    };
    this.totalRecordsCount = 5; //data needs from api
    this.stagingStatus = [
      {
        id: StagingFilterType.All,
        name: this.captions.lbl_all,
        isSelected: true
      },
      {
        id: StagingFilterType.Posted,
        name: this.captions.lbl_posted,
        isSelected: false
      },
      {
        id: StagingFilterType.NotPosted,
        name: this.captions.lbl_notPosted,
        isSelected: false
      },
      {
        id: StagingFilterType.Duplicates,
        name: this.captions.lbl_duplicates,
        isSelected: false
      },
    ];
    this.searchTextChanged.pipe(debounceTime(SEARCH_DEBOUNCE_TIME), distinctUntilChanged()).subscribe(async val => await this.searchByInputAfterDebounce(val));
  }

  async generateTable() {
    this.headerOptions = this.business.GetTableHeader(this.IsViewOnly);
    this.options = this.business.GetTableOptions(this.IsViewOnly);
    await this.getUserInfo();
    this.getTableContent();
    this.initialTableContent = [...this.tableContent]
  }

  onStatusClick(item) {
    let itemSelected = item;
    this.stagingStatus.forEach(value => {
      value.isSelected = value.id === item.id;
    });
    this.getTableContent();
  }

  inventoryDateChange(eve) {
    console.log('InventoryStagingForm', this.inventoryStagingForm);
    this.getTableContent();

  }
  searchByInputAfterDebounce(input: string) {
    this.searchText = input;
    this.currentPage = 1;//Avoid double API call when searching from pages other than first page
    this.getTableContent();
  }

  async getInventoryPostTypeSetting(){
    let setting = await this.business.getInventorySetting();
    this.inventorySetting = Number(setting.value);
  }

  async getUserInfo(){
    this.users = await this.business.getUserInfo(this.utils.PropertyInfo.PropertyId,this.utils.PropertyInfo.ProductId)
  }

  inventoryStagingSearch(eve) {
    if (eve.length >= 3 || (eve.length == 0 && this.searchText.length > 0)) {
      this.searchTextChanged.next(eve);
    }
  }
  onRefresh() {
    this.getTableContent(true);

  }

  async onAction() {
    if (this.selectedTabledata && this.selectedTabledata.some(x => x.outletId != this.selectedTabledata[0].outletId)) {
      this.utils.showAlert(this.captions.warn_stagingDifferentOutlet, AlertType.Warning, ButtonType.Ok);
      return;
    }
    this.selectedTabledata.forEach(element => {
      element['transferedQty'] = 0;
      element['checkedFlag'] = true;
    });
    const dataset = {
      entireTabledata: this.tableContent,
      selectedRow: this.selectedTabledata,
      fromOutletId: this.selectedTabledata[0].outletId
      // fromOutlet: this.fromOutlet
    };
    const transferInventorydialogRef = this.dialog.open(RetailPopupComponent, {
      width: '90%',
      height: '80%',
      maxHeight: '1000px',
      maxWidth: '1200px',
      disableClose: true,
      data: { from: RetailScreen.inventoryStaging, headername: this.captions.addTransferInventory, closebool: true, templatename: 'TI', datarecord: dataset, popupConfig: { operation: '' } },
      hasBackdrop: true,
      panelClass: 'small-popup'
    });

    transferInventorydialogRef.afterClosed().subscribe(result => {
      this.getTableContent();
    });
  }
  tableAction(eve) {
    switch (eve.fromType) {
      case FromTypeEnum.delete:
        this.utils.showAlert(this.captions.warn_deleteSingleStaging, AlertType.Warning, ButtonType.YesNo, res => {
          if (res === AlertAction.YES)
            this.deleteEvent(eve.Obj);
        })
        break;
      case FromTypeEnum.rowcheck:
      case FromTypeEnum.allcheckbox:
        // let selectedData = this.tableContent.some(x=> x.checked)
        if (eve.checkedData.filter(x => !x.isCheckboxDisabled).length > 0) {
          let zeroQuantityCheck = eve.checkedData.filter(x => !x.isCheckboxDisabled).some(x => x.quantity == null || x.quantity == '');
          let allChecked = eve.checkedData.every(x => x.checked && !x.isCheckboxDisabled);
          this.postSaveButton.disabledproperty = zeroQuantityCheck && allChecked;
          this.saveButton.disabledproperty = zeroQuantityCheck && allChecked;
          this.transferInventoryButton.disabledproperty = zeroQuantityCheck && allChecked;
        } else {
          this.disableButtons();
        }
    }
    this.selectedTabledata = eve.checkedData.filter(x => !x.isCheckboxDisabled);
  }

  disableButtons(){
    this.postSaveButton.disabledproperty = true;
    this.saveButton.disabledproperty = true;
    this.transferInventoryButton.disabledproperty = true;
  }

  async deleteEvent(obj) {
    let result = await this.business.deleteStagings([obj.id]);
    if (result.result) {
      this.retailUtils.showAlert(this.inventoryStagingCaptions.success_InventoryDeleted, AlertType.Success, ButtonType.Ok);
    }
    else {
      this.retailUtils.showAlert(this.captions.err_Stagingnotdeleted, AlertType.Error, ButtonType.Ok);
    }
    this.getTableContent();
  }

  async deleteSelected() {
    this.utils.showAlert(this.captions.warn_deleteMultipleStagings, AlertType.Warning, ButtonType.YesNo, async res => {
      if (res === AlertAction.YES) {
        let deleteIds = this.selectedTabledata.map(x => x.id);
        let result = await this.business.deleteStagings(deleteIds);
        if (result.result) {
          this.retailUtils.showAlert(this.inventoryStagingCaptions.success_SelectedInventoryDeleted, AlertType.Success, ButtonType.Ok);
        }
        else {
          this.retailUtils.showAlert(this.captions.err_Stagingnotdeleted, AlertType.Error, ButtonType.Ok);
        }
        this.getTableContent();
      }
    })
  }

  async onPostSave(eve) {
    if (this.selectedTabledata.some(x => x.quantity == null || x.quantity == '')) {
      this.retailUtils.showAlert(this.captions.warn_quantityMissingStaging, AlertType.Warning, ButtonType.Ok);
      return;
    }
    if (this.inventorySetting == InventoryStagingPostType.AskEveryTime) {
      const dialogRef = this.dialog.open(AlertPopupComponent, {
        width: "300px",
        height: 'auto',
        data: {
          type: AlertType.Info, message: this.captions.InventoryStagingPostType_warning, header: '',
          dynamic: [{ value: InventoryStagingPostType.Override, label: this.captions.label_override, class: 'primary' }, { value: InventoryStagingPostType.Cumulative, label: this.captions.label_Cumulative, class: 'secondary' },
          { value: 0, label: this.captions.label_Cancel, class: 'tertiary' }
          ]
        },
        panelClass: 'small-popup',
        disableClose: false,
      });
      return dialogRef.afterClosed().subscribe(async res => {
        if(res != null && res != 0){
          let isCumulativeCount = (res == InventoryStagingPostType.Cumulative)
          let result = await this.business.PostAndSaveInventoryStagings(this.selectedTabledata, isCumulativeCount);
          if (result) {
            this.retailUtils.showAlert(this.inventoryStagingCaptions.success_InventoryStagingPosted, AlertType.Success, ButtonType.Ok);
          }
          else{
            this.retailUtils.showError(this.captions.err_postingInventoryStagingFailed);
          }
          this.getTableContent(); 
        }
      });
    }
    else {
      console.log(eve);
      let isCumulativeCount = this.inventorySetting == InventoryStagingPostType.Cumulative ? true : false;
      let result = await this.business.PostAndSaveInventoryStagings(this.selectedTabledata, isCumulativeCount);
      if (!result) {
        this.retailUtils.showError(this.captions.err_postingInventoryStagingFailed);
      }
      this.getTableContent();
    }
    this.isPageInvalid = false;
  }

  async onSave(eve) {
    let result = await this.business.updateInventoryStagingQuantity(this.selectedTabledata);
    if (result.result) {
      this.retailUtils.showAlert(this.inventoryStagingCaptions.success_InventoryStagingUpdated, AlertType.Success, ButtonType.Ok);
    }
    else{
      this.retailUtils.showError(result.errorDescription);
    }
    this.isPageInvalid = false;
    this.getTableContent();
  }
  
  onCancel(eve) {
    this.isPageInvalid = false;
    this.selectedTabledata = [];
    this.getTableContent();
  }
  getTableContent(fromRefresh:boolean = false) {
    this.selectedTabledata = [];
    this.retailUtils.ToggleLoader(true);
    try {
      const filterType = this.stagingStatus.find(x => x.isSelected)?.id ?? StagingFilterType.All;
      let filter: InventoryStagingFilter = {
        InventoryDate: this.localization.convertDateObjToAPIdate(this.inventoryStagingForm.value.inventoryDate),
        InputString: this.searchText,
        PageNumber: this.currentPage,
        PageSize: this.pageSize,
        FilterType: filterType
      };
      if(this.isAccessAllowed && !this.isViewOnlyAccess){
        this.business.IsPostingInProgress().then(resp => {
          this.isPostingInProgress = resp ? true : false;
          this.refreshButton.disabledproperty = !this.isPostingInProgress;
          {
            this.IsViewOnly = this.isPostingInProgress;
            this.headerOptions = this.business.GetTableHeader(this.IsViewOnly);
            this.options = this.business.GetTableOptions(this.IsViewOnly);
          }
          if(fromRefresh && !this.isPostingInProgress){
            this.utils.showAlert(this.inventoryStagingCaptions.completed_msg,AlertType.Info, ButtonType.Ok);
          }
        });

      }
     
      this.business.getTableContent(filter, this.users).then(
        res => {
          if (res[0]) {
            this.selectedTabledata = [];
            this.tableContent = res[0];
            this.totalRecordsCount = res[1];
            this.retailUtils.ToggleLoader(false);
            this.isPageInvalid = false;
            this.disableButtons();
          }

        }
      );
    }
    catch (ex) {
      this.retailUtils.ToggleLoader(false);
    }
  }

  serverPageEmit($event) {
    if ($event && (this.currentPage != ($event.pageIndex + 1) || this.pageSize != $event.pageSize)) {
      this.pageSize = $event.pageSize ? $event.pageSize : 20;
      this.currentPage = $event.pageIndex ? $event.pageIndex + 1 : 1;
      this.getTableContent();
    }

  }

  onQuantityBlur(eve, element) {
    this.isPageInvalid = true;
    if(element.checked)
      return;
    this.cdkComponent.emitcheckdata({checked:true}, element, 'checked');
  }
  
  
}
